import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { colors } from "@/helpers";
import { StyledButtons, StyledSelectField, } from "@/components/Audits/AuditPlanTable/index.styles";
import { TaskStatus } from "@/components/Audits/Tasks/TasksListTable/helpers";
import { IconButton } from "@/packages/icon-button/IconButton";
import { MOCK_STATUS_VALUES } from "@/components/Audits/AuditPlanTable/helpers";
import styled from "styled-components";
import {
  TWarrantyAuditTaskDto,
  TWarrantyEditStatusRequest,
  TWarrantyTask,
} from "@/helpers/api/warranty-audit/plan/types";
import { t, TFunction } from "i18next";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

const StyledData = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const getDefaultWarrantyPlanTasksHeaders = <
  TRecord extends object
>(options: {
  isKia: boolean;
  onSetTaskStatus: (id: number, patch: TWarrantyEditStatusRequest) => void;
  onMessageClick: (task: TWarrantyAuditTaskDto) => void;
  openUserModal: Dispatch
}): IHeaderItem<TWarrantyTask>[] => {
  const { onMessageClick, onSetTaskStatus, isKia, openUserModal } = options;
  return [
    {
      Header: t("warrantyAudits.list.id") || "",
      accessor: "id",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: t("audits.tasks.audit") || "",
      accessor: "audit.title",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: t("audits.list.checkpoint") || "",
      accessor: "title",
    },
    {
      Header: t("audits.templates.plan") || "",
      accessor: "task",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
    },
    {
      Header: t("audits.tasks.responsibleId") || "",
      accessor: "responsibleId",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledUserNameWrapper
            onClick={() => openUserModal({ userId: original?.responsible.id })}>
            {original?.responsible?.firstName} {original?.responsible?.lastName}
          </StyledUserNameWrapper>
        );
      },
    },
    {
      Header: t("warrantyAudits.list.deadline") || "",
      accessor: "deadline",
      width: "111px",
      Cell: ({ row: { original }, value }) => {
        const isOld = new Date() > new Date(value);

        return value ? (
          <StyledData
            style={{
              color: isOld ? "#E32446" : "black",
            }}
          >
            <Icon
              size={16}
              name="calendar-16"
              color={isOld ? "redStatus10" : "black"}
            />
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
          </StyledData>
        ) : (
          "-"
        );
      },
    },
    {
      Header: t("audits.tasks.completedAt") || "",
      accessor: "completedAt",
      width: "111px",
      Cell: ({ row: { original }, value }) => {
        if (value) {
          const date = new Date(value).toLocaleDateString();

          return (
            <StyledData>
              <Icon size={16} name="calendar-16"/>
              <Paragraph size={12} weight={300}>
                {date}
              </Paragraph>
            </StyledData>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: t("audits.tasks.status") || "",
      accessor: "status",
      Cell: ({ row: { original }, value }) => {
        let color;
        switch (value) {
          case "review": {
            color = colors.green100;

            break;
          }
          case "in_work": {
            color = colors.gray100;

            break;
          }
          case "accepted": {
            color = colors.yellow;

            break;
          }
        }

        return isKia ? (
          <StyledSelectField
            options={MOCK_STATUS_VALUES.filter((item) => !!item.value)}
            value={MOCK_STATUS_VALUES.find(
              (item) => item.value === value?.toString()
            )}
            defaultValue={original?.status || null}
            onChange={(value, option) => {
              if (!Array.isArray(option)) {
                onSetTaskStatus(original.id, {
                  status: value,
                });
              }
            }}
            color={color}
          />
        ) : (
          <TaskStatus status={value}/>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        return (
          <StyledButtons>
            <IconButton
              icon="chat"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => onMessageClick(original)}
            />
          </StyledButtons>
        );
      },
    },
  ];
};

export const warrantyChatBtn = ({
                                  t,
                                  isDealerKia,
                                  selectedTask,
                                  handleEditStatus,
                                  ConfirmModalOpen,
                                }: {
  t: TFunction;
  isDealerKia: boolean;
  selectedTask?: TWarrantyAuditTaskDto;
  ConfirmModalOpen?: () => void;
  handleEditStatus: (
    taskId: number,
    request: TWarrantyEditStatusRequest,
    message?: string
  ) => void;
}) => {
  const accept = {
    confirmText: t("warrantyAudits.list.accept"),
    confirmCb: () => {
      if (selectedTask) {
        handleEditStatus(selectedTask.id, {
          status: "accepted",
        });
      }
    },
  };

  const inWork = {
    cancelText: t("warrantyAudits.list.sendToRework"),
    cancelCb: () => {
      if (selectedTask) {
        handleEditStatus(selectedTask.id, {
          status: "in_work",
        });
      }
    },
  };

  const cancelSend = {
    cancelText: t("warrantyAudits.list.cancelSendToReview"),
    cancelCb: () => {
      if (selectedTask) {
        handleEditStatus(selectedTask.id, { status: "in_work" });
      }
    },
  };

  const sendToKia = {
    confirmText: t("warrantyAudits.list.sendToReview"),
    confirmCb: () => {
      if (selectedTask) {
        handleEditStatus(selectedTask.id, { status: "review" });
      }
    },
  };

  if (isDealerKia) {
    switch (selectedTask?.status) {
      case "in_work":
        return {
          ...accept,
        };
      case "review":
        return { ...accept, ...inWork };
    }
  } else {
    switch (selectedTask?.status) {
      case "in_work":
        return {
          ...sendToKia,
        };
      case "review":
        return {
          ...cancelSend,
        };
    }
  }
};
