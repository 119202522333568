import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TEngineTypeForm, ENGINE_TYPE_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export interface IEngineTypeCreateModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (value: TEngineTypeForm) => void;
  isLoadingSubmit: boolean;
}

export const EngineTypeCreateModal: React.FC<IEngineTypeCreateModal> = (
  props
) => {
  const { onSubmit, isLoadingSubmit, ...restProps } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState<TEngineTypeForm>(ENGINE_TYPE_INITIAL_FORM);

  const engineTypeError = !form.engineType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      engineType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit(form);
  };

  useEffect(() => {
    if (!restProps.isOpen) {
      setForm(ENGINE_TYPE_INITIAL_FORM);
    }
  }, [restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
      title={t("parts.addEngineType") || ""}
      confirmButtonText={t("parts.create") || ""}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.engine") || ""}
          description={t("parts.engineEnter") || ""}
          value={form.engineType}
          error={engineTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
