import { Button } from "@/packages/button/Button";
import { Icon } from "@/packages/icon/Icon";
import styled from "styled-components";

export const StyledAddButton = styled(Button)`
  border: none;
  background: none;
`;

export const StyledAddIcon = styled(Icon)`
  margin: 0 10px 0 0;
`;
