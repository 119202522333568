import React from "react";

export const useSimpleDictCreateModal = (
  onSubmitCreator: (close) => (record: string) => void
) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClose = () => {
    closeModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onSubmit = onSubmitCreator(closeModal);

  const modalState = {
    isOpen,
    onSubmit,
    onClose,
  };

  return {
    modalState,
    openModal,
    closeModal,
  };
};
