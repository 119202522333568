import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledMonitoringUpload = styled.div`
  max-width: 570px;
`;

export const StyledUploadList = styled.ul<{ isExpandedGrid?: boolean }>`
  box-sizing: border-box;

  display: grid;
  ${({ isExpandedGrid }) =>
    isExpandedGrid
      ? `
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 0 20px;
    gap: 12px;
    border: 0;
    background-color: transparent;
    min-width: 350px;
    align-items: end;
  `
      : `
      grid-template-columns: repeat(5, 1fr);
      padding: 16px 24px;
      gap: 12px;
      border: 1px solid ${colors.gray300};
      background-color: ${colors.white};
      min-width: 350px;
      align-items: end;
      `}

  width: 100%;

  border-radius: 4px;
  transition: all 0.5s ease;

  .ant-upload.ant-upload-drag {
    height: 100%;
    border-radius: 4px;
    background-color: #e7eaff;
    border: 1px solid ${colors.gray300};
  }

  img {
    border-radius: 4px;
    min-height: initial;
    overflow: hidden;
  }
`;

export const StyledUploadItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const StyledUploadFieldWrap = styled.div`
  position: relative;
  height: 96px;

  & > div {
    height: 100%;
  }
`;

export const StyledSubTitleWrap = styled.div`
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const StyledParagraph = styled(Paragraph)`
  width: 84px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
