interface IIconButtonTheme {
  [key: string]: {
    [key: string]: { [key: string]: { [key: string]: string } };
  };
}
const themes: IIconButtonTheme = {
  primary: {
    brand: {
      default: {
        color: "white",
        backgroundColor: "brand",
        borderColor: "brand",
      },
      hoverAndFocus: {
        color: "white",
        backgroundColor: "brand",
        borderColor: "brand",
      },
      active: {
        color: "white",
        backgroundColor: "brand",
        borderColor: "brand",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "gray300",
        borderColor: "gray300",
      },
      loading: {
        backgroundColor: "brand",
        borderColor: "brand",
        loaderColor: "white",
      },
    },
    black: {
      default: {
        color: "white",
        backgroundColor: "black",
        borderColor: "black",
      },
      hoverAndFocus: {
        color: "white",
        backgroundColor: "gray600",
        borderColor: "gray600",
      },
      active: {
        color: "white",
        backgroundColor: "black",
        borderColor: "black",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "gray300",
        borderColor: "gray300",
      },
      loading: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        loaderColor: "white",
      },
    },
    gray: {
      default: {
        color: "black",
        backgroundColor: "gray300",
        borderColor: "gray300",
      },
      hoverAndFocus: {
        color: "black",
        backgroundColor: "gray400",
        borderColor: "gray400",
      },
      active: {
        color: "black",
        backgroundColor: "gray400",
        borderColor: "gray400",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "gray300",
        borderColor: "gray300",
      },
      loading: {
        backgroundColor: "gray300",
        borderColor: "gray300",
        loaderColor: "black",
      },
    },

    white: {
      default: {
        color: "black",
        backgroundColor: "white",
        borderColor: "gray300",
      },
      hoverAndFocus: {
        color: "black",
        backgroundColor: "gray400",
        borderColor: "gray400",
      },
      active: {
        color: "black",
        backgroundColor: "gray400",
        borderColor: "gray400",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "gray300",
        borderColor: "gray300",
      },
      loading: {
        backgroundColor: "gray300",
        borderColor: "gray300",
        loaderColor: "black",
      },
    },
  },
  secondary: {
    brand: {
      default: {
        borderColor: "brand",
        backgroundColor: "transparent",
        color: "brand",
      },
      hoverAndFocus: {
        borderColor: "brand",
        backgroundColor: "transparent",
        color: "brand",
      },
      active: {
        borderColor: "brand",
        backgroundColor: "transparent",
        color: "brand",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "gray300",
      },
      loading: {
        borderColor: "transparent",
        backgroundColor: "transparent",
        loaderColor: "brand",
      },
    },
    black: {
      default: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "black",
      },
      hoverAndFocus: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "gray600",
      },
      active: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "black",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "gray300",
      },
      loading: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        loaderColor: "black",
      },
    },
    gray: {
      default: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "gray300",
      },
      hoverAndFocus: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "gray400",
      },
      active: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "gray400",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "gray400",
      },
      loading: {
        backgroundColor: "transparent",
        borderColor: "gray300",
        loaderColor: "black",
      },
    },
  },
  tertiary: {
    brand: {
      default: {
        borderColor: "transparent",
        backgroundColor: "transparent",
        color: "brand",
      },
      hoverAndFocus: {
        borderColor: "transparent",
        backgroundColor: "transparent",
        color: "brand",
      },
      active: {
        borderColor: "transparent",
        backgroundColor: "transparent",
        color: "brand",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      loading: {
        borderColor: "transparent",
        backgroundColor: "transparent",
        loaderColor: "brand",
      },
    },
    black: {
      default: {
        color: "black",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hoverAndFocus: {
        color: "gray600",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        color: "gray600",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      loading: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        loaderColor: "black",
      },
    },
    gray: {
      default: {
        color: "gray500",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hoverAndFocus: {
        color: "gray600",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        color: "gray600",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        color: "gray400",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      loading: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        loaderColor: "gray500",
      },
    },
  },
};

export default themes;
