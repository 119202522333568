import { accessTokenService } from "@/helpers/api/accessTokenService";
import { api } from "@/helpers/api/axiosInstance";
import { TGetProfileResponse } from "@/helpers/api/profile/types";
import { usePermission } from "@/hooks/usePermission";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  TGetDealerByIdRequest,
  TGetDealerByIdResponse,
  TGetDealerListFiltersResponse,
  TGetDealerListRequest,
  TGetDealerListResponse,
} from "./types";

export const useGetPublicDealerList = (request: TGetDealerListRequest) => {
  return useQuery(
    ["dealers", "list", "public"],
    () => {
      return api.get<TGetDealerListResponse>("/public/dealers", {
        params: request,
      });
    },
    { enabled: !Boolean(accessTokenService.getToken()) }
  );
};

export const useGetDealerList = (request: TGetDealerListRequest) => {
  const queryClient = useQueryClient();
  const { hasAccess } = usePermission("dc", "read");
  const hasToken = Boolean(accessTokenService.getToken());

  const isEnabled = Boolean(
    hasToken &&
      hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "dealer"
  );

  return useQuery(
    ["dealers", "list", request],
    () => {
      return api.get<TGetDealerListResponse>("/dealer/dealers", {
        params: request,
      });
    },
    {
      enabled: isEnabled,
    }
  );
};

export const useGetDealerById = (request: TGetDealerByIdRequest) => {
  const queryClient = useQueryClient();

  const isEnabled = Boolean(
    (
      queryClient.getQueryData([
        "profile",
        accessTokenService.getToken(),
      ]) as AxiosResponse<TGetProfileResponse>
    )?.data?.type === "dealer"
  );

  return useQuery(
    ["dealers", "detail", request.id],
    () => {
      return api.get<TGetDealerByIdResponse>(`/dealer/dealers/card`);
    },
    { enabled: isEnabled }
  );
};

export const useGetDealerListFilters = () => {
  const queryClient = useQueryClient();

  const isEnabled = Boolean(
    (
      queryClient.getQueryData([
        "profile",
        accessTokenService.getToken(),
      ]) as AxiosResponse<TGetProfileResponse>
    )?.data?.type === "dealer"
  );

  return useQuery(
    ["dealers", "list-filters"],
    () => {
      return api.get<TGetDealerListFiltersResponse>(`/dealer/dealers/filters`);
    },
    { enabled: isEnabled }
  );
};
