import { colors } from "@/helpers";
import { NestedTable } from "@/packages/nestedTable/NestedTable";
import { Pagination } from "@/packages/pagination/Pagination";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledModelsListTab = styled.div`
  background-color: ${colors.white};
`;

export const StyledNestedTable = styled(NestedTable)`
  border: none;
`;

export const StyledModelsListTabFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;

export const StyledLastUpdate = styled(Paragraph)``;

export const StyledPagination = styled(Pagination)`
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
