import * as yup from "yup";
import { TFlexTariffsRecord } from "@/helpers/api/flex/tariffs/types";
import { TCreateTypeDTO, transformCreateDataToDTO } from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TFunction } from "i18next";
import { useEffect } from "react";

export interface TTariffsValues {
  longPeriod: number[];
  shortPeriod: number[];
  generationId: string;
}

const DEFAULT_TARIFFS_FORM_VALUES: TTariffsValues = {
  longPeriod: [],
  shortPeriod: [],
  generationId: "",
};

const formSchema = ({ t, isEdit }: { t: TFunction; isEdit: boolean }) => {
  const requiredObject: { [key: string]: any } = {
    shortPeriod: yup.array().of(
      yup
        .number()
        .typeError(t("common.required") || "")
        .min(1, t("common.required") || "")
        .required(t("common.required") || "")
    ),
    longPeriod: yup.array().of(
      yup
        .number()
        .typeError(t("common.required") || "")
        .min(1, t("common.required") || "")
        .required(t("common.required") || "")
    ),
  };
  if (!isEdit) {
    requiredObject.generationId = yup
      .string()
      .required(t("common.required") || "");
  }

  return yup.object(requiredObject);
};

export const useCreateTariffsForm = ({
  onSubmit,
  t,
}: {
  t: TFunction;
  onSubmit: (value: TCreateTypeDTO) => void;
}) => {
  const form = useForm<TTariffsValues>({
    resolver: yupResolver(formSchema({ t, isEdit: false })),
    mode: "onChange",
    defaultValues: DEFAULT_TARIFFS_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    const dataToDTO = transformCreateDataToDTO(data);
    onSubmit(dataToDTO);
  });

  const onClearHandler = () => {
    form.reset();
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};

export const useEditTariffsForm = ({
  onSubmit,
  record,
  t,
}: {
  t: TFunction;
  onSubmit: ({ id, patch }: { id?: string; patch: TCreateTypeDTO }) => void;
  record?: TFlexTariffsRecord;
}) => {
  const form = useForm<TTariffsValues>({
    mode: "onChange",
    resolver: yupResolver(formSchema({ t, isEdit: true })),
    defaultValues: {
      generationId: record?.generation.id,
      longPeriod: record?.longPeriod.map((item) => item.cost) || [],
      shortPeriod: record?.shortPeriod.map((item) => item.cost) || [],
    },
  });

  const reset = form.reset;

  useEffect(() => {
    if (record) {
      reset({
        generationId: record.generation.id,
        longPeriod: record?.longPeriod.map((item) => item.cost) || [],
        shortPeriod: record?.shortPeriod.map((item) => item.cost) || [],
      });
    }
  }, [record, reset]);

  const onSubmitHandler = form.handleSubmit((data) => {
    const dataToDTO = transformCreateDataToDTO(data);
    onSubmit({ id: record?.id, patch: dataToDTO });
  });

  const onClearHandler = () => {
    form.reset();
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
