import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

import { AutoParkUpload } from "@/components/AutoPark/AutoParkUpload";

import { useGetGenerationList } from "@/helpers/api/generations/hooks";
import { useGetModelList } from "@/helpers/api/models/hooks";
import { useGetEquipmentList } from "@/helpers/api/modifications/hooks";
import { useGetDecoder } from "@/helpers/api/vin/hooks";

import { TVehicleRecord } from "@/helpers/api/vehicles/types";
import { useNotification } from "@/hooks/useNotification";

import {
  IAutoParkFormValues,
  TOnSubmitAutoParkFormCb,
  useAutoParkForm,
} from "./hooks";

import {
  StyledAutoParkFormWrap,
  StyledButtonWrap,
  StyledFormHalfColumn,
  StyledFormLayout,
  StyledFormRow,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IAutoParkForm {
  isLoading: boolean;
  onFormSubmit: TOnSubmitAutoParkFormCb;
  defaultValues?: TVehicleRecord;
}

export const AutoParkForm: React.FC<IAutoParkForm> = (props) => {
  const { isLoading, onFormSubmit, defaultValues } = props;
  const [isManualVinPrefilling, setIsManualVinPrefilling] = useState(
    !!defaultValues
  );
  const [isMounted, setIsMounted] = useState(false);

  const { t } = useTranslation();

  const { form, onSubmitHandler, imageFieldArray, fileFieldArray } =
    useAutoParkForm(onFormSubmit, defaultValues);

  const [vin, modelId, generationId, modificationId, images, files] =
    form.watch([
      "vin",
      "modelId",
      "generationId",
      "modificationId",
      "images",
      "files",
    ]);

  const isVinError = !!form.formState.errors?.vin;

  const { createNotificationError } = useNotification();

  const { data: responseModelList } = useGetModelList({ limit: 0 });
  const { data: responseGenerationList } = useGetGenerationList({
    modelId: modelId!,
  });
  const { data: responseEquipmentList } = useGetEquipmentList({
    generationId: generationId!,
  });

  const {
    data: responseDecoder,
    isError: isDecoderError,
    mutateAsync: getDecoderAsync,
    isLoading: isGetDecoderLoading,
    reset: responseDecoderReset,
  } = useGetDecoder();

  const mappedModels = useMemo(() => {
    const currentModelList = responseModelList?.data?.data || null;
    return (
      currentModelList?.map((el) => ({
        value: el.id,
        name: el.name,
        label: el.name,
      })) || []
    );
  }, [responseModelList]);

  const mappedGenerations = useMemo(() => {
    const currentGenerationList = responseGenerationList?.data?.data || null;
    return (
      currentGenerationList?.map((el) => ({
        value: el.id,
        name: `${el.name} ${el.restylingName ? el.restylingName : ""}`,
        label: `${el.name} ${el.restylingName ? el.restylingName : ""}`,
      })) || []
    );
  }, [responseGenerationList]);

  const mappedModifications = useMemo(() => {
    const currentModifications = responseEquipmentList?.data?.data || null;
    return (
      currentModifications?.map((el) => {
        const currentName = `${el.carBodyType.name} ${el.engineVolume.volume} ${el.engineType.name} ${el.fuelType.name} ${el.transmissionType.name}`;
        return {
          value: el.id,
          name: currentName,
          label: currentName,
        };
      }) || []
    );
  }, [responseEquipmentList]);

  const mappedEquipments = useMemo(() => {
    const currentEquipment =
      responseEquipmentList?.data?.data.find(
        (item) => item.id === modificationId
      ) || null;
    return (
      // @ts-ignore
      currentEquipment?.equipments.map((el) => ({
        value: el?.id,
        name: el?.name,
        label: el?.name,
      })) || []
    );
  }, [responseEquipmentList, modificationId]);

  const clearForms = (...arg: (keyof IAutoParkFormValues)[]): void => {
    arg.forEach((formName) => {
      if (formName && form.watch(formName)) {
        form.setValue(formName, "");
      }
    });
    // if (!isManualVinPrefilling) {
    //   form.setValue("vin", "");
    // }
  };

  const resetDecoderData = () => {
    setIsManualVinPrefilling(false);
    responseDecoderReset();
  };

  const handleVinButtonClick = () => {
    getDecoderAsync({
      data: { vin: vin! },
      onSuccess: () => {
        setTimeout(() => resetDecoderData, 8000);
      },
      onError: () => {
        setIsManualVinPrefilling(false);
        createNotificationError(t("park.createNotificationError") || "");
      },
    });
  };

  useEffect(() => {
    if (isMounted) {
      clearForms(
        "generationId",
        "modificationId",
        "equipmentId",
        "fuelType",
        "engineType",
        "carBodyType",
        "engineVolume",
        "transmissionType"
      );
    }
  }, [modelId]);

  useEffect(() => {
    if (isMounted) {
      clearForms(
        "modificationId",
        "equipmentId",
        "fuelType",
        "engineType",
        "carBodyType",
        "engineVolume",
        "transmissionType"
      );
    }
  }, [generationId]);

  useEffect(() => {
    if (isMounted) {
      clearForms("equipmentId");
    }
  }, [modificationId]);

  useEffect(() => {
    if (modificationId) {
      const currentTypes = responseEquipmentList?.data
        ? responseEquipmentList?.data?.data.find(
            (item) => item.id === modificationId
          )
        : responseDecoder?.data
        ? responseDecoder?.data?.generation.modification
        : null;

      if (currentTypes) {
        form.setValue("transmissionType", currentTypes?.transmissionType?.name);
        form.setValue("engineType", currentTypes?.engineType?.name);
        form.setValue("carBodyType", currentTypes?.carBodyType?.name);
        form.setValue("fuelType", currentTypes?.fuelType?.name);
        form.setValue(
          "engineVolume",
          currentTypes?.engineVolume?.volume as unknown as string
        );
      }
    }

    if (isManualVinPrefilling) {
      resetDecoderData();
    }
  }, [modificationId, responseEquipmentList]);

  useEffect(() => {
    const currentValues = responseDecoder?.data || null;
    if (currentValues) {
      form.setValue("modelId", currentValues.id);
    }
  }, [responseDecoder]);

  useEffect(() => {
    const currentValues = responseDecoder?.data || null;
    if (currentValues) {
      const currentGeneration = mappedGenerations.find(
        (item) => item.value === currentValues.generation.id
      );
      if (currentGeneration) {
        form.setValue("generationId", currentGeneration.value);
      }
    }
  }, [responseDecoder, mappedGenerations]);

  useEffect(() => {
    const currentValues = responseDecoder?.data || null;
    if (currentValues) {
      const currentModification = mappedModifications.find(
        (item) => item.value === currentValues.generation.modification.id
      );
      if (currentModification) {
        form.setValue("modificationId", currentModification.value);
      }
    }
  }, [responseDecoder, mappedModifications]);

  useEffect(() => {
    const currentValues = responseDecoder?.data || null;
    if (currentValues) {
      const currentEquipment = mappedEquipments.find(
        (item) =>
          item.value ===
          // @ts-ignore
          responseDecoder?.data.generation.modification?.equipment?.id
      );
      if (currentEquipment) {
        form.setValue("equipmentId", currentEquipment.value);
      }
    }
  }, [responseDecoder, mappedEquipments]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <StyledAutoParkFormWrap>
      <StyledFormLayout>
        <FormProvider {...form}>
          <StyledFormRow>
            <FormDatepickerField
              name="commissioningDate"
              label={t("park.commissioningDate") || ""}
            />
            <FormDatepickerField
              name="expiredOSAGO"
              label={t("park.expiredOSAGO") || ""}
            />
            <FormDatepickerField
              name="expiredCASCO"
              label={t("park.expiredCASCO") || ""}
            />
          </StyledFormRow>
          <StyledFormRow>
            <FormInputField
              name="registrationCertificate"
              label={t("park.registrationCertificate") || ""}
            />
            <FormInputField
              name="number"
              label={t("park.xNNNxxNNN") || ""}
              placeholder="xNNNxxNNN"
            />
          </StyledFormRow>
          <StyledFormRow>
            <FormInputField name="vin" label="VIN*" />
            <StyledButtonWrap>
              <Button
                color="brand"
                size="small"
                paddingInline={24}
                onClick={handleVinButtonClick}
                isDisabled={isGetDecoderLoading || isVinError || !vin}
              >
                {t("park.prefill") || ""}
              </Button>
            </StyledButtonWrap>
          </StyledFormRow>
          <StyledFormRow>
            <FormSelect
              name="modelId"
              label={t("park.modelId") || ""}
              isEditable={true}
              isSearchShow={true}
              options={mappedModels}
            />
            <FormSelect
              name="generationId"
              label={t("park.generationId") || ""}
              isSearchShow={true}
              isEditable={!!modelId}
              options={mappedGenerations}
            />
            <FormSelect
              name="modificationId"
              label={t("park.modificationId") || ""}
              isSearchShow={true}
              isEditable={!!generationId}
              options={mappedModifications}
            />
          </StyledFormRow>
          <StyledFormRow>
            <FormSelect
              name="equipmentId"
              label={t("park.equipmentId") || ""}
              isSearchShow={true}
              isEditable={!!modificationId}
              options={mappedEquipments}
            />
            <FormInputField name="color" label={t("park.color") || ""} />
          </StyledFormRow>
          <StyledFormRow>
            <FormInputField
              name="transmissionType"
              label={t("park.transmissionType") || ""}
              isEditable={false}
            />
            <FormInputField
              name="engineType"
              label={t("park.engineType") || ""}
              isEditable={false}
            />
            <FormInputField
              name="carBodyType"
              label={t("park.carBodyType") || ""}
              isEditable={false}
            />
          </StyledFormRow>
          <StyledFormRow>
            <FormInputField
              name="fuelType"
              label={t("park.fuelType") || ""}
              isEditable={false}
            />
            <FormInputField
              name="engineVolume"
              label={t("park.engineVolume") || ""}
              isEditable={false}
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledFormHalfColumn>
              <AutoParkUpload
                label={t("park.photo") || ""}
                data={images}
                fieldsName="images"
                fieldArray={imageFieldArray}
                allowedTypes={["image/png", "image/jpeg", "image/tiff"]}
                description={t("park.photoDescr") || ""}
              />
            </StyledFormHalfColumn>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFormHalfColumn>
              <AutoParkUpload
                label={t("park.files") || ""}
                data={files}
                fieldsName="files"
                fieldArray={fileFieldArray}
                description={t("park.filesDescr") || ""}
              />
            </StyledFormHalfColumn>
          </StyledFormRow>
        </FormProvider>
      </StyledFormLayout>

      <Button
        color="brand"
        theme="primary"
        size="middle"
        onClick={onSubmitHandler}
        isLoading={isLoading}
        isDisabled={!form.formState.isDirty || isLoading}
      >
        {t("profile.settings.save") || ""}
      </Button>
    </StyledAutoParkFormWrap>
  );
};
