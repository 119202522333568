import { Space } from "antd";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { DashboardLayout } from "@/layouts/DashboardLayout";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { NewsDataFields } from "@/components/NewsDataFields";
import { useNewsEditDataForm } from "@/components/NewsDataFields/hooks";
import { newsFormValuesToDTO } from "@/components/NewsDataFields/utils";
import { useGetNewsById, usePatchNews } from "@/helpers/api/news/hooks";
import { Button } from "@/packages/button/Button";

interface IEditFormData {
  id: string;
  name: string;
  description: string;
  text: string;
  fileId?: any;
}

const _NewsEdit: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = params.id as string;

  const { data: newsResponse, isLoading: isLoadingNews } = useGetNewsById({
    id,
  });

  const { mutateAsync: patchNewsAsync, isLoading: isLoadingPatch } =
    usePatchNews();

  const news = newsResponse?.data;
  //@ts-ignore
  const currentGroupId = newsResponse?.data.group_id;

  const { mutateAsync: patchNews } = usePatchNews();

  const onEdit: any = async (values) => {
    if (news?.id) {
      const editValues = { id: news?.id, ...newsFormValuesToDTO(values.patch) };
      await patchNews({
        id: news.id,
        patch: editValues,
      });
      //@ts-ignore
      navigate(`/news?activeTab=${news.group_id}`);
    }
  };

  const editDataForm: IEditFormData = {
    id: news?.id || "",
    name: news?.name || "",
    description: news?.description || "",
    text: news?.text || "",
    fileId: null,
  };

  if (news?.photo?.id) {
    editDataForm.fileId = news?.photo;
  }

  const { form, submit, isSubmitLoading, reset } = useNewsEditDataForm(
    editDataForm,
    onEdit,
    isLoadingNews
  );

  return (
    <DashboardLayout title="Изменение новости" toBack="/news">
      {isLoadingNews ? (
        <p>Loading...</p>
      ) : (
        <PermissionsAdapter
          resourceName="news"
          resourceAction="update"
          currentGroupId={currentGroupId}
          fallback={() => <p>Access denied</p>}
        >
          <FormProvider {...form}>
            <NewsDataFields />
          </FormProvider>
          <Space>
            <Button
              theme="primary"
              color="brand"
              size="middle"
              onClick={submit}
              isLoading={isSubmitLoading}
            >
              Сохранить изменения
            </Button>
            <Button
              theme="secondary"
              color="brand"
              size="middle"
              onClick={reset}
              isDisabled={!form.formState.isDirty}
            >
              Отмена
            </Button>
          </Space>
        </PermissionsAdapter>
      )}
    </DashboardLayout>
  );
};

export const NewsEdit = _NewsEdit;
