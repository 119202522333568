import React from "react";

import {
  StyledTableColumnLeft,
  StyledTableHeadLeftWrap,
  StyledInfoBlockList,
  StyledInfoBlockItem,
  StyledInfoBlockSubList,
  StyledInfoBlockSubItem,
  StyledInfoBlockSubItemParagraph,
  StyledUnitName,
  StyledModelParagraph,
  StyledC2CParagraph,
  StyledAutoContent,
  StyledUnitTitle,
  StyledUnitTitleWrapper,
  StyledUnitTitleSecond,
  StyledLoader,
} from "./index.styles";

import { IDatesTree } from "@/helpers/date";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Loader } from "@/packages/loader/Loader";
import { useTranslation } from "react-i18next";

interface IFlexCalendarTableColumnLeft {
  data: any[];
  datesTree: IDatesTree | null;
  isLoading: boolean;
}

export const FlexCalendarTableColumnLeft: React.FC<
  IFlexCalendarTableColumnLeft
> = (props) => {
  const { data, datesTree, isLoading } = props;
	const { t } = useTranslation();

  return (
    <StyledTableColumnLeft>
      {datesTree ? <StyledTableHeadLeftWrap /> : <></>}
      <StyledUnitTitleWrapper>
        <StyledUnitTitle size={12}>{t("kiaFlex.dc") || ""}</StyledUnitTitle>
        <StyledUnitTitleSecond size={12}>
          {t("kiaFlex.auto's") || ""}
        </StyledUnitTitleSecond>
      </StyledUnitTitleWrapper>
      <StyledInfoBlockList>
        {!isLoading ? (
          data.map((unit, unitIndex) => (
            <StyledInfoBlockItem
              padding={false}
              key={`units-item-${unit.name}-${unitIndex}`}
            >
              <Tooltip content={<div>{unit.name}</div>}>
                <StyledUnitName>
                  <Paragraph size={12} weight={700}>
                    {unit.name}
                  </Paragraph>
                </StyledUnitName>
              </Tooltip>

              <StyledInfoBlockList>
                {unit.models.map((car, carIndex) => (
                  <StyledInfoBlockItem
                    padding={true}
                    key={`cars-item-${car.name}-${carIndex}`}
                  >
                    <StyledModelParagraph
                      size={12}
                      weight={300}
                      color={"black10"}
                    >
                      {car.name}
                    </StyledModelParagraph>
                    <StyledInfoBlockSubList>
                      {car.vehicles.map((carItem, carItemIndex) => (
                        <StyledInfoBlockSubItem
                          key={`cars-sub-item-${car.number}-${carIndex}-${carItem.vin}-${carItemIndex}`}
                        >
                          <StyledAutoContent>
                            <StyledInfoBlockSubItemParagraph
                              size={12}
                              weight={300}
                              color="black10"
                            >
                              {`${carItem.vin} ${carItem.number}`}
                            </StyledInfoBlockSubItemParagraph>
                            {carItem.c2c && (
                              <StyledC2CParagraph size={10} weight={400}>
                                C2C
                              </StyledC2CParagraph>
                            )}
                          </StyledAutoContent>
                        </StyledInfoBlockSubItem>
                      ))}
                    </StyledInfoBlockSubList>
                  </StyledInfoBlockItem>
                ))}
              </StyledInfoBlockList>
            </StyledInfoBlockItem>
          ))
        ) : (
          <StyledLoader>
            <Loader size={48} />
          </StyledLoader>
        )}
      </StyledInfoBlockList>
    </StyledTableColumnLeft>
  );
};
