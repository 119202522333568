import React, { useEffect, useState } from "react";
import { IInput, Input } from "../Input";

export interface IInputNumber extends Omit<IInput, "onChange" | "value"> {
  value?: number | null;
  onChange?: (
    value: number | null,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  disableDot?: boolean;
}

const VALIDATE_REGEX = new RegExp(/[^0-9\.]/g);

export const InputNumber: React.FC<IInputNumber> = (props) => {
  const { value, onChange, disableDot = false, ...restInputProps } = props;

  const [innerValue, setInnerValue] = useState<string>("");

  const handleChange = (
    tempValue: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (tempValue === "") {
      setInnerValue("");
      onChange && onChange(null, event);
      return;
    }

    const newValue = tempValue.replace(VALIDATE_REGEX, "");

    setInnerValue(newValue);
  };

  useEffect(() => {
    if (!innerValue) return;

    const numberValue = parseFloat(innerValue);
    onChange && onChange(numberValue);
  }, [innerValue]);

  useEffect(() => {
    value && setInnerValue(value.toString());
  }, [value]);

  return (
    <Input
      {...restInputProps}
      value={innerValue}
      onChange={handleChange}
      type="number"
      disableDot={disableDot}
    />
  );
};
