import React from "react";
import styled, { Theme, ThemeProvider } from "styled-components";

import { colors } from "@/helpers/index";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { themes } from "./themes";

export interface IStatusLabel {
  color: "green" | "red" | "gray" | "purple" | "yellow" | "cyan" | "scarlet";
  content: string;
}

const StyledStatusLabel = styled(Paragraph)`
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  background-color: ${({ theme }) => colors[theme.backgroundColor]};
  color: ${({ theme }) => colors[theme.color]};
`;

export const StatusLabel: React.FC<IStatusLabel> = (props) => {
  const { color, content } = props;

  const adjustedTheme = (ancestorTheme: Theme) => ({
    ...ancestorTheme,
    ...themes[color],
  });

  return (
    <ThemeProvider theme={adjustedTheme}>
      <StyledStatusLabel size={10}>{content}</StyledStatusLabel>
    </ThemeProvider>
  );
};
