import { TMaintenanceGridsRecord } from "@/helpers/api/maintenanceGrids/grids/types";
import { TGsmRecord } from "@/helpers/api/maintenanceGrids/gsm/types";
import { createContext, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { usePagination } from "@/hooks/usePagination";
import {
  useCreateMaintenanceGrid,
  useGetMaintenanceGrids,
  useUpdateMaintenanceGrid,
} from "@/helpers/api/maintenanceGrids/grids/hooks";
import {
  useCreateGsm,
  useDeleteGsm,
  useGetGsmList,
  useGetGsmListDefault,
  useUpdateGsm,
} from "@/helpers/api/maintenanceGrids/gsm/hooks";
import {
  useCreateWork,
  useDeleteWork,
  useGetWorks,
  useGetWorksDefault,
  useUpdateWork,
} from "@/helpers/api/maintenanceGrids/works/hooks";
import { TWorksRecord } from "@/helpers/api/maintenanceGrids/works/types";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateMaintenanceGridsFormValues } from "@/components/MaintenanceGrids/GridsTab/hooks";
import {
  useCreateSpares,
  useDeleteSpares,
  useGetSpares,
  useUpdateSpare,
  useUpdateSpares,
} from "@/helpers/api/maintenanceGrids/spares/hooks";
import { usePermission } from "@/hooks/usePermission";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { FilterParams } from "@/components/MaintenanceGrids/GridsTab/GridsFilters";
import { StringParam, useQueryParam, useQueryParams, withDefault } from "use-query-params";
import { useRequestParams } from "@/hooks/useRequestParams";

export const useMaintenanceGrids = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchValueGsm, setSearchValueGsm] = useState<string>("");
  const [searchValueSpares, setSearchValueSpares] = useState<string>("");
  const [searchValueWorks, setSearchValueWorks] = useState<string>("");
  // const [searchValueGrids, setSearchValueGrids] = useState<string>("");
  const [searchValueGrids, setSearchValueGrids] = useQueryParam('searchGrids', withDefault(StringParam, ""));

  const [debouncedSearchValueGsm] = useDebounce(searchValueGsm, 400);
  const [debouncedSearchValueSpares] = useDebounce(searchValueSpares, 400);
  const [debouncedSearchValueWorks] = useDebounce(searchValueWorks, 400);
  const [debouncedSearchValueGrids] = useDebounce(searchValueGrids, 400);


  const navigate = useNavigate();
  const location = useLocation();

  const isEnabled = location.pathname.includes("/maintenance-grids");
  const { hasAccess: hasMaintenanceGridsEdit } = usePermission(
    "maintenance-grid",
    "update"
  );

  const { paginationParams, setPage } = usePagination();
  const { paginationParams: paginationGridsParams, setPage: setGridsPage } =
    usePagination();

  const [activeMaintenanceGrid, setActiveMaintenanceGrid] =
    useState<TMaintenanceGridsRecord | null>(null);
  const [creatingMaintenanceGridForm, setCreatingMaintenanceGridForm] =
    useState<CreateMaintenanceGridsFormValues | null>(null);
  const [activeGsm, setActiveGsm] = useState<TGsmRecord | null>(null);
  const [activeSpare, setActiveSpare] = useState<TGsmRecord | null>(null);
  const [activeWork, setActiveWork] = useState<TWorksRecord | null>(null);

  const [gridsFilters, setGridsFilters] = useQueryParams({
    models: withDefault(StringParam, undefined),
    generations: withDefault(StringParam, undefined),
    restyling: withDefault(StringParam, undefined),
  });

  const [filterPage, setFilterPage] = useState<FilterParams | null>(null);

  useEffect(() => {
    const filter: FilterParams = {}
    if (gridsFilters.models) {
      filter['filters[models]'] = gridsFilters.models
    }

    if (gridsFilters.generations) {
      filter['filters[generations]'] = gridsFilters.generations
    }

    if (gridsFilters.restyling) {
      filter['filters[restyling]'] = gridsFilters.restyling
    }

    setFilterPage(filter);

  }, [gridsFilters.models, gridsFilters.generations, gridsFilters.restyling]);

  const [maintenanceGridsSortParams, setMaintenanceGridsSortParams] = useRequestParams<any>({
    sortBy: "modelName",
    sortAt: "ASC",
  });

  const { data: maintenanceGrids } = useGetMaintenanceGrids(
    {
      ...paginationParams,
      "filters[modelName]": debouncedSearchValueGrids,
      onlyActive: !hasMaintenanceGridsEdit,
      ...maintenanceGridsSortParams,
      ...filterPage
    },
    isEnabled
  );
  const { mutate: createMaintenanceGrid, error: createMaintenanceGridError } =
    useCreateMaintenanceGrid({
      onSuccess: () => navigate("/maintenance-grids"),
    });
  const {
    mutate: updateMaintenanceGrid,
    isLoading: isMaintenanceGridUpdating,
    error: updateMaintenanceGridError,
    reset: resetUpdateMaintenanceGridQuery,
  } = useUpdateMaintenanceGrid();

  const [searchGrids, setSearchGrids] = useState("");
  const [searchWorks, setSearchWorks] = useState("");
  const [searchSpare, setSearchSpare] = useState("");

  const getRequestData = (searchValue: string, query?: boolean) => {
    if (searchValue === "") {
      return { limit: 50 };
    } else if (query) {
      return { limit: 0, query: searchValue };
    } else {
      return { limit: 0, name: searchValue };
    }
  };

  const [GSMRequestParams, setGSMRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const [sparesRequestParams, setSparesRequestParams] = useState<any>({
    field: null,
    orderBy: null,
  });

  const handleGSMSort = (accessor: HeaderArgType, order: SortOrder) => {
    setGSMRequestParams((prev) => ({
      ...prev,
      sortBy: order ? accessor.id : null,
      sortAt: order ? order.toUpperCase() : null,
    }));
  };

  const handleSparesSort = (accessor: HeaderArgType, order: SortOrder) => {
    setSparesRequestParams((prev) => ({
      ...prev,
      field: order ? accessor.id : null,
      orderBy: order ? order.toUpperCase() : null,
    }));
  };

  const { data: gsmList, refetch: refetchGsmList } = useGetGsmList({
    ...GSMRequestParams,
    ...paginationParams,
    query: debouncedSearchValueGsm,
    // @ts-ignore
    isEnabled,
    limit: 5,
  });
  const { data: gsmListFilters } = useGetGsmList(
    getRequestData(searchGrids, true)
  );

  const { data: gsmListDefault } = useGetGsmListDefault(
    isEnabled && Boolean(creatingMaintenanceGridForm?.isGsmDefault)
  );
  const { mutate: createGsm } = useCreateGsm();
  const { mutate: updateGsm } = useUpdateGsm();
  const { mutate: deleteGsm } = useDeleteGsm();
  const { mutate: deleteSpare } = useDeleteSpares();
  const { mutate: updateSpare } = useUpdateSpare();
  const { mutate: createSpare } = useCreateSpares();

  const [worksRequestParams, setWorksRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const handleWorksSort = (accessor: HeaderArgType, order: SortOrder) => {
    setWorksRequestParams((prev) => ({
      ...prev,
      sortBy: order ? accessor.id : null,
      orderBy: order ? order.toUpperCase() : null,
    }));
  };

  const { data: works, refetch: refetchWorks } = useGetWorks({
    ...worksRequestParams,
    search: debouncedSearchValueWorks,
    ...paginationParams,
    isEnabled,
  });

  const { data: worksListFilters } = useGetWorks(
    getRequestData(searchWorks, true)
  );

  const { data: worksDefault } = useGetWorksDefault(
    isEnabled && Boolean(creatingMaintenanceGridForm?.isWorksDefault)
  );
  const { mutate: createWork } = useCreateWork();
  const { mutate: updateWork } = useUpdateWork();
  const { mutate: deleteWork } = useDeleteWork();

  const {
    data: spares,
    isLoading: isSparesLoading,
    refetch: refetchSpares,
  } = useGetSpares(
    { ...paginationParams, limit: 40, query: searchSpare },
    isEnabled
  );

  const {
    data: sparesGrids,
    isLoading: isSparesGridsLoading,
    refetch: refetchSparesGrids,
  } = useGetSpares(
    {
      ...paginationGridsParams,
      ...sparesRequestParams,
      limit: 5,
      query: debouncedSearchValueSpares,
    },
    isEnabled
  );

  const { data: sparesListFilters } = useGetSpares(
    getRequestData(searchSpare, true),
    isEnabled
  );

  const { mutate: updateSpares, isLoading: isSparesUpdating } =
    useUpdateSpares();

  useEffect(() => {
    if (!spares?.data.data.length && !isSparesUpdating && !isSparesLoading) {
      updateSpares();
    }
  }, [spares?.data.data.length]);

  return {
    state: {
      activeMaintenanceGrid,
      activeGsm,
      activeSpare,
      activeWork,
      maintenanceGrids: maintenanceGrids?.data,
      gsmList: gsmList?.data,
      gsmListDefault: gsmListDefault?.data,
      currentPage: paginationParams.page,
      currentGridsPage: paginationGridsParams.page,
      works: works?.data,
      worksDefault: worksDefault?.data,
      spares: spares?.data,
      sparesGrids: sparesGrids?.data,
      creatingMaintenanceGridForm,
      isMaintenanceGridUpdating,
      createMaintenanceGridError,
      updateMaintenanceGridError,
      searchValue,
      debouncedSearchValueGsm,
      debouncedSearchValueWorks,
      debouncedSearchValueGrids,
      debouncedSearchValueSpares,
      gsmListFilters: gsmListFilters?.data,
      worksListFilters: worksListFilters?.data,
      sparesListFilters: sparesListFilters?.data,
      worksRequestParams,
      gridsFilters,
    },
    mutations: {
      setActiveMaintenanceGrid,
      setActiveGsm,
      setActiveSpare,
      setActiveWork,
      setSearchValue,
      setSearchValueGsm,
      setSearchValueWorks,
      setSearchValueGrids,
      setPage,
      setCreatingMaintenanceGridForm,
      setSearchSpare,
      setSearchGrids,
      setSearchWorks,
      setGridsPage,
      setGridsFilters,
      setSearchValueSpares,
      setMaintenanceGridsSortParams,
    },
    actions: {
      refetchWorks,
      refetchGsmList,
      createMaintenanceGrid,
      updateMaintenanceGrid,
      createGsm,
      createSpare,
      updateGsm,
      deleteGsm,
      deleteSpare,
      updateSpare,
      createWork,
      updateWork,
      deleteWork,
      refetchSpares,
      updateSpares,
      resetUpdateMaintenanceGridQuery,
      handleWorksSort,
      handleGSMSort,
      handleSparesSort,
    },
  };
};

export type MAINTENANCE_GRIDS = ReturnType<typeof useMaintenanceGrids>;

export const ContextMaintenanceGrids = createContext<MAINTENANCE_GRIDS>(
  {} as MAINTENANCE_GRIDS
);
