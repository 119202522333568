import React from "react";
import { Title } from "../title/Title";
import { StyledTitleDividerWrapper, StyledDivider } from "./index.styles";

export type TitleDividerProps = {
  className?: string;
  children?: React.ReactNode;
  size?: "small" | "medium";
};

export const TitleDivider: React.FC<TitleDividerProps> = (props) => {
  const { className, children, size = "small" } = props;

  return (
    <StyledTitleDividerWrapper className={className} size={size}>
      <Title
        level={size === "small" ? 5 : 3}
        weight={400}
        color={size === "small" ? "gray100" : "black"}
      >
        {children}
      </Title>
      <StyledDivider size={size} />
    </StyledTitleDividerWrapper>
  );
};
