import React from "react";

import { TTransmissionRecord } from "@/helpers/api/transmissions/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { StyledPagination, StyledTabContent } from "../../index.styles";
import {
  getDefaultDictTableHeaders,
  IDefaultDictTab,
} from "@/components/parts/utils";

export interface ITransmissionTypeTab
  extends IDefaultDictTab<TTransmissionRecord> {}

export const TransmissionTypeTab: React.FC<ITransmissionTypeTab> = (props) => {
  const {
    currentPage,
    onChangePage,
    totalItems,
    items,
    onEditClick,
    onDeleteClick,
    onSort,
    handleSearch,
  } = props;

  const headers = getDefaultDictTableHeaders<TTransmissionRecord>({
    onEditClick,
    onDeleteClick,
    isSearchable: true,
  });

  return (
    <StyledTabContent>
      <TableSimple
        data={items}
        headers={headers}
        onSort={onSort}
        onSearch={handleSearch}
      />
      <StyledPagination
        current={currentPage}
        onChange={onChangePage}
        pageSize={10}
        total={totalItems}
      />
    </StyledTabContent>
  );
};
