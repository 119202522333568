import styled from "styled-components";
import { Button } from "../button/Button";
import { Card } from "../Card";
import { Paragraph } from "../paragraph/Paragraph";
import { Title } from "../title/Title";

export const StyledCardInfo = styled(Card)`
  width: 550px;
  max-width: 550px;
`;

export const StyledCardInfoTitle = styled(Title)`
  text-align: center;
`;

export const StyledCardInfoDescription = styled(Paragraph)`
  margin-top: 24px;
  text-align: center;
`;

export const StyledCardInfoConfirmButton = styled(Button)`
  margin-top: 52px;
`;
