import { cloneElement, useEffect, useState } from "react";
import styled from "styled-components";
import { usePopperTooltip, Config, TriggerType } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import { Paragraph } from "@/packages/paragraph/Paragraph";
import { colors } from "@/helpers";
type PlacementType = "top" | "bottom" | "right" | "left";

type Size = "s" | "l";
type Content = number | string | JSX.Element;

export interface ITooltip {
  size?: Size;
  content: Content | Content[];
  children: JSX.Element;
  config?: Config;
  isVisible?: boolean;
  trigger?: TriggerType | TriggerType[];
  placement?: PlacementType;
  delayShow?: number;
  delayHide?: number;
  offset?: [number, number];
  isFitContent?: boolean;
}

interface IStyledTooltip {
  size: Size;
}

const StyledTooltipArrow = styled.div``;

const StyledTooltip = styled.div<IStyledTooltip>`
  max-width: ${({ size }) => (size === "s" ? 200 : 456)}px;
  border: none;
  padding: 8px;
  position: relative;
  z-index: 1000;
  border-radius: 4px;
  background-color: ${colors.white};
  filter: drop-shadow(0px 2px 12px rgba(22, 35, 70, 0.16));

  &[data-popper-placement*="bottom"] ${StyledTooltipArrow}::before {
    border-bottom-color: ${colors.white};
  }

  &[data-popper-placement*="top"] ${StyledTooltipArrow}::before {
    border-top-color: ${colors.white};
  }

  &[data-popper-placement*="right"] ${StyledTooltipArrow}::before {
    border-right-color: ${colors.white};
  }

  &[data-popper-placement*="left"] ${StyledTooltipArrow}::before {
    border-left-color: ${colors.white};
  }
`;

export const StyledTrigger = styled.div`
  position: relative;
  width: 100%;
`;

export const Tooltip: React.FC<ITooltip> = (props) => {
  const {
    size = "s",
    content,
    children,
    trigger = "hover",
    placement = "auto",
    delayShow = 0,
    delayHide = 100,
    offset = [0, 15],
    isVisible = false,
    isFitContent = true,
  } = props;
  const [innerIsVisible, setInnerIsVisible] = useState(isVisible);

  useEffect(() => setInnerIsVisible(isVisible), [isVisible]);

  const handleVisibleChange = (newIsVisible: boolean) => {
    setInnerIsVisible(newIsVisible);
  };

  const {
    visible,

    setTriggerRef,
    setTooltipRef,
    getArrowProps,
    getTooltipProps,
  } = usePopperTooltip({
    trigger,
    placement,
    offset,
    delayHide,
    delayShow,
    visible: innerIsVisible,
    interactive: true,
    onVisibleChange: handleVisibleChange,
  });

  return (
    <>
      <div
        ref={setTriggerRef}
        style={isFitContent ? { width: "fit-content" } : {}}
        className="trigger"
      >
        {children}
        <div id="menu-portal" />
      </div>

      {visible && (
        <StyledTooltip
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
          size={size}
        >
          <StyledTooltipArrow
            {...getArrowProps({ className: "tooltip-arrow" })}
          />
          <Paragraph size={16}>{content}</Paragraph>
        </StyledTooltip>
      )}
    </>
  );
};
