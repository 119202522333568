import {
  //@ts-ignore
  TGetChiefInfoRequest,
  TPatchProfileRequest,
  TPatchProfileResponse,
  TPasswordChangeRequest,
  TGetProfileResponse,
} from "./types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import { accessTokenService } from "../accessTokenService";
import { refreshTokenService } from "../refreshTokenService";

export const useGetProfile = () => {
  return useQuery(
    ["profile", accessTokenService.getToken()],
    () => {
      return api.get<TGetProfileResponse>("/profile");
    },
    { enabled: Boolean(refreshTokenService.getToken()) }
  );
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ patch }: TPatchProfileRequest) => {
      return api.patch<TPatchProfileResponse>("/profile", patch);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
};

export const useUpdatePassword = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPasswordChangeRequest) => {
      return api.post("/auth/change-password", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["password"]);
      },
    }
  );
};

export const useRetrieveChief = () => {
  return useMutation((data: TGetChiefInfoRequest) => {
    return api.post("/profile/chief", data);
  });
};
