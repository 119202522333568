import {
  TRegistryDataFieldsSuccessValues,
  TRegistryDataFieldsValues,
} from "@/components/RegistryDataFields/types";
import {
  DEFAULT_REGISTRY_DATA_FORM_VALUES,
  registryFieldsSchema,
  successRegistryFieldsToDealerDTO,
  TRegistryFieldsDealerDTODataPart,
} from "@/components/RegistryDataFields/utils";
import {
  TOnSubmitFormAsyncCb,
  TOnSubmitFormCb,
  useForm,
} from "@/hooks/useForm";

export type TOnSubmitRegistryCreateFormCb =
  TOnSubmitFormAsyncCb<TRegistryFieldsDealerDTODataPart>;

export const useRegistryCreateForm = (
  onSubmit: TOnSubmitRegistryCreateFormCb
) => {
  const onSubmitHandler: TOnSubmitFormCb<
    TRegistryDataFieldsSuccessValues
  > = async (successValues) => {
    const createRecord = successRegistryFieldsToDealerDTO(successValues);
    await onSubmit(createRecord);
  };

  return useForm<TRegistryDataFieldsValues, TRegistryDataFieldsSuccessValues>({
    validateFormSchema: registryFieldsSchema(),
    defaultValues: DEFAULT_REGISTRY_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};
