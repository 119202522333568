import { TMonitoringQualityCarsRecord } from "@/helpers/api/monitoringQuality/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import { TMonitoringApplicationDataFieldsSuccessValues } from "./types";
import { DEFAULT_MONITORING_DATA_FORM_VALUES, monitoringAppValuesDTO, monitoringFieldsAppSchema, } from "./utils";

export const monitoringCarsFieldsAtEditDTO = (
  values: TMonitoringQualityCarsRecord
) => {
  const {
    carProductionDate,
    //@ts-ignore
    generation,
    isSold,
    carSoldDate,
    photos,
    files,
    ...restFields
  } = values;

  return {
    generationId: { value: generation.id, label: generation.name },
    carProductionDate: new Date(carProductionDate),
    ...(isSold && carSoldDate && { carSoldDate: new Date(carSoldDate) }),
    isSold,
    photoIds: photos.length
      ? photos
      : [],
    fileIds: files.length
      ? files
      : [],
    ...restFields,
  };
};

export const monitoringCarsFieldsAtEditDTOToBack = (values: any) => {
  const { generationId, photoIds, fileIds, ...restFields } = values;

  const result = {
    generationId: generationId.value,
    fileIds: fileIds
      ? fileIds
        .map((file) => file.id)
      : [],
    photoIds: photoIds
      ? photoIds
        .map((photo) => photo.id)
      : [],
    ...restFields,
  };
  return result;
};

export type TOnSubmitCreateMonitoringFormCallback = (values: any) => void;

export const useCreateMonitoringApplicationForm = (
  onSubmit: TOnSubmitCreateMonitoringFormCallback
) => {
  const onSubmitHandler = (
    values: TMonitoringApplicationDataFieldsSuccessValues
  ) => {
    onSubmit(monitoringAppValuesDTO(values));
  };

  return useForm<any>({
    validateFormSchema: monitoringFieldsAppSchema,
    defaultValues: DEFAULT_MONITORING_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};

export const useEditMonitoringApplicationForm = (
  editableRecord: any,
  onSubmit: TOnSubmitCreateMonitoringFormCallback
) => {
  const onSubmitHandler = (values: any) => {
    onSubmit(monitoringCarsFieldsAtEditDTOToBack(values));
  };

  const result = useForm<any>({
    validateFormSchema: monitoringFieldsAppSchema,
    defaultValues: editableRecord ? monitoringCarsFieldsAtEditDTO(editableRecord) : DEFAULT_MONITORING_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(monitoringCarsFieldsAtEditDTO(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
