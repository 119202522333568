import React from "react";

import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IconButton } from "@/packages/icon-button/IconButton";

import {
  StyledCalendarColor,
  StyledCalendarColorList,
  StyledCalendarColorItem,
  StyledCalendarColorWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

interface ICalendarColors {
  data: {
    title: string;
    color: string;
  }[];
  onButtonEditClick: () => void;
}

export const CalendarColors: React.FC<ICalendarColors> = (props) => {
  const { data, onButtonEditClick } = props;
  const { t } = useTranslation();
  return (
    <StyledCalendarColorWrapper>
      <StyledCalendarColorList>
        {data.map((el, index) => (
          <StyledCalendarColorItem key={`${el.title}-${el.color}-${index}`}>
            <StyledCalendarColor color={el.color} />
            <Paragraph size={12} weight={300}>
              {el.title}
            </Paragraph>
          </StyledCalendarColorItem>
        ))}
      </StyledCalendarColorList>
      <IconButton
        size="m"
        icon="edit"
        color="black"
        variant="tertiary"
        onClick={onButtonEditClick}
      >
        {t("common.password.edit") || ""}
      </IconButton>
    </StyledCalendarColorWrapper>
  );
};
