import { TKiaHoldingRecord } from "@/helpers/api/dc/kia-holdings/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { usePermission } from "@/hooks/usePermission";
import { Popconfirm, Space } from "antd";
import React, { useEffect } from "react";
import { StyledClick, StyledPagination, StyledTable } from "./index.styles";
import { Loader } from "@/packages/loader/Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IHoldingTable {
  data: TKiaHoldingRecord[];
  paginationParams: { page: number; limit: number };
  pageCount: number;
  isLoading: boolean;
  setPage: (page: number) => void;
  onEditClick: (record: TKiaHoldingRecord) => void;
  onDeleteClick: (record: TKiaHoldingRecord) => void;
  filterQueryParams?: any;
}

export const HoldingsTable: React.FC<IHoldingTable> = ({
                                                         data,
                                                         paginationParams,
                                                         pageCount,
                                                         isLoading,
                                                         setPage,
                                                         onEditClick,
                                                         onDeleteClick,
                                                         filterQueryParams,
                                                       }) => {
  const { hasAccess } = usePermission("holding", "update");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToDcByWarrantyCode = (dealers: TKiaHoldingRecord["dealers"], warrantyCode: string) => {
    const dealerId = dealers.filter((item) => item.warrantyCode === warrantyCode)[0].id;
    navigate(`/card-dc/${dealerId}`)
  };

  const navigateToDcByMerchantName = (dealers: TKiaHoldingRecord["dealers"], merchantName: string) => {
    const dealerId = dealers.filter((item) => item.merchantName === merchantName)[0].id;
    navigate(`/card-dc/${dealerId}`)
  };

  const headers: IHeaderItem<TKiaHoldingRecord>[] = [
    {
      Header: t("registers.holdingsDC.nameTable") || "",
      accessor: "name",
    },
    {
      Header: t("registers.holdingsDC.codeTable") || "",
      accessor: "dealers",
      Cell(arg) {
        const dealers = arg.value as TKiaHoldingRecord["dealers"];
        return (
          <div>
            {
              dealers.map(({ warrantyCode }, index) => {
                return (
                  <StyledClick onClick={() => navigateToDcByWarrantyCode(dealers, warrantyCode)}>
                    {warrantyCode}
                    {dealers.length - 1 !== index && ', '}
                  </StyledClick>
                )
              })
            }
          </div>
        )
      },
    },
    {
      Header: t("registers.holdingsDC.merchantName") || "",
      accessor: "merchantName",
      Cell({ row }) {
        const dealers: TKiaHoldingRecord["dealers"] = row.original.dealers;
        return (
          <div>
            {
              dealers.map(({ merchantName }, index) => {
                return (
                  <StyledClick onClick={() => navigateToDcByMerchantName(dealers, merchantName)}>
                    {merchantName}
                    {dealers.length - 1 !== index && ', '}
                  </StyledClick>
                )
              })
            }
          </div>
        )
      }
    },
    {
      Header: "",
      id: "actions",
      width: "50px",
      minWidth: 50,
      maxWidth: 50,

      Cell: (data) => {
        const onEditClickHandler = () => {
          onEditClick(data.row.original);
        };

        const onDeleteClickHandler = () => {
          onDeleteClick(data.row.original);
        };
        return hasAccess ? (
          <Space>
            <IconButton
              icon="edit"
              color="black"
              size="l"
              variant="tertiary"
              onClick={onEditClickHandler}
            />
            <Popconfirm
              title={t("registers.holdingsDC.deleteQuestion") || ""}
              onConfirm={onDeleteClickHandler}
              okText={t("registers.holdingsDC.yes") || ""}
              cancelText={t("registers.holdingsDC.no") || ""}
            >
              <IconButton
                icon="delete"
                color="black"
                size="l"
                variant="tertiary"
              />
            </Popconfirm>
          </Space>
        ) : (
          <></>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [setPage, filterQueryParams]);

  return !isLoading ? (
    <>
      <StyledTable
        headers={headers}
        data={data}
        getRowId={(record) => record.id}
      />
      {pageCount > 1 ? (
        <StyledPagination
          onChange={setPage}
          current={paginationParams.page}
          pageSize={1}
          total={pageCount}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <Loader size={48}/>
  );
};
