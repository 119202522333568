import React, { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { IModal, Modal } from "@/packages/modal/Modal";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";

import {
  TOnSubmitMediaBaseFormHandler,
  useMediaBaseModalForm,
} from "@/components/MediaBase/MediaBaseModal/hook";

import { StyledModalContentWrapper } from "./index.styles";

import { MEDIA } from "@/helpers/consts";
import { useTranslation } from "react-i18next";

type MediaBaseModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IMediaBaseModal {
  onSubmit: TOnSubmitMediaBaseFormHandler;
  defaultValues?: any;
}

export const MediaBaseModal: React.FC<MediaBaseModalProps & IMediaBaseModal> = (
  props
) => {
  const { onSubmit, defaultValues, onCancel } = props;
  const { form, onSubmitHandler } = useMediaBaseModalForm(
    onSubmit,
    defaultValues
  );
  const { t } = useTranslation();
  const mappedMedia = useMemo(
    () =>
      MEDIA.map((item, index) => ({
        value: item.name,
        label: item.name,
        record: { id: index, name: item.slug },
      })),
    []
  );

  return (
    <Modal
      {...props}
      title={t("park.addMedia") || ""}
      size="medium"
      cancelButtonText={t("audits.templates.cancel") || ""}
      confirmButtonText={t("audits.templates.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancel}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormInputField
            name="name"
            label={t("maintenanceGrids.worksModel.workForm.name") || ""}
            placeholder={t("park.enterName") || ""}
          />
          <FormSelect
            name="type"
            label={t("park.section") || ""}
            placeholder={t("park.sectionDescr") || ""}
            options={mappedMedia}
          />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
