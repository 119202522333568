import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../axiosInstance";

export const useGetStatistics = (request: any) => {
  return useQuery(["statistics", request], () => {
    return api.get<any>("/test-drives/vehicles/statistics", {
      params: request,
    });
  });
};

export const useExportStatistics = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/test-drives/vehicles/statistics/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
