import { colors } from "@/helpers";
import { IconWorkType } from "@/packages/iconWorkType";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledWorkTypeLegend = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: end;
  padding: 0 20px;
`;

const StyledLegendItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

const StyledParagraph = styled(Paragraph)`
  color: ${colors.gray200};
`;

export const WorkTypesLegend = () => {
  const { t } = useTranslation();
  return (
    <StyledWorkTypeLegend>
      <StyledLegendItem>
        <IconWorkType type="I" />
        <StyledParagraph size={14}>
          {t("maintenanceGrids.gridsModel.viewGrid.inspection") || ""}
        </StyledParagraph>
      </StyledLegendItem>
      <StyledLegendItem>
        <IconWorkType type="R" />
        <StyledParagraph size={14}>
          {t("maintenanceGrids.gridsModel.viewGrid.replacement") || ""}
        </StyledParagraph>
      </StyledLegendItem>
      <StyledLegendItem>
        <IconWorkType type="O" />
        <StyledParagraph size={14}>
          {t("maintenanceGrids.gridsModel.viewGrid.optional") || ""}
        </StyledParagraph>
      </StyledLegendItem>
    </StyledWorkTypeLegend>
  );
};
