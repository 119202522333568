import { FC, useMemo, useState } from "react";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import SettingsCreateModal from "@/components/KiaFlex/SettingsQuotas/SettingsCreateModal";
import { useCreateFlexQuotas, useExportFlexQuotas, useGetFlexQuotasList, } from "@/helpers/api/flex/settings/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { ISelectOption, Select } from "@/packages/formElements/inputs/Select";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

import {
  StyledActionsRow,
  StyledFlexQuotas,
  StyledHeaderRow,
  StyledHeaderSection,
  StyledSearch,
  StyledYearSelectWrapper,
} from "./index.styles";
import { transformQuotasModel } from "./utils";
import { useTranslation } from "react-i18next";
import { FlexQuotasTable } from "./FlexQuotasTable";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { Loader } from "@/packages/loader/Loader";

interface IFlexQuotasProps {}

const yearOptions: ISelectOption[] = Array.from(
  new Array(10),
  (x, i) => i + 2020
).map((year) => ({
  value: year,
  label: year.toString(),
}));

export const FlexQuotas: FC<IFlexQuotasProps> = (props) => {
  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const [year, setYear] = useState(
    yearOptions.find((option) => option.value === new Date().getFullYear())
      ?.value || yearOptions[0].value
  );
  const [searchValue, setSearchValue] = useState<string>();

  const [requestParams, setRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const { mutateAsync: exportQuotas } = useExportFlexQuotas();

  const onSort = (accessor: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      orderBy: order ? order.toUpperCase() : null,
      sortBy: order ? accessor.id : null,
    }));
  };

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { data: flexQuotasDataList, refetch: updateQuotas, isLoading } =
    //@ts-ignore
    useGetFlexQuotasList({
      search: searchValue ? searchValue : "",
      limit: 100,
      sortAt: requestParams.orderBy,
      sortBy:
        requestParams.sortBy === "name" ? "merchantName" : requestParams.sortBy,
      year: +year,
    });
  const { mutate: createFlexQuotas } = useCreateFlexQuotas();

  const flexQuotasData = useMemo(() => {
    // @ts-ignore
    return transformQuotasModel(flexQuotasDataList?.data);
  }, [flexQuotasDataList?.data]);

  const onCreateQuotas = (payload) => {
    createFlexQuotas({
      data: {
        ...payload,
        year: year,
      },
      onError: (error) => {
        createNotificationError(error.response?.data.message || error.message);
      },
      onSuccess: () => {
        updateQuotas();
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessQuotas") || ""
        );
      },
    });
  };

  const {
    modalState: createQuotasModalState,
    openModal: openCreateQuotasModal,
  } = useModal<any>((close) => {
    return async (payload) => {
      onCreateQuotas(payload);
      close();
    };
  });

  const handleExportQuotas = () => {
    exportQuotas(requestParams, {
      onSuccess: (response) => {
        handleFileLoad(response.data as Blob, "quotas.xlsx");
      },
    });
  };

  return (
    <StyledFlexQuotas>
      <StyledHeaderRow>
        <StyledHeaderSection>
          <StyledYearSelectWrapper>
            <Paragraph size={12} tag="label" color="black">
              {t("bonuses.reportsView.year") || ""}
            </Paragraph>
            <Select
              placeholder={t("bonuses.reportsView.year") || ""}
              options={yearOptions}
              value={year}
              onChange={setYear}
            />
          </StyledYearSelectWrapper>
          <StyledSearch
            value={searchValue}
            placeholder={t("kiaFlex.DC") || ""}
            onChange={setSearchValue}
          />
        </StyledHeaderSection>
        <StyledHeaderSection>
          <PermissionsAdapter
            resourceName="flex.quotas"
            resourceAction="create"
          >
            <Button
              color="brand"
              theme="primary"
              onClick={openCreateQuotasModal}
            >
              {t("warrantyAudits.list.add") || ""}
            </Button>
          </PermissionsAdapter>
        </StyledHeaderSection>
      </StyledHeaderRow>
      <StyledActionsRow>
        <Tooltip
          trigger="hover"
          placement={"bottom"}
          content={t("common.save") || ""}
        >
          <IconButton
            icon="save"
            variant="secondary"
            color="gray"
            isSquared={true}
            onClick={handleExportQuotas}
          />
        </Tooltip>
        <Tooltip
          trigger="hover"
          placement={"bottom"}
          content={t("common.share") || ""}
        >
          <IconButton
            icon="share"
            variant="secondary"
            color="gray"
            isSquared={true}
            onClick={() => copyCurrentUrl()}
          />
        </Tooltip>
      </StyledActionsRow>
      {!isLoading ? (
        flexQuotasData && (
          <FlexQuotasTable
            // @ts-ignore
            data={flexQuotasData}
            onSort={onSort}
            udpdateData={updateQuotas}
          />
        )
      ) : (
        <Loader size={48} />
      )}
      {createQuotasModalState.isOpen ? (
        <SettingsCreateModal {...createQuotasModalState} />
      ) : (
        <></>
      )}
    </StyledFlexQuotas>
  );
};
