import { ISelect, Select } from "@/packages/formElements/inputs/Select";
import { Dispatch, FC, SetStateAction } from "react";
import { Field } from "../Field";

export type ISelectField = ISelect & {
  label?: string;
  error?: string;
  description?: string;
  isErrorAbsolute?: boolean;
  refetchValue?: string;
  setRefetchValue?: Dispatch<SetStateAction<string | undefined>>;

};

const SelectField: FC<ISelectField> = (props) => {
  const {
    label,
    error,
    description,
    readOnly,
    isErrorAbsolute,
    ...selectProps
  } = props;

  return (
    <Field>
      {label ? (
        <Field.Label readOnly={readOnly} disabled={selectProps.disabled}>
          {label}
        </Field.Label>
      ) : (
        <></>
      )}

      <Field.FieldControlContainer>
        <Select {...selectProps} readOnly={readOnly} />
      </Field.FieldControlContainer>

      {error ? (
        <Field.FieldError isErrorAbsolute={isErrorAbsolute}>
          {error}
        </Field.FieldError>
      ) : (
        <></>
      )}
      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};

export { SelectField };
