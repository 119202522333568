import React, { useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";

import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormPhoneInput } from "@/packages/formElements/formFields/FormPhoneInput";
import { Icon } from "@/packages/icon/Icon";

import { TOnSubmitChangeDataFormHandler, useChangeDataForm, } from "@/components/profile/ChangeDataForm/hook";

import {
  StyledEditFormWrapper,
  StyledFormAction,
  StyledFormBox,
  StyledFormGroup,
  StyledFormInfo,
} from "@/components/profile/ChangeDataForm/index.styles";
import { Button } from "@/packages/button/Button";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { isHeadUserBySlug } from "@/helpers/utils";
import { UploadType } from "@/packages/UploadImageDropzone";
import { ContextProfile } from "@/hooks/contexts/useProfile";

interface IChangeDataForm {
  rolesOptions?: {
    value: string;
    label: string;
  }[];
  onSubmit: TOnSubmitChangeDataFormHandler;
  defaultValues?: any;
}

export const ChangeDataForm: React.FC<IChangeDataForm> = (props) => {
  const { rolesOptions, onSubmit, defaultValues } = props;
  const { form, onSubmitHandler } = useChangeDataForm(onSubmit, defaultValues);

  const {
    state: { profile },
  } = useContext(ContextProfile);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isPhotoChanged, setIsPhotoChanged] = useState<boolean>(false);

  const hasNewRole = Boolean(defaultValues?.newRole);
  const newRoleName = defaultValues?.newRole?.name;
  const isHeadUser = isHeadUserBySlug(defaultValues?.role?.slug);

  const onClickHandler = () => {
    onSubmitHandler();
    setIsDisabled(true);
    form.reset({}, { keepValues: true });
  };

  const isDealer = profile?.type === 'dealer';
  useEffect(() => {
    if (form.formState.isDirty) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [form.formState]);

  return (
    <>
      <StyledEditFormWrapper>
        <FormProvider {...form}>
          <StyledFormBox>
            <StyledFormGroup>
              <FormImageDropzoneField
                label="Изображение"
                name="image"
                path="users/photos"
                hasRemove={true}
                typeInfo={UploadType.IMAGE}
                maxFileSize={5000000}
                allowedTypes={["image/png", "image/jpeg", "image/tiff"]}
                isWithCustomPlaceholderTitle={false}
                onChange={(response) => {
                  form.setValue("imageId", response?.id ? response?.id : null);
                  form.setValue("image", response ? response : null);
                  setIsPhotoChanged(true);
                }}
              />
            </StyledFormGroup>

            {
              isDealer &&
              <>
                <StyledFormGroup>
                  <FormInputField
                    name="firstName"
                    label="Имя"
                    placeholder="Введите имя"
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormInputField
                    name="lastName"
                    label="Фамилия"
                    placeholder="Введите фамилию"
                  />
                </StyledFormGroup>
                {!isHeadUser ? (
                  <>
                    <StyledFormGroup>
                      <FormSelect
                        name="role"
                        suffixIcon={<Icon name="dropdown-arrow" size={16}/>}
                        label="Должность"
                        placeholder="Выберите должность"
                        options={rolesOptions}
                      />
                    </StyledFormGroup>

                    <StyledFormInfo>
                      {hasNewRole
                        ? `Ожидается подтверждение изменения должности на ${newRoleName}`
                        : "Для изменения этих данных необходимо подтверждение директора"}
                    </StyledFormInfo>
                  </>
                ) : (
                  <></>
                )}
              </>
            }
          </StyledFormBox>
          {
            isDealer &&
            <StyledFormBox>
              <StyledFormGroup>
                <FormPhoneInput name="phone" label="Телефон"/>
              </StyledFormGroup>
              <StyledFormGroup>
                <FormInputField
                  name="email"
                  label="Почта"
                  type="email"
                  placeholder="Введите E-mail"
                  isDisabled={true}
                />
              </StyledFormGroup>
              <StyledFormInfo>
                Чтобы изменить вашу почту, обратитесь в службу поддержки
              </StyledFormInfo>
            </StyledFormBox>
          }
        </FormProvider>
      </StyledEditFormWrapper>
      <StyledFormAction>
        <Button
          theme="primary"
          color="brand"
          size="middle"
          onClick={onClickHandler}
          isDisabled={!isPhotoChanged && (form.formState.isDirty || isDisabled)}
        >
          Сохранить
        </Button>
      </StyledFormAction>
    </>
  );
};
