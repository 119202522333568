import React, { useState } from "react";

import { IModal } from "@/packages/modal/Modal";

import { IApplicationsFormValues } from "@/components/Applications/ApplicationsForm/hooks";
import { ApplicationsNewForm } from "@/components/Applications/ApplicationsNewForm";
import {
  StyledModal,
  StyledModalContentWrapper,
  StyledModalTitle,
} from "@/components/Calendar/CalendarTable/CalendarTableColumnRight/CalendarApplication/CalendarApplicationNewModal/index.styles";
import { useCreateApplication } from "@/helpers/api/applications/hooks";
import { TApplicationCreateRecord } from "@/helpers/api/applications/types";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

interface ICalendarApplicationNewModal
  extends Pick<IModal, "isOpen" | "onCancel"> {
  closeModal: any;
  startEventForNew: Date | null;
  vehicleIdForNew: string | null;
}

export const CalendarApplicationNewModal: React.FC<
  ICalendarApplicationNewModal
> = (props) => {
  const { closeModal, startEventForNew, vehicleIdForNew } = props;

  const {
    mutateAsync: createApplicationAsync,
    isLoading: isCreateApplicationLoading,
  } = useCreateApplication();

  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const handleFormSubmit = async (data: IApplicationsFormValues) => {
    const {
      startEvent,
      endEvent,
      vehicleId,
      appointment,
      mediaId,
      statusId,
      requestPublicationLink,
      comment,
      receivingUser,
      fileId,
    } = data;

    const dataToServer: TApplicationCreateRecord = {
      startEvent: startEvent ? startEvent.toISOString() : "",
      endEvent: endEvent ? endEvent.toISOString() : "",
      vehicleId: vehicleId!,
      appointment: appointment!,
      mediaId: appointment === "Media" ? (mediaId as string) : undefined,
      statusId: statusId!,
      requestPublicationLink: requestPublicationLink || "",
      comment: comment!,
      receivingUser: receivingUser!,
      // @ts-ignore TODO
      fileIds: fileId?.id ? [fileId?.id] : [],
    };

    try {
      await createApplicationAsync(dataToServer);
      closeModal();
    } catch (err: any) {
      if (err?.response?.data?.message === "Expired CASCO or OSAGO") {
        createNotificationError(t("park.expiredCASCO&OSAGO") || "");
      }
      if (
        err?.response?.data?.message === "Request overlaps with other requests"
      ) {
        createNotificationError(t("park.overlaps") || "");
      }
    }
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmitHandler = () => {
    setIsSubmitted(true);
  };

  return (
    <StyledModal
      {...props}
      title={t("park.request") || ""}
      size="medium"
      cancelButtonText={t("common.cancel") || ""}
      showConfirmButton={true}
      confirmButtonText={t("audits.templates.save") || ""}
      isDisabledConfirmButton={isDisabled}
      onSubmit={onSubmitHandler}
    >
      <StyledModalContentWrapper>
        <StyledModalTitle level={3} weight={400}>
          {t("kiaFlex.newApp") || ""}
        </StyledModalTitle>

        {startEventForNew ? (
          <ApplicationsNewForm
            defaultValues={{
              startEvent: startEventForNew as Date,
              vehicleId: vehicleIdForNew ? (vehicleIdForNew as string) : null,
            }}
            onFormSubmit={handleFormSubmit}
            isLoading={isCreateApplicationLoading}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            setIsDisabled={setIsDisabled}
          />
        ) : (
          <></>
        )}
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
