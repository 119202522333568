import { colors } from "@/helpers";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import styled, { css } from "styled-components";

export const InsuranceWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  cursor: pointer;
  height: 88px;
  border-radius: 10px;
  align-items: center;

  ${({ active }) => {
    return active
      ? css`
          background-color: rgba(132, 166, 254, 0.16);
          border: 1px solid rgba(72, 123, 255, 0.3);
        `
      : css`
          background-color: white;
          border: 1px solid ${colors.gray400};
        `;
  }}
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  background-color: ${colors.gray4};
  margin: 0 10px 14px 10px;
`;
export const TitleFieldWrapper = styled.div`
  margin: 10px 0;
`;

export const ExistCarFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FieldWrapper = styled.div``;

export const FormInputTextUpper = styled(FormInputField)`
  ${css`
    && input {
      text-transform: uppercase;
    }
  `}
`;
