import { FC } from "react";
import { Input as AntInput } from "antd";
import styled from "styled-components";
import { colors } from "@/helpers";

import { Icon } from "@/packages/icon/Icon";

const { TextArea } = AntInput;

interface IInput {
  icon?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  isBlock?: boolean;
  isError?: boolean;
  description?: string;
  value?: string;
  id?: string;
  maxLength?: number;
  isDisabled?: boolean;
  isTextArea?: boolean;
  onClick?: () => void;
  onPressEnter?: () => void;
  onChange?: () => void;
}

const Input: FC<IInput> = (props) => {
  const {
    label,
    placeholder,
    error,
    isError,
    description,
    isBlock,
    isDisabled,
    icon,
    onClick,
    onPressEnter,
    onChange,
    value,
    isTextArea,
    maxLength,
    type,
    id,
    ...rest
  } = props;

  const StyledInputContainer = styled.div<IInput>`
    min-width: 240px;
    width: ${({ isBlock }) => (isBlock ? "100%" : "max-content")};
  `;

  const StyledInputLabel = styled.label<IInput>`
    ${({ isDisabled }) =>
      isDisabled
        ? `
        color: ${colors.gray300};
        cursor: not-allowed;
      `
        : `
        color: ${colors.black};
        cursor: pointer;
      `}
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `;

  const StyledInputWrapper = styled.div<IInput>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 240px;
    width: ${({ isBlock }) => (isBlock ? "100%" : "max-content")};
    margin-top: 4px;
  `;

  const InputIcon = styled(Icon)<IInput>`
    ${({ isDisabled }) =>
      isDisabled
        ? `
        & svg {
          & path {
            fill: ${colors.gray300};
          }
        }
      `
        : `
        & svg {
          & path {
            fill: ${colors.gray400};
          }
        }
      `}

    position: absolute;
    top: 50%;
    right: 11px;
    transform: translate(0, -50%);
    z-index: 2;
  `;

  const StyledInputText = styled.div`
    margin: 4px 0 0 0;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
  `;

  const InputErrorText = styled(StyledInputText)`
    color: ${colors.red200};
  `;

  const InputDescriptionText = styled(StyledInputText)`
    color: ${colors.gray400};
  `;

  const InputComponent = ({ isTextArea, type, ...rest }) => {
    const Component = isTextArea
      ? TextArea
      : type === "password"
      ? AntInput.Password
      : AntInput;
    return <Component {...rest} />;
  };

  const StyledInputItem = styled(InputComponent)<IInput>`
    ${({ isDisabled, error }) =>
      isDisabled && error
        ? `
      border: 1px solid ${colors.red200};
      border-color: ${colors.red200} !important;
    `
        : `
      border: 1px solid ${colors.gray300};
  `}

    color: ${colors.black};
    min-width: 240px;
    width: ${({ isBlock }) => (isBlock ? "100%" : "max-content")};
    min-height: 32px;
    background: ${colors.white};
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray900};
    position: relative;
    transition: all 0.5s ease;

    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: ${colors.gray400};
    }

    &:hover {
      border: 1px solid ${colors.gray400};
    }

    &:focus {
      border: 1px solid ${colors.brand};
    }
  `;

  const StyledPasswordIcon = styled.div`
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 5;
  `;

  return (
    <StyledInputContainer isBlock={isBlock}>
      <StyledInputLabel isDisabled={isDisabled}>{label}</StyledInputLabel>

      <StyledInputWrapper isBlock={isBlock}>
        {icon ? (
          <InputIcon
            name={icon}
            size={16}
            color="black"
            isDisabled={isDisabled}
          />
        ) : (
          <></>
        )}

        <StyledInputItem
          isBlock={isBlock}
          disabled={isDisabled}
          errorMessage={error}
          isDisabled={isDisabled}
          status={error ? "error" : isError ? "error" : ""}
          placeholder={placeholder}
          onClick={onClick}
          onPressEnter={onPressEnter}
          onChange={onChange}
          iconRender={(visible) =>
            visible ? (
              <StyledPasswordIcon>
                <Icon name="eye-open" size={16} />
              </StyledPasswordIcon>
            ) : (
              <StyledPasswordIcon>
                <Icon name="eye-close" size={16} />
              </StyledPasswordIcon>
            )
          }
          isTextArea={isTextArea}
          value={value}
          id={id}
          type={type}
          maxLength={maxLength}
          {...rest}
        />
      </StyledInputWrapper>

      {error ? <InputErrorText>{error}</InputErrorText> : <></>}

      {description ? (
        <InputDescriptionText>{description}</InputDescriptionText>
      ) : (
        <></>
      )}
    </StyledInputContainer>
  );
};

export { Input };
