import React from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { useTranslation } from "react-i18next";
import { useGetWarrantyAuditClaimsList } from "@/helpers/api/warranty-audit/audit-list/hooks";
import { usePagination } from "@/hooks/usePagination";
import { getAllClaimsSparesModalHeaders } from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/helpers";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { Pagination } from "@/packages/pagination/Pagination";
import {
  StyledModalWrapper
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/AllClaimsWarrantyModal/index.styles";
import { Loader } from "@/packages/loader/Loader";

export type IAllClaimsModal = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

export interface AllClaimsModalProps {
  auditId: number;
  claimSerial: string;
}

export const AllClaimsSparesModal: React.FC<
  IAllClaimsModal & AllClaimsModalProps
> = (props) => {
  const { onCancel, auditId, claimSerial } = props;
  const { t } = useTranslation();

  const { setPage, paginationParams } = usePagination();

  const { data: claimsList, isLoading } =
    useGetWarrantyAuditClaimsList({
      "filter[auditId]": auditId,
      "filter[claimSerial]": claimSerial,
      ...paginationParams,
    })

  const headers = getAllClaimsSparesModalHeaders();

  return (
    <Modal
      {...props}
      title={t('warrantyAudits.list.allClaimsModalTitle') || ""}
      size="large"
      cancelButtonText={t("common.back") || ""}
      showConfirmButton={false}
      onCancel={onCancel}
    >
      <StyledModalWrapper>
        {
          !isLoading ?
            <>
              {
                !!claimsList?.data.meta?.itemCount &&
                claimsList?.data.meta?.itemCount !== 0 ?
                  <TableSimple
                    headers={headers}
                    data={claimsList?.data?.data ?? []}
                  /> :
                  <>Нет данных</>
              }
              {
                !!claimsList?.data.meta?.pageCount &&
                claimsList?.data.meta?.pageCount > 1 &&
                <Pagination
                  current={paginationParams.page}
                  onChange={setPage}
                  pageSize={10}
                  total={claimsList?.data?.meta?.itemCount}
                  showSizeChanger={false}
                />
              }
            </>
            :
            <Loader size={48}
            />
        }
      </StyledModalWrapper>
    </Modal>
  );
};
