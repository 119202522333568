import { createContext, ReactNode, useContext } from "react";
import { UserInfoModal, UserInfoModalProps } from "@/components/UserInfoModal/index";
import { useUserInfoModal } from "@/components/UserInfoModal/hooks";

type UserInfoModelProviderProps = { children: ReactNode }

type State = Omit<{ userId: string; }, "isOpen"> & {
  onSubmit: (payload: UserInfoModalProps) => void;
  onCancel: () => void;
  isOpen: boolean;
}
export type Dispatch = (additionModalState: { userId: string }) => void

const Context = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

export function useUserInfoModel() {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error('useUserInfoModel must be used within a UserInfoModelProvider')
  }
  return context
}

export function UserInfoModelProvider({ children }: UserInfoModelProviderProps) {

  const { modalState, openModal, closeModal } = useUserInfoModal();
  return (
    <Context.Provider value={{ state: modalState, dispatch: openModal }}>
      {children}
      <UserInfoModal {...modalState}/>
    </Context.Provider>
  )
}

