import { ITextArea, TextArea } from "@/packages/formElements/inputs/TextArea";
import { Field } from "../Field";

export interface ITextAreaField extends Omit<ITextArea, "error"> {
  label?: string;
  error?: string;
  description?: string;
  isBlock?: boolean;
}

export const TextAreaField: React.FC<ITextAreaField> = (props) => {
  const {
    label,
    error,
    description,
    readOnly = false,
    isBlock = true,
    ...inputProps
  } = props;

  return (
    <Field isBlock={isBlock}>
      {label ? (
        <Field.Label disabled={inputProps.disabled} readOnly={readOnly}>
          {label}
        </Field.Label>
      ) : (
        <></>
      )}

      <Field.FieldControlContainer>
        <TextArea {...inputProps} error={!!error} readOnly={readOnly} />
      </Field.FieldControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
