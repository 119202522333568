import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TEngineCreateRequest,
  TEngineCreateResponse,
  TEngineDeleteRequest,
  TEngineDeleteResponse,
  TEnginePutRequest,
  TEnginePutResponse,
  TGetEngineByIdRequest,
  TGetEngineByIdResponse,
  TGetEngineListRequest,
  TGetEngineListResponse,
} from "./types";

export const useGetEngineList = (request: TGetEngineListRequest) => {
  return useQuery(["engines", "list", request], () => {
    return api.get<TGetEngineListResponse>("/engines", {
      params: request,
    });
  });
};

export const useGetEngineById = (request: TGetEngineByIdRequest) => {
  return useQuery(["engines", "detail", request.id], () => {
    return api.get<TGetEngineByIdResponse>(`/engines/${request.id}`);
  });
};

export const useCreateEngine = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TEngineCreateRequest) => {
      return api.post<TEngineCreateResponse>("/engines", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["engines", "list"]);
      },
    }
  );
};

export const usePutEngine = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TEnginePutRequest) => {
      return api.put<TEnginePutResponse>(`/engines/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["engines", "list"]);
        queryClient.invalidateQueries(["engines", "detail", variables.id]);
      },
    }
  );
};

export const useDeleteEngine = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TEngineDeleteRequest) => {
      return api.delete<TEngineDeleteResponse>(`/engines/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["engines", "list"]);
        queryClient.invalidateQueries(["engines", "detail", variables.id]);
      },
    }
  );
};
