import React, { useMemo } from "react";

import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem, SortOrder } from "@/packages/nestedTable/NestedTable";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

import { colors, vehicleNumberToWithSpaces } from "@/helpers";
import { transformDateToDotted } from "@/helpers/date";

import {
  StyledAutoParkButtons,
  StyledAutoParkMessage,
  StyledAutoParkTable,
  StyledStatus,
  StyledTableWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import { useGetStatuses } from "../../../helpers/api/testDrives/hooks";

interface IAutoParkTable {
  isLoading: boolean;
  data: any;
  onEditClick: (dataId: string) => void;
  onToFromClick: (dataId: string) => void;
  onSort: (accessor: string, order: SortOrder) => void;
  isArchiveTabActive: boolean;
}

export interface ITableChild {
  id: string;
  name: string;
  number: string;
  expiredOSAGO: Date;
  createdAt: Date;
  status: string;
  buttons: string;
}

interface ITableItem {
  id: string;
  name: string;
  child: ITableChild[];
}

const Colors = {
  NONE: colors.white,
  PEND: colors.orangeStatus,
  REJ: colors.redStatus10,
  RES: colors.greenStatus10,
};

export const AutoParkTable: React.FC<IAutoParkTable> = (props) => {
  const {
    data,
    onSort,
    isLoading,
    onToFromClick,
    onEditClick,
    isArchiveTabActive,
  } = props;
  const { t } = useTranslation();

  const { data: responseGetStatusList } = useGetStatuses({
    withoutRejection: false,
  });

  const statusesDict = responseGetStatusList?.data?.data.reduce((dict, status) => {
    dict[status.slug] = status;
    
    return dict;
  }, {} as Record<string, unknown>) || {};

  const [headers, fixedData] = useMemo(() => {
    const tempHeaders: IHeaderItem[] = [
      {
        Header: "park.autoOne",
        accessor: "name",
        isOpening: true,
      },
      {
        Header: "park.number",
        accessor: "number",
        isSortable: true,
      },
      {
        Header: "park.expiredOSAGO",
        accessor: "expiredOSAGO",
        isSortable: true,
      },
      {
        Header: "park.expiredCASCO",
        accessor: "expiredCASCO",
        isSortable: true,
      },
      {
        Header: "park.createdAt",
        accessor: "createdAt",
        isSortable: true,
      },
      //@ts-ignore
      !isArchiveTabActive && {
        Header: "park.status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "buttons",
      },
    ];

    const tempData: ITableItem[] = data.map((model) => ({
      id: model.id,
      name: model.name,
      child: model.vehicles.map((vehicle) => ({
        id: vehicle.id,
        name: (
          <>
            <Paragraph
              size={14}
              color="gray600"
            >{`${vehicle.equipmentName} ${vehicle.generationName}`}</Paragraph>
            <Paragraph size={14} weight={300} color="gray600">
              {vehicle.vin}
            </Paragraph>
          </>
        ),
        number: vehicleNumberToWithSpaces(vehicle.number),
        expiredOSAGO: transformDateToDotted(vehicle.expiredOSAGO),
        expiredCASCO: transformDateToDotted(vehicle.expiredCASCO),
        createdAt: transformDateToDotted(vehicle.createdAt),
        status: vehicle.uniqueVehicleRequestsStatuses.length 
          ? (
            vehicle.uniqueVehicleRequestsStatuses.map((statusSlug) => {
              const status = statusesDict[statusSlug];

              return (
                <Tooltip
                  content={
                    <Paragraph size={10} color="gray600">
                      {!!status ? status?.title : t("common.noData") || ""}
                    </Paragraph>
                  }
                >
                  <StyledStatus
                    backgroundColor={
                      !!status ? status?.color : Colors.NONE
                    }
                  />
                </Tooltip>
              )
            })
            ) : (
              <Tooltip
                content={
                  <Paragraph size={10} color="gray600">
                    {t("common.noData") || ""}
                  </Paragraph>
                }
              >
                <StyledStatus
                  backgroundColor={Colors.NONE}
                />
              </Tooltip>
        ),
        buttons: (
          <StyledAutoParkButtons>
            <IconButton
              size="l"
              icon="edit"
              color="black"
              variant="tertiary"
              onClick={() => onEditClick(vehicle.id)}
            />
            <IconButton
              size="l"
              color="black"
              variant="tertiary"
              onClick={() => onToFromClick(vehicle.id)}
              icon={vehicle.archived ? "reload" : "to-archive"}
            />
          </StyledAutoParkButtons>
        ),
      })),
    }));

    return [tempHeaders, tempData];
  }, [data, statusesDict]);

  const nestedTableArgs = {
    headers,
    data: fixedData,
  };

  return (
    <StyledTableWrapper>
      {!isLoading ? (
        !!data.length ? (
          <StyledAutoParkTable
            variant="secondary"
            onSort={onSort}
            {...nestedTableArgs}
          />
        ) : !data.length ? (
          <StyledAutoParkMessage level={2}>
            {t("common.noData") || ""}
          </StyledAutoParkMessage>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </StyledTableWrapper>
  );
};
