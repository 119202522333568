import React from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { AUDIT_TEMPLATES } from "@/constants/routes";

import { useGetAuditTemplateById } from "@/helpers/api/audits/hooks";
import { useParams } from "react-router-dom";
import { Loader } from "@/packages/loader/Loader";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";

import { components } from "@/helpers/api/swagger-scheme";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { getDefaultAuditTableHeaders } from "./helpers";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

export type TAuditSections = components["schemas"]["AuditSection"][];

export const mutateArrOfObj = (arrObj: TAuditSections) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    // @ts-ignore
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  return res.map((item, index) => recursive(item, 0, `${index + 1}`));
};

export const AuditTemplateView: React.FC = () => {
  const params = useParams();
  const auditId = params.id as string;

  const { data: audit, isLoading } = useGetAuditTemplateById(auditId);

  const batchSections = mutateArrOfObj(audit?.data.sections || []);

  const headers = getDefaultAuditTableHeaders({});

  return (
    <>
      <DashboardLayout
        toBack={AUDIT_TEMPLATES}
        title={audit?.data?.title || ""}
      >
        <>
          {!isLoading ? (
            <PermissionsAdapter
              resourceName="audit.templates"
              resourceAction="read"
              fallback={() => <span>No rights</span>}
            >
              <DndProvider backend={HTML5Backend}>
                <TreeComponent
                  data={batchSections || []}
                  renderComponent={({ data }) => (
                    <TableSimple headers={headers || []} data={data || []}/>
                  )}
                />
              </DndProvider>
            </PermissionsAdapter>
          ) : (
            <Loader size={48}/>
          )}
        </>
      </DashboardLayout>
    </>
  );
};
