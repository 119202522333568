import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { name, version } from "../package.json";
import { App } from "./App";

const insert2body = (html: string) => {
  const spriteContainer = document.createElement("div");
  spriteContainer.innerHTML = html;
  spriteContainer.style.display = "none";
  document.body.prepend(spriteContainer);
};

(async () => {
  const spriteLink = "/sprite.svg";

  try {
    const spriteRequest = await fetch(spriteLink);
    const spriteHTML = await spriteRequest.text();

    if (spriteHTML) insert2body(spriteHTML);

    if ("caches" in window) {
      // console.log("caches is in window");
      const newCache = await caches.open(`${name}${version}`);
      // console.log("caches openes");
      const options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "image/svg+xml",
        }),
      };

      let response = await newCache.match(spriteLink);
      // console.log("response from cache is received");
      if (!response) {
        const req = new Request(spriteLink, options);
        // console.log("response from request is received");
        await newCache.add(req);
        // console.log("new cache is added");
        await newCache.match(spriteLink);
        // console.log("new cache is matched");
        return;
      }
    }
  } catch (e) {
    console.log(e);
  }
  //    const newCache = await caches.open(`${name}${version}`);
  //
  //    const options = {
  //      method: "GET",
  //      headers: new Headers({
  //        "Content-Type": "image/svg+xml",
  //      }),
  //    };
  //
  //    let response = await newCache.match(spriteLink);
  //    let html;
  //
  //    if (!response) {
  //      const req = new Request(spriteLink, options);
  //      await newCache.add(req);
  //      response = await newCache.match(spriteLink);
  //      html = await response?.text();
  //      insert2body(html || "");
  //
  //      return;
  //    }
  //  }
})();

const root = ReactDOM.createRoot(
  // tslint:disable-next-line:ban-ts-ignore
  // @ts-ignore
  document.getElementById("root")
);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
