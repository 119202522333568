import React, { useContext, useEffect, useRef } from "react";

import { accessTokenService } from "@/helpers/api/accessTokenService";
import { TPermissionResourcesName } from "@/helpers/api/eventLogs/types";
import { NAV_ITEMS } from "@/helpers/consts";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import {
  DashboardLayoutNavBarStyles,
  NavBarInfoBlock,
  ScrollItem,
  StyledDashboardLayoutNavBarLogo,
  StyledDashboardLayoutNavItemsList,
  StyledMenuTop,
  StyledTriggerButton,
} from "./index.styles";
import { NavItem } from "./NavItem";
import { ContactItem } from "./ContactItem";
import { useTranslation } from "react-i18next";
import { refreshTokenService } from "@/helpers/api/refreshTokenService";

export type NavMenuItemNode = {
  key: string;
  permission?:
    | TPermissionResourcesName
    | TPermissionResourcesName[]
    | "hide-section";
  label: string;
  icon: string;
  children: NavMenuItemLeaf[];
};

export type NavMenuItemLeaf = {
  permission?:
    | TPermissionResourcesName
    | TPermissionResourcesName[]
    | "hide-section";
  key: string;
  icon: string;
  label: string;
  path: string;
  children?: NavMenuItemLeaf[];
};

export type NavMenuItem = NavMenuItemNode | NavMenuItemLeaf;

export const getPermissionsList = (
  child: NavMenuItemLeaf[]
): TPermissionResourcesName[] => {
  return child.map((item) => item?.permission) as TPermissionResourcesName[];
};

export interface IDashboardLayoutNavBar {
  isOpen: boolean;
  onMenuToggle: () => void;
}

export const DashboardLayoutNavBar: React.FC<IDashboardLayoutNavBar> = ({
                                                                          isOpen,
                                                                          onMenuToggle,
                                                                        }) => {
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const onLeave = () => {
    accessTokenService.deleteToken();
    refreshTokenService.deleteToken();
    window.location.reload();
  };

  /*
     Нужны для сохранения позиции скролла навигационного меню, так как при переходе навигации
     DashboardLayoutNavBar ререндерится из-за повсеместного использования в DashboardLayout
   */
  const navScrollPositionRef = useRef<number>(0);
  const scrollItemRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (event) => {
    navScrollPositionRef.current = event.currentTarget.scrollTop;
  };

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('navScrollPositionRef');
    if (!scrollPosition) {
      return;
    }
    scrollItemRef?.current?.scrollTo({
      top: Number(scrollPosition)
    })
  }, [])


  const { t } = useTranslation();
  return (
    <DashboardLayoutNavBarStyles isOpen={isOpen}>
      <StyledMenuTop>
        <StyledDashboardLayoutNavBarLogo>
          <use xlinkHref="#logo"/>
        </StyledDashboardLayoutNavBarLogo>
        <StyledTriggerButton
          isOpen={isOpen}
          icon="menu-close"
          isSquared={true}
          variant="primary"
          size="s"
          color="white"
          onClick={onMenuToggle}
        />
      </StyledMenuTop>
      <ScrollItem ref={scrollItemRef} onScroll={handleScroll}>
        <StyledDashboardLayoutNavItemsList isOpen={isOpen}>
          {NAV_ITEMS(t, profile).map((navItem, i) => (
            <NavItem
              navScrollPositionRef={navScrollPositionRef}
              key={i}
              navItem={navItem}
              isOpen={isOpen}
              onClick={() => !isOpen && onMenuToggle()}
            />
          ))}
        </StyledDashboardLayoutNavItemsList>
      </ScrollItem>
      <NavBarInfoBlock isOpen={isOpen}>
        <ContactItem/>
        <NavItem
          navScrollPositionRef={navScrollPositionRef}
          onClick={onLeave}
          navItem={{
            key: "",
            icon: "exit",
            label: t("common.leave") || "",
            path: "login",
          }}
          permissionDisabled={true}
          isOpen={isOpen}
        />
      </NavBarInfoBlock>
    </DashboardLayoutNavBarStyles>
  );
};
