import {
  TPatchWarrantyAuditTemplateFileRequest,
  TWarrantyAuditTemplateList,
} from "@/helpers/api/warranty-audit/templates/types";
import { Select } from "@/packages/formElements/inputs/Select";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Popconfirm } from "antd";
import { StyledButtons, StyledSelect } from "./index.styles";
import { t } from "i18next";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const MOCK_AUDIT_FILTERS = [
  { label: "audits.templates.all", value: "" },
  { label: "audits.templates.active", value: "true" },
  { label: "audits.templates.hide", value: "false" },
];
export const MOCK_AUDIT_FILTERS_TABLE = [
  { label: "audits.templates.active", value: "true" },
  { label: "audits.templates.hide", value: "false" },
];

export const getDefaultAuditWarrantyTemplatesTableHeaders = (options: {
  onDeleteClick?: (id: number) => void;
  onAuditUpdate: (data: TPatchWarrantyAuditTemplateFileRequest) => void;
  onEditClick: (id: number) => void;
  onViewClick?: (id: number) => void;
  openUserModal: Dispatch;
}): IHeaderItem<TWarrantyAuditTemplateList>[] => {
  const { onDeleteClick, onAuditUpdate, onEditClick, onViewClick, openUserModal } = options;

  return [
    {
      Header: "ID",
      accessor: "id",
      width: 50,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: t("audits.templates.name") || "",
      accessor: "title",
      isSortable: true,
    },

    {
      Header: t("audits.templates.status_2") || "",
      accessor: "active",

      width: "142px",
      minWidth: 142,
      maxWidth: 142,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledSelect>
            <Select
              options={MOCK_AUDIT_FILTERS_TABLE.filter((item) => !!item.value)}
              value={
                MOCK_AUDIT_FILTERS_TABLE.find(
                  (item) => item.value === value?.toString()
                )?.label
              }
              onChange={(_, option) => {
                if (!Array.isArray(option) && onAuditUpdate) {
                  onAuditUpdate({
                    id: original.id,
                    data: {
                      active: option.value === "true",
                    },
                  });
                }
              }}
            />
          </StyledSelect>
        );
      },
    },
    {
      Header: t("audits.templates.creator") || "",
      accessor: "creator",

      Cell: ({ row: { original } }) => {
        return (
          original?.creator
            ?
            <StyledUserNameWrapper
              onClick={() => openUserModal({ userId: original?.creator.id })}>
              {original?.creator?.firstName} {original?.creator?.lastName}
            </StyledUserNameWrapper>
            : "-"
        );
      },
    },
    {
      Header: t("audits.templates.dateCreation") || "",
      accessor: "createdAt",
      isSortable: true,
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      Cell: ({ row: { original }, value }) => {
        return (
          <div>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Paragraph>
          </div>
        );
      },
    },
    {
      Header: t("audits.templates.dateChange") || "",
      accessor: "updatedAt",
      isSortable: true,
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      Cell: ({ row: { original }, value }) => {
        return (
          <div>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Paragraph>
          </div>
        );
      },
    },

    {
      Header: "",
      id: "actions",
      width: 80,
      minWidth: 80,
      maxWidth: 80,

      Cell: ({ row: { original } }) => {
        // const hasEdit = usePermission("audit.templates", "update"); // TODO: заменить пермишн
        return (
          <StyledButtons>
            <IconButton
              icon={true ? "edit" : "eye-open"} // TODO: добавить пермишн
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => onEditClick(original.id)}
            />
            <Popconfirm
              title={t("audits.templates.deleteConfirm") || ""}
              onConfirm={() => onDeleteClick && onDeleteClick(original.id)}
              okText={t("common.yes") || ""}
              cancelText={t("common.no") || ""}
            >
              <IconButton
                icon="delete"
                color="black"
                size="l"
                variant="tertiary"
              />
            </Popconfirm>
          </StyledButtons>
        );
      },
    },
  ];
};
