import { useEffect } from "react";

import { useForm } from "@/hooks/useForm";
export interface IDealerBonusesFilterFormValues {
  managerId?: string | null;
  year?: string | null;
  quarter?: string | null;
  search?: string;
}

export const DEFAULT_DEALER_BONUSES_FILTER_FORM_VALUES: IDealerBonusesFilterFormValues =
  {
    managerId: null,
    year: null,
    quarter: null,
    search: "",
  };

export type TOnSubmitDealerBonusesFilterFormCb = (
  values: IDealerBonusesFilterFormValues
) => void;

export const useDealerBonusesFilterForm = (
  onSubmit: TOnSubmitDealerBonusesFilterFormCb,
  defaultValues: IDealerBonusesFilterFormValues
) => {
  const form = useForm<IDealerBonusesFilterFormValues>({
    defaultValues: DEFAULT_DEALER_BONUSES_FILTER_FORM_VALUES,
    onSubmit,
  });

  useEffect(() => {
    form.form.reset(defaultValues, { keepDefaultValues: true });
    // form.form.reset();
  }, []);

  return form;
};
