import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";

import {
  ExportWithdrawalRequest,
  TEditWithdrawalRequest,
  TEditWithdrawalResponse,
  TGetWarrantyWithdrawalRequest,
  TGetWarrantyWithdrawalResponse,
} from "./types";
import { TExportGsmResponse } from "@/helpers/api/maintenanceGrids/gsm/types";

export const useGetWarrantyWithdrawal = (
  query: TGetWarrantyWithdrawalRequest
) => {
  return useQuery(
    ["warranty", "withdrawal", query],
    () => {
      return api.get<TGetWarrantyWithdrawalResponse>("/guarantee/withdrawal", {
        params: query,
      });
    },
    { cacheTime: 0 }
  );
};

export const useEditWithdrawal = () => {
  return useMutation(
    ({
       id,
       data,
       onSuccess,
       onError,
     }: {
      id: string;
      data: TEditWithdrawalRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TEditWithdrawalResponse>(`/guarantee/withdrawal/${id}`, {
        ...data,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useExportWithdrawal = () => {
  return useMutation((request: ExportWithdrawalRequest) => {
    return api.get<TExportGsmResponse>(`/guarantee/withdrawal/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
