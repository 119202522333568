import React from "react";
import styled from "styled-components";
import { Icon } from "@/packages/icon/Icon";

const StyledMessageWrap = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  align-items: flex-end;
  align-self: ${({ isMyMessage }) => (isMyMessage ? "flex-end" : "flex-start")};
  flex-direction: ${({ isMyMessage }) => (isMyMessage ? "row-reverse" : "row")};
  gap: 16px;
`;

const StyledAvatar = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #f8f7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IMessageAdapter {
  children: JSX.Element;
  isMyMessage: boolean;
}
export const MessageAdapter: React.FC<IMessageAdapter> = (props) => {
  const { isMyMessage, children } = props;
  return (
    <StyledMessageWrap isMyMessage={isMyMessage}>
      <StyledAvatar>
        <Icon name="profile" size={16} />
      </StyledAvatar>
      {children}
    </StyledMessageWrap>
  );
};
