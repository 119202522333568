import { StyledContent } from '@/components/VinDecoder/index.styles';
import { GridTab } from '@/components/VinDecoder/GridTab';
import { HistoryTab } from '@/components/VinDecoder/HistoryTab';
import { InfoTab } from '@/components/VinDecoder/InfoTab';
import {
    useGetDecoderInfo,
    useGetDecoderMaintenanceGrid,
    useGetDecoderPurchase,
} from '@/helpers/api/vinDecoder/hooks';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { ITab, Tabs } from '@/packages/tabs/Tabs';
import React, { FC, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Placeholder } from '@/packages/placeholder/Placeholder';
import { Loader } from '@/packages/loader/Loader';
import { useNotification } from '@/hooks/useNotification';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useVinDecoderForm } from '@/components/VinDecoder/hooks';
import { FormProvider } from 'react-hook-form';
import { StyledButton, StyledForm, StyledFormWrapper, StyledInputField } from './index.styles';
import { VinDecoderHistoryModel } from '@/pages/VinDecoder/VinDecoderHistoryModel/VinDecoderHistoryModel';
import { useModal } from '@/hooks/useModal';
import { ContextProfile } from '@/hooks/contexts/useProfile';

const StyledVinDecoder = styled.div``;

const VinDecoder: FC = () => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const search = useLocation().search;

    const selectedVin = new URLSearchParams(search).get('activeVin') || '';
    const selectedTab = new URLSearchParams(search).get('activeTab') || '';

    const [ activeVin, setActiveVin ] = useState<{ vin: string }>({
        vin: selectedVin,
    });

    const [ vinSearchValue, setVinSearchValue ] = useState(selectedVin);

    const { createNotificationSuccess, createNotificationError } =
        useNotification();

    const {
        data: decoderInfo,
        refetch: refetchDecoderInfo,
        isFetching: isDecoderInfoFetching,
        isSuccess: isDecoderInfoSuccess,
    } = useGetDecoderInfo({
        vin: activeVin.vin,
        onSuccess: () => createNotificationSuccess(t('kiaFlex.vinSuccess') || ''),
        onError: () =>
            createNotificationError(t('vin-decoder.desipherError') || ''),
    });

    const {
        data: decoderPurchase,
        refetch: refetchDecoderPurchase,
        isFetching: isDecoderPurchaseFetching,
        isSuccess: isDecoderPurchaseSuccess,
    } = useGetDecoderPurchase({
        vin: activeVin.vin,
        onSuccess: () => createNotificationSuccess(t('kiaFlex.historySuccess') || ''),
        onError: () => createNotificationError(t('kiaFlex.historyError') || ''),
    });

    const {
        data: decodedMaintenanceGrid,
        refetch: refetchDecoderMaintenanceGrid,
        isFetching: isDecoderMaintenanceGridFetchnig,
        isSuccess: isDecoderMaintenanceGridSuccess,
    } = useGetDecoderMaintenanceGrid({
        onSuccess: () => ({}),
        onError: () => ({}),
        vin: activeVin.vin,
    });

    const { t } = useTranslation();

    const isVinLoading = isDecoderInfoFetching || isDecoderPurchaseFetching;
    const isVinDecoded = isDecoderInfoSuccess || isDecoderPurchaseSuccess;
    const isGridDecoded =
        isDecoderMaintenanceGridFetchnig || isDecoderMaintenanceGridSuccess;

    const handleFilterChange = (data: { vin: string }) => {
        setActiveVin(data);
        setActiveTab(tabs[0]);
        setSearchParams({ activeVin: data.vin });
    };

    const { form, onSubmitHandler } = useVinDecoderForm(handleFilterChange);

    const {
        modalState: vinDecodeHistoryModal,
        openModal: openVinDecodeHistoryModal,
    } = useModal<any>();

    const [ historyData, setHistoryData ] = useState(null);

    const tabs: ITab[] = [
        {
            name: 'park.autoInfo',
            slug: 'info',
            content: (
                <InfoTab
                    title={`Результат по ${activeVin.vin}:`}
                    data={decoderInfo?.data}
                    vin={activeVin.vin}
                />
            ),
        },
        {
            name: 'kiaFlex.service',
            slug: 'history',
            content: (
                <HistoryTab
                    title={`${t('kiaFlex.serviceTitle') || ''} ${activeVin.vin}:`}
                    subtitle={`${t('kiaFlex.serviceSubTitle')} ${
                        decoderPurchase?.data?.meta?.itemCount
                    } ${t('kiaFlex.serviceSubTitle_2') || ''}`}
                    data={decoderPurchase?.data}
                    openVinDecodeHistoryModal={openVinDecodeHistoryModal}
                    setHistoryData={setHistoryData}
                />
            ),
        },
        {
            name: t('maintenanceGrids.titleSolo') || '',
            slug: 'grid',
            content: (
                <GridTab
                    title={`${t('kiaFlex.TO') || ''} ${activeVin.vin}:`}
                    // @ts-ignore
                    subtitle={`${decoderInfo?.data?.model} ${decoderInfo?.data?.description}`}
                    data={decodedMaintenanceGrid?.data}
                />
            ),
        },
    ];

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const isDealer = profile?.type === 'dealer';

    const dealerTabs: ITab[] = [
        {
            name: 'park.autoInfo',
            slug: 'info',
            content: (
                <InfoTab
                    title={`Результат по ${activeVin.vin}:`}
                    data={decoderInfo?.data}
                    vin={activeVin.vin}
                />
            ),
        },
        {
            name: t('maintenanceGrids.titleSolo') || '',
            slug: 'grid',
            content: (
                <GridTab
                    title={`${t('kiaFlex.TO') || ''} ${activeVin.vin}:`}
                    // @ts-ignore
                    subtitle={`${decoderInfo?.data?.model} ${decoderInfo?.data?.description}`}
                    data={decodedMaintenanceGrid?.data}
                />
            ),
        },
    ];

    const [ activeTab, setActiveTab ] = useState(tabs[0]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);

        setSearchParams({ activeVin: activeVin.vin });
        refetchTabData(tab.slug);
    };

    const refetchTabData = (tabSlug: string) => {
        switch (tabSlug) {
            case 'history':
                !isDecoderPurchaseSuccess && refetchDecoderPurchase();
                return;

            case 'grid':
                !isDecoderMaintenanceGridSuccess && refetchDecoderMaintenanceGrid();
                return;
        }
    };

    const handleVinSearchValue = (e: string) => {
        setVinSearchValue(e);
        form.setValue('vin', e);
    };

    useEffect(() => {
        if (selectedVin) {
            setActiveVin({ vin: selectedVin });
            refetchDecoderInfo();
        }
    }, [ selectedVin ]);

    return (
        <DashboardLayout
            title={t('vin-decoder.title') || ''}
            description={t('vin-decoder.description') || ''}
            noIndent={true}
        >
            <StyledFormWrapper>
                <FormProvider {...form}>
                    <StyledForm>
                        <StyledInputField
                            name='vin'
                            placeholder={t('kiaFlex.vinDescr') || ''}
                            allowClear
                            size={'large'}
                            bordered={true}
                            defaultValue={vinSearchValue}
                            value={vinSearchValue}
                            onChange={handleVinSearchValue}
                        />

                        <StyledButton
                            theme='secondary'
                            color='brand'
                            size='small'
                            onClick={onSubmitHandler}
                        >
                            {t('vin-decoder.decodeBtn')}
                        </StyledButton>
                    </StyledForm>
                </FormProvider>
            </StyledFormWrapper>

            <StyledVinDecoder>
                {isVinLoading ? (
                    <Loader size={48}/>
                ) : isVinDecoded || isGridDecoded ? (
                    <Tabs
                        tabs={isDealer ? dealerTabs : tabs}
                        activeTab={activeTab}
                        onChange={handleTabChange}
                        type='card'
                    />
                ) : (
                    <StyledContent>
                        <Placeholder>{t('vin-decoder.emptyInfo')}</Placeholder>
                    </StyledContent>
                )}
            </StyledVinDecoder>
            <VinDecoderHistoryModel data={historyData} {...vinDecodeHistoryModal} />
        </DashboardLayout>
    );
};

export { VinDecoder };
