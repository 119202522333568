import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import { TGetWarrantyStatiscticsRequest, TGetWarrantyStatiscticsV2Response, } from "./types";

export const useGetWarrantyStatistics = (
  id: TGetWarrantyStatiscticsRequest
) => {
  return useQuery(
    ['statistic', id],
    () => {
      return api.get<TGetWarrantyStatiscticsV2Response>(
        `/guarantee/audits/${id}/statistics/v2`
      );
    },
    { cacheTime: 0 }
  );
};

export const useGetWarrantyStatisticsExcel = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/guarantee/audits/${request.id}/statistics/v2/export/excel`, {
      params: request,
      responseType: "blob",
    });
  });
};
