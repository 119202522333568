import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetDocuments } from "@/helpers/api/documents/hooks";

export const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  const { data: document } = useGetDocuments(
    "consent-to-the-processing-of-personal-data"
  );

  useEffect(() => {
    if (document) {
      const newWindow = window.open(
        // @ts-ignore
        document?.data?.data[0]?.file.fileUrl,
        "_self"
      );
      newWindow?.focus();
    }
  }, [document]);

  return <></>;
};
