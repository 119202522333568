import React, { useCallback, useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";

import { WARRANTY_AUDIT_TEMPLATES } from "@/constants/routes";
import styled from "styled-components";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Icon } from "@/packages/icon/Icon";
import { Space } from "antd";
import { useModal } from "@/hooks/useModal";
import { useParams } from "react-router-dom";
import { Loader } from "@/packages/loader/Loader";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  TCreateWarrantyAuditViolationTemplateRequest,
  TCreateWarrantyAuditViolationTemplateResponse,
  TemplateSelectionType,
  TPutWarrantyParameterRequest,
} from "@/helpers/api/warranty-audit/templates/types";
import {
  useCreateViolationTemplate,
  useDeleteViolationTemplate,
  useDeleteWarrantyParameter,
  useEditWarrantyParameter,
  useGetTemplateViolationList,
  useGetWarrantyTemplateById,
  useMoveSection,
  usePatchViolationTemplate,
} from "@/helpers/api/warranty-audit/templates/hooks";
import { mutateArrOfObj } from "@/helpers/utils";
import { WarrantyEditBatchModal } from "@/components/AuditWarranty/Templates/WarrantyEditBatchModal";
import {
  AddWarrantyParametersModals,
  IUploadWarrantyModalPayload,
} from "@/components/AuditWarranty/Templates/AddWarrantyParametersModals";
import { ChangeWarrantyTemplateNameModal } from "@/components/AuditWarranty/Templates/ChangeWarrantyTemplateNameModal";
import { ITab } from "@/packages/tabs/Tabs";
import { useNotification } from "@/hooks/useNotification";
import { StyledTab, StyledTabType, StyledViolationData } from "./index.styles";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import { usePagination } from "@/hooks/usePagination";
import { Pagination } from "@/packages/pagination/Pagination";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { ViolationTemplateCreateModal } from "src/components/AuditWarranty/Templates/ViolationCreateTemplateModal";
import { Button } from "@/packages/button/Button";
import { ViolationTemplateEditModal } from "@/components/AuditWarranty/Templates/ViolationEditTemplateModal";
import {
  WarrantyTemplateParametersTable
} from "@/pages/AuditWarrantyEdit/WarrantyParametrsTable/WarrantyTemplateParametrsTable";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { StyledInfoIcon } from "@/components/Bonuses/ReportsImport/ReportCard/index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

const StyledTopContent = styled(Space)`
    display: flex;
    justify-items: center;
    gap: 10px;
    align-items: center;
`;

const StyledInfoButton = styled.div`
    cursor: pointer;
`;


const StyledTexButtonIcon = styled.div`
    margin-right: 12px;
`;

const tabs: ITab[] = [
  {
    name: "Проверка стандартов ДППО",
    label: "warrantyAudits.list.dppo",
    slug: "dppo",
  },
  {
    name: "Гарантийная документация",
    label: "warrantyAudits.list.warranty",
    slug: "warranty",
  },
  {
    name: "Запасные части",
    label: "warrantyAudits.list.spares",
    slug: "spareParts",
  },
  {
    name: "Нарушения и баллы",
    label: "warrantyAudits.list.violationsTitle",
    slug: "violations",
  },
];

export const AuditWarrantyEdit: React.FC = () => {
  const { t } = useTranslation();
  const {
    modalState: EditAuditTreeModalState,
    openModal: EditAuditTreeOpenModal,
    closeModal: EditAuditTreeCloseModal,
  } = useModal();

  const {
    modalState: ChangeNameModalState,
    openModal: ChangeNameModalOpen,
    closeModal: ChangeNameModalClose,
  } = useModal();

  const {
    modalState: ParametersModalState,
    openModal: ParametersModalOpen,
    closeModal: ParametersModalClose,
  } = useModal();

  const {
    modalState: violationCreateModalState,
    openModal: violationCreateModalOpen,
    closeModal: violationCreateModalClose,
  } = useModal();


  const { createNotificationSuccess } = useNotification();
  const { createNotificationError } = useNotification();
  const params = useParams();
  const templateId = Number(params.id);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const isDppo = activeTab.slug === "dppo";
  const {
    data: template,
    isLoading,
    refetch: updateTemplate,
  } = useGetWarrantyTemplateById({
    id: templateId,
    sectionsType: t(activeTab.name) as TemplateSelectionType,
  });

  const { paginationParams, setPage } = usePagination();

  const {
    data: violationList,
    isLoading: isViolationsLoading,
    refetch: refetchVioletionList,
  } =
    useGetTemplateViolationList({
        "page[limit]": paginationParams.limit,
        "page[page]": paginationParams.page,
        "filter[templateId]": templateId,
      },
      activeTab.slug === 'violations',
    );

  const { mutate: deleteParameter } = useDeleteWarrantyParameter();
  const { mutate: editParam } = useEditWarrantyParameter(isDppo);
  const { mutate: deleteViolation } = useDeleteViolationTemplate();
  const { mutate: createViolationTemplate } =
    useCreateViolationTemplate();

  const { mutate: editViolationTemplate } =
    usePatchViolationTemplate();

  const {
    modalState: violationEditModalState,
    openModal: violationEditModalOpen,
  } =
    useModal<{ id: string; patch: TCreateWarrantyAuditViolationTemplateRequest }, {
      record: TCreateWarrantyAuditViolationTemplateResponse
    }>(
      (close) => {
        return async (payload) => {
          try {
            await editViolationTemplate(payload);
            close();
            createNotificationSuccess(t("warrantyAudits.violation.editComplete") || "");
          } catch (error: any) {
            createNotificationError("Произошла ошибка");
          }
        };
      }
    );

  const [modalPayload, setModalPayload] =
    useState<IUploadWarrantyModalPayload | null>();


  const onSubmitViolationCreate = useCallback(async (data) => {
    try {
      await createViolationTemplate({ data: data });
      createNotificationSuccess(t("warrantyAudits.violation.createComplete") || "");
    } catch (error: any) {
      createNotificationError("Произошла ошибка");
    }
  }, []);

  const handleSuccessAddParams = () => {
    ParametersModalClose();
    setModalPayload(null);
    updateTemplate();
  };

  const handleDeleteParameter = (id: string) => {
    deleteParameter({
      id,
      onSuccess: () => {
        updateTemplate();
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleDeleteViolation = (id: string) => {
    deleteViolation({
      id,
      onSuccess: () => {
        refetchVioletionList();
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleChangePhotoParam = (
    id: string,
    value: TPutWarrantyParameterRequest["body"]
  ) => {
    editParam({
      id,
      body: value,
      onSuccess: () => {
        updateTemplate();
      },
      onError: (error) => {
        createNotificationError(error.response?.data.message || error.message);
      },
    });
  };

  const handleSuccessChangeName = () => {
    ChangeNameModalClose();
    updateTemplate();
  };

  const handleSuccessChangeTree = () => {
    EditAuditTreeCloseModal();
    updateTemplate();
  };

  const headers = [
    {
      Header: t("warrantyAudits.violation.code") || "",
      accessor: "code",
      maxWidth: 100,
    },
    {
      Header: t("warrantyAudits.violation.pointAmount") || "",
      accessor: "pointAmount",
      maxWidth: 100,
    },
    {
      Header: t("warrantyAudits.violation.description") || "",
      accessor: "description",
    },
    {
      Header: "",
      accessor: "actions",
      minWidth: 50,
      maxWidth: 50,
      Cell: (tableData) => {
        return (
          <Space>
            <IconButton
              size="l"
              icon="edit"
              color="black"
              variant="tertiary"
              isDisabled={isViolationsLoading}
              onClick={() => violationEditModalOpen({ record: tableData.row.original })}
            />
            <IconButton
              size="l"
              icon="delete"
              color="black"
              variant="tertiary"
              isDisabled={isViolationsLoading}
              onClick={() => handleDeleteViolation(tableData.row.original.id)}
            />
          </Space>
        );
      },
    },
  ];

  const { mutate: move } = useMoveSection();
  const handleMove = ({ id, index }) => {
    move({
      id,
      patch: { order: index },
      onSuccess: () => {
        updateTemplate();
        createNotificationSuccess('Порядок изменен успешно')
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        toBack={WARRANTY_AUDIT_TEMPLATES}
        title={template?.data?.title || ""}
        headerLeft={
          activeTab.slug !== 'violations' &&
          <PermissionsAdapter
            resourceName="audit.templates"
            resourceAction="update"
            loader={<Loader size={24}/>}
            isLoading={isLoading}
          >
            <IconButton
              onClick={() => ChangeNameModalOpen()}
              icon="edit"
              size="l"
              color="black"
              variant="tertiary"
            />
          </PermissionsAdapter>
        }
      >
        <>
          <PermissionsAdapter
            resourceName="guarantee.templates"
            resourceAction="update"
            fallback={() => <span>No rights</span>}
            loader={<Loader size={48}/>}
          >
            <StyledTabType>
              {tabs.map((el) => (
                <StyledTab
                  key={el.slug}
                  isActive={el.slug === activeTab.slug}
                  onClick={() => {
                    setActiveTab(el);
                  }}
                >
                  <Paragraph size={12} weight={400}>
                    {
                      // @ts-ignore
                      t(el.label)
                    }
                  </Paragraph>
                </StyledTab>
              ))}
            </StyledTabType>
            {
              activeTab.slug !== 'violations' &&
              <StyledTopContent size={24}>
                <ButtonText
                  icon={
                    <StyledTexButtonIcon>
                      <Icon name="edit" size={16}/>
                    </StyledTexButtonIcon>
                  }
                  isIconLeft={true}
                  onClick={EditAuditTreeOpenModal}
                >
                  {t("audits.templates.structure") || ""}
                </ButtonText>
                <StyledInfoButton>
                  <Tooltip
                    content={t("warrantyAudits.templates.dndTooltip") || ""}
                    placement='bottom'
                  >
                    <StyledInfoIcon size={16} name='info'/>
                  </Tooltip>
                </StyledInfoButton>
              </StyledTopContent>
            }
            {
              activeTab.slug !== 'violations' &&
              <>
                {
                  !isLoading ? (
                    <DndProvider backend={HTML5Backend}>
                      <TreeComponent
                        data={mutateArrOfObj(template?.data?.sections) || []}
                        paramKey="params"
                        onParametrsModalOpen={(id) => {
                          setModalPayload({ sectionId: id });
                          ParametersModalOpen();
                        }}
                        isDraggable
                        onDragEnd={handleMove}
                        renderComponent={({ data }) => {
                          return (
                            <WarrantyTemplateParametersTable
                              data={data}
                              onEditClick={(id) => {
                                setModalPayload({ selectedParamId: id });
                                ParametersModalOpen();
                              }}
                              isDppo={isDppo}
                              onPhotoParamEdit={handleChangePhotoParam}
                              onDeleteParameter={handleDeleteParameter}
                            />
                          );
                        }}
                      />
                    </DndProvider>
                  ) : (
                    <Loader size={48}/>
                  )}
              </>
            }
            {
              activeTab.slug === 'violations' &&
              <>
                {
                  !isViolationsLoading ?
                    <>
                      <Button
                        color="brand"
                        size="middle"
                        theme="primary"
                        onClick={violationCreateModalOpen}
                      >
                        {t("warrantyAudits.violation.create") || ""}
                      </Button>
                      <StyledViolationData>
                        <TableSimple
                          data={violationList?.data.data || []}
                          headers={headers}
                        />
                        <Pagination
                          current={paginationParams.page}
                          onChange={setPage}
                          pageSize={1}
                          total={violationList?.data.meta?.pageCount}
                        />
                      </StyledViolationData>
                    </>
                    :
                    <Loader size={48}/>
                }
              </>}
          </PermissionsAdapter>
        </>
      </DashboardLayout>
      <WarrantyEditBatchModal
        isOpen={EditAuditTreeModalState.isOpen}
        onCancel={EditAuditTreeCloseModal}
        onSubmit={() => handleSuccessChangeTree()}
        data={mutateArrOfObj(template?.data?.sections) || []}
        templateId={templateId}
        type={t(activeTab.name) as TemplateSelectionType}
      />
      {template?.data ? (
        <ChangeWarrantyTemplateNameModal
          id={templateId}
          oldName={template?.data?.title || ""}
          isOpen={ChangeNameModalState.isOpen}
          onCancel={ChangeNameModalClose}
          onSubmit={handleSuccessChangeName}
        />
      ) : (
        <></>
      )}
      {modalPayload ? (
        <AddWarrantyParametersModals
          isDppo={activeTab.slug === "dppo"}
          payload={modalPayload}
          isOpen={ParametersModalState.isOpen}
          onSubmit={handleSuccessAddParams}
          onCancel={ParametersModalClose}
        />
      ) : (
        <></>
      )}
      {
        violationList &&
        <>
          <ViolationTemplateCreateModal
            templateId={templateId}
            isOpen={violationCreateModalState.isOpen}
            onSubmit={onSubmitViolationCreate}
            onCancel={violationCreateModalClose}
          />
          <ViolationTemplateEditModal
            templateId={templateId}
            {...violationEditModalState}
          />
        </>

      }
    </>
  );
};
