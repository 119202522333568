import { StatisticFilter } from "@/components/informationalLetters/InformationLettersStatistic/InformationLettersFilter";
import { IStatisticFilterFormValues } from "@/components/informationalLetters/InformationLettersStatistic/InformationLettersFilter/hooks";
import { StatisticTable } from "@/components/informationalLetters/InformationLettersStatistic/InformationLettersStatisticTable";
import { useGetDealerListFilters } from "@/helpers/api/dc/dealers/hooks";
import { useGetKiaDealerListFilters } from "@/helpers/api/dc/kia-dealers/hooks";
import {
  useExportInformationLetterStatistic,
  useGetInformationLetterById,
  useGetInformationLettersStatistic,
} from "@/helpers/api/InformationalLetters/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Space } from "antd";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { createEnumParam, StringParam, useQueryParams } from "use-query-params";
import { StyledPagination } from "./index.styles";

export const InformationLettersStatistic: React.FC = () => {
  const params = useParams();

  const {
    state: { profile },
  } = useProfile();

  const { data: informationLetterResponse } = useGetInformationLetterById({
    id: params.id as string,
  });
  const { paginationParams, setPage } = usePagination();
  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: "URL страницы скопирован в буфер обмена",
  });

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    name: StringParam,
    city: StringParam,
    warrantyCode: StringParam,
    sapCode: StringParam,
    mobisCode: StringParam,
    status: createEnumParam(["Ознакомлен", "Не ознакомлен"]),
  });

  const filters: IStatisticFilterFormValues = {
    name: filterQueryParams.name || undefined,
    city: filterQueryParams.city || undefined,
    warrantyCode: filterQueryParams.warrantyCode || undefined,
    sapCode: filterQueryParams.sapCode || undefined,
    mobisCode: filterQueryParams.mobisCode || undefined,
    status: filterQueryParams.status || undefined,
  };

  const { mutateAsync: exportStatistic } =
    useExportInformationLetterStatistic();

  const { data: filterOptionsResponse } = useGetDealerListFilters();
  const { data: kiaFilterOptionsResponse } = useGetKiaDealerListFilters();

  const { data: statistics } = useGetInformationLettersStatistic({
    letterId: params.id as string,
    ...paginationParams,
    ...filters,
  });
  const filterOptions = filterOptionsResponse?.data;
  const kiaFilterOptions = kiaFilterOptionsResponse?.data;

  const adaptFilterOptions = kiaFilterOptions || filterOptions;

  const statisticsList = statistics?.data.data;
  const statisticTotal = statistics?.data.meta?.itemCount;

  const handleExportStatistic = () => {
    exportStatistic(
      {
        letterId: params.id as string,
        ...filters,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "statistics.xlsx");
        },
      }
    );
  };

  useEffect(() => {
    setPage(1);
  }, [filterQueryParams]);

  return (
    <>
      {profile?.type === "dealer" ? (
        <DashboardLayout title="Статистика ИП" toBack="/information-letters">
          <span>No rights</span>
        </DashboardLayout>
      ) : (
        <DashboardLayout
          title={informationLetterResponse?.data.name || "Статистика ИП"}
          toBack="/information-letters"
          headerRight={
            <Space>
              <IconButton
                icon="save"
                isSquared
                variant="primary"
                color="white"
                size="m"
                onClick={handleExportStatistic}
              />
              <IconButton
                icon="share"
                size="m"
                color="white"
                isSquared
                variant="primary"
                onClick={() => copyCurrentUrl()}
              />
            </Space>
          }
        >
          <>
            <StatisticFilter
              initValues={filters}
              filterOptions={adaptFilterOptions}
              onFiltersChange={setFilterQueryParams}
            />

            <StatisticTable data={statisticsList} />
          </>
          <StyledPagination
            current={paginationParams.page}
            onChange={setPage}
            pageSize={10}
            total={statisticTotal}
            showSizeChanger={false}
          />
        </DashboardLayout>
      )}
    </>
  );
};
