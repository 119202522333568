import { StyledCollapse, StyledPanel } from "./index.styles";
import { Icon } from "@/packages/icon/Icon";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { TAuditSections } from "@/pages/AuditEdit";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes, TableDragItem } from "@/packages/draggableTable/DraggableTable";
import { useRef } from "react";
import { Identifier, XYCoord } from "dnd-core";

interface IRow {
  hasInfo?: boolean;
  section: TAuditSections[0] & { level: string };
  paramKey: "parameters" | "params";
  children: JSX.Element | null;
  headerCallback?: (data: any) => string;
  onParametrsModalOpen?: (id: string) => void;
  renderComponent: ({ data, level }) => JSX.Element;
  activeWarrantyKeys: Array<string> | undefined;
  setActiveWarrantyKeys: any;
  isDraggable?: boolean;
  index: number;
  onDragEnd?: (item: TableDragItem) => void;
}

const Row: React.FC<IRow> = ({
                               hasInfo,
                               section,
                               children,
                               paramKey,
                               onParametrsModalOpen,
                               renderComponent,
                               headerCallback,
                               activeWarrantyKeys,
                               setActiveWarrantyKeys,
                               isDraggable,
                               index,
                               onDragEnd,
                             }) => {
  const answeredCount = hasInfo && headerCallback && headerCallback(section);
  const { t } = useTranslation();

  const allQuestionsLengthAudits = section?.children
    ?.map((children) => children?.parameters?.length)
    .reduce((partialSum, a) => partialSum + a, 0);

  const allQuestionsLengthWarrantyAudits = section?.children
    // @ts-ignore
    ?.map((children) => children?.params?.length)
    .reduce((partialSum, a) => partialSum + a, 0);

  const allQuestionsLength = allQuestionsLengthAudits
    ? allQuestionsLengthAudits
    : allQuestionsLengthWarrantyAudits;

  const handlePanelChange = (key) => {
    setActiveWarrantyKeys((prevActiveKeys) => {
      if (prevActiveKeys.includes(key)) {
        return prevActiveKeys.filter((k) => k !== key);
      } else {
        return [...prevActiveKeys, key];
      }
    });
  };

  const getActiveKey = () => {
    if (activeWarrantyKeys?.length === 0) {
      const storedActiveKey = sessionStorage.getItem(
        "activeWarrantyAuditsKeys"
      );
      if (storedActiveKey) {
        const parsedActiveKey = JSON.parse(storedActiveKey);
        return parsedActiveKey[parsedActiveKey.indexOf(section.id)];
      }
    } else if (
      activeWarrantyKeys &&
      activeWarrantyKeys?.indexOf(section.id) > -1
    ) {
      return activeWarrantyKeys[activeWarrantyKeys.indexOf(section.id)];
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    TableDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.TREE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: TableDragItem) {
      onDragEnd && onDragEnd(item);
    },
    hover(item: TableDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      item.index = hoverIndex;
    },
  });

  const [{}, drag] = useDrag(() => ({
    type: ItemTypes.TREE,
    canDrag: () => !!isDraggable,
    item: () => {
      return { id: section.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [])

  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
    >
      <StyledCollapse
        accordion
        expandIconPosition="end"
        bordered={false}
        className="site-collapse-custom-collapse"
        defaultActiveKey={setActiveWarrantyKeys && getActiveKey()}
        onChange={setActiveWarrantyKeys && handlePanelChange}
      >
        <StyledPanel
          header={`${section.level} ${section.title} 
        ${hasInfo ? `(${answeredCount} из 
        ${allQuestionsLength
              ? allQuestionsLength
              : section[paramKey]?.length && section[paramKey]?.length
            })`
            : ""
          }`}
          key={section.id}
        >
          {
            section[paramKey]?.length ? (
              renderComponent({
                data: section[paramKey] || [],
                level: section?.level,
              })
            ) : <></>
          }
          {onParametrsModalOpen ? (
            <ButtonText
              isIconLeft={true}
              icon={<Icon name="plus" size={12}/>}
              onClick={() => {
                onParametrsModalOpen(section.id);
              }}
            >
              {t("warrantyAudits.templates.addParam") || ""}
            </ButtonText>
          ) : (
            <></>
          )}
          <>
            {children ? children : <></>}
          </>
        </StyledPanel>
      </StyledCollapse>
    </div>
  );
};

export default Row;
