import { colors } from "@/helpers/index";
import { Icon } from "@/packages/icon/Icon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useDebounce } from "use-debounce";

interface ISearch {
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  onChange: (value?: string) => void;
  onClear?: () => void;
  uppercase?: boolean;
}

const StyledInput = styled.input<{ isDisabled: boolean }>`
  outline: none;
  height: 32px;
  padding: 8px 20px 8px 32px;
  border: 1px solid ${colors.gray400};
  border-radius: 4px;
  color: ${({ isDisabled }) => (isDisabled ? colors.gray300 : colors.gray700)};
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  &::placeholder {
    color: ${({ isDisabled }) =>
      isDisabled ? colors.gray300 : colors.gray500};
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  &:focus {
    border-color: ${colors.brand};
  }
  transition: 0.5s;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

const WrappedInput = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledSearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;

const StyledClear = styled.div<{ isDisabled: boolean }>`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        svg {
          fill: ${colors.gray700};
        }
      }
    `}
`;

export const Search: React.FC<ISearch> = (props) => {
  const { t } = useTranslation();
  const {
    placeholder = t("common.search") || "Поиск",
    value,
    isDisabled,
    className,
    onChange,
    onClear,
    uppercase,
  } = props;

  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const [searchDebouncedValue] = useDebounce(searchValue, 500);

  useEffect(() => {
    onChange(searchDebouncedValue || "");
  }, [searchDebouncedValue]);

  return (
    <WrappedInput className={className}>
      <StyledSearchIcon>
        <Icon
          name="search-16"
          size={16}
          color={isDisabled ? "gray300" : "gray500"}
        />
      </StyledSearchIcon>
      <StyledInput
        disabled={isDisabled}
        value={uppercase ? searchValue?.toUpperCase() : searchValue}
        placeholder={placeholder}
        isDisabled={!!isDisabled}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <StyledClear
        isDisabled={!!isDisabled}
        onClick={(e) => {
          e.stopPropagation();
          onClear && onClear();
          setSearchValue("");
        }}
      >
        <Icon
          name="close-16"
          size={16}
          color={isDisabled ? "gray300" : "gray500"}
        />
      </StyledClear>
    </WrappedInput>
  );
};
