import {
  ISelectField,
  SelectField,
} from "@/packages/formElements/fields/SelectField";
import React, { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormSelect extends Omit<ISelectField, "error"> {
  name: string;
  isDisabled?: boolean;
  refetchValue?: string;
  setRefetchValue?: Dispatch<SetStateAction<string | undefined>>;
}

const _FormSelect: React.FC<IFormSelect> = (props) => {
  const { name, isDisabled, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <SelectField
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
export const FormSelect = React.memo(_FormSelect);
