import { useControlledForm } from "@/hooks/useControlledForm";

export interface IRegistryEmployeeFilterFormValues {
  name: string | undefined;
  roleId: string | undefined;
  status: boolean | undefined;
}

export const DEFAULT_REGISTRY_EMPLOYEES_FILTER_FORM_VALUES: IRegistryEmployeeFilterFormValues =
  {
    name: undefined,
    roleId: undefined,
    status: true,
  };

export const useRegistryEmployeesFilterForm = (options: {
  values: IRegistryEmployeeFilterFormValues;
  onChange: (values: IRegistryEmployeeFilterFormValues) => void;
}) => {
  return useControlledForm(options);
};
