import { PremiumHeader } from "@/components/Bonuses/BonusesPremium/PremiumHeader";
import { PremiumPdfViewer } from "@/components/Bonuses/BonusesPremium/PremiumPdfViewer";
import { StyledFileNotFoundParagraph } from "@/components/Bonuses/BonusesPremium/PremiumPdfViewer/index.styles";
import { useGetQuarter } from "@/helpers/api/bonuses/calculations/hooks";
import {
  useExportDocuments,
  useGetDocument,
} from "@/helpers/api/bonuses/premium-documents/hooks";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { handleFileLoad } from "@/helpers/utils";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { FC, useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BonusesPremiumView: FC = (props) => {
  const params = useParams();
  const documentId = params.id as string;
  const search = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);
  const { createNotificationError } = useNotification();

  const { data: documentResponse } = useGetDocument({
    id: documentId,
  });

  const { mutateAsync: exportDocuments } = useExportDocuments();

  const { data: quarterResponse, isLoading: isQuarterLoading } = useGetQuarter({
    quarter: selectedQuarter,
    year: selectedYear,
  });

  const isDocumentsGenerated = !!quarterResponse?.data?.isDocumentsGenerated;
  const quarterExists = !!quarterResponse?.data;
  const quartedClosedAt = quarterResponse?.data?.closedAt;

  //@ts-ignore
  const documentData = documentResponse?.data;
  //@ts-ignore
  const fileUrl = documentData?.file ? documentData?.file.fileUrl : null;
  const { t } = useTranslation();
  const handleExportDocuments = () => {
    exportDocuments(
      { quarter: selectedQuarter, year: selectedYear, ids: [documentId] },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "documents.xlsx");
        },
      }
    );
  };

  const handleExportPdf = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "document.pdf";
    link.click();
  };

  useEffect(() => {
    //@ts-ignore
    if (documentData?.quarter) {
      setSearchParams({
        //@ts-ignore
        quarter: documentData?.quarter.quarter,
        //@ts-ignore
        year: documentData?.quarter.year,
      });
    }
  }, [documentData]);

  return (
    <DashboardLayout
      toBack={`/bonuses/premium/${search}`}
      title={t("bonuses.premium.title_L") || ""}
      description={t("bonuses.premium.description") || ""}
    >
      <PremiumHeader
        quarterExists={quarterExists}
        quartedClosedAt={quartedClosedAt}
        isQuarterLoading={isQuarterLoading}
        isActGenerated={isDocumentsGenerated}
        fileExists={!!fileUrl}
        handleExport={handleExportDocuments}
        downloadPdf={handleExportPdf}
      />
      {fileUrl ? (
        <PremiumPdfViewer fileUrl={fileUrl} />
      ) : (
        <StyledFileNotFoundParagraph>
          Документ не найден
        </StyledFileNotFoundParagraph>
      )}
    </DashboardLayout>
  );
};
