import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TAuditCreatePlanRequest,
  TAuditCreatePlanResponse,
  TAuditCreateTaskRequest,
  TAuditCreateTaskResponse,
  TAuditPlanSetStatusRequest,
  TAuditPlanSetStatusResponse,
  TAuditUpdateTaskRequest,
  TAuditUpdateTaskResponse,
  TCompleteAuditPlanRequest,
  TCompleteAuditPlanResponse,
  TDealerSendAuditPlanRequest,
  TDealerSendAuditPlanResponse,
  TGetAuditsResponsibleRequest,
  TGetAuditsResponsibleResponse,
  TGetAuditsTasksDealerRequest,
  TGetAuditsTasksDealerResponse,
  TGetAuditsTasksKiaRequest,
  TGetAuditsTasksKiaResponse,
} from "./types";
import { AxiosError } from "axios";

export const useGetAuditTasksKia = (
  request: TGetAuditsTasksKiaRequest,
  isDealerKia: boolean
) => {
  return useQuery(["audit-plan", "tasks-kia", request, isDealerKia], () => {
    return api.get<TGetAuditsTasksKiaResponse>(
      `/audits/tasks/${isDealerKia ? "kia" : "dealer"}`,
      {
        params: request,
      }
    );
  });
};

export const useGetAuditResponsible = (
  request: TGetAuditsResponsibleRequest
) => {
  return useQuery(["audit-responsible", request], () => {
    return api.get<TGetAuditsResponsibleResponse>(`/audits/tasks/responsible`, {
      params: request,
    });
  });
};

export const useGetAuditTasksDealer = (
  request: TGetAuditsTasksDealerRequest
) => {
  return useQuery(["audit-plan", "tasks-dealer", request], () => {
    return api.get<TGetAuditsTasksDealerResponse>(`/audits/tasks/dealer`, {
      params: request,
    });
  });
};

export const useAuditCreatePlan = (request: TAuditCreatePlanRequest) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      onSuccess,
      onError,
    }: {
      onSuccess: (resp: TAuditCreatePlanResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TAuditCreatePlanResponse>(
        "/audits/tasks/start-creation",
        { auditId: request.auditId }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
          queryClient.invalidateQueries([
            "audit-plan",
            "tasks-dealer",
            request.auditId,
          ]);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useAuditCreateTask = (request: TAuditCreateTaskRequest) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      onSuccess,
      onError,
    }: {
      onSuccess: (resp: TAuditCreateTaskResponse, auditId?: number) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TAuditCreateTaskResponse>("/audits/tasks", {
        auditId: request.auditId,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp?.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const usePatchTask = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
      onSuccess,
      onError,
    }: {
      id: TAuditUpdateTaskRequest["id"];
      patch: TAuditUpdateTaskRequest["patch"];
      onSuccess: (resp: TAuditUpdateTaskResponse) => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TAuditUpdateTaskResponse>(`/audits/tasks/${id}`, {
        ...patch,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: { id: number }) => {
      return api.delete<TAuditUpdateTaskResponse>(`/audits/tasks/${id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "audit-plan",
          "tasks-kia",
          _?.data.auditId,
        ]);
      },
    }
  );
};

export const useDealerSendAuditPlan = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: TDealerSendAuditPlanRequest;
      onSuccess: (resp: TDealerSendAuditPlanResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TDealerSendAuditPlanResponse>("/audits/tasks/publish", {
        auditId: id,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
          queryClient.invalidateQueries([
            "audit-plan",
            "tasks-dealer",
            resp.data,
          ]);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCompleteAuditPlan = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: TCompleteAuditPlanRequest;
      onSuccess: (resp: TCompleteAuditPlanResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TCompleteAuditPlanResponse>("/audits/tasks/complete", {
        auditId: id,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useAuditPlanSetStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
      onSuccess,
      onError,
    }: {
      id: TAuditPlanSetStatusRequest["id"];
      patch: TAuditPlanSetStatusRequest["patch"];
      onSuccess: (resp: TAuditPlanSetStatusResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TAuditPlanSetStatusResponse>(
        `/audits/tasks/${id}/status`,
        {
          ...patch,
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
