import {
  TPermissionResourcesName,
} from "@/helpers/api/eventLogs/types";
import type { TPermissionAction } from "@/helpers/interfaces";
import { useProfile } from "@/hooks/contexts/useProfile";

export const usePermission = (
  resourceName:
    | TPermissionResourcesName
    | TPermissionResourcesName[]
    | "hide-section",
  resourceAction: TPermissionAction,
  currentGroupId?: string,
  rulesCallback?: (hasAccess: boolean) => boolean
) => {
  const {
    state: { profile, isProfileLoading },
  } = useProfile();
  const permissions = profile?.permissions || profile?.role?.permissions;

  const isAvailableDepartmentToEdit = currentGroupId
    ? profile?.role?.groupId === currentGroupId
    : true;

  const hasAccess = permissions?.some((permission) => {
    let hasAccess: boolean;
    if (!Array.isArray(resourceName)) {
      const activePermission = permission.name === resourceName && permission;

      hasAccess =
        activePermission && !!activePermission[resourceAction] && isAvailableDepartmentToEdit;
    } else {
      const activePermission =
        !!resourceName.find((item) => item === permission.name) && permission;

      hasAccess =
        activePermission && !!activePermission[resourceAction] && isAvailableDepartmentToEdit;
    }

    return rulesCallback ? rulesCallback(hasAccess) : hasAccess;
  });

  return { hasAccess: Boolean(hasAccess), isProfileLoading };
};
