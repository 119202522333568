import { Button } from "@/packages/button/Button";
import { Space } from "antd";
import React from "react";
import {
  StyledDescription,
  StyledHeader,
  StyledHeaderRight,
  StyledModelsExportDictTab,
  StyledTitle,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IModelsExportDictTab {
  onSubmit: () => void;
}

export const ModelsExportDictTab: React.FC<IModelsExportDictTab> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModelsExportDictTab>
      <StyledHeader>
          <Space align="center">
            <Button
              color="brand"
              theme="primary"
              size="middle"
              onClick={onSubmit}
            >
              {t("models.directoryExportModel.downloadGuide") || ""}
            </Button>
          </Space>
      </StyledHeader>
    </StyledModelsExportDictTab>
  );
};
