import {
  ITransmissionTypeSelectFieldAdapter,
  TransmissionTypeSelectFieldAdapter,
} from "@/adapters/selects/TransmissionTypeSelectFieldAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormTransmissionTypeSelectField
  extends Omit<
    ITransmissionTypeSelectFieldAdapter,
    "error" | "value" | "onChange"
  > {
  name: string;
}

const _FormTransmissionTypeSelectField: React.FC<
  IFormTransmissionTypeSelectField
> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <TransmissionTypeSelectFieldAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormTransmissionTypeSelectField = React.memo(
  _FormTransmissionTypeSelectField
);
