import { FC, useContext, useEffect } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormProvider } from "react-hook-form";
import { CreateGsmSuccessFormValues, useCreateSparesForm, } from "../hooks";
import { ContextMaintenanceGrids, MAINTENANCE_GRIDS, } from "@/hooks/contexts/useMaintenanceGrids";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";
import { GsmFormModal } from "@/components/MaintenanceGrids/GsmTab/GsmFormModal";

type TGsmEditModal = Pick<IModal, "onCancel">;

const SparesEditModal: FC<TGsmEditModal> = (props) => {
  const { onCancel } = props;
  const { hasAccess: hasEdit } = usePermission("maintenance-grid", "update");
  const {
    state: { activeSpare },
    actions: { updateSpare },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const onSubmitButtonClick = (data: CreateGsmSuccessFormValues) => {
    if (!activeSpare) return;

    updateSpare({
      id: activeSpare.code,
    // @ts-ignore
      data,
      onCancel,
    });
  };

  const { form, onSubmitHandler } = useCreateSparesForm(onSubmitButtonClick, true);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (!activeSpare) return;

    form.setValue("type", activeSpare.type, { shouldDirty: true });
    form.setValue("name", activeSpare.name, { shouldDirty: true });
    form.setValue("code", activeSpare.code, { shouldDirty: true });
    // @ts-ignore
    form.setValue("price", activeSpare.price, {
      shouldDirty: true,
    });
  }, [activeSpare, form]);

  return (
    <Modal
      {...props}
      title={`${hasEdit ? t("common.edit") || "" : t("common.view") || ""}
       ${t("maintenanceGrids.spare")}`}
      confirmButtonText={t("maintenanceGrids.gridsModel.spareSave") || ""}
      cancelButtonText={t("common.cancel") || ""}
      isDisabledCancelButton={!hasEdit}
      isDisabledConfirmButton={!hasEdit}
      onSubmit={() => {
        onSubmitHandler();
      }}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <GsmFormModal
          editMode={true}
          type={'spare'}
        />
      </FormProvider>
    </Modal>
  );
};

export { SparesEditModal };
