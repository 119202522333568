import { TModelCreateRecord } from "@/helpers/api/models/types";
import { TCodeListValue } from "@/packages/formElements/fields/CodeListField";
import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";

export enum ModelFormFieldsEnum {
  Name = "name",
  YearRange = "yearRange",
  ProductionYearRange = "productionYearRange",
  VinIndexes = "vinIndexes",
  VinModelClasses = "vinModelClasses",
}

interface IYearRangeInputFieldValueWithRequiredStart
  extends Omit<IYearRangeInputFieldValue, "start"> {
  start: number;
}

export interface IModelFormValues {
  [ModelFormFieldsEnum.Name]: string;
  [ModelFormFieldsEnum.YearRange]?: IYearRangeInputFieldValue;
  [ModelFormFieldsEnum.ProductionYearRange]?: IYearRangeInputFieldValue;
  [ModelFormFieldsEnum.VinIndexes]: TCodeListValue;
}

export interface IModelSuccessFormValuesExceptions {
  [ModelFormFieldsEnum.YearRange]: IYearRangeInputFieldValueWithRequiredStart;
  [ModelFormFieldsEnum.ProductionYearRange]: IYearRangeInputFieldValueWithRequiredStart;
}

export type IModelSuccessFormValues = Exclude<
  IModelFormValues,
  IModelSuccessFormValuesExceptions
> &
  IModelSuccessFormValuesExceptions;

export type IModelDTOFields = TModelCreateRecord;
