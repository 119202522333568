import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum MonitoringFilterFormFieldsNameEnum {
  GenerationIds = "generationIds",
  Titles = "titles",
  CatalogueNumbers = "catalogueNumbers",
  Status = "status",
  OnlyUnreadMessages = "onlyUnreadMessages",
}

export enum MonitoringFilterOptionsNameEnum {
  Generations = "generations",
  Titles = "titles",
  Catalogues = "catalogues",
}

export interface IMonitoringFilterOptions {
  [MonitoringFilterOptionsNameEnum.Generations]:
    | { value: string; label: string }[]
    | undefined;
  [MonitoringFilterOptionsNameEnum.Titles]:
    | { value: string; label: string }[]
    | undefined;
  [MonitoringFilterOptionsNameEnum.Catalogues]:
    | { value: string; label: string }[]
    | undefined;
}

export interface IMonitoringFilterFormValues {
  [MonitoringFilterFormFieldsNameEnum.GenerationIds]: string[] | undefined;
  [MonitoringFilterFormFieldsNameEnum.OnlyUnreadMessages]: boolean;
  [MonitoringFilterFormFieldsNameEnum.Titles]: string[] | undefined;
  [MonitoringFilterFormFieldsNameEnum.CatalogueNumbers]: string[] | undefined;
  [MonitoringFilterFormFieldsNameEnum.Status]:
    | "open"
    | "closed"
    | "search-completed"
    | undefined;
}

export const DEFAULT_MONITORING_FILTER_FORM_VALUES: IMonitoringFilterFormValues =
  {
    [MonitoringFilterFormFieldsNameEnum.GenerationIds]: [],
    [MonitoringFilterFormFieldsNameEnum.Titles]: [],
    [MonitoringFilterFormFieldsNameEnum.CatalogueNumbers]: [],
    [MonitoringFilterFormFieldsNameEnum.Status]: undefined,
    [MonitoringFilterFormFieldsNameEnum.OnlyUnreadMessages]: false,
  };

export type TOnSubmitMonitoringFilterFormCb = TOnSubmitFormSyncCb<any>;

export const useMonitoringFilterForm = (options: {
  onSubmit: TOnSubmitMonitoringFilterFormCb;
  initValues: IMonitoringFilterFormValues;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<any>({
    defaultValues: DEFAULT_MONITORING_FILTER_FORM_VALUES,
    onSubmit,
  });

  useEffect(() => {
    result.form.reset(initValues, { keepDefaultValues: true });
  }, []);

  return result;
};
