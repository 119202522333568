import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { SettingsTariffTable } from "@/components/KiaFlex/SettingsTariffs/TariffsTable";
import { useActiveTab } from "@/hooks/useActiveTab";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { FC } from "react";
import { FlexQuotas } from "./FlexQuotas";
import { useTranslation } from "react-i18next";
import { Loader } from "@/packages/loader/Loader";

const tabs: ITab[] = [
  {
    name: "kiaFlex.quotas",
    slug: "quotas",
    content: (
      <PermissionsAdapter resourceName="flex.quotas" resourceAction="read">
        <FlexQuotas />
      </PermissionsAdapter>
    ),
  },
  {
    name: "kiaFlex.rates",
    slug: "rates",
    content: (
      <PermissionsAdapter resourceName="flex.quotas" resourceAction="read">
        <SettingsTariffTable />
      </PermissionsAdapter>
    ),
  },
];

export const FlexSettings: FC = () => {
  const { activeTab, setActiveTab } = useActiveTab(tabs);

  const { t } = useTranslation();

  return (
    <DashboardLayout
      title={t("kiaFlex.subTitle_1") || ""}
      description={t("kiaFlex.subTitle_1Descr") || ""}
    >
      <PermissionsAdapter
        resourceName="flex.quotas"
        resourceAction="read"
        loader={<Loader size={48} />}
        fallback={() => <div>No access</div>}
      >
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          type="card"
          isContentBorderless={true}
          onChange={setActiveTab}
        />
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
