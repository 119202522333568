import styled from "styled-components";
import { colors } from "@/helpers";

import { NestedTable } from "@/packages/nestedTable/NestedTable";
import { Title } from "@/packages/title/Title";

export const StyledTableWrapper = styled.div`
  overflow: auto;
`;

export const StyledAutoParkTable = styled(NestedTable)`
  border: none;
`;

export const StyledAutoParkMessage = styled(Title)`
  padding: 0 0 14px 22px;
`;

export const StyledStatus = styled.span<{ backgroundColor: string }>`
  display: inline-block;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  border: 1px solid
    ${({ backgroundColor }) =>
      backgroundColor === colors.white ? colors.black : backgroundColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const StyledAutoParkButtons = styled.div`
  display: flex;
  gap: 18px;
  justify-content: flex-end;
`;
