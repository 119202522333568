import React from "react";

const DEFAULT_PAGE_LIMIT = 10;

export const usePagination = (initPaginationParams?: {
  page: number;
  limit: number;
}) => {
  const [page, setPage] = React.useState<number>(initPaginationParams?.page || 1);
  const [limit, setLimit] = React.useState<number>(
    initPaginationParams?.limit || DEFAULT_PAGE_LIMIT
  );

  const paginationParams = {
    page,
    limit,
  };

  return {
    paginationParams,
    setPage,
    setLimit,
  };
};
