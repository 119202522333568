import React from "react";
import { NavMenuItemLeaf } from "@/layouts/DashboardLayout/MainLayoutNavBar/index";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  StyledActiveChildIndicator,
  StyledDashboardLayoutSubNavItem,
} from "@/layouts/DashboardLayout/MainLayoutNavBar/index.styles";

interface ChildNavItem {
  index: number;
  navItemChild: NavMenuItemLeaf;
  navScrollPositionRef: React.MutableRefObject<number>
}

export const ChildNavItem: React.FC<ChildNavItem> = (props) => {
  const { navItemChild, index, navScrollPositionRef } = props;

  const saveNavScrollPosition = () => {
    sessionStorage.setItem('navScrollPositionRef', navScrollPositionRef.current.toString())
  }

  return (
    <PermissionsAdapter
      key={index}
      resourceName={navItemChild.permission || []}
      resourceAction="read"
    >
      <StyledDashboardLayoutSubNavItem
        onClick={saveNavScrollPosition}
        key={navItemChild.key}
        to={navItemChild.path}
      >
        {navItemChild.label}
        <StyledActiveChildIndicator/>
      </StyledDashboardLayoutSubNavItem>
    </PermissionsAdapter>
  )
}