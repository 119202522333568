import React, { useEffect, useState } from "react";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useCompleteAudit } from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import { useNotification } from "@/hooks/useNotification";
import { SimpleConfirmModal } from "@/components/SimpleConfirmModal";
import { useModal } from "@/hooks/useModal";
import {
  StyledConfirm,
  StyledConfirmContainer,
} from "@/components/Audits/FillAuditStages/FillAuditFirstStage/index.styles";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Icon } from "@/packages/icon/Icon";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { useGetWarrantyById } from "@/helpers/api/warranty-audit/hooks";
import { FillWarrantyFirstStage } from "@/components/AuditWarranty/FillWarrantyStages/FillWarrantyFirstStage";
import { useStartFillWarrantyAudit } from "@/helpers/api/warranty-audit/fill/hooks";
import { WARRANTY_AUDIT_LIST } from "@/constants/routes";
import { FillWarrantySecondStage } from "@/components/AuditWarranty/FillWarrantyStages/FillWarrantySecondStage";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const renderStage = ({
                       stageIndex,
                       warrantyData,
                       id,
                       isStarting,
                       onCompleteStage,
                       updateInfo,
                     }: {
  id: number;
  stageIndex: number;
  isStarting: boolean;
  warrantyData: TGetWarrantyByIdResponse;
  onCompleteStage: (stageIndex: number, value: any) => void;
  updateInfo: () => void;
}) => {
  return {
    0: {
      title: t("audits.list.completing") || "",
      component: (
        <FillWarrantyFirstStage
          id={id}
          data={warrantyData}
          onCompleteStage={onCompleteStage}
          isStarting={isStarting}
        />
      ),
    },
    1: {
      title: `${t("audits.list.completing") || ""}  “${warrantyData.title}”`,
      component: (
        <FillWarrantySecondStage
          id={id}
          warrantyData={warrantyData}
          updateInfo={updateInfo}
        />
      ),
    },
  }[stageIndex];
};

export const WarrantyFill: React.FC = () => {
  const navigate = useNavigate();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();
  const params = useParams();
  const auditId = Number(params?.id) as number;
  const {
    data: warrantyData,
    isLoading,
    refetch: updateAuditData,
  } = useGetWarrantyById(auditId);
  const { mutate: startFill, isLoading: isStarting } =
    useStartFillWarrantyAudit();
  const { mutate: completeAudit, isLoading: isCompletenig } =
    useCompleteAudit();
  const [stage, setStage] = useState(
    warrantyData?.data.stage !== "Не начат" ? 1 : 0
  );

  useEffect(() => {
    setStage(warrantyData?.data.stage !== "Не начат" ? 1 : 0);
  }, [warrantyData]);

  const currentStage = warrantyData
    ? renderStage({
      isStarting,
      id: auditId,
      warrantyData: warrantyData.data,
      stageIndex: stage,
      updateInfo: updateAuditData,
      onCompleteStage: (stage, value) => {
        startFill({
          id: auditId,
          patch: value,
          onSuccess: () => {
            updateAuditData();
            createNotificationSuccess('Аудит создан и направлен ответственному');
            setStage(stage);
          },
          onError: (error) => {
            createNotificationError(
              error.response?.data.message || error.message
            );
          },
        });
      },
    })
    : undefined;

  const handleCompleteAudit = () => {
    completeAudit({
      id: auditId,
      onSuccess: () => {
        updateAuditData();
        ConfirmModalOpen();
      },
    });
  };

  const {
    modalState: ConfirmModalState,
    openModal: ConfirmModalOpen,
    closeModal: ConfirmModalClose,
  } = useModal();

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={currentStage?.title}
        toBack={WARRANTY_AUDIT_LIST}
      >
        <PermissionsAdapter
          resourceName="guarantee.audits-implementation"
          resourceAction="update"
          loader={<Loader size={48}/>}
          isLoading={isLoading}
          fallback={() => <span>No rights</span>}
        >
          {!isLoading ? currentStage?.component : <Loader size={48}/>}
        </PermissionsAdapter>
      </DashboardLayout>
      <SimpleConfirmModal
        isOpen={ConfirmModalState.isOpen}
        onSubmit={() => {
          ConfirmModalClose();
          navigate(`/audit-total/${auditId}`);
        }}
        onClose={() => ({})}
        showCancelButton={false}
        confirmButtonText={t("audits.list.results") || ""}
      >
        <StyledConfirmContainer>
          <StyledConfirm>
            <Paragraph size={18}>
              {t("audits.list.auditSuccess") || ""}
            </Paragraph>
          </StyledConfirm>
          <Icon name="check" size={64}/>
        </StyledConfirmContainer>
      </SimpleConfirmModal>
    </>
  );
};
