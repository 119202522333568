import { colors } from "@/helpers";
import { Loader } from "@/packages/loader/Loader";
import { Select } from "antd";
import styled, { css } from "styled-components";

export const StyledSelect = styled(Select)<{
  isabled: boolean;
  readOnly: boolean;
  isEditable?: boolean;
}>`
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 32px;
  background-color: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  transition: all 0.5s ease;

  &:hover {
    border: 1px solid #c5c8cd !important;
  }

  & .ant-select-selector {
    padding: 0 12px 0 11px !important;
    border-color: none;
    height: 15px !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    border: none !important;
  }

  & .ant-select-selection-search {
    margin: 0;
    display: ${({ showSearch }) => (showSearch ? "block" : "none")};
  }

  & .ant-select-selection-placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px !important;
    color: #a4abb5;
  }

  & .ant-select-selection-item {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: ${({ disabled }) => (disabled ? colors.gray200 : "282a30")};
    display: flex;

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${colors.gray200};
      `}
  }

  & .ant-select-item-option:hover {
    background: #f8f9fb !important;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #282a30;
  }

  & .ant-select-item-option-active {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #487bff !important;
    background: transparent !important;
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${colors.gray5};
      cursor: default;
      border: 1px solid ${colors.gray6};
    `}

  ${({ isEditable }) =>
    !isEditable &&
    css`
      pointer-events: none;
      color: ${colors.gray500};
      background-color: ${colors.gray300};

      .ant-select-selector,
      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: inherit;
        background-color: inherit;
      }

      .ant-select-arrow {
        /* display: none; */
      }
    `}
`;

export const StyledDropdown = styled.div`
  .ant-select-item-option {
    min-height: 32px;
    color: ${colors.black};
  }

  .ant-select-item-option-active {
    background-color: ${colors.gray300};
  }

  .ant-select-item-option-selected {
    background-color: ${colors.gray3};
  }
`;

export const StyledLoader = styled(Loader)`
  width: 100%;
  margin: 10px;
`;
