import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ICountrySelect,
  CountrySelect,
} from "@/packages/formElements/fields/CountrySelect";

export type FormCountrySelectProps = {
  name: string;
  isDisabled?: boolean;
} & Omit<ICountrySelect, "error" | "value" | "onChange">;

const _FormCountrySelect: React.FC<FormCountrySelectProps> = (props) => {
  const { name, isDisabled, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <CountrySelect
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
export const FormCountrySelect = React.memo(_FormCountrySelect);
