import React, { useEffect, useState } from "react";
import { Radio as AntdRadio, RadioChangeEvent, RadioProps } from "antd";
import styled, { css } from "styled-components";
import { colors } from "@/helpers";

export interface IRadio {
  name?: string;
  label?: string;
  value?: boolean;
  isDisabled?: boolean;
  error?: string;
  groupValue?: string;
  onChange?: (value: boolean, event: RadioChangeEvent) => void;
}

interface IRadioStyled extends RadioProps {
  hasLabel: boolean;
  isError?: boolean;
}

const StyledRadio = styled(AntdRadio)<IRadioStyled>`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  ${({ hasLabel }) =>
    !hasLabel &&
    css`
      width: 16px;
      height: 16px;
    `}
  .ant-radio-checked .ant-radio-inner {
    border: 1px solid ${colors.gray300};
  }
  .ant-radio-checked .ant-radio-inner:hover {
    border: 1px solid ${colors.gray400};
  }
  .ant-radio-inner {
    border: 1px solid ${colors.gray300};
  }
  .ant-radio-inner:hover {
    border: 1px solid ${colors.gray400};
  }
  .ant-radio-inner::after {
    background-color: ${colors.brand};
  }

  ${({ isError }) =>
    isError &&
    css`
      .ant-radio-inner {
        border: 1px solid ${colors.red200};
      }
      .ant-radio-checked .ant-radio-inner {
        border: 1px solid ${colors.red200};
      }
      .ant-radio-inner::after {
        background-color: ${colors.red200};
      }
    `}
`;

const WrappedRadio = styled.div`
  width: fit-content;
  :focus-visible {
    outline: 1px solid ${colors.brand};
    border-radius: 4px;
  }
`;

const StyledError = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${colors.red200};
`;

const Radio: React.FC<IRadio> = (props) => {
  const { name, groupValue, label, value, isDisabled, error, onChange } = props;

  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <WrappedRadio>
      <StyledRadio
        value={groupValue}
        hasLabel={!!label}
        name={name}
        checked={isChecked}
        disabled={isDisabled}
        isError={!!error}
        onChange={(event) => {
          setIsChecked(event.target.checked);
          onChange && onChange(event.target.checked, event);
        }}
      >
        {label}
      </StyledRadio>
      {label && error ? <StyledError>{error}</StyledError> : <></>}
    </WrappedRadio>
  );
};

export { Radio };
