import React from "react";
import { ILogsTable, LogsTable } from "@/components/logs/LogsTable";
import { Pagination } from "@/packages/pagination/Pagination";
import { StyledContent, StyledSearchWrapper } from "./index.styles";
import { Loader } from "@/packages/loader/Loader";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { TEventLogRecord } from "@/helpers/api/eventLogs/types";
import { Search } from "@/packages/formElements/inputs/search/Search";

export interface ILogsTableAdapter extends ILogsTable {
  isLoading: boolean;
  paginationParams: { page: number; limit: number };
  setPage: (page: number) => void;
  hasPagination?: boolean;
  headers?: IHeaderItem<TEventLogRecord>[];
  setRequestParams?: React.Dispatch<any>;
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
}

export const LogsTableAdapter: React.FC<ILogsTableAdapter> = (props) => {
  const { isLoading, paginationParams, setPage, hasPagination, searchValue, setSearchValue } = props;

  return (
    <StyledContent>
      {!isLoading ? (
        <>
          {
            searchValue && setSearchValue &&
            <StyledSearchWrapper>
              {
              setSearchValue &&
              <Search
                value={searchValue}
                onChange={(value) => setSearchValue && setSearchValue(value ?? '')}
              />
              }
            </StyledSearchWrapper>
          }
          <LogsTable {...props} />
          {hasPagination ? (
            <Pagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={1}
              total={paginationParams.limit}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <Loader size={48}/>
      )}
    </StyledContent>
  );
};
