import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringFilter } from "@/components/MonitoringQuality/MonitoringFilter";
import { IMonitoringFilterFormValues } from "@/components/MonitoringQuality/MonitoringFilter/hooks";
import { MonitoringTable } from "@/components/MonitoringQuality/MonitoringTable";
import {
  useGetMonitoringQuality,
  useGetMonitoringQualityFiltersCatalogues,
  useGetMonitoringQualityFiltersGenerations,
  useGetMonitoringQualityFiltersTitles,
} from "@/helpers/api/monitoringQuality/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Loader } from "@/packages/loader/Loader";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { BooleanParam, createEnumParam, NumberParam, StringParam, useQueryParams } from "use-query-params";
import { StyledPagination } from "./index.styles";
import { useTranslation } from "react-i18next";

export const MonitoringQuality: React.FC = () => {
  const { paginationParams, setPage } = usePagination();
  const navigate = useNavigate();
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { t } = useTranslation();

  const { data: filtersGenerations, refetch: filtersGenerationsRefetch } =
    useGetMonitoringQualityFiltersGenerations();

  const { data: filtersTitles, refetch: filtersTitlesRefetch } =
    useGetMonitoringQualityFiltersTitles();

  const {
    data: filtersCatalogueNumbers,
    refetch: filtersCatalogueNumbersRefetch,
  } = useGetMonitoringQualityFiltersCatalogues();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    generationIds: StringParam,
    titles: StringParam,
    catalogueNumbers: StringParam,
    onlyUnreadMessages: BooleanParam,
    status: createEnumParam(["open", "search-completed", "closed"]),
    page: NumberParam,
  });

  useEffect(() => {
    const numberPage = Number(filterQueryParams.page);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setFilterQueryParams({
      ...filterQueryParams,
      page: paginationParams.page
    })
  }, [paginationParams.page])

  const [sortParams, setSortParams] = useState({});

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      sortBy: header.id,
      orderBy: order?.toUpperCase(),
    }));
  };

  const filters: IMonitoringFilterFormValues = {
    generationIds: filterQueryParams.generationIds
      ? filterQueryParams.generationIds.split(",")
      : undefined,
    titles: filterQueryParams.titles
      ? filterQueryParams.titles.split(",")
      : undefined,
    catalogueNumbers: filterQueryParams.catalogueNumbers
      ? filterQueryParams.catalogueNumbers.split(",")
      : undefined,
    status: filterQueryParams.status || undefined,
    onlyUnreadMessages: filterQueryParams.onlyUnreadMessages || false,
  };

  const {
    data: monitoringQualityData,
    isLoading,
    refetch: updateList,
  } = useGetMonitoringQuality({
    ...paginationParams,
    ...sortParams,
    ...filters,
  });

  const filtersGenerationsOptions = useMemo(() => {
    if (filtersGenerations?.data) {
      //@ts-ignore
      return filtersGenerations.data.data.map((generation) => ({
        value: generation.id,
        label: `${generation.fullModelName} `,
      }));
    }
  }, [filtersGenerations]);

  const filtersTitlesOptions = useMemo(() => {
    if (filtersTitles?.data) {
      //@ts-ignore
      return filtersTitles.data.data.map((title) => ({
        value: title,
        label: title,
      }));
    }
  }, [filtersTitles]);

  const filtersCatalogueNumbersOptions = useMemo(() => {
    if (filtersCatalogueNumbers?.data) {
      //@ts-ignore
      return filtersCatalogueNumbers.data.data.map((catalogue) => ({
        value: catalogue,
        label: catalogue,
      }));
    }
  }, [filtersCatalogueNumbers]);

  const filterOptions = useMemo(() => {
    const filters = {
      generations: filtersGenerationsOptions,
      titles: filtersTitlesOptions,
      catalogues: filtersCatalogueNumbersOptions,
    };
    return filters;
  }, [
    filtersGenerationsOptions,
    filtersTitlesOptions,
    filtersCatalogueNumbersOptions,
  ]);

  const clickHandler = useCallback(() => {
    navigate("/monitoring-quality/create-program");
  }, [navigate]);

  const monitoringQualityList = monitoringQualityData?.data.data;

  // useEffect(() => {
  //   setPage(1);
  // }, [filterQueryParams]);

  useEffect(() => {
    filtersGenerationsRefetch();
    filtersTitlesRefetch();
    filtersCatalogueNumbersRefetch();
  }, []);

  return (
    <DashboardLayout
      title={t("monitoring.title") || ""}
      headerRight={
        <>
          {profile?.type === "kia" && (
            <Button
              color="brand"
              size="middle"
              theme="primary"
              onClick={clickHandler}
            >
              {t("monitoring.addNew") || ""}
            </Button>
          )}
        </>
      }
    >
      <PermissionsAdapter
        resourceName="quality-monitoring"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48}/>}
      >
        <MonitoringFilter
          initValues={filters}
          filterOptions={filterOptions}
          onFiltersChange={setFilterQueryParams}
          resetPage={() => setPage(1)}
        />
        {isLoading ? (
          <Loader size={48}/>
        ) : (
          <div>
            <MonitoringTable
              monitoringQualityList={monitoringQualityList}
              onSort={handleSort}
              updateList={updateList}
            />
            <StyledPagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={10}
              total={monitoringQualityData?.data.meta?.itemCount || 0}
            />
          </div>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
