import {
  BodyTypeTabAdapter,
  IBodyTypeTabAdapter,
} from "@/adapters/parts/BodyTypeTabAdapter";
import {
  EngineTypeTabAdapter,
  IEngineTypeTabAdapter,
} from "@/adapters/parts/EngineTypeTabAdapter";
import {
  EngineVolumeTabAdapter,
  IEngineVolumeTabAdapter,
} from "@/adapters/parts/EngineVolumeTabAdapter";
import {
  FuelTypeTabAdapter,
  IFuelTypeTabAdapter,
} from "@/adapters/parts/FuelTypeTabAdapter";
import {
  ITransmissionTypeTabAdapter,
  TransmissionTypeTabAdapter,
} from "@/adapters/parts/TransmissionTypeTabAdapter";
import { BodyTypeCreateModal } from "@/components/parts/bodyType/BodyTypeCreateModal";
import {
  BodyTypeTypeEditModal,
  TBodyTypeEditModalSubmitPayload,
} from "@/components/parts/bodyType/BodyTypeEditModal";
import { BodyTypeFooter } from "@/components/parts/bodyType/BodyTypeFooter";
import { EngineTypeCreateModal } from "@/components/parts/engineType/EngineTypeCreateModal";
import {
  EngineTypeEditModal,
  TEngineTypeEditModalSubmitPayload,
} from "@/components/parts/engineType/EngineTypeEditModal";
import { EngineTypeFooter } from "@/components/parts/engineType/EngineTypeFooter";
import { EngineVolumeCreateModal } from "@/components/parts/engineVolume/EngineVolumeCreateModal";
import {
  EngineVolumeEditModal,
  TEngineVolumeEditModalSubmitPayload,
} from "@/components/parts/engineVolume/EngineVolumeEditModal";
import { EngineVolumeFooter } from "@/components/parts/engineVolume/EngineVolumeFooter";
import { FuelCreateModal } from "@/components/parts/fuelType/FuelCraeteModal";
import {
  FuelEditModal,
  TFuelEditModalSubmitPayload,
} from "@/components/parts/fuelType/FuelEditModel";
import { FuelFooter } from "@/components/parts/fuelType/FuelFooter";
import { TransmissionCreateModal } from "@/components/parts/transmission/TransmissionCraeteModel";
import {
  TransmissionEditModal,
  TTransmissionEditModalSubmitPayload,
} from "@/components/parts/transmission/TransmissionEditModel";
import { TransmissionFooter } from "@/components/parts/transmission/TransmissionFooter";
import {
  useCreateBody,
  useDeleteBody,
  usePutBody,
} from "@/helpers/api/bodies/hooks";
import { TBodyRecord } from "@/helpers/api/bodies/types";
import {
  useCreateEngine,
  useDeleteEngine,
  usePutEngine,
} from "@/helpers/api/engines/hooks";
import { TEngineRecord } from "@/helpers/api/engines/types";
import {
  useCreateEngineVolume,
  useDeleteEngineVolume,
  usePutEngineVolume,
} from "@/helpers/api/engineVolumes/hooks";
import { TEngineVolumeFromList, TEngineVolumeRecord } from "@/helpers/api/engineVolumes/types";
import {
  useCreateFuel,
  useDeleteFuel,
  usePutFuel,
} from "@/helpers/api/fuel/hooks";
import { TFuelRecord } from "@/helpers/api/fuel/types";
import {
  useCreateTransmission,
  useDeleteTransmission,
  usePutTransmission,
} from "@/helpers/api/transmissions/hooks";
import { TTransmissionRecord } from "@/helpers/api/transmissions/types";
import { useActiveTab } from "@/hooks/useActiveTab";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import React from "react";
import { useModal } from "@/hooks/useModal";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

export const TRANSMISSION_INITIAL_FORM = {
  transmissionType: "",
  mpv: "",
};
export type TTransmissionForm = typeof TRANSMISSION_INITIAL_FORM;

export const FUEL_INITIAL_FORM = {
  fuelType: "",
  mpv: "",
};
export type TFuelForm = typeof FUEL_INITIAL_FORM;

export const ENGINE_TYPE_INITIAL_FORM = {
  engineType: "",
  mpv: "",
};
export type TEngineTypeForm = typeof ENGINE_TYPE_INITIAL_FORM;

export const BODY_INITIAL_FORM = {
  bodyType: "",
  mpv: "",
};
export type TBodyForm = typeof BODY_INITIAL_FORM;

export const ENGINE_VOLUME_INITIAL_FORM = {
  engineVolume: 0,
  mpv: "",
};
export type TEngineVolumeForm = typeof ENGINE_VOLUME_INITIAL_FORM;

export const Parts: React.FC = () => {
  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { t } = useTranslation();

  //TRANSMISSION ======================================

  const {
    mutateAsync: createTransmissionAsync,
    isLoading: isLoadingCreateTransmission,
  } = useCreateTransmission();
  const {
    mutateAsync: putTransmissionAsync,
    isLoading: isLoadingPutTransmission,
  } = usePutTransmission();
  const { mutateAsync: deleteTransmissionAsync } = useDeleteTransmission();
  const onDeleteTransmissionHandler: ITransmissionTypeTabAdapter["onDeleteClick"] =
    async (record) => {
      try {
        await deleteTransmissionAsync({
          id: record.id,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessDelete") || ""
        );
      } catch {
        createNotificationError(t("parts.createNotificationErrorDelete") || "");
      }
    };

  const {
    modalState: transmissionCreateModalState,
    openModal: openTransmissionCreateModalState,
  } = useModal<TTransmissionForm>((close) => {
    return async (data) => {
      try {
        await createTransmissionAsync({
          name: data.transmissionType,
          mpv: data.mpv,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessCreate") || ""
        );
        close();
      } catch {
        createNotificationError(t("parts.createNotificationErrorCreate") || "");
      }
    };
  });

  const {
    modalState: transmissionEditModalState,
    openModal: openTransmissionEditModalState,
  } = useModal<
    TTransmissionEditModalSubmitPayload,
    { record: TTransmissionRecord }
  >((close) => {
    return async ({ record, data }) => {
      try {
        await putTransmissionAsync({
          id: record.id,
          put: {
            name: data.transmissionType,
            mpv: data.mpv,
          },
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessChange") || ""
        );
        close();
      } catch {
        createNotificationError(t("parts.createNotificationErrorChange") || "");
      }
    };
  });

  //FUEL ======================================

  const { mutateAsync: createFuelAsync, isLoading: isLoadingCreateFuel } =
    useCreateFuel();
  const { mutateAsync: putFuelAsync, isLoading: isLoadingPutFuel } =
    usePutFuel();
  const { mutateAsync: deleteFuelAsync } = useDeleteFuel();

  const onDeleteFuelHandler: IFuelTypeTabAdapter["onDeleteClick"] = async (
    record
  ) => {
    try {
      await deleteFuelAsync({
        id: record.id,
      });
      createNotificationSuccess(
        t("parts.createNotificationSuccessFuelDelete") || ""
      );
    } catch {
      createNotificationError(
        t("parts.createNotificationErrorFuelDelete") || ""
      );
    }
  };
  const {
    modalState: fuelCreateModalState,
    openModal: openFuelCreateModalState,
  } = useModal<TFuelForm>((close) => {
    return async (data) => {
      try {
        await createFuelAsync({
          name: data.fuelType,
          mpv: data.mpv,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessFuelCreate") || ""
        );
        close();
      } catch {
        createNotificationError(
          t("parts.createNotificationErrorFuelCreate") || ""
        );
      }
    };
  });

  const { modalState: fuelEditModalState, openModal: openFuelEditModalState } =
    useModal<TFuelEditModalSubmitPayload, { record: TFuelRecord }>((close) => {
      return async ({ data, record }) => {
        try {
          await putFuelAsync({
            id: record.id,
            put: {
              name: data.fuelType,
              mpv: data.mpv,
            },
          });
          createNotificationSuccess(
            t("parts.createNotificationSuccessFuelChange") || ""
          );
          close();
        } catch {
          createNotificationError(
            t("parts.createNotificationErrorFuelChange") || ""
          );
        }
      };
    });

  //ENGINE TYPE ======================================

  const { mutateAsync: createEngineAsync, isLoading: isLoadingCreateEngine } =
    useCreateEngine();
  const { mutateAsync: putEngineAsync, isLoading: isLoadingPutEngine } =
    usePutEngine();
  const { mutateAsync: deleteEngineAsync } = useDeleteEngine();

  const onDeleteEngineHandler: IEngineTypeTabAdapter["onDeleteClick"] = async (
    record
  ) => {
    try {
      await deleteEngineAsync({
        id: record.id,
      });
      createNotificationSuccess(
        t("parts.createNotificationSuccessEngineDelete") || ""
      );
    } catch {
      createNotificationError(
        t("parts.createNotificationErrorEngineDelete") || ""
      );
    }
  };

  const {
    modalState: engineCreateModalState,
    openModal: openEngineCreateModalState,
  } = useModal<TEngineTypeForm>((close) => {
    return async (data) => {
      try {
        await createEngineAsync({
          name: data.engineType,
          mpv: data.mpv,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessEngineCreate") || ""
        );
        close();
      } catch {
        createNotificationError(
          t("parts.createNotificationErrorEngineCreate") || ""
        );
      }
    };
  });

  const {
    modalState: engineEditModalState,
    openModal: openEngineEditModalState,
  } = useModal<TEngineTypeEditModalSubmitPayload, { record: TEngineRecord }>(
    (close) => {
      return async ({ data, record }) => {
        try {
          await putEngineAsync({
            id: record.id,
            put: {
              name: data.engineType,
              mpv: data.mpv,
            },
          });
          createNotificationSuccess(
            t("parts.createNotificationSuccessEngineChange") || ""
          );
          close();
        } catch {
          createNotificationError(
            t("parts.createNotificationErrorEngineChange") || ""
          );
        }
      };
    }
  );

  //BODY TYPE ======================================

  const { mutateAsync: createBodyAsync, isLoading: isLoadingCreateBody } =
    useCreateBody();
  const { mutateAsync: putBodyAsync, isLoading: isLoadingPutBody } =
    usePutBody();
  const { mutateAsync: deleteBodyAsync } = useDeleteBody();

  const onDeleteBodyTypeHandler: IBodyTypeTabAdapter["onDeleteClick"] = async (
    record
  ) => {
    try {
      await deleteBodyAsync({
        id: record.id,
      });
      createNotificationSuccess(
        t("parts.createNotificationSuccessCarcaseDelete") || ""
      );
    } catch {
      createNotificationError(
        t("parts.createNotificationErrorCarcaseDelete") || ""
      );
    }
  };

  const {
    modalState: bodyTypeCreateModalState,
    openModal: openBodyTypeCreateModalState,
  } = useModal<TBodyForm>((close) => {
    return async (data) => {
      try {
        await createBodyAsync({
          name: data.bodyType,
          mpv: data.mpv,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessCarcaseCreate") || ""
        );
        close();
      } catch (error) {
        const errorTyped = error as AxiosError<{
          message: string | string[];
        }>;
        if (
          errorTyped.response?.data.message ||
          errorTyped.message === "MPV already exists"
        ) {
          createNotificationError(
            t("parts.createNotificationErrorAddNewMPV") || ""
          );
        } else {
          createNotificationError(
            t("parts.createNotificationErrorCarcaseCreate") || ""
          );
        }
      }
    };
  });

  const {
    modalState: bodyTypeEditModalState,
    openModal: openBodyTypeEditModalState,
  } = useModal<TBodyTypeEditModalSubmitPayload, { record: TBodyRecord }>(
    (close) => {
      return async ({ data, record }) => {
        try {
          await putBodyAsync({
            id: record.id,
            put: {
              name: data.bodyType,
              mpv: data.mpv,
            },
          });
          createNotificationSuccess(
            t("parts.createNotificationSuccessCarcaseChange") || ""
          );
          close();
        } catch {
          createNotificationError(
            t("parts.createNotificationErrorCarcaseChange") || ""
          );
        }
      };
    }
  );

  //ENGINE VOLUME ======================================

  const {
    mutateAsync: createEngineVolumeAsync,
    isLoading: isLoadingCreateEngineVolume,
  } = useCreateEngineVolume();
  const {
    mutateAsync: putEngineVolumeAsync,
    isLoading: isLoadingPutEngineVolume,
  } = usePutEngineVolume();
  const { mutateAsync: deleteEngineVolumeAsync } = useDeleteEngineVolume();

  const onDeleteEngineVolumeHandler: IEngineVolumeTabAdapter["onDeleteClick"] =
    async (record) => {
      try {
        await deleteEngineVolumeAsync({
          id: record.id,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessEngineCapacityDelete") || ""
        );
      } catch {
        createNotificationError(
          t("parts.createNotificationErrorEngineCapacityDelete") || ""
        );
      }
    };

  const {
    modalState: engineVolumeTypeCreateModalState,
    openModal: openEngineVolumeCreateModalState,
  } = useModal<TEngineVolumeForm>((close) => {
    return async (data) => {
      try {
        await createEngineVolumeAsync({
          volume: data.engineVolume,
          mpv: data.mpv,
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessEngineCapacityCreate") || ""
        );
        close();
      } catch {
        createNotificationError(
          t("parts.createNotificationErrorEngineCapacityCreate") || ""
        );
      }
    };
  });

  const {
    modalState: engineVolumeTypeEditModalState,
    openModal: openEngineVolumeEditModalState,
  } = useModal<
    TEngineVolumeEditModalSubmitPayload,
    { record: TEngineVolumeFromList }
  >((close) => {
    return async ({ data, record }) => {
      try {
        await putEngineVolumeAsync({
          id: record.id,
          put: {
            volume: data.engineVolume,
            mpv: data.mpv,
          },
        });
        createNotificationSuccess(
          t("parts.createNotificationSuccessEngineCapacityChange") || ""
        );
        close();
      } catch {
        createNotificationError(
          t("parts.createNotificationErrorEngineCapacityChange") || ""
        );
      }
    };
  });

  const tabs: ITab[] = [
    {
      name: t("parts.carcaseTab") || "",
      slug: "body-type",
      content: (
        <BodyTypeTabAdapter
          onEditClick={(record) => openBodyTypeEditModalState({ record })}
          onDeleteClick={onDeleteBodyTypeHandler}
        />
      ),
    },
    {
      name: t("parts.engineCapacityTab") || "",
      slug: "engine-volume",
      content: (
        <EngineVolumeTabAdapter
          onDeleteClick={onDeleteEngineVolumeHandler}
          onEditClick={(record) => openEngineVolumeEditModalState({ record })}
        />
      ),
    },
    {
      name: t("parts.engineTab") || "",
      slug: "engine-type",
      content: (
        <EngineTypeTabAdapter
          onEditClick={(record) => openEngineEditModalState({ record })}
          onDeleteClick={onDeleteEngineHandler}
        />
      ),
    },
    {
      name: t("parts.fuelTab") || "",
      slug: "fuel-type",
      content: (
        <FuelTypeTabAdapter
          onEditClick={(record) => openFuelEditModalState({ record })}
          onDeleteClick={onDeleteFuelHandler}
        />
      ),
    },
    {
      name: t("parts.transmissionTab") || "",
      slug: "transmission-type",
      content: (
        <TransmissionTypeTabAdapter
          onEditClick={(record) => openTransmissionEditModalState({ record })}
          onDeleteClick={onDeleteTransmissionHandler}
        />
      ),
    },
  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  return (
    <DashboardLayout title={t("parts.title") || ""}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
        type="card"
      />

      {activeTab.slug === "transmission-type" ? (
        <TransmissionFooter onCreate={openTransmissionCreateModalState} />
      ) : (
        <></>
      )}

      {activeTab.slug === "fuel-type" ? (
        <FuelFooter onCreate={openFuelCreateModalState} />
      ) : (
        <></>
      )}

      {activeTab.slug === "engine-type" ? (
        <EngineTypeFooter onCreate={openEngineCreateModalState} />
      ) : (
        <></>
      )}

      {activeTab.slug === "body-type" ? (
        <BodyTypeFooter onCreate={openBodyTypeCreateModalState} />
      ) : (
        <></>
      )}

      {activeTab.slug === "engine-volume" ? (
        <EngineVolumeFooter onCreate={openEngineVolumeCreateModalState} />
      ) : (
        <></>
      )}

      <TransmissionCreateModal
        {...transmissionCreateModalState}
        isLoadingSubmit={isLoadingCreateTransmission}
      />
      <TransmissionEditModal
        {...transmissionEditModalState}
        isLoadingSubmit={isLoadingPutTransmission}
      />
      <FuelCreateModal
        {...fuelCreateModalState}
        isLoadingSubmit={isLoadingCreateFuel}
      />
      <FuelEditModal
        {...fuelEditModalState}
        isLoadingSubmit={isLoadingPutFuel}
      />
      <EngineTypeCreateModal
        {...engineCreateModalState}
        isLoadingSubmit={isLoadingCreateEngine}
      />
      <EngineTypeEditModal
        {...engineEditModalState}
        isLoadingSubmit={isLoadingPutEngine}
      />
      <BodyTypeCreateModal
        {...bodyTypeCreateModalState}
        isLoadingSubmit={isLoadingCreateBody}
      />
      <BodyTypeTypeEditModal
        {...bodyTypeEditModalState}
        isLoadingSubmit={isLoadingPutBody}
      />
      <EngineVolumeCreateModal
        {...engineVolumeTypeCreateModalState}
        isLoadingSubmit={isLoadingCreateEngineVolume}
      />
      <EngineVolumeEditModal
        {...engineVolumeTypeEditModalState}
        isLoadingSubmit={isLoadingPutEngineVolume}
      />
    </DashboardLayout>
  );
};
