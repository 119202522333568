export enum InformationLettersDataFormFieldsEnum {
  Name = "name",
  Number = "number",
  RoleIds = "roleIds",
  Description = "description",
  FileIds = "fileIds",
}

type TExtendedInformationLettersDataValues = {
  [InformationLettersDataFormFieldsEnum.Name]: string | null;
  [InformationLettersDataFormFieldsEnum.Number]: string | null;
  [InformationLettersDataFormFieldsEnum.RoleIds]: string[] | null;
  [InformationLettersDataFormFieldsEnum.Description]: string | null;
  [InformationLettersDataFormFieldsEnum.FileIds]: string[] | null;
};

type TInformationLettersDataFormSelector = Exclude<
  InformationLettersDataFormFieldsEnum,
  keyof TExtendedInformationLettersDataValues
>;

export type TInformationLettersDataFieldsValues = Record<
  TInformationLettersDataFormSelector,
  string
> &
  TExtendedInformationLettersDataValues;

export type TInformationLettersDataFieldsSuccessValues = Omit<
  TInformationLettersDataFieldsValues,
  | InformationLettersDataFormFieldsEnum.Name
  | InformationLettersDataFormFieldsEnum.Number
  | InformationLettersDataFormFieldsEnum.RoleIds
  | InformationLettersDataFormFieldsEnum.Description
  | InformationLettersDataFormFieldsEnum.FileIds
> & {
  [InformationLettersDataFormFieldsEnum.Name]: string;
  [InformationLettersDataFormFieldsEnum.Number]: string;
  [InformationLettersDataFormFieldsEnum.RoleIds]: string[];
  [InformationLettersDataFormFieldsEnum.Description]: string;
  [InformationLettersDataFormFieldsEnum.FileIds]: string[];
};
