import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TTransmissionCreateRequest,
  TTransmissionCreateResponse,
  TTransmissionDeleteRequest,
  TTransmissionDeleteResponse,
  TTransmissionPutRequest,
  TTransmissionPutResponse,
  TGetTransmissionByIdRequest,
  TGetTransmissionByIdResponse,
  TGetTransmissionListRequest,
  TGetTransmissionListResponse,
} from "./types";

export const useGetTransmissionList = (
  request: TGetTransmissionListRequest
) => {
  return useQuery(["transmissions", "list", request], () => {
    return api.get<TGetTransmissionListResponse>("/transmissions", {
      params: request,
    });
  });
};

export const useGetTransmissionById = (
  request: TGetTransmissionByIdRequest
) => {
  return useQuery(["transmissions", "detail", request.id], () => {
    return api.get<TGetTransmissionByIdResponse>(
      `/transmissions/${request.id}`
    );
  });
};

export const useCreateTransmission = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TTransmissionCreateRequest) => {
      return api.post<TTransmissionCreateResponse>("/transmissions", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transmissions", "list"]);
      },
    }
  );
};

export const usePutTransmission = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TTransmissionPutRequest) => {
      return api.put<TTransmissionPutResponse>(`/transmissions/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["transmissions", "list"]);
        queryClient.invalidateQueries([
          "transmissions",
          "detail",
          variables.id,
        ]);
      },
    }
  );
};

export const useDeleteTransmission = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TTransmissionDeleteRequest) => {
      return api.delete<TTransmissionDeleteResponse>(
        `/transmissions/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["transmissions", "list"]);
        queryClient.invalidateQueries([
          "transmissions",
          "detail",
          variables.id,
        ]);
      },
    }
  );
};
