export const useControlledForm = <T extends object = object>(options: {
  values: T;
  onChange: (values: T) => void;
}) => {
  const { values, onChange } = options;

  const onChangeFieldHandlerCreator = <K extends keyof T>(name: K) => {
    return (value: T[K]) => {
      const newFormState = {
        ...values,
        [name]: value,
      } as T;

      onChange(newFormState);
    };
  };

  return {
    onChangeFieldHandlerCreator,
  };
};
