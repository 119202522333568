import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";

export const useGetModels = () => {
  return useQuery(["test-drives", "models"], () => {
    return api.get<any>("/test-drives/models");
  });
};

export const useGetCalendar = (request: any) => {
  return useQuery(["test-drives", "calendar", request], () => {
    return api.get<any>("/test-drives/requests/calendar", {
      params: request,
    });
  });
};

export const useGetAuthors = () => {
  return useQuery(["test-drives", "authors"], () => {
    return api.get<any>("/test-drives/requests/authors");
  });
};

export const useGetStatus = (request: any) => {
  return useQuery(["test-drives", "status", request], () => {
    return api.get<any>(`/test-drives/statuses/${request.id}`);
  });
};

export const useGetStatuses = (request: any) => {
  return useQuery(["test-drives", "statuses", request], () => {
    return api.get<any>("/test-drives/statuses", {
      params: request,
    });
  });
};

export const usePatchStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: any) => {
      return api.patch<any>(
        `/test-drives/statuses/${request.id}`,
        request.patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test-drives", "statuses"]);
      },
    }
  );
};

export const useCreateStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) => {
      return api.post<any>("/test-drives/statuses", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test-drives", "statuses"]);
      },
    }
  );
};

export const useDeleteStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: any) => {
      return api.delete<any>(`/test-drives/statuses/${request.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test-drives", "statuses"]);
      },
    }
  );
};

export const useExportCalendar = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/test-drives/requests/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
