import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TChangeTaskStatusRequest,
  TChangeTaskStatusResponse,
  TGetAuditTasksKiaRequest,
  TGetAuditTasksKiaResponse,
  TGetAuditTasksRequest,
  TGetAuditTasksResponse,
  TSendTaskToReviewRequest,
  TSendTaskToReviewResponse,
} from "./types";

export const useGetAuditTasks = (
  request: TGetAuditTasksRequest | TGetAuditTasksKiaRequest,
  isKia: boolean
) => {
  return useQuery(["audit", "list", request, isKia], () => {
    return api.get<TGetAuditTasksResponse | TGetAuditTasksKiaResponse>(
      `/audits/tasks/${isKia ? "kia" : "dealer"}`,
      {
        params: request,
      }
    );
  });
};

export const useChangeTaskStatus = () => {
  return useMutation(
    ({
      data,
      onSuccess,
    }: {
      data: TChangeTaskStatusRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TChangeTaskStatusResponse>(
        `/audits/tasks/${data.id}/status`,
        { status: data.data }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useTaskToReview = () => {
  return useMutation(
    ({
      id,
      isCancel,
      onSuccess,
    }: {
      id: TSendTaskToReviewRequest;
      isCancel: boolean;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TSendTaskToReviewResponse>(
        `/audits/tasks/${id}/${isCancel ? "cancel-sending" : "send-for-review"}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
