import { FC, useEffect, useState } from "react";
import { TitleDivider } from "@/packages/TitleDivider";
import {
  StyledCheckboxes,
  StyledForm,
  StyledFormList,
  StyledGridsFormTop,
} from "@/components/MaintenanceGrids/index.styles";
import { RadioCard } from "@/packages/radio-card";
import { GridsFormModalForm } from "./GridsFormModalForm";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

enum FormRadioType {
  DUPLICATE = "duplicate",
  DEFAULT = "default",
}

const GridsFormModal: FC = () => {
  const form = useFormContext();
  const { t } = useTranslation();

  const [activeRadioType, setActiveRadioType] = useState<FormRadioType | null>(
    null
  );

  const isDuplicateType = activeRadioType === FormRadioType.DUPLICATE;

  useEffect(() => () => form.reset(), []);

  return (
    <>
      <StyledGridsFormTop>
        <TitleDivider size="medium">
          {t("maintenanceGrids.newGridForm.model") || ""}
        </TitleDivider>
        <StyledFormList>
          <GridsFormModalForm />
        </StyledFormList>
      </StyledGridsFormTop>

      <TitleDivider size="medium">
        {t("maintenanceGrids.newGridForm.grid") || ""}
      </TitleDivider>

      <StyledForm>
        <RadioCard
          title={t("maintenanceGrids.newGridForm.duplicateTitle") || ""}
          subtitle={t("maintenanceGrids.newGridForm.duplicateSubtitle") || ""}
          radioName="option"
          radioValue={isDuplicateType}
          onRadioChange={() => {
            setActiveRadioType(FormRadioType.DUPLICATE);
            form.setValue("isWorksDefault", false);
            form.setValue("isGsmDefault", false);
          }}
        />
        {activeRadioType && isDuplicateType ? (
          <GridsFormModalForm isDuplicateForm={true} />
        ) : (
          <></>
        )}

        <RadioCard
          title={t("maintenanceGrids.newGridForm.gsmAddTitle") || ""}
          subtitle={t("maintenanceGrids.newGridForm.gsmAddSubTitle") || ""}
          radioName="option"
          radioValue={Boolean(activeRadioType) && !isDuplicateType}
          onRadioChange={() => {
            setActiveRadioType(FormRadioType.DEFAULT);
            form.setValue("modelDuplicate", null);
          }}
        />
        {activeRadioType && !isDuplicateType ? (
          <StyledCheckboxes>
            <FormCheckbox
              name="isWorksDefault"
              label={t("maintenanceGrids.newGridForm.worksByDefault") || ""}
            />
            <FormCheckbox
              name="isGsmDefault"
              label={t("maintenanceGrids.newGridForm.gsmByDefault") || ""}
            />
          </StyledCheckboxes>
        ) : (
          <></>
        )}
      </StyledForm>
    </>
  );
};

export { GridsFormModal };
