export const getSelectedYearAndQuarter = (search) => {
  const preselectedQuarter = new URLSearchParams(search).get("quarter");
  const preselectedYear = new URLSearchParams(search).get("year");

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math?.floor(currentMonth / 3 + 1);
  const prevQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;

  const selectedQuarter = preselectedQuarter || prevQuarter;
  const selectedYear =
    preselectedYear ||
    (!preselectedQuarter && prevQuarter === 4 ? currentYear - 1 : currentYear);

  return [Number(selectedYear), Number(selectedQuarter)];
};
