import * as yup from "yup";
import { ExistCarFieldsName } from "./type";
import { t } from "i18next";

export const existFormSchema = () => {
  return yup.object({
    [ExistCarFieldsName.OWNER]: yup
      .string()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),
    [ExistCarFieldsName.C2C]: yup.string().nullable(),
    [ExistCarFieldsName.VIN]: yup
      .string()
      .min(17, t("kiaFlex.vin_17") || "")
      .max(17, t("kiaFlex.vin_17") || "")
      .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
      .uppercase()
      .nullable(),
    [ExistCarFieldsName.MATERAL_CODE]: yup
      .string()
      .min(9, t("kiaFlex.material_9") || "")
      .max(9, t("kiaFlex.material_9") || "")
      .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
      .nullable(),
    [ExistCarFieldsName.MODEL]: yup
      .string()
      .uuid()
      .nullable()
      .required(t("kiaFlex.fieldRequired") || ""),
    [ExistCarFieldsName.GENERATION]: yup
      .string()
      .uuid()
      .nullable()
      .required(t("kiaFlex.fieldRequired") || ""),
    [ExistCarFieldsName.MODIFICATION]: yup
      .string()
      .uuid()
      .nullable()
      .required(t("kiaFlex.fieldRequired") || ""),
    [ExistCarFieldsName.EQUIPMENT]: yup
      .string()
      .uuid()
      .nullable()
      .required(t("kiaFlex.fieldRequired") || ""),
    [ExistCarFieldsName.COLOR]: yup
      .string()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),
    [ExistCarFieldsName.PTS]: yup
      .string()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),
    [ExistCarFieldsName.REGISTER_NUMBER]: yup
      .string()
      .required(t("kiaFlex.fieldRequired") || "")
      .test("len", t("kiaFlex.regNumberMin") || "", (value) => {
        const clearValue = value?.replace(/_/g, "");
        return clearValue ? clearValue.length > 7 : true;
      })
      .nullable(),
    [ExistCarFieldsName.DEALER_CENTER]: yup
      .string()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),
    fromK: yup
      .date()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),

    toK: yup
      .date()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),

    fromO: yup
      .date()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),

    toO: yup
      .date()
      .required(t("kiaFlex.fieldRequired") || "")
      .nullable(),
  });
};
