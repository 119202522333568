import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IChartWrap {
  title: string;
  description: string;
  children: JSX.Element;
  isBig?: boolean;
}

const StyledBox = styled.div`
  height: 500px;
  background: ${colors.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px 12px;
`;

const StyledTop = styled.div`
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 19px;
`;

const StyledChart = styled.div<{ isBig: boolean }>`
  height: ${({ isBig }) => (isBig ? "485px" : "376px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartWrap: React.FC<IChartWrap> = (props) => {
  const { t } = useTranslation();
  const { title, description, children, isBig = false } = props;
  return (
    <StyledBox>
      <StyledTop>
        <Title level={2}>{t(title) || ""}</Title>
        <Paragraph size={12}>{t(description) || ""}</Paragraph>
      </StyledTop>
      <StyledChart isBig={isBig}>{children}</StyledChart>
    </StyledBox>
  );
};
