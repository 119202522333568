import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledPdfViewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 40px;
  background-color: ${colors.white};
`;

export const StyledFileNotFoundParagraph = styled(Paragraph)`
  margin-top: 16px;
  text-align: center;
  width: 100%;
`;
