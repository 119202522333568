import { TFlexIdleRecord } from "@/helpers/api/flex/downtime/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import * as yup from "yup";
import { t } from "i18next";

export interface TDowntimeValues {
  name: string;
}

const DEFAULT_TARIFFS_FORM_VALUES: TDowntimeValues = {
  name: "",
};

const formSchema = () => {
  return yup.object({
    name: yup
      .string()
      .min(5, t("kiaFlex.min_5") || "")
      .max(40, t("kiaFlex.max_40") || ""),
  });
};

export const useCreateDowntimeForm = (onSubmit: any) => {
  const onSubmitHandler = (values: TDowntimeValues) => {
    onSubmit(values);
  };
  return useForm({
    defaultValues: DEFAULT_TARIFFS_FORM_VALUES,
    onSubmit: onSubmitHandler,
    validateFormSchema: formSchema(),
  });
};

export const useEditDowntimeForm = (options: {
  onSubmit: any;
  record: TFlexIdleRecord | undefined;
}) => {
  const { onSubmit, record } = options;

  const onSubmitHandler = (values: TDowntimeValues) => {
    onSubmit({ id: record?.id, put: values });
  };
  const result = useForm({
    defaultValues: DEFAULT_TARIFFS_FORM_VALUES,
    onSubmit: onSubmitHandler,
    validateFormSchema: formSchema(),
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (record) {
      reset({ name: record.name });
    }
  }, [record, reset]);

  return result;
};
