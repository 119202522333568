import React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { ITemplateValues } from "@/components/AuditWarranty/Templates/ViolationEditTemplateModal/hooks";
import {
  StyledModalContentWrapper
} from "@/components/AuditWarranty/Templates/ViolationCreateTemplateModal/index.styles";
import { t } from "i18next";

export interface ViolationModalFieldsProps {
  form: UseFormReturn<ITemplateValues, any>;
}

export const ViolationModalFields: React.FC<
  ViolationModalFieldsProps
> = (props) => {
  const { form } = props;

  return (
    <FormProvider {...form}>
      <StyledModalContentWrapper>
        <FormInputField
          name="code"
          label={t("warrantyAudits.violation.code") || ""}
          placeholder="Введите код нарушения"
        />
        <FormInputField
          name="pointAmount"
          label={t("warrantyAudits.violation.pointAmount") || ""}
          placeholder="Введите баллы нарушения"
        />
        <FormInputField
          name="description"
          label={t("warrantyAudits.violation.description") || ""}
          placeholder="Введите описание"
        />
      </StyledModalContentWrapper>
    </FormProvider>
  );
};