import { TableSimple } from "@/packages/tableSimple/TableSimple";
import styled, { css } from "styled-components";

export const StyledTable = styled(TableSimple)`
  ${() => css`
    && .cJWgEU {
      background-color: white !important;
      border-top-right-radius: none !important;
    }
    && .jbOPMQ {
      border-top: none;
      background-color: white !important;
    }
  `}
`;

export const TableHeader = styled.div`
  background-color: white;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
