import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import styled, { css } from "styled-components";

export const StyledTable = styled(TableSimple)`
  ${css`
    && .th {
      background-color: white !important;
    }
    && .cfrySN:last-of-type {
      border-top-right-radius: 0;
    }
  `}
`;

export const StyledSpace = styled(Space)`
  border-radius: 12px;
`;
