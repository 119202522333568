import { TextAreaProps } from "antd/lib/input/TextArea";
import React from "react";
import { StyledTextArea } from "./index.styled";

export interface ITextArea extends Omit<TextAreaProps, "onChange"> {
  onChange: (value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
}

export const TextArea: React.FC<ITextArea> = ({
  onChange,
  error = false,
  ...rest
}) => {
  const onChangeHandler:
    | React.ChangeEventHandler<HTMLTextAreaElement>
    | undefined = onChange
    ? (e) => {
        onChange(e.target.value, e);
      }
    : undefined;

  return (
    <StyledTextArea
      {...rest}
      onChange={onChangeHandler}
      error={error}
      autoSize={{ minRows: 1 }}
    />
  );
};
