import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import React from "react";
import {
  StyledStatsTab,
  StyledTable,
  StyledTableDataCell,
  StyledTableHeaderCell,
  StyledTableHeaderRow,
  StyledTableHeaderText,
  StyledTableRow,
  StyledTitle,
} from "./index.styled";
import { useTranslation } from "react-i18next";

export interface IDetailRegistryPhotosTab {
  dealer: TDealerRecord;
}

export const DetailRegistryStatsTab: React.FC<
  IDetailRegistryPhotosTab
> = () => {
  const { t } = useTranslation();
  return (
    <StyledStatsTab>
      <StyledTitle level={5} weight={400}>
        {t("registers.registersDC.plan") || ""}
      </StyledTitle>

      <StyledTable>
        <StyledTableHeaderRow>
          <StyledTableHeaderCell>
            <StyledTableHeaderText size={12} color="gray200">
              {t("registers.registersDC.taskType") || ""}
            </StyledTableHeaderText>
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>
            <StyledTableHeaderText size={12} color="gray200">
              {t("registers.registersDC.quantity") || ""}
            </StyledTableHeaderText>
          </StyledTableHeaderCell>
        </StyledTableHeaderRow>

        <StyledTableRow>
          <StyledTableDataCell>
            {t("registers.registersDC.activeTasks") || ""}
          </StyledTableDataCell>
          <StyledTableDataCell>0</StyledTableDataCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableDataCell>
            {t("registers.registersDC.failedTasks") || ""}
          </StyledTableDataCell>
          <StyledTableDataCell>203</StyledTableDataCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableDataCell>
            {t("registers.registersDC.completedTasks") || ""}
          </StyledTableDataCell>
          <StyledTableDataCell>335</StyledTableDataCell>
        </StyledTableRow>
      </StyledTable>
    </StyledStatsTab>
  );
};
