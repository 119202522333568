import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledPage = styled.section``;

export const StyledPageHeader = styled.header``;

export const StyledChangePasswordForm = styled.form`
  max-width: 388px;
`;

export const StyledFormBox = styled.div`
  padding: 24px;
  width: 100%;
  background-color: ${colors.white};
`;

export const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledFormAction = styled.div`
  margin-top: 32px;
`;
