import { Space } from "antd";
import React from "react";

import { Button } from "@/packages/button/Button";
import { FormProvider } from "react-hook-form";
import { InformationalLettersDataFields } from "../InformationLettersDataFields";
// import { TOnSubmitIPCreateFormCb, useCreateIPForm } from "../InformationLettersEditData/hooks"
import { TOnSubmitIPCreateFormCb, useCreateIPForm } from "./hooks";

export interface InformationLettersCreateDataProps {
  onCreate: TOnSubmitIPCreateFormCb;
}

export const InformationLettersCreateData: React.FC<
  InformationLettersCreateDataProps
> = ({ onCreate }) => {
  const { form, submit, isSubmitLoading, reset } = useCreateIPForm(onCreate);
  const handleRemove = (id?: string) => {
    console.log("id    ", id);
  };

  return (
    <div>
      <FormProvider {...form}>
        <InformationalLettersDataFields
          mode="create"
          handleRemove={handleRemove}
        />
      </FormProvider>
      <Space>
        <Button
          theme="primary"
          color="brand"
          size="middle"
          onClick={submit}
          isDisabled={isSubmitLoading}
        >
          Опубликовать
        </Button>
      </Space>
    </div>
  );
};
