import React, { useContext, useMemo, useState } from "react";
import {
  StyledCheckboxesWrapper,
  StyledCheckboxWrapper,
  StyledFormSelect,
} from "@/components/MaintenanceGrids/ExportTab/index.styles";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { useGetGenerationListWithoutModelId } from "@/helpers/api/generations/hooks";

const FirstExportType = () => {
  const { data: generations } = useGetGenerationListWithoutModelId(
    {
      onlyActive: true,
      limit: 1000,
      orderBy: 'ASC',
      sortBy: 'modelName'
    }
  );

  const {
    state: { works, gsmList },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const [worksCheckbox, setWorksCheckbox] = useState<boolean>(false);
  const [gsmCheckbox, setGsmCheckbox] = useState<boolean>(false);

  const preparedGenerationsList = useMemo(
    () =>
      generations?.data?.data?.map((item) => ({
        label: `${item.fullModelName} (${item.generationYearStart}${item.generationYearEnd ? ('-' + item.generationYearEnd) : ''})`,
        value: item.id,
      })) || [],
    [generations?.data]
  );

  const preparedWorksList = useMemo(
    () =>
      works?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [works]
  );
  const preparedGSMList = useMemo(
    () =>
      gsmList?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [gsmList]
  );

  return (
    <>
      <StyledFormSelect
        name={"generation"}
        label={"Модель"}
        placeholder={"Введите или выберите модель из списка"}
        isSearchShow={true}
        options={preparedGenerationsList}
        allowClear
        mode={"multiple"}
      />
      <Paragraph size={14} tag="label" color="black">
        {"Категория работ"}
      </Paragraph>
      <StyledCheckboxesWrapper>
        <StyledCheckboxWrapper>
          <FormCheckbox
            label={"Работы"}
            name="worksCheckbox"
            isDisabled={false}
            readOnly={false}
            onChange={(value) => setWorksCheckbox(value)}
            value={worksCheckbox}
          />
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper>
          <FormCheckbox
            label={"ГСМ"}
            name="gsmCheckbox"
            isDisabled={false}
            readOnly={false}
            onChange={(value) => setGsmCheckbox(value)}
            value={gsmCheckbox}
          />
        </StyledCheckboxWrapper>
      </StyledCheckboxesWrapper>
      { worksCheckbox  &&
        <StyledFormSelect
          name={"work"}
          label={"Тип работ"}
          placeholder={"Введите или выберите тип работ из списка"}
          isSearchShow={true}
          options={preparedWorksList}
          allowClear
          mode={"multiple"}
        />
      }
      { gsmCheckbox &&
        <StyledFormSelect
          name={"gsm"}
          label={"ГСМ"}
          placeholder={"Введите или выберите ГСМ из списка"}
          isSearchShow={true}
          options={preparedGSMList}
          allowClear
          mode={"multiple"}
        />
      }
    </>
  );
};
export { FirstExportType };
