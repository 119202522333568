import { ButtonText } from "@/packages/buttonText/ButtonText";
import { IconButton } from "@/packages/icon-button/IconButton";
import styled from "styled-components";


export const StyledButtonText = styled(ButtonText)`
display: flex;
align-self: flex-start;
padding-left: 5px;
`

export const StyledIconButton = styled(IconButton).attrs({icon: "file", color: "white"})`
margin: 0 auto;
`
export const StyledLink= styled.a`
margin: 0 auto;
`