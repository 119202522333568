import { boolStringToBool } from "@/helpers/boolStringUtils";
import { StringBoolean } from "@/helpers/types";
import { ISelect, Select } from "@/packages/formElements/inputs/Select";
import { FC } from "react";
import { Field } from "../Field";
import { t } from "i18next";
import {useTranslation} from "react-i18next";

export type ISelectBooleanField = Omit<
  ISelect,
  "options" | "value" | "onChange"
> & {
  value?: boolean;
  onChange?: (value?: boolean) => void;
  label: string;
  error?: string;
  description?: string;
  trueLabel?: string;
  falseLabel?: string;
};

const getOptions = (options: { trueLabel?: string; falseLabel?: string }) => {
  const { trueLabel, falseLabel } = options;

  return [
    { label: trueLabel || t("common.yes") || "", value: "true" },
    { label: falseLabel || t("common.no") || "", value: "false" },
  ];
};

export const SelectBooleanField: FC<ISelectBooleanField> = (props) => {
  const {
    label,
    error,
    description,
    value,
    onChange,
    trueLabel,
    falseLabel,
    readOnly,
    ...selectProps
  } = props;
	const { t } = useTranslation();
  const stringValue = value === undefined ? undefined : value.toString();

  const onChangeHandler = onChange
    ? (value?: StringBoolean) => {
        onChange(value ? boolStringToBool(value) : undefined);
      }
    : undefined;

  return (
    <Field>
      <Field.Label readOnly={readOnly} disabled={selectProps.disabled}>
        {label}
      </Field.Label>
      <Field.FieldControlContainer>
        <Select
          {...selectProps}
          readOnly={readOnly}
          value={stringValue}
          onChange={onChangeHandler}
          options={getOptions({ trueLabel, falseLabel })}
        />
      </Field.FieldControlContainer>

      {error && <Field.FieldError>{error}</Field.FieldError>}
      {description && (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      )}
    </Field>
  );
};
