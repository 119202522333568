import styled from "styled-components";

import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Icon } from "@/packages/icon/Icon";

import { IModal, Modal } from "@/packages/modal/Modal";

const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;

const StyledModalContentMessage = styled(Paragraph)`
  text-align: center;
  max-width: 450px;
`;

export const ErrorModal: React.FC<IModal> = (props) => {
  const { children } = props;

  return (
    <Modal {...props}>
      <StyledModalContentWrapper>
        <Icon name="error" size={54} />
        <StyledModalContentMessage size={18}>
          {children}
        </StyledModalContentMessage>
      </StyledModalContentWrapper>
    </Modal>
  );
};
