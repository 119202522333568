import { formatBytes } from "@/components/informationalLetters/utils";
import { accessTokenService } from "@/helpers/api/accessTokenService";
import { useDeleteFile } from "@/helpers/api/files/hooks";
import { TFileRecord } from "@/helpers/api/files/types";
import { useNotification } from "@/hooks/useNotification";
import { DraggerProps } from "antd/lib/upload/Dragger";

import React, { ChangeEvent, memo, useCallback, useEffect, useId, useMemo, useState, } from "react";
import { Icon } from "../icon/Icon";
import FileContainer from "./FileContainer";
import {
  CommentFieldWrapper,
  StyledFileListWrpapper,
  StyledIconDropzone,
  StyledInputDrop,
  StyledPlaceholder,
  StyledUpload,
  StyledWraperDragger,
} from "./index.styles";
import { t } from "i18next";
import { StyledCommentArea } from "@/components/Applications/ApplicationsNewForm/index.styles";
import { RcFile } from "antd/lib/upload/interface";

export enum UploadType {
  IMAGE = "image",
  FILE = "file",
  UNIVERSAL = "universal",
  XLS = "xls",
}

export interface IFileAndImageDropwallField {
  path: string;
  fieldName?: string;
  value?: TFileRecord[] | null;
  onChange?: (uploadResponse: TFileRecord[] | any | null) => void;
  onRemove?: (id?: string) => void;
  placeholder?: React.ReactNode;
  maxCount?: number;
  isDisabled?: boolean;
  typeInfo?: UploadType;
  isWithCustomPlaceholderTitle?: boolean;
  isCentered?: boolean;
  variantBtn?: "plus" | "contained";
  iconDelete?: boolean;
  minWidth?: number;
  minHeight?: number;
  isUpload?: boolean;
  allowedTypesProps?: Array<string>;
  withCommentField?: boolean;
  isMultiple?: boolean;
}

const allowedTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/tiff",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const getUploadFileUrl = (path: string) =>
  `${process.env.REACT_APP_BASE_URL}/files?path=${path}`;

const accessToken = accessTokenService.getToken();

const getIconType = (type: UploadType): string => {
  switch (type) {
    case UploadType.IMAGE: {
      return "dropzone-icon-image";
    }
    case UploadType.FILE: {
      return "dropzone-icon-file";
    }
    case UploadType.UNIVERSAL: {
      return "file";
    }
    case UploadType.XLS: {
      return "file-xls";
    }
    default: {
      return "";
    }
  }
};

const getTitleType = (type: UploadType): string => {
  switch (type) {
    case UploadType.IMAGE: {
      return t("common.photo") || "";
    }
    case UploadType.FILE: {
      return t("common.file") || "";
    }
    case UploadType.UNIVERSAL: {
      return t("common.file") || "";
    }
    default: {
      return "";
    }
  }
};

async function getImgSize(imgSrc: string, check: { w: number; h: number }) {
  const newImg = new Image();
  newImg.src = imgSrc;

  return new Promise((resolve) => {
    newImg.onload = () => {
      const width = newImg.width;
      if (check.w < width) {
        resolve(true);
      }

      resolve(false);
    };
  });
}

export const _UploadImageAndFileDropzone: React.FC<
  IFileAndImageDropwallField
> = (props) => {
  const {
    path,
    value,
    onChange,
    placeholder,
    onRemove,
    maxCount,
    isDisabled,
    isWithCustomPlaceholderTitle = true,
    typeInfo = UploadType.IMAGE,
    variantBtn = "plus",
    iconDelete = true,
    minWidth = 0,
    minHeight = 0,
    isUpload = true,
    allowedTypesProps,
    withCommentField = false,
    isMultiple = false,
  } = props;

  const { createNotificationError } = useNotification();
  const [fileList, setFileList] = useState<TFileRecord[]>([]);
  const [removeList, setRemoveList] = useState<string[]>([]);
  const { mutateAsync: deleteFile } = useDeleteFile();
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const key: string = useId();
  const getAction: string = useMemo(() => {
    return getUploadFileUrl(path);
  }, [path]);

  const isTypeAllowed = (file: RcFile) => {
    return allowedTypesProps && allowedTypesProps?.length > 0
      ? allowedTypesProps.includes(file.type)
      : allowedTypes.includes(file.type);
  }

  const isDoneStatus = (file) => {
    return file.status === 'done'
  }


  const onBeforeUploadHandler: DraggerProps["beforeUpload"] = useCallback((file: RcFile) => {
      if (!isTypeAllowed(file)) {
        createNotificationError(
          "Файл с данным расширением не доступен для загрузки"
        );
      }
    },
    [createNotificationError]
  );

  const handleRemoveI = (id?: string) => {
    id &&
    setRemoveList((prev) =>
      [...prev.filter((item) => item !== id), id]
    );
  };

  const onChangeHandler = useCallback(
    async (info) => {
      if (onChange && info.file.status === "done") {
        if (info?.fileList && Array.isArray(info.fileList)) {
          const elements = info.fileList.map(
            (el) => {
              if (isTypeAllowed(el) && isDoneStatus(el)) {
                return el.response.data[0]
              }
            }
          ).filter((el) => el !== undefined) as TFileRecord[];
          // @ts-ignore
          const uniqueData = [...value, ...elements].filter(
            (item, index, self) => {
              return index === self.findIndex((t) => t.id === item.id);
            }
          );
          const removeIds = uniqueData.filter(
            (item) => !removeList.includes(item.id)
          );
          onChange(removeIds);
          setFileList(removeIds);
        } else {
          const element = info.file.response.data[0];

          const isSizeApproved = await getImgSize(element.fileUrl, {
            w: minWidth,
            h: minHeight,
          });

          if (isSizeApproved) {
            // @ts-ignore
            value ? onChange([...value, element]) : onChange([element]);
            setFileList((prev) => [...prev, element]);
          } else {
            createNotificationError("Фото не подходит по размеру");
            await deleteFile(element?.id);
          }
        }
      }
    },
    [onChange, value, removeList, onRemove]
  );

  useEffect(() => {
    value && setFileList(value);
  }, [value]);

  return (
    <div style={{ margin: "0 auto" }}>
      <StyledWraperDragger withCommentField={withCommentField}>
        {fileList?.map((item, index) =>
          withCommentField ? (
            <StyledFileListWrpapper key={item.id}>
              <FileContainer
                isDisabled={removeList.includes(item.id)}
                name={item.fileName?.slice(0, 10)}
                size={
                  formatBytes(item.size) +
                  " " +
                  item.extension?.slice(1, item.extension.length).toUpperCase()
                }
                iconDelete={iconDelete}
                onRemove={(id) => {
                  onRemove && onRemove(id);
                  handleRemoveI(id);
                }}
                path={item?.fileUrl}
                id={item.id}
                extension={item?.extension}
              />
              {
                !removeList.includes(item.id) &&
                <CommentFieldWrapper>
                  <StyledCommentArea
                    tpClaims={true}
                    name={`${props.fieldName}.descriptions.${index}`}
                    label={t("bonuses.calculations.descriptionCoeff") || ""}
                    disabled={isDisabled}
                  />
                </CommentFieldWrapper>
              }
            </StyledFileListWrpapper>
          ) : (
            <FileContainer
              key={item.id}
              isDisabled={removeList.includes(item.id)}
              name={item.fileName?.slice(0, 10)}
              size={
                formatBytes(item.size) +
                " " +
                item?.extension?.slice(1, item.extension.length).toUpperCase()
              }
              iconDelete={iconDelete}
              onRemove={(id) => {
                onRemove && onRemove(id);
                handleRemoveI(id);
              }}
              path={item?.fileUrl}
              id={item.id}
              extension={item?.extension}
            />
          )
        )}
        {!isUpload ? (
          <StyledInputDrop variantBtn={variantBtn} htmlFor={key}>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange && onChange(e);
                const files = e.target.files;
                setInputPlaceholder(files ? files[0].name : "");
              }}
              // @ts-ignore
              disabled={fileList.length - removeList.length >= maxCount}
              name=""
              type="file"
              id={key}
              hidden
            />
            {!isDisabled && (
              <>
                {variantBtn === "plus" ? (
                  <Icon name={"plus"} size={54} color="brand"/>
                ) : (
                  <>
                    <StyledIconDropzone
                      isWithCustomPlaceholderTitle={
                        isWithCustomPlaceholderTitle
                      }
                    >
                      <Icon name={getIconType(typeInfo)} size={24}/>
                    </StyledIconDropzone>
                    {isWithCustomPlaceholderTitle ? (
                      <StyledPlaceholder>
                        {inputPlaceholder
                          ? inputPlaceholder
                          : placeholder ||
                          `${
                            t(
                              "models.addNewGenerationModel.photoField.drag"
                            ) || ""
                          } ${getTitleType(typeInfo)} ${
                            t("models.addNewGenerationModel.photoField.or") ||
                            ""
                          } ${
                            t(
                              "models.addNewGenerationModel.photoField.choose"
                            ) || ""
                          }`}
                      </StyledPlaceholder>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </StyledInputDrop>
        ) : (
          <StyledUpload
            action={getAction}
            multiple={isMultiple}
            name="files"
            headers={{ Authorization: `Bearer ${accessToken}` }}
            onChange={onChangeHandler}
            beforeUpload={onBeforeUploadHandler}
            listType="picture-card"
            // @ts-ignore
            disabled={fileList.length - removeList.length >= maxCount}
            // maxCount={maxCount}
            showUploadList={false}
            variantBtn={variantBtn}
            accept={allowedTypesProps?.join(', ')}
          >
            {!isDisabled && (
              <>
                {variantBtn === "plus" ? (
                  <Icon name={"plus"} size={54} color="brand"/>
                ) : (
                  <>
                    <StyledIconDropzone
                      isWithCustomPlaceholderTitle={
                        isWithCustomPlaceholderTitle
                      }
                    >
                      <Icon name={getIconType(typeInfo)} size={24}/>
                    </StyledIconDropzone>
                    {isWithCustomPlaceholderTitle ? (
                      <StyledPlaceholder>
                        {placeholder ||
                          `${
                            t("models.addNewGenerationModel.photoField.drag") ||
                            ""
                          } ${getTitleType(typeInfo)} ${
                            t("models.addNewGenerationModel.photoField.or") ||
                            ""
                          } ${
                            t(
                              "models.addNewGenerationModel.photoField.choose"
                            ) || ""
                          }`}
                      </StyledPlaceholder>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </StyledUpload>
        )}
      </StyledWraperDragger>
    </div>
  );
};

export const UploadImageAndFileDropzone = memo(_UploadImageAndFileDropzone);
