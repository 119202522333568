import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { TResOptions } from "@/pages/TransportComplaints";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { ITransportComFilterFormValues, TOnSubmitTransportComFilterFormCb, useTransportComFilterForm, } from "./hooks";
import { StyledButton, StyledColDate, StyledDash } from "./index.styles";
import { useTranslation } from "react-i18next";
import { preparedDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";

export interface ITransportComplaintsFilter {
  onFilterChange: TOnSubmitTransportComFilterFormCb;
  initValues: Partial<ITransportComFilterFormValues>;
  resOptions?: TResOptions;
}

export const holdingRoles = [
  'holding-direktor',
  'holding-tekhnicheskij-direktor',
  'holding-rop',
  'holding-rukovoditel-otdela-marketinga',
  'holding-rks',
  'holding-rukovoditel-otdela-ozch',
  'holding-servis-menedzher',
  'holding-specialist-po-garantii',
  'holding-rukovoditel-otdela-logistiki',
];

const TransportComplaintsFilter: React.FC<ITransportComplaintsFilter> = (
  props
) => {
  const { onFilterChange, initValues, resOptions } = props;
  const [searchValue, setSearchValue] = useState("");
  const { data: dealerList } =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const {
    state: { profile },
  } = useProfile();

  const isDealer = profile?.type === "dealer";
  const isDistributor = profile?.role?.slug === "cis-distributor";

  const { form, submit, reset } = useTransportComFilterForm({
    initValues,
    onFilterChange,
  });
  const { t } = useTranslation();
  return (
    <>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInputField name={"vin"} label={"VIN"}/>
          </Col>
          <Col span={4}>
            <FormSelect
              name="codeCase"
              label={t("complaints.codeCase") || ""}
              options={resOptions?.codeCase}
              isSearchShow
              allowClear
            />
          </Col>
          {
            (!isDealer || isDistributor || holdingRoles.includes(profile?.role?.slug ?? '')) &&
            <Col xs={24} md={7} span={6}>
              <FormSelect
                name="placeAcceptence"
                label={t("complaints.complaintsForm.dealer") || ""}
                options={preparedDealerList(dealerList?.data?.data || [])}
                onSearch={setSearchValue}
                isSearchShow
                allowClear
              />
            </Col>
          }
        </Row>
        <Row gutter={16}>
          <StyledColDate>
            <FormDatepickerField
              name={"dateAcceptanceStart"}
              label={t("complaints.dateAcceptenceStart") || ""}
              maxDate={form.watch("dateAcceptanceStart") || undefined}
              preventOpenOnFocus
            />
            <StyledDash/>
            <FormDatepickerField
              name={"dateAcceptanceEnd"}
              maxDate={form.watch("dateAcceptanceEnd") || undefined}
              preventOpenOnFocus
            />
          </StyledColDate>
          <StyledColDate>
            <FormDatepickerField
              name={"updatedAtStart"}
              label={t("complaints.updatedAtStart") || ""}
              maxDate={form.watch("updatedAtStart") || undefined}
              preventOpenOnFocus
            />
            <StyledDash/>
            <FormDatepickerField
              name={"updatedAtEnd"}
              maxDate={form.watch("updatedAtEnd") || undefined}
              preventOpenOnFocus
            />
          </StyledColDate>
        </Row>
      </FormProvider>

      <Row gutter={16}>
        <Col>
          <StyledButton
            theme="primary"
            color="brand"
            size="middle"
            onClick={submit}
          >
            {t("audits.list.find") || ""}
          </StyledButton>
        </Col>
        <Col>
          <StyledButton
            color="brand"
            theme="secondary"
            size="middle"
            onClick={reset}
          >
            {t("audits.list.clear") || ""}
          </StyledButton>
        </Col>
      </Row>
    </>
  );
};

export default TransportComplaintsFilter;
