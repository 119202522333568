import cookie from "js-cookie";
const LOGIN_TO_TOKEN_KEY = "loginTo";

class LoginToTokenServiceTokenService {
  getToken() {
    return cookie.get(LOGIN_TO_TOKEN_KEY) || "/news";
  }

  setToken(location: string) {
    cookie.set(LOGIN_TO_TOKEN_KEY, location, {
      expires: 10000 / 60 / 60 / 24,
    });
  }

  deleteToken() {
    cookie.remove(LOGIN_TO_TOKEN_KEY);
  }
}

export const loginToTokenService = new LoginToTokenServiceTokenService();
