import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";

import TrabsportComplaintsFilter from "@/components/TransportComplaints/TransportComplaintsFilter";
import TransportComplaintsTable from "@/components/TransportComplaints/TransportComplaintsTable";
import { TPermissionResourcesName } from "@/helpers/api/eventLogs/types";
import { useExportTransportComplaintsList, useGetTransportClaimsList } from "@/helpers/api/transportComplaints/hooks";
import {
  TGetListTransportClaimsRequest,
  TGetListTransportClaimsResponse,
} from "@/helpers/api/transportComplaints/types";
import { usePagination } from "@/hooks/usePagination";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateParam, StringParam, useQueryParam, useQueryParams } from "use-query-params";
import { StyledPagination, StyledTransportComplaintsTableWrapper } from "./index.styles";
import { useTranslation } from "react-i18next";
import { Space } from "antd";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { handleFileLoad } from "@/helpers/utils";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";

export const RESOURCE_NAME_TCLAIMS: TPermissionResourcesName =
  "transport_claims";

export enum RESOURCE_ACTIONS {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
}

export interface TResOptions {
  codeCase: { value: string; label: string }[];
  placeAcceptence: { value: string; label: string }[];
}

const TransportComplaints: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const [pageQuery, setPageQuery] = useQueryParam('page', StringParam)
  const { paginationParams, setPage } = usePagination();
  useEffect(() => {
    const numberPage = Number(pageQuery);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setPageQuery(paginationParams.page?.toString())
  }, [paginationParams.page])


  const [filterParams, setFiterParams] = useQueryParams({
    vin: StringParam,
    codeCase: StringParam,
    placeAcceptence: StringParam,
    dateAcceptanceStart: DateParam,
    dateAcceptanceEnd: DateParam,
    updatedAtStart: DateParam,
    updatedAtEnd: DateParam,
    sortAt: StringParam,
  });
  const filters = {
    vin: filterParams.vin || undefined,
    codeCase: filterParams.codeCase || undefined,
    placeAcceptence: filterParams.placeAcceptence || undefined,
    dateAcceptanceStart: filterParams.dateAcceptanceStart || undefined,
    dateAcceptanceEnd: filterParams.dateAcceptanceEnd || undefined,
    updatedAtStart: filterParams.updatedAtStart || undefined,
    updatedAtEnd: filterParams.updatedAtEnd || undefined,
    sortAt: filterParams.sortAt || undefined,
  };

  const handleDateSort = (header: HeaderArgType, order: SortOrder) => {
    setFiterParams((prev) => ({
      ...prev,
      sortAt: order ? order.toUpperCase() : null,
    }));
  };

  const {
    data: transportClaimsList,
    isLoading,
    refetch,
  } = useGetTransportClaimsList({
    ...(filterParams as TGetListTransportClaimsRequest),
    ...paginationParams,
  });
  const { data: transportClaimsListToOptions } = useGetTransportClaimsList({
    limit: 0,
  });

  const transportClaimsData =
    transportClaimsList &&
    //@ts-ignore
    (transportClaimsList.data.data as TGetListTransportClaimsResponse);

  const transportClaimsDataTooptions =
    transportClaimsListToOptions &&
    //@ts-ignore
    (transportClaimsListToOptions.data.data as TGetListTransportClaimsResponse);

  const resOptions = useMemo(() => {
    if (transportClaimsDataTooptions) {
      const resObj = transportClaimsDataTooptions.reduce(
        (acc, prev) => {
          if (acc && prev.codeCase) {
            acc.codeCase.push(prev.codeCase);
          }
          if (acc && prev.dealer) {
            acc.placeAcceptence.push(prev.dealer.name);
          }
          return acc;
        },
        { codeCase: [], placeAcceptence: [] } as {
          codeCase: string[];
          placeAcceptence: string[];
        }
      );
      const codeCaseOptions = [...new Set(resObj.codeCase)].map((item) => ({
        value: item,
        label: item,
      }));

      const placeAcceptenceOptions = [...new Set(resObj.placeAcceptence)].map(
        (item) => ({ value: item, label: item })
      );

      return {
        codeCase: codeCaseOptions,
        placeAcceptence: placeAcceptenceOptions,
      };
    }
  }, [transportClaimsDataTooptions]);

  useEffect(() => {
    refetch();
  }, [transportClaimsDataTooptions]);

  const { mutateAsync: exportTransportComplaintsList } = useExportTransportComplaintsList();

  const handleClaimsExport = () => {
    exportTransportComplaintsList(
      { ...(filterParams as TGetListTransportClaimsRequest) },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "transportComplaints.xlsx");
        },
      }
    );
  };

  return (
    <DashboardLayout
      title={t("complaints.title") || ""}
      headerRight={
        <Space>
          <PermissionsAdapter
            resourceName={RESOURCE_NAME_TCLAIMS}
            resourceAction={RESOURCE_ACTIONS.READ}
          >
            <Space>
              <Tooltip content={t("common.export") || ""} placement="bottom">
                <IconButton
                  icon="save"
                  isSquared
                  variant="primary"
                  color="white"
                  size="m"
                  onClick={handleClaimsExport}
                />
              </Tooltip>
              <Tooltip content={t("common.share") || ""} placement="bottom">
                <IconButton
                  icon="share"
                  isSquared
                  variant="primary"
                  color="white"
                  size="m"
                  onClick={() => copyCurrentUrl()}
                />
              </Tooltip>
            </Space>
          </PermissionsAdapter>
          <PermissionsAdapter
            resourceName={RESOURCE_NAME_TCLAIMS}
            resourceAction={RESOURCE_ACTIONS.CREATE}
          >
            <Space>
              <Button
                color="brand"
                size="middle"
                theme="primary"
                onClick={() => navigate("/transport-claims/create")}
              >
                {t("complaints.add") || ""}
              </Button>
            </Space>
          </PermissionsAdapter>
        </Space>
      }
    >
      {!isLoading && (
        <PermissionsAdapter
          resourceName={RESOURCE_NAME_TCLAIMS}
          resourceAction={RESOURCE_ACTIONS.READ}
          fallback={() => <div>No access</div>}
        >
          <TrabsportComplaintsFilter
            onFilterChange={setFiterParams}
            initValues={filters}
            resOptions={resOptions}
          />
          <StyledTransportComplaintsTableWrapper>
            {transportClaimsList && (
              <TransportComplaintsTable
                transportClaimsList={transportClaimsData || []}
                onSort={handleDateSort}
              />
            )}
            <StyledPagination
              onChange={setPage}
              current={paginationParams.page}
              pageSize={10}
              //@ts-ignore
              total={transportClaimsList?.data.meta.itemCount || 0}
            />
          </StyledTransportComplaintsTableWrapper>
        </PermissionsAdapter>
      )}
    </DashboardLayout>
  );
};

export { TransportComplaints };
