import { colors } from "@/helpers";
import styled from "styled-components";
import { Button } from "@/packages/button/Button";

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledButton = styled(Button)`
  margin-top: 42px;
`;

export const StyledFirstColumn = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.gray20};
  padding: 20px;

  height: 100%;
  width: 25%;
`;

export const StyledSecondColumn = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  padding: 20px;
  height: 100%;
  width: 75%;
`;
