import { colors } from "@/helpers";
import { Card } from "@/packages/Card";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledHeaderWrapper = styled.div<{ textMode?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 200px;
  ${({ textMode }) => textMode && "justify-content: center;"}
`;

export const StyledTitle = styled(Paragraph)<{ textMode?: boolean }>`
  ${({ textMode }) => !textMode && "margin-bottom: 12px;"}
`;
export const StyledLineDivider = styled.span<{ textMode?: boolean }>`
  ${({ textMode }) => (textMode ? "height: 50px;" : "height: 100px")}
  align-self: center;
  background-color: ${colors.gray20};
  width: 1px;
  z-index: 10;
  margin: 0 20px;
`;
export const StyledCard = styled(Card)`
  display: flex;
  padding: 20px;
  margin-top: 16px;
`;

export const StyledBodyWrapper = styled.div`
  display: flex;
  align-items: center;
`;
