import styled from "styled-components";
import { Icon } from "@/packages/icon/Icon";
import { colors } from "@/helpers";
import { REPORT_STATUS, status2color } from ".";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledReportCard = styled.div<{
  status: REPORT_STATUS;
  isDragEnter: boolean;
}>`
  background-color: ${colors.white};
  border-radius: 4px;
  max-width: 357px;
  height: 280px;
  padding: 24px;
  position: relative;
  opacity: ${({ status }) => (status === REPORT_STATUS.Disabled ? "0.5" : "1")};
  border: ${({ isDragEnter, status }) =>
    isDragEnter && status === REPORT_STATUS.Empty
      ? `1px solid ${colors.green100}`
      : "none"};
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 35px;
  height: 100%;
`;

export const StyledName = styled(Paragraph)`
  text-align: center;
`;

export const StyledSize = styled(Paragraph)`
  color: ${colors.gray200};
  position: absolute;
  bottom: 24px;
  left: 24px;
`;

export const StyledUploadedAt = styled(Paragraph)`
  color: ${colors.gray200};
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

export const StyledFileInputLabel = styled.label`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const StyledDownloadIcon = styled(Icon)`
  cursor: pointer;
`;

export const StyledSaveIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const StyledSendIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const StyledInfoIcon = styled(Icon)``;

export const StyledInfoWrap = styled.div`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const StyledOptionsIcon = styled(Icon)`
  position: relative;
`;

export const StyledErrorText = styled(Paragraph)`
  position: absolute;
  bottom: 24px;
  max-width: 300px;
  text-align: center;
  color: ${colors.red};
`;

export const StyledReportLabel = styled.div<{ status: REPORT_STATUS }>`
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px ${({ status }) => colors[status2color[status]]} solid;
  border-radius: 10px;
  color: ${({ status }) => colors[status2color[status]]};
  min-height: 116px;
  width: 100px;
  margin-top: 10px;
`;
