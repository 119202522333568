import {
  useDeleteIdle,
  useGetIdleById,
  usePutIdle,
} from "@/helpers/api/flex/downtime/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { useCallback, useMemo, useState } from "react";
import { DowntimeModal } from "../DowntimeGuideModal";
import { StyledButtons, StyledTable } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IFlexDowntimeFuideTableProps {
  idles: { id: string; name: string }[];
}

const _FlexDowntimeFuideTable: React.FC<IFlexDowntimeFuideTableProps> = (
  props
) => {
  const { idles } = props;

  const { t } = useTranslation();

  const [idIdle, setIdIdle] = useState("");

  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { data: record } = useGetIdleById(idIdle);
  const { mutateAsync: editIdle } = usePutIdle();
  const { mutateAsync: deleteIdle } = useDeleteIdle();

  const onSubmitHandler = useCallback(
    async (values) => {
      try {
        await editIdle(values);
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessDowntime") || ""
        );
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(t("kiaFlex.createNotificationErrorDowntime") || "");
      }
    },
    [createNotificationError, createNotificationSuccess, editIdle]
  );

  const onDeleteHandler = useCallback(
    async (id) => {
      try {
        await deleteIdle({ id });
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessDelete") || ""
        );
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(t("kiaFlex.createNotificationErrorDelete") || "");
      }
    },
    [createNotificationError, createNotificationSuccess, deleteIdle]
  );

  const { modalState: editDowntimeState, openModal: openEditDowntimeModal } =
    useModal<any>((close) => {
      return async (payload) => {
        await onSubmitHandler(payload);
        close();
      };
    });

  const headers = useMemo(() => {
    const headers = [
      {
        Header: "№",
        accessor: "number",
        width: 50,
        minWidth: 50,
        Cell: (data) => {
          const number = parseInt(data.cell.row.id) + 1;
          return <Paragraph size={14}>{number}</Paragraph>;
        },
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.name",
        accessor: "name",
      },

      {
        Header: "",
        accessor: "actions",

        Cell: (data) => {
          const id = data.cell.row.id;

          return (
            <>
              <StyledButtons>
                <IconButton
                  icon="edit"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => {
                    openEditDowntimeModal();
                    setIdIdle(data.data[id].id);
                  }}
                />
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => onDeleteHandler(data.data[id].id)}
                />
              </StyledButtons>
            </>
          );
        },
      },
    ];

    return headers;
  }, [onDeleteHandler, openEditDowntimeModal]);

  return (
    <>
      <StyledTable headers={headers} data={idles} />
      <DowntimeModal record={record?.data} mode="edit" {...editDowntimeState} />
    </>
  );
};
export const FlexDowntimeFuideTable = React.memo(_FlexDowntimeFuideTable);
