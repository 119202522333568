import { useGetKiaGroupDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { FormBooleanSelectField } from "@/packages/formElements/formFields/FormBooleanSelectField";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormCountrySelect } from "@/packages/formElements/formFields/FormCountrySelect";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormFirstLastNameField } from "@/packages/formElements/formFields/FormFirstLastNameField";
import { FormHoldingSelect } from "@/packages/formElements/formFields/FormHoldingSelect";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormInputNumberField } from "@/packages/formElements/formFields/FormInputNumberField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Title } from "@/packages/title/Title";
import { Col, Divider, Row } from "antd";
import React, { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  DC_BRAND_TYPE_OPTIONS,
  DEALER_GROUP_OPTIONS,
  DEALER_TYPE_OPTIONS,
  DISTRICT_OPTIONS,
  FACADE_OPTIONS,
  FURNITURE_OPTIONS,
  INTERIOR_OPTIONS,
} from "./constants";
import {
  StyledRegistryDataFields,
  StyledSection,
  StyledMultipleInput, StyledMultipleButton, StyledCheckbox,
} from "./index.styles";
import { RegistryDataFormFieldsEnum } from "./types";
import { useTranslation } from "react-i18next";
import { IconButton } from "@/packages/icon-button/IconButton";

interface IRegistryDataFields {
  isReadOnly?: boolean;
}

const MAX_DOMAIN_INPUTS = 5;

export const RegistryDataFields: React.FC<IRegistryDataFields> = (props) => {
  const { isReadOnly = false } = props;

  const { t } = useTranslation();

  const { control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "domains",
  });

  // useEffect(() => {
  //   remove(0);
  // }, [remove])

  const { data: warrantyCuratorGroup } = useGetKiaGroupDealerList(
    {
      group: "warrantyCurator",
    },
    isReadOnly
  );
  const { data: zoneDevelopmentManagerGroup } = useGetKiaGroupDealerList(
    {
      group: "zoneDevelopmentManager",
    },
    isReadOnly
  );
  const { data: zoneSalesManagerGroup } = useGetKiaGroupDealerList(
    {
      group: "zoneSalesManager",
    },
    isReadOnly
  );
  const { data: zoneServiceManagerGroup } = useGetKiaGroupDealerList(
    {
      group: "zoneServiceManager",
    },
    isReadOnly
  );

  const warrantyCuratorGroupOptions = useMemo(
    () =>
      warrantyCuratorGroup?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    [warrantyCuratorGroup]
  );
  const zoneDevelopmentManagerGroupOptions = useMemo(
    () =>
      zoneDevelopmentManagerGroup?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),

    [zoneDevelopmentManagerGroup]
  );
  const zoneSalesManagerGroupOptions = useMemo(
    () =>
      zoneSalesManagerGroup?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),

    [zoneSalesManagerGroup]
  );
  const zoneServiceManagerGroupOptions = useMemo(
    () =>
      zoneServiceManagerGroup?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),

    [zoneServiceManagerGroup]
  );

  const checkDomainsFieldsLength = () => {
    return fields.length < MAX_DOMAIN_INPUTS;
  }

  return (
    <StyledRegistryDataFields>
      <StyledSection>
        <Title level={4} weight={400}>
          {t("registers.holdingsDC.info") || ""}
        </Title>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.LawName}
              label={t("registers.holdingsDC.LawName") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.WarrantyCode}
              label={t("registers.holdingsDC.WarrantyCode") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.INN}
              label={t("registers.holdingsDC.INN") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.NumDealerContract}
              label={t("registers.holdingsDC.NumDealerContract") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.MerchantName}
              label={t("registers.holdingsDC.MerchantName") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.MobisCode}
              label={t("registers.holdingsDC.MobisCode") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.LawAddress}
              label={t("registers.holdingsDC.LawAddress") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={3}>
            <FormSelect
              name={RegistryDataFormFieldsEnum.DealerType}
              label={t("registers.holdingsDC.DealerType") || ""}
              options={DEALER_TYPE_OPTIONS}
              readOnly={isReadOnly}
              allowClear
              isSearchShow
            />
          </Col>
          <Col span={3}>
            <FormSelect
              name={RegistryDataFormFieldsEnum.DealerGroup}
              label={t("registers.holdingsDC.DealerGroup") || ""}
              options={DEALER_GROUP_OPTIONS}
              readOnly={isReadOnly}
              allowClear
              isSearchShow
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Name}
              label={t("registers.holdingsDC.Name") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={3}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.SapCode}
              label={t("registers.holdingsDC.SapCode") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={3}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.ShowroomCode}
              label={t("registers.holdingsDC.ShowroomCode") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormHoldingSelect
              name={RegistryDataFormFieldsEnum.Holding}
              label={t("registers.holdingsDC.Holding") || ""}
              readOnly={isReadOnly}
              allowClear
              isSearchShow
              onDeselect={() =>
                setValue(RegistryDataFormFieldsEnum.Holding, "")
              }
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Region}
              label={t("registers.holdingsDC.Region") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormDatepickerField
              name={RegistryDataFormFieldsEnum.CompanyStartDate}
              label={t("registers.holdingsDC.CompanyStartDate") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormDatepickerField
              name={RegistryDataFormFieldsEnum.AddressStartDate}
              label={t("registers.holdingsDC.AddressStartDate") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormDatepickerField
              name={RegistryDataFormFieldsEnum.DealerContractStart}
              label={t("registers.holdingsDC.DealerContractStart") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormDatepickerField
              name={RegistryDataFormFieldsEnum.DealerContractEnd}
              label={t("registers.holdingsDC.DealerContractEnd") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.CityAmount}
              label={t("registers.holdingsDC.CityAmount") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormCountrySelect
              name={RegistryDataFormFieldsEnum.Country}
              label={t("registers.holdingsDC.Country") || ""}
              readOnly={isReadOnly}
              allowClear
              isSearchShow
            />
          </Col>

          <Col span={3}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.City}
              label={t("registers.holdingsDC.City") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={3}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.EngCity}
              label={t("registers.holdingsDC.EngCity") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.Population}
              label={t("registers.holdingsDC.Population") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Address}
              label={t("registers.holdingsDC.Address") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name={RegistryDataFormFieldsEnum.District}
              options={DISTRICT_OPTIONS}
              label={t("registers.holdingsDC.District") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.CarStorage}
              label={t("registers.holdingsDC.CarStorage") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.DealerEmail}
              label={t("registers.holdingsDC.DealerEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Phone1}
              label={t("registers.holdingsDC.Phone1") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Phone2}
              label={t("registers.holdingsDC.Phone2") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.Phone3}
              label={t("registers.holdingsDC.Phone3") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <StyledCheckbox>
              <FormCheckbox
                name={RegistryDataFormFieldsEnum.IsVerifyDomain}
                label={t("registers.holdingsDC.IsVerifyDomain") || ""}
                align="center"
                readOnly={isReadOnly}
              />
            </StyledCheckbox>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <>
              {fields.map((item, index) =>
                <StyledMultipleInput key={item.id}>
                  <FormInputField
                    name={`domains.${index}.domain`}
                    label={index === 0 ? t("registers.holdingsDC.Domain") || "" : ""}
                    readOnly={isReadOnly}
                  />
                  {
                    index === 0 &&
                    <StyledMultipleButton>
                      <IconButton
                        onClick={() => checkDomainsFieldsLength() ? append({ domain: '' }) : null}
                        icon={"add"}
                        size={"s"}
                        color={"white"}
                      />
                    </StyledMultipleButton>
                  }
                </StyledMultipleInput>
              )}
            </>
          </Col>
        </Row>
      </StyledSection>

      <Divider type="horizontal"/>

      <StyledSection>
        <Title level={4} weight={400}>
          {t("registers.holdingsDC.admin") || ""}
        </Title>

        <Row gutter={32}>
          <Col span={6}>
            <FormFirstLastNameField
              name={[
                RegistryDataFormFieldsEnum.DirectorName,
                RegistryDataFormFieldsEnum.DirectorLastName,
              ]}
              label={t("registers.holdingsDC.DirectorName") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.DirectorEmail}
              label={t("registers.holdingsDC.DirectorEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormFirstLastNameField
              name={[
                RegistryDataFormFieldsEnum.SalesManagerName,
                RegistryDataFormFieldsEnum.SalesManagerLastName,
              ]}
              label={t("registers.holdingsDC.SalesManagerName") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.SalesManagerEmail}
              label={t("registers.holdingsDC.SalesManagerEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormFirstLastNameField
              name={[
                RegistryDataFormFieldsEnum.MarketingManagerName,
                RegistryDataFormFieldsEnum.MarketingManagerLastName,
              ]}
              label={t("registers.holdingsDC.MarketingManagerName") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.MarketingManagerEmail}
              label={t("registers.holdingsDC.MarketingManagerEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormFirstLastNameField
              name={[
                RegistryDataFormFieldsEnum.ChiefTechnicalOfficeName,
                RegistryDataFormFieldsEnum.ChiefTechnicalOfficeLastName,
              ]}
              label={t("registers.holdingsDC.ChiefTechnicalOfficeName") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.ChiefTechnicalOfficeEmail}
              label={t("registers.holdingsDC.ChiefTechnicalOfficeEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormFirstLastNameField
              name={[
                RegistryDataFormFieldsEnum.ChiefAccountantName,
                RegistryDataFormFieldsEnum.ChiefAccountantLastName,
              ]}
              label={t("registers.holdingsDC.ChiefAccountantName") || ""}
              readOnly={isReadOnly}
            />
          </Col>

          <Col span={6}>
            <FormInputField
              name={RegistryDataFormFieldsEnum.ChiefAccountantEmail}
              label={t("registers.holdingsDC.ChiefAccountantEmail") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>
      </StyledSection>

      <Divider type="horizontal"/>

      <StyledSection>
        <Title level={4} weight={400}>
          {t("registers.holdingsDC.object") || ""}
        </Title>

        <Row gutter={32}>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.DealerBrand") || ""}
              name={RegistryDataFormFieldsEnum.DealerBrand}
              options={DC_BRAND_TYPE_OPTIONS}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.Facade") || ""}
              name={RegistryDataFormFieldsEnum.Facade}
              options={FACADE_OPTIONS}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.Interior") || ""}
              name={RegistryDataFormFieldsEnum.Interior}
              options={INTERIOR_OPTIONS}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormBooleanSelectField
              label={t("registers.holdingsDC.Mast") || ""}
              name={RegistryDataFormFieldsEnum.Mast}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.Furniture") || ""}
              name={RegistryDataFormFieldsEnum.Furniture}
              options={FURNITURE_OPTIONS}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormBooleanSelectField
              label={t("registers.holdingsDC.RoundPodium") || ""}
              name={RegistryDataFormFieldsEnum.RoundPodium}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormBooleanSelectField
              label={t("registers.holdingsDC.RoofInstallation") || ""}
              name={RegistryDataFormFieldsEnum.RoofInstallation}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormBooleanSelectField
              label={t("registers.holdingsDC.FurnitureDynamicCube") || ""}
              name={RegistryDataFormFieldsEnum.FurnitureDynamicCube}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.ShowroomArea}
              label={t("registers.holdingsDC.ShowroomArea") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.LocksmithArea}
              label={t("registers.holdingsDC.LocksmithArea") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormInputNumberField
              name={RegistryDataFormFieldsEnum.WarehouseArea}
              label={t("registers.holdingsDC.WarehouseArea") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.WarrantyCurator") || ""}
              options={warrantyCuratorGroupOptions}
              name={RegistryDataFormFieldsEnum.WarrantyCurator}
              readOnly={isReadOnly}
              isSearchShow
              // defaultValue={
              //   dealerData?.dealerBuildInfrastructure?.warrantyCurator &&
              //   `${dealerData.dealerBuildInfrastructure.warrantyCurator.firstName}
              //    ${dealerData.dealerBuildInfrastructure.warrantyCurator.lastName}`
              // }
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.ZoneDevelopmentManager") || ""}
              options={zoneDevelopmentManagerGroupOptions}
              name={RegistryDataFormFieldsEnum.ZoneDevelopmentManager}
              readOnly={isReadOnly}
              isSearchShow
              // defaultValue={
              //   dealerData?.dealerBuildInfrastructure?.zoneDevelopmentManager &&
              //   `${dealerData.dealerBuildInfrastructure?.zoneDevelopmentManager?.firstName}
              //    ${dealerData.dealerBuildInfrastructure?.zoneDevelopmentManager?.lastName}`
              // }
            />
          </Col>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.ZoneSalesManager") || ""}
              options={zoneSalesManagerGroupOptions}
              name={RegistryDataFormFieldsEnum.ZoneSalesManager}
              readOnly={isReadOnly}
              isSearchShow
              // defaultValue={
              //   dealerData?.dealerBuildInfrastructure?.zoneSalesManager &&
              //   `${dealerData.dealerBuildInfrastructure?.zoneSalesManager?.firstName}
              //    ${dealerData.dealerBuildInfrastructure?.zoneSalesManager?.lastName}`
              // }
            />
          </Col>
          <Col span={6}>
            <FormSelect
              label={t("registers.holdingsDC.ZoneServiceManager") || ""}
              options={zoneServiceManagerGroupOptions}
              name={RegistryDataFormFieldsEnum.ZoneServiceManager}
              readOnly={isReadOnly}
              isSearchShow
              // defaultValue={
              //   dealerData?.dealerBuildInfrastructure?.zoneServiceManager &&
              //   `${dealerData.dealerBuildInfrastructure?.zoneServiceManager?.firstName}
              //    ${dealerData.dealerBuildInfrastructure?.zoneServiceManager?.lastName}`
              // }
            />
          </Col>
        </Row>
      </StyledSection>
    </StyledRegistryDataFields>
  );
};
