import React, { FC, useMemo } from "react";
import { StyledFormSelect } from "@/components/MaintenanceGrids/ExportTab/index.styles";
import { useGetGenerationListWithoutModelId } from "@/helpers/api/generations/hooks";

const SecondExportType: FC = () => {
  const { data: generations } = useGetGenerationListWithoutModelId(
    {
      onlyActive: true,
      limit: 1000,
      orderBy: 'ASC',
      sortBy: 'modelName'
    }
  );

  const preparedGenerationsList = useMemo(
    () =>
      generations?.data?.data?.map((item) => ({
        label: `${item.fullModelName} (${item.generationYearStart}${item.generationYearEnd ? ('-' + item.generationYearEnd) : ''})`,
        value: item.id,
      })) || [],
    [generations?.data]
  );

  return (
    <>
      <StyledFormSelect
        name={"secondGeneration"}
        label={"Модель"}
        placeholder={"Введите или выберите модель из списка"}
        isSearchShow={true}
        options={preparedGenerationsList}
        allowClear
        mode={"multiple"}
      />
    </>
  );
};
export { SecondExportType };
