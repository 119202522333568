import { Card, Image, Space } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  border-radius: 12px;
  background: #f8f9fb;
  height: 300px;
  width: 340px;
`;
export const StyledImg = styled(Image)`
  height: 200px;
  border-radius: 8.5px !important;
  object-fit: cover;
  margin-bottom: 14px;
`;
export const StyledSpace = styled(Space).attrs({ size: 64 })`
  display: flex;
  justify-content: center;
  height: 510px;
`;
