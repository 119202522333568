import React from "react";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import {
  ISelectField,
  SelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";

export interface IHoldingSelectAdapter extends Omit<ISelectField, "options"> {}

export const HoldingSelectAdapter: React.FC<IHoldingSelectAdapter> = (
  props
) => {
  const { data: holdings, isLoading: isHoldingsLoading } = useGetKiaHoldingList(
    { limit: 0 }
  );

  const holdingsList = holdings?.data.data;

  const options = holdingsList?.map(
    (record) =>
      ({
        value: record.id,
        label: record.name,
      } as ISelectOption)
  );

  return (
    <SelectField options={options} {...props} loading={isHoldingsLoading} />
  );
};
