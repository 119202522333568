import styled from "styled-components";
import { colors } from "@/helpers";

import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";

export const StyledStatisticsFilterWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const StyledFormLayout = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

export const StyledDatepickerWrapper = styled.div`
  display: grid;
  gap: 10px;
  align-items: end;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: minmax(0, 1fr) min-content minmax(0, 1fr);
  white-space: pre;
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  margin-bottom: 14px;
  background-color: ${colors.gray4}; 
`;

export const StyledField = styled.span`
  width: 12px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;