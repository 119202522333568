import React from "react";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { useActiveTab } from "@/hooks/useActiveTab";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { DealerBonusesTab } from "@/components/Bonuses/ReportsCalculation/DealerBonusesTab";
import styled from "styled-components";
import { CoefficientATab } from "@/components/Bonuses/ReportsCalculation/CoefficientTabs/CoefficientATab";
import { CoefficientBTab } from "@/components/Bonuses/ReportsCalculation/CoefficientTabs/CoefficientBTab";
import { CoefficientCTab } from "@/components/Bonuses/ReportsCalculation/CoefficientTabs/CoefficientCTab";
import { FactorZ } from "@/components/Bonuses/ReportsCalculation/FactorZ";
import { useTranslation } from "react-i18next";

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const BonusesCalculation: React.FC = (props: any) => {
  const { t } = useTranslation();

  const tabs: ITab[] = [
    {
      name: "bonuses.calculations.dealer",
      slug: "dealer",
      content: <DealerBonusesTab />,
    },
    {
      name: "bonuses.calculations.factorA",
      slug: "factor-a",
      content: <CoefficientATab />,
    },
    {
      name: "bonuses.calculations.factorB",
      slug: "factor-B",
      content: <CoefficientBTab />,
    },
    {
      name: "bonuses.calculations.factorC",
      slug: "factor-C",
      content: <CoefficientCTab />,
    },
    {
      name: "bonuses.calculations.factorZ",
      slug: "factor-Z",
      content: <FactorZ />,
    },
  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  return (
    <DashboardLayout
      title={t("bonuses.calculations.title") || ""}
      description={t("bonuses.calculations.description") || ""}
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
        type="card"
      />
    </DashboardLayout>
  );
};
