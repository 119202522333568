import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGenerationCreateRequest,
  TGenerationCreateResponse,
  TGenerationPatchRequest,
  TGenerationPatchResponse,
  TGenerationUpdateStatusRequest,
  TGenerationUpdateStatusResponse,
  TGetGenerationByIdRequest,
  TGetGenerationByIdResponse,
  TGetGenerationListRequest,
  TGetGenerationListResponse,
} from "./types";

export const useGetGenerationList = (request: TGetGenerationListRequest) => {
  //@ts-ignore
  if (!request.modelId) delete request.modelId;

  return useQuery(
    ["generations", "list", request],
    () => {
      return api.get<TGetGenerationListResponse>("/generations", {
        params: request,
      });
    },
    {
      enabled: !!request.modelId,
    }
  );
};

export const useGetGenerationListWithoutModelId = (
  request: TGetGenerationListRequest
) => {
  return useQuery(["generations", "list", request], () => {
    return api.get<TGetGenerationListResponse>("/generations", {
      params: request,
    });
  });
};

export const useGetGenerationById = ({
  id,
  onSuccess,
}: TGetGenerationByIdRequest & {
  onSuccess?: (response: TGetGenerationByIdResponse) => void;
}) => {
  return useQuery(
    ["generations", "detail", id],
    () => {
      return api.get<TGetGenerationByIdResponse>(`/generations/${id}`);
    },
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response.data);
      },
    }
  );
};

export const useCreateGeneration = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TGenerationCreateRequest) => {
      return api.post<TGenerationCreateResponse>("/generations", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["generations", "list"]);

        queryClient.invalidateQueries(["models", "list"]);
        queryClient.invalidateQueries(["models", "detail", variables.modelId]);
      },
    }
  );
};

export const usePatchGeneration = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TGenerationPatchRequest) => {
      return api.patch<TGenerationPatchResponse>(`/generations/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["generations", "detail", variables.id]);
        queryClient.invalidateQueries(["generations", "list"]);

        queryClient.invalidateQueries(["models", "list"]);
        queryClient.invalidateQueries([
          "models",
          "detail",
          // @ts-ignore
          variables.patch.modelId,
        ]);
      },
    }
  );
};

export const useUpdateGenerationStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: TGenerationUpdateStatusRequest) => {
      return api.patch<TGenerationUpdateStatusResponse>(
        `/generations/${id}/isActive`,
        data
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["generations", "detail", variables.id]);
        queryClient.invalidateQueries(["generations", "list"]);

        queryClient.invalidateQueries(["models", "list"]);
      },
    }
  );
};
