import AuditListFilter from "@/components/AuditList/AuditListFilter";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import React, { useContext, useEffect, useState } from "react";
import { AuditListTable } from "@/components/AuditList/AuditListTable";
import {
  useDeleteAudit,
  useExportAuditList,
  useGetAuditList,
} from "@/helpers/api/audit-list/hooks";
import { usePagination } from "@/hooks/usePagination";
import { CreateAuditModal } from "@/components/AuditList/CreateAuditModal";
import { useModal } from "@/hooks/useModal";
import { CopyAuditModal } from "@/components/AuditList/CopyAuditModal";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { useRequestParams } from "@/hooks/useRequestParams";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { IRequestParams } from "@/helpers/interfaces";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@/hooks/useNotification";
import { useArchiveAudit } from "@/helpers/api/audit-fill/hooks";
import { IconButton } from "@/packages/icon-button/IconButton";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import {
  StyledButtonsTop,
  StyledContentTitle,
  StyledSelectField,
} from "../AuditList/index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useTranslation } from "react-i18next";
import { DateParam, NumberParam, StringParam, useQueryParams } from "use-query-params";
import { IAuditFilterFormValues } from "@/components/AuditList/AuditListFilter/hooks";
import { Space } from "antd";

const AuditList: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const isKia = profile?.type === "kia";

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: "URL страницы скопирован в буфер обмена",
  });

  const navigate = useNavigate();
  const { paginationParams, setPage } = usePagination();
  const [sortParams, setSortParams] = useState({});
  const [requestParams, setRequestParams] = useRequestParams({});
  const [selectedAudit, setSelectedAudit] = useState<number | null>(null);

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    auditorId: StringParam,
    dealerId: StringParam,
    holdingId: StringParam,
    type: StringParam,
    status: StringParam,
    holding: StringParam,
    dealer: StringParam,
    manager: StringParam,
    startCreatedAt: DateParam,
    endCreatedAt: DateParam,
    page: NumberParam,
  });

  useEffect(() => {
    const numberPage = Number(filterQueryParams.page);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setFilterQueryParams({
      ...filterQueryParams,
      page: paginationParams.page
    })
  }, [paginationParams.page])

  const filterParams: IAuditFilterFormValues = {
    auditorId: filterQueryParams.auditorId as string,
    dealerId: filterQueryParams.dealerId as string,
    holdingId: filterQueryParams.holdingId as string,
    type: filterQueryParams.type as string,
    status: filterQueryParams.status as string,
    holding: filterQueryParams.holding as string,
    dealer: filterQueryParams.dealer as string,
    manager: filterQueryParams.manager as string,
    startCreatedAt: filterQueryParams.startCreatedAt as Date,
    endCreatedAt: filterQueryParams.endCreatedAt as Date,
  };

  const {
    data: auditList,
    isLoading,
    refetch: updateList,
  } = useGetAuditList(
    {
      ...sortParams,
      ...requestParamsMapper(requestParams),
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
    },
    isKia,
  );

  const { mutateAsync: exportAuditList } = useExportAuditList(isKia);
  const { mutate: deleteAudit } = useDeleteAudit();
  const { createNotificationError } = useNotification();
  const { mutate: archiveAudit } = useArchiveAudit();
  const {
    modalState: CreateAuditModalState,
    openModal: CreateAuditModalOpen,
    closeModal: CreateAuditModalClose,
  } = useModal();

  const {
    modalState: CopyAuditModalState,
    openModal: CopyAuditModalOpen,
    closeModal: CopyAuditModalClose,
  } = useModal();

  const handleSubmit = () => {
    updateList();
    CreateAuditModalClose();
    CopyAuditModalClose();
  };

  const handleDeleteAudit = (id: number) => {
    deleteAudit({
      id,
      onSuccess: handleSubmit,
      onError: (resp) => {
        createNotificationError(resp.response?.data.message || resp.message);
      },
    });
  };

  const handleArchiveAudit = (id: number) => {
    archiveAudit({
      id,
      onSuccess: () => updateList(),
      onError: (resp) => {
        createNotificationError(resp.response?.data.message || resp.message);
      },
    });
  };
  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      "sort[sortBy]": header.id,
      "sort[sortAt]": order?.toUpperCase(),
    }));
  };

  const handleExportAudits = () => {
    exportAuditList(
      {
        ...sortParams,
        ...requestParamsMapper(requestParams),
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "audits.xlsx");
        },
      }
    );
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={t("audits.list.title") || ""}
        headerRight={
          <Space>
            <Tooltip content={t("common.save") || ""} placement="bottom">
              <IconButton
                icon="save"
                isSquared={true}
                variant="primary"
                color="white"
                onClick={handleExportAudits}
              />
            </Tooltip>
            <Tooltip content={t("common.share") || ""} placement="bottom">
              <IconButton
                icon="share"
                isSquared={true}
                variant="primary"
                color="white"
                onClick={() => copyCurrentUrl()}
              />
            </Tooltip>
            <PermissionsAdapter
              resourceName="audit.list"
              resourceAction="create"
            >
              <StyledButtonsTop>
                <Button
                  color="brand"
                  size="middle"
                  theme="primary"
                  onClick={CreateAuditModalOpen}
                >
                  {t("audits.list.addNew") || ""}
                </Button>
              </StyledButtonsTop>
            </PermissionsAdapter>
          </Space>
        }
      >
        <StyledContentTitle>
          <StyledSelectField>
            <AuditListFilter
              resetPage={()=> setPage(1)}
              onFilterChange={(data: IRequestParams) => {
                setRequestParams((prev) => ({ ...prev, ...data }));
              }}
              setFilterQueryParams={setFilterQueryParams}
              initValues={filterParams}
            />
          </StyledSelectField>
        </StyledContentTitle>
        <AuditListTable
          hasPagination={
            !!auditList?.data.meta?.pageCount &&
            auditList?.data.meta?.pageCount > 1
          }
          paginationParams={{
            page: paginationParams.page,
            limit: auditList?.data.meta?.pageCount || 1,
          }}
          onSort={handleSort}
          data={auditList?.data.data || []}
          isLoading={isLoading}
          setPage={setPage}
          onDelete={handleDeleteAudit}
          onViewClick={(path) => navigate(path)}
          onArchive={handleArchiveAudit}
          openCopyModal={(id) => {
            setSelectedAudit(id);
            CopyAuditModalOpen();
          }}
          requestParams={requestParams}
        />
      </DashboardLayout>
      <PermissionsAdapter resourceName="audit.list" resourceAction="read">
        <CreateAuditModal
          isOpen={CreateAuditModalState.isOpen}
          onSubmit={handleSubmit}
          onCancel={CreateAuditModalClose}
        />
        {selectedAudit ? (
          <CopyAuditModal
            isOpen={CopyAuditModalState.isOpen}
            onSubmit={handleSubmit}
            auditId={selectedAudit}
            onCancel={CopyAuditModalClose}
          />
        ) : (
          <></>
        )}
      </PermissionsAdapter>
    </>
  );
};

export { AuditList };
