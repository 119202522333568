import { colors, media, mediaRevers } from "@/helpers";
import { TAuditInfo } from "@/helpers/api/audit-fill/types";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

const StyledInfoForm = styled.div<{ isOpen: boolean }>`
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    margin-bottom: ${({ isOpen }) => (isOpen ? "16px" : 0)};

    ${media.mobile} {
        max-height: ${({ isOpen }) => (isOpen ? "1000px" : 0)};
    }

    ${media.tablet} {
        max-height: ${({ isOpen }) => (isOpen ? "600px" : 0)};
    }

    ${media.desktop} {
        max-height: ${({ isOpen }) => (isOpen ? "400px" : 0)};
    }
`;
const StyledInfoGrid = styled.div`
    display: grid;
    gap: 16px 24px;

    ${media.mobile} {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }

    ${media.desktop} {
        grid-template-columns: repeat(6, 1fr);
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }
`;
const StyledMainCard = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;

    padding: 36px 24px;
    background: ${colors.white};
    border-radius: 8px;

    ${media.mobile} {
    }

    ${media.mobile} {
        grid-column: 1 / span 3;
        grid-row: 1 / span 2;
    }

    ${media.desktop} {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }
`;

const StyledInfoCard = styled.div`
    padding: 16px;
    background: ${colors.white};
    border-radius: 8px;

    ${mediaRevers.tablet} {
        grid-column: 1 / span 3;
    }
`;

const StyledInfoButton = styled.div`
    button {
        width: 100%;
    }

    ${media.mobile} {
        margin-top: 24px;
        grid-column: 1 / span 3;
    }

    ${media.desktop} {
        margin-top: 24px;
        grid-column: unset;
        grid-row: unset;
    }
`;

const StyledValues = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

const StyledOpen = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledInfoWrap = styled.div`
    margin-bottom: 24px;
`;
const ParagraphWithSpace = styled(Paragraph)<{ space: number }>`
    margin-bottom: ${({ space }) => space}px;
`;

const StyledIcon = styled.div`
    margin-right: 8px;
`;

interface IAuditInfo {
  auditData: TAuditInfo;
  renderComponent?: () => JSX.Element;
}

const MOCK_BLOCKS = [
  {
    title: "audits.list.currentScore",
    accessor: "currentScore",
  },
  {
    title: "audits.list.responsibleDealer",
    accessor: "responsible",
  },
  {
    title: "audits.list.manager",
    accessor: "manager",
  },
  {
    title: "audits.list.auditor",
    accessor: "auditor",
  },
  {
    title: "audits.list.implementation",
    accessor: "implementation",
  },
  {
    title: "audits.list.type",
    accessor: "type",
  },
  {
    title: "audits.list.startedAt",
    accessor: "startedAt",
  },
];

export const getScoreString = (min: number, max: number, percent) => {
  return `${min} ${t("audits.list.from") || ""} ${max} (${percent}%)`;
};
export const AuditInfo: React.FC<IAuditInfo> = (props) => {
  const { auditData, renderComponent } = props;
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    console.log(auditData)
  }, [auditData])
  const preparedData = {
    ...auditData,
    startedAt: new Date(auditData.startedAt).toLocaleDateString(),
    implementation:
      getScoreString(
        auditData.answersCount,
        auditData.questionsCount,
        Math.round(
          (auditData?.answersCount * 100) / auditData.questionsCount
        ) || 0
      ) || 0,
    manager: `${auditData?.managerFirstName || "-"} ${
      auditData?.managerLastName || ""
    } `,
    currentScore:
      getScoreString(
        auditData.score,
        auditData.maxScore,
        Math.round((auditData?.score * 100) / auditData.maxScore) || 0
      ) || 0,
  };
  const { dispatch: openUserModal } = useUserInfoModel();

  return (
    <StyledInfoWrap>
      <StyledInfoForm isOpen={isOpen}>
        <StyledInfoGrid>
          <StyledMainCard>
            <Icon size={64} color="yellow" name="home"/>
            <div>
              <ParagraphWithSpace space={12} size={14} weight={300}>
                {t("audits.list.dealer") || ""}
              </ParagraphWithSpace>
              <ParagraphWithSpace space={12} size={16} weight={400}>
                {auditData.dealerName}
              </ParagraphWithSpace>
              <StyledValues>
                <Paragraph size={14} weight={300}>
                  {t("audits.list.code") || ""}
                </Paragraph>
                <Paragraph size={14} weight={400}>
                  {auditData.dealerMobis}
                </Paragraph>
              </StyledValues>
              <StyledValues>
                <Paragraph size={14} weight={300}>
                  {t("audits.list.warrantyCode") || ""}
                </Paragraph>
                <Paragraph size={14} weight={400}>
                  {auditData.dealerWarranty}
                </Paragraph>
              </StyledValues>
            </div>
          </StyledMainCard>
          {MOCK_BLOCKS.map((item, index) => (
            <StyledInfoCard key={index}>
              <ParagraphWithSpace space={8} size={14} weight={300}>
                {t(item.title) || ""}
              </ParagraphWithSpace>
              {
                (item.accessor === 'responsible' || item.accessor === 'auditor' || item.accessor === 'manager') ?
                  <StyledUserNameWrapper onClick={() =>
                    openUserModal({ userId: auditData[`${item.accessor}Id`] })
                  }>
                    <ParagraphWithSpace space={0} size={14} weight={400}>
                      {preparedData[item.accessor]}
                    </ParagraphWithSpace>
                  </StyledUserNameWrapper>
                  :
                  <ParagraphWithSpace space={0} size={14} weight={400}>
                    {preparedData[item.accessor]}
                  </ParagraphWithSpace>
              }
            </StyledInfoCard>
          ))}
          <StyledInfoButton>
            {renderComponent ? <>{renderComponent()}</> : <></>}
          </StyledInfoButton>
        </StyledInfoGrid>
      </StyledInfoForm>
      <StyledOpen>
        <ButtonText
          isIconLeft={true}
          icon={
            <StyledIcon>
              <Icon
                name={isOpen ? "double-shevron-up" : "double-shevron-down"}
                size={16}
              />
            </StyledIcon>
          }
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen ? t("audits.list.hide") || "" : t("audits.list.show") || ""}
          {t("audits.list.info") || ""}
        </ButtonText>
      </StyledOpen>
    </StyledInfoWrap>
  );
};
