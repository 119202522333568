import { colors, media } from "@/helpers";
import { Button, IButton } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Popconfirm } from "antd";
import { MouseEvent, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

export type TModalSize = "small" | "medium" | "large" | "extra" | "photo";

export interface IModal {
  children: ReactNode;
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  styledConfirmButton?: (
    props: IButton,
    confirmButtonText: string
  ) => ReactNode;
  cancelPopconfirmTitle?: string;
  isLoadingConfirmButton?: boolean;
  isDisabledConfirmButton?: boolean;
  isDisabledCancelButton?: boolean;
  isBodyLock?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  onClose?: () => void;
  onPopupConfirm?: () => void;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  showSideEffectButton?: boolean;
  showSideEffectTitle?: string;
  onShowSideEffectConfirm?: () => void;
  sideEffectText?: string;
  allowCancelPopconfirm?: boolean;
  isOpen?: boolean;
  size?: TModalSize;
}

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  display: grid;
  align-items: center;
  justify-items: center;

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

const StyledContainer = styled.div<{ size: TModalSize }>`
  width: 100vw;
  height: 100vh;

  ${media.tablet} {
    width: 100%;
    display: grid;
    vertical-align: middle;
    height: auto;

    ${({ size }) => {
      switch (size) {
        case "small":
          return css`
            max-width: 561px;
          `;
        case "medium":
          return css`
            max-width: 830px;
          `;

        case "large":
          return css`
            max-width: 1234px;
          `;

        case "extra":
          return css`
            max-width: 1400px;
          `;

        case "photo":
          return css`
            width: 100vw;
            height: 100vh;

            div:first-child {
              margin: auto;
            }
          `;

        default:
          break;
      }
    }}
  }
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  height: 100vh;

  ${media.tablet} {
    height: auto;
    margin: 48px 0px;
  }
`;

const StyledHeader = styled.div<{ hasBackGround: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ hasBackGround }) => (hasBackGround ? "19px 32px" : "0")};
  background-color: ${({ hasBackGround }) =>
          hasBackGround ? colors.gray300 : "transparent"};
  min-height: ${({ hasBackGround }) => (hasBackGround ? "56px" : "0")};
`;

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  background-color: ${colors.gray30};
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;

  button {
    width: 100%;
  }

  ${media.desktop} {
    flex-direction: row-reverse;

    button {
      width: fit-content;
    }
  }
`;

const StyledBody = styled.div<{ hasBackGround: boolean }>`
  padding: 24px 32px;
  min-height: 70vh;

  ${media.tablet} {
    min-height: ${({ hasBackGround }) => hasBackGround && "152px"};
  }

  background-color: ${({ hasBackGround }) =>
          hasBackGround ? colors.white : "transparent"};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 19px;
  right: 19px;
`;

const Modal: React.FC<IModal> = (props) => {
  const {
    title,
    isBodyLock = true,
    children,
    cancelButtonText = "Нет",
    confirmButtonText = "Да",
    styledConfirmButton,
    cancelPopconfirmTitle,
    isLoadingConfirmButton,
    isDisabledConfirmButton,
    isDisabledCancelButton,
    isOpen,
    onCancel,
    onSubmit,
    onClose,
    onPopupConfirm,
    showCancelButton = true,
    showConfirmButton = true,
    allowCancelPopconfirm = false,
    showSideEffectButton,
    showSideEffectTitle,
    size = "small",
    onShowSideEffectConfirm,
    sideEffectText,
  } = props;

  const handleOverlayClick = (event: MouseEvent) => {
    if (onClose) {
      onClose && event.target === event.currentTarget && onClose();
    } else {
      onCancel && event.target === event.currentTarget && onCancel();
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (!onCancel && !onClose) return;
    if (onClose) {
      if (isOpen) {
        const handleKeydown = (event: KeyboardEvent) =>
          event.key === "Escape" && onClose();
        document.addEventListener("keydown", handleKeydown);
        if (isBodyLock) document.body.style.overflow = "hidden";
        return () => {
          document.removeEventListener("keydown", handleKeydown);
          if (isBodyLock) document.body.style.overflow = "auto";
        };
      }
    } else if (onCancel) {
      if (isOpen) {
        const handleKeydown = (event: KeyboardEvent) =>
          event.key === "Escape" && onCancel();
        document.addEventListener("keydown", handleKeydown);
        if (isBodyLock) document.body.style.overflow = "hidden";
        return () => {
          document.removeEventListener("keydown", handleKeydown);
          if (isBodyLock) document.body.style.overflow = "auto";
        };
      }
    }
  }, [onClose, onCancel, isBodyLock, isOpen]);

  const buttonConfirmProps: IButton = {
    isBlock: false,
    color: "brand",
    theme: "primary",
    onClick: onSubmit,
    isLoading: isLoadingConfirmButton,
    isDisabled: isDisabledConfirmButton,
  };

  const isPhotoModal = size === "photo";

  return isOpen ? (
    createPortal(
      <StyledModal onMouseDown={handleOverlayClick}>
        <StyledContainer size={size}>
          <StyledContent>
            <StyledHeader hasBackGround={!isPhotoModal}>
              {title ? <Paragraph size={14}>{title}</Paragraph> : <></>}
              <StyledClose>
                <IconButton
                  onClick={() => {
                    if (onClose) {
                      onClose && onClose();
                    } else {
                      onCancel && onCancel();
                    }
                  }}
                  icon="close-16"
                  size="m"
                  color="gray"
                  variant="tertiary"
                />
              </StyledClose>
            </StyledHeader>
            <StyledBody hasBackGround={!isPhotoModal}>{children}</StyledBody>
            {!isPhotoModal ? (
              <StyledFooter>
                {onSubmit && showConfirmButton ? (
                  styledConfirmButton ? (
                    styledConfirmButton(buttonConfirmProps, confirmButtonText)
                  ) : (
                    <Button
                      isBlock={false}
                      color="brand"
                      theme="primary"
                      onClick={onSubmit}
                      isLoading={isLoadingConfirmButton}
                      isDisabled={isDisabledConfirmButton}
                    >
                      {confirmButtonText}
                    </Button>
                  )
                ) : (
                  <></>
                )}

                {
                  showSideEffectButton &&
                  <Popconfirm
                    title={showSideEffectTitle}
                    okText={t("common.yes") || ""}
                    cancelText={t("common.no") || ""}
                    onConfirm={() => {
                      onShowSideEffectConfirm && onShowSideEffectConfirm();
                    }}
                  >
                    <Button isBlock={false} theme="secondary" color="brand">
                      {sideEffectText}
                    </Button>
                  </Popconfirm>
                }

                {showCancelButton && allowCancelPopconfirm ? (
                  <Popconfirm
                    title={cancelPopconfirmTitle}
                    okText={t("common.cancelYes") || ""}
                    cancelText={t("common.cancelNo") || ""}
                    onConfirm={() => {
                      onCancel && onCancel();
                      onPopupConfirm && onPopupConfirm();
                    }}
                  >
                    <Button isBlock={false} theme="secondary" color="brand">
                      {cancelButtonText}
                    </Button>
                  </Popconfirm>
                ) : showCancelButton ? (
                  <Button
                    isBlock={false}
                    theme="secondary"
                    color="brand"
                    onClick={onCancel}
                    isDisabled={isDisabledCancelButton}
                  >
                    {cancelButtonText}
                  </Button>
                ) : (
                  <></>
                )}
              </StyledFooter>
            ) : (
              <></>
            )}
          </StyledContent>
        </StyledContainer>
      </StyledModal>,
      document.body
    )
  ) : (
    <></>
  );
};

export { Modal };
