import React, { FC } from "react";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import styled from "styled-components";
import { Space } from "antd";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

const StyledGsmFormModal = styled.div``;

export type PropsType = {
  editMode: boolean;
  type: 'spare' | 'gsm'
};

const TYPE_OPTIONS = [
  {
    value: "material",
    label: "maintenanceGrids.gsm",
  },
  {
    value: "spare",
    label: "maintenanceGrids.spare",
  },
];

const GsmFormModal: FC<PropsType> = (props) => {
  const { editMode,type} = props;

  const { hasAccess: hasEdit } = usePermission("maintenance-grid", "update");
  const { t } = useTranslation();

  return (
    <StyledGsmFormModal>
        <Space
          style={{ width: "100%" }}
          direction="vertical"
          size={24}
        >
          {
            editMode &&
            <FormSelect
              name="type"
              label={t(`maintenanceGrids.gsmModel.gsmForm.type`) || ""}
              placeholder={t(`maintenanceGrids.gsmModel.gsmForm.typeDescription`) || ""}
              options={TYPE_OPTIONS}
              allowClear
            />
          }
          <FormInputField
            label={t(`maintenanceGrids.gsmModel.${type}Form.name`) || ""}
            name="name"
            description={
              t(`maintenanceGrids.gsmModel.${type}Form.nameDescription`) || ""
            }
            readOnly={!hasEdit}
          />
          <FormInputField
            label={t(`maintenanceGrids.gsmModel.${type}Form.code`) || ""}
            name="code"
            description={
              t(`maintenanceGrids.gsmModel.${type}Form.codeDescription`) || ""
            }
            readOnly={!hasEdit}
          />
          {
            type === 'gsm' &&
            <FormInputField
            label={t(`maintenanceGrids.gsmModel.gsmForm.volume`) || ""}
            name="volume"
            description={
              t(`maintenanceGrids.gsmModel.gsmForm.volumeDescription`) || ""
            }
            readOnly={!hasEdit}
          />
          }
          <FormInputField
            label={t(`maintenanceGrids.gsmModel.${type}Form.price`) || ""}
            name="price"
            description={t(`maintenanceGrids.gsmModel.${type}Form.priceDescription`) || ""}
            readOnly={!hasEdit}
          />
        </Space>
    </StyledGsmFormModal>
  );
};

export { GsmFormModal };
