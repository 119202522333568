import { TOnSubmitFormCb, useForm } from "@/hooks/useForm";
import * as yup from "yup";
import { t } from "i18next";

export type RegistrationSecondStepFormValues = {
  firstName: string;
  lastName: string;
  code: string;
  phone: string;
  email: string;
};
export type RegistrationSecondStepFormSuccessValues = {
  firstName: string;
  lastName: string;
  code: string;
  phone: string;
  email: string;
};

export const DEFAULT_REGISTRATION_FORM_SECOND_STEP_VALUES: RegistrationSecondStepFormValues =
  {
    firstName: "",
    lastName: "",
    code: "",
    phone: "",
    email: "",
  };

export const formSchema = () => {
  return yup
    .object({
      firstName: yup
        .string()
        .required(t("authorization.validation.name") || ""),
      lastName: yup
        .string()
        .required(t("authorization.validation.surname") || ""),
      phone: yup.string(),
      code: yup.string(),
      email: yup
        .string()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t("authorization.validation.emailFormat") || ""
        )
        .required(t("authorization.validation.email") || ""),
    })
    .required();
};

export type TOnSubmitFormSecondStepCb =
  TOnSubmitFormCb<RegistrationSecondStepFormSuccessValues>;

export const useRegistrationFormSecondStep = (
  onSubmit: TOnSubmitFormSecondStepCb
) => {
  return useForm<
    RegistrationSecondStepFormValues,
    RegistrationSecondStepFormSuccessValues
  >({
    validateFormSchema: formSchema(),
    onSubmit: onSubmit,
    defaultValues: DEFAULT_REGISTRATION_FORM_SECOND_STEP_VALUES,
  });
};
