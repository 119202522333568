import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";

import { useRequestParams } from "@/hooks/useRequestParams";
import { useGetVehicleList } from "@/helpers/api/vehicles/hooks";
import { useGetMediaList } from "@/helpers/api/mediaBase/hooks";

import {
  IApplicationsFormValues,
  TOnSubmitApplicationsFormCb,
  useApplicationsForm,
} from "./hooks";

import {
  StyledApplicationsFormWrapper,
  StyledCommentArea,
  StyledDatepickerWrapper,
  StyledFormLayout,
  StyledFormSelect,
  StyledLinkArea,
  StyledMainForm,
  StyledNewStatusForm,
} from "./index.styles";

import { TApplicationRecord } from "@/helpers/api/applications/types";
import { useGetStatuses } from "@/helpers/api/testDrives/hooks";
import { useTranslation } from "react-i18next";

export interface IApplicationsForm {
  isLoading: boolean;
  onFormSubmit: TOnSubmitApplicationsFormCb;
  defaultValues?: TApplicationRecord;
  isSubmitted?: boolean;
  setIsSubmitted?: (value: boolean) => void;
  setIsDisabled?: (value: boolean) => void;
  isNewPage?: boolean;
}

export const APPOINTMENT_OPTIONS = [
  {
    value: "Media",
    label: "Media",
    record: { id: 1, name: "Media" },
  },
  {
    value: "Kia",
    label: "Kia",
    record: { id: 2, name: "Kia" },
  },
];

const today = new Date(Date.now() + 3600 * 1000 * 24);

export const ApplicationsForm: React.FC<IApplicationsForm> = (props) => {
  const {
    isLoading,
    onFormSubmit,
    defaultValues,
    isSubmitted,
    setIsSubmitted,
    setIsDisabled,
    isNewPage,
  } = props;
  const [isMounted, setIsMounted] = useState(false);
  const [isNewPublication, setIsNewPublication] = useState(false);

  const { form, onSubmitHandler } = useApplicationsForm(
    onFormSubmit,
    defaultValues
  );
  const { t } = useTranslation();
  const [requestVehicleParams, setRequestVehicleParams] = useRequestParams({
    take: {
      value: 1000,
      type: "page",
    },
    skip: {
      value: 0,
      type: "page",
    },
    name: {
      value: "",
      type: "sort",
    },
    type: {
      value: "",
      type: "sort",
    },
    "filter[archived]": {
      value: false,
    },
    "filter[type]": {
      value: "",
    },
  });

  const { data: responseGetVehicleList } =
    // @ts-ignore
    useGetVehicleList(requestVehicleParams);
  const { data: responseGetMediaList } = useGetMediaList({
    "page[take]": 0,
    "sort[name]": "ASC",
  });
  const { data: responseGetStatusList } = useGetStatuses({
    withoutRejection: false,
  });

  const [
    startEvent,
    endEvent,
    appointment,
    statusId,
    receivingUser,
    vehicleId,
    mediaId,
    fileId,
  ] = form.watch([
    "startEvent",
    "endEvent",
    "appointment",
    "statusId",
    "receivingUser",
    "vehicleId",
    "mediaId",
    "fileId",
  ]);

  const statusListOptions = responseGetStatusList?.data.data
    .filter((status) =>
      appointment ? status.appointment === appointment : status
    )
    .map((status) => ({
      label: status.title,
      value: status.id,
    }));

  const clearForms = (...arg: (keyof IApplicationsFormValues)[]): void => {
    arg.forEach((formName) => {
      if (formName && form.watch(formName)) {
        form.setValue(formName, null);
      }
    });
  };

  const mappedVehicles = useMemo(() => {
    const vehicleList = responseGetVehicleList?.data.data || null;
    if (vehicleList) {
      return vehicleList.map((vehicle) => ({
        value: vehicle.id,
        label: vehicle.name,
        // record: { id: vehicle.id, expiredOSAGO: vehicle.expiredOSAGO },
      }));
    }
    return vehicleList;
  }, [responseGetVehicleList]);

  const mappedMedia = useMemo(() => {
    const mediaList = responseGetMediaList?.data.data || null;

    if (mediaList) {
      return mediaList.map((media) => ({
        value: media.id,
        label: media.name,
        name: media.type,
      }));
    }
  }, [responseGetMediaList]);

  const renderNewPublication = (): JSX.Element => (
    <StyledNewStatusForm>
      <StyledLinkArea
        name="requestPublicationLink"
        label={t("park.links") || ""}
      />
      <FormImageDropzoneField
        name="fileId"
        path="requests/files"
        allowedTypes={[
          "image/png",
          "image/jpeg",
          "image/tiff",
          "application/pdf",
        ]}
      />
    </StyledNewStatusForm>
  );

  useEffect(() => {
    if (!!statusListOptions) {
      const newPublicationStatus = statusListOptions.find(
        (option) => option.label === t("park.public") || ""
      );

      if (newPublicationStatus && statusId === newPublicationStatus.value) {
        setIsNewPublication(true);
      } else {
        setIsNewPublication(false);
      }
    }
  }, [statusListOptions, statusId]);

  useEffect(() => {
    if (isMounted) {
      clearForms("statusId", "receivingUser");
    }
  }, [appointment]);

  useEffect(() => {
    if (isMounted) {
      clearForms("fileId", "requestPublicationLink");
    }
  }, [statusId]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (appointment !== "Media") {
      // @ts-ignore
      form.setValue("mediaId", null);
    }
  }, [appointment]);

  useEffect(() => {
    const currentValues = defaultValues || [];
    if (
      currentValues &&
      appointment &&
      statusListOptions &&
      statusListOptions[appointment]
    ) {
      const currentStatus = statusListOptions[appointment].find(
        (item) => item.name === defaultValues?.status?.title
      );
      if (currentStatus) {
        form.setValue("statusId", currentStatus.value);
      }
    }
  }, [defaultValues, statusListOptions]);

  useEffect(() => {
    if (isSubmitted && setIsSubmitted) {
      onSubmitHandler();
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (
      !form.formState.isDirty ||
      isLoading ||
      (!mediaId && appointment === "Media")
    ) {
      if (setIsDisabled) {
        setIsDisabled(true);
      }
    } else {
      if (setIsDisabled) {
        setIsDisabled(false);
      }
    }
  }, [appointment, form.formState.isDirty, isLoading, mediaId, setIsDisabled]);

  useEffect(() => {
    if (defaultValues?.requestPublication?.files?.[0]) {
      // @ts-ignore
      form.setValue("fileId", defaultValues.requestPublication.files[0]);
    }
  }, [defaultValues]);

  return (
    <StyledApplicationsFormWrapper>
      <StyledFormLayout>
        <FormProvider {...form}>
          <StyledMainForm>
            <StyledDatepickerWrapper>
              <FormDatepickerField
                name="startEvent"
                label={t("park.startEvent") || ""}
                maxDate={endEvent || undefined}
                isSameDateEnabled={true}
              />
              <FormDatepickerField
                name="endEvent"
                label={t("park.endEvent") || ""}
                minDate={startEvent || undefined}
                isSameDateEnabled={true}
              />
            </StyledDatepickerWrapper>

            <StyledFormSelect
              name="vehicleId"
              label={t("park.vehicleId") || ""}
              options={mappedVehicles}
            />

            <StyledFormSelect
              name="appointment"
              label={t("park.appointment") || ""}
              options={APPOINTMENT_OPTIONS}
            />

            {appointment === "Media" ? (
              <StyledFormSelect
                name="mediaId"
                label={t("park.mediaId") || ""}
                options={mappedMedia}
                allowClear
                isSearchShow
              />
            ) : (
              <FormInputField
                name="receivingUser"
                label={t("park.receivingUser") || ""}
              />
            )}

            <StyledFormSelect
              name="statusId"
              label={t("park.statusId") || ""}
              options={statusListOptions}
            />
          </StyledMainForm>

          {isNewPublication ? renderNewPublication() : <></>}
          <StyledCommentArea name="comment" label={t("park.comment") || ""} />
        </FormProvider>
      </StyledFormLayout>

      {isNewPage && (
        <Button
          color="brand"
          theme="primary"
          size="middle"
          isLoading={isLoading}
          onClick={onSubmitHandler}
          isDisabled={isLoading}
        >
          {t("profile.settings.save") || ""}
        </Button>
      )}
    </StyledApplicationsFormWrapper>
  );
};
