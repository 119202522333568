import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { useForm } from "@/hooks/useForm";
import { useNotification } from "@/hooks/useNotification";
import { useEffect } from "react";
import { transformValueToDTO } from "../../KiaFlexCar/KiaFlexExistCar/ExistsCarFields/utils";
import {
  DEFAULT_FILES,
  DEFAULT_IMAGES,
} from "../../KiaFlexCar/KiaFlexExistCar/ExistsCarForm/hook";
import {
  ExistCarFieldsName,
  IExistCarFromValues,
  InsuranceKasko,
  InsuranceOsago,
} from "../../KiaFlexCar/KiaFlexExistCar/ExistsCarForm/type";

const DEFAULT_EXIST_CAR_VALUES = {
  [ExistCarFieldsName.PTS]: null,
  [InsuranceOsago.NAME_INSURANCE]: null,
  [InsuranceKasko.NAME_INSURANCE]: null,
  [InsuranceOsago.POLICY_HOLDER]: null,
  [InsuranceKasko.POLICY_HOLDER]: null,
  [InsuranceOsago.SERIES_AND_NUMBER]: null,
  [InsuranceKasko.SERIES_AND_NUMBER]: null,
  [InsuranceOsago.MANAGMENT_ALL]: null,
  [InsuranceKasko.MANAGMENT_ALL]: null,
  [InsuranceOsago.TERM_INSURANSE_END]: null,
  [InsuranceKasko.TERM_INSURANSE_END]: null,
  [InsuranceOsago.TERM_INSURANSE_START]: null,
  [InsuranceKasko.TERM_INSURANSE_START]: null,
  [ExistCarFieldsName.PHOTO]: DEFAULT_IMAGES,
  [ExistCarFieldsName.FILE]: DEFAULT_FILES,
};

// export const formSchema = yup.object({
//   //   modelAuto: yup.string().required(),
//   //   typeTransmission: yup.string().required(),
//   //   engine: yup.string().required(),
//   //   equipment: yup.string().required(),
//   //   color: yup.string().required(),
// });

export type TOnSubmitExistCarForm = (values: IExistCarFromValues) => void;

export const useRegisterVehiclesForm = (props: {
  onSubmit: TOnSubmitExistCarForm | any;
  editableRecord?: TFlexOrder;
}) => {
  const { onSubmit, editableRecord } = props;

  const { createNotificationError } = useNotification();

  const onSubmitHandler = (values: IExistCarFromValues) => {
    const data = transformValueToDTO(values);

    if (data.insurance.length < 2) {
      createNotificationError("Обязательно нужно указать два вида страховки");
    } else {
      onSubmit(data);
    }
  };

  const result = useForm<any>({
    defaultValues: DEFAULT_EXIST_CAR_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;
  useEffect(() => {
    // if (editableRecord) {
    //   // reset(transformDTOtoRecord(editableRecord));
    // }
  }, [editableRecord, reset]);

  return result;
};
