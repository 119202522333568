import { colors, media } from "@/helpers";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Select } from "@/packages/formElements/inputs/Select";
import styled from "styled-components";

const StyledSelectField = styled(SelectField)`
  width: 250px;
`;

const StyledStatusSelect = styled(Select)<{ color: string }>`
  color: ${({ color }) => (color ? color : colors.gray100)};
`;

const StyledIconWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-items: center;
`;

const StyledTableSimple = styled.div`
  overflow: auto;
  padding-bottom: 99px;

  display: grid;
  justify-items: center;
  gap: 24px;
  width: 100%;
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  gap: 16px;
  button {
    width: 100%;
  }
  ${media.laptop} {
    flex-direction: row;

    button {
      width: fit-content;
    }
  }
  ${media.desktop} {
    margin-top: 24px;
  }
`;

const StyledData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMessage = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
`;

const StyledDescription = styled.div`
  margin-top: 12px;
`;

export {
  StyledDescription,
  StyledSelectField,
  StyledTableSimple,
  StyledData,
  StyledInfoBlock,
  StyledMessage,
  StyledStatusSelect,
  StyledIconWrapper,
};
