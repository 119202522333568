import React from "react";

import { Modal } from "@/packages/modal/Modal";
import { IModal } from "@/packages/modal/Modal";

import {
  StyledStatisticsModalWrapper,
  StyledStatisticsModalTitle,
  StyledStatisticsModalFiles,
  StyledStatisticsModalLink,
} from "./index.styles";
import { useTranslation } from "react-i18next";

interface IStatisticsModal
  extends Pick<IModal, "isOpen" | "onSubmit" | "onCancel"> {
  data: any;
}

export const StatisticsModal: React.FC<IStatisticsModal> = (props) => {
  const { data, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="small"
      title={t("park.publicationCountTitle") || ""}
      confirmButtonText={t("profile.settings.close") || ""}
      showCancelButton={false}
    >
      <StyledStatisticsModalWrapper>
        <StyledStatisticsModalTitle
          level={3}
          weight={400}
          color="blackSecondary"
        >
          {data ? data.carModel : ""} VIN: {data ? data.vin : ""}
        </StyledStatisticsModalTitle>
        <StyledStatisticsModalFiles>
          {data &&
            data.publications.map((publication) => (
              <StyledStatisticsModalLink key={publication.id}>
                {publication.link}
              </StyledStatisticsModalLink>
            ))}
        </StyledStatisticsModalFiles>
      </StyledStatisticsModalWrapper>
    </Modal>
  );
};
