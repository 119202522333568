import { components } from "@/helpers/api/swagger-scheme";

export enum MonitoringDataFormFieldsAppEnum {
  Vin = "vin",
  Mileage = "mileage",
  GenerationId = "generationId",
  CarProductionDate = "carProductionDate",
  IsSold = "isSold",
  CustomerСomplaint = "customerСomplaint",
  CarSoldDate = "carSoldDate",
  ReasonPetition = "reasonPetition",
  DiagnosticResult = "diagnosticResult",
  SuspectedDefect = "suspectedDefect",
  PhotoIds = "photoIds",
  FileIds = "fileIds",
}

export type TMonitoringApplicationDataFieldsSuccessValues = Omit<
  MonitoringDataFormFieldsAppEnum,
  | MonitoringDataFormFieldsAppEnum.Vin
  | MonitoringDataFormFieldsAppEnum.Mileage
  | MonitoringDataFormFieldsAppEnum.GenerationId
  | MonitoringDataFormFieldsAppEnum.CarProductionDate
  | MonitoringDataFormFieldsAppEnum.IsSold
  | MonitoringDataFormFieldsAppEnum.CustomerСomplaint
  | MonitoringDataFormFieldsAppEnum.CarSoldDate
  | MonitoringDataFormFieldsAppEnum.ReasonPetition
  | MonitoringDataFormFieldsAppEnum.DiagnosticResult
  | MonitoringDataFormFieldsAppEnum.SuspectedDefect
  | MonitoringDataFormFieldsAppEnum.PhotoIds
  | MonitoringDataFormFieldsAppEnum.FileIds
> & {
  [MonitoringDataFormFieldsAppEnum.Vin]: string;
  [MonitoringDataFormFieldsAppEnum.Mileage]: string;
  [MonitoringDataFormFieldsAppEnum.GenerationId]: string;
  [MonitoringDataFormFieldsAppEnum.CarProductionDate]: string;
  [MonitoringDataFormFieldsAppEnum.IsSold]: boolean;
  [MonitoringDataFormFieldsAppEnum.CustomerСomplaint]: boolean;
  [MonitoringDataFormFieldsAppEnum.CarSoldDate]: string;
  [MonitoringDataFormFieldsAppEnum.ReasonPetition]: string;
  [MonitoringDataFormFieldsAppEnum.DiagnosticResult]: string;
  [MonitoringDataFormFieldsAppEnum.SuspectedDefect]: string;
  [MonitoringDataFormFieldsAppEnum.PhotoIds]: components["schemas"]["File"][];
  [MonitoringDataFormFieldsAppEnum.FileIds]: components["schemas"]["File"][];
};
