import { LogsTableAdapter } from "@/adapters/logs/LogsTableAdapter";
import { useGetUsersLog } from "@/helpers/api/eventLogs/hooks";
import { usePagination } from "@/hooks/usePagination";
import { useNotification } from "@/hooks/useNotification";
import React, { useEffect } from "react";
import { AxiosError } from "axios";
import { ITab } from "@/packages/tabs/Tabs";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { TEventLogRecord } from "@/helpers/api/eventLogs/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { getLogMessage } from "@/components/logs/LogsTable/helpers";
import { t } from "i18next";

interface UserLogsType {
  isTabChanged: ITab | undefined;
  id: string;
}

export const UserLogs: React.FC<UserLogsType> = (props) => {
  const { isTabChanged, id } = props;

  const { createNotificationError } =
    useNotification();
  const { paginationParams, setPage } = usePagination();

  const {
    data: userLogs,
    isLoading,
    refetch,
  } = useGetUsersLog({
    ...paginationParams,
    stateId: id,
  });

  const handleSuccessRestore = () => {
    refetch();
  };

  const handeRestoreError = (error: AxiosError) => {
    createNotificationError(error.message);
  };

  useEffect(() => {
    refetch();
  }, [isTabChanged]);

  const headers: IHeaderItem<TEventLogRecord>[] = [
    {
      Header: t("models.logsModel.date") || "",
      accessor: "date",
      Cell: ({ row }) => new Date(row?.original?.date || "").toLocaleString(),
    },
    {
      Header: "Должность",
      accessor: "account",
    },
    {
      Header: t("models.logsModel.user") || "",
      accessor: "user",

      Cell: ({ row }) => {
        return (
          <div>
            <Paragraph size={14} weight={600}>
              {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
            </Paragraph>
          </div>
        );
      },
    },
    {
      Header: t("models.logsModel.change") || "",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <Paragraph size={12} weight={300}>
            {getLogMessage(
              row.original.action,
              row.original.entity,
              row.original
            )}
          </Paragraph>
        );
      },
    },
  ];

  return (
    <>
      <LogsTableAdapter
        headers={headers}
        isLoading={isLoading}
        data={userLogs?.data?.data || []}
        hasPagination={!!userLogs?.data.meta?.pageCount}
        setPage={setPage}
        paginationParams={{
          page: paginationParams.page,
          limit: userLogs?.data.meta?.pageCount || 10,
        }}
      />
    </>
  );
};
