import {
  HeaderArgType,
  IDataItem,
  SortOrder,
} from "@/packages/tableSimple/TableSimple";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StyledTable } from "./index.styles";
import { t } from "i18next";

export interface IMonitoringDealersTable {
  monitoringQualityDealersList: any;
  onSort: (header: HeaderArgType, order: SortOrder) => void;
}

export const MonitoringDealersTable: React.FC<IMonitoringDealersTable> = (
  props
) => {
  const params = useParams();
  const { monitoringQualityDealersList, onSort } = props;
  const navigate = useNavigate();

  const clickHandler = useCallback(
    (_, { id }: IDataItem) => {
      navigate(
        `/monitoring-quality/${params.id as string}/dealers-cars-info/${id}`
      );
    },
    [params.id, navigate]
  );

  const monitoringDealers = monitoringQualityDealersList?.map((quality) => {
    const {
      merchantName,
      cityName,
      warrantyCode,
      addingLastCarTime,
      submittedCarsCount,
      createdAt,
      familiarized,
      ...restFields
    } = quality;
    return {
      name: merchantName,
      city: cityName,
      code: warrantyCode,
      "adding-last-car-date": addingLastCarTime
        ? new Date(addingLastCarTime).toLocaleDateString()
        : null,
      "submitted-cars": submittedCarsCount,
      "created-at": createdAt,
      status: familiarized
        ? {
            label: t("monitoring.familiarized") || "",
            variantBtn: { background: "#B5D880", color: "#2F4A04" },
          }
        : {
            label: t("monitoring.notFamiliarized") || "",
            variantBtn: { background: "#D88080", color: "#4A0404" },
          },
      ...restFields,
    };
  });
  const headers = [
    {
      Header: t("monitoring.dealer"),
      accessor: "name",
      isSortable: true,
      minWidth: 50,
      maxWidth: 180,
    },

    {
      Header: t("monitoring.city"),
      accessor: "city",
      isSortable: true,
      minWidth: 50,
      maxWidth: 130,
    },

    {
      Header: t("monitoring.dealerCode") || "",
      accessor: "code",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: t("monitoring.adding-last-car-date") || "",
      accessor: "adding-last-car-date",
      isSortable: true,
      minWidth: 50,
      maxWidth: 180,
    },
    {
      Header: t("monitoring.submitted-cars") || "",
      accessor: "submitted-cars",
      isSortable: true,
      minWidth: 50,
      maxWidth: 70,
    },
    {
      Header: t("monitoring.status") || "",
      accessor: "status",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,

      Cell: ({ value }) => {
        // TODO: Использование dangerouslySetInnerHTML, мб есть замена
        const { label, variantBtn } = value;
        return (
          <span
            style={{
              padding: "4px 8px",
              background: variantBtn.background,
              borderRadius: "4px",
              textAlign: "center",
              color: variantBtn.color,
              marginRight: "15px",
            }}
          >
            {label}
          </span>
        );
      },
    },
  ];
  return (
    <StyledTable
      headers={headers}
      data={monitoringDealers ?? []}
      onSort={onSort}
      buttonIcons={["eye-open"]}
      onButtonClick={clickHandler}
    />
  );
};
