import { format } from "date-fns";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { NewsCard } from "@/components/NewsSingle/NewsTab";
import { TOnSubmitRegistryEditFormCb } from "@/components/RegistryDataFields/hooks";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { TRegistryRecord } from "@/helpers/api/dc/kia-holdings/types";
import { useDeleteNewsById, useGetNewsById } from "@/helpers/api/news/hooks";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { useNotification } from "@/hooks/useNotification";
import { StyledImagesContainer, StyledPhotosTab } from "./index.styled";
import { useTranslation } from "react-i18next";

export interface INews {
  data?: TDealerRecord[];
  onEdit: TOnSubmitRegistryEditFormCb;
  onDeleteClick: (record: TRegistryRecord) => void;
}

export const NewsSingle: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id as string;

  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const { data: newsResponse, isLoading } = useGetNewsById({ id });

  const { mutateAsync: deleteNewsByIdAsync } = useDeleteNewsById();

  const newsContent = newsResponse?.data;
  //@ts-ignore
  const currentGroupId = newsResponse?.data.group_id || "";

  const onDeleteClickHandler = async (newsId: string) => {
    try {
      await deleteNewsByIdAsync({
        id: newsId,
      });
      navigate(`/news?activeTab=${currentGroupId}`);
    } catch {
      createNotificationError("Произошла ошибка при удалении новости");
    }
  };

  return (
    <DashboardLayout
      title={t("news.title") || ""}
      toBack={`/news?activeTab=${currentGroupId}`}
    >
      <StyledPhotosTab>
        <StyledImagesContainer>
          {isLoading ? (
            <p>Loading...</p>
          ) : newsContent?.id ? (
            <NewsCard
              id={newsContent.id}
              currentGroupId={currentGroupId}
              imgUrl={newsContent?.photo?.fileUrl}
              authorId={newsContent?.createdBy?.id}
              author={`${newsContent?.createdBy?.firstName} ${newsContent?.createdBy?.lastName}`}
              title={newsContent.name}
              text={newsContent.text}
              description={newsContent.description}
              createdAt={format(
                Date.parse(newsContent.createdAt),
                "dd.MM.yyyy"
              )}
              onEdit={() => {}}
              onDelete={onDeleteClickHandler}
            />
          ) : (
            <p>News not found</p>
          )}
        </StyledImagesContainer>
      </StyledPhotosTab>
    </DashboardLayout>
  );
};
