import { formatDateToPeriod } from "@/helpers/utils";
import { IconButton } from "@/packages/icon-button/IconButton";

const statusesQuality = [
  { value: "open", label: "Открыта" },
  { value: "search-completed", label: "Поиск завершен" },
  { value: "closed", label: "Закрыта" },
];

export const generateQualityMonitoringData = (quality) => {
  if (quality) {
    const {
      status,
      generations,
      primaryRequirements,
      additionalRequirements,
      carProductionDateFrom,
      carProductionDateTo,
      carSearchPeriodFrom,
      carSearchPeriodTo,
      dateOfTheStudyFrom,
      dateOfTheStudyTo,
      files,
    } = quality;
    return [
      {
        title: "Статус",
        description:
          statusesQuality.find((el) => el.value === status)?.label || null,
      },
      {
        title: "Модель",
        description: generations
          ?.map(
            (el) =>
              `${el?.model?.name || ""} ${el?.name || ""} ${
                el?.restylingName || ""
              }`
          )
          .join(", "),
      },
      {
        title: "Основные требования",
        description: primaryRequirements,
      },
      {
        title: "Дополнительные требования",
        description: additionalRequirements,
      },
      {
        title: "Даты производства автомобиля",
        description: formatDateToPeriod(
          carProductionDateFrom,
          carProductionDateTo
        ),
      },
      {
        title: "Период поиска автомобиля",
        description: formatDateToPeriod(carSearchPeriodFrom, carSearchPeriodTo),
      },
      {
        title: "Даты проведения иследования",
        description: formatDateToPeriod(dateOfTheStudyFrom, dateOfTheStudyTo),
      },
      {
        title: "Фото",
        description: files.length
          ? files.map((file) => (
              <IconButton
                key={file.id}
                size="l"
                icon="dropzone-icon-image"
                color="black"
                variant="tertiary"
              />
            ))
          : "Нет загруженных файлов",
      },
    ];
  }
};
