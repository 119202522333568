import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col } from "antd";
import React, { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useAuditFilterForm } from "./hooks";
import { Button } from "@/packages/button/Button";
import {
  ColumnWraper,
  StyledButton,
  StyledButtons,
  StyledFilters,
  StyledRow,
  StyledSeparator,
} from "./index.styles";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { preparedDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { IRequestParams } from "@/helpers/interfaces";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { components } from "@/helpers/api/swagger-scheme";
import { useGetUserList } from "@/helpers/api/users/hooks";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetWarrantyAuditorsList } from "@/helpers/api/warranty-audit/audit-list/hooks";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface IWarrantyAuditListFilter {
  onFilterChange: (value: IRequestParams) => void;
  filterFields?: Array<"type" | "status" | "auditor">;
  specialFields?: Array<"responsible">;
  responsibleData?: components["schemas"]["User"];
  statusOptions?: ISelectOption[];
}

const MOCK_AUDIT_TYPE = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "KIA",
    value: "kia",
  },
  {
    label: "warrantyAudits.list.dealer",
    value: "dealer",
  },
  {
    label: "factory",
    value: "factory",
  },
  {
    label: "warrantyAudits.list.extAuditor",
    value: "external auditor",
  },
];

const MOCK_AUDIT_STATUS = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "audits.list.new",
    value: "Новый",
  },
  {
    label: "audits.list.active",
    value: "Активный",
  },
  {
    label: "audits.list.closed",
    value: "Закрытый",
  },
  {
    label: "audits.list.archived",
    value: "В архиве",
  },
];

const WarrantyAuditPlanFilter: React.FC<IWarrantyAuditListFilter> = (props) => {
  const {
    onFilterChange,
    filterFields = ["type", "status"],
    specialFields = [],
    statusOptions,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const { data: users} = useGetUserList({
    permissionSlug: "guarantee.dealer-violations-actions",
    permissionAction: "update",
    limit: 0,
  });

  const mappedUsers = useMemo(
    () =>
      users?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [],
    [users]
  );

  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });

  const { data: dealerList} =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const { data: auditorsList, isLoading: isAuditorsLoading } =
    useGetWarrantyAuditorsList({ "page[limit]": 0 });

  const onFilterApply = (data) => {
    let params = {};
    Object.entries(data).forEach(([key, value]) => {
      params = {
        ...params,
        [key]: {
          value,
          type: "filter",
        },
      };
    });
    onFilterChange(params);
  };

  const { form, onClearHandler, onSubmitHandler } =
    useAuditFilterForm(onFilterApply);

  const clearFilters = () => {
    onClearHandler();
    onFilterApply({});
  };

  const preparedHoldingList = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );

  const preparedAuditorsList = useMemo(
    () =>
      auditorsList?.data?.data?.map((item) => ({
        // @ts-ignore на беке дто от списка аудитов
        label: `${item.firstName}  ${item.lastName}`,
        value: item.id,
      })) || [],
    [auditorsList?.data, isAuditorsLoading]
  );

  return (
    <StyledFilters isOpen={isOpen}>
      <FormProvider {...form}>
        <StyledRow gutter={16} space={24}>
          {filterFields.includes("type") && (
            <Col xs={8} md={4}>
              <SelectField
                label={t("audits.list.auditType") || ""}
                placeholder={t("audits.list.all") || ""}
                options={MOCK_AUDIT_TYPE}
                onChange={(value) => {
                  onFilterChange({
                    type: {
                      value,
                      type: "filter",
                    },
                  });
                }}
              />
            </Col>
          )}
          {filterFields.includes("status") && (
            <Col xs={8} md={4} xxl={3}>
              <SelectField
                label={t("audits.list.auditStatus") || ""}
                placeholder={t("audits.list.all") || ""}
                options={statusOptions || MOCK_AUDIT_STATUS}
                onChange={(value) => {
                  onFilterChange({
                    status: {
                      value,
                      type: "filter",
                    },
                  });
                }}
              />
            </Col>
          )}
          {filterFields.includes("auditor") && (
            <Col xs={8} md={4} xxl={3}>
              <SelectField
                label={t("audits.list.auditorId") || ""}
                options={preparedAuditorsList}
                onChange={(value) => {
                  onFilterChange({
                    auditor: {
                      value,
                      type: "filter",
                    },
                  });
                }}
                placeholder={t("audits.list.choose") || ""}
              />
            </Col>
          )}
          <Col>
            <StyledButton
              icon="filter"
              color="black"
              size="m"
              isSquared={true}
              variant={isOpen ? "primary" : "secondary"}
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={24} md={8}>
            <FormSelect
              name={t("audits.list.holdingId") || ""}
              label={t("audits.list.holdingId") || ""}
              options={preparedHoldingList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
            />
          </Col>
          <Col xs={24} md={7}>
            <FormSelect
              name="dealerId"
              label={t("audits.list.dealerId") || ""}
              options={preparedDealerList(dealerList?.data?.data || [])}
              placeholder={t("audits.list.chooseList") || ""}
              onSearch={setSearchValue}
              isSearchShow
              allowClear
            />
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          {!filterFields.includes("auditor") && (
            <Col xs={24} md={8}>
              <FormSelect
                name="auditorId"
                label={t("audits.list.auditorId") || ""}
                options={preparedAuditorsList}
                placeholder={t("audits.list.choose") || ""}
              />
            </Col>
          )}
          {specialFields.includes("responsible") && (
            <Col xs={24} md={8}>
              <FormSelect
                name="responsibleId"
                label={t("audits.tasks.responsibleId") || ""}
                options={mappedUsers || []}
                placeholder={t("audits.list.choose") || ""}
                isSearchShow
              />
            </Col>
          )}
          <Col xs={24} md={8}>
            <ColumnWraper>
              <FormDatepickerField
                name="startCreatedAt"
                label={t("audits.list.date") || ""}
              />
              <StyledSeparator />
              <FormDatepickerField name="endCreatedAt" label={" "} />
            </ColumnWraper>
          </Col>
        </StyledRow>
        <StyledButtons>
          <Button
            color="brand"
            theme="primary"
            size="middle"
            onClick={onSubmitHandler}
          >
            {t("audits.list.find") || ""}
          </Button>
          <Button
            color="brand"
            theme="secondary"
            size="middle"
            onClick={clearFilters}
          >
            {t("audits.list.clear") || ""}
          </Button>
        </StyledButtons>
      </FormProvider>
    </StyledFilters>
  );
};

export default WarrantyAuditPlanFilter;
