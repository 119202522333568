import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled from "styled-components";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectField = styled(SelectField)`
  width: 188px;
`;

const StyledTableSimple = styled.div`
  display: grid;
  justify-items: center;
`;

const StyledButtons = styled(Space)``;

const StyledSelect = styled.div`
  width: 107px;
`;

export {
  StyledSelect,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
};
