import { IModal, Modal } from "@/packages/modal/Modal";
import { FC, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";

import { InformationalLettersDataFields } from "../InformationLettersDataFields";
import { useEditIPForm } from "../InformationLettersEditData/hooks";
import { useTranslation } from "react-i18next";

export interface IInformationLettersEditModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  record?: any;
  onSubmit: any;
}

const InformationLettersEditModal: FC<IInformationLettersEditModal> = (
  props
) => {
  const { onSubmit, record, onCancel, ...rest } = props;
  const [removeList, setRemoveList] = useState<string[]>([]);
  const { t } = useTranslation();
  const { form, submit } = useEditIPForm({
    editableRecord: record,
    onSubmit,
    removeList,
  });

  const handleRemove = (id?: string) => {
    id &&
      setRemoveList((prev) => {
        return [...prev.filter((item) => item !== id), id];
      });
  };
  useEffect(() => {
    return () => {
      setRemoveList([]);
    };
  }, []);

  return (
    <Modal
      {...props}
      title={t("dealerMaterials.letters.editIP") || ""}
      confirmButtonText={t("dealerMaterials.letters.save") || ""}
      cancelButtonText={t("dealerMaterials.letters.close") || ""}
      onSubmit={submit}
      onCancel={() => onCancel && onCancel()}
    >
      <FormProvider {...form}>
        <InformationalLettersDataFields
          mode="edit"
          record={record}
          handleRemove={handleRemove}
        />
      </FormProvider>
    </Modal>
  );
};

export { InformationLettersEditModal };
