import React, { useEffect, useState } from "react";
import { useGetWarrantyQuestions, usePatchAnswerWarranty, } from "@/helpers/api/warranty-audit/fill/hooks";
import { mutateArrOfObj, requestParamsMapper } from "@/helpers/utils";
import { useRequestParams } from "@/hooks/useRequestParams";

import { TableSimple } from "@/packages/tableSimple/TableSimple";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";
import { getDefaultWarrantyAnswerTableHeaders } from "./helpers";
import { TPatchAnswerWarrantyRequest } from "@/helpers/api/warranty-audit/fill/types";
import { AuditWarrantyTableAdapter } from "../../../AuditWarrantyTableAdapter";
import { usePagination } from "@/hooks/usePagination";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { getRowStatisticsWarranty } from "../../DppoParams";
import { getWarrantyDocsTableHeaders } from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/helpers";
import { StyledInputField } from "@/pages/AuditWarrantyTemplates/index.styles";
import { t } from "i18next";
import { useDebounce } from "use-debounce";
import { StyledNestedQuestions } from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/index.styles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useModal } from "@/hooks/useModal";
import {
  AllClaimsWarrantyModal
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/AllClaimsWarrantyModal";
import {
  useAnswerProvider
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsSpares/provider";

interface INestedQuestions {
  id: number;
  trigger: boolean;
  isKiaProfileType: boolean;
  type: "Гарантийная документация" | "Запасные части";
  openChat: (params: IMessageParams) => void;
}

export const NestedQuestionsWarranty: React.FC<INestedQuestions> = (props) => {
  const { id, type, trigger, isKiaProfileType, openChat } = props;
  const { mutate: answerQuestion } = usePatchAnswerWarranty();

  const [requestParams] = useRequestParams({});
  const { paginationParams, setPage } = usePagination();

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500);

  const {
    modalState: claimModelState,
    openModal: claimModelOpen,
  } = useModal<void, { claimSerial: string }>();

  const {
    data: questionsKia,
    isLoading: isQuestionsLoadingKia,
    refetch: updateQuestionsKia,
  } = useGetWarrantyQuestions(
    {
      auditId: id,
      params: {
        ...requestParamsMapper(requestParams),
        "filter[type]": type,
        "page[limit]": paginationParams.limit,
        "page[page]": paginationParams.page,
        "filter[search]": debouncedSearch,
      },
    },
    isKiaProfileType
  );

  const headers = getWarrantyDocsTableHeaders({ claimModelOpen });

  const handleAnswerQuestion = (patch: TPatchAnswerWarrantyRequest) => {
    answerQuestion({
      patch,
      onSuccess: updateQuestionsKia,
    });
  };

  const { state: answers, dispatch: setAnswers } = useAnswerProvider();


  const questionsHeaders = getDefaultWarrantyAnswerTableHeaders({
    openChat,
    answerQuestion: handleAnswerQuestion,
    answers,
    setAnswers,
  });
  useEffect(() => {
    updateQuestionsKia();
  }, [trigger]);

  return (
    <StyledNestedQuestions>
      <StyledInputField
        label={t("common.searchParams") || ""}
        onChange={(value) => setSearch(value)}
      />
      <AuditWarrantyTableAdapter
        isCutVerticalPadding
        withOverflow={false}
        isStickyRow
        paginationParams={{
          ...paginationParams,
          limit: questionsKia?.data.meta?.pageCount || 0,
        }}
        setPage={setPage}
        isLoading={isQuestionsLoadingKia}
        data={questionsKia?.data.data || []}
        headers={headers}
        renderElement={(row) => {
          const sections = mutateArrOfObj(row.original.sections || []);
          return (
            <DndProvider backend={HTML5Backend}>
              <TreeComponent
                hasInfo={true}
                data={sections || []}
                paramKey="params"
                headerCallback={getRowStatisticsWarranty}
                renderComponent={({ data }) => (
                  <TableSimple
                    headers={questionsHeaders || []}
                    data={data || []}
                    isCutVerticalPadding
                  />
                )}
              />
            </DndProvider>
          );
        }}
      />
      <AllClaimsWarrantyModal
        auditId={id}
        {...claimModelState}
      />
    </StyledNestedQuestions>
  );
};
