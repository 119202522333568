import { TFactorZ } from "@/helpers/api/bonuses/calculations/types";
import { IHeaderItem } from "@/packages/stickyTable/StickyTable";
import { StyledName } from "../ReportsCalculation/BonusesTableAdapter/index.styles";
import { t } from "i18next";

export const getBonusesCalculationHeaders = <TRecord extends object>({
  moduleA,
  moduleC,
}): IHeaderItem<TFactorZ>[] => {
  return [
    {
      Header: t("bonuses.reportsView.dealerCenter") || "",
      accessor: "dealerName",
      sticky: "left",
      width: 400,
      defaultCanSort: true,
      isSearchable: true,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledName>
            {`${original.mobisCode} ${original.dealerName}`}
          </StyledName>
        );
      },
    },
    {
      Header: t("bonuses.reportsView.CoeffA") || "",
      accessor: "npsAvg",
      tip: "NPS",
      width: 25,
      defaultCanSort: true,
    },
    ...(moduleA
      ? [
          {
            Header: t("bonuses.reportsView.monthOne") || "",
            accessor: "monthOne",
            width: 25,
            defaultCanSort: true,
          },
          {
            Header: t("bonuses.reportsView.monthTwo") || "",
            accessor: "monthTwo",
            width: 25,
            defaultCanSort: true,
          },
          {
            Header: t("bonuses.reportsView.monthThree") || "",
            accessor: "monthThree",
            width: 25,
            defaultCanSort: true,
          },
        ]
      : []),
    {
      Header: t("bonuses.reportsView.weightA") || "",
      accessor: "coefficientA",
      width: 25,
      defaultCanSort: true,
    },
    {
      Header: "B",
      accessor: "auditPercent",
      width: 25,
      defaultCanSort: true,
      tip: t("bonuses.reportsView.auditResults") || "",
    },
    {
      Header: t("bonuses.reportsView.weightB") || "",
      accessor: "coefficientB",
      width: 25,
      defaultCanSort: true,
    },
    {
      Header: "C",
      accessor: "avgEducationPercent",
      width: 25,
      defaultCanSort: true,
    },
    ...(moduleC
      ? [
          {
            Header: "KSAP",
            accessor: "ksapPercent",
            width: 25,
          },
          {
            Header: "96%",
            accessor: "ksapNormalizedPercent",
            width: 25,
          },
          {
            Header: "KSAP",
            accessor: "ksmpPercent",
            width: 25,
          },
          {
            Header: "96%",
            accessor: "ksmpNormalizedPercent",
            width: 50,
          },
          {
            Header: "TRP",
            accessor: "trpPercent",
            width: 50,
          },
          {
            Header: "55%",
            accessor: "trpNormalizedPercent",
            width: 50,
          },
        ]
      : []),
    {
      Header: t("bonuses.reportsView.weightC") || "",
      accessor: "coefficientC",
      width: 25,
      defaultCanSort: true,
      tip: t("bonuses.reportsView.plan") || "",
    },
    {
      Header: "Z",
      accessor: "coefficientZ",
      width: 25,
      defaultCanSort: true,
      tip: t("bonuses.reportsView.reductionFactor") || "",
    },
  ];
};
