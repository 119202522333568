import { CardInfo } from "@/packages/CardInfo";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ConfirmRegisterInfoCard: React.FC = () => {
  const navigate = useNavigate();
  const onClickConfirmButton = () => {
    navigate("/login");
  };

  return (
    <CardInfo
      title="Ожидается подтверждение Вашей учетной записи"
      description="Пожалуйста, подождите, вы сможете продолжить как только будет получено подтверждение добавления учетной записи от ответственного сотрудника"
      confirmButton="Продолжить"
      onClickConfirmButton={onClickConfirmButton}
    />
  );
};
