import { TModelRecord } from "@/helpers/api/models/types";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormSwitchField } from "@/packages/formElements/formFields/FormSwitchField";
import { FormYearRangeInputField } from "@/packages/formElements/formFields/FormYearRangeInputField";
import { Col, Row } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { StyledFormLayout, StyledGenerationFieldsWrapper, } from "./index.styles";
import { GenerationFieldsEnum } from "./types";
import { useTranslation } from "react-i18next";
import { FormCodeListField } from "@/packages/formElements/formFields/FormCodeListField";

const GENERATION_NUMBER_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

interface IGenerationFields {
  isReadOnly?: boolean;
  parentModel?: TModelRecord;
}

const _GenerationFields: React.FC<IGenerationFields> = (props) => {
  const { isReadOnly = false, parentModel } = props;

  const { watch, setValue } = useFormContext();

  const { t } = useTranslation();

  const isRestyling = watch(GenerationFieldsEnum.IsRestyling);

  return (
    <StyledGenerationFieldsWrapper>
      <Row gutter={16}>
        <Col span={8}>
          <FormImageDropzoneField
            label={t("models.addNewGenerationModel.photo") || ""}
            description={t("models.addNewGenerationModel.photoDescr") || ""}
            name={GenerationFieldsEnum.Photo}
            path="generations/photos"
            onChange={(response) => {
              setValue(GenerationFieldsEnum.Photo, response);
            }}
            disabled={isReadOnly}
            allowedTypes={["image/png", "image/jpeg", "image/tiff"]}
          />
        </Col>
        <Col span={16}>
          <StyledFormLayout>
            <Row gutter={16}>
              <Col span={12}>
                <FormInputField
                  name={GenerationFieldsEnum.Name}
                  label={t("models.addNewGenerationModel.name") || ""}
                  description={
                    t("models.addNewGenerationModel.nameDescr") || ""
                  }
                  readOnly={isReadOnly}
                />
              </Col>
              <Col span={12}>
                <FormInputField
                  name={GenerationFieldsEnum.MaterialCode}
                  label={t("models.addNewGenerationModel.mpv") || ""}
                  description={t("models.addNewGenerationModel.mpvDescr") || ""}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <FormYearRangeInputField
                  name={GenerationFieldsEnum.GenerationYearRange}
                  label={t("models.addNewGenerationModel.date") || ""}
                  description={
                    t("models.addNewGenerationModel.dateDescr") || ""
                  }
                  placeholder={[
                    t("models.addNewGenerationModel.year") || "",
                    t("models.addNewGenerationModel.rightNow") || "",
                  ]}
                  readOnly={isReadOnly}
                  min={parentModel?.modelYearStart}
                  max={parentModel?.modelYearEnd}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormSelect
                  name={GenerationFieldsEnum.Number}
                  label={t("models.addNewGenerationModel.generationNum") || ""}
                  options={GENERATION_NUMBER_OPTIONS}
                  description={
                    t("models.addNewGenerationModel.generationNumDescr") || ""
                  }
                  readOnly={isReadOnly}
                />
              </Col>
              <Col span={12}>
                <FormCodeListField
                  name={GenerationFieldsEnum.VinModelClasses}
                  label={t("models.addNewGenerationModel.VIN_2") || ""}
                  description={t("models.addNewGenerationModel.VIN_2_Description") || ""}
                  placeholder="G1"
                  addButtonText={t("models.addNewGenerationModel.VIN_2_Btn") || ""}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>

            <Row gutter={16} align="middle">
              <Col span={12}>
                <FormSwitchField
                  name={GenerationFieldsEnum.IsRestyling}
                  label={t("models.addNewGenerationModel.restyling_L") || ""}
                  readOnly={isReadOnly}
                />
              </Col>
              <Col span={12}>
                <FormInputField
                  name={GenerationFieldsEnum.RestylingName}
                  label={
                    t("models.addNewGenerationModel.restyling_L_name") || ""
                  }
                  description={
                    t("models.addNewGenerationModel.restyling_L_nameDescr") ||
                    ""
                  }
                  placeholder={
                    t("models.addNewGenerationModel.restyling_L_placeholder") ||
                    ""
                  }
                  isDisabled={!isRestyling}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>
          </StyledFormLayout>
        </Col>
      </Row>
    </StyledGenerationFieldsWrapper>
  );
};

export const GenerationFields = React.memo(_GenerationFields);
