import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TMediaRecord } from "@/helpers/api/mediaBase/types";

import { requiredSelectSimpleValueSchema } from "@/helpers/validation";
import { t } from "i18next";

export type MediaBaseFormValues = {
  name: string | null;
  type: string | null;
};

export type TOnSubmitMediaBaseFormHandler = (
  data: TMediaRecord,
  isCreate: boolean
) => void;

const DEFAULT_MEDIA_BASE_FORM_VALUES: MediaBaseFormValues = {
  name: null,
  type: null,
};

export const formSchema = () => {
  return yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("park.enterName") || ""),
    type: requiredSelectSimpleValueSchema(t("park.sectionRequired") || ""),
  });
};

export const useMediaBaseModalForm = (
  onSubmit: TOnSubmitMediaBaseFormHandler,
  defaultValues: any
) => {
  const form = useForm<MediaBaseFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: defaultValues || DEFAULT_MEDIA_BASE_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(
      {
        id: defaultValues ? defaultValues.id : "",
        name: data.name,
        type: data.type,
      } as TMediaRecord,
      defaultValues ? false : true
    );
  });

  return {
    form,
    onSubmitHandler,
  };
};
