import { colors } from "@/helpers/index";
import { Checkbox as AntdCheckbox, CheckboxProps } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Paragraph } from "../paragraph/Paragraph";

type TAlign = "baseline" | "center";
export interface ICheckbox {
  name?: string;
  label?: string;
  value?: boolean;
  error?: string;
  align?: TAlign;
  description?: string;
  isDisabled?: boolean;
  multiValue?: IMultiValue;
  multiValues?: IMultiValue[];
  multiValueColor?: TCheckboxMultiValueColor;
  color?: TCheckboxColor;
  readOnly?: boolean;
  gsmCheckMark?: boolean;
  onChange?: (value: boolean) => void;
  onMultiChange?: (value: IMultiValue) => void;
  fontSize?: string;
  children?: ReactNode;
}

type TCheckboxColor = "gray" | "white";
export type TCheckboxMultiValueColor = "orange" | "green" | "blue" | "default";
export interface IMultiValue {
  color: TCheckboxMultiValueColor;
  value: boolean;
}

interface ICheckboxStyled extends CheckboxProps {
  isError?: boolean;
  color: TCheckboxColor;
  multiColor?: TCheckboxMultiValueColor;
  align: TAlign;
  readOnly: boolean;
}

const StyledDescription = styled.div``;

const StyledCheckbox = styled(AntdCheckbox)<ICheckboxStyled>`
  position: relative;
  align-items: ${({ align }) => align};

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${colors.brand};
      }
    }
  }

  .ant-checkbox-input {
    &:focus {
      + .ant-checkbox-inner {
        border-color: ${colors.brand};
      }
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-color: ${colors.brand};
    }
    .ant-checkbox-inner {
      background-color: ${colors.brand};
      border: 1px solid ${colors.brand};
    }
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${colors.white};
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.gray300};
    border: 1px solid ${colors.gray300};
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      .ant-checkbox,
      .ant-checkbox-input {
        cursor: default;
      }

      .ant-checkbox-inner {
        border: 1px solid ${colors.gray6};
      }

      cursor: default;
      color: ${colors.gray5};
    `}

  ${({ color, multiColor = "default" }) => {
    let colorStyles: any;
    let multiColorStyles: any;

    switch (color) {
      case "gray":
        colorStyles = css`
          .ant-checkbox-inner {
            border: 1px solid ${colors.gray300};
          }
          &:hover {
            .ant-checkbox-inner {
              border: 1px solid ${colors.gray400};
            }
          }
        `;
        break;

      case "white":
      default:
        colorStyles = css`
          .ant-checkbox-inner {
            border: 1px solid ${colors.gray300};
          }
          &:hover {
            .ant-checkbox-inner {
              border: 1px solid ${colors.gray400};
            }
          }
        `;
        break;
    }

    switch (multiColor) {
      case "blue":
        multiColorStyles = css`
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: ${colors.blue};
              border: 1px solid ${colors.blue};
            }

            &:hover {
              &::after {
                border-color: ${colors.blue};
              }
            }
          }
        `;
        break;

      case "orange":
        multiColorStyles = css`
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: ${colors.orange};
              border: 1px solid ${colors.orange};
            }

            &:hover {
              &::after {
                border-color: ${colors.orange};
              }
            }
          }
        `;
        break;

      case "green":
        multiColorStyles = css`
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: ${colors.green100};
              border: 1px solid ${colors.green100};
            }

            &:hover {
              &::after {
                border-color: ${colors.green100};
              }
            }
          }
        `;
        break;

      case "default":
      default:
        break;
    }

    return [multiColorStyles, colorStyles];
  }}

  ${({ isError }) =>
    isError &&
    css`
      .ant-checkbox-inner {
        border: 1px solid ${colors.red200} !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${colors.red200};
      }
    `}

  :focus-visible {
    outline: 1px solid ${colors.brand};
    border-radius: 2px;
  }

  ${({ children }) =>
    !children &&
    css`
      width: 16px;
      height: 16px;
    `}
`;

const CustomGsmCheckContainer = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: orange;
  border-radius: 2px;
  color: ${colors.white};
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

const WrappedCheckbox = styled.div`
  width: fit-content;
`;
const StyledError = styled.p`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.red200};
  margin: 4px 0 0 0;
`;

const StyledLabel = styled.div<{ fontSize: string }>`
  font-size: 14px;
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}
`;

export const Checkbox: React.FC<ICheckbox> = (props) => {
  const {
    name,
    label,
    error,
    value,
    align = "baseline",
    isDisabled = false,
    readOnly = false,
    multiValue,
    multiValues,
    onMultiChange,
    description,
    gsmCheckMark = false,
    color = "white",
    onChange,
    fontSize = "14",
    children,
  } = props;

  const [activeMultiValueIndex, setActiveMultiValueIndex] = useState<number>(0);
  const [activeMultiValue, setActiveMultiValue] = useState<IMultiValue | null>(
    null
  );

  const onChangeHandler = React.useCallback(
    (event: CheckboxChangeEvent) => {
      if (multiValues) {
        setActiveMultiValueIndex((prev) =>
          prev < multiValues.length - 1 ? prev + 1 : 0
        );
      } else {
        onChange && onChange(event.target.checked);
      }
    },
    [onChange, onMultiChange]
  );

  useEffect(() => {
    if (!activeMultiValue || !multiValues) return;

    const tempValue = multiValues[activeMultiValueIndex];

    setActiveMultiValue(tempValue);
    onMultiChange && onMultiChange(tempValue);
  }, [activeMultiValueIndex]);

  useEffect(() => multiValue && setActiveMultiValue(multiValue), [multiValue]);

  return (
    <WrappedCheckbox>
      <StyledCheckbox
        align={align}
        multiColor={activeMultiValue?.color}
        color={color}
        name={name}
        checked={activeMultiValue ? activeMultiValue.value : value}
        disabled={isDisabled}
        readOnly={readOnly}
        isError={!!error}
        onChange={!readOnly ? onChangeHandler : undefined}
      >
        {value && gsmCheckMark ? (
          <CustomGsmCheckContainer>
            <Paragraph size={12}>R</Paragraph>
          </CustomGsmCheckContainer>
        ) : null}
        {children ? children : null}
        <StyledLabel fontSize={fontSize}>{label}</StyledLabel>
      </StyledCheckbox>
      {description && label ? (
        <Paragraph size={12} color="gray4">
          {description}
        </Paragraph>
      ) : (
        <></>
      )}

      {error && label ? <StyledError>{error}</StyledError> : <></>}
    </WrappedCheckbox>
  );
};
