import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledChat = styled.div`
  height: 100%;
  border-radius: 14px;
  border: 1px solid #e4e7ec;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-height: 454px;
`;

export const StyledChatBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 7px;
  width: 100%;
  max-height: 369px;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;

export const StyledAttachmentsContainer = styled.div<{ isMyMessage: boolean }>`
  margin: 0 54px 5px 54px;
  align-self: ${({ isMyMessage }) => (isMyMessage ? "flex-end" : "flex-start")};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  max-width: 200px;
`;

export const StyledFile = styled.div<{ isMyMessage: boolean }>`
  width: 96px;
  height: 96px;
`;

export const StyledDate = styled.div`
  padding: 1px 12px 0;
  background: #eeeff2;
  border-radius: 4px;
  margin: 0 auto;
  font-size: 10px;
  line-height: 16px;
  font-weight: 300;
`;

export const StyledFilePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 93px;
  background-color: ${colors.blue100};
`;
