import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
export const fileFormDTO = (values: any) => {
  const { photos, files } = values;
  return {
    photoIds: [
      photos?.map((photo) => ({ value: photo, type: "photoIds" })),
      files?.map((file) => ({ value: file, type: "fileIds" })),
    ].flat(),
  };
};

export const useFileForm = (record: any) => {
  const result = useForm<any>({
    defaultValues: { photoIds: [] },
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (record) {
      reset(fileFormDTO(record));
    }
  }, [record, reset]);

  return result;
};
