import { Button } from "@/packages/button/Button";
import styled from "styled-components";
import { Input } from "@/packages/formElements/inputs/Input";
import { Field } from "@/packages/formElements/fields/Field";
import { Icon } from "@/packages/icon/Icon";

export const StyledControlContainer = styled(Field.FieldControlContainer)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAddButton = styled(Button)`
  margin-top: 30px;
  border: none;
  box-shadow: none;
`;

export const StyledInputWithDeleteContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  flex: 1;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;
