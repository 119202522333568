import { usePatchMonitoringQualityCarStatus } from "@/helpers/api/monitoringQuality/hooks";
import { TMonitoringQualityCarsRecord } from "@/helpers/api/monitoringQuality/types";
import { formatDateToPeriod } from "@/helpers/utils";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import {
  HeaderArgType,
  IDataItem,
  SortOrder,
} from "@/packages/tableSimple/TableSimple";
import { MenuProps } from "antd";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MonitoringCarsTableExpandDataForm } from "../MonitoringCars/MonitoringCarsTable/MonitoringCarsTableExpandDataForm";
import { generateExpandDataCars } from "../MonitoringCars/MonitoringCarsTable/utils";
import { StyledTable } from "./index.styles";
import { generateMenuItems, Statuses, statusesInTable } from "./utils";
import { t } from "i18next";
import { AxiosError } from "axios";
import { usePermission } from "@/hooks/usePermission";

export interface IMonitoringDealersInfoTable {
  monitoringQualityCarsList: TMonitoringQualityCarsRecord[] | any;
  refetchMonitoringCars: any;
  onSort: (header: HeaderArgType, order: SortOrder) => void;
}

export const MonitoringDealersInfoTable: React.FC<
  IMonitoringDealersInfoTable
> = (props) => {
  const { monitoringQualityCarsList, onSort, refetchMonitoringCars } = props;
  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { hasAccess: hasEdit } = usePermission(
    "quality-monitoring.request-cars",
    "update"
  );

  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const {
    mutateAsync: updateStatusMonitoringCar,
    isLoading: isStatusUpdatingLoading,
  } = usePatchMonitoringQualityCarStatus();

  const clickHandler = (_, { id }: IDataItem) => {
    navigate(
      `/monitoring-quality/${params.id as string}/dealers-cars-info/${id}`
    );
  };

  const updateStatusAsync = async (id: string, status: Statuses) => {
    try {
      await updateStatusMonitoringCar({ id, patch: status });
      createNotificationSuccess(t("monitoring.statusSuccess") || "");
      refetchMonitoringCars();
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(
        errorTyped.response?.data.message ||
          errorTyped.message ||
          t("monitoring.statusError") ||
          ""
      );
    }
  };

  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    if (typeof key === "string") {
      const id = key.split(",")[1];
      switch (key.split(",")[0]) {
        case Statuses.TO_BE_AGREED:
          updateStatusAsync(id, Statuses.TO_BE_AGREED);
          break;
        case Statuses.APPROVED:
          updateStatusAsync(id, Statuses.APPROVED);
          break;
        case Statuses.SENT_FOR_REVISION:
          updateStatusAsync(id, Statuses.SENT_FOR_REVISION);
          break;
        case Statuses.WITHDRAWN:
          updateStatusAsync(id, Statuses.WITHDRAWN);
          break;
        case Statuses.REJECTED:
          updateStatusAsync(id, Statuses.REJECTED);
          break;
        default:
          navigate(`${key}`);
          break;
      }
    }
  };

  const requestCars = monitoringQualityCarsList?.map((qualityCar) => {
    const {
      id,
      generation,
      qualityMonitoring,
      vin,
      mileage,
      status,
      carProductionDate,
      files,
    } = qualityCar;
    const { dateOfTheStudyFrom, dateOfTheStudyTo } = qualityMonitoring;

    return {
      id,
      vin,
      model: `${generation.model.name} ${generation.name} ${
        generation.restylingName || ""
      }`,
      carProductionDate,
      mileage,
      programEndDate: formatDateToPeriod(dateOfTheStudyFrom, dateOfTheStudyTo),
      status: statusesInTable.find((x) => x.value === status) || "",
      expandedData: generateExpandDataCars(qualityCar),
      files,
      dropdownProps: generateMenuItems({
        requestCarStatus: status,
        id,
        isLoading: isStatusUpdatingLoading,
        monitoringId: params?.id,
        profileType: profile?.type,
        permission: hasEdit,
      }),
    };
  });

  const headers = [
    {
      Header: "VIN",
      accessor: "vin",
      isSortable: true,
      width: 150,
      minWidth: 150,
    },

    {
      Header: "monitoring.models",
      accessor: "model",
      isSortable: true,
      width: 100,
      minWidth: 100,
    },

    {
      Header: "monitoring.carProductionDateSolo",
      accessor: "carProductionDate",
      isSortable: true,
      width: 140,
      minWidth: 140,
    },

    {
      Header: "monitoring.mileage",
      accessor: "mileage",
      isSortable: true,
      width: 80,
      minWidth: 80,
    },
    {
      Header: "monitoring.programEndDate",
      accessor: "programEndDate",
      isSortable: true,
      width: 150,
    },
    {
      Header: "monitoring.status",
      accessor: "status",
      isSortable: true,
      width: 150,

      Cell: ({ value }) => {
        let { label, variantBtn } = value;
        switch (label) {
          case "Утверждена":
            label = t("monitoring.approved") || "";
            break;
          case "Отправлена на доработку":
            label = t("monitoring.sent_for_revision") || "";
            break;
          case "На согласовании":
            label = t("monitoring.to_be_agreed") || "";
            break;
          case "Отклонена":
            label = t("monitoring.rejected") || "";
            break;
          case "Отозвана":
            label = t("monitoring.withdrawn") || "";
            break;
        }
        return (
          <span
            style={{
              padding: "4px 8px",
              background: variantBtn.background,
              borderRadius: "4px",
              textAlign: "center",
              color: variantBtn.color,
            }}
          >
            {label}
          </span>
        );
      },
    },
  ];
  return (
    <StyledTable
      headers={headers}
      data={requestCars ?? []}
      renderElement={(row) => {
        return (
          <MonitoringCarsTableExpandDataForm
            monitoringCarId={row?.original?.id}
            expandedData={row?.original?.expandedData}
          />
        );
      }}
      buttonIcons={hasEdit ? ["vertical-dots"] : undefined}
      alignCell="start"
      alignHeader="start"
      isButtonDropdown={true}
      dropdownProps={{ handleMenuClick }}
      onButtonClick={() => clickHandler}
      onSort={onSort}
    />
  );
};
