import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { requiredSelectSimpleValueSchema } from "@/helpers/validation";

import { TFileRecord } from "@/helpers/api/files/types";
import { TVehicleRecord } from "@/helpers/api/vehicles/types";
import { t } from "i18next";

type Nullable<T> = T | null;

export type IFileInfo = {
  value: TFileRecord | null;
};

const FILES_UPLOAD_COUNT = 5;
const DEFAULT_IMAGES: IFileInfo[] = new Array(FILES_UPLOAD_COUNT).fill({
  value: null,
});
const DEFAULT_FILES: IFileInfo[] = new Array(FILES_UPLOAD_COUNT).fill({
  value: null,
});

const getUploadFilesFromServer = (
  dirName: string,
  files: TFileRecord[] = [],
  defaultFiles: IFileInfo[] = []
): IFileInfo[] => {
  const filteredFiles = files.filter((el) => el.dir === dirName);
  return defaultFiles.map((_, index) => {
    return { value: filteredFiles[index] || null };
  });
};

export interface IAutoParkFormValues {
  commissioningDate: Nullable<Date>;
  expiredOSAGO: Nullable<Date>;
  expiredCASCO: Nullable<Date>;

  registrationCertificate: string;
  number: string;
  color: string;
  vin: string;

  modelId: Nullable<string>;
  generationId: Nullable<string>;
  modificationId: Nullable<string>;
  equipmentId: Nullable<string>;

  transmissionType: string;
  engineVolume: string;
  carBodyType: string;
  engineType: string;
  fuelType: string;

  images: IFileInfo[];
  files: IFileInfo[];
}

export const DEFAULT_AUTO_PARK_FORM_VALUES: IAutoParkFormValues = {
  commissioningDate: null,
  expiredOSAGO: null,
  expiredCASCO: null,

  registrationCertificate: "",
  number: "",
  color: "",
  vin: "",

  modelId: null,
  generationId: null,
  modificationId: null,
  equipmentId: null,

  transmissionType: "",
  engineVolume: "",
  carBodyType: "",
  engineType: "",
  fuelType: "",

  images: DEFAULT_IMAGES,
  files: DEFAULT_FILES,
};

export const formSchema = () => {
  return yup.object({
    commissioningDate: yup
      .date()
      .nullable()
      .required(t("park.validation.startDate") || ""),
    expiredOSAGO: yup
      .date()
      .nullable()
      .required(t("park.validation.endDate") || ""),
    expiredCASCO: yup
      .date()
      .nullable()
      .required(t("park.validation.casco") || ""),
    registrationCertificate: yup
      .string()
      .required(t("park.validation.sts") || ""),
    number: yup
      .string()
      .test({
        test: (value) =>
          value
            ? /^([а-яА-Яa-zA-Z]){1}([\d]){3}([а-яА-Яa-zA-Z]){2}([\d]){2,3}$/g.test(
                value
              )
            : true,
        message: t("park.validation.xnx") || "",
      })
      .required(t("park.validation.number") || ""),
    vin: yup
      .string()
      .test({
        test: (value) => (value ? /^([\da-zA-Z]){17}$/g.test(value) : true),
        message: t("park.validation.vin") || "",
      })
      .required(t("park.validation.vinRequired") || ""),
    color: yup.string().required(t("park.validation.color") || ""),

    modelId: requiredSelectSimpleValueSchema(t("park.validation.model") || ""),
    generationId: requiredSelectSimpleValueSchema(
      t("park.validation.generation") || ""
    ),
    modificationId: requiredSelectSimpleValueSchema(
      t("park.validation.mod") || ""
    ),
    equipmentId: requiredSelectSimpleValueSchema(
      t("park.validation.complete") || ""
    ),
  });
};

export type TOnSubmitAutoParkFormCb = (values: IAutoParkFormValues) => void;

export const useAutoParkForm = (
  onSubmit: TOnSubmitAutoParkFormCb,
  defaultValues?: TVehicleRecord
) => {
  const images = getUploadFilesFromServer(
    "photos",
    // @ts-ignore TODO: swagger scheme
    defaultValues?.files,
    DEFAULT_IMAGES
  );
  const files = getUploadFilesFromServer(
    "files",
    // @ts-ignore TODO: swagger scheme
    defaultValues?.files,
    DEFAULT_IMAGES
  );

  const form = useForm<IAutoParkFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: defaultValues
      ? {
          ...DEFAULT_AUTO_PARK_FORM_VALUES,
          modelId:
            //@ts-ignore
            defaultValues.equipment.modification.generation.model?.id || null,
          generationId:
            //@ts-ignore
            defaultValues.equipment.modification.generation?.id || null,
          //@ts-ignore
          modificationId: defaultValues.equipment.modification?.id || null,
          //@ts-ignore
          equipmentId: defaultValues.equipment?.id || null,
          //@ts-ignore
          // equipmentId: defaultValues.equipment.modification.generation.id,
          commissioningDate: new Date(defaultValues.commissioningDate),
          registrationCertificate: defaultValues.registrationCertificate,
          expiredOSAGO: new Date(defaultValues.expiredOSAGO),
          expiredCASCO: new Date(defaultValues.expiredCASCO),
          color: defaultValues.color?.color || "",
          number: defaultValues.number,
          vin: defaultValues.vin,
          images,
          files,
        }
      : DEFAULT_AUTO_PARK_FORM_VALUES,
  });

  const imageFieldArray = useFieldArray({
    name: "images",
    control: form.control,
  });

  const fileFieldArray = useFieldArray({
    name: "files",
    control: form.control,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
    imageFieldArray,
    fileFieldArray,
  };
};
