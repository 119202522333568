import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TDeadocPremiumRequest,
  TDeadocPremiumResponse,
  TExportDocumentListRequest,
  TExportDocumentListResponse,
  TGenerateDocumentsRequest,
  TGenerateDocumentsResponse,
  TGetDocumentListRequest,
  TGetDocumentListResponse,
  TGetDocumentRequest,
  TGetDocumentResponse,
  TPatchDocumentRequest,
  TPatchDocumentResponse,
  TPatchDocumentsRequest,
  TPatchDocumentsResponse,
} from "./types";
import { AxiosError } from "axios";

export const useGenerateDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TGenerateDocumentsRequest) => {
      return api.post<TGenerateDocumentsResponse>(`/bonuses/documents`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["premium", "documents"]);
        queryClient.invalidateQueries(["bonuses", "quarter"]);
      },
    }
  );
};

export const usePatchDocumentlist = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPatchDocumentsRequest) => {
      return api.patch<TPatchDocumentsResponse>(`/bonuses/documents`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["premium", "documents"]);
      },
    }
  );
};

export const useGetDocumentList = (request: TGetDocumentListRequest) => {
  return useQuery(["premium", "documents", request], () => {
    return api.get<TGetDocumentListResponse>("bonuses/documents", {
      params: request,
    });
  });
};

export const useExportDocuments = () => {
  return useMutation((request: TExportDocumentListRequest) => {
    return api.get<TExportDocumentListResponse>(`bonuses/documents/export`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useGetDocument = (request: TGetDocumentRequest) => {
  return useQuery(["premium", "document", request], () => {
    return api.get<TGetDocumentResponse>(`bonuses/documents/${request.id}`, {
      params: request,
    });
  });
};

export const usePatchDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: TPatchDocumentRequest) => {
      return api.patch<TPatchDocumentResponse>(
        `/bonuses/documents/${request.id}`,
        request.patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["premium", "documents"]);
      },
    }
  );
};

export const usePatchDiadocPremium = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TDeadocPremiumRequest;
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TDeadocPremiumResponse>(
        `/bonuses/documents/bulk-send-to-diadoc`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
