import { useState, useEffect } from "react";

function getWindowWidth() {
  const { innerWidth: width } = window;
  return width;
}

export const useViewerWidth = () => {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const pageWidth = windowWidth > 1600 ? 1100 : windowWidth > 1200 ? 900 : 700;

  return pageWidth;
};
