import { TGetDealerListFiltersResponse } from "@/helpers/api/dc/dealers/types";
import { Button } from "@/packages/button/Button";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  IRegistryFilterFormValues,
  RegistryFilterFormFieldsNameEnum,
  TOnSubmitRegistryFilterFormCb,
  useRegistryFilterForm,
} from "./hooks";
import {
  StyledButtonsContainer,
  StyledFormBooleanSelectField,
  StyledFormInputField,
  StyledFormLayout,
  StyledFormSelectCity,
  StyledFormSelectName,
  StyledFormSelectRegion,
  StyledRegistryFilterWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";
import { TKiaDealerRecord } from "@/helpers/api/dc/kia-dealers/types";

export interface IRegistryFilter {
  initValues: IRegistryFilterFormValues;
  filterOptions: TGetDealerListFiltersResponse;
  onFiltersChange: TOnSubmitRegistryFilterFormCb;
}

export const RegistryFilter: React.FC<IRegistryFilter> = (props) => {
  const { initValues, filterOptions, onFiltersChange } = props;

  const [searchValue, setSearchValue] = useState("");
  const { data: dealerList } =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const { t } = useTranslation();

  const { form, submit, onClearHandler } = useRegistryFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  const preparedDealerList = (dealerList: TKiaDealerRecord[]) =>
    dealerList?.map((item) => ({
      label: `${item.mobisCode} / ${item.warrantyCode} / ${item.lawName} / ${item.merchantName}`,
      value: item.merchantName,
    }));

  return (
    <StyledRegistryFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <StyledFormSelectName
            name={RegistryFilterFormFieldsNameEnum.Name}
            label={t("registers.registersDC.name") || ""}
            placeholder={t("registers.registersDC.codePlaceholder") || ""}
            allowClear
            showSearch
            options={preparedDealerList(dealerList?.data?.data || [])}
            onSearch={setSearchValue}
            isSearchShow
          />

          <StyledFormInputField
            name={RegistryFilterFormFieldsNameEnum.WarrantyCode}
            label={t("registers.registersDC.code") || ""}
            placeholder={t("registers.registersDC.codePlaceholder") || ""}
            allowClear
          />

          <StyledFormSelectRegion
            name={RegistryFilterFormFieldsNameEnum.Region}
            options={filterOptions.regionOptions}
            label={t("registers.registersDC.region") || ""}
            placeholder={t("registers.registersDC.regionPlaceholder") || ""}
            allowClear
            showSearch
          />

          <StyledFormSelectCity
            name={RegistryFilterFormFieldsNameEnum.City}
            options={filterOptions.cityOptions}
            label={t("registers.registersDC.city") || ""}
            placeholder={t("registers.registersDC.cityPlaceholder") || ""}
            allowClear
            showSearch
          />

          <StyledFormBooleanSelectField
            name="status"
            label={t("registers.registersDC.status") || ""}
            placeholder={t("registers.registersDC.statusPlaceholder") || ""}
            trueLabel={t("registers.registersDC.trueLabel") || ""}
            falseLabel={t("registers.registersDC.falseLabel") || ""}
          />
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("registers.registersDC.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("registers.registersDC.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledRegistryFilterWrapper>
  );
};
