import styled from "styled-components";

export const StyledRegistryDataFields = styled.div`
  margin-bottom: 64px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCheckbox = styled.div`
  padding-top: 14px;
`;

export const StyledMultipleInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
`;

export const StyledMultipleButton = styled.div`
  margin-bottom: 4px;
`;

