import { colors } from "@/helpers";
import { Tabs as AntdTabs, TabsProps } from "antd";
import { TabsType } from "antd/lib/tabs";
import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const { TabPane } = AntdTabs;

export interface ITab {
  name: string;
  slug: string;
  label?: string;
  isDisabled?: boolean;
  content?: React.ReactNode;
}

export interface ITabsProps {
  className?: string;
  tabs: ITab[];
  activeTab?: ITab;
  onChange: (tab: ITab) => void;
  type?: TabsType;
  isContentBorderless?: boolean;
}

interface ITabsStyledProps extends TabsProps {
  isCard?: boolean;
  countTabs: number;
  isContentBorderless: boolean;
}

const StyledTabs = styled(AntdTabs)<ITabsStyledProps>`
  margin: 0;

  .ant-tabs-nav {
    overflow-y: hidden;
    margin: 0;
    &::before {
      ${({ isCard }) =>
        !isCard ? `border-bottom: 2px solid ${colors.gray3}` : "border: none"};
    }
  }
  ${({ countTabs }) =>
    countTabs > 3 &&
    css`
      .ant-tabs-nav-list {
        width: 99%;
      }
    `}

  .ant-tabs-tab {
    padding: 10px 0;
    color: ${colors.gray600};

    &:hover {
      color: ${colors.brand};
    }
    ${({ countTabs }) =>
      countTabs > 3 &&
      css`
        width: 100%;
        justify-content: center;
      `}
  }
  .ant-tabs-tab:not(:first-child) {
    margin-left: 40px;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${colors.brand};
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${colors.brand};
  }

  ${({ isCard, countTabs, isContentBorderless }) =>
    isCard &&
    css`
      overflow-y: visible !important;

      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-tab {
        padding: 9px 24px;
      }
      .ant-tabs-tab:not(:first-child) {
        margin-left: 0;
      }
      .ant-tabs-tab:first-child {
        border-top-left-radius: 12px !important;
      }

      .ant-tabs-tab:nth-child(${countTabs}) {
        border-top-right-radius: 12px !important;
      }

      .ant-tabs-tab-active {
        background-color: ${colors.brand};
        border-radius: 24px;
      }
      .ant-tabs-tab {
        color: ${colors.gray3};
        padding: 18px 10px !important;
        overflow-y: visible;

        .ant-tabs-tab-btn::after {
          display: block;
          content: "";
          height: 0;
        }
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 0 !important;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        position: relative;

        &::before {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          display: block;
          content: "";
          height: 1px;
          background-color: ${colors.white};
        }

        .ant-tabs-tab-btn {
          color: ${colors.black} !important;
        }

        .ant-tabs-tab-btn::after {
          height: 10px;
        }

        border-top-right-radius: 12px !important;
        border-top-left-radius: 12px !important;
      }

      .ant-tabs-nav-wrap {
        overflow: visible !important;
      }

      .ant-tabs-nav-list {
        align-items: flex-end;
        overflow-y: visible;
      }

      && .ant-tabs-tabpane {
        background-color: ${colors.gray30};
        border-radius: 0 0 12px 12px;
        ${!isContentBorderless &&
        `border: 1px solid ${colors.stroke4HeaderTable}`};
        overflow: hidden;
      }
    `}
`;

const Tabs: React.FC<ITabsProps> = (props) => {
  const { className, tabs, activeTab, onChange, type, isContentBorderless } =
    props;

  const { t } = useTranslation();

  return (
    <StyledTabs
      className={className}
      onChange={(activeKey: string) => {
        onChange &&
          onChange(tabs.find((item) => item.slug === activeKey) || tabs[0]);
      }}
      defaultActiveKey={activeTab?.slug}
      isCard={type === "card"}
      type={type}
      countTabs={tabs.length}
      activeKey={activeTab?.slug}
      isContentBorderless={!!isContentBorderless}
    >
      {tabs?.map((item) => (
        <TabPane
          tabKey={item.slug}
          disabled={item.isDisabled}
          tab={t(item.name) || ""}
          key={item.slug}
        >
          {item.content}
        </TabPane>
      ))}
    </StyledTabs>
  );
};

export { Tabs };
