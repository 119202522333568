import styled from "styled-components";
import { colors } from "@/helpers";

interface IColoredLabel {
  event: string;
  days: number;
  isIntersecting: boolean;
}

export const StyledColoredLabel = styled.div<IColoredLabel>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ days }) => 20 * days + "px"};
  height: 20px;
  border-radius: 24px;
  color: ${colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${({ event }) =>
    event === "idle" ? `${colors.black}` : `${colors.green100}`};
  z-index: 2;
  cursor: pointer;
`;

export const StyledComment = styled.div`
  color: ${colors.gray200};
`;

export const StyledIntersectionThumb = styled.div`
  position: absolute;
  pointer-events: none;
  transform: translateY(-50%);
  top: 50%;
  left: 2px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${colors.white};
  z-index: 3;
`;

export const StyledFirstThumb = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 24px;
  background-color: ${colors.white};
  z-index: 3;
  pointer-events: none;
`;

export const StyledLabelWrap = styled.div`
  position: relative;
`;
