import { Checkbox } from "@/packages/checkbox/Checkbox";
import { Icon } from "@/packages/icon/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import type { Identifier, XYCoord } from "dnd-core";

import {
  Column,
  ColumnInstance,
  HeaderGroup,
  HeaderPropGetter,
  Row,
  useFlexLayout,
  useRowSelect,
  useTable,
} from "react-table";
import { IconButton } from "../icon-button/IconButton";
import {
  StyledCell,
  StyledDrag,
  StyledHeaderCell,
  StyledHeaderCellContent,
  StyledRow,
  StyledTable,
  WrappedButtons,
} from "./DraggableTable.style";
import { useTranslation } from "react-i18next";

export interface IDataItem {
  [key: string]: any;
}

export interface TableDragItem {
  id: string;
  index: number;
}

export interface IHeaderItem<T extends object> {
  Header: Column<T>["Header"] | ((props: Column<T>) => React.ReactNode);
  accessor?: string;
  columns?: IHeaderItem<T>[];
  id?: Column<T>["id"];
  Cell?: (arg: { row: { original: T }; value: any }) => React.ReactNode;
  isSortable?: boolean;
  isSearchable?: boolean;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  isHeaderHidden?: boolean;
}

export interface IDefaultColumn {
  minWidth: number;
  width?: number | string;
  maxWidth: number;
}

export type SortOrder = null | "asc" | "desc";

export type HeaderArgType = HeaderGroup & IColumn;

export interface IHeaderParams {
  color?: "gray30" | "white";
  size?: "s" | "m";
  hasTopBorderRadius?: boolean;
}

export interface IDraggableTable<T extends object = object> {
  headers: IHeaderItem<T>[];
  data: T[];
  buttonIcons?: Array<string>;
  defaultColumn?: IDefaultColumn;
  onSort?: (header: HeaderArgType, order: SortOrder) => void;
  onButtonClick?: (button: string, value: IDataItem) => void;
  headerParams?: IHeaderParams;
  onCellClick?: (cell: { rowIndex: number; accessor: string }) => void;
  renderElement?: (row: Row<IDataItem>) => React.ReactNode;
  className?: string;
  getRowId?: (record: T) => string;
  alignCell?: "start" | "center";
  onTableDragIndex?: (id: number, index: number) => void;
  onTableDrag?: (value: T[]) => void;
  onDragEnd: (item: TableDragItem) => void;
  // Необходимо, так как в некоторых местах DndProvider уже имплементирован,
  // что приводит к ошибкам
  isDndProviderImplemented?: boolean;
}

export interface IColumn {
  isSortable?: boolean;
  isSearchable?: boolean;
  isFixedWidth?: number;
  isHeaderHidden?: boolean;
}

export const DEFAULT_COLUMN: IDefaultColumn = {
  minWidth: 150,
  width: 250,
  maxWidth: 350,
};

export const BUTTON_COLUMN: IDefaultColumn = {
  minWidth: 56,
  width: "fit-content",
  maxWidth: 350,
};
export const OPEN_BUTTON_COLUMN: IDefaultColumn = {
  minWidth: 72,
  width: 72,
  maxWidth: 72,
};

export const CHECKBOX_COLUMN: IDefaultColumn = {
  minWidth: 78,
  width: 78,
  maxWidth: 78,
};

export const DRAG_COLUMN: IDefaultColumn = {
  minWidth: 44,
  width: 44,
  maxWidth: 44,
};

export const CustomCheckbox = (elProps) => {
  const { label, checked, onChange } = elProps;

  const handleChange = (value: boolean) => {
    onChange({ target: { checked: value } });
  };
  return (
    <Checkbox
      label={label}
      color={elProps.isGray ? "gray" : "white"}
      name="selectTable"
      value={checked}
      onChange={handleChange}
    />
  );
};

export const ItemTypes = {
  CARD: "card",
  TREE: "tree",
};

const RowItem = ({ row, index, moveCard, onDragEnd }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    TableDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: TableDragItem) {
      onDragEnd(item);
    },
    hover(item: TableDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex, row?.original?.id);
      item.index = hoverIndex;
    },
  });

  const [{}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: row.original.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <StyledRow
      {...row.getRowProps()}
      key={index}
      className="tr"
      ref={ref}
      // opacity={opacity}
      data-handler-id={handlerId}
    >
      {row.cells.map((cell, $$i) => {
        const column: ColumnInstance & IColumn = cell.column;
        const isButtonIcon = column.id === "button-icon";
        const isOpenBtn = column.id === "open-button";
        const isCheckbox = column.id === "selection";
        const isDrag = column.id === "drag";

        const buttonIconPropsStyle = isButtonIcon ? BUTTON_COLUMN : {};
        const checkboxPropsStyle = isCheckbox ? CHECKBOX_COLUMN : {};
        const openButtonStyle = isOpenBtn ? OPEN_BUTTON_COLUMN : {};
        const dragStyle = isDrag ? DRAG_COLUMN : {};
        return (
          <StyledCell
            {...cell.getCellProps()}
            style={{
              ...cell.getCellProps().style,
              ...buttonIconPropsStyle,
              ...checkboxPropsStyle,
              ...openButtonStyle,
              ...dragStyle,
            }}
            key={$$i}
            className="td"
            isFixedWidth={!!column.isFixedWidth || isButtonIcon}
            alignCell="center"
            isButton={cell
              .getCellProps()
              .key.toString()
              .includes("button-icon")}
            onClick={() => ({})}
          >
            {cell.render("Cell")}
          </StyledCell>
        );
      })}
    </StyledRow>
  );
};

const DraggableTable = <T extends object>(props: IDraggableTable<T>) => {
  const {
    headerParams,
    headers,
    data,
    buttonIcons,
    defaultColumn = DEFAULT_COLUMN,
    onButtonClick,
    onTableDragIndex,
    onTableDrag,
    renderElement,
    onDragEnd,
    className,
    getRowId,
    isDndProviderImplemented,
  } = props;

  const columns = headers;
  const { t } = useTranslation();
  const tableDefaultColumn = defaultColumn;
  const [currentItems, setCurrentItems] = useState(data);

  useEffect(() => setCurrentItems(data), [data]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable<T>(
      {
        defaultColumn: tableDefaultColumn,
        //@ts-ignore
        columns,
        data: currentItems,
        getRowId,
      },
      useRowSelect,
      useFlexLayout,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: "drag",
            Header: () => <></>,
            Cell: ({ row }) => (
              <StyledDrag>
                <Icon name="drag" size={16}/>
              </StyledDrag>
            ),
          },
          ...columns,
        ]);

        if (renderElement) {
          hooks.visibleColumns.push((columns) => [
            ...columns,
            {
              id: "open-button",
              Header: () => <></>,
              Cell: ({ row }) => <></>,
            },
          ]);
        }
        if (buttonIcons?.length) {
          hooks.visibleColumns.push((columns) => [
            ...columns,
            {
              id: "button-icon",
              Header: () => <></>,
              Cell: ({ row }) => {
                return (
                  <WrappedButtons>
                    {buttonIcons.map((item, index) => (
                      <IconButton
                        key={index}
                        onClick={() =>
                          onButtonClick && onButtonClick(item, row.original)
                        }
                        icon={item}
                        variant="tertiary"
                        color="black"
                      />
                    ))}
                  </WrappedButtons>
                );
              },
            },
          ]);
        }
      }
    );

  const handleMoveCard = useCallback(
    (dragIndex: number, hoverIndex: number, id: number) => {
      setCurrentItems((prevCards: T[]) => {
        const newArray = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        onTableDrag && onTableDrag(newArray);
        onTableDragIndex && onTableDragIndex(id, hoverIndex);
        return newArray;
      });
    },
    []
  );

  return (
    <StyledTable {...getTableProps()} className={className}>
      <div className="header">
        {headerGroups.map((headerGroup, $i) => (
          <div {...headerGroup.getHeaderGroupProps()} key={$i} className="tr">
            {headerGroup.headers
              // @ts-ignore
              .map((column: HeaderArgType, $$i) => {
                const isButtonIcon = column.id === "button-icon";
                const isCheckbox = column.id === "selection";
                const isOpenBtn = column.id === "open-button";
                const isDrag = column.id === "drag";
                const headerProps = column.getHeaderProps(
                  column as HeaderPropGetter<IColumn>
                );

                const buttonIconPropsStyle = isButtonIcon ? BUTTON_COLUMN : {};
                const checkboxPropsStyle = isCheckbox ? CHECKBOX_COLUMN : {};
                const openButtonStyle = isOpenBtn ? OPEN_BUTTON_COLUMN : {};
                const dragPropsStyle = isDrag ? DRAG_COLUMN : {};

                return (
                  <StyledHeaderCell
                    {...headerProps}
                    style={{
                      ...headerProps.style,
                      ...buttonIconPropsStyle,
                      ...checkboxPropsStyle,
                      ...openButtonStyle,
                      ...dragPropsStyle,
                    }}
                    key={$$i}
                    className="th"
                    isSortable={!!column.isSortable}
                    isFixedWidth={!!column.isFixedWidth}
                    headerParams={headerParams}
                  >
                    <StyledHeaderCellContent>
                      {typeof column.render("Header") === "string"
                        ? t(`${column.render("Header")}`)
                        : ""}
                    </StyledHeaderCellContent>
                  </StyledHeaderCell>
                );
              })}
          </div>
        ))}
      </div>
      {
        isDndProviderImplemented ?
          <div {...getTableBodyProps()} className="body">
            {rows.map((row, $i) => {
              prepareRow(row);
              return (
                <RowItem
                  row={row}
                  index={$i}
                  moveCard={handleMoveCard}
                  onDragEnd={onDragEnd}
                />
              );
            })}
          </div>
          :
          <DndProvider backend={HTML5Backend}>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row, $i) => {
                prepareRow(row);
                return (
                  <RowItem
                    row={row}
                    index={$i}
                    moveCard={handleMoveCard}
                    onDragEnd={onDragEnd}
                  />
                );
              })}
            </div>
          </DndProvider>
      }

    </StyledTable>
  );
};

export { DraggableTable };
