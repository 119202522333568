import { TWorksRecord } from "@/helpers/api/maintenanceGrids/works/types";
import { useGetSettingsValue } from "@/helpers/api/shared/settings/hooks";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { Button } from "@/packages/button/Button";
import { Checkbox } from "@/packages/checkbox/Checkbox";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IconWorkType } from "@/packages/iconWorkType";
import { Pagination } from "@/packages/pagination/Pagination";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Placeholder } from "@/packages/placeholder/Placeholder";
import {
  HeaderArgType,
  IHeaderItem,
  SortOrder,
  TableSimple,
} from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Space } from "antd";
import { FC, useContext, useEffect, useMemo } from "react";
import { StyledContent, StyledHeader, StyledTitle } from "../index.styles";
import { PRICE_RATE_HOUR_KEY } from "./WorksRatePerHourModal";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface IWorksTab {
  hasEdit: boolean;
  onOpenCreateModal?: () => void;
  onOpenEditModal?: () => void;
  onOpenRatePerHourModal?: () => void;
  handleSort: (accessor: HeaderArgType, order: SortOrder) => void;
}
interface IDataItem extends TWorksRecord {
  actions?: {
    isDefault?: boolean;
    onDefaultChange?: (value: boolean, row: IDataItem) => void;
    onDelete?: (row: IDataItem) => void;
    onEdit?: (row: IDataItem) => void;
  };
  [key: string]: any;
}

const headers = (hasEdit: boolean): IHeaderItem<IDataItem>[] => [
  {
    Header: t("maintenanceGrids.worksModel.workTitle") || "",
    accessor: "name",
    isSearchable: true,
    width: "250px",
    minWidth: 250,
    maxWidth: 250,
  },
  {
    Header: t("maintenanceGrids.worksModel.types") || "",
    accessor: "types",
    width: "100px",
    minWidth: 100,
    maxWidth: 100,
    Cell: ({ value }) => {
      return value.map((type) => (
        <Space size={8} direction="vertical">
          <IconWorkType type={type} />
          <br />
        </Space>
      ));
    },
  },
  {
    Header: t("maintenanceGrids.worksModel.code") || "",
    accessor: "code",
    isSortable: true,
    width: "150px",
    minWidth: 150,
    maxWidth: 150,
    Cell: ({ value }) => {
      return value.map((code, index) => (
        <Space size={8} direction="vertical">
          {index === 0 && code}
          <br />
        </Space>
      ));
    },
  },
  {
    Header: t("maintenanceGrids.worksModel.normHours") || "",
    accessor: "normInHours",

    Cell: ({ value }) => {
      return value.map((normInHours) => (
        <Space size={8} direction="vertical">
          {normInHours}
          <br />
        </Space>
      ));
    },
  },
  {
    Header: t("maintenanceGrids.worksModel.totalCost") || "",
    accessor: "totalCost",
    Cell: ({ value }) => {
      return value.map((totalCost) => (
        <Space size={8} direction="vertical">
          {totalCost}
          <br />
        </Space>
      ));
    },
  },
  {
    Header: t("maintenanceGrids.worksModel.actions") || "",
    accessor: "actions",
    width: "150px",
    minWidth: 150,
    maxWidth: 150,
    Cell: ({ value, row }) => {
      const checkboxData = { ...row.original, code: row.original.code[0] };
      return (
        <Space align="center">
          <Checkbox
            value={value?.isDefault}
            isDisabled={!hasEdit}
            onChange={(checked) =>
              value?.onDefaultChange(checked, checkboxData)
            }
          />
          {hasEdit ? (
            <Tooltip
              content={
                <div>{t("maintenanceGrids.worksModel.deleteWork") || ""}</div>
              }
            >
              <div>
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => value?.onDelete(row.original)}
                />
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip
            content={
              <div>
                {hasEdit
                  ? t("maintenanceGrids.worksModel.edit") || ""
                  : t("maintenanceGrids.worksModel.fullInfo") || ""}
              </div>
            }
          >
            <div>
              <IconButton
                icon={hasEdit ? "edit" : "eye-open"}
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => value?.onEdit(row.original)}
              />
            </div>
          </Tooltip>
        </Space>
      );
    },
  },
];

const WorksTab: FC<IWorksTab> = (props) => {
  const {
    hasEdit,
    onOpenCreateModal,
    onOpenEditModal,
    onOpenRatePerHourModal,
    handleSort,
  } = props;
  const { t } = useTranslation();
  const {
    state: { works, currentPage, debouncedSearchValueWorks },
    mutations: { setPage, setSearchValueWorks, setActiveWork },
    actions: { deleteWork, updateWork },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const { data: priceRateHourResponse } = useGetSettingsValue({
    key: PRICE_RATE_HOUR_KEY,
  });

  const priceRateHour =
    priceRateHourResponse?.data.value ||
    t("maintenanceGrids.worksModel.notSet") ||
    "";

  const handleEditButtonClick = (row: IDataItem) => {
    setActiveWork(row);
    onOpenEditModal && onOpenEditModal();
  };

  const handleDeleteButtonClick = (row: IDataItem) => {
    deleteWork({ id: row.id });
  };

  const handleGsmSearch = (key: string, value?: string) => {
    setSearchValueWorks(value || "");
  };

  const handleDefaultChange = (value: boolean, row: IDataItem) => {
    updateWork({ id: row.id, data: { ...row, isDefault: value } });
  };

  const meta = works?.meta;
  const adaptWorks = useMemo(() => {
    return (
      works?.data?.map((work) => {
        // @ts-ignore
        const workTypeCodes = work.workTypes.map(() => work.code);

        const workTypeTypes = work.workTypes.map((type) => type.type);
        const workTypeNormInHours = work.workTypes.map(
          (type) => type.normInHours
        );
        const workTypeCost = work.workTypes.map((type) => type.totalCost);

        return {
          ...work,
          types: workTypeTypes,
          code: workTypeCodes,
          normInHours: workTypeNormInHours,
          totalCost: workTypeCost,
          actions: {
            isDefault: work.isDefault,
            onDefaultChange: handleDefaultChange,
            onEdit: handleEditButtonClick,
            onDelete: handleDeleteButtonClick,
          },
        };
      }) || []
    );
  }, [works]);

  const hasWorks = adaptWorks.length > 0;

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchValueWorks]);

  return (
    <StyledContent>
      <StyledHeader>
        <StyledTitle>
          <Paragraph size={16} color="black">
            {t("maintenanceGrids.worksModel.works") || ""}
          </Paragraph>
          <Paragraph size={10} color="gray3">
            {`${t("maintenanceGrids.worksModel.worksNum") || ""}
            ${works?.meta?.itemCount}
            ${t("maintenanceGrids.worksModel.worksType") || ""}`}
          </Paragraph>
        </StyledTitle>

        <Space size={48}>
          <Space size={16}>
            <Paragraph size={16} color="black">
              {t("maintenanceGrids.worksModel.price") || ""} {priceRateHour}
            </Paragraph>
            <Button
              color="brand"
              size="middle"
              onClick={onOpenRatePerHourModal}
              isDisabled={!hasEdit}
            >
              {t("maintenanceGrids.worksModel.makePrice") || ""}
            </Button>
          </Space>
          <Button
            color="brand"
            size="middle"
            theme="primary"
            isDisabled={!Number(priceRateHour) || !hasEdit}
            onClick={onOpenCreateModal}
          >
            {t("maintenanceGrids.worksModel.createNewWork") || ""}
          </Button>
        </Space>
      </StyledHeader>

      {hasWorks || debouncedSearchValueWorks.length !== 0 ? (
        <>
          <TableSimple
            headers={headers(hasEdit)}
            // @ts-ignore
            data={adaptWorks}
            headerParams={{
              color: "white" as "white",
            }}
            alignCell="start"
            onSearch={handleGsmSearch}
            onSort={handleSort}
          />

          <Pagination
            current={currentPage}
            onChange={setPage}
            pageSize={1}
            total={meta?.pageCount}
          />
        </>
      ) : (
        <Placeholder>
          {t("maintenanceGrids.worksModel.noWorks") || ""}
        </Placeholder>
      )}
    </StyledContent>
  );
};

export { WorksTab };
