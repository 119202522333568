import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import { FormCodeListField } from '@/packages/formElements/formFields/FormCodeListField';
import { FormTextAreaField } from '@/packages/formElements/formFields/FormTextAreaField';
import { FormBodyTypeSelectField } from '@/packages/formElements/formFields/special/FormBodyTypeSelectField';
import { FormEngineTypeSelectField } from '@/packages/formElements/formFields/special/FormEngineTypeSelectField';
import { FormEngineVolumeSelectField } from '@/packages/formElements/formFields/special/FormEngineVolumeSelectField';
import { FormFuelSelectField } from '@/packages/formElements/formFields/special/FormFuelSelectField';
import {
    FormTransmissionTypeSelectField,
} from '@/packages/formElements/formFields/special/FormTransmissionTypeSelectField';
import { StyledButton, StyledFormLayout, StyledMPVInputField, StyledMPVWrapper } from './index.styles';
import { CompleteSetFromFieldsEnum } from './types';
import { FormEquipmentListField } from '@/packages/formElements/formFields/FormEquipmentListField';
import { ContextProfile } from '@/hooks/contexts/useProfile';

interface ICompleteSetFields {
    isReadOnly?: boolean;
    refetch?: any;
}

const _CompleteSetFields: React.FC<ICompleteSetFields> = (props) => {
    const { isReadOnly = false, refetch } = props;

    const handleExport = () => {
        refetch();
    };

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const isDealerType = profile?.type === 'dealer';

    return (
        <StyledFormLayout>
            <Row gutter={16}>
                <Col span={12}>
                    <StyledMPVWrapper>
                        <Col style={{ padding: 0 }} span={16}>
                            <StyledMPVInputField
                                name={CompleteSetFromFieldsEnum.Mpv}
                                label='Код MPV'
                                description='9 символов MPV кода'
                                readOnly={isReadOnly}
                            />
                        </Col>
                        {
                            !isDealerType &&
                            <Col span={16}>
                                <StyledButton
                                    onClick={() => handleExport()}
                                    color='brand'
                                    theme='secondary'
                                    size='small'
                                >
                                    Расшифровать
                                </StyledButton>
                            </Col>
                        }
                    </StyledMPVWrapper>
                </Col>
                <Col span={12}>
                    <FormFuelSelectField
                        name={CompleteSetFromFieldsEnum.FuelTypeId}
                        label='Тип топлива'
                        description='Выберите из списка тип топлива'
                        readOnly={isReadOnly}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <FormTransmissionTypeSelectField
                        name={CompleteSetFromFieldsEnum.TransmissionTypeId}
                        label='Тип трансмиссии'
                        description='Выберите тип трансмиссии'
                        readOnly={isReadOnly}
                    />
                </Col>
                <Col span={12}>
                    <FormBodyTypeSelectField
                        name={CompleteSetFromFieldsEnum.CarBodyTypeId}
                        label='Тип кузова'
                        description='Выберите из списка тип кузова'
                        readOnly={isReadOnly}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <FormEngineTypeSelectField
                        name={CompleteSetFromFieldsEnum.EngineTypeId}
                        label='Тип двигателя'
                        description='Выберите из списка тип двигателя'
                        readOnly={isReadOnly}
                    />
                </Col>
                <Col span={12}>
                    <FormEngineVolumeSelectField
                        name={CompleteSetFromFieldsEnum.EngineVolumeId}
                        label='Объем двигателя (л)'
                        description='Выберите объем двигателя'
                        readOnly={isReadOnly}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <FormCodeListField
                        name={CompleteSetFromFieldsEnum.VinEngineTransmission}
                        label='8,9 Символов VIN'
                        description='8,9 символ VIN номера. Например: L'
                        addButtonText='Добавить'
                        readOnly={isReadOnly}
                    />
                </Col>
                {
                    !isDealerType &&
                    <Col span={12}>
                        <FormEquipmentListField
                            name={CompleteSetFromFieldsEnum.Equipments}
                            nameLabel='Название комплектации'
                            nameDescription='Введите название комплектации'
                            namePlaceholder='Название комплектации'
                            codeLabel='Код комплектации (OCN)'
                            codeDescription='Введите 5-значный код OCN (включая Grade)'
                            codePlaceholder='Введите код (OCN)'
                            addButtonText='Добавить'
                            readOnly={isReadOnly}
                        />
                    </Col>
                }
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <FormTextAreaField
                        name={CompleteSetFromFieldsEnum.Comment}
                        label='Комментарий к модификации'
                        description='Комментарий (минимум 5 символов)'
                        readOnly={isReadOnly}
                    />
                </Col>
            </Row>
        </StyledFormLayout>
    );
};

export const CompleteSetFields = React.memo(_CompleteSetFields);
