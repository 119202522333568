import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type DirectorRegistrationFormValues = {
  password: string;
  repeatPassword: string;
};

const DEFAULT_DIRECTOR_REGISTRATION_FORM_VALUES: DirectorRegistrationFormValues =
  {
    password: "",
    repeatPassword: "",
  };

export type OnSubmitDirectorRegistrationFormHandler = (
  data: DirectorRegistrationFormValues
) => void;

export const formSchema = () => {
  return yup
    .object({
      password: yup
        .string()
        .required(t("common.password.passwordEnter") || "")
        .matches(/^[A-Za-z0-9 ,@$!%*?&«»";:'`.—_-|<>^()-]+$/, t("common.password.letters") || "")
        .min(6, t("common.password.min") || "")
        .max(12, t("common.password.max") || ""),

      repeatPassword: yup
        .string()
        .oneOf([yup.ref("password")], t("common.password.same") || "")
        .required(t("common.password.again") || ""),
    })
    .required();
};

export const useDirectorRegistrationForm = (
  onSubmit: OnSubmitDirectorRegistrationFormHandler
) => {
  const form = useForm<DirectorRegistrationFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_DIRECTOR_REGISTRATION_FORM_VALUES,
  });

  const onSubmitButtonClickHandler = form.handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return { form, onSubmitButtonClickHandler };
};
