import { formatDateToPeriod } from "@/helpers/utils";
import { t } from "i18next";

export enum QualityMonitoringStatusEnum {
  OPEN = "open",
  SEARCH_COMPLETED = "search-completed",
  CLOSED = "closed",
}

export const generateMenuItemsForOpenedStatus = (id: string) => {
  return [
    {
      label: t("monitoring.edit"),
      key: `${id}/edit`,
    },
    {
      label: t("monitoring.dealers"),
      key: `${id}/dealers`,
    },
    {
      label: t("monitoring.cars"),
      key: `${id}/cars`,
    },
    {
      label: t("monitoring.notifyAboutAddCarsLink"),
      key: `notifyAboutAddCars,${id}`,
    },
    {
      label: t("monitoring.notifyDealersLink"),
      key: `notifyDealers,${id}`,
    },
    {
      label: t("monitoring.notifyUnfamiliarLink"),
      key: `notifyUnfamiliar,${id}`,
    },
    {
      label: t("monitoring.closeSearchLink"),
      key: `closeSearch,${id}`,
    },
    {
      label: t("monitoring.closeProgramLink"),
      key: `closeProgram,${id}`,
    },
    {
      label: t("monitoring.exportExcell"),
      key: `exportExcell,${id}`,
    },
  ];
};

export const generateMenuItemsForSearchingStatus = (id: string) => {
  return [
    {
      label: t("monitoring.edit"),
      key: `${id}/edit`,
    },
    {
      label: t("monitoring.info"),
      key: `${id}/info`,
    },
    {
      label: t("monitoring.dealers"),
      key: `${id}/dealers`,
    },
    {
      label: t("monitoring.cars"),
      key: `${id}/cars`,
    },
    {
      label: t("monitoring.closeProgramLink"),
      key: `closeProgram,${id}`,
    },
    {
      label: t("monitoring.exportExcell"),
      key: `exportExcell,${id}`,
    },
  ];
};

export const generateMenuItemsForClosedStatus = (id: string) => {
  return [
    {
      label: 'Открыть мониторинг',
      key: `openMonitoring,${id}`,
    },
    {
      label: t("monitoring.info"),
      key: `${id}/info`,
    },
    {
      label: t("monitoring.dealers"),
      key: `${id}/dealers`,
    },
    {
      label: t("monitoring.cars"),
      key: `${id}/cars`,
    },
    {
      label: t("monitoring.exportExcell"),
      key: `exportExcell,${id}`,
    },
  ];
};

export const generateMenuItemsForDealers = (
  id: string,
  status: QualityMonitoringStatusEnum,
  permission: boolean
) => {
  return [
    status !== QualityMonitoringStatusEnum.CLOSED &&
    status !== QualityMonitoringStatusEnum.SEARCH_COMPLETED &&
    permission
      ? {
          label: t("monitoring.createApp"),
          key: `${id}/create-application`,
        }
      : undefined,
    {
      label: t("monitoring.dealerCards"),
      key: `${id}/dealers-cars`,
    },
    permission
      ? {
          label: t("monitoring.forDealers"),
          key: `${id}/for-dealers`,
        }
      : undefined,
    {
      label: t("monitoring.exportExcell"),
      key: `exportExcell,${id}`,
    },
  ];
};

export const generateExpandData = (rowOriginal) => {
  if (rowOriginal) {
    const {
      additionalRequirements,
      primaryRequirements,
      carProductionDateFrom,
      carProductionDateTo,
      carSearchPeriodFrom,
      carSearchPeriodTo,
      dateOfTheStudyFrom,
      dateOfTheStudyTo,
    } = rowOriginal;

    const rowTableData = [
      {
        label: t("monitoring.primaryRequirements"),
        value: primaryRequirements,
      },
      {
        label: t("monitoring.additionalRequirements"),
        value: additionalRequirements,
      },
      {
        label: t("monitoring.carProductionDate"),
        value: formatDateToPeriod(carProductionDateFrom, carProductionDateTo),
      },
      {
        label: t("monitoring.carSearchPeriod"),
        value: formatDateToPeriod(carSearchPeriodFrom, carSearchPeriodTo),
      },
      {
        label: t("monitoring.dateOfTheStudy"),
        value: formatDateToPeriod(dateOfTheStudyFrom, dateOfTheStudyTo),
      },
    ];
    return rowTableData;
  }
};
