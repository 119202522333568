import { RegisterForm } from "@/components/registration/RegisterationForm";
import { RegistrationFormSuccessCallback } from "@/components/registration/RegisterationForm/hooks";
import { useAuthRegisterM } from "@/helpers/api/auth/hooks";
import { useNotification } from "@/hooks/useNotification";
import IdentityLayout from "@/layouts/IdentityLayout";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const Register: React.FC = () => {
  const { createNotificationError } = useNotification();
  const { mutateAsync: registerM } = useAuthRegisterM();

  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit: RegistrationFormSuccessCallback = async (data) => {
    try {
      await registerM({
        email: data.email,
        country: data.country,
        phone: data.code + data.phone,
        firstName: data.firstName,
        roleId: data.postId,
        lastName: data.lastName,
        dealerId: data.dealersId,
      });
      setIsSuccess(true);
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      if (
        errorTyped?.response?.data.message ===
        "User with current email already register"
      ) {
        createNotificationError(t("authorization.alreadyRegEmail") || "");
      } else if (errorTyped?.response?.data.message === "Check email domain") {
        createNotificationError(t("authorization.domainError") || "");
      }
    }
  };
  return (
    <IdentityLayout>
      <RegisterForm onSubmit={onSubmit} isSubmitSuccess={isSuccess} />
    </IdentityLayout>
  );
};
