export const useScaleValues = (maxValue: number = 500) => {
  const pointCount = 13;
  const scaleValues: any[] = [];
  let count = 0;
  const step = maxValue / pointCount;
  do {
    scaleValues.push(Math.floor(step * count));
    count += 1;
  } while (pointCount + 1 !== count);
  scaleValues.push(null);

  return { scaleValues };
};
