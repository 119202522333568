import { useAnswerAuditQuestion } from "@/helpers/api/audit-fill/hooks";
import { TAnswerOnQuestionRequest, TAuditParametrs, } from "@/helpers/api/audit-fill/types";
import { useNotification } from "@/hooks/useNotification";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import React, { useState } from "react";

import { getDefaultAuditTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";

interface IAuditQuestionsTable {
  auditId: number;
  data?: TAuditParametrs[];
  isLoadingData: boolean;
  level?: string;
  updateList: () => void;
  openChat: (paramId: string) => void;
  isSoloTable?: boolean;
  isReadOnly?: boolean;
}
export const AuditQuestionsTable: React.FC<IAuditQuestionsTable> = (props) => {
  const {
    auditId,
    isLoadingData,
    data,
    level,
    updateList,
    openChat,
    isSoloTable,
    isReadOnly,
  } = props;
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const { createNotificationError } = useNotification();
  const { mutate: answerQuestion, isLoading: isAnswerSending } =
    useAnswerAuditQuestion();

  const handleAnswerQuestion = (data: TAnswerOnQuestionRequest) => {
    setLoadingIds((prev) => [...prev, data.paramId]);
    answerQuestion({
      data,
      onSuccess: (resp) => {
        setLoadingIds((prev) => prev.filter((item) => item !== resp.paramId));
        updateList();
      },
      onError: (error) =>
        createNotificationError('Запрещенная операция в очном аудите'),
    });
  };

  const headers = getDefaultAuditTableHeaders({
    loadingIds,
    level,
    auditId,
    answerQuestion: handleAnswerQuestion,
    openChat,
    isBtnLoading: isAnswerSending || isLoadingData,
    isSoloTable,
    isReadOnly,
  });

  return (
    <>
      <StyledTableSimple>
        <TableSimple
          alignCell="start"
          alignHeader="start"
          headers={headers || []}
          data={data || []}
          autoOverflow
          withOverflow
        />
      </StyledTableSimple>
    </>
  );
};
