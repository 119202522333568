import { useGetFlexQuotasById } from "@/helpers/api/flex/settings/hooks";
import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { SettingsFields } from "../SettingsFields";
import {
  TOnSubmitCreateQuotasForm,
  useEditQuotasForm,
} from "../SettingsForm/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@/packages/loader/Loader";

export interface QuotasRecord {
  [month: string]: [];
}
export interface IEditQuotasModalProps
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitCreateQuotasForm;
  quotasId: string;
}

export const EditQuotasModal: React.FC<IEditQuotasModalProps> = (props) => {
  const { onSubmit, quotasId, onCancel, ...rest } = props;

  const { t } = useTranslation();
  const { data: quotasData, isLoading } = useGetFlexQuotasById(quotasId);

  const { form, setValue, submit, reset } = useEditQuotasForm({
    onSubmit,
    editableRecord: quotasData?.data,
  });

  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);

  const handleReset = () => {
    form.reset();
    onCancel && onCancel();
  };

  useEffect(() => {
    form.reset();
  }, [form, reset, rest.isOpen]);

  return (
    <Modal
      {...rest}
      size="medium"
      title={t("kiaFlex.updateQuote") || ""}
      confirmButtonText={t("kiaFlex.updateQuotes") || ""}
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      onSubmit={submit}
      onCancel={handleReset}
      isDisabledConfirmButton={isDisabledSubmit}
    >
      <FormProvider {...form}>
        {!isLoading ? (
          quotasData?.data && (
            <SettingsFields
              isLoading={isLoading}
              setValue={setValue}
              isDisabled={true}
              record={quotasData.data}
              setIsDisabledSubmit={setIsDisabledSubmit}
            />
          )
        ) : (
          <Loader size={48} />
        )}
      </FormProvider>
    </Modal>
  );
};
