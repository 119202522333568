import { usePermission } from "@/hooks/usePermission";
import { queryClient } from "@/providers/ReactQueryProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { accessTokenService } from "../../accessTokenService";
import { api } from "../../axiosInstance";
import { TGetProfileResponse } from "../../profile/types";
import {
  TExportFlexOrdersRequest,
  TFlexOrdersCreateRequest,
  TFlexOrdersCreateResponse,
  TFlexOrdersPatchRequset,
  TFlexOrdersPatchResponse,
  TGetFlexOrdersActiveRequest,
  TGetFlexOrdersActiveResponse,
  TGetFlexOrdersQuotasRequest,
  TGetFlexOrdersQuotasResponse,
  TGetFlexOrdersRequest,
  TGetFlexOrdersRequestById,
  TGetFlexOrdersResponse,
  TGetFlexOrdersResponseById,
  TGetFlexOrdersStatusesRequest,
  TGetFlexOrdersStatusesResponse,
  TGetFlexOrdersVehiclesRequest,
  TGetFlexOrdersVehiclesRequestById,
  TGetFlexOrdersVehiclesResponse,
  TGetFlexOrdersVehiclesResponseById,
  TOrdersDeleteRequest,
  TOrdersDeleteResponse,
} from "./types";

export const useGetFlexOrdersList = (
  request: TGetFlexOrdersRequest,
  isEnabled: boolean
) => {
  return useQuery(
    ["orders", "list", request],
    () => {
      return api.get<TGetFlexOrdersResponse>("/flex/orders", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetFlexOrdersById = (id: TGetFlexOrdersRequestById) => {
  return useQuery(["orders", "detail", id], () => {
    return api.get<TGetFlexOrdersResponseById>(`/flex/orders/${id}`);
  });
};

export const useGetFlexOrdersVehiclesById = (
  id: TGetFlexOrdersVehiclesRequestById
) => {
  const { hasAccess } = usePermission("flex.order", "read");

  const isEnabled = Boolean(
    hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "kia"
  );
  return useQuery(
    ["orders/vehicles", "detail", id],
    () => {
      return api.get<TGetFlexOrdersVehiclesResponseById>(
        `/flex/orders/${id}/vehicles`
      );
    },
    { enabled: isEnabled }
  );
};

export const useCreateFlexOrders = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFlexOrdersCreateRequest) => {
      return api.post<TFlexOrdersCreateResponse>("/flex/orders", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orders", "list"]);
      },
    }
  );
};

export const usePatchFlexOrders = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TFlexOrdersPatchRequset) => {
      return api.patch<TFlexOrdersPatchResponse>(`/flex/orders/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["orders", "list"]);
        queryClient.invalidateQueries(["orders", "detail", variables.id]);
      },
    }
  );
};

export const useGetFlexOrdersQuotasList = (
  request: TGetFlexOrdersQuotasRequest,
  isEnabled: boolean
) => {
  return useQuery(
    ["ordersQuotas", "list", request],
    () => {
      return api.get<TGetFlexOrdersQuotasResponse>("/flex/orders/quotas", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetFlexOrderStatusesList = (
  request: TGetFlexOrdersStatusesRequest
) => {
  return useQuery(["ordersStatuses", "list", request], () => {
    return api.get<TGetFlexOrdersStatusesResponse>("/flex/orders/statuses", {
      params: request,
    });
  });
};

export const useGetFlexOrdersVehiclesList = (
  request: TGetFlexOrdersVehiclesRequest
) => {
  const { hasAccess } = usePermission("flex.order", "read");

  const isEnabled = Boolean(
    hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "dealer"
  );

  return useQuery(
    ["ordersVehicles", "list", request],
    () => {
      return api.get<TGetFlexOrdersVehiclesResponse>("/flex/orders/vehicles", {
        params: request,
      });
    },

    { enabled: isEnabled }
  );
};

export const useGetFlexOrdersActiveList = (
  request: TGetFlexOrdersActiveRequest
) => {
  return useQuery(["orders", "list", request], () => {
    return api.get<TGetFlexOrdersActiveResponse>("/flex/orders/active", {
      params: request,
    });
  });
};

export const useDeleteOrders = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TOrdersDeleteRequest) => {
      return api.delete<TOrdersDeleteResponse>(`flex/orders/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["orders", "list"]);
        queryClient.invalidateQueries(["orders", "detail", variables.id]);
      },
    }
  );
};

export const useExportFlexOrders = () => {
  return useMutation((request: TExportFlexOrdersRequest) => {
    return api.get<any>(`/flex/orders/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
