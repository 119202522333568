import { useGetWarrantyStatistics, useGetWarrantyStatisticsExcel } from "@/helpers/api/warranty-audit/statistics/hooks";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { useCallback } from "react";
import { ChartWrap } from "../ChartWrap";
import { PieChart } from "../PieChart";
import { RadarChart } from "../RadarChart";
import { getFinalResultHeaders, getResults, getStaticsTableHeaders, getSummaryHeaders } from "./helpers";
import { ActionWrapper, StyledCharts } from "./index.styles";
import { useTranslation } from "react-i18next";
import { SummaryViolationTable } from "@/components/AuditWarranty/SummaryViolationTable";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { handleFileLoad } from "@/helpers/utils";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { StatisticGrid } from "@/components/AuditWarranty/FillWarrantyStages/StatisticGrid";

interface IStatistics {
  id: number;
  auditInfo: TGetWarrantyByIdResponse;
}

export const Statistics: React.FC<IStatistics> = (props) => {
  const { id, auditInfo } = props;
  const { data: statistics2, isLoading } = useGetWarrantyStatistics(id);
  const { mutateAsync: exportExel } =
    useGetWarrantyStatisticsExcel();
  const headers = getStaticsTableHeaders();
  const summaryHeaders = getSummaryHeaders();
  const finalResultHeaders = getFinalResultHeaders();
  const { t } = useTranslation();

  const getStaticsTableData = useCallback(() => {
    if (!statistics2) {
      return []
    }
    return [
      ...statistics2.data.identifiedViolations.warrantyDocs,
      ...statistics2.data.identifiedViolations.spares,
      ...statistics2.data.identifiedViolations.dppo,
    ]
  }, [statistics2])

  const getStaticsSummaryTableData = useCallback(() => {
    if (!statistics2) {
      return []
    }

    let dppo = statistics2.data?.finalResults?.dppo;
    let spares = statistics2.data?.finalResults?.spares;
    let warrantyDocs = statistics2?.data?.finalResults?.warrantyDocs;

    dppo = {
      ...dppo,
      //@ts-ignore
      title: 'Стандарты ДППО',
    }
    spares = {
      ...spares,
      //@ts-ignore
      title: 'Зарекламированные запасные части',
    }
    warrantyDocs = {
      ...warrantyDocs,
      //@ts-ignore
      title: 'Гарантийная документация и гарантийные требования',
    }
    return [
      warrantyDocs,
      spares,
      dppo,
    ]
  }, [statistics2])
  const handleExportStatistic = () => {
    exportExel(
      { id: id },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "statistic.xlsx");
        },
      }
    );
  };

  return (
    <>
      <ActionWrapper>
        <Tooltip content={t("monitoring.exportExcell") || ""}>
          <IconButton
            icon="save"
            isSquared
            variant="primary"
            color="white"
            size="m"
            onClick={handleExportStatistic}
            isLoading={isLoading}
          />
        </Tooltip>
      </ActionWrapper>
      <StatisticGrid
        auditInfo={auditInfo}
      />
      <div>
        {!isLoading ? (
          <StyledCharts>
            <ChartWrap
              title={"warrantyAudits.list.results"}
              description={"warrantyAudits.list.resultsDescr"}
              isBig={true}
            >
              <RadarChart
                dppo={getResults(statistics2?.data?.finalResults?.dppo) ?? 0}
                spares={getResults(statistics2?.data?.finalResults?.spares) || 0}
                warranty={getResults(statistics2?.data?.finalResults?.warrantyDocs) || 0}
              />
            </ChartWrap>
            <ChartWrap
              title={"warrantyAudits.list.violationR"}
              description={"warrantyAudits.list.violationDescr"}
            >
              {
                statistics2?.data?.identifiedViolations?.warrantyDocs?.length ? (
                  <PieChart
                    labels={statistics2?.data?.identifiedViolations?.warrantyDocs.map(value => value.code) ?? []}
                    data={statistics2?.data?.identifiedViolations?.warrantyDocs.map(value => Number(value.quantity)) ?? []}
                  />
                ) : (
                  <Paragraph size={14}>{t("common.noData") || ""}</Paragraph>
                )}
            </ChartWrap>
            <ChartWrap
              title={"warrantyAudits.list.violationP"}
              description={"warrantyAudits.list.violationDescrParts"}
            >
              {
                statistics2?.data?.identifiedViolations?.spares?.length ? (
                  <PieChart
                    labels={statistics2?.data?.identifiedViolations.spares.map(value => value.code) ?? []}
                    data={statistics2?.data?.identifiedViolations.spares.map(value => Number(value.quantity)) ?? []}
                  />
                ) : (
                  <Paragraph size={14}>{t("common.noData") || ""}</Paragraph>
                )}
            </ChartWrap>
          </StyledCharts>
        ) : (
          <></>
        )}
        {
          !isLoading ?
            <>
              <SummaryViolationTable
                data={statistics2?.data.identifiedViolations.warrantyDocs ?? []}
                title={'warrantyAudits.list.statisticWarrantyTitle'}
                headers={summaryHeaders}
              />
              <SummaryViolationTable
                data={statistics2?.data.identifiedViolations.spares ?? []}
                title={'warrantyAudits.list.statisticSparesTitle'}
                headers={summaryHeaders}
              />
              <SummaryViolationTable
                data={getStaticsSummaryTableData()}
                title={'warrantyAudits.list.statisticSummaryTitle'}
                headers={finalResultHeaders || []}
              />
              <SummaryViolationTable
                data={getStaticsTableData()}
                title={'warrantyAudits.list.statisticTitle'}
                headers={headers || []}
              />
            </>
            :
            <></>
        }
      </div>
    </>
  );
};
