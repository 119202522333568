import { TOnSubmitFormSyncCb } from "@/hooks/useForm";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export enum GridsFilterFormFieldsNameEnum {
  Model = "models",
  Generation = "generations",
  Restyling = "restyling",
}

export interface IGridsFilterFormValues {
  [GridsFilterFormFieldsNameEnum.Model]: string | undefined;
  [GridsFilterFormFieldsNameEnum.Generation]: string | undefined;
  [GridsFilterFormFieldsNameEnum.Restyling]: string | undefined;
}

export const DEFAULT_GRIDS_FILTER_FORM_VALUES: IGridsFilterFormValues = {
  [GridsFilterFormFieldsNameEnum.Model]: undefined,
  [GridsFilterFormFieldsNameEnum.Generation]: undefined,
  [GridsFilterFormFieldsNameEnum.Restyling]: undefined,
};

export type TOnSubmitGridsFilterFormCb = TOnSubmitFormSyncCb<any>;

export const useGridsFilterForm = (options: {
  onSubmit: TOnSubmitGridsFilterFormCb;
  initValues: IGridsFilterFormValues;
}) => {
  const form = useForm<any>({
    mode: "onChange",
    defaultValues: DEFAULT_GRIDS_FILTER_FORM_VALUES,
  });

  const { initValues, onSubmit } = options;

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  useEffect(() => {
    if (initValues) {
      form.reset(initValues, { keepDefaultValues: true });
      onSubmit(form.getValues());
    }
  }, []);

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
