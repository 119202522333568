import { FC, useContext, useEffect, useState } from 'react';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { ITab, Tabs } from '@/packages/tabs/Tabs';
import { useModal } from '@/hooks/useModal';
import { GridsTab } from '@/components/MaintenanceGrids/GridsTab';
import { GsmTab } from '@/components/MaintenanceGrids/GsmTab';
import { LogsTab } from '@/components/MaintenanceGrids/LogsTab';
import { WorksTab } from '@/components/MaintenanceGrids/WorksTab';
import { Button } from '@/packages/button/Button';
import styled from 'styled-components';
import { GridsCreateModal } from '@/components/MaintenanceGrids/GridsTab/GridsCreateModal';
import { GridsEditModal } from '@/components/MaintenanceGrids/GridsTab/GridsEditModal';
import { WorksCreateModal } from '@/components/MaintenanceGrids/WorksTab/WorksCreateModal';
import { WorksEditModal } from '@/components/MaintenanceGrids/WorksTab/WorksEditModal';
import { GsmCreateModal } from '@/components/MaintenanceGrids/GsmTab/GsmCreateModal';
import { GsmEditModal } from '@/components/MaintenanceGrids/GsmTab/GsmEditModal';
import { WorksRatePerHourModal } from '@/components/MaintenanceGrids/WorksTab/WorksRatePerHourModal';
import { useNavigate } from 'react-router-dom';
import { TMaintenanceGridsRecord } from '@/helpers/api/maintenanceGrids/grids/types';
import { ContextMaintenanceGrids, MAINTENANCE_GRIDS } from '@/hooks/contexts/useMaintenanceGrids';
import { usePermission } from '@/hooks/usePermission';
import { PermissionsAdapter } from '@/adapters/shared/PermissionsAdapter';
import { useCopyCurrentUrl } from '@/hooks/useCopyCurrentUrl';
import { useTranslation } from 'react-i18next';
import { ExportTab } from '@/components/MaintenanceGrids/ExportTab';
import { SparesEditModal } from '@/components/MaintenanceGrids/GsmTab/SparesEditModal';
import { useActiveTab } from '@/hooks/useActiveTab';
import { SpareCreateModal } from '@/components/MaintenanceGrids/GsmTab/SpareCreateModal';
import { useGetProfile } from '@/helpers/api/profile/hooks';

const StyledContent = styled.div`
    margin-bottom: 64px;
`;

const MaintenanceGrids: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ isTabChanged, setIsTabChanged ] = useState<ITab>();
    const copyCurrentUrl = useCopyCurrentUrl({
        successMessage: t('maintenanceGrids.copyCurrentUrl') || '',
    });

    const profile = useGetProfile();
    const isDealerProfileType = profile?.data?.data.type === 'dealer';
    const { hasAccess: hasEdit } = usePermission('maintenance-grid', 'update');
    const { hasAccess: hasCreate } = usePermission('maintenance-grid', 'create');
    const {
        mutations: { setPage },
        actions: { handleWorksSort, handleGSMSort, handleSparesSort },
    } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

    const {
        modalState: maintenanceGridsCreateModalState,
        openModal: openMaintenanceGridsCreateModalState,
    } = useModal();

    const {
        modalState: maintenanceGridsEditModalState,
        openModal: openMaintenanceGridsEditModalState,
    } = useModal();

    const {
        modalState: worksCreateModalState,
        openModal: openWorksCreateModalState,
    } = useModal();

    const {
        modalState: worksEditModalState,
        openModal: openWorksEditModalState,
    } = useModal();

    const {
        modalState: worksRatePerHourModalState,
        openModal: openWorksRatePerHourModalState,
    } = useModal();

    const {
        modalState: gsmCreateModalState,
        openModal: openGsmCreateModalState,
    } = useModal();

    const {
        modalState: spareCreateModalState,
        openModal: openSpareCreateModalState,
    } = useModal();

    const { modalState: gsmEditModalState, openModal: openGsmEditModalState } =
        useModal();

    const { modalState: sparesEditModal, openModal: openSparesEditModal } =
        useModal();

    const handleGridsEditButtonClick = (row: TMaintenanceGridsRecord) => {
        navigate(`/maintenance-grids/details/edit/${row.id}`);
    };

    const handleGridsShareButtonClick = (row: TMaintenanceGridsRecord) => {
        copyCurrentUrl(`${window.location.href.split('?')[0]}/details/view/${row.id}`);
    };

    const tabs = (hasEdit: boolean): ITab[] =>
        [
            {
                name: t('maintenanceGrids.title') || '',
                slug: 'grids',
                content: (
                    <GridsTab
                        onEdit={handleGridsEditButtonClick}
                        hasEdit={hasEdit}
                        onShare={handleGridsShareButtonClick}
                    />
                ),
            },
            hasCreate
                ? {
                    name: t('maintenanceGrids.works') || '',
                    slug: 'works',
                    content: (
                        <WorksTab
                            hasEdit={hasEdit}
                            onOpenCreateModal={openWorksCreateModalState}
                            onOpenEditModal={openWorksEditModalState}
                            onOpenRatePerHourModal={openWorksRatePerHourModalState}
                            handleSort={handleWorksSort}
                        />
                    ),
                }
                : [],
            hasCreate
                ? {
                    name: t('maintenanceGrids.gsmModel.gsmTab') || '',
                    slug: 'gsm',
                    content: (
                        <GsmTab
                            hasEdit={hasEdit}
                            onOpenCreateModalGsm={openGsmCreateModalState}
                            onOpenCreateModalSpare={openSpareCreateModalState}
                            onOpenEditModal={openGsmEditModalState}
                            onOpenSparesEditModal={openSparesEditModal}
                            handleSort={handleGSMSort}
                            handleSparesSort={handleSparesSort}
                        />
                    ),
                }
                : [],
            hasEdit
                ? {
                    name: t('maintenanceGrids.logs') || '',
                    slug: 'logs',
                    content: <LogsTab isTabChanged={isTabChanged}/>,
                }
                : [],
            {
                name: t('maintenanceGrids.export') || '',
                slug: 'export',
                content: <ExportTab/>,
            },
        ].flat();

    const { activeTab, setActiveTab } = useActiveTab(tabs(true));


    useEffect(() => {
        if (activeTab?.name !== isTabChanged?.name) {
            setIsTabChanged(activeTab);
        }
    }, [ activeTab ]);

    return (
        <DashboardLayout
            title={t('maintenanceGrids.title') || ''}
            description={t('maintenanceGrids.description') || ''}
        >
            <PermissionsAdapter
                resourceName='maintenance-grid'
                resourceAction='read'
                fallback={() => <div>No Access</div>}
            >
                <StyledContent>
                    <Tabs
                        tabs={tabs(hasEdit)}
                        activeTab={activeTab}
                        onChange={(tab) => {
                            setActiveTab(tab);
                            setPage(1);
                        }}
                        type='card'
                    />
                </StyledContent>
                {(activeTab?.slug === 'grids' || !activeTab) && (
                    <PermissionsAdapter
                        resourceAction='update'
                        resourceName='maintenance-grid'
                    >
                        <Button
                            color='brand'
                            size='middle'
                            theme='primary'
                            onClick={openMaintenanceGridsCreateModalState}
                        >
                            {t('maintenanceGrids.addNewGrid') || ''}
                        </Button>
                    </PermissionsAdapter>
                )}

                <GridsCreateModal {...maintenanceGridsCreateModalState} />
                <GridsEditModal {...maintenanceGridsEditModalState} />

                <WorksCreateModal {...worksCreateModalState} />
                <WorksEditModal {...worksEditModalState} />
                <WorksRatePerHourModal {...worksRatePerHourModalState} />

                <GsmCreateModal {...gsmCreateModalState} />
                <SpareCreateModal {...spareCreateModalState} />

                <GsmEditModal {...gsmEditModalState} />
                <SparesEditModal {...sparesEditModal} />
            </PermissionsAdapter>
        </DashboardLayout>
    );
};

export { MaintenanceGrids };
