import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledModalContentWrapper, StyledTitle } from "./index.styles";
import { useFormValues } from "./hooks";
import { Title } from "@/packages/title/Title";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import {
  preparedDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { useCopyAudit } from "@/helpers/api/audit-list/hooks";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

type ICopyAuditModal = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadAuditTemplateBase {
  onSubmit: () => void;
  auditId: number;
}

export const CopyAuditModal: React.FC<
  ICopyAuditModal & IUploadAuditTemplateBase
> = (props) => {
  const { auditId, onSubmit, onCancel } = props;
  const { mutate: copyAudit, isLoading } = useCopyAudit();
  const [searchValue, setSearchValue] = useState<string>("");
  const { data: dealerList, isLoading: isDealersLoading} =
    useGetFilteredDealerList({ q: searchValue, limit: 40 });

  const onFormChange = (value) => {
    copyAudit({
      data: { ...value, auditId },
      onSuccess: handleSubmit,
    });
  };

  const { form, onSubmitHandler, setDefaultValue, resetValues } =
    useFormValues(onFormChange);

  const handleSubmit = () => {
    resetValues();
    onSubmit();
  };

  useEffect(() => {
    setSearchValue("");
  }, []);
  const preparedDealer = useMemo(
    () => preparedDealerList(dealerList?.data?.data || []) || [],
    [dealerList, isDealersLoading]
  );

  const handleCancel = () => {
    form.reset();
    onCancel && onCancel();
  };
  return (
    <Modal
      {...props}
      title="Копирование аудита"
      size="small"
      isLoadingConfirmButton={isLoading}
      cancelButtonText="Отменить"
      confirmButtonText="Создать аудит"
      onSubmit={onSubmitHandler}
      onCancel={handleCancel}
    >
      <StyledModalContentWrapper>
        <StyledTitle>
          <Title level={3} weight={400}>
            Копирование аудита
          </Title>
        </StyledTitle>
        <FormProvider {...form}>
          <FormInputField
            name="title"
            label="Наименование аудита"
            placeholder="Введите наименование аудита"
          />
          <FormSelect
            name="dealerId"
            label="Выбрать дилерский центр"
            isSearchShow={true}
            onSearch={(value) => setSearchValue(value)}
            placeholder="Выберите дилерские центры"
            options={preparedDealer || []}
            // isDisabled={isDealersLoading}
          />
          <FormCheckbox name="withData" label="Копировать с данными" />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
