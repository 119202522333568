import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { IDataItem } from "@/packages/nestedTable/NestedTable";
import { TFinalResult } from "@/helpers/api/warranty-audit/statistics/types";
import styled from "styled-components";

const StyledCell = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
`
export const getStaticsTableHeaders = (): IHeaderItem<IDataItem>[] => {
  return [
    {
      Header: "Тип нарушения",
      accessor: "code",
      minWidth: 50,
      maxWidth: 80,
    },
    {
      Header: "Описание нарушения",
      accessor: "description",
    },
    {
      Header: "Кол-во найденных нарушений",
      accessor: "quantity",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{original.quantity}</StyledCell>
      },
    },
  ];
};


export const getSummaryHeaders = (): IHeaderItem<IDataItem>[] => {
  return [
    {
      Header: "Тип нарушения",
      accessor: "code",
      minWidth: 50,
      maxWidth: 80,
    },
    {
      Header: "Описание нарушения",
      accessor: "description",
    },
    {
      Header: "Кол-во найденных нарушений",
      accessor: "quantity",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{original.quantity}</StyledCell>
      },
      Footer: () => <StyledCell>Итого</StyledCell>,
    },
    {
      Header: "Кол-во отозванных средств",
      accessor: "amount",
      maxWidth: 200,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{original.amount.toFixed(2).replace('.', ',')}</StyledCell>
      },
      Footer: (props) => {
        let total = 0;
        props.data.forEach((el) => {
          total = total + el.amount;
        });
        return <StyledCell>{total.toFixed(2).replace('.', ',')}</StyledCell>
      },
    },
  ]
}

export const getFinalResultHeaders = (): IHeaderItem<IDataItem>[] => {
  return [
    {
      Header: "Направление деятельности предприятия",
      accessor: "title",
      Footer: () => 'Итого',
    },
    {
      Header: "Максимум баллов",
      accessor: "max",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{original.max}</StyledCell>
      },
      Footer: (props) => {
        let total = 0;
        props.data.forEach((el) => {
          total = total + Number(el.max);
        });
        return <StyledCell>{total}</StyledCell>
      },

    },
    {
      Header: "Достигнуто баллов",
      accessor: "current",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{original.current}</StyledCell>
      },
      Footer: (props) => {
        let total = 0;
        props.data.forEach((el) => {
          total = total + Number(el.current);
        });
        return <StyledCell>{total}</StyledCell>
      },
    },
    {
      Header: "Максимум в %",
      accessor: "maxPercent",
      minWidth: 100,
      maxWidth: 100,
      Cell: () => <StyledCell>100</StyledCell>,
      Footer: () => <StyledCell>100</StyledCell>,
    },
    {
      Header: "Достигнуто в %",
      accessor: "currentPercent",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return <StyledCell>{getResults(original)}</StyledCell>
      },
      Footer: (props) => {
        let totalCurrent = 0;
        props.data.forEach((el) => {
          totalCurrent = totalCurrent + Number(el.current);
        });

        let totalMax = 0;
        props.data.forEach((el) => {
          totalMax = totalMax + Number(el.max);
        });

        return <StyledCell>{Math.round(totalCurrent / totalMax * 100)}</StyledCell>
      },
    },
    {
      Header: "Эталонный уровень",
      accessor: "referenceLevel",
      minWidth: 100,
      maxWidth: 100,
      Cell: () => <StyledCell>100</StyledCell>,
      Footer: () => <StyledCell>100</StyledCell>,
    },
    {
      Header: "Допустимый уровень в %",
      accessor: "acceptableLevel",
      minWidth: 100,
      maxWidth: 100,
      Cell: () => <StyledCell>90</StyledCell>,
      Footer: () => <StyledCell>90</StyledCell>,
    },
  ]
}

export const getResults = (value: TFinalResult | undefined | IDataItem) => {
  if (!value) {
    return
  }
  return Math.round(Number(value.current) / Number(value.max) * 100)
}


