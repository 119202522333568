import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TCreateWarrantyAuditViolationTemplateRequest } from "@/helpers/api/warranty-audit/templates/types";
import {
  DEFAULT_FORM_VALUES,
  formSchema
} from "@/components/AuditWarranty/Templates/ViolationCreateTemplateModal/hooks";

export interface ITemplateValues {
  code?: string;
  pointAmount?: number;
  description?: string;
  templateId?: number;
  id?: string;
}

export type TOnSubmitFormCb = (values: ITemplateValues) => void;

export type TOnSubmitEditValidationFormCallback = (payload: {
  id: string,
  patch: TCreateWarrantyAuditViolationTemplateRequest
}) => void;

export const useFormValues = (onSubmit: TOnSubmitEditValidationFormCallback) => {
  const form = useForm<ITemplateValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data: any) => {
    const value: { id: string, patch: TCreateWarrantyAuditViolationTemplateRequest } = {
      id: data.id,
      patch: {
        templateId: data.templateId,
        description: data.description,
        pointAmount: data.pointAmount,
        code: data.code,
      }
    }
    onSubmit(value);
  });

  return {
    form,
    onSubmitHandler,
  };
};