import * as yup from "yup";

export interface IAuditBatchResponseToMutate {
  children?: any[];
  id: string;
  title: string;
  order: number;
  parentId: string | null;
  level?: string;
  isChecked?: boolean;
}

export const removeIdDeep = (
  data: IAuditBatchResponseToMutate[],
  idToRemove: string
) => {
  const filtered = data.filter((entry) => entry.id !== idToRemove);
  return filtered.map((entry) => {
    if (!entry.children) return entry;
    return { ...entry, children: removeIdDeep(entry.children, idToRemove) };
  });
};

export const batchIPFieldsSchema = yup.array().of(
  yup.object({
    event: yup.mixed().oneOf(["add", "edit", "delete"]),
    payload: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        title: yup
          .string()
          .min(1, "Название не должно быть пустое")
          .max(30, "Максимально допустимое количество символов - 30"),
        parentId: yup.string(),
        order: yup.number(),
      })
    ),
  })
);

export const mutateArrOfObj = (arrObj: IAuditBatchResponseToMutate[]) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  const result = res.map((item, index) => recursive(item, 0, `${index + 1}`));

  return result;
};

export const mutateArrOfObjDTO = (arrObj: IAuditBatchResponseToMutate[]) => {
  if (!arrObj) {
    return arrObj;
  }

  const transform1 = ({ id, name, order, parrent_id, children }) => {
    return children
      ? [{ id, name, order, parrent_id }, ...transformAll(children)]
      : [{ id, name, order, parrent_id }];
  };

  const transformAll = (children) => children.flatMap((c) => transform1(c));

  // Удаление дубликатов при рекурсии
  const uniqueArray = transformAll(arrObj).filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      transformAll(arrObj).findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });

  return uniqueArray;
};
