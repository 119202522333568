import { Checkbox } from "@/packages/checkbox/Checkbox";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Popconfirm } from "antd";

import { StyledButtons } from "./index.styles";
import { TPutWarrantyParameterRequest, } from "@/helpers/api/warranty-audit/templates/types";
import { t } from "i18next";

export const getDefaultWarrantyTableHeaders = <
  TRecord extends { id: string } & TPutWarrantyParameterRequest["body"]
>(options: {
  isDppo?: boolean;
  onDeleteClick?: (id: string) => void;
  onEditClick: (id: string) => void;
  onPhotoParamEdit: (
    id: string,
    value: TPutWarrantyParameterRequest["body"]
  ) => void;
}): IHeaderItem<TRecord>[] => {
  const {
    isDppo = false,
    onDeleteClick,
    onEditClick,
    onPhotoParamEdit,
  } = options;

  return [
    {
      Header: t("warrantyAudits.templates.code") || "",
      accessor: "code",
    },
    {
      Header: t("warrantyAudits.templates.violation") || "",
      accessor: "templateViolation",
      Cell: ({ row: { original } }) => {
        return `${original?.templateViolation?.code ?? ''}`;
      },
    },
    {
      Header: t("audits.list.criteria") || "",
      accessor: "title",
    },
    {
      Header: t("warrantyAudits.templates.deacceptType") || "",
      accessor: "deacceptType",
      isHeaderHidden: !!isDppo,
    },
    {
      Header: t("audits.templates.photo") || "",
      accessor: "photo",
      Cell: ({ row: { original }, value }) => {
        return (
          <Checkbox
            value={value}
            onChange={(flag) =>
              onPhotoParamEdit(original?.id, {
                ...original,
                photo: flag,
              })
            }
          />
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: "110px",
      minWidth: 110,
      maxWidth: 110,

      Cell: ({ row: { original } }) => {
        return (
          <StyledButtons>
            <IconButton
              icon="edit"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => onEditClick(original.id)}
            />
            <Popconfirm
              title={t("audits.deleteConfirmParam") || ""}
              onConfirm={() => onDeleteClick && onDeleteClick(original.id)}
              okText={t("common.yes") || ""}
              cancelText={t("common.no") || ""}
            >
              <IconButton
                icon="delete"
                color="black"
                size="l"
                variant="tertiary"
              />
            </Popconfirm>
          </StyledButtons>
        );
      },
    },
  ].filter((item) => !item?.isHeaderHidden);
};
