import { components, paths } from "../swagger-scheme";

export enum NotificationType {
  CONFIRM_REGISTRATION_BY_DEALER = "confirm-registration-by-dealer",
  CONFIRM_REGISTRATION_BY_KIA = "confirm-registration-by-kia",
  CHANGE_ROLE = "confirm-change-role",
  ROLE_DELEGATION = "role-delegation",
  MONITORING_STUDY_CLOSED = "monitoring-study-closed",
  MONITORING_NEW_PROGRAM = "monitoring-new-program",
  MONITORING_REQUEST = "monitoring-request",
  AUDIT_DEADLINE = "audit-deadline",
  GUARANTEE_AUDIT_DONE = "guarantee-audit-done",
  GUARANTEE_TASK_DEADLINE = "guarantee-task-deadline",
  GUARANTEE_PLAN_DECLINE = "guarantee-plan-decline",
  GUARANTEE_VIOLATIONS = "guarantee-violations",
  GUARANTEE_PLAN_FILLED_BY_DEALER = "guarantee-plan-filled-by-dealer",
  INFORMATION_LETTER = "information-letter",
  AUDIT_STARTED = "audit-started",
  GUARANTEE_AUDIT_STARTED = "guarantee-audit-started",
  AUDIT_PARAM_LEFT_COMMENT = "audit-param-left-comment",
  AUDIT_REVIEW = "audit-review",
  AUDIT_CREATE_TASKS = "audit-create-tasks",
  AUDIT_TASKS = "audit-tasks"
}

export type TNotificationsStatus = "new" | "rejected" | "confirmed";

export type TGetNotificationsRequest =
  paths["/api/notifications"]["get"]["parameters"]["query"];

export type TGetNotificationsResponse =
  paths["/api/notifications"]["get"]["responses"]["200"];

export type TGetNotificationByIdRequest =
  paths["/api/notifications/{id}"]["get"]["parameters"]["path"];

export type TGetNotificationByIdResponse =
  paths["/api/notifications/{id}"]["get"]["responses"]["200"]["content"]["application/json"];

export type TUpdateNotificationsRequest =
  paths["/api/notifications/bulk-favorite"]["patch"]["requestBody"]["content"]["application/json"];

export type TUpdateNotificationsResponse =
  paths["/api/notifications/bulk-favorite"]["patch"]["responses"]["200"];

//schemas
export type TNotificationWithStringId =
  components["schemas"]["TextAndButtonWithStringIdNotification"];

export type TRegistrationByDealerNotification =
  components["schemas"]["ConfirmRegistationByDealerNotification"];

export type TRegistrationByDKiaNotification =
  components["schemas"]["ConfirmRegistationByKiaNotification"];

export type TChangeRoleNotification =
  components["schemas"]["ChangeRoleNotification"];

export type TMonitoringNewProgramNotification =
  components["schemas"]["MonitoringNewProgramNotification"];
