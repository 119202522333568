import { FC, useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Title } from "@/packages/title/Title";
import {
  StyledButtonsRow,
  StyledChangeDelegationBlock,
  StyledChangeDelegationLabel,
  StyledDelegationFields,
  StyledDelegrationRow,
  StyledPage,
  StyledSettingBoxSmall,
  StyledSettingsBoxHeader,
  StyledSettingsList,
  StyledSettingsListField,
  StyledSettingsListItem,
  StyledSettingsListLabel,
  StyledSettingsWrapper,
} from "./index.style";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Switch } from "@/packages/formElements/inputs/switch";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useGetProfile, useUpdateProfile } from "@/helpers/api/profile/hooks";
import { useGetUserList } from "@/helpers/api/users/hooks";
import {
  useCreateDelegation,
  useDeleteDelegation,
  useGetDelegationsFromUser,
  usePatchDelegation,
} from "@/helpers/api/rightsDelegations/hooks";
import { useRightsDelegationForm } from "./hooks";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { Modal } from "@/packages/modal/Modal";
import { useTranslation } from "react-i18next";
import { ContextProfile } from "@/hooks/contexts/useProfile";

const ChangeSettings: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [modalText, setModalText] = useState<string>(
    t("profile.successDelegated") || ""
  );

  const { createNotificationError } = useNotification();

  // const { mutateAsync: patchProfileAsync } = useUpdateProfile();
  const { mutateAsync: createDelegationAsync } = useCreateDelegation();
  const { mutateAsync: patchDelegationAsync } = usePatchDelegation();
  const { mutateAsync: deleteDelegationAsync } = useDeleteDelegation();
  const { data: delegations, refetch: refetchDelegations } =
    useGetDelegationsFromUser();

  const currentDelegation = delegations?.data.data[0] || null;
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const isProfileKia = profile?.type === "kia";
  const isProfileDealer = profile?.type === "dealer";
  const dealerId = profile?.dealer?.id;

  const { data } = useGetProfile();
  const { mutate: updateProfile } = useUpdateProfile();
  const { data: userListResponse } = useGetUserList({
    limit: 0,
    dealerId: dealerId,
    showOnlyDelegatedRoles: isProfileDealer,
    type: isProfileKia ? "kia" : undefined,
  });
  const userList = userListResponse?.data.data ?? [];
  const selectEmployeeOptions = [
    {
      label: t("profile.chooseEmployee") || "",
      value: "",
    },
    ...userList.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
  ];

  const onSubmit = async (data) => {
    try {
      if (currentDelegation) {
        await patchDelegationAsync({
          id: currentDelegation.id,
          patch: {
            toUser: data.employee,
            beginDate: data.delegationDateStart,
            endDate: data.delegationDateEnd,
          },
        });
      } else {
        await createDelegationAsync({
          toUser: data.employee,
          beginDate: data.delegationDateStart,
          endDate: data.delegationDateEnd,
        });
      }

      setModalOpen(true);
      setIsEditMode(false);
    } catch {
      createNotificationError(t("profile.error") || "");
    }
  };


  const { form, onSubmitButtonClickHandler } =
    useRightsDelegationForm(onSubmit);

  const cancelDelegation = async () => {
    try {
      await deleteDelegationAsync({
        id: currentDelegation.id,
      });
      form.reset();
      setModalText(t("profile.canceledSuccess") || "");
      setModalOpen(true);
      refetchDelegations();
    } catch {
      createNotificationError(t("profile.canceledError") || "");
    }
  };

  const changeChecked = (check: boolean) => {
    setChecked(check);
    // @ts-ignore
    updateProfile({ patch: { isSendingEmail: check } });
  };

  useEffect(() => {
    if (data) {
      setChecked(data.data.isSendingEmail);
    }
  }, [data]);

  useEffect(() => {
    console.log('trigger');
    if (currentDelegation) {
      form.setValue("employee", currentDelegation.toUser.id);
      form.setValue(
        "delegationDateStart",
        new Date(currentDelegation.beginDate)
      );
      form.setValue("delegationDateEnd", new Date(currentDelegation.endDate));
      setIsEditMode(false);
    }
  }, [currentDelegation, form]);


  const getMinDate = (watchDate) => {
    if (!watchDate) {
      return;
    }
    let date = new Date(watchDate.getTime());
    date.setDate(date.getDate() - 21);
    return date;
  }

  const getMaxDate = (watchDate) => {
    if (!watchDate) {
      return;
    }
    let date = new Date(watchDate.getTime());
    date?.setDate(date.getDate() + 20);
    return date;
  }

  const isDealer = profile?.type === 'dealer';


  return (
    <DashboardLayout
      toBack="/profile"
      title={t("profile.settings.title") || ""}
    >
      <StyledPage>
        <StyledSettingsWrapper>
          <StyledSettingBoxSmall>
            <StyledSettingsBoxHeader>
              <Title level={2}>
                {t("profile.settings.notifications") || ""}
              </Title>
            </StyledSettingsBoxHeader>
            <StyledSettingsList>
              <StyledSettingsListItem>
                <StyledSettingsListLabel>
                  {t("profile.settings.notificationsToMail") || ""}
                </StyledSettingsListLabel>
                <StyledSettingsListField>
                  <Switch onChange={changeChecked} value={checked}/>
                </StyledSettingsListField>
              </StyledSettingsListItem>
            </StyledSettingsList>
          </StyledSettingBoxSmall>
          <StyledSettingBoxSmall>
            <StyledSettingsBoxHeader>
              <Title level={2}>
                {t("profile.settings.rightsDelegation") || ""}
              </Title>
            </StyledSettingsBoxHeader>
            <StyledDelegationFields>
              <FormProvider {...form}>
                <FormSelect
                  name="employee"
                  label={t("profile.settings.kiaEmployee") || ""}
                  options={selectEmployeeOptions}
                  isDisabled={!isEditMode}
                  isSearchShow
                />
                <StyledDelegrationRow>
                  <FormDatepickerField
                    name="delegationDateStart"
                    label={t("profile.settings.delegationDataBeginning") || ""}
                    isDisabled={!isEditMode}
                    minDate={isDealer ? getMinDate(form.watch('delegationDateEnd')) : undefined}
                    maxDate={form.watch('delegationDateEnd')}
                  />
                  <FormDatepickerField
                    name="delegationDateEnd"
                    label={t("profile.settings.delegationDataEnding") || ""}
                    isDisabled={!isEditMode}
                    minDate={form.watch('delegationDateStart')}
                    maxDate={isDealer ? getMaxDate(form.watch('delegationDateStart')) : undefined}
                  />
                </StyledDelegrationRow>
                {!isEditMode && (
                  <StyledChangeDelegationBlock>
                    <StyledChangeDelegationLabel>
                      {t("profile.settings.delegationBtnDescription") || ""}
                    </StyledChangeDelegationLabel>
                    <div>
                      <Button
                        onClick={() => setIsEditMode(true)}
                        theme="secondary"
                        color="brand"
                        size="middle"
                      >
                        {t("profile.settings.change") || ""}
                      </Button>
                    </div>
                  </StyledChangeDelegationBlock>
                )}
              </FormProvider>
            </StyledDelegationFields>
            <StyledButtonsRow>
              <Button
                onClick={onSubmitButtonClickHandler}
                theme="primary"
                color="brand"
                size="middle"
              >
                {t("profile.settings.save") || ""}
              </Button>
              {currentDelegation && isEditMode && (
                <Button
                  onClick={cancelDelegation}
                  theme="primary"
                  color="brand"
                  size="middle"
                >
                  {t("profile.settings.delegationCancel") || ""}
                </Button>
              )}
            </StyledButtonsRow>
            <Modal
              isOpen={modalOpen}
              showCancelButton={false}
              confirmButtonText={t("profile.settings.close") || ""}
              onSubmit={() => {
                setModalOpen(false);
                setModalText(
                  t("profile.settings.delegationMessageSuccess") || ""
                );
              }}
            >
              {modalText}
            </Modal>
          </StyledSettingBoxSmall>
        </StyledSettingsWrapper>
      </StyledPage>
    </DashboardLayout>
  );
};

export default ChangeSettings;
