import { Checkbox } from "@/packages/checkbox/Checkbox";

import { IHeaderItem } from "@/packages/tableSimple/TableSimple";

export const MOCK_AUDIT_FILTERS = [
  { label: "Все", value: "" },
  { label: "Активный", value: "true" },
  { label: "Скрытый", value: "false" },
];

export const getDefaultAuditTableHeaders = <
  TRecord extends object
>(options: {}): IHeaderItem<TRecord>[] => {
  return [
    {
      Header: " № Вопроса",
      accessor: "title",
    },
    {
      Header: "Параметр проверки",
      accessor: "criteria",
    },
    {
      Header: "План действий",
      accessor: "plan",
    },
    {
      Header: "Признак вопроса",
      accessor: "attribute",
    },
    {
      Header: "Параметр KPI",
      accessor: "kpi",
    },
    {
      Header: "Вес параметра",
      accessor: "weight",
    },
    {
      Header: "Обязательное фото",
      accessor: "photo",
      Cell: ({ row: { original }, value }) => {
        return <Checkbox value={value} />;
      },
    },
  ];
};
