import { TOnSubmitFormCb, useForm } from "@/hooks/useForm";
import {
  ICompleteSetDTOValues,
  ICompleteSetFormValues,
  ICompleteSetSuccessFormValues,
} from "../CompleteSetFields/types";
import {
  completeSetFormSchema,
  completeSetValuesToDTO,
  DEFAULT_COMPLETE_SET_FORM_VALUES,
} from "../CompleteSetFields/utils";

export interface ICreateCompleteSetPayload extends ICompleteSetDTOValues {
  generationId: string;
}

export type TOnSubmitCreateCompleteFormFormCallback = (
  values: ICreateCompleteSetPayload
) => void;

export const useCreateCompleteSetForm = (options: {
  generationId?: string;
  onSubmit: TOnSubmitCreateCompleteFormFormCallback;
}) => {
  const { onSubmit, generationId } = options;

  const onSubmitHandler: TOnSubmitFormCb<
    ICompleteSetSuccessFormValues
  > = async (values) => {
    if (generationId) {
      const dtoValues = completeSetValuesToDTO(values);
      await onSubmit({
        ...dtoValues,
        generationId,
      });
    }
  };
  return useForm<ICompleteSetFormValues, ICompleteSetSuccessFormValues>({
    validateFormSchema: completeSetFormSchema,
    onSubmit: onSubmitHandler,
    defaultValues: DEFAULT_COMPLETE_SET_FORM_VALUES,
  });
};
