import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringCarsFilter } from "@/components/MonitoringQuality/MonitoringCars/MonitoringCarsFilter";
import { IMonitoringCarsFilterOptions } from "@/components/MonitoringQuality/MonitoringCars/MonitoringCarsFilter/hooks";
import { MonitoringCarsTable } from "@/components/MonitoringQuality/MonitoringCars/MonitoringCarsTable";
import {
  useExportKiaMonitoringQualityCars,
  useGetKiaMonitoringQualityCars,
  useGetMonitoringQualityById,
  useGetMonitoringQualityCarsFilters,
} from "@/helpers/api/monitoringQuality/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Loader } from "@/packages/loader/Loader";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, createEnumParam, NumberParam, StringParam, useQueryParams } from "use-query-params";
import { StyledPagination } from "./index.styles";


export const MonitoringQualityCars: React.FC = () => {
  const { paginationParams, setPage } = usePagination();
  const params = useParams();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    cityIds: StringParam,
    dealerIds: StringParam,
    vins: StringParam,
    generationIds: StringParam,
    onlyUnreadMessages: BooleanParam,
    status: createEnumParam(["Все", "Открыто", "Закрыто", "Поиск завершен"]),
    page: NumberParam,
  });

  useEffect(() => {
    const numberPage = Number(filterQueryParams.page);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setFilterQueryParams({
      ...filterQueryParams,
      page: paginationParams.page
    })
  }, [paginationParams.page])

  const filters = {
    qualityMonitoringIds: [params.id as string],
    cityIds: filterQueryParams.cityIds
      ? filterQueryParams.cityIds.split(",")
      : undefined,
    dealerIds: filterQueryParams.dealerIds
      ? filterQueryParams.dealerIds.split(",")
      : undefined,
    vins: filterQueryParams.vins
      ? filterQueryParams.vins.split(",")
      : undefined,
    generationIds: filterQueryParams.generationIds
      ? filterQueryParams.generationIds.split(",")
      : undefined,
    status: filterQueryParams.status || undefined,
    onlyUnreadMessages: filterQueryParams.onlyUnreadMessages || false,
  };

  const [sortParams, setSortParams] = useState({});

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      sortBy: header.id,
      orderBy: order?.toUpperCase(),
    }));
  };

  const { mutateAsync: exportKiaMonitoringQualityCars } =
    useExportKiaMonitoringQualityCars();

  const {
    data: monitoringQualityCarsData,
    isLoading,
    refetch,
  } = useGetKiaMonitoringQualityCars({
    ...filters,
    ...paginationParams,
    ...sortParams,
  });

  const monitoringQualityCarsList = monitoringQualityCarsData?.data.data;
  const qualityMonitoringIds = monitoringQualityCarsData?.data.data.map(
    //@ts-ignore
    (quality) => quality?.qualityMonitoring?.id
  );
  const { data: monitoringQualityCarsFilters } =
    useGetMonitoringQualityCarsFilters({ qualityMonitoringIds });

  const filtersOptions: IMonitoringCarsFilterOptions | undefined =
    useMemo(() => {
      if (monitoringQualityCarsFilters?.data) {
        const { cities, dealers, generations, vins } =
          monitoringQualityCarsFilters?.data;

        return {
          cities: cities
            //@ts-ignore
            ?.map((x) => ({
              value: x.id as string,
              label: x.name as string,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1)),
          dealers: dealers
            //@ts-ignore
            ?.map((x) => ({
              value: x.id as string,
              label:
                `${x.sapCode} / ${x.mobisCode} / ${x.merchantName}` as string,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1)),
          generations: generations
            //@ts-ignore
            ?.map((x) => ({
              value: x.id as string,
              label: `${x.fullModelName} ` as string,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1)),
          vins: vins
            //@ts-ignore
            ?.map((x) => ({
              value: x.vin as string,
              label: x.vin as string,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1)),
        };
      }
    }, [monitoringQualityCarsFilters]);

  const handleMonitoringQualityCarsExport = () => {
    exportKiaMonitoringQualityCars(
      {
        ...filters,
        ...paginationParams,
        ...sortParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "monitoring-quality-cars.xlsx");
        },
      }
    );
  };
  const { data: dealerInfo } = useGetMonitoringQualityById({
    id: params.id as string,
  });

  return (
    <DashboardLayout
      title={dealerInfo?.data.title || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName="quality-monitoring.request-cars"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        isLoading={isLoading}
        loader={<Loader size={48}/>}
      >
        <MonitoringCarsFilter
          initValues={filters}
          filterOptions={filtersOptions}
          onFiltersChange={(value) => {
            setFilterQueryParams(value);
          }}
          handleExport={handleMonitoringQualityCarsExport}
          resetPage={() => setPage(1)}
        />
        {isLoading ? (
          <Loader size={48}/>
        ) : (
          <>
            <MonitoringCarsTable
              monitoringQualityCarsList={monitoringQualityCarsList}
              onSort={handleSort}
              refetchRequestCars={refetch}
            />
            <StyledPagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={10}
              total={monitoringQualityCarsData?.data.meta?.itemCount || 0}
            />
          </>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
