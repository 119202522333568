import styled from "styled-components";

import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";

export const StyledCoefficientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${colors.white};
`;

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledNpsValueHeaderColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  flex-basis: 20%;
`;

export const StyledFactorHeaderColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 15%;
`;

export const StyledDescriptionHeaderColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 45%;
`;

export const StyledNpsValues = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
`;

export const StyledNonDnmValue = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
`;

export const StyledNonDnmDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  flex-basis: 80%;
  border: 1px solid ${colors.gray20};
`;

export const StyledContentDescr = styled.div`
  display: flex;
  flex-basis: 45%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray20};
  padding: 15px;
`;

export const StyledFactor = styled.div`
  display: flex;
  flex-basis: 15%;
  align-items: center;
  justify-content: center;
  width: 200px;
  border: 1px solid ${colors.gray20};
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 48px;
`;

export const StyledNoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  border: 1px solid ${colors.gray20};
`;

export const StyledButton = styled(Button)`
  padding: 14px 24px;
`;
