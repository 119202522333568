import { DEFAULT_HEADERS_MOCK } from "@/packages/tableMaintenanceGrids/mocks";
import { IHeader } from "@/packages/tableMaintenanceGrids";
import {
  IMultiValue,
  TCheckboxMultiValueColor,
} from "@/packages/checkbox/Checkbox";

export const getTableHeaders = <TData extends object = any>(
  newHeaderValues?: { [key: string]: any },
  startMutateHeaderIndex = 0,
  idName?: string | undefined,
  isDealer?: boolean | undefined,
  isReadOnlyQuantity?: boolean | undefined
): IHeader<TData>[] => {
  const hasNewValues = Boolean(
    newHeaderValues && Object.keys(newHeaderValues).length > 0
  );

  if (!hasNewValues) return DEFAULT_HEADERS_MOCK as any;

  const newHeaders = DEFAULT_HEADERS_MOCK(
    idName,
    isDealer,
    isReadOnlyQuantity
  ).map((header, index) =>
    index >= startMutateHeaderIndex
      ? {
          ...header,
          ...newHeaderValues,
        }
      : header
  );

  return newHeaders as any;
};

const CHECKBOX_MULTI_VALUES_BY_TYPE: { [key: string]: IMultiValue } = {
  DEFAULT: {
    color: "default",
    value: false,
  },
  I: {
    color: "blue",
    value: true,
  },
  R: {
    color: "orange",
    value: true,
  },
  O: {
    color: "green",
    value: true,
  },
};

export enum TYPE_COLOR {
  DEFAULT = "default",
  BLUE = "I",
  ORANGE = "R",
  GREEN = "O",
}

export const getMultiColorCheckboxValue = (
  type?: "I" | "R" | "O"
): IMultiValue => {
  switch (type) {
    case "I":
      return CHECKBOX_MULTI_VALUES_BY_TYPE.I;

    case "R":
      return CHECKBOX_MULTI_VALUES_BY_TYPE.R;

    case "O":
      return CHECKBOX_MULTI_VALUES_BY_TYPE.O;

    default:
      return CHECKBOX_MULTI_VALUES_BY_TYPE.DEFAULT;
  }
};

export const getTypeByCheckboxColor = (color: TCheckboxMultiValueColor) => {
  switch (color) {
    case "default":
      return TYPE_COLOR.DEFAULT;

    case "blue":
      return TYPE_COLOR.BLUE;

    case "orange":
      return TYPE_COLOR.ORANGE;

    case "green":
      return TYPE_COLOR.GREEN;
  }
};

export const getMultiValues = (workTypes: any[]): IMultiValue[] => {
  const result = workTypes.map((type) => getMultiColorCheckboxValue(type.type));

  return [getMultiColorCheckboxValue(), ...result];
};
