import { TGetMonitoringQualityByIdResponse } from "@/helpers/api/monitoringQuality/types";
import * as yup from "yup";
import { MonitoringDataFormFieldsAppEnum, TMonitoringApplicationDataFieldsSuccessValues, } from "./types";

export const allowedTypesImages = ["image/png", "image/jpeg", "image/tiff"];

export const allowedTypesFiles = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint"
];

export const monitoringFieldsAppSchema = yup.object({
  [MonitoringDataFormFieldsAppEnum.Vin]: yup
    .string()
    .required("Необходимо ввести VIN")
    .test({
      test: (value) => (value ? /^([\da-zA-Z]){17}$/g.test(value) : true),
      message:
        "VIN должен состоять из 17 символов. Доступен ввод латиницей и цифр.",
    }),
  [MonitoringDataFormFieldsAppEnum.Mileage]: yup
    .number()
    .typeError("Пробег должен быть числом")
    .required("Необходимо ввести пробег машины")
    .positive("Пробег не может быть меньше 0")
    .min(0),
  [MonitoringDataFormFieldsAppEnum.GenerationId]: yup.lazy((value) => {
    switch (typeof value) {
      case "object":
        return yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .nullable()
          .required("Необходимо выбрать модель");
      case "string":
        return yup.string().required("Необходимо выбрать модель");
      default:
        return yup.mixed();
    }
  }),
  [MonitoringDataFormFieldsAppEnum.CarProductionDate]: yup
    .date()
    .typeError("Необходимо выбрать дату")
    .required("Необходимо ввести дату производства автомобиля")
    .nullable()
    .default(undefined),
  [MonitoringDataFormFieldsAppEnum.IsSold]: yup
    .boolean()
    .nullable()
    .required("Это поле является обязательным"),
  [MonitoringDataFormFieldsAppEnum.CarSoldDate]: yup
    .date()
    .nullable()
    .default(null)
    .when(MonitoringDataFormFieldsAppEnum.IsSold, {
      is: true,
      then: yup
        .date()
        .typeError("Необходимо выбрать дату")
        .required("Необходимо ввести дату продажи автомобиля")
        .nullable()
        .default(undefined),
    }),
  [MonitoringDataFormFieldsAppEnum.ReasonPetition]: yup
    .string()
    .max(200, "Максимально допустимое количество символов - 200")
    .required("Необходимо указать причину обращения"),
  [MonitoringDataFormFieldsAppEnum.DiagnosticResult]: yup
    .string()
    .max(200, "Максимально допустимое количество символов - 200")
    .required("Необходимо указать результаты диагностики"),
  [MonitoringDataFormFieldsAppEnum.SuspectedDefect]: yup
    .string()
    .max(200, "Максимально допустимое количество символов - 200")
    .required("Необходимо указать предполагаемую причину неисправности"),
});

export const DEFAULT_MONITORING_DATA_FORM_VALUES: any = {
  [MonitoringDataFormFieldsAppEnum.Vin]: "",
  [MonitoringDataFormFieldsAppEnum.Mileage]: "",
  [MonitoringDataFormFieldsAppEnum.GenerationId]: [],
  [MonitoringDataFormFieldsAppEnum.CarProductionDate]: "",
  [MonitoringDataFormFieldsAppEnum.IsSold]: null,
  [MonitoringDataFormFieldsAppEnum.CustomerСomplaint]: false,
  [MonitoringDataFormFieldsAppEnum.CarSoldDate]: null,
  [MonitoringDataFormFieldsAppEnum.ReasonPetition]: "",
  [MonitoringDataFormFieldsAppEnum.DiagnosticResult]: "",
  [MonitoringDataFormFieldsAppEnum.SuspectedDefect]: "",
  [MonitoringDataFormFieldsAppEnum.PhotoIds]: [],
  [MonitoringDataFormFieldsAppEnum.FileIds]: [],
};

export const monitoringAppValuesDTO = (
  values: TMonitoringApplicationDataFieldsSuccessValues
) => {
  const { photoIds, fileIds, isSold, carSoldDate, ...rest } = values;

  return {
    ...rest,
    ...(isSold && { carSoldDate }),
    isSold,
    photoIds: photoIds
      ? photoIds
        .map((photo) => photo.id)
      : [],
    fileIds: fileIds
      ? fileIds
        .map((file) => file.id)
      : [],
  };
};

export const useValidateDateProduction = (
  dataResponse?: TGetMonitoringQualityByIdResponse
) => {
  let minDate, maxDate;
  if (dataResponse) {
    const { carProductionDateFrom, carProductionDateTo } = dataResponse;
    minDate = new Date(carProductionDateFrom);
    maxDate = new Date(carProductionDateTo);
    return {
      minDate,
      maxDate,
    };
  }
  return {
    minDate,
    maxDate,
  };
};
