import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { SettingsFields } from "../SettingsFields";
import {
  TOnSubmitCreateQuotasForm,
  useCreateQuotasForm,
} from "../SettingsForm/hooks";
import { useTranslation } from "react-i18next";

export interface IModelCreateModal extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitCreateQuotasForm;
}

const CreateQuotasModal: React.FC<IModelCreateModal> = (props) => {
  const { onSubmit, onCancel, ...rest } = props;
  const { form, submit, setValue, reset } = useCreateQuotasForm(onSubmit);
  const { t } = useTranslation();

  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);

  const handleReset = () => {
    form.reset();
    onCancel && onCancel();
  };

  useEffect(() => {
    form.reset();
  }, [reset, rest.isOpen]);

  return (
    <Modal
      {...rest}
      size="medium"
      title={t("kiaFlex.createQuote") || ""}
      confirmButtonText={t("kiaFlex.updateQuotes") || ""}
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      onSubmit={submit}
      onCancel={handleReset}
      isDisabledConfirmButton={isDisabledSubmit}
    >
      <FormProvider {...form}>
        <SettingsFields
          setValue={setValue}
          setIsDisabledSubmit={setIsDisabledSubmit}
        />
      </FormProvider>
    </Modal>
  );
};
export default CreateQuotasModal;
