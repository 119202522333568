import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { Modal, IModal } from "@/packages/modal/Modal";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";

import { colors } from "@/helpers";

import {
  TOnSubmitCalendarStatusFormHandler,
  useCalendarStatusForm,
} from "@/components/Calendar/CalendarStatusModal/hooks";

import {
  StyledStatusModalContent,
  StyledStatusFormRow,
  StyledColorWrapper,
  StyledStatusColor,
  StyledAppendButton,
  StyledRemoveButton,
} from "@/components/Calendar/CalendarStatusModal/index.styles";
import { Icon } from "@/packages/icon/Icon";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useTranslation } from "react-i18next";

const APPOINTMENT_TYPES = [
  {
    value: "Media",
    label: "Media",
    record: { id: 1, name: "Media" },
  },
  {
    value: "Kia",
    label: "Kia",
    record: { id: 2, name: "Kia" },
  },
];

interface ICalendarStatusModal extends Pick<IModal, "isOpen" | "onCancel"> {
  onSubmit: TOnSubmitCalendarStatusFormHandler;
  defaultValues?: any;
}

export const CalendarStatusModal: React.FC<ICalendarStatusModal> = (props) => {
  const { onSubmit, defaultValues, onCancel } = props;
  const { form, fields, append, remove, onSubmitHandler } =
    useCalendarStatusForm(onSubmit, defaultValues);
  const { t } = useTranslation();
  const handleAppendClick = () => {
    append({
      id: null,
      title: null,
      color: "#000000",
      appointment: null,
      lockActive: false,
    });
  };

  const handleRemoveClick = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append([...defaultValues]);
    }
  }, [defaultValues]);

  return (
    <Modal
      {...props}
      title={t("park.editStatus") || ""}
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      confirmButtonText={t("profile.settings.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancel}
    >
      <StyledStatusModalContent>
        <FormProvider {...form}>
          {fields.map((item, index) => (
            <StyledStatusFormRow key={item.id}>
              <StyledColorWrapper>
                <StyledStatusColor
                  color={form.watch(`status.${index}.color`) || colors.white}
                />
              </StyledColorWrapper>
              <FormInputField
                name={`status.${index}.color`}
                defaultValue={`status.${index}.color`}
                label="HEX"
                placeholder={t("park.hex") || ""}
              />
              <FormInputField
                name={`status.${index}.title`}
                defaultValue={`status.${index}.title`}
                label={t("park.header") || ""}
                isDisabled={item.lockActive}
                placeholder={t("park.autoName") || ""}
              />
              <FormSelect
                name={`status.${index}.appointment`}
                defaultValue={`status.${index}.appointment` || null}
                label={t("park.purpose") || ""}
                //@ts-ignore
                isDisabled={form.watch(`status.${index}.appointment`)}
                placeholder={t("park.purposeDescr") || ""}
                options={APPOINTMENT_TYPES}
              />
              {!item.lockActive ? (
                <StyledRemoveButton>
                  <IconButton
                    icon="close-16"
                    color="black"
                    size="l"
                    variant="tertiary"
                    onClick={() => handleRemoveClick(index)}
                  />
                </StyledRemoveButton>
              ) : (
                <></>
              )}
            </StyledStatusFormRow>
          ))}
          <StyledAppendButton
            icon={<Icon name="plus" size={16} />}
            isIconLeft={true}
            size="small"
            onClick={handleAppendClick}
          >
            {t("complaints.add") || ""}
          </StyledAppendButton>
        </FormProvider>
      </StyledStatusModalContent>
    </Modal>
  );
};
