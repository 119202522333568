import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import {
  StyledInfoContainer,
  StyledPhoto,
  StyledPhotoCard,
} from "./index.styles";

export interface IPhotoCard {
  url: string;
  fileName: string;
  format: string;
  fileSize: number;
}
export const PhotoCard: React.FC<IPhotoCard> = (props) => {
  const { url, fileName, format, fileSize } = props;
  const img = new Image();
  img.src = url;
  const fileSizeMb = (fileSize / 1024 / 1024).toFixed(2);

  return (
    <StyledPhotoCard>
      <StyledPhoto src={url} />
      <StyledInfoContainer>
        <Paragraph size={14}>{fileName}</Paragraph>
        <Paragraph size={12} color="gray200">
          {format} - {img.width}×{img.height}- {fileSizeMb}MB
        </Paragraph>
      </StyledInfoContainer>
    </StyledPhotoCard>
  );
};
