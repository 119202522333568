import { Row } from "antd";
import styled from "styled-components";
import { IconButton } from "@/packages/icon-button/IconButton";
import { media } from "@/helpers";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;
export const StyledDatepickerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;

export const StyledButton = styled(IconButton)``;

export const StyledRow = styled(Row)<{ space: number }>`
  align-items: flex-end;
  margin-bottom: ${({ space }) => space}px;
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  button {
    width: 100%;
  }

  margin-top: 24px;

  ${media.tablet} {
    button {
      width: fit-content;
    }
  }

  ${media.desktop} {
    margin-top: 0;
  }
`;

export const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledSelectField = styled.div`
  width: 100%;
`;

export const StyledFilters = styled.div<{ isOpen: boolean }>`
  margin-bottom: 24px;
  transition: all 0.5s ease-in-out;
  overflow: ${({ isOpen }) => (isOpen ? "initial" : "hidden")};

  ${media.mobile} {
    max-height: ${({ isOpen }) => (isOpen ? "400px" : "64px")};
  }

  ${media.tablet} {
    max-height: ${({ isOpen }) => (isOpen ? "400px" : "64px")};
  }
`;

export const ColumnWraper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSeparator = styled.div`
  width: 12px;
  height: 1px;
  margin: auto 14px 14px;
  border: 1px solid #8e8ea9;
  background-color: #8e8ea9;
`;
