import React from "react";
import styled from "styled-components";
import { IModal, Modal } from "@/packages/modal/Modal";

export interface IConfirmModal extends Pick<IModal, "onCancel" | "isOpen"> {
  text: string;
  onSubmit?: () => void;
  title?: string;
}

export const StyledNotifyText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalConfirm: React.FC<IConfirmModal> = (props) => {
  const { text, title, onCancel, onSubmit, ...rest } = props;

  return (
    <Modal
      {...rest}
      size="small"
      showCancelButton={true}
      confirmButtonText="Да"
      cancelButtonText="Нет"
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={title}
    >
      <StyledNotifyText>{text}</StyledNotifyText>
    </Modal>
  );
};
