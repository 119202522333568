import { TGetKiaDealerListResponse } from "@/helpers/api/dc/kia-dealers/types";
import { useQuery } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import { TGetFilteredDealerListRequest } from "@/helpers/api/global-filters/types";

export const useGetFilteredDealerList = (request: TGetFilteredDealerListRequest) => {
  return useQuery(
    ["filteredDealers", request],
    () => {
      return api.get<TGetKiaDealerListResponse>("/global-filters/dealers", {
        params: request,
      });
    },
  );
};