import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";
import React, { useMemo, useState } from "react";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MaterialsEditBatchModal } from "@/components/Materials/MaterialsEditBatchModal";
import {
  useDeleteMaterialFile,
  useGetMaterialsSectionsBatch,
  usePatchMaterialsSections,
  useUploadMaterialSection,
} from "@/helpers/api/materials/hooks";
import { TGetMaterialsSectionsBatchResponse, TPatchMaterialsSectionsRequest, } from "@/helpers/api/materials/types";
import TreeComponent from "./TreeComponent";
import { mutateArrOfObj } from "./utils";
import { useTranslation } from "react-i18next";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { Space } from "antd";
import { useDebounce } from "use-debounce";

const Materials: React.FC = () => {
  const { t } = useTranslation();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 800);

  const {
    data: materialsSectionsBatch,
    refetch: materialsSectionsBatchRefetch,
  } = useGetMaterialsSectionsBatch(
    {
      search: debouncedSearchValue,
    }
  );

  const { mutateAsync: patchMaterialsSectionsAsync } =
    usePatchMaterialsSections();

  const { mutateAsync: deleteMaterialFileAsync } = useDeleteMaterialFile();
  const { mutateAsync: uploadMaterialSectionAsync } =
    useUploadMaterialSection();

  const onCreateMaterialsSection = async (
    data: TPatchMaterialsSectionsRequest
  ) => {
    try {
      await patchMaterialsSectionsAsync(data);
      createNotificationSuccess(
        t("dealerMaterials.materials.createNotificationSuccessStructure") || ""
      );
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.materials.createNotificationErrorStructure") || ""
      );
      throw e;
    }
  };

  const batch = useMemo(
    //@ts-ignore
    () => materialsSectionsBatch?.data.data,
    [materialsSectionsBatch]
  );

  const onDeleteMaterialSectionHandler = async (ids: string[]) => {
    try {
      const payload = { data: ids.map((id) => ({ id })) };
      await deleteMaterialFileAsync({ data: payload });
      createNotificationSuccess(
        t("dealerMaterials.materials.createNotificationSuccessDeleted") || ""
      );
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.materials.createNotificationErrorDeleted") || ""
      );
      throw e;
    }
  };

  const onUploadMaterialSectionHandler = async (
    id: string,
    fileIds: string[]
  ) => {
    try {
      const body = {
        fileIds: fileIds,
      };
      const response = (await uploadMaterialSectionAsync({
        id,
        //@ts-ignore
        body,
      })) as any;

      if (response.status === 201) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");

        link.href = href;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        createNotificationSuccess(
          t("dealerMaterials.materials.createNotificationSuccessDownload") || ""
        );
      }
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.materials.createNotificationErrorDownload") || ""
      );
      throw e;
    }
  };

  const {
    modalState: materialsEditBatchModalState,
    openModal: openMaterialsEditBatchModalState,
  } = useModal((close) => {
    return async (payload: TPatchMaterialsSectionsRequest) => {
      await onCreateMaterialsSection(payload);
      materialsSectionsBatchRefetch();
      close();
    };
  });

  const batchSections = mutateArrOfObj(
    batch as unknown as TGetMaterialsSectionsBatchResponse
  );

  return (
    <DashboardLayout
      title={t("dealerMaterials.materials.title") || ""}
      headerLeft={
        <Space>
          <Search
            value={searchValue}
            onChange={(value) => setSearchValue(value || "")}
          />
        </Space>
      }
      headerRight={
        <PermissionsAdapter resourceName="materials" resourceAction="update">
          <IconButton
            variant="tertiary"
            icon="edit"
            color="brand"
            onClick={() => openMaterialsEditBatchModalState()}
          >
            {t("dealerMaterials.letters.edit") || ""}
          </IconButton>
        </PermissionsAdapter>
      }
    >
      <TreeComponent
        data={batchSections}
        onDeleteMaterialSectionHandler={onDeleteMaterialSectionHandler}
        onUploadMaterialSectionHandler={onUploadMaterialSectionHandler}
        searchValue={debouncedSearchValue}
      />
      <MaterialsEditBatchModal
        // @ts-ignore
        batchSections={mutateArrOfObj(materialsSectionsBatch?.data.data) || []}
        {...materialsEditBatchModalState}
      />
    </DashboardLayout>
  );
};

export default Materials;
