import styled from "styled-components";

import { colors } from "@/helpers";

import { Modal } from "@/packages/modal/Modal";
import { Title } from "@/packages/title/Title";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledModal = styled(Modal)`
  background-color: black;
  width: 100%;
`;

export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;

export const StyledModalTitle = styled(Title)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray30};
`;

export const StyledFormGrid = styled.div<{ isNonDnm?: boolean }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ isNonDnm }) => (isNonDnm ? "1" : "2")},
    1fr
  );
  gap: 16px 32px;
  margin-bottom: 32px;
`;

export const StyledStageContainer = styled.div`
  max-width: 848px;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.gray500};
`;

export const StyledIcon = styled(Icon)`
  margin: 0 auto;
`;

export const StyledDescription = styled.div`
  text-align: center;
`;
