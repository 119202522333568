import cookie from "js-cookie";
const REFRESH_TOKEN_KEY = "refreshToken";

class RefreshTokenService {
  getToken() {
    return cookie.get(REFRESH_TOKEN_KEY);
  }

  setToken(token: string, expires: number) {
    cookie.set(REFRESH_TOKEN_KEY, token, { expires: expires / 60 / 60 / 24 });
  }

  deleteToken() {
    cookie.remove(REFRESH_TOKEN_KEY);
  }
}

export const refreshTokenService = new RefreshTokenService();
