import React, { useMemo, useState } from "react";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { TitleDivider } from "@/packages/TitleDivider";
import { Col, Row } from "antd";
import { TitleFieldWrapper } from "../../KiaFlexOrders/NewCarFIelds/index.styles";
import { ColumnWraper, InputWrapper, Wrapper } from "./index.styles";
import { useTranslation } from "react-i18next";

import { useGetModelList } from "@/helpers/api/models/hooks";
import { FormInputNumberField } from "@/packages/formElements/formFields/FormInputNumberField";

export interface ITariffsFieldsProps {
  mode?: "edit" | "create";
  setValue: (
    key: `shortPeriod[${number}]` | `longPeriod[${number}]` | string,
    value: number | string
  ) => void;
}

const fieldsRentDays = new Array(30).fill(1);
const fieldsRentMonths = new Array(35).fill(1);

export const TariffsFields: React.FC<ITariffsFieldsProps> = (props) => {
  const { mode = "create", setValue } = props;
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const { data: models } = useGetModelList({ name: search });

  const modelsOption = useMemo(() => {
    const modOptions: ISelectOption[] = [];

    models &&
      models?.data.data.map((item) => {
        modOptions.push(
          ...item.generations.map((gen) => ({
            label: `${item.name} ${gen.name} ${gen.restylingName || ""}`,
            value: gen.id,
          }))
        );
      });
    return modOptions;
  }, [models]);

  return (
    <>
      {mode === "create" && (
        <>
          <TitleFieldWrapper>
            <Row>
              <Paragraph size={14}>{t("kiaFlex.newTariffPlanModal")}</Paragraph>
            </Row>

            <TitleDivider />
          </TitleFieldWrapper>
          <Row>
            <Col span={12}>
              <FormSelect
                name={"generationId"}
                options={modelsOption}
                label={t("kiaFlex.generationName") || ""}
                description={t("kiaFlex.generationNameDescr") || ""}
                onSearch={setSearch}
                isSearchShow={true}
              />
            </Col>
          </Row>
        </>
      )}

      <TitleFieldWrapper>
        <Row>
          <Paragraph size={14}>{t("kiaFlex.tariff_30") || ""}</Paragraph>
        </Row>

        <TitleDivider />
      </TitleFieldWrapper>
      <Row gutter={10}>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.dayPeriod") || ""}</Paragraph>
        </Col>
        <Col span={8}>
          <Paragraph size={12}>{t("kiaFlex.rentCost") || ""}</Paragraph>
        </Col>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.dayPeriod") || ""}</Paragraph>
        </Col>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.rentCost") || ""}</Paragraph>
        </Col>
      </Row>

      <Wrapper>
        <ColumnWraper>
          {fieldsRentDays.slice(0, 15).map((field, index) => {
            return (
              <InputWrapper key={index}>
                <Paragraph size={12}>{index + 1} </Paragraph>
                <FormInputNumberField
                  style={{ width: "200px" }}
                  name={`shortPeriod.${index}`}
                  isErrorAbsolute={true}
                  onChangeCapture={(value) => {
                    setValue(
                      value.currentTarget.name,
                      +value.currentTarget.value
                    );
                  }}
                />
              </InputWrapper>
            );
          })}
        </ColumnWraper>
        <ColumnWraper>
          {fieldsRentDays.slice(15, 30).map((field, index) => {
            return (
              <InputWrapper key={index}>
                <Paragraph size={12}>{index + 16} </Paragraph>
                <FormInputNumberField
                  style={{ width: "200px" }}
                  name={`shortPeriod[${index + 15}]`}
                  isErrorAbsolute={true}
                  onChangeCapture={(value) => {
                    setValue(
                      value.currentTarget.name,
                      +value.currentTarget.value
                    );
                  }}
                />
              </InputWrapper>
            );
          })}
        </ColumnWraper>
      </Wrapper>
      <TitleFieldWrapper>
        <Row>
          <Paragraph size={14}>{t("kiaFlex.tariff_months") || ""}</Paragraph>
        </Row>

        <TitleDivider />
      </TitleFieldWrapper>
      <Row gutter={10}>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.monthPeriod") || ""}</Paragraph>
        </Col>
        <Col span={7}>
          <Paragraph size={12}>{t("kiaFlex.rentCostDay") || ""} </Paragraph>
        </Col>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.monthPeriod") || ""}</Paragraph>
        </Col>
        <Col>
          <Paragraph size={12}>{t("kiaFlex.rentCostDay") || ""} </Paragraph>
        </Col>
      </Row>
      <Wrapper>
        <ColumnWraper>
          {fieldsRentMonths.slice(0, 18).map((field, index) => {
            return (
              <InputWrapper key={index}>
                <Paragraph size={12}>{index + 2} </Paragraph>
                <FormInputNumberField
                  style={{ width: "200px" }}
                  name={`longPeriod[${index}]`}
                  isErrorAbsolute={true}
                  onChangeCapture={(value) => {
                    setValue(
                      value.currentTarget.name,
                      +value.currentTarget.value
                    );
                  }}
                />
              </InputWrapper>
            );
          })}
        </ColumnWraper>
        <ColumnWraper>
          {fieldsRentMonths.slice(18, 35).map((field, index) => {
            return (
              <InputWrapper key={index}>
                <Paragraph size={12}>{index + 20} </Paragraph>
                <FormInputNumberField
                  style={{ width: "200px" }}
                  name={`longPeriod[${index + 17}]`}
                  isErrorAbsolute={true}
                  onChangeCapture={(value) => {
                    setValue(
                      value.currentTarget.name,
                      +value.currentTarget.value
                    );
                  }}
                />
              </InputWrapper>
            );
          })}
        </ColumnWraper>
      </Wrapper>
    </>
  );
};
