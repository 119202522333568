import React, { useEffect, useRef, useState } from "react";

import { Paragraph } from "@/packages/paragraph/Paragraph";

import {
  StyledTableColumnRight,
  StyledTableHeadRightList,
  StyledTableHeadRightItem,
  StyledDaysInfoList,
  StyledDaysInfoItem,
  StyledDaysBlockList,
  StyledDaysBlockItem,
  StyledDaysBlockSubList,
  StyledDaysBlockSubItem,
  StyledDaysList,
  StyledDaysItem,
  StyledColoredLabelContainer,
  StyledParagraph,
} from "./index.styles";

import {
  getMonthByIndex,
  IDatesTree,
  getDateDiff,
  isDateToday,
} from "@/helpers/date";
import { CalendarApplication } from "./CalendarApplication";
import { useModal } from "@/hooks/useModal";
import { CalendarApplicationNewModal } from "./CalendarApplication/CalendarApplicationNewModal";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

// генерируем пустые ячейки для таблицы, если выбранный период очень короткий
const getTableDataMock = (currentCount: number) => {
  const TABLE_DATA_MOCK_COUNT = 100;

  const count =
    currentCount < TABLE_DATA_MOCK_COUNT
      ? TABLE_DATA_MOCK_COUNT - currentCount
      : 0;

  return new Array(count).fill("");
};

interface ICalendarTableColumnRight {
  data: any[];
  datesArray: Date[];
  datesTree: IDatesTree | null;
  calendarDatesDiff: number;
  scrollPage: number;
  setScrollPage: any;
  setScrollPrevPage: any;
  startPeriodDateFilters: Date;
  handleStatusNewModalCloseTable: () => void;
}

export const CalendarTableColumnRight: React.FC<ICalendarTableColumnRight> = (
  props
) => {
  const {
    data,
    datesTree,
    datesArray,
    calendarDatesDiff,
    setScrollPage,
    setScrollPrevPage,
    startPeriodDateFilters,
    handleStatusNewModalCloseTable,
  } = props;
  const { t } = useTranslation();
  const [isCalendarHovering, setIsCalendarHovering] = useState(false);
  const [isScrollToDateDisabled, setIsScrollToDateDisabled] = useState(false);
  const [vehicleIdForNew, setVehicleIdForNew] = useState(null);
  const [startEventForNew, setStartEventForNew] = useState(null);
  const [pageOffset, setPageOffset] = useState(0);

  const daysBlockRef = useRef(null);

  const scrollRef = useRef(null);

  const executeScrollToDate = () => {
    if (scrollRef && scrollRef.current) {
      //@ts-ignore
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "end",
      });
    }
  };

  const onDaysBlockWheel = (event) => {
    if (daysBlockRef.current) {
      //@ts-ignore
      daysBlockRef.current.scrollLeft += event.deltaY;
    }
  };

  const onDaysBlockScroll = (event) => {
    if (daysBlockRef.current) {
      const { scrollLeft, offsetWidth } = daysBlockRef.current;

      const calendarWidth = calendarDatesDiff * 20 - (offsetWidth + 20);

      if (scrollLeft + pageOffset >= calendarWidth) {
        setPageOffset(scrollLeft);
        setScrollPage((scrollPage) => (scrollPage += 1));
        setIsScrollToDateDisabled(true);
      }
      if (scrollLeft === 0) {
        setScrollPrevPage((scrollRevPage) => (scrollRevPage += 1));
        //@ts-ignore
        daysBlockRef.current.scrollLeft += 3420;
        setIsScrollToDateDisabled(true);
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    setScrollPrevPage(
      (currentDate.getFullYear() - startPeriodDateFilters.getFullYear() + 1) * 2
    );
    setScrollPage(
      currentDate.getFullYear() - startPeriodDateFilters.getFullYear() + 1
    );
  }, [setScrollPage, setScrollPrevPage, startPeriodDateFilters]);

  useEffect(() => {
    if (isCalendarHovering) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isCalendarHovering]);

  const {
    modalState: CalendarApplicationNewModalState,
    openModal: CalendarApplicationNewOpenModal,
    closeModal: CalendarApplicationNewCloseModal,
  } = useModal();

  const handleApplicationNewModalClose = () => {
    setVehicleIdForNew(null);
    setStartEventForNew(null);
    CalendarApplicationNewCloseModal();
    handleStatusNewModalCloseTable();
  };

  useEffect(() => {
    if (!isScrollToDateDisabled) {
      executeScrollToDate();
    }
  }, [scrollRef, datesTree]);

  const handleDayClick = (day, carItem, id) => {
    if (!id) {
      setVehicleIdForNew(carItem.id);

      setStartEventForNew(day);
      CalendarApplicationNewOpenModal();
    }
  };

  const today = startPeriodDateFilters;

  return (
    <StyledTableColumnRight
      onWheel={onDaysBlockWheel}
      onScroll={onDaysBlockScroll}
      ref={daysBlockRef}
      onMouseOver={() => setIsCalendarHovering(true)}
      onMouseOut={() => setIsCalendarHovering(false)}
    >
      {datesTree ? (
        <StyledTableHeadRightList>
          {Object.keys(datesTree).map((year) => (
            <>
              {Object.keys(datesTree[year]).map((month) => (
                <StyledTableHeadRightItem key={`head-month-${year}-${month}`}>
                  <StyledParagraph size={12}>
                    {t(getMonthByIndex(Number(month)))} {year}
                  </StyledParagraph>
                  <StyledDaysInfoList>
                    {datesTree[year][month].map((day: Date) => (
                      <StyledDaysInfoItem
                        key={`head-day-${year}-${month}-${day.getDate()}`}
                        isToday={isDateToday(today, day)}
                      >
                        <Paragraph size={12}>{day.getDate()}</Paragraph>
                      </StyledDaysInfoItem>
                    ))}
                  </StyledDaysInfoList>
                </StyledTableHeadRightItem>
              ))}
            </>
          ))}
        </StyledTableHeadRightList>
      ) : (
        <></>
      )}
      <StyledDaysBlockList>
        {data.map((car, carIndex) => (
          <StyledDaysBlockItem key={`days-cars-item-${car.title}-${carIndex}`}>
            <StyledDaysBlockSubList>
              {car.vehicles.map((carItem, carItemIndex) => {
                const requestsMapByEndDates = {};
                const requestsMapByEndDatesSecondLayer = {};
                const requestsStartDates = [];
                const requestsEndDates = [];

                if (!!carItem.requests.length) {
                  carItem.requests.forEach((record) => {
                    const sameDate = moment(record.endEvent).diff(
                      moment(record.startEvent),
                      "days"
                    );
                    const requestStartEventDateString = new Date(
                      record.startEvent
                    ).toDateString();
                    const requestEndEventDateString = new Date(
                      record.endEvent
                    ).toDateString();

                    const isIntersecting =
                      !!requestsMapByEndDates[requestEndEventDateString] ||
                      requestsEndDates.includes(
                        requestStartEventDateString as never
                      );

                    const isIntersectingByStart = requestsEndDates.includes(
                      requestStartEventDateString as never
                    );

                    if (
                      !requestsStartDates.includes(
                        requestStartEventDateString as never
                      )
                    ) {
                      requestsMapByEndDates[requestStartEventDateString] = {
                        record: record,
                        isIntersecting: isIntersecting,
                        isIntersectingByStart: isIntersectingByStart,
                      };
                      requestsStartDates.push(
                        requestStartEventDateString as never
                      );
                      requestsEndDates.push(requestEndEventDateString as never);
                    } else {
                      requestsMapByEndDatesSecondLayer[
                        requestStartEventDateString
                      ] = {
                        record: record,
                        isIntersecting: isIntersecting,
                      };
                    }
                  });
                }

                return (
                  <StyledDaysBlockSubItem
                    key={`days-cars-sub-item-${car.name}-${carIndex}-${carItem.name}-${carItemIndex}`}
                  >
                    <StyledDaysList>
                      <>
                        {datesArray.map((day, dayIndex) => {
                          const request =
                            requestsMapByEndDates[day.toDateString()];
                          const requestSecondLayer =
                            requestsMapByEndDatesSecondLayer[
                              day.toDateString()
                            ];

                          const requestStartEventDate = new Date(
                            request?.record.startEvent
                          );
                          const requestEndEventDate = new Date(
                            request?.record.endEvent
                          );

                          const requestStartEventDateSecondLayer = new Date(
                            requestSecondLayer?.record.startEvent
                          );
                          const requestEndEventDateSecondLayer = new Date(
                            requestSecondLayer?.record.endEvent
                          );

                          const isToday = isDateToday(today, day);
                          // console.log(isToday && today);

                          const isSameDate = moment(
                            requestSecondLayer?.record.endEvent
                          ).diff(
                            moment(requestSecondLayer?.record.startEvent),
                            "days"
                          );

                          const isSecondLayersStartDateSame =
                            moment(
                              requestSecondLayer?.record.startEvent
                            ).day() === day.getDay();

                          return (
                            <StyledDaysItem
                              ref={isToday ? scrollRef : null}
                              onClick={() =>
                                handleDayClick(
                                  day,
                                  carItem,
                                  request?.record?.id
                                )
                              }
                              key={`table-data-${day.getDate()}-${dayIndex}`}
                            >
                              <>
                                {!!request && (
                                  <StyledColoredLabelContainer>
                                    <CalendarApplication
                                      id={request.record.id}
                                      days={getDateDiff(
                                        requestStartEventDate,
                                        requestEndEventDate
                                      )}
                                      color={request.record.status.color}
                                      title={
                                        request.record.media?.name ||
                                        request.record.receivingUser
                                      }
                                      comment={request.record.comment}
                                      isIntersecting={
                                        isSameDate !== 0 ||
                                        request.isIntersecting
                                      }
                                      isIntersectingByStart={
                                        request.isIntersectingByStart
                                      }
                                    />
                                  </StyledColoredLabelContainer>
                                )}
                                {!!requestSecondLayer && (
                                  <StyledColoredLabelContainer>
                                    <CalendarApplication
                                      id={requestSecondLayer.record.id}
                                      days={getDateDiff(
                                        requestStartEventDateSecondLayer,
                                        requestEndEventDateSecondLayer
                                      )}
                                      color={
                                        requestSecondLayer.record.status.color
                                      }
                                      title={
                                        requestSecondLayer.record.media?.name ||
                                        requestSecondLayer.record.receivingUser
                                      }
                                      comment={
                                        requestSecondLayer.record.comment
                                      }
                                      isIntersecting={false}
                                    />
                                  </StyledColoredLabelContainer>
                                )}
                              </>
                            </StyledDaysItem>
                          );
                        })}
                      </>
                      {getTableDataMock(datesArray.length).map(
                        (day, dayIndex) => (
                          <StyledDaysItem
                            key={`mock-table-data-${dayIndex}`}
                          ></StyledDaysItem>
                        )
                      )}
                    </StyledDaysList>
                  </StyledDaysBlockSubItem>
                );
              })}
            </StyledDaysBlockSubList>
          </StyledDaysBlockItem>
        ))}
      </StyledDaysBlockList>
      {CalendarApplicationNewModalState.isOpen ? (
        <CalendarApplicationNewModal
          {...CalendarApplicationNewModalState}
          isOpen={CalendarApplicationNewModalState.isOpen}
          closeModal={handleApplicationNewModalClose}
          startEventForNew={startEventForNew}
          vehicleIdForNew={vehicleIdForNew}
        />
      ) : (
        <></>
      )}
    </StyledTableColumnRight>
  );
};
