import React from "react";

import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip, } from "chart.js";
import { Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface IChart {
  dppo: number;
  spares: number;
  warranty: number;
}

export const RadarChart: React.FC<IChart> = (props) => {
  const { dppo, spares, warranty } = props;
  const { t } = useTranslation();
  return (
    <Radar
      data={{
        labels: [
          t("warrantyAudits.list.dppoTitle") || "",
          t("warrantyAudits.list.guarantees") || "",
          t("warrantyAudits.list.partsTitle") || "",
        ],
        datasets: [
          {
            label: t("warrantyAudits.list.results") || "",
            data: [dppo, warranty, spares],
            backgroundColor: "rgb(217, 178, 255, 0.5)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        showLine: false,
        scales: {
          r: {
            max: 100,
            min: 0,
            ticks: {
              stepSize: 20,
              maxTicksLimit: 100,
            },
          },
        },
      }}
    />
  );
};
