import React from "react";
import { useGetDealersReport } from "@/helpers/api/reports/hooks";
import { TGetBulletGraphRequest } from "@/helpers/api/reports/types";
import { requestParamsMapper } from "@/helpers/utils";
import { usePagination } from "@/hooks/usePagination";
import { Loader } from "@/packages/loader/Loader";

import { DealerSellsBullet } from "../DealerSellsBullet";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";
import { Pagination } from "@/packages/pagination/Pagination";
import { StyledNoData } from "../SellsFilter/index.styles";
import { BulletLegend } from "../BulletLegend";

interface IDealerBulletChart {
  requestParams: {};
  noDataText: string;
}

const StyledPagination = styled(Pagination)`
  width: max-content;
  margin: 0 auto;
`;

const StyledDealerBulletChart = styled.div`
  position: relative;
`;

export const DealerBulletChart: React.FC<IDealerBulletChart> = (props) => {
  const { requestParams, noDataText } = props;

  const { setPage, paginationParams } = usePagination();
  const { data: dealersReportResponse, isLoading: isDealerReportLoading } =
    useGetDealersReport({
      ...(requestParamsMapper(requestParams) as TGetBulletGraphRequest),
      "page[page]": paginationParams.page,
      "page[limit]": paginationParams.limit,
    });

  const dealersReport = dealersReportResponse?.data.data;

  const isLoading = isDealerReportLoading;

  return !isLoading ? (
    dealersReport ? (
      <StyledDealerBulletChart>
        <BulletLegend />
        {dealersReport?.map((report) => (
          <DealerSellsBullet
            salesAmount={+report.salesFact}
            salesPlan={+report.salesPlan}
            avgByDealers={+report.avgByDealers}
            dealerName={report.dealerName}
            dealerCode={report.mobisCode}
          />
        ))}
        {dealersReportResponse.data.meta?.pageCount || 0 > 1 ? (
          <StyledPagination
            onChange={setPage}
            current={paginationParams.page}
            pageSize={1}
            total={dealersReportResponse.data.meta?.pageCount}
          />
        ) : (
          <></>
        )}
      </StyledDealerBulletChart>
    ) : (
      <StyledNoData>
        <Paragraph size={16}>{noDataText}</Paragraph>
      </StyledNoData>
    )
  ) : (
    <Loader size={48} />
  );
};
