export enum ModelFieldsName {
  MODEL = "model",
  GENERATION = "generation",
  MODIFICATION = "modification",
  EQUIPMENT = "equipmentId",
  COLOR = "color",
}

export enum ModelFieldsNamePartial {
  NUMBER = "number",
  PTS = "pts",
}
