import styled from "styled-components";

export const StyledAutoParkFormWrap = styled.div``;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
`;

export const StyledFormRow = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, minmax(0, 1fr)) 0.5fr; */
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const StyledFormHalfColumn = styled.div`
  grid-column: 1 / 3;
`;

export const StyledButtonWrap = styled.div`
  box-sizing: border-box;
  display: block;
  padding-top: 26px;
`;
