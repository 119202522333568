import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CodeListField, ICodeListField, } from "@/packages/formElements/fields/CodeListField";
import { Field } from "@/packages/formElements/fields/Field";

export interface IFormCodeListField
  extends Omit<ICodeListField, "error" | "value" | "onChange"> {
  name: string;
}

const _FormCodeListField: React.FC<IFormCodeListField> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
                 field: { ref: _, ...fieldWithoutRef },
                 fieldState: { error },
               }) => {
        return (
          <>
            <CodeListField
              {...fieldWithoutRef}
              {...inputProps}
              error={error?.message}
            />
            {
              Array.isArray(error) &&
              error?.map((value, key) => {
                return <Field.FieldError>{`В ${key} поле ${value?.message}`}</Field.FieldError>
              })
            }
          </>
        );
      }}
    />
  );
};
export const FormCodeListField = React.memo(_FormCodeListField);
