import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";

import {
  TChangeParametersOrderResponse,
  TCreateWarrantyAuditTemplateListResponse,
  TCreateWarrantyAuditViolationTemplateRequest,
  TCreateWarrantyAuditViolationTemplateResponse,
  TCreateWarrantyParameterRequest,
  TCreateWarrantyParameterResponse,
  TDeleteAuditParameterRequest,
  TDeleteAuditParameterResponse,
  TGetWarrantyAuditTemplateFiletResponse,
  TGetWarrantyAuditTemplateListRequest,
  TGetWarrantyAuditTemplateListResponse,
  TGetWarrantyAuditViolationTemplateListRequest,
  TGetWarrantyAuditViolationTemplateListResponse,
  TGetWarrantyParameterByIdRequest,
  TGetWarrantyParameterByIdResponse,
  TGetWarrantyTemplateByIdRequest,
  TGetWarrantyTemplateByIdResponse,
  TPatchWarrantyAuditTemplateFileRequest,
  TPatchWarrantyAuditTemplateFileResponse,
  TPutWarrantyParameterRequest,
  TPutWarrantyParameterResponse,
  TPutWarrantyTemplateSectionsRequest,
  TPutWarrantyTemplateSectionsResponse,
  TUploadWarrantyAuditTemplateFiletResponse,
} from "./types";
import { TChangeParamtrsOrderRequest } from "@/helpers/api/audit-parametrs/types";


export const useGetWarrantyAuditTemplateList = (
  request: TGetWarrantyAuditTemplateListRequest
) => {
  return useQuery(
    ["audit", "warranty", "list", request],
    () => {
      return api.get<TGetWarrantyAuditTemplateListResponse>(
        "guarantee/templates",
        {
          params: request,
        }
      );
    },
    { cacheTime: 0 }
  );
};

export const useCreateWarrantyTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       onSuccess,
       onError,
     }: {
      onSuccess: (resp: TCreateWarrantyAuditTemplateListResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateWarrantyAuditTemplateListResponse>(
        "/guarantee/templates"
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetWarrantyTemplateById = (
  request: TGetWarrantyTemplateByIdRequest
) => {
  return useQuery(["warranty", "template-edit", request], () => {
      return api.get<TGetWarrantyTemplateByIdResponse>(
        `/guarantee/templates/${request.id}`,
        {
          params: {
            sectionsType: request?.sectionsType,
          },

        },
      );
    },
    {
      enabled: request.sectionsType !== "Нарушения и баллы"
    });
};

export const usePatchWarrantyAuditTemplate = () => {
  return useMutation(
    ({
       patch,
       onSuccess,
       onError,
     }: {
      patch: TPatchWarrantyAuditTemplateFileRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchWarrantyAuditTemplateFileResponse>(
        `/guarantee/templates/${patch.id}`,
        patch.data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetAuditTemplateExample = () => {
  return useMutation(
    ({
       onSuccess,
       onError,
     }: {
      onSuccess?: (response) => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.get<TGetWarrantyAuditTemplateFiletResponse>(
        "/guarantee/templates/files",
        {
          responseType: "blob",
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useUploadWarrantyAuditTemplate = () => {
  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: FormData;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TUploadWarrantyAuditTemplateFiletResponse>(
        "/guarantee/templates/files",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteWarrantyAuditTemplate = () => {
  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: number;
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<void>(`/guarantee/templates/${id}`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useWarrantyAddSection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPutWarrantyTemplateSectionsRequest) => {
      return api.put<TPutWarrantyTemplateSectionsResponse>(
        `/guarantee/templates/sections`,
        data
      );
    },
    {
      onSuccess: (_, variables) => {
        // queryClient.invalidateQueries(["request", "list"]);
        // queryClient.invalidateQueries(["requestById", variables.id]);
      },
    }
  );
};

export const useGetWarrantyParameterById = (
  id?: TGetWarrantyParameterByIdRequest
) => {
  return useQuery(
    ["warranty", "parameters", id],
    () => {
      return api.get<TGetWarrantyParameterByIdResponse>(
        `/guarantee/templates/parameters/${id}`
      );
    },
    {
      enabled: !!id,
    }
  );
};

export const useCreateWarrantyParameter = (isDppo: boolean) => {
  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: TCreateWarrantyParameterRequest;
      onSuccess: (resp: TCreateWarrantyParameterResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateWarrantyParameterResponse>(
        `/guarantee/templates/parameters${isDppo ? "/dppo" : ""}`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditWarrantyParameter = (isDppo: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       id,
       body,
       onSuccess,
     }: {
      id: TPutWarrantyParameterRequest["id"];
      body: TPutWarrantyParameterRequest["body"];
      onSuccess: () => void;
      onError: (response: AxiosError<{ message: string }>) => void;
    }) => {
      return api.put<TPutWarrantyParameterResponse>(
        `/guarantee/templates/parameters/${id}${isDppo ? "/dppo" : ""}`,
        body
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteWarrantyParameter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: TDeleteAuditParameterRequest["id"];
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<TDeleteAuditParameterResponse>(
        `/guarantee/templates/parameters/${id}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useMoveParam = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       id,
       patch,
       onSuccess,
       onError,
     }: {
      id: TChangeParamtrsOrderRequest["id"];
      patch: TChangeParamtrsOrderRequest["patch"];
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TChangeParametersOrderResponse>(
        `/guarantee/templates/parameters/${id}/order`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    })
}

export const useMoveSection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       id,
       patch,
       onSuccess,
       onError,
     }: {
      id: TChangeParamtrsOrderRequest["id"];
      patch: TChangeParamtrsOrderRequest["patch"];
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TChangeParametersOrderResponse>(
        `/guarantee/templates/sections/${id}/order`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    })
}

export const useGetTemplateViolationList = (
  request: TGetWarrantyAuditViolationTemplateListRequest,
  isEnabled: boolean = true,
) => {
  return useQuery(
    [request, isEnabled, 'violation-template'],
    () => {
      return api.get<TGetWarrantyAuditViolationTemplateListResponse>(
        "/guarantee/template-violations",
        {
          params: request,
        }
      );
    },
    { enabled: isEnabled }
  );
};

export const useDeleteViolationTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: string;
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<void>(`/guarantee/template-violations/${id}`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        queryClient.invalidateQueries(["violation-template"]);
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCreateViolationTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       data,
     }: {
      data: TCreateWarrantyAuditViolationTemplateRequest;
    }) => {
      return api.post<TCreateWarrantyAuditViolationTemplateResponse>(
        '/guarantee/template-violations',
        data
      );
    },
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries(["violation-template"]);
      },
      onError: (response) => {

      },
    }
  );
};

export const usePatchViolationTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: {
      id: string
      patch: TCreateWarrantyAuditViolationTemplateRequest;
    }) => {
      return api.patch<TCreateWarrantyAuditViolationTemplateRequest>(
        `/guarantee/template-violations/${id}`,
        patch
      );
    },
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries(["violation-template"]);
      },
      onError: (response) => {
      },
    }
  );
};

