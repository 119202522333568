import { TFileRecord } from "@/helpers/api/files/types";

export enum NewsDataFormFieldsEnum {
  Name = "name",
  Description = "description",
  Text = "text",
  FileId = "fileId"
}

type TExtendedNewsDataValues = {
  [NewsDataFormFieldsEnum.Name]: string | null;
  [NewsDataFormFieldsEnum.Description]: string | null;
  [NewsDataFormFieldsEnum.Text]: string | null;
  [NewsDataFormFieldsEnum.FileId]?: TFileRecord | null;
};

type TNewsDataFormSelector = Exclude<
NewsDataFormFieldsEnum,
  keyof TExtendedNewsDataValues
>;

export type TNewsDataFieldsValues = Record<
  TNewsDataFormSelector,
  string
> &
  TExtendedNewsDataValues;

export type TNewsDataFieldsSuccessValues = TNewsDataFieldsValues;

