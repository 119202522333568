import { DashboardLayout } from "@/layouts/DashboardLayout";
import React, { useEffect, useState } from "react";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { useActiveTab } from "@/hooks/useActiveTab";
import { UserTable } from "@/pages/Admin/UserTable";
import { UserLogs } from "@/pages/Admin/UserLogs";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { Button } from "@/packages/button/Button";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";

export const Admin = () => {

  return (
    <DashboardLayout
      title={'Администрирование порталом'}
      headerRight={
        <Space>
          <PermissionsAdapter resourceName="admin.user" resourceAction="create">
            <Link to="/admin/user/create">
              <Button
                color="brand"
                theme="primary"
                size="middle"
              >
                Создание пользователя
              </Button>
            </Link>
          </PermissionsAdapter>
        </Space>
      }
    >
      <PermissionsAdapter
        resourceName="admin.user"
        resourceAction="read"
        fallback={() => <>No rights</>}
      >
        <UserTable/>
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
