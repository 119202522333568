import styled from "styled-components";

export const StyledCalendarColorWrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  margin-bottom: 16px;
`;

export const StyledCalendarColorList = styled.ul`
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

export const StyledCalendarColorItem = styled.li`
  display: flex;
  align-items: center;
`;

export const StyledCalendarColor = styled.span<{ color: string }>`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;