import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledEditFormWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const StyledPageHeader = styled.header`
  margin-bottom: 32px;
  padding: 0 24px;
`;

export const StyledFormBox = styled.div`
  max-width: 388px;
  padding: 24px;
  align-self: flex-start;
  flex-grow: 1;
  background: ${colors.white};
  margin-right: 20px;
  border-radius: 8px;
  &:last-child {
    margin: 0;
  }
`;

export const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledFormAction = styled.div``;

export const StyledFormInfo = styled.p`
  margin-top: 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 2;
  color: ${colors.gray200};
`;
