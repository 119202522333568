import { SelectField } from "@/packages/formElements/fields/SelectField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 32px;
  margin-bottom: 32px;
`;

export const StyledStageContainer = styled.div`
  max-width: 848px;
`;

export const StyledGroupTitle = styled(Paragraph)`
  margin-bottom: 15px;
`;

export const StyledGroup = styled.div`
  margin-bottom: 64px;
`;

export const StyledSelectField = styled(SelectField)`
  width: 188px;
  margin-bottom: 24px;
`;

export const StyledIconWrapper = styled.div`
  position: absolute;
  right: 32px;
`;
