import { colors } from "@/helpers";
import styled, { css } from "styled-components";
import { IHeaderParams } from "./TableSimple";

export const StyledTable = styled.div<{ withOverflow: boolean, autoOverflow: boolean }>`
  ${({ withOverflow }) =>
    withOverflow &&
    css`
      overflow: visible;
    `}

  ${({ autoOverflow }) =>
          autoOverflow &&
          css`
      overflow: auto;
    `}

  width: 100% !important;
  min-width: 100% !important;
  border: none;

  [data-sticky-last-left-td] {
    box-shadow: 2px 0 15px rgba(33, 33, 52, 0.1);
    clip-path: inset(0px -15px 0px 0px);
  }

  [data-sticky-last-left-td] {
    border-right: 1px solid ${colors.gray300};

    &::before {
      content: "";

      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;

      width: 1px;
    }
  }

  [data-sticky-first-right-td] {
    border-left: 1px solid ${colors.gray300};
    box-shadow: 0 0 12px rgba(31, 8, 98, 0.1);
  }
`;

interface IStyledHeaderCell {
  isSortable: boolean;
  isFixedWidth?: boolean;
  headerParams?: IHeaderParams;
  alignHeader?: string;
  border?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
}

export const StyledHeaderCell = styled.div<IStyledHeaderCell>`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: ${({ alignHeader }) =>
    alignHeader ? alignHeader : "flex-start"};

  text-align: ${({ alignHeader }) => (alignHeader ? alignHeader : "initial")};
  border-left: ${({ border }) => border?.left};
  border-right: ${({ border }) => border?.right};
  border-top: ${({ border }) => border?.top};
  border-bottom: ${({ border }) => border?.bottom};

  ${({ isFixedWidth }) =>
    isFixedWidth &&
    css`
      flex-grow: 0 !important;
    `}

  ${({ headerParams, alignHeader }) =>
    headerParams?.size === "s"
      ? css`
          height: 56px;
          padding: ${alignHeader === "center" ? "0 8px 0 0" : "0 16px 0 0"};
          &:first-of-type {
            padding: ${alignHeader === "center" ? "0 8px 0 0" : "0 16px 0 32px"};
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "4px"
              : "0"};
          }
          &:last-of-type {
            padding-right: ${alignHeader === "center" ? "8px" : "28px"};
            border-top-right-radius: 4px;
          }
        `
      : css`
          &:first-of-type {
            padding: ${alignHeader === "center" ? "0 8px 0 0" : "0 16px 0 32px"};
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "12px;"
              : "0"};
          }
          &:last-of-type {
            padding-right: ${alignHeader === "center" ? "8px" : "16px"};
            border-top-right-radius: 12px;
          }
          padding: ${alignHeader === "center" ? "0 8px 0 0" : "0 16px 0 0"};
          height: 74px;
        `};

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  background-color: ${({ headerParams }) =>
    colors[headerParams?.color || "gray30"]};
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "auto")};
`;

export const StyledHeaderCellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const StyledCreateCell = styled.th`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  height: 64px;

  background-color: ${colors.gray30};
`;

export const StyledFooterCell = styled.div<IStyledHeaderCell>`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: ${({ alignHeader }) =>
    alignHeader ? alignHeader : "flex-start"};

  ${({ isFixedWidth }) =>
    isFixedWidth &&
    css`
      flex-grow: 0 !important;
    `}

  ${({ headerParams }) =>
    headerParams?.size === "s"
      ? css`
          height: 56px;
          padding: 16px;
          &:first-of-type {
            padding: 0 16px 0 32px;
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "4px"
              : "0"};
          }
          &:last-of-type {
            padding-right: 28px;
            border-top-right-radius: 4px;
          }
        `
      : css`
          &:first-of-type {
            padding: 0 16px 0 32px;
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "12px;"
              : "0"};
          }
          &:last-of-type {
            padding-right: 16px;
            border-top-right-radius: 12px;
          }
          padding: 16px;
          height: 74px;
        `};

  ${({ headerParams }) => headerParams?.hasTopBorderRadius && css``}

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  background-color: ${({ headerParams }) =>
    colors[headerParams?.color || "gray30"]};
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "auto")};
`;

export const StyledFooterCellContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

interface IStyledCell {
  isFixedWidth?: boolean;
  isButton?: boolean;
  headerParams?: IHeaderParams;
  alignCell: "start" | "center" | "flex-start";
  isArchived: boolean;
  isCutVerticalPadding?: boolean;
}

export const StyledCell = styled.div<IStyledCell>`
    width: 200px;
    ${({ isFixedWidth }) =>
            isFixedWidth &&
            css`
                flex-grow: 0 !important;
            `}
    word-break: break-word;
    border-bottom: 1px solid ${colors.gray20};
    ${({ headerParams, alignCell, isCutVerticalPadding }) =>
            headerParams?.size === "s"
                    ? css`
                        min-height: 40px;

                        &:first-of-type {
                            padding: ${isCutVerticalPadding ? (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 32px") :
                                    (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 32px")};
                        }

                        &:last-of-type {
                            padding-right: ${alignCell === "center" ? "8px 0" : "16px 28px"};
                        }

                        padding: ${isCutVerticalPadding ? (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 0") :
                                (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 0")};
                    `
                    : css`
                        min-height: ${isCutVerticalPadding ? '30px' : '50px'};

                        &:first-of-type {
                            padding: ${isCutVerticalPadding ? (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 32px") :
                                    (alignCell === "center" ? " 8px" : "0 16px 0 32px")};
                        }

                        &:last-of-type {
                            padding-right: ${alignCell === "center" ? "0" : "16px"};
                        }

                        padding: ${isCutVerticalPadding ? (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 0") :
                                (alignCell === "center" ? "0 8px 0 0" : "0 16px 0 0")};
                    `}

    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ alignCell }) => alignCell};
    transition: 0.4s;

    ${({ isButton }) =>
            isButton &&
            css`
                padding: 0;
            `}

    ${({ isArchived }) =>
            isArchived &&
            css`
                color: ${colors.gray100};
            `}
`;

export const StyledRow = styled.div<{
  rowSpace?: number;
  isSticky?: boolean;
}>`
  ${({ isSticky }) =>
          isSticky ?
                  css`
                    position: sticky;
                    top: 20px;
                    z-index: 100;
                  `
                  :
                  css`
                    position: relative;
                  `
  }
  margin-bottom: ${({ rowSpace }) => (rowSpace ? rowSpace : 0)}px;
`;

export const StyledSearchButton = styled.div`
  margin-right: 10px;
`;

export const WrappedButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-btn:empty {
    display: none;
  }
`;
