import React, { useMemo } from "react";
import { Space } from "antd";

import { Title } from "@/packages/title/Title";
import { IconButton } from "@/packages/icon-button/IconButton";
import {
  IHeaderItem,
  TableSimple,
  HeaderArgType,
  SortOrder,
} from "@/packages/tableSimple/TableSimple";

import { TMediaRecord } from "@/helpers/api/mediaBase/types";

import { StyledTable } from "./index.styles";
import { useTranslation } from "react-i18next";

interface IMediaBaseTable {
  isLoading: boolean;
  data: TMediaRecord[];
  onSort: (header: HeaderArgType, order: SortOrder) => void;
  onEditButtonClick: (data: TMediaRecord) => void;
  onDeleteButtonClick: (data: TMediaRecord) => void;
}

export const MediaBaseTable: React.FC<IMediaBaseTable> = (props) => {
  const { data, onSort, isLoading, onEditButtonClick, onDeleteButtonClick } =
    props;
  const { t } = useTranslation();
  const headers: IHeaderItem<TMediaRecord | { buttons: JSX.Element }>[] =
    useMemo(() => {
      return [
        {
          Header: t("maintenanceGrids.worksModel.workForm.name") || "",
          accessor: "name",
          isSortable: true,
          minWidth: 400,
          maxWidth: 400,
          width: "400px",
        },
        {
          Header: t("park.mediaType") || "",
          accessor: "type",
          isSortable: true,
          minWidth: 300,
        },
        {
          Header: "",
          accessor: "buttons",
          width: "50px",
          minWidth: 50,
          Cell: (data) => {
            const currentData = data.row.original;
            return (
              <Space>
                <IconButton
                  size="l"
                  icon="edit"
                  color="black"
                  variant="tertiary"
                  isDisabled={isLoading}
                  onClick={() => onEditButtonClick(currentData as TMediaRecord)}
                />
                <IconButton
                  size="l"
                  icon="delete"
                  color="black"
                  variant="tertiary"
                  isDisabled={isLoading}
                  onClick={() =>
                    onDeleteButtonClick(currentData as TMediaRecord)
                  }
                />
              </Space>
            );
          },
        },
      ];
    }, []);

  const nestedTableArgs = { headers, data };

  return (
    <StyledTable>
      {!!data.length ? (
        <TableSimple
          onSort={onSort}
          showCheckbox={false}
          {...nestedTableArgs}
        />
      ) : !isLoading && !data.length ? (
        <Title level={2}>{t("common.noData") || ""}</Title>
      ) : (
        <></>
      )}
    </StyledTable>
  );
};
