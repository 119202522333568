import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col } from "antd";
import React, { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IAuditFilterFormValues, useAuditFilterForm } from "./hooks";
import { Button } from "@/packages/button/Button";
import {
  ColumnWraper,
  StyledButton,
  StyledButtons,
  StyledFilters,
  StyledRow,
  StyledSeparator,
} from "./index.styles";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import {
  preparedDealerList,
  useGetKiaGroupDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { IRequestParams } from "@/helpers/interfaces";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { useGetAuditorsList } from "@/helpers/api/audit-list/hooks";
import { useGetUserList } from "@/helpers/api/users/hooks";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface IWarrantyAuditListFilter {
  onFilterChange: (value: IRequestParams) => void;
  setFilterQueryParams: (value: IAuditFilterFormValues) => void;
  initValues: IAuditFilterFormValues;
}

const MOCK_AUDIT_TYPE = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "KIA",
    value: "kia",
  },
  {
    label: "warrantyAudits.list.dealer",
    value: "dealer",
  },
  {
    label: "factory",
    value: "factory",
  },
  {
    label: "warrantyAudits.list.extAuditor",
    value: "external auditor",
  },
];

const MOCK_AUDIT_STATUS = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "audits.list.new",
    value: "Новый",
  },
  {
    label: "audits.list.active",
    value: "Активный",
  },
  {
    label: "audits.list.closed",
    value: "Закрытый",
  },
  {
    label: "audits.list.archived",
    value: "В архиве",
  },
];

const WarrantyAuditListFilter: React.FC<IWarrantyAuditListFilter> = (props) => {
  const { onFilterChange, setFilterQueryParams, initValues } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });

  const { data: dealerList} =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const { data: auditorsList, isLoading: isAuditorsLoading } =
    useGetAuditorsList({ "page[limit]": 0 });

  const { data: users, isLoading: isUsersLoading } = useGetUserList({
    permissionSlug: "guarantee.dealer-violations-actions",
    permissionAction: "update",
    limit: 0,
  });

  const { data: managerFilterOptionsResponse } = useGetKiaGroupDealerList({
    group: "zoneServiceManager",
  });

  const mappedUsers = useMemo(
    () =>
      users?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [],
    [users]
  );

  const mappedManagers = useMemo(
    () =>
      managerFilterOptionsResponse?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [],
    [managerFilterOptionsResponse]
  );

  const onFilterApply = (data) => {
    let params = {};
    Object.entries(data).forEach(([key, value]) => {
      params = {
        ...params,
        [key]: {
          value,
          type: "filter",
        },
      };
    });
    setFilterQueryParams(data);
    onFilterChange(params);
  };

  const { form, onClearHandler, onSubmitHandler } = useAuditFilterForm({
    initValues,
    onSubmit: onFilterApply,
  });

  const clearFilters = () => {
    onClearHandler();
    onFilterApply({});
  };

  const preparedHoldingList = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );

  const preparedAuditorsList = useMemo(
    () =>
      auditorsList?.data?.data?.map((item) => ({
        label: `${item.firstName}  ${item.lastName}`,
        value: item.id,
      })) || [],
    [auditorsList?.data, isAuditorsLoading]
  );

  return (
    <StyledFilters isOpen={isOpen}>
      <FormProvider {...form}>
        <StyledRow gutter={16} space={24}>
          <Col xs={24} md={4} xxl={3}>
            <SelectField
              label={t("audits.list.auditType") || ""}
              placeholder={t("audits.list.all") || ""}
              options={MOCK_AUDIT_TYPE}
              onChange={(value) => {
                onFilterChange({
                  type: {
                    value,
                    type: "filter",
                  },
                });
              }}
            />
          </Col>

          <Col xs={20} md={4} xxl={3}>
            <SelectField
              label={t("audits.list.auditStatus") || ""}
              placeholder={t("audits.list.all") || ""}
              options={MOCK_AUDIT_STATUS}
              onChange={(value) => {
                onFilterChange({
                  status: {
                    value,
                    type: "filter",
                  },
                });
              }}
            />
          </Col>

          <Col>
            <StyledButton
              icon="filter"
              color="black"
              size="m"
              isSquared={true}
              variant={isOpen ? "primary" : "secondary"}
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={12} md={8}>
            <FormSelect
              name="holdingId"
              label={t("audits.list.holdingId") || ""}
              options={preparedHoldingList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={12} md={9}>
            <FormSelect
              name="dealerId"
              label={t("audits.list.dealerId") || ""}
              options={preparedDealerList(dealerList?.data?.data || [])}
              placeholder={t("audits.list.chooseList") || ""}
              onSearch={setSearchValue}
              isSearchShow
              allowClear
            />
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={24} md={8}>
            <FormSelect
              name="curatorId"
              label={t("warrantyAudits.list.curator") || ""}
              options={mappedUsers || []}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={24} md={9}>
            <FormSelect
              name="managerId"
              label={t("warrantyAudits.list.manager") || ""}
              options={mappedManagers}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={24} md={9} xxl={8}>
            <FormSelect
              name="auditorId"
              label={t("audits.list.auditorId") || ""}
              options={preparedAuditorsList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={24} md={8} style={{ marginTop: 10 }}>
            <ColumnWraper>
              <FormDatepickerField
                name="startCreatedAt"
                label={t("audits.list.date") || ""}
              />
              <StyledSeparator />
              <FormDatepickerField
                name="endCreatedAt"
                label={t("warrantyAudits.list.endDate") || ""}
              />
            </ColumnWraper>
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}></StyledRow>
        <StyledButtons>
          <Button
            color="brand"
            theme="primary"
            size="middle"
            onClick={onSubmitHandler}
          >
            {t("audits.list.find") || ""}
          </Button>
          <Button
            color="brand"
            theme="secondary"
            size="middle"
            onClick={clearFilters}
          >
            {t("audits.list.clear") || ""}
          </Button>
        </StyledButtons>
      </FormProvider>
    </StyledFilters>
  );
};

export default WarrantyAuditListFilter;
