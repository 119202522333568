import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import { StyledButton, StyledWrapperCard, StyledWrapperIcon, StyledWrapperLink, } from "./index.styles";

interface TransportComplaintsFileProps {
  name: string | undefined;
  size?: string;
  onRemove?: (id?: string) => void;
  path?: string;
  id?: string;
  isDisabled?: boolean;
  iconDelete: boolean;
  extension?: string;
}
const FileContainer: React.FC<TransportComplaintsFileProps> = (props) => {
  const { name, extension, size, onRemove, path, id, isDisabled, iconDelete } = props;

  const getFileIconByExtension = (extension: string | undefined): string => {
    console.log(extension);
    switch (extension?.toLocaleLowerCase()) {
      case '.png':
        return 'file-png';
      case '.docx':
      case '.doc':
        return 'file-doc';
      case '.xlsx':
      case '.xls':
        return 'file-xlsx';
      case '.pdf':
      case '.PDF':
        return 'file-pdf';
      case '.ppt':
      case '.pptx':
        return 'file-pptx';
      case '.jpeg':
      case '.jpg':
      case '.JPG':
      case '.jfif':
        return 'file-jpeg';
      default:
        return 'file'
    }
  }

  return (
    <>
      {!isDisabled ? (
        <StyledWrapperCard
          style={{ display: isDisabled ? "none !important" : "flex" }}
        >
          <Paragraph size={12}>{name}</Paragraph>
          {size && (
            <Paragraph size={12} color={"gray4"}>
              {size}
            </Paragraph>
          )}
          {iconDelete && onRemove && (
            <StyledButton
              onClick={(e) => {
                e.stopPropagation();
                onRemove(id);
              }}
            >
              <Icon size={12} name={"close-16"} />
            </StyledButton>
          )}

          <StyledWrapperLink target={"_blank"} href={path || " "}>
            <StyledWrapperIcon>
              <Icon size={54} name={getFileIconByExtension(extension)} />
            </StyledWrapperIcon>
          </StyledWrapperLink>
        </StyledWrapperCard>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileContainer;
