import {
  FuelSelectFieldAdapter,
  IFuelSelectFieldAdapter,
} from "@/adapters/selects/FuelSelectFieldAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormFuelSelectField
  extends Omit<IFuelSelectFieldAdapter, "error" | "value" | "onChange"> {
  name: string;
}

const _FormFuelSelectField: React.FC<IFormFuelSelectField> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <FuelSelectFieldAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormFuelSelectField = React.memo(_FormFuelSelectField);
