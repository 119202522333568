export const vehicleNumberToWithSpaces = (number: string) => {
  const isDigit = (char) => {
    return /^\d+$/.test(char);
  };

  const isLetter = (char) => {
    return /^[dа-яёА-ЯЁa-zA-Z]+$/.test(char);
  };

  let result = "";

  for (let i = 0; i < number.length; i++) {
    if (
      (isDigit(number[i]) && isDigit(number[i + 1])) ||
      (isLetter(number[i]) && isLetter(number[i + 1]))
    ) {
      result += number[i];
    } else {
      result += number[i] + " ";
    }
  }

  return result;
};
