import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { NewCarFields } from "../NewCarFIelds";
import { TOnSubmitKiaFlexForm, useCreateOrderForm } from "../NewCarForm/hooks";
import { useTranslation } from "react-i18next";

export interface ICreateOrdersModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitKiaFlexForm;
  record?: any;
}

const CreateOrdersModal: React.FC<ICreateOrdersModal> = (props) => {
  const { onSubmit, record, ...rest } = props;

  const status = record?.status;

  const { t } = useTranslation();

  const { form, submit } = useCreateOrderForm(onSubmit, true);

  useEffect(() => {
    if (!rest.isOpen) {
      form.reset();
    }
  }, [form, rest.isOpen]);

  return (
    <Modal
      {...rest}
      size="medium"
      confirmButtonText={t("kiaFlex.appRequest") || ""}
      cancelButtonText={t("kiaFlex.appRequestCancel") || ""}
      onSubmit={submit}
    >
      <FormProvider {...form}>
        <NewCarFields status={status} form={form} />
      </FormProvider>
    </Modal>
  );
};
export default CreateOrdersModal;
