import { colors } from '@/helpers';
import { useExportMaintenanceGrid, useGetMaintenanceGridById } from '@/helpers/api/maintenanceGrids/grids/hooks';
import { useGetEquipmentList } from '@/helpers/api/modifications/hooks';
import { getAdaptSelectOptions, handleFileLoad } from '@/helpers/utils';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { Button } from '@/packages/button/Button';
import { SwitchField } from '@/packages/formElements/fields/SwitchField';
import { IconButton } from '@/packages/icon-button/IconButton';
import { Icon } from '@/packages/icon/Icon';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { TableMaintenanceGrids } from '@/packages/tableMaintenanceGrids';
import { getTableHeaders } from '@/packages/tableMaintenanceGrids/utils';
import { Space } from 'antd';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ContextProfile } from '@/hooks/contexts/useProfile';
import { IconWorkType } from '@/packages/iconWorkType';
import { PermissionsAdapter } from '@/adapters/shared/PermissionsAdapter';
import { TPermissionResourcesName } from '@/helpers/api/eventLogs/types';
import { usePermission } from '@/hooks/usePermission';
import { useCopyCurrentUrl } from '@/hooks/useCopyCurrentUrl';
import { useTranslation } from 'react-i18next';
import { SelectField } from '@/packages/formElements/fields/SelectField';
import { InfoModal } from '@/pages/MaintenanceGridsDetailsView/infoModal';
import { useModal } from '@/hooks/useModal';

export type MaintenanceGridType = {
    type: 'I' | 'R' | 'O';
}

interface TWorkTableData {
    workId: string;
    name: string;
    quantity: number;

    [key: number]: {
        workTypeId: string;
        number: number;
    };
}

interface TGsmTableData {
    materialId: string;
    name: string;
    quantity: number;

    [key: number]: boolean;
}

const StyledMaintenanceGridsDetails = styled.div`
    padding: 24px 32px;
    margin-bottom: 64px;

    border-radius: 12px;

    background-color: ${colors.white};
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
`;

const StyledContentTop = styled.div`
    display: grid;
    gap: 24px;
    margin-bottom: 24px;
`;

const StyledEditIcon = styled.div`
    margin-right: 8px;
`;

const StyledContentTitle = styled.div`
    position: relative;

    padding: 20px 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledButtons = styled(Space)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledHeaderRightContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
`;

const WrappedButtons = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    align-items: center;
`;

const StyledTableMaintenanceGrids = styled((props) => (
    <TableMaintenanceGrids<TWorkTableData | TGsmTableData> {...props} />
))`
    margin-left: -32px;
    margin-right: -32px;
`;

const StyledRoleButtons = styled(Space)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 4px;
    overflow: hidden;
`;

const StyledButton = styled(Button)`
    width: 96px;
    border: 0;
    border-radius: 0;
`;

const StyledInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PAGE_PERMISSION_NAME: TPermissionResourcesName = 'maintenance-grid';

const MaintenanceGridsDetailsView: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const maintenanceId = params.id as string;
    const copyCurrentUrl = useCopyCurrentUrl({
        successMessage: 'URL сетки ТО скопирован в буфер обмена',
    });

    const { t } = useTranslation();

    const { hasAccess: isEditEnabled } = usePermission(
        PAGE_PERMISSION_NAME,
        'update',
    );
    const {
        state: { profile },
    } = useContext(ContextProfile);

    const profileRoleType = profile?.type || 'dealer';
    const isDealer = profile?.type === 'dealer';

    useEffect(() => {
        setActiveRole(!isEditEnabled);
    }, [ isEditEnabled ]);

    const [ activeRole, setActiveRole ] = useState<boolean>(isDealer);

    const { data: maintenanceGrid, isLoading: isGridLoading } =
        useGetMaintenanceGridById({
            id: maintenanceId,
            isEnabled: Boolean(maintenanceId),
        });

    const { data: equipments } = useGetEquipmentList({});

    const { mutateAsync: exportMaintenanceGrid } = useExportMaintenanceGrid();

    const [ creatingModificationIds, setCreatingModificationIds ] = useState<
        string[]
    >([]);

    const [ creatingModificationIdsLabel, setCreatingModificationIdsLabel ] =
        useState<string>('');


    const worksTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код работ',
        isDealer,
        true,
    );

    const gsmTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код ГСМ',
        isDealer,
        true,
    );

    const partsTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код запчастей',
        isDealer,
        true,
    );

    const summaryTableHeaders = getTableHeaders(
        {
            isCheckable: false,
            isMultiColor: false,
        },
        3,
        '-',
        false,
        true,
    );

    const equipmentOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options:
                    equipments?.data.data.map((equipment) => ({
                        ...equipment,
                        name: equipment.fullName,
                    })) || [],
            }),
        [ equipments?.data.data ],
    );

    const { preparedWorks, preparedGsm, preparedSpares, preparedSummary } =
        useMemo(() => {
            const preparedWorks =
                maintenanceGrid?.data?.works?.map((workItem) => {
                    const maintancePositions = workItem.maintenanceNumbers.reduce(
                        (acc: { [key: number]: number | JSX.Element }, item) => ({
                            ...acc,
                            [item.number]: !activeRole ? (
                                (
                                    checkMaterialIsOptionalType(item?.workType) ? <></> :
                                        item?.workType?.totalCost * workItem?.amount
                                )
                            ) : item?.workType?.type ? (
                                <IconWorkType type={item?.workType?.type}/>
                            ) : (
                                <></>
                            ),
                        }),
                        {},
                    );

                    return {
                        id: !activeRole ? workItem?.work?.code : <></>,
                        name: `${workItem?.work?.name}`,
                        quantity: workItem?.amount,
                        ...maintancePositions,
                    };
                }) || [];

            const preparedGsm =
                maintenanceGrid?.data?.materials?.map((item) => {
                    const maintancePositions = item.maintenanceNumbers.reduce(
                        (
                            acc: { [key: number]: string | number | boolean | JSX.Element },
                            reduceItem,
                        ) => {
                            const maintenanceGridMaterialNumber: MaintenanceGridType | undefined = item?.maintenanceGridMaterialNumbers.find(({ number }) => reduceItem === number);
                            return {
                                ...acc,
                                [reduceItem]: !activeRole ?
                                    (checkMaterialIsOptionalType(maintenanceGridMaterialNumber) ? <></> :
                                            Number.isInteger(item?.material?.pricePerLiter * item?.amount)
                                                ? item?.material?.pricePerLiter * item?.amount
                                                : (item?.material?.pricePerLiter * item?.amount).toFixed(2)
                                    )
                                    : maintenanceGridMaterialNumber ?
                                        <IconWorkType type={maintenanceGridMaterialNumber.type}/> : <></>,
                            };
                        },
                        {},
                    );
                    return {
                        id: item?.material?.code,
                        name: `${item?.material?.name}`,
                        quantity: item?.amount,
                        value: !!item?.material?.pricePerLiter,
                        ...maintancePositions,
                    };
                }) || [];


            const preparedSpares =
                maintenanceGrid?.data?.spares?.map((item) => {
                    const maintancePositions = item.maintenanceNumbers.reduce(
                        (
                            acc: { [key: number]: string | number | boolean | JSX.Element },
                            reduceItem,
                        ) => {
                            const maintenanceGridSpareNumbers: MaintenanceGridType | undefined = item?.maintenanceGridSpareNumbers.find(({ number }) => reduceItem === number);

                            return {
                                ...acc,
                                [reduceItem]: !activeRole ?
                                    (
                                        checkMaterialIsOptionalType(maintenanceGridSpareNumbers) ? <></> :
                                            (
                                                Number.isInteger(item?.spare?.price * item?.amount)
                                                    ? item?.spare?.price * item?.amount
                                                    : (item?.spare?.price * item?.amount).toFixed(2)
                                            )
                                    )
                                    : maintenanceGridSpareNumbers ?
                                        <IconWorkType type={maintenanceGridSpareNumbers.type}/> : <></>,
                            };
                        },
                        {},
                    );
                    return {
                        // @ts-ignore
                        id: item?.spare?.code,
                        name: `${item?.spare?.name}`,
                        quantity: item?.amount,
                        value: !!item?.spare?.price,
                        ...maintancePositions,
                    };
                }) || [];

            const summaryObject: any = {};

            const parseCostsToSummary = (preparedSection: any[]) => {
                for (let i in preparedSection) {
                    const tempRow = preparedSection[i];
                    for (const key in tempRow) {
                        if (key !== 'name' && key !== 'value') {
                            if (!summaryObject[key]) {
                                summaryObject[key] = 0;
                            }
                            const parsed = Number.parseFloat(tempRow[key]);
                            if (!isNaN(parsed)) {
                                summaryObject[key] += parsed;
                            }
                        }
                    }
                }
            };

            parseCostsToSummary(preparedWorks);
            parseCostsToSummary(preparedGsm);
            parseCostsToSummary(preparedSpares);

            for (let i = 1; i < 11; i++) {
                if (!summaryObject[i]) {
                    summaryObject[i] = 0;
                } else {
                    summaryObject[i] = summaryObject[i].toFixed(2);
                }
            }
            if (summaryObject.quantity) {
                summaryObject.quantity = summaryObject.quantity.toFixed(2);
            }
            if (summaryObject.id || typeof summaryObject.id === 'number') {
                summaryObject.id = '';
            }

            const preparedSummary = [ summaryObject ];

            return { preparedWorks, preparedGsm, preparedSpares, preparedSummary };
        }, [ maintenanceGrid?.data, activeRole ]);

    useEffect(() => {
        setCreatingModificationIds(() => [
            ...(maintenanceGrid?.data.modifications?.map(
                (equipment) => `${equipment.fullName} (${equipment.vinEngineTransmission?.join(', ') ?? ''})`,
            ) || []),
        ]);
    }, [ maintenanceGrid?.data.modifications ]);

    function checkMaterialIsOptionalType<T extends MaintenanceGridType>(maintenanceGridMaterialNumber: undefined | T) {
        if (!maintenanceGridMaterialNumber) {
            return false;
        }

        return maintenanceGridMaterialNumber.type === 'O';
    }

    useEffect(() => {
        setCreatingModificationIdsLabel(() => {
            return (
                maintenanceGrid?.data.modifications?.map(
                    (equipment) =>
                        `${equipment.generation.model.name} ${
                            equipment.generation.name ? equipment.generation.name : ''
                        } ${
                            equipment.generation.restylingName === null
                                ? ''
                                : equipment.generation.restylingName
                        } (${equipment.generation?.generationYearStart ?? ''}-${equipment?.generation?.generationYearEnd ?? ''})`,
                )[0] || ''
            );
        });
    }, [ maintenanceGrid?.data.modifications ]);

    const handleEditOpen = () => {
        navigate(`/maintenance-grids/details/edit/${maintenanceId}`);
    };

    const handleExportMaintenanceGrid = () => {
        exportMaintenanceGrid(
            //@ts-ignore
            maintenanceId,
            {
                onSuccess: (response) => {
                    handleFileLoad(response.data as Blob, 'maintenance-grid.xlsx');
                },
            },
        );
    };

    const {
        modalState: InfoModalState,
        openModal: InfoModalOpen,
        closeModal: InfoModalClose,
    } = useModal();

    return (
        <PermissionsAdapter
            resourceName={PAGE_PERMISSION_NAME}
            resourceAction='read'
            fallback={() => <span>No rights</span>}
        >
            <DashboardLayout
                title={t('maintenanceGrids.gridsModel.viewGrid.title') || ''}
                toBack={-1}
                isLoading={isGridLoading}
                headerRight={
                    <PermissionsAdapter
                        resourceName={PAGE_PERMISSION_NAME}
                        resourceAction='update'
                    >
                        <StyledHeaderRightContent>
                            <WrappedButtons>
                                <SwitchField
                                    className='row-reverse'
                                    label={t('maintenanceGrids.gridsModel.viewGrid.status') || ''}
                                    disabled={true}
                                    value={!!maintenanceGrid?.data?.isActive}
                                />
                                <Button
                                    color='brand'
                                    icon={
                                        <StyledEditIcon>
                                            <Icon name='edit' size={16}/>
                                        </StyledEditIcon>
                                    }
                                    isUploadLeft={true}
                                    onClick={handleEditOpen}
                                >
                                    {t('maintenanceGrids.gridsModel.viewGrid.editGrid') || ''}
                                </Button>
                            </WrappedButtons>
                        </StyledHeaderRightContent>
                    </PermissionsAdapter>
                }
            >
                <StyledMaintenanceGridsDetails>
                    <StyledContentTop>
                        <StyledInfoWrapper>
                            <Paragraph size={16} color='black'>
                                {t('maintenanceGrids.gridsModel.viewGrid.models') || ''}
                            </Paragraph>
                            <Button
                                onClick={InfoModalOpen}
                            >
                                Пояснения к сеткам ТО
                            </Button>
                        </StyledInfoWrapper>
                        <SelectField
                            value={creatingModificationIds}
                            options={equipmentOptions}
                            label={creatingModificationIdsLabel}
                            mode='multiple'
                            maxTagCount='responsive'
                            removeIcon={() => null}
                            readOnly={true}
                            showArrow={false}
                        />
                    </StyledContentTop>
                    <StyledContentTitle>
                        <StyledButtons size={8}>
                            <IconButton
                                icon='save'
                                isSquared={true}
                                variant='secondary'
                                color='gray'
                                onClick={handleExportMaintenanceGrid}
                            />
                            <IconButton
                                icon='share'
                                isSquared={true}
                                variant='secondary'
                                color='gray'
                                onClick={() => copyCurrentUrl()}
                            />
                        </StyledButtons>

                        <Paragraph size={18} color='black'>
                            {t('maintenanceGrids.gridsModel.viewGrid.grid') || ''}
                        </Paragraph>

                        {profileRoleType === 'kia' ? (
                            <StyledRoleButtons size={0}>
                                <StyledButton
                                    size='middle'
                                    theme='primary'
                                    color={activeRole ? 'gray' : 'brand'}
                                    onClick={() => setActiveRole((prev) => !prev)}
                                    isDisabled={!isEditEnabled}
                                >
                                    {t('maintenanceGrids.gridsModel.viewGrid.manager') || ''}
                                </StyledButton>
                                <StyledButton
                                    size='middle'
                                    theme='primary'
                                    color={activeRole ? 'brand' : 'gray'}
                                    onClick={() =>
                                        isEditEnabled && setActiveRole((prev) => !prev)
                                    }
                                >
                                    {t('maintenanceGrids.gridsModel.viewGrid.dealer') || ''}
                                </StyledButton>
                            </StyledRoleButtons>
                        ) : (
                            <></>
                        )}
                    </StyledContentTitle>

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.works') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.worksDescr') || ''
                        }
                        headers={worksTableHeaders}
                        data={preparedWorks}
                        withFooter={!activeRole}
                        isViewMode={true}
                    />

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.gsm') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.gsmDescr') || ''
                        }
                        headers={gsmTableHeaders}
                        data={preparedGsm}
                        withFooter={!activeRole}
                        isViewMode={true}
                        gsmCheckMark={true}
                    />

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.details') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.detailsDescr') || ''
                        }
                        headers={partsTableHeaders}
                        data={preparedSpares || []}
                        withFooter={!activeRole}
                        isViewMode={true}
                        gsmCheckMark={true}
                    />

                    {
                        !activeRole &&
                        <StyledTableMaintenanceGrids
                            title={t('maintenanceGrids.gridsModel.viewGrid.total') || ''}
                            headers={summaryTableHeaders}
                            data={preparedSummary || []}
                            isSummary={true}
                            isViewMode={true}
                        />
                    }
                </StyledMaintenanceGridsDetails>
            </DashboardLayout>
            <InfoModal
                isOpen={InfoModalState.isOpen}
                onCancel={InfoModalClose}
            />
        </PermissionsAdapter>
    );
};

export { MaintenanceGridsDetailsView };
