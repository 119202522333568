import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledContent = styled.div`
  padding: 20px 32px 120px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${colors.white};
`;
