import styled from "styled-components";
import { Col } from "antd";

export const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
`;