import React from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { Icon } from "@/packages/icon/Icon";
import {
  StyledFrame,
  StyledErrorParagraph,
  StyledMessageParagraph,
  StyledMessagesContainer,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IErrorMessages {
  error: string;
  messages: string[];
}

export interface ReportsErrorModal extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: () => void;
  isWithSubmit: boolean;
  errorMessages: IErrorMessages;
}

export const ReportsErrorModal: React.FC<ReportsErrorModal> = (props) => {
  const { errorMessages, onSubmit, onCancel, isWithSubmit } = props;

  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      showConfirmButton={isWithSubmit}
      confirmButtonText={t("common.ignore") || ""}
      cancelButtonText={t("common.cancel") || ""}
      onSubmit={onSubmit}
      onCancel={() => onCancel && onCancel()}
    >
      <StyledFrame>
        <Icon name="warning" size={64} color="red" />
        <StyledErrorParagraph size={14}>
          {errorMessages.error}
        </StyledErrorParagraph>
      </StyledFrame>
      <StyledMessagesContainer>
        {!!errorMessages.messages.length &&
          errorMessages.messages.map((message, index) => (
            <StyledMessageParagraph size={14}>
              {`${index + 1}) ${message}`}
            </StyledMessageParagraph>
          ))}
      </StyledMessagesContainer>
    </Modal>
  );
};
