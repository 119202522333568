interface IThemes {
  [key: string]: {
    [key: string]: string;
  };
}

export const themes: IThemes = {
  green: {
    backgroundColor: "greenStatusBg",
    color: "greenStatus",
  },
  red: {
    backgroundColor: "redStatusBg",
    color: "redStatus",
  },
  gray: {
    backgroundColor: "grayStatusBg",
    color: "grayStatus",
  },
  purple: {
    backgroundColor: "purpleStatusBg",
    color: "purpleStatus",
  },
  yellow: {
    backgroundColor: "yellowStatusBg",
    color: "yellowStatus",
  },
  cyan: {
    backgroundColor: "cyanStatusBg",
    color: "cyanStatus",
  },
  scarlet: {
    backgroundColor: "scarletStatusBg",
    color: "scarletStatus",
  },
};
