import { TPatchAuditRecord } from "@/helpers/api/audits/types";
import { usePermission } from "@/hooks/usePermission";
import { Select } from "@/packages/formElements/inputs/Select";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Popconfirm } from "antd";

import { StyledButtons, StyledSelect } from "./index.styles";
import { t } from "i18next";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const MOCK_AUDIT_FILTERS = [
  { label: "audits.templates.all", value: "" },
  { label: "audits.templates.active", value: "true" },
  { label: "audits.templates.hide", value: "false" },
];
export const MOCK_AUDIT_FILTERS_TABLE = [
  { label: "audits.templates.active", value: "true" },
  { label: "audits.templates.hide", value: "false" },
];

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  onDeleteClick?: (id: number) => void;
  onAuditUpdate?: (id: number, patch: TPatchAuditRecord) => void;
  onEditClick: (id: number) => void;
  onViewClick: (id: number) => void;
  openUserModal: Dispatch;
}): IHeaderItem<TRecord>[] => {
  const { onDeleteClick, onAuditUpdate, onEditClick, onViewClick, openUserModal } = options;

  return [
    {
      Header: "ID",
      accessor: "id",
      width: "78px",
      minWidth: 78,
      maxWidth: 78,
    },
    {
      Header: t("audits.templates.name") || "",
      accessor: "title",
      isSortable: true,
      width: "250px",
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: t("audits.templates.max") || "",
      accessor: "sum",
      minWidth: 100,
      maxWidth: 150,
    },
    {
      Header: t("audits.templates.status_2") || "",
      accessor: "active",
      isSortable: true,
      width: "142px",
      minWidth: 142,
      maxWidth: 142,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledSelect>
            <Select
              options={MOCK_AUDIT_FILTERS_TABLE.filter((item) => !!item.value)}
              value={
                MOCK_AUDIT_FILTERS_TABLE.find(
                  (item) => item.value === value?.toString()
                )?.label
              }
              onChange={(_, option) => {
                if (!Array.isArray(option)) {
                  onAuditUpdate &&
                  // @ts-ignore
                  onAuditUpdate(original.id, {
                    active: option.value === "true",
                  });
                }
              }}
            />
          </StyledSelect>
        );
      },
    },
    {
      Header: t("audits.templates.creator") || "",
      accessor: "creator",
      isSortable: true,

      Cell: ({ row: { original } }) => {
        return (
          // @ts-ignore
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original?.creator?.id ?? '' })}>
            {/*// @ts-ignore*/}
            {original?.creator?.firstName} {original?.creator?.lastName}
          </StyledUserNameWrapper>
        );
      },
    },
    {
      Header: t("audits.templates.dateCreation") || "",
      accessor: "createdAt",
      isSortable: true,
      width: "78px",
      minWidth: 78,
      maxWidth: 78,
      Cell: ({ row: { original }, value }) => {
        return (
          <div>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Paragraph>
          </div>
        );
      },
    },
    {
      Header: t("audits.templates.dateChange") || "",
      accessor: "updatedAt",
      isSortable: true,
      width: "78px",
      minWidth: 78,
      maxWidth: 78,
      Cell: ({ row: { original }, value }) => {
        return (
          <div>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Paragraph>
          </div>
        );
      },
    },

    {
      Header: "",
      id: "actions",
      width: "110px",
      minWidth: 110,
      maxWidth: 110,
      Cell: ({ row: { original } }) => {
        const hasEdit = usePermission("audit.templates", "update");
        return (
          <StyledButtons>
            {/* <IconButton
              icon="eye-open"
              color="black"
              size="l"
              variant="tertiary"
              // @ts-ignore
              onClick={() => onViewClick(original.id)}
            /> */}
            <Tooltip
              content={
                hasEdit
                  ? t("audits.templates.edit") || ""
                  : t("audits.templates.look") || ""
              }
              placement="bottom"
            >
              <IconButton
                icon={hasEdit ? "edit" : "eye-open"}
                color="black"
                size="l"
                variant="tertiary"
                onClick={() =>
                  // @ts-ignore
                  hasEdit ? onEditClick(original.id) : onViewClick(original.id)
                }
              />
            </Tooltip>
            <Tooltip
              content={t("audits.templates.delete") || ""}
              placement="bottom"
            >
              <Popconfirm
                title={t("audits.templates.deleteConfirm") || ""}
                // @ts-ignore
                onConfirm={() => onDeleteClick && onDeleteClick(original.id)}
                okText={t("common.yes") || ""}
                cancelText={t("common.no") || ""}
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Popconfirm>
            </Tooltip>
          </StyledButtons>
        );
      },
    },
  ];
};
