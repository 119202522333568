import styled from "styled-components";
import { colors, media } from "@/helpers";

export const StyledApplicationsFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 24px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const StyledFirstFormRow = styled.div`
  ${media.tablet} {
    display: grid;
    gap: 32px;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: minmax(100px, 100px) auto;
  }
  ${media.desktop} {
    grid-template-columns: minmax(200px, 200px) auto;
  }
`;

export const StyledLastFormRow = styled.div`
  ${media.tablet} {
    display: grid;
    gap: 32px;
    grid-template-columns:
      minmax(208px, auto) minmax(130px, auto) minmax(130px, auto)
      minmax(130px, auto);
  }
  ${media.desktop} {
    grid-template-columns:
      minmax(408px, auto) minmax(298px, auto) minmax(160px, auto)
      minmax(160px, auto);
  }
`;

export const StyledDatepickerWrapper = styled.div`
  display: grid;
  gap: 10px;
  align-items: end;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  margin-bottom: 14px;
  background-color: ${colors.gray4};
`;

export const StyledField = styled.span`
  width: 12px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
