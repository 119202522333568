import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledTab = styled.div`
  padding: 0 32px 20px 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${colors.white};
  &:last-child {
    gap: 0px;
  }
`;

export const StyledHR = styled.hr`
  border: 1px solid #f2f3f6;
  width: 100%;
  margin: 0 auto;
`;
