import { ICreatedRow } from '@/packages/tableMaintenanceGrids';
import { TYPE_COLOR } from '@/packages/tableMaintenanceGrids/utils';

export const getAdaptMaintenanceNumbersForTable = (
  maintenanceNumbers: ({ number: number } | number)[]
) => {
  const initialHeaders = new Array(15)
    .fill(null)
    .map((_, index) => ({ header: index + 1 }));

  return initialHeaders.reduce((acc, header) => {
    const match = maintenanceNumbers?.find(
        (number) =>
            (typeof number !== "number" ? number.number : number) === header.header
    );

    acc[header.header] = match ? match : typeof match === "number";

    return acc;
  }, {});
};

export const getMaintenanceNumbers = ({
  data,
  isMultiColorNumbers = false,
  idKey,
}: {
  data: { [key: string | number]: any };
  isMultiColorNumbers?: boolean;
  idKey?: string;
}) => {
  return isMultiColorNumbers && idKey
    ? Object.values(data).filter(
        (dataValue: any) =>
          dataValue[idKey] && dataValue[idKey] !== TYPE_COLOR.DEFAULT
      )
    : Object.entries(data)
        .map(
          ([key, value]) =>
            typeof value === "number" &&
            key !== "id" &&
            key !== "quantity" &&
            value
        )
        .filter(Boolean);
};

export const createWorkTableRow = (work: ICreatedRow) => ({
  workId: work.name?.id,
  workTypes: work.name?.workTypes,
  name: work.name?.name,
  quantity: work.quantity || 1,
  ...getAdaptMaintenanceNumbersForTable([]),
});

export const createGsmTableRow = (gsm: ICreatedRow) => ({
  materialId: gsm.name?.id,
  name: gsm.name?.name,
  quantity: gsm.quantity || 1,
  ...getAdaptMaintenanceNumbersForTable([]),
});

export const createSpareTableRow = (spare: ICreatedRow) => ({
  spareCode: spare.name?.code,
  name: spare.name?.name,
  quantity: spare.quantity || 1,
  ...getAdaptMaintenanceNumbersForTable([]),
});
