import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TFuelForm, FUEL_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export interface IFuelCreateModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (value: TFuelForm) => void;
  isLoadingSubmit: boolean;
}

export const FuelCreateModal: React.FC<IFuelCreateModal> = (props) => {
  const { onSubmit, isLoadingSubmit, ...restProps } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState<TFuelForm>(FUEL_INITIAL_FORM);

  useEffect(() => {
    if (!restProps.isOpen) {
      setForm(FUEL_INITIAL_FORM);
    }
  }, [restProps.isOpen]);

  const onSubmitHandler = async () => {
    if (!Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit(form);
  };

  return (
    <PartCreateModal
      {...restProps}
      onSubmit={onSubmitHandler}
      isLoadingConfirmButton={isLoadingSubmit}
      title={t("parts.addFuelType") || ""}
      confirmButtonText={t("parts.create") || ""}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.fuel") || ""}
          description={t("parts.fuelEnter") || ""}
          value={form.fuelType}
          error={!form.fuelType && error ? error : ""}
          onChange={(value) =>
            setForm((prev) => ({
              ...prev,
              fuelType: value,
            }))
          }
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={!form.mpv && error ? error : ""}
          onChange={(value) =>
            setForm((prev) => ({
              ...prev,
              mpv: value,
            }))
          }
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
