import { colors } from "@/helpers";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled from "styled-components";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectField = styled(SelectField)<{ color: string }>`
  color: ${({ color }) => (color ? color : colors.gray100)};
  width: 158px;
`;

const StyledTableSimple = styled.div`
  overflow: auto;
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)``;

const StyledSelect = styled.div`
  width: 107px;
`;

const StyledInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMessage = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
`;

const StyledData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledChatCell = styled.div``;

export {
  StyledData,
  StyledInfoBlock,
  StyledMessage,
  StyledSelect,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
};
