import cookie from "js-cookie";
const ACCESS_TOKEN_KEY = "accessToken";

class AccessTokenService {
  getToken() {
    return cookie.get(ACCESS_TOKEN_KEY);
  }

  setToken(token: string, expires: number) {
    cookie.set(ACCESS_TOKEN_KEY, token, { expires: expires / 60 / 60 / 24 });
  }

  deleteToken() {
    cookie.remove(ACCESS_TOKEN_KEY);
  }
}

export const accessTokenService = new AccessTokenService();
