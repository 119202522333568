import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum RegistryFilterFormFieldsNameEnum {
  Name = "name",
  WarrantyCode = "warrantyCode",
  Region = "region",
  City = "city",
  Status = "status",
}

export interface IRegistryFilterFormValues {
  [RegistryFilterFormFieldsNameEnum.Name]: string | undefined;
  [RegistryFilterFormFieldsNameEnum.WarrantyCode]: string | undefined;
  [RegistryFilterFormFieldsNameEnum.Region]: string | undefined;
  [RegistryFilterFormFieldsNameEnum.City]: string | undefined;
  [RegistryFilterFormFieldsNameEnum.Status]: boolean;
}

export const DEFAULT_REGISTRY_FILTER_FORM_VALUES: IRegistryFilterFormValues = {
  [RegistryFilterFormFieldsNameEnum.Name]: undefined,
  [RegistryFilterFormFieldsNameEnum.WarrantyCode]: undefined,
  [RegistryFilterFormFieldsNameEnum.Region]: undefined,
  [RegistryFilterFormFieldsNameEnum.City]: undefined,
  [RegistryFilterFormFieldsNameEnum.Status]: true,
};

export type TOnSubmitRegistryFilterFormCb =
  TOnSubmitFormSyncCb<IRegistryFilterFormValues>;

export const useRegistryFilterForm = (options: {
  onSubmit: TOnSubmitRegistryFilterFormCb;
  initValues: IRegistryFilterFormValues;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<IRegistryFilterFormValues>({
    defaultValues: DEFAULT_REGISTRY_FILTER_FORM_VALUES,
    onSubmit,
  });

  const onClearHandler = () => {
    result.form.reset();
    onSubmit(result.form.getValues());
  };

  useEffect(() => {
    if (initValues) {
      result.form.reset(initValues, { keepDefaultValues: true });
      onSubmit(result.form.getValues());
    }
  }, []);

  return {
    form: result.form,
    submit: result.submit,
    onClearHandler,
  };
};
