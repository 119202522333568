import { Search } from "@/packages/formElements/inputs/search/Search";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { SortOrder } from "@/packages/tableSimple/TableSimple";
import { TCarData } from "@/pages/KiaFlexCars";
import { Space } from "antd";
import React from "react";
import { Headers } from "./Headers";
import { StyledTable, TableHeader } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IKiaFlexCarInfoTableProps {
  headerInfo?: { status: string; count: string }[];
  data?: TCarData[];
  onSort: (accessor: string, order: SortOrder) => void;
  onSearch: (value: string) => void;
  searchValue: string;
}

export const KiaFlexCarInfoTable: React.FC<IKiaFlexCarInfoTableProps> = (
  props
) => {
  const { headerInfo, data, searchValue, onSort, onSearch } = props;
  const { t } = useTranslation();
  const rentCars = headerInfo?.filter((elem) => elem.status === "rent");
  const idleCars = headerInfo?.filter((elem) => elem.status === "idle");
  const pendingCars = headerInfo?.filter((elem) => elem.status === "pending");
  return (
    <>
      <TableHeader>
        <Space direction="vertical">
          <Paragraph size={16}>{t("kiaFlex.activeProgram") || ""}</Paragraph>
          <Paragraph size={12} color="gray400">{`${t("kiaFlex.allCards") || ""} ${
            rentCars?.[0]?.count || 0
          } ${t("kiaFlex.active") || ""}, ${idleCars?.[0]?.count || 0} ${
            t("kiaFlex.downtimeIn") || ""
          }, ${pendingCars?.[0]?.count || 0} ${
            t("kiaFlex.inWaiting") || ""
          } `}</Paragraph>
        </Space>

        <Search
          value={searchValue}
          onChange={(value) => onSearch(value || "")}
        />
      </TableHeader>

      <StyledTable
        variant="secondary"
        headers={Headers()}
        data={data}
        onSort={onSort}
      />
    </>
  );
};
