import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";

import {
  TEditWarrantyViolationsRequest,
  TEditWarrantyViolationsResponse,
  TGetWarrantyViolationsRequest,
  TGetWarrantyViolationsResponse,
  TSendDealerWarrantyViolationsRequest,
  TSendDealerWarrantyViolationsResponse,
} from "./types";

export const useGetWarrantyViolations = (
  query: TGetWarrantyViolationsRequest
) => {
  return useQuery(
    ["violations", query],
    () => {
      return api.get<TGetWarrantyViolationsResponse>("/guarantee/violations", {
        params: query,
      });
    },
    { cacheTime: 0 }
  );
};

export const useEditViolation = () => {
  return useMutation(
    ({
      id,
      data,
      isDealerKia,
      onSuccess,
      onError,
    }: {
      id: string;
      data: TEditWarrantyViolationsRequest;
      isDealerKia: boolean;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TEditWarrantyViolationsResponse>(
        `/guarantee/violations/${id}${isDealerKia ? "" : "/dealer"}`,
        { ...data }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useSendViolationtoDealer = () => {
  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TSendDealerWarrantyViolationsRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TSendDealerWarrantyViolationsResponse>(
        `guarantee/violations/send-to-dealer?auditId=${data.auditId}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useSendViolationtonKia = () => {
  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: TSendDealerWarrantyViolationsRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TSendDealerWarrantyViolationsResponse>(
        `guarantee/violations/send-to-kia?auditId=${data.auditId}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
