import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useFirstSymbolAvatar } from "@/hooks/useFirstSymbolAvatar";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import {
  StyledAvatarAvatar,
  StyledCurrentUserWrapper,
  StyledDealersName,
  StyledNameAndDealerName,
  StyledUsername,
} from "./index.styles";

export type CurrentUserProps = {
  className?: string;
};

export const CurrentUser: React.FC<CurrentUserProps> = (props) => {
  const { className } = props;

  const {
    state: { profile },
  } = useContext(ContextProfile);

  const name = `${profile?.firstName || ""} ${profile?.lastName || ""}`;
  // @ts-ignore
  const imageAvatar = profile?.image?.fileUrl;

  const symbolsAvatar = useFirstSymbolAvatar(profile && name);

  return (
    <Link to="/profile">
      <StyledCurrentUserWrapper className={className}>
        <StyledNameAndDealerName>
          <StyledUsername size={14}>{name || ""}</StyledUsername>
          {profile?.role?.name ? (
            <StyledDealersName size={14}>
              {profile?.role?.name || ""}
            </StyledDealersName>
          ) : (
            <></>
          )}
        </StyledNameAndDealerName>

        <StyledAvatarAvatar  gap={10} size={40} src={imageAvatar}>
          {symbolsAvatar}
        </StyledAvatarAvatar>
      </StyledCurrentUserWrapper>
    </Link>
  );
};
