import { ICountryOption } from "@/packages/phone-input/PhoneInput";
import { getNavItems } from "@/helpers/utils";

export const MONTHS = [
  "date.1",
  "date.2",
  "date.3",
  "date.4",
  "date.5",
  "date.6",
  "date.7",
  "date.8",
  "date.9",
  "date.10",
  "date.11",
  "date.12",
];
export const MONTHS_SELECT = [
  { label: "date.1", value: "Январь" },
  { label: "date.2", value: "Февраль" },
  { label: "date.3", value: "Март" },
  { label: "date.4", value: "Апрель" },
  { label: "date.5", value: "Май" },
  { label: "date.6", value: "Июнь" },
  { label: "date.7", value: "Июль" },
  { label: "date.8", value: "Август" },
  { label: "date.9", value: "Сентябрь" },
  { label: "date.10", value: "Октябрь" },
  { label: "date.11", value: "Ноябрь" },
  { label: "date.12", value: "Декабрь" },
];

export const MONTHS_SELECT_ID = [
  { label: "date.1", value: 0 },
  { label: "date.2", value: 1 },
  { label: "date.3", value: 2 },
  { label: "date.4", value: 3 },
  { label: "date.5", value: 4 },
  { label: "date.6", value: 5 },
  { label: "date.7", value: 6 },
  { label: "date.8", value: 7 },
  { label: "date.9", value: 8 },
  { label: "date.10", value: 9 },
  { label: "date.11", value: 10 },
  { label: "date.12", value: 11 },
];

export const COUNTRIES: ICountryOption[] = [
  {
    icon: "russia-flag",
    code: "+7",
    mask: "(000) 000-00-00",
  },
  {
    icon: "belorussia-flag",
    code: "+375",
    mask: "(00) 000-00-00",
  },
  {
    icon: "armenia-flag",
    code: "+374",
    mask: "(000) 00-00-00",
  },
  {
    icon: "kazakhstan-flag",
    code: "+7",
    mask: "(000) 00-00-00",
  },
  {
    icon: "kyrgyzstan-flag",
    code: "+996",
    mask: "(000) 00-00-00",
  },
  {
    icon: "azerbaijan-flag",
    code: "+994",
    mask: "(000) 00-00-00",
  },
  {
    icon: "tajikistan-flag",
    code: "+992",
    mask: "(000) 00-00-00",
  },
  {
    icon: "uzbekistan-flag",
    code: "+998",
    mask: "(000) 00-00-00",
  },
];

export const NAV_ITEMS = getNavItems;

export const MEDIA = [
  {
    name: "Auto Print",
    slug: "auto-print",
  },
  {
    name: "Auto Online",
    slug: "auto-online",
  },
  {
    name: "Business",
    slug: "business",
  },
  {
    name: "Lifestyle",
    slug: "life-style",
  },
  {
    name: "TV",
    slug: "tv",
  },
  {
    name: "Radio",
    slug: "radio",
  },
  {
    name: "General",
    slug: "general",
  },
  {
    name: "Blogger",
    slug: "blogger",
  },
];
