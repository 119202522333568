import { TCountry } from "@/helpers/api/country/type";
import {
  TDealerBrand,
  TDealerDistrict,
  TDealerFacade,
  TDealerFurniture,
  TDealerGroup,
  TDealerInterior,
  TDealerType,
} from "@/helpers/api/dc/dealers/types";

export enum RegistryDataFormFieldsEnum {
  LawName = "lawName",
  WarrantyCode = "warrantyCode",
  INN = "inn",
  NumDealerContract = "numDealerContract",
  MerchantName = "merchantName",
  SapCode = "sapCode",
  LawAddress = "lawAddress",
  DealerType = "dealerType",
  DealerGroup = "dealerGroup",
  Name = "name", // на латинице
  MobisCode = "mobisCode",
  ShowroomCode = "showroomCode",
  Holding = "holding",
  Region = "region",

  CompanyStartDate = "companyStartDate",
  AddressStartDate = "addressStartDate",
  DealerContractStart = "dealerContractStart",
  DealerContractEnd = "dealerContractEnd",
  CityAmount = "cityAmount",
  Country = "country",
  District = "district",
  City = "city",
  CityName = "cityName",
  EngCity = "engCity",
  Population = "population",
  Address = "address",
  CarStorage = "carStorage",
  DealerEmail = "dealerEmail",
  Phone1 = "phone1",
  Phone2 = "phone2",
  Phone3 = "phone3",
  Phone4 = "phone4",
  IsVerifyDomain = "isVerifyDomain",

  DirectorName = "directorName",
  DirectorLastName = "directorLastName",
  DirectorEmail = "directorEmail",
  SalesManagerName = "salesManagerName",
  SalesManagerLastName = "salesManagerLastName",
  SalesManagerEmail = "salesManagerEmail",
  MarketingManagerName = "marketingManagerName",
  MarketingManagerLastName = "marketingManagerLastName",
  MarketingManagerEmail = "marketingManagerEmail",
  ChiefTechnicalOfficeName = "chiefTechnicalOfficeName",
  ChiefTechnicalOfficeLastName = "chiefTechnicalOfficeLastName",
  ChiefTechnicalOfficeEmail = "chiefTechnicalOfficeEmail",
  ChiefAccountantName = "chiefAccountantName",
  ChiefAccountantLastName = "chiefAccountantLastName",
  ChiefAccountantEmail = "chiefAccountantEmail",

  DealerBrand = "dealerBrand",
  Facade = "facade",
  Interior = "interior",
  Mast = "mast",
  Furniture = "furniture",
  RoundPodium = "roundPodium",
  RoofInstallation = "roofInstallation",
  FurnitureDynamicCube = "furnitureDynamicCube",
  ShowroomArea = "showroomArea",
  LocksmithArea = "locksmithArea",
  WarehouseArea = "warehouseArea",
  WarrantyCurator = "warrantyCurator",
  ZoneDevelopmentManager = "zoneDevelopmentManager",
  ZoneSalesManager = "zoneSalesManager",
  ZoneServiceManager = "zoneServiceManager",
  Domains = 'domains',
}

type TExtendedRegistryDataValues = {
  [RegistryDataFormFieldsEnum.CompanyStartDate]: Date | null;
  [RegistryDataFormFieldsEnum.AddressStartDate]: Date | null;
  [RegistryDataFormFieldsEnum.DealerContractStart]: Date | null;
  [RegistryDataFormFieldsEnum.DealerContractEnd]: Date | null;
  [RegistryDataFormFieldsEnum.CityAmount]: number | null;
  [RegistryDataFormFieldsEnum.Population]: number | null;
  [RegistryDataFormFieldsEnum.Country]: TCountry | null;
  [RegistryDataFormFieldsEnum.District]: TDealerDistrict | null;

  [RegistryDataFormFieldsEnum.IsVerifyDomain]: boolean;
  [RegistryDataFormFieldsEnum.DealerType]: TDealerType;
  [RegistryDataFormFieldsEnum.DealerGroup]: TDealerGroup;

  [RegistryDataFormFieldsEnum.DealerBrand]: TDealerBrand;
  [RegistryDataFormFieldsEnum.Facade]: TDealerFacade;
  [RegistryDataFormFieldsEnum.Interior]: TDealerInterior;
  [RegistryDataFormFieldsEnum.Mast]: boolean;
  [RegistryDataFormFieldsEnum.Furniture]: TDealerFurniture;
  [RegistryDataFormFieldsEnum.RoundPodium]: boolean;
  [RegistryDataFormFieldsEnum.RoofInstallation]: boolean;
  [RegistryDataFormFieldsEnum.FurnitureDynamicCube]: boolean;
  [RegistryDataFormFieldsEnum.ShowroomArea]: number | null;
  [RegistryDataFormFieldsEnum.LocksmithArea]: number | null;
  [RegistryDataFormFieldsEnum.WarehouseArea]: number | null;
  [RegistryDataFormFieldsEnum.Domains]: {domain: string}[];
};

type TRegistryDataFormSelector = Exclude<
  RegistryDataFormFieldsEnum,
  keyof TExtendedRegistryDataValues
>;

export type TRegistryDataFieldsValues = Record<
  TRegistryDataFormSelector,
  string
> &
  TExtendedRegistryDataValues;

export type TRegistryDataFieldsSuccessValues = Omit<
  TRegistryDataFieldsValues,
  | RegistryDataFormFieldsEnum.Country
  | RegistryDataFormFieldsEnum.DealerContractStart
  | RegistryDataFormFieldsEnum.DealerContractEnd
  | RegistryDataFormFieldsEnum.CityAmount
  | RegistryDataFormFieldsEnum.District
> & {
  [RegistryDataFormFieldsEnum.Country]: TCountry;
  [RegistryDataFormFieldsEnum.DealerContractStart]: Date;
  [RegistryDataFormFieldsEnum.DealerContractEnd]: Date;
  [RegistryDataFormFieldsEnum.CityAmount]: number;
  [RegistryDataFormFieldsEnum.District]: TDealerDistrict;
};
