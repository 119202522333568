import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import styled from "styled-components";

export interface IDataInfo {
  size: number;
  error: number;
  successUpload: number;
}
interface IProgressBar {
  year: number;
  quarter: number;
  dataInfo: IDataInfo;
  min: number;
  max: number;
}

const StyledProgressbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 32px;
  background-color: ${colors.white};
  white-space: nowrap;
  border-top: 1px solid ${colors.gray400};
`;

const StyledSize = styled(Paragraph)`
  margin-left: 24px;
`;

const StyledLine = styled.div`
  border-radius: 28px;
  background-color: #eaeaef;
  width: 100%;
  position: relative;
  height: 8px;
  margin: 0 16px;
`;
const StyledGreenLine = styled.div<{ percent: number }>`
  position: absolute;
  border-radius: 28px;
  background-color: #62bb2c;
  border-radius: 28px;
  width: ${({ percent }) => percent}%;
  left: 0;
  top: 0;
  bottom: 0;
  transition: width 0.5s;
`;

export const ProgressBar: React.FC<IProgressBar> = (props) => {
  const { year, quarter, dataInfo, min, max } = props;
  const percent = Math.round((min * 100) / max);
  return percent !== 0 ? (
    <StyledProgressbar>
      <Paragraph
        size={12}
        weight={400}
      >{`Certification Status_KSAP_${year}-${quarter}`}</Paragraph>
      <StyledSize size={12} weight={400}>{`${dataInfo.size} Kb`}</StyledSize>
      <StyledLine>
        <StyledGreenLine percent={percent} />
      </StyledLine>
      <Paragraph size={12} weight={400} color="gray">
        {`${percent} %`}
      </Paragraph>
    </StyledProgressbar>
  ) : null;
};
