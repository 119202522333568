import React, { useEffect } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import {
  ITemplateValues,
  TOnSubmitEditValidationFormCallback,
  useFormValues
} from "@/components/AuditWarranty/Templates/ViolationEditTemplateModal/hooks";
import { t } from "i18next";
import { TCreateWarrantyAuditViolationTemplateResponse } from "@/helpers/api/warranty-audit/templates/types";
import { ViolationModalFields } from "@/components/AuditWarranty/Templates/ViolationModalFields";

export interface ValidationEditModalProps extends Pick<IModal, "onCancel" | "isOpen"> {
  record: TCreateWarrantyAuditViolationTemplateResponse;
  onSubmit: TOnSubmitEditValidationFormCallback;
  templateId: number;
}

export const ViolationTemplateEditModal: React.FC<
  ValidationEditModalProps
> = (props) => {
  const { templateId, onSubmit, record, onCancel } = props;

  const { form, onSubmitHandler } =
    useFormValues(onSubmit);


  useEffect(() => {
    const value: ITemplateValues = {
      templateId,
      id: record?.id,
      code: record?.code,
      description: record?.description,
      pointAmount: record?.pointAmount,
    }

    form.reset(value)
  }, [templateId, record]);

  const handleCancel = () => {
    form.reset();
    onCancel && onCancel();
  };
  return (
    <Modal
      {...props}
      title={t("warrantyAudits.violation.edit") || ""}
      size="small"
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("common.save") || ""}
      onSubmit={() => {
        onSubmitHandler();
        handleCancel();
      }}
      onCancel={handleCancel}
    >
      <ViolationModalFields form={form}/>
    </Modal>
  );
};