import {
  useDownloadTransportClaimsArchive,
} from "@/helpers/api/transportComplaints/hooks";
import { NavigateFunction } from "react-router-dom";
import { StyledButtonText, StyledLink } from "./index.styles";
import { t } from "i18next";
import React from "react";
import { formatDate } from "@/helpers/formatDate";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Space } from "antd";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { Loader } from "@/packages/loader/Loader";
import {
  FileDownload
} from "@/components/TransportComplaints/TransportComplaintsTable/TransportComplaintsTableFileDownload";

interface transportClaimsHeadersProps {
  navigate: NavigateFunction;
}

const transportClaimsHeaders = (props: transportClaimsHeadersProps) => {
  const { navigate } = props;
  return [
    {
      Header: t("complaints.VIN") || "",
      asseccor: "vin",
      width: 200,
      style: { justifyContent: "flex-start" },
      Cell: (data) => {
        const element = data.data[data.row.id];
        return (
          <StyledButtonText
            onClick={() => {
              navigate(`/transport-claims/${element.id}`);
            }}
          >
            {element.vin}
          </StyledButtonText>
        );
      },
    },
    {
      Header: t("complaints.dateAcceptenceStartTable") || "",
      asseccor: "dateAcceptence",
      width: 100,
      maxWidth: 100,
      Cell: (data) => {
        const element = data.data[data.row.id];
        return formatDate(element.dateAcceptence, "dateOnly");
      },
    },
    {
      Header: t("audits.list.dealer") || "",
      asseccor: "merchantName",
      width: 100,
      maxWidth: 100,
      Cell: (data) => {
        const element = data.data[data.row.id];
        return element.dealer?.merchantName;
      },
    },
    {
      Header: t("complaints.codeCase") || "",
      asseccor: "codeCase",
      width: 100,
      maxWidth: 100,
      Cell: (data) => {
        const element = data.data[data.row.id];
        return element.codeCase;
      },
    },
    {
      Header: t("complaints.report") || "",
      asseccor: "report",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "center" },
      Cell: (data) => <FileDownload
        data={data.data[data.row.id]}
        type={'oupts'}
        fileName={`${data.data[data.row.id].vin}-${data.data[data.row.id].codeCase}-ОУПТС`}
        arrayKey={'oupts'}
      />,
    },
    {
      Header: "TTH/CMR",
      asseccor: "tth",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "center" },

      Cell: (data) => <FileDownload
        data={data.data[data.row.id]}
        type={'tth'}
        fileName={`${data.data[data.row.id].vin}-${data.data[data.row.id].codeCase}-TTN/CMR`}
        arrayKey={'tthCmr'}
      />,
    },
    {
      Header: t("complaints.generalView") || "",
      asseccor: "generalView",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "center", textAlign: "center" },
      Cell: (data) => <FileDownload
        data={data.data[data.row.id]}
        type={'generalView'}
        fileName={`${data.data[data.row.id].vin}-${data.data[data.row.id].codeCase}-Общий вид автомобиля`}
        arrayKey={'generalView'}
      />
    },
    {
      Header: "Дата изменения",
      asseccor: "updatedAt",
      width: 100,
      maxWidth: 100,
      isSortable: true,
      Cell: (data) => {
        const element = data.data[data.row.id];
        return formatDate(element.updatedAt, "dateAndTime");
      },
    },
    {
      Header: "",
      id: "actions",
      width: 80,
      maxWidth: 80,
      minWidth: 80,
      Cell: (data) => {
        const element = data.data[data.row.id];
        const { handleDownload, isLoading, isArchiveNotEmpty } = useDownloadTransportClaimsArchive();

        return (
          <Space>
            {
              isArchiveNotEmpty(data.data[data.row.id]) &&
              <>
                {
                  !isLoading ?
                    <Tooltip
                      content={t("monitoring.downloadAll") || ""}
                      placement="top"
                    >
                      <StyledLink>
                        <IconButton
                          icon="to-archive"
                          size="l"
                          variant="tertiary"
                          onClick={() => handleDownload(
                            data.data[data.row.id].id,
                            'all',
                            `${data.data[data.row.id].vin}-${data.data[data.row.id].codeCase}`
                          )}
                        />
                      </StyledLink>
                    </Tooltip>
                    :
                    <Loader/>
                }
              </>
            }
            <PermissionsAdapter
              resourceName="transport_claims"
              resourceAction="update"
            >
              <Tooltip content={t("monitoring.edit") || ""} placement="top">
                <StyledLink>
                  <IconButton
                    icon="edit"
                    color="black"
                    size="l"
                    variant="tertiary"
                    onClick={() => {
                      navigate(`/transport-claims/edit/${element.id}`);
                    }}
                  />
                </StyledLink>
              </Tooltip>
            </PermissionsAdapter>
          </Space>
        );
      },
    },
  ];
};

export { transportClaimsHeaders };
