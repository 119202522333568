import styled from "styled-components";

import { Modal } from "@/packages/modal/Modal";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";

export const StyledModal = styled(Modal)`
  background-color: black;
  width: 100%;
`;

export const StyledModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
`;

export const StyledTextAreaField = styled(FormTextAreaField)`
  resize: none;
  height: 32px !important;
`;
