import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { useGetProfile } from "@/helpers/api/profile/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useGetTransportClaimsList } from "@/helpers/api/transportComplaints/hooks";
import { Loader } from "@/packages/loader/Loader";
import { StyledLoaderWrapper } from "@/pages/TransportComplaintsLink/index.styles";
import { holdingRoles } from "@/components/TransportComplaints/TransportComplaintsFilter";

const TransportComplaintsLink: React.FC = () => {
  const navigate = useNavigate();
  const { data: profileData, isLoading } = useGetProfile();
  const { createNotificationError } = useNotification();

  const [prefillQueryParams] = useQueryParams({
    p: StringParam, // код диллера
    d: StringParam, // дата
    v: StringParam, // VIN
    c: StringParam, // код случай
  });

  const {
    data: transportClaimsList,
  } = useGetTransportClaimsList({
    dealerSapCode: prefillQueryParams.p ?? undefined,
    vin: prefillQueryParams.v ?? undefined,
    codeCase: prefillQueryParams.c ?? undefined,
    dateAcceptanceStart: prefillQueryParams.d ? new Date(prefillQueryParams.d) : undefined,
    dateAcceptanceEnd: prefillQueryParams.d ? new Date(prefillQueryParams.d) : undefined,
  });

  useEffect(() => {
    if (prefillQueryParams.d) {
      const date = Date.parse(prefillQueryParams.d)
      if (isNaN(date)) {
        navigate('/transport-claims');
        createNotificationError('Неверный формат даты');
      }
    }

    if (!prefillQueryParams.p || !prefillQueryParams.v || !prefillQueryParams.c) {
      navigate('/transport-claims');
      createNotificationError('Доступ запрещен');
    }
  }, [prefillQueryParams])

  useEffect(() => {
    const dealer = profileData?.data.dealer;
    if (
      profileData?.data.type === "kia" ||
      profileData?.data?.role?.slug === 'cis-distributor' ||
      profileData?.data?.role?.slug === 'super-admin' ||
      profileData?.data?.role?.slug === 'kia-admin' ||
      holdingRoles.includes(profileData?.data?.role?.slug ?? '')
    ) {
      return;
    }
    if (
      (dealer && prefillQueryParams.p !== dealer.sapCode) ||
      (dealer === null)
    ) {
      navigate('/transport-claims');
      createNotificationError('Доступ запрещен');
    }
  }, [profileData?.data.dealer]);

  useEffect(() => {
    //@ts-ignore
    if (transportClaimsList?.data.meta?.itemCount > 0) {
      //@ts-ignore
      navigate(`/transport-claims/edit/${transportClaimsList?.data?.data[0].id}&goBack`)
    } else if (transportClaimsList?.data) {
      if (profileData?.data?.role?.slug === 'cis-distributor') {
        navigate('/transport-claims')
      } else {
        navigate(`/transport-claims/create?p=${prefillQueryParams.p}&d=${prefillQueryParams.d}&v=${prefillQueryParams.v}&c=${prefillQueryParams.c}`);
      }
    }
  }, [transportClaimsList])

  return (<StyledLoaderWrapper>
    {
      isLoading &&
      <Loader size={64} isCentered/>
    }
  </StyledLoaderWrapper>);
};

export { TransportComplaintsLink };
