import { TableSimple } from "@/packages/tableSimple/TableSimple";
import Dropdown from "antd/lib/dropdown";
import styled from "styled-components";

export const StyledTable = styled(TableSimple)`
  margin-bottom: 64px;
  border-radius: 12px;
`;

export const StyledDropdownButton = styled(Dropdown.Button)`
  button {
    border: none;
  }
`;
