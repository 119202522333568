import styled from "styled-components";
import { colors } from "@/helpers";

export const StyledCalendarTable = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: var(--left-column-width) var(--right-column-width);

  background-color: ${colors.background};
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  --left-column-width: 408px;
  --right-column-width: 1fr;
  --padding-left: 14.5px;
  --margin-bottom: 12px;
`;

export const StyledTableColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTableHeadLeftWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  padding-left: var(--padding-left);

  background-color: ${colors.gray30};
`;

export const StyledInfoBlockList = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

export const StyledInfoBlockItem = styled.li`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 330px;
  align-items: center;
  padding-left: var(--padding-left);

  &:not(:last-of-type) {
    margin-bottom: var(--margin-bottom);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: ${colors.gray500};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`;

export const StyledInfoBlockSubList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoBlockSubItem = styled.li`
  box-sizing: border-box;
  padding: 7px 8.5px;

  &:nth-of-type(2n) {
    background-color: ${colors.gray40};
  }
`;

export const StyledTableColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${colors.white};

  overflow-y: initial;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTableHeadRightList = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
`;

export const StyledTableHeadRightItem = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: repeat(2, 37px);
  align-items: center;
  width: auto;

  background-color: ${colors.gray30};
  text-align: center;
`;

export const StyledDaysInfoList = styled.ul`
  display: flex;
  height: 100%;
`;

export const StyledDaysInfoItem = styled.li`
  display: inline-flex;
  width: 20px;
  justify-content: center;
  align-items: center;
`;

export const StyledDaysBlockList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledDaysItem = styled.li`
  position: relative;
  width: 20px;
  height: 30px;
  border-right: 1px solid ${colors.gray500};
  border-bottom: 1px solid ${colors.gray500};
`;

export const StyledDaysBlockSubItem = styled.li`
  display: grid;

  &:first-of-type ${StyledDaysItem} {
    border-top: 1px solid ${colors.gray500};
  }
`;

export const StyledDaysBlockItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: var(--margin-bottom);
  }

  &:first-of-type {
    ${StyledDaysBlockSubItem}:first-of-type ${StyledDaysItem} {
      &::before {
        box-sizing: border-box;
        position: absolute;
        content: "";
        z-index: 0;
        top: -75px;
        left: 0;
        right: 0;
        width: 20px;
        height: 74px;
        background-color: ${colors.gray30};
      }
    }
  }
`;

export const StyledDaysBlockSubList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledDaysList = styled.ul`
  display: flex;
`;
