import React from "react";

import { RegistryDataFields } from "@/components/RegistryDataFields";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { Button } from "@/packages/button/Button";
import { Space } from "antd";
import { FormProvider } from "react-hook-form";
import { StyledRegistryTableExpandDataForm } from "./index.styled";
import {
  TOnSubmitRegistryEditFormCb,
  useRegistryEditDataForm,
} from "@/components/RegistryDataFields/hooks";
import { useNotification } from "@/hooks/useNotification";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useProfile } from "@/hooks/contexts/useProfile";

export interface IRegistryTableExpandDataFormProps {
  dealer: TDealerRecord;
  onSubmit: TOnSubmitRegistryEditFormCb;
  isDisabled?: boolean;
}

export const RegistryTableExpandDataForm: React.FC<
  IRegistryTableExpandDataFormProps
> = ({ dealer, onSubmit, isDisabled }) => {
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const {
    state: { profile },
  } = useProfile();

  const onSubmitHandler: TOnSubmitRegistryEditFormCb = async (values) => {
    try {
      await onSubmit(values);
      createNotificationSuccess(t("common.success") || "");
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  const {
    form,
    submit,
    isSubmitLoading: isSumbitLoading,
    reset,
  } = useRegistryEditDataForm(
    {
      ...dealer,
      dealerManager: {
        ...dealer.dealerManager,
        // @ts-ignore
        holding:
          profile?.type === "dealer"
            ? dealer?.holding?.name
            : dealer?.holding?.id,
      },
      // @ts-ignore
      files: dealer.files.map((file) => file.id),
    },
    onSubmitHandler,
    isDisabled
  );

  return (
    <StyledRegistryTableExpandDataForm>
      <FormProvider {...form}>
        <RegistryDataFields isReadOnly={isDisabled} />
      </FormProvider>
      {!isDisabled ? (
        <Space>
          <Button
            theme="primary"
            color="brand"
            size="middle"
            onClick={submit}
            // isDisabled={!form.formState.isDirty}
            isLoading={isSumbitLoading}
          >
            {t("models.logsModel.save") || ""}
          </Button>
          <Button
            theme="secondary"
            color="brand"
            size="middle"
            onClick={reset}
            isDisabled={!form.formState.isDirty}
          >
            {t("common.cancel") || ""}
          </Button>
        </Space>
      ) : (
        <></>
      )}
    </StyledRegistryTableExpandDataForm>
  );
};
