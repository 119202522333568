import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { TOnSubmitKiaFlexForm } from "../NewCarForm/hooks";
import { useRegisterVehiclesForm } from "../RegisterForm/hooks";
import { RegistriFields } from "../RegistriFields";
import { useTranslation } from "react-i18next";

export interface IRegistriModalModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitKiaFlexForm;
  record?: TFlexOrder & { ID: string };
}

const RegisterModal: React.FC<IRegistriModalModal> = (props) => {
  const { onSubmit, record, ...rest } = props;

  const { t } = useTranslation();

  const {
    state: { profile },
  } = useProfile();

  const isDealer = profile?.type === "dealer";

  const { form, submit } = useRegisterVehiclesForm({
    onSubmit,
    editableRecord: record,
  });

  useEffect(() => {
    if (!rest.isOpen) {
      form.reset();
    }
  }, [form, rest.isOpen]);

  return (
    <Modal
      {...rest}
      size="medium"
      confirmButtonText={t("kiaFlex.addNewCar") || ""}
      cancelButtonText={t("audits.list.cancel") || ""}
      onSubmit={submit}
      showConfirmButton={isDealer}
    >
      <FormProvider {...form}>
        <RegistriFields
          form={form}
          mode="create"
          record={record}
          isDisabled={!isDealer}
        />
      </FormProvider>
    </Modal>
  );
};
export default RegisterModal;
