import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";

export type LoginFormValues = {
  vin: string;
};

const DEFAULT_LOGIN_FORM_VALUES: LoginFormValues = {
  vin: "",
};

export const formSchema = () => {
  return yup.object({
    vin: yup
      .string()
      .required(t("park.validation.vinRequired") || "")
      .min(17, t("kiaFlex.vin_17") || "")
      .max(17, t("kiaFlex.vin_17") || "")
      .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
      .uppercase()
      .nullable(),
  });
};

export type TOnSubmitLoginFormCb = (data: LoginFormValues) => Promise<void>;

export const useVinDecoderForm = (onSubmit: any) => {
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_LOGIN_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    isSubmitLoading,
    onSubmitHandler,
  };
};
