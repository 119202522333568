import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IUploadButton, UploadButton } from "@/packages/UploadButton";
import React from "react";
import {
  StyledHeader,
  StyledImagesContainer,
  StyledPhotosTab,
} from "./index.styled";
import { PhotoCard } from "./PhotoCard";
import { useTranslation } from "react-i18next";

export interface IDetailRegistryPhotosTab {
  dealer: TDealerRecord;
  onUploadFile: IUploadButton["onUploadFile"];
  isDisabled?: boolean;
}
export const DetailRegistryPhotosTab: React.FC<IDetailRegistryPhotosTab> = ({
  dealer,
  onUploadFile,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPhotosTab>
      <StyledHeader>
        <Paragraph color="gray200" size={16}>
          {dealer.files?.length > 0
            ? `${dealer.files?.length} ${t("common.entries") || ""}`
            : t("common.noPhoto") || ""}
        </Paragraph>
        <UploadButton
          path="dealers/photos"
          theme="primary"
          color="brand"
          size="middle"
          onUploadFile={onUploadFile}
          isDisabled={isDisabled}
        >
          {t("registers.registersDC.addNewPhoto") || ""}
        </UploadButton>
      </StyledHeader>

      <StyledImagesContainer>
        {dealer.files?.map((file) => {
          return (
            <PhotoCard
              url={file.fileUrl}
              fileName={file.fileName}
              fileSize={file.size}
              format={file.extension}
            />
          );
        })}
      </StyledImagesContainer>
    </StyledPhotosTab>
  );
};
