import {
  useDeleteCoefficient,
  usePatchCoefficient,
  usePatchNonDnmCoefficient,
} from "@/helpers/api/bonuses/bonuses-calculation/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { FC, useState } from "react";
import { CoefficientModal } from "../CoefficientModal";
import { ICoefficientFormValues } from "../CoefficientModal/CoefficientForm/hooks";
import { ConfirmModal } from "./ConfirmModal";
import {
  StyledCloseIcon,
  StyledEditIcon,
  StyledValue,
  StyledValueContainer,
} from "./index.styles";
import { useTranslation } from "react-i18next";

interface ICoefficientValues {
  //TODO
  coefficient: any;
  prevCoefficientPercent?: number;
  isNonDnm?: boolean;
  moduleTitle: string;
  inputTitle1: string;
  inputTitle2: string;
}

export const CoefficientValues: FC<ICoefficientValues> = (props) => {
  const {
    coefficient,
    prevCoefficientPercent,
    isNonDnm,
    moduleTitle,
    inputTitle1,
    inputTitle2,
  } = props;
  const [modal, setModal] = useState<boolean>(false);
  const percent = coefficient?.percent;
  const coefficientValue = coefficient.coefficientValue;

  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();
  const { mutateAsync: updateCoefficient } = usePatchCoefficient();
  const { mutateAsync: deleteCoefficient } = useDeleteCoefficient();

  const { mutateAsync: updateNonDnmCoefficient } = usePatchNonDnmCoefficient();

  const {
    modalState: openConfirmDeleteModalState,
    openModal: openConfirmDeleteModal,
    closeModal,
  } = useModal();

  const openModal = openConfirmDeleteModal as (state: any) => void;

  const handleDeleteCoefficient = async () => {
    try {
      await deleteCoefficient(coefficient.id);
      createNotificationSuccess(t("common.success") || "");
    } catch (error) {
      createNotificationError(
        //@ts-ignore
        (Array.isArray(error.response?.data?.message) &&
          //@ts-ignore
          error.response?.data?.message[0]) ||
          //@ts-ignore
          error.response?.data?.message
      );
      console.log(error);
    }
  };

  const handleDeleteCoefficientWithConfirm = () => {
    openModal({
      textMessage: t("bonuses.reportsView.deleteConfirm") || "",
      errorMessage: t("common.error") || "",
      onAsyncSubmit: () => handleDeleteCoefficient(),
      onCloseModal: () => closeModal(),
    });
  };

  const handleFormSubmit = async (data: ICoefficientFormValues) => {
    const { percent, coefficientValue } = data;

    const dataToServer = {
      percent: percent ? Number(percent) : null,
      coefficientValue: Number(coefficientValue),
    };

    const nonDnmDataToServer = {
      coefficientValue: Number(coefficientValue),
    };

    try {
      if (isNonDnm) {
        await updateNonDnmCoefficient({
          id: coefficient.id,
          //@ts-ignore TODO
          patch: nonDnmDataToServer,
        });
      } else {
        await updateCoefficient({
          id: coefficient.id,
          //@ts-ignore TODO
          patch: dataToServer,
        });
      }
      setModal(false);
      createNotificationSuccess(t("common.success") || "");
    } catch (error) {
      createNotificationError(
        //@ts-ignore
        (Array.isArray(error.response?.data?.message) &&
          //@ts-ignore
          error.response?.data?.message[0]) ||
          //@ts-ignore
          error.response?.data?.message
      );
      console.log(error);
    }
  };

  return (
    <StyledValueContainer>
      {!isNonDnm ? (
        <StyledValue>
          {percent ? `≥${percent}%` : `<${prevCoefficientPercent}`}
        </StyledValue>
      ) : null}
      <StyledValue>
        {coefficientValue}
        <StyledEditIcon
          size="l"
          icon="edit"
          color="black"
          variant="tertiary"
          onClick={() => setModal(true)}
        />
        {!isNonDnm && !prevCoefficientPercent ? (
          <StyledCloseIcon
            size="l"
            icon="close-16"
            color="black"
            variant="tertiary"
            onClick={handleDeleteCoefficientWithConfirm}
          />
        ) : null}
      </StyledValue>
      <CoefficientModal
        isOpen={modal}
        onClose={() => setModal(false)}
        onCancel={() => setModal(false)}
        //@ts-ignore
        onSubmit={handleFormSubmit}
        moduleTitle={moduleTitle}
        inputTitle1={inputTitle1}
        inputTitle2={inputTitle2}
        defaultValues={{
          percent: percent,
          coefficientValue: coefficientValue,
        }}
        prevCoefficientPercent={prevCoefficientPercent}
        isNonDnm={isNonDnm}
      />
      <ConfirmModal {...openConfirmDeleteModalState} />
    </StyledValueContainer>
  );
};
