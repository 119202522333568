import { Button } from "@/packages/button/Button";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledModalContentMessage = styled(Paragraph)`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  padding: 4px 35px;
`;
