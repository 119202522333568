import React from "react";

import {
  ISelectField,
  SelectField,
} from "@/packages/formElements/fields/SelectField";
import { Icon } from "@/packages/icon/Icon";
import { Space } from "antd";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { TCountry } from "@/helpers/api/country/type";
import { t } from "i18next";

interface ICountrySelectOption extends ISelectOption<TCountry> {
  icon: string;
}

export const COUNTRY_SELECT_OPTIONS: ICountrySelectOption[] = [
  {
    value: "Armenia",
    label: "country.armenia",
    icon: "armenia-flag",
  },
  {
    value: "Azerbaijan",
    label: "country.azerbaijan",
    icon: "azerbaijan-flag",
  },
  {
    value: "Belarus",
    label: "country.belarus",
    icon: "belorussia-flag",
  },
  {
    value: "Kazakhstan",
    label: "country.kazakhstan",
    icon: "kazakhstan-flag",
  },
  {
    value: "Kyrgyzstan",
    label: "country.kyrgyzstan",
    icon: "kyrgyzstan-flag",
  },
  {
    value: "Russia",
    label: "country.russia",
    icon: "russia-flag",
  },
  {
    value: "Tajikistan",
    label: "country.tajikistan",
    icon: "tajikistan-flag",
  },
  {
    value: "Uzbekistan",
    label: "country.uzbekistan",
    icon: "uzbekistan-flag",
  },
];

export interface ICountrySelect extends Omit<ISelectField, "options"> {}

const renderOption = (option: ISelectOption) => {
  return (
    <Space align="center">
      <Icon name={option.icon} size={16} /> {t(option.label)}
    </Space>
  );
};

export const CountrySelect: React.FC<ICountrySelect> = (props) => {
  return (
    <SelectField
      options={COUNTRY_SELECT_OPTIONS}
      renderOption={renderOption}
      {...props}
    />
  );
};
