import React, { useEffect, useState } from "react";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { KiaFlexModal } from "@/components/KiaFlex/KiaFlexCar/KiaFlexModal";
import { KiaFlexCarArchiveTable } from "@/components/KiaFlex/KiaFlexCar/Tables/KiaFlexCarArchiveTable";
import { KiaFlexCarInfoTable } from "@/components/KiaFlex/KiaFlexCar/Tables/KiaFlexCarInfo";
import { KiaFlexOrdersTable } from "@/components/KiaFlex/KiaFlexCar/Tables/KiaFlexOrdersTable";
import { KiaFlexQuotasTable } from "@/components/KiaFlex/KiaFlexCar/Tables/KiaFlexQuotasTable";
import {
  useExportFlexOrders,
  useGetFlexOrdersList,
  useGetFlexOrdersQuotasList,
} from "@/helpers/api/flex/orders/hooks";
import {
  useExportFlexArchivedVehicles,
  useExportFlexVehicles,
  useGetFlexArchivesList,
  useGetFlexList,
  useGetFlexWithStatusList,
} from "@/helpers/api/flex/vehicles/hooks";
import { TGetFlexResponseById } from "@/helpers/api/flex/vehicles/types";
import { handleFileLoad } from "@/helpers/utils";
import { useProfile } from "@/hooks/contexts/useProfile";

import { useActiveTab } from "@/hooks/useActiveTab";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useModal } from "@/hooks/useModal";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { SortOrder } from "@/packages/tableSimple/TableSimple";
import { ITab } from "@/packages/tabs/Tabs";
import { Space } from "antd";
import { StyledSpace, StyledTabs } from "./index.styles";
import { useTranslation } from "react-i18next";
import { HeaderArgType } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Loader } from "@/packages/loader/Loader";

export interface TCarData {
  name: string;
  child: TGetFlexResponseById[];
}

export const KiaFlexCars: React.FC = () => {
  const { t } = useTranslation();
  const [requestParams, setRequestParams] = useState<any>({});
  const [activeQuery, setActiveQuery] = useState<ITab["slug"]>();
  const [searchValue, setSearchValue] = useState("");

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const { modalState, closeModal, openModal } = useModal();

  const { mutateAsync: exportFlexVehicles } = useExportFlexVehicles();
  const { mutateAsync: exportFlexArchivedVehicles } =
    useExportFlexArchivedVehicles();
  const { mutateAsync: exportFlexOrders } = useExportFlexOrders();

  const { data: flexList } = useGetFlexList(
    {
      query: searchValue,
      sortAt: requestParams.orderBy,
      sortBy: requestParams.sortBy,
    },
    activeQuery === "car-info"
  );

  const { data: flexListArchived } = useGetFlexArchivesList(
    {
      query: searchValue,
      sortAt: requestParams.orderBy,
      sortBy: requestParams.sortBy,
    },
    activeQuery === "car-archive"
  );

  const { data: statusSumResponse } = useGetFlexWithStatusList({});

  const { data: ordersList } = useGetFlexOrdersList(
    {
      modelName: searchValue,
      sortAt: requestParams.orderBy,
      sortBy: requestParams.sortBy,
    },
    activeQuery === "orders"
  );

  const { data: quotasList } = useGetFlexOrdersQuotasList(
    {},
    activeQuery === "orders"
  );

  const carInfoData = flexList?.data.data.reduce((acc, item) => {
    // @ts-ignore
    acc.push({ name: `${item.name} ${item.mobisCode}`, child: item.vehicles });
    return acc;
  }, [] as TCarData[]);

  // @ts-ignore
  const carInfoDataArchived = flexListArchived?.data.data.reduce(
    (acc, item) => {
      acc.push({
        name: `${item.name} ${item.mobisCode}`,
        child: item.vehicles,
      });
      return acc;
    },
    [] as TCarData[]
  );

  const handleSort = (accessor: string | HeaderArgType, order: SortOrder) => {
    accessor = typeof accessor === "string" ? accessor : accessor.id;

    setRequestParams((prev) => ({
      ...prev,
      orderBy: order ? order.toUpperCase() : null,
      sortBy: order ? accessor : null,
    }));
  };

  const {
    state: { profile },
  } = useProfile();

  const handleFlexVehiclesExport = () => {
    if (activeTab.slug === "car-archive") {
      exportFlexArchivedVehicles(
        {
          // vin: vin ? vin : undefined,
          // number: number ? number : undefined,
          query: searchValue,
          sortAt: requestParams.orderBy,
          sortBy: requestParams.sortBy,
        },
        {
          onSuccess: (response) => {
            handleFileLoad(response.data as Blob, "flex-archived-vehicles.xlsx");
          },
        }
      );
    } else if (activeTab.slug === "orders") {
      exportFlexOrders(
        {
          // vin: vin ? vin : undefined,
          // number: number ? number : undefined,
        },
        {
          onSuccess: (response) => {
            handleFileLoad(response.data as Blob, "flex-orders.xlsx");
          },
        }
      );
    } else {
      exportFlexVehicles(
        {
          // vin: vin ? vin : undefined,
          sortAt: requestParams.orderBy,
          sortBy: requestParams.sortBy,
        },
        {
          onSuccess: (response) => {
            handleFileLoad(response.data as Blob, "flex-vehicles.xlsx");
          },
        }
      );
    }
  };

  const tabs: ITab[] = [
    {
      name: t("kiaFlex.autoInfo") || "",
      slug: "car-info",
      content: (
        <>
          {statusSumResponse && (
            <KiaFlexCarInfoTable
              searchValue={searchValue}
              data={carInfoData}
              //@ts-ignore
              headerInfo={statusSumResponse?.data?.data}
              onSort={handleSort}
              onSearch={setSearchValue}
            />
          )}
        </>
      ),
    },
    {
      name: t("kiaFlex.orders") || "",
      slug: "orders",
      content: (
        <StyledSpace direction="vertical" size={50}>
          <KiaFlexOrdersTable
            searchValue={searchValue}
            data={ordersList?.data?.data || []}
            onSearch={setSearchValue}
            onSort={handleSort}
          />

          {profile?.type === "dealer" && quotasList && (
            <KiaFlexQuotasTable
              // @ts-ignore
              data={quotasList?.data.data || []}
            />
          )}
        </StyledSpace>
      ),
    },
    {
      name: t("kiaFlex.carArchive") || "",
      slug: "car-archive",

      content: (
        <>
          {statusSumResponse && (
            <KiaFlexCarArchiveTable
              searchValue={searchValue}
              data={carInfoDataArchived}
              //@ts-ignore
              headerInfo={statusSumResponse?.data?.data}
              onSort={handleSort}
              onSearch={setSearchValue}
            />
          )}
        </>
      ),
    },
  ];
  const { activeTab, setActiveTab } = useActiveTab(tabs);

  useEffect(() => {
    setActiveQuery(activeTab.slug);
    setRequestParams({});
    setSearchValue("");
  }, [activeTab.slug]);

  return (
    <DashboardLayout
      title={t("kiaFlex.subTitle_4") || ""}
      description={t("kiaFlex.subTitle_4Descr") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleFlexVehiclesExport}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
          <PermissionsAdapter
            resourceName="flex.vehicle"
            resourceAction="create"
          >
            <Button
              color="brand"
              size="middle"
              theme="primary"
              htmlType="submit"
              onClick={openModal}
            >
              {t("park.addNewCar") || ""}
            </Button>
          </PermissionsAdapter>
        </Space>
      }
    >
      <PermissionsAdapter
        resourceName="flex.vehicle"
        resourceAction={"read"}
        fallback={() => <div>No access</div>}
        loader={<Loader size={48} />}
      >
        <StyledTabs
          tabs={tabs}
          activeTab={activeTab}
          onChange={setActiveTab}
          type="card"
        />
        <KiaFlexModal isOpen={modalState.isOpen} closeModal={closeModal} />
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
