import React, { FC } from "react";
import { StyledModal } from "@/pages/VinDecoder/index.styles";
import { useTranslation } from "react-i18next";
import {
  StyledFormEditBatchModal,
  StyledModalFields,
} from "@/components/Materials/MaterialsEditBatchModal/index.styles";
import { StyledParagraph, StyledWrapper } from "./index.styles";

export type VinHistoryType = {
  code: string;
  dealerCode: string;
  dateOpen: string;
  status: string;
  tag: string;
  totalSum: number;
  nameOfWorks: string;
  recommendations: string;
};

type PropsType = {
  onCancel: () => void;
  isOpen: boolean;
  data: VinHistoryType | null;
};

export const VinDecoderHistoryModel: FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const { onCancel, isOpen, data, ...rest } = props;

  return (
    <StyledModal
      title={`Описание работ № ${data?.code}`}
      size="medium"
      isOpen={isOpen}
      cancelButtonText={t("profile.settings.close") || ""}
      onCancel={() => onCancel && onCancel()}
    >
      <StyledModalFields>
        <StyledFormEditBatchModal>
          <StyledWrapper>
            <StyledParagraph size={16} weight={700} tag={"p"}>
              Наименование работ:
            </StyledParagraph>
            <StyledParagraph size={16} weight={600} tag={"p"}>
              {` ${data?.nameOfWorks}`}
            </StyledParagraph>
          </StyledWrapper>
          <StyledWrapper>
            <StyledParagraph size={16} weight={700}>
              Рекомендации ДЦ:
            </StyledParagraph>
            <StyledParagraph size={16} weight={600}>
              {` ${data?.recommendations}`}
            </StyledParagraph>
          </StyledWrapper>
        </StyledFormEditBatchModal>
      </StyledModalFields>
    </StyledModal>
  );
};
