import { DeletePopconfirm } from "@/packages/DeletePopconfirm";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import {
  HeaderArgType,
  IHeaderItem,
  SortOrder,
} from "@/packages/tableSimple/TableSimple";
import { PaginationProps, Space } from "antd";
import { t } from "i18next";

export interface IDefaultDictTab<TRecord> {
  currentPage: PaginationProps["current"];
  onChangePage: PaginationProps["onChange"];
  totalItems: PaginationProps["total"];
  items: TRecord[];

  onEditClick: (record: TRecord) => void;
  onDeleteClick: (record: TRecord) => void;
  onSort: (header: HeaderArgType, order: SortOrder) => void;
  handleSearch?: (key: string, value?: string) => void;
}

export const getDefaultDictTableHeaders = <TRecord extends object>(options: {
  onEditClick: (record: TRecord) => void;
  onDeleteClick: (record: TRecord) => void;
  nameKey?: keyof TRecord;
  isSearchable?: boolean;
  isSortable?: boolean;
}): IHeaderItem<TRecord>[] => {
  const {
    onDeleteClick,
    onEditClick,
    nameKey = "name",
    isSearchable = false,
    isSortable = false,
  } = options;

  return [
    {
      Header: t("parts.table.name") || "",
      accessor: nameKey.toString(),
      isSearchable: isSearchable,
      isSortable: isSortable,
      width: "250px",
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: t("parts.table.mpv") || "",
      accessor: "mpv",
      isSearchable: false,
      isSortable: true,
      width: "250px",
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: t("parts.table.models") || "",
      id: "modelName",
      accessor: "models",

      Cell: ({ row: { original } }) => {
        return (
          <Paragraph weight={300} size={12}>
            {/* @ts-ignore */}
            {original?.modelName?.join(", ")}
          </Paragraph>
        );
      },
    },

    {
      Header: "",
      id: "actions",
      width: "50px",
      minWidth: 50,
      maxWidth: 50,

      Cell: ({ row: { original } }) => {
        return (
          <Space>
            <DeletePopconfirm onConfirm={() => onDeleteClick(original)}>
              <IconButton
                icon="delete"
                color="black"
                size="l"
                variant="tertiary"
              />
            </DeletePopconfirm>

            <IconButton
              icon="edit"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => onEditClick(original)}
            />
          </Space>
        );
      },
    },
  ];
};
