import { FC } from "react";
import { Pagination as AntPagination, PaginationProps } from "antd";
import styled from "styled-components";
import { colors } from "@/helpers";

const StyledPagination = styled(AntPagination)`
  .ant-pagination-item {
    &:hover,
    &-active {
      border-color: ${colors.brand};

      a {
        color: ${colors.brand};
      }
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    &:hover {
      .ant-pagination-item-link {
        color: ${colors.brand};
        border-color: ${colors.brand};
      }
    }
  }
`;

const Pagination: FC<PaginationProps> = (props) => {
  return <StyledPagination {...props} showSizeChanger={false} />;
};

export { Pagination };
