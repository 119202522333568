import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { accessTokenService } from "./accessTokenService";
import { refreshTokenService } from "./refreshTokenService";

export const ApiUrl =
  process.env.REACT_APP_BASE_URL ??
  "https://master-kia-dms-platform.kia.outside.dev/api";

const api = axios.create({
  baseURL: ApiUrl,
});

const updateTooken = () => {
  api
    .post("/auth/refresh-token", {
      refreshToken: refreshTokenService.getToken(),
    })
    .then((response) => {
      const { accessToken, refreshToken, refreshExpiresIn, expiresIn } =
        response.data;
      accessTokenService.setToken(accessToken, expiresIn);
      refreshTokenService.setToken(refreshToken, refreshExpiresIn);
    });
};

const apiRequestInterceptor = (config: AxiosRequestConfig<any>) => {
  const newConfig = { ...config };
  const accessToken = accessTokenService.getToken();
  const refreshToken = refreshTokenService.getToken();
  // if (!accessToken && refreshToken) {
  //   updateTooken();
  // }

  if (accessToken) {
    const newConfigHeaders: AxiosRequestHeaders = {
      ...(newConfig.headers ?? {}),
      Authorization: `Bearer ${accessToken}`,
    };

    newConfig.headers = newConfigHeaders;
  }

  return newConfig;
};

const apiResponceErrorInterceptor = async (error) => {
  if (error.response?.status === 401) {
    accessTokenService.deleteToken();
    updateTooken();
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(apiRequestInterceptor);
api.interceptors.response.use((config) => config, apiResponceErrorInterceptor);

export { api };
