import React from "react";
import { Space } from "antd";

import { Button } from "@/packages/button/Button";
import { FormProvider } from "react-hook-form";
import { RegistryDataFields } from "../../RegistryDataFields";
import { TOnSubmitRegistryCreateFormCb, useRegistryCreateForm } from "./hooks";
import { StyledDataTab } from "./index.styled";
import { useTranslation } from "react-i18next";

export interface IRegistryCreateDataTab {
  onCreate: TOnSubmitRegistryCreateFormCb;
}
export const RegistryCreateDataTab: React.FC<IRegistryCreateDataTab> = ({
  onCreate,
}) => {
  const { form, submit, isSubmitLoading, reset } =
    useRegistryCreateForm(onCreate);
  const { t } = useTranslation();
  return (
    <StyledDataTab>
      <FormProvider {...form}>
        <RegistryDataFields />
      </FormProvider>
      <Space>
        <Button
          theme="primary"
          color="brand"
          size="middle"
          onClick={submit}
          // isDisabled={!form.formState.isDirty}
          isLoading={isSubmitLoading}
        >
          {t("registers.holdingsDC.save") || ""}
        </Button>
        <Button
          theme="secondary"
          color="brand"
          size="middle"
          onClick={reset}
          isDisabled={!form.formState.isDirty}
        >
          {t("registers.holdingsDC.cancel") || ""}
        </Button>
      </Space>
    </StyledDataTab>
  );
};
