import { IdentityFormCard } from '@/components/IdentityFormCard';
import { FormCheckbox } from '@/packages/formElements/formFields/FormCheckbox';
import { FormInputField } from '@/packages/formElements/formFields/FormInputField';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TOnSubmitLoginFormCb, useLoginForm } from './hooks';
import {
    StyledFormLayout,
    StyledLoginFormForgetPassword,
    StyledLoginFormOther,
    StyledLoginFormOtherLink,
    StyledLoginFormSubTitle,
    StyledLoginFormSubTitleLink,
    StyledLoginFormTitle,
    StyledLoginFromFormLayoutBottomBlock,
    StyledLoginSubmitButton,
    StyledActiveSessionParagraph,
    StyledMailLink,
    StyledMailWrapper,
} from './index.styles';
import { useTranslation } from 'react-i18next';

export interface ILoginForm {
    onSubmit: TOnSubmitLoginFormCb;
}

export const LoginForm: React.FC<ILoginForm> = ({ onSubmit }) => {
    const { form, onSubmitHandler, isSubmitLoading } = useLoginForm(onSubmit);
    const rememberMe = form.watch('rememberMe');

    const { t } = useTranslation();
    const onSubmitLoginHandler = () => {
        form.setValue('isAdLogin', false);
        onSubmitHandler();
    };
    const onSubmitLoginAdHandler = () => {
        form.setValue('isAdLogin', true);
        onSubmitHandler();
    };

    return (
        <IdentityFormCard>
            <FormProvider {...form}>
                <StyledLoginFormTitle level={1}>
                    {t('authorization.title') || ''}
                </StyledLoginFormTitle>
                <StyledLoginFormSubTitle>
                    {t('authorization.subTitle') || ''}
                    <Link to={'/registration'}>
                        <StyledLoginFormSubTitleLink>
                            {t('authorization.subTitleLink') || ''}
                        </StyledLoginFormSubTitleLink>
                    </Link>
                </StyledLoginFormSubTitle>

                <StyledFormLayout>
                    <FormInputField
                        name='login'
                        label={t('authorization.login') || ''}
                        placeholder={t('authorization.loginPlaceholder') || ''}
                    />

                    <FormInputField
                        name='password'
                        type='password'
                        label={t('authorization.password') || ''}
                        placeholder={t('authorization.passwordPlaceholder') || ''}
                    />

                    <StyledLoginFromFormLayoutBottomBlock>
                        <FormCheckbox
                            name={'rememberMe'}
                            label={t('authorization.rememberMe') || ''}
                        />
                        {rememberMe ? (
                            <StyledActiveSessionParagraph>
                                {t('authorization.activeSession') || ''}
                            </StyledActiveSessionParagraph>
                        ) : null}
                        <Link to={'/passwordForgot'}>
                            <StyledLoginFormForgetPassword>
                                {t('authorization.passwordForgot') || ''}
                            </StyledLoginFormForgetPassword>
                        </Link>
                    </StyledLoginFromFormLayoutBottomBlock>
                </StyledFormLayout>

                <StyledLoginSubmitButton
                    isBlock={true}
                    theme='primary'
                    color='brand'
                    size='middle'
                    onClick={onSubmitLoginHandler}
                    isLoading={isSubmitLoading}
                >
                    {t('authorization.enter') || ''}
                </StyledLoginSubmitButton>

                <StyledLoginFormOther
                    size='middle'
                    isBlock
                    onClick={onSubmitLoginAdHandler}
                >
                    <StyledLoginFormOtherLink>
                        {t('authorization.kiaEmployee') || ''}
                    </StyledLoginFormOtherLink>
                </StyledLoginFormOther>
                <StyledMailWrapper>
                    <StyledMailLink href='mailto:dip.support@kia.ru'>
                        Поддержка: dip.support@kia.ru
                    </StyledMailLink>
                </StyledMailWrapper>
            </FormProvider>


        </IdentityFormCard>
    );
};
