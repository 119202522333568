import React, { useState } from "react";

import { IModal } from "@/packages/modal/Modal";

import {
  StyledModal,
  StyledModalContentWrapper,
} from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight/RentEditModal/index.styles";
import { RentForm } from "../RentForm";
import {
  useGetRentById,
  usePatchRent,
} from "@/helpers/api/flex/vehicles/hooks";
import { IRentFormValues } from "../RentForm/hooks";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useNotification } from "@/hooks/useNotification";
import { Loader } from "@/packages/loader/Loader";
import { useTranslation } from "react-i18next";

interface IRentEditModal extends Pick<IModal, "isOpen"> {
  id: string;
  closeModal: () => void;
  dealerOptions: ISelectOption[];
}

export const RentEditModal: React.FC<IRentEditModal> = (props) => {
  const { id, closeModal, dealerOptions } = props;
  const {
    data: rentResponse,
    isLoading,
    refetch: refetchApplication,
  } = useGetRentById({
    id,
  });

  const { mutate: patchRent, isLoading: isUpdateRentLoading } = usePatchRent();

  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const handleFormSubmit = (data: IRentFormValues) => {
    const { from, to, vehicleId, idleId, event } = data;

    const dataToServer: any = {
      from: from ? from.toISOString() : "",
      to: to ? to.toISOString() : "",
      vehicleId: vehicleId!,
      idleId: event === "idle" ? idleId : undefined,
      event: event === "rent" ? "rent" : "idle",
    };
    patchRent({
      id: id,
      patch: dataToServer,
      onSuccess: () => {
        closeModal();
        refetchApplication();
      },
      onError: (error) => {
        if (from && to && from.toISOString() === to.toISOString()) {
          createNotificationError(t("kiaFlex.1dayError") || "");
        } else {
          createNotificationError(
            error.response?.data?.message || t("kiaFlex.eventError") || ""
          );
        }
      },
    });
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmitHandler = () => {
    setIsSubmitted(true);
  };

  return (
    <StyledModal
      {...props}
      title={t("kiaFlex.eventInfo") || ""}
      size="medium"
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("kiaFlex.save") || ""}
      onClose={closeModal}
      showConfirmButton={true}
      isDisabledConfirmButton={isDisabled}
      onSubmit={onSubmitHandler}
    >
      <StyledModalContentWrapper>
        {!isLoading ? (
          <RentForm
            defaultValues={rentResponse?.data || []}
            onFormSubmit={handleFormSubmit}
            isLoading={isUpdateRentLoading}
            dealerOptions={dealerOptions}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            setIsDisabled={setIsDisabled}
          />
        ) : (
          <Loader size={48}></Loader>
        )}
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
