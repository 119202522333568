import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import { TTransmissionDeleteResponse } from "../transmissions/types";
import {
  TGetListTransportClaimsRequest,
  TGetListTransportClaimsResponse,
  TGetTransportClaimsByIdRequest,
  TGetTransportClaimsByIdResponse,
  TTransportClaimsCreateRequest,
  TTransportClaimsCreateResponse,
  TTransportClaimsDeleteRequest,
  TTransportClaimsPatchRequest,
  TTransportClaimsPatchResponse,
} from "./types";
import { handleFileLoad } from "@/helpers/utils";
import { TransportClaimDto, TransportClaimFileType } from "@/components/TransportComplaints/types";
import { formatDateWithOffset } from "@/helpers/date";

const mapParams = (request: TGetListTransportClaimsRequest) => {
  return {
    dateAcceptenceStart: formatDateWithOffset(request.dateAcceptanceStart, true),
    dateAcceptenceEnd: formatDateWithOffset(request.dateAcceptanceEnd, false),
    updatedAtStart: formatDateWithOffset(request.updatedAtStart, true),
    updatedAtEnd: formatDateWithOffset(request.updatedAtEnd, false),
    page: request.page,
    limit: request.limit,
    vin: request.vin,
    codeCase: request.codeCase,
    placeAcceptence: request.placeAcceptence,
    'sort[sortBy]': 'updatedAt',
    'sort[sortAt]': request.sortAt,
  }
}


export const useGetTransportClaimsList = (
  request: TGetListTransportClaimsRequest
) => {
  return useQuery(["transport-claims", "list", request], () => {
    return api.get<TGetListTransportClaimsResponse>("/transport-claims", {
      params: mapParams(request),
    });
  });
};

export const useGetTransportClaimsById = (
  request: TGetTransportClaimsByIdRequest
) => {
  return useQuery(["transport-claims", "detail", request.id], () => {
    return api.get<TGetTransportClaimsByIdResponse>(
      `/transport-claims/${request.id}`
    );
  });
};

export const useCreateTransportClaims = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TTransportClaimsCreateRequest) => {
      return api.post<TTransportClaimsCreateResponse>(
        "/transport-claims",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transport-claims", "list"]);
      },
    }
  );
};

export const usePatchTransportClaims = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TTransportClaimsPatchRequest) => {
      return api.patch<TTransportClaimsPatchResponse>(
        `/transport-claims/${id}`,
        patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["transport-claims", "list"]);
        queryClient.invalidateQueries([
          "transport-claims",
          "detail",
          variables.id,
        ]);
      },
    }
  );
};

export const useDeleteTransportClaims = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TTransportClaimsDeleteRequest) => {
      return api.delete<TTransmissionDeleteResponse>(
        `/transport-claims/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["transport-claims", "list"]);
        queryClient.invalidateQueries(["transport-claims", "detail", variables.id]);
      },
    }
  );
};

export const useGetArchiveTransportClaims = () => {
  return useMutation((request: any) => {
    return api.get<any>(
      `/transport-claims/archive/${request.path.id}`,
      {
        params: request.type,
        responseType: "blob",
      }
    );
  });
};


export const useDownloadTransportClaimsArchive = () => {
  const { mutateAsync: archiveUrl, isLoading } = useGetArchiveTransportClaims();

  const isArchiveNotEmpty = (data) => {
    const dataKeys = Object.keys(data);
    const keys = ['generalView', 'oupts', 'tthCmr'];
    for (const key of dataKeys) {
      if (keys.includes(key) && data[key].length > 0) {
        return true;
      }
    }
    return false;
  }

  const isCategoryArchiveNotEmpty = (data: TransportClaimDto, type): boolean => {
    return (Array.isArray(data[type]) && data[type].length > 0);
  }
  const handleDownload = (id: string, type: TransportClaimFileType, fileName: string) => {
    archiveUrl(
      {
        path: { id },
        type: { type },
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, `${fileName}.zip`);
        },
      }
    );
  }

  return {
    isLoading,
    handleDownload,
    isArchiveNotEmpty,
    isCategoryArchiveNotEmpty,
  }

}

export const useExportTransportComplaintsList = () => {
  return useMutation((request: TGetListTransportClaimsRequest) => {
    return api.get<any>(`/transport-claims/export`, {
      params: mapParams(request),
      responseType: "blob",
    });
  });
};
