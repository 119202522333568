import Row from "./Row";

const TreeComponent = ({
  openInformationLettersEditModalState,
  onDeleteIPHandler,
  data,
  setActiveMaterialKeys,
  activeMaterialKeys,
                         searchValue,
}) => {
  return data?.map((item) => (
    <Row
      activeMaterialKeys={activeMaterialKeys}
      setActiveMaterialKeys={setActiveMaterialKeys}
      section={item}
      openInformationLettersEditModalState={
        openInformationLettersEditModalState
      }
      onDeleteIPHandler={onDeleteIPHandler}
      searchValue={searchValue}
    >
      {item.children && item.children.length ? (
        <TreeComponent
          activeMaterialKeys={activeMaterialKeys}
          setActiveMaterialKeys={setActiveMaterialKeys}
          data={item.children}
          openInformationLettersEditModalState={
            openInformationLettersEditModalState
          }
          onDeleteIPHandler={onDeleteIPHandler}
          searchValue={searchValue}
        />
      ) : (
        <></>
      )}
    </Row>
  ));
};

export default TreeComponent;
