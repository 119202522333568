import { FC, useEffect } from "react";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { TitleDivider } from "@/packages/TitleDivider";
import styled from "styled-components";
import { Space } from "antd";
import {
  StyledWorksFormBottom,
  StyledWorksFormTop,
} from "@/components/MaintenanceGrids/index.styles";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IconWorkType } from "@/packages/iconWorkType";
import { TWorksCreateRequest } from "@/helpers/api/maintenanceGrids/works/types";
import { FormInputNumberField } from "@/packages/formElements/formFields/FormInputNumberField";
import { useGetMaintenanceGridSettingsValue } from "@/helpers/api/shared/settings/hooks";
import { PRICE_RATE_HOUR_KEY } from "../WorksRatePerHourModal";
import { usePermission } from "@/hooks/usePermission";
import { getLegendTextForWorkType } from "./utils";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { useTranslation } from "react-i18next";

const StyledWorksFormModalForm = styled.div``;
const StyledOptionBlock = styled(Space)`
  max-width: 67px;
`;

const WorksFormModalForm: FC<any> = (props) => {
  const { activeWork } = props;
  const { hasAccess: hasEdit } = usePermission("maintenance-grid", "update");
  const { t } = useTranslation();
  const { data: priceRateHour } = useGetMaintenanceGridSettingsValue({
    key: PRICE_RATE_HOUR_KEY,
  });
  const form = useFormContext<TWorksCreateRequest>();
  const { fields: workTypesFields, update } =
    useFieldArray<TWorksCreateRequest>({
      control: form.control,
      name: "workTypes",
    });

  const code = form.watch("code");

  useEffect(() => {
    workTypesFields.forEach((workType, index) => {
      if (activeWork) {
        for (let i = 0; i < activeWork.types.length; i++) {
          if (workType.type === activeWork.types[i]) {
            workType.normInHours = activeWork.normInHours[i].toString();
            // @ts-ignore
            workType.totalCost = activeWork.totalCost[i];
          }
          //fix for data from preview edits
          else if (
            typeof workType.normInHours === "number" &&
            workType.normInHours > 0
          ) {
            workType.normInHours = 0;
            // @ts-ignore
            delete workType.totalCost;
          }
        }
      }
      update(index, workType);
    });
  }, [update]);

  useEffect(() => {
    workTypesFields.forEach((workType, index) => {
      //@ts-ignore
      workType.code = code;

      update(index, workType);
    });
  }, [code, update]);

  return (
    <StyledWorksFormModalForm>
      <TitleDivider size="medium">
        {t("maintenanceGrids.worksModel.workForm.work") || ""}
      </TitleDivider>

      <StyledWorksFormTop>
        <FormInputField
          label={t("maintenanceGrids.worksModel.workForm.name") || ""}
          name="name"
          description={
            t("maintenanceGrids.worksModel.workForm.nameDescription") || ""
          }
          readOnly={!hasEdit}
        />
        <FormInputField
          label={t("maintenanceGrids.worksModel.workForm.code") || ""}
          name="code"
          description={
            t("maintenanceGrids.worksModel.workForm.codeDescription") || ""
          }
          readOnly={!hasEdit}
        />
        <FormTextAreaField
          label={t("maintenanceGrids.worksModel.workForm.about") || ""}
          name="description"
          description={
            t("maintenanceGrids.worksModel.workForm.aboutDescription") || ""
          }
          readOnly={!hasEdit}
        />
        <FormCheckbox
          label={t("maintenanceGrids.worksModel.workForm.workByDefault") || ""}
          name="isDefault"
          readOnly={!hasEdit}
          isDisabled={!hasEdit}
        />
      </StyledWorksFormTop>

      <StyledWorksFormBottom>
        {workTypesFields.map((field, index) => {
          const { id, type } = field;
          return (
            <Space size={16} align="center" key={id}>
              <StyledOptionBlock direction="vertical" align="center" size={8}>
                <IconWorkType type={type} />
                <Paragraph size={10} color="gray5" textAlign="center">
                  {getLegendTextForWorkType(type)}
                </Paragraph>
              </StyledOptionBlock>

              <Space size={16} wrap={false} align="start">
                <FormInputNumberField
                  label={t("maintenanceGrids.worksModel.workForm.norm") || ""}
                  description={
                    t("maintenanceGrids.worksModel.workForm.normDescription") ||
                    ""
                  }
                  readOnly={!hasEdit}
                  onBlur={(event) => {
                    const {
                      target: { value },
                    } = event;

                    const numberValue = Number(value);

                    const newState = {
                      ...form.getValues().workTypes[index],
                      normInHours: value === "" ? 0 : value,
                      totalCost:
                        value === ""
                          ? undefined
                          : numberValue *
                            Number(priceRateHour?.data.value || 0),
                    };
                    if (value === "") {
                      delete newState?.totalCost;
                    }
                    // @ts-ignore
                    update(index, newState);
                  }}
                  name={`workTypes.${index}.normInHours`}
                />
                <FormInputField
                  label={t("maintenanceGrids.worksModel.workForm.price") || ""}
                  readOnly={true}
                  name={`workTypes.${index}.totalCost`}
                />
              </Space>
            </Space>
          );
        })}
      </StyledWorksFormBottom>
    </StyledWorksFormModalForm>
  );
};

export { WorksFormModalForm };
