import { useForm } from "react-hook-form";

export interface IFormValues {
  modelId: string | null;
}

export const DEFAULT_FORM_VALUES: IFormValues = {
  modelId: null,
};

export type TOnSubmitFormCb = (values: IFormValues) => void;

export const useFormValues = (onSubmit: TOnSubmitFormCb) => {
  const form = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};

export const getRowStatistics = (parameters: any) => {
  if (parameters?.children) {
    const arrayOfItems = parameters?.children.map((children) => {
      return children.parameters?.reduce((acc, item) => {
        const hasAnswer = item?.answers[0] && item?.answers[0]?.answer !== null;
        const hasImage = !item?.photo || item?.hasPhoto;
        const hasKpi =
          item?.kpi === "Не обязательно" || !!item?.answers[0]?.kpi;

        if (hasAnswer && hasImage && hasKpi) {
          return acc + 1;
        }
        return acc;
      }, 0);
    });
    return arrayOfItems.reduce((partialSum, a) => partialSum + a, 0);
  } else {
    const count = parameters?.parameters?.reduce((acc, item) => {
      const hasAnswer = item?.answers[0] && item?.answers[0]?.answer !== null;
      const hasImage = !item?.photo || item?.hasPhoto;
      const hasKpi = item?.kpi === "Не обязательно" || !!item?.answers[0]?.kpi;

      if (hasAnswer && hasImage && hasKpi) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return count.toString();
  }
};
