import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { TPatchAnswerWarrantyRequest, TWarrantyParameter, } from "@/helpers/api/warranty-audit/fill/types";

import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { useState } from "react";
import { PhotoInfo } from "@/components/Audits/PhotoInfo/PhotoInfo";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { t } from "i18next";
import { Badge } from "antd";
import { StyledAnswerButtons } from "@/components/AuditWarranty/FillWarrantyStages/DppoParams/index.styles";
import {
  Answer,
  AnswerProviderDispatch,
  AnswerProviderState
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsSpares/provider";

export function saveAnswers(
  savedAnswer: Answer | undefined,
  id: string,
  defaultAnswer: boolean,
  savedAnswers: Answer[],
  setAnswers: AnswerProviderDispatch
) {
  if (!savedAnswer) {
    const newAnswer: Answer = {
      id,
      isActive: defaultAnswer,
    }
    setAnswers([...savedAnswers, newAnswer]);

  } else {
    const mappedSavedAnswers = savedAnswers.map(it => {
      if (it.id === savedAnswer.id) {
        return {
          id: it.id,
          isActive: !it.isActive,
        }
      }
      return {
        ...it
      }
    })
    setAnswers(mappedSavedAnswers)
  }
}

export const getDefaultSparesAnswerTableHeaders = <
  TRecord extends object
>(options: {
  answerQuestion: (data: TPatchAnswerWarrantyRequest) => void;
  openChat: (params: IMessageParams) => void;
  answers: AnswerProviderState;
  setAnswers: AnswerProviderDispatch;
}): IHeaderItem<TWarrantyParameter>[] => {
  const { answerQuestion, openChat, setAnswers, answers } = options;

  return [
    {
      Header: t("warrantyAudits.templates.code") || "",
      accessor: "code",
      width: 60,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      Header: t("warrantyAudits.templates.violation") || "",
      accessor: "violation",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      Cell: ({ row: { original } }) => {
        return `${original?.templateViolation?.code ?? ''}`;
      },
    },
    {
      Header: t("warrantyAudits.templates.checkParam") || "",
      accessor: "title",
    },
    {
      Header: "",
      accessor: "info",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.photo &&
              <PhotoInfo
                isRequired={true}
                isUpload={!!original?.answers[0]?.hasPhoto}
              />
            }
            {
              original.transcript &&
              <Paragraph size={12} weight={300}>
                {original.transcript}
              </Paragraph>
            }
          </>
        );
      },
    },
    {
      Header: t("warrantyAudits.weight") || "",
      accessor: "weight",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: t("audits.list.result") || "",
      accessor: "answers",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        const [deaceptValue, setDeaceptValue] = useState<number>(
          original.answers[0].deaccept
        );

        const savedAnswer = answers.find(it => it.id === original?.answers[0]?.id);
        const savedAnswers = [...answers];
        const defaultAnswer = original.defaultValue
        const isAnswer = original?.answers[0]?.answer;
        const hasAnswer =
          !!original?.answers?.length && original?.answers[0].answer !== null;

        const isActive = savedAnswer ? savedAnswer.isActive : (hasAnswer && isAnswer);

        return !original.manual ? (
          <StyledAnswerButtons>
            <Button
              size="middle"
              customColor={
                isActive
                  ? isActive === defaultAnswer && isActive
                    ? "#62bb2c"
                    : "#e32446"
                  : ""
              }
              onClick={() => {
                if (isActive) {
                  return;
                }

                saveAnswers(
                  savedAnswer,
                  original.answers[0].id,
                  !defaultAnswer,
                  savedAnswers,
                  setAnswers);

                answerQuestion({
                  id: original.answers[0].id,
                  data: {
                    answer: true,
                  },
                })
              }}
            >
              {t("common.yes") || ""}
            </Button>
            <Button
              size="middle"
              customColor={
                !isActive
                  ? isActive === defaultAnswer && !isActive
                    ? "#62bb2c"
                    : "#e32446"
                  : ""
              }
              onClick={() => {
                if (!isActive) {
                  return;
                }
                saveAnswers(
                  savedAnswer,
                  original.answers[0].id,
                  defaultAnswer,
                  savedAnswers,
                  setAnswers
                );
                answerQuestion({
                  id: original.answers[0].id,
                  data: {
                    answer: false,
                  },
                })
              }
              }
            >
              {t("common.no") || ""}
            </Button>
          </StyledAnswerButtons>
        ) : (
          <>
            <InputNumberField
              label={t("warrantyAudits.list.deacceptance") || ""}
              value={deaceptValue}
              onChange={(value) => {
                setDeaceptValue(value || 0);
              }}
              onBlur={() =>
                answerQuestion({
                  id: original.answers[0].id,
                  data: {
                    deaccept: deaceptValue || 0,
                  },
                })
              }
            />
          </>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
      Cell: ({ row: { original } }) => {
        const unreadCount = original.answers[0].unreadMessagesAmount;
        const allCount = original.answers[0].allMessagesAmount;
        return (
          <Badge color={unreadCount === 0 ? '#808080' : undefined} count={unreadCount > 0 ? unreadCount : allCount}>
            <IconButton
              size="l"
              icon="chat"
              color="black"
              variant="tertiary"
              onClick={() => {
                openChat({ answerId: original.answers[0].id });
              }}
            />
          </Badge>
        );
      },
    },
  ];
};
