import React from "react";
import { useNavigate } from "react-router-dom";

import { AutoParkErrorModal } from "@/components/AutoPark/AutoParkErrorModal";
import { AutoParkForm } from "@/components/AutoPark/AutoParkForm";
import { IAutoParkFormValues } from "@/components/AutoPark/AutoParkForm/hooks";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { useCreateVehicle } from "@/helpers/api/vehicles/hooks";
import { TVehicleCreateRecord } from "@/helpers/api/vehicles/types";
import { useModal } from "@/hooks/useModal";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

export const AutoParkCreate: React.FC = () => {
  const navigate = useNavigate();
  const { modalState, openModal } = useModal();
  const { t } = useTranslation();
  const { mutateAsync: createVehicleAsync, isLoading: isCreateVehicleLoading } =
    useCreateVehicle();

  const { createNotificationError } = useNotification();

  const handleFormSubmit = async (data: IAutoParkFormValues) => {
    const {
      commissioningDate,
      expiredCASCO,
      expiredOSAGO,
      registrationCertificate,
      number,
      vin,
      equipmentId,
      color,
      images,
      files,
    } = data;

    const mediaImages = images
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const mediaFiles = files
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const media = {
      photo: mediaImages,
      file: mediaFiles,
    };

    const dataToServer: TVehicleCreateRecord = {
      commissioning: commissioningDate ? commissioningDate.toISOString() : "",
      // @ts-ignore TODO
      expiredCASCO: expiredCASCO ? expiredCASCO.toISOString() : undefined,
      expiredOSAGO: expiredOSAGO ? expiredOSAGO.toISOString() : "",
      equipmentId: equipmentId || "",
      registrationCertificate,
      governmentNumber: number,
      media,
      color,
      vin,
    };

    if (expiredOSAGO! > new Date()) {
      try {
        await createVehicleAsync(dataToServer);
        navigate("/autopark");
      } catch (error) {
        if (
          error &&
          // @ts-ignore
          error.response?.data.message === "Generation is inactive"
        ) {
          createNotificationError("Поколение неактивно");
        }
      }
    } else {
      openModal();
    }
  };

  return (
    <DashboardLayout toBack="/autopark" title={t("park.addNewCar_2") || ""}>
      <AutoParkForm
        onFormSubmit={handleFormSubmit}
        isLoading={isCreateVehicleLoading}
      />

      <AutoParkErrorModal
        isOpen={modalState.isOpen}
        onSubmit={modalState.onSubmit}
      />
    </DashboardLayout>
  );
};
