import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledModalContentWrapper, StyledTitle } from "./index.styles";
import { useFormValues } from "./hooks";
import { useGetAuditsTemplatesList } from "@/helpers/api/audits/hooks";
import { Title } from "@/packages/title/Title";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import {
  preparedDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { useCreateAudit } from "@/helpers/api/audit-list/hooks";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";
import { useGetCurrentVisitNumber } from "@/helpers/api/audit-fill/hooks";

type ICreateAuditModal = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadAuditTemplateBase {
  onSubmit: () => void;
}

export const CreateAuditModal: React.FC<
  ICreateAuditModal & IUploadAuditTemplateBase
> = (props) => {
  const { onSubmit, onCancel } = props;
  const [searchValue, setSearchValue] = useState({ dealer: "", template: "" });

  const { mutate: createAudit, isLoading } = useCreateAudit();
  const { data: dealerList } =
    useGetFilteredDealerList({ q: searchValue.dealer, limit: 0 });
  const { data: templates, isLoading: isTemplatesLoading } =
    useGetAuditsTemplatesList(
      {
        "filter[title]": searchValue.template,
        "filter[active]": true,
      },
      true
    );
  const { t } = useTranslation();
  const onFormChange = (value) => {
    createAudit({
      data: { ...value, templateId: +value.templateId },
      onSuccess: handleSubmit,
    });
  };

  const { form, onSubmitHandler, setDefaultValue, resetValues } =
    useFormValues(onFormChange);

  const { data: visitNumber } = useGetCurrentVisitNumber({ dealerId: form.watch('dealerId') })

  const handleSubmit = () => {
    resetValues();
    onSubmit();
  };

  const preparedTemplates = useMemo(
    () =>
      templates?.data.data?.map((item) => ({
        label: item.title,
        value: +item.id,
      })) || [],
    [templates?.data?.data, isTemplatesLoading]
  );
  useEffect(() => {
    setSearchValue({ dealer: "", template: "" });
  }, []);

  const handleCancel = () => {
    resetValues();
    onCancel && onCancel();
  };

  const nowDate = new Date();
  const quarter = Math.floor(nowDate.getMonth() / 3) + 1;

  useEffect(() => {
    const dealer = dealerList?.data.data.find(it => it.id === form.watch('dealerId'))
    if (!dealer) {
      return;
    }
    form.setValue('title',
      `${dealer.mobisCode}_${dealer.lawName}_${quarter}кв-${nowDate.getFullYear()}_${visitNumber?.data.currentVisitNumber ?? ''}`
    )
  }, [form.watch('dealerId'), visitNumber])
  return (
    <Modal
      {...props}
      title={t("audits.list.createAudit") || ""}
      size="small"
      isLoadingConfirmButton={isLoading}
      cancelButtonText={t("audits.list.cancel") || ""}
      confirmButtonText={t("audits.list.createAudit_2") || ""}
      onSubmit={onSubmitHandler}
      onCancel={handleCancel}
    >
      <StyledModalContentWrapper>
        <StyledTitle>
          <Title level={3} weight={400}>
            {t("audits.list.createAudit") || ""}
          </Title>
        </StyledTitle>
        <FormProvider {...form}>
          <FormSelect
            name="dealerId"
            label={t("audits.list.dealerId_2") || ""}
            placeholder={t("audits.list.dealerIdDescr") || ""}
            options={preparedDealerList(dealerList?.data?.data || []) || []}
            // isDisabled={isDealersLoading}
            isSearchShow={true}
            onSearch={(value) => {
              setSearchValue((prev) => ({ ...prev, dealer: value }));
            }}
          />
          <FormSelect
            name="templateId"
            label={t("audits.list.templateId") || ""}
            placeholder={t("audits.list.templateId") || ""}
            options={preparedTemplates || []}
            isSearchShow={true}
            onSearch={(value) =>
              setSearchValue((prev) => ({ ...prev, template: value }))
            }
          />
          <FormInputField
            name="title"
            label={t("audits.list.name") || ""}
            placeholder={t("audits.list.nameDescr") || ""}
          />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
