import { IconButton } from "@/packages/icon-button/IconButton";
import styled from "styled-components";

export const StyledPrevButton = styled(IconButton)``;

export const StyledNextButton = styled(IconButton)`
  transform: rotate(180deg);
`;

export const StyledContentTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
`;
