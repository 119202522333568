import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TModelCreateRequest,
  TModelCreateResponse,
  TModelPatchRequest,
  TModelPatchResponse,
  TGetModelByIdRequest,
  TGetModelByIdResponse,
  TGetModelListRequest,
  TGetModelListResponse,
  TModelUpdateStatusRequest,
  TModelUpdateStatusResponse,
  TExportModelsRequest,
  TExportModelsResponse,
} from "./types";

export const useGetModelList = (
  request: TGetModelListRequest,
  isEnabled = true
) => {
  return useQuery(
    ["models", "list", request],
    () => {
      return api.get<TGetModelListResponse>("/models", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetModelById = ({
  id,
  onSuccess,
}: TGetModelByIdRequest & {
  onSuccess?: (response: TGetModelByIdResponse) => void;
}) => {
  return useQuery(
    ["models", "detail", id],
    () => {
      return api.get<TGetModelByIdResponse>(`/models/${id}`);
    },
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response.data);
      },
    }
  );
};

export const useCreateModel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TModelCreateRequest) => {
      return api.post<TModelCreateResponse>("/models", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["models", "list"]);
      },
    }
  );
};

export const usePatchModel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TModelPatchRequest) => {
      return api.patch<TModelPatchResponse>(`/models/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["models", "detail", variables.id]);
        queryClient.invalidateQueries(["models", "list"]);
      },
    }
  );
};

export const useUpdateModelStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: TModelUpdateStatusRequest) => {
      return api.patch<TModelUpdateStatusResponse>(
        `/models/${id}/isActive`,
        data
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["models", "detail", variables.id]);
        queryClient.invalidateQueries(["models", "list"]);
      },
    }
  );
};

export const useExportModels = () => {
  return useMutation((request: TExportModelsRequest) => {
    return api.get<TExportModelsResponse>(`/models/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
