import { useGetGenerationList } from "@/helpers/api/generations/hooks";
import { useGetModelList } from "@/helpers/api/models/hooks";
import { useGetEquipmentList } from "@/helpers/api/modifications/hooks";
import { Field } from "@/packages/formElements/fields/Field";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Row } from "antd";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { IExistCarFromValues } from "../../KiaFlexExistCar/ExistsCarForm/type";
import { StyledInput } from "./index.styles";
import { ModelFieldsName, ModelFieldsNamePartial } from "./types";
import { useTranslation } from "react-i18next";

export interface IModelFieldsProps {
  description?: any;
  isDisabled?: boolean;
}

const _ModelFields: React.FC<IModelFieldsProps> = (props) => {
  const { description, isDisabled } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const { model, generation } = useWatch<IExistCarFromValues>();

  const { data: modelList } = useGetModelList({ limit: 100 });

  const { data: genList } = useGetGenerationList({
    limit: 100,
    modelId: model ? model : "",
  });

  const { data: modList } = useGetEquipmentList({
    limit: 100,
    generationId: generation ? generation : "",
  });

  const modelOptions = useMemo(() => {
    return (
      modelList &&
      modelList?.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  }, [modelList]);

  const genOptions = useMemo(() => {
    return (
      genList &&
      genList?.data.data.map((item) => ({
        label: `${item.name} ${item.restylingName || ""}`,
        value: item.id,
      }))
    );
  }, [genList]);

  const modOptions = useMemo(() => {
    return (
      modList &&
      modList?.data.data.map((item) => ({
        label: item.fullName,
        value: item.id,
      }))
    );
  }, [modList]);

  const equpOptions = useMemo(() => {
    let result: any[] = [];
    modList &&
      modList?.data.data.map((item) =>
        // @ts-ignore

        item.equipments.map((equp) =>
          result.push({
            label: equp.name,

            value: equp.id,
          })
        )
      );
    return result;
  }, [modList]);

  useEffect(() => {
    setValue("generation", null);
    setValue("modification", null);
    setValue("equipmentId", null);
  }, [model]);

  useEffect(() => {
    setValue("modification", null);
    setValue("equipmentId", null);
  }, [generation]);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.MODEL}
            label={t("maintenanceGrids.gridsModel.model") || ""}
            options={modelOptions}
            description={description && description[ModelFieldsName.MODEL]}
            isDisabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.GENERATION}
            label={t("kiaFlex.generation") || ""}
            options={genOptions}
            description={description && description[ModelFieldsName.GENERATION]}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.MODIFICATION}
            label={t("maintenanceGrids.newGridForm.modification") || ""}
            options={modOptions}
            description={
              description && description[ModelFieldsName.MODIFICATION]
            }
            isDisabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.EQUIPMENT}
            label={t("kiaFlex.equip") || ""}
            options={equpOptions ?? []}
            description={description && description[ModelFieldsName.EQUIPMENT]}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInputField
            name={ModelFieldsName.COLOR}
            label={t("kiaFlex.color") || ""}
            description={description && description[ModelFieldsName.EQUIPMENT]}
            isDisabled={isDisabled}
          />
        </Col>

        <Col span={12}>
          <Controller
            name={ModelFieldsNamePartial.NUMBER}
            control={control}
            render={({
              field: { ref: _, ...fieldWithoutRef },
              fieldState: { error },
            }) => {
              return (
                <Field>
                  <Field.Label disabled={isDisabled}>
                    {t("kiaFlex.regNum") || ""}
                  </Field.Label>

                  <Field.FieldControlContainer>
                    <StyledInput
                      formatChars={{ х: /[а-яА-Я]/, "?": "[0-9]", 9: "[0-9]" }}
                      {...fieldWithoutRef}
                      mask="х999хх99?"
                      isDisabled={isDisabled}
                    ></StyledInput>
                  </Field.FieldControlContainer>
                  {error ? (
                    <Field.FieldError>{error?.message}</Field.FieldError>
                  ) : (
                    <></>
                  )}
                  <Field.FieldDescription>
                    {t("kiaFlex.lastNum") || ""}
                  </Field.FieldDescription>
                </Field>
              );
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormInputField
            name={ModelFieldsNamePartial.PTS}
            label={t("kiaFlex.pts") || ""}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
    </>
  );
};
export const ModelFields = React.memo(_ModelFields);
