import { TModificationRecord } from '@/helpers/api/modifications/types';
import { codeListSchema, requiredSelectSimpleValueSchema } from '@/helpers/validation';
import * as yup from 'yup';
import { string } from 'yup';
import {
    CompleteSetFromFieldsEnum,
    ICompleteSetDTOValues,
    ICompleteSetFormValues,
    ICompleteSetSuccessFormValues,
} from './types';

export const DEFAULT_COMPLETE_SET_FORM_VALUES: ICompleteSetFormValues = {
    mpv: null,
    fuelTypeId: null,
    transmissionTypeId: null,
    carBodyTypeId: null,
    engineTypeId: null,
    engineVolumeId: null,
    vinEngineTransmission: [ '' ],
    //@ts-ignore
    equipments: [ { code: '', name: '' } ],
    // codes: [""],
    comment: undefined,
};

export const completeSetFormSchema = yup
    .object({
        [CompleteSetFromFieldsEnum.Mpv]: string()
            .nullable()
            .matches(
                /^[A-Za-z0-9]+$/,
                'Доступен ввод только латиницы и числовых значений.',
            )
            .min(9, 'Необходимо ввести 9 символов')
            .max(9, 'Необходимо ввести 9 символов'),
        [CompleteSetFromFieldsEnum.FuelTypeId]: requiredSelectSimpleValueSchema(
            'Необходимо выбрать тип топлива',
        ),
        [CompleteSetFromFieldsEnum.TransmissionTypeId]:
            requiredSelectSimpleValueSchema('Необходимо выбрать тип трансмиссии'),
        [CompleteSetFromFieldsEnum.CarBodyTypeId]: requiredSelectSimpleValueSchema(
            'Необходимо выбрать тип кузова',
        ),
        [CompleteSetFromFieldsEnum.EngineTypeId]: requiredSelectSimpleValueSchema(
            'Необходимо выбрать тип двигателя',
        ),
        [CompleteSetFromFieldsEnum.EngineVolumeId]: requiredSelectSimpleValueSchema(
            'Необходимо выбрать объем двигателя',
        ),
        [CompleteSetFromFieldsEnum.VinEngineTransmission]: codeListSchema({
            required: 'Необходимо ввести код OCN',
            lengthCnt: 2,
            formatErrorMessage:
                'Код должен содержать 2 симовола. Допустимы символы латинского алфавита и цифры',
        }),
        [CompleteSetFromFieldsEnum.Comment]: yup
            .string()
            .min(5, 'Комментарий должен содержать минимум 5 симоволов')
            .nullable(),
    })
    .required();

export const completeSetRecordToValues = (
    record: TModificationRecord,
): ICompleteSetFormValues => {
    const {
        fuelType,
        carBodyType,
        transmissionType,
        engineType,
        engineVolume,
        // @ts-ignore TODO: DELETE
        equipments,
        ...rest
    } = record;
    // @ts-ignore TODO: DELETE
    return {
        ...rest,
        fuelTypeId: fuelType.id,
        carBodyTypeId: carBodyType.id,
        transmissionTypeId: transmissionType.id,
        engineTypeId: engineType.id,
        engineVolumeId: engineVolume.id,
        equipments: equipments,
    };
};

export const completeSetValuesToDTO = (
    values: ICompleteSetSuccessFormValues,
): ICompleteSetDTOValues => {
    const { comment, equipments, mpv, ...rest } = values;

    const result = {
        ...rest,
        parentGeneration: undefined,
        parentModel: undefined,
        mpv: mpv || '',
        comment: comment || undefined,
        equipments: equipments.map((el) => ({
            code: el.code,
            name: el.name,
        })),
    };

    //@ts-ignore
    return result;
};
