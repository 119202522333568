import React, { useEffect, useState } from "react";
import { Loader } from "@/packages/loader/Loader";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";
import { useGetWarrantyQuestionsDppo, usePatchAnswerWarranty, } from "@/helpers/api/warranty-audit/fill/hooks";
import { mutateArrOfObj } from "@/helpers/utils";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { StyledTableSimple } from "./index.styles";
import { getDefaultWarrantyAnswerTableHeaders } from "./helpers";
import { TPatchAnswerWarrantyRequest } from "@/helpers/api/warranty-audit/fill/types";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {
  useAnswerProvider
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsSpares/provider";

interface IDppoParams {
  id: number;
  trigger: boolean;
  openChat: (params: IMessageParams) => void;
}

export const getRowStatisticsWarranty = (parameters: any) => {
  if (parameters?.children) {
    const arrayOfItems = parameters?.children.map((children) => {
      return children.params?.reduce((acc, item) => {
        const hasImage = !item?.photo || item?.hasPhoto;
        if (hasImage) {
          return acc + 1;
        }
        return acc;
      }, 0);
    });
    return arrayOfItems.reduce((partialSum, a) => partialSum + a, 0);
  } else {
    const count = parameters.params?.reduce((acc, item) => {
      const hasImage = !item?.photo || !!item.answers[0].hasPhoto;
      if (hasImage) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return count.toString();
  }
};
export const DppoParams: React.FC<IDppoParams> = (props) => {
  const { id, trigger, openChat } = props;

  const {
    data: questionsDppo,
    isLoading: isQuestionsLoadingDppo,
    refetch: updateQuestionsDppo,
  } = useGetWarrantyQuestionsDppo(id);

  const sectionsDppo = mutateArrOfObj(questionsDppo?.data?.sections || []);

  const { mutate: answerQuestion } = usePatchAnswerWarranty();

  const handleAnswerQuestion = (patch: TPatchAnswerWarrantyRequest) => {
    answerQuestion({
      patch,
      onSuccess: updateQuestionsDppo,
    });
  };

  const { state: answers, dispatch: setAnswers } = useAnswerProvider();

  const headers =
    getDefaultWarrantyAnswerTableHeaders({
      answerQuestion: handleAnswerQuestion,
      openChat,
      answers,
      setAnswers,
    });

  useEffect(() => {
    updateQuestionsDppo();
  }, [trigger]);

  const [activeWarrantyKeys, setActiveWarrantyKeys] = useState([]);

  useEffect(() => {
    const storedActiveKey = sessionStorage.getItem("activeWarrantyAuditsKeys");
    if (storedActiveKey) {
      const parsedActiveKey = JSON.parse(storedActiveKey);
      const filteredActiveKey = parsedActiveKey.filter(
        (value, index, self) => value !== null && self.indexOf(value) === index
      );
      setActiveWarrantyKeys(filteredActiveKey);
    }
  }, []);

  useEffect(() => {
    if (activeWarrantyKeys && activeWarrantyKeys.length > 0) {
      sessionStorage.setItem(
        "activeWarrantyAuditsKeys",
        JSON.stringify(activeWarrantyKeys)
      );
    }
  }, [activeWarrantyKeys]);

  return !isQuestionsLoadingDppo ? (
    <DndProvider backend={HTML5Backend}>
      <TreeComponent
        activeWarrantyKeys={activeWarrantyKeys}
        setActiveWarrantyKeys={setActiveWarrantyKeys}
        headerCallback={getRowStatisticsWarranty}
        hasInfo={true}
        data={sectionsDppo || []}
        paramKey="params"
        renderComponent={({ data }) => (
          <StyledTableSimple>
            <TableSimple headers={headers || []} data={data || []} isCutVerticalPadding/>
          </StyledTableSimple>
        )}
      />
    </DndProvider>
  ) : (
    <Loader size={48}/>
  );
};
