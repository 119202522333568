import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";
import {
  TEditBonusesParamsRequest,
  TEditBonusesParamsResponse,
  TGetBonusesCalculationsRequest,
  TGetBonusesCalculationsResponse,
  TGetBonusesFactorZRequest,
  TGetBonusesFactorZResposne,
} from "./types";

export const useGetBonusesCalculation = (
  requset: TGetBonusesCalculationsRequest
) => {
  return useQuery(["bonuses", "reports", requset], () => {
    return api.get<TGetBonusesCalculationsResponse>(
      "/bonuses/quarters/bonuses-calculations",
      {
        params: requset,
      }
    );
  });
};

export const useEditBonusesParams = () => {
  return useMutation(
    ({
      data,
      id,
      onSuccess,
      onError,
    }: {
      id: string;
      data: TEditBonusesParamsRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TEditBonusesParamsResponse>(
        `/bonuses/quarters/${id}`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

//TODO TYPES
export const useGetQuarter = (request: any) => {
  return useQuery(["bonuses", "quarter", request], () => {
    return api.get<any>("bonuses/quarters", {
      params: request,
    });
  });
};

//TODO TYPES
export const useCloseQuarter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) => {
      return api.patch<any>("bonuses/quarters/close", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["bonuses", "quarter"]);
      },
    }
  );
};

export const useGetFactorZ = (requset: TGetBonusesFactorZRequest) => {
  return useQuery(["bonuses", "factorZ", requset], () => {
    return api.get<TGetBonusesFactorZResposne>(
      "bonuses/quarters/coefficients-calculations",
      {
        params: requset,
      }
    );
  });
};

//TODO TYPES
export const useExportCoefficients = () => {
  return useMutation((request: any) => {
    return api.get<any>(`bonuses/quarters/coefficients-calculations/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
