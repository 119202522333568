export enum MonitoringDataFormFieldsEnum {
  Title = "title",
  CityIds = "cityIds",
  Number = "number",
  PrimaryRequirements = "primaryRequirements",
  AdditionalRequirements = "additionalRequirements",
  CarProductionDateFrom = "carProductionDateFrom",
  CarProductionDateTo = "carProductionDateTo",
  CarSearchPeriodFrom = "carSearchPeriodFrom",
  CarSearchPeriodTo = "carSearchPeriodTo",
  DateOfTheStudyFrom = "dateOfTheStudyFrom",
  DateOfTheStudyTo = "dateOfTheStudyTo",
  DealerIds = "dealerIds",
  PhotoIds = "photoIds",
  FileIds = "fileIds",

  GenerationsIds = "generationIds",
  PartName = "partName",
  CatalogueNumber = "catalogueNumber",

  Parts = "parts",
}

export const ALL_SELECTED = "all";
export const MOSCOW = "Moscow";
