import { LoginForm } from "@/components/Login/LoginForm";
import { TOnSubmitLoginFormCb } from "@/components/Login/LoginForm/hooks";
import { useAuthLogin, useAuthLoginAd } from "@/helpers/api/auth/hooks";
import IdentityLayout from "@/layouts/IdentityLayout";
import { AxiosError } from "axios";
import React from "react";
import { useNotification } from "@/hooks/useNotification";
import { loginToTokenService } from "@/helpers/api/loginToTokenService";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const { mutateAsync: authLogin } = useAuthLogin();
  const { mutateAsync: authLoginAd } = useAuthLoginAd();

  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const redirectTo = loginToTokenService.getToken();

  const onSubmitHandler: TOnSubmitLoginFormCb = async (data) => {
    const payload = {
      username: data.login,
      password: data.password,
    };

    const onSuccessOptions = {
      onSuccess: () => {
        window.location.assign(redirectTo);
      },
    };

    try {
      if (data.isAdLogin) {
        await authLoginAd(payload, onSuccessOptions);
      } else {
        await authLogin(payload, onSuccessOptions);
      }
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      if (errorTyped?.response?.data?.message === "Invalid password") {
        createNotificationError(t("authorization.invalidPassword") || "");
      }
      // @ts-ignore
      else if (errorTyped?.response?.data?.error === "Not Found") {
        createNotificationError(t("authorization.emailNotFound") || "");
      } else if (errorTyped?.response?.data?.message === "User is not active") {
        createNotificationError(t("authorization.accountInActive") || "");
      } else if (errorTyped?.response?.data?.message === "Invalid email and/or password") {
        createNotificationError(t("authorization.invalidLoginParams") || "");
      } else if (errorTyped) {
        createNotificationError(errorTyped?.response?.data?.message || "");
      }
    }
  };

  return (
    <IdentityLayout>
      <LoginForm onSubmit={onSubmitHandler}/>
    </IdentityLayout>
  );
};

export default Login;
