import { TInformationLettersBatchRecord } from "@/helpers/api/InformationalLetters/types";
import {
  TOnSubmitFormAsyncCb,
  TOnSubmitFormCb,
  useForm,
} from "@/hooks/useForm";
import {
  TInformationLettersDataFieldsSuccessValues,
  TInformationLettersDataFieldsValues,
} from "../InformationLettersDataFields/types";
import {
  DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
  informationLettersFieldsSchema,
  successInformationLettersCreateDTO,
} from "../InformationLettersDataFields/utils";

export type TOnSubmitIPCreateFormCb = TOnSubmitFormAsyncCb<any>;
export type TOnSubmitIPEditFormCb = TOnSubmitFormAsyncCb<{
  id: string;
  patch: any;
}>;

export type TOnSubmitIPEditBatchFormCb =
  TOnSubmitFormAsyncCb<TInformationLettersBatchRecord>;

export const useCreateIPForm = (onSubmit: TOnSubmitIPCreateFormCb) => {
  const onSubmitHandler: TOnSubmitFormCb<
    TInformationLettersDataFieldsSuccessValues
  > = async (successValues) => {
    const createRecord = await successInformationLettersCreateDTO(
      successValues
    );

    await onSubmit(createRecord);
  };

  return useForm<
    TInformationLettersDataFieldsValues,
    TInformationLettersDataFieldsSuccessValues
  >({
    validateFormSchema: informationLettersFieldsSchema(),
    defaultValues: DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};
