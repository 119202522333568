export const transformDottedDateToIso = (date: string): string =>
  date.split(".").reverse().join("-");

export const transformIsoDateToDotted = (date: string): string =>
  date.split("-").reverse().join(".");

const timeZeroFormat = (value: number): string =>
  value < 10 ? `0${value}` : String(value);

export const transformDateToDotted = (date: string): string => {
  if (!date) return "";

  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  return `${timeZeroFormat(day)}.${timeZeroFormat(month)}.${year}`;
};
