import { colors, media } from "@/helpers";
import React from "react";
import styled from "styled-components";

export const StyledHeaderContainer = styled.div`
  grid-area: h;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  height: 48px;
  ${media.tablet} {
    padding: 0 64px;
  }
  background: ${colors.black};
  max-width: 100%;
`;

export const StyledLogo = styled.svg`
  height: 13px;
  max-width: 60px;
`;

export const MobileHeader: React.FC = () => {
  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledLogo>
          <use xlinkHref="#logo" />
        </StyledLogo>
      </StyledHeader>
    </StyledHeaderContainer>
  );
};
