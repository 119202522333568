import { colors } from "@/helpers";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Col, Row } from "antd";
import styled from "styled-components";

export const StyledMonitoringDataFields = styled.div`
    margin-bottom: 64px;
`;

export const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledDateContainer = styled.div`
    margin: 16px 0 32px 0;
`;

export const StyledColDate = styled(Col).attrs({ span: 8 })`
    display: flex;
    align-items: flex-end;
`;

export const StyledDash = styled.span`
    width: 12px;
    height: 1px;
    background-color: ${colors.gray4};
    margin: 0 10px 14px 10px;
`;

export const StyledIconButton = styled(IconButton)`
    margin-top: 20px;
`;

export const StyledRow = styled(Row)`
    margin-bottom: 24px;
`;


