import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledContent = styled.div`
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;

  background-color: ${colors.white};
`;

export const StyledSearchWrapper = styled.div`
    padding: 32px 0 0 32px;
    width: 100%;

`;