import { InputField } from "@/packages/formElements/fields/InputField";
import { FormProvider } from "react-hook-form";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import React, { useMemo, useState } from "react";
import {
  StyledConfirm,
  StyledConfirmContainer,
  StyledFormGrid,
  StyledGroup,
  StyledGroupTitle,
  StyledStageContainer,
} from "./index.styles";
import { useFormValues } from "./hooks";

import { RadioGroup } from "@/packages/radio-group/RadioGroup";
import { Radio } from "@/packages/radio/Radio";
import { Space } from "antd";
import { Button } from "@/packages/button/Button";
import { useStartAuditFilling } from "@/helpers/api/audit-fill/hooks";
import { TGetAuditByIdResponse } from "@/helpers/api/audit-fill/types";
import { useGetUserList } from "@/helpers/api/users/hooks";
import { SimpleConfirmModal } from "@/components/SimpleConfirmModal";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { useModal } from "@/hooks/useModal";
import { useNavigate } from "react-router-dom";
import { AUDIT_LIST } from "@/constants/routes";
import { DatepickerField } from "@/packages/formElements/fields/DatepickerField";
import { useTranslation } from "react-i18next";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { useNotification } from "@/hooks/useNotification";

interface IFillAuditFirstStage {
  id: number;
  data: TGetAuditByIdResponse;
  onCompleteStage: (stage) => void;
}

enum AuditTypes {
  IN_HOUSE = "Очно",
  ONLINE = "Онлайн",
}

export const FillAuditFirstStage: React.FC<IFillAuditFirstStage> = (props) => {
  const { id, data } = props;
  const { onCompleteStage } = props;
  const { createNotificationError } =
    useNotification();
  const { mutate: startFilling, isLoading: isCreating } =
    useStartAuditFilling();
  const [isDeligate, setIsDeligate] = useState(false);
  const [selectedType, setSelectedType] = useState(AuditTypes.IN_HOUSE);
  const navigate = useNavigate();
  const { data: users, isLoading: isUsersLoading } = useGetUserList({
    dealerId: data.dealerId,
    permissionSlug: "audit.implementation",
    permissionAction: "update",
  });
  const { t } = useTranslation();
  const mappedUsers = useMemo(
    () =>
      users?.data.data
        .filter((item) => item.isActive === true)
        .map((item) => ({
          label: `${item.firstName} ${item.lastName} / ${item.role.name}`,
          value: item.id,
        })) || [],
    [users]
  );

  const formSubmit = (value) => {
    startFilling({
      patch: {
        id,
        data: {
          ...value,
          type: selectedType,
          delegated: isDeligate,
        },
      },
      onSuccess: () => {
        onCompleteStage(1);
        setIsDeligate(false);
      },
      onError: () => {
        createNotificationError('Аудит еще не начат');
      },
    });
  };
  const { form, onSubmitHandler } = useFormValues(formSubmit, data);

  const {
    modalState: ConfirmModalState,
    openModal: ConfirmModalOpen,
    closeModal: ConfirmModalClose,
  } = useModal();

  const handleDeligate = () => {
    ConfirmModalOpen();
    setIsDeligate(true);
  };

  return (
    <>
      <StyledStageContainer>
        <FormProvider {...form}>
          <StyledFormGrid>
            <FormInputField
              name="title"
              label={t("audits.list.name") || ""}
            />
            <InputField
              name="dealerName"
              value={data.dealerName}
              label={t("audits.list.dealership") || ""}
              readOnly={true}
            />
            <FormSelect
              name="responsibleId"
              label={t("audits.list.representative") || ""}
              placeholder={t("audits.list.representativeDescr") || ""}
              options={mappedUsers}
            />
            <DatepickerField
              label={t("audits.list.auditDate") || ""}
              value={new Date()}
              readOnly={true}
            />
          </StyledFormGrid>
          <StyledGroup>
            <StyledGroupTitle weight={700} size={14}>
              {t("audits.list.auditType") || ""}
            </StyledGroupTitle>
            <RadioGroup
              isColumn={true}
              defaultValue={selectedType}
              onChange={(value) => setSelectedType(value as AuditTypes)}
            >
              <Radio
                groupValue={AuditTypes.IN_HOUSE}
                label={t("audits.list.fullTime") || ""}
              />
              <Radio
                groupValue={AuditTypes.ONLINE}
                label={t("audits.list.online") || ""}
              />
            </RadioGroup>
          </StyledGroup>
          <Space>
            <Button onClick={() => navigate(AUDIT_LIST)}>
              {t("audits.list.cancel") || ""}
            </Button>
            {selectedType !== AuditTypes.ONLINE ? (
              <Button theme="primary" color="brand" onClick={onSubmitHandler}>
                {t("audits.list.filling") || ""}
              </Button>
            ) : (
              <></>
            )}

            {selectedType === AuditTypes.ONLINE ? (
              <Button theme="primary" color="brand" onClick={handleDeligate}>
                {t("audits.list.sendToFilling") || ""}
              </Button>
            ) : (
              <></>
            )}
          </Space>
        </FormProvider>
      </StyledStageContainer>
      <SimpleConfirmModal
        isOpen={ConfirmModalState.isOpen}
        onSubmit={onSubmitHandler}
        isLoadingConfirmButton={isCreating}
        onClose={() => {
          setIsDeligate(false);
          ConfirmModalClose();
        }}
      >
        <StyledConfirmContainer>
          <StyledConfirm>
            <Paragraph size={18}>
              {t("audits.list.sendConfirm") || ""}
            </Paragraph>
          </StyledConfirm>
        </StyledConfirmContainer>
      </SimpleConfirmModal>
    </>
  );
};
