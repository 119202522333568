import styled from "styled-components";
import { colors, media } from "@/helpers";

export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  ${media.tablet} {
    min-width: 423px;
  }
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledFieldError = styled.li`
    margin: 4px 0 0 0;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: ${colors.red200};
`;
