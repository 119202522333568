import { TTransportClaimsCreateRecord } from "@/helpers/api/transportComplaints/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import { transformCreateFields, transformResponseFields } from "./../TransportComplaintsFormCreate/utils";
import { formComplaintSchema } from "@/components/TransportComplaints/TransportComplaintsFormCreate/hooks";

export interface ITransportComDetailFormValues
  extends Partial<TTransportClaimsCreateRecord> {
  id?: string | null;
}

export const DEFAULT_TRANSPORT_DETAIL_FORM_VALUES: ITransportComDetailFormValues =
  {
    id: null,
    isEllada: false,
    vin: "",
    codeCase: "",
    placeAcceptence: "",
    dateAcceptence: "",
    dealerId: "",
    oupts: [],
    tthCmr: [],
    vinPlate: [],
    generalView: [],
    viewDetail: [],
    photoDefect: [],
    other: [],
    kiaSng: [],
    openForViewing: "",
  };

export type TOnSubmitTransportComDetailFormCb = (
  values: ITransportComDetailFormValues
) => void;

export const useDetailTCForm = (options: {
  detailRecord: ITransportComDetailFormValues;
  onSubmit?: TOnSubmitTransportComDetailFormCb;
}) => {
  const { detailRecord, onSubmit } = options;
  const onSubmitHandler = (values: any) => {
    const editRecord = transformCreateFields(values);

    if (editRecord) {
      //@ts-ignore
      onSubmit({
        id: detailRecord.id,
        //@ts-ignore
        patch: editRecord,
      });
    }
  };

  const result = useForm<ITransportComDetailFormValues>({
    validateFormSchema: formComplaintSchema(),
    defaultValues: detailRecord ? transformResponseFields(detailRecord) : DEFAULT_TRANSPORT_DETAIL_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (detailRecord) {
      reset(transformResponseFields(detailRecord));
    }
  }, [detailRecord, reset]);

  return result;
};