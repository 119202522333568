import {
  useDownloadTransportClaimsArchive,
} from "@/helpers/api/transportComplaints/hooks";
import { StyledIconButton, StyledLink } from "@/components/TransportComplaints/TransportComplaintsTable/index.styles";
import { Loader } from "@/packages/loader/Loader";
import React from "react";
import { TransportClaimDto, TransportClaimFileType } from "@/components/TransportComplaints/types";

type FileDownloadProps = {
  data: TransportClaimDto;
  type: TransportClaimFileType;
  fileName: string;
  arrayKey: keyof TransportClaimDto;
}
export const FileDownload = ({ data, type, fileName, arrayKey }: FileDownloadProps) => {
  const { handleDownload, isLoading, isCategoryArchiveNotEmpty } = useDownloadTransportClaimsArchive();
  return (
    <>
      {
        isCategoryArchiveNotEmpty(data, arrayKey) &&
        <>
          {
            !isLoading ?
              <StyledLink>
                <StyledIconButton
                  onClick={() => handleDownload(data.id, type, `${fileName}`)}
                />
              </StyledLink>
              :
              <Loader isCentered/>
          }
        </>
      }
    </>
  )
}