import * as yup from "yup";

import { TNewsCreateRequest } from "@/helpers/api/news/types";

import { NewsDataFormFieldsEnum, TNewsDataFieldsValues } from "./types";
import { t } from "i18next";

export const newsFieldsSchema = () => {
  return yup
    .object({
      [NewsDataFormFieldsEnum.Name]: yup
        .string()
        .required(t("news.addNewsForm.validation.name") || "")
        .min(3, t("news.addNewsForm.validation.nameMin") || "")
        .max(30, t("news.addNewsForm.validation.nameMax") || ""),
      [NewsDataFormFieldsEnum.Description]: yup
        .string()
        .required(t("news.addNewsForm.validation.description") || "")
        .min(3, t("news.addNewsForm.validation.descriptionMin") || "")
        .max(100, t("news.addNewsForm.validation.descriptionMax") || ""),
      [NewsDataFormFieldsEnum.Text]: yup
        .string()
        .required(t("news.addNewsForm.validation.text") || "")
        .min(3, t("news.addNewsForm.validation.textMin") || "")
        .max(100000, t("news.addNewsForm.validation.textMax") || ""),
      [NewsDataFormFieldsEnum.FileId]: yup.object().nullable(),
    })
    .required();
};

export type TNewsFieldsDataPart = Omit<TNewsCreateRequest, "id">;

export const newsFormValuesToDTO = (
  values: TNewsDataFieldsValues
): TNewsFieldsDataPart => {
  const { name, description, text, fileId } = values;

  const returningObj: any = {
    name: name || "",
    description: description || "",
    text: text || "",
  };

  if (fileId?.id) {
    returningObj.fileId = fileId?.id;
  }

  return returningObj;
};

export const DEFAULT_NEWS_DATA_FORM_VALUES: TNewsFieldsDataPart = {
  [NewsDataFormFieldsEnum.Name]: "",
  [NewsDataFormFieldsEnum.Description]: "",
  [NewsDataFormFieldsEnum.Text]: "",
  [NewsDataFormFieldsEnum.FileId]: "",
};
