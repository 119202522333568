import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TransportComplaintsDetail } from "@/components/TransportComplaints/TransportComplaintsDetail";
import {
  useGetTransportClaimsById,
} from "@/helpers/api/transportComplaints/hooks";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import React from "react";
import { useParams } from "react-router-dom";
import {
  RESOURCE_ACTIONS,
  RESOURCE_NAME_TCLAIMS,
} from "../TransportComplaints";

const TransportComplaintsDetails: React.FC = () => {
  const { id } = useParams();
  const { data: transportClaimsItem, isLoading } = useGetTransportClaimsById({
    id: id?.replace('&goBack', '') ?? "0",
  });

  return (
    <DashboardLayout toBack={id?.includes('&goBack') ? '/transport-claims' : -1} title=" ">
      {!isLoading && (
        <PermissionsAdapter
          resourceName={RESOURCE_NAME_TCLAIMS}
          resourceAction={RESOURCE_ACTIONS.READ}
          fallback={() => <div>No Access</div>}
        >
          {transportClaimsItem && (
            <TransportComplaintsDetail
              detailRecord={transportClaimsItem?.data}
              isReadOnly={true}
            />
          )}
        </PermissionsAdapter>
      )}
    </DashboardLayout>
  );
};

export default TransportComplaintsDetails;
