import React from "react";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { useActiveTab } from "@/hooks/useActiveTab";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { SellsTab } from "@/components/Bonuses/Reports/SellsTab";
import { BonusesCalculation } from "@/components/Bonuses/BonusesCalculation";
import { useTranslation } from "react-i18next";

export const BonusesReportsView: React.FC = () => {
  const { t } = useTranslation();

  const tabs: ITab[] = [
    {
      name: t("bonuses.reportsView.sells") || "",
      slug: "sells",
    },
    {
      name: t("bonuses.reportsView.bonuses") || "",
      slug: "bonuses",
    },
  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  return (
    <DashboardLayout
      title={t("bonuses.reportsView.title") || ""}
      description={t("bonuses.reportsView.description") || ""}
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
        type="card"
      />
      {
        {
          sells: <SellsTab />,
          bonuses: <BonusesCalculation />,
        }[activeTab.slug]
      }
    </DashboardLayout>
  );
};
