import React from "react";
import styled from "styled-components";

import { Modal, IModal } from "@/packages/modal/Modal";

interface ISuccessChangeModal
  extends Pick<IModal, "isOpen" | "onSubmit" | "onCancel"> {
  isRoleChanged: boolean;
}

const StyledModalText = styled.div`
  text-align: center;
`;

export const SuccessChangeModal: React.FC<ISuccessChangeModal> = (props) => {
  const { isRoleChanged, ...rest } = props;

  return (
    <Modal
      {...rest}
      size="small"
      title=""
      confirmButtonText="Закрыть"
      showCancelButton={false}
    >
      <StyledModalText>Ваши данные успешно изменены!</StyledModalText>
      {isRoleChanged ? (
        <StyledModalText>
          Роль изменится после подтверждения руководителя отдела, вы получите
          уведомление об изменении роли.
        </StyledModalText>
      ) : (
        <></>
      )}
    </Modal>
  );
};
