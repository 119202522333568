import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { TitleDivider } from "@/packages/TitleDivider";
import { Row } from "antd";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { TitleFieldWrapper } from "../../KiaFlexOrders/NewCarFIelds/index.styles";
import { useCreateDowntimeForm, useEditDowntimeForm } from "./hooks";
import { useTranslation } from "react-i18next";

export interface IDowntimeModalProps
  extends Pick<IModal, "onCancel" | "isOpen"> {
  record?: any;
  onSubmit: any;
  mode?: "create" | "edit";
}

const _DowntimeModal: React.FC<IDowntimeModalProps> = (props) => {
  const { onSubmit, record, mode = "create", ...rest } = props;

  const { form, reset, submit } = useCreateDowntimeForm(onSubmit);

  const { form: editForm, submit: editSubmit } = useEditDowntimeForm({
    onSubmit,
    record,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  return (
    <Modal
      {...rest}
      size="small"
      title={
        mode === "create"
          ? t("kiaFlex.addDowntime") || ""
          : t("kiaFlex.changeDowntime") || ""
      }
      confirmButtonText={
        mode === "create"
          ? t("kiaFlex.addDowntime") || ""
          : t("kiaFlex.changeDowntime") || ""
      }
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      onSubmit={mode === "create" ? submit : editSubmit}
    >
      {mode === "create" ? (
        <FormProvider {...form}>
          <TitleFieldWrapper>
            <Row>
              <Paragraph size={14}>{t("kiaFlex.downtimeName") || ""}</Paragraph>
            </Row>

            <TitleDivider />
          </TitleFieldWrapper>
          <FormInputField
            name="name"
            label={t("kiaFlex.downtimeName") || ""}
            description={t("kiaFlex.downtimeNameDescr") || ""}
          />
        </FormProvider>
      ) : (
        <FormProvider {...editForm}>
          <TitleFieldWrapper>
            <Row>
              <Paragraph size={14}>
                {t("kiaFlex.downtimeNameDescr") || ""}
              </Paragraph>
            </Row>

            <TitleDivider />
          </TitleFieldWrapper>
          <FormInputField
            name="name"
            label={t("kiaFlex.downtimeNameDescr") || ""}
            description={t("kiaFlex.downtimeNameDescr") || ""}
          />
        </FormProvider>
      )}
    </Modal>
  );
};
export const DowntimeModal = React.memo(_DowntimeModal);
