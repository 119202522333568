import React from "react";
import Row from "./Row";
import { TableDragItem } from "@/packages/draggableTable/DraggableTable";

interface ITreeComponent {
  data: any[];
  headerCallback?: (data: any) => string;
  hasInfo?: boolean;
  paramKey?: "parameters" | "params";
  sectionKey?: "section" | "sections";
  onParametrsModalOpen?: (id: string) => void;
  renderComponent: ({ data, level }) => JSX.Element;
  activeWarrantyKeys?: Array<string>;
  setActiveWarrantyKeys?: any;
  onDragEnd?: (item: TableDragItem) => void;
  isDraggable?: boolean;
}

const TreeComponent: React.FC<ITreeComponent> = ({
                                                   data,
                                                   hasInfo,
                                                   paramKey = "parameters",
                                                   onParametrsModalOpen,
                                                   renderComponent,
                                                   headerCallback,
                                                   activeWarrantyKeys,
                                                   setActiveWarrantyKeys,
                                                   onDragEnd,
                                                   isDraggable
                                                 }) => {
  return (
    <>
      {data?.map((item, $i) => {
        return (
          <Row
            index={$i}
            activeWarrantyKeys={activeWarrantyKeys}
            setActiveWarrantyKeys={setActiveWarrantyKeys}
            key={item.id}
            section={item}
            onParametrsModalOpen={onParametrsModalOpen}
            renderComponent={renderComponent}
            hasInfo={hasInfo}
            paramKey={paramKey}
            headerCallback={headerCallback}
            onDragEnd={onDragEnd}
            isDraggable={isDraggable}
          >
            <TreeComponent
              headerCallback={headerCallback}
              data={item.children}
              hasInfo={hasInfo}
              paramKey={paramKey}
              renderComponent={renderComponent}
              onParametrsModalOpen={onParametrsModalOpen}
              isDraggable={isDraggable}
            />
          </Row>
        );
      })}
    </>
  );
};

export default TreeComponent;
