import { NotificationsResultDealer } from "@/components/NotificationsResult/NotificationsResultDealer";
import { NotificationsResultKiaEmployee } from "@/components/NotificationsResult/NotificationsResultKiaEmployee";
import { NotificationsResultMonitoringNew } from "@/components/NotificationsResult/NotificationsResultMonitoringNew";
import { NotificationsResultSimple } from "@/components/NotificationsResult/NotificationsResultSimple";
import { NotificationsResultUserDataChange } from "@/components/NotificationsResult/NotificationsResultUserDataChange";
import { useGetNotificationById } from "@/helpers/api/notifications/hooks";
import {
  NotificationType,
  TChangeRoleNotification,
  TMonitoringNewProgramNotification,
  TNotificationWithStringId,
  TRegistrationByDealerNotification,
  TRegistrationByDKiaNotification,
} from "@/helpers/api/notifications/types";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";

export const NotificationsResult: FC = () => {
  const params = useParams();

  const notificationId = params.id as string;

  const { data: notification } = useGetNotificationById({
    id: notificationId,
  });

  const notificationData = notification?.data;

  const title = notificationData?.title || "";

  return (
    <DashboardLayout title={title} toBack="/notifications">
      {notificationData ? (
        notificationData?.type ===
        NotificationType.CONFIRM_REGISTRATION_BY_DEALER ? (
          <NotificationsResultDealer
            notification={notificationData as TRegistrationByDealerNotification}
          />
        ) : notificationData?.type ===
          NotificationType.CONFIRM_REGISTRATION_BY_KIA ? (
          <NotificationsResultKiaEmployee
            notification={notificationData as TRegistrationByDKiaNotification}
          />
        ) : notificationData?.type === NotificationType.CHANGE_ROLE ? (
          <NotificationsResultUserDataChange
            notification={notificationData as TChangeRoleNotification}
          />
        ) : notificationData?.type ===
          NotificationType.MONITORING_NEW_PROGRAM ? (
          <NotificationsResultMonitoringNew
            notification={notificationData as TMonitoringNewProgramNotification}
          />
        ) : (
          <NotificationsResultSimple
            notification={notificationData as TNotificationWithStringId}
          />
        )
      ) : null}
    </DashboardLayout>
  );
};
