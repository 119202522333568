import { usePatchMonitoringQualityCarStatus } from "@/helpers/api/monitoringQuality/hooks";
import { TMonitoringQualityCarsRecord } from "@/helpers/api/monitoringQuality/types";
import { formatDateToPeriod } from "@/helpers/utils";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { MenuProps } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { generateMenuItems, Statuses, statusesInTable, } from "../../MonitoringDealersInfo/utils";
import { StyledTable } from "./index.styles";
import { MonitoringCarsTableExpandDataForm } from "./MonitoringCarsTableExpandDataForm";
import { generateExpandDataCars } from "./utils";
import { usePermission } from "@/hooks/usePermission";

export interface IMockMonitoring {
  id: string;
  vin: string;
  model: string;
  dateProduction: string;
  carSearchPeriod: string;
  dateSubmission: string;
  mileage: string;
  dealer: string;
  codeDealer: string;
  city: string;
  status: string;
}

export interface IMonitoringCarsTable {
  monitoringQualityCarsList: TMonitoringQualityCarsRecord[] | any;
  onSort: (header: HeaderArgType, order: SortOrder) => void;
  refetchRequestCars: any;
}

export const MonitoringCarsTable: React.FC<IMonitoringCarsTable> = (props) => {
  const { monitoringQualityCarsList, onSort, refetchRequestCars } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { hasAccess: hasEdit } = usePermission(
    "quality-monitoring.request-cars",
    "update"
  );
  const clickHandler = () => {
    navigate("/monitoring-quality/edit");
  };

  const {
    mutateAsync: updateStatusMonitoringCar,
    isLoading: isStatusUpdatingLoading,
  } = usePatchMonitoringQualityCarStatus();

  const updateStatusAsync = async (id: string, status: Statuses) => {
    try {
      await updateStatusMonitoringCar({ id, patch: status });
      createNotificationSuccess(t("park.statusSuccess") || "");
      refetchRequestCars();
    } catch (error: any) {
      createNotificationError(
        error.response?.data.message
          ? t(`monitoring.errorFromServer.${error.response?.data.message}`)
          : error.message || t("park.statusError") || ""
      );
    }
  };

  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    if (typeof key === "string") {
      const id = key.split(",")[1];
      switch (key.split(",")[0]) {
        case Statuses.TO_BE_AGREED:
          updateStatusAsync(id, Statuses.TO_BE_AGREED);
          break;
        case Statuses.APPROVED:
          updateStatusAsync(id, Statuses.APPROVED);
          break;
        case Statuses.SENT_FOR_REVISION:
          updateStatusAsync(id, Statuses.SENT_FOR_REVISION);
          break;
        case Statuses.WITHDRAWN:
          updateStatusAsync(id, Statuses.WITHDRAWN);
          break;
        case Statuses.REJECTED:
          updateStatusAsync(id, Statuses.REJECTED);
          break;
        default:
          navigate(`${key}`);
          break;
      }
    }
  };

  const monitoringQualityCars = monitoringQualityCarsList?.map((qualityCar) => {
    const {
      id,
      generation,
      qualityMonitoring,
      dealer,
      vin,
      mileage,
      status,
      files,
      unreadMessagesAmount,
      createdAt: qualityCarCreatedAt,
    } = qualityCar;
    const {
      carSearchPeriodFrom,
      carSearchPeriodTo,
    } = qualityMonitoring;

    return {
      id,
      model: `${generation.model.name} ${generation.name} ${
        generation?.restylingName || ""
      }`,

      carSearchPeriod: formatDateToPeriod(
        carSearchPeriodFrom,
        carSearchPeriodTo
      ),
      dealer: dealer.merchantName,
      dealerCode: dealer.warrantyCode,
      city: dealer.city.name,
      vin,
      mileage,
      status: statusesInTable.find((x) => x.value === status) || "",
      carProductionDateStart: generation.model.productionYearStart,
      carProductionDateEnd: generation.model.productionYearEnd,
      programDuration: new Date(qualityCarCreatedAt).toLocaleDateString(),
      expandedData: generateExpandDataCars(qualityCar),
      files,
      unreadMessagesAmount,
      dropdownProps: generateMenuItems({
        requestCarStatus: status,
        id,
        isLoading: isStatusUpdatingLoading,
        profileType: profile?.type,
        permission: hasEdit,
      }),
    };
  });
  const dealerHeaders = [
    {
      Header: t("kiaFlex.vin") || "",
      accessor: "vin",
      isSortable: true,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("monitoring.autoModel") || "",
      accessor: "model",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.creationDate") || "",
      accessor: "carProductionDate",
      isSortable: true,
      minWidth: 95,
      maxWidth: 95,
      Cell: ({ row: { original } }) => {
        return original.carProductionDateStart + ' - ' + (original.carProductionDateEnd ? original.carProductionDateEnd : '...');
      }
    },
    {
      Header: t("monitoring.submission") || "",
      accessor: "programDuration",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.mileage") || "",
      accessor: "mileage",
      isSortable: true,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: t("monitoring.dealer") || "",
      accessor: "dealer",
      isSortable: true,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("monitoring.dealerCode") || "",
      accessor: "dealerCode",
      isSortable: true,
      minWidth: 65,
      maxWidth: 65,
    },
    {
      Header: t("monitoring.city") || "",
      accessor: "city",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.status") || "",
      accessor: "status",
      isSortable: true,
      minWidth: 100,
      maxWidth: 110,

      Cell: ({ value }) => {
        let { label, variantBtn } = value;
        switch (label) {
          case "Утверждена":
            label = t("monitoring.approved") || "";
            break;
          case "Отправлена на доработку":
            label = t("monitoring.sent_for_revision") || "";
            break;
          case "На согласовании":
            label = t("monitoring.to_be_agreed") || "";
            break;
          case "Отклонена":
            label = t("monitoring.rejected") || "";
            break;
          case "Отозвана":
            label = t("monitoring.withdrawn") || "";
            break;
        }
        return (
          <span
            style={{
              padding: "4px 8px",
              background: variantBtn.background,
              borderRadius: "4px",
              textAlign: "center",
              color: variantBtn.color,
            }}
          >
            {label}
          </span>
        );
      },
    },
  ];

  const kiaHeaders = [
    {
      Header: t("kiaFlex.vin") || "",
      accessor: "vin",
      isSortable: true,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("monitoring.autoModel") || "",
      accessor: "model",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.creationDate") || "",
      accessor: "carProductionDate",
      isSortable: true,
      minWidth: 95,
      maxWidth: 95,
      Cell: ({ row: { original } }) => {
        return original.carProductionDateStart + ' - ' + (original.carProductionDateEnd ? original.carProductionDateEnd : '...');
      }
    },
    {
      Header: t("monitoring.submission") || "",
      accessor: "programDuration",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.mileage") || "",
      accessor: "mileage",
      isSortable: true,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: t("monitoring.dealer") || "",
      accessor: "dealer",
      isSortable: true,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("monitoring.dealerCode") || "",
      accessor: "dealerCode",
      isSortable: true,
      minWidth: 65,
      maxWidth: 65,
    },
    {
      Header: t("monitoring.city") || "",
      accessor: "city",
      isSortable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      Header: t("monitoring.status") || "",
      accessor: "status",
      isSortable: true,
      minWidth: 100,
      maxWidth: 110,

      Cell: ({ value }) => {
        let { label, variantBtn } = value;
        switch (label) {
          case "Утверждена":
            label = t("monitoring.approved") || "";
            break;
          case "Отправлена на доработку":
            label = t("monitoring.sent_for_revision") || "";
            break;
          case "На согласовании":
            label = t("monitoring.to_be_agreed") || "";
            break;
          case "Отклонена":
            label = t("monitoring.rejected") || "";
            break;
          case "Отозвана":
            label = t("monitoring.withdrawn") || "";
            break;
        }
        return (
          <span
            style={{
              padding: "4px 8px",
              background: variantBtn.background,
              borderRadius: "4px",
              textAlign: "center",
              color: variantBtn.color,
            }}
          >
            {label}
          </span>
        );
      },
    },
  ];

  const isKia = profile?.type === 'kia';

  return (
    <StyledTable
      headers={isKia ? kiaHeaders : dealerHeaders}
      data={monitoringQualityCars ?? []}
      renderElement={(row) => {
        return (
          <MonitoringCarsTableExpandDataForm
            monitoringCarId={row.original.id}
            expandedData={row.original.expandedData}
            refetchRequestCars={refetchRequestCars}
          />
        );
      }}
      buttonIcons={["vertical-dots"]}
      alignCell="start"
      alignHeader="start"
      isButtonDropdown
      dropdownProps={{ handleMenuClick }}
      onButtonClick={() => clickHandler}
      onSort={onSort}
      autoOverflow
    />
  );
};
