import { IModal, Modal } from '@/packages/modal/Modal';
import React from 'react';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import styled from 'styled-components';

export interface ICompleteSetEditModal
    extends Pick<IModal, 'onCancel' | 'isOpen'> {
}

const StyledParagraph = styled(Paragraph)`
    margin-top: 20px;
    margin-bottom: 10px;
`
const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const InfoModal: React.FC<ICompleteSetEditModal> = (
    props,
) => {
    return (
        <Modal
            {...props}
            size='large'
            title='Пояснения к Сеткам ТО автомобилей KIA, обслуживаемых предприятиями дилерской сети ООО "Киа Россия и СНГ"'
            showCancelButton={false}
        >
            Плановое ТО автомобилей Kia необходимо проводить каждые 12 месяцев или 15000 км (в зависимости от того, что
            наступит ранее).
            Технические жидкости и расходные материалы (фильтры, свечи зажигания и т.п.) нуждаются в более частой замене
            при тяжелых условиях эксплуатации. Перечень условий эксплуатации, характеризуемый как “тяжелые условия”
            указаны в Руководстве по эксплуатации автомобиля.
            Замену моторного масла в бензиновом турбированном двигателе с непосредственным впрыском топлива (T-GDI)
            следует производить через каждые 6 месяцев или 7500 км (в зависимости от того, что наступит ранее).
            При расчете Сетки Технического Обслуживания использован перечень рекомендованных операций, указанный в
            Руководстве по эксплуатации автомобиля.
            Выполнение работ по очистке, регулировке или замене, определённые в результате обязательного осмотра,
            проводятся по согласованию с владельцем автомобиля и за его счет.
            При выполнении рекомендованных работ по техническому обслуживанию автомобилей Kia может возникнуть
            необходимость в проведении внеплановых дополнительных работ (снятие/установка защиты картера, демонтаж
            дополнительного оборудования и т.п.).
            Дополнительные работы проводятся по согласованию с владельцем автомобиля и за его счет.

            <StyledParagraph size={16} weight={500}>
                Примечания к отдельным видам работ
            </StyledParagraph>
            <StyledList>
                <li>
                    Во избежание возможных протечек масла в процессе эксплуатации, после откручивания сливной/заливной
                    пробки следует установить новое уплотнительное кольцо.
                </li>
                <li>
                    Топливный фильтр бензинового двигателя считается компонентом, не требующим технического
                    обслуживания. Однако при использовании топлива низкого качества возможно его ускоренное засорение.
                    Топливный фильтр необходимо проверить при поступлении жалоб на потерю мощности и/или и затрудненного
                    запуска двигателя, а также при наличии неисправностей в системе контроля токсичности отработавших
                    газов.
                </li>
                <li>
                    Топливный фильтр дизельного двигателя может быть оснащен водоотделителем, который накапливает воду,
                    содержащуюся в дизельном топливе. Необходимо регулярно сливать воду из водоотделителя и проверять
                    состояние датчика наличия воды в фильтре.
                </li>
                <li>
                    Тормозная жидкость очень гигроскопична и поглощает влагу из воздуха. Тормозную жидкость необходимо
                    регулярно проверять на содержание в ней воды и производить полную замену в случае превышения порога
                    максимальной концентрации.
                </li>
                <li>
                    Изменение заданных зазоров между деталями клапанного механизма могут служить причиной неустойчивой
                    работы и/или вибрации двигателя. Необходимо регулярно прослушивать клапанный механизм на отсутствие
                    чрезмерного шума при различных режимах работы двигателя.
                </li>
                <li>
                    Внутренний аккумулятор модуля “ЭРА-ГЛОНАСС” необходимо менять каждые 3 года вне зависимости от
                    пробега автомобиля. Работа по замене аккумулятора “ЭРА-ГЛОНАСС” не включена в стоимость работ
                    планового ТО и проводится дополнительно по согласованию с владельцем автомобиля.
                </li>
            </StyledList>
        </Modal>
    );
};
