import React, { useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import {
  useCreateAuditTemplate,
  useExportAuditsTemplates,
  useGetAuditsTemplatesList,
  useGetAuditTemplateExample,
} from "@/helpers/api/audits/hooks";

import { useRequestParams } from "@/hooks/useRequestParams";

import { useModal } from "@/hooks/useModal";
import { UploadAuditTemplateModal } from "@/components/Audits/UploadAuditTemplateModal";

import { AuditTemplateTable } from "@/components/Audits/AuditTemplateTable";
import {
  StyledButtons,
  StyledButtonsTop,
  StyledContentTitle, StyledFilterWrapper,
  StyledSelectField,
} from "@/components/Audits/AuditTemplateTable/index.styles";
import { MOCK_AUDIT_FILTERS } from "@/components/Audits/AuditTemplateTable/helpers";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { usePagination } from "@/hooks/usePagination";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { useNotification } from "@/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { AUDIT_EDIT, AUDIT_VIEW } from "@/constants/routes";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { Loader } from "@/packages/loader/Loader";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { useDebounce } from "use-debounce";

export const AuditTemplates: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createNotificationError } = useNotification();
  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [debouncedSearchValue] = useDebounce(searchValue, 800);
  const { paginationParams, setPage } = usePagination();
  const { mutate: createAuditTemplate, isLoading: isTemplateCreating } =
    useCreateAuditTemplate();
  const { mutate: getTemplateFile, isLoading: isFileLoading } =
    useGetAuditTemplateExample();
  const [requestParams, setRequestParams] = useRequestParams({
    active: {
      value: "",
      type: "filter",
    },
    sortBy: {
      value: "createdAt",
      type: "sort",
    },
    sortAt: {
      value: "DESC",
      type: "sort",
    },
  });

  const { mutateAsync: exportAuditsTemplates } = useExportAuditsTemplates();

  const handleExportAuditsTemplates = () => {
    exportAuditsTemplates(
      {
        ...requestParamsMapper(requestParams),
        "sort[sortBy]": "createdAt",
        "sort[sortAt]": "DESC",
      },
      {
        onSuccess: (response) => {
          handleFileLoad(
            //@ts-ignore
            response.data,
            "audit_templates.xlsx"
          );
        },
      }
    );
  };

  const {
    data: auditTemplates,
    refetch: updateAuditList,
    isLoading,
  } = useGetAuditsTemplatesList(
    {
      ...requestParamsMapper(requestParams),
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
      'filter[title]': debouncedSearchValue,
    },
    true
  );

  const handleDownloadFile = () => {
    getTemplateFile(undefined, {
      onSuccess: (response) => {
        // @ts-ignore
        handleFileLoad(response.data, "audit_template.xlsx");
      },
    });
  };

  const {
    modalState,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModal();

  const handleSubmitUploadTemplate = () => {
    updateAuditList();
    closeUploadModal();
  };

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => {
      const copyPrev = { ...prev };
      Object.entries(copyPrev).forEach(([key, value]) => {
        if (value.type === "sort") {
          delete copyPrev[key];
        }
      });
      return {
        ...copyPrev,
        sortAt: {
          type: "sort",
          value: order ? order.toUpperCase() : null,
        },
        sortBy: {
          type: "sort",
          value: header.id,
        },
      };
    });
  };

  const handleCreateAuditTemplate = () => {
    createAuditTemplate({
      onSuccess: (response) => {
        handleOpenEdit(response.id);
      },
      onError: (error) => {
        createNotificationError(error.response?.data?.message || "");
      },
    });
  };

  const handleOpenEdit = (id: number) => {
    navigate(AUDIT_EDIT.replace(":id", id.toString()));
  };

  const handleViewOpen = (id: number) => {
    navigate(AUDIT_VIEW.replace(":id", id.toString()));
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={t("audits.templates.title") || ""}
        headerRight={
          <PermissionsAdapter
            resourceName="audit.templates"
            resourceAction="update"
          >
            <StyledButtonsTop>
              <Button
                color="brand"
                theme="secondary"
                size="small"
                onClick={handleDownloadFile}
                isLoading={isFileLoading}
              >
                {t("audits.templates.download") || ""}
              </Button>
              <Button
                color="brand"
                theme="secondary"
                size="small"
                onClick={openUploadModal}
              >
                {t("audits.templates.download_2") || ""}
              </Button>
              <Button
                color="brand"
                theme="primary"
                size="small"
                isLoading={isTemplateCreating}
                onClick={handleCreateAuditTemplate}
              >
                {t("audits.templates.create") || ""}
              </Button>
            </StyledButtonsTop>
          </PermissionsAdapter>
        }
      >
        <PermissionsAdapter
          resourceName="audit.templates"
          resourceAction="read"
          fallback={() => <span>No rights</span>}
        >
          <StyledContentTitle>
            <StyledFilterWrapper>
              <Search
                value={searchValue}
                onChange={(value) => setSearchValue(value)}
              />
              <StyledSelectField
                label={t("audits.templates.status") || ""}
                placeholder={t("audits.list.all") || ""}
                options={MOCK_AUDIT_FILTERS}
                onChange={(_, option) => {
                  if (!Array.isArray(option)) {
                    setRequestParams((prev) => ({
                      ...prev,
                      active: {
                        value: !option.value ? "" : option.value === "true",
                        type: "filter",
                      },
                    }));
                  }
                }}
              />
            </StyledFilterWrapper>
            <StyledButtons size={8}>
              <Tooltip content={t("audits.templates.save") || ""}>
                <IconButton
                  icon="save"
                  isSquared={true}
                  variant="primary"
                  color="white"
                  onClick={handleExportAuditsTemplates}
                />
              </Tooltip>
              <Tooltip
                content={t("audits.templates.copy") || ""}
                placement="bottom"
              >
                <IconButton
                  icon="share"
                  isSquared={true}
                  variant="primary"
                  color="white"
                  onClick={() => copyCurrentUrl()}
                />
              </Tooltip>
            </StyledButtons>
          </StyledContentTitle>
          {!isLoading ? (
            <AuditTemplateTable
              data={auditTemplates?.data}
              updateAuditList={updateAuditList}
              isLoading={isLoading}
              onSort={handleSort}
              hasPagination={
                !!auditTemplates?.data.meta?.pageCount &&
                auditTemplates?.data.meta?.pageCount > 1
              }
              paginationParams={{
                page: paginationParams.page,
                limit: auditTemplates?.data.meta?.pageCount || 1,
              }}
              setPage={setPage}
              onEditClick={handleOpenEdit}
              onViewClick={handleViewOpen}
            />
          ) : (
            <Loader size={48}/>
          )}
        </PermissionsAdapter>
      </DashboardLayout>
      <UploadAuditTemplateModal
        isOpen={modalState.isOpen}
        onSubmit={handleSubmitUploadTemplate}
        onCancel={closeUploadModal}
      />
    </>
  );
};
