import { TModificationRecord } from "@/helpers/api/modifications/types";
import { usePermission } from "@/hooks/usePermission";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { CompleteSetFields } from "../CompleteSetFields";
import { IEditCompleteSetPayload, useEditCompleteSetForm } from "./hooks";
import { TModelRecord } from "@/helpers/api/models/types";
import { CompleteSetFromFieldsEnum } from "@/components/Models/ModelsListTab/CompleteSetFields/types";
import { useGetPublicDealerList } from "@/helpers/api/vin/hooks";

export interface ICompleteSetEditModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  record?: TModificationRecord | any;
  onSubmit: (payload: IEditCompleteSetPayload) => void;
  parentModel?: TModelRecord;
}

export const CompleteSetEditModal: React.FC<ICompleteSetEditModal> = (
  props
) => {
  const { onSubmit, record, parentModel, ...rest } = props;

  //@ts-ignore
  const { hasAccess } = usePermission("model", "update");

  const { form, submit, isSubmitLoading, reset } = useEditCompleteSetForm({
    record,
    onSubmit,
  });

  const [mpv] = form.watch([CompleteSetFromFieldsEnum.Mpv]);

  const { data: getDecoderMPV, refetch } = useGetPublicDealerList({ mpv: mpv });

  useEffect(() => {
    form.setValue(
      CompleteSetFromFieldsEnum.FuelTypeId,
      getDecoderMPV?.data?.fuel?.id ? getDecoderMPV?.data?.fuel.id : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.TransmissionTypeId,
      getDecoderMPV?.data?.transmission?.id
        ? getDecoderMPV?.data?.transmission.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.EngineTypeId,
      getDecoderMPV?.data?.engineType?.id
        ? getDecoderMPV?.data?.engineType?.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.EngineVolumeId,
      getDecoderMPV?.data?.engineVolume?.id
        ? getDecoderMPV?.data?.engineVolume?.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.CarBodyTypeId,
      getDecoderMPV?.data?.body?.id ? getDecoderMPV?.data?.body?.id : null
    );
  }, [getDecoderMPV]);

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);
  return (
    <Modal
      {...rest}
      size="medium"
      title="Изменение модификации"
      confirmButtonText="Сохранить изменения"
      cancelButtonText="Отмена"
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
    >
      <TitleDivider size="medium">
        {`${record?.parentModel?.name} ${record?.parentModel?.modelYearStart} ${
          record?.parentModel?.modelYearEnd
            ? `- ${record?.parentModel?.modelYearEnd}`
            : "- ..."
        }  ${record?.parentGeneration?.name} ${
          record?.parentGeneration?.restylingName || ""
        } (${record?.parentGeneration?.number} поколение${
          record?.parentGeneration.isRestyling ? ", рестайлинг" : ""
        })  ${record?.parentGeneration?.generationYearStart || ""} 
        ${
          record?.parentGeneration?.generationYearEnd
            ? `- ${record?.parentGeneration?.generationYearEnd}`
            : ""
        }`}
      </TitleDivider>
      <FormProvider {...form}>
        <CompleteSetFields isReadOnly={!hasAccess} refetch={refetch} />
      </FormProvider>
    </Modal>
  );
};
