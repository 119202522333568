import React from "react";

import {
  SelectField,
  ISelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetTransmissionList } from "@/helpers/api/transmissions/hooks";

export interface ITransmissionTypeSelectFieldAdapter
  extends Omit<ISelectField, "options"> {}

export const TransmissionTypeSelectFieldAdapter: React.FC<
  ITransmissionTypeSelectFieldAdapter
> = (props) => {
  const { data: getTransmissionListResponse, isLoading } =
    useGetTransmissionList({
      page: 1,
      limit: 0,
    });

  const options =
    getTransmissionListResponse?.data.data.map(
      (fuelRecord) =>
        ({
          value: fuelRecord.id,
          label: fuelRecord?.name,
        } as ISelectOption)
    ) || [];

  return <SelectField options={options} loading={isLoading} {...props} />;
};
