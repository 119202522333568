import React, { useEffect, useState } from "react";
import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TEngineVolumeFromList, TEngineVolumeRecord } from "@/helpers/api/engineVolumes/types";
import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { TEngineVolumeForm, ENGINE_VOLUME_INITIAL_FORM } from "@/pages/Parts";
import { InputField } from "@/packages/formElements/fields/InputField";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export type TEngineVolumeEditModalSubmitPayload = {
  record: TEngineVolumeFromList;
  data: TEngineVolumeForm;
};
export interface IEngineVolumeEditModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (payload: TEngineVolumeEditModalSubmitPayload) => void;
  isLoadingSubmit: boolean;
  record?: TEngineVolumeFromList;
}

export const EngineVolumeEditModal: React.FC<IEngineVolumeEditModal> = (
  props
) => {
  const { onSubmit, isLoadingSubmit, record, ...restProps } = props;

  const { t } = useTranslation();

  const initialForm = {
    engineVolume: record?.name || 0,
    mpv: record?.mpv || "",
  };

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState(initialForm);

  const engineVolumeError = !form.engineVolume && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleVolumeChange = (value: number | null) =>
    value &&
    setForm((prev) => ({
      ...prev,
      engineVolume: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (
      !record ||
      !Object.values(form).every(Boolean) ||
      !(form.engineVolume > 0)
    ) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit({ record: record, data: form });
  };

  useEffect(() => {
    setForm(restProps.isOpen ? initialForm : ENGINE_VOLUME_INITIAL_FORM);
  }, [record, restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.changeEngineCapacity") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputNumberField
          label={t("parts.engineCapacity") || ""}
          description={t("parts.engineCapacityEnter") || ""}
          value={form.engineVolume}
          error={engineVolumeError}
          onChange={handleVolumeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
