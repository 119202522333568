import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { WithDrawalData } from "@/helpers/api/warranty-audit/withdrawal/types";
import { t } from "i18next";

export const getDefaultWarrantyWithdrawalTableHeaders = <
  TRecord extends object
>(options: {}): IHeaderItem<WithDrawalData>[] => {
  const {} = options;

  return [
    {
      Header: "№",
      accessor: "id",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
      Cell: ({ row, value }) => {
        // @ts-ignore
        return +row.id + 1;
      },
    },
    {
      Header: t("warrantyAudits.list.dealerCode") || "",
      accessor: "audit.dealer.warrantyCode",
      width: 150,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: t("warrantyAudits.list.ro") || "",
      accessor: "ro",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: "VIN",
      accessor: "vin",
      width: 170,
      minWidth: 170,
      maxWidth: 170,
    },
    {
      Header: t("warrantyAudits.list.claim") || "",
      accessor: "claim",
      width: 110,
      minWidth: 110,
      maxWidth: 100,
    },
    {
      Header: t("warrantyAudits.list.roOpenDate") || "",
      accessor: "roOpenDate",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("warrantyAudits.list.roCloseDate") || "",
      accessor: "roCloseDate",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("warrantyAudits.list.invoiceDate") || "",
      accessor: "invoiceDate",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("warrantyAudits.list.invoiceNumber") || "",
      accessor: "invoiceNumber",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: t("warrantyAudits.list.deacept") || "",
      accessor: "deacept",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      Cell: ({ row: { original }, value }) => {
        return <>{original?.answers[0]?.param?.code}</>;
      },
      Footer: () => 'Итого',
    },
    {
      Header: t("warrantyAudits.list.fullAmount") || "",
      accessor: "fullAmount",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original } }) => {
        return original.fullAmount.toFixed(2).replace('.', ',')
      },
      Footer: (props) => {
        let total = 0;
        props.data.forEach((data) => {
          total = total + data.fullAmount;
        });

        return total.toFixed(2).replace('.', ',');
      },
    },
  ];
};
