import { colors } from "@/helpers";
import { NestedTable } from "@/packages/nestedTable/NestedTable";
import styled, { css } from "styled-components";

export const TableHeader = styled.div`
  background-color: white;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTable = styled(NestedTable)`
  background-color: white;
  border: none;
  ${() => css`
    && .sc-jKvnYE {
      border-top: 1px solid ${colors.gray10};
    }
    && .sc-hZNxer {
    }
  `}
`;
