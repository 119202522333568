import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColumnWraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
