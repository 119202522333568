import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { ITab } from "@/packages/tabs/Tabs";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { MediaBaseModal } from "@/components/MediaBase/MediaBaseModal";
import { MediaBaseTabs } from "@/components/MediaBase/MediaBaseTabs";
import { MediaBaseTable } from "@/components/MediaBase/MediaBaseTable";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";

import { useModal } from "@/hooks/useModal";
import { useActiveTab } from "@/hooks/useActiveTab";
import { useRequestParams } from "@/hooks/useRequestParams";
import {
  useGetMediaList,
  useCreateMedia,
  useUpdateMedia,
  useDeleteMedia,
  useGetMediaListValidatedParams,
} from "@/helpers/api/mediaBase/hooks";
import { TMediaRecord } from "@/helpers/api/mediaBase/types";
import { MEDIA } from "@/helpers/consts";
import { useTranslation } from "react-i18next";

const tabs: ITab[] = [
  {
    name: "Все",
    slug: "all",
  },
  ...MEDIA,
];

export const MediaBase: React.FC = () => {
  const [activeTabSlug, _] = useQueryParam("activeTab", StringParam);
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useActiveTab(tabs);
  const { modalState, openModal, closeModal } = useModal();
  const [payload, setPayload] = useState<null | TMediaRecord>(null);
  const [requestParams, setRequestParams] = useRequestParams({
    take: {
      value: 1000,
      type: "page",
    },
    skip: {
      value: 0,
      type: "page",
    },
    name: {
      value: "",
      type: "sort",
    },
    type: {
      value: "",
      type: "sort",
    },
    "filter[type]": {
      value: "",
    },
  });

  const { data: response, isLoading: isGetMediaListLoading } =
    // @ts-ignore
    useGetMediaListValidatedParams(requestParams);
  const { mutateAsync: createMediaAsync, isLoading: isCreateMediaLoading } =
    useCreateMedia();
  const { mutateAsync: updateMediaAsync, isLoading: isUpdateMediaLoading } =
    useUpdateMedia();
  const { mutateAsync: deleteMeidaAsync } = useDeleteMedia();

  const handleActiveTabChange = (tab: ITab) => {
    setActiveTab(tab);

    setRequestParams((prev) => ({
      ...prev,
      "filter[type]": {
        value: tab.slug !== "all" ? tab.name : "",
      },
    }));
  };

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      [header.id]: {
        type: "sort",
        value: order ? order.toUpperCase() : "",
      },
    }));
  };

  const handleModalClose = () => {
    setPayload(null);
    closeModal();
  };

  const handleEditButtonClick = (data: TMediaRecord) => {
    setPayload(data);
    openModal();
  };

  const handleDeleteButtonClick = (data: TMediaRecord) => {
    deleteMeidaAsync({ ids: [data.id] });
  };

  const createMedia = async (data: TMediaRecord) => {
    try {
      await createMediaAsync({ name: data.name, type: data.type });
      handleModalClose();
    } catch (err) {
      console.log(err);
    }
  };

  const updateMedia = async (data: TMediaRecord) => {
    try {
      await updateMediaAsync({
        id: data.id,
        put: {
          name: data.name,
          type: data.type,
        },
      });
      handleModalClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = (data: TMediaRecord, isCreate: boolean) => {
    isCreate ? createMedia(data) : updateMedia(data);
  };

  useEffect(() => {
    if (activeTabSlug) {
      const tab = tabs.find((el) => el.slug === activeTabSlug);
      if (tab) {
        setRequestParams((prev) => ({
          ...prev,
          "filter[type]": {
            value: tab.slug !== "all" ? tab.name : "",
          },
        }));
      }
    }
  }, []);

  return (
    <DashboardLayout title={t("park.subTitle_5") || ""}>
      <MediaBaseTabs
        tabs={tabs}
        activeTab={activeTab}
        onAddButtonClick={openModal}
        onChange={handleActiveTabChange}
      />

      {response?.data.data ? (
        <MediaBaseTable
          onSort={handleSort}
          isLoading={isGetMediaListLoading}
          data={response.data?.data || []}
          onEditButtonClick={handleEditButtonClick}
          onDeleteButtonClick={handleDeleteButtonClick}
        />
      ) : (
        <></>
      )}

      {modalState.isOpen ? (
        <MediaBaseModal
          {...modalState}
          defaultValues={payload}
          isLoadingConfirmButton={isUpdateMediaLoading || isCreateMediaLoading}
          isDisabledConfirmButton={isUpdateMediaLoading || isCreateMediaLoading}
          onSubmit={handleFormSubmit}
          onCancel={handleModalClose}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};
