import React, { useEffect, useState } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { StyledBody, StyledImage, ImageGrid } from "./index.styles";
import { useUploadFiles } from "@/helpers/api/files/hooks";
import { UploadImageDropzone } from "@/packages/UploadImageDropzone";
import { MessageInput } from "../components/MessageInput";
import { ISendMessage } from "..";

type IChatModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadImageModalProps {
  files: FileList;
  onSubmit: (data: ISendMessage) => void;
  onCancel: () => void;
}

export const UploadImageModal: React.FC<
  IUploadImageModalProps & IChatModalProps
> = (props) => {
  const { files, onSubmit, onCancel } = props;

  const [currentFiles, setCurrentFiles] = useState(files);
  useEffect(() => {
    setCurrentFiles(files);
  }, [files]);

  const { mutate: uploadFiles } = useUploadFiles();

  const [isSending, setIsSending] = useState(false);

  const handleUploadFiles = (data: ISendMessage) => {
    const formData = new FormData();
    Object.values(currentFiles).forEach((value) => {
      formData.append("files", value);
    });

    if (isSending) {
      return;
    }
    setIsSending(true);

    uploadFiles({
      data: formData,
      path: "audits/messages",
      onSuccess: (resp) => {
        onSubmit({
          message: data.message,
          filesIds: resp.data.map((item) => item.id),
        });
        setIsSending(false);
      },
    });
  };

  return (
    <Modal
      {...props}
      title=""
      size="small"
      //   isLoadingConfirmButton={isLoading}
      showCancelButton={false}
      showConfirmButton={false}
      onSubmit={() => ({})}
      onCancel={onCancel}
    >
      <StyledBody>
        <ImageGrid>
          {Object.values(currentFiles).map((item, index) => (
            <StyledImage key={index}>
              <UploadImageDropzone
                hasRemove={true}
                onRemove={() => true}
                onChange={(value) => ({})}
                // @ts-ignore
                value={{
                  fileUrl: URL.createObjectURL(item),
                }}
                allowedTypes={["image/png", "image/jpeg"]}
                description="Максимальный размер фотографии — 5 МБ"
              />
            </StyledImage>
          ))}
        </ImageGrid>
        <MessageInput onSendMessage={handleUploadFiles} />
      </StyledBody>
    </Modal>
  );
};
