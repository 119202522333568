import { media } from "@/helpers";
import styled from "styled-components";
import { Button } from "@/packages/button/Button";
import { Space } from "antd";
import { SelectField } from "@/packages/formElements/fields/SelectField";

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 48px;

    ${media.tablet} {
        flex-direction: row;
        width: fit-content;
    }

    ${media.desktop} {
        margin-top: 0;
    }
`;

const StyledContentTitle = styled.div`
    padding: 20px 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media.tablet} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const StyledWrapperButtons = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

const StyledButtons = styled(Space)`
    margin-left: auto;
`;

const StyledSelectField = styled(SelectField)`
    display: none;
    width: 100%;

    ${media.tablet} {
        width: 188px;
    }
`;
export { StyledButton, StyledButtons, StyledSelectField, StyledContentTitle, StyledWrapperButtons };
