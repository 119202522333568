import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TBodyForm, BODY_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export interface IBodyTypeCreateModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (value: TBodyForm) => void;
  isLoadingSubmit: boolean;
}

export const BodyTypeCreateModal: React.FC<IBodyTypeCreateModal> = (props) => {
  const { onSubmit, isLoadingSubmit, ...restProps } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState<TBodyForm>(BODY_INITIAL_FORM);

  const bodyTypeError = !form.bodyType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      bodyType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit(form);
  };

  useEffect(() => {
    if (!restProps.isOpen) {
      setForm(BODY_INITIAL_FORM);
    }
  }, [restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.createCarcase") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.carcase") || ""}
          description={t("parts.carcaseEnter") || ""}
          value={form.bodyType}
          error={bodyTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
