import { colors } from "@/helpers";
import { Search } from "@/packages/formElements/inputs/search/Search";
import styled from "styled-components";

export const StyledHeaderSection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

export const StyledHeaderRow = styled(StyledHeaderSection)`
  justify-content: space-between;
  padding: 15px 28px;
  border-bottom: 1px solid ${colors.background};
`;

export const StyledYearSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 375px;
`;

export const StyledSearch = styled(Search)`
  min-width: 268px;
`;

export const StyledActionsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-bottom: 1px solid ${colors.background};
`;
export const StyledFlexQuotas = styled.div`
  background: ${colors.white};
`;
