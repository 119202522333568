import React, { useEffect } from "react";
import { UseFieldArrayReturn } from "react-hook-form";

import { Field } from "@/packages/formElements/fields/Field";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { UploadType } from "@/packages/UploadImageDropzone";

import { TFileRecord } from "@/helpers/api/files/types";

import { IFileInfo } from "@/components/AutoPark/AutoParkForm/hooks";

import {
  StyledMonitoringUpload,
  StyledParagraph,
  StyledSubTitleWrap,
  StyledUploadFieldWrap,
  StyledUploadItem,
  StyledUploadList,
  StyledTitleWrap,
  StyledParagraphFile,
} from "./index.styles";

const getUploadTitleInfo = (file: TFileRecord | null): JSX.Element => {
  if (!file) {
    return <></>;
  }

  return (
    <>
      <StyledSubTitleWrap>
        <StyledParagraph size={12} color="gray3">
          {file.extension.slice(1).toUpperCase()}
        </StyledParagraph>
      </StyledSubTitleWrap>
      <StyledTitleWrap>
        <StyledParagraph size={12} color="gray">
          {file.fileName.split(file.extension)[0]}
        </StyledParagraph>
        <StyledParagraphFile size={12} color="black10">
          File
        </StyledParagraphFile>
      </StyledTitleWrap>
    </>
  );
};

export type FieldsName = "photoIds" | "fileIds";

export interface IMonitoringUpload {
  label?: string;
  data: IFileInfo[];
  description: string;
  isExpandedGrid?: boolean;
  allowedTypes?: string[];
  fieldsName: FieldsName;
  fieldArray: UseFieldArrayReturn<any, any, "id">;
  readOnly: boolean;
  maxCount?: number;
  hasRemove: boolean;
}

export const FlexUpload: React.FC<IMonitoringUpload> = (props) => {
  const {
    data,
    label,
    fieldsName,
    fieldArray,
    description,
    isExpandedGrid,
    allowedTypes,
    readOnly,
    maxCount = 100,
    hasRemove = true,
  } = props;

  const { fields, append, remove } = fieldArray;
  // if (
  //   data?.length !== maxCount &&
  //   data?.every((el) => el.value !== null) &&
  //   !readOnly
  // ) {
  //   append(
  //     new Array(1).fill({
  //       value: null,
  //     })
  //   );
  // }

  const typeInfo =
    fieldsName === "photoIds" ? UploadType.IMAGE : UploadType.FILE;

  const typePath = fieldsName === "photoIds" ? "flex/photos" : "flex/files";

  useEffect(() => {});
  return (
    <StyledMonitoringUpload>
      <Field.Label>{label}</Field.Label>
      <StyledUploadList isExpandedGrid={true}>
        {fields?.slice(0, maxCount)?.map((item, index) => (
          <StyledUploadItem key={item?.id}>
            {getUploadTitleInfo(data[index].value)}
            <StyledUploadFieldWrap>
              <FormImageDropzoneField
                path={typePath}
                typeInfo={typeInfo}
                maxFileSize={5000000}
                allowedTypes={allowedTypes}
                isWithCustomPlaceholderTitle={false}
                name={`${fieldsName}.${index}.value`}
                disabled={readOnly}
                hasRemove={hasRemove}
              />
            </StyledUploadFieldWrap>
          </StyledUploadItem>
        ))}
      </StyledUploadList>
      <Field.FieldDescription>{description}</Field.FieldDescription>
    </StyledMonitoringUpload>
  );
};
