import styled from "styled-components";

export const StyledRegistryFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
