import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TModificationCreateRequest,
  TModificationCreateResponse,
  TModificationDeleteRequest,
  TModificationDeleteResponse,
  TModificationPatchRequest,
  TModificationPatchResponse,
  TGetModificationByIdRequest,
  TGetModificationByIdResponse,
  TGetModificationListRequest,
  TGetModificationListResponse,
} from "./types";

export const useGetEquipmentList = (request: TGetModificationListRequest) => {
  return useQuery(["modifications", "list", request], () => {
    return api.get<TGetModificationListResponse>("/modifications", {
      params: request,
    });
  }, {
    enabled: !!request.generationId
  });
};

export const useGetEquipmentById = (request: TGetModificationByIdRequest) => {
  return useQuery(["modifications", "detail", request.id], () => {
    return api.get<TGetModificationByIdResponse>(`/modifications/${request.id}`);
  }, {
    enabled: !!request.id
  });
};

export const useCreateEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TModificationCreateRequest) => {
      return api.post<TModificationCreateResponse>("/modifications", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["modifications", "list"]);

        queryClient.invalidateQueries(["models", "list"]);

        queryClient.invalidateQueries([
          "generations",
          "detail",
          variables.generationId,
        ]);
        queryClient.invalidateQueries([
          "generations",
          "list",
          variables.generationId,
        ]);
      },
    }
  );
};

export const usePatchEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TModificationPatchRequest) => {
      return api.patch<TModificationPatchResponse>(
        `/modifications/${id}`,
        patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["modifications", "list"]);
        queryClient.invalidateQueries([
          "modifications",
          "detail",
          variables.id,
        ]);

        queryClient.invalidateQueries(["models", "list"]);
        queryClient.invalidateQueries(["models", "detail"]);

        queryClient.invalidateQueries(["generations", "list"]);
        queryClient.invalidateQueries(["generations", "detail"]);
      },
    }
  );
};

export const useDeleteEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TModificationDeleteRequest) => {
      return api.delete<TModificationDeleteResponse>(
        `/modifications/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["modifications", "list"]);
        queryClient.invalidateQueries([
          "modifications",
          "detail",
          variables.id,
        ]);

        queryClient.invalidateQueries(["models", "list"]);

        queryClient.invalidateQueries(["generations", "detail"]);
        queryClient.invalidateQueries(["generations", "list"]);
      },
    }
  );
};
