import styled from "styled-components";

import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { IconButton } from "@/packages/icon-button/IconButton";

export const StyledTable = styled(TableSimple)`
  .td {
    button {
      align-self: center;
    }
  }

  .th:last-child {
    justify-content: center;
  }
`;

export const StyledIcon = styled(IconButton)`
  align-self: center;
`;
