import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled, { css } from "styled-components";

export const StyledBulletLegend = styled.div`
  position: absolute;
  top: -28px;
  right: 0;
  display: flex;
  gap: 8px;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  gap: 5px;
`;

export const StyledCircle = styled.div<{
  type: "avgSum" | "avgDealer" | "sellsPlan";
}>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  ${({ type }) =>
    type === "avgSum"
      ? css`
          background-color: ${colors.green100};
        `
      : type === "avgDealer"
      ? css`
          background-color: ${colors.greenStatus};
        `
      : css`
          background-color: ${colors.green100};
          opacity: 0.5;
        `}
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.gray200};
  max-width: 150px;
`;
