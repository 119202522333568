import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { StyledSpan } from "./index.styles";

export const getWarrantyDocsTableHeaders = <
  TRecord extends object
>(options: {
  claimModelOpen: (additionModalState: { claimSerial: string; }) => void
}): IHeaderItem<TRecord>[] => {
  const { claimModelOpen } = options;

  return [
    {
      Header: "№",
      accessor: "id",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      Cell: ({ row }) => {
        // @ts-ignore
        return +row.id + 1;
      },
    },
    {
      Header: "VIN",
      accessor: "vin",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
    },
    {
      Header: "RO",
      accessor: "ro",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "CLAIM",
      accessor: "claim",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row, value }) => {
        return (
          <StyledSpan
            onClick={() => claimModelOpen({ claimSerial: value })}
          >
            {value}
          </StyledSpan>);
      }
    },
    {
      Header: "Parts Amount",
      accessor: "partsAmount",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Labor Amount",
      accessor: "laborAmount",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },

    {
      Header: "Sublet Amount",
      accessor: "subletAmount",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Full Amount",
      accessor: "fullAmount",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Parts CB",
      accessor: "partsCB",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: "Labor CB",
      accessor: "laborCB",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: "Sublet CB",
      accessor: "subletCB",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      Header: "CB Amount (1st)",
      accessor: "amountCB",
      width: 90,
      minWidth: 90,
      maxWidth: 90,
      Cell: ({ row, value }) => {
        return (
          <Paragraph color="red" size={12} weight={400}>
            {value}₽
          </Paragraph>
        );
      },
    },
  ];
};

export const getAllClaimsSparesModalHeaders = <
  TRecord extends object
>(): IHeaderItem<TRecord>[] => {

  return [
    {
      Header: "Claim serial",
      accessor: "claimSerial",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    },
    {
      Header: "Replace part number",
      accessor: "replacePartNo",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Replace part number desc",
      accessor: "replacePartNoDesc",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Reimbursement Part Qty",
      accessor: "reimbursementPartQty",
      width: 150,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      Header: "Reimbursement Part Amount",
      accessor: "reimbursementPartAmount",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    }
  ];
};

export const getAllClaimsWarrantyModalHeaders = <
  TRecord extends object
>(): IHeaderItem<TRecord>[] => {

  return [
    {
      Header: "Claim serial",
      accessor: "claimSerial",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Replace part number",
      accessor: "replacePartNo",
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    {
      Header: "Replace part number desc",
      accessor: "replacePartNoDesc",
      width: 60,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      Header: "Reimbursement Part Qty",
      accessor: "reimbursementPartQty",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Reimbursement Part Amount",
      accessor: "reimbursementPartAmount",
      width: 60,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      Header: "Operation Code",
      accessor: "operationCode",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Operation Code Description",
      accessor: "operationCodeDescription",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Reimbursement OP Time",
      accessor: "reimbursementOPTime",
      width: 120,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "Reimbursement Labor Amount",
      accessor: "reimbursementLaborAmount",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    }
  ];
};
