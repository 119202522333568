import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledGenerationFieldsWrapper = styled.div``;

export const StyledImageInputPreview = styled.div`
  background-color: ${colors.gray300};
  height: 100%;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
