import React, { useState } from "react";
import { IModal } from "@/packages/modal/Modal";
import {
  StyledModal,
  StyledModalContentWrapper,
} from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight/RentNewModal/index.styles";
import { RentForm } from "../RentForm";
import {
  useCreateRent,
  useCreateRentIdle,
} from "@/helpers/api/flex/vehicles/hooks";
import { IRentFormValues } from "../RentForm/hooks";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";
import {
  TFlexCreateRentIdleRequest,
  TFlexCreateRentRequest,
} from "@/helpers/api/flex/vehicles/types";

interface IRentNewModal extends Pick<IModal, "isOpen"> {
  closeModal: () => void;
  startEventForNew: Date | null;
  vehicleIdForNew: string | null;
  dealerIdForNew: string | null;
  dealerOptions: ISelectOption[];
}

export const RentNewModal: React.FC<IRentNewModal> = (props) => {
  const {
    closeModal,
    startEventForNew,
    vehicleIdForNew,
    dealerIdForNew,
    dealerOptions,
  } = props;

  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const { mutate: createRent, isLoading: isCreateRentLoading } =
    useCreateRent();

  const { mutate: createRentIdle, isLoading: isCreateRentIdleLoading } =
    useCreateRentIdle();

  const handleSuccess = () => {
    closeModal();
  };

  const handleFormSubmit = (data: IRentFormValues) => {
    const { from, to, vehicleId, idleId, event, total } = data;

    if (idleId) {
      const dataToServer: TFlexCreateRentIdleRequest | TFlexCreateRentRequest =
        {
          from: from ? from.toISOString() : "",
          to: to ? to.toISOString() : "",
          vehicleId: vehicleId!,
          idleId: idleId,
        };

      createRentIdle({
        data: dataToServer,
        onSuccess: () => {
          handleSuccess();
        },
        onError: (error) => {
          if (from && to && from.toISOString() === to.toISOString()) {
            createNotificationError(t("kiaFlex.1dayError") || "");
          } else {
            createNotificationError(
              error.response?.data?.message || t("kiaFlex.eventError") || ""
            );
          }
        },
      });
    } else {
      const dataToServer: TFlexCreateRentRequest = {
        from: from ? from.toISOString() : "",
        to: to ? to.toISOString() : "",
        vehicleId: vehicleId!,
        total: total as number,
      };

      createRent({
        data: dataToServer,
        onSuccess: () => {
          handleSuccess();
        },
        onError: (error) => {
          if (from && to && from.toISOString() === to.toISOString()) {
            createNotificationError(t("kiaFlex.1dayError") || "");
          } else {
            createNotificationError(
              error.response?.data?.message || t("kiaFlex.eventError") || ""
            );
          }
        },
      });
    }
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmitHandler = () => {
    setIsSubmitted(true);
  };

  return (
    <StyledModal
      {...props}
      title={t("kiaFlex.newEvent") || ""}
      size="medium"
      cancelButtonText={t("models.logsModel.cancel") || ""}
      showConfirmButton={true}
      confirmButtonText={t("kiaFlex.createEvent") || ""}
      isDisabledConfirmButton={isDisabled}
      onSubmit={onSubmitHandler}
      onClose={closeModal}
      isLoadingConfirmButton={isCreateRentIdleLoading || isCreateRentLoading}
      isDisabledCancelButton={isCreateRentIdleLoading || isCreateRentLoading}
    >
      <StyledModalContentWrapper>
        <RentForm
          defaultValues={{
            from: startEventForNew || null,
            vehicleId: vehicleIdForNew || null,
            dealerId: dealerIdForNew || null,
          }}
          onFormSubmit={handleFormSubmit}
          isLoading={isCreateRentLoading}
          dealerOptions={dealerOptions}
          isNew={true}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          setIsDisabled={setIsDisabled}
        />
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
