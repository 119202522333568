import { Icon } from "@/packages/icon/Icon";
import React from "react";
import {
  NavBarInfoBlockEmail,
  NavBarInfoBlockIconContainer,
  StyledContactForm,
  StyledIconWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export const ContactItem: React.FC = () => {
  const { t } = useTranslation();
  return (
    <NavBarInfoBlockIconContainer>
      <StyledIconWrapper>
        <Icon size={16} name="help" />
      </StyledIconWrapper>
      <StyledContactForm>
        <NavBarInfoBlockEmail size={12}>
          <a href="mailto:dip.support@kia.ru">dip.support@kia.ru</a>
          <div>{t("common.support") || ""}</div>
        </NavBarInfoBlockEmail>
      </StyledContactForm>
    </NavBarInfoBlockIconContainer>
  );
};
