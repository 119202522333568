import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { Col } from "antd";
import styled from "styled-components";

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  background-color: ${colors.gray4};
  margin: 0 10px 14px 10px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;
export const StyledDatepickerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
  margin-bottom: 32px;
`;
export const StyledColDate = styled(Col)`
  display: flex;
  align-items: flex-end;
`;
