import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ModelFields } from "../ModelFields";
import { ModelFormFieldsEnum } from "../ModelFields/types";
import { TOnSubmitCreateModelFormCallback, useCreateModelForm } from "./hooks";
import { useTranslation } from "react-i18next";

export interface IModelCreateModal extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitCreateModelFormCallback;
}

export const ModelCreateModal: React.FC<IModelCreateModal> = (props) => {
  const { onSubmit, ...rest } = props;
  const { form, submit, isSubmitLoading, reset } = useCreateModelForm(onSubmit);

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  const [name, _yearRange] = form.watch([
    ModelFormFieldsEnum.Name,
    ModelFormFieldsEnum.YearRange,
  ]);
  const yearRange = _yearRange as IYearRangeInputFieldValue | undefined;

  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      size="medium"
      title={t("models.addNewModelFrom.title") || ""}
      confirmButtonText={t("models.addNewModelFrom.save") || ""}
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      allowCancelPopconfirm={true}
      cancelPopconfirmTitle={
        t("models.addNewModelFrom.cancelDescription") || ""
      }
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
    >
      <TitleDivider size="medium">
        {name} {yearRange?.start || "..."} — {yearRange?.end || "..."}
      </TitleDivider>
      <FormProvider {...form}>
        <ModelFields />
      </FormProvider>
    </Modal>
  );
};
