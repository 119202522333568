import React, { useMemo } from "react";

import { FlexCalendarTableColumnLeft } from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnLeft";
import {
  FlexCalendarTableColumnRight,
  TOnDayClick,
} from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight";

import { StyledCalendarTable } from "./index.styles";

import { getDateDiff, getDatesArray, getDatesTree } from "@/helpers/date";
import { CalendarData } from "@/helpers/api/flex/vehicles/types";
import { TOnEditCLick } from "./FlexCalendarTableColumnRight/CalendarRent";

interface ICalendarTable {
  data: CalendarData[];
  endPeriodDate: Date;
  startPeriodDate: Date;
  scrollPage: number;
  isLoading: boolean;
  setScrollPage: React.Dispatch<React.SetStateAction<number>>;
  setScrollPrevPage: React.Dispatch<React.SetStateAction<number>>;
  onDayClick: TOnDayClick;
  onEditDateClick: TOnEditCLick;
  startPeriodDateFilters: Date;
}

export const FlexCalendarTable: React.FC<ICalendarTable> = (props) => {
  const {
    data,
    endPeriodDate,
    startPeriodDate,
    scrollPage,
    setScrollPage,
    setScrollPrevPage,
    isLoading,
    onDayClick,
    onEditDateClick,
    startPeriodDateFilters,
  } = props;

  const datesArray = getDatesArray(startPeriodDate, endPeriodDate);
  const datesTree = getDatesTree(datesArray);
  const calendarDatesDiff = getDateDiff(startPeriodDate, endPeriodDate);

  const table = useMemo(() => {
    return (
      <>
        <FlexCalendarTableColumnLeft
          data={data}
          datesTree={datesTree}
          isLoading={isLoading}
        />

        <FlexCalendarTableColumnRight
          data={data || []}
          datesTree={datesTree}
          datesArray={datesArray}
          calendarDatesDiff={calendarDatesDiff}
          scrollPage={scrollPage}
          setScrollPage={setScrollPage}
          setScrollPrevPage={setScrollPrevPage}
          onDayClick={onDayClick}
          onEditDateClick={onEditDateClick}
          startPeriodDateFilters={startPeriodDateFilters}
        />
      </>
    );
  }, [data, datesTree, datesArray]);

  return <StyledCalendarTable>{table}</StyledCalendarTable>;
};
