import { useMutation, useQuery } from "@tanstack/react-query";

import { api } from "../axiosInstance";
import {
  TExportDecoderDocumentListRequest,
  TExportDecoderDocumentListResponse,
  TExportDecoderMPVListRequest,
  TExportDecoderMPVListResponse,
  TGetDecoderPurchaseRequest,
  TGetDecoderPurchaseResponse,
  TGetDecoderRequest,
  TGetDecoderResponse,
} from "./types";

export const useGetDecoder = () => {
  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TGetDecoderRequest;
      onSuccess?: () => void;
      onError?: () => void;
    }) => {
      return api.get<TGetDecoderResponse>(`/decoder/${data.vin}/for-prefill`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError();
        }
      },
    }
  );
};

export const useGetDecoderPurchase = (request: TGetDecoderPurchaseRequest) => {
  return useQuery(
    ["decoderPurchase", "list", request.vin],
    () => {
      return api.get<TGetDecoderPurchaseResponse>(
        `/decoder/${request.vin}/purchase`
      );
    },
    {
      enabled: !!request.vin,
    }
  );
};

export const useExportDecoderDocuments = () => {
  return useMutation((request: TExportDecoderDocumentListRequest) => {
    return api.get<TExportDecoderDocumentListResponse>(
      `/decoder/${request.vin}/export`,
      {
        params: request,
        responseType: "blob",
      }
    );
  });
};

export const useGetPublicDealerList = (
  request: TExportDecoderMPVListRequest
) => {
  return useQuery(
    ["mpv", "list", request.mpv],
    () => {
      return api.get<TExportDecoderMPVListResponse>(
        `/decoder/mpv/${request.mpv}`,
        {
          params: request,
        }
      );
    },
    {
      enabled: false,
    }
  );
};
