import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import {
  TKiaDealerCreateRequest,
  TKiaDealerCreateResponse,
  TKiaDealerPatchRequest,
  TKiaDealerPatchResponse,
  TGetKiaDealerByIdRequest,
  TGetKiaDealerByIdResponse,
  TGetKiaDealerListFiltersResponse,
  TGetKiaDealerListRequest,
  TGetKiaDealerListResponse,
  TGetKiaGroupDealerListRequest,
  TGetKiaGroupDealerListResponse,
  TKiaDealerRecord,
  TExportDealersRequest,
  TExportDealerRequest,
  TExportDealersResponse,
  TExportDealerResponse,
} from "./types";
import { AxiosResponse } from "axios";
import { TGetProfileResponse } from "@/helpers/api/profile/types";
import { usePermission } from "@/hooks/usePermission";
import { accessTokenService } from "../../accessTokenService";
import {
  TGetModelListRequest,
  TGetModelListResponse,
} from "@/helpers/api/models/types";

export const preparedDealerList = (dealerList: TKiaDealerRecord[]) =>
  dealerList?.map((item) => ({
    label: `${item.mobisCode} / ${item.warrantyCode} / ${item.lawName} / ${item.merchantName}`,
    value: item.id,
  }));

export const useGetKiaDealerList = (request: TGetKiaDealerListRequest) => {
  const queryClient = useQueryClient();
  const { hasAccess } = usePermission("dc", "read");

  const isEnabled = Boolean(
    hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "kia"
  );

  return useQuery(
    ["kiaDealers", "list", request],
    () => {
      return api.get<TGetKiaDealerListResponse>("/kia/dealers", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetKiaGroupDealerList = (
  request: TGetKiaGroupDealerListRequest,
  isReadOnly?: boolean
) => {
  const queryClient = useQueryClient();
  const { hasAccess } = usePermission("dc", "read");

  const isEnabled = Boolean(
    hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "kia" &&
      !isReadOnly
  );

  return useQuery(
    ["kiaDealers", "group", "list", request.group],
    () => {
      return api.get<TGetKiaGroupDealerListResponse>(
        `/kia/dealers/get/list/${request.group}`
      );
    },
    { enabled: isEnabled }
  );
};

export const useGetKiaDealerById = (request: TGetKiaDealerByIdRequest) => {
  const queryClient = useQueryClient();

  const isEnabled = Boolean(
    (
      queryClient.getQueryData([
        "profile",
        accessTokenService.getToken(),
      ]) as AxiosResponse<TGetProfileResponse>
    )?.data?.type === "kia"
  );

  return useQuery(
    ["kiaDealers", "detail", request.id],
    () => {
      return api.get<TGetKiaDealerByIdResponse>(`/kia/dealers/${request.id}`);
    },
    { enabled: true }
  );
};

export const useGetKiaDealerListFilters = () => {
  const queryClient = useQueryClient();

  const isEnabled = Boolean(
    (
      queryClient.getQueryData([
        "profile",
        accessTokenService.getToken(),
      ]) as AxiosResponse<TGetProfileResponse>
    )?.data?.type === "kia"
  );

  return useQuery(
    ["kiaDealers", "list-filters"],
    () => {
      return api.get<TGetKiaDealerListFiltersResponse>(`/kia/dealers/filters`);
    },
    { enabled: isEnabled }
  );
};

export const useGetKiaModels = (request: TGetModelListRequest) => {
  return useQuery(["kiaModels", "models"], () => {
    return api.get<TGetModelListResponse>("/models", { params: request });
  });
};

export const useGetRestylingModels = (request: any) => {
  return useQuery(["kiaRestylingModels", "RestylingModels"], () => {
    return api.get<any>("/models/with-full-name", {
      params: request,
    });
  });
};

export const useCreateKiaDealer = () => {
  const { refetch: refetchKiaDealerList } = useGetKiaDealerList({
    status: true,
    page: 1,
    limit: 10,
  });

  return useMutation(
    (data: TKiaDealerCreateRequest) => {
      return api.post<TKiaDealerCreateResponse>("/kia/dealers", data);
    },
    {
      onSuccess: () => {
        refetchKiaDealerList();
      },
    }
  );
};

export const usePatchKiaDealer = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TKiaDealerPatchRequest) => {
      return api.patch<TKiaDealerPatchResponse>(`/kia/dealers/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["kiaDealers", "detail", variables.id]);
        queryClient.invalidateQueries(["kiaDealers", "list"]);
      },
    }
  );
};

export const useExportDealers = () => {
  return useMutation((request: TExportDealersRequest) => {
    return api.get<TExportDealersResponse>("/kia/dealers/export", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportDealer = () => {
  return useMutation((id: TExportDealerRequest) => {
    return api.get<TExportDealerResponse>(`/kia/dealers/${id}/export`, {
      responseType: "blob",
    });
  });
};
