import { Modal, IModal } from "@/packages/modal/Modal";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { FC } from "react";
import styled from "styled-components";

interface INotificationsResultModal
  extends Omit<IModal, "children" | "showCancelButton" | "confirmButtonText"> {
  resultText: string;
}

const StyledResultText = styled(Paragraph)`
  text-align: center;
`;

const NotificationsResultModal: FC<INotificationsResultModal> = (props) => {
  const { resultText, ...restProps } = props;

  return (
    <Modal {...restProps} showCancelButton={false} confirmButtonText="Закрыть">
      <StyledResultText size={18} color="black">
        {resultText}
      </StyledResultText>
    </Modal>
  );
};

export { NotificationsResultModal };
