import { Checkbox } from "@/packages/checkbox/Checkbox";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { StyledButtons } from "./index.styles";

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  level: string;
  onDeleteClick?: (id: string) => void;
  onEditClick: (id: string) => void;
  onPhotoParamEdit: (id: string, value: boolean) => void;
}): IHeaderItem<TRecord>[] => {
  const { onDeleteClick, onEditClick, onPhotoParamEdit, level } = options;

  return [
    {
      Header: "№ Вопроса",
      accessor: "number",
      width: 80,
      minWidth: 80,
      maxWidth: 80,

      Cell: ({ row: { original } }) => {
        return (
          <>
            {/* @ts-ignore */}
            {`${level}.${original.order + 1}`}
          </>
        );
      },
    },
    {
      Header: "Контрольная точка для проверки",
      accessor: "title",
      Cell: ({ row: { original }, value }) => {
        return value ? (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true} />
        ) : (
          ""
        );
      },
    },
    {
      Header: "Параметр проверки",
      accessor: "criteria",
    },
    {
      Header: "План действий",
      accessor: "plan",
      Cell: ({ row: { original }, value }) => {
        return value ? (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true} />
        ) : (
          ""
        );
      },
    },
    {
      Header: "Признак вопроса",
      accessor: "attribute",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: "Параметр KPI",
      accessor: "kpi",
    },
    {
      Header: "Вес параметра",
      accessor: "weight",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: "Обязательное фото",
      accessor: "photo",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original }, value }) => {
        return (
          <Checkbox
            value={value}
            // @ts-ignore
            onChange={(flaq) => onPhotoParamEdit(original?.id, flaq)}
          />
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: 70,
      minWidth: 70,
      maxWidth: 70,

      Cell: ({ row: { original } }) => {
        return (
          <StyledButtons>
            <IconButton
              icon="edit"
              color="black"
              size="l"
              variant="tertiary"
              // @ts-ignore
              onClick={() => onEditClick(original.id)}
            />

            <IconButton
              icon="delete"
              color="black"
              size="l"
              variant="tertiary"
              // @ts-ignore
              onClick={() => onDeleteClick && onDeleteClick(original.id)}
            />
          </StyledButtons>
        );
      },
    },
  ];
};
