import React from "react";

import {
  SelectField,
  ISelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetBodyList } from "@/helpers/api/bodies/hooks";

export interface IBodyTypeSelectFieldAdapter
  extends Omit<ISelectField, "options"> {}

export const BodyTypeSelectFieldAdapter: React.FC<
  IBodyTypeSelectFieldAdapter
> = (props) => {
  const { data: getBodyListResponse, isLoading } = useGetBodyList({
    page: 1,
    limit: 0,
  });
  const options =
    getBodyListResponse?.data.data.map(
      (fuelRecord) =>
        ({ value: fuelRecord.id, label: fuelRecord.name } as ISelectOption)
    ) || [];

  return <SelectField options={options} loading={isLoading} {...props} />;
};
