import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect } from "react";
import {
  DeepPartial,
  FieldValues,
  useForm as useFormRHF,
} from "react-hook-form";
import { Subscription } from "react-hook-form/dist/utils/createSubject";
import { AnyObjectSchema } from "yup";

export type TOnSubmitFormAsyncCb<TSuccessFormValues> = (
  values: TSuccessFormValues
) => Promise<any>;

export type TOnSubmitFormSyncCb<TSuccessFormValues> = (
  values: TSuccessFormValues
) => void;

export type TOnSubmitFormCb<TSuccessFormValues> =
  | TOnSubmitFormAsyncCb<TSuccessFormValues>
  | TOnSubmitFormSyncCb<TSuccessFormValues>;

export interface IUseFormOptions<TFormValues, TSuccessFormValues> {
  validateFormSchema?: AnyObjectSchema;
  onSubmit?: TOnSubmitFormCb<TSuccessFormValues>;
  defaultValues: DeepPartial<TFormValues>;
  onChangeValues?: (values: TFormValues) => void;
  reValidateMode?: "onBlur" | "onChange" | "onSubmit";
}

export const useForm = <
  TFormValues extends FieldValues,
  TSuccessFormValues = TFormValues
>(
  options: IUseFormOptions<TFormValues, TSuccessFormValues>
) => {
  const {
    onSubmit,
    validateFormSchema,
    defaultValues,
    onChangeValues,
    reValidateMode,
  } = options;

  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  const form = useFormRHF<TFormValues>({
    resolver: validateFormSchema ? yupResolver(validateFormSchema) : undefined,
    mode: "onChange",
    defaultValues: defaultValues,
    reValidateMode: reValidateMode ? reValidateMode : "onChange",
  });

  useEffect(() => {
    // form.trigger();
    let subscription: Subscription | null = null;
    if (onChangeValues) {
      subscription = form.watch((formValues) => {
        if (onChangeValues) {
          onChangeValues(formValues as TFormValues);
        }
      });
    }

    return () => {
      if (subscription !== null) {
        subscription.unsubscribe();
      }
    };
  }, [form, onChangeValues]);

  const submit = onSubmit
    ? form.handleSubmit(async (data) => {
        if (onSubmit instanceof Promise<any>) {
          try {
            setIsSubmitLoading(true);

            onSubmit(data as unknown as TSuccessFormValues);
          } finally {
            setIsSubmitLoading(false);
          }
        } else {
          onSubmit(data as unknown as TSuccessFormValues);
        }
      })
    : undefined;

  return {
    form,
    submit,
    reset: form.reset,
    setValue: form.setValue,
    isSubmitLoading: isSubmitLoading,
  };
};
