import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";

import { IFlexStatusFilterFormValues, TOnSubmitFlexStatusFilterFormCb, useFlexStatusFilterForm, } from "./hooks";

import {
  StyledButtonsContainer,
  StyledDatepickerDash,
  StyledDatepickerWrapper,
  StyledDaysCount,
  StyledFirstFormRow,
  StyledFlexStatusFilterWrapper,
  StyledFormLayout,
  StyledLastFormRow,
} from "./index.styles";

import { countDaysBetweenDates, getShiftedDate } from "@/helpers/date";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { useGetKiaDealerList, useGetRestylingModels, } from "@/helpers/api/dc/kia-dealers/hooks";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { useTranslation } from "react-i18next";

export interface IFlexStatusFilter {
  onFiltersChange: TOnSubmitFlexStatusFilterFormCb;
  initValues: IFlexStatusFilterFormValues;
}

const MAX_CALENDAR_DAYS = 44;

export const FlexStatusFilter: React.FC<IFlexStatusFilter> = (props) => {
  const { onFiltersChange, initValues } = props;
  const [search, setSearch] = useState({
    dealer: "",
  });
  const { form, onSubmitHandler, onClearHandler } = useFlexStatusFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });
  const [daysCount, setDaysCount] = useState<number | null>(12)

  const { data: modelsList, isLoading: isModelsLoading } =
    useGetRestylingModels({
      limit: 0,
    });

  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });

  const { data: dealersList, isLoading: isDealersLoading } =
    useGetKiaDealerList({ limit: 0, name: search.dealer });

  const [fromDate, toDate, dealer] = form.watch([
    "fromDate",
    "toDate",
    "dealer",
  ]);
  const { t } = useTranslation();

  const modelOptions = useMemo(
    () =>
      modelsList?.data.data.map((item) => ({
        label: item.fullModelName,
        value: item.generationId,
      })) || [],
    [modelsList?.data, isModelsLoading]
  );

  const holdingOptions = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );

  const holdingOptionsSelect = [
    { label: "common.unSelected", value: null },
    ...holdingOptions,
  ];

  const dealerOptions = useMemo(
    () =>
      // @ts-ignore
      dealersList?.data?.data?.map((item) => ({
        label: item.merchantName,
        value: item.id,
        record: item,
      })) || [],
    [dealersList?.data, isDealersLoading]
  );

  const dealerOptionSelect = [
    { label: "common.unSelected", value: null, record: undefined },
    ...dealerOptions,
  ];

  const c2cOptions = [
    {
      label: "C2C",
      value: true,
    },
    {
      label: "audits.list.all",
      value: false,
    },
  ];

  useEffect(() => {
    if (dealer) {
      const selectedDealerOption = dealerOptionSelect.find(
        (option) => option.value === dealer
      );

      selectedDealerOption &&
      form.setValue("holding", selectedDealerOption.record?.holding?.id);
    } else {
      form.setValue("holding", undefined);
    }
  }, [dealer]);

  console.log(form.watch('fromDate'))

  useEffect(() => {
    setDaysCount(countDaysBetweenDates(form.watch('fromDate'), form.watch('toDate')))
  }, [form.watch('fromDate'), form.watch('toDate')])

  return (
    <StyledFlexStatusFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <StyledFirstFormRow>
            <StyledDatepickerWrapper>
              <FormDatepickerField
                name="fromDate"
                isErrorAbsolute={true}
                label={t("kiaFlex.fromDate") || ""}
                maxDate={toDate || undefined}
                minDate={
                  toDate
                    ? getShiftedDate(toDate, -MAX_CALENDAR_DAYS)
                    : undefined
                }
              />
              <StyledDatepickerDash>_</StyledDatepickerDash>
              <FormDatepickerField
                name="toDate"
                isErrorAbsolute={true}
                minDate={fromDate || undefined}
                maxDate={
                  getShiftedDate(fromDate, MAX_CALENDAR_DAYS) || undefined
                }
              />
              {
                daysCount &&
                <>
                  <StyledDatepickerDash>_</StyledDatepickerDash>
                  <StyledDaysCount>{daysCount} дн.</StyledDaysCount>
                </>
              }
            </StyledDatepickerWrapper>
            <FormInputField
              name="vin"
              label={t("kiaFlex.vin") || ""}
              placeholder={t("kiaFlex.vinDescr") || ""}
            />
            <FormInputField
              name="number"
              label={t("kiaFlex.number") || ""}
              placeholder={t("kiaFlex.numberDescr") || ""}
            />
            <FormSelect
              name="c2c"
              label={t("kiaFlex.type") || ""}
              options={c2cOptions as any}
              placeholder={t("audits.list.all") || ""}
              allowClear
            />
          </StyledFirstFormRow>
          <StyledLastFormRow>
            <FormSelect
              name="holding"
              label={t("audits.list.holdingId") || ""}
              // @ts-ignore
              options={holdingOptionsSelect}
              placeholder={t("audits.list.all") || ""}
              allowClear
              isSearchShow
            />
            <FormSelect
              name="dealer"
              label={t("audits.list.dealer") || ""}
              // @ts-ignore
              options={dealerOptionSelect}
              placeholder={t("audits.list.all") || ""}
              allowClear
              onSearch={(value) => {
                setSearch((prev) => ({ ...prev, dealer: value }));
              }}
              isSearchShow={true}
            />
            <FormSelect
              name="model"
              label={t("park.model") || ""}
              placeholder={t("audits.list.all") || ""}
              options={modelOptions}
              allowClear
              isSearchShow
            />
          </StyledLastFormRow>
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button
          color="brand"
          theme="primary"
          size="middle"
          onClick={onSubmitHandler}
        >
          {t("audits.list.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("dealerMaterials.letters.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledFlexStatusFilterWrapper>
  );
};
