import React, { useState } from "react";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { useCreateTatiffs, useGetTatiffsList, } from "@/helpers/api/flex/tariffs/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { TariffsModal } from "../TariffsModal";
import { Headers } from "./Headers";
import { StyledTable, TableHeader } from "./index.styles";
import { useTranslation } from "react-i18next";
import { Loader } from "@/packages/loader/Loader";

const _SettingsTariffTable: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const {
    data: tariffs,
    isLoading,
    refetch: updateTarif,
  } = useGetTatiffsList({
    modelName: searchValue,
  });
  const { mutate: createTariff } = useCreateTatiffs();

  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const {
    modalState: createTariffsState,
    openModal: openCreateTariffsModal,
    closeModal,
  } = useModal();

  const handleSubmit = (value) => {
    createTariff({
      data: value,
      onSuccess: () => {
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessRates") || ""
        );
        updateTarif();
        closeModal();
      },
      onError: (error) => {
        createNotificationError(error.response?.data.message || error.message);
        updateTarif();
      },
    });
  };

  return (
    <>
      <TableHeader>
        <Search
          onChange={(value) => setSearchValue(value)}
          value={searchValue}
        />
        <PermissionsAdapter resourceName="flex.tariff" resourceAction="create">
          <Button
            theme="primary"
            color="brand"
            onClick={openCreateTariffsModal}
          >
            {t("kiaFlex.register") || ""}
          </Button>
        </PermissionsAdapter>
      </TableHeader>
      {!isLoading ? (
        <StyledTable
          headers={Headers()}
          data={tariffs?.data.data || []}
          withOverflow
        />
      ) : (
        <Loader size={48}/>
      )}
      {createTariffsState.isOpen ? (
        <TariffsModal
          mode="create"
          {...createTariffsState}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export const SettingsTariffTable = React.memo(_SettingsTariffTable);
