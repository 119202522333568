import styled from "styled-components";
import { Select } from "@/packages/formElements/inputs/Select";
import { Field } from "../Field";

export const StyledYearRangeFieldControlContainer = styled(
  Field.FieldControlContainer
)`
  display: flex;
  gap: 12px;
`;

export const StyledSelect = styled(Select)`
  flex: 1;
`;
