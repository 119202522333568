import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import Card from "antd/lib/card";
import styled from "styled-components";

export const StyledMonitoringCarsTableExpandDataForm = styled(Card)`
  background: ${colors.white};
`;

export const StyledCardLeft = styled(Card)`
  .ant-card-body {
    margin: 0;
    padding: 0;
  }
  border: none;
`;

export const StyledFormTitle = styled(Paragraph)`
  padding-left: 15px;
  padding-top: 13px;
`;

export const StyledFormDescription = styled(Paragraph)`
  background: ${colors.white};
  padding: 5px;
`;

export const StyledContainer = styled.div`
  height: 100%;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  left: 40%;
  transform: translate(-5vw%, 0);
`;

export const StyledWrapperDropzone = styled.div`
  margin: 0 0 52px 0;
`;

export const StyledCardFormWrapper = styled.div`
  display: grid;
  gap: 12px;
`;

export const StyledCardRight = styled(Card)`
  .ant-card-body {
    margin: 0 auto;
    padding: 0;
  }
  min-height: 454px;

  display: grid;
  grid-auto-flow: column;
  gap: 16px;

  margin-top: 10px;
  border-radius: 12px;
  height: 100%;
`;
