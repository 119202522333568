import { FormBooleanSelectField } from "@/packages/formElements/formFields/FormBooleanSelectField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Title } from "@/packages/title/Title";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Col, Row } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  IMonitoringDealersFilterFormValues,
  MonitoringDealersFilterFormFieldsNameEnum,
  useMonitoringDealersFilterForm,
} from "./hooks";
import {
  StyledMonitoringFilterWrapper,
  StyledTitleContainer,
  StyledButtonsContainer,
} from "./index.styles";
import { t } from "i18next";
import { Button } from "@/packages/button/Button";

export interface IMonitoringDealersFilterProps {
  initValues: IMonitoringDealersFilterFormValues;
  filterOptions: any;
  onFiltersChange: any;
  handleExport: () => void;
  resetPage: () => void;
}

export const MonitoringDealersFilter: React.FC<
  IMonitoringDealersFilterProps
> = ({ initValues, filterOptions, onFiltersChange, handleExport, resetPage }) => {
  const { form, submit, reset } = useMonitoringDealersFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  const handleReset = () => {
    reset();
    resetPage();
    submit && submit();
  };

  const handleSubmit = () => {
    resetPage();
    submit && submit();
  };

  return (
    <StyledMonitoringFilterWrapper>
      <StyledTitleContainer>
        <Title level={3} weight={400}>
          {t("monitoring.dealers")}
        </Title>
        <Tooltip content={t("monitoring.exportExcell") || ""}>
          <IconButton
            icon="save"
            isSquared
            variant="primary"
            color="white"
            size="m"
            onClick={handleExport}
          />
        </Tooltip>
      </StyledTitleContainer>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={6}>
            <FormSelect
              name={MonitoringDealersFilterFormFieldsNameEnum.Names}
              label={t("monitoring.commName") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              isSearchShow
              options={filterOptions?.dealerNameOptions}
              allowClear
            />
          </Col>

          <Col span={4}>
            <FormSelect
              name={MonitoringDealersFilterFormFieldsNameEnum.CityNames}
              label={t("monitoring.city") || ""}
              isSearchShow
              options={filterOptions?.cities}
              placeholder={t("dealerMaterials.letters.all") || ""}
              allowClear
            />
          </Col>

          <Col span={4}>
            <FormSelect
              name={MonitoringDealersFilterFormFieldsNameEnum.Codes}
              label={t("monitoring.dealerCode") || ""}
              isSearchShow
              options={filterOptions?.dealerCodeOptions}
              placeholder={t("dealerMaterials.letters.all") || ""}
              allowClear
            />
          </Col>
          <Col span={4}>
            <FormBooleanSelectField
              name={MonitoringDealersFilterFormFieldsNameEnum.Familiarize}
              label={t("monitoring.status") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              trueLabel={t("monitoring.familiarized") || ""}
              falseLabel={t("monitoring.notFamiliarized") || ""}
              allowClear
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name={MonitoringDealersFilterFormFieldsNameEnum.SubmittedCars}
              label={t("monitoring.submitted-cars") || ""}
              options={[
                { value: "", label: "dealerMaterials.letters.all" },
                { value: "with-requests", label: "monitoring.withRequests" },
                {
                  value: "without-requests",
                  label: "monitoring.withOutRequests",
                },
              ]}
              placeholder={t("dealerMaterials.letters.all") || ""}
              allowClear
            />
          </Col>
        </Row>
      </FormProvider>
      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={handleSubmit}>
          {t("monitoring.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={handleReset}
        >
          {t("monitoring.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledMonitoringFilterWrapper>
  );
};
