import React, { useEffect, useMemo, useState } from "react";
import { useEditUser, useGetUserById } from "@/helpers/api/users/hooks";
import { Col, Row, Space } from "antd";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormPhoneInput } from "@/packages/formElements/formFields/FormPhoneInput";
import { FormCountrySelect } from "@/packages/formElements/formFields/FormCountrySelect";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { STATUS_TYPES } from "@/pages/Admin/UsersFilters";
import { t } from "i18next";
import { EditUserFormFieldsEnum, useEditUserForm } from "@/pages/Admin/UserEdit/hook";
import { FormProvider } from "react-hook-form";
import { FieldsWrapper } from "@/pages/Admin/UserEdit/index.styles";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { FormDealersSelect } from "@/packages/formElements/formFields/FormDealersSelect";
import { Button } from "@/packages/button/Button";
import { useNotification } from "@/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTE } from "@/constants/routes";
import { Title } from "@/packages/title/Title";
import { holdingRoles } from "@/components/TransportComplaints/TransportComplaintsFilter";
import { InputField } from "@/packages/formElements/fields/InputField";
import { Loader } from "@/packages/loader/Loader";
import { useGetRoleList } from "@/helpers/api/roles/hooks";
import { ACCOUNT_TYPE_OPTIONS, CreateUserFormFieldsEnum } from "@/pages/Admin/UserCreate/hooks";

interface UserEditType {
  id: string;
}

export const UserEdit: React.FC<UserEditType> = ({ id }) => {
  const [searchValueDealerList, setSearchValueDealerList] = useState("");

  const { data: kiaDealersList, isLoading: isLoadingDealer } = useGetKiaDealerList({
    limit: 0, name: searchValueDealerList ? searchValueDealerList : undefined,
  });
  const { data: userData, refetch, isLoading } = useGetUserById({ id });
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { mutate: updateUser } = useEditUser();
  const navigate = useNavigate();

  const handleSubmit = (data) => {
    updateUser({
      request: { id, patch: data },
      onSuccess: () => {
        navigate(ADMIN_ROUTE);
        refetch();
        createNotificationSuccess('Пользователь обновлен успешно')

      }, onError: () => {
        createNotificationError('Произошла ошибка при обновлении пользователя')
      }
    })
  }

  const { form, submit, isSubmitLoading } = useEditUserForm({
    onSubmit: handleSubmit,
    initValues: userData?.data,
    kiaDealersListData: kiaDealersList?.data.data
  });

  const [currentDealer, setCurrentDealer] = useState<any>(userData?.data?.dealer)

  useEffect(() => {
    const dealer = kiaDealersList?.data.data.find(it => it.id === form.getValues("dealerId"))
    setCurrentDealer(dealer)
  }, [form.watch('dealerId'), userData?.data, kiaDealersList?.data.data]);

  const { data: rolesList } = useGetRoleList({ type: form.watch('type') })

  const preparedRoleList = useMemo(
    () =>
      rolesList?.data?.data?.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      })) || [],
    [rolesList?.data, form.watch('type')]
  );

  useEffect(() => {
    const phone = form.getValues('phone');
    form.setValue('roleId', null);
    form.setValue('phone', phone);
  }, [form.watch('type')]);

  return (
    <FormProvider {...form}>
      {
        isLoading || isLoadingDealer ? <Loader size={24}/> :
          <FieldsWrapper>
            <Row gutter={32}>
              <Col span={6}>
                <FormInputField
                  name={EditUserFormFieldsEnum.FirstName}
                  label='Имя'
                  placeholder={"Введите Имя"}
                />
              </Col>
              <Col span={6}>
                <FormInputField
                  name={EditUserFormFieldsEnum.LastName}
                  label='Фамилия'
                  placeholder={"Введите Фамилию"}
                />
              </Col>
              <Col span={6}>
                <FormInputField
                  name={EditUserFormFieldsEnum.Email}
                  label='Email'
                  placeholder={"Введите Email"}
                />
              </Col>
              <Col span={6}>
                <FormPhoneInput
                  name={EditUserFormFieldsEnum.Phone}
                  label='Телефон'
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={6}>
                <FormCountrySelect
                  name={EditUserFormFieldsEnum.Country}
                  label={t("authorization.country") || ""}
                  placeholder={t("authorization.countryPlaceholder") || ""}
                />
              </Col>
              <Col span={6}>
                <FormSelect
                  name={EditUserFormFieldsEnum.RoleId}
                  label={t("authorization.post") || ""}
                  placeholder={t("authorization.postPlaceholder") || ""}
                  options={preparedRoleList}
                />
              </Col>
              <Col span={6}>
                <FormSelect
                  name={CreateUserFormFieldsEnum.Type}
                  label='Тип аккаунта'
                  options={ACCOUNT_TYPE_OPTIONS}
                />
              </Col>
              <Col span={6}>
                <FormSelect
                  name={EditUserFormFieldsEnum.IsActive}
                  label={"Статус"}
                  options={STATUS_TYPES}
                />
              </Col>
            </Row>
            {
              !isLoadingDealer &&
              <>
                <Title level={4} weight={400}>Информация о ДЦ</Title>
                <Row gutter={32}>
                  <Col span={6}>
                    <FormDealersSelect
                      name={EditUserFormFieldsEnum.DealerId}
                      label={"Дилерский центр"}
                      placeholder={t("authorization.dealershipPlaceholder") || ""}
                      showSearch
                      dealerList={kiaDealersList?.data?.data}
                      setSearchValueDealerList={setSearchValueDealerList}
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label={"Юридическое наименование"}
                      value={currentDealer?.lawName}
                      readOnly
                      disabled
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label={"Код Mobis"}
                      value={currentDealer?.mobisCode}
                      readOnly
                      disabled
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label={"Код SAP"}
                      value={currentDealer?.sapCode}
                      readOnly
                      disabled
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label={"Код шоурума"}
                      value={currentDealer?.showroomCode}
                      readOnly
                      disabled
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label={"Страна"}
                      value={t(`country.${currentDealer?.country.toLowerCase()}`) ?? ''}
                      readOnly
                      disabled
                    />
                  </Col>
                </Row>
              </>
            }
            {
              <Row gutter={32}>
                <Col span={6}>
                  <InputField
                    label={"Холдинг"}
                    value={holdingRoles.includes(userData?.data?.role?.slug ?? '') ?userData?.data?.dealer?.holding?.name : ''}
                    readOnly
                    disabled
                  />
                </Col>
              </Row>
            }
            <Space>
              <Button
                theme="primary"
                color="brand"
                size="middle"
                onClick={submit}
                isLoading={isSubmitLoading}
              >
                Отправить
              </Button>
            </Space>
          </FieldsWrapper>
      }
    </FormProvider>
  );
}