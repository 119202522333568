import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

import { TOnSubmitRentFormCb, useRentForm } from "./hooks";
import {
  useGetAvailableVehicles,
  useGetIdleList,
  useGetRentCosts,
} from "@/helpers/api/flex/vehicles/hooks";

import {
  StyledRentFormWrapper,
  StyledFormLayout,
  StyledRateParagraph,
  StyledDatepickerDash,
  StyledDatepickerFieldWrapper,
  StyledRateInfo,
  StyledRateColumn,
  StyledRadioWrapper,
  StyledFormRow,
  StyledRadioLabel,
} from "./index.styles";
import { Button } from "@/packages/button/Button";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Radio } from "@/packages/radio/Radio";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { Loader } from "@/packages/loader/Loader";
import { useTranslation } from "react-i18next";
import { ContextProfile } from "@/hooks/contexts/useProfile";

export interface IRentForm {
  isLoading: boolean;
  onFormSubmit: TOnSubmitRentFormCb;
  defaultValues?: any;
  dealerOptions: ISelectOption[];
  isNew?: boolean;
  isSubmitted: boolean;
  setIsSubmitted: (value: boolean) => void;
  setIsDisabled: (value: boolean) => void;
}

export const RentForm: React.FC<IRentForm> = (props) => {
  const {
    isLoading,
    onFormSubmit,
    defaultValues,
    dealerOptions,
    isNew,
    isSubmitted,
    setIsSubmitted,
    setIsDisabled,
  } = props;
  const [isMounted, setIsMounted] = useState(false);
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const isDealer = profile?.type === "dealer";

  if (isDealer) {
    defaultValues.dealerId = dealerOptions[0].value;
  }

  const [isRent, setIsRent] = useState(false);

  const { form, onSubmitHandler } = useRentForm(
    onFormSubmit,
    defaultValues,
    isRent
  );
  const [from, to, dealerId, vehicleId, event] = form.watch([
    "from",
    "to",
    "dealerId",
    "vehicleId",
    "event",
  ]);

  const [generationId, setGenerationId] = useState(null);

  const isCostEnabled = !!from && !!to && !!generationId;

  const { data: availableVehiclesResponse } = useGetAvailableVehicles({
    dealerId: dealerId,
  });

  const { data: idleListResponse } = useGetIdleList({
    limit: 0,
  });
  const { t } = useTranslation();

  const { data: rentCostsResponse, isLoading: isRentCostsLoading } =
    useGetRentCosts({
      request: { from, to, generationId },
    });

  const totalSum = rentCostsResponse?.data?.sum || 0;
  const prepayment = rentCostsResponse?.data?.prepayment || 0;

  const vehicleOptions = useMemo(() => {
    return (
      availableVehiclesResponse?.data?.data?.map((vehicle) => ({
        value: vehicle.id,
        label: `${
          vehicle?.equipment?.modification?.generation?.fullModelName || ""
        } ${vehicle?.vin || ""} ${vehicle?.number || ""}`,
        generationId: vehicle?.equipment?.modification?.generation?.id,
      })) || []
    );
  }, [availableVehiclesResponse]);

  const idleReasonOptions =
    idleListResponse?.data?.data.map((idle) => ({
      value: idle.id,
      label: idle.name,
    })) || [];

  const handleSelectRent = () => {
    form.setValue("event", "rent");
  };

  const handleSelectIdle = () => {
    form.setValue("event", "idle");
  };

  const clearForms = (...arg: any): void => {
    arg.forEach((formName) => {
      if (formName && form.watch(formName)) {
        form.setValue(formName, null);
      }
    });
  };

  useEffect(() => {
    setIsRent(event === "rent");
  }, [event]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      clearForms("vehicleId");
    }
  }, [dealerId]);

  useEffect(() => {
    if (vehicleId && vehicleOptions.length) {
      const selectedVehicleOption = vehicleOptions.find(
        (option) => option.value === vehicleId
      );
      setGenerationId(selectedVehicleOption?.generationId);
    }
  }, [vehicleId, vehicleOptions]);

  useEffect(() => {
    form.setValue("total", totalSum);
  }, [totalSum]);

  useEffect(() => {
    if (isSubmitted) {
      onSubmitHandler();
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if ((isNew && !form.formState.isDirty) || isLoading) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [form.formState.isDirty, isLoading, isNew, setIsDisabled]);

  return (
    <StyledRentFormWrapper>
      <StyledFormLayout>
        <FormProvider {...form}>
          <StyledFormRow>
            <StyledRadioWrapper isActive={isRent} onClick={handleSelectRent}>
              <Radio value={isRent} />
              <StyledRadioLabel>
                <Paragraph size={14} weight={600}>
                  {t("kiaFlex.rent") || ""}
                </Paragraph>
                <Paragraph size={12}>{t("kiaFlex.rentDescr") || ""}</Paragraph>
              </StyledRadioLabel>
            </StyledRadioWrapper>
            <StyledRadioWrapper isActive={!isRent} onClick={handleSelectIdle}>
              <Radio value={!isRent} />
              <StyledRadioLabel>
                <Paragraph size={14} weight={600}>
                  {t("kiaFlex.downtime") || ""}
                </Paragraph>
                <Paragraph size={12}>{t("kiaFlex.downtimeDescr") || ""}</Paragraph>
              </StyledRadioLabel>
            </StyledRadioWrapper>
          </StyledFormRow>
          <StyledFormRow>
            <StyledDatepickerFieldWrapper>
              <FormDatepickerField
                name="from"
                label={t("kiaFlex.from") || ""}
                maxDate={to || undefined}
              />
            </StyledDatepickerFieldWrapper>
            <StyledDatepickerDash>_</StyledDatepickerDash>
            <StyledDatepickerFieldWrapper>
              <FormDatepickerField
                name="to"
                label={t("kiaFlex.to") || ""}
                minDate={from || undefined}
              />
            </StyledDatepickerFieldWrapper>
          </StyledFormRow>
          {!isRent ? (
            <FormSelect
              name="idleId"
              label={t("kiaFlex.idleId") || ""}
              placeholder={t("kiaFlex.idleIdDescr") || ""}
              options={idleReasonOptions}
            />
          ) : null}
          {!isDealer ? (
            <FormSelect
              name="dealerId"
              label={t("kiaFlex.dealerId") || ""}
              placeholder={t("kiaFlex.dealerDescr") || ""}
              options={dealerOptions}
            />
          ) : (
            <></>
          )}
          <FormSelect
            name="vehicleId"
            label={t("kiaFlex.auto") || ""}
            placeholder={t("kiaFlex.autoDescr") || ""}
            options={vehicleOptions}
          />
          {isRent ? (
            <>
              <StyledRateParagraph size={14}>
                {t("kiaFlex.info") || ""}
              </StyledRateParagraph>
              {isCostEnabled ? (
                !isRentCostsLoading ? (
                  <StyledRateInfo>
                    <StyledRateColumn>
                      <Paragraph size={14} weight={800}>
                        {t("kiaFlex.sum") || ""}
                      </Paragraph>
                      <Paragraph size={14}>{`${totalSum} ₽`}</Paragraph>
                    </StyledRateColumn>

                    <StyledRateColumn>
                      <Paragraph size={14} weight={800}>
                        {t("kiaFlex.prepayment") || ""}
                      </Paragraph>
                      <Paragraph size={14}>{`${prepayment} ₽`}</Paragraph>
                    </StyledRateColumn>
                  </StyledRateInfo>
                ) : (
                  <Loader size={48} />
                )
              ) : (
                <Paragraph size={16}>{t("kiaFlex.rentRequired")}</Paragraph>
              )}
            </>
          ) : (
            <></>
          )}
        </FormProvider>
      </StyledFormLayout>
    </StyledRentFormWrapper>
  );
};
