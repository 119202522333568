import styled from "styled-components";
import { Title } from "@/packages/title/Title";


const StyledTableSimple = styled.div`
  display: grid;
  justify-items: center;
  gap: 10px;
  margin-top: 40px;
`;
const StyledTitle = styled(Title)`
  text-align: center;
`

export {
  StyledTableSimple,
  StyledTitle,
};
