import { TGetFlexQuotasResponse } from "@/helpers/api/flex/settings/types";

export const transformQuotasModel = (quotas: TGetFlexQuotasResponse) => {
  const result = quotas?.data.map((item) => {
    const res = item.months.reduce((acc, month) => {
      month.generations.map((gen) => {
        const fullCarName = `${gen.generation.model.name || ""} ${
          gen.generation.name || ""
        } ${gen.generation.restylingName || ""}`;
        if (acc[fullCarName]) {
          acc[fullCarName] = {
            ...acc[fullCarName],
            [month.month]: gen.amount,
          };
        } else {
          acc[fullCarName] = { [month.month]: gen.amount };
        }
      });

      return acc;
    }, {});

    const resultModel = Object.keys(res).map((key) => ({
      name: key,
      months: res[key],
    }));

    const total = item.months.map((month) => ({
      [month.month]: month.total,
    }));

    return {
      total,
      models: resultModel,
      id: item.id,
      name: item.dealer.merchantName,
      holding: item.dealer.holding?.name,
      code: item.dealer.mobisCode,
    };
  });

  return result;
};

export const uploadAllFilesHandler = async (
  file: any,
  successNotes,
  errorNotes
) => {
  try {
    // @ts-ignore

    const blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.href = href;
    document.body.appendChild(link);
    link.click();
    successNotes("Скачивание архива прошло успешно");
  } catch (e) {
    errorNotes("Произошла ошибка при скачивании архива");
  }
};
