import { useState } from "react";
import { IRequestParams } from "@/helpers/interfaces";

export const useRequestParams = <T = IRequestParams>(
  initialParams: T | IRequestParams = {
    take: {
      value: 10,
    },
    skip: {
      value: 0,
    },
  }
) => {
  const [requestParams, setRequestParams] =
    useState<T | IRequestParams>(initialParams);

  return [requestParams, setRequestParams] as const;
};
