import React, { useState } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { StyledFrame, StyledInputWrap, StyledParagraph } from "./index.styles";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { useDealerBonusesModalForm } from "./hooks";
import { FormProvider } from "react-hook-form";
import { Button } from "@/packages/button/Button";
import { TEditBonusesParamsRequest } from "@/helpers/api/bonuses/calculations/types";
import { Icon } from "@/packages/icon/Icon";
import { useTranslation } from "react-i18next";

export interface IBonusesModal
  extends Pick<
    IModal,
    "onCancel" | "isOpen" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
  > {
  onSubmit: (data: TEditBonusesParamsRequest) => void;
  quarter: number;
  year: number;
  planPercent: number;
  bonusPercent: number;
}

const BonusesModal: React.FC<IBonusesModal> = (props) => {
  const { onCancel, onSubmit, planPercent, bonusPercent, quarter } = props;
  const [defaulValue, setDefaulValue] = useState({
    planPercent,
    bonusPercent,
  });

  const { t } = useTranslation();

  const { form, submit } = useDealerBonusesModalForm(onSubmit, defaulValue);

  const [disableBlock, setDisabledBlock] = useState({
    planPercent: true,
    bonusPercent: true,
  });

  const handleUnlock = ({ key }: { key: string }) => {
    setDisabledBlock((prev) => ({ ...prev, [key]: false }));
  };
  return (
    <Modal
      {...props}
      title={t("bonuses.reportsView.premium") || ""}
      confirmButtonText={t("common.confirm") || ""}
      cancelButtonText={t("common.cancel") || ""}
      onSubmit={submit}
      onCancel={() => onCancel && onCancel()}
    >
      <FormProvider {...form}>
        <StyledInputWrap>
          <FormInputField
            suffix="%"
            name="bonusPercent"
            label={t("bonuses.reportsView.bonusPercent") || ""}
            isBlock={true}
            isDisabled={disableBlock.bonusPercent}
          />
          <Button
            color="brand"
            size="small"
            isDisabled={!disableBlock.planPercent}
            onClick={() => handleUnlock({ key: "bonusPercent" })}
          >
            {t("common.change") || ""}
          </Button>
        </StyledInputWrap>
        <StyledInputWrap>
          <FormInputField
            suffix="%"
            name="planPercent"
            label={t("bonuses.reportsView.overallPlan") || ""}
            isBlock={true}
            isDisabled={disableBlock.planPercent}
          />
          <Button
            color="brand"
            size="small"
            isDisabled={!disableBlock.bonusPercent}
            onClick={() => handleUnlock({ key: "planPercent" })}
          >
            {t("common.change") || ""}
          </Button>
        </StyledInputWrap>
        <StyledFrame>
          <Icon name="warning" size={64} color="red" />
          <StyledParagraph size={14}>
            {t("bonuses.reportsView.message") || ""} {quarter}
          </StyledParagraph>
        </StyledFrame>
      </FormProvider>
    </Modal>
  );
};

export { BonusesModal };
