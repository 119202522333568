import { Button } from "@/packages/button/Button";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const StyledHeaderContainer = styled.div`
  margin-bottom: 32px;
`;

export const StyledFormTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledFromFormLayoutBottomBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSubmitButton = styled(Button)`
  margin-top: 24px;
`;
