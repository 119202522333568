import { FC, useState } from "react";
import { PremiumTableHeaders } from "@/components/Bonuses/BonusesPremium/hooks";
import { PremiumHeader } from "@/components/Bonuses/BonusesPremium/PremiumHeader";
import { PremiumTableAdapter } from "@/components/Bonuses/BonusesPremium/PremiumTableAdapter";
import {
  useCloseQuarter,
  useGetQuarter,
} from "@/helpers/api/bonuses/calculations/hooks";
import {
  useExportDocuments,
  useGenerateDocuments,
  useGetDocumentList,
  usePatchDocument,
  usePatchDocumentlist,
} from "@/helpers/api/bonuses/premium-documents/hooks";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { handleFileLoad } from "@/helpers/utils";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BonusesPremium: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);
  const { paginationParams, setPage } = usePagination();
  const { createNotificationError } = useNotification();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [dealerSearch, setDealerSearch] = useState<string>("");

  const {
    data: documentListResponse,
    isLoading: isDocumentListLoading,
    refetch: refetchDocumentList,
  } = useGetDocumentList({
    "filter[quarter]": selectedQuarter,
    "filter[year]": selectedYear,
    "filter[dealerSearch]": dealerSearch,
    "page[limit]": paginationParams.limit,
    "page[page]": paginationParams.page,
  });

  const { data: quarterResponse, isLoading: isQuarterLoading } = useGetQuarter({
    quarter: selectedQuarter,
    year: selectedYear,
  });

  const { mutateAsync: closeQuarter } = useCloseQuarter();
  const { mutateAsync: generateDocuments } = useGenerateDocuments();
  const { mutateAsync: exportDocuments } = useExportDocuments();
  const { mutateAsync: updateDocumentList } = usePatchDocumentlist();
  const { mutateAsync: updateDocument } = usePatchDocument();

  const handleCloseQuarter = async () => {
    try {
      await closeQuarter({ quarter: selectedQuarter, year: selectedYear });
    } catch (error) {
      //@ts-ignore
      createNotificationError(error.response?.data?.message || "");
    }
  };

  const handleGenerateDocuments = async () => {
    try {
      await generateDocuments({ quarter: selectedQuarter, year: selectedYear });
      refetchDocumentList();
    } catch (error) {
      //@ts-ignore
      createNotificationError(error.response?.data?.message || "");
    }
  };

  const handleExportDocuments = () => {
    exportDocuments(
      { quarter: selectedQuarter, year: selectedYear, ids: checkedIds },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "documents.xlsx");
        },
      }
    );
  };

  const hanldeUpdateDocuments = async (value: string, id?: string) => {
    try {
      if (id) {
        await updateDocument({
          id: id,
          patch: {
            commitment: value,
          },
        });
      } else {
        await updateDocumentList({
          quarter: selectedQuarter,
          year: selectedYear,
          commitment: value,
        });
      }
    } catch (error) {
      //@ts-ignore
      createNotificationError(error.response?.data?.message || "");
    }
  };

  const headers = PremiumTableHeaders({
    onViewClick: (path) => {
      navigate(path);
    },
    onUpdateCommitment: hanldeUpdateDocuments,
  });

  const isDocumentsGenerated = !!quarterResponse?.data?.isDocumentsGenerated;
  const quarterExists = !!quarterResponse?.data;
  const quartedClosedAt = quarterResponse?.data?.closedAt;

  const documentsData = documentListResponse?.data.data || [];

  const handleRowsChecked = (ids: string[]) => {
    const clickedRowIds = ids.reduce<string[]>((acc, id) => {
      const matchDocumend = documentsData.find((_, index) => +id === index);

      if (matchDocumend) acc.push(matchDocumend.id);

      return acc;
    }, []);

    setCheckedIds(clickedRowIds);
  };

  return (
    <DashboardLayout
      title={t("bonuses.premium.title_L") || ""}
      description={t("bonuses.premium.description") || ""}
    >
      <PremiumHeader
        onGenerateDocuments={handleGenerateDocuments}
        isActGenerated={isDocumentsGenerated}
        handleExport={handleExportDocuments}
        handleCloseQuarter={handleCloseQuarter}
        onSearchInput={(value) => {
          setDealerSearch(value || "");
        }}
        quartedClosedAt={quartedClosedAt}
        quarterExists={quarterExists}
        isQuarterLoading={isQuarterLoading}
        documentIds={checkedIds}
      />
      {isDocumentsGenerated ? (
        // {documentsTableData ? (
        <PremiumTableAdapter
          isLoading={isDocumentListLoading}
          paginationParams={{
            ...paginationParams,
            limit: documentListResponse?.data.meta?.pageCount || 0,
          }}
          setPage={setPage}
        >
          <TableSimple
            headers={headers || []}
            // data={mockTableData}
            data={documentsData || []}
            defaultColumn={{
              minWidth: 25,
              width: 125,
              maxWidth: 300,
            }}
            onRowsChecked={handleRowsChecked}
          />
        </PremiumTableAdapter>
      ) : null}
    </DashboardLayout>
  );
};
