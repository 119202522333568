import { useGetProfile, useUpdateProfile } from "@/helpers/api/profile/hooks";
import { createContext } from "react";

export const useProfile = () => {
  const { data: profile, isLoading, error: getProfileError } = useGetProfile();
  const { mutateAsync: updateProfileAsync } = useUpdateProfile();

  return {
    state: {
      profile: profile?.data,
      isProfileLoading: isLoading,
    },
    mutations: {},
    actions: {
      updateProfileAsync,
    },
  };
};

export type PROFILE = ReturnType<typeof useProfile>;

export const ContextProfile = createContext<PROFILE>({} as PROFILE);
