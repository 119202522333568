import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { EditQuotasModal } from "@/components/KiaFlex/SettingsQuotas/SettingsEditModal";
import { colors } from "@/helpers";
import { usePatchFlexQuotas } from "@/helpers/api/flex/settings/hooks";
import { MONTHS } from "@/helpers/consts";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Icon } from "@/packages/icon/Icon";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Column,
  useBlockLayout,
  useExpanded,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const monthsNumberToSlug = {
  0: "monthOne",
  1: "monthTwo",
  2: "monthThree",
  3: "monthFour",
  4: "monthFive",
  5: "monthSix",
  6: "monthSeven",
  7: "monthEight",
  8: "monthNine",
  9: "monthTen",
  10: "monthEleven",
  11: "monthTwelve",
};

interface IFlexQuotasTableProps {
  data: IFlexQuotasRecord[];
  onSort: (accessor: HeaderArgType, order: SortOrder) => void;
  udpdateData: () => void;
}

export interface IFlexQuotasRecord {
  name: string;
  code?: string;
  holding: string;
  id: string;
  models: IFlexQuotasModel[];
  total: number[];
}

interface IFlexQuotasModel {
  name: string;
  months: {
    [month: string]: number;
  };
}

interface IFlexQuotasTableColumns {
  name: string;
  holding?: string;
  code?: string;
  subRows?: IFlexQuotasTableColumns[];
}

const StyledTable = styled.div`
  overflow-x: auto;
  min-width: auto !important;

  [data-sticky-last-left-td] {
    box-shadow: 2px 0 15px rgba(33, 33, 52, 0.1);
    clip-path: inset(0px -15px 0px 0px);
  }
`;

const StyledHeaderRow = styled.div`
  box-shadow: 0 2px 15px rgba(33, 33, 52, 0.1);
  border-bottom: 1px solid ${colors.neutral150};
`;

const StyledHeaderCell = styled.div`
  background: ${colors.table};
  padding: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.black};
  text-align: center;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:not(:last-child):before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${colors.gray3};
    height: 16px;
    width: 1px;
  }
`;

const StyledSubHeaderCell = styled.div<{
  defaultCanSort: boolean;
  isCentered: boolean;
}>`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 9px 12px;
  background: ${colors.white};

  ${({ defaultCanSort }) => defaultCanSort && "cursor: pointer;"}
  ${({ isCentered }) =>
    isCentered &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

const StyledExpandButton = styled(IconButton)<{ isReversed: boolean }>`
  ${({ isReversed }) => isReversed && "transform: rotate(180deg);"}
`;

const StyledCell = styled.div<{
  isCentered: boolean;
}>`
  padding: 12px;
  background: ${colors.white};
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.neutral800};

  display: flex !important;
  align-items: center;
  ${({ isCentered }) =>
    isCentered &&
    css`
      justify-content: center;
    `}

  &:first-child {
    padding-left: 29px;
  }
`;

const StyledEditCell = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const StyledRow = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.neutral150};
  }
`;

const StyledSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const StyledHeaderCellContent = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;

const StyledHeaderCellTip = styled.span`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.neutral400};
  margin-top: 4px;
`;

export const FlexQuotasTable: FC<IFlexQuotasTableProps> = (props) => {
  const { data: rawData, onSort, udpdateData } = props;

  const [quotasId, setQuotasId] = useState<string>("");

  const { mutate: updateQuotas } = usePatchFlexQuotas();

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { t } = useTranslation();

  const {
    modalState: editQuotasModalState,
    openModal: editQuotasModal,
    closeModal: editQuotasModalClose,
  } = useModal();

  const handleSubmit = (data) => {
    updateQuotas({
      ...data,
      onSuccess: () => {
        udpdateData();
        editQuotasModalClose();
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessQuote") || ""
        );
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const data = useMemo<IFlexQuotasTableColumns[]>(
    () =>
      rawData.map((record) => {
        return {
          name: record.name,
          code: record.code,
          holding: record.holding,
          ...MONTHS.reduce((prev, monthName, monthNumber) => {
            return {
              ...prev,
              [monthNumber]: record.total?.reduce((prevValue, month) => {
                prevValue += month[monthNumber] ? month[monthNumber] : 0;
                return prevValue;
              }, 0),
            };
          }, {}),

          subRows: record.models.map((model) => {
            return {
              name: model?.name,
              ...MONTHS.reduce((prev, monthName, monthNumber) => {
                return {
                  ...prev,

                  [monthNumber]: model.months[monthNumber]
                    ? model.months[monthNumber]
                    : 0,
                };
              }, {}),
            };
          }),
        };
      }),
    [rawData]
  );

  const columns = useMemo<Column<IFlexQuotasTableColumns>[]>(
    () => [
      {
        Header: t("kiaFlex.DCinfo") || "",
        sticky: "left",
        columns: [
          {
            id: "edit",
            width: 162,
            Cell: ({ row }) => {
              if (row.id.split(".").length <= 1) {
                return (
                  <PermissionsAdapter
                    resourceName="flex.quotas"
                    resourceAction="update"
                  >
                    <StyledEditCell>
                      <IconButton
                        icon="edit"
                        variant="secondary"
                        color="gray"
                        isSquared={true}
                        onClick={() => {
                          editQuotasModal();
                          setQuotasId(rawData[row.id].id);
                        }}
                      />
                      {row.original.code}
                    </StyledEditCell>
                  </PermissionsAdapter>
                );
              }

              return null;
            },
          },
          {
            Header: t("bonuses.reportsView.dealerCenter") || "",
            accessor: "name",
            width: 200,
            defaultCanSort: true,
          },
          {
            Header: t("audits.list.holdingId") || "",
            // @ts-ignore
            accessor: "holdingName",
            defaultCanSort: true,
            Cell: ({ row: { original } }) => {
              return original.holding;
            },
          },
          {
            id: "expander",
            width: 56,
            Cell: ({ row }) => {
              if (row.canExpand) {
                return (
                  <StyledExpandButton
                    {...row.getToggleRowExpandedProps()}
                    icon="chevron-down-16"
                    variant={row.isExpanded ? "primary" : "secondary"}
                    size="xs"
                    isReversed={row.isExpanded}
                  />
                );
              }

              return null;
            },
          },
        ],
      },
      // @ts-ignore
      {
        Header: t("kiaFlex.month") || "",
        columns: MONTHS.map((monthName, monthNumber) => ({
          Header: t(monthName),
          accessor: monthsNumberToSlug[monthNumber],
          width: 220,
          defaultCanSort: true,
          isCentered: true,
          isSortable: true,
          Cell: ({ row: { original } }) => {
            return original[monthNumber];
          },
          tip: `${data.reduce((prev, record) => {
            prev += record[monthNumber] ? record[monthNumber] : 0;
            return prev;
          }, 0)} ${t("kiaFlex.autos") || ""}`,
        })),
      },
    ],
    [data]
  );

  const { getTableProps, prepareRow, headerGroups, getTableBodyProps, rows } =
    useTable(
      {
        columns,
        data,
        manualSortBy: true,
      },
      useBlockLayout,
      useSticky,
      useSortBy,
      useExpanded
    );

  const HeaderCell = (props) => {
    const { index } = props;
    const HeaderComponent =
      index === 0 ? StyledHeaderCell : StyledSubHeaderCell;

    return <HeaderComponent {...props} />;
  };

  const [sortOrders, setSortOrders] = useState<SortOrder[]>(
    columns.map(() => null)
  );

  useEffect(() => setSortOrders(columns.map(() => null)), []);

  const handleHeaderClick = (header: HeaderArgType, index: number) => {
    if (header.defaultCanSort) {
      const sortOrder = sortOrders[index];
      let newSortOrder: SortOrder = null;
      if (!sortOrder) newSortOrder = "asc";
      if (sortOrder === "asc") newSortOrder = "desc";
      if (sortOrder === "desc") newSortOrder = null;
      const newSortOrders: SortOrder[] = columns.map(() => null);
      newSortOrders[index] = newSortOrder;
      setSortOrders(newSortOrders);
      if (onSort) onSort(header, newSortOrder);
    }
  };

  return (
    <>
      <StyledTable {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map((headerGroup, headerLevelIndex) => (
            <StyledHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <HeaderCell
                    {...column.getHeaderProps()}
                    index={headerLevelIndex}
                    defaultCanSort={column.defaultCanSort}
                    sSortable={column.isSorted}
                    isCentered={!!column.isCentered}
                    //@ts-ignore
                    onClick={() => handleHeaderClick(column, index)}
                  >
                    <StyledHeaderCellContent>
                      {typeof column.render("Header") === "string" ? (
                        t(`${column.render("Header")}`)
                      ) : (
                        <></>
                      )}
                      {column.defaultCanSort ? (
                        <StyledSortIcon>
                          <Icon
                            size={8}
                            name="chevron-sort-up-8"
                            color={
                              column.isSortedDesc === true ? "black" : "gray400"
                            }
                          />
                          <Icon
                            size={8}
                            name="chevron-sort-down-8"
                            color={
                              column.isSortedDesc === false
                                ? "black"
                                : "gray400"
                            }
                          />
                        </StyledSortIcon>
                      ) : null}
                    </StyledHeaderCellContent>
                    {column.tip ? (
                      <StyledHeaderCellTip>{column.tip}</StyledHeaderCellTip>
                    ) : null}
                  </HeaderCell>
                );
              })}
            </StyledHeaderRow>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);

            return (
              <StyledRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <StyledCell
                      {...cell.getCellProps()}
                      isCentered={!!cell.column.isCentered}
                    >
                      {cell.render("Cell")}
                    </StyledCell>
                  );
                })}
              </StyledRow>
            );
          })}
        </div>
      </StyledTable>
      {editQuotasModalState.isOpen ? (
        <EditQuotasModal
          quotasId={quotasId}
          isOpen={editQuotasModalState.isOpen}
          onSubmit={handleSubmit}
          onCancel={editQuotasModalClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};
