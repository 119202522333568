import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const DirectorRegistrationWrapper = styled.div`
  padding: 64px 64px 32px 64px;
  width: 506px;
  background: ${colors.white};
  border-radius: 16px;
`;

export const DirectorRegistrationTitle = styled(Title)`
  width: 100%;
  margin-bottom: 20px;
`;

export const DirectorRegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  margin-top: 20px;
`;

export const DirectorRegistrationInput = styled(FormInputField)`
  width: 100%;
  margin-bottom: 20px;
`;

export const DirectorRegistrationButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;
