import { media } from "@/helpers";

import styled from "styled-components";

const StyledCharts = styled.div`
  display: grid;
  justify-content: center;
  padding: 48px 0;
  border-radius: 12px;
  gap: 40px;
  margin-bottom: 24px;

  ${media.mobile} {
    grid-template-columns: repeat(auto-fill, 270px);
  }

  ${media.tablet} {
    grid-template-columns: repeat(auto-fill, 500px);
  }

  ${media.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  justify-content: flex-end;
`;
export { StyledCharts, ActionWrapper };
