import { Button } from "@/packages/button/Button";
import styled from "styled-components";
import { IconButton } from "@/packages/icon-button/IconButton";

export const StyledMainInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 32px 0 0;
`;

export const StyledMainTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledBtnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 10px;
`;
export const StyledMainWrapperCard = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button).attrs({
  size: "middle",
  color: "brand",
})`
  margin-top: 22px;
`;
