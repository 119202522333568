import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ConfirmAlertRegisterModalProps } from "./ConfirmRegisterAlertModal";
import { t } from "i18next";

export type ConfirmRegisterFormValues = {
  password: string;
  repeatPassword: string;
};

export type OnSubmitConfirmRegisterFormHandler = (
  data: ConfirmRegisterFormValues
) => void;

const DEFAULT_CONFIRM_REGISTER_FORM_VALUES: ConfirmRegisterFormValues = {
  password: "",
  repeatPassword: "",
};

export const formSchema = () => {
  return yup
    .object({
      password: yup
        .string()
        .required(t("common.password.passwordEnter") || "")
        .matches(
          /[^ЁёА-я]+$/,
          t("registers.holdingsDC.validation.letters") || ""
        )
        .min(6, t("common.password.min") || "")
        .max(12, t("common.password.max") || ""),

      repeatPassword: yup
        .string()
        .oneOf([yup.ref("password")], t("common.password.same") || "")
        .required(t("common.password.again") || ""),
    })
    .required();
};

export const useConfirmRegisterForm = (
  onSubmit: OnSubmitConfirmRegisterFormHandler
) => {
  const [confirmModalState, setConfirmModalState] =
    React.useState<ConfirmAlertRegisterModalProps>({
      isOpen: false,
    });

  const form = useForm<ConfirmRegisterFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_CONFIRM_REGISTER_FORM_VALUES,
  });

  const onSubmitButtonClickHandler = form.handleSubmit(async (data) => {
    setConfirmModalState({
      isOpen: true,
      onCancel: () => {
        setConfirmModalState({ isOpen: false });
      },
      onSubmit: () => {
        setConfirmModalState({ isOpen: false });
        onSubmit(data);
      },
    });
  });

  return {
    form,
    onSubmitButtonClickHandler,
    confirmModalState,
  };
};
