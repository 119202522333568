import { ResetPasswordForm } from "@/components/resetPassword/ResetPasswordForm";
import { TOnSubmitResetPasswordFormCb } from "@/components/resetPassword/ResetPasswordForm/hooks";
import { useAuthResetPassword } from "@/helpers/api/auth/hooks";
import { useNotification } from "@/hooks/useNotification";
import IdentityLayout from "@/layouts/IdentityLayout";
import { AxiosError } from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const { mutateAsync: authResetPassword } = useAuthResetPassword();

  const { createNotificationError } = useNotification();

  const navigate = useNavigate();

  const search = useLocation().search;

  const email = new URLSearchParams(search).get("email");
  const hash = new URLSearchParams(search).get("hash");

  const onSubmitHandler: TOnSubmitResetPasswordFormCb = async (data) => {
    if (email !== null && hash !== null) {
      authResetPassword({
        email: email,
        hash: hash,
        password: data.password,
        retryPassword: data.repeatPassword,
      })
        .then(() => {
          navigate("/login");
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            createNotificationError("Ссылка не действительна");
          } else {
            const errorTyped = error as AxiosError<{
              message: string | string[];
            }>;
            createNotificationError(errorTyped?.response?.data.message || "");
          }
        });
    } else {
      createNotificationError("Ссылка не действительна");
    }
  };

  return (
    <IdentityLayout>
      <ResetPasswordForm onSubmit={onSubmitHandler} />
    </IdentityLayout>
  );
};

export default ResetPassword;
