import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum SellsFilterFormFieldsNameEnum {
  County = "county",
  Manager = "manager",
  State = "state",
  City = "city",
  Dealer = "dealerId",
}

export interface ISellsFilterFormValues {
  [SellsFilterFormFieldsNameEnum.County]: string | undefined;
  [SellsFilterFormFieldsNameEnum.Manager]: string | undefined;
  [SellsFilterFormFieldsNameEnum.State]: string | undefined;
  [SellsFilterFormFieldsNameEnum.City]: string | undefined;
  [SellsFilterFormFieldsNameEnum.Dealer]: string | undefined;
}

export const DEFAULT_SELLS_FILTER_FORM_VALUES: ISellsFilterFormValues = {
  [SellsFilterFormFieldsNameEnum.County]: undefined,
  [SellsFilterFormFieldsNameEnum.Manager]: undefined,
  [SellsFilterFormFieldsNameEnum.State]: undefined,
  [SellsFilterFormFieldsNameEnum.City]: undefined,
  [SellsFilterFormFieldsNameEnum.Dealer]: undefined,
};

export type TOnSubmitSellsFilterFormCb =
  TOnSubmitFormSyncCb<ISellsFilterFormValues>;

export const useSellsFilterForm = (options: {
  onSubmit: TOnSubmitSellsFilterFormCb;
  // initValues: ISellsFilterFormValues;
}) => {
  // const { initValues, onSubmit } = options;
  const { onSubmit } = options;

  const result = useForm<ISellsFilterFormValues>({
    defaultValues: DEFAULT_SELLS_FILTER_FORM_VALUES,
    onSubmit,
  });

  // useEffect(() => {
  //   result.form.reset(initValues, { keepDefaultValues: true });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return result;
};
