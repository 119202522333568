import { useState } from "react";

export const useSelectItems = <ItemType = any>() => {
  const [selectItems, setSelectItems] = useState<ItemType[]>([]);
  const isEmptySelectItemsList = !selectItems.length;

  const onClearSelectItems = () => {
    setSelectItems([]);
  };

  return {
    selectItems,
    setSelectItems,
    isEmptySelectItemsList,
    onClearSelectItems,
  };
};
