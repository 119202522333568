import { usePermission } from "@/hooks/usePermission";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TGetAuditsListRequest,
  TGetAuditsListResponse,
  TCreateAuditRequest,
  TCreateAuditResponse,
  TCopyAuditRequest,
  TCopyAuditResponse,
  TDeleteAuditRequest,
  TDeleteAuditResponse,
  TGetAuditorsListResponse,
} from "./types";

export const useGetAuditList = (
  request: TGetAuditsListRequest,
  isKia: boolean
) => {
  let path = "";
  const { hasAccess: hasHolding } = usePermission("audit.list-holding", "read");
  if (!isKia) {
    if (hasHolding) {
      path = "holding";
    } else {
      path = "dealer";
    }
  }

  return useQuery(
    ["audit", "list", request, path, isKia],
    () => {
      return api.get<TGetAuditsListResponse>(`/audits/${path}`, {
        params: request,
      });
    },
    {
      cacheTime: 0,
    }
  );
};

export const useGetAuditorsList = (request: TGetAuditsListRequest) => {
  return useQuery(["audit", "list", request], () => {
    return api.get<TGetAuditorsListResponse>("/audits/auditors", {
      params: request,
    });
  });
};

export const useCreateAudit = () => {
  return useMutation(
    ({
      data,
      onSuccess,
    }: {
      data: TCreateAuditRequest;
      onSuccess: () => void;
    }) => {
      return api.post<TCreateAuditResponse>("/audits", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useCopyAudit = () => {
  return useMutation(
    ({
      data,
      onSuccess,
    }: {
      data: TCopyAuditRequest;
      onSuccess: () => void;
    }) => {
      return api.post<TCopyAuditResponse>("/audits/copy", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useDeleteAudit = () => {
  return useMutation(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: TDeleteAuditRequest;
      onSuccess: () => void;
      onError: (response: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<TDeleteAuditResponse>(`/audits/${id}`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useExportAuditList = (isKia: boolean) => {
  let path = "kia";
  const { hasAccess: hasHolding } = usePermission("audit.list-holding", "read");
  if (!isKia) {
    if (hasHolding) {
      path = "holding";
    } else {
      path = "dealer";
    }
  }

  return useMutation((request: any) => {
    return api.get<any>(`audits/export-${path}`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportWarrantyAuditList = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/guarantee/audits/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
