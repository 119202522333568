import styled from "styled-components";

export const StyledRegistryFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledFormLayout = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, 188px);
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
