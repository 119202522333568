import React, { ReactNode } from "react";
import { ISelectField, SelectField } from "../SelectField";
import styled from "styled-components";

export type ISelectFieldWithPrefixIcon = ISelectField & {
  isDisabled?: boolean;
  prefixIcon: ReactNode;
};

const SelectWrapper = styled.div`
  position: relative;
`;

const PrefixIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`;

const StyledSelectField = styled(SelectField)`
  padding-left: 28px;
`;

const _SelectFieldWithPrefixIcon: React.FC<ISelectFieldWithPrefixIcon> = (
  props
) => {
  const { prefixIcon, ...inputProps } = props;

  return (
    <SelectWrapper>
      {prefixIcon && <PrefixIconWrapper>{prefixIcon}</PrefixIconWrapper>}
      <StyledSelectField {...inputProps} />
    </SelectWrapper>
  );
};
export const SelectFieldWithPrefixIcon = React.memo(_SelectFieldWithPrefixIcon);
