import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { IModal, Modal } from "@/packages/modal/Modal";
import {
  useCreateMaintenanceGridSettingsValue,
  useGetMaintenanceGridSettingsValue,
  useUpdateMaintenanceGridSettingsValue,
} from "@/helpers/api/shared/settings/hooks";
import { FC, useEffect, useState } from "react";
import { TSettingsValueRecord } from "@/helpers/api/shared/settings/types";
import { useTranslation } from "react-i18next";

type TWorksRatePerHourModal = Pick<IModal, "onSubmit" | "onCancel">;

export const PRICE_RATE_HOUR_KEY = "priceRateHour";

const WorksRatePerHourModal: FC<TWorksRatePerHourModal> = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const { data: priceRateHour } = useGetMaintenanceGridSettingsValue({
    key: PRICE_RATE_HOUR_KEY,
  });
  const { mutate: createSettingsValue } =
    useCreateMaintenanceGridSettingsValue();
  const { mutate: updateSettingsValue } =
    useUpdateMaintenanceGridSettingsValue();

  const [activeRatePerHour, setActiveRatePerHour] = useState<number | null>(
    Number(priceRateHour?.data.value)
  );

  const handleSubmit = () => {
    const data = {
      key: PRICE_RATE_HOUR_KEY as TSettingsValueRecord["key"],
      value: activeRatePerHour ? String(activeRatePerHour) : "",
    };

    priceRateHour?.data.key
      ? updateSettingsValue(data)
      : createSettingsValue(data);
    onSubmit && onSubmit();
  };

  useEffect(() => {
    priceRateHour?.data.value &&
      setActiveRatePerHour(Number(priceRateHour?.data.value || 0));
  }, [priceRateHour?.data]);

  return (
    <Modal
      {...props}
      title={t("maintenanceGrids.worksModel.makePrice") || ""}
      confirmButtonText={t("maintenanceGrids.worksModel.save") || ""}
      cancelButtonText={t("maintenanceGrids.worksModel.cancel") || ""}
      onSubmit={handleSubmit}
    >
      <InputNumberField
        label={t("maintenanceGrids.worksModel.makePrice") || ""}
        value={activeRatePerHour}
        onChange={(value) => setActiveRatePerHour(value)}
        description={t("maintenanceGrids.worksModel.setPrice") || ""}
      />
    </Modal>
  );
};

export { WorksRatePerHourModal };
