import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";

export const useGetDelegationsFromUser = () => {
  return useQuery(["rights-delegations", "from-user"], () => {
    return api.get<any>("/rights-delegations/from-user");
  });
};

export const useGetDelegationsToUser = (request: any) => {
  return useQuery(["rights-delegations", "to-user", request], () => {
    return api.get<any>("/rights-delegations/to-user", {
      params: request,
    });
  });
};

export const useGetDelegation = (request: any) => {
  return useQuery(["rights-delegations", "delegation", request], () => {
    return api.get<any>(`/rights-delegations/${request.id}`, {
      params: request,
    });
  });
};

export const useCreateDelegation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) => {
      return api.post<any>("/rights-delegations", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["rights-delegations", "delegation"]);
        queryClient.invalidateQueries([
          "rights-delegations",
          "from-user",
          variables.id,
        ]);
      },
    }
  );
};

export const usePatchDelegation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: any) => {
      return api.patch<any>(`/rights-delegations/${request.id}`, request.patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["rights-delegations", "delegation"]);
        queryClient.invalidateQueries([
          "rights-delegations",
          "from-user",
          variables.id,
        ]);
      },
    }
  );
};

export const useDeleteDelegation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: any) => {
      return api.delete<any>(`/rights-delegations/${request.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["rights-delegations", "delegation"]);
        queryClient.invalidateQueries([
          "rights-delegations",
          "from-user",
          variables.id,
        ]);
      },
    }
  );
};
