import { MaterialsTable } from "@/components/Materials";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import { FC, ReactNode, useState } from "react";

import { TFileRecord } from "@/helpers/api/files/types";
import { usePostMaterialFiles } from "@/helpers/api/materials/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useNotification } from "@/hooks/useNotification";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { DeletePopconfirm } from "@/packages/DeletePopconfirm";
import { IconButton } from "@/packages/icon-button/IconButton";
import { UploadButtonMaterials } from "@/packages/UploadButtonMaterials";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import { StyledCollapse, StyledPanel } from "./index.styles";
import { ITreeComponentProps } from "./TreeComponent";
import { IBatchResponseToMutate } from "./utils";
import { useTranslation } from "react-i18next";

export interface IRowProps extends ITreeComponentProps {
  section: IBatchResponseToMutate;
  children: ReactNode;
}

const Row: FC<IRowProps> = ({
  section,
  onDeleteMaterialSectionHandler,
  onUploadMaterialSectionHandler,
  children,
  data,
                              searchValue,
}) => {
  const { t } = useTranslation();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const { mutateAsync: postMaterialFiles } = usePostMaterialFiles();

  const [query, setQuery] = useQueryParams({
    activePanel: withDefault(ArrayParam, []),
  });

  const {
    state: { profile },
  } = useProfile();

  const {
    createNotificationSuccess,
    createNotificationError,
    createNotificationWarning,
  } = useNotification();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("dealerMaterials.materials.successMessage") || "",
  });

  const onUploadFile = async (file: TFileRecord, fileName: string) => {
    try {
      await postMaterialFiles({
        file_id: file.id,
        section_id: section.id,
        name: fileName,
      });
      createNotificationSuccess(
        t("dealerMaterials.materials.createNotificationSuccessFileAdd") || ""
      );
    } catch (error) {
      createNotificationError(
        t("dealerMaterials.materials.createNotificationErrorFileAdd") || ""
      );
    }
  };

  const onDeleteFilesHandler = () => {
    if (!checkedIds.length) {
      return createNotificationWarning(
        t("dealerMaterials.materials.createNotificationSuccessFileChoose") || ""
      );
    }
    onDeleteMaterialSectionHandler(checkedIds);
  };

  const onDownloadFilesHandler = () => {
    onUploadMaterialSectionHandler(section.id, checkedIds);
  };

  const onChange = (key: string | string[]) => {
    const { activePanel } = query;
    const filteredQuery = activePanel.filter((el) => el !== section.level);
    if (!key) {
      setQuery({ activePanel: filteredQuery });
    } else {
      setQuery({
        activePanel: [...filteredQuery, key as string],
      });
    }
  };

  const findItemNested = (
    initialArr: IBatchResponseToMutate[],
    itemId: string | null
  ) =>
    initialArr.reduce((a, initialItem) => {
      if (a) return a;
      if (initialItem.level === itemId) return (initialItem.isChecked = true);
      if (initialItem.children)
        return findItemNested(initialItem.children, itemId);
    }, null);

  if (query.activePanel) {
    query.activePanel.forEach((item) => {
      findItemNested(data, item);
    });
  }

  const genExtra = (key: string) => (
    <IconButton
      icon="share"
      color="black"
      size="l"
      variant="tertiary"
      onClick={() => {
        onChange(key);
        copyCurrentUrl();
      }}
    />
  );
  return (
    <StyledCollapse
      accordion
      expandIconPosition="end"
      bordered={false}
      className="site-collapse-custom-collapse"
      onChange={onChange}
      defaultActiveKey={section.isChecked ? section.level : ""}
    >
      <StyledPanel
        header={`${section.level} ${section.name}`}
        key={section.level as string}
        extra={genExtra(section.level as string)}
      >
        <Space align="center">
          <Space align="center">
            {profile?.type === "kia" && (
              <UploadButtonMaterials
                path="materials/files"
                color="brand"
                size="small"
                onUploadFile={onUploadFile}
              >
                <Paragraph size={12} color="black">
                  {t("dealerMaterials.materials.addNew") || ""}
                </Paragraph>
              </UploadButtonMaterials>
            )}
          </Space>
          <ButtonText
            onClick={onDownloadFilesHandler}
            // @ts-ignore
            isDisabled={section?.files?.length === 0}
          >
            {checkedIds.length > 0
              ? t("dealerMaterials.materials.downloadPart") || ""
              : t("dealerMaterials.materials.downloadAll") || ""}
          </ButtonText>
          {profile?.type === "kia" && (
            <DeletePopconfirm
              title={
                checkedIds.length > 0
                  ? t("dealerMaterials.materials.deleteSomeConfirm") || ""
                  : t("dealerMaterials.materials.deleteAllConfirm") || ""
              }
              onConfirm={onDeleteFilesHandler}
            >
              <ButtonText
                // @ts-ignore
                isDisabled={section?.files?.length === 0}
              >
                {checkedIds.length > 0
                  ? t("dealerMaterials.materials.deletePart") || ""
                  : t("dealerMaterials.materials.deleteAll") || ""}
              </ButtonText>
            </DeletePopconfirm>
          )}
        </Space>
        <MaterialsTable
          onDeleteMaterialSection={onDeleteMaterialSectionHandler}
          parrentId={section.id}
          setCheckedIds={setCheckedIds}
          searchValue={searchValue}
        />
        {children ? children : <></>}
      </StyledPanel>
    </StyledCollapse>
  );
};

export default Row;
