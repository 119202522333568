import { TUpdateTaskStatusDto } from "@/helpers/api/audit-tasks/types";
import { TFunction } from "i18next";
import { ITaskChatPayload } from ".";

export const auditChatBtnCallback = ({
                                       t,
                                       isDealerKia,
                                       selectedTask,
                                       handleChangeStatus,
                                       handleSendToReview,
                                       handleSendNotification,
                                     }: {
  t: TFunction;
  isDealerKia: boolean;
  selectedTask: ITaskChatPayload;
  handleChangeStatus: (id: number, status: TUpdateTaskStatusDto) => void;
  handleSendToReview: (isCancel: boolean) => void;
  handleSendNotification: () => void;
}) => {
  if (isDealerKia)
    return selectedTask?.status !== "accepted"
      ? selectedTask?.status !== "in_work"
        ? {
          cancelText: t("audits.tasks.accept") || "",
          cancelCb: () => {
            if (selectedTask?.taskId) {
              handleChangeStatus(selectedTask.taskId, "accepted");
            }
          },
          sideEffectText: 'Отправить уведомление дилеру',
          sideEffectCb: handleSendNotification,
          confirmText: t("audits.tasks.sendToRework") || "",
          confirmCb: () => {
            if (selectedTask?.taskId) {
              handleChangeStatus(selectedTask.taskId, "in_work");
            }
          },
        }
        : {
          cancelText: t("audits.tasks.accept") || "",
          cancelCb: () => {
            if (selectedTask?.taskId) {
              handleChangeStatus(selectedTask.taskId, "accepted");
            }
          },
          sideEffectText: 'Отправить уведомление дилеру',
          sideEffectCb: handleSendNotification,
        }
      : undefined;
  else if (selectedTask?.status === "review") {
    return {
      cancelText: t("audits.tasks.cancelSend") || "",
      cancelCb: () => handleSendToReview(true),
    };
  } else if (selectedTask?.status === "in_work") {
    return {
      confirmText: t("audits.tasks.sendToKia") || "",
      confirmCb: () => handleSendToReview(false),
    };
  }
};
