import { NotificationType, TNotificationWithStringId, } from "@/helpers/api/notifications/types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StyledButton, StyledFirstColumn, StyledRow, StyledSecondColumn, } from "./index.styles";

interface INotificationsResultSimple {
  notification: TNotificationWithStringId;
}

export const NotificationsResultSimple: FC<INotificationsResultSimple> = (
  props
) => {
  const { notification } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <StyledRow>
        <StyledFirstColumn>{notification.category}</StyledFirstColumn>
        <StyledSecondColumn>{notification.description}</StyledSecondColumn>
      </StyledRow>
      {notification.type === NotificationType.AUDIT_DEADLINE && (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/audit-list/fill/${notification.data.entityId}`)
          }
        >
          {t("notifications.toAudit")}
        </StyledButton>
      )}
      {notification.type === NotificationType.GUARANTEE_PLAN_DECLINE ||
      notification.type === NotificationType.GUARANTEE_PLAN_FILLED_BY_DEALER ? (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/warranty-audit/fill/${notification.data.entityId}`)
          }
        >
          {t("notifications.toAudit")}
        </StyledButton>
      ) : notification.type === NotificationType.MONITORING_REQUEST ? (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/monitoring-quality/${notification.data.entityId}/cars`)
          }
        >
          {t("notifications.toProgram")}
        </StyledButton>
      ) : null}
      {notification.type === NotificationType.AUDIT_STARTED ? (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/audit-list/fill/${notification.data.entityId}`)
          }
        >
          {t("notifications.toAudit")}
        </StyledButton>
      ) : (
        <></>
      )}
      {
        notification.type === NotificationType.AUDIT_PARAM_LEFT_COMMENT &&
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() => {
            const path = notification.data.taskId ?
              `/audit-plan/${notification.data.auditId}` :
              `/audit-list/fill/${notification.data.auditId}`;
            navigate(path);

          }
          }
        >
          {t("notifications.toAudit")}
        </StyledButton>
      }
      {
        (
          notification.type === NotificationType.GUARANTEE_AUDIT_STARTED ||
          notification.type === NotificationType.GUARANTEE_AUDIT_DONE ||
          notification.type === NotificationType.GUARANTEE_TASK_DEADLINE ||
          notification.type === NotificationType.GUARANTEE_VIOLATIONS
        ) ? (
          <StyledButton
            theme="primary"
            color="brand"
            onClick={() =>
              navigate(`/warranty-audit/fill/${notification.data.entityId}`)
            }
          >
            {t("notifications.toAudit")}
          </StyledButton>
        ) : (
          <></>
        )}
      {notification.type === NotificationType.INFORMATION_LETTER ? (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/information-letters/${notification.data.entityId}`)
          }
        >
          Перейти к письму
        </StyledButton>
      ) : (
        <></>
      )}
      {notification.type === NotificationType.AUDIT_REVIEW && (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/audit-list/fill/${notification.data.entityId}`)
          }
        >
          Перейти к аудиту
        </StyledButton>
      )}
      {notification.type === NotificationType.AUDIT_CREATE_TASKS && (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/audit-plan/${notification.data.entityId}`)
          }
        >
          Перейти к плану действий по аудиту
        </StyledButton>
      )}
      {notification.type === NotificationType.AUDIT_TASKS && (
        <StyledButton
          theme="primary"
          color="brand"
          onClick={() =>
            navigate(`/audit-tasks`)
          }
        >
          Перейти к списку задач
        </StyledButton>
      )}
    </>
  );
};
