import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import styled, { css } from "styled-components";

export const FormInputTextUpper = styled(FormInputField)`
  ${css`
    && input {
      text-transform: uppercase;
    }
  `}
`;
