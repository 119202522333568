import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/packages/button/Button";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { ApplicationsFilter } from "@/components/Applications/ApplicationsFilter";
import { ApplicationsTable } from "@/components/Applications/ApplicationsTable";
import { DateParam, StringParam, useQueryParams } from "use-query-params";

import { usePagination } from "@/hooks/usePagination";

import {
  useDeleteApplication,
  useGetApplicationList,
  usePatchApplication,
} from "@/helpers/api/applications/hooks";
import { useGetVehicleParkList } from "@/helpers/api/vehicles/hooks";
import { useGetMediaList } from "@/helpers/api/mediaBase/hooks";
import { useGetModelList } from "@/helpers/api/models/hooks";

import {
  useExportCalendar,
  useGetAuthors,
  useGetStatuses,
} from "@/helpers/api/testDrives/hooks";
import { StyledPagination } from "@/components/parts/index.styles";
import { vehicleNumberToWithSpaces } from "@/helpers";
import { useTranslation } from "react-i18next";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Space } from "antd";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { handleFileLoad } from "@/helpers/utils";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useNotification } from "@/hooks/useNotification";

export type TOptionList = {
  value: string;
  label: string;
  name: string;
};

export type TFilterOptions = {
  modelOptions: TOptionList[];
  mediaOptions: TOptionList[];
  authorOptions: TOptionList[];
  vehicleOptions: TOptionList[];
  statusListOptions: {
    [key: string]: TOptionList[];
  };
};

export const Applications: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { paginationParams, setPage } = usePagination();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    modelIds: StringParam,
    startEvent: DateParam,
    endEvent: DateParam,
    appointment: StringParam,
    userCreatorId: StringParam,
    vehicleNumber: StringParam,
    mediaId: StringParam,
  });

  const filterParams = {
    "filter[modelId]": filterQueryParams.modelIds || undefined,
    "filter[startEvent]":
      filterQueryParams.startEvent?.toISOString() || undefined,
    "filter[endEvent]": filterQueryParams.endEvent?.toISOString() || undefined,
    "filter[appointment]": filterQueryParams.appointment || undefined,
    "filter[userCreatorId]": filterQueryParams.userCreatorId || undefined,
    "filter[vehicleNumber]": filterQueryParams.vehicleNumber || undefined,
    "filter[mediaId]": filterQueryParams.mediaId || undefined,
  };

  const {
    data: responseApplicationList,
    isLoading: isApplicationListLoading,
    refetch: refetchApplicationList,
  } = useGetApplicationList({
    "page[take]": paginationParams.limit,
    "page[skip]": paginationParams.limit * (paginationParams.page - 1),
    ...filterParams,
  });

  const { data: responseGetModelList, refetch: refetchGetModelList } =
    useGetModelList({});
  const { data: responseGetMediaList } = useGetMediaList({
    "page[take]": 0,
    "sort[name]": "ASC",
  });
  const { data: responseGetStatusList } = useGetStatuses({
    withoutRejection: false,
  });
  const { data: responseGetAuthorsList } = useGetAuthors();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const { createNotificationError } = useNotification();

  const { mutateAsync: exportCalendar } = useExportCalendar();

  const handleExportCalendar = () => {
    exportCalendar(
      {
        // "filter[modelId]": form.modelIds,
        // "filter[startEvent]": startPeriodDate,
        // "filter[endEvent]": endPeriodDate,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "requests.xlsx");
        },
      }
    );
  };

  const { data: responseGetVehicleParkList } = useGetVehicleParkList({
    query: {},
  });

  const {
    mutateAsync: deleteApplicationAsync,
    isLoading: isLoadingDeleteApplication,
  } = useDeleteApplication();
  const { mutateAsync: patchApplicationAsync } = usePatchApplication();

  const filterOptions: TFilterOptions = useMemo(() => {
    const responseModel = responseGetModelList?.data.data || [];
    const modelOptions =
      responseModel.map((model) => ({
        value: model.id,
        label: model.name,
        name: model.name,
      })) || [];

    const responseMedia = responseGetMediaList?.data.data || [];
    const mediaOptions =
      responseMedia.map((media) => ({
        value: media.id,
        label: media.name,
        name: media.type,
      })) || [];

    const responseStatus = responseGetStatusList?.data?.data || [];
    const statusListOptions = responseStatus.map((status) => ({
      label: status.title,
      name: status.title,
      value: status.id,
      record: { color: status.color, appointment: status.appointment },
    }));

    const responseAuthor = responseGetAuthorsList?.data?.data || [];
    const authorOptions =
      responseAuthor.map((author) => ({
        value: author.id,
        label: `${author.firstName} ${author.lastName}`,
        name: `${author.firstName} ${author.lastName}`,
      })) || [];

    const responseVehicle = responseGetVehicleParkList?.data.data || [];

    const vehicleOptions =
      responseVehicle.flatMap((model) =>
        model.vehicles.map((vehicle) => {
          return {
            value: vehicle.number,
            label: vehicleNumberToWithSpaces(vehicle.number),
            name: vehicle.number,
          };
        })
      ) || [];

    return {
      modelOptions,
      mediaOptions,
      authorOptions,
      vehicleOptions,
      statusListOptions,
    };
  }, [
    responseGetModelList,
    responseGetMediaList,
    responseGetStatusList,
    responseGetVehicleParkList,
    responseGetAuthorsList,
  ]);

  const handleEditButtonClick = (dataId: string) => {
    navigate(`/applications/${dataId}`);
  };

  const handleDeleteButtonClick = async (dataId: string) => {
    await deleteApplicationAsync({ id: dataId });
    refetchApplicationList();
  };

  const handleStatusChange = async (dataId: string, statusId: string) => {
    try {
      await patchApplicationAsync({ id: dataId, patch: { statusId } });
    } catch (err: any) {
      if (
        err?.response?.data?.message === "Request overlaps with other requests"
      ) {
        createNotificationError(t("park.overlaps") || "");
      }
    }
    refetchApplicationList();
    refetchGetModelList();
  };

  return (
    <DashboardLayout
      title={t("park.subTitle_3") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleExportCalendar}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
          <Button
            onClick={() => navigate("/applications/create")}
            color="brand"
            size="middle"
            theme="primary"
          >
            {t("park.addApplication") || ""}
          </Button>
        </Space>
      }
    >
      <ApplicationsFilter
        defaultValues={filterParams}
        filterOptions={filterOptions}
        onFiltersChange={setFilterQueryParams}
      />
      {responseApplicationList?.data.data ? (
        <>
          <ApplicationsTable
            onEditButtonClick={handleEditButtonClick}
            onDeleteButtonClick={handleDeleteButtonClick}
            data={responseApplicationList.data?.data || []}
            statusList={filterOptions.statusListOptions || []}
            onStatusChange={handleStatusChange}
            isLoading={isApplicationListLoading || isLoadingDeleteApplication}
          />
        </>
      ) : (
        <></>
      )}
      <StyledPagination
        current={paginationParams.page}
        onChange={setPage}
        pageSize={10}
        //@ts-ignore
        total={responseApplicationList?.data.meta?.total || 0}
      />
    </DashboardLayout>
  );
};
