import { colors } from "@/helpers";
import { TAuditTasksList, TUpdateTaskStatusDto, } from "@/helpers/api/audit-tasks/types";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";

import { IconButton } from "@/packages/icon-button/IconButton";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { ITaskChatPayload } from "@/pages/AuditTasks";
import { StyledChatCell, StyledData, StyledInfoBlock, StyledMessage, StyledSelectField, } from "./index.styles";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";
import { formatDate } from "@/helpers/formatDate";
import { Dispatch } from "@/components/UserInfoModal/provider";
import React from "react";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const MOCK_TASK_STATUS = [
  { label: "audits.tasks.accepted", value: "accepted" },
  { label: "audits.tasks.review", value: "review" },
  { label: "audits.tasks.in_work", value: "in_work" },
];

const statusToText = {
  accepted: {
    color: "#2F4A04",
    background: "#B5D880",
    message: "audits.tasks.accepted",
  },
  review: {
    background: "#7B7D81",
    color: "#DBDCE0",
    message: "audits.tasks.comm",
  },
  in_work: {
    color: "#B06E0A",
    background: "#F5DA69",
    hasIcon: true,
    message: "audits.tasks.performTask",
  },
};

export const TaskStatus: React.FC<{ status: string }> = (props) => {
  const { status } = props;
  const { t } = useTranslation();
  return (
    <StyledInfoBlock>
      <StyledMessage
        background={statusToText[status].background}
        color={statusToText[status].color}
      >
        {t(statusToText[status].message) || ""}
      </StyledMessage>
      {statusToText[status]?.hasIcon ? (
        <Icon name="info" color="yellowStatusBg" size={16}/>
      ) : (
        <></>
      )}
    </StyledInfoBlock>
  );
};

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  isDealerKia: boolean;
  openChat: (data: ITaskChatPayload) => void;
  onStatusChange: (taskId: number, status: TUpdateTaskStatusDto) => void;
  openUserModal: Dispatch;
}): IHeaderItem<TAuditTasksList>[] => {
  const { isDealerKia, openChat, onStatusChange, openUserModal } = options;
  return [
    {
      Header: t("audits.tasks.id") || "",
      accessor: "dealerTaskId",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
      Cell: ({ row: { original }, value }) => {
        return (
          `${original.audit.visitNumber ? original.audit.visitNumber : ''}.${original.dealerTaskId}`
        );
      },
    },
    {
      Header: "№ Вопроса",
      accessor: "questionNumber",
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      Header: t("audits.tasks.merchantName") || "",
      accessor: "audit.dealer.merchantName",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      Header: t("audits.tasks.audit") || "",
      accessor: "audit.title",
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      Header: t("audits.tasks.code") || "",
      accessor: "audit.dealer.mobisCode",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("audits.tasks.task") || "",
      accessor: "task",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original }, value }) => {
        return (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true}/>
        );
      },
    },
    {
      Header: t("audits.tasks.plan") || "",
      accessor: "title",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original }, value }) => {
        return (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true}/>
        );
      },
    },
    {
      Header: t("audits.tasks.weight") || "",
      accessor: "weight",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: t("audits.tasks.responsible") || "",
      accessor: "responsible.firstName",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original?.responsible?.id ?? '' })}>
            {original?.responsible?.firstName} {original?.responsible?.lastName}
          </StyledUserNameWrapper>
        );
      },
    },
    {
      Header: t("audits.tasks.completedAt") || "",
      accessor: "completedAt",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original }, value }) => {
        const isAccepted = original.status === "accepted";
        const displayValue = isAccepted ? value : original.deadline;
        const isOld = new Date() > new Date(displayValue);
        return !!displayValue ? (
          <StyledData
            style={{
              color: isOld ? "#E32446" : "black",
            }}
          >
            <Icon
              size={16}
              name="calendar-16"
              color={isOld ? "redStatus10" : "black"}
            />
            <Paragraph size={12} weight={300}>
              {formatDate(new Date(displayValue), "dateOnly")}
            </Paragraph>
          </StyledData>
        ) : null;
      },
    },
    {
      Header: t("audits.tasks.status") || "",
      accessor: "status",
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      Cell: ({ row: { original }, value }) => {
        let color;
        switch (value) {
          case "review": {
            color = colors.green100;

            break;
          }
          case "in_work": {
            color = colors.gray100;

            break;
          }
          case "accepted": {
            color = colors.yellow;

            break;
          }
        }

        return isDealerKia ? (
          <StyledSelectField
            options={MOCK_TASK_STATUS.filter((item) => !!item.value)}
            // value={MOCK_TASK_STATUS.find(
            //   (item) => item.value === value?.toString()
            // )}
            placeholder={t("audits.list.all") || ""}
            defaultValue={original?.status || null}
            onChange={(value, option) => {
              onStatusChange(original.id, value);
            }}
            color={color}
          />
        ) : (
          <TaskStatus status={value}/>
        );
      },
    },
    {
      Header: "",
      accessor: "chat",
      sticky: "right",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      Cell: ({ row: { original }, value }) => {
        const unreadCount = original.unreadMessagesAmount;
        return (
          <StyledChatCell>
            <Tooltip content={t("audits.list.chat") || ""} placement="bottom">
              <Badge count={unreadCount}>
                <IconButton
                  size="l"
                  icon="chat"
                  color="black"
                  variant="tertiary"
                  onClick={() =>
                    openChat({ taskId: original.id, status: original.status })
                  }
                />
              </Badge>
            </Tooltip>
          </StyledChatCell>
        );
      },
    },
  ];
};
