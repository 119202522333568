import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../../axiosInstance";
import {
  TArchiveWarrantyRequest,
  TArchiveWarrantyResponse,
  TGetDppoQuestionsWarrantyRequest,
  TGetDppoQuestionsWarrantyResponse,
  TGetQuestionsImplementerWarrantyRequest,
  TGetQuestionsImplementerWarrantyResponse,
  TGetQuestionsKiaWarrantyRequest,
  TGetQuestionsKiaWarrantyResponse,
  TPatchAnswerWarrantyRequest,
  TPatchAnswerWarrantyResponse,
  TPatchRequirementsRequest,
  TPatchRequirementsResponse,
  TStartFillWarrantyRequest,
  TStartFillWarrantyResponse,
} from "./types";

export const useStartFillWarrantyAudit = () => {
  return useMutation(
    ({
       id,
       patch,
       onSuccess,
       onError,
     }: {
      id: TStartFillWarrantyRequest["id"];
      patch: TStartFillWarrantyRequest["patch"];
      onSuccess?: () => void;
      onError?: (resp: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TStartFillWarrantyResponse>(
        `/guarantee/audits/${id}/start-filling`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useArchiveAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
     }: {
      id: TArchiveWarrantyRequest;
      onSuccess: () => void;
      onError: (resp: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TArchiveWarrantyResponse>(`/audits/${id}/archive`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetWarrantyQuestionsDppo = (
  auditId: TGetDppoQuestionsWarrantyRequest
) => {
  return useQuery(["warranty", "questions/dppo", auditId], () => {
    return api.get<TGetDppoQuestionsWarrantyResponse>(
      `/guarantee/questions/${auditId}/dppo`
    );
  });
};

export const useGetWarrantyQuestions = (
  request:
    | TGetQuestionsKiaWarrantyRequest
    | TGetQuestionsImplementerWarrantyRequest,
  isKia?: boolean
) => {
  return useQuery(
    ["warranty", "questions/kia", request],
    () => {
      return api.get<
        | TGetQuestionsKiaWarrantyResponse
        | TGetQuestionsImplementerWarrantyResponse
      >(
        `/guarantee/questions/${request.auditId}/requirements/${
          isKia ? "kia" : "implementer"
        }`,
        {
          params: request.params,
        }
      );
    },
    {
      enabled: !!request.auditId,
    }
  );
};

export const useGetWarrantyQuestionsImplementer = (
  request: TGetQuestionsImplementerWarrantyRequest
) => {
  return useQuery(
    ["warranty", "questions/implementer", request.auditId],
    () => {
      return api.get<TGetQuestionsImplementerWarrantyResponse>(
        `/guarantee/questions/${request.auditId}/requirements/implementer`,
        {
          params: request.params,
        }
      );
    },
    {
      enabled: !!request.auditId,
    }
  );
};

export const usePatchAnswerWarranty = () => {
  return useMutation(
    ({
       patch,
       onSuccess,
       onError,
     }: {
      patch: TPatchAnswerWarrantyRequest;
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchAnswerWarrantyResponse>(
        `/guarantee/answers/${patch.id}`,
        patch.data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditRequirementsTemplate = () => {
  return useMutation(
    ({
       id,
       data,
       onSuccess,
       onError,
     }: {
      id: string;
      data: TPatchRequirementsRequest;
      onSuccess: (resp: TPatchRequirementsResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchRequirementsResponse>(
        `/guarantee/requirements/${id}`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
