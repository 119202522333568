import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

const StyledInputWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  width: 100%;
`;

const StyledFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px 91px;
  margin-left: -32px;
  margin-right: -32px;
  margin-top: 44px;
  margin-bottom: 44px;
  text-align: center;
  border: 1px solid ${colors.neutral150};
  border-left: none;
  border-right: none;
  p {
    text-align: center;
  }
`;

const StyledParagraph = styled(Paragraph)`
  width: 295px;
`;
export { StyledInputWrap, StyledFrame, StyledParagraph };
