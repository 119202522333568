import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDataFieldsApp } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFieldsApp";
import {
  useCreateMonitoringApplicationForm
} from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFieldsApp/hooks";
import {
  useGetMonitoringQualityById,
  usePostMonitoringQualityCars,
} from "@/helpers/api/monitoringQuality/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Loader } from "@/packages/loader/Loader";
import { Space } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IMonitoringQualityCreateApplicationProps {
}

export const MonitoringQualityCreateApplication: React.FC<
  IMonitoringQualityCreateApplicationProps
> = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { t } = useTranslation();

  const {
    mutateAsync: createMonitoringApplicationAsync,
    isLoading: isCreateLoading,
  } = usePostMonitoringQualityCars();

  const { data: monitoringApplicationData } = useGetMonitoringQualityById({
    id: params.id as string,
  });

  const onCreateMonitoringHandler: any = async (data) => {
    try {
      await createMonitoringApplicationAsync({
        qualityMonitoringId: params.id as string,
        ...data,
      });
      createNotificationSuccess(t("monitoring.addNewAppSuccess") || "");

      navigate(`/monitoring-quality/${params.id as string}/dealers-cars`);
    } catch (error) {
      //@ts-ignore
      if (error.response?.data?.message === "Car search timed out") {
        createNotificationError(t("monitoring.addNewCarPeriod") || "");
        //@ts-ignore
      } else if (error.response?.data?.message === "Monitoring is closed") {
        createNotificationError(t("monitoring.addNewCarUnActive") || "");
      } else {
        createNotificationError(
          //@ts-ignore
          error.response?.data?.message || t("monitoring.addNewCarError") || ""
        );
      }
    }
  };

  const onCancelHandler = () => {
    navigate(`/monitoring-quality/${params.id as string}/dealers-cars`);
  };

  const { form, submit, isSubmitLoading } = useCreateMonitoringApplicationForm(
    onCreateMonitoringHandler
  );

  return (
    <DashboardLayout
      title={t("monitoring.addNewApp") || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName="quality-monitoring.request-cars"
        resourceAction="create"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48}/>}
      >
        <FormProvider {...form}>
          <MonitoringDataFieldsApp
            form={form}
            monitoringQuality={monitoringApplicationData?.data}
          />
        </FormProvider>
        <Space>
          <Button
            theme="secondary"
            color="brand"
            size="middle"
            onClick={onCancelHandler}
          >
            {t("models.logsModel.cancel_2") || ""}
          </Button>
          <Button
            theme="primary"
            color="brand"
            size="middle"
            onClick={submit}
            isLoading={isCreateLoading}
            isDisabled={isCreateLoading}
          >
            {t("common.send") || ""}
          </Button>
        </Space>
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
