import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDealersInfoTable } from "@/components/MonitoringQuality/MonitoringDealersInfo";
import {
  useExportDealerMonitoringQualityCars,
  useGetDealerMonitoringQualityCars,
  useGetMonitoringQualityById,
} from "@/helpers/api/monitoringQuality/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Loader } from "@/packages/loader/Loader";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StyledPagination } from "./index.styles";
import { useTranslation } from "react-i18next";
import { usePermission } from "@/hooks/usePermission";

export interface IMonitoringQualityCarsDealersProps {}

const hideBtnStatus = ["closed", "search-completed"];
export const MonitoringQualityCarsDealers: React.FC<
  IMonitoringQualityCarsDealersProps
> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { paginationParams, setPage } = usePagination();

  const { t } = useTranslation();

  const [sortParams, setSortParams] = useState({});
  const { data: monitoringInfo, isLoading: isInfoLoading } =
    useGetMonitoringQualityById({
      id: params.id as string,
    });
  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      sortBy: header.id,
      orderBy: order?.toUpperCase(),
    }));
  };

  const { mutateAsync: exportDealerMonitoringQualityCars } =
    useExportDealerMonitoringQualityCars({ id: params.id as string });

  const {
    data: monitoringQualityCarsData,
    isLoading,
    refetch: refetchMonitoringCars,
  } = useGetDealerMonitoringQualityCars(params.id as string, {
    ...paginationParams,
    ...sortParams,
  });

  const monitoringQualityCarsList = monitoringQualityCarsData?.data.data;

  const onSubmitHandler = () => {
    if (params.id) {
      navigate(`/monitoring-quality/${params.id}/create-application`);
    }
  };

  const { hasAccess: hasEdit } = usePermission(
    "quality-monitoring.request-cars",
    "update"
  );

  const onCancelHandler = () => {
    navigate("/monitoring-quality");
  };

  useEffect(() => {
    refetchMonitoringCars();
  }, []);

  const handleMonitoringQualityCarsExport = () => {
    exportDealerMonitoringQualityCars(
      {
        ...paginationParams,
        ...sortParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "monitoring-quality-cars.xlsx");
        },
      }
    );
  };

  return (
    <DashboardLayout
      title={t("monitoring.sentCars") || ""}
      toBack={-1}
      headerRight={
        <IconButton
          icon="save"
          isSquared
          variant="primary"
          color="white"
          size="m"
          onClick={handleMonitoringQualityCarsExport}
        />
      }
    >
      <PermissionsAdapter
        resourceName="quality-monitoring.request-cars"
        resourceAction="read"
        loader={<Loader size={48} />}
        fallback={() => <span>No rights</span>}
      >
        {isLoading ? (
          <Loader size={48} />
        ) : (
          <>
            <MonitoringDealersInfoTable
              monitoringQualityCarsList={monitoringQualityCarsList}
              refetchMonitoringCars={refetchMonitoringCars}
              onSort={handleSort}
            />
            <StyledPagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={10}
              total={monitoringQualityCarsData?.data.meta?.itemCount || 0}
            />
            <Space>
              <Button
                theme="secondary"
                color="brand"
                size="middle"
                onClick={onCancelHandler}
              >
                {t("models.logsModel.cancel_2") || ""}
              </Button>
              {!hideBtnStatus.includes(monitoringInfo?.data.status || "") ? (
                <Button
                  theme="primary"
                  color="brand"
                  size="middle"
                  onClick={onSubmitHandler}
                  isDisabled={!hasEdit}
                >
                  {t("monitoring.giveCars") || ""}
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
