import {
  StyledBulletLegend,
  StyledCircle,
  StyledLegendItem,
  StyledParagraph,
} from "./index.styles";
import { t } from "i18next";

export const BulletLegend = () => {
  return (
    <StyledBulletLegend>
      <StyledLegendItem>
        <StyledCircle type="avgDealer" />
        <StyledParagraph size={12}>
          {t("bonuses.reportsView.avgDC") || ""}
        </StyledParagraph>
      </StyledLegendItem>
      <StyledLegendItem>
        <StyledCircle type="avgSum" />
        <StyledParagraph size={12}>
          {t("bonuses.reportsView.avgSum") || ""}
        </StyledParagraph>
      </StyledLegendItem>
      <StyledLegendItem>
        <StyledCircle type="sellsPlan" />
        <StyledParagraph size={12}>
          {t("bonuses.reportsView.sellPlan") || ""}
        </StyledParagraph>
      </StyledLegendItem>
    </StyledBulletLegend>
  );
};
