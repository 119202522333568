import styled from "styled-components";
import { media } from "@/helpers";
export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  ${media.tablet} {
    min-width: 423px;
  }
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledTitle = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaef;
`;
