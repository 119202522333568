import styled from "styled-components";

import { ButtonText } from "@/packages/buttonText/ButtonText";

export const StyledStatusModalContent = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 15px;
  height: 100%;
`;

export const StyledStatusFormRow = styled.div`
  display: grid;
  flex-direction: column;
  gap: 16px;
  grid-template-columns: 
    0.2fr
    minmax(auto, 2fr)
    minmax(auto, 2fr)
    minmax(100px, 1fr)
    0.3fr;
`;

export const StyledColorWrapper = styled.div`
  padding-top: 34px;
`;

export const StyledStatusColor = styled.span<{ color: string | null }>`
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const StyledAppendButton = styled(ButtonText)`
  padding: 0;
`;

export const StyledRemoveButton = styled.div`
  padding-top: 30px;
`;