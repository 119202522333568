import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGetAuditsTotalRequest,
  TGetAuditsTotalResponse,
} from "@/helpers/api/audit-total/types";

export const useGetAuditTotal = (request: TGetAuditsTotalRequest) => {
  return useQuery(["audit", "total", request], () => {
    return api.get<TGetAuditsTotalResponse>(`/audits/${request}/statistics`, {
      params: {},
    });
  });
};

export const useExportAuditTotal = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/audits/${request.id}/export-statistics`, {
      params: request,
      responseType: "blob",
    });
  });
};
