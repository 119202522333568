import { useForm } from "react-hook-form";
import { useEffect } from "react";

export interface IAuditFilterFormValues {
  auditorId: string | null;
  dealerId: string | null;
  holdingId: string | undefined;
  curatorId: string | null;
  type: string | null;
  status: string | null;
  holding: string | null;
  dealer: string | null;
  manager: string | null;
  startCreatedAt: Date | null;
  endCreatedAt: Date | null;
}

export const DEFAULT_AUDIT_FILTER_FORM_VALUES: IAuditFilterFormValues = {
  auditorId: null,
  dealerId: null,
  curatorId: null,
  type: null,
  status: null,
  holding: null,
  holdingId: undefined,
  dealer: null,
  manager: null,
  startCreatedAt: null,
  endCreatedAt: null,
};

export type TOnSubmitAuditFilterFormCb = (
  values: IAuditFilterFormValues
) => void;

export const useAuditFilterForm = (options: {
  initValues: IAuditFilterFormValues;
  onSubmit: TOnSubmitAuditFilterFormCb;
}) => {
  const form = useForm<IAuditFilterFormValues>({
    mode: "onChange",
    defaultValues: DEFAULT_AUDIT_FILTER_FORM_VALUES,
  });

  const { initValues, onSubmit } = options;

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  useEffect(() => {
    if (initValues) {
      form.reset(initValues, { keepDefaultValues: true });
      onSubmit(form.getValues());
    }
  }, []);

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
