import { Row } from "antd";
import styled from "styled-components";

export const StyledMonitoringDataFields = styled.div`
  margin-bottom: 64px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;
