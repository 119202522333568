import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";
import {
  TGetWarrantyByIdRequest,
  TGetWarrantyByIdResponse
} from "@/helpers/api/warranty-audit/types";

export const useGetWarrantyById = (id: TGetWarrantyByIdRequest) => {
  return useQuery(["warranty", id], () => {
    return api.get<TGetWarrantyByIdResponse>(`/guarantee/audits/${id}`
    );
  });
};
