import React, { RefObject, useEffect, useRef, useState } from "react";

import { Paragraph } from "@/packages/paragraph/Paragraph";

import {
  StyledTableColumnRight,
  StyledTableHeadRightList,
  StyledTableHeadRightItem,
  StyledDaysInfoList,
  StyledDaysInfoItem,
  StyledDaysBlockList,
  StyledDaysBlockItem,
  StyledDaysBlockSubList,
  StyledDaysBlockSubItem,
  StyledDaysList,
  StyledDaysItem,
  StyledColoredLabelContainer,
  StyledParagraph,
} from "./index.styles";

import {
  getDateDiff,
  getMonthByIndex,
  IDatesTree,
  isDateToday,
} from "@/helpers/date";
import { CalendarRent, TOnEditCLick } from "./CalendarRent";
import {
  CalendarData,
  TVehiclesProps,
} from "@/helpers/api/flex/vehicles/types";

// генерируем пустые ячейки для таблицы, если выбранный период очень короткий
const getTableDataMock = (currentCount: number) => {
  const TABLE_DATA_MOCK_COUNT = 100;

  const count =
    currentCount < TABLE_DATA_MOCK_COUNT
      ? TABLE_DATA_MOCK_COUNT - currentCount
      : 0;

  return new Array(count).fill("");
};

export interface ISelectedDayProps {
  day: Date;
  carItem: TVehiclesProps;
  unitId: string;
}

export type TOnDayClick = ({ day, carItem, unitId }: ISelectedDayProps) => void;

export interface IFlexCalendarTableColumnRight {
  data: CalendarData[];
  datesArray: Date[];
  datesTree: IDatesTree | null;
  calendarDatesDiff: number;
  scrollPage: number;
  setScrollPage: React.Dispatch<React.SetStateAction<number>>;
  setScrollPrevPage: React.Dispatch<React.SetStateAction<number>>;
  onDayClick: TOnDayClick;
  onEditDateClick: TOnEditCLick;
  startPeriodDateFilters: Date;
}

export const FlexCalendarTableColumnRight: React.FC<
  IFlexCalendarTableColumnRight
> = (props) => {
  const {
    data,
    datesTree,
    datesArray,
    calendarDatesDiff,
    setScrollPage,
    setScrollPrevPage,
    onDayClick,
    onEditDateClick,
    startPeriodDateFilters,
  } = props;
  // const [isCalendarHovering, setIsCalendarHovering] = useState(false);
  const [isScrollToDateDisabled, setIsScrollToDateDisabled] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);

  const daysBlockRef: RefObject<HTMLDivElement> = useRef(null);

  const scrollRef: RefObject<HTMLLIElement> = useRef(null);

  const executeScrollToDate = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "end",
      });
    }
  };

  const onDaysBlockWheel = (event) => {
    if (daysBlockRef.current) {
      daysBlockRef.current.scrollLeft += event.deltaY;
    }
  };

  const onDaysBlockScroll = () => {
    if (daysBlockRef.current) {
      const { scrollLeft, offsetWidth } = daysBlockRef.current;

      const calendarWidth = calendarDatesDiff * 20 - (offsetWidth + 20);

      if (scrollLeft + pageOffset >= calendarWidth) {
        setPageOffset(scrollLeft);
        setScrollPage((scrollPage) => (scrollPage += 1));
        setIsScrollToDateDisabled(true);
      }
      if (scrollLeft === 0) {
        setScrollPrevPage((scrollRevPage) => (scrollRevPage += 1));
        daysBlockRef.current.scrollLeft += 3420;
        setIsScrollToDateDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (!isScrollToDateDisabled) {
      executeScrollToDate();
    }
  }, [scrollRef, datesTree]);

  const today = startPeriodDateFilters;

  const isWeekends = (date: any) => {
    return [0, 6].indexOf(date.getDay()) !== -1;
  };

  return (
    <StyledTableColumnRight
      onWheel={onDaysBlockWheel}
      onScroll={onDaysBlockScroll}
      ref={daysBlockRef}
    >
      {datesTree ? (
        <StyledTableHeadRightList>
          {Object.keys(datesTree).map((year) => (
            <>
              {Object.keys(datesTree[year]).map((month) => (
                <StyledTableHeadRightItem key={`head-month-${year}-${month}`}>
                  <StyledParagraph size={12}>
                    {getMonthByIndex(Number(month))} {year}
                  </StyledParagraph>
                  <StyledDaysInfoList>
                    {datesTree[year][month].map((day: Date) => (
                      <StyledDaysInfoItem
                        key={`head-day-${year}-${month}-${day.getDate()}`}
                        isToday={isDateToday(today, day)}
                      >
                        {isWeekends(day) ? (
                          <Paragraph size={12} color={"gray3"}>
                            {day.getDate()}
                          </Paragraph>
                        ) : (
                          <Paragraph size={12}>{day.getDate()}</Paragraph>
                        )}
                      </StyledDaysInfoItem>
                    ))}
                  </StyledDaysInfoList>
                </StyledTableHeadRightItem>
              ))}
            </>
          ))}
        </StyledTableHeadRightList>
      ) : (
        <></>
      )}
      <StyledDaysBlockList>
        {data?.map((unit) => (
          <div>
            {unit.models.map((car, carIndex) => (
              <StyledDaysBlockItem
                key={`days-cars-item-${car.name}-${carIndex}`}
              >
                <StyledDaysBlockSubList>
                  {car.vehicles.map((carItem, carItemIndex) => {
                    const rentsMapByEndDates = {};
                    const rentsEndDates: string[] = [];

                    if (!!carItem.rents.length) {
                      carItem.rents.forEach((record) => {
                        rentsEndDates.push(new Date(record.to).toDateString());
                      });
                    }

                    if (!!carItem.rents.length) {
                      carItem.rents.forEach((record) => {
                        const rentStartEventDateString = new Date(
                          record.from
                        ).toDateString();

                        const isIntersecting = rentsEndDates.includes(
                          rentStartEventDateString
                        );

                        rentsMapByEndDates[rentStartEventDateString] = {
                          record: record,
                          isIntersecting: isIntersecting,
                        };
                      });
                    }

                    return (
                      <StyledDaysBlockSubItem
                        key={`days-cars-sub-item-${car.id}-${carIndex}-${carItem.vin}-${carItemIndex}`}
                      >
                        <StyledDaysList>
                          {datesArray.map((day, dayIndex) => {
                            const request =
                              rentsMapByEndDates[day.toDateString()];

                            const rentStartEventDate = new Date(
                              request?.record.from
                            );

                            const rentEndEventDate = new Date(
                              request?.record.to
                            );

                            const isToday = isDateToday(today, day);

                            return (
                              <StyledDaysItem
                                ref={isToday ? scrollRef : null}
                                onClick={() => {
                                  onDayClick({ day, carItem, unitId: unit.id });
                                }}
                                key={`table-data-${day.getDate()}-${dayIndex}`}
                              >
                                {!!rentsMapByEndDates[day.toDateString()] ? (
                                  <StyledColoredLabelContainer>
                                    <CalendarRent
                                      id={request.record.id}
                                      days={getDateDiff(
                                        rentStartEventDate,
                                        rentEndEventDate
                                      )}
                                      event={request.record.event}
                                      isIntersecting={request.isIntersecting}
                                      onEditDateClick={onEditDateClick}
                                    />
                                  </StyledColoredLabelContainer>
                                ) : (
                                  <></>
                                )}
                              </StyledDaysItem>
                            );
                          })}
                          {getTableDataMock(datesArray.length).map(
                            (_, dayIndex) => (
                              <StyledDaysItem
                                key={`mock-table-data-${dayIndex}`}
                              />
                            )
                          )}
                        </StyledDaysList>
                      </StyledDaysBlockSubItem>
                    );
                  })}
                </StyledDaysBlockSubList>
              </StyledDaysBlockItem>
            ))}
          </div>
        ))}
      </StyledDaysBlockList>
    </StyledTableColumnRight>
  );
};
