import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const StyledFormTitle = styled(Title)`
  margin-bottom: 24px;
`;

export const StyledFormPreTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #939598;
  margin-bottom: 24px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
`;
