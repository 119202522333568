import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ISimpleConfirmModal extends Omit<IModal, "onCancel"> {
  contentTitle?: string;
  onClose: () => void;
}

export const SimpleConfirmModal: React.FC<ISimpleConfirmModal> = (props) => {
  const { t } = useTranslation();
  const {
    contentTitle,
    cancelButtonText = t("models.addNewModelFrom.cancel") || "",
    children,
    onClose,
    ...rest
  } = props;

  return (
    <Modal {...rest} cancelButtonText={cancelButtonText} onCancel={onClose}>
      {contentTitle ? (
        <TitleDivider size="medium">{contentTitle}</TitleDivider>
      ) : (
        <></>
      )}
      {children ? children : <></>}
    </Modal>
  );
};
