import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled from "styled-components";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectField = styled(SelectField)`
  width: 188px;
`;

const StyledTextField = styled(TextAreaField)`
  width: 207px;
`;

const StyledTableSimple = styled.div`
  overflow: auto;
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)``;

export {
  StyledTextField,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
};
