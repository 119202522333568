import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TFuelCreateRequest,
  TFuelCreateResponse,
  TFuelDeleteRequest,
  TFuelDeleteResponse,
  TFuelPutRequest,
  TFuelPutResponse,
  TGetFuelByIdRequest,
  TGetFuelByIdResponse,
  TGetFuelListRequest,
  TGetFuelListResponse,
} from "./types";

export const useGetFuelList = (request: TGetFuelListRequest) => {
  return useQuery(["fuel", "list", request], () => {
    return api.get<TGetFuelListResponse>("/fuel", {
      params: request,
    });
  });
};

export const useGetFuelById = (request: TGetFuelByIdRequest) => {
  return useQuery(["fuel", "detail", request.id], () => {
    return api.get<TGetFuelByIdResponse>(`/fuel/${request.id}`);
  });
};

export const useCreateFuel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFuelCreateRequest) => {
      return api.post<TFuelCreateResponse>("/fuel", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fuel", "list"]);
      },
    }
  );
};

export const usePutFuel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TFuelPutRequest) => {
      return api.put<TFuelPutResponse>(`/fuel/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["fuel", "list"]);
        queryClient.invalidateQueries(["fuel", "detail", variables.id]);
      },
    }
  );
};

export const useDeleteFuel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFuelDeleteRequest) => {
      return api.delete<TFuelDeleteResponse>(`/fuel/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["fuel", "list"]);
        queryClient.invalidateQueries(["fuel", "detail", variables.id]);
      },
    }
  );
};
