import styled from "styled-components";
import { media } from "@/helpers";

export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  ${media.tablet} {
    min-width: 423px;
  }
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: flex-end;
  margin-top: 5px;
`;

export const StyledFormCheckboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: flex-end;
  margin-top: 15px;
`;
