import { colors, media } from "@/helpers";
import styled from "styled-components";

export const StyledPage = styled.section``;

export const StyledPageHeader = styled.header`
  margin-bottom: 32px;
`;

export const StyledSettingsWrapper = styled.div``;

export const StyledSettingsBox = styled.section``;

export const StyledSettingsBoxHeader = styled.header`
  margin-bottom: 16px;
`;

export const StyledSettingBoxSmall = styled(StyledSettingsBox)`
  max-width: 489px;

  &:not(:last-child) {
    margin-bottom: 36px;
  }
`;

export const StyledSettingsList = styled.ul`
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledSettingsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledSettingsListLabel = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.12;
  color: ${colors.blackSecondary};
`;

export const StyledSettingsListField = styled.div``;

export const StyledSettingsDropdown = styled.div`
  max-width: 378px;
  width: 100%;
`;

export const StyledDelegationFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const StyledDelegrationRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledChangeDelegationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledChangeDelegationLabel = styled.label`
  font-size: 12px;
  color: ${colors.gray200};
`;
