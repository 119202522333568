import { useState } from "react";
import {
  useCreateCoefficient,
  useGetCoefficientsParams,
} from "@/helpers/api/bonuses/bonuses-calculation/hooks";
import {
  StyledButton,
  StyledButtonBlock,
  StyledCoefficientsWrapper,
  StyledContentDescr,
  StyledDescriptionHeaderColumn,
  StyledFactor,
  StyledFactorHeaderColumn,
  StyledNoDataContainer,
  StyledNpsValueHeaderColumn,
  StyledNpsValues,
  StyledTableBody,
  StyledTableHeader,
} from "../index.styles";
import { CoefficientModal } from "../CoefficientModal";
import { CoefficientValues } from "../CoefficientValues";
import { ICoefficientFormValues } from "../CoefficientModal/CoefficientForm/hooks";
import { useNotification } from "@/hooks/useNotification";
import { Loader } from "@/packages/loader/Loader";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CoefficientBTab = () => {
  const [modal, setModal] = useState<boolean>(false);

  const search = useLocation().search;
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { mutateAsync: createCoefficient } = useCreateCoefficient();

  const { data: coefficientReponse, error: coefficientsResponseError } =
    useGetCoefficientsParams({
      coefficientType: "B",
      year: selectedYear,
      quarter: selectedQuarter,
    });

  //@ts-ignore
  const sortedCoefficientData = coefficientReponse?.data.data.sort(
    (a, b) => b.percent - a.percent
  );

  const handleFormSubmit = async (data: ICoefficientFormValues) => {
    const { percent, coefficientValue } = data;

    const dataToServer = {
      year: selectedYear,
      quarter: selectedQuarter,
      coefficientValue: Number(coefficientValue),
      percent: Number(percent),
      coefficientType: "B",
    };

    try {
      await createCoefficient(dataToServer);
      setModal(false);
      createNotificationSuccess(
        t("bonuses.calculations.createNotificationSuccessCoeff") || ""
      );
    } catch (error) {
      createNotificationError(
        //@ts-ignore
        (Array.isArray(error.response?.data?.message) &&
          //@ts-ignore
          error.response?.data?.message[0]) ||
          //@ts-ignore

          error.response?.data?.message
      );

      console.log(error);
    }
  };

  const isParamsNotFoundError = coefficientsResponseError
    ? //@ts-ignore
      coefficientsResponseError?.response?.data?.error === "Not Found"
    : false;
  const { t } = useTranslation();
  return (
    <StyledCoefficientsWrapper>
      <StyledTableHeader>
        <StyledFactorHeaderColumn>
          {t("bonuses.calculations.factor") || ""}
        </StyledFactorHeaderColumn>
        <StyledDescriptionHeaderColumn>
          {t("bonuses.calculations.descriptionCoeff") || ""}
        </StyledDescriptionHeaderColumn>
        <StyledNpsValueHeaderColumn>
          {t("bonuses.calculations.audit") || ""}
        </StyledNpsValueHeaderColumn>
        <StyledNpsValueHeaderColumn>
          {t("bonuses.calculations.valueB") || ""}
        </StyledNpsValueHeaderColumn>
      </StyledTableHeader>
      <StyledTableBody>
        <StyledFactor>B</StyledFactor>
        <StyledContentDescr>
          {t("bonuses.calculations.markDealership") || ""}
        </StyledContentDescr>
        <StyledNpsValues>
          {sortedCoefficientData ? (
            sortedCoefficientData.map((value, index) => (
              <CoefficientValues
                coefficient={value}
                prevCoefficientPercent={
                  value.percent
                    ? null
                    : sortedCoefficientData[index > 0 ? index - 1 : 0].percent
                }
                moduleTitle={t("bonuses.calculations.auditChange") || ""}
                inputTitle1={t("bonuses.calculations.auditScore") || ""}
                inputTitle2={t("bonuses.calculations.valueB") || ""}
              />
            ))
          ) : isParamsNotFoundError ? (
            <StyledNoDataContainer>
              {t("bonuses.calculations.noData") || ""}
            </StyledNoDataContainer>
          ) : (
            <StyledNoDataContainer>
              <Loader size={48} />
            </StyledNoDataContainer>
          )}
        </StyledNpsValues>
      </StyledTableBody>
      <StyledTableBody></StyledTableBody>
      <StyledButtonBlock>
        <StyledButton
          color="brand"
          size="middle"
          theme="primary"
          onClick={() => setModal(true)}
          isDisabled={isParamsNotFoundError}
        >
          {t("bonuses.calculations.addNewCondition") || ""}
        </StyledButton>
      </StyledButtonBlock>

      <CoefficientModal
        isOpen={modal}
        onClose={() => setModal(false)}
        onCancel={() => setModal(false)}
        onSubmit={handleFormSubmit}
        moduleTitle={t("bonuses.calculations.auditChange") || ""}
        inputTitle1={t("bonuses.calculations.auditScore") || ""}
        inputTitle2={t("bonuses.calculations.valueB") || ""}
      />
    </StyledCoefficientsWrapper>
  );
};
