import React from "react";
import { useNavigate } from "react-router-dom";

import { ApplicationsErrorModal } from "@/components/Applications/ApplicationsErrorModal";
import { ApplicationsForm } from "@/components/Applications/ApplicationsForm";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { IApplicationsFormValues } from "@/components/Applications/ApplicationsForm/hooks";
import { useCreateApplication } from "@/helpers/api/applications/hooks";
import { TApplicationCreateRecord } from "@/helpers/api/applications/types";
import { useModal } from "@/hooks/useModal";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

const CAR_ALREADY_BOOKED_FOR_DATES_ERROR_MESSAGE =
  "Request overlaps with other requests";

export const ApplicationsCreate: React.FC = () => {
  const navigate = useNavigate();
  const { modalState, openModal } = useModal();
  const { t } = useTranslation();
  const {
    mutateAsync: createApplicationAsync,
    isLoading: isCreateApplicationLoading,
  } = useCreateApplication();
  const { createNotificationError } = useNotification();

  const handleFormSubmit = async (data: IApplicationsFormValues) => {
    const {
      startEvent,
      endEvent,
      vehicleId,
      appointment,
      mediaId,
      statusId,
      requestPublicationLink,
      comment,
      receivingUser,
      fileId,
    } = data;

    const dataToServer: TApplicationCreateRecord = {
      startEvent: startEvent ? startEvent.toISOString() : "",
      endEvent: endEvent ? endEvent.toISOString() : "",
      vehicleId: vehicleId!,
      appointment: appointment!,
      mediaId: appointment === "Media" ? (mediaId as string) : undefined,
      statusId: statusId!,
      requestPublicationLink: requestPublicationLink || "",
      comment: comment!,
      receivingUser: receivingUser!,
      // @ts-ignore
      fileIds: fileId?.id ? [fileId?.id] : [],
    };

    try {
      await createApplicationAsync(dataToServer);
      navigate("/applications");
    } catch (err: any) {
      if (err?.response?.data?.message === "Expired CASCO or OSAGO") {
        createNotificationError(t("park.expiredCASCO&OSAGO") || "");
      }
      if (
        err?.response?.data?.message === "Request overlaps with other requests"
      ) {
        createNotificationError(t("park.overlaps") || "");
      }
      if (
        err?.response?.data?.message ===
        CAR_ALREADY_BOOKED_FOR_DATES_ERROR_MESSAGE
      ) {
        openModal();
      }
    }
  };

  return (
    <DashboardLayout
      toBack="/applications"
      title={t("park.applicationCreate") || ""}
    >
      <ApplicationsForm
        onFormSubmit={handleFormSubmit}
        isLoading={isCreateApplicationLoading}
        isNewPage={true}
      />

      <ApplicationsErrorModal
        isOpen={modalState.isOpen}
        onSubmit={modalState.onSubmit}
      />
    </DashboardLayout>
  );
};
