import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";

import {
  requiredStringSchema,
  requiredSelectSimpleValueSchema,
  requiredHEXScheme,
} from "@/helpers/validation";

export type CalendarStatus = {
  id: string | null;
  title: string | null;
  color: string | null;
  appointment: string | null;
  lockActive: boolean;
};

export type CalendarStatusFormValues = {
  status: CalendarStatus[];
};

export type TOnSubmitCalendarStatusFormHandler = (data) => void;

export const formSchema = () => {
  return yup.object({
    status: yup.array().of(
      yup.object({
        id: yup.string().nullable(),
        title: requiredStringSchema("Title required"),
        color: requiredHEXScheme(),
        appointment: requiredSelectSimpleValueSchema("Destination required"),
      })
    ),
  });
};

export const useCalendarStatusForm = (
  onSubmit: TOnSubmitCalendarStatusFormHandler,
  defaultValues: any
) => {
  const form = useForm<CalendarStatusFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: {
      status: [...defaultValues],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "status",
    control: form.control,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    fields,
    append,
    remove,
    onSubmitHandler,
  };
};
