import { Field } from "@/packages/formElements/fields/Field";
import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { StyledFieldControlContainer, StyledInput } from "./index.styled";
import { t } from "i18next";

export interface IFormFirstLastNameField {
  label?: string;
  name: [string, string];
  isDisabled?: boolean;
  readOnly?: boolean;
}

const _FormFirstLastNameField: React.FC<IFormFirstLastNameField> = (props) => {
  const { name, label, isDisabled = false, readOnly = false } = props;

  const { control } = useFormContext();

  const formState = useFormState({
    control,
    name,
  });

  const error = (formState.errors[name[0]]?.message ||
    formState.errors[name[1]]?.message) as string | undefined;

  return (
    <Field>
      {label ? (
        <Field.Label readOnly={readOnly} disabled={isDisabled}>
          {label}
        </Field.Label>
      ) : (
        <></>
      )}
      <StyledFieldControlContainer>
        <Controller
          name={name[0]}
          control={control}
          render={({
            field: { ref: _, ...fieldWithoutRef },
            fieldState: { error },
          }) => {
            return (
              <StyledInput
                {...fieldWithoutRef}
                placeholder={t("registers.holdingsDC.nameDirector") || ""}
                error={!!error}
                disabled={isDisabled}
                readOnly={readOnly}
              />
            );
          }}
        />

        <Controller
          name={name[1]}
          control={control}
          render={({
            field: { ref: _, ...fieldWithoutRef },
            fieldState: { error },
          }) => {
            return (
              <StyledInput
                {...fieldWithoutRef}
                placeholder={t("registers.holdingsDC.lastnameDirector") || ""}
                error={!!error}
                disabled={isDisabled}
                readOnly={readOnly}
              />
            );
          }}
        />
      </StyledFieldControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}
    </Field>
  );
};
export const FormFirstLastNameField = React.memo(_FormFirstLastNameField);
