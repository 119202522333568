import React, { useEffect, useState } from "react";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { FillAuditFirstStage } from "@/components/Audits/FillAuditStages/FillAuditFirstStage";
import { FillAuditSecondStage } from "@/components/Audits/FillAuditStages/FillAuditSecondStage";
import {
  useCompleteAudit,
  useGetAuditById, useVerificationAudit,
} from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import { TGetAuditByIdResponse } from "@/helpers/api/audit-fill/types";
import { Button } from "@/packages/button/Button";
import { useNotification } from "@/hooks/useNotification";
import { SimpleConfirmModal } from "@/components/SimpleConfirmModal";
import { useModal } from "@/hooks/useModal";
import {
  StyledConfirm,
  StyledConfirmContainer,
} from "@/components/Audits/FillAuditStages/FillAuditFirstStage/index.styles";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Icon } from "@/packages/icon/Icon";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/contexts/useProfile";

const renderStage = ({
                       stageIndex,
                       auditData,
                       id,
                       onCompleteStage,
                       updateInfo,
                     }: {
  id: number;
  stageIndex: number;
  auditData: TGetAuditByIdResponse;
  onCompleteStage: (stageIndex: number) => void;
  updateInfo: () => void;
}) => {
  return {
    0: {
      title: t("audits.list.completing") || "",
      component: (
        <FillAuditFirstStage
          id={id}
          data={auditData}
          onCompleteStage={onCompleteStage}
        />
      ),
    },
    1: {
      title: `${t("audits.list.completing") || ""} “${auditData.title}”`,
      component: (
        <FillAuditSecondStage
          id={id}
          auditData={auditData}
          updateInfo={updateInfo}
        />
      ),
    },
  }[stageIndex];
};

export const AuditFill: React.FC = () => {
  const navigate = useNavigate();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();
  const params = useParams();
  const auditId = params.id as string;
  const {
    data: auditData,
    isLoading,
    refetch: updateAuditData,
  } = useGetAuditById(+auditId);
  const { mutate: completeAudit, isLoading: isCompletenig } =
    useCompleteAudit();

  const { mutate: verificateAudit } = useVerificationAudit();


  const [stage, setStage] = useState(auditData?.data.startedAt ? 1 : 0);

  useEffect(() => {
    setStage(auditData?.data.startedAt ? 1 : 0);
  }, [auditData]);

  const currentStage = auditData
    ? renderStage({
      id: +auditId,
      auditData: auditData.data,
      stageIndex: stage,
      updateInfo: updateAuditData,
      onCompleteStage: (stage) => {
        setStage(stage);
        updateAuditData();
      },
    })
    : undefined;

  const handleCompleteAudit = () => {
    completeAudit({
      id: +auditId,
      onSuccess: () => {
        updateAuditData();
        ConfirmModalOpen();
      },
      onError: (error) => {
        if (error.response?.data.message === "Forbidden resource") {
          return createNotificationError(
            t("audits.list.auditCompleteError") || ""
          );
        } else {
          return createNotificationError(
            error.response?.data.message || error.message
          );
        }
      },
    });
  };

  const handleVerificateAudit = () => {
    verificateAudit({
      id: +auditId,
      onSuccess: () => {
        return createNotificationSuccess(
          'Аудит отправлен на проверку'
        );
      },
      onError: (error) => {
        return createNotificationError(
          'Произошла ошибка при отправке на проверку'
        );
      },
    });
  };

  const {
    modalState: ConfirmModalState,
    openModal: ConfirmModalOpen,
    closeModal: ConfirmModalClose,
  } = useModal();

  const isCompleteDisabled =
    auditData?.data.percent !== 100 ||
    auditData?.data.status === "Закрытый" ||
    auditData?.data.status === "В архиве";

  const {
    state: { profile },
  } = useProfile();
  const isProfileDealer = profile?.type === "dealer";

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={currentStage?.title}
        headerRight={
          <>
            {
              stage && !isProfileDealer &&
              <Button
                isDisabled={isCompleteDisabled}
                color="brand"
                theme="primary"
                onClick={handleCompleteAudit}
              >
                {t("audits.list.auditComplete") || ""}
              </Button>
            }
            {
              stage && isProfileDealer && auditData?.data.type !== 'Очно' &&
              <Button
                isDisabled={isCompleteDisabled}
                color="brand"
                theme="primary"
                onClick={handleVerificateAudit}
              >
                {"Отправить на проверку Kia"}
              </Button>
            }
          </>
        }
        toBack="/audit-list"
      >
        <PermissionsAdapter
          resourceName="audit.implementation"
          resourceAction="update"
          fallback={() => <span>No rights</span>}
        >
          {!isLoading ? currentStage?.component : <Loader size={48}/>}
        </PermissionsAdapter>
      </DashboardLayout>
      <SimpleConfirmModal
        isOpen={ConfirmModalState.isOpen}
        onSubmit={() => {
          ConfirmModalClose();
          navigate(`/audit-total/${auditId}`);
        }}
        onClose={() => ({})}
        showCancelButton={false}
        confirmButtonText={t("audits.list.results") || ""}
      >
        <StyledConfirmContainer>
          <StyledConfirm>
            <Paragraph size={18}>
              {t("audits.list.auditSuccess") || ""}
            </Paragraph>
          </StyledConfirm>
          <Icon name="check" size={64}/>
        </StyledConfirmContainer>
      </SimpleConfirmModal>
    </>
  );
};
