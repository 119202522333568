import { TableMaintenanceGrids } from '@/packages/tableMaintenanceGrids';
import { getTableHeaders } from '@/packages/tableMaintenanceGrids/utils';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StyledContent } from '@/components/VinDecoder/index.styles';
import { Space } from 'antd';
import { IconButton } from '@/packages/icon-button/IconButton';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { Button } from '@/packages/button/Button';
import { TGetDecoderMaintenanceGridResponse } from '@/helpers/api/vinDecoder/types';
import { useExportMaintenanceGrid, useGetMaintenanceGridById } from '@/helpers/api/maintenanceGrids/grids/hooks';
import { IconWorkType } from '@/packages/iconWorkType';
import { ContextProfile } from '@/hooks/contexts/useProfile';
import { usePermission } from '@/hooks/usePermission';
import { useCopyCurrentUrl } from '@/hooks/useCopyCurrentUrl';
import { Loader } from '@/packages/loader/Loader';
import { handleFileLoad } from '@/helpers/utils';
import { t } from 'i18next';
import { MaintenanceGridType } from '@/pages/MaintenanceGridsDetailsView';

interface IGridTab {
    title: string;
    subtitle: string;
    data?: TGetDecoderMaintenanceGridResponse;
}

const StyledContentTitle = styled.div`
    position: relative;

    padding: 20px 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledRoleButtons = styled(Space)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 4px;
    overflow: hidden;
`;

const StyledButtons = styled(Space)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledButton = styled(Button)`
    width: 96px;
    border: 0;
    border-radius: 0;
`;

const StyledTableMaintenanceGrids = styled(TableMaintenanceGrids)`
    margin-left: -32px;
    margin-right: -32px;
`;

const GridTab: FC<IGridTab> = (props) => {
    const { title, subtitle, data } = props;

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const profileRoleType = profile?.type || 'dealer';
    const isDealer = profile?.type === 'dealer';

    const [ activeRole, setActiveRole ] = useState<boolean>(isDealer);

    const copyCurrentUrl = useCopyCurrentUrl({
        successMessage: t('bonuses.reportsView.successMessage') || '',
    });

    const { hasAccess: isEditEnabled } = usePermission(
        'maintenance-grid',
        'update',
    );

    useEffect(() => {
        setActiveRole(!isEditEnabled);
    }, [ isEditEnabled ]);

    const {
        data: maintenanceGrid,
        refetch: updateGrid,
        isLoading: isGridLoading,
    } = useGetMaintenanceGridById({
        id: data?.id || '',
        isEnabled: Boolean(data?.id),
    });

    useEffect(() => {
        updateGrid();
    }, [ data ]);


    const worksTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код работ',
        isDealer,
        true,
    );

    const gsmTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код ГСМ',
        isDealer,
        true,
    );

    const partsTableHeaders = getTableHeaders(
        {
            isCheckable: false,
        },
        3,
        'Код запчастей',
        isDealer,
        true,
    );

    function checkMaterialIsOptionalType<T extends MaintenanceGridType>(maintenanceGridMaterialNumber: undefined | T) {
        if (!maintenanceGridMaterialNumber) {
            return false;
        }

        return maintenanceGridMaterialNumber.type === 'O';
    }

    const { preparedWorks, preparedGsm, preparedSpares } =
        useMemo(() => {
            const preparedWorks =
                maintenanceGrid?.data?.works?.map((workItem) => {
                    const maintancePositions = workItem.maintenanceNumbers.reduce(
                        (acc: { [key: number]: number | JSX.Element }, item) => ({
                            ...acc,
                            [item.number]: !activeRole ? (
                                (
                                    checkMaterialIsOptionalType(item?.workType) ? <></> :
                                        item?.workType?.totalCost * workItem?.amount
                                )
                            ) : item?.workType?.type ? (
                                <IconWorkType type={item?.workType?.type}/>
                            ) : (
                                <></>
                            ),
                        }),
                        {},
                    );

                    return {
                        id: !activeRole ? workItem?.work?.code : <></>,
                        name: `${workItem?.work?.name}`,
                        quantity: workItem?.amount,
                        ...maintancePositions,
                    };
                }) || [];

            const preparedGsm =
                maintenanceGrid?.data?.materials?.map((item) => {
                    const maintancePositions = item.maintenanceNumbers.reduce(
                        (
                            acc: { [key: number]: string | number | boolean | JSX.Element },
                            reduceItem,
                        ) => {
                            const maintenanceGridMaterialNumber: MaintenanceGridType | undefined = item?.maintenanceGridMaterialNumbers.find(({ number }) => reduceItem === number);
                            return {
                                ...acc,
                                [reduceItem]: !activeRole ?
                                    (checkMaterialIsOptionalType(maintenanceGridMaterialNumber) ? <></> :
                                            Number.isInteger(item?.material?.pricePerLiter * item?.amount)
                                                ? item?.material?.pricePerLiter * item?.amount
                                                : (item?.material?.pricePerLiter * item?.amount).toFixed(2)
                                    )
                                    : maintenanceGridMaterialNumber ?
                                        <IconWorkType type={maintenanceGridMaterialNumber.type}/> : <></>,
                            };
                        },
                        {},
                    );
                    return {
                        id: item?.material?.code,
                        name: `${item?.material?.name}`,
                        quantity: item?.amount,
                        value: !!item?.material?.pricePerLiter,
                        ...maintancePositions,
                    };
                }) || [];


            const preparedSpares =
                maintenanceGrid?.data?.spares?.map((item) => {
                    const maintancePositions = item.maintenanceNumbers.reduce(
                        (
                            acc: { [key: number]: string | number | boolean | JSX.Element },
                            reduceItem,
                        ) => {
                            const maintenanceGridSpareNumbers: MaintenanceGridType | undefined = item?.maintenanceGridSpareNumbers.find(({ number }) => reduceItem === number);

                            return {
                                ...acc,
                                [reduceItem]: !activeRole ?
                                    (
                                        checkMaterialIsOptionalType(maintenanceGridSpareNumbers) ? <></> :
                                            (
                                                Number.isInteger(item?.spare?.price * item?.amount)
                                                    ? item?.spare?.price * item?.amount
                                                    : (item?.spare?.price * item?.amount).toFixed(2)
                                            )
                                    )
                                    : maintenanceGridSpareNumbers ?
                                        <IconWorkType type={maintenanceGridSpareNumbers.type}/> : <></>,
                            };
                        },
                        {},
                    );
                    return {
                        id: item?.spare?.code,
                        name: `${item?.spare?.name}`,
                        quantity: item?.amount,
                        value: !!item?.spare?.price,
                        ...maintancePositions,
                    };
                }) || [];

            return { preparedWorks, preparedGsm, preparedSpares };
        }, [ maintenanceGrid?.data, activeRole ]);

    const { mutateAsync: exportMaintenanceGrid } = useExportMaintenanceGrid();

    const handleExportMaintenanceGrid = () => {
        exportMaintenanceGrid(
            //@ts-ignore
            data?.id || '',
            {
                onSuccess: (response) => {
                    handleFileLoad(response.data as Blob, 'maintenance-grid.xlsx');
                },
            },
        );
    };

    return (
        <StyledContent>
            <Space direction='vertical' size={8}>
                <Paragraph size={16} color='black'>
                    {title}
                </Paragraph>
                <Paragraph size={14} color='gray3'>
                    {subtitle}
                </Paragraph>
            </Space>

            <StyledContentTitle>
                {profileRoleType === 'kia' ? (
                    <StyledButtons size={8}>
                        <IconButton
                            icon='save'
                            isSquared={true}
                            variant='secondary'
                            color='gray'
                            onClick={handleExportMaintenanceGrid}
                        />
                        <IconButton
                            icon='share'
                            isSquared={true}
                            variant='secondary'
                            color='gray'
                            onClick={() => copyCurrentUrl()}
                        />
                    </StyledButtons>
                ) : (
                    <></>
                )}

                <Paragraph size={18} color='black'>
                    Сетка ТО
                </Paragraph>

                <StyledRoleButtons size={0}>
                    <StyledButton
                        size='middle'
                        theme='primary'
                        color={activeRole ? 'gray' : 'brand'}
                        onClick={() => setActiveRole((prev) => !prev)}
                        isDisabled={!isEditEnabled}
                    >
                        Менеджер
                    </StyledButton>
                    <StyledButton
                        size='middle'
                        theme='primary'
                        color={activeRole ? 'brand' : 'gray'}
                        onClick={() => isEditEnabled && setActiveRole((prev) => !prev)}
                    >
                        Дилер
                    </StyledButton>
                </StyledRoleButtons>
            </StyledContentTitle>
            {!isGridLoading ? (
                <>
                    <StyledTableMaintenanceGrids
                        title='Работы:'
                        amountTitle='ИТОГО РАБОТЫ:'
                        headers={worksTableHeaders}
                        data={preparedWorks}
                        withFooter={!activeRole}
                        isViewMode={true}
                    />

                    <StyledTableMaintenanceGrids
                        title='ГСМ:'
                        amountTitle='ИТОГО ГСМ:'
                        headers={gsmTableHeaders}
                        data={preparedGsm || []}
                        withFooter={!activeRole}
                        isViewMode={true}
                        gsmCheckMark={true}
                    />

                    <StyledTableMaintenanceGrids
                        title='Запчасти:'
                        amountTitle='ИТОГО ЗАПЧАСТИ:'
                        headers={partsTableHeaders}
                        data={preparedSpares || []}
                        withFooter={!activeRole}
                        isViewMode={true}
                        gsmCheckMark={true}
                    />
                </>
            ) : (
                <Loader size={48}/>
            )}
        </StyledContent>
    );
};

export { GridTab };
