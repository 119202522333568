import { MenuProps } from "antd";
import { Icon } from "../icon/Icon";
import { StyledDropdown, StyledMenu } from "./Dropdown.style";

export interface IDropdownProps {
  items?: MenuProps["items"];
  handleMenuClick?: MenuProps["onClick"];
  handleButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Dropdown: React.FC<IDropdownProps> = ({
  items,
  handleMenuClick,
  handleButtonClick,
}) => {
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <StyledDropdown overlay={<StyledMenu {...menuProps} />} trigger={["click"]}>
      <Icon name="vertical-dots" size={24} />
    </StyledDropdown>
  );
};

export { Dropdown };
