import {
  TDecoderPurchaseRecord,
  TGetDecoderPurchaseResponse,
} from "@/helpers/api/vinDecoder/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem, TableSimple } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import { Pagination } from "@/packages/pagination/Pagination";
import { StyledContent } from "@/components/VinDecoder/index.styles";
import { FC } from "react";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { usePagination } from "@/hooks/usePagination";
import { useTranslation } from "react-i18next";

interface IHistoryTab {
  title: string;
  subtitle: string;
  data?: TGetDecoderPurchaseResponse;
  openVinDecodeHistoryModal: () => void;
  setHistoryData: (VinHistoryType) => void;
}

interface IDataItem extends TDecoderPurchaseRecord {
  actions?: {
    onInfo?: () => void;
    onCopy?: () => void;
  };
}

const HistoryTab: FC<IHistoryTab> = (props) => {
  const { title, subtitle, data, openVinDecodeHistoryModal, setHistoryData } =
    props;

  const { paginationParams, setPage } = usePagination();

  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const headers: IHeaderItem<IDataItem>[] = [
    {
      Header: "Код Заказ наряда",
      accessor: "code",
      isSortable: true,
      isSearchable: true,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      Header: "Дилерский центр",
      accessor: "dealerCode",
      isSortable: true,
      isSearchable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: "Дата открытия",
      accessor: "dateOpen",
      isSortable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      isSortable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: "Вид ремонта",
      accessor: "tag",
      width: 200,
    },
    {
      Header: "Сумма",
      accessor: "totalSum",
      isSortable: true,
      width: 100,
      Cell: ({ value }) => {
        const adaptSum = `${value.toFixed(2).toLocaleString()} ₽`;
        return <>{adaptSum}</>;
      },
    },
    {
      Header: "Действия",
      accessor: "actions",
      width: 100,
      Cell: (data) => {
        const onViewHandler = () => {
          openVinDecodeHistoryModal();
          setHistoryData(data.row.original);
        };
        return (
          <Space>
            <IconButton
              icon="info"
              color="black"
              size="l"
              variant="tertiary"
              onClick={onViewHandler}
            ></IconButton>
            {/*<IconButton*/}
            {/*  icon="link"*/}
            {/*  color="black"*/}
            {/*  size="l"*/}
            {/*  variant="tertiary"*/}
            {/*></IconButton>*/}
          </Space>
        );
      },
    },
  ];

  return (
    <StyledContent>
      <Space size={16} align="center">
        <Space size={8}>
          <IconButton
            icon="save"
            isSquared={true}
            variant="secondary"
            color="gray"
          />
          <IconButton
            icon="share"
            isSquared={true}
            variant="secondary"
            color="gray"
            onClick={() => copyCurrentUrl()}
          />
        </Space>

        <Space direction="vertical" size={8}>
          <Paragraph size={16} color="black">
            {title}
          </Paragraph>
          <Paragraph size={10} color="gray3">
            {subtitle}
          </Paragraph>
        </Space>
      </Space>

      <Space direction="vertical" size={24} align="center">
        <TableSimple
          headers={headers}
          alignHeader="center"
          alignCell="center"
          data={data?.data || []}
          getRowId={(record) => record.code}
          headerParams={{
            color: "white" as any,
          }}
        />
        <Pagination
          current={paginationParams.page}
          onChange={setPage}
          pageSize={1}
          total={data?.meta?.pageCount}
          disabled={data?.meta?.hasNextPage}
        />
      </Space>
    </StyledContent>
  );
};

export { HistoryTab };
