import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TExportDealerMonitoringQualityCarsRequest,
  TExportDealerMonitoringQualityCarsResponse,
  TExportKiaMonitoringQualityCarsRequest,
  TExportKiaMonitoringQualityCarsResponse,
  TExportMonitoringQualityDealersRequest,
  TExportMonitoringQualityDealersResponse,
  TGetDealerMonitoringQualityCarsRequest,
  TGetDealerMonitoringQualityCarsResponse,
  TGetKiaMonitoringQualityCarsRequest,
  TGetKiaMonitoringQualityCarsResponse,
  TGetMonitoringQualityByIdRequest,
  TGetMonitoringQualityByIdResponse,
  TGetMonitoringQualityCarsByIdRequest,
  TGetMonitoringQualityCarsByIdResponse,
  TGetMonitoringQualityCarsFiltersRequest,
  TGetMonitoringQualityCarsFiltersResponse,
  TGetMonitoringQualityCarsMessagesRequest,
  TGetMonitoringQualityCarsMessagesResponse,
  TGetMonitoringQualityDealersFiltersRequest,
  TGetMonitoringQualityDealersFiltersResponse,
  TGetMonitoringQualityDealersRequest,
  TGetMonitoringQualityDealersResponse,
  TGetMonitoringQualityFiltersCataloguesResponse,
  TGetMonitoringQualityFiltersGenerationsResponse,
  TGetMonitoringQualityFiltersTitlesResponse,
  TGetMonitoringQualityNumbersResponse,
  TGetMonitoringQualityRequest,
  TGetMonitoringQualityResponse,
  TNotifyMonitoringQualityDealersRequest,
  TNotifyMonitoringQualityDealersResponse,
  TNotifyNotFamiliarizedDealersRequest,
  TNotifyNotFamiliarizedDealersResponse,
  TPatchMonitoringQualityByIdRequest,
  TPatchMonitoringQualityByIdResponse,
  TPatchMonitoringQualityCarsByIdRequest,
  TPatchMonitoringQualityCarsByIdResponse,
  TPatchMonitoringQualityCarsStatusRequest,
  TPatchMonitoringQualityCloseSearchRequest,
  TPatchMonitoringQualityCloseSearchResponse,
  TPatchMonitoringQualityCloseStudyRequest,
  TPatchMonitoringQualityCloseStudyResponse,
  TPatchMonitoringQualityFamiliarizeRequest,
  TPatchMonitoringQualityFamiliarizeResponse,
  TPatchOpenMonitoringRequest,
  TPostMonitoringQualityCarsMessagesRequestBody,
  TPostMonitoringQualityCarsMessagesResponse,
  TPostMonitoringQualityCarsResponse,
  TPostMonitoringQualityRequest,
  TPostMonitoringQualityResponse,
} from "./types";
import { useNotification } from "@/hooks/useNotification";

export const useGetMonitoringQuality = (
  request?: TGetMonitoringQualityRequest
) => {
  return useQuery(
    ["monitoring-quality", "list", request],
    () => {
      return api.get<TGetMonitoringQualityResponse>("/quality-monitoring", {
        params: request,
      });
    },
    {
      refetchOnMount: true,
    }
  );
};

export const useGetMonitoringQualityNumber = () => {
  return useQuery(["monitoring-quality", "list"], () => {
    return api.get<TGetMonitoringQualityNumbersResponse>(
      "/quality-monitoring/numbers/next"
    );
  });
};

export const useGetMonitoringQualityFiltersGenerations = () => {
  return useQuery(["monitoring-quality", "list", "generations"], () => {
    return api.get<TGetMonitoringQualityFiltersGenerationsResponse>(
      "/quality-monitoring/filters/generations"
    );
  });
};

export const useGetMonitoringQualityFiltersTitles = () => {
  return useQuery(["monitoring-quality", "list", "titles"], () => {
    return api.get<TGetMonitoringQualityFiltersTitlesResponse>(
      "/quality-monitoring/filters/titles"
    );
  });
};

export const useGetMonitoringQualityFiltersCatalogues = () => {
  return useQuery(["monitoring-quality", "list", "catalogues"], () => {
    return api.get<TGetMonitoringQualityFiltersCataloguesResponse>(
      "/quality-monitoring/filters/catalogue-numbers"
    );
  });
};

export const useGetMonitoringQualityById = (
  request: TGetMonitoringQualityByIdRequest
) => {
  return useQuery(["monitoring-quality", "list", request], () => {
    return api.get<TGetMonitoringQualityByIdResponse>(
      `/quality-monitoring/${request.id}`
    );
  });
};

export const usePostMonitoringQuality = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPostMonitoringQualityRequest) => {
      return api.post<TPostMonitoringQualityResponse>(
        "/quality-monitoring",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["monitoring-quality", "list"]);
      },
    }
  );
};

export const usePatchMonitoringQualityFamiliarize = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: TPatchMonitoringQualityFamiliarizeRequest) => {
      return api.patch<TPatchMonitoringQualityFamiliarizeResponse>(
        `/quality-monitoring/${id}/familiarize`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["monitoring-quality", "list"]);
      },
    }
  );
};

export const usePatchMonitoringQualityCloseSearch = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: TPatchMonitoringQualityCloseSearchRequest) => {
      return api.patch<TPatchMonitoringQualityCloseSearchResponse>(
        `/quality-monitoring/${id}/close-search`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["monitoring-quality", "list"]);
      },
    }
  );
};

export const usePatchMonitoringQualityCloseStudy = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: TPatchMonitoringQualityCloseStudyRequest) => {
      return api.patch<TPatchMonitoringQualityCloseStudyResponse>(
        `quality-monitoring/${id}/close-study`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["monitoring-quality", "list"]);
      },
    }
  );
};

export const usePatchMonitoringQualityById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchMonitoringQualityByIdRequest) => {
      return api.patch<TPatchMonitoringQualityByIdResponse>(
        `/quality-monitoring/${id}`,
        patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["monitoring-quality", "list"]);
      },
    }
  );
};

// Заявки автомобилей
export const useGetKiaMonitoringQualityCars = (
  request?: TGetKiaMonitoringQualityCarsRequest
) => {
  return useQuery(["kia", "request-cars", request], () => {
    return api.get<TGetKiaMonitoringQualityCarsResponse>(
      "/kia/quality-monitoring/request-cars",
      {
        params: request,
      }
    );
  });
};

export const useGetDealerMonitoringQualityCars = (
  id: TGetDealerMonitoringQualityCarsRequest["id"],
  request?: TGetDealerMonitoringQualityCarsRequest["query"]
) => {
  return useQuery(["dealers", "request-cars", id, request], () => {
    return api.get<TGetDealerMonitoringQualityCarsResponse>(
      `/dealer/quality-monitoring/${id}/request-cars`,
      {
        params: request,
      }
    );
  });
};

export const useGetMonitoringQualityCarById = (
  request: TGetMonitoringQualityCarsByIdRequest
) => {
  return useQuery(
    ["monitoring-quality", "request-cars", "list", request],
    () => {
      return api.get<TGetMonitoringQualityCarsByIdResponse>(
        `/quality-monitoring/request-cars/${request.id}`,
        {
          params: request,
        }
      );
    }
  );
};

export const usePatchMonitoringQualityCarById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchMonitoringQualityCarsByIdRequest) => {
      return api.patch<TPatchMonitoringQualityCarsByIdResponse>(
        `/quality-monitoring/request-cars/${id}`,
        patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dealers", "kia", "list"]);
      },
    }
  );
};

export const usePatchMonitoringQualityCarStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchMonitoringQualityCarsStatusRequest) => {
      return api.patch<TPatchMonitoringQualityCarsStatusRequest>(
        `/quality-monitoring/request-cars/${id}/status`,
        { status: patch }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "monitoring-quality",
          "request-cars",
          "list",
        ]);
      },
    }
  );
};

export const usePatchOpenMonitoring = () => {
  const queryClient = useQueryClient();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  return useMutation(
    ({ id }: TPatchOpenMonitoringRequest) => {
      return api.patch<TPatchOpenMonitoringRequest>(
        `/quality-monitoring/${id}/open-study`,
      );
    },
    {
      onSuccess: () => {
        createNotificationSuccess('Программа переоткрыта, необходимо изменить даты поиска автомобиля и даты исследований');
        queryClient.invalidateQueries([
          "monitoring-quality",
          "list",
        ]);
      },
      onError: () => {
        createNotificationError('Ошибка открытия программы');
      }
    }
  );
};

export const usePostMonitoringQualityCars = (
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPostMonitoringQualityRequest) => {
      return api.post<TPostMonitoringQualityCarsResponse>(
        "/quality-monitoring/request-cars",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "monitoring-quality",
          "request-cars",
          "list",
        ]);
      },
    }
  );
};

export const useGetMonitoringQualityCarsFilters = (
  request?: TGetMonitoringQualityCarsFiltersRequest
) => {
  return useQuery(
    ["monitoring-quality", "request-cars", "filters", request],
    () => {
      return api.get<TGetMonitoringQualityCarsFiltersResponse>(
        "/quality-monitoring/request-cars/filters",
        {
          params: request,
        }
      );
    }
  );
};

export const useGetMonitoringQualityCarsMessages = (
  request?: TGetMonitoringQualityCarsMessagesRequest
) => {
  return useQuery(
    ["monitoring-quality", "request-cars", "messages", request],
    () => {
      return api.get<TGetMonitoringQualityCarsMessagesResponse>(
        "/quality-monitoring/request-cars/messages",
        {
          params: request,
        }
      );
    }
  );
};

export const usePostMonitoringQualityCarsMessages = () => {
  return useMutation(
    ({
       data,
       onSuccess,
     }: {
      data: TPostMonitoringQualityCarsMessagesRequestBody;
      onSuccess: () => void;
    }) => {
      return api.post<TPostMonitoringQualityCarsMessagesResponse>(
        "/quality-monitoring/request-cars/messages",
        data
      );
    },
    {
      onSuccess: (_, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useExportKiaMonitoringQualityCars = () => {
  return useMutation((request: TExportKiaMonitoringQualityCarsRequest) => {
    return api.get<TExportKiaMonitoringQualityCarsResponse>(
      `kia/quality-monitoring/request-cars/export`,
      {
        params: request,
        responseType: "blob",
      }
    );
  });
};

// Дилеры

export const useGetMonitoringQualityDealers = (
  id: string,
  request?: TGetMonitoringQualityDealersRequest
) => {
  return useQuery(
    ["monitoring-quality", "dealers", "list", request, id],
    () => {
      return api.get<TGetMonitoringQualityDealersResponse>(
        `/quality-monitoring/${id}/dealers`,
        {
          params: request,
        }
      );
    }
  );
};

export const useGetMonitoringQualityDealersFilters = (
  id: string,
  request?: TGetMonitoringQualityDealersFiltersRequest
) => {
  return useQuery(["monitoring-quality", "dealers", "filters", request], () => {
    return api.get<TGetMonitoringQualityDealersFiltersResponse>(
      `/quality-monitoring/${id}/dealers/filters`,
      {
        params: request,
      }
    );
  });
};

export const useExportDealerMonitoringQualityCars = ({ id }) => {
  return useMutation((request: TExportDealerMonitoringQualityCarsRequest) => {
    return api.get<TExportDealerMonitoringQualityCarsResponse>(
      `dealer/quality-monitoring/${id}/request-cars/export`,
      {
        params: request,
        responseType: "blob",
      }
    );
  });
};

export const useExportMonitoringQualityDealers = () => {
  return useMutation((request: TExportMonitoringQualityDealersRequest) => {
    return api.get<TExportMonitoringQualityDealersResponse>(
      //@ts-ignore
      `/quality-monitoring/${request.id}/dealers/export`,
      {
        params: request,
        responseType: "blob",
      }
    );
  });
};

export const useReadAllMessages = () => {
  const queryClient = useQueryClient();
  return useMutation((request: TNotifyMonitoringQualityDealersRequest) => {
      return api.post<TNotifyMonitoringQualityDealersResponse>(
        `/quality-monitoring/request-cars/${request.id}/read-all-messages`
      );
    },
    {
      onSuccess: () => {
        console.log('READING');
        queryClient.invalidateQueries(["request-cars", 'list']);
      },
    });
};

export const useNotifyMonitoringQualityDealers = () => {
  return useMutation((request: TNotifyMonitoringQualityDealersRequest) => {
    return api.post<TNotifyMonitoringQualityDealersResponse>(
      `/quality-monitoring/${request.id}/notify-dealers`
    );
  });
};

export const useNotifyNotFamiliarizedDealers = () => {
  return useMutation((request: TNotifyNotFamiliarizedDealersRequest) => {
    return api.post<TNotifyNotFamiliarizedDealersResponse>(
      `/quality-monitoring/${request.id}/notify-not-familiarized-dealers`
    );
  });
};

export const useNotifyMonitoringQualityDealersAddingCars = () => {
  return useMutation((request: TNotifyMonitoringQualityDealersRequest) => {
    return api.post<TNotifyMonitoringQualityDealersResponse>(
      `/quality-monitoring/${request.id}/notify-about-adding-cars`
    );
  });
};
