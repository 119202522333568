import { Button } from "@/packages/button/Button";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  StyledButtonsContainer,
  StyledGridsFilterWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_GRIDS_FILTER_FORM_VALUES,
  GridsFilterFormFieldsNameEnum,
  IGridsFilterFormValues,
  useGridsFilterForm,
} from "@/components/MaintenanceGrids/GridsTab/GridsFilters/hooks";
import { useGetGridsList } from "@/helpers/api/grids/hooks";

export interface IGridsFilterProps {
  initValues: IGridsFilterFormValues;
  setGridsFilters: (filters: IGridsFilterFormValues | any) => void;
  setPage: ((page: number, pageSize: number) => void);
}

export type FilterParams  = {
    "filters[models]"?: string;
    "filters[generations]"?: string;
    "filters[restyling]"?: string;
}

export const GridsFilters: React.FC<IGridsFilterProps> = ({
  initValues,
  setGridsFilters,
  setPage,
}) => {
  const onFilterApply = (data) => {
    setGridsFilters(data);
  };

  const { form, onClearHandler, onSubmitHandler } = useGridsFilterForm({
    initValues,
    onSubmit: onFilterApply,
  });

  const { t } = useTranslation();

  const [filters, setFilters] = useState(initValues);
  const [filtersParams, setFiltersParams] = useState<FilterParams | null>(null);
  useEffect(() => {
        const filter: FilterParams = {}
        if(!!filters?.models?.length) {
            filter['filters[models]'] = filters?.models
        }

        if(!!filters?.generations?.length) {
            filter['filters[generations]'] = filters?.generations
        }

        if(filters?.restyling?.length) {
            filter['filters[restyling]'] = filters?.restyling
        }

        setFiltersParams(filter);

    }, [filters?.models, filters?.generations, filters?.restyling]);

  const handleReset = () => {
    onClearHandler();
    onSubmitHandler && onSubmitHandler();
    setFilters(DEFAULT_GRIDS_FILTER_FORM_VALUES);
  };

  const handleSubmit = () => {
    setPage(1, 10);
    onSubmitHandler();
  };

  const { data: gridsList, isLoading: isGridsLoading } = useGetGridsList(filtersParams);
  const preparedModelsList = useMemo(
    () =>
      gridsList?.data?.models?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [gridsList?.data, isGridsLoading]
  );
  const preparedGenerationsList = useMemo(
    () =>
      gridsList?.data?.generations?.map((item) => ({
        label: item.fullModelName,
        value: item.id,
      })) || [],
    [gridsList?.data, isGridsLoading]
  );
  const preparedRestylingList = useMemo(
    () =>
      gridsList?.data?.restylings?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    [gridsList?.data, isGridsLoading]
  );

  return (
    <StyledGridsFilterWrapper>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={8}>
            <FormSelect
              name={GridsFilterFormFieldsNameEnum.Model}
              label={"Модель"}
              placeholder={t("monitoring.all") || ""}
              isSearchShow={true}
              onSearch={() => ({})}
              options={preparedModelsList}
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, models: value });
                if(value) {
                    form.setValue("models", [value]);
                } else {
                    form.setValue("models", []);
                }
              }}
              value={filters?.models}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={GridsFilterFormFieldsNameEnum.Generation}
              label={"Поколение"}
              placeholder={t("monitoring.all") || ""}
              isSearchShow={true}
              isEditable={!!filters?.models?.length}
              onSearch={() => ({})}
              options={preparedGenerationsList}
              onChange={(value) => {
                setFilters({ ...filters, generations: value });
                  if(value) {
                    form.setValue("generations", [value]);
                  } else {
                      form.setValue("generations", []);
                  }
              }}
              allowClear
              value={filters?.generations}
            />
          </Col>
          {/*<Col span={7}>*/}
          {/*  <FormSelect*/}
          {/*    name={GridsFilterFormFieldsNameEnum.Restyling}*/}
          {/*    label={"Рестайлинг"}*/}
          {/*    placeholder={t("monitoring.all") || ""}*/}
          {/*    isSearchShow={true}*/}
          {/*    isEditable={!!filters?.generations?.length}*/}
          {/*    onSearch={() => ({})}*/}
          {/*    options={preparedRestylingList}*/}
          {/*    onChange={(value) => {*/}
          {/*      setFilters({ ...filters, restyling: value });*/}
          {/*        if(value) {*/}
          {/*            form.setValue("restyling", [value]);*/}
          {/*        } else {*/}
          {/*            form.setValue("restyling", []);*/}
          {/*        }*/}
          {/*    }}*/}
          {/*    allowClear*/}
          {/*    value={filters?.restyling}*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col span={4}>*/}
          {/*  <FormSelect*/}
          {/*    name={MonitoringFilterFormFieldsNameEnum.Titles}*/}
          {/*    label={t("monitoring.titles") || ""}*/}
          {/*    placeholder={t("monitoring.all") || ""}*/}
          {/*    mode="multiple"*/}
          {/*    isSearcShow={true}*/}
          {/*    onSearch={() => ({})}*/}
          {/*    options={filterOptions?.titles}*/}
          {/*    allowClear*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col span={4}>*/}
          {/*  <FormSelect*/}
          {/*    name={MonitoringFilterFormFieldsNameEnum.CatalogueNumbers}*/}
          {/*    label={t("monitoring.catalogueNumbers") || ""}*/}
          {/*    placeholder={t("monitoring.all") || ""}*/}
          {/*    mode="multiple"*/}
          {/*    isSearcShow={true}*/}
          {/*    onSearch={() => ({})}*/}
          {/*    options={filterOptions?.catalogues}*/}
          {/*    allowClear*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
      </FormProvider>
      <StyledButtonsContainer>
        <Button
          color="brand"
          theme="secondary"
          size="small"
          onClick={handleReset}
        >
          {"Сбросить"}
        </Button>
        <Button
          color="brand"
          theme="primary"
          size="small"
          onClick={handleSubmit}
        >
          {"Искать"}
        </Button>
      </StyledButtonsContainer>
    </StyledGridsFilterWrapper>
  );
};
