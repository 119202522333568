import { colors } from "@/helpers";
import { TChangeRoleNotification } from "@/helpers/api/notifications/types";
import {
  useConfirmRoleChange,
  useGetUserById,
  useRejectRoleChange,
} from "@/helpers/api/users/hooks";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { InputField } from "@/packages/formElements/fields/InputField";
import { Space } from "antd";
import { FC, useState } from "react";
import styled from "styled-components";

const StyledNotificationsResultUserDataChange = styled.div`
  padding: 24px;
  width: 50%;

  display: grid;
  gap: 32px;

  background-color: ${colors.white};
`;

const StyledInputs = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
`;

interface INotificationsUserDataChange {
  notification: TChangeRoleNotification;
}

const NotificationsResultUserDataChange: FC<INotificationsUserDataChange> = (
  props
) => {
  const { notification } = props;
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { data: userData } = useGetUserById({ id: notification.data.entityId });
  const { mutateAsync: confirmRoleChange } = useConfirmRoleChange();
  const { mutateAsync: rejectRoleChange } = useRejectRoleChange();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const personId = notification?.data?.entityId as string;

  const handleConfirmButtonClick = async () => {
    try {
      await confirmRoleChange({
        id: personId,
      });
      createNotificationSuccess("Заявка на смену роли принята");
      setIsDisabled(true);
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
      setIsDisabled(true);
    }
  };

  const handleCancelButtonClick = async () => {
    try {
      await rejectRoleChange({
        id: personId,
      });
      createNotificationSuccess("Заявка на смену роли отклонена");
      setIsDisabled(true);
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
      setIsDisabled(true);
    }
  };

  return (
    <>
      <StyledNotificationsResultUserDataChange>
        <StyledInputs>
          <Space direction="vertical" size={24}>
            <InputField
              label="Фамилия"
              value={notification?.data.lastName}
              disabled={true}
            />
            <InputField
              label="Старая должность"
              value={notification?.data.oldRole}
              disabled={true}
            />
            <InputField
              label="Почта"
              value={notification?.data.email}
              disabled={true}
            />
          </Space>

          <Space direction="vertical" size={24}>
            <InputField
              label="Имя"
              value={notification?.data.firstName}
              disabled={true}
            />
            <InputField
              label="Новая должность"
              value={notification?.data.role}
              disabled={true}
            />
            <InputField
              label="Телефон"
              value={notification?.data.phone}
              disabled={true}
            />
          </Space>
        </StyledInputs>

        <Space align="center" size={16}>
          <Button
            color="brand"
            theme="primary"
            onClick={handleConfirmButtonClick}
            isDisabled={
              isDisabled || userData?.data.role.name === notification.data.role
            }
          >
            Подтвердить изменения
          </Button>
          <Button
            color="brand"
            theme="secondary"
            onClick={handleCancelButtonClick}
            isDisabled={
              isDisabled || userData?.data.role.name === notification.data.role
            }
          >
            Отклонить изменения
          </Button>
        </Space>
      </StyledNotificationsResultUserDataChange>
    </>
  );
};

export { NotificationsResultUserDataChange };
