import { useForm } from "@/hooks/useForm";
import * as yup from "yup";
import { useEffect } from "react";
import { TGetUserByIdResponse } from "@/helpers/api/users/types";

export enum EditUserFormFieldsEnum {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Phone = "phone",
  Country = "country",
  IsActive = "isActive",
  RoleId = "roleId",
  DealerId = "dealerId",
  Type = "type",
}

export interface EditUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  country?: string;
  isActive?: boolean;
  roleId?: string | null;
  dealerId?: string;
  type: 'kia' | 'dealer' | 'external';
}

export const DEFAULT_CREATE_FORM_VALUES: EditUser =
  {
    [EditUserFormFieldsEnum.FirstName]: undefined,
    [EditUserFormFieldsEnum.LastName]: undefined,
    [EditUserFormFieldsEnum.Email]: undefined,
    [EditUserFormFieldsEnum.Phone]: undefined,
    [EditUserFormFieldsEnum.Country]: undefined,
    [EditUserFormFieldsEnum.IsActive]: undefined,
    [EditUserFormFieldsEnum.RoleId]: undefined,
    [EditUserFormFieldsEnum.DealerId]: undefined,
    [EditUserFormFieldsEnum.Type]: 'dealer',
  };

export const formatSchema = () => {
  return yup
    .object({
      [EditUserFormFieldsEnum.FirstName]: yup
        .string()
        .required("Введите имя")
        .nullable(),
      [EditUserFormFieldsEnum.LastName]: yup
        .string()
        .required("Введите фамилию")
        .nullable(),
      [EditUserFormFieldsEnum.Country]: yup
        .string()
        .required("Выберите страну")
        .nullable(),
      [EditUserFormFieldsEnum.RoleId]: yup
        .string()
        .required("Выберите должность")
        .nullable(),
      [EditUserFormFieldsEnum.Email]: yup
        .string()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Неверный формат email"
        )
        .required("Введите email")
        .nullable(),

    })
    .required();
};

const getDefaultValues = (values: TGetUserByIdResponse | undefined): EditUser => {
  return {
    firstName: values?.firstName ?? undefined,
    lastName: values?.lastName ?? undefined,
    //@ts-ignore
    email: values?.email?._address ?? undefined,
    phone: values?.phone ?? undefined,
    country: values?.country ?? undefined,
    isActive: values?.isActive ?? undefined,
    roleId: values?.role?.id ?? undefined,
    dealerId: values?.dealer?.id ?? undefined,
    type: values?.type ?? 'dealer',
  }
}

export const useEditUserForm = (options: {
  onSubmit;
  initValues?: TGetUserByIdResponse;
  kiaDealersListData?: any
}) => {
  const { onSubmit, initValues, kiaDealersListData } = options;

  const form = useForm<EditUser>({
    defaultValues: DEFAULT_CREATE_FORM_VALUES,
    validateFormSchema: formatSchema(),
    onSubmit: (data) => onSubmit(data),
  });

  useEffect(() => {
    form.reset(getDefaultValues(initValues))
  }, [initValues, kiaDealersListData]);

  return form;
};