import { useDeleteAuditTemplate, usePatchAuditTemplate, } from "@/helpers/api/audits/hooks";
import { TAuditTemplatesResponse, TPatchAuditRecord, } from "@/helpers/api/audits/types";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";

import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import React from "react";

import { getDefaultAuditTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

interface IAuditTemplateTable extends Pick<ITableSimple, "onSort"> {
  data?: TAuditTemplatesResponse;
  isLoading?: boolean;
  paginationParams: { page: number; limit: number };
  hasPagination?: boolean;
  updateAuditList: () => void;
  setPage: (page: number) => void;
  onEditClick: (id: number) => void;
  onViewClick: (id: number) => void;
}

export const AuditTemplateTable: React.FC<IAuditTemplateTable> = (props) => {
  const {
    data,
    isLoading,
    hasPagination,
    paginationParams,
    setPage,
    updateAuditList,
    onSort,
    onEditClick,
    onViewClick,
  } = props;
  const { mutate: deleteTemplate } = useDeleteAuditTemplate();

  const handleDeleteTemplate = (id: number) => {
    deleteTemplate(
      {
        id,
      },
      {
        onSuccess: () => {
          updateAuditList();
        },
      }
    );
  };

  const { mutate: updateAuditTemplate } = usePatchAuditTemplate();

  const handleUpdateAuditTemplate = (id: number, patch: TPatchAuditRecord) => {
    updateAuditTemplate(
      { id, patch },
      {
        onSuccess: () => {
          updateAuditList();
        },
      }
    );
  };

  const { dispatch } = useUserInfoModel();

  const headers = getDefaultAuditTableHeaders({
    onAuditUpdate: handleUpdateAuditTemplate,
    onDeleteClick: handleDeleteTemplate,
    onEditClick,
    onViewClick,
    openUserModal: dispatch
  });

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          <>
            <TableSimple
              headers={headers || []}
              data={data?.data || []}
              onSort={onSort}
              withOverflow
              autoOverflow
            />
            {hasPagination ? (
              <Pagination
                current={paginationParams.page}
                onChange={setPage}
                pageSize={1}
                total={paginationParams.limit}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Loader size={48}/>
          </>
        )}
      </StyledTableSimple>
    </>
  );
};
