import React from "react";
import styled from "styled-components";
import { Button as AntButtonText } from "antd";
import { keyframes } from "styled-components";

import { colors } from "@/helpers";

import { ReactComponent as LoadingIcon } from "@/assets/icons/button-loading.svg";

interface IButtonText {
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  href?: string;
  htmlType?: "submit" | "reset" | "button";
  isLoadingLeft?: boolean;
  isLoadingRight?: boolean;
  isIconLeft?: boolean;
  isIconRight?: boolean;
  isBlock?: boolean;
  target?: string;
  isUploadLeft?: boolean;
  isUploadRight?: boolean;
  color?: "brand" | "red" | "gray";
  size?: "middle" | "small";
}

const StyledButtonText = styled(AntButtonText)<IButtonText>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  outline: none;
  transition: all 0.5s ease;
  position: relative;
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  &:disabled {
    cursor: not-allowed;
  }

  ${({ color }) =>
    color === "brand"
      ? `
    & svg {
          & path {
            fill: ${colors.brand}
          }
      }
  `
      : color === "red"
      ? `
    & svg {
          & path {
            fill: ${colors.red}
          }
      }
  `
      : color === "gray"
      ? `
    & svg {
          & path {
            fill: ${colors.gray}
          }
      }
  `
      : `
    & svg {
          & path {
            fill: ${colors.black}
          }
      }
  `}

  ${({ size }) =>
    size === "middle"
      ? `
    padding: 8px 16px;
  `
      : `
    padding: 4px 8px;
  `}

  ${({ color }) =>
    `
      color: ${
        color === "brand"
          ? colors.black
          : color === "red"
          ? colors.red
          : color === "gray"
          ? colors.gray200
          : colors.black
      };

      &:disabled {
        color:  ${colors.gray200};
      }

      &:hover {
        border: 1px solid transparent;
        color: ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : color === "gray"
            ? colors.brand
            : colors.black
        };
      }

      &:focus {
        border: 1px solid ${colors.black};
        color: ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : color === "gray"
            ? colors.black
            : colors.black
        };
      }
      `}
`;

const AnimationCircle = keyframes`
 0% { transform: rotate(0deg) }
 100% { transform: rotate(360deg) }
`;

const LoadingIcons = styled(LoadingIcon)`
  width: 12px;
  height: 12px;
  transition: all 0.5s ease;
  animation-name: ${AnimationCircle};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

const StyledButtonTextIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTextLeftIcon = styled(StyledButtonTextIcon)`
  margin-right: 4px;
`;

const ButtonTextRightIcon = styled(StyledButtonTextIcon)`
  margin-left: 4px;
`;

const StyledChildrenBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChildrenWrapper = styled.div`
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  position: relative;
`;

const ButtonText: React.FC<IButtonText> = (props) => {
  const {
    isDisabled,
    children,
    onClick,
    icon,
    href,
    htmlType,
    isBlock,
    target,
    isIconLeft,
    isIconRight,
    isLoadingLeft,
    isLoadingRight,
    color,
    size,
    ...rest
  } = props;

  return (
    <StyledButtonText
      disabled={isDisabled}
      onClick={onClick}
      href={href}
      htmlType={htmlType}
      block={isBlock}
      target={target}
      color={color}
      size={size}
      {...rest}
    >
      <StyledChildrenWrapper>
        <StyledChildrenBlock className="children-block">
          {isLoadingLeft ? (
            <ButtonTextLeftIcon>
              <LoadingIcons />
            </ButtonTextLeftIcon>
          ) : (
            <></>
          )}
          {icon && isIconLeft ? (
            <ButtonTextLeftIcon>{icon}</ButtonTextLeftIcon>
          ) : (
            <></>
          )}
          {children}
          {icon && isIconRight ? (
            <ButtonTextRightIcon>{icon}</ButtonTextRightIcon>
          ) : (
            <></>
          )}
          {isLoadingRight ? (
            <ButtonTextRightIcon>
              <LoadingIcons />
            </ButtonTextRightIcon>
          ) : (
            <></>
          )}
        </StyledChildrenBlock>
      </StyledChildrenWrapper>
    </StyledButtonText>
  );
};

export { ButtonText };
