import { TKiaDealerCreateRequest } from "@/helpers/api/dc/kia-dealers/types";
import { TInformationLettersRecord } from "@/helpers/api/InformationalLetters/types";
import * as yup from "yup";
import {
  InformationLettersDataFormFieldsEnum,
  TInformationLettersDataFieldsValues,
} from "./types";
import { t } from "i18next";

export const informationLettersFieldsSchema = () => {
  return yup
    .object({
      [InformationLettersDataFormFieldsEnum.Name]: yup
        .string()
        .required(t("dealerMaterials.letters.validation.name") || ""),
      [InformationLettersDataFormFieldsEnum.Number]: yup
        .string()
        .required(t("dealerMaterials.letters.validation.number") || ""),
      // TODO: IP валидация
      [InformationLettersDataFormFieldsEnum.RoleIds]: yup
        .array()
        .min(1, t("dealerMaterials.letters.validation.role") || ""),
      [InformationLettersDataFormFieldsEnum.Description]: yup
        .string()
        .required(t("dealerMaterials.letters.validation.descr") || "")
        .max(1000, t("dealerMaterials.letters.validation.max_1000") || ""),

      // [InformationLettersDataFormFieldsEnum.FileIds]: yup
      //   .array()
      //   .nullable(),
    })
    .required();
};

export const DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES: TInformationLettersDataFieldsValues =
  {
    [InformationLettersDataFormFieldsEnum.Name]: "",
    [InformationLettersDataFormFieldsEnum.Number]: "",
    [InformationLettersDataFormFieldsEnum.RoleIds]: [],
    [InformationLettersDataFormFieldsEnum.Description]: "",
    [InformationLettersDataFormFieldsEnum.FileIds]: [],
  };

export const informationLetterRecordToRegistryFields = (
  record: TInformationLettersRecord
): any => {
  const {
    name,
    number,
    description,
    files,
    createdAt,
    createdBy,
    ...restFields
  } = record;

  // @ts-ignore
  return {
    ...restFields,
    createdAt: new Date(createdAt),
    createdBy,
    name,
    number,
    description,
    files: files.map((x) => x.id),
  };
};

export type TRegistryFieldsDealerDTODataPart = Omit<
  TKiaDealerCreateRequest,
  "id" | "isActive" | "files"
>;

export const successInformationLettersFieldsToDealerDTO = (
  values: any
): any => {
  const { name, number, roleIds, description, fileIds, roles, ...restFields } =
    values;

  const result: any = {
    name,
    number,
    roleIds: roleIds.map((role) => role.value),
    description,
    ...restFields,
  };

  if (fileIds) {
    result.fileIds = [fileIds?.id];
  }

  return result;
};

export const informationLettersEditFieldsToDealerDTO = (values: any): any => {
  const {
    name,
    number,
    roleIds,
    description,
    fileIds,
    roles,
    files,
    ...restFields
  } = values;

  const result: any = {
    name,
    number,
    roleIds: roleIds && roleIds.map((role) => role.value),
    description,
    ...restFields,
  };

  // if (files && files.length) {
  //   result.fileIds = files;
  // }

  return result;
};

export const successInformationLettersCreateDTO = (values: any): any => {
  const { name, number, roleIds, description, fileIds, roles, ...restFields } =
    values;

  const result: any = {
    name,
    number,
    roleIds,
    description,
    ...restFields,
  };

  if (fileIds) {
    result.fileIds = values?.fileIds.map((item) => item.id);
  }

  return result;
};

export const infoAtInformationLettersDTO = (values: any): any => {
  const { name, number, roles, description, files, fileIds, ...restFields } =
    values;

  const result: any = {
    name,
    number,
    roleIds: roles.map((role) => ({
      value: role.id,
      label: role.name,
    })),
    description,
    ...restFields,
  };

  if (files) {
    result.fileIds = files[0];
  }

  return result;
};
