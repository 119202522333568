import { TInformationLettersRecord } from "@/helpers/api/InformationalLetters/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { Collapse } from "antd";
import styled, { css } from "styled-components";

const { Panel } = Collapse;

export const StyledTable = styled(TableSimple<TInformationLettersRecord>)`
  margin-top: 16px;
`;

export const StyledCollapse = styled(Collapse)`
  background: none;
  .ant-collapse-header {
    background: #fff;
    margin: 15px 0;
    border-radius: 12px !important;
  }

  .ant-collapse-content-box {
    padding-right: 0;
  }
`;

export const StyledPanel = styled(Panel)`
  border-radius: 12px;
  border: none;
  margin-bottom: 12px;
  background: none;
`;

export const StyledTabType = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  overflow-x: auto;
  padding-bottom: 15px;
`;

interface ITab {
  isActive: boolean;
}

export const StyledTab = styled.div<ITab>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 9px 24px;
  border-radius: 12px;
  width: fit-content;
  white-space: nowrap;
  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #05141f;
          color: #f9f9fd;
        `
      : css`
          background-color: transparent;
          color: #282a30;
        `}
`;

export const StyledViolationData = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px
`;
