import { FC, useContext, useEffect } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormProvider } from "react-hook-form";
import { CreateGsmSuccessFormValues, useCreateGsmForm } from "../hooks";
import { GsmFormModal } from "@/components/MaintenanceGrids/GsmTab/GsmFormModal";
import { ContextMaintenanceGrids, MAINTENANCE_GRIDS, } from "@/hooks/contexts/useMaintenanceGrids";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

type TGsmEditModal = Pick<IModal, "onCancel">;

const GsmEditModal: FC<TGsmEditModal> = (props) => {
  const { onCancel } = props;
  const { hasAccess: hasEdit } = usePermission("maintenance-grid", "update");
  const {
    state: { activeGsm },
    actions: { updateGsm },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const onSubmitButtonClick = (data: CreateGsmSuccessFormValues) => {
    if (!activeGsm) return;
    updateGsm({ id: activeGsm.id,
      //@ts-ignore
      data,
      onCancel,
    });
  };

  const { form, onSubmitHandler } = useCreateGsmForm(onSubmitButtonClick, true);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (!activeGsm) return;

    form.setValue("type", activeGsm.type, { shouldDirty: true });
    form.setValue("name", activeGsm.name, { shouldDirty: true });
    form.setValue("code", activeGsm.code, { shouldDirty: true });
    form.setValue("price", activeGsm.price, {
      shouldDirty: true,
    });
    // @ts-ignore
    form.setValue("volume", activeGsm.volume, {
      shouldDirty: true,
    });
    form.setValue("isDefault", activeGsm.isDefault, { shouldDirty: true });
  }, [activeGsm, form]);

  return (
    <Modal
      {...props}
      title={`${hasEdit ? t("common.edit") || "" : t("common.view") || ""}
       ${t("maintenanceGrids.gsm")}`}
      confirmButtonText={t("maintenanceGrids.gridsModel.gsmSave") || ""}
      cancelButtonText={t("common.cancel") || ""}
      isDisabledCancelButton={!hasEdit}
      isDisabledConfirmButton={!hasEdit}
      onSubmit={() => {
        onSubmitHandler();
      }}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <GsmFormModal editMode={true} type={'gsm'}/>
      </FormProvider>
    </Modal>
  );
};

export { GsmEditModal };
