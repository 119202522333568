import React from "react";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Field } from "../Field";
import {
  StyledAddButton,
  StyledControlContainer,
  StyledInput,
  StyledInputsContainer,
  StyledInputWithDeleteContainer,
  StyledIcon,
} from "./index.styles";

export type TCodeListValue = {
  code: string;
  name: string;
};

export interface IEquipmentListField {
  addButtonText: string;
  value?: TCodeListValue[];
  onChange?: (value: TCodeListValue[]) => void;
  nameDescription?: string;
  codeDescription?: string;
  disabled?: boolean;
  readOnly?: boolean;
  nameLabel?: string;
  codeLabel?: string;
  namePlaceholder?: string;
  codePlaceholder?: string;
  error?: string;
}

export interface IInputsForm
  extends Omit<IEquipmentListField, "error" | "addButtonText"> {
  nameValue?: string;
  codeValue?: string;
  onCodeChange?: (value) => void;
  onNameChange?: (value) => void;
}

const INITIAL_FORM = {
  code: "",
  name: "",
};

const InputsForm: React.FC<IInputsForm> = (props) => {
  const {
    nameValue,
    codeValue,
    onCodeChange,
    onNameChange,
    disabled,
    nameLabel,
    codeLabel,
    namePlaceholder,
    codePlaceholder,
    nameDescription,
    codeDescription,
    readOnly = false,
  } = props;

  return (
    <StyledInputsContainer>
      <Field>
        {codeLabel ? (
          <Field.Label disabled={disabled} readOnly={readOnly}>
            {codeLabel}
          </Field.Label>
        ) : (
          <></>
        )}
        <StyledInput
          value={codeValue}
          placeholder={codePlaceholder}
          onChange={onCodeChange}
          disabled={disabled}
          readOnly={readOnly}
        />

        {codeDescription ? (
          <Field.FieldDescription>{codeDescription}</Field.FieldDescription>
        ) : (
          <></>
        )}
      </Field>
      <Field>
        {nameLabel ? (
          <Field.Label disabled={disabled} readOnly={readOnly}>
            {nameLabel}
          </Field.Label>
        ) : (
          <></>
        )}
        <StyledInput
          value={nameValue}
          placeholder={namePlaceholder}
          onChange={onNameChange}
          disabled={disabled}
          readOnly={readOnly}
        />

        {nameDescription ? (
          <Field.FieldDescription>{nameDescription}</Field.FieldDescription>
        ) : (
          <></>
        )}
      </Field>
    </StyledInputsContainer>
  );
};

export const EquipmentListField: React.FC<IEquipmentListField> = (props) => {
  const {
    disabled = false,
    error,
    value,
    onChange,
    nameDescription,
    codeDescription,
    nameLabel,
    codeLabel,
    namePlaceholder,
    codePlaceholder,
    readOnly = false,
    addButtonText,
  } = props;

  const internalValue = value ? value : [INITIAL_FORM];

  const getInputPropsByIndex = (index: number) => {
    return {
      value: value?.[index] || INITIAL_FORM,
      onChanges: {
        onCodeChange: (inputValue?: string) => {
          const newValue = [...internalValue];
          newValue[index] = { ...newValue[index], code: inputValue || "" };
          onChange && onChange(newValue);
        },
        onNameChange: (inputValue?: string) => {
          const newValue = [...internalValue];
          newValue[index] = { ...newValue[index], name: inputValue || "" };
          onChange && onChange(newValue);
        },
      },
    };
  };

  const getDeleteItemHandler = (index: number) => () => {
    const newValue = [...internalValue];
    newValue.splice(index, 1);
    onChange && onChange(newValue);
  };

  const addItem = () => {
    const newValue = [...internalValue, INITIAL_FORM];
    onChange && onChange(newValue);
  };

  return (
    <Field>
      <StyledControlContainer>
        {internalValue.map((_, index) => {
          const props = getInputPropsByIndex(index);
          const { value, onChanges } = props;
          const { code, name } = value;
          const { onCodeChange, onNameChange } = onChanges;

          if (index !== 0) {
            return (
              <StyledInputWithDeleteContainer>
                <InputsForm
                  codeValue={code}
                  nameValue={name}
                  disabled={disabled}
                  nameLabel={nameLabel}
                  codeLabel={codeLabel}
                  namePlaceholder={namePlaceholder}
                  codePlaceholder={codePlaceholder}
                  nameDescription={nameDescription}
                  codeDescription={codeDescription}
                  onCodeChange={onCodeChange}
                  onNameChange={onNameChange}
                  readOnly={readOnly}
                />
                <IconButton
                  size="l"
                  icon="delete"
                  onClick={getDeleteItemHandler(index)}
                  color="gray"
                  variant="tertiary"
                  isDisabled={readOnly}
                />
              </StyledInputWithDeleteContainer>
            );
          }
          return (
            <InputsForm
              codeValue={code}
              nameValue={name}
              disabled={disabled}
              nameLabel={nameLabel}
              codeLabel={codeLabel}
              namePlaceholder={namePlaceholder}
              codePlaceholder={codePlaceholder}
              nameDescription={nameDescription}
              codeDescription={codeDescription}
              onCodeChange={onCodeChange}
              onNameChange={onNameChange}
              readOnly={readOnly}
            />
          );
        })}
      </StyledControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      <StyledAddButton
        theme="secondary"
        onClick={addItem}
        isDisabled={readOnly}
      >
        <StyledIcon size={16} name="add" />
        {addButtonText}
      </StyledAddButton>
    </Field>
  );
};
