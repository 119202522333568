import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TFlexIdleCreateRequest,
  TFlexIdleCreateResponse,
  TFlexIdlePutRequset,
  TFlexIdlePutResponse,
  TGetFlexIdleRequest,
  TGetFlexIdleRequestById,
  TGetFlexIdleResponse,
  TGetFlexIdleResponseById,
  TIdleDeleteRequest,
  TIdleDeleteResponse,
} from "./types";

export const useGetIdleList = (request: TGetFlexIdleRequest) => {
  return useQuery(["idle", "list", request], () => {
    return api.get<TGetFlexIdleResponse>("/flex/idle", {
      params: request,
    });
  });
};

export const useGetIdleById = (id: TGetFlexIdleRequestById) => {
  return useQuery(["idle", "detail", id], () => {
    return api.get<TGetFlexIdleResponseById>(`/flex/idle/${id}`);
  });
};

export const useCreateIdle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFlexIdleCreateRequest) => {
      return api.post<TFlexIdleCreateResponse>("/flex/idle", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["idle", "list"]);
      },
    }
  );
};

export const usePutIdle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TFlexIdlePutRequset) => {
      return api.put<TFlexIdlePutResponse>(`/flex/idle/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["idle", "list"]);
        queryClient.invalidateQueries(["idle", "detail", variables.id]);
      },
    }
  );
};

export const useDeleteIdle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TIdleDeleteRequest) => {
      return api.delete<TIdleDeleteResponse>(`flex/idle/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["idle", "list"]);
        queryClient.invalidateQueries(["idle", "detail", variables.id]);
      },
    }
  );
};

export const useExportIdleList = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/flex/idle/export`, {
      responseType: "blob",
    });
  });
};
