import { IRoleSelectAdapter } from "@/adapters/selects/RoleSelectAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HoldingSelectAdapter } from "@/adapters/selects/HoldingSelectAdapter";

export interface IFormPostSelect
  extends Omit<IRoleSelectAdapter, "error" | "value" | "onChange"> {
  name: string;
  isDisabled?: boolean;
}

const _FormHoldingSelect: React.FC<IFormPostSelect> = (props) => {
  const { name, isDisabled, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <HoldingSelectAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
export const FormHoldingSelect = React.memo(_FormHoldingSelect);
