import { InputField } from "@/packages/formElements/fields/InputField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Popconfirm } from "antd";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  StyledChildren, StyledDrag,
  StyledFormEditBatchModal,
  StyledInputWrapper,
} from "./index.styles";

import { ITreeProps } from "./Tree";
import { getMutatedParentDndSectionsArray, findNodeById, IAuditBatchResponseToMutate, isParent, removeIdDeep } from "./utils";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "@/packages/draggableTable/DraggableTable";
import { Identifier, XYCoord } from "dnd-core";
import { Icon } from "@/packages/icon/Icon";
import { useNotification } from "@/hooks/useNotification";

export interface IRowComponentProps extends Omit<ITreeProps, "data"> {
  section: IAuditBatchResponseToMutate;
  children: React.ReactNode;
  index: number;
  id: string;
  hasChildren?: boolean;
}

export interface DragItem {
  id: string;
  hoverIndex: number;
  dragIndex: number;
}

const RowComponent: FC<IRowComponentProps> = (props) => {
  const {
    hasChildren,
    section,
    sections,
    setSections,
    children,
    onActionHandler,
    index,
  } = props;
  const { t } = useTranslation();
  const [inputSection, setInputSection] = useState(section);

  const { createNotificationError } =
    useNotification();

  useEffect(() => {
    setInputSection(section)
  }, [section])

  const addSubSectionHandler = useCallback(() => {
    const { id, children, level, depth, } = section;

    const index = children ? children.length + 1 : 1;

    const newSubSection = {
      id: uuidv4(),
      title: "",
      depth: depth + 1,
      parentId: id,
      level: `${level}.${index}`,
      order: section.children ? section.children.length : 0,
    };

    section.children = section.children
      ? [...section.children, newSubSection]
      : [newSubSection];

    setSections(JSON.parse(JSON.stringify(sections)));
    onActionHandler("add", newSubSection);
  }, [section, setSections, sections, onActionHandler]);

  const deleteSectionHandler = useCallback(() => {
    const filteredSections = removeIdDeep(sections, section.id);
    setSections(JSON.parse(JSON.stringify(filteredSections)));
    onActionHandler("delete", section);
  }, [section, setSections, sections, onActionHandler]);

  const editSection = (prop: string, event: string) => {
    setInputSection((prev) => ({ ...prev, title: event }));
    section[prop] = event;
    onActionHandler("edit", section);
  };
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.TREE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem) {
      const dropNode = findNodeById(sections, item.id);
      if (!dropNode) {
        return;
      }

      if (isParent(dropNode, section.id) || isParent(section, dropNode.id)) {
        createNotificationError('Невозожно перенести родительский элемент в дочерний и наоборот!')
        return;
      }

      const mutatedSections = getMutatedParentDndSectionsArray(sections, dropNode.id, section, onActionHandler)
      if (!mutatedSections) {
        return;
      }
      const mutatedSections2 = getMutatedParentDndSectionsArray(mutatedSections, section.id, dropNode, onActionHandler)
      if (!mutatedSections2) {
        return;
      }

      setSections(mutatedSections2);
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.hoverIndex;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      item.hoverIndex = hoverIndex;
      item.dragIndex = dragIndex;
    },
  });

  const [{}, drag] = useDrag(() => ({
    type: ItemTypes.TREE,
    canDrag: true,
    item: () => {
      return { id: section.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [])

  drag(drop(ref));

  return (
    <StyledFormEditBatchModal>
      <StyledInputWrapper ref={ref}>
        <StyledDrag>
          <Icon name="drag" size={16}/>
        </StyledDrag>
        <InputField
          name={section.id}
          label={t("dealerMaterials.letters.sectionName") || ""}
          prefix={inputSection.level}
          value={inputSection.title}
          isBlock={true}
          onChange={(value) => {
            editSection("title", value);
          }}
        />
        <Popconfirm
          title={t("audits.templates.cantDelete") || ""}
          okText="Ok"
          showCancel={false}
          disabled={!hasChildren}
        >
          <IconButton
            variant="tertiary"
            icon="delete"
            color="brand"
            size="l"
            onClick={() => !hasChildren && deleteSectionHandler()}
          />
        </Popconfirm>
      </StyledInputWrapper>

      <IconButton
        variant="tertiary"
        icon="plus"
        color="brand"
        onClick={addSubSectionHandler}
      >
        {t("dealerMaterials.letters.addSection_2") || ""}
      </IconButton>

      {children ? <StyledChildren>{children}</StyledChildren> : <></>}
    </StyledFormEditBatchModal>
  );
};

export default RowComponent;
