import { useQuery } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import { FilterParams } from "@/components/MaintenanceGrids/GridsTab/GridsFilters";

export const useGetGridsList = (request: FilterParams | null) => {
  return useQuery(["gridsModels", "models", request], () => {
    return api.get<any>("/maintenance-grids/filters", {
      params: request,
    });
  });
};
