import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../../axiosInstance";
import {
  TFlexTariffsCreateRequest,
  TFlexTariffsCreateResponse,
  TGetTariffsModelsRequest,
  TGetTariffsModelsResponse,
  TGetTariffsRequest,
  TGetTariffsRequestById,
  TGetTariffsResponse,
  TGetTariffsResponseById,
  TTariffsDeleteRequest,
  TTariffsDeleteResponse,
  TTariffsPatchRequset,
  TTariffsPatchResponse,
} from "./types";

export const useGetTatiffsList = (request: TGetTariffsRequest) => {
  return useQuery(["tariffs", "list", request], () => {
    return api.get<TGetTariffsResponse>("/flex/tariffs", {
      params: request,
    });
  });
};

export const useGetTatiffModelsList = (request: TGetTariffsModelsRequest) => {
  return useQuery(["tariffs/model", "list", request], () => {
    return api.get<TGetTariffsModelsResponse>("/flex/tariffs/models", {
      params: request,
    });
  });
};

export const useGetTatiffsById = (id?: TGetTariffsRequestById) => {
  return useQuery(
    ["tariffs", "detail", id],
    () => {
      return api.get<TGetTariffsResponseById>(`/flex/tariffs/${id}`);
    },
    { enabled: !!id }
  );
};

export const useCreateTatiffs = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TFlexTariffsCreateRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TFlexTariffsCreateResponse>("/flex/tariffs", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const usePatchTatiffs = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TTariffsPatchRequset) => {
      return api.patch<TTariffsPatchResponse>(`/flex/tariffs/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["tariffs", "list"]);
        queryClient.invalidateQueries(["tariffs", "detail", variables.id]);
      },
    }
  );
};

export const useDeleteTariffs = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TTariffsDeleteRequest) => {
      return api.delete<TTariffsDeleteResponse>(`flex/tariffs/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["tariffs", "list"]);
        queryClient.invalidateQueries(["tariffs", "detail", variables.id]);
      },
    }
  );
};
