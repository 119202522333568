import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { api } from "../axiosInstance";

import {
  TDeleteNewsByIdRequest,
  TDeleteNewsByIdResponse,
  TGetNewsByIdRequest,
  TGetNewsByIdResponse,
  TGetNewsListRequest,
  TGetNewsListResponse,
  TNewsCreateRequest,
  TNewsCreateResponse,
  TNewsPatchRequest,
  TNewsPatchResponse,
} from "./types";

export const useGetNewsList = (request: TGetNewsListRequest) => {
  return useQuery(
    ["news", "list", request],
    () => {
      return api.get<TGetNewsListResponse>("/news", {
        params: request,
      });
    },
    {
      refetchOnMount: true,
      enabled: !!request.groupId,
    }
  );
};

export const useGetNewsGroupsList = (request: any) => {
  return useQuery(
    ["groups", "list", request],
    () => {
      return api.get<any>("/news/groups", {
        params: request,
      });
    },
    {
      refetchOnMount: true,
    }
  );
};

export const useGetNewsById = (request: TGetNewsByIdRequest) => {
  return useQuery(
    ["news", "detail", request.id],
    () => {
      return api.get<TGetNewsByIdResponse>(`/news/${request.id}`);
    },
    {
      refetchOnMount: true,
    }
  );
};

export const useCreateNews = () => {
  const queryClient = new QueryClient();

  return useMutation(
    (data: TNewsCreateRequest) => {
      return api.post<TNewsCreateResponse>("/news", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["news", "list"]);
      },
    }
  );
};

export const usePatchNews = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TNewsPatchRequest) => {
      return api.patch<TNewsPatchResponse>(`/news/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["news", "detail", variables.id]);
        queryClient.invalidateQueries(["news", "list"]);
      },
    }
  );
};

export const useDeleteNewsById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TDeleteNewsByIdRequest) => {
      return api.delete<TDeleteNewsByIdResponse>(`/news/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["news", "detail", variables.id]);
        queryClient.invalidateQueries(["news", "list"]);
      },
    }
  );
};
