import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { AutoParkForm } from "@/components/AutoPark/AutoParkForm";
import { AutoParkErrorModal } from "@/components/AutoPark/AutoParkErrorModal";
import { IAutoParkFormValues } from "@/components/AutoPark/AutoParkForm/hooks";

import { TVehiclePutRecord } from "@/helpers/api/vehicles/types";

import { useModal } from "@/hooks/useModal";
import {
  useGetVehicleById,
  usePatchVehicle,
} from "@/helpers/api/vehicles/hooks";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

export const AutoParkEdit: React.FC = () => {
  const params = useParams();
  const id = params.id as string;

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { modalState, openModal } = useModal();

  const { data: vehicleResponse, isLoading: isLoadingVehicle } =
    useGetVehicleById({ id });
  const { mutateAsync: putVehicleAsync, isLoading: isPutVehicleLoading } =
    usePatchVehicle();

  const { createNotificationError } = useNotification();

  const handleFormSubmit = async (data: IAutoParkFormValues) => {
    const {
      commissioningDate,
      expiredCASCO,
      expiredOSAGO,
      registrationCertificate,
      number,
      vin,
      equipmentId,
      color,
      images,
      files,
    } = data;

    const mediaImages = images
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const mediaFiles = files
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const media = {
      photo: mediaImages,
      file: mediaFiles,
    };

    const dataToServer: TVehiclePutRecord = {
      commissioning: commissioningDate ? commissioningDate.toISOString() : "",
      expiredCASCO: expiredCASCO ? expiredCASCO.toISOString() : undefined,
      expiredOSAGO: expiredOSAGO ? expiredOSAGO.toISOString() : "",
      equipmentId: equipmentId || "",
      registrationCertificate,
      governmentNumber: number,
      media,
      color,
      vin,
    };

    if (expiredOSAGO! > new Date()) {
      try {
        await putVehicleAsync({
          id,
          put: { ...dataToServer },
        });
        navigate("/autopark");
      } catch (error) {
        if (
          error &&
          // @ts-ignore
          error.response?.data.message === "Generation is inactive"
        ) {
          createNotificationError("Поколение неактивно");
        }
      }
    } else {
      openModal();
    }
  };

  return (
    <DashboardLayout toBack="/autopark" title={t("park.editCar") || ""}>
      {vehicleResponse?.data ? (
        <AutoParkForm
          defaultValues={vehicleResponse?.data}
          onFormSubmit={handleFormSubmit}
          isLoading={isPutVehicleLoading}
        />
      ) : (
        <></>
      )}

      <AutoParkErrorModal
        isOpen={modalState.isOpen}
        onSubmit={modalState.onSubmit}
      />
    </DashboardLayout>
  );
};
