import { Button } from "@/packages/button/Button";
import React from "react";
import { StyledFooter } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export interface ITransmissionFooter {
  onCreate: () => void;
}
export const TransmissionFooter: React.FC<ITransmissionFooter> = ({
  onCreate,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Button color="brand" size="middle" theme="primary" onClick={onCreate}>
        {t("parts.addTransmission") || ""}
      </Button>
    </StyledFooter>
  );
};
