import { ChatModal, IMessageParams } from "@/components/Audits/ChatModal";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import {
  useEditViolation,
  useGetWarrantyViolations,
  useSendViolationtoDealer,
  useSendViolationtonKia,
} from "@/helpers/api/warranty-audit/violations/hooks";
import { DppoViolations, TEditWarrantyViolationsRequest, } from "@/helpers/api/warranty-audit/violations/types";
import { useEditWithdrawal } from "@/helpers/api/warranty-audit/withdrawal/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { Loader } from "@/packages/loader/Loader";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import React, { useCallback, useContext, useState } from "react";
import { GetDppoHeaders, getWarrantyDocsTableHeaders } from "./helpers";
import { StyledParagraph, StyledTitle } from "./index.styles";
import { useTranslation } from "react-i18next";
import { useGetTemplateViolationList } from "@/helpers/api/warranty-audit/templates/hooks";
import { useEditRequirementsTemplate } from "@/helpers/api/warranty-audit/fill/hooks";
import { useReadAllAuditGaranteeMessage } from "@/helpers/api/audit-chat/hooks";

interface IViolations {
  id: number;
  auditInfo: TGetWarrantyByIdResponse;
  warrantyData: TGetWarrantyByIdResponse;
}

export const Violations: React.FC<IViolations> = (props) => {
  const { id, auditInfo } = props;
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const { t } = useTranslation();
  const isDealerKia = profile?.type === "kia";

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const {
    data: violations,
    isLoading,
    refetch: updateViolation,
  } = useGetWarrantyViolations({ auditId: id });
  const { mutate: editViolation } = useEditViolation();
  const { mutate: sendViolation, isLoading: isSending } =
    useSendViolationtoDealer();

  const { mutate: sendViolationToKia, isLoading: isSendingToKia } =
    useSendViolationtonKia();

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const handleEditViolation = (
    id: string,
    request: TEditWarrantyViolationsRequest
  ) => {
    editViolation({
      isDealerKia,
      id,
      data: request,
      onSuccess: () => {
        ChatModalClose();
        createNotificationSuccess(
          t("warrantyAudits.list.createNotificationSuccess") || ""
        );
        updateViolation();
      },
      onError: (error) =>
        createNotificationError(error.response?.data?.message || error.message),
    });
  };
  const { mutate: editWithdrawal } = useEditWithdrawal();

  const [selectedViolation, setSelectedViolation] =
    useState<DppoViolations | null>(null);
  const [chatParams, setChatParams] = useState<IMessageParams | null>(null);
  const [isSentToDealerDisabled, setIsSentToDealerDisabled] = useState(false);
  const handleOpenChat = (
    params: IMessageParams,
    violation: DppoViolations
  ) => {
    setChatParams(params);
    setSelectedViolation(violation);
    ChatModalOpen();
  };

  const dppoHeaders = GetDppoHeaders({
    isDealerKia,
    openChat: handleOpenChat,
    onSetTaskStatus: handleEditViolation,
  });

  const handleEditWithdrawal = (requirementId: string, flaq: boolean) => {
    editWithdrawal({
      id: requirementId,
      data: { withdrawal: flaq },
      onSuccess: () => {
        updateViolation();
      },
      onError: (error) =>
        createNotificationError(error.response?.data?.message || error.message),
    });
  };

  const { data: violationTemplates } =
    useGetTemplateViolationList({
      "page[limit]": 0,
      "page[page]": 0,
      'filter[templateId]': auditInfo.templateId,
    });

  const { mutate: editRequirements } = useEditRequirementsTemplate();

  const handleEditRequirements = ({ id, request }) => {
    editRequirements({
      id,
      data: request,
      onSuccess: () => {
        updateViolation();
        createNotificationSuccess('Тип нарушения изменен успешно')
      },
      onError: () => createNotificationError('Произошла ошибка при изменении типа нарушения'),
    });
  };

  const warrantyHeaders = getWarrantyDocsTableHeaders({
    onWithdrawal: handleEditWithdrawal,
    violationTemplates: violationTemplates?.data.data,
    onEditRequirement: handleEditRequirements,
  });
  const handleSendViolation = () => {
    sendViolation({
      data: { auditId: id },
      onSuccess: () => {
        updateViolation();
        setIsSentToDealerDisabled(true);
        createNotificationSuccess(
          t("warrantyAudits.list.createNotificationSuccessViolations") || ""
        );
      },
      onError: (error) =>
        createNotificationError(error.response?.data?.message || error.message),
    });
  };

  const handleSendViolationToKia = () => {
    sendViolationToKia({
      data: { auditId: id },
      onSuccess: () => {
        updateViolation();
        setIsSentToDealerDisabled(true);
        createNotificationSuccess(
          t("warrantyAudits.list.createNotificationSuccessViolationsToKia") || ""
        );
      },
      onError: (error) =>
        createNotificationError(error.response?.data?.message || error.message),
    });
  };

  const btnCallback = () => {
    const accept = {
      cancelText: t("warrantyAudits.list.accept") || "",
      cancelCb: () => {
        if (selectedViolation) {
          handleEditViolation(selectedViolation.id, { status: "accepted" });
        }
      },
    };

    const declined = {
      confirmText: t("warrantyAudits.list.declined") || "",
      confirmCb: () => {
        if (selectedViolation) {
          handleEditViolation(selectedViolation.id, { status: "declined" });
        }
      },
    };

    const cancelSendToReview = {
      cancelText: t("warrantyAudits.list.cancelSendToReview") || "",
      cancelCb: () => {
        if (selectedViolation) {
          handleEditViolation(selectedViolation.id, { status: "in_work" });
        }
      },
    };

    const sendToReview = {
      confirmText: t("warrantyAudits.list.sendToReview") || "",
      confirmCb: () => {
        if (selectedViolation) {
          handleEditViolation(selectedViolation.id, { status: "review" });
        }
      },
    };

    if (isDealerKia) {
      switch (selectedViolation?.status) {
        case "accepted": {
          return { ...declined };
        }
        case "in_work":
          return { ...accept, ...declined };

        case "review":
          return { ...accept, ...declined };
        case "declined":
          return { ...accept };
      }
    } else {
      switch (selectedViolation?.status) {
        case "in_work":
          return { ...sendToReview };

        case "review":
          return { ...cancelSendToReview };
      }
    }
    return {};
  };

  const isImplementerProfile =
    auditInfo.implementerId === profile?.id;

  const handleCloseChat = () => {
    ChatModalClose();
    setChatParams(null);
  };


  const getSortedDppoViolations = useCallback(() => {
    if (!violations?.data.dppoViolations) {
      return []
    }

    const dppoViolations = JSON.parse(JSON.stringify(violations.data.dppoViolations))

    return dppoViolations.sort((value1, value2) => {
      const [charValue1, digitValue1] = value1.answer.param.code.split(/(?<=\D)(?=\d)/);
      const [charValue2, digitValue2] = value2.answer.param.code.split(/(?<=\D)(?=\d)/);

      if (charValue1 < charValue2) {
        return -1;
      }
      if (charValue1 > charValue2) {
        return 1;
      }

      if (Number(digitValue1) < Number(digitValue2)) {
        return -1;
      }
      if (Number(digitValue1) > Number(digitValue2)) {
        return 1;
      }
      return 1;
    })
  }, [violations]);

  return !isLoading ? (
    <>
      {isDealerKia ? (
        <Button
          color="brand"
          size="middle"
          theme="primary"
          isLoading={isSending}
          isDisabled={
            auditInfo.stage !== "Заполнение" || isSentToDealerDisabled
          }
          onClick={() => handleSendViolation()}
        >
          {
            isImplementerProfile && auditInfo.type === "external auditor" ?
              (t("warrantyAudits.list.sendToDealerAndKia") || "")
              :
              (t("warrantyAudits.list.sendToDealer") || "")
          }
        </Button>
      ) : (
        <></>
      )}
      {
        isImplementerProfile && auditInfo.type === 'dealer' ? (
          <Button
            color="brand"
            size="middle"
            theme="primary"
            isLoading={isSendingToKia}
            isDisabled={
              auditInfo.stage !== "Заполнение" || isSentToDealerDisabled
            }
            onClick={() => handleSendViolationToKia()}
          >
            {t("warrantyAudits.list.sendToKia") || ""}
          </Button>
        ) : (
          <></>
        )}
      <StyledTitle>
        <Paragraph size={16}>
          {t("warrantyAudits.list.dppoStandarts") || ""}
        </Paragraph>
      </StyledTitle>
      {
        violations?.data?.dppoViolations &&
        violations?.data?.dppoViolations?.length > 0 ?
          <TableSimple
            isCutVerticalPadding
            headers={dppoHeaders}
            data={getSortedDppoViolations()}
          />
          :
          <StyledParagraph>
            <Paragraph size={14}>
              {t("common.noFilled") || ""}
            </Paragraph>
          </StyledParagraph>
      }
      <StyledTitle>
        <Paragraph size={16}>
          {t("warrantyAudits.list.documentation") || ""}
        </Paragraph>
      </StyledTitle>
      {
        violations?.data?.warrantyRequirements &&
        violations?.data?.warrantyRequirements?.length > 0 ?
          <TableSimple
            autoOverflow
            headers={warrantyHeaders}
            data={violations?.data.warrantyRequirements || []}
            isCutVerticalPadding
            renderElement={(row) => {
              return (
                <TableSimple
                  data={row.original.violations || []}
                  headers={dppoHeaders || []}
                  isCutVerticalPadding
                />
              );
            }}
          />
          :
          <StyledParagraph>
            <Paragraph size={14}>
              {t("common.noFilled") || ""}
            </Paragraph>
          </StyledParagraph>
      }
      <StyledTitle>
        <Paragraph size={16}>{t("warrantyAudits.list.parts") || ""}</Paragraph>
      </StyledTitle>
      {
        violations?.data?.sparesRequirements &&
        violations?.data?.sparesRequirements?.length > 0 ?
          <TableSimple
            autoOverflow
            headers={warrantyHeaders}
            data={violations?.data.sparesRequirements || []}
            isCutVerticalPadding
            renderElement={(row) => {
              return (
                <TableSimple
                  data={row.original.violations || []}
                  headers={dppoHeaders || []}
                  isCutVerticalPadding
                />
              );
            }}
          />
          :
          <StyledParagraph>
            <Paragraph size={14}>
              {t("common.noFilled") || ""}
            </Paragraph>
          </StyledParagraph>
      }
      {chatParams ? (
        <ChatModal
          readAllAuditMessage={useReadAllAuditGaranteeMessage}
          isOpen={ChatModalState.isOpen}
          params={chatParams}
          onClose={handleCloseChat}
          isWarranty={true}
          btnCallback={btnCallback()}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <Loader size={48}/>
  );
};
