import { useNotification } from "./useNotification";

export const useCopyCurrentUrl = (options: {
  successMessage?: string;
  errorMessage?: string;
}) => {
  const { successMessage, errorMessage } = options;
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  return async (url = window.location.href) => {
    try {
      await navigator.clipboard.writeText(url);
      if (successMessage) {
        createNotificationSuccess(successMessage);
      }
    } catch {
      if (errorMessage) {
        createNotificationError(errorMessage);
      }
    }
  };
};
