import { useEffect } from "react";

import * as yup from "yup";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { requiredStringSchema } from "@/helpers/validation";

export interface ICoefficientFormValues {
  percent: string | null;
  coefficientValue: string | null;
}

export const DEFAULT_COEFFICIENT_FORM_VALUES: ICoefficientFormValues = {
  percent: null,
  coefficientValue: null,
};

export type TOnSubmitCoefficientFormCb = (
  values: ICoefficientFormValues
) => void;

export const formSchema = yup.object({
  percent: requiredStringSchema("Необходимо ввести значение")
    .nullable()
    .transform((origVal, val) => {
      if (String(val).includes("%")) {
        return val.replace("%", "");
      }
      return String(val);
    })
    .matches(/^\d*(\.\d)?$/, "Доступен ввод только числовых значений.")
    .test(
      "maxLenght",
      "Максимальное значение - 100",
      //@ts-ignore
      (val) => Number(val) <= 100
    )
    .test("maxLenght", "Минимальное значение - 1", (val) => Number(val) >= 1),
  coefficientValue: requiredStringSchema("Необходимо ввести значение")
    .nullable()
    .matches(/^\d*(\.\d+)?$/, "Доступен ввод только числовых значений.")
    .test(
      "maxLenght",
      "Максимальное значение - 100",
      (val) => Number(val) <= 100
    )
    .test(
      "maxLenght",
      "Минимальное значение - 0.1",
      (val) => Number(val) >= 0.1
    ),
});

export const nonDnmFormSchema = yup.object({
  coefficientValue: requiredStringSchema("Необходимо ввести значение")
    .nullable()
    .matches(/^\d*(\.\d+)?$/, "Доступен ввод только числовых значений.")
    .test(
      "maxLenght",
      "Максимальное значение - 100",
      (val) => Number(val) <= 100
    )
    .test(
      "maxLenght",
      "Минимальное значение - 0.1",
      (val) => Number(val) >= 0.1
    ),
});

export const useCoefficientForm = (
  onSubmit: TOnSubmitCoefficientFormCb,
  defaultValues: ICoefficientFormValues,
  withoutPercentValidation?: boolean
) => {
  const form = useForm<ICoefficientFormValues>({
    resolver: yupResolver(
      withoutPercentValidation ? nonDnmFormSchema : formSchema
    ),
    mode: "onChange",
    defaultValues: defaultValues
      ? {
          percent: String(defaultValues.percent),
          coefficientValue: String(defaultValues.coefficientValue),
        }
      : DEFAULT_COEFFICIENT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  useEffect(() => {
    form.reset(defaultValues, { keepDefaultValues: true });
  }, []);

  return { form, onSubmitHandler };
};
