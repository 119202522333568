import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDataFields } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields";
import { useEditMonitoringForm } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/hooks";
import { ALL_SELECTED } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/types";
import { MonitoringModal } from "@/components/MonitoringQuality/MonitoringModal";
import { useGetCities } from "@/helpers/api/city/hooks";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import {
  useGetMonitoringQualityById,
  usePatchMonitoringQualityById,
} from "@/helpers/api/monitoringQuality/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Loader } from "@/packages/loader/Loader";
import { Space } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IMonitoringQualityEditProps {}

export const MonitoringQualityEdit: React.FC<IMonitoringQualityEditProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    modalState: notificationModalState,
    openModal: openNotificationModalState,
  } = useModal();

  const params = useParams();
  const navigate = useNavigate();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { data: citiesData } = useGetCities({
    limit: 0,
  });

  const { data: dealersData } = useGetKiaDealerList({
    limit: 0,
  });

  const allCitiesIds = citiesData?.data.data.map((item) => item.id);
  //@ts-ignore
  const allDealersIds = dealersData?.data.data.map((item) => item.id);

  const onEditMonitoringHandler: any = async (data) => {
    const dataToServer = data;
    if (data.cityIds?.includes(ALL_SELECTED)) {
      dataToServer.cityIds = allCitiesIds;
    }
    if (data.dealerIds?.includes(ALL_SELECTED)) {
      dataToServer.dealerIds = allDealersIds;
    }
    try {
      await patchMonitoringQualityByIdAsync({
        id: params.id as string,
        patch: dataToServer,
      });
      createNotificationSuccess(t("monitoring.editSuccess"));
      navigate("/monitoring-quality");
    } catch (error) {
      createNotificationError(t("monitoring.editError") || "");
      throw error;
    }
  };

  const { data: monitoringQualityById, isLoading } =
    useGetMonitoringQualityById({
      id: params.id as string,
    });

  const {
    mutateAsync: patchMonitoringQualityByIdAsync,
    isLoading: isEditLoading,
  } = usePatchMonitoringQualityById();

  const onCancelEditHandler = () => {
    //@ts-ignore
    openNotificationModalState({
      textMessage: t("monitoring.cancelConfirm") || "",
    });
  };

  const { form, submit } = useEditMonitoringForm({
    editableRecord: monitoringQualityById?.data,
    t,
    onSubmit: onEditMonitoringHandler,
  });

  return (
    <DashboardLayout
      title={t("monitoring.editTitle") || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName={"quality-monitoring"}
        resourceAction="update"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48} />}
      >
        {isLoading ? (
          <Loader size={48} />
        ) : (
          <>
            <FormProvider {...form}>
              <MonitoringDataFields form={form} isEditable={true} />
            </FormProvider>
            <Space>
              <Button
                theme="secondary"
                color="brand"
                size="middle"
                onClick={onCancelEditHandler}
              >
                {t("bonuses.reportsView.cancel") || ""}
              </Button>
              <Button
                theme="primary"
                color="brand"
                size="middle"
                onClick={submit}
                isLoading={isEditLoading}
                isDisabled={isEditLoading}
              >
                {t("bonuses.reportsView.save") || ""}
              </Button>
            </Space>
            <MonitoringModal
              {...notificationModalState}
              onSubmit={() => navigate("/monitoring-quality")}
            />
          </>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
