import { TKiaHoldingRecord } from "@/helpers/api/dc/kia-holdings/types";
import { Pagination } from "@/packages/pagination/Pagination";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import styled from "styled-components";

export const StyledTable = styled(TableSimple<TKiaHoldingRecord>)`
  margin-bottom: 64px;
`;

export const StyledPagination = styled(Pagination)`
  width: max-content;
  margin: 0 auto;
`;

export const StyledClick = styled.span`
  cursor: pointer;
`;
