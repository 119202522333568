import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type LoginFormValues = {
  login: string;
  password: string;
  rememberMe: boolean;
  isAdLogin: boolean;
};

const DEFAULT_LOGIN_FORM_VALUES: LoginFormValues = {
  login: "",
  password: "",
  rememberMe: false,
  isAdLogin: false,
};

export const formSchema = () => {
  return yup
    .object({
      login: yup.string().required(t("authorization.validation.login") || ""),
      password: yup
        .string()
        .required(t("authorization.validation.password") || ""),
    })
    .required();
};

export type TOnSubmitLoginFormCb = (data: LoginFormValues) => Promise<void>;

export const useLoginForm = (onSubmit: TOnSubmitLoginFormCb) => {
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_LOGIN_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    setIsSubmitLoading(true);
    try {
      await onSubmit(data);
    } finally {
      setIsSubmitLoading(false);
    }
  });

  return {
    form,
    onSubmitHandler,
    isSubmitLoading,
  };
};
