import InputMask from "react-input-mask";
import styled, { css } from "styled-components";

export const StyledInput = styled(InputMask)`
  border: 1px solid #e4e7ec !important;
  border-radius: 4px;
  color: #353333;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  min-height: 32px;
  background-color: #ffffff;
  padding: 0 10px;
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
      `;
    }
  }}
`;
