import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import {
  useEditUserActivity,
  useGetDealerEmployeesList,
} from "@/helpers/api/users/hooks";
import { TUserRecord } from "@/helpers/api/users/types";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { useSelectItems } from "@/hooks/useSelectItems";
import { Space } from "antd";
import { Pagination } from "@/packages/pagination/Pagination";
import React, { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { EmployeeFilter } from "./EmployeeFilter";
import { IRegistryEmployeeFilterFormValues } from "./EmployeeFilter/hooks";
import { EmployeeTable } from "./EmployeeTable";
import {
  StyledDetailRegistryEmployeesTab,
  StyledTableWrapper,
} from "./index.styled";

export interface IDetailRegistryEmployeesProps {
  dealer: TDealerRecord;
  onDeleteClick: (userId: string) => void;
  isDisabled?: boolean;
}

export const DetailRegistryEmployeesTab: React.FC<
  IDetailRegistryEmployeesProps
> = ({ dealer, onDeleteClick, isDisabled = false }) => {
  const {
    setSelectItems,
  } = useSelectItems();

  const { mutate: updateUserActivity } = useEditUserActivity();

  const { createNotificationError } = useNotification();

  const { paginationParams, setPage } = usePagination();

  const [filterQueryParamsValues, setFilterQueryParamsValues] = useQueryParams({
    name: StringParam,
    roleId: StringParam,
    status: BooleanParam,
  });

  const enabledFilterValues: IRegistryEmployeeFilterFormValues = {
    name: filterQueryParamsValues.name || undefined,
    roleId: filterQueryParamsValues.roleId || undefined,
    status: filterQueryParamsValues.status ?? true,
  };

  const [debouncedFilterName] = useDebounce(enabledFilterValues.name, 300);

  const { data: userListResponse, refetch: updateUsers } =
    useGetDealerEmployeesList({
      ...paginationParams,
      ...enabledFilterValues,
      dealerId: dealer.id,
      name: debouncedFilterName,
    });

  const usersTotal = userListResponse?.data?.meta?.itemCount;
  const userList = userListResponse?.data.data ?? [];

  const onChangeIsActive = (record: TUserRecord, value: boolean) => {
    updateUserActivity({
      request: {
        id: record.id,
        patch: { isActive: value },
      },
      onSuccess: () => updateUsers(),
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  useEffect(() => {
    setPage(1);
  }, [filterQueryParamsValues]);

  return (
    <StyledDetailRegistryEmployeesTab>
      <Space size={32}>
        <EmployeeFilter
          values={enabledFilterValues}
          onChange={setFilterQueryParamsValues}
        />
      </Space>
      <StyledTableWrapper>
        <EmployeeTable
          data={userList}
          isDisabled={isDisabled}
          onChangeSelectedItems={setSelectItems}
          onChangeIsActive={onChangeIsActive}
          onDeleteClick={onDeleteClick}
        />

        <Pagination
          current={paginationParams.page}
          onChange={setPage}
          pageSize={10}
          total={usersTotal}
          showSizeChanger={false}
        />
      </StyledTableWrapper>
    </StyledDetailRegistryEmployeesTab>
  );
};
