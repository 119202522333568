import { IconButton } from "@/packages/icon-button/IconButton";
import React from "react";
import { Field } from "../Field";
import {
  StyledAddButton,
  StyledControlContainer,
  StyledInput,
  StyledInputWithDeleteContainer,
  StyledIcon,
} from "./index.styles";

export type TCodeListValue = string[];

export interface ICodeListField {
  addButtonText;
  value?: TCodeListValue;
  onChange?: (value: TCodeListValue) => void;
  description?: string;
  disabled?: boolean;
  label?: string;
  error?: string;
  placeholder?: string;
  readOnly?: boolean;
}

export const CodeListField: React.FC<ICodeListField> = (props) => {
  const {
    addButtonText,
    disabled,
    label,
    error,
    value,
    onChange,
    description,
    placeholder,
    readOnly = false,
  } = props;

  const internalValue = value ? value : [""];

  const getInputPropsByIndex = (index: number) => {
    return {
      value: value?.[index] || "",
      onChange: onChange
        ? (inputValue?: string) => {
            const newValue = [...internalValue];
            newValue[index] = inputValue || "";
            onChange(newValue);
          }
        : undefined,
      placeholder,
    };
  };

  const getDeleteItemHandler = (index: number) =>
    onChange
      ? () => {
          const newValue = [...internalValue];
          newValue.splice(index, 1);
          onChange(newValue);
        }
      : undefined;

  const addItem = onChange
    ? () => {
        const newValue = [...internalValue, ""];
        onChange(newValue);
      }
    : undefined;

  return (
    <Field>
      <StyledControlContainer>
        {internalValue.map((_, index) => {
          const props = getInputPropsByIndex(index);
          if (index !== 0) {
            return (
              <StyledInputWithDeleteContainer>
                <Field isBlock={true}>
                  {label ? (
                    <Field.Label disabled={disabled} readOnly={readOnly}>
                      {label}
                    </Field.Label>
                  ) : (
                    <></>
                  )}
                  <StyledInput {...props} readOnly={readOnly} />
                  {description ? (
                    <Field.FieldDescription>
                      {description}
                    </Field.FieldDescription>
                  ) : (
                    <></>
                  )}
                </Field>
                <IconButton
                  size="l"
                  icon="delete"
                  onClick={getDeleteItemHandler(index)}
                  color="gray"
                  variant="tertiary"
                  isDisabled={readOnly}
                />
              </StyledInputWithDeleteContainer>
            );
          }
          return (
            <Field isBlock={true}>
              {label ? (
                <Field.Label disabled={disabled} readOnly={readOnly}>
                  {label}
                </Field.Label>
              ) : (
                <></>
              )}
              <StyledInput {...props} readOnly={readOnly} />
              {description ? (
                <Field.FieldDescription>{description}</Field.FieldDescription>
              ) : (
                <></>
              )}
            </Field>
          );
        })}
      </StyledControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      <StyledAddButton
        theme="secondary"
        color="gray"
        onClick={addItem}
        isDisabled={readOnly}
      >
        <StyledIcon size={16} name="add" />
        {addButtonText}
      </StyledAddButton>
    </Field>
  );
};
