import { MonitoringQualityFamiliarize } from "@/components/MonitoringQuality/MonitoringQualityFamiliarize";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MonitoringQualityForDealers: React.FC = () => {
  const params = useParams();

  const { t } = useTranslation();

  return (
    <DashboardLayout
      title={t("monitoring.programIntro") || ""}
      toBack={-1}
    >
      <MonitoringQualityFamiliarize id={params.id || ""} />
    </DashboardLayout>
  );
};
