import {
  useExportKiaMonitoringQualityCars,
  useNotifyMonitoringQualityDealers,
  useNotifyMonitoringQualityDealersAddingCars,
  useNotifyNotFamiliarizedDealers,
  usePatchMonitoringQualityCloseSearch,
  usePatchMonitoringQualityCloseStudy,
  usePatchOpenMonitoring,
} from "@/helpers/api/monitoringQuality/hooks";
import { TGetMonitoringQualityResponse } from "@/helpers/api/monitoringQuality/types";
import { formatDateToPeriod, handleFileLoad } from "@/helpers/utils";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useModal } from "@/hooks/useModal";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { MenuProps } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MonitoringModal } from "../MonitoringModal";
import { StyledTable } from "./index.styles";
import { MonitoringTableExpandDataForm } from "./MonitoringTableExpandDataForm";
import {
  generateMenuItemsForClosedStatus,
  generateMenuItemsForDealers,
  generateMenuItemsForOpenedStatus,
  generateMenuItemsForSearchingStatus,
  QualityMonitoringStatusEnum,
} from "./utils";
import { useTranslation } from "react-i18next";
import { usePermission } from "@/hooks/usePermission";

export interface IMockMonitoring {
  id: string;
  number: string;
  title: string;
  models: string;
  catalogNumber: string;
  detailsTitle: string;
  period: string;
  dateLastCase: string;
  quality: number;
  status: string;
}

export interface IMonitoringTable {
  monitoringQualityList?: TGetMonitoringQualityResponse["data"];
  onSort?: (accessor: HeaderArgType, order: SortOrder) => void;
  updateList: () => void;
}

export const MonitoringTable: React.FC<IMonitoringTable> = ({
                                                              monitoringQualityList,
                                                              onSort,
                                                              updateList,
                                                            }) => {
  const navigate = useNavigate();
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { hasAccess } = usePermission(
    "quality-monitoring.request-cars",
    "create"
  );

  const { t } = useTranslation();
  const clickHandler = (id: string) => {
    navigate(`/monitoring-quality/${id}/edit`);
  };

  const {
    modalState: notificationModalState,
    openModal: openNotificationModalState,
    closeModal,
  } = useModal();

  const openModal = openNotificationModalState as (state: any) => void;

  const checkStatus = (status: string) => {
    switch (status) {
      case QualityMonitoringStatusEnum.OPEN:
        return t("monitoring.open") || "";
      case QualityMonitoringStatusEnum.SEARCH_COMPLETED:
        return t("monitoring.search") || "";
      case QualityMonitoringStatusEnum.CLOSED:
        return t("monitoring.close") || "";
      default:
        return "";
    }
  };

  const { mutateAsync: closeSearchAsync } =
    usePatchMonitoringQualityCloseSearch();
  const { mutateAsync: closeStudyAsync } =
    usePatchMonitoringQualityCloseStudy();
  const { mutateAsync: notifyDealers } = useNotifyMonitoringQualityDealers();
  const { mutateAsync: notifyDealersAddingCars } =
    useNotifyMonitoringQualityDealersAddingCars();

  const { mutateAsync: notifyNotFamiliarizedDealers } =
    useNotifyNotFamiliarizedDealers();
  const { mutate: exportCsv } = useExportKiaMonitoringQualityCars();

  const { mutateAsync: openMonitoring } = usePatchOpenMonitoring();

  const handleMonitoringQualityCarsExport = (id: string) => {
    exportCsv(
      { qualityMonitoringIds: [id] },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "monitoring-quality-cars.xlsx");
        },
      }
    );
  };

  const handleMenuClick: MenuProps["onClick"] = async ({ key }) => {
    if (typeof key === "string") {
      const id = key.split(",")[1];
      switch (key.split(",")[0]) {
        case `notifyUnfamiliar`:
          openModal({
            textMessage: t("monitoring.notifyUnfamiliar.textMessage") || "",
            successMessage:
              t("monitoring.notifyUnfamiliar.successMessage") || "",
            errorMessage: t("monitoring.notifyUnfamiliar.errorMessage") || "",
            onAsyncSubmit: () => {
              notifyNotFamiliarizedDealers({ id });
              updateList();
            },
            onCloseModal: () => closeModal(),
          });
          break;
        case `notifyAboutAddCars`:
          openModal({
            textMessage: t("monitoring.notifyAboutAddCars.textMessage") || "",
            successMessage:
              t("monitoring.notifyAboutAddCars.successMessage") || "",
            errorMessage: t("monitoring.notifyAboutAddCars.errorMessage") || "",
            onAsyncSubmit: () => {
              notifyDealersAddingCars({ id });
              updateList();
            },
            onCloseModal: () => closeModal(),
          });
          break;
        case `notifyDealers`:
          openModal({
            textMessage: t("monitoring.notifyDealers.textMessage") || "",
            successMessage: t("monitoring.notifyDealers.successMessage") || "",
            errorMessage: t("monitoring.notifyDealers.errorMessage") || "",
            onAsyncSubmit: () => {
              notifyDealers({ id });
              updateList();
            },
            onCloseModal: () => closeModal(),
          });
          break;
        case `closeSearch`:
          openModal({
            textMessage: t("monitoring.closeSearch.textMessage") || "",
            successMessage: t("monitoring.closeSearch.successMessage") || "",
            errorMessage: t("monitoring.closeSearch.errorMessage") || "",
            onAsyncSubmit: () => {
              closeSearchAsync(id);
              updateList();
            },
            onCloseModal: () => closeModal(),
          });
          break;
        case "closeProgram":
          openModal({
            textMessage: t("monitoring.closeProgram.textMessage") || "",
            successMessage: t("monitoring.closeProgram.successMessage") || "",
            errorMessage: t("monitoring.closeProgram.errorMessage") || "",
            onAsyncSubmit: () => {
              closeStudyAsync(id);
              updateList();
            },
            onCloseModal: () => closeModal(),
          });
          break;
        case "exportExcell":
          handleMonitoringQualityCarsExport(id);
          break;
        case "openMonitoring":
          await openMonitoring({ id });
          navigate(`/monitoring-quality/${id}/edit`);
          break;
        default:
          navigate(`/monitoring-quality/${key}`);
          break;
      }
    }
  };

  const generateProps = (
    quality: any,
    permission: boolean,
    profileType?: string
  ) => {
    const { id, status } = quality;
    if (profileType === "kia") {
      return status === QualityMonitoringStatusEnum.OPEN
        ? generateMenuItemsForOpenedStatus(id)
        : status === QualityMonitoringStatusEnum.SEARCH_COMPLETED
          ? generateMenuItemsForSearchingStatus(id)
          : generateMenuItemsForClosedStatus(id);
    } else {
      return generateMenuItemsForDealers(id, status, permission);
    }
  };

  const monitoringQuality = monitoringQualityList?.map((quality) => {
    const {
      parts,
      models,
      status,
      dateOfTheStudyFrom,
      dateOfTheStudyTo,
      addingLastCarDate,
      submittedCarsCount,
      ...restFields
    } = quality;
    return {
      ...restFields,
      "study-completion-date": formatDateToPeriod(
        dateOfTheStudyFrom,
        dateOfTheStudyTo
      ),
      "adding-last-car-date": addingLastCarDate
        ? new Date(addingLastCarDate).toLocaleDateString()
        : "",
      "submitted-cars": submittedCarsCount,
      model: models.map((el) => el.name).join(", "),
      catalogueNumber: parts.map((el) => el.catalogueNumber).join(", "),
      partName: parts.map((el) => el.name).join(", "),
      status: checkStatus(status),
      dropdownProps: generateProps(quality, hasAccess, profile?.type),
    };
  });

  const dealerHeaders = [
    {
      Header: "№",
      accessor: "number",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.name",
      accessor: "title",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.model",
      accessor: "model",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: "monitoring.catalogueNumber",
      accessor: "catalogueNumber",
      minWidth: 70,
      maxWidth: 100,
    },
    {
      Header: "monitoring.partName",
      accessor: "partName",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: "monitoring.study-completion-date",
      accessor: "study-completion-date",
      isSortable: true,
      minWidth: 50,
      maxWidth: 110,
    },
    {
      Header: "monitoring.adding-last-car-date",
      accessor: "adding-last-car-date",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.submitted-cars",
      accessor: "submitted-cars",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: "monitoring.status",
      accessor: "status",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: "Статус ознакомления",
      accessor: "isDealerFamiliarized",
      minWidth: 130,
      maxWidth: 140,
      Cell: ({ row: { original } }) => {
        const isFamiliarized = original.isDealerFamiliarized;
        const label = isFamiliarized ? t("monitoring.familiarized") : t("monitoring.notFamiliarized")
        const style = isFamiliarized ? {
            background: "#B5D880", color: "#2F4A04",
          } :
          {
            background: "#D88080", color: "#4A0404",
          }
        return (
          <span
            style={{
              padding: "4px 8px",
              background: style.background,
              borderRadius: "4px",
              textAlign: "center",
              color: style.color,
              width: '110px',
            }}
          >
            {label}
          </span>
        )
      }
    },
  ];

  const kiaHeaders = [
    {
      Header: "№",
      accessor: "number",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.name",
      accessor: "title",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.model",
      accessor: "model",
      isSortable: true,
      minWidth: 50,
      maxWidth: 150,
    },
    {
      Header: "monitoring.catalogueNumber",
      accessor: "catalogueNumber",
      minWidth: 80,
      maxWidth: 150,
    },
    {
      Header: "monitoring.partName",
      accessor: "partName",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: "monitoring.study-completion-date",
      accessor: "study-completion-date",
      isSortable: true,
      minWidth: 50,
      maxWidth: 110,
    },
    {
      Header: "monitoring.adding-last-car-date",
      accessor: "adding-last-car-date",
      isSortable: true,
      minWidth: 50,
      maxWidth: 120,
    },
    {
      Header: "monitoring.submitted-cars",
      accessor: "submitted-cars",
      isSortable: true,
      minWidth: 80,
      maxWidth: 150,
    },
    {
      Header: "monitoring.status",
      accessor: "status",
      isSortable: true,
      minWidth: 50,
      maxWidth: 100,
    },
  ];

  const isKia = profile?.type === 'kia';
  return (
    <>
      <StyledTable
        headers={isKia ? kiaHeaders : dealerHeaders}
        data={monitoringQuality ?? []}
        renderElement={(row) => {
          return (
            <MonitoringTableExpandDataForm monitoringId={row.original.id}/>
          );
        }}
        buttonIcons={["vertical-dots"]}
        isButtonDropdown
        dropdownProps={{
          handleMenuClick,
        }}
        alignCell="start"
        alignHeader="start"
        onSort={onSort}
        onButtonClick={() => clickHandler}
        autoOverflow
      />
      <MonitoringModal {...notificationModalState} />
    </>
  );
};
