import { DowntimeModal } from "@/components/KiaFlex/DowntimeGuide/DowntimeGuideModal";
import { FlexDowntimeFuideTable } from "@/components/KiaFlex/DowntimeGuide/DowntimeGuideTable";
import {
  useCreateIdle,
  useExportIdleList,
  useGetIdleList,
} from "@/helpers/api/flex/downtime/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Space } from "antd";
import React, { useCallback } from "react";
import { StyledButton, StyledSpace } from "./index.styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Loader } from "@/packages/loader/Loader";

const _KiaFlexIdleCatalog: React.FC = () => {
  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const { mutateAsync: createIdle } = useCreateIdle();
  const { data: idlesResponse, isLoading } = useGetIdleList({});

  const { mutateAsync: exportIdleList } = useExportIdleList();

  const idles = idlesResponse?.data.data;
  const onSubmitHandler = useCallback(
    async (values) => {
      try {
        await createIdle(values);
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessDirectory") || ""
        );
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(
          t("kiaFlex.createNotificationErrorDirectory") || ""
        );
      }
    },
    [createIdle, createNotificationError, createNotificationSuccess]
  );

  const {
    modalState: createDowntimeState,
    openModal: openCreateDowntimeModal,
  } = useModal<any>((close) => {
    return async (payload) => {
      await onSubmitHandler(payload);
      close();
    };
  });

  const handleIdleListExport = () => {
    exportIdleList(
      {},
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "flex-idle-list.xlsx");
        },
      }
    );
  };

  return (
    <DashboardLayout
      title={t("kiaFlex.subTitle_3") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="left">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleIdleListExport}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
        </Space>
      }
    >
      <StyledSpace direction="vertical" size={64}>
        {!isLoading ? (
          idles ? (
            <FlexDowntimeFuideTable idles={idles} />
          ) : (
            <></>
          )
        ) : (
          <Loader size={48} />
        )}
        <StyledButton
          theme="primary"
          color="brand"
          size={"middle"}
          onClick={openCreateDowntimeModal}
        >
          {t("kiaFlex.addDowntime") || ""}
        </StyledButton>
      </StyledSpace>

      <DowntimeModal {...createDowntimeState} />
    </DashboardLayout>
  );
};
export const KiaFlexIdleCatalog = React.memo(_KiaFlexIdleCatalog);
