import { useQuery } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import { TGetCitiesRequest, TGetCitiesResponse } from "./types";

export const useGetCities = (request?: TGetCitiesRequest) => {
  return useQuery(["cities", "list", request], () => {
    return api.get<TGetCitiesResponse>("/cities", {
      params: { ...request },
    });
  });
};
