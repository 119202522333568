import { requiredSelectSimpleValueSchema } from "@/helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { TApplicationRecord } from "@/helpers/api/applications/types";
import { TFileRecord } from "@/helpers/api/files/types";
import { t } from "i18next";

type Nullable<T> = T | null;
export interface IApplicationsFormValues {
  startEvent: Nullable<Date>;
  endEvent: Nullable<Date>;
  vehicleId: Nullable<string>;
  appointment: Nullable<string>;
  mediaId: string;
  statusId: Nullable<string>;
  requestPublicationLink: Nullable<string>;
  comment: Nullable<string>;
  receivingUser: Nullable<string>;
  fileId: TFileRecord | null;
}

export const DEFAULT_APPLICATIONS_FORM_VALUES: IApplicationsFormValues = {
  startEvent: null,
  endEvent: null,
  vehicleId: null,
  appointment: null,
  mediaId: " ",
  statusId: null,
  requestPublicationLink: null,
  comment: null,
  receivingUser: null,
  fileId: null,
};

export const formSchema = () => {
  return yup.object({
    startEvent: yup
      .date()
      .nullable()
      .required(t("park.testRequired") || ""),
    endEvent: yup
      .date()
      .nullable()
      .required(t("park.testRequiredEnd") || ""),
    vehicleId: requiredSelectSimpleValueSchema(t("kiaFlex.autoRequired") || ""),
    appointment: requiredSelectSimpleValueSchema(t("park.pathRequired") || ""),
    statusId: requiredSelectSimpleValueSchema(t("park.statusRequired") || ""),
  });
};

export type TOnSubmitApplicationsFormCb = (
  values: IApplicationsFormValues
) => void;

export const useApplicationsForm = (
  onSubmit: TOnSubmitApplicationsFormCb,
  defaultValues?: TApplicationRecord
) => {
  const form = useForm<IApplicationsFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: defaultValues
      ? {
          startEvent: new Date(defaultValues.startEvent),
          endEvent: new Date(defaultValues.endEvent),
          vehicleId: defaultValues.vehicle?.id,
          appointment: defaultValues.appointment,
          mediaId: defaultValues.media?.id || " ",
          statusId: defaultValues.status?.id,
          requestPublicationLink: defaultValues.requestPublication?.link || "",
          comment: defaultValues.comment,
          receivingUser: defaultValues.receivingUser,
          // @ts-ignore
          fileId: defaultValues.requestPublication?.file,
        }
      : DEFAULT_APPLICATIONS_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
  };
};
