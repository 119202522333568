import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import {
  DppoViolations,
  RequirementWithViolationDto,
  TEditWarrantyViolationsRequest,
} from "@/helpers/api/warranty-audit/violations/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { colors } from "@/helpers";
import { StyledInfoBlock, StyledMessage, StyledSelectField, } from "./index.styles";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Checkbox } from "@/packages/checkbox/Checkbox";
import { useState } from "react";
import { t } from "i18next";
import { Badge } from "antd";
import { TTemplateViolations } from "@/helpers/api/warranty-audit/templates/types";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { TPatchRequirementsRequest } from "@/helpers/api/warranty-audit/fill/types";

const MOCK_STATUS_VALUES = [
  { label: "Принято", value: "accepted" },
  { label: "В работе у дилера", value: "in_work" },
  { label: "Проверить", value: "review" },
  { label: "Отклонено", value: "declined" },
];

const statusToText = {
  accepted: { color: "#2F4A04", background: "#B5D880", message: "Принято" },
  review: {
    background: "#7B7D81",
    color: "#DBDCE0",
    message: "Комментарий отправлен на рассмотрение в Киа",
  },
  in_work: {
    color: "#B06E0A",
    background: "#F5DA69",
    hasIcon: true,
    message: "Выполнить задачу",
  },
  declined: {
    color: "#B72B1A",
    background: "#FCECEA",
    hasIcon: true,
    message: "Отклонено",
  },
};

export const TaskStatus: React.FC<{ status: string }> = (props) => {
  const { status } = props;
  return (
    <StyledInfoBlock>
      <StyledMessage
        background={statusToText[status].background}
        color={statusToText[status].color}
      >
        {statusToText[status].message}
      </StyledMessage>
      {statusToText[status]?.hasIcon ? (
        <Icon name="info" color="yellowStatusBg" size={16}/>
      ) : (
        <></>
      )}
    </StyledInfoBlock>
  );
};

export const GetDppoHeaders = <TRecord extends object>(options: {
  isDealerKia: boolean;
  openChat: (params: IMessageParams, violation: DppoViolations) => void;
  onSetTaskStatus: (
    id: string,
    request: TEditWarrantyViolationsRequest
  ) => void;
}): IHeaderItem<DppoViolations>[] => {
  const { isDealerKia, openChat, onSetTaskStatus } = options;

  return [
    {
      Header: "Код деакцепта",
      accessor: "answer.param.code",
      minWidth: 60,
      maxWidth: 60,
      width: 60,
    },
    {
      Header: t("warrantyAudits.templates.violation") || "",
      accessor: "violation",
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      Cell: ({ row: { original } }) => {
        return `${original?.answer?.param?.templateViolation?.code ?? ''}`;
      },
    },
    {
      Header: t("warrantyAudits.list.param") || "",
      accessor: "answer.param.title",
    },
    {
      Header: t("audits.templates.status_2") || "",
      accessor: "status",
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      Cell: ({ row: { original }, value }) => {
        // "in_work" | "accepted" | "review" | "declined";
        let color;
        switch (value) {
          case "review": {
            color = colors.yellow;

            break;
          }
          case "in_work": {
            color = colors.gray100;

            break;
          }
          case "accepted": {
            color = colors.green100;

            break;
          }
          case "declined": {
            color = colors.red;
          }
        }

        return isDealerKia ? (
          <StyledSelectField
            options={MOCK_STATUS_VALUES.filter((item) => !!item.value)}
            value={MOCK_STATUS_VALUES.find(
              (item) => item.value === value?.toString()
            )}
            defaultValue={original?.status || null}
            onChange={(value, option) => {
              if (!Array.isArray(option)) {
                // @ts-ignore
                onSetTaskStatus(original.id, { status: value || "in_work" });
              }
            }}
            color={color}
          />
        ) : (
          <TaskStatus status={original.status}/>
        );
      },
    },
    {
      Header: "",
      accessor: "button",
      minWidth: 25,
      maxWidth: 25,
      width: 25,
      Cell: ({ row: { original } }) => {
        const unreadCount = original.answer.unreadMessagesAmount;
        const allCount = original.answer.allMessagesAmount;
        return (
          <Badge color={unreadCount === 0 ? '#808080' : undefined} count={unreadCount > 0 ? unreadCount : allCount}>
            <IconButton
              icon="chat"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => openChat({ answerId: original?.answer?.id }, original)}
            />
          </Badge>
        )
      },
    },
  ];
};

export const getWarrantyDocsTableHeaders = <TRecord extends object>(options: {
  onWithdrawal: (requirementId: string, flaq: boolean) => void;
  violationTemplates: TTemplateViolations | undefined;
  onEditRequirement: ({
                        id,
                        request,
                      }: {
    id: string;
    request: TPatchRequirementsRequest;
  }) => void;
}): IHeaderItem<RequirementWithViolationDto>[] => {
  const { onWithdrawal, violationTemplates, onEditRequirement } = options;

  const violationOptions = violationTemplates ?
    violationTemplates.map((el) => ({
      label: `${el.code}`,
      value: el.id,
    }))
    : [];

  return [
    {
      Header: "№",
      accessor: "id",
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      Cell: ({ row }) => {
        // @ts-ignore
        return +row.id + 1;
      },
    },
    {
      Header: t("warrantyAudits.templates.violation") || "",
      accessor: "violation",
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      Cell: ({ row: { original } }) => {
        return (<SelectField
          options={violationOptions}
          value={violationOptions.find((item) => item.value === original.templateViolation?.id)}
          defaultValue={violationOptions.find((item) => item.value === original.templateViolation?.id) || null}
          onChange={(value) => onEditRequirement({
            id: original.id,
            request: {
              templateViolationId: value,
            },
          })}
        />);
      },
    },
    {
      Header: "VIN",
      accessor: "vin",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
    },
    {
      Header: "RO",
      accessor: "ro",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "CLAIM",
      accessor: "claim",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Parts Amount",
      accessor: "partsAmount",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Labor Amount",
      accessor: "laborAmount",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Sublet Amount",
      accessor: "subletAmount",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Full Amount",
      accessor: "fullAmount",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Parts CB",
      accessor: "partsCB",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Labor CB",
      accessor: "laborCB",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Sublet CB",
      accessor: "subletCB",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "CB Amount (1st)",
      accessor: "amountCB",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ row, value }) => {
        return (
          <Paragraph color="red" size={12} weight={400}>
            {value}₽
          </Paragraph>
        );
      },
    },

    {
      Header: t("warrantyAudits.list.withdrawal") || "",
      accessor: "withdrawal",
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      Cell: ({ row: { original }, value }) => {
        const [withdrawal, setWithdrawal] = useState(value);
        return (
          <Checkbox
            value={withdrawal}
            onChange={(value) => {
              setWithdrawal(value);
              onWithdrawal(original.id, value);
            }}
          />
        );
      },
    },
  ];
};
