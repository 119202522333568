import { DashboardLayout } from "@/layouts/DashboardLayout";
import React, { useEffect } from "react";
import { AuditTotalTable } from "@/components/AuditTotal/AuditTotalTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  useExportAuditTotal,
  useGetAuditTotal,
} from "@/helpers/api/audit-total/hooks";
import { AuditInfo } from "@/components/Audits/FillAuditStages/AuditInfo";
import { useGetAuditById } from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { useAuditCreatePlan } from "@/helpers/api/audit-plan/hooks";
import { IconButton } from "@/packages/icon-button/IconButton";
import { handleFileLoad } from "@/helpers/utils";
import {
  StyledButtons,
  StyledContentTitle,
  StyledSelectField, StyledWrapperButtons,
} from "./index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

const AuditTotal: React.FC = () => {
  const params = useParams();
  const auditId = Number(params.id) as number;
  const navigate = useNavigate();
  const { createNotificationError } = useNotification();
  const {
    data: auditTotal,
    isLoading: isTotalLoading,
    refetch: updateList,
  } = useGetAuditTotal(auditId);

  const {
    data: auditData,
    isLoading: isAuditLoading,
  } = useGetAuditById(auditId);
  const { mutate: createAuditPlan, isLoading: isPlanCreating } =
    useAuditCreatePlan({ auditId });

  const { mutateAsync: exportAuditTotal } = useExportAuditTotal();

  useEffect(() => {
    updateList({});
  }, [auditId]);

  const handleCreatePlan = () => {
    if (auditData?.data.planStatus === "Создание") {
      navigate(`/audit-plan/create/${auditId}`);
    } else if (auditData?.data.planStatus === "Не начат") {
      createAuditPlan({
        onSuccess: (response) => {
          navigate(`/audit-plan/create/${auditId}`);
        },
        onError: (error) => {
          createNotificationError(
            error.response?.data?.message || error.message
          );
        },
      });
    } else {
      navigate(`/audit-plan/${auditId}`);
    }
  };

  const handleExportAuditTotal = () => {
    exportAuditTotal(
      { id: auditId },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "audit-total.xlsx");
        },
      }
    );
  };

  return (
    <>
      <DashboardLayout toBack={-1} hasMobile={true} title="Общая информация по аудиту">
        <StyledContentTitle>
          <StyledSelectField/>
          <StyledButtons size={8}>
            <Tooltip content="Сохранить">
              <IconButton
                icon="save"
                isSquared
                variant="secondary"
                color="gray"
                onClick={handleExportAuditTotal}
              />
            </Tooltip>
          </StyledButtons>
        </StyledContentTitle>

        {isAuditLoading || isTotalLoading ? (
          <Loader size={48}/>
        ) : auditData && auditTotal ? (
          <>
            <AuditInfo
              auditData={auditData?.data}
              renderComponent={() =>
                auditData.data.planStatus === "Не начат" ||
                auditData.data.planStatus === "Создание" ? (
                  <div>
                    <Button
                      size="middle"
                      color="brand"
                      theme="primary"
                      onClick={handleCreatePlan}
                      isLoading={isPlanCreating}
                    >
                      Создать план действий
                    </Button>
                  </div>
                ) : (
                  <StyledWrapperButtons>
                    <Button
                      size="middle"
                      color="brand"
                      theme="primary"
                      onClick={() =>
                        navigate(`/audit-plan/${auditData.data.id}`)
                      }
                    >
                      Смотреть план действий
                    </Button>
                    {
                      auditData?.data.status === 'Закрытый' &&
                      <Button
                        size="middle"
                        color="brand"
                        theme="primary"
                        onClick={() =>
                          navigate(`/audit-list/info/${auditData.data.id}`)
                        }
                      >
                        Просмотр аудита
                      </Button>
                    }
                  </StyledWrapperButtons>
                )
              }
            />
            <AuditTotalTable data={auditTotal?.data?.sections || []}/>
          </>
        ) : (
          <></>
        )}
      </DashboardLayout>
    </>
  );
};

export { AuditTotal };
