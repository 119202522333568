import { MONTHS } from "@/helpers/consts";
import { t } from "i18next";

export enum QuotasFormFields {
  YEAR = "year",
  DEALER_ID = "dealerId",
  MONTH = "month",
  GENERATIONS = "generations",
  MODEL = "generationId",
  AMOUNT = "amount",
  MONTHS = "months",
}

export enum TMonth {
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
}

export const monthOptions = MONTHS.map((item) => ({
  label: t(item) || "",
  value: item,
}));

interface IGeneration {
  [QuotasFormFields.MODEL]: string;
  [QuotasFormFields.AMOUNT]: number;
}
export interface IQuotasFormValues {
  [QuotasFormFields.YEAR]: number;
  [QuotasFormFields.DEALER_ID]: string;
  [QuotasFormFields.MONTHS]: {
    [key: number]: {
      [QuotasFormFields.MODEL]: string;
      [QuotasFormFields.AMOUNT]: number;
    }[];
  };
}
export interface IQuotasSuccessFormValuesExceptions {
  [QuotasFormFields.GENERATIONS]: IGeneration[];
}

export type IQuotasSuccessFormValues = IQuotasFormValues;

export type IQuotasDTOValues = IQuotasFormValues;
