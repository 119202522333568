import {
  allowedTypesFiles,
  allowedTypesImages,
} from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/utils";
import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { Field } from "@/packages/formElements/fields/Field";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Radio } from "@/packages/radio/Radio";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";
import { TitleDivider } from "@/packages/TitleDivider";

import { Col, Row } from "antd";
import React, { useState } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { FlexUpload } from "../../FlexUpload";

import { InsuranceFields } from "../../KiaFlexCar/KiaFlexFormsFields/InsuranceFields";
import {
  ExistCarFormWrapper,
  FieldWrapper,
  InsuranceWrapper,
  StyledInput,
  StyledStatusLabel,
  TitleFieldWrapper,
} from "./index.styles";
import { t } from "i18next";

export interface IExistsCarFieldsProps {
  form: UseFormReturn<any>;
  record?: TFlexOrder & { ID: string };
  mode?: "other" | "create";
  isDisabled: boolean;
}

export enum Insurance {
  CASCO = "_casco",
  OSAGO = "_osago",
}

const kaskoFields = [
  "insurantK",
  "seriesK",
  "insuranceNameK",
  "fromK",
  "toK",
  "openInsuranceK",
];
const osagoFields = [
  "insurantO",
  "seriesO",
  "insuranceNameO",
  "fromO",
  "toO",
  "openInsuranceO",
];

const _RegistriFields: React.FC<IExistsCarFieldsProps> = (props) => {
  const { form, record, isDisabled } = props;

  const [insurance, setInsurance] = useState<Insurance>(Insurance.CASCO);

  const fieldArrayFiles = useFieldArray({
    control: form.control,
    name: "fileIds",
  });

  const fieldArrayPhotos = useFieldArray({
    control: form.control,
    name: "photoIds",
  });

  const [photoIds, fileIds] = form.watch(["photoIds", "fileIds"]);

  const vin = record?.vin;

  const changeForKia = record?.payload;

  return (
    <FieldWrapper>
      <ExistCarFormWrapper>
        <TitleFieldWrapper>
          <Row>
            <Col span={2}>
              <Paragraph size={14}>
                {" "}
                {`${t("kiaFlex.order") || ""} ${record?.ID}`}
              </Paragraph>
            </Col>
            <StatusLabel color="green" content={t("kiaFlex.registration") || ""}/>
          </Row>
          <Row>
            <Paragraph size={12} color="gray400">
              {t("kiaFlex.autoReg") || ""}
            </Paragraph>
          </Row>
          <TitleDivider/>
        </TitleFieldWrapper>

        {changeForKia?.equipment && (
          <StyledStatusLabel size={12}>
            {changeForKia?.equipment}
          </StyledStatusLabel>
        )}
        {changeForKia?.model && (
          <StyledStatusLabel size={12}>{changeForKia?.model}</StyledStatusLabel>
        )}
        {changeForKia?.generation && (
          <StyledStatusLabel size={12}>
            {changeForKia?.generation}
          </StyledStatusLabel>
        )}
        {changeForKia?.modification && (
          <StyledStatusLabel size={12}>
            {changeForKia?.modification}
          </StyledStatusLabel>
        )}
        {changeForKia?.color && (
          <StyledStatusLabel size={12}>{changeForKia?.color}</StyledStatusLabel>
        )}
        {changeForKia?.updatedDeliveryDates &&
          changeForKia?.updatedDeliveryDates.map(it => <StyledStatusLabel size={12}>{it}</StyledStatusLabel>)
        }
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.regData") || ""}</Paragraph>
          </Row>
          <TitleDivider/>
        </TitleFieldWrapper>
        {
          vin &&
          <Row gutter={16}>
            <Col span={12}>
              <FormInputField
                label={'VIN'}
                isDisabled={true}
                value={vin}
                name={'vin'}
              />
            </Col>
          </Row>
        }
        <Row gutter={16}>
          <Col span={12}>
            <Controller
              name={"number"}
              control={form.control}
              render={({
                         field: { ref: _, ...fieldWithoutRef },
                         fieldState: { error },
                       }) => {
                return (
                  <Field>
                    <Field.Label>{t("kiaFlex.regNum") || ""}</Field.Label>

                    <Field.FieldControlContainer>
                      <StyledInput
                        formatChars={{
                          х: /[а-яА-Я]/,
                          "?": "[0-9]",
                          9: "[0-9]",
                        }}
                        {...fieldWithoutRef}
                        mask="х999хх99?"
                      ></StyledInput>
                    </Field.FieldControlContainer>
                    <Field.FieldDescription>
                      {t("kiaFlex.lastNum") || ""}
                    </Field.FieldDescription>
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={12}>
            <FormInputField
              name={"pts"}
              label={t("kiaFlex.pts") || ""}
              description={t("kiaFlex.ptsDescr") || ""}
              isDisabled={isDisabled}
            />
          </Col>
        </Row>

        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.insuranceInfo") || ""}</Paragraph>
          </Row>
          <TitleDivider/>
        </TitleFieldWrapper>

        <Row gutter={16}>
          <Col span={12}>
            <InsuranceWrapper
              active={insurance === Insurance.CASCO}
              onClick={() => setInsurance(Insurance.CASCO)}
            >
              <div>
                <Paragraph size={12}>{t("kiaFlex.casco") || ""}</Paragraph>
                <Paragraph size={12}> {t("kiaFlex.cascoInfo") || ""}</Paragraph>
              </div>
              <Radio value={insurance === Insurance.CASCO}/>
            </InsuranceWrapper>
          </Col>
          <Col span={12}>
            <InsuranceWrapper
              active={insurance === Insurance.OSAGO}
              onClick={() => setInsurance(Insurance.OSAGO)}
            >
              <div>
                <Paragraph size={12}>{t("kiaFlex.osago") || ""}</Paragraph>
                <Paragraph size={12}> {t("kiaFlex.osagoInfo") || ""}</Paragraph>
              </div>
              <Radio value={insurance === Insurance.OSAGO}/>
            </InsuranceWrapper>
          </Col>
        </Row>

        {insurance === Insurance.CASCO && (
          <InsuranceFields
            form={form}
            insuranceFields={kaskoFields}
            isDisabled={isDisabled}
          />
        )}

        {insurance === Insurance.OSAGO && (
          <InsuranceFields
            form={form}
            insuranceFields={osagoFields}
            isDisabled={isDisabled}
          />
        )}
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.autoPhoto") || ""}</Paragraph>
          </Row>
          <TitleDivider/>
        </TitleFieldWrapper>
        <Row>
          <Col>
            <FlexUpload
              data={photoIds}
              allowedTypes={allowedTypesImages}
              description={""}
              fieldsName={"photoIds"}
              fieldArray={fieldArrayPhotos}
              readOnly={false}
              maxCount={4}
              hasRemove={!isDisabled}
            />
          </Col>
          <Col>
            <FlexUpload
              label={t("kiaFlex.files") || ""}
              data={fileIds}
              allowedTypes={allowedTypesFiles}
              description={""}
              fieldsName={"fileIds"}
              fieldArray={fieldArrayFiles}
              readOnly={false}
              maxCount={4}
              hasRemove={!isDisabled}
            />
          </Col>
        </Row>
      </ExistCarFormWrapper>
    </FieldWrapper>
  );
};
export const RegistriFields = React.memo(_RegistriFields);
