import { IFileInfo } from "@/components/AutoPark/AutoParkForm/hooks";
import { TFileRecord } from "@/helpers/api/files/types";
import { TFlexExist } from "@/helpers/api/flex/vehicles/types";
import { useForm } from "@/hooks/useForm";
import { useNotification } from "@/hooks/useNotification";
import { useEffect } from "react";
import {
  transformDTOToValues,
  transformValueToDTO,
} from "../ExistsCarFields/utils";
import { existFormSchema } from "./formSchema";
import {
  ExistCarFieldsName,
  IExistCarFromValues,
  InsuranceKasko,
  InsuranceOsago,
} from "./type";
import { t } from "i18next";

export const DEFAULT_IMAGES: IFileInfo[] = new Array(5).fill({
  value: null,
});

export const DEFAULT_FILES: IFileInfo[] = new Array(5).fill({
  value: null,
});

const DEFAULT_EXIST_CAR_VALUES: IExistCarFromValues = {
  [ExistCarFieldsName.OWNER]: null,
  [ExistCarFieldsName.C2C]: null,
  [ExistCarFieldsName.VIN]: null,
  [ExistCarFieldsName.MATERAL_CODE]: null,
  [ExistCarFieldsName.MODEL]: null,
  [ExistCarFieldsName.GENERATION]: null,
  [ExistCarFieldsName.MODIFICATION]: null,
  [ExistCarFieldsName.EQUIPMENT]: null,
  [ExistCarFieldsName.COLOR]: null,
  [ExistCarFieldsName.EQUIPMENT]: null,
  [ExistCarFieldsName.PTS]: null,
  [ExistCarFieldsName.REGISTER_NUMBER]: null,
  [InsuranceOsago.NAME_INSURANCE]: null,
  [InsuranceKasko.NAME_INSURANCE]: null,
  [InsuranceOsago.POLICY_HOLDER]: null,
  [InsuranceKasko.POLICY_HOLDER]: null,
  [InsuranceOsago.SERIES_AND_NUMBER]: null,
  [InsuranceKasko.SERIES_AND_NUMBER]: null,
  [InsuranceOsago.MANAGMENT_ALL]: null,
  [InsuranceKasko.MANAGMENT_ALL]: null,
  [InsuranceOsago.TERM_INSURANSE_END]: null,
  [InsuranceKasko.TERM_INSURANSE_END]: null,
  [InsuranceOsago.TERM_INSURANSE_START]: null,
  [InsuranceKasko.TERM_INSURANSE_START]: null,
  [ExistCarFieldsName.DEALER_CENTER]: null,
  [ExistCarFieldsName.PHOTO]: DEFAULT_IMAGES,
  [ExistCarFieldsName.FILE]: DEFAULT_FILES,
};

export type TOnSubmitExistCarForm = (values: IExistCarFromValues) => void;

export const useExistCarForm = (props: {
  onSubmit: TOnSubmitExistCarForm;
  record?: TFlexExist;
}) => {
  const { onSubmit, record } = props;

  const { createNotificationError } = useNotification();

  const onSubmitHandler = (values: IExistCarFromValues) => {
    const data = transformValueToDTO(values);

    if (data.insurance.length < 2) {
      createNotificationError(t("kiaFlex.insuranceField") || "");
    } else {
      // @ts-ignore
      onSubmit(data);
    }
  };
  const result = useForm<IExistCarFromValues>({
    defaultValues: DEFAULT_EXIST_CAR_VALUES,
    onSubmit: onSubmitHandler,
    validateFormSchema: existFormSchema(),
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (record) {
      // @ts-ignore

      reset(transformDTOToValues(record));
    }
  }, [record, reset]);

  return result;
};
