import { FC, useEffect, useMemo } from 'react';
import { StyledSelects } from '@/components/MaintenanceGrids/index.styles';
import styled from 'styled-components';
import { useGetModelList } from '@/helpers/api/models/hooks';
import { getAdaptSelectOptions } from '@/helpers/utils';
import { useGetGenerationList } from '@/helpers/api/generations/hooks';
import { useGetEquipmentList } from '@/helpers/api/modifications/hooks';
import { FormSelect } from '@/packages/formElements/formFields/FormSelect';
import { useFormContext } from 'react-hook-form';
import { getModelListFieldFormName } from '../utils';
import { useTranslation } from 'react-i18next';

interface IGridsFormModalForm {
    isDuplicateForm?: boolean;
}

const StyledForm = styled.div``;

const GridsFormModalForm: FC<IGridsFormModalForm> = (props) => {
    const { isDuplicateForm = false } = props;
    const { t } = useTranslation();
    const form = useFormContext();

    const modelName = getModelListFieldFormName('model', isDuplicateForm);
    const generationName = getModelListFieldFormName(
        'generation',
        isDuplicateForm,
    );
    const equipmentName = getModelListFieldFormName(
        'equipments',
        isDuplicateForm,
    );

    const modelId = form.watch(modelName)?.id;
    const generationId = form.watch(generationName)?.id;

    const hasGeneration = Boolean(generationId);
    const hasEquipments = Boolean(form.watch(equipmentName)?.length > 0);

    const { data: models, refetch: refetchModels } = useGetModelList(
        {
            onlyActive: true,
            limit: 0,
        },
        false,
    );
    const { data: generations, refetch: refetchGenerations } =
        useGetGenerationList({
            modelId,
            limit: 0,
        });
    const { data: equipments, refetch: refetchEquipments } = useGetEquipmentList({
        generationId,
        limit: 0,
    });

    const modelOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options: models?.data.data || [],
            }),
        [ models?.data.data ],
    );
    const generationOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options: generations?.data.data || [],
            }),
        [ generations?.data.data ],
    );
    const equipmentOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options:
                    equipments?.data.data.map((equipment) => ({
                        ...equipment,
                        name: equipment.fullName,
                    })) || [],
            }),
        [ equipments?.data.data ],
    );

    const handleModelChange = (_, option) => {
        form.setValue(modelName, option, {
            shouldDirty: true,
            shouldValidate: true,
        });

        if (hasGeneration) form.resetField(generationName);
        if (hasEquipments) form.resetField(equipmentName);
    };

    const handleGenerationChange = (_, option) => {
        form.setValue(generationName, option, {
            shouldDirty: true,
            shouldValidate: true,
        });

        if (hasEquipments) form.resetField(equipmentName);
    };

    const handleEquipmentChange = (_, options) => {
        form.setValue(equipmentName, isDuplicateForm ? [ options ] : options, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    useEffect(() => {
        refetchModels();
    }, []);

    useEffect(() => {
        if (!modelId) return;
        refetchGenerations();
    }, [ modelId ]);

    useEffect(() => {
        if (!generationId) return;
        refetchEquipments();
    }, [ generationId ]);

    return (
        <StyledForm>
            <StyledSelects>
                <FormSelect
                    name={modelName}
                    label={t('maintenanceGrids.newGridForm.nameModel') || ''}
                    options={modelOptions}
                    optionInValue={true}
                    onChange={handleModelChange}
                />

                <FormSelect
                    name={generationName}
                    label={t('maintenanceGrids.newGridForm.generation') || ''}
                    options={generationOptions}
                    optionInValue={true}
                    onChange={handleGenerationChange}
                />
            </StyledSelects>

            <FormSelect
                name={equipmentName}
                label={t('maintenanceGrids.newGridForm.modification') || ''}
                options={equipmentOptions}
                optionInValue={true}
                mode={isDuplicateForm ? undefined : 'multiple'}
                onChange={handleEquipmentChange}
            />
        </StyledForm>
    );
};

export { GridsFormModalForm };
