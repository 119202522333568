import React from "react";

export const useConfirmModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClose = () => {
    closeModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const modalState = {
    isOpen,
    onClose,
  };

  return {
    modalState,
    openModal,
    closeModal,
  };
};
