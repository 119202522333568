import { IModal, Modal } from "@/packages/modal/Modal";
import { useGetUserById } from "@/helpers/api/users/hooks";
import React from "react";
import { Loader } from "@/packages/loader/Loader";
import { Title } from "@/packages/title/Title";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StyledDataWrapper, StyledModalWrapper } from "@/components/UserInfoModal/index.styled";

export interface UserInfoModalProps extends Pick<IModal, "onCancel" | "isOpen"> {
  userId: string;
}

export const UserInfoModal = (props: UserInfoModalProps) => {
  const { userId } = props

  const { data: userData, isLoading } = useGetUserById({ id: userId });

  return (
    <Modal
      {...props}
      title={`Информация о пользователе ${userData?.data.firstName ?? ''} ${userData?.data.lastName ?? ''}`}
      size={'medium'}
      cancelButtonText="Закрыть"
      showConfirmButton={false}
    >
      {
        !isLoading ?
          <StyledModalWrapper>
            <StyledDataWrapper>
              <Title level={5} weight={600}> Имя </Title>
              <Paragraph size={14} weight={300}>{userData?.data.firstName}</Paragraph>
            </StyledDataWrapper>
            <StyledDataWrapper>
              <Title level={5} weight={600}> Фамилия </Title>
              <Paragraph size={14} weight={300}>{userData?.data.lastName}</Paragraph>
            </StyledDataWrapper>
            <StyledDataWrapper>
              <Title level={5} weight={600}> Почта </Title>
              {/*@ts-ignore*/}
              <Paragraph size={14} weight={300}>{userData?.data.email._address}</Paragraph>
            </StyledDataWrapper>
            <StyledDataWrapper>
              <Title level={5} weight={600}> Номер телефона </Title>
              <Paragraph size={14} weight={300}>{userData?.data.phone}</Paragraph>
            </StyledDataWrapper>
            <StyledDataWrapper>
              <Title level={5} weight={600}> Должность </Title>
              <Paragraph size={14} weight={300}>{userData?.data.role.name}</Paragraph>
            </StyledDataWrapper>
          </StyledModalWrapper>
          :
          <Loader size={64}/>
      }
    </Modal>
  )
}