import { TWorksRecord } from "@/helpers/api/maintenanceGrids/works/types";
import { DEFAULT_CREATE_WORKS_FORM_VALUES } from "../hooks";

export const setEditedWorkFormValues = ({
  work,
  formSetter,
}: {
  work: TWorksRecord;
  formSetter: any;
}) => {
  const adaptWorkTypes = work.workTypes.reduce((acc, type) => {
    acc = acc.map((accType) => (accType.type === type.type ? type : accType));
    return acc;
  }, DEFAULT_CREATE_WORKS_FORM_VALUES.workTypes);

  formSetter("name", work.name, { shouldDirty: true });
  formSetter("code", work.code[0], { shouldDirty: true });
  formSetter("reportName", work.reportName, {
    shouldDirty: true,
  });
  formSetter("description", work.description, {
    shouldDirty: true,
  });
  formSetter("isDefault", work.isDefault, {
    shouldDirty: true,
  });
  formSetter("workTypes", adaptWorkTypes, { shouldDirty: true });
};
