import { Icon } from "@/packages/icon/Icon";
import React from "react";
import { ParagraphWithSpace, StyledInfoCard, StyledInfoGrid, StyledMainCard, StyledValues } from './index.styles'
import { t } from "i18next";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { formatDate } from "@/helpers/formatDate";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const MOCK_BLOCKS = [
  {
    title: "Номер визита",
    accessor: "visitNumber",
  },
  {
    title: "Тип аудита",
    accessor: "type",
  },
  {
    title: "Аудитор",
    accessor: "auditor",
  },
  {
    title: "Представитель дилера",
    accessor: "responsible",
  },
  {
    title: "Дата старта",
    accessor: "startedAt",
  },
];

const TypeAudit = {
  "kia": 'Заполняется сотрудником Kia',
  'factory': 'Заполняется сотрудником завода',
  'external auditor': 'Заполняется внешним аудитором',
  'dealer': 'Заполняется дилером',
}

interface StatisticGridProps {
  auditInfo: TGetWarrantyByIdResponse;
}

export const StatisticGrid = (
  { auditInfo }: StatisticGridProps
) => {
  const preparedData = {
    ...auditInfo,
    startedAt: formatDate(new Date(auditInfo.startedAt), 'dateOnly'),
    auditor: `${auditInfo?.auditor?.firstName || ""} ${
      auditInfo?.auditor?.lastName || ""
    } `,
    responsible: `${auditInfo?.responsible?.firstName || ""} ${
      auditInfo?.responsible?.lastName || ""
    } `,
    type: TypeAudit[auditInfo.type],
    visitNumber: auditInfo.visitNumber,
    dealerName: auditInfo.dealer.merchantName,
    mobisCode: auditInfo.dealer.mobisCode,
    warrantyCode: auditInfo.dealer.warrantyCode,
  };

  return (
    <StyledInfoGrid>
      <StyledMainCard>
        <Icon size={64} color="yellow" name="home"/>
        <div>
          <ParagraphWithSpace space={12} size={14} weight={300}>
            {t("warrantyAudits.list.dealer") || ""}
          </ParagraphWithSpace>
          <ParagraphWithSpace space={12} size={16} weight={400}>
            {preparedData.dealerName}
          </ParagraphWithSpace>
          <StyledValues>
            <Paragraph size={14} weight={300}>
              {t("audits.list.code") || ""}
            </Paragraph>
            <Paragraph size={14} weight={400}>
              {preparedData.mobisCode}
            </Paragraph>
          </StyledValues>
          <StyledValues>
            <Paragraph size={14} weight={300}>
              {t("audits.list.warrantyCode") || ""}
            </Paragraph>
            <Paragraph size={14} weight={400}>
              {preparedData.warrantyCode}
            </Paragraph>
          </StyledValues>
        </div>
      </StyledMainCard>
      {MOCK_BLOCKS.map((item, index) => (
        <StyledInfoCard key={index}>
          <ParagraphWithSpace space={8} size={14} weight={300}>
            {item.title}
          </ParagraphWithSpace>
          <ParagraphWithSpace space={0} size={14} weight={400}>
            {preparedData[item.accessor]}
          </ParagraphWithSpace>
        </StyledInfoCard>
      ))}
    </StyledInfoGrid>
  )
}