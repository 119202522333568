import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

type AnswerProviderProps = { children: ReactNode }

export type Answer = {
  id: string;
  isActive: boolean;
}

export type AnswerProviderState = Answer[];
export type AnswerProviderDispatch = Dispatch<SetStateAction<Answer[]>>

const Context = createContext<{ state: AnswerProviderState; dispatch: AnswerProviderDispatch } | undefined>(undefined)

export function useAnswerProvider() {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error('useAnswerProvider must be used within a AnswerProvider')
  }
  return context
}

export function AnswerProvider({ children }: AnswerProviderProps) {
  const [savedAnswers, setSavedAnswers] = useState<Answer[]>([]);
  return (
    <Context.Provider value={{ state: savedAnswers, dispatch: setSavedAnswers }}>
      {children}
    </Context.Provider>
  )
}