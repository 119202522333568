import { Checkbox } from "@/packages/checkbox/Checkbox";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { Icon } from "@/packages/icon/Icon";
import React, { useCallback, useEffect, useState } from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  HeaderPropGetter,
  Row,
  useFlexLayout,
  useRowSelect,
  useTable,
} from "react-table";
import { Dropdown, IDropdownProps } from "../dropdown/Dropdown";
import { IconButton } from "../icon-button/IconButton";
import {
  StyledCell,
  StyledCreateCell,
  StyledFooterCellContent,
  StyledHeaderCell,
  StyledHeaderCellContent,
  StyledRow,
  StyledSearchButton,
  StyledSortIcon,
  StyledTable,
  WrappedButtons,
} from "./TableSimple.style";
import { useSticky } from "./sticky";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";

export interface IDataItem {
  [key: string]: any;
}

export interface IHeaderItem<T extends object> {
  Header: Column<T>["Header"] | ((props: Column<T>) => React.ReactNode);
  accessor?: string;
  columns?: IHeaderItem<T>[];
  subHeaders?: IHeaderItem<T>[];
  id?: Column<T>["id"];
  Cell?: (arg: { row: { original: T }; value: any }) => React.ReactNode;
  isSortable?: boolean;
  sortType?: "ASC" | "DESC" | null;
  isSearchable?: boolean;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  isHeaderHidden?: boolean;
  border?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  Footer?: Column<T>["Footer"] | ((props: Column<T>) => React.ReactNode);
  isFooterHidden?: boolean;
  sticky?: "left" | "right";
}

export interface IDefaultColumn {
  minWidth: number;
  width?: number | string;
  maxWidth: number;
}

export type SortOrder = null | "asc" | "desc";

export type HeaderArgType = HeaderGroup & IColumn;

export interface IHeaderParams {
  color?: "gray30" | "white";
  size?: "s" | "m";
  hasTopBorderRadius?: boolean;
}

export interface ITableSimple<T extends object = object> {
  withOverflow?: boolean;
  autoOverflow?: boolean;
  headers: IHeaderItem<T>[];
  data: T[];
  buttonIcons?: Array<string>;
  isButtonDropdown?: boolean;
  isCloseOnRefresh?: boolean;
  dropdownProps?: IDropdownProps | any;
  defaultColumn?: IDefaultColumn;
  columnWidth?: number;
  onSort?: (header: HeaderArgType, order: SortOrder) => void;
  onButtonClick?: (button: string, value: IDataItem) => void;
  headerParams?: IHeaderParams;
  showCheckbox?: boolean;
  onRowsChecked?: (ids: string[]) => void;
  onCellClick?: (cell: { rowIndex: number; accessor: string }) => void;
  onSearch?: (key: string, value?: string) => void;
  initialSearch?: string;
  renderElement?: (row: Row<IDataItem>) => React.ReactNode;
  className?: string;
  getRowId?: (record: T) => string;
  alignCell?: "start" | "center";
  alignHeader?: string;
  rowSpan?: number;
  enableRowSpan?: boolean;
  CreateRow?: () => JSX.Element;
  rowSpace?: number;
  isStickyRow?: boolean;
  isCutVerticalPadding?: boolean;
}

export interface IColumn {
  isSortable?: boolean;
  isSearchable?: boolean;
  isFixedWidth?: number;
  isHeaderHiddne?: boolean;
}

export const DEFAULT_COLUMN: IDefaultColumn = {
  minWidth: 150,
  width: 250,
  maxWidth: 350,
};

export const BUTTON_COLUMN: IDefaultColumn = {
  minWidth: 56,
  width: "fit-content",
  maxWidth: 350,
};
export const OPEN_BUTTON_COLUMN: IDefaultColumn = {
  minWidth: 60,
  width: "fit-content",
  maxWidth: 60,
};

export const CHECKBOX_COLUMN: IDefaultColumn = {
  minWidth: 78,
  width: 78,
  maxWidth: 78,
};

export const findFooter = (header: IHeaderItem<any>) => {
  if (header.Footer) {
    return true;
  } else if (header?.columns) {
    return header.columns.find((column) => findFooter(column));
  } else {
    return false;
  }
};

export const CustomCheckbox = (elProps) => {
  const { label, checked, onChange } = elProps;

  const handleChange = (value: boolean) => {
    onChange({ target: { checked: value } });
  };

  return (
    <Checkbox
      label={label}
      color={elProps.isGray ? "gray" : "white"}
      name="selectTable"
      value={checked}
      onChange={handleChange}
    />
  );
};

const TableSimple = <T extends object>(props: ITableSimple<T>) => {
  const {
    withOverflow = true,
    autoOverflow = false,
    headerParams,
    headers,
    data,
    buttonIcons,
    isButtonDropdown,
    isCloseOnRefresh,
    dropdownProps,
    defaultColumn = DEFAULT_COLUMN,
    showCheckbox,
    onSort,
    onButtonClick,
    onRowsChecked,
    onCellClick,
    onSearch,
    renderElement,
    className,
    getRowId,
    rowSpace,
    alignHeader = "flex-start",
    alignCell = "flex-start",
    CreateRow,
    initialSearch,
    isStickyRow,
    isCutVerticalPadding,
  } = props;
  const [openRows, setOpenRows] = useState<Array<number>>([]);

  const columns = headers;
  const tableData = data;
  const tableDefaultColumn = defaultColumn;

  const { t } = useTranslation();

  const handleOpenRow = (rowIndex: number) => {
    setOpenRows((prev) =>
      prev.includes(rowIndex)
        ? prev.filter((item) => item !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds },
  } = useTable<T>(
    {
      defaultColumn: tableDefaultColumn,
      // @ts-ignore
      columns,
      data: tableData,
      getRowId,
    },
    useRowSelect,
    useFlexLayout,
    useSticky,
    (hooks) => {
      if (showCheckbox) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",

            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CustomCheckbox
                isGray={headerParams?.color === "gray30"}
                {...getToggleAllRowsSelectedProps()}
              />
            ),

            Cell: ({ row }) => (
              <CustomCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]);
      }
      if (renderElement) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "open-button",
            Header: () => <></>,
            Cell: () => <></>,
          },
        ]);
      }
      if (buttonIcons?.length) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "button-icon",
            Header: () => <></>,
            Cell: ({ row }) => {
              return (
                <WrappedButtons>
                  {buttonIcons.map((item, index) => {
                    if (item === "vertical-dots" && isButtonDropdown) {
                      return (
                        <Dropdown
                          key={index}
                          {...dropdownProps}
                          items={row.original.dropdownProps}
                        />
                      );
                    } else {
                      return (
                        <IconButton
                          key={index}
                          onClick={() =>
                            onButtonClick && onButtonClick(item, row.original)
                          }
                          icon={item}
                          variant="tertiary"
                          color="black"
                          size={"l"}
                        />
                      );
                    }
                  })}
                </WrappedButtons>
              );
            },
          },
        ]);
      }
    }
  );

  const [sortOrders, setSortOrders] = useState<SortOrder[]>(
    headers.map(() => null)
  );

  const handleHeaderClick = useCallback(
    (header: HeaderArgType, index: number) => {
      if (header.isSortable) {
        const sortOrder = sortOrders[index];
        let newSortOrder: SortOrder = null;
        if (sortOrder === null) newSortOrder = "asc";
        if (sortOrder === "asc") newSortOrder = "desc";
        if (sortOrder === "desc") newSortOrder = null;
        const newSortOrders: SortOrder[] = sortOrders;
        newSortOrders[index] = newSortOrder;
        setSortOrders(newSortOrders);
        if (onSort) onSort(header, newSortOrder);
      }
    },
    [headers, onSort]
  );

  useEffect(() => {
    if (onRowsChecked) {
      const ids = Object.keys(selectedRowIds);
      onRowsChecked(ids);
    }
  }, [selectedRowIds]);

  const [searchedField, setSearchedFiled] = useState<string>();

  const handleCellClick = (cell: Cell) => {
    if (onCellClick) {
      onCellClick({ rowIndex: cell.row.index, accessor: cell.column.id });
    }
  };

  useEffect(() => {
    if (isCloseOnRefresh) {
      setOpenRows([]);
    }
  }, [renderElement]);

  return (
    <StyledTable
      {...getTableProps()}
      className={`${className} table sticky`}
      withOverflow={withOverflow}
      autoOverflow={autoOverflow}
    >
      <div className="header">
        {headerGroups.map((headerGroup, $i) => (
          <div {...headerGroup.getHeaderGroupProps()} key={$i} className="tr">
            {headerGroup.headers
              // @ts-ignore
              .filter((column: HeaderArgType) => !column?.isHeaderHidden)
              // @ts-ignore
              .map((column: HeaderArgType, $$i) => {
                const index = $$i;
                const sortOrder = sortOrders[index];
                const isButtonIcon = column.id === "button-icon";
                const isCheckbox = column.id === "selection";
                const isOpenBtn = column.id === "open-button";

                const headerProps = column.getHeaderProps(
                  column as HeaderPropGetter<IColumn>
                );

                const buttonIconPropsStyle = isButtonIcon ? BUTTON_COLUMN : {};
                const checkboxPropsStyle = isCheckbox ? CHECKBOX_COLUMN : {};
                const openButtonStyle = isOpenBtn ? OPEN_BUTTON_COLUMN : {};

                return (
                  <StyledHeaderCell
                    {...headerProps}
                    style={{
                      ...headerProps.style,
                      ...buttonIconPropsStyle,
                      ...checkboxPropsStyle,
                      ...openButtonStyle,
                    }}
                    alignHeader={alignHeader}
                    key={$$i}
                    className="th"
                    isSortable={!!column.isSortable}
                    isFixedWidth={!!column.isFixedWidth}
                    // @ts-ignore
                    border={column?.border}
                    headerParams={headerParams}
                    onClick={() => handleHeaderClick(column, index)}
                  >
                    <StyledHeaderCellContent>
                      {searchedField === column.id ? (
                        <Search
                          value={initialSearch}
                          onChange={(value) => {
                            onSearch && onSearch(searchedField, value);
                          }}
                          onClear={() => {
                            onSearch && onSearch(searchedField, "");
                            setSearchedFiled("");
                          }}
                        />
                      ) : (
                        <>
                          {column.isSearchable ? (
                            <StyledSearchButton key={$$i}>
                              <IconButton
                                icon="search-16"
                                onClick={() => setSearchedFiled(column.id)}
                                size="m"
                                variant="secondary"
                                isSquared={true}
                                color="black"
                              />
                            </StyledSearchButton>
                          ) : (
                            <></>
                          )}
                          {typeof column.Header === "string"
                            ? t(`${column.render("Header")}`)
                            : column.render("Header")}
                          {column.isSortable && (
                            <StyledSortIcon key={$$i}>
                              <Icon
                                size={8}
                                name="chevron-sort-up-8"
                                color={
                                  sortOrder === "asc" ? "black" : "gray400"
                                }
                              />
                              <Icon
                                size={8}
                                name="chevron-sort-down-8"
                                color={
                                  sortOrder === "desc" ? "black" : "gray400"
                                }
                              />
                            </StyledSortIcon>
                          )}
                        </>
                      )}
                    </StyledHeaderCellContent>
                  </StyledHeaderCell>
                );
              })}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()} className="body">
        {rows.map((row, $i) => {
          prepareRow(row);

          return (
            <>
              <StyledRow
                isSticky={isStickyRow && openRows.includes($i)}
                {...row.getRowProps()}
                key={$i}
                className="tr"
              >
                {row.cells.map((cell, $$i) => {
                  //@ts-ignore
                  const column: ColumnInstance & IColumn = cell.column;
                  const isButtonIcon = column.id === "button-icon";
                  const isOpenBtn = column.id === "open-button";
                  const isCheckbox = column.id === "selection";
                  const buttonIconPropsStyle = isButtonIcon
                    ? BUTTON_COLUMN
                    : {};
                  const checkboxPropsStyle = isCheckbox ? CHECKBOX_COLUMN : {};
                  const openButtonStyle = isOpenBtn ? OPEN_BUTTON_COLUMN : {};

                  const isOpen = openRows.includes(row.index);
                  //@ts-ignore
                  const unreadMessagesAmount = cell.row.original.unreadMessagesAmount;
                  return (
                    <StyledCell
                      isCutVerticalPadding = {isCutVerticalPadding}
                      isArchived={
                        "isArchived" in row?.original &&
                        // @ts-ignore
                        !!row?.original?.isArchived
                      }
                      {...cell.getCellProps()}
                      style={{
                        ...cell.getCellProps().style,
                        ...buttonIconPropsStyle,
                        ...checkboxPropsStyle,
                        ...openButtonStyle,
                      }}
                      key={$$i}
                      className="td"
                      isFixedWidth={!!column.isFixedWidth}
                      alignCell={alignCell}
                      isButton={cell
                        .getCellProps()
                        .key.toString()
                        .includes("button-icon")}
                      /* @ts-ignore */
                      onClick={handleCellClick.bind(null, cell)}
                    >
                      {isOpenBtn ? (
                        unreadMessagesAmount ?
                          <Badge count={unreadMessagesAmount}>
                            <IconButton
                              onClick={() => handleOpenRow(row.index)}
                              icon={isOpen ? "arrow-up" : "arrow-down"}
                              size="s"
                              variant={isOpen ? "primary" : "secondary"}
                              color={isOpen ? "brand" : "black"}
                            />
                          </Badge>
                          :
                          <IconButton
                            onClick={() => handleOpenRow(row.index)}
                            icon={isOpen ? "arrow-up" : "arrow-down"}
                            size="s"
                            variant={isOpen ? "primary" : "secondary"}
                            color={isOpen ? "brand" : "black"}
                          />
                      ) : (
                        cell.render("Cell")
                      )}
                    </StyledCell>
                  );
                })}
              </StyledRow>

              {renderElement && openRows.includes($i) ? (
                /* @ts-ignore */
                <StyledRow>{renderElement(row)}</StyledRow>
              ) : (
                <></>
              )}
            </>
          );
        })}
        {CreateRow && (
          <StyledRow key="create" className="tr" rowSpace={rowSpace}>
            <StyledCreateCell colSpan={3}>{CreateRow()}</StyledCreateCell>
          </StyledRow>
        )}
      </div>

      {headers.find((header) => findFooter(header)) && (
        <div className="footer">
          <div
            {...footerGroups[0].getFooterGroupProps()}
            key="footer-0"
            className="tr"
          >
            {footerGroups[0].headers &&
              footerGroups[0].headers
                // @ts-ignore
                .filter((column: HeaderArgType) => !column?.isFooterHidden)
                // @ts-ignore
                .map((column: HeaderArgType, $$i) => {
                  if (column.Footer) {
                    const footerProps = column.getFooterProps(
                      column as HeaderPropGetter<IColumn>
                    );

                    return (
                      <StyledHeaderCell
                        {...footerProps}
                        key={`${$$i}-footer`}
                        style={{
                          ...footerProps.style,
                        }}
                        border={undefined}
                        className="tf"
                        alignHeader={alignHeader}
                        isSortable={false}
                        isFixedWidth={!!column.isFixedWidth}
                        headerParams={headerParams}
                      >
                        <StyledFooterCellContent>
                          {column.render("Footer")}
                        </StyledFooterCellContent>
                      </StyledHeaderCell>
                    );
                  } else return false;
                })}
          </div>
        </div>
      )}
    </StyledTable>
  );
};

export { TableSimple };
