import { colors } from "@/helpers";
import { TBonusDocument } from "@/helpers/api/bonuses/premium-documents/types";
import { transformDateToDotted } from "@/helpers/date";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/stickyTable/StickyTable";
import { CustomCheckbox } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCommitmentHeader,
  StyledCommitmentInput,
  StyledHeaderParagraph,
  StyledSetCommitmentLink,
} from "./PremiumTableAdapter/index.styles";

export enum Statuses {
  TO_BE_AGREED = "awaiting_for_delivery",
  ACCEPTED = "Требуется подписать и отправить",
}

export const premiumTableStatuses = [
  {
    value: Statuses.TO_BE_AGREED,
    label: "bonuses.calculations.shipmentWait",
    variantBtn: {
      background: `${colors.yellowStatusBg}`,
      color: `${colors.yellowStatus}`,
    },
  },
  {
    value: Statuses.ACCEPTED,
    label: "bonuses.calculations.accepted",
    variantBtn: {
      background: `${colors.greenStatus}`,
      color: `${colors.greenStatusBg}`,
    },
  },
];

export const PremiumTableHeaders = (options: {
  onViewClick: (id: string) => void;
  onUpdateCommitment: (value: string, id?: string) => void;
}): IHeaderItem<TBonusDocument>[] => {
  const { onViewClick, onUpdateCommitment } = options;
  const { t } = useTranslation();

  return [
    {
      Header: ({ getToggleAllRowsSelectedProps }) => {
        return <CustomCheckbox {...getToggleAllRowsSelectedProps()} />;
      },
      accessor: "checkbox",
      width: 25,
      Cell: ({ row }) => {
        //@ts-ignore
        return <CustomCheckbox {...row.getToggleRowSelectedProps()} />;
      },
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {" "}
          {t("bonuses.premium.dealerCode")}
        </StyledHeaderParagraph>
      ),
      accessor: "dealerCode",
      width: 70,
      Cell: ({ row: { original }, value }) => {
        return original?.dealer.mobisCode || "";
      },
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {t("bonuses.premium.dealerName")}
        </StyledHeaderParagraph>
      ),
      accessor: "dealerName",
      width: 120,
      Cell: ({ row: { original }, value }) => {
        return original?.dealer.merchantName || "";
      },
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {" "}
          {t("bonuses.premium.actDate")}
        </StyledHeaderParagraph>
      ),
      accessor: "createdAt",
      width: 70,
      Cell: ({ row: { original }, value }) => {
        return transformDateToDotted(value);
      },
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {" "}
          {t("bonuses.premium.actNumber")}
        </StyledHeaderParagraph>
      ),
      accessor: "actNumber",
      width: 70,
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {t("bonuses.premium.actSum")}
        </StyledHeaderParagraph>
      ),
      accessor: "bonus",
      width: 70,
    },
    {
      Header: (
        <StyledHeaderParagraph size={14}>
          {t("bonuses.premium.diadocStatus")}
        </StyledHeaderParagraph>
      ),
      accessor: "status",
      width: 100,

      Cell: ({ value }) => {
        const status =
          premiumTableStatuses.find((x) => x.value === value) ||
          premiumTableStatuses[0];
        //@ts-ignore
        const label = status.label;
        const variantBtn = status.variantBtn;
        return (
          <span
            style={{
              padding: "4px 8px",
              background: variantBtn.background,
              borderRadius: "4px",
              textAlign: "center",
              color: variantBtn.color,
            }}
          >
            {t(label) || ""}
          </span>
        );
      },
    },
    {
      Header: () => {
        const [isCommitmentInputOpen, setIsCommitmentOpen] = useState(false);
        return isCommitmentInputOpen ? (
          <StyledCommitmentInput
            onChange={(value) =>
              setTimeout(() => onUpdateCommitment(value), 1500)
            }
            placeholder="Text"
            onMouseOut={() => setIsCommitmentOpen(false)}
          />
        ) : (
          <StyledCommitmentHeader onClick={() => setIsCommitmentOpen(true)}>
            <StyledHeaderParagraph size={14}>
              {t("bonuses.premium.commitment.main")}
            </StyledHeaderParagraph>
            <StyledSetCommitmentLink size={14}>
              {t("bonuses.premium.commitment.setCommon")}
            </StyledSetCommitmentLink>
          </StyledCommitmentHeader>
        );
      },
      accessor: "commitment",
      width: 100,

      Cell: ({ row: { original }, value }) => (
        <StyledCommitmentInput
          defaultValue={value}
          onChange={(value) =>
            setTimeout(() => onUpdateCommitment(value, original.id), 1500)
          }
          placeholder="Text"
        />
      ),
    },
    {
      Header: "",
      accessor: "eye",
      width: 25,

      Cell: ({ row: { original }, value }) => (
        <Tooltip
          content={t("bonuses.calculations.viewAct") || ""}
          placement="bottom"
        >
          <IconButton
            icon="eye-open"
            color="black"
            size="l"
            variant="tertiary"
            onClick={() => {
              onViewClick(`/bonuses/premium/${original.id}`);
            }}
          />
        </Tooltip>
      ),
    },
  ];
};
