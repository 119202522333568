import { ConfirmRegisterForm } from "@/components/ConfirmRegister/ConfirmRegisterForm";
import { OnSubmitConfirmRegisterFormHandler } from "@/components/ConfirmRegister/ConfirmRegisterForm/hooks";
import { ConfirmRegisterInfoCard } from "@/components/ConfirmRegister/ConfirmRegisterInfoCard";
import { useAuthRegisterConfirm } from "@/helpers/api/auth/hooks";
import { useNotification } from "@/hooks/useNotification";
import IdentityLayout from "@/layouts/IdentityLayout";
import { AxiosError } from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ConfirmRegister: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const { mutateAsync: authRegisterConfirm } = useAuthRegisterConfirm();
  const { createNotificationError } = useNotification();

  const search = useLocation().search;

  const email = new URLSearchParams(search).get("email");
  const hash = new URLSearchParams(search).get("hash");

  const { t } = useTranslation();

  const onSubmit: OnSubmitConfirmRegisterFormHandler = async (data) => {
    if (email !== null && hash !== null) {
      try {
        await authRegisterConfirm({
          email: email,
          hash: hash,
          password: data.password,
          retryPassword: data.repeatPassword,
        });
        setIsSubmitted(true);
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 400) {
            createNotificationError(t("authorization.linkError") || "");
          } else {
            if (
              e.response?.data.message ===
              "No employees found who can verify the user's account"
            ) {
              createNotificationError(t("authorization.employeeError") || "");
            } else if (
              e.response?.data.message ===
              "Cannot read property 'confirmationStatus' of null"
            ) {
              createNotificationError(
                t("authorization.newPasswordError") || ""
              );
            } else {
              createNotificationError(t("authorization.isError") || "");
            }
          }
        }
      }
    } else {
      createNotificationError(t("authorization.linkError") || "");
    }
  };

  return (
    <IdentityLayout>
      {isSubmitted ? (
        <ConfirmRegisterInfoCard />
      ) : (
        <ConfirmRegisterForm onSubmit={onSubmit} />
      )}
    </IdentityLayout>
  );
};
