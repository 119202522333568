import { FC } from "react";
import styled, { css } from "styled-components";

import { colors, TColorName } from "@/helpers";

export type TSize =
  | 0
  | 8
  | 10
  | 12
  | 16
  | 18
  | 20
  | 24
  | 32
  | 40
  | 48
  | 54
  | 64
  | 110;

export interface IIcon {
  size: TSize;
  name: string;
  color?: TColorName;
  className?: string;
  onClick?: () => void;
}

interface IStyledIcon {
  size: TSize;
  color: TColorName;
}

const StyledIcon = styled.svg<IStyledIcon>`
    ${(props) => css`
        display: block;

        width: ${props.size}px;
        height: ${props.size}px;

        fill: ${colors[props.color || "black"]};
        transition: fill 0.4s;

    path {
      fill: ${colors[props.color || "black"]} !important;
    }
  `}
`;

const Icon: FC<IIcon> = (props) => {
  const { className, name, color = "black", size, onClick } = props;

  return (
    <StyledIcon
      className={className}
      size={size}
      color={color}
      onClick={onClick}
    >
      <use xlinkHref={`#${name}`}/>
    </StyledIcon>
  );
};

export { Icon };
