import { TableSimple } from "@/packages/tableSimple/TableSimple";
import styled, { css } from "styled-components";

export const StyledTable = styled(TableSimple)`
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  ${css`
    && .th {
      background-color: white !important;
    }
    && .iUJMmt:last-of-type {
      border-top-right-radius: 0;
    }
  `}
`;

export const TableHeader = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  background-color: white;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
