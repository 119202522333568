import { TGetMonitoringQualityByIdResponse } from "@/helpers/api/monitoringQuality/types";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";

import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { Col } from "antd";
import React, { useMemo } from "react";
import { StyledMonitoringDataFields, StyledRow } from "./index.styles";
import { MonitoringDataFormFieldsAppEnum } from "./types";
import { allowedTypesFiles, allowedTypesImages, useValidateDateProduction, } from "./utils";
import { t } from "i18next";
import { FormFileDropzoneField } from "@/packages/formElements/formFields/FormFileDropzoneField";
import {
  MonitoringDataFormFieldsEnum
} from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/types";
import { UploadType } from "@/packages/UploadImageAndFileDropzone"; // так не правильно упортировать
export interface IMonitoringDataFieldsAppProps {
  form: any;
  readOnly?: boolean;
  monitoringQuality?: TGetMonitoringQualityByIdResponse;
}

export const MonitoringDataFieldsApp: React.FC<
  IMonitoringDataFieldsAppProps
> = (props) => {
  const { form, readOnly = false, monitoringQuality } = props;

  const periodDateProduction = useValidateDateProduction(monitoringQuality);

  const filtersGenerationsOptions = useMemo(() => {
    if (monitoringQuality && monitoringQuality.generations) {
      //@ts-ignore
      return monitoringQuality.generations.map((generation) => ({
        value: generation.id,
        label: `${generation?.model.name || ""} ${generation?.name} ${
          generation?.restylingName || ""
        }`,
      }));
    }
  }, [monitoringQuality]);

  const isSoldOptions = [
    {
      value: true,
      label: t("common.yes") || "",
    },
    {
      value: false,
      label: t("common.no") || "",
    },
  ];

  const [isSold, photoIds, fileIds, productionDate] = form.watch([
    MonitoringDataFormFieldsAppEnum.IsSold,
    MonitoringDataFormFieldsAppEnum.PhotoIds,
    MonitoringDataFormFieldsAppEnum.FileIds,
    MonitoringDataFormFieldsAppEnum.CarProductionDate,
  ]);

  const onRemoveFunc = (field: any, id: any) => {
    if (!readOnly) {
      form.setValue(
        `${field}`,
        form.getValues(field)?.filter((el) => el.id !== id),
      );
    }
  };

  return (
    <StyledMonitoringDataFields>
      <StyledRow gutter={32} align="middle">
        <Col span={4}>
          <FormInputField
            name={MonitoringDataFormFieldsAppEnum.Vin}
            label="VIN*"
            readOnly={readOnly}
            isErrorAbsolute={true}
          />
        </Col>
        <Col span={4}>
          <FormInputField
            name={MonitoringDataFormFieldsAppEnum.Mileage}
            label={t("monitoring.mileage*") || ""}
            readOnly={readOnly}
            isErrorAbsolute={true}
          />
        </Col>
        <Col span={4}>
          <FormSelect
            name={MonitoringDataFormFieldsAppEnum.GenerationId}
            options={filtersGenerationsOptions}
            allowClear
            showArrow
            placeholder={t("monitoring.addNewProgramForm.choose") || ""}
            label={t("monitoring.model*") || ""}
            isDisabled={readOnly}
            isErrorAbsolute={true}
          />
        </Col>
        <Col span={4}>
          <FormDatepickerField
            name={MonitoringDataFormFieldsAppEnum.CarProductionDate}
            label={t("monitoring.carProductionDate*") || ""}
            isDisabled={readOnly}
            isErrorAbsolute={true}
            {...periodDateProduction}
          />
        </Col>
        <Col span={4}>
          <FormSelect
            name={MonitoringDataFormFieldsAppEnum.IsSold}
            options={isSoldOptions as any}
            showArrow
            placeholder={t("monitoring.addNewProgramForm.choose") || ""}
            label={t("monitoring.autoSold*") || ""}
            isDisabled={readOnly}
            isErrorAbsolute={true}
          />
        </Col>
        <Col span={4}>
          {isSold && (
            <FormDatepickerField
              name={MonitoringDataFormFieldsAppEnum.CarSoldDate}
              label={t("monitoring.autoSoldDate") || ""}
              isDisabled={readOnly}
              minDate={productionDate}
              isErrorAbsolute={true}
            />
          )}
        </Col>
      </StyledRow>

      <StyledRow gutter={32}>
        <Col span={24}>
          <FormTextAreaField
            name={MonitoringDataFormFieldsAppEnum.ReasonPetition}
            label={t("monitoring.reasons*") || ""}
            disabled={readOnly}
          />
        </Col>
      </StyledRow>
      <StyledRow gutter={32}>
        <Col span={24}>
          <FormTextAreaField
            name={MonitoringDataFormFieldsAppEnum.DiagnosticResult}
            label={t("monitoring.diagnResults*") || ""}
            disabled={readOnly}
          />
        </Col>
      </StyledRow>
      <StyledRow gutter={32}>
        <Col span={24}>
          <FormTextAreaField
            name={MonitoringDataFormFieldsAppEnum.SuspectedDefect}
            label={t("monitoring.possibleCause*") || ""}
            disabled={readOnly}
          />
        </Col>
      </StyledRow>
      <StyledRow gutter={32}>
        <Col span={16}>
          <FormFileDropzoneField
            name={MonitoringDataFormFieldsEnum.PhotoIds}
            path={'quality-monitoring/photos'}
            isDisabled={readOnly}
            iconDelete={!readOnly}
            fileIds={photoIds}
            variantBtn="contained"
            typeInfo={UploadType.IMAGE}
            allowedTypesProps={allowedTypesImages}
            withCommentField={false}
            isNotTransportClaims
            maxCount={20}
            label={t("monitoring.addNewProgramForm.photo") || ""}
            description={t("monitoring.addNewProgramForm.photoDescr") || ""}
            isMultiple
            onRemove={(id) => {
              onRemoveFunc("photoIds", id);
            }}
          />
        </Col>
      </StyledRow>
      <StyledRow gutter={32}>
        <Col span={16}>
          <FormFileDropzoneField
            label={t("monitoring.addNewProgramForm.file") || ""}
            description={t("monitoring.addNewProgramForm.fileDescr") || ""}
            name={MonitoringDataFormFieldsEnum.FileIds}
            path={'quality-monitoring/files'}
            isDisabled={readOnly}
            iconDelete={!readOnly}
            fileIds={fileIds}
            variantBtn="contained"
            typeInfo={UploadType.FILE}
            allowedTypesProps={allowedTypesFiles}
            withCommentField={false}
            isNotTransportClaims
            maxCount={20}
            isMultiple
            onRemove={(id) => {
              onRemoveFunc("fileIds", id);
            }}
          />
        </Col>
      </StyledRow>
    </StyledMonitoringDataFields>
  );
};
