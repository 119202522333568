import React from "react";
import { StyledPagination, StyledLoader } from "./index.styles";

interface IPremiumTableAdapter {
  children: JSX.Element;
  isLoading: boolean;
  paginationParams: { page: number; limit: number };
  setPage: (page: number) => void;
}
export const PremiumTableAdapter: React.FC<IPremiumTableAdapter> = (props) => {
  const { children, paginationParams, isLoading, setPage } = props;
  return (
    <>
      {!isLoading ? (
        <>
          {children}
          {paginationParams.limit >= 1 ? (
            <StyledPagination
              onChange={setPage}
              current={paginationParams.page}
              pageSize={1}
              total={paginationParams.limit}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <StyledLoader size={48} />
      )}
    </>
  );
};
