import React, { useState } from "react";
import { AuditInfo } from "../AuditInfo";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";
import {
  StyledIconWrapper,
  StyledSelectField
} from "@/components/Audits/FillAuditStages/FillAuditSecondStage/index.styles";
import { TAuditInfo, TAuditParametrs } from "@/helpers/api/audit-fill/types";
import { useExportAuditWithQuestions, useGetAuditWithQuestions, } from "@/helpers/api/audit-fill/hooks";
import { useRequestParams } from "@/hooks/useRequestParams";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { AuditQuestionsTable } from "../AuditQuestionsTable/AuditQuestionsTable";
import { Loader } from "@/packages/loader/Loader";
import { ChatModal, IButtonCallback } from "../../ChatModal";
import { useModal } from "@/hooks/useModal";
import { IRequestParams } from "@/helpers/interfaces";
import { Space } from "antd";
import { getRowStatistics } from "@/components/Audits/FillAuditStages/FillAuditSecondStage/hooks";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {
  useReadAllAuditPPOMessage,
  useSendNotificationForDealer
} from "@/helpers/api/audit-chat/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

interface IFillAuditSecondStage {
  auditData: TAuditInfo;
  id: number;
  updateInfo: () => void;
  isReadOnly?: boolean;
}

const MOCK_VARIANTS = [
  { label: "audits.list.all", value: "" },
  { label: "audits.list.noAnswer", value: "empty" },
  { label: "audits.list.yes", value: "yes" },
  { label: "audits.list.no", value: "no" },
];

const mutateArrOfObj = (arrObj: any[]) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  return res.map((item, index) => recursive(item, 0, `${index + 1}`));
};

export const FillAuditSecondStage: React.FC<IFillAuditSecondStage> = (
  props
) => {
  const { id, auditData, updateInfo, isReadOnly } = props;

  const [requestParams, setRequestParams] = useRequestParams<IRequestParams>(
    {}
  );
  const { t } = useTranslation();
  const {
    data: questions,
    isLoading: isQuestionsLoading,
    refetch: updateQuestions,
    isRefetching,
  } = useGetAuditWithQuestions({
    id,
    params: requestParamsMapper(requestParams),
  });
  const sections = mutateArrOfObj(questions?.data.sections || []);

  const [selectedParam, setSelectedParam] = useState("");
  const handleOpenChat = (paramId: string) => {
    setSelectedParam(paramId);
    ChatModalOpen();
  };

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const { mutateAsync: exportAuditWithQuestions } =
    useExportAuditWithQuestions();

  const handleUpdateList = () => {
    updateInfo();
    updateQuestions();
  };

  const handleCloseChat = () => {
    ChatModalClose();
    updateQuestions();
    updateInfo();
  };

  const handleExportAudit = () => {
    exportAuditWithQuestions(
      { id: id, ...requestParamsMapper(requestParams) },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "audit.xlsx");
        },
      }
    );
  };

  const questionNum = questions?.data?.sections
    .map((elem) => {
      // @ts-ignore
      if (!elem.children) {
        let numbers = [];
        if (elem.parameters.length > 0) {
          for (let i = 0; i < elem.parameters.length; i++) {
            numbers.push(
              // @ts-ignore
              `${elem.level}.${elem.parameters[i].order + 1}`
            );
          }
        }
        return numbers;
      }
    })
    .filter((elem) => elem !== undefined);

  const { mutate: sendNotification } = useSendNotificationForDealer();

  const {
    state: { profile },
  } = useProfile();
  const isProfileKia = profile?.type === "kia";

  const btnCallback: IButtonCallback = {
    sideEffectText: 'Отправить уведомление дилеру',
    sideEffectCb: () => {
      sendNotification({ id: id, paramId: selectedParam })
    }
  }

  return (
    <>
      <AuditInfo auditData={auditData}/>
      <Space>
        <StyledSelectField
          label={t("audits.list.variants") || ""}
          placeholder={t("audits.list.all") || ""}
          options={MOCK_VARIANTS}
          onChange={(value) => {
            setRequestParams({
              answer: {
                value,
                type: "filter",
              },
            });
          }}
        />
        <StyledIconWrapper>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              variant="secondary"
              color="black"
              isSquared={true}
              onClick={handleExportAudit}
            />
          </Tooltip>
        </StyledIconWrapper>
      </Space>
      {!isQuestionsLoading ? (
        !!requestParams?.answer?.value ? (
          <AuditQuestionsTable
            isSoloTable={requestParams.answer.value !== ""}
            isLoadingData={isQuestionsLoading || isRefetching}
            openChat={handleOpenChat}
            auditId={id}
            // @ts-ignore
            data={[
              questions?.data?.sections.reduce(
                (acc: TAuditParametrs[], item) => [...acc, ...item.parameters],
                []
              ) || [],
            ]
              .flat()
              .map((elem, index) => ({
                ...elem,
                order: questionNum ? questionNum.flat(1)[index] : "",
              }))}
            updateList={handleUpdateList}
          />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <TreeComponent
              hasInfo={true}
              data={sections || []}
              headerCallback={getRowStatistics}
              paramKey="parameters"
              renderComponent={({ data, level }) => (
                <AuditQuestionsTable
                  isReadOnly={isReadOnly}
                  level={level}
                  openChat={handleOpenChat}
                  auditId={id}
                  data={data || []}
                  isLoadingData={isQuestionsLoading || isRefetching}
                  updateList={handleUpdateList}
                />
              )}
            />
          </DndProvider>
        )
      ) : (
        <Loader size={48}/>
      )}
      {selectedParam ? (
        <ChatModal
          btnCallback={isProfileKia && !isReadOnly ? btnCallback : undefined}
          params={{ auditId: id, paramId: selectedParam }}
          isOpen={ChatModalState.isOpen}
          onClose={handleCloseChat}
          readAllAuditMessage={useReadAllAuditPPOMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};
