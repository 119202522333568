import { accessTokenService } from "@/helpers/api/accessTokenService";
import { TFileCreateResponse, TFileRecord } from "@/helpers/api/files/types";
import { useNotification } from "@/hooks/useNotification";
import { Button, IButton } from "@/packages/button/Button";
import { Upload as AntUpload, UploadProps } from "antd";
import React from "react";

export interface IUploadButtonMaterials extends IButton {
  path: string;
  onUploadFile?: (uploadResponse: TFileRecord, fileName: string) => void;
}

const allowedExtensions = [
  ".png",
  ".jpeg",
  ".jpg",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".xlsm",
  ".gif",
  ".tiff",
  ".pptx",
  ".zip",
  ".rar",
  ".mp4",
  '.psd',
  '.psb',
  '.fla',
  '.ai'
];

export const getUploadFileUrl = (path: string) =>
  `${process.env.REACT_APP_BASE_URL}/files?path=${path}`;

export const UploadButtonMaterials: React.FC<IUploadButtonMaterials> = (
  props
) => {
  const { path, onUploadFile, ...buttonProps } = props;
  const { createNotificationError } = useNotification();

  const accessToken = accessTokenService.getToken();

  const onBeforeUploadHandler: UploadProps["beforeUpload"] = (file) => {
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));
    const isAllowed = allowedExtensions.includes(fileExtension);
    // const isLetter200M = file.size / 1024 / 1024 < 200;
    if (!isAllowed) {
      createNotificationError(
        "Доступна загрузка только PDF, DOC, DOCX, XLS, XLSX, XLSM, JPG, GIF, TIF, TIFF, PCX, PNG, PSD, PSB, FLA, AI, JPEG, PPTX, ZIP, RAR, MP4 файлов"
      );
    }
    // if (!isLetter200M) {
    //   createNotificationError(
    //     "Максимально допустимый размер одного файла - 200 МБ"
    //   );
    // }
    return isAllowed;
  };

  const onChangeHandler = (info) => {
    if (onUploadFile && info.file.status === "done") {
      const response = info.file.response as TFileCreateResponse;
      const fileName = info.file.name.replace(/\.[^/.]+$/, "");
      onUploadFile(response.data[0], fileName);
    }
  };

  return (
    <AntUpload
      name="files"
      action={getUploadFileUrl(path)}
      headers={{ Authorization: `Bearer ${accessToken}` }}
      onChange={onChangeHandler}
      beforeUpload={onBeforeUploadHandler}
      maxCount={20}
      showUploadList={false}
    >
      <Button {...buttonProps} />
    </AntUpload>
  );
};
