import { TModelRecord } from "@/helpers/api/models/types";
import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ModelFields } from "../ModelFields";
import { ModelFormFieldsEnum } from "../ModelFields/types";
import { TOnSubmitEditModelFormCallback, useEditModelForm } from "./hooks";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

export interface IModelEditModal extends Pick<IModal, "onCancel" | "isOpen"> {
  record?: TModelRecord;
  onSubmit: TOnSubmitEditModelFormCallback;
}

export const ModelEditModal: React.FC<IModelEditModal> = (props) => {
  const { onSubmit, record, ...rest } = props;
  const { t } = useTranslation();
  const { hasAccess } = usePermission("model", "update");

  const { form, submit, isSubmitLoading, reset } = useEditModelForm({
    editableRecord: record,
    onSubmit,
  });

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  const [name, _yearRange] = form.watch([
    ModelFormFieldsEnum.Name,
    ModelFormFieldsEnum.YearRange,
  ]);
  const yearRange = _yearRange as IYearRangeInputFieldValue | undefined;

  return (
    <Modal
      {...rest}
      size="medium"
      title={t("models.logsModel.title") || ""}
      confirmButtonText={t("models.logsModel.save") || ""}
      cancelButtonText={t("models.logsModel.cancel_2") || ""}
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
      isDisabledConfirmButton={!hasAccess}
    >
      <TitleDivider size="medium">
        {name} {yearRange?.start || "..."} — {yearRange?.end || "..."}
      </TitleDivider>
      <FormProvider {...form}>
        <ModelFields isReadOnly={!hasAccess} />
      </FormProvider>
    </Modal>
  );
};
