import { createGlobalStyle } from "styled-components";
import { spacingStyles } from "./spacing";
import { fontStyles } from "./fonts";
import { antCustomStyles } from "./antCustomStyles";
import { media } from "@/helpers/styleBreakpoints";

export const GlobalStyles = createGlobalStyle`
  ${spacingStyles}
  ${fontStyles}
  ${antCustomStyles}
  
  body {
    font-family: var(--font-brand), sans-serif;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    max-width: 100vw;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    outline: none;
  }

  menu {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    font-family: var(--font-brand), sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 700;
    font-family: var(--font-brand), sans-serif;
  }

  ol,
  ul,
  dl {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a,
  button,
  input,
  label,
  select,
  textarea {
    outline: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: 0;
    outline: 0;
    background: none;
    padding: 0;
    margin: 0;
  }

  table {
    border-collapse: collapse;
  }

  .space-xxl {
    margin-bottom: calc(var(--space-xxl) - (var(--spacing-coefficient) * 6px));

    @media (${media.tablet}) {
      margin-bottom: var(--space-xxl);
    }
  }
  .space-xl {
    margin-bottom: calc(var(--space-xl) - (var(--spacing-coefficient) * 8px));
    @media (${media.tablet}) {
      margin-bottom: var(--space-xl);
    }
  }
  .space-l {
    margin-bottom: calc(var(--space-xl) - (var(--spacing-coefficient) * 4px));
    @media (${media.tablet}) {
      margin-bottom: var(--space-l);
    }
  }
  .space-m {
    margin-bottom: calc(var(--space-xl) - (var(--spacing-coefficient) * 4px));
    @media (${media.tablet}) {
      margin-bottom: var(--space-m);
    }
  }
  .space-s {
    margin-bottom: calc(var(--space-xl) - (var(--spacing-coefficient) * 2px));
    @media (${media.tablet}) {
      margin-bottom: var(--space-s);
    }
  }
  .space-xs {
    margin-bottom: var(--space-xs);
  }
  .space-xxs {
    margin-bottom: var(--space-xxs);
  }

  .row-reverse{
    flex-direction: row-reverse;
  }

 

  /* custom scrollbar */
  /*.scrollbar {*/
  /*  overflow-y: auto;*/
  /*  &::-webkit-scrollbar-track {*/
  /*    border-radius: 0px;*/
  /*    background-color: var(--color-bluegray-80);*/
  /*  }*/
  /*  &::-webkit-scrollbar {*/
  /*    width: 2px;*/
  /*    background-color: var(--color-bluegray-80);*/
  /*  }*/
  /*  &::-webkit-scrollbar-thumb {*/
  /*    border-radius: 0px;*/
  /*    background-color: var(--color-brand);*/
  /*  }*/
  /*}*/

`;
