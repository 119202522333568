import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import React, { useMemo, useState } from "react";
import { ACCOUNT_TYPE_OPTIONS, CreateUser, CreateUserFormFieldsEnum } from "@/pages/Admin/UserCreate/hooks";
import { FormCountrySelect } from "@/packages/formElements/formFields/FormCountrySelect";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormDealersSelect } from "@/packages/formElements/formFields/FormDealersSelect";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormPhoneInput } from "@/packages/formElements/formFields/FormPhoneInput";
import { useGetDealerList } from "@/helpers/api/dc/dealers/hooks";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { FieldsWrapper, StyledCol } from "@/pages/Admin/UserDataFields/index.styles";
import { STATUS_TYPES } from "@/pages/Admin/UsersFilters";
import { useGetRoleList } from "@/helpers/api/roles/hooks";
import { UseFormReturn } from "react-hook-form";

interface UserDataFieldsProps {
  form: UseFormReturn<CreateUser, any>
}

export const UserDataFields = ({ form }: UserDataFieldsProps) => {
  const { t } = useTranslation();

  const [searchValueDealerList, setSearchValueDealerList] = useState("");

  const { data: dealerList } = useGetDealerList({
    name: searchValueDealerList ? searchValueDealerList : undefined,
  });
  const { data: kiaDealersList } = useGetKiaDealerList({
    limit: 0, name: searchValueDealerList ? searchValueDealerList : undefined,
  });
  const { data: rolesList } = useGetRoleList({ type: form.watch('type') })

  const preparedRoleList = useMemo(
    () =>
      rolesList?.data?.data?.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      })) || [],
    [rolesList?.data, form.watch('type')]
  );

  return (
    <FieldsWrapper>
      <Row gutter={32}>
        <Col span={6}>
          <FormInputField
            name={CreateUserFormFieldsEnum.FirstName}
            label='Имя'
            placeholder={"Введите Имя"}
          />
        </Col>
        <Col span={6}>
          <FormInputField
            name={CreateUserFormFieldsEnum.LastName}
            label='Фамилия'
            placeholder={"Введите Фамилию"}
          />
        </Col>
        <Col span={6}>
          <FormInputField
            name={CreateUserFormFieldsEnum.Email}
            label='Email'
            placeholder={"Введите Email"}
          />
        </Col>
        <Col span={6}>
          <FormPhoneInput
            name={CreateUserFormFieldsEnum.Phone}
            label='Телефон'
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={6}>
          <FormCountrySelect
            name={CreateUserFormFieldsEnum.Country}
            label={t("authorization.country") || ""}
            placeholder={t("authorization.countryPlaceholder") || ""}
          />
        </Col>
        <Col span={6}>
          <FormSelect
            name={CreateUserFormFieldsEnum.RoleId}
            label={t("authorization.post") || ""}
            placeholder={t("authorization.postPlaceholder") || ""}
            options={preparedRoleList}
          />
        </Col>
        <Col span={6}>
          <FormDealersSelect
            name={CreateUserFormFieldsEnum.DealerId}
            label={"Дилерский центр"}
            placeholder={t("authorization.dealershipPlaceholder") || ""}
            showSearch
            dealerList={kiaDealersList?.data?.data ?? dealerList?.data?.data}
            setSearchValueDealerList={setSearchValueDealerList}
          />
        </Col>
        <Col span={6}>
          <FormSelect
            name={CreateUserFormFieldsEnum.IsActive}
            label={"Статус"}
            options={STATUS_TYPES}
          />
        </Col>
      </Row>
      <Row gutter={50}>
        <StyledCol span={6}>
          <FormCheckbox
            name={CreateUserFormFieldsEnum.IsConfirmed}
            label='Подтвержденный аккаунт'
          />
        </StyledCol>
        <Col span={6}>
          <FormSelect
            name={CreateUserFormFieldsEnum.Type}
            label='Тип аккаунта'
            options={ACCOUNT_TYPE_OPTIONS}
          />
        </Col>
      </Row>
    </FieldsWrapper>
  );
}