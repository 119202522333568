import { TGenerationRecord } from "@/helpers/api/generations/types";
import { materialCodeSchema, requiredStringSchema, yearRangeSchema, } from "@/helpers/validation";
import * as yup from "yup";
import {
  GenerationFieldsEnum,
  IGenerationFormDTOValues,
  IGenerationFormValues,
  IGenerationSuccessFormValues,
} from "./types";
import { t } from "i18next";

export const DEFAULT_GENERATION_FORM_VALUES: IGenerationFormValues = {
  name: "",
  materialCode: "",
  isRestyling: false,
  restylingName: "",
  photo: null,
  number: 1,
  vinModelClasses: [],
};

export const generationFormSchema = () => {
  return yup
    .object({
      [GenerationFieldsEnum.Name]: requiredStringSchema(
        t("models.addNewGenerationModel.photoField.validation.name") || ""
      ),
      materialCode: materialCodeSchema({
        required:
          t("models.addNewGenerationModel.photoField.validation.code") || "",
      }),
      generationYearRange: yearRangeSchema({
        startRequired:
          t("models.addNewGenerationModel.photoField.validation.year") || "",
      }),
      [GenerationFieldsEnum.VinModelClasses]: yup
        .array()
        .of(yup.string().min(2,
          t("models.addNewGenerationModel.photoField.validation.vin") || "")
          .max(2, t("models.addNewGenerationModel.photoField.validation.vin") || ""))
        .min(1, t("models.addNewGenerationModel.photoField.validation.vin") || "")
        .required(
          t("models.addNewGenerationModel.photoField.validation.vin") || ""
        ),
      isRestyling: yup.boolean(),
      restylingName: yup.string().when("isRestyling", {
        is: true,
        then: requiredStringSchema(
          t("models.addNewGenerationModel.photoField.validation.restyling") ||
          ""
        ),
      }),
      [GenerationFieldsEnum.Photo]: yup.object().nullable(),
    })
    .required();
};

export const generationFormValuesToDTO = (
  values: IGenerationSuccessFormValues | any
): IGenerationFormDTOValues | any => {
  const {
    generationYearRange,
    isRestyling,
    restylingName,
    photo,
    createdAt,
    fullModelName,
    id,
    isActive,
    materialCode,
    name,
    number,
    type,
    updatedAt,
    vinModelClasses,
  } = values;
  return {
    generationYearStart: generationYearRange.start,
    generationYearEnd: generationYearRange.end,
    isRestyling,
    restylingName: isRestyling ? restylingName : undefined,
    fileId: photo ? photo.id : null,
    photo: photo ? photo : null,
    createdAt,
    fullModelName,
    id,
    isActive,
    materialCode,
    name,
    number,
    type,
    updatedAt,
    vinModelClasses: vinModelClasses?.filter((value: string) => value !== ''),
  };
};

export const generationDTOToFormValues = (
  values: TGenerationRecord
): IGenerationFormValues => {
  const {
    generationYearStart,
    generationYearEnd,
    isRestyling,
    restylingName,
    ...restValues
  } = values;
  return {
    ...restValues,
    generationYearRange: {
      start: generationYearStart,
      end: generationYearEnd,
    },
    isRestyling: isRestyling || false,
    restylingName: restylingName || "",
  };
};
