import { UploadProps } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { IconButton } from "@/packages/icon-button/IconButton";
import { accessTokenService } from "@/helpers/api/accessTokenService";
import { ApiUrl } from "@/helpers/api/axiosInstance";
import { TFileCreateResponse, TFileRecord } from "@/helpers/api/files/types";
import { useNotification } from "@/hooks/useNotification";
import React, { useEffect, useState } from "react";
import { Icon } from "../icon/Icon";
import {
  StyledDownloadIcon,
  StyledDragger,
  StyledEyeIcon,
  StyledIconDropzone,
  StyledImagePreview,
  StyledImagePreviewForModal,
  StyledImagePreviewForModalContainer,
  StyledMainWrap,
  StyledModal,
  StyledPlaceholder,
  StyledPreviewOverlay,
  StyledRemoveButtonWrap,
} from "./index.styles";
import { useModal } from "@/hooks/useModal";
import { useDeleteFile, useDownloadFile } from "@/helpers/api/files/hooks";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export enum UploadType {
  IMAGE = "image",
  FILE = "file",
}

export interface IUploadImageDropdown extends Omit<UploadProps, "onChange"> {
  path: string;
  maxFileSize?: number;
  minFileSize?: number;
  typeInfo?: UploadType;
  allowedTypes?: string[];
  value?: TFileRecord | null;
  disabled?: boolean;
  isWithCustomPlaceholderTitle?: boolean;
  isWithCustomPlaceholder?: boolean;
  onChange?: (uploadResponse: TFileRecord | null) => void;
  placeholder?: React.ReactNode;
  hasRemove?: boolean;
}

export const getUploadFileUrl = (path: string) =>
  `${ApiUrl}/files?path=${path}`;

const getIconType = (type: UploadType): string => {
  switch (type) {
    case UploadType.IMAGE: {
      return "dropzone-icon-image";
    }
    case UploadType.FILE: {
      return "dropzone-icon-file";
    }
    default: {
      return "";
    }
  }
};

const getTitleType = (type: UploadType): string => {
  switch (type) {
    case UploadType.IMAGE: {
      return t("models.addNewGenerationModel.photoField.photo") || "";
    }
    case UploadType.FILE: {
      return t("models.addNewGenerationModel.photoField.file") || "";
    }
    default: {
      return "";
    }
  }
};

export const UploadImageDropzone: React.FC<IUploadImageDropdown> = (props) => {
  const {
    path,
    value,
    onChange,
    placeholder,
    maxFileSize = 0,
    minFileSize = 0,
    allowedTypes = [],
    typeInfo = UploadType.IMAGE,
    disabled = false,
    isWithCustomPlaceholderTitle = true,
    isWithCustomPlaceholder = false,
    hasRemove = true,
  } = props;
  const [isPreviewHovering, setIsPreviewHovering] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { t } = useTranslation();

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { mutateAsync: downloadFileAsync } = useDownloadFile();
  const { mutateAsync: deleteFile } = useDeleteFile();

  const {
    modalState: previewModalState,
    openModal: previewOpenModal,
  } = useModal();

  const accessToken = accessTokenService.getToken();
  const FilesTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const hasMatchingType = FilesTypes.some((fileType) =>
    allowedTypes.includes(fileType)
  );

  const onBeforeUploadHandler: DraggerProps["beforeUpload"] = (file) => {
    if (!!maxFileSize) {
      if (file.size > maxFileSize) {
        createNotificationError(
          t("models.addNewGenerationModel.photoField.maxSize") || ""
        );
        return false;
      }
    }
    if (!!minFileSize) {
      if (file.size < minFileSize) {
        createNotificationError(
          t("models.addNewGenerationModel.photoField.minSize") || ""
        );
        return false;
      }
    }
    if (!!allowedTypes.length) {
      if (!allowedTypes.includes(file.type)) {
        createNotificationError(
          t("models.addNewGenerationModel.photoField.type") || ""
        );
        return false;
      }
    }
    return true;
  };

  const onChangeHandler = (info) => {
    if (onChange && info.file.status === "done") {
      const response = info.file.response as TFileCreateResponse;
      onChange(response.data[0]);
      setIsFileUploaded(true);
    }
  };

  const onRemoveHandler = () => {
    if (value?.id) {
      deleteFile({ id: value?.id });
      setIsFileUploaded(false);
    }
    onChange && onChange(null);
  };

  const onPreviewShowHandler = (e, fileUrl: string) => {
    e.stopPropagation();
    setPreviewFileUrl(fileUrl);
    previewOpenModal();
  };

  const onFileDownload = async (e, id: string) => {
    e.stopPropagation();

    try {
      const response = (await downloadFileAsync({ id })) as any;
      if (response.status === 200) {
        const link = document.createElement("a");
        link.href = response.data.fileUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        createNotificationSuccess(
          t("models.addNewGenerationModel.photoField.downloaded") || ""
        );
      }
    } catch (e) {
      createNotificationError(
        t("models.addNewGenerationModel.photoField.downloadedError") || ""
      );
      throw e;
    }
  };

  const renderCustomPlaceholder = (
    isWithCustomPlaceholderTitle: boolean
  ): JSX.Element => (
    <>
      <StyledIconDropzone
        isWithCustomPlaceholderTitle={isWithCustomPlaceholderTitle}
      >
        <Icon
          name={getIconType(hasMatchingType ? UploadType.FILE : typeInfo)}
          size={24}
        />
      </StyledIconDropzone>
      {isWithCustomPlaceholderTitle ? (
        <StyledPlaceholder>
          {placeholder || isFileUploaded
            ? t("models.addNewGenerationModel.fileUploaded")
            : `${
              t("models.addNewGenerationModel.photoField.drag") || ""
            } ${getTitleType(hasMatchingType ? UploadType.FILE : typeInfo)} ${
              t("models.addNewGenerationModel.photoField.or") || ""
            } ${t("models.addNewGenerationModel.photoField.choose") || ""}`}
        </StyledPlaceholder>
      ) : (
        <></>
      )}
    </>
  );

  useEffect(() => {
    if (value) {
      setIsFileUploaded(true);
    }
  }, [value]);

  return (
    <StyledMainWrap>
      <StyledDragger
        fileExist={!!value}
        listType="picture-card"
        name="files"
        showUploadList={false}
        action={getUploadFileUrl(path)}
        headers={{ Authorization: `Bearer ${accessToken}` }}
        onChange={onChangeHandler}
        onRemove={onRemoveHandler}
        beforeUpload={onBeforeUploadHandler}
        disabled={disabled}
      >
        {value &&
        typeInfo === UploadType.IMAGE &&
        !FilesTypes.includes(value.mime) ? (
          <div
            onMouseOver={() => setIsPreviewHovering(true)}
            onMouseOut={() => setIsPreviewHovering(false)}
            onClick={(e) => onPreviewShowHandler(e, value.fileUrl)}
          >
            <StyledImagePreview src={value.fileUrl}/>
            {isPreviewHovering && (
              <>
                <StyledPreviewOverlay/>
                <StyledEyeIcon/>
              </>
            )}
          </div>
        ) : (
          <div
            onMouseOver={() => setIsPreviewHovering(true)}
            onMouseOut={() => setIsPreviewHovering(false)}
          >
            {value && isPreviewHovering && (
              <div onClick={(e) => onFileDownload(e, value.id)}>
                <StyledPreviewOverlay/>
                <StyledDownloadIcon/>
              </div>
            )}

            {isWithCustomPlaceholder
              ? placeholder
              : renderCustomPlaceholder(isWithCustomPlaceholderTitle)}
          </div>
        )}
      </StyledDragger>
      {value && hasRemove ? (
        <StyledRemoveButtonWrap>
          <IconButton
            size="m"
            color="gray"
            icon="close-16"
            variant="tertiary"
            isDisabled={disabled}
            onClick={onRemoveHandler}
          />
        </StyledRemoveButtonWrap>
      ) : (
        <></>
      )}

      <StyledModal
        {...previewModalState}
        size="photo"
        isOpen={previewModalState.isOpen}
        showConfirmButton={false}
        showCancelButton={false}
      >
        <StyledImagePreviewForModalContainer>
          <StyledImagePreviewForModal src={previewFileUrl}/>
        </StyledImagePreviewForModalContainer>
      </StyledModal>
    </StyledMainWrap>
  );
};
