import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TransportComplaintsFormCreate } from "@/components/TransportComplaints/TransportComplaintsFormCreate";
import { useCreateTransportClaims } from "@/helpers/api/transportComplaints/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  RESOURCE_ACTIONS,
  RESOURCE_NAME_TCLAIMS,
} from "../TransportComplaints";

const TransportComplaintsCreate = () => {
  const navigate = useNavigate();

  const { mutateAsync: createTransportComplaints, isLoading } =
    useCreateTransportClaims();
  const { createNotificationSuccess } = useNotification();
  const { createNotificationError } = useNotification();

  const onSubmitHandler = useCallback(async (data) => {
    try {
      await createTransportComplaints(data);
      createNotificationSuccess("Рекламация успешно добавлена");
      navigate("/transport-claims");
    } catch (error: any) {
      if (error.response.status === 409) {
        createNotificationError(error.response.data.message);
      } else {
        createNotificationError("Произошла ошибка");
      }
    }
  }, []);
  return (
    <DashboardLayout toBack="/transport-claims" title=" ">
      {!isLoading && (
        <PermissionsAdapter
          resourceName={RESOURCE_NAME_TCLAIMS}
          resourceAction={RESOURCE_ACTIONS.CREATE}
          fallback={() => <div>No access</div>}
        >
          <TransportComplaintsFormCreate onSubmitChange={onSubmitHandler}/>
        </PermissionsAdapter>
      )}
    </DashboardLayout>
  );
};

export default TransportComplaintsCreate;
