import { TModelRecord } from "@/helpers/api/models/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import {
  IModelDTOFields,
  IModelFormValues,
  IModelSuccessFormValues,
} from "../ModelFields/types";
import {
  DEFAULT_MODEL_FORM_VALUES,
  mapModelFieldsToDTO,
  modelFieldsFormSchema,
  mapModelDTOToFields,
} from "../ModelFields/utils";

export type TOnSubmitEditModelFormCallback = (payload: {
  id: string;
  patch: IModelDTOFields;
}) => void;

export const useEditModelForm = (options: {
  editableRecord?: TModelRecord;
  onSubmit: TOnSubmitEditModelFormCallback;
}) => {
  const { editableRecord, onSubmit } = options;
  const onSubmitHandler = (values: IModelSuccessFormValues) => {
    const modelDTOFields = mapModelFieldsToDTO(values);

    if (editableRecord) {
      onSubmit({ id: editableRecord.id, patch: modelDTOFields });
    }
  };
  const result = useForm<IModelFormValues, IModelSuccessFormValues>({
    validateFormSchema: modelFieldsFormSchema(),
    defaultValues: DEFAULT_MODEL_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(mapModelDTOToFields(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
