import React from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import {
  StyledModalContentMessage,
  StyledModalContentWrapper,
} from "./index.styles";

export type ConfirmAlertRegisterModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "onSubmit"
>;

export const ConfirmAlertRegisterModal: React.FC<
  ConfirmAlertRegisterModalProps
> = (props) => {
  return (
    <Modal title="Сохранение данных" {...props}>
      <StyledModalContentWrapper>
        <StyledModalContentMessage size={18}>
          Вы уверены, что хотите сохранить данные и отправить на подтверждение
          Вашей учетной записи?
        </StyledModalContentMessage>
      </StyledModalContentWrapper>
    </Modal>
  );
};
