import React from "react";
import styled, { css } from "styled-components";

import { colors } from "@/helpers";
import { TAuditMessage } from "@/helpers/api/audit-chat/types";
import { TMonitoringQualityCarMessageRecord } from "@/helpers/api/monitoringQuality/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { MessageMenu } from "./MessageMenu";

const StyledMessageBody = styled.div<{ isMyMessage: boolean }>`
  padding: 12px;

  ${({ isMyMessage }) =>
    isMyMessage
      ? css`
          background: #fafafa;
          border: 1px solid #f4f5f5;
          border-radius: 14px 14px 0 14px;
        `
      : css`
          background: linear-gradient(
              0deg,
              rgba(72, 123, 255, 0.05),
              rgba(72, 123, 255, 0.05)
            ),
            #fafafa;
          border: 1px solid #f4f5f5;
          border-radius: 14px 14px 14px 0;
        `}

  max-width: 311px;
`;

const StyledUser = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
const StyledMessageInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
`;

const StyledReply = styled.div`
  border-left: 1px solid ${colors.brand};
  padding-left: 5px;
  margin-right: 10px;
`;

const StyledReplyMessage = styled(Paragraph)`
  margin-top: 4px;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 230px;
  height: 16px;
  white-space: nowrap;
`;

const StyledMessageWrap = styled.div`
  word-break: break-word;
`;

interface IMessageText {
  data: TAuditMessage | TMonitoringQualityCarMessageRecord;
  isMyMessage: boolean;
  onReply: () => void;
}
export const MessageText: React.FC<IMessageText> = (props) => {
  const { data, isMyMessage, onReply } = props;
  return (
    <StyledMessageBody isMyMessage={isMyMessage}>
      <StyledUser>
        <Paragraph size={12} weight={400}>
          {`${data.user.firstName} ${data.user.lastName}`}
        </Paragraph>
        <MessageMenu onReply={onReply} />
      </StyledUser>
      {data.replyId ? (
        <StyledUser>
          {!data.reply ? (
            <></>
          ) : (
            <StyledReply>
              <Paragraph
                size={12}
              >{`${data.reply.user.firstName} ${data.reply.user.lastName}`}</Paragraph>
              <StyledReplyMessage size={12}>
                {data.reply.message}
              </StyledReplyMessage>
            </StyledReply>
          )}
        </StyledUser>
      ) : (
        <></>
      )}
      <StyledMessageInfo>
        <StyledMessageWrap>
          <Paragraph size={12} weight={400}>
            {data.message}
          </Paragraph>
        </StyledMessageWrap>
        <Paragraph color="gray" size={10} weight={300}>
          {new Date(data.createdAt).toLocaleTimeString("ru-RU", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Paragraph>
      </StyledMessageInfo>
    </StyledMessageBody>
  );
};
