import styled from "styled-components";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledAutoParkUpload = styled.div``;

export const StyledUploadList = styled.ul`
  box-sizing: border-box;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  width: 100%;
  min-width: 500px;
  align-items: end;

  background-color: ${colors.white};
  border: 1px solid ${colors.gray300};
  border-radius: 4px;
  transition: all 0.5s ease;

  .ant-upload.ant-upload-drag {
    height: 100%;
    border-radius: 4px;
    background-color: #e7eaff;
    border: 1px solid ${colors.gray300};
  }

  img {
    border-radius: 4px;
    min-height: initial;
    overflow: hidden;
  }
`;

export const StyledUploadItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const StyledUploadFieldWrap = styled.div`
  position: relative;
  height: 96px;

  & > div {
    height: 100%;
  }
`;

export const StyledSubTitleWrap = styled.div`
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const StyledParagraph = styled(Paragraph)`
  width: 84px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
