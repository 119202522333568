import { colors } from "@/helpers";
import { Pagination } from '@/packages/pagination/Pagination';
import styled from "styled-components";

export const StyledTabContent = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

export const StyledPagination = styled(Pagination)`
  width: max-content;
  margin: 24px 50%;
`;

export const StyledFooter = styled.div`
  margin-top: 64px;
  align-self: center;
`;

export const StyledModalFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`
