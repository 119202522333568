import {
  StyledColoredLabel,
  StyledFirstThumb,
  StyledIntersectionThumb,
  StyledLabelWrap,
} from "./index.styles";

export interface IEditDatePayload {
  id: string;
}
export type TOnEditCLick = (payload: IEditDatePayload) => void;
interface ICalendarRent {
  id: string;
  days: number;
  event: string;
  isIntersecting: boolean;
  onEditDateClick: TOnEditCLick;
}

export const CalendarRent: React.FC<ICalendarRent> = (props) => {
  const { id, days, isIntersecting, event, onEditDateClick } = props;

  const handleApplicationButtonClick = (e) => {
    e.stopPropagation();
    onEditDateClick({ id });
  };

  return (
    <>
      {/* <Tooltip content={<StyledComment>{comment}</StyledComment>}> */}
      <StyledLabelWrap>
        <StyledFirstThumb />
        <StyledColoredLabel
          event={event}
          days={days}
          isIntersecting={isIntersecting}
          onClick={handleApplicationButtonClick}
        />
        {isIntersecting && <StyledIntersectionThumb />}
      </StyledLabelWrap>
      {/* </Tooltip> */}
    </>
  );
};
