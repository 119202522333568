import { TableSimple } from "@/packages/tableSimple/TableSimple";
import styled, { css } from "styled-components";
import { Space } from "antd";

export const StyledTable = styled(TableSimple)`
  ${() => css`
    && .body {
      && .td {
        flex: none;
      }
    }
  `}
`;

export const StyledButtons = styled(Space)`
  margin-left: auto;
`;
