import { ITab } from "@/packages/tabs/Tabs";
import { StringParam, useQueryParam } from "use-query-params";

export const useActiveTab = (tabs: ITab[]) => {
  const [activeTabSlug, setActiveTabSlug] = useQueryParam(
    "activeTab",
    StringParam
  );

  const activeTab = tabs.find((tab) => tab.slug === activeTabSlug) || tabs[0];

  const setActiveTab = (tab: ITab) => {
    setActiveTabSlug(tab.slug);
  };

  return {
    activeTab,
    setActiveTab,
  };
};
