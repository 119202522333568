import React, { useEffect } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import {
  ITemplateValues,
  useFormValues
} from "@/components/AuditWarranty/Templates/ViolationCreateTemplateModal/hooks";
import { t } from "i18next";
import { ViolationModalFields } from "@/components/AuditWarranty/Templates/ViolationModalFields";

type IViolationModal = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

export interface ViolationTemplateModalProps extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: (data: ITemplateValues) => void;
  templateId: number;
}

export const ViolationTemplateCreateModal: React.FC<
  IViolationModal & ViolationTemplateModalProps
> = (props) => {
  const { templateId, onSubmit, onCancel } = props;

  const { form, onSubmitHandler } =
    useFormValues(onSubmit);

  useEffect(() => {
    form.reset({ templateId })
  }, [templateId]);

  const handleCancel = () => {
    form.reset();
    onCancel && onCancel();
  };
  return (
    <Modal
      {...props}
      title={t("warrantyAudits.violation.create") || ""}
      size="small"
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("common.save") || ""}
      onSubmit={() => {
        onSubmitHandler();
        handleCancel();
      }}
      onCancel={handleCancel}
    >
      <ViolationModalFields form={form}/>
    </Modal>
  );
};