export const DEFAULT_HEADERS_MOCK = (
  header: string | undefined,
  isDealer: boolean | undefined,
  isReadOnlyQuantity: boolean | undefined
) => {
  return isDealer
    ? [
      {
        Header: `${
          header ? header : "maintenanceGrids.gridsModel.viewGrid.id"
        }`,
        accessor: "id",
        isCheckable: false,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sticky: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.name",
        accessor: "name",
        isCheckable: false,
        width: 356,
        maxWidth: 356,
        minWidth: 356,
        sticky: "left",
        textAlign: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.quantity",
        accessor: "quantity",
        isCheckable: false,
        isQuantity: true,
        isReadOnlyQuantity: isReadOnlyQuantity,
        sticky: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_1",
        accessor: "1",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_2",
        accessor: "2",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_3",
        accessor: "3",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_4",
        accessor: "4",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_5",
        accessor: "5",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_6",
        accessor: "6",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_7",
        accessor: "7",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_8",
        accessor: "8",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_9",
        accessor: "9",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_10",
        accessor: "10",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
    ]
    : [
      {
        Header: `${
          header ? header : "maintenanceGrids.gridsModel.viewGrid.id"
        }`,
        accessor: "id",
        isCheckable: false,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sticky: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.name",
        accessor: "name",
        isCheckable: false,
        width: 256,
        maxWidth: 256,
        minWidth: 256,
        sticky: "left",
        textAlign: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.quantity",
        accessor: "quantity",
        isCheckable: false,
        isQuantity: true,
        isReadOnlyQuantity: isReadOnlyQuantity,
        sticky: "left",
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_1",
        accessor: "1",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_2",
        accessor: "2",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_3",
        accessor: "3",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_4",
        accessor: "4",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_5",
        accessor: "5",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_6",
        accessor: "6",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_7",
        accessor: "7",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_8",
        accessor: "8",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_9",
        accessor: "9",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      {
        Header: "maintenanceGrids.gridsModel.viewGrid.grid_10",
        accessor: "10",
        Footer: "23 421",
        isCheckable: true,
        isMultiColor: true,
      },
      // {
      //   Header: "ТО 11",
      //   accessor: "11",
      //   Footer: "23 421",
      //   isCheckable: true,
      //   isMultiColor: true,
      // },
      // {
      //   Header: "ТО 12",
      //   accessor: "12",
      //   Footer: "23 421",
      //   isCheckable: true,
      //   isMultiColor: true,
      // },
      // {
      //   Header: "ТО 13",
      //   accessor: "13",
      //   Footer: "23 421",
      //   isCheckable: true,
      //   isMultiColor: true,
      // },
      // {
      //   Header: "ТО 14",
      //   accessor: "14",
      //   Footer: "23 421",
      //   isCheckable: true,
      //   isMultiColor: true,
      // },
      // {
      //   Header: "ТО 15",
      //   accessor: "15",
      //   Footer: "23 421",
      //   isCheckable: true,
      //   isMultiColor: true,
      // },
    ];
};

export const CHECKBOX_DATA_MOCK = [
  {
    id: "001",
    name: "Приводные ремни",
    quantity: "2",
    1: false,
    2: true,
    3: true,
    4: false,
    5: true,
    6: false,
    7: false,
    8: false,
    9: true,
    10: false,
    11: false,
    12: false,
    13: true,
    14: false,
    15: false,
  },
  {
    id: "002",
    name: "Приводные ремни 2",
    quantity: "5",
    1: false,
    2: false,
    3: false,
    4: true,
    5: true,
    6: false,
    7: true,
    8: false,
    9: true,
    10: false,
    11: true,
    12: true,
    13: true,
    14: false,
    15: false,
  },
];

export const MULTI_COLOR_DATA_MOCK = [
  {
    id: "001",
    name: "Приводные ремни",
    quantity: "2",
    1: {
      color: "green",
      value: true,
    },
    2: {
      color: "orange",
      value: true,
    },
    3: {
      color: "blue",
      value: true,
    },
    4: {
      color: "default",
      value: false,
    },
    5: {
      color: "green",
      value: true,
    },
    6: {
      color: "default",
      value: false,
    },
    7: {
      color: "default",
      value: false,
    },
    8: {
      color: "default",
      value: false,
    },
    9: {
      color: "blue",
      value: true,
    },
    10: {
      color: "default",
      value: false,
    },
    11: {
      color: "default",
      value: false,
    },
    12: {
      color: "default",
      value: false,
    },
    13: {
      color: "default",
      value: false,
    },
    14: {
      color: "default",
      value: false,
    },
    15: {
      color: "default",
      value: false,
    },
  },
  {
    id: "002",
    name: "Приводные ремни 2",
    quantity: "5",
    1: {
      color: "blue",
      value: true,
    },
    2: {
      color: "default",
      value: false,
    },
    3: {
      color: "default",
      value: false,
    },
    4: {
      color: "orange",
      value: true,
    },
    5: {
      color: "green",
      value: true,
    },
    6: {
      color: "default",
      value: false,
    },
    7: true,
    8: {
      color: "default",
      value: false,
    },
    9: true,
    10: {
      color: "default",
      value: false,
    },
    11: {
      color: "green",
      value: true,
    },
    12: {
      color: "orange",
      value: true,
    },
    13: {
      color: "orange",
      value: true,
    },
    14: {
      color: "orange",
      value: true,
    },
    15: {
      color: "orange",
      value: true,
    },
  },
];

export const TEXT_DATA_MOCK = [
  {
    id: "001",
    name: "Приводные ремни",
    quantity: "2",
    1: "540",
    2: "540",
    3: "540",
    4: "540",
    5: "540",
    6: "540",
    7: "540",
    8: "540",
    9: "540",
    10: "540",
    11: "540",
    12: "540",
    13: "540",
    14: "540",
    15: "540",
  },
  {
    id: "002",
    name: "Приводные ремни 2",
    quantity: "5",
    to1: "220",
    to2: "220",
    to3: "220",
    to4: "220",
    to5: "220",
    to6: "220",
    to7: "220",
    to8: "220",
    to9: "220",
    to10: "220",
    to11: "220",
    to12: "220",
    to13: "220",
    to14: "220",
    to15: "220",
  },
];
