import { useEffect } from "react";

import { useForm } from "@/hooks/useForm";
import { TEditBonusesParamsRequest } from "@/helpers/api/bonuses/calculations/types";
import * as yup from "yup";
import { requiredNumberSchema } from "@/helpers/validation";

export type TOnSubmitDealerBonusesModalFormCb = (
  values: TEditBonusesParamsRequest
) => void;

export const validateFormSchema = yup.object({
  bonusPercent: requiredNumberSchema("Необходимо ввести числовое значение")
    .integer("iЗначение должно быть целым числом")
    .nullable()
    .min(1, "Минимальное значение - 1")
    .max(100, "Максимальное значение - 100"),
  planPercent: requiredNumberSchema("Необходимо ввести числовое значение")
    .integer("Значение должно быть целым числом")
    .nullable()
    .min(1, "Минимальное значение - 1")
    .max(300, "Максимальное значение - 300"),
});

export const useDealerBonusesModalForm = (
  onSubmit: TOnSubmitDealerBonusesModalFormCb,
  defaultValues: TEditBonusesParamsRequest
) => {
  const form = useForm<TEditBonusesParamsRequest>({
    validateFormSchema,
    defaultValues,
    onSubmit,
  });

  useEffect(() => {
    form.form.reset(defaultValues, { keepDefaultValues: true });
    // form.form.reset();
  }, []);

  return form;
};
