import React from "react";
import styled, { css, Theme, ThemeProvider } from "styled-components";
import { themes } from "./themes";
import { colors, media, TColorName } from "@/helpers";

type TTextAlign = "left" | "center" | "right";

interface IParagraph {
  children: React.ReactNode;
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24;
  weight?: number;
  tag?: string;
  color?: TColorName;
  className?: string;
  textAlign?: TTextAlign;
  onClick?: () => void;
}

interface IStyledParagraph {
  tag: string;
  color: string;
  weight: number;
  children: React.ReactNode;
  textAlign: TTextAlign;
}

const StyledParagraph = styled.p.attrs(
  ({ tag, color, ...rest }: IStyledParagraph) => ({
    ...rest,
    as: tag,
    _color: color,
  })
)<IStyledParagraph>`
    text-align: ${({ textAlign }) => textAlign};
    font-family: var(--font-brand), sans-serif;
    font-weight: ${(props) => props.weight};
    color: ${(props) => colors[props._color]};
    transition: 0.4s;
    font-size: ${(props) => props.theme.mobile.fontSize};
    line-height: ${(props) => props.theme.mobile.lineHeight};
    margin: 0;
    padding: 0;
    ${(props) =>
            props.theme.tablet &&
            css`
                ${media.tablet} {
                    font-size: ${props.theme.tablet.fontSize};
                    line-height: ${props.theme.tablet.lineHeight};
                }
            `}

    ${(props) =>
            props.theme.laptop &&
            css`
                ${media.laptop} {
                    font-size: ${props.theme.laptop.fontSize};
                    line-height: ${props.theme.laptop.lineHeight};
                }
            `}

    ${(props) =>
            props.theme.desktop &&
            css`
                ${media.desktop} {
                    font-size: ${props.theme.desktop.fontSize};
                    line-height: ${props.theme.desktop.lineHeight};
                }
            `}
`;

const Paragraph: React.FC<IParagraph> = ({
                                           children,
                                           size = 20,
                                           weight = 400,
                                           tag = "p",
                                           color = "gray-700",
                                           textAlign = "left",
                                           className,
                                           onClick
                                         }) => {
  const adjustedTheme = (ancestorTheme: Theme) => ({
    ...ancestorTheme,
    ...themes[size],
  });

  return (
    <ThemeProvider theme={adjustedTheme}>
      <StyledParagraph
        className={className}
        tag={tag}
        color={color}
        weight={weight}
        textAlign={textAlign}
        onClick={onClick}
      >
        {children}
      </StyledParagraph>
    </ThemeProvider>
  );
};
export { Paragraph };
