import { NotificationsResultModal } from "@/components/NotificationsResult/NotificationsResultModal";
import { colors } from "@/helpers";
import { TRegistrationByDealerNotification } from "@/helpers/api/notifications/types";
import {
  useConfirmDealerRegistrationByDealer,
  useGetUserById,
  useRejectDealerRegistrationByDealer,
} from "@/helpers/api/users/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { InputField } from "@/packages/formElements/fields/InputField";
import { Space } from "antd";
import { FC, useState } from "react";
import styled from "styled-components";
import { useGetProfile } from "@/helpers/api/profile/hooks";

const StyledNotificationsResultDealer = styled.div`
  padding: 24px;
  width: 50%;

  display: grid;
  gap: 32px;

  background-color: ${colors.white};
`;

const StyledInputs = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
`;

interface INotificationsResultDealer {
  notification: TRegistrationByDealerNotification;
}

const NotificationsResultDealer: FC<INotificationsResultDealer> = (props) => {
  const { notification } = props;
  const { createNotificationError } = useNotification();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    modalState: notificationsResultConfirmModalState,
    openModal: openNotificationsResultConfirmModalState,
  } = useModal();

  const {
    modalState: notificationsResultCancelModalState,
    openModal: openNotificationsResultCancelModalState,
  } = useModal();

  const personId = notification?.data?.entityId as string;

  const { mutateAsync: confirmNotification } =
    useConfirmDealerRegistrationByDealer();

  const { mutateAsync: rejectNotification } =
    useRejectDealerRegistrationByDealer();

  const profile = useGetProfile();

  const { data: userData } = useGetUserById({ id: notification.data.entityId });

  const handleConfirmButtonClick = async () => {
    try {
      if (!notification?.data?.entityId) {
        createNotificationError(
          "Данного пользователя не существует или его заявка была отклонена"
        );
      }
      if (profile?.data?.data.type !== "dealer") {
        createNotificationError(
          "Данная заявка должна быть обработана техническим директором"
        );
      }
      if (
        // @ts-ignore
        userData?.data.confirmationStatus === "confirmed-by-kia" ||
        // @ts-ignore
        userData?.data.confirmationStatus === "confirmed-by-dealer"
      ) {
        createNotificationError(
          "Данная заявка уже была подтверждена техническим директором"
        );
      } else {
        await confirmNotification({
          id: personId,
        });
        openNotificationsResultConfirmModalState();
        setIsDisabled(true);
      }
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
    }
  };

  const handleCancelButtonClick = async () => {
    try {
      if (
        !notification?.data?.entityId ||
        // @ts-ignore
        !userData?.data?.confirmationStatus
      ) {
        createNotificationError(
          "Данного пользователя не существует или его заявка уже была отклонена"
        );
      }
      if (profile?.data?.data.type !== "dealer") {
        createNotificationError(
          "Данная заявка должна быть обработана техническим директором"
        );
      }
      if (
        // @ts-ignore
        userData?.data.confirmationStatus === "confirmed-by-kia" ||
        // @ts-ignore
        userData?.data.confirmationStatus === "confirmed-by-dealer"
      ) {
        createNotificationError(
          "Данная заявка уже была подтверждена техническим директором"
        );
      } else {
        await rejectNotification({
          id: personId,
        });
        openNotificationsResultCancelModalState();
        setIsDisabled(true);
      }
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <>
      <StyledNotificationsResultDealer>
        <StyledInputs>
          <Space direction="vertical" size={24}>
            <InputField
              label="Фамилия"
              value={notification?.data.lastName}
              disabled={true}
            />
            <InputField
              label="Должность"
              value={notification?.data.role}
              disabled={true}
            />
            <InputField
              label="Телефон"
              value={notification?.data.phone}
              disabled={true}
            />
          </Space>

          <Space direction="vertical" size={24}>
            <InputField
              label="Имя"
              value={notification?.data.firstName}
              disabled={true}
            />
            <InputField
              label="Почта"
              value={notification?.data.email}
              disabled={true}
            />
          </Space>
        </StyledInputs>

        <Space align="center" size={16}>
          <Button
            color="brand"
            theme="primary"
            onClick={handleConfirmButtonClick}
            isDisabled={
              isDisabled ||
              // @ts-ignore
              !userData?.data?.confirmationStatus ||
              // @ts-ignore
              !(userData?.data.confirmationStatus === "email-confirmed")
            }
          >
            Отправить на подтверждение
          </Button>
          <Button
            color="brand"
            theme="secondary"
            onClick={handleCancelButtonClick}
            isDisabled={
              isDisabled ||
              // @ts-ignore
              !userData?.data?.confirmationStatus ||
              // @ts-ignore
              !(userData?.data.confirmationStatus === "email-confirmed")
            }
          >
            Отклонить учетную запись
          </Button>
        </Space>
      </StyledNotificationsResultDealer>

      <NotificationsResultModal
        {...notificationsResultConfirmModalState}
        resultText="Учетная запись направлена на подтверждение ответственному сотруднику KIA"
      />
      <NotificationsResultModal
        {...notificationsResultCancelModalState}
        resultText="Учетная запись отклонена"
      />
    </>
  );
};

export { NotificationsResultDealer };
