import styled, { css } from "styled-components";

import { Paragraph } from "@/packages/paragraph/Paragraph";
import { colors } from "@/helpers";

export const StyledRentFormWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 32px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;
`;

export const StyledFormRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const StyledDatepickerFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledRadioWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  padding: 30px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;

  ${({ isActive }) => {
    return isActive
      ? css`
          background-color: rgba(132, 166, 254, 0.16);
          border: 1px solid rgba(72, 123, 255, 0.3);
        `
      : css`
          background-color: white;
          border: 1px solid ${colors.gray400};
        `;
  }}
`;

export const StyledRadioLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledRateParagraph = styled(Paragraph)`
  padding: 10px 0;
  border-top: 1px solid ${colors.gray30};
  border-bottom: 1px solid ${colors.gray30};
`;

export const StyledDatepickerDash = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRateInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const StyledRateColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
