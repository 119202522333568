import {TTransportRecordRequest} from "../types";
import { ITransportComDetailFormValues } from "@/components/TransportComplaints/TransportComplaintsDetail/hooks";

export const transformCreateFields = (
  obj: ITransportComDetailFormValues
): TTransportRecordRequest => {
  const objKeys = Object.keys(obj);
  const other = [
    "vin",
    "codeCase",
    "placeAcceptence",
    "dateAcceptence",
    "dealerId",
    "openForViewing",
    "isEllada",
  ];

  const keysWithDescription = [
      "other",
      "viewDetail"
  ]
  const result: any = {};

  for (const key of objKeys) {
    if (other.includes(key)) {
      result[key] = obj[key];
    } else if (obj[key]?.files) {
      if (keysWithDescription.includes(key)) {
        result[key] = obj[key].files.map((item, index) => ({
          fileId: item.id,
          description: obj[key].descriptions[index],
        }))
      } else {
        result[key] = obj[key].files.map((item) => item.id);
      }
    } else  {
      result[key] = [];
    }
  }
  return result;
};

export const transformResponseFields = (
  obj: ITransportComDetailFormValues
) => {
  const objKeys = Object.keys(obj);
  const other = [
    'id',
    'createdBy',
    'dealer',
    'updatedAt',
    "vin",
    "codeCase",
    "placeAcceptence",
    "dateAcceptence",
    "dealerId",
    "openForViewing",
    "isEllada",
  ];

  const keysWithDescription = [
    "other",
    "viewDetail",
    'generalView',
    'kiaSng',
    'oupts',
    'photoDefect',
    'tthCmr',
    'vinPlate'
  ]
  const result: any = {};

  for (const key of objKeys) {
    if (other.includes(key)) {
      result[key] = obj[key];
    } else if (Array.isArray(obj[key])) {
      if (keysWithDescription.includes(key)) {
        const filesArray = obj[key].map((it) => (it.file))
        const descriptionArray = obj[key].map((it) => (it.description))
        result[key] = {files: filesArray, descriptions: descriptionArray}
      } else {
        result[key] = [];
      }
    } else  {
      result[key] = [];
    }
  }

  return result;
};
