import { Space } from "antd";
import styled from "styled-components";
import { Button } from "@/packages/button/Button";

export const StyledSpace = styled(Space)`
  width: 100%;
`;
export const StyledButton = styled(Button)`
  padding: 10px 22px 10px 22px;
`;
