import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { TGenerationRecord } from "@/helpers/api/generations/types";
import { TModelRecord } from "@/helpers/api/models/types";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import { CompleteSetFields } from "../CompleteSetFields";
import { StyledTitleSecondary } from "../GenerationCreateModal/index.styles";
import { ICreateCompleteSetPayload, useCreateCompleteSetForm } from "./hooks";
import { CompleteSetFromFieldsEnum } from "@/components/Models/ModelsListTab/CompleteSetFields/types";
import { useGetPublicDealerList } from "@/helpers/api/vin/hooks";

export interface ICompleteSetCreateModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  generationId?: string;
  parentModel?: TModelRecord;
  parentGeneration?: TGenerationRecord | any;
  onSubmit: (payload: ICreateCompleteSetPayload) => void;
}

export const CompleteSetCreateModal: React.FC<ICompleteSetCreateModal> = (
  props
) => {
  const { onSubmit, parentGeneration, ...rest } = props;

  const { form, submit, isSubmitLoading, reset } = useCreateCompleteSetForm({
    generationId: parentGeneration?.id || "",
    onSubmit,
  });

  const [mpv] = form.watch([CompleteSetFromFieldsEnum.Mpv]);

  const { data: getDecoderMPV, refetch } = useGetPublicDealerList({ mpv: mpv });

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  useEffect(() => {
    form.setValue(
      CompleteSetFromFieldsEnum.FuelTypeId,
      getDecoderMPV?.data?.fuel?.id ? getDecoderMPV?.data?.fuel.id : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.TransmissionTypeId,
      getDecoderMPV?.data?.transmission?.id
        ? getDecoderMPV?.data?.transmission.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.EngineTypeId,
      getDecoderMPV?.data?.engineType?.id
        ? getDecoderMPV?.data?.engineType?.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.EngineVolumeId,
      getDecoderMPV?.data?.engineVolume?.id
        ? getDecoderMPV?.data?.engineVolume?.id
        : null
    );
    form.setValue(
      CompleteSetFromFieldsEnum.CarBodyTypeId,
      getDecoderMPV?.data?.body?.id ? getDecoderMPV?.data?.body?.id : null
    );
  }, [getDecoderMPV]);

  return (
    <Modal
      {...rest}
      size="medium"
      title="Добавление новой модификации"
      confirmButtonText="Сохранить модификацию"
      cancelButtonText="Отмена"
      allowCancelPopconfirm={true}
      cancelPopconfirmTitle="Вы уверены, что хотите отменить добавление новой модификации?"
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
    >
      <TitleDivider size="medium">
        <StyledTitleSecondary>{`${parentGeneration?.parentModel?.name} ${
          parentGeneration?.parentModel?.modelYearStart
        } ${
          parentGeneration?.parentModel?.modelYearEnd
            ? `- ${parentGeneration?.parentModel?.modelYearEnd}`
            : "- ..."
        }  ${parentGeneration?.name} ${
          parentGeneration?.restylingName || ""
        } (${parentGeneration?.number} поколение${
          parentGeneration?.isRestyling ? ", рестайлинг" : ""
        })  ${parentGeneration?.generationYearStart || ""} 
        ${
          parentGeneration?.generationYearEnd
            ? `- ${parentGeneration?.generationYearEnd}`
            : ""
        }`}</StyledTitleSecondary>
      </TitleDivider>
      <FormProvider {...form}>
        <CompleteSetFields refetch={refetch} />
      </FormProvider>
    </Modal>
  );
};
