import {
  useMaintenanceGrids,
  ContextMaintenanceGrids,
} from "@/hooks/contexts/useMaintenanceGrids";
import { FC, ReactNode } from "react";

interface IContextProvider {
  children: ReactNode;
}

const MaintenanceGridsContextProvider: FC<IContextProvider> = (props) => {
  const { children } = props;

  const maintenanceGrids = useMaintenanceGrids();

  return (
    <ContextMaintenanceGrids.Provider value={maintenanceGrids}>
      {children}
    </ContextMaintenanceGrids.Provider>
  );
};

export { MaintenanceGridsContextProvider };
