import React from "react";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { FillAuditSecondStage } from "@/components/Audits/FillAuditStages/FillAuditSecondStage";
import {
  useGetAuditById,
} from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";

export const AuditInfo: React.FC = () => {
  const params = useParams();
  const auditId = params.id as string;
  const {
    data: auditData,
    isLoading,
    refetch: updateAuditData,
  } = useGetAuditById(+auditId);

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={`Информация о аудите “${auditData?.data.title}”`}
        toBack={-1}
      >
        {
          !isLoading && auditData?.data ?
            <FillAuditSecondStage
              id={+auditId}
              auditData={auditData?.data}
              updateInfo={updateAuditData}
              isReadOnly
            /> : <Loader size={48}/>
        }
      </DashboardLayout>
    </>
  );
};
