import { useMutation } from "@tanstack/react-query";
import { accessTokenService } from "../accessTokenService";
import { api } from "../axiosInstance";
import { refreshTokenService } from "../refreshTokenService";
import {
  TAuthChiefPasswordRequest,
  TAuthForgotPasswordRequest,
  TAuthLoginRequest,
  TAuthLoginResponse,
  TAuthLoginAdRequest,
  TAuthRegisterConfirmRequest,
  TAuthRegisterConfirmResponse,
  TAuthRegisterRequest,
  TAuthRegisterResponse,
  TAuthResetPasswordRequest,
} from "./types";

export const useAuthLogin = () => {
  return useMutation(
    (data: TAuthLoginRequest) => {
      return api.post<TAuthLoginResponse>("/auth/login", data);
    },
    {
      onSuccess: (response) => {
        const { accessToken, refreshToken, refreshExpiresIn, expiresIn } =
          response.data;
        accessTokenService.setToken(accessToken, expiresIn);
        refreshTokenService.setToken(refreshToken, refreshExpiresIn);
      },
    }
  );
};

export const useAuthLoginAd = () => {
  return useMutation(
    (data: TAuthLoginAdRequest) => {
      return api.post<TAuthLoginResponse>("/auth/ad-login", data);
    },
    {
      onSuccess: (response) => {
        const { accessToken, refreshToken, refreshExpiresIn, expiresIn } =
          response.data;
        accessTokenService.setToken(accessToken, expiresIn);
        refreshTokenService.setToken(refreshToken, refreshExpiresIn);
      },
    }
  );
};

export const useAuthRegisterM = () => {
  return useMutation((data: TAuthRegisterRequest) => {
    return api.post<TAuthRegisterResponse>("/auth/register", data);
  });
};

export const useAuthRegisterConfirm = () => {
  return useMutation((data: TAuthRegisterConfirmRequest) => {
    return api.post<TAuthRegisterConfirmResponse>(
      "/auth/register-confirm",
      data
    );
  });
};

export const useAuthRegisterDirector = () => {
  return useMutation((data: TAuthRegisterConfirmRequest) => {
    return api.post<TAuthRegisterConfirmResponse>(
      "/auth/register-confirm",
      data
    );
  });
};

export const useAuthForgotPasswordM = () => {
  return useMutation((data: TAuthForgotPasswordRequest) => {
    return api.post("/auth/forgot-password", data);
  });
};

export const useAuthResetPassword = () => {
  return useMutation((data: TAuthResetPasswordRequest) => {
    return api.post("/auth/reset-password", data);
  });
};

export const useAuthChiefPassword = () => {
  return useMutation(
    (data: TAuthChiefPasswordRequest) => {
      return api.post("/auth/chief-password", data);
    },
    {
      onSuccess: (response) => {
        const { accessToken, refreshToken, refreshExpiresIn, expiresIn } =
          response.data;
        accessTokenService.setToken(accessToken, expiresIn);
        refreshTokenService.setToken(refreshToken, refreshExpiresIn);
      },
    }
  );
};

export const useRefreshTooken = () => {
  return useMutation(
    (data: { refreshToken: string }) => {
      return api.post("/auth/refresh-token", data);
    },
    {
      onSuccess: (response) => {
        const { accessToken, refreshToken, refreshExpiresIn, expiresIn } =
          response.data;
        accessTokenService.setToken(accessToken, expiresIn);
        refreshTokenService.setToken(refreshToken, refreshExpiresIn);
      },
    }
  );
};
