import { useForm } from "@/hooks/useForm";
import {
  IModelDTOFields,
  IModelFormValues,
  IModelSuccessFormValues,
} from "../ModelFields/types";
import {
  DEFAULT_MODEL_FORM_VALUES,
  mapModelFieldsToDTO,
  modelFieldsFormSchema,
} from "../ModelFields/utils";

export type TOnSubmitCreateModelFormCallback = (
  values: IModelDTOFields
) => void;

export const useCreateModelForm = (
  onSubmit: TOnSubmitCreateModelFormCallback
) => {
  const onSubmitHandler = (values: IModelSuccessFormValues) => {
    const modelDTOFields = mapModelFieldsToDTO(values);

    onSubmit(modelDTOFields);
  };
  return useForm<IModelFormValues, IModelSuccessFormValues>({
    validateFormSchema: modelFieldsFormSchema(),
    defaultValues: DEFAULT_MODEL_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};
