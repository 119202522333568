import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import InputMask from "react-input-mask";
import styled, { css } from "styled-components";

export const InsuranceWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  cursor: pointer;
  height: 88px;
  border-radius: 10px;
  align-items: center;

  ${({ active }) => {
    return active
      ? css`
          background-color: rgba(132, 166, 254, 0.16);
          border: 1px solid rgba(72, 123, 255, 0.3);
        `
      : css`
          background-color: white;
          border: 1px solid ${colors.gray400};
        `;
  }}
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  background-color: ${colors.gray4};
  margin: 0 10px 14px 10px;
`;
export const TitleFieldWrapper = styled.div`
  margin: 10px 0;
`;

export const ExistCarFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FieldWrapper = styled.div``;

export const StyledInput = styled(InputMask)`
  border: 1px solid #e4e7ec !important;
  border-radius: 4px;
  color: #353333;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  min-height: 32px;
  background-color: #ffffff;
  padding: 0 10px;
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
`;

export const StyledStatusLabel = styled(Paragraph)`
  margin: 5px 0;
  border-radius: 4px;
  padding: 16px 24px;
  display: inline-block;
  background-color: rgba(132, 166, 254, 0.16);
  color: black;
`;
