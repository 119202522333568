import { colors } from "@/helpers";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { Icon } from "@/packages/icon/Icon";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Column,
  useBlockLayout,
  useExpanded,
  useSortBy,
  useTable,
  HeaderGroup,
  HeaderPropGetter,
} from "react-table";
import { useSticky } from "react-table-sticky";
import styled, { css } from "styled-components";
import { IconButton } from "../icon-button/IconButton";
import { StyledSearchButton } from "../tableSimple/TableSimple.style";
import { t } from "i18next";

export interface IDataItem {
  [key: string]: any;
}

export type SortOrder = null | "asc" | "desc";

export interface IColumn {
  defaultCanSort?: boolean;
  isSearchable?: boolean;
}

export type HeaderArgType = HeaderGroup & IColumn;

export interface IHeaderItem<T extends object> {
  Header: Column<T>["Header"] | ((props: Column<T>) => React.ReactNode);
  accessor?: string;
  columns?: IHeaderItem<T>[];
  subHeaders?: IHeaderItem<T>[];
  id?: Column<T>["id"];
  Cell?: (arg: { row: { original: T }; value: any }) => React.ReactNode;
  defaultCanSort?: boolean;
  isSearchable?: boolean;
  sortType?: "ASC" | "DESC" | null;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  sticky?: "left" | "right";
  tip?: string;
  Footer?: Column<T>["Footer"] | ((props: Column<T>) => React.ReactNode);
  isFooterHidden?: boolean;
  color?: string;
}

interface IStickyTable<T extends object = object> {
  headers: IHeaderItem<T>[];
  data: T[];
  buttonIcons?: Array<string>;
  isButtonDropdown?: boolean;
  onSort?: (header: HeaderArgType, order: SortOrder) => void;
  onCellClick?: (cell: { rowIndex: number; accessor: string }) => void;
  onSearch?: (key: string, value?: string) => void;
  className?: string;
  rowSpace?: number;
}

const StyledTable = styled.div`
  overflow-x: auto;
  min-width: auto !important;

  [data-sticky-last-left-td] {
    box-shadow: 2px 0 15px rgba(33, 33, 52, 0.1);
    clip-path: inset(0px -15px 0px 0px);
  }
`;

const StyledHeaderRow = styled.div`
  box-shadow: 0 2px 15px rgba(33, 33, 52, 0.1);
  border-bottom: 1px solid ${colors.neutral150};
`;

const StyledHeaderCell = styled.div<{ color: string }>`
  background: ${colors.white};
  padding: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ color }) => colors[color]};
  text-align: center;
  box-shadow: none !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:not(:last-child):before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${colors.gray3};
    height: 16px;
    width: 1px;
  }
`;

const StyledSubHeaderCell = styled.div<{
  defaultCanSort: boolean;
  isCentered: boolean;
  color: string;
}>`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 12px;
  margin: 0 !important;
  line-height: 15px;
  padding: 9px 12px;
  background: ${colors.white};
  width: auto;
  color: ${({ color }) => colors[color]};
  ${({ defaultCanSort }) => defaultCanSort && "cursor: pointer;"};
  ${({ isCentered }) =>
    isCentered &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

const StyledCell = styled.div<{
  isCentered: boolean;
}>`
  padding: 12px;
  background: ${colors.white};
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.neutral800};
  width: auto;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:first-child {
    padding-left: 29px;
  }
`;

const StyledRow = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.neutral150};
  }
`;

const StyledSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const StyledHeaderCellContent = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;

const StyledHeaderCellTip = styled.span`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.neutral400};
  margin-top: 4px;
`;

export interface IDefaultColumn {
  minWidth: number;
  width?: number | string;
  maxWidth: number;
}

const DEFAULT_COLUMN: IDefaultColumn = {
  minWidth: 150,
  width: 300,
  maxWidth: 450,
};

export const StickyTable: FC<IStickyTable> = (props) => {
  const { data, headers, onSort, onSearch } = props;

  const columns = headers;

  const { getTableProps, prepareRow, headerGroups, getTableBodyProps, rows } =
    useTable(
      {
        // @ts-ignore
        columns,
        data,
        manualSortBy: true,
        defaultColumn: DEFAULT_COLUMN,
      },
      useBlockLayout,
      useSticky,
      useSortBy,
      useExpanded
    );

  const HeaderCell = (props) => {
    const { index } = props;
    const HeaderComponent =
      index === 0 ? StyledHeaderCell : StyledSubHeaderCell;

    return <HeaderComponent {...props} />;
  };

  const [sortOrders, setSortOrders] = useState<SortOrder[]>(
    headers.map(() => null)
  );
  useEffect(() => setSortOrders(headers.map(() => null)), [headers]);

  const [searchedField, setSearchedFiled] = useState<string>();

  const handleHeaderClick = useCallback(
    (header: HeaderArgType, index: number) => {
      if (header.defaultCanSort && searchedField !== header.id) {
        const sortOrder = sortOrders[index];
        let newSortOrder: SortOrder = null;
        if (sortOrder === null) newSortOrder = "asc";
        if (sortOrder === "asc") newSortOrder = "desc";
        if (sortOrder === "desc") newSortOrder = null;
        const newSortOrders: SortOrder[] = sortOrders;
        newSortOrders[index] = newSortOrder;
        setSortOrders(newSortOrders);

        if (onSort) onSort(header, newSortOrder);
      }
    },
    [headers, onSort]
  );

  return (
    <StyledTable {...getTableProps()} className="table sticky">
      <div className="header">
        {headerGroups.map((headerGroup, headerLevelIndex) => (
          <StyledHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => {
              // const { onClick } = column.getSortByToggleProps();
              const sortOrder = sortOrders[index];
              return (
                <HeaderCell
                  index={headerLevelIndex}
                  defaultCanSort={column.defaultCanSort}
                  isCentered={!!column.isCentered}
                  onClick={() => handleHeaderClick(column, index)}
                  // @ts-ignore
                  color={column?.color || "black"}
                  style={
                    column.getHeaderProps(column as HeaderPropGetter<IColumn>)
                      .style
                  }
                >
                  <StyledHeaderCellContent>
                    {searchedField === column.id ? (
                      <Search
                        onChange={(value) => {
                          if (value) {
                            setTimeout(() => {
                              onSearch && onSearch(searchedField, value);
                            }, 1500);
                          } else {
                            onSearch && onSearch(searchedField, value);
                          }
                        }}
                        onClear={() => setSearchedFiled("")}
                      />
                    ) : (
                      <>
                        {
                          //@ts-ignore
                          column.isSearchable ? (
                            <StyledSearchButton>
                              <IconButton
                                icon="search-16"
                                onClick={(e) => setSearchedFiled(column.id)}
                                size="m"
                                variant="secondary"
                                isSquared={true}
                                color="black"
                              />
                            </StyledSearchButton>
                          ) : (
                            <></>
                          )
                        }
                        {typeof column.render("Header") === "string"
                          ? t(`${column.render("Header")}`)
                          : ""}
                        {column.defaultCanSort && (
                          <StyledSortIcon>
                            <Icon
                              size={8}
                              name="chevron-sort-up-8"
                              // color={sortOrder === "asc" ? "black" : "gray400"}
                              color={"black"}
                            />
                            <Icon
                              size={8}
                              name="chevron-sort-down-8"
                              // color={sortOrder === "desc" ? "black" : "gray400"}
                              color={"black"}
                            />
                          </StyledSortIcon>
                        )}
                      </>
                    )}
                  </StyledHeaderCellContent>
                  {column.tip ? (
                    <StyledHeaderCellTip>{column.tip}</StyledHeaderCellTip>
                  ) : null}
                </HeaderCell>
              );
            })}
          </StyledHeaderRow>
        ))}
      </div>
      <div {...getTableBodyProps()} className="body">
        {rows.map((row) => {
          prepareRow(row);

          return (
            <StyledRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <StyledCell
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                    }}
                    isCentered={!!cell.column.isCentered}
                  >
                    {cell.render("Cell")}
                  </StyledCell>
                );
              })}
            </StyledRow>
          );
        })}
      </div>
    </StyledTable>
  );
};
