import React from "react";
import { InputProps } from "antd";
import { Icon } from "@/packages/icon/Icon";

import {
  InputIcon,
  StyledInputWrapper,
  StyledInputItem,
  StyledPasswordIcon,
} from "./index.styles";

export interface IInput extends Omit<InputProps, "onChange"> {
  className?: string;
  icon?: string;
  isEditable?: boolean;
  error?: boolean;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  disableDot?: boolean;
}

export const Input: React.FC<IInput> = (props) => {
  const {
    icon,
    error,
    onChange,
    className,
    isEditable = true,
    disableDot = false,
    ...inputProps
  } = props;

  const onChangeHandler:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = onChange
    ? (e) => {
        if (disableDot) {
          onChange(e.target.value.replaceAll(".", ""), e);
        } else {
          onChange(e.target.value, e);
        }
      }
    : undefined;

  return (
    <StyledInputWrapper className={className}>
      {icon ? (
        <InputIcon
          name={icon}
          size={16}
          color="black"
          disabled={inputProps.disabled}
        />
      ) : (
        <></>
      )}

      <StyledInputItem
        {...inputProps}
        onChange={onChangeHandler}
        error={error}
        isEditable={isEditable}
        iconRender={(visible) =>
          visible ? (
            <StyledPasswordIcon>
              <Icon name="eye-open" size={16} />
            </StyledPasswordIcon>
          ) : (
            <StyledPasswordIcon>
              <Icon name="eye-close" size={16} />
            </StyledPasswordIcon>
          )
        }
      />
    </StyledInputWrapper>
  );
};
