import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TDeleteMaterialFileResponse,
  TDeleteMaterialSectionRequest,
  TDeleteMaterialSectionResponse,
  TGetMaterialFilesByIdRequest,
  TGetMaterialFilesByIdResponse,
  TGetMaterialFilesRequest,
  TGetMaterialFilesResponse,
  TGetMaterialSectionByIdRequest,
  TGetMaterialSectionByIdResponse,
  TGetMaterialSectionUploadRequest,
  TGetMaterialSectionUploadResponse,
  TGetMaterialsSectionsBatchRequest,
  TGetMaterialsSectionsBatchResponse,
  TGetMaterialsSectionsRequest,
  TGetMaterialsSectionsResponse,
  TPatchMaterialsSectionsRequest,
  TPatchMaterialsSectionsResponse,
  TPostMaterialFilesRequest,
  TPostMaterialFilesResponse,
} from "./types";

export const useGetMaterialsSectionsBatch = (request: TGetMaterialsSectionsBatchRequest) => {
  return useQuery(["materials", "sections", "batch", request], () => {
    return api.get<TGetMaterialsSectionsBatchResponse>(
      "/materials-sections/batch",
      { params: request }
    );
  });
};

export const useGetMaterialsSections = (
  request: TGetMaterialsSectionsRequest
) => {
  return useQuery(["materials", "sections"], () => {
    return api.get<TGetMaterialsSectionsResponse>("/materials-sections", {
      params: request,
    });
  });
};

export const useGetMaterialSectionById = (
  request: TGetMaterialSectionByIdRequest
) => {
  return useQuery(["materials", "list", request.id], () => {
    return api.get<TGetMaterialSectionByIdResponse>(
      `/materials-sections/${request.id}`
    );
  });
};

export const useDeleteMaterialSection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TDeleteMaterialSectionRequest) => {
      return api.delete<TDeleteMaterialSectionResponse>(
        `/materials-sections/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["materials", "detail", variables.id]);
        queryClient.invalidateQueries(["materials", "list"]);
      },
    }
  );
};

export const usePatchMaterialsSections = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPatchMaterialsSectionsRequest) => {
      return api.patch<TPatchMaterialsSectionsResponse>(
        "/materials-sections",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["batch"]);
        queryClient.invalidateQueries(["materials", "detail"]);
        queryClient.invalidateQueries(["materials", "list"]);
      },
    }
  );
};

export const usePostMaterialFiles = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPostMaterialFilesRequest) => {
      return api.post<TPostMaterialFilesResponse>(`/materials-files`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["materials", "list"]);
      },
    }
  );
};

export const useGetMaterialFiles = (request: TGetMaterialFilesRequest) => {
  return useQuery(["materials", "list", request], () => {
    return api.get<TGetMaterialFilesResponse>("/materials-files", { params: request });
  });
};

export const useGetMaterialFilesById = (
  request: TGetMaterialFilesByIdRequest
) => {
  return useQuery(["materials", "list", request.id], () => {
    return api.get<TGetMaterialFilesByIdResponse>(
      `/materials-files/${request.id}`
    );
  });
};

export const useDeleteMaterialFile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) => {
      return api.delete<TDeleteMaterialFileResponse>("/materials-files", data);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["materials", "detail", variables.id]);
        queryClient.invalidateQueries(["materials", "list"]);
      },
    }
  );
};

export const useUploadMaterialSection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: TGetMaterialSectionUploadRequest) => {
      return api.post<TGetMaterialSectionUploadResponse>(
        `/materials-sections/${request.id}/get-archive`,
        //@ts-ignore
        request.body,
        { responseType: "blob" }
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["materials", "detail", variables.id]);
        queryClient.invalidateQueries(["materials", "list"]);
      },
    }
  );
};

// export const useUploadMaterialSection = (
//   request: TGetMaterialSectionUploadRequest
// ) => {
//   return useQuery(["materials", "list", request.id], () => {
//     return api.get<TGetMaterialFilesByIdResponse>(
//       `/materials-sections/archive/${request.id}`
//     );
//   });
// };
