import React from "react";
import { Col, Row } from "antd";

import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormCodeListField } from "@/packages/formElements/formFields/FormCodeListField";
import { FormYearRangeInputField } from "@/packages/formElements/formFields/FormYearRangeInputField";
import { StyledFormLayout, StyledModelFieldsWrapper } from "./index.styles";
import { ModelFormFieldsEnum } from "./types";
import { useTranslation } from "react-i18next";

interface IModelFields {
  isReadOnly?: boolean;
}

const _ModelFields: React.FC<IModelFields> = (props) => {
  const { isReadOnly = false } = props;
  const { t } = useTranslation();

  return (
    <StyledModelFieldsWrapper>
      <StyledFormLayout>
        <Row gutter={16}>
          <Col span={12}>
            <FormInputField
              name={ModelFormFieldsEnum.Name}
              label={t("models.addNewModelFrom.name") || ""}
              description={t("models.addNewModelFrom.nameDescription") || ""}
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={12}>
            <FormYearRangeInputField
              name={ModelFormFieldsEnum.YearRange}
              label={t("models.addNewModelFrom.date") || ""}
              description={t("models.addNewModelFrom.dateDescription") || ""}
              placeholder={[
                t("models.addNewModelFrom.datePlaceholder_1") || "",
                t("models.addNewModelFrom.datePlaceholder_2") || "",
              ]}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormYearRangeInputField
              name={ModelFormFieldsEnum.ProductionYearRange}
              label={t("models.addNewModelFrom.dateProduction") || ""}
              description={
                t("models.addNewModelFrom.dateProductionDescription") || ""
              }
              placeholder={[
                t("models.addNewModelFrom.datePlaceholder_1") || "",
                t("models.addNewModelFrom.datePlaceholder_1") || "",
              ]}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormCodeListField
              name={ModelFormFieldsEnum.VinIndexes}
              label={t("models.addNewModelFrom.VIN_1") || ""}
              description={t("models.addNewModelFrom.VIN_1_Description") || ""}
              placeholder="XWE"
              addButtonText={t("models.addNewModelFrom.VIN_1_Btn") || ""}
              readOnly={isReadOnly}
            />
          </Col>
        </Row>
      </StyledFormLayout>
    </StyledModelFieldsWrapper>
  );
};

export const ModelFields = React.memo(_ModelFields);
