import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum MonitoringDealersFilterFormFieldsNameEnum {
  Names = "names",
  CityNames = "cityNames",
  Codes = "codes",
  SubmittedCars = "submittedCars",
  Familiarize = "familiarize",
}

export enum MonitoringCarsFilterOptionsNameEnum {
  Cities = "cities",
  Dealers = "dealers",
  Generations = "generations",
  Vins = "vins",
}

export interface IMonitoringDealersFilterFormValues {
  [MonitoringDealersFilterFormFieldsNameEnum.Names]: string[] | undefined;
  [MonitoringDealersFilterFormFieldsNameEnum.CityNames]: string[] | undefined;
  [MonitoringDealersFilterFormFieldsNameEnum.Codes]: string[] | undefined;
  [MonitoringDealersFilterFormFieldsNameEnum.SubmittedCars]:
    | "with-requests"
    | "without-requests"
    | undefined;
  [MonitoringDealersFilterFormFieldsNameEnum.Familiarize]: boolean | undefined;
}

export const DEFAULT_MONITORING_FILTER_FORM_VALUES: IMonitoringDealersFilterFormValues =
  {
    [MonitoringDealersFilterFormFieldsNameEnum.Names]: undefined,
    [MonitoringDealersFilterFormFieldsNameEnum.CityNames]: undefined,
    [MonitoringDealersFilterFormFieldsNameEnum.Codes]: undefined,
    [MonitoringDealersFilterFormFieldsNameEnum.SubmittedCars]: undefined,
    [MonitoringDealersFilterFormFieldsNameEnum.Familiarize]: undefined,
  };

export type TOnSubmitMonitoringFilterFormCb =
  TOnSubmitFormSyncCb<IMonitoringDealersFilterFormValues>;

export const useMonitoringDealersFilterForm = (options: {
  onSubmit: TOnSubmitMonitoringFilterFormCb;
  initValues: IMonitoringDealersFilterFormValues;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<any>({
    defaultValues: DEFAULT_MONITORING_FILTER_FORM_VALUES,
    onSubmit,
  });

  useEffect(() => {
    result.form.reset(initValues, { keepDefaultValues: true });
  }, []);

  return result;
};
