import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export interface IHoldingsFilterFormValues {
  holdingName: string | undefined;
  dealerWarrantyCode: string | undefined;
}

export const DEFAULT_HOLDINGS_FILTER_FORM_VALUES: IHoldingsFilterFormValues = {
  holdingName: undefined,
  dealerWarrantyCode: "",
};

export type TOnSubmitHoldingsFilterFormCb = (
  values: IHoldingsFilterFormValues
) => void;

export const useHoldingsFilterForm = (options: {
  initValues: IHoldingsFilterFormValues;
  onSubmit: TOnSubmitHoldingsFilterFormCb;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<IHoldingsFilterFormValues>({
    defaultValues: DEFAULT_HOLDINGS_FILTER_FORM_VALUES,
    onSubmit,
  });

  const onClearHandler = () => {
    result.form.reset();
    onSubmit(result.form.getValues());
  };

  useEffect(() => {
    if (initValues) {
      result.form.reset(initValues, { keepDefaultValues: true });
      onSubmit(result.form.getValues());
    }
  }, []);

  return {
    form: result.form,
    submit: result.submit,
    onClearHandler,
  };
};
