import { IdentityFormCard } from "@/components/IdentityFormCard";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import React from "react";
import { FormProvider } from "react-hook-form";
import { TOnSubmitResetPasswordFormCb, useResetPasswordForm } from "./hooks";

import { StyledFormLayout, StyledFormTitle } from "./index.styles";

export type ResetPasswordFormProps = {
  onSubmit: TOnSubmitResetPasswordFormCb;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
}) => {
  const { form, submit } = useResetPasswordForm(onSubmit);

  return (
    <IdentityFormCard>
      <StyledFormTitle level={1} weight={400}>
        Изменение пароля
      </StyledFormTitle>
      <FormProvider {...form}>
        <StyledFormLayout>
          <FormInputField
            name="password"
            type="password"
            label="Пароль"
            placeholder="Введите пароль"
          />
          <FormInputField
            name="repeatPassword"
            type="password"
            label="Повторите пароль"
            placeholder="Введите пароль"
          />
        </StyledFormLayout>

        <Button
          isDisabled={!form.formState.isValid}
          onClick={submit}
          isBlock={true}
          theme="primary"
          color="brand"
          size="middle"
        >
          Сохранить
        </Button>
      </FormProvider>
    </IdentityFormCard>
  );
};
