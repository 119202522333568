import React from "react";
import styled, { ThemeProvider, css, Theme } from "styled-components";
import { TColorName, colors, media } from "@/helpers";
import { themes } from "./themes";

interface IStyledTitle {
  tag: string;
  color: string;
  weight: number;
  children: React.ReactNode;
  className?: string;
}

type TitleLevel = 1 | 2 | 3 | 4 | 5;

interface ITitle {
  children: React.ReactNode;
  level: TitleLevel;
  weight?: number;
  color?: TColorName;
  className?: string;
}

const TitleWithTag = (props: IStyledTitle) =>
  React.createElement(props.tag, { ...props, tag: null }, props.children);

const StyledTitle = styled((props: IStyledTitle) =>
  TitleWithTag(props)
)<IStyledTitle>`
  font-weight: ${(props) => props.weight};
  color: ${(props) => colors[props.color]};

  font-size: ${(props) => props.theme.mobile.fontSize};
  line-height: ${(props) => props.theme.mobile.lineHeight};

  ${(props) =>
    props.theme.tablet &&
    css`
      ${media.tablet} {
        font-size: ${props.theme.tablet.fontSize};
        line-height: ${props.theme.tablet.lineHeight};
      }
    `}

  ${(props) =>
    props.theme.laptop &&
    css`
      ${media.laptop} {
        font-size: ${props.theme.laptop.fontSize};
        line-height: ${props.theme.laptop.lineHeight};
      }
    `}

  ${(props) =>
    props.theme.desktop &&
    css`
      ${media.desktop} {
        font-size: ${props.theme.desktop.fontSize};
        line-height: ${props.theme.desktop.lineHeight};
      }
    `}
`;

const Title: React.FC<ITitle> = ({
  children,
  level,
  weight = 600,
  color = "gray-700",
  className,
}): JSX.Element => {
  const tag: string = ["h1", "h2", "h3", "h4", "h5"][level - 1];

  const adjustedTheme = (ancestorTheme: Theme) => ({
    ...ancestorTheme,
    ...themes[tag],
  });

  return (
    <ThemeProvider theme={adjustedTheme}>
      <StyledTitle
        tag={tag}
        color={color}
        weight={weight}
        className={className}
      >
        {children}
      </StyledTitle>
    </ThemeProvider>
  );
};
export { Title };
