import React, { useState } from "react";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { StyledButtons, StyledSelectField } from "./index.styles";
import { Input } from "@/packages/formElements/inputs/Input";
import { TAuditPlanSetStatusRequest, TAuditTaskDto, TAuditUpdateTaskRequest, } from "@/helpers/api/audit-plan/types";
import { Datepicker } from "@/packages/formElements/inputs/Datepicker";
import styled from "styled-components";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { colors } from "@/helpers";
import { TaskStatus } from "../Tasks/TasksListTable/helpers";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import { t } from "i18next";
import { Badge } from "antd";

const StyledData = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const MOCK_STATUS_VALUES = [
  { label: "Принято", value: "accepted" },
  { label: "В работе у дилера", value: "in_work" },
  { label: "Проверить", value: "review" },
];

interface ITableInput {
  onEditField: (request: TAuditUpdateTaskRequest) => void;
  name: string;
  id: number;
  type?: "text" | "date";
}

export const TableInput: React.FC<ITableInput> = ({
                                                    onEditField,
                                                    id,
                                                    name,
                                                    type = "text",
                                                  }) => {
  let initValue;
  switch (type) {
    case "text":
      initValue = "";
  }
  const [value, setValue] = useState(initValue);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      {type === "date" && (
        <Datepicker
          value={value}
          onChange={(value) =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
        />
      )}
      {type === "text" && (
        <Input
          value={value}
          onChange={(value) => setValue(value)}
          onBlur={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
          onPressEnter={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
        />
      )}
    </div>
  );
};

export const getDefaultAuditPlanTableHeaders = <
  TRecord extends object
>(options: {
  isDealerKia: boolean;
  onSetTaskStatus: (
    id: number,
    patch: TAuditPlanSetStatusRequest["patch"]
  ) => void;
  onMessageClick: ({
                     taskId,
                     status,
                   }: {
    taskId: number;
    status: string;
  }) => void;
}): IHeaderItem<TAuditTaskDto>[] => {
  const { onMessageClick, onSetTaskStatus, isDealerKia } = options;

  return [
    {
      Header: t("warrantyAudits.list.id") || "",
      accessor: "dealerTaskId",
      width: 78,
      minWidth: 78,
      maxWidth: 78,
    },
    {
      Header: t("audits.tasks.audit") || "",
      accessor: "audit.title",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      Cell: ({ row: { original }, value }) => {
        return value ? (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true}/>
        ) : (
          ""
        );
      },
    },
    {
      Header: t("audits.list.checkpoint") || "",
      accessor: "title",
      width: 170,
      minWidth: 170,
      maxWidth: 170,
      Cell: ({ row: { original }, value }) => {
        return value ? (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true}/>
        ) : (
          ""
        );
      },
    },
    {
      Header: t("audits.templates.plan") || "",
      accessor: "task",
      width: 170,
      minWidth: 170,
      maxWidth: 170,
      Cell: ({ row: { original }, value }) => {
        return value ? (
          <TextAreaField value={value} onChange={() => ({})} readOnly={true}/>
        ) : (
          ""
        );
      },
    },
    {
      Header: t("audits.list.weight") || "",
      accessor: "weight",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("audits.tasks.responsibleId") || "",
      accessor: "responsibleId",
      width: 105,
      maxWidth: 105,
      minWidth: 105,
      Cell: ({ row: { original }, value }) => {
        return `${original?.responsible?.firstName} ${original?.responsible?.lastName}`;
      },
    },
    {
      Header: t("warrantyAudits.list.deadline") || "",
      accessor: "deadline",
      width: 125,
      maxWidth: 125,
      minWidth: 125,
      Cell: ({ row: { original }, value }) => {
        const isOld = new Date() > new Date(value);
        return value ? (
          <StyledData
            style={{
              color: isOld ? "#E32446" : "black",
            }}
          >
            <Icon
              size={16}
              name="calendar-16"
              color={isOld ? "redStatus10" : "black"}
            />
            <Paragraph size={12} weight={300}>
              {new Date(value).toLocaleDateString()}
            </Paragraph>
          </StyledData>
        ) : (
          "-"
        );
      },
    },
    {
      Header: t("audits.tasks.completedAt") || "",
      accessor: "completedAt",
      width: 125,
      maxWidth: 125,
      minWidth: 125,
      Cell: ({ row: { original }, value }) => {
        if (value) {
          const date = new Date(value).toLocaleDateString();

          return (
            <StyledData>
              <Icon size={16} name="calendar-16"/>
              <Paragraph size={12} weight={300}>
                {date}
              </Paragraph>
            </StyledData>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: t("audits.tasks.status") || "",
      accessor: "status",
      width: 170,
      minWidth: 170,
      maxWidth: 170,
      Cell: ({ row: { original }, value }) => {
        let color;
        switch (value) {
          case "review": {
            color = colors.green100;

            break;
          }
          case "in_work": {
            color = colors.gray100;

            break;
          }
          case "accepted": {
            color = colors.yellow;

            break;
          }
        }

        return isDealerKia ? (
          <StyledSelectField
            options={MOCK_STATUS_VALUES.filter((item) => !!item.value)}
            value={MOCK_STATUS_VALUES.find(
              (item) => item.value === value?.toString()
            )}
            defaultValue={original?.status || null}
            onChange={(value, option) => {
              if (!Array.isArray(option)) {
                onSetTaskStatus(original.id, {
                  status: value,
                });
              }
            }}
            color={color}
          />
        ) : (
          <TaskStatus status={value}/>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        const unreadCount = original.unreadMessagesAmount;
        return (
          <StyledButtons>
            <Badge count={unreadCount}>
              <IconButton
                icon="chat"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() =>
                  onMessageClick({ taskId: original.id, status: original.status })
                }
              />
            </Badge>
          </StyledButtons>
        );
      },
    },
  ];
};
