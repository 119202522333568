import { colors, media, mediaRevers } from "@/helpers";

import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const StyledInfoGrid = styled.div`
  display: grid;
  gap: 16px 24px;

  ${media.mobile} {
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }

  ${media.desktop} {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }
`;
const StyledMainCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;

  padding: 36px 24px;
  background: ${colors.white};
  border-radius: 8px;

  ${media.mobile} {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
  }

  ${media.desktop} {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }
`;

const StyledInfoCard = styled.div`
  padding: 16px;
  background: ${colors.white};
  border-radius: 8px;

  ${mediaRevers.tablet} {
    grid-column: 1 / span 3;
  }
`;

const StyledValues = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ParagraphWithSpace = styled(Paragraph)<{ space: number }>`
  margin-bottom: ${({ space }) => space}px;
`;

export { StyledInfoGrid, StyledInfoCard, StyledMainCard, StyledValues, ParagraphWithSpace };
