import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { useGetInformationLetters } from "@/helpers/api/InformationalLetters/hooks";
import { TInformationLettersRecord } from "@/helpers/api/InformationalLetters/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StyledTable, StyledText } from "./index.styles";
import { formatBytes } from "./utils";
import { useTranslation } from "react-i18next";
import { isAdminUserBySlug } from "@/helpers/utils";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export interface IRegistryTable {
  onOpenEditModal?: (record: TInformationLettersRecord) => void;
  onDeleteIp: (record: TInformationLettersRecord) => void;
  parrentId: string;
  searchValue: string;
}

export const InformationalLettersTable: React.FC<IRegistryTable> = (props) => {
  const { parrentId, onOpenEditModal, searchValue, ...rest } = props;

  const {
    state: { profile },
  } = useProfile();
  const { t } = useTranslation();
  const { data: informationLetters, refetch: refetchInformationalLettersGet } =
    useGetInformationLetters({
      sectionId: parrentId,
      search: searchValue,
    });
  const navigate = useNavigate();
  const { dispatch } = useUserInfoModel();

  const informationLettersList = informationLetters?.data.data.map(
    (informationLetter) => {
      const {
        createdAt,
        createdBy,
        description,
        files,
        id,
        name,
        number,
        roles,
      } = informationLetter;

      return {
        id,
        name,
        number,
        description,
        createdBy,
        createdAt: new Date(createdAt).toLocaleDateString(),
        createdByName: {
          firstLine: `${createdBy.firstName} ${createdBy.lastName}`,
          secondLine: createdBy.firstName,
        },
        files: files.length,
        fileIds: files,
        size:
          files.length === 0
            ? "-"
            : formatBytes(
              files.reduce((prev, curr) => prev + Number(curr.size), 0)),
        roleIds: roles.map((role) => {
          return {
            value: role.id,
            label: role.name,
          };
        }),
      };
    }
  );
  useEffect(() => {
    refetchInformationalLettersGet();
  }, [refetchInformationalLettersGet]);

  const headers: IHeaderItem<TInformationLettersRecord>[] = [
    {
      Header: t("dealerMaterials.letters.name") || "",
      accessor: "name",
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      Cell: ({ value }) => {
        return (
          <>
            <StyledText size={12}>{value}</StyledText>
          </>
        );
      },
    },

    {
      Header: t("dealerMaterials.letters.newIP") || "",
      accessor: "number",
      width: 200,
    },

    {
      Header: t("dealerMaterials.letters.filesQuantity") || "",
      accessor: "files",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("dealerMaterials.letters.filesSize") || "",
      accessor: "size",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("dealerMaterials.letters.createdByName") || "",
      accessor: "createdByName",
      width: 150,
      Cell: (data) => {
        //@ts-ignore
        const id = data.cell.row.id;
        return (
          <>
            {/* @ts-ignore */}
            <StyledUserNameWrapper onClick={() => dispatch({ userId: data.data[id].createdBy.id })}>
              {/* @ts-ignore */}
              {data.data[id].createdByName.firstLine}
            </StyledUserNameWrapper>
          </>
        );
      },
    },
    {
      Header: t("dealerMaterials.letters.createdAt") || "",
      accessor: "createdAt",
      width: 80,
    },

    {
      Header: "",
      id: "actions",
      width: 80,

      Cell: (data) => {
        const onClickEditHandler = onOpenEditModal
          ? () => {
            onOpenEditModal(data.row.original);
          }
          : undefined;

        return (
          <Space>
            <Link to={`/information-letters/${data.row.original.id}`}>
              <IconButton
                icon="info"
                color="black"
                size="l"
                variant="tertiary"
              />
            </Link>
            <PermissionsAdapter
              resourceName="information-letters"
              resourceAction="update"
            >
              {(data.row.original.createdBy.id === profile?.id ||
                isAdminUserBySlug(profile?.role?.slug)) && (
                <IconButton
                  icon="edit"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={onClickEditHandler}
                />
              )}
            </PermissionsAdapter>
            {profile?.type === "kia" && (
              <Link
                to={`/information-letters/statistic/${data.row.original.id}`}
              >
                <IconButton
                  icon="gear"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Link>
            )}
          </Space>
        );
      },
    },
  ];

  const handleCellClick = (cell: { rowIndex: number; accessor: string }) => {
    const { rowIndex, accessor } = cell;
    if (accessor === "actions" || accessor === "createdByName") return;

    navigate(`/information-letters/${informationLetters?.data.data[rowIndex].id}`);
  };

  return (
    <StyledTable
      // @ts-ignore
      data={informationLettersList ?? []}
      headers={headers}
      onCellClick={handleCellClick}
    />
  );
};
