import { TGetDealerListFiltersResponse } from "@/helpers/api/dc/dealers/types";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Row, Col } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  IStatisticFilterFormValues,
  StatisticFilterFormFieldsNameEnum,
  TOnSubmitStatisticFilterFormCb,
  useStatisticFilterForm,
} from "./hooks";
import {
  StyledButtonsContainer,
  StyledFormLayout,
  StyledRegistryFilterWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IStatisticFilter {
  initValues: IStatisticFilterFormValues;
  filterOptions?: TGetDealerListFiltersResponse;
  onFiltersChange: TOnSubmitStatisticFilterFormCb;
}

export const StatisticFilter: React.FC<IStatisticFilter> = (props) => {
  const { initValues, filterOptions, onFiltersChange } = props;

  const { form, submit, onClearHandler } = useStatisticFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });
  const { t } = useTranslation();
  return (
    <StyledRegistryFilterWrapper>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={8}>
            <FormSelect
              name={StatisticFilterFormFieldsNameEnum.DealerName}
              label={t("dealerMaterials.letters.dealerName") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={filterOptions?.nameOptions}
              allowClear
              isSearchShow={true}
            />
          </Col>

          <Col span={8}>
            <FormSelect
              name={StatisticFilterFormFieldsNameEnum.City}
              options={filterOptions?.cityOptions}
              label={t("dealerMaterials.letters.city") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              allowClear
              isSearchShow={true}
            />
          </Col>

          <Col span={8}>
            <FormInputField
              name={StatisticFilterFormFieldsNameEnum.WarrantyCode}
              label={t("dealerMaterials.letters.warrantyCode") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              allowClear
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormInputField
              name={StatisticFilterFormFieldsNameEnum.SapCode}
              label={t("dealerMaterials.letters.sapCode") || ""}
            />
          </Col>
          <Col span={8}>
            <FormInputField
              label={t("dealerMaterials.letters.mobisCode") || ""}
              name={StatisticFilterFormFieldsNameEnum.MobisCode}
            />
          </Col>

          <Col span={8}>
            <FormSelect
              name={StatisticFilterFormFieldsNameEnum.Status}
              label={t("dealerMaterials.letters.status") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={[
                {
                  value: "Ознакомлен",
                  label: t("dealerMaterials.letters.familiarized") || "",
                },
                {
                  value: "Не ознакомлен",
                  label: t("dealerMaterials.letters.notFamiliarized") || "",
                },
              ]}
            />
          </Col>
        </Row>
      </FormProvider>

      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("dealerMaterials.letters.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("dealerMaterials.letters.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledRegistryFilterWrapper>
  );
};
