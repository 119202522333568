import { TextArea } from "@/packages/formElements/inputs/TextArea";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledInput = styled(TextArea)`
  width: 100%;
  height: 100%;
  max-height: 130px;
  border: none;
  margin: 0 18px;

  &:focus,
  &:hover,
  &:active {
    border: none;
    box-shadow: none;
  }
  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: none;
    box-shadow: none;
  }
`;

interface IMessageTextarea {
  message: string;
  onChange: (value: string) => void;
}

export const MessageTextarea: React.FC<IMessageTextarea> = (props) => {
  const { message, onChange } = props;
  const { t } = useTranslation();
  return (
    <StyledInput
      value={message}
      bordered={false}
      placeholder={t("audits.list.sendMessage") || ""}
      autoSize={{ minRows: 1 }}
      onChange={(value) => onChange(value)}
    />
  );
};
