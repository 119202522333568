import { InputField } from "@/packages/formElements/fields/InputField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IBatchResponseToMutate } from "@/pages/InformationalLetters/utils";
import React, { FC, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  StyledChildren,
  StyledFormEditBatchModal,
  StyledInputWrapper,
} from "./index.styles";
import { ITreeProps } from "./Tree";
import { removeIdDeep } from "./utils";
import { useTranslation } from "react-i18next";

export interface IRowComponentProps extends Omit<ITreeProps, "data"> {
  section: IBatchResponseToMutate;
  children: React.ReactNode;
  isLastChild: number | undefined;
}

const RowComponent: FC<IRowComponentProps> = (props) => {
  const {
    section,
    sections,
    setSections,
    children,
    onActionHandler,
    isLastChild,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [inputSection, setInputSection] = useState(section);

  const addSubSectionHandler = useCallback(() => {
    const { id, order, level, children, depth } = section;

    const index = children ? children.length + 1 : 1;

    const newSubSection = {
      id: uuidv4(),
      depth: depth + 1,
      parrent_id: id,
      level: `${level}.${index}`,
      order: section.children ? section.children.length + 1 : 0,
    };
    section.children = section.children
      ? [...section.children, newSubSection]
      : [newSubSection];

    setSections(JSON.parse(JSON.stringify(sections)));
    onActionHandler("add", newSubSection);
  }, [section, setSections, sections, onActionHandler]);

  const deleteSectionHandler = useCallback(() => {
    const filteredSections = removeIdDeep(sections, section.id);
    setSections(JSON.parse(JSON.stringify(filteredSections)));
    onActionHandler("delete", section);
  }, [section, setSections, sections, onActionHandler]);

  const editSection = (prop: string, event: string) => {
    setInputSection((prev) => ({ ...prev, name: event }));
    section[prop] = event;
    onActionHandler("edit", section);
  };

  return (
    <StyledFormEditBatchModal>
      <StyledInputWrapper>
        <InputField
          name={section.id}
          label={t("dealerMaterials.letters.sectionName") || ""}
          value={inputSection.name}
          prefix={inputSection.level}
          isBlock
          onChange={(event) => editSection("name", event)}
        />
        {isLastChild ? (
          <></>
        ) : (
          <IconButton
            variant="tertiary"
            icon="delete"
            color="brand"
            size="l"
            onClick={deleteSectionHandler}
          />
        )}
      </StyledInputWrapper>

      <IconButton
        variant="tertiary"
        icon="plus"
        color="brand"
        onClick={addSubSectionHandler}
      >
        {t("dealerMaterials.letters.addSection_2") || ""}
      </IconButton>

      {children ? <StyledChildren>{children}</StyledChildren> : <></>}
    </StyledFormEditBatchModal>
  );
};

export default RowComponent;
