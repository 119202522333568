import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";

import * as yup from "yup";
import { ModelFieldsName } from "../../KiaFlexCar/KiaFlexFormsFields/ModelFields/types";
import { transformDataToDTOCreateOrders, transformDTOtoRecord, transformValueToDto, } from "./util";
import { t } from "i18next";

export interface IKiaFlexFromValues {
  [ModelFieldsName.MODEL]: string | null;
  [ModelFieldsName.GENERATION]: string | null;
  [ModelFieldsName.MODIFICATION]: string | null;
  [ModelFieldsName.EQUIPMENT]: string | null;
  [ModelFieldsName.COLOR]: string | null;
  comment: string | null;
  price?: string | null;
  statusId?: string | null;
  sideCheck?: string | null;
  deliveryDate?: Date | null;
  vin?: string | null;
}

const DEFAULT_KIA_FLEX_VALUES: IKiaFlexFromValues = {
  [ModelFieldsName.MODEL]: null,
  [ModelFieldsName.GENERATION]: null,
  [ModelFieldsName.MODIFICATION]: null,
  [ModelFieldsName.EQUIPMENT]: null,
  [ModelFieldsName.COLOR]: null,
  vin: null,
  comment: null,
  statusId: null,
  deliveryDate: null,
};
const DEFAULT_KIA_FLEX_VALUES_CREATE: IKiaFlexFromValues = {
  [ModelFieldsName.MODEL]: null,
  [ModelFieldsName.GENERATION]: null,
  [ModelFieldsName.MODIFICATION]: null,
  [ModelFieldsName.EQUIPMENT]: null,
  [ModelFieldsName.COLOR]: null,
  vin: null,
  comment: null,
};

export const step1FormSchema = yup.object({
  [ModelFieldsName.MODEL]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.GENERATION]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.MODIFICATION]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.EQUIPMENT]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.COLOR]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  comment: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable()
    .min(5, "Минимальная длина комментария - 5")
    .max(1000, "Максимальная длина комментария - 1000"),
});

export const formSchema = yup.object({
  [ModelFieldsName.MODEL]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.GENERATION]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.MODIFICATION]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.EQUIPMENT]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  [ModelFieldsName.COLOR]: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  comment: yup
    .string()
    .required("Это поля обязательно для заполнения")
    .nullable()
    .min(5, "Минимальная длина комментария - 5")
    .max(1000, "Максимальная длина комментария - 1000"),
  statusId: yup.string().notRequired(),
  sideCheck: yup.string().notRequired(),
  deliveryDate: yup
    .date()
    .required("Это поля обязательно для заполнения")
    .nullable(),
  vin: yup
    .string()
    .required(t("park.validation.vinRequired") || "")
    .min(17, t("kiaFlex.vin_17") || "")
    .max(17, t("kiaFlex.vin_17") || "")
    .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
    .uppercase()
    .nullable(),
});

export const editFormSchema = yup.object({
  vin: yup
    .string()
    .required(t("park.validation.vinRequired") || "")
    .min(17, t("kiaFlex.vin_17") || "")
    .max(17, t("kiaFlex.vin_17") || "")
    .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
    .uppercase()
    .nullable(),
  deliveryDate: yup
    .date()
    .required("Это поля обязательно для заполнения")
    .nullable(),
});
export const editFormSchemaDealer = yup.object({
  vin: yup
    .string()
    .required(t("park.validation.vinRequired") || "")
    .min(17, t("kiaFlex.vin_17") || "")
    .max(17, t("kiaFlex.vin_17") || "")
    .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
    .uppercase()
    .nullable(),
});

export type TOnSubmitKiaFlexForm = (values: IKiaFlexFromValues) => void;

export const useCreateOrderForm = (
  onSubmit: TOnSubmitKiaFlexForm,
  step1?: boolean
) => {
  const onSubmitHandler = (values: any) => {
    onSubmit(transformDataToDTOCreateOrders(values));
  };
  return useForm({
    defaultValues: DEFAULT_KIA_FLEX_VALUES_CREATE,
    onSubmit: onSubmitHandler,
    validateFormSchema: step1 ? step1FormSchema : formSchema,
  });
};

export const useEditFlexOrdersForm = (options: {
  editableRecord?: TFlexOrder;
  onSubmit: any;
  status?: any;
  isKia: boolean;
}) => {
  const { editableRecord, onSubmit, status, isKia } = options;

  const statusDelivery = status ? status.status : "";

  const onSubmitHandler = (values) => {
    const data = transformValueToDto(values, editableRecord, isKia);
    onSubmit({ id: editableRecord?.id, patch: data });
  };

  const result = useForm({
    defaultValues: DEFAULT_KIA_FLEX_VALUES,
    onSubmit: onSubmitHandler,
    validateFormSchema:
      statusDelivery === "registration" ||
      statusDelivery === "new" ||
      statusDelivery === "confirmation"
        ? editFormSchemaDealer
        : editFormSchema,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      // @ts-ignore

      reset(transformDTOtoRecord(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
