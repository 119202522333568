import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled, { css } from "styled-components";
import { media } from "@/helpers";

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 32px;
  margin-bottom: 32px;
`;

export const StyledStageContainer = styled.div`
  max-width: 848px;
`;

export const StyledGroupTitle = styled(Paragraph)`
  margin-bottom: 15px;
`;

export const StyledGroup = styled.div`
  margin-bottom: 64px;
`;

export const StyledSelectField = styled(SelectField)`
  width: 188px;
  margin-bottom: 24px;
`;

export const StyledTabType = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  overflow-x: auto;
  padding-bottom: 15px;
`;

interface ITab {
  isActive: boolean;
}

export const StyledTab = styled.div<ITab>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 9px 24px;
  border-radius: 12px;
  width: fit-content;
  white-space: nowrap;
  ${media.tablet} {
  }
  ${media.desktop} {
  }
  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #05141f;
          color: #f9f9fd;
        `
      : css`
          background-color: transparent;
          color: #282a30;
        `}
`;

export const StyledViolationsParagraph = styled(Paragraph)``;
