import { Tabs } from "@/packages/tabs/Tabs";
import { Space } from "antd";
import styled, { css } from "styled-components";

export const StyledSpace = styled(Space)`
  background-color: #f3f4f7;
  width: 100%;
`;

export const StyledTabs = styled(Tabs)`
  ${css`
    && .ant-tabs-tabpane {
      border: none;
    }
  `}
`;
