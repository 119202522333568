import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";

export interface IFlexStatusFilterFormValues {
  fromDate: Date;
  toDate?: Date;
  number?: string;
  vin?: string;
  holding?: string;
  dealer?: string;
  model?: string;
  c2c: boolean;
}

export const DEFAULT_FLEX_STATUS_FILTER_FORM_VALUES: IFlexStatusFilterFormValues =
  {
    fromDate: new Date(),
    toDate: undefined,
    number: undefined,
    vin: undefined,
    holding: undefined,
    dealer: undefined,
    model: undefined,
    c2c: false,
  };

export const formSchema = yup.object({
  fromDate: yup
    .date()
    .nullable()
    .required("Необходимо выбрать дату начала временного периода"),
  // toDate: yup
  //   .date()
  //   .nullable()
  //   .required("Необходимо ввести дату конца временного периода"),
});

export type TOnSubmitFlexStatusFilterFormCb = (
  values: IFlexStatusFilterFormValues
) => void;

export const useFlexStatusFilterForm = (options: {
  initValues: IFlexStatusFilterFormValues | undefined;
  onSubmit: TOnSubmitFlexStatusFilterFormCb;
}) => {
  const form = useForm<IFlexStatusFilterFormValues>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: DEFAULT_FLEX_STATUS_FILTER_FORM_VALUES,
  });

  const { initValues, onSubmit } = options;

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  useEffect(() => {
    if (initValues?.fromDate) {
      form.reset(initValues, { keepDefaultValues: true });
      onSubmit(form.getValues());
    }
  }, []);

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
