import { colors } from "@/helpers";
import { Card, Row } from "antd";
import styled from "styled-components";

export const gridStyleTitle: React.CSSProperties = {
  width: "25%",
  boxShadow: "none",
  background: `${colors.gray20}`,
};

export const gridStyleDesc: React.CSSProperties = {
  width: "75%",
  boxShadow: "none",
  overflow: "hidden",
  wordWrap: "break-word",
};

export const StyledCard = styled(Card)`
  .ant-card-body {
    margin: 0;
    padding: 0;
  }
  background: transparent;
  margin-bottom: 64px;
`;

export const StyledRow = styled(Row)`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.gray3};
  :nth-child(1) {
    border-radius: 12px 0 0 0;
  }
  :last-child {
    border-radius: 0 0 0 12px;
  }
`;
