import { Space } from "antd";
import styled from "styled-components";
import { Select } from "@/packages/formElements/inputs/Select";
import { colors } from "@/helpers";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTableSimple = styled.div`
  overflow: auto;
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)``;

const StyledSelectField = styled(Select)<{ color: string }>`
  color: ${({ color }) => (color ? color : colors.gray100)};
  width: 100%;
  min-width: 160px;
`;

export {
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
};
