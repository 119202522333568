import { TWorkTypeRecord } from "@/helpers/api/maintenanceGrids/works/types";
import { t } from "i18next";

export const getLegendTextForWorkType = (type: TWorkTypeRecord["type"]) => {
  switch (type) {
    case "I":
      return t("maintenanceGrids.worksModel.workForm.inspection") || "";

    case "R":
      return t("maintenanceGrids.worksModel.workForm.replacement") || "";

    case "O":
      return t("maintenanceGrids.worksModel.workForm.optionalWork") || "";
  }
};

export const getLegendTooltipTextForWorkType = (
  type: TWorkTypeRecord["type"]
) => {
  switch (type) {
    case "I":
      return t("maintenanceGrids.gridsModel.viewGrid.inspection_2") || "";

    case "R":
      return t("maintenanceGrids.gridsModel.viewGrid.replacement_2") || "";

    case "O":
      return t("maintenanceGrids.gridsModel.viewGrid.optional_2") || "";
  }
};
