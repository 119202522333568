import React, { useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";

import { AUDIT_TEMPLATES } from "@/constants/routes";
import styled from "styled-components";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Icon } from "@/packages/icon/Icon";
import { Space } from "antd";
import { useModal } from "@/hooks/useModal";
import { useGetAuditTemplateById } from "@/helpers/api/audits/hooks";
import { useParams } from "react-router-dom";
import { Loader } from "@/packages/loader/Loader";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";
import { ChangeAuditNameModal } from "@/components/Audits/ChangeAuditNameModal";
import { AddAuditParametersModals, IUploadAuditModalPayload, } from "@/components/Audits/AddAuditParametersModals";
import { useDeleteAuditParametr, useEditAuditParameter, } from "@/helpers/api/audit-parametrs/hooks";
import { AuditParametersTable } from "@/components/Audits/AuditParametersTable/AuditParametersTable";
import { components } from "@/helpers/api/swagger-scheme";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { AuditEditBatchModal } from "@/components/Audits/AuditEditBatchModal";
import { ConfirmModal } from "@/components/Bonuses/ReportsCalculation/CoefficientTabs/CoefficientValues/ConfirmModal";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const StyledTopContent = styled(Space)`
  display: flex;
  align-items: center;
`;

const StyledTexButtonIcon = styled.div`
  margin-right: 12px;
`;

export type TAuditSections = components["schemas"]["AuditSection"][];

export const mutateArrOfObj = (arrObj: TAuditSections) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    // @ts-ignore
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  return res.map((item, index) => recursive(item, 0, `${index + 1}`));
};

export const AuditEdit: React.FC = () => {
  const { t } = useTranslation();
  const {
    modalState: EditAuditTreeModalState,
    openModal: EditAuditTreeOpenModal,
    closeModal: EditAuditTreeCloseModal,
  } = useModal();

  const {
    modalState: ChangeNameModalState,
    openModal: ChangeNameModalOpen,
    closeModal: ChangeNameModalClose,
  } = useModal();

  const {
    modalState: ParametrsModalState,
    openModal: ParametrsModalOpen,
    closeModal: ParametrsModalClose,
  } = useModal();

  const {
    modalState: openConfirmDeleteModalState,
    openModal: openConfirmDeleteModal,
    closeModal,
  } = useModal();

  const openModal = openConfirmDeleteModal as (state: any) => void;
  const { createNotificationError } = useNotification();
  const params = useParams();
  const auditId = params.id as string;

  const {
    data: audit,
    isLoading,
    refetch: updateTemplate,
  } = useGetAuditTemplateById(auditId);
  const { mutate: deleteParametr } = useDeleteAuditParametr();
  const { mutate: editParam } = useEditAuditParameter();
  const batchSections = mutateArrOfObj(audit?.data.sections || []);

  const handleSuccessChangeName = () => {
    ChangeNameModalClose();
    updateTemplate();
  };
  const [modalPayload, setModalPayload] =
    useState<IUploadAuditModalPayload | null>();
  const handleSuccessAddParams = () => {
    ParametrsModalClose();
    updateTemplate();
    setModalPayload(null);
  };

  const handleDeleteParametr = (id: string) => {
    deleteParametr({ id }, { onSuccess: () => updateTemplate() });
  };

  const handleDeleteCoefficientWithConfirm = (id: string) => {
    openModal({
      textMessage: t("audits.templates.deleteConfirm_2") || "",
      errorMessage: t("audits.templates.deleteConfirmError") || "",
      onAsyncSubmit: () => handleDeleteParametr(id),
      onCloseModal: () => closeModal(),
    });
  };

  const handleChangePhotoParam = (id: string, value: boolean) => {
    editParam({
      id,
      patch: { photo: value },
      onSuccess: updateTemplate,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleSuccessChangeTree = () => {
    EditAuditTreeCloseModal();
    updateTemplate();
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        toBack={AUDIT_TEMPLATES}
        title={audit?.data?.title || ""}
        headerLeft={
          !isLoading ? (
            <PermissionsAdapter
              resourceName="audit.templates"
              resourceAction="update"
            >
              <IconButton
                onClick={() => ChangeNameModalOpen()}
                icon="edit"
                size="l"
                color="black"
                variant="tertiary"
              />
            </PermissionsAdapter>
          ) : (
            <></>
          )
        }
      >
        <>
          {!isLoading ? (
            <PermissionsAdapter
              resourceName="audit.templates"
              resourceAction="update"
              fallback={() => <span>No rights</span>}
            >
              <StyledTopContent size={24}>
                <ButtonText
                  icon={
                    <StyledTexButtonIcon>
                      <Icon name="edit" size={16}/>
                    </StyledTexButtonIcon>
                  }
                  isIconLeft={true}
                  onClick={EditAuditTreeOpenModal}
                >
                  {t("audits.templates.structure") || ""}
                </ButtonText>
              </StyledTopContent>
              <DndProvider backend={HTML5Backend}>
                <TreeComponent
                  data={batchSections || []}
                  onParametrsModalOpen={(id) => {
                    setModalPayload({ sectionId: id });
                    ParametrsModalOpen();
                  }}
                  renderComponent={({ data, level }) => (
                    <AuditParametersTable
                      data={data}
                      level={level}
                      onEditClick={(id) => {
                        setModalPayload({ selectedParamId: id });
                        ParametrsModalOpen();
                      }}
                      onPhotoParamEdit={handleChangePhotoParam}
                      onDeleteParametr={handleDeleteCoefficientWithConfirm}
                    />
                  )}
                />
              </DndProvider>
            </PermissionsAdapter>
          ) : (
            <Loader size={48}/>
          )}
        </>
      </DashboardLayout>
      <AuditEditBatchModal
        isOpen={EditAuditTreeModalState.isOpen}
        onCancel={EditAuditTreeCloseModal}
        onSubmit={() => handleSuccessChangeTree()}
        data={batchSections || []}
        templateId={+auditId}
      />
      {audit?.data ? (
        <ChangeAuditNameModal
          id={+auditId}
          oldName={audit?.data?.title || ""}
          isOpen={ChangeNameModalState.isOpen}
          onCancel={ChangeNameModalClose}
          onSubmit={handleSuccessChangeName}
        />
      ) : (
        <></>
      )}
      {modalPayload ? (
        <AddAuditParametersModals
          payload={modalPayload}
          isOpen={ParametrsModalState.isOpen}
          onSubmit={handleSuccessAddParams}
          onCancel={ParametrsModalClose}
        />
      ) : (
        <></>
      )}
      <ConfirmModal {...openConfirmDeleteModalState} />
    </>
  );
};
