interface IThemes {
  [key: number]: { [key: string]: { [key: string]: string } };
}

export const themes: IThemes = {
  10: {
    mobile: {
      fontSize: "10px",
      lineHeight: "14px",
    },
  },
  12: {
    mobile: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  14: {
    mobile: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  16: {
    mobile: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  18: {
    mobile: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  20: {
    mobile: {
      fontSize: "20px",
      lineHeight: "34px",
    },
  },
  24: {
    mobile: {
      fontSize: "24px",
      lineHeight: "34px",
    },
  },
};
