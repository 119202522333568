import { ISelectField } from "@/packages/formElements/fields/SelectField";
import React, { ReactNode } from "react";
import { FormSelect } from "../FormSelect";
import styled from "styled-components";

export interface IFormSelect extends Omit<ISelectField, "error"> {
  name: string;
  isDisabled?: boolean;
  prefixIcon: ReactNode;
}

const SelectWrapper = styled.div`
  position: relative;
`;

const PrefixIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`;

const StyledFormSelect = styled(FormSelect)`
  padding-left: 28px;
`;

const _FormSelectWithPrefixIcon: React.FC<IFormSelect> = (props) => {
  const { prefixIcon, name, ...inputProps } = props;

  return (
    <SelectWrapper>
      {prefixIcon && <PrefixIconWrapper>{prefixIcon}</PrefixIconWrapper>}
      <StyledFormSelect name={name} {...inputProps} />
    </SelectWrapper>
  );
};
export const FormSelectWithPrefixIcon = React.memo(_FormSelectWithPrefixIcon);
