import { colors } from "@/helpers";
import { Card } from "@/packages/Card";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const StyledStatsTab = styled(Card)`
  margin-top: 24px;
  padding: 24px;
  width: 400px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 24px;
`;

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
`;

export const StyledTableRow = styled.tr``;
export const StyledTableHeaderRow = styled.tr``;

export const StyledTableHeaderCell = styled.th`
  padding-bottom: 4px;
`;

export const StyledTableHeaderText = styled(Paragraph)`
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.gray20};
  display: inline-block;
`;

export const StyledTableDataCell = styled.td`
  padding: 8px 0;
`;
