import { Card } from "@/packages/Card";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledRegistryTableExpandDataForm = styled(Card)`
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 24px 12px;
`;

export const StyledFormTitle = styled(Paragraph)`
  margin-bottom: 32px;
`;
