import { InformationLettersCreateData } from "@/components/informationalLetters/InformationLettersCreateData";
import {
  useCreateInformationLetter,
  useGetInformationLetters,
} from "@/helpers/api/InformationalLetters/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const InformationLettersCreate: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const {
    state: { profile },
  } = useProfile();
  const {
    mutateAsync: createInformationLetterAsync,
    isLoading: isLoadingCreateInformationLetter,
  } = useCreateInformationLetter();

  const { data: informationalLetters } = useGetInformationLetters({
    sectionId: params.id as string,
  });

  const onCreateInformationLetterHandler = useCallback(
    async (values) => {
      try {
        let matchNumberIp;
        informationalLetters?.data.data.map((item) =>
          item.number === values.number ? (matchNumberIp = true) : null
        );
        if (matchNumberIp) {
          createNotificationError(
            "Информационное письмо с указанным номером уже существует"
          );
        } else {
          // @ts-ignore
          await createInformationLetterAsync({
            ...values,
            sectionId: params.id,
          });
          createNotificationSuccess("Информационное письмо успешно добавлено!");
          navigate("/information-letters");
        }
      } catch {
        createNotificationError("При создании ИП произошла ошибка");
        throw new Error();
      }
    },
    [
      createInformationLetterAsync,
      createNotificationError,
      createNotificationSuccess,
      navigate,
      params.id,
    ]
  );

  return (
    <>
      {profile?.type === "dealer" ? (
        <DashboardLayout title="Добавление ИП" toBack="/information-letters">
          <span>No rights</span>
        </DashboardLayout>
      ) : (
        <DashboardLayout toBack="/information-letters" title="Добавление ИП">
          <InformationLettersCreateData
            onCreate={onCreateInformationLetterHandler}
          />
        </DashboardLayout>
      )}
    </>
  );
};
