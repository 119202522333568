import { TGenerationRecord } from "@/helpers/api/generations/types";
import { TModelRecord } from "@/helpers/api/models/types";
import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React, { useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { GenerationFields } from "../GenerationFields";
import { GenerationFieldsEnum, IGenerationFormDTOValues, } from "../GenerationFields/types";
import { TOnSubmitCreateGenerationFormCallback, useEditGenerationForm, } from "./hooks";
import { StyledTitleSecondary } from "./index.styles";
import { usePermission } from "@/hooks/usePermission";

export interface IGenerationEditModalSubmitPayload {
  record: TGenerationRecord;
  patch: IGenerationFormDTOValues;
}

export interface IGenerationEditModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  parentModel?: TModelRecord;
  record?: TGenerationRecord;
  onSubmit: (payload: IGenerationEditModalSubmitPayload) => void;
}

export const GenerationEditModal: React.FC<IGenerationEditModal> = (props) => {
  const { onSubmit, record, parentModel, ...rest } = props;

  //@ts-ignore
  const { hasAccess } = usePermission("model", "update");

  const fileIdToDelete = useRef<string | undefined>(undefined);

  useEffect(() => {
    fileIdToDelete.current = record?.photo?.id;
  }, [record])

  const onSubmitHandler: TOnSubmitCreateGenerationFormCallback = (data) => {
    if (record) {
      onSubmit({
        record: record,
        patch: {
          ...data,
          // @ts-ignore на беке поле рестайлинга не может быть нулл, но его как-то надо удалять
          restylingName:
            data.restylingName && data.isRestyling ? data.restylingName : null,
          // @ts-ignore
          generationYearEnd: data.generationYearEnd
            ? data.generationYearEnd
            : null,
          fileIdToDelete: fileIdToDelete.current,
        },
      });
    }
  };


  const { form, submit, isSubmitLoading, reset } = useEditGenerationForm({
    onSubmit: onSubmitHandler,
    editableRecord: record,
  });

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  const [name, isRestyling, restylingName, _yearRange, number] = form.watch([
    GenerationFieldsEnum.Name,
    GenerationFieldsEnum.IsRestyling,
    GenerationFieldsEnum.RestylingName,
    GenerationFieldsEnum.GenerationYearRange,
    GenerationFieldsEnum.Number,
  ]);

  const yearRange = _yearRange as IYearRangeInputFieldValue;

  useEffect(() => {
    form.trigger();
  }, [isRestyling]);

  return (
    <Modal
      {...rest}
      size="large"
      title="Редактирование поколения"
      confirmButtonText="Сохранить изменения"
      cancelButtonText="Отмена"
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
      isDisabledConfirmButton={!hasAccess}
    >
      <TitleDivider size="medium">
        {parentModel?.name} {parentModel?.modelYearStart}
        {parentModel?.modelYearEnd
          ? ` - ${parentModel?.modelYearEnd} `
          : " - ... "}
        <StyledTitleSecondary>
          {`${name} ${restylingName}`} ({`${number} поколение`}
          {isRestyling ? `, рестайлинг` : ""}) {yearRange?.start}
          {yearRange?.end ? ` - ${yearRange?.end}` : ""}
        </StyledTitleSecondary>
      </TitleDivider>
      <FormProvider {...form}>
        <GenerationFields isReadOnly={!hasAccess} parentModel={parentModel}/>
      </FormProvider>
    </Modal>
  );
};
