import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type CreateGsmFormValues = {
  type: string;
  name: string;
  code: string;
  price: number | null;
  isDefault?: boolean;
  volume: number | null;
};

export type CreateSpareFormValues = {
  type: string;
  name: string;
  code: string;
  price: number | null;
};

export type CreateGsmSuccessFormValues = CreateGsmFormValues;

export const DEFAULT_CREATE_GSM_FORM_VALUES: CreateGsmSuccessFormValues = {
  type: "",
  name: "",
  code: "",
  price: null,
  isDefault: false,
  volume: null,
};

export const DEFAULT_CREATE_SPARE_FORM_VALUES: CreateSpareFormValues = {
  type: "",
  name: "",
  code: "",
  price: null,
};

export const formCreateSchema = () => {
  const regexToTestPriceByLengthOfDigitsInDecimalNumber =
    /^[1-9]\d{0,8}(?:\.\d{0,2})?$/;

  return yup
    .object({
      name: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.name") || "")
        .test({
          test: (value) =>
            value ? /^.{5,500}$/g.test(value) : false,
          message:
            t("maintenanceGrids.gsmModel.gsmForm.validation.nameMessage") || "",
        }),
      code: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.code") || ""),
      volume: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.gsmForm.validation.pricePositiveMsg") ||
            ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.gsmForm.validation.priceNumber") || ""
        )
        .test({
          test: (value) =>
            value
              ? regexToTestPriceByLengthOfDigitsInDecimalNumber.test(
                  value.toString()
                )
              : false,
          message:
            t(
              "maintenanceGrids.gsmModel.gsmForm.validation.priceDigitsLengthMsg"
            ) || "",
        })
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.gsmForm.validation.price") || ""
        ),
      price: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.gsmForm.validation.pricePositiveMsg") ||
            ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.gsmForm.validation.priceNumber") || ""
        )
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.gsmForm.validation.price") || ""
        ),
      isDefault: yup.boolean(),
    })
    .required();
};

export const formEditSchema = () => {
  const regexToTestPriceByLengthOfDigitsInDecimalNumber =
    /^[1-9]\d{0,8}(?:\.\d{0,2})?$/;

  return yup
    .object({
      name: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.name") || "")
        .test({
          test: (value) =>
            value ? /^.{5,500}$/g.test(value) : false,
          message:
            t("maintenanceGrids.gsmModel.gsmForm.validation.nameMessage") || "",
        }),
      code: yup
        .string()
        .required("AASDASDAS")
        .nullable(),
      type: yup
        .mixed()
        .oneOf(['material', 'spare'])
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.type") || ""),
      volume: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.gsmForm.validation.pricePositiveMsg") ||
          ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.gsmForm.validation.priceNumber") || ""
        )
        .test({
          test: (value) =>
            value
              ? regexToTestPriceByLengthOfDigitsInDecimalNumber.test(
                value.toString()
              )
              : false,
          message:
            t(
              "maintenanceGrids.gsmModel.gsmForm.validation.priceDigitsLengthMsg"
            ) || "",
        })
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.gsmForm.validation.price") || ""
        ),
      price: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.gsmForm.validation.pricePositiveMsg") ||
          ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.gsmForm.validation.priceNumber") || ""
        )
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.gsmForm.validation.price") || ""
        ),
      isDefault: yup.boolean(),
    })
    .required();
};

export const formSchemaCreateSpares = () => {
  const regexToTestPriceByLengthOfDigitsInDecimalNumber =
    /^[1-9]\d{0,8}(?:\.\d{0,2})?$/;

  return yup
    .object({
      name: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.name") || "")
        .test({
          test: (value) =>
            value ? /^.{5,500}$/g.test(value) : false,
          message:
            t("maintenanceGrids.gsmModel.spareForm.validation.nameMessage") || "",
        }),
      code: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.spareForm.validation.code") || ""),
      price: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.spareForm.validation.pricePositiveMsg") ||
          ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.spareForm.validation.priceNumber") || ""
        )
        .test({
          test: (value) =>
            value
              ? regexToTestPriceByLengthOfDigitsInDecimalNumber.test(
                value.toString()
              )
              : false,
          message:
            t(
              "maintenanceGrids.gsmModel.spareForm.validation.priceDigitsLengthMsg"
            ) || "",
        })
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.spareForm.validation.price") || ""
        ),
    })
    .required();
};

export const formSchemaEditSpares = () => {
  const regexToTestPriceByLengthOfDigitsInDecimalNumber =
    /^[1-9]\d{0,8}(?:\.\d{0,2})?$/;

  return yup
    .object({
      name: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.name") || "")
        .test({
          test: (value) =>
            value ? /^.{5,500}$/g.test(value) : false,
          message:
            t("maintenanceGrids.gsmModel.spareForm.validation.nameMessage") || "",
        }),
      type: yup
        .mixed()
        .oneOf(['material', 'spare'])
        .required(t("maintenanceGrids.gsmModel.gsmForm.validation.type") || ""),
      code: yup
        .string()
        .required(t("maintenanceGrids.gsmModel.spareForm.validation.code") || ""),
      price: yup
        .number()
        .positive(
          t("maintenanceGrids.gsmModel.spareForm.validation.pricePositiveMsg") ||
          ""
        )
        .typeError(
          t("maintenanceGrids.gsmModel.spareForm.validation.priceNumber") || ""
        )
        .test({
          test: (value) =>
            value
              ? regexToTestPriceByLengthOfDigitsInDecimalNumber.test(
                value.toString()
              )
              : false,
          message:
            t(
              "maintenanceGrids.gsmModel.spareForm.validation.priceDigitsLengthMsg"
            ) || "",
        })
        .nullable()
        .required(
          t("maintenanceGrids.gsmModel.spareForm.validation.price") || ""
        ),
    })
    .required();
};

export type TOnSubmitCreateGsmFormCallback = (
  values: CreateGsmSuccessFormValues
) => void;

export const useCreateGsmForm = (
  onSubmit: TOnSubmitCreateGsmFormCallback,
  isEdit?: boolean,
) => {
  const form = useForm<CreateGsmFormValues>({
    resolver: yupResolver(isEdit ? formEditSchema() : formCreateSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_CREATE_GSM_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as CreateGsmSuccessFormValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};

export const useCreateSparesForm = (
  onSubmit: TOnSubmitCreateGsmFormCallback,
  isEdit?: boolean
) => {
  const form = useForm<CreateGsmFormValues>({
    resolver: yupResolver(isEdit ? formSchemaEditSpares() : formSchemaCreateSpares()),
    mode: "onChange",
    defaultValues: DEFAULT_CREATE_SPARE_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as CreateGsmSuccessFormValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};
