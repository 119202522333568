import { Modal, IModal } from "@/packages/modal/Modal";
import { FC, useState } from "react";
import { GridsFormModalForm } from "@/components/MaintenanceGrids/GridsTab/GridsFormModal/GridsFormModalForm";

type TGridsEditModal = Pick<IModal, "onCancel">;

const GridsEditModal: FC<TGridsEditModal> = (props) => {
  const { onCancel } = props;

  const [submitData, setSubmitData] = useState<any | null>(null);

  const onFormChange = (formData) => {
    setSubmitData({
      models: formData.models,
      duplicate: formData.duplicate,
      isWorksDefault: formData.isWorksDefault,
      isGsmDefault: formData.isGsmDefault,
    });
  };

  const onSubmitButtonClick = () => {
    // Отправляем данные submitData
  };

  const onCancelButtonClick = () => {
    setSubmitData(null);
    onCancel && onCancel();
  };

  return (
    <Modal
      {...props}
      title="Редактировать сетку ТО"
      confirmButtonText="Сохранить"
      cancelButtonText="Отмена"
      onSubmit={onSubmitButtonClick}
      onCancel={onCancelButtonClick}
    >
      <GridsFormModalForm />
    </Modal>
  );
};

export { GridsEditModal };
