import { requiredStringSchema, yearRangeSchema, } from "@/helpers/validation";
import * as yup from "yup";
import { IModelDTOFields, IModelFormValues, IModelSuccessFormValues, ModelFormFieldsEnum, } from "./types";
import { t } from "i18next";

export const DEFAULT_MODEL_FORM_VALUES: IModelFormValues = {
  name: "",
  vinIndexes: [""],
};

export const modelFieldsFormSchema = () => {
  return yup
    .object({
      [ModelFormFieldsEnum.Name]: requiredStringSchema(
        t("models.addNewModelFrom.validation.name") || ""
      ),
      [ModelFormFieldsEnum.YearRange]: yearRangeSchema({
        startRequired: t("models.addNewModelFrom.validation.dataModel") || "",
      }),
      [ModelFormFieldsEnum.ProductionYearRange]: yearRangeSchema({
        startRequired:
          t("models.addNewModelFrom.validation.dataIndustrial") || "",
      }),
    })
    .required();
};

export const mapModelFieldsToDTO = (
  variables: IModelSuccessFormValues | any
): IModelDTOFields | any => {
  const {
    yearRange,
    productionYearRange,
    createdAt,
    id,
    isActive,
    name,
    type,
    updatedAt,
    vinIndexes,
  } = variables;
  return {
    modelYearStart: yearRange?.start ? yearRange?.start : null,
    modelYearEnd: yearRange?.end ? yearRange?.end : null,
    productionYearStart: productionYearRange?.start
      ? productionYearRange?.start
      : null,
    productionYearEnd: productionYearRange?.end
      ? productionYearRange?.end
      : null,
    createdAt,
    id,
    isActive,
    name,
    type,
    updatedAt,
    vinIndexes: vinIndexes?.filter((item: string) => item !== ''),
  };
};

export const mapModelDTOToFields = (
  variables: IModelDTOFields
): IModelFormValues => {
  const {
    modelYearStart,
    modelYearEnd,
    productionYearStart,
    productionYearEnd,
    ...rest
  } = variables;

  //@ts-ignore
  return {
    ...rest,
    yearRange: {
      start: modelYearStart,
      end: modelYearEnd,
    },
    productionYearRange: {
      start: productionYearStart,
      end: productionYearEnd,
    },
  };
};
