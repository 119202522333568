import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { StyledFormGrid, StyledModalContentWrapper } from "./index.styles";
import { useTemplateForm } from "./hooks";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import {
  useCreateAuditParametr,
  useEditAuditParameter,
  useGetAuditParametrById,
} from "@/helpers/api/audit-parametrs/hooks";
import { FormInputNumberField } from "@/packages/formElements/formFields/FormInputNumberField";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

type IChnageAuditNameProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

export interface IUploadAuditModalPayload {
  sectionId?: string;
  selectedParamId?: string;
}

interface IUploadAuditTemplateBase {
  payload: IUploadAuditModalPayload;
  onSubmit: () => void;
}

const MOCK_ATTRIBUTE = [
  { label: "Стандарты", value: "Стандарты" },
  { label: "Процессы", value: "Процессы" },
  { label: "Ключ. показатели", value: "Ключ. показатели" },
];
const MOCK_KPI = [
  { label: "Не обязательно", value: "Не обязательно" },
  { label: "Процент", value: "Процент" },
  { label: "Натуральное число", value: "Натуральное число" },
  { label: "Дробное число", value: "Дробное число" },
];

export const AddAuditParametersModals: React.FC<
  IChnageAuditNameProps & IUploadAuditTemplateBase
> = (props) => {
  const { onSubmit, onCancel, payload, isOpen } = props;
  const { createNotificationError } = useNotification();
  const { data: parametrs } = useGetAuditParametrById(payload.selectedParamId);

  const { mutate: createParametr, isLoading: isCreating } =
    useCreateAuditParametr();
  const { mutate: editParametr, isLoading: isUpdating } =
    useEditAuditParameter();

  const handleSubmit = () => {
    handleReset();
    onSubmit();
  };
  const { t } = useTranslation();
  const onFormChange = (value) => {
    if (payload.selectedParamId) {
      editParametr({
        id: payload.selectedParamId,
        patch: value,
        onSuccess: handleSubmit,
        onError: (error) =>
          createNotificationError(
            error.response?.data.message || error.message
          ),
      });
    } else {
      createParametr({
        data: { ...value, sectionId: payload.sectionId },
        onSuccess: handleSubmit,
        onError: (error) =>
          createNotificationError(
            error.response?.data.message || error.message
          ),
      });
    }
  };

  useEffect(() => {
    if (parametrs?.data) {
      setDefaultValue(parametrs?.data);
    }
  }, [parametrs, isOpen]);

  const { form, onSubmitHandler, setDefaultValue, handleChange, handleReset } =
    useTemplateForm(onFormChange);

  useEffect(() => {
    if (!isOpen) {
      handleReset();
    }
  }, [isOpen]);

  return (
    <Modal
      {...props}
      title={
        !payload.selectedParamId
          ? t("audits.templates.addParameters") || ""
          : t("audits.templates.editParameters") || ""
      }
      size="small"
      cancelButtonText={t("audits.templates.cancel") || ""}
      confirmButtonText={t("audits.templates.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancel}
      isLoadingConfirmButton={isCreating || isUpdating}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormTextAreaField
            name="title"
            label={t("audits.templates.checkpoint") || ""}
            placeholder={t("audits.templates.text") || ""}
          />
          <FormTextAreaField
            name="criteria"
            label={t("audits.templates.criteria") || ""}
            placeholder={t("audits.templates.text") || ""}
          />
          <FormTextAreaField
            name="plan"
            label={t("audits.templates.plan") || ""}
            placeholder={t("audits.templates.text") || ""}
          />

          <StyledFormGrid>
            <FormSelect
              name="attribute"
              label={t("audits.templates.attribute") || ""}
              options={MOCK_ATTRIBUTE}
            />
            <FormSelect
              name="kpi"
              label={t("audits.templates.kpi") || ""}
              options={MOCK_KPI}
            />
            <FormInputNumberField
              name="weight"
              type="number"
              label={t("audits.templates.weight") || ""}
            />
            <FormCheckbox
              name="photo"
              label={t("audits.templates.photo") || ""}
            />
          </StyledFormGrid>
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
