import React, { RefObject, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledFieldError, StyledFile, StyledModalContentWrapper } from "./index.styles";
import { useUploadAuditTemplateForm } from "./hooks";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Button } from "@/packages/button/Button";
import { Icon } from "@/packages/icon/Icon";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useNotification } from "@/hooks/useNotification";
import { useUploadWarrantyAuditTemplate } from "@/helpers/api/warranty-audit/templates/hooks";
import { useTranslation } from "react-i18next";

type IUploadAuditTemplateModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadAuditTemplateBase {
  onSubmit: () => void;
}

export const UploadWarrantyAuditTemplateModal: React.FC<
  IUploadAuditTemplateModalProps & IUploadAuditTemplateBase
> = (props) => {
  const { onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const { createNotificationError } = useNotification();
  const inputFile: RefObject<HTMLInputElement> = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { mutate: uploadFile, isLoading } = useUploadWarrantyAuditTemplate();
  const [errors, setErrors] = useState<string[]>([])

  const onFormChange = (value) => {
    if (selectedFile) {
      const formData = new FormData();
      Object.entries(value).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.append("file", selectedFile);

      uploadFile({
        data: formData,
        onSuccess: () => {
          form.reset();
          setSelectedFile(null);
          onSubmit();
        },
        onError: (error) => {
          //@ts-ignore
          setErrors(error?.response?.data?.rest?.errors ?? [error?.response?.data?.message])
          createNotificationError(
            //@ts-ignore
            error?.response?.data?.rest?.message ?? error?.response?.data?.message
          );
        },
      });
    }
  };
  const { form, onSubmitHandler } = useUploadAuditTemplateForm(onFormChange);

  const onUploadFile = (file: File) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      {...props}
      title={t("audits.list.templateDownload") || ""}
      size="small"
      isLoadingConfirmButton={isLoading}
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("common.upload") || ""}
      onSubmit={() => onSubmitHandler()}
      onCancel={() => {
        form.reset();
        setErrors([]);
        setSelectedFile(null);
        onCancel && onCancel();
      }}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormInputField
            name="title"
            label={t("common.name") || ""}
            placeholder={t("common.enterName") || ""}
          />
          {!selectedFile ? (
            <Button
              isUploadLeft={true}
              isBlock={false}
              icon={<Icon name="plus" size={12} />}
              onClick={() => inputFile.current?.click()}
            >
              {t("common.addFile")}
            </Button>
          ) : (
            <StyledFile>
              <Paragraph size={12}>{selectedFile.name}</Paragraph>
              <IconButton
                icon="delete"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => {
                  setErrors([]);
                  setSelectedFile(null)
                }}
              />
            </StyledFile>
          )}
          <input
            type="file"
            accept="*"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(event) => {
              const eventCopy = { ...event };
              if (eventCopy?.target?.files) {
                onUploadFile(eventCopy?.target?.files[0]);
              }
            }}
          />
        </FormProvider>
        {
          errors &&
          errors.map((value, index) =>
            <StyledFieldError key={index}>
              {value}
            </StyledFieldError>)
        }
      </StyledModalContentWrapper>
    </Modal>
  );
};