import { Modal } from "@/packages/modal/Modal";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  background-color: black;
  width: 100%;
`;

export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;

export const StyledFirstFormRow = styled.div`
  display: grid;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  grid-template-columns: 1fr 1fr 2fr;
`;

export const StyledThirdRow = styled.div`
  display: grid;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledTextAreaField = styled(FormTextAreaField)`
  resize: none;
  height: 32px !important;
`;
