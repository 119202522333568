import { ISendMessage } from "@/components/Audits/ChatModal";
import { MessageAdapter } from "@/components/Audits/ChatModal/components/MessageAdapter";
import { MessageInput } from "@/components/Audits/ChatModal/components/MessageInput";
import { MessageText } from "@/components/Audits/ChatModal/components/MessageText";

import {
  useGetMonitoringQualityCarsMessages,
  usePostMonitoringQualityCarsMessages,
  useReadAllMessages,
} from "@/helpers/api/monitoringQuality/hooks";
import { TMonitoringQualityCarMessageRecord } from "@/helpers/api/monitoringQuality/types";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { Loader } from "@/packages/loader/Loader";
import { UploadImageDropzone, UploadType, } from "@/packages/UploadImageDropzone";
import { useModal } from "@/hooks/useModal";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  StyledAttachmentsContainer,
  StyledChat,
  StyledChatBody,
  StyledDate,
  StyledFile,
  StyledFilePlaceholder,
} from "./index.styles";
import { UploadImageModal } from "./UploadImageModal";
import { Icon } from "@/packages/icon/Icon";

export interface IComponentNameProps {
  monitoringCarsId?: string;
  refetchRequestCars?: any;
}

export const MonitoringChat: React.FC<IComponentNameProps> = ({
                                                                monitoringCarsId,
                                                                refetchRequestCars,
                                                              }) => {
  const {
    data: monitoringQualityCarsMessages,
    isLoading,
    refetch: updateMessages,
  } = useGetMonitoringQualityCarsMessages({
    qualityMonitoringRequestCarIds: [monitoringCarsId as string],
  });

  const { mutateAsync: readAllMessages } = useReadAllMessages();

  useEffect(() => {
    if (!monitoringCarsId) {
      return;
    }
    readAllMessages({ id: monitoringCarsId });
    refetchRequestCars && refetchRequestCars();
  }, [monitoringCarsId])

  const { mutateAsync: postMessageAsync } =
    usePostMonitoringQualityCarsMessages();

  const {
    state: { profile },
  } = useContext(ContextProfile);

  const [reply, setReply] = useState<TMonitoringQualityCarMessageRecord | null>(
    null
  );
  const [files, setFiles] = useState<FileList | null>(null);
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = async ({
                                     message,
                                     filesIds,
                                     replyId,
                                   }: ISendMessage) => {
    setReply(null);
    UploadModalClose();

    const preparedData = {
      message,
      fileIds: filesIds || [],
      replyId: replyId || reply?.id,
      qualityMonitoringRequestCarId: monitoringCarsId,
    };

    Object.entries(preparedData).forEach(([key, value]) => {
      if (!value) {
        delete preparedData[key];
      }
    });

    if (isSending) {
      return;
    }
    setIsSending(true);

    await postMessageAsync({
      //@ts-ignore
      data: {
        ...preparedData,
      },
      onSuccess: () => {
        setIsSending(false);
        updateMessages();
      },
    });
  };

  const {
    modalState: UploadModalState,
    openModal: UploadModalOpen,
    closeModal: UploadModalClose,
  } = useModal();

  useEffect(() => {
    if (files?.length) {
      UploadModalOpen();
    }
  }, [files]);

  const preparedMessage = useMemo(
    () =>
      monitoringQualityCarsMessages?.data.data?.reduce(
        (
          acc: { [key: string]: TMonitoringQualityCarMessageRecord[] },
          item
        ) => {
          const date = new Date(item.createdAt).toLocaleDateString();
          if (acc[date]) {
            return { ...acc, [date]: [...acc[date], item] };
          } else {
            return { ...acc, [date]: [item] };
          }
        },
        {}
      ) || {},
    [monitoringQualityCarsMessages]
  );

  return (
    <>
      <StyledChat>
        <StyledChatBody>
          {!isLoading ? (
            Object.entries(preparedMessage).map(([key, messages]) => {
              return (
                <>
                  {messages?.map((item) => {
                    const isMyMessage = profile?.id === item.userId;
                    return (
                      <>
                        {!!item?.files.length ? (
                          <StyledAttachmentsContainer isMyMessage={isMyMessage}>
                            {item?.files?.map((fileItem) => {
                              return (
                                <>
                                  {item?.files.length === 1 && isMyMessage ? (
                                    <div></div>
                                  ) : null}
                                  <StyledFile
                                    key={fileItem.id}
                                    isMyMessage={isMyMessage}
                                  >
                                    <UploadImageDropzone
                                      hasRemove={false}
                                      value={fileItem}
                                      path="request/files"
                                      typeInfo={
                                        fileItem.mime.includes("image")
                                          ? UploadType.IMAGE
                                          : UploadType.FILE
                                      }
                                      placeholder={
                                        <StyledFilePlaceholder>
                                          <Icon name="file-pdf" size={40}/>
                                        </StyledFilePlaceholder>
                                      }
                                      isWithCustomPlaceholder
                                    />
                                  </StyledFile>
                                </>
                              );
                            })}
                          </StyledAttachmentsContainer>
                        ) : null}
                        {item.message ? (
                          <MessageAdapter isMyMessage={isMyMessage}>
                            <MessageText
                              data={item}
                              isMyMessage={isMyMessage}
                              onReply={() => setReply(item)}
                            />
                          </MessageAdapter>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                  <StyledDate>{key}</StyledDate>
                </>
              );
            })
          ) : (
            <Loader size={48}/>
          )}
        </StyledChatBody>
        <MessageInput
          isContainer
          reply={reply}
          onSendMessage={handleSendMessage}
          onFilesAdd={setFiles}
          onCancelReply={() => setReply(null)}
        />
      </StyledChat>
      {files?.length ? (
        <UploadImageModal
          isOpen={UploadModalState.isOpen}
          onSubmit={handleSendMessage}
          onCancel={UploadModalClose}
          files={files}
        />
      ) : (
        <></>
      )}
    </>
  );
};
