import React from "react";
import { ErrorModal } from "@/packages/ErrorModal/ErrorModal";
import { IModal } from "@/packages/modal/Modal";

type ApplicationsErrorModalProps = Pick<
  IModal,
  "isOpen" | "onSubmit"
>;

export const ApplicationsErrorModal: React.FC<ApplicationsErrorModalProps> = (props) => {
  const { ...rest } = props;

  return (
    <ErrorModal
      {...rest}
      size="small"
      title="Отклонено"
      confirmButtonText="Закрыть"
      showCancelButton={false}
    >
      Данный автомобиль уже забронирован на выбранные даты
    </ErrorModal>
  );
};
