import React from "react";
import { Switch as AntdSwitch, SwitchProps } from "antd";
import styled, { css } from "styled-components";
import { colors } from "@/helpers/index";

export interface ISwitch {
  value?: boolean;
  loading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  readOnly?: boolean;
  onChange?: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

interface ISwitchStyledProps extends SwitchProps {
  error?: boolean;
  readOnly: boolean;
}

const StyledSwitch = styled(AntdSwitch)<ISwitchStyledProps>`
  background-image: none;
  border: 1px solid transparent;

  .ant-switch-handle {
    top: 1px;
    left: 1px;
  }

  ${({ disabled, readOnly }) =>
    disabled &&
    readOnly &&
    css`
      background-color: ${colors.gray5};
      &:hover {
        background-color: ${colors.gray5};
      }
    `}

  ${({ checked }) =>
    checked
      ? css`
          background-color: ${colors.brand};
          &.ant-switch-checked {
            .ant-switch-handle {
              left: calc(100% - 12px - 1px);
            }
          }
        `
      : css`
          background-color: ${colors.gray300};
          &:hover {
            background-color: ${colors.gray400};
          }
        `}
  ${({ error: isError }) =>
    isError &&
    css`
      background-color: ${colors.red200};
      &:hover {
        background-color: ${colors.red200};
      }
    `};
  :focus-visible {
    outline: 1px solid ${colors.brand};
  }

  :focus {
    box-shadow: none;
  }
`;

const Switch: React.FC<ISwitch> = (props) => {
  const {
    value,
    loading,
    disabled,
    hasError,
    readOnly = false,
    onChange,
  } = props;

  return (
    <StyledSwitch
      size="small"
      checked={value}
      loading={loading}
      disabled={disabled || readOnly}
      error={hasError}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};

export { Switch };
