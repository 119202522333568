import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";
import { Row } from "antd";
import styled, { css } from "styled-components";

export const TitleFieldWrapper = styled.div`
  margin: 10px 0;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledStatus = styled(StatusLabel)`
  background-color: rgba(132, 166, 254, 0.16);
  ${() => css`
    && .gUCyuk {
      background-color: rgba(132, 166, 254, 0.16);
    }
  `}
`;

export const StyledTitleWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

export const StyledStatusLabel = styled(Paragraph)`
  margin: 5px 0;
  border-radius: 4px;
  padding: 16px 24px;
  display: inline-block;
  background-color: rgba(132, 166, 254, 0.16);
  color: black;
`;
