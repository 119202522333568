import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Loader } from "@/packages/loader/Loader";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledHeader = styled.div`
  background: ${colors.white};
  padding: 0 20px;
`;

export const StyledFirstLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  padding: 12px 0;
  border-bottom: 1px solid #f6f6f9;
  margin: 8px 0 12px 0;
`;

export const StyledSecondLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  padding-bottom: 12px;
  margin: 8px 0 0 0;
`;

export const StyledThirdLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  padding-bottom: 12px;
`;

export const StyledPrevButton = styled(IconButton)``;

export const StyledNextButton = styled(IconButton)`
  transform: rotate(180deg);
  position: absolute;
  right: 0;
`;

export const StyledQuarterClosedButton = styled(Button)`
  margin-right: auto;
  background-color: ${colors.red30};
  color: ${colors.red10};
  border-color: ${colors.red20};

  &.active {
    background-color: ${colors.red30};
    color: ${colors.red10};
    border-color: ${colors.red20};
  }
`;

export const StyledQuarterNotFoundParagraph = styled(Paragraph)`
  margin: 0 auto;
`;
