import { colors } from "@/helpers";
import { Upload } from "antd";
import styled, { css } from "styled-components";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { Modal } from "@/packages/modal/Modal";
import { DownloadOutlined } from "@ant-design/icons";

export const StyledMainWrap = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledImagePreview = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const StyledImagePreviewForModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: auto;
`;

export const StyledImagePreviewForModal = styled.img`
  /* position: absolute; */
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const StyledModal = styled(Modal)`
  background-color: black;
  width: 100%;
`;

export const StyledDragger = styled(Upload.Dragger)<{ fileExist: boolean }>`
  &:not(.ant-upload-disabled):hover {
    border-color: ${colors.brand} !important;
  }

  ${({ fileExist }) => {
    return fileExist
      ? css`
          && .ant-upload-btn {
            padding: 0;
          }
        `
      : "";
  }}
`;

export const StyledPreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  background: ${colors.black};
  height: 100%;
  width: 100%;
  animation: overlayIn;
  animation-duration: 0.5s;
  z-index: 2;
  @keyframes overlayIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }
`;

export const StyledEyeIcon = styled(EyeOutlined)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(1.6) translate(-30%, -30%);
  color: white;
  cursor: pointer;
  animation: iconIn;
  animation-duration: 0.5s;
  z-index: 3;

  @keyframes iconIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledDownloadIcon = styled(DownloadOutlined)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(1.6) translate(-30%, -30%);
  color: white;
  animation: iconIn;
  animation-duration: 0.5s;
  z-index: 3;

  @keyframes iconIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledPlaceholder = styled.div`
  font-size: 12px;
  span {
    color: ${colors.brand};
  }
`;

export const StyledIconDropzone = styled.div<{
  isWithCustomPlaceholderTitle?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  background: #e7eaff;

  ${({ isWithCustomPlaceholderTitle }) =>
    isWithCustomPlaceholderTitle
      ? css`
          width: 48px;
          height: 48px;
          margin-bottom: 12px;
        `
      : css`
          width: 55px;
          height: 55px;
          svg {
            width: 55px;
            height: 55px;
          }
        `}
`;

export const StyledRemoveButtonWrap = styled.p`
  position: absolute;
  z-index: 10;
  top: 4px;
  right: 4px;
`;
