import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { InputField } from "@/packages/formElements/fields/InputField";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const ResetMailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 64px;
  width: 506px;
  background: #ffffff;
  border-radius: 16px;
`;

export const ResetMailTitle = styled(Title)`
  width: 100%;
`;

export const ResetMailSubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const ResetMailSubTitleLink = styled(ButtonText)`
  margin-left: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.brand};
`;

export const ResetMailForm = styled.form`
  margin-top: 32px;
  width: 100%;
`;

export const ResetMailInput = styled(InputField)`
  width: 100%;
`;

export const ResetMailButton = styled(Button)`
  margin-top: 40px;
`;
