import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface IUploadAuditTemplateValues {
  title: string;
}

export const DEFAULT_CALENDAR_FILTER_FORM_VALUES: IUploadAuditTemplateValues = {
  title: "",
};

export const formSchema = () => {
  return yup.object({
    title: yup
      .string()
      .required(t("warrantyAudits.templates.validation.nameRequired") || ""),
  });
};

export type TOnSubmitCalendarFilterFormCb = (
  values: IUploadAuditTemplateValues
) => void;

export const useUploadAuditTemplateForm = (
  onSubmit: TOnSubmitCalendarFilterFormCb
) => {
  const form = useForm<IUploadAuditTemplateValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_CALENDAR_FILTER_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
