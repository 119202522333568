import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";

export enum SellsFilterFormFieldsNameEnum {
  Manager = "managerId",
  State = "region",
  City = "city",
  Dealer = "dealerId",
  Year = "year",
  Quarter = "quarter",
  CoefficientA = "coefficientA",
  CoefficientC = "coefficientC",
}

export interface ISellsFilterFormValues {
  [SellsFilterFormFieldsNameEnum.Manager]?: string | undefined;
  [SellsFilterFormFieldsNameEnum.State]?: string | undefined;
  [SellsFilterFormFieldsNameEnum.City]?: string | undefined;
  [SellsFilterFormFieldsNameEnum.Dealer]?: string | undefined;
  [SellsFilterFormFieldsNameEnum.Year]?: string | undefined;
  [SellsFilterFormFieldsNameEnum.Quarter]?: string | undefined;
}

export type TOnSubmitSellsFilterFormCb =
  TOnSubmitFormSyncCb<ISellsFilterFormValues>;

export const useSellsFilterForm = (options: {
  onSubmit: TOnSubmitSellsFilterFormCb;
  defaultValues: ISellsFilterFormValues;
}) => {
  // const { initValues, onSubmit } = options;
  const { onSubmit, defaultValues } = options;

  const result = useForm<ISellsFilterFormValues>({
    defaultValues,
    onSubmit,
  });

  // useEffect(() => {
  //   result.form.reset(initValues, { keepDefaultValues: true });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return result;
};
