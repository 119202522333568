import { IFileInfo } from "@/components/AutoPark/AutoParkForm/hooks";

export enum ExistCarFieldsName {
  OWNER = "owner",
  C2C = "c2c",
  VIN = "vin",
  MATERAL_CODE = "materialCode",
  MODEL = "model",
  GENERATION = "generation",
  MODIFICATION = "modification",
  EQUIPMENT = "equipmentId",
  COLOR = "color",
  PTS = "pts",
  REGISTER_NUMBER = "number",
  PHOTO = "photoIds",
  FILE = "fileIds",
  DEALER_CENTER = "dealerId",
}

export enum InsuranceKasko {
  POLICY_HOLDER = "insurantK",
  SERIES_AND_NUMBER = "seriesK",
  NAME_INSURANCE = "insuranceNameK",
  TERM_INSURANSE_START = "fromK",
  TERM_INSURANSE_END = "toK",
  MANAGMENT_ALL = "openInsuranceK",
}
export enum InsuranceOsago {
  POLICY_HOLDER = "insurantO",
  SERIES_AND_NUMBER = "seriesO",
  NAME_INSURANCE = "insuranceNameO",
  TERM_INSURANSE_START = "fromO",
  TERM_INSURANSE_END = "toO",
  MANAGMENT_ALL = "openInsuranceO",
}

export interface IInsuranceOsago {
  [InsuranceOsago.POLICY_HOLDER]: string | null;
  [InsuranceOsago.NAME_INSURANCE]: string | null;
  [InsuranceOsago.SERIES_AND_NUMBER]: string | null;
  [InsuranceOsago.MANAGMENT_ALL]: string | null;
  [InsuranceOsago.TERM_INSURANSE_END]: Date | null;
  [InsuranceOsago.TERM_INSURANSE_START]: Date | null;
}
export interface IInsuranceKasko {
  [InsuranceKasko.POLICY_HOLDER]: string | null;
  [InsuranceKasko.NAME_INSURANCE]: string | null;
  [InsuranceKasko.SERIES_AND_NUMBER]: string | null;
  [InsuranceKasko.MANAGMENT_ALL]: string | null;
  [InsuranceKasko.TERM_INSURANSE_END]: Date | null;
  [InsuranceKasko.TERM_INSURANSE_START]: Date | null;
}
export interface IExistCarFromValues extends IInsuranceOsago, IInsuranceKasko {
  [ExistCarFieldsName.OWNER]: string | null;
  [ExistCarFieldsName.C2C]: boolean | null;
  [ExistCarFieldsName.VIN]: string | null;
  [ExistCarFieldsName.MATERAL_CODE]: string | null;
  [ExistCarFieldsName.MODEL]: string | null;
  [ExistCarFieldsName.GENERATION]: string | null;
  [ExistCarFieldsName.MODIFICATION]: string | null;
  [ExistCarFieldsName.EQUIPMENT]: string | null;
  [ExistCarFieldsName.COLOR]: string | null;
  [ExistCarFieldsName.EQUIPMENT]: string | null;
  [ExistCarFieldsName.PTS]: string | null;
  [ExistCarFieldsName.REGISTER_NUMBER]: null | string;
  [ExistCarFieldsName.DEALER_CENTER]: string | null;
  [ExistCarFieldsName.PHOTO]: IFileInfo[] | null;
  [ExistCarFieldsName.FILE]: IFileInfo[] | null;
}
