import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";
import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TEngineRecord } from "@/helpers/api/engines/types";
import { TEngineTypeForm, ENGINE_TYPE_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export type TEngineTypeEditModalSubmitPayload = {
  record: TEngineRecord;
  data: TEngineTypeForm;
};
export interface IEngineTypeEditModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (payload: TEngineTypeEditModalSubmitPayload) => void;
  isLoadingSubmit: boolean;
  record?: TEngineRecord;
}

export const EngineTypeEditModal: React.FC<IEngineTypeEditModal> = (props) => {
  const { onSubmit, isLoadingSubmit, record, ...restProps } = props;

  const { t } = useTranslation();

  const initialForm = {
    engineType: record?.name || "",
    // @ts-ignore
    mpv: record?.mpv || "",
  };

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState(initialForm);

  const engineTypeError = !form.engineType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      engineType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!record || !Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit({ record: record, data: form });
  };

  useEffect(() => {
    setForm(restProps.isOpen ? initialForm : ENGINE_TYPE_INITIAL_FORM);
  }, [record, restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.changeEngineType") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.engine") || ""}
          description={t("parts.engineEnter") || ""}
          value={form.engineType}
          error={engineTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
