import { FC, useContext, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { ChangeDataForm } from "@/components/profile/ChangeDataForm";
import { SuccessChangeModal } from "@/components/profile/SuccessChangeModal";
import { useNotification } from "@/hooks/useNotification";
import { useModal } from "@/hooks/useModal";
import { useGetRoleListForDealer } from "@/helpers/api/roles/hooks";
import { TPatchProfileRequest } from "@/helpers/api/profile/types";
import { ContextProfile } from "@/hooks/contexts/useProfile";

export const Edit: FC = (): JSX.Element => {
  const [isRoleChanged, setIsRoleChanged] = useState(false);

  const { createNotificationError } = useNotification();
  const { modalState, openModal } = useModal();

  const {
    state: { profile },
    actions: { updateProfileAsync },
  } = useContext(ContextProfile);

  const { data: rolesData } = useGetRoleListForDealer();

  const rolesOptions = useMemo(() => {
    if (rolesData?.data.data) {
      return rolesData?.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
  }, [rolesData]);

  const handleFormSubmit = async (data) => {
    const updatedData: TPatchProfileRequest["patch"] = {
      firstName: data.firstName,
      lastName: data.lastName,
      newRoleId: profile?.role?.id,
      // dealerId: data.dealer,
      phone: data.phone,
      imageId: data?.imageId,
    };

    if (profile?.role?.id !== data.role) {
      setIsRoleChanged(true);
      updatedData.newRoleId = data.role;
    }

    try {
      await updateProfileAsync({
        patch: updatedData,
      });
      openModal();
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  return (
    <DashboardLayout toBack="/profile" title="Личная информация">
      {profile ? (
        <ChangeDataForm
          onSubmit={handleFormSubmit}
          rolesOptions={rolesOptions}
          defaultValues={profile}
        />
      ) : (
        <Paragraph>Произошла ошибка, или вы не авторизованы</Paragraph>
      )}
      <SuccessChangeModal {...modalState} isRoleChanged={isRoleChanged} />
    </DashboardLayout>
  );
};
