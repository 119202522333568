import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TAnswerOnQuestionRequest,
  TAnswerOnQuestionResponse,
  TArchiveAuditRequest,
  TArchiveAuditResponse,
  TCompleteAuditRequest,
  TCompleteAuditResponse,
  TGetAuditByIdRequest,
  TGetAuditByIdResponse,
  TGetAuditWithQuestionsRequest,
  TGetAuditWithQuestionsResponse,
  TStartAuditFillingRequest,
  TStartAuditFillingResponse,
} from "./types";

export const useGetAuditById = (id?: TGetAuditByIdRequest) => {
  return useQuery(
    ["audit", "detail", id],
    () => {
      return api.get<TGetAuditByIdResponse>(`/audits/${id}`);
    },
    {
      enabled: !!id,
      cacheTime: 0,
    }
  );
};

export const useGetAuditWithQuestions = (
  requset: TGetAuditWithQuestionsRequest
) => {
  return useQuery(["audit", "questions", requset.id, requset.params], () => {
    return api.get<TGetAuditWithQuestionsResponse>(
      `/audits/${requset.id}/questions`,
      {
        params: requset.params,
      }
    );
  });
};

export const useStartAuditFilling = () => {
  return useMutation(
    ({
       patch,
       onSuccess,
     }: {
      patch: TStartAuditFillingRequest;
      onSuccess: () => void;
      onError: () => void;
    }) => {
      return api.patch<TStartAuditFillingResponse>(
        `/audits/${patch.id}/start-filling`,
        patch.data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError();
        }
      },
    }
  );
};

export const useAnswerAuditQuestion = () => {
  return useMutation(
    ({
       data,
       onSuccess,
     }: {
      data: TAnswerOnQuestionRequest;
      onSuccess: (resp: TAnswerOnQuestionResponse) => void;
      onError: (resp: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TAnswerOnQuestionResponse>("/audits/answers", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCompleteAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: TCompleteAuditRequest;
      onSuccess: () => void;
      onError?: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TCompleteAuditResponse>(`/audits/${id}/complete`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useVerificationAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: TCompleteAuditRequest;
      onSuccess: () => void;
      onError?: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TCompleteAuditResponse>(`/audits/${id}/verification`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useArchiveAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
     }: {
      id: TArchiveAuditRequest;
      onSuccess: () => void;
      onError: (resp: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TArchiveAuditResponse>(`/audits/${id}/archive`);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useExportAuditWithQuestions = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/audits/${request.id}/export-questions`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useGetCurrentVisitNumber = (request: any) => {
  return useQuery(
    [request],
    () => {
      return api.get<any>(`/audits/current-visit-number`, {
          params: request,
        }
      );
    },
    { enabled: !!request.dealerId }
  );
};
