import { GlobalStyles } from "@/helpers/styles/globalStyles";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { AppRouter } from "./AppRouter";
import { ProfileContextProvider } from "./providers/ProfileContextProvider";
import "./translations/i18n";
import locale from 'antd/lib/locale-provider/ru_RU';
import { ConfigProvider } from "antd";
import { UserInfoModelProvider } from "@/components/UserInfoModal/provider";

export const App: React.FC = () => {
  return (
    <ReactQueryProvider>
      <ConfigProvider locale={locale}>
        <GlobalStyles/>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <ProfileContextProvider>
              <UserInfoModelProvider>
                <AppRouter/>
              </UserInfoModelProvider>
            </ProfileContextProvider>
          </QueryParamProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false}/>
      </ConfigProvider>
    </ReactQueryProvider>
  );
};
