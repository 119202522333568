import { IRoleSelectAdapter, RoleSelectAdapter, } from "@/adapters/selects/RoleSelectAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormRoleSelect
  extends Omit<IRoleSelectAdapter, "error" | "value" | "onChange"> {
  name: string;
  isShowHoldingRoles?: boolean;
}

const _FormRoleSelect: React.FC<IFormRoleSelect> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <RoleSelectAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormRoleSelect = React.memo(_FormRoleSelect);
