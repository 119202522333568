import React, { useState } from "react";

import { Paragraph } from "@/packages/paragraph/Paragraph";

import {
  StyledTableColumnLeft,
  StyledTableHeadLeftWrap,
  StyledInfoBlockList,
  StyledInfoBlockItem,
  StyledInfoBlockSubList,
  StyledInfoBlockSubItem,
  StyledInfoBlockSubItemParagraph,
} from "./index.styles";

import { IDatesTree } from "@/helpers/date";
import { useModal } from "@/hooks/useModal";
import { VehicleModal } from "./VehicleModal";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

interface ICalendarTableColumnLeft {
  data: any[];
  datesTree: IDatesTree | null;
}

export const CalendarTableColumnLeft: React.FC<ICalendarTableColumnLeft> = (
  props
) => {
  const { data, datesTree } = props;
  const [vehicleId, setVehicleId] = useState(null);
  const { t } = useTranslation();
  const {
    modalState: VehicleModalState,
    openModal: VehicleOpenModal,
    closeModal: VehicleCloseModal,
  } = useModal();

  const handleVehicleModalOpen = (carItem) => {
    setVehicleId(carItem.id);
    VehicleOpenModal();
  };

  const handleVehicleModalClose = () => {
    setVehicleId(null);
    VehicleCloseModal();
  };

  return (
    <StyledTableColumnLeft>
      {datesTree ? (
        <StyledTableHeadLeftWrap>
          <Paragraph size={12}>{t("park.auto") || ""}</Paragraph>
        </StyledTableHeadLeftWrap>
      ) : (
        <></>
      )}
      <StyledInfoBlockList>
        {data.map((car, carIndex) => (
          <StyledInfoBlockItem key={`cars-item-${car.name}-${carIndex}`}>
            <Paragraph size={12} weight={300} color={"black10"}>
              {car.name}
            </Paragraph>
            <StyledInfoBlockSubList>
              {car.vehicles.map((carItem, carItemIndex) => (
                <StyledInfoBlockSubItem
                  onClick={() => handleVehicleModalOpen(carItem)}
                  key={`cars-sub-item-${car.name}-${carIndex}-${carItem.name}-${carItemIndex}`}
                >
                  <Tooltip
                    isFitContent={false}
                    content={<div>{carItem.name}</div>}
                  >
                    <StyledInfoBlockSubItemParagraph
                      size={12}
                      weight={300}
                      color="black10"
                    >
                      {carItem.name}
                    </StyledInfoBlockSubItemParagraph>
                  </Tooltip>
                </StyledInfoBlockSubItem>
              ))}
            </StyledInfoBlockSubList>
          </StyledInfoBlockItem>
        ))}
      </StyledInfoBlockList>
      <VehicleModal
        {...VehicleModalState}
        isOpen={VehicleModalState.isOpen}
        closeModal={VehicleCloseModal}
        onCancel={handleVehicleModalClose}
        id={vehicleId ? vehicleId : ""}
      />
    </StyledTableColumnLeft>
  );
};
