import { useEffect } from "react";

import { useForm } from "@/hooks/useForm";
export interface IApplicationsFilterFormValues {
  "filter[modelId]": string | undefined;
  "filter[startEvent]": string | undefined;
  "filter[endEvent]": string | undefined;
  "filter[appointment]": string | undefined;
  "filter[userCreatorId]": string | undefined;
  "filter[vehicleNumber]": string | undefined;
  "filter[mediaId]": string | undefined;
}

export const DEFAULT_APPLICATIONS_FILTER_FORM_VALUES: IApplicationsFilterFormValues =
  {
    "filter[modelId]": undefined,
    "filter[startEvent]": undefined,
    "filter[endEvent]": undefined,
    "filter[appointment]": undefined,
    "filter[userCreatorId]": undefined,
    "filter[vehicleNumber]": undefined,
    "filter[mediaId]": undefined,
  };

export type TOnSubmitApplicationsFilterFormCb = (
  values: IApplicationsFilterFormValues
) => void;

export const useApplicationsFilterForm = (
  onSubmit: TOnSubmitApplicationsFilterFormCb,
  defaultValues: IApplicationsFilterFormValues
) => {
  const form = useForm<IApplicationsFilterFormValues>({
    defaultValues: DEFAULT_APPLICATIONS_FILTER_FORM_VALUES,
    onSubmit,
  });

  useEffect(() => {
    form.form.reset(defaultValues, { keepDefaultValues: true });
    // form.form.reset();
  }, []);

  return form;
};
