import styled, { css } from "styled-components";
import { colors } from "@/helpers";
import { Icon } from "@/packages/icon/Icon";

interface IColoredLabel {
  color: string;
  days: number;
}

export const StyledColoredLabel = styled.div<IColoredLabel>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ days }) => 20 * days + "px"};
  height: 24px;
  border: 2px solid ${colors.black};
  border-radius: 24px;
  color: ${colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${({ color }) => color};
  z-index: 2;
  cursor: pointer;
`;

export const StyledComment = styled.div`
  color: ${colors.gray200};
`;

export const StyledTriangleIcon = styled(Icon)`
  position: absolute;
  top: -15px;
  left: 25%;
`;

export const StyledIntersectionThumb = styled.div<{
  isIntersectingByStart?: boolean;
}>`
  position: absolute;
  top: 2px;
  ${({ isIntersectingByStart }) =>
    isIntersectingByStart
      ? css`
          left: 2px;
        `
      : css`
          right: -2px;
        `}
  height: 20px;
  width: 20px;
  border-radius: 24px;
  background-color: ${colors.white};
  z-index: 3;
`;
