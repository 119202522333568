import { CardInfo } from "@/packages/CardInfo";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import { RegisterFinalFormDescriptionTop } from "./index.styles";
import { useTranslation } from "react-i18next";

export const RegistrationFinalForm: React.FC = () => {
  const { t } = useTranslation();
  return (
    <CardInfo
      title={t("authorization.thanksFor") || ""}
      description={
        <div>
          <RegisterFinalFormDescriptionTop>
            {t("authorization.emailAfterReg") || ""}
          </RegisterFinalFormDescriptionTop>
          <Paragraph color="gray200" size={16}>
            {t("authorization.emailAfterMessage") || ""}
            <a href="mailto:dip.support@kia.ru">
              {t("authorization.emailAfterMessage_2") || ""}
            </a>
          </Paragraph>
        </div>
      }
    />
  );
};
