import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Row } from "antd";
import React, { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
// import { ITransportComFilterFormValues, TOnSubmitTransportComFilterFormCb, useTransportComFilterForm, } from "./hooks";
// import { StyledButton, StyledColDate, StyledDash } from "./index.styles";
import { useTranslation } from "react-i18next";
import { Button } from "@/packages/button/Button";
import {
  DEFAULT_FILTER_FORM_VALUES,
  SubmitFilterCb,
  UserFilter,
  useUserFilterForm
} from "@/pages/Admin/UsersFilters/hooks";
import { StyledUsersFilter } from "@/pages/Admin/UsersFilters/index.styles";
import { FormCountrySelect } from "@/packages/formElements/formFields/FormCountrySelect";
import { FormRoleSelect } from "@/packages/formElements/formFields/FormRoleSelect";
import { preparedDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface UserFilterProps {
  initValues: UserFilter;
  onFilterChange: SubmitFilterCb;
  resetPage: () => void;
}

export const ACCOUNT_TYPES = [
  { label: "Дилер", value: "dealer" },
  { label: "Киа", value: "kia" },
]

export const STATUS_TYPES = [
  { label: "Активный", value: true },
  { label: "Неактивный", value: false },
]

const UsersFilter: React.FC<UserFilterProps> = (
  props
) => {
  const { initValues, onFilterChange, resetPage } = props;
  const { t } = useTranslation();

  const { form, submit, reset } = useUserFilterForm({ onFilterChange, initValues });

  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });
  const [searchValue, setSearchValue] = useState("");

  const { data: dealerList } =
    useGetFilteredDealerList({
      q: searchValue, limit: 0,
      //@ts-ignore
      holdingIds: form.watch('holdingId') ? [form.watch('holdingId')] : undefined
    });

  const preparedHoldingList = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );
  const handleReset = () => {
    reset();
    resetPage();
    onFilterChange(DEFAULT_FILTER_FORM_VALUES);
  }

  const handleSubmit = () => {
    resetPage();
    submit && submit();
  }

  return (
    <StyledUsersFilter>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInputField name={"searchById"} label={"Id пользователя"}/>
          </Col>
          <Col span={6}>
            <FormInputField name={"searchByName"} label={"ФИО"}/>
          </Col>
          <Col span={6}>
            <FormSelect
              name={"type"}
              label={"Категория пользователя"}
              options={ACCOUNT_TYPES}
            />
          </Col>
          <Col span={6}>
            <FormRoleSelect
              name={"roleId"}
              label={t("authorization.post") || ""}
              placeholder={"Выберите должность"}
              isShowHoldingRoles
            />
          </Col>
          <Col span={6}>
            <FormInputField name={"searchByEmail"} label={"Почта"}/>
          </Col>
          <Col span={6}>
            <FormSelect
              name={"status"}
              label={"Статус"}
              options={STATUS_TYPES}
            />
          </Col>
          <Col span={6}>
            <FormCountrySelect
              name={'country'}
              label={t("authorization.country") || ""}
              placeholder={"Выберите страну"}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name="holdingId"
              label={t("audits.list.holdingId") || ""}
              options={preparedHoldingList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name="dealerId"
              label={t("audits.list.dealerId") || ""}
              options={preparedDealerList(dealerList?.data?.data || [])}
              placeholder={t("audits.list.chooseList") || ""}
              onSearch={setSearchValue}
              isSearchShow
              allowClear
            />
          </Col>
        </Row>
      </FormProvider>

      <Row gutter={16}>
        <Col>
          <Button
            theme="primary"
            color="brand"
            size="middle"
            onClick={handleSubmit}
          >
            {t("audits.list.find") || ""}
          </Button>
        </Col>
        <Col>
          <Button
            color="brand"
            theme="secondary"
            size="middle"
            onClick={handleReset}
          >
            {t("audits.list.clear") || ""}
          </Button>
        </Col>
      </Row>
    </StyledUsersFilter>
  );
};

export default UsersFilter;
