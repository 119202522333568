import { TModificationCreateRecord } from "@/helpers/api/modifications/types";
import { components } from "@/helpers/api/swagger-scheme";

export enum CompleteSetFromFieldsEnum {
  Mpv = "mpv",
  FuelTypeId = "fuelTypeId",
  TransmissionTypeId = "transmissionTypeId",
  CarBodyTypeId = "carBodyTypeId",
  EngineTypeId = "engineTypeId",
  EngineVolumeId = "engineVolumeId",
  VinEngineTransmission = "vinEngineTransmission",
  Equipments = "equipments",
  Comment = "comment",
}

export type ICompleteSetFormValues = {
  [CompleteSetFromFieldsEnum.Mpv]: string | null;
  [CompleteSetFromFieldsEnum.FuelTypeId]: string | null;
  [CompleteSetFromFieldsEnum.TransmissionTypeId]: string | null;
  [CompleteSetFromFieldsEnum.CarBodyTypeId]: string | null;
  [CompleteSetFromFieldsEnum.EngineTypeId]: string | null;
  [CompleteSetFromFieldsEnum.EngineVolumeId]: string | null;
  [CompleteSetFromFieldsEnum.VinEngineTransmission]: string[];
  [CompleteSetFromFieldsEnum.Equipments]: Omit<
    components["schemas"]["Equipment"],
    "id" | "deletedAt"
  >[];
  [CompleteSetFromFieldsEnum.Comment]: string | undefined;
};

type ICompleteSetFormValuesExceptions = {
  [CompleteSetFromFieldsEnum.FuelTypeId]: string;
  [CompleteSetFromFieldsEnum.TransmissionTypeId]: string;
  [CompleteSetFromFieldsEnum.CarBodyTypeId]: string;
  [CompleteSetFromFieldsEnum.EngineTypeId]: string;
  [CompleteSetFromFieldsEnum.EngineVolumeId]: string;
};

export type ICompleteSetSuccessFormValues = Exclude<
  ICompleteSetFormValues,
  ICompleteSetFormValuesExceptions
> &
  ICompleteSetFormValuesExceptions;

export interface ICompleteSetDTOValues
  extends Omit<TModificationCreateRecord, "generationId"> {}
