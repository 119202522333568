import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  IHoldingsFilterFormValues,
  TOnSubmitHoldingsFilterFormCb,
  useHoldingsFilterForm,
} from "./hooks";
import {
  StyledButtonsContainer,
  StyledFormLayout,
  StyledHoldingsFilterWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

export interface IHoldingsFilter {
  initValues: IHoldingsFilterFormValues;
  onFiltersChange: TOnSubmitHoldingsFilterFormCb;
  filterOptions: any;
}

export const HoldingsFilter: React.FC<IHoldingsFilter> = (props) => {
  const { initValues, onFiltersChange, filterOptions } = props;

  const { t } = useTranslation();

  const { form, submit, onClearHandler } = useHoldingsFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  return (
    <StyledHoldingsFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <FormSelect
            name="holdingName"
            label={t("registers.holdingsDC.name") || ""}
            placeholder={t("dealerMaterials.letters.all") || ""}
            options={filterOptions}
            allowClear
            showSearch
          />

          <FormInputField
            name="dealerWarrantyCode"
            label={t("registers.holdingsDC.code") || ""}
            placeholder={t("dealerMaterials.letters.all") || ""}
            allowClear
          />
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("registers.holdingsDC.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("registers.holdingsDC.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledHoldingsFilterWrapper>
  );
};
