import { InputField } from "@/packages/formElements/fields/InputField";
import { FormProvider } from "react-hook-form";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import React, { useMemo, useState } from "react";
import {
  StyledButtons,
  StyledFilesWrapper,
  StyledFormGrid,
  StyledGroup,
  StyledGroupTitle,
  StyledStageContainer,
} from "./index.styles";
import { useFormValues } from "./hooks";

import { RadioGroup } from "@/packages/radio-group/RadioGroup";
import { Button } from "@/packages/button/Button";
import { useGetUserList } from "@/helpers/api/users/hooks";

import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";

import { FormRadio } from "@/packages/formElements/formFields/FormRadio";

import { FileDropzoneField } from "@/packages/formElements/fields/FileDropzoneField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { DatepickerField } from "@/packages/formElements/fields/DatepickerField";
import { useTranslation } from "react-i18next";
import { UploadType } from "@/packages/UploadImageAndFileDropzone";
import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";

interface IFillWarrantyFirstStage {
  id: number;
  data: TGetWarrantyByIdResponse;
  isStarting: boolean;
  onCompleteStage: (stage, value) => void;
}

enum WarrantyAuditTypes {
  IN_HOUSE_KIA = "kia",
  IN_HOUSE_FACTORY = "factory",
  IN_HOUSE_EXTERNAL_AUDITOR = "external auditor",
  ONLINE = "dealer",
}

export const FillWarrantyFirstStage: React.FC<IFillWarrantyFirstStage> = (
  props
) => {
  const { data, isStarting } = props;
  const { onCompleteStage } = props;
  const [selectedType, setSelectedType] = useState(
    WarrantyAuditTypes.IN_HOUSE_KIA
  );
  const [files, setFiles] = useState({});

  const { data: users } = useGetUserList({
    dealerId: data.dealerId,
    permissionSlug: "guarantee.dealer-violations-actions",
    permissionAction: "update",
  });

  const { data: auditorUsers } = useGetUserList({
    roleSlug: "kia-external-auditor",
  });

  const { t } = useTranslation();
  const mappedUsers = useMemo(
    () =>
      users?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [],
    [users]
  );

  const mappedAuditorUsers = useMemo(
    () => {
      return auditorUsers?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [];
    },
    [auditorUsers]
  );

  const formSubmit = (value) => {
    const formData = new FormData();
    Object.entries(value).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    Object.entries(files).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.delete("type");
    formData.delete("date");
    formData.append("type", selectedType);
    onCompleteStage(1, formData);
  };
  const { form, onSubmitHandler } = useFormValues(formSubmit);

  const today = new Date();

  return (
    <>
      <StyledStageContainer>
        <FormProvider {...form}>
          <StyledFormGrid>
            <InputNumberField
              onChange={(value) => form.setValue('visitNumber', value ?? undefined)}
              name="visitNumber"
              value={data.visitNumber}
              label={t("warrantyAudits.list.visitNum") || ""}
            />
            <InputField
              name="dealerName"
              value={data?.dealer?.merchantName}
              label={t("audits.list.dealership") || ""}
              readOnly={true}
            />
          </StyledFormGrid>
          <StyledGroup>
            <StyledGroupTitle weight={700} size={14}>
              {t("audits.list.auditType") || ""}
            </StyledGroupTitle>
            <RadioGroup
              isColumn={true}
              defaultValue={selectedType}
              onChange={(value) => setSelectedType(value as WarrantyAuditTypes)}
            >
              <FormRadio
                name="type"
                groupValue={WarrantyAuditTypes.IN_HOUSE_KIA}
                label={t("warrantyAudits.list.fullTime") || ""}
              />
              <FormRadio
                name="type"
                groupValue={WarrantyAuditTypes.IN_HOUSE_FACTORY}
                label={t("warrantyAudits.list.fullTime_2") || ""}
              />
              {selectedType === WarrantyAuditTypes.IN_HOUSE_FACTORY ? (
                <StyledFormGrid>
                  <FormInputField
                    name="externalAuditorEmail"
                    placeholder={t("warrantyAudits.list.enterEmail") || ""}
                    label="E-mail"
                  />
                </StyledFormGrid>
              ) : (
                <></>
              )}
              <FormRadio
                name="type"
                groupValue={WarrantyAuditTypes.IN_HOUSE_EXTERNAL_AUDITOR}
                label={t("warrantyAudits.list.fullTime_3") || ""}
              />
              <FormRadio
                name="type"
                groupValue={WarrantyAuditTypes.ONLINE}
                label={t("warrantyAudits.list.online") || ""}
              />
            </RadioGroup>
          </StyledGroup>
          {selectedType === WarrantyAuditTypes.IN_HOUSE_EXTERNAL_AUDITOR && (
            <StyledFormGrid>
              <FormSelect
                name="implementerId"
                label={t("warrantyAudits.list.extAuditor") || ""}
                placeholder={t("warrantyAudits.list.extAuditor") || ""}
                options={mappedAuditorUsers}
              />
            </StyledFormGrid>
          )}
          <StyledFormGrid>
            <FormSelect
              name="responsibleId"
              label={t("audits.list.representative") || ""}
              placeholder={t("audits.list.representativeDescr") || ""}
              options={mappedUsers}
              defaultValue={data.responsibleId}
            />
            <DatepickerField
              label={t("audits.list.auditDate") || ""}
              value={today}
              readOnly={true}
            />
          </StyledFormGrid>
          <StyledFilesWrapper>
            <FileDropzoneField
              typeInfo={UploadType.XLS}
              path="requests/files"
              placeholder={t("warrantyAudits.list.file") || ""}
              onChange={(event) => {
                const eventCopy = { ...event };

                if (eventCopy?.target?.files)
                  setFiles({ ...files, spares: eventCopy?.target?.files[0] });
              }}
              variantBtn="contained"
              isUpload={false}
              // isWithCustomPlaceholder={true}
            />
            <FileDropzoneField
              typeInfo={UploadType.XLS}
              path="requests/files"
              placeholder={t("warrantyAudits.list.file_2") || ""}
              onChange={(event) => {
                const eventCopy = { ...event };

                if (eventCopy?.target?.files)
                  setFiles({ ...files, warranty: eventCopy?.target?.files[0] });
              }}
              variantBtn="contained"
              isUpload={false}
              // isWithCustomPlaceholder={true}
            />
            <FileDropzoneField
              typeInfo={UploadType.XLS}
              path="requests/files"
              placeholder={t("warrantyAudits.list.file_3") || ""}
              onChange={(event) => {
                const eventCopy = { ...event };
                if (eventCopy?.target?.files)
                  setFiles({
                    ...files,
                    allClaims: eventCopy?.target?.files[0],
                  });
              }}
              variantBtn="contained"
              isUpload={false}
              // isWithCustomPlaceholder={true}
            />
          </StyledFilesWrapper>
          {selectedType === WarrantyAuditTypes.IN_HOUSE_FACTORY && (
            <StyledFilesWrapper>
              <FileDropzoneField
                typeInfo={UploadType.XLS}
                path="requests/files"
                placeholder={t("warrantyAudits.list.file_4") || ""}
                onChange={(event) => {
                  const eventCopy = { ...event };
                  if (eventCopy?.target?.files)
                    setFiles({
                      ...files,
                      factorySpares: eventCopy?.target?.files[0],
                    });
                }}
                variantBtn="contained"
                isUpload={false}
                // isWithCustomPlaceholder={true}
              />
              <FileDropzoneField
                typeInfo={UploadType.XLS}
                path="requests/files"
                placeholder={t("warrantyAudits.list.file_5") || ""}
                onChange={(event) => {
                  const eventCopy = { ...event };
                  if (eventCopy?.target?.files)
                    setFiles({
                      ...files,
                      factoryWarranty: eventCopy?.target?.files[0],
                    });
                }}
                // isWithCustomPlaceholder={true}
                variantBtn="contained"
                isUpload={false}
              />
            </StyledFilesWrapper>
          )}
          <StyledButtons>
            <Button
              theme="primary"
              color="brand"
              onClick={onSubmitHandler}
              isLoading={isStarting}
            >
              {t("audits.list.filling") || ""}
            </Button>
          </StyledButtons>
        </FormProvider>
      </StyledStageContainer>
    </>
  );
};
