import {
  Popconfirm as AntPopconfirm,
  PopconfirmProps as AntPopconfirmProps,
} from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IDeletePopconfirm extends Omit<AntPopconfirmProps, "title"> {
  title?: string;
}

export const DeletePopconfirm: React.FC<IDeletePopconfirm> = (props) => {
  const { t } = useTranslation();
  const { title = t("deleteConfirm.title") || "", ...rest } = props;

  return (
    <AntPopconfirm
      title={title}
      okText={t("deleteConfirm.yes") || ""}
      cancelText={t("deleteConfirm.cancel") || ""}
      {...rest}
    />
  );
};
