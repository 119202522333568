import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  IEquipmentListField,
  EquipmentListField,
} from "@/packages/formElements/fields/EquipmentListField";

export interface IFormEquipmentListField
  extends Omit<IEquipmentListField, "error" | "value" | "onChange"> {
  name: string;
}

const _FormEquipmentListField: React.FC<IFormEquipmentListField> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <EquipmentListField
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormEquipmentListField = React.memo(_FormEquipmentListField);
