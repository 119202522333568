import { TAuditSectionWithStats } from "@/helpers/api/audit-total/types";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";

export const getDefaultAuditTotalHeaders = <TRecord extends object>(
  options: {},
  data
): IHeaderItem<TAuditSectionWithStats>[] => {
  const headers = [
    {
      Header: "",
      accessor: "order",
      id: "order",
      width: 70,
      minWidth: 70,
      maxWidth: 70,

      Cell: ({ row }) => {
        return (
          <>
            {/* @ts-ignore */}
            {+row?.id + 1}
          </>
        );
      },
    },
    {
      Header: "Раздел",
      accessor: "title",
      id: "title",
      minWidth: 180,
      Footer: "Всего",
    },
    {
      Header: "Количество вопросов и их вес",
      isFooterHidden: true,
      border: {
        bottom: "1px solid #C7C8CA",
        left: "1px solid #C7C8CA",
        right: "1px solid #C7C8CA",
      },
      columns: [
        {
          Header: "Количество вопросов",
          accessor: "questionsCount",
          id: "questionsCount",
          width: 80,
          Footer: (props) => {
            let total = 0;
            props.data.forEach((el) => {
              total = total + el.questionsCount;
            });

            return total;
          },
          border: {
            left: "1px solid #C7C8CA",
            right: "1px solid #C7C8CA",
          },
        },
      ],
    },
    {
      Header: " ",
      width: "100px",
      isFooterHidden: true,
      columns: [
        {
          Header: "Доля модуля в общем аудите, %",
          accessor: "proportion",
          id: "proportion",
          Cell: ({ row }) => {
            return row.original.proportion + "%";
          },
          Footer: (props) => {
            let total = 0;
            props.data.forEach((el) => {
              total = total + el.proportion;
            });
            return total + "%";
          },
        },
      ],
    },
    {
      Header: " ",
      isFooterHidden: true,
      columns: [
        {
          Header: "Максимальное количество баллов",
          accessor: "maxScore",
          id: "maxScore",

          Footer: (props) => {
            let total = 0;
            props.data.forEach((data) => {
              total = total + data.maxScore;
            });

            return total;
          },
        },
      ],
    },
    {
      Header: " ",
      isFooterHidden: true,
      columns: [
        {
          Header: "Достигнутый результат",
          accessor: "score",
          id: "score",

          Footer: (props) => {
            let total = 0;
            props.data.forEach((data) => {
              total = total + data.score;
            });

            return total;
          },
        },
      ],
    },
    {
      Header: " ",
      isFooterHidden: true,
      columns: [
        {
          Header: "Результат в процентах, %",
          accessor: "percent",
          id: "percent",
          Cell: ({ row }) => {
            return row.original.percent + "%";
          },
          Footer: (props) => {
            const scoreObject = props.data.reduce(
              (acc: { score: number; maxScore: number }, item) => {
                return {
                  score: acc.score + item.score,
                  maxScore: acc.maxScore + item.maxScore,
                };
              },
              { score: 0, maxScore: 0 }
            );
            const percent = Math.round(
              (scoreObject.score * 100) / scoreObject.maxScore
            );
            return percent + "%";
          },
        },
      ],
    },
  ];

  const weights = new Set();
  data?.forEach((section) =>
    Object.keys(section?.questionsWeight).forEach((el) => {
      weights.add(el);
    })
  );

  Array.from(weights).forEach((el) => {
    headers[2]?.columns?.push({
      Header: `${el} баллов`,
      accessor: `questionsWeight[${el}]`,
      id: `questionsWeight[${el}]`,
      alignCell: "center",
      width: 80,
      // @ts-ignore
      Footer: (props) => {
        let total = 0;
        props.data.forEach((data) => {
          // @ts-ignore
          total = total + data.questionsWeight[el];
        });

        return total;
      },
      // @ts-ignore
      border: {
        right: "1px solid #C7C8CA",
      },
    });
  });
  //@ts-ignore
  return headers;
};
