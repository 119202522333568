import { TFileRecord } from "@/helpers/api/files/types";
import { TGenerationCreateRecord } from "@/helpers/api/generations/types";
import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import { TCodeListValue } from "@/packages/formElements/fields/CodeListField";

export enum GenerationFieldsEnum {
  Name = "name",
  MaterialCode = "materialCode",
  GenerationYearRange = "generationYearRange",
  IsRestyling = "isRestyling",
  RestylingName = "restylingName",
  Photo = "photo",
  Number = "number",
  VinModelClasses = "vinModelClasses",
}

interface TRequiredYearRangeInputFieldValue {
  start: number;
  end: number;
}

export interface IGenerationFormValues {
  [GenerationFieldsEnum.Name]: string;
  [GenerationFieldsEnum.MaterialCode]: string;
  [GenerationFieldsEnum.GenerationYearRange]?: IYearRangeInputFieldValue;
  [GenerationFieldsEnum.IsRestyling]: boolean;
  [GenerationFieldsEnum.RestylingName]: string;
  [GenerationFieldsEnum.Photo]?: TFileRecord | null;
  [GenerationFieldsEnum.Number]: number;
  [GenerationFieldsEnum.VinModelClasses]: TCodeListValue;
}

type IGenerationSuccessFormValuesExceptions = {
  [GenerationFieldsEnum.GenerationYearRange]: TRequiredYearRangeInputFieldValue;
  [GenerationFieldsEnum.Photo]: TFileRecord;
};

export type IGenerationSuccessFormValues = Exclude<
  IGenerationFormValues,
  IGenerationSuccessFormValuesExceptions
> &
  IGenerationSuccessFormValuesExceptions;

export interface IGenerationFormDTOValues
  extends Omit<TGenerationCreateRecord, "modelId"> {}
