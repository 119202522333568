import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDealersInfoTable } from "@/components/MonitoringQuality/MonitoringDealersInfo";
import { useGetKiaMonitoringQualityCars } from "@/helpers/api/monitoringQuality/hooks";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Loader } from "@/packages/loader/Loader";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { Title } from "@/packages/title/Title";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledPagination } from "./index.styles";
import { t } from "i18next";

export interface IMonitoringQualityDealersCarsInfoProps {
}

export const MonitoringQualityCarsDealersInfo: React.FC<
  IMonitoringQualityDealersCarsInfoProps
> = (props) => {
  const params = useParams();
  const { paginationParams, setPage } = usePagination();

  const [sortParams, setSortParams] = useState({});

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      sortBy: header.id,
      orderBy: order?.toUpperCase(),
    }));
  };

  const {
    data: monitoringQualityCarsData,
    isLoading,
    refetch: refetchMonitoringCars,
  } = useGetKiaMonitoringQualityCars({
    dealerIds: [params.dealerId as string],
    qualityMonitoringIds: [params.id as string],
    ...paginationParams,
    ...sortParams,
  });

  const monitoringQualityCarsList = monitoringQualityCarsData?.data.data;

  const generateTitle = () => {
    if (monitoringQualityCarsList && monitoringQualityCarsList.length) {
      //@ts-ignore
      const dealer = monitoringQualityCarsList[0].dealer;
      return `${dealer?.sapCode} ${dealer?.name}(${dealer?.city?.name})`;
    }
  };

  return (
    <DashboardLayout
      title={t("monitoring.requestsDisplay") || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName="quality-monitoring.request-cars"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
      >
        {isLoading ? (
          <Loader size={48}/>
        ) : (
          <>
            <Title level={3} weight={400}>
              {generateTitle()}
            </Title>
            <MonitoringDealersInfoTable
              monitoringQualityCarsList={monitoringQualityCarsList}
              refetchMonitoringCars={refetchMonitoringCars}
              onSort={handleSort}
            />
            {monitoringQualityCarsData?.data.meta?.itemCount ? (
              <StyledPagination
                current={paginationParams.page}
                onChange={setPage}
                pageSize={10}
                total={monitoringQualityCarsData?.data.meta?.itemCount}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
