import React, { useEffect } from "react";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import { StyledTableSimple } from "./index.styles";
import styled from "styled-components";

interface IAuditWarrantyTableAdapter extends ITableSimple {
  isLoading?: boolean;
  paginationParams: { page: number; limit: number };
  setPage: (page: number) => void;
  isDealerKia?: boolean;
  withOverflow?: boolean;
  requestParams?: any;
  isCutVerticalPadding?: boolean;
}

const StyledPagination = styled(Pagination)`
    margin-top: 32px;
`;

export const AuditWarrantyTableAdapter: React.FC<IAuditWarrantyTableAdapter> = (
  props
) => {
  const {
    paginationParams,
    isLoading,
    withOverflow = true,
    autoOverflow = false,
    setPage,
    requestParams,
    isCutVerticalPadding,
    ...rest
  } = props;

  useEffect(() => {
    setPage(1);
  }, [requestParams]);

  return (
    <StyledTableSimple>
      {!isLoading ? (
        <>
          <TableSimple
            {...rest}
            autoOverflow={autoOverflow}
            withOverflow={withOverflow}
            alignCell="start"
            alignHeader="start"
            isCutVerticalPadding={isCutVerticalPadding}
          />
          {paginationParams.limit > 1 ? (
            <StyledPagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={1}
              total={paginationParams.limit}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Loader size={48}/>
        </>
      )}
    </StyledTableSimple>
  );
};
