import moment from "moment";
import { MONTHS } from "@/helpers/consts";
import { t } from "i18next";

export const transformDottedDateToIso = (date: string): string =>
  date.split(".").reverse().join("-");

export const transformIsoDateToDotted = (date: string): string =>
  date.split("-").reverse().join(".");

export const getNumberWithPrefixZero = (value: number): string =>
  value < 10 ? `0${value}` : String(value);

export const transformDateToDotted = (date: string): string => {
  if (!date) return "";

  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  return `${getNumberWithPrefixZero(day)}.${getNumberWithPrefixZero(
    month
  )}.${year}`;
};

export const getShiftedDate = (
  date: Date | null,
  days: number
): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDatesArray = (startDate: Date, endDate: Date): Date[] => {
  const result: Date[] = [];

  if (!startDate || !endDate) {
    return result;
  }

  let currentDate = startDate;
  while (currentDate <= endDate) {
    currentDate.setHours(0, 0, 0, 0);
    result.push(currentDate);
    currentDate = getShiftedDate(currentDate, 1) as Date;
  }

  return result;
};

export interface IDatesTree {
  [key: number]: {
    [key: number]: Date[];
  };
}

export const getDatesTree = (dates: Date[]): IDatesTree | null => {
  if (!dates || !dates.length) {
    return null;
  }

  const result: IDatesTree = {};

  dates.forEach((el) => {
    const year = el.getFullYear();
    const month = el.getMonth();
    const day = el.getDate();

    if (!result[year]) {
      result[year] = {};
    }

    if (!result[year][month]) {
      result[year][month] = [];
    }

    result[year][month].push(el);
  });

  return result;
};

export const getMonthByIndex = (index: number): string => {
  return t(MONTHS[index]);
};

export const getDateDiff = (startDate: Date, endDate: Date) => {
  return moment(endDate).diff(moment(startDate), "days") + 1;
};

export const isDateToday = (date1: Date, date2: Date) => {
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  ) {
    return true;
  }
  return false;
};

export const getYearsList = ({ yearsCount = 20 }) => {
  const list: { value: number; label: string }[] = [];

  const currentYear = new Date().getFullYear();
  let count = currentYear - Math.round(yearsCount / 2);
  do {
    count += 1;
    list.push({ value: count, label: count.toString() });
  } while (list.length <= yearsCount);
  return list;
};

export const getClearHoursTodayDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

/*
* Конвертирование Date в string c учетом offSet
* Необходимо при отправке даты через Get params, так как дата конвертируется с помощью Date.toJson()
* без учета offset
* */
export function formatDateWithOffset(date: Date | undefined, isFirstDate: boolean = true) {
  if (!date) {
    return;
  }
  const pad = (num: number): string => {
      return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(isFirstDate ? 0 : 23) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    '.000Z';
}

export function countDaysBetweenDates(date1, date2) {
  if (!date1 || !date2) {
    return null;
  }
  const date1MS = date1.getTime();
  const date2MS = date2.getTime();

  const timeDiff = date2MS - date1MS;

  return Math.floor(timeDiff / (1000 * 3600 * 24));
}
