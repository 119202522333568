import {
  useExportCoefficients,
  useGetFactorZ,
} from "@/helpers/api/bonuses/calculations/hooks";
import { TGetBonusesFactorZRequest } from "@/helpers/api/bonuses/calculations/types";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { useProfile } from "@/hooks/contexts/useProfile";
import { usePagination } from "@/hooks/usePagination";
import { useRequestParams } from "@/hooks/useRequestParams";
import {
  HeaderArgType,
  SortOrder,
  StickyTable,
} from "@/packages/stickyTable/StickyTable";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { BonusesFilters } from "../BonusesFilters";
import { CalculationsFilter } from "../CalculationsFilter";
import { BonusesTableAdapter } from "../ReportsCalculation/BonusesTableAdapter";
import { DealerBonusesTitleHeader } from "../ReportsCalculation/DealerBonusesTab/DealerBonusesTitleHeader";
import { getBonusesCalculationHeaders } from "./hooks";
import { StyledTab } from "./index.styles";

interface IBonusesCalculation {}

export const BonusesCalculation: React.FC<IBonusesCalculation> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const { setPage, paginationParams } = usePagination();
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);
  const activeTab = new URLSearchParams(search).get("activeTab");
  const [isAllDealers, setIsAllDealers] = useState(true);
  const [searchDealer, setSearchDealer] = useState("");
  const [requestParams, setRequestParams] = useRequestParams({
    quarter: {
      type: "filter",
      value: selectedQuarter,
    },
    year: {
      type: "filter",
      value: selectedYear,
    },
    coefficientA: {
      type: "modules",
      value: false,
    },
    coefficientC: {
      type: "modules",
      value: false,
    },
  });

  const {
    state: { profile },
  } = useProfile();
  const isProfileKia = profile?.type === "kia";
  const dealerId = profile?.dealer?.id;

  const [sortParams, setSortParams] = useState({
    "sort[sortAt]": undefined,
    "sort[sortBy]": undefined,
  });

  useEffect(() => {
    setSearchParams({
      quarter: requestParams?.quarter?.value?.toString() || "",
      year: requestParams?.year?.value?.toString() || "",
      activeTab: activeTab || "sells",
    });
  }, [requestParams]);

  const { mutateAsync: exportCoefficients } = useExportCoefficients();

  const { data: factorZ, isLoading } = useGetFactorZ({
    ...(requestParamsMapper(requestParams) as TGetBonusesFactorZRequest),
    "page[page]": paginationParams.page,
    "page[limit]": paginationParams.limit,
    "modules[coefficientB]": true,
    "filter[dealerSearch]": searchDealer,
    "filter[dealerId]": !isProfileKia ? dealerId : undefined,
    ...sortParams,
  });

  const headers = getBonusesCalculationHeaders({
    moduleA: requestParams.coefficientA.value,
    moduleC: requestParams.coefficientC.value,
  });

  const handleFiltersApply = (filters) => {
    if (filters.dealerId) {
      setIsAllDealers(false);
    } else {
      setIsAllDealers(true);
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (typeof value === "boolean") {
        //@ts-ignore
        setRequestParams((prev) => ({
          ...prev,
          [key]: {
            type: "modules",
            value,
          },
        }));
      } else {
        //@ts-ignore
        setRequestParams((prev) => ({
          ...prev,
          [key]: {
            type: "filter",
            value,
          },
        }));
      }
    });
  };

  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setSortParams({
      "sort[sortAt]": order ? order.toUpperCase() : (undefined as any),
      "sort[sortBy]": order ? accessor.id : (undefined as any),
    });
  };

  const handleCoefficientsExport = () => {
    exportCoefficients(
      {
        ...(requestParamsMapper(requestParams) as TGetBonusesFactorZRequest),
        "page[page]": paginationParams.page,
        "page[limit]": paginationParams.limit,
        "modules[coefficientB]": true,
        "filter[dealerSearch]": searchDealer,
        "filter[dealerId]": !isProfileKia ? dealerId : undefined,
        ...sortParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "requests.xlsx");
        },
      }
    );
  };

  return (
    <StyledTab>
      <BonusesFilters
        quarter={selectedQuarter}
        isAllDealers={isAllDealers && isProfileKia}
        //@ts-ignore
        searchValue={requestParams?.searchValue?.value?.toString() || ""}
        onSearchInput={(value) => {
          setSearchDealer(value || "");
        }}
      >
        <CalculationsFilter
          quarter={selectedQuarter.toString()}
          year={selectedYear.toString()}
          onFiltersApply={handleFiltersApply}
          isProfileKia={isProfileKia}
        />
      </BonusesFilters>
      <DealerBonusesTitleHeader
        handleExport={handleCoefficientsExport}
        hasButton={false}
        disablePrev={
          requestParams?.quarter?.value
            ? +requestParams?.quarter?.value <= 1
            : true
        }
        disableNext={
          requestParams?.quarter?.value
            ? +requestParams?.quarter?.value >= 4
            : true
        }
        disableExport={!factorZ?.data.data}
        onNextClick={() =>
          setRequestParams((prev) =>
            prev?.quarter?.value
              ? {
                  ...prev,
                  quarter: {
                    type: "filter",
                    value: +prev?.quarter?.value + 1,
                  },
                }
              : prev
          )
        }
        onPrevClick={() =>
          setRequestParams((prev) =>
            prev?.quarter?.value
              ? {
                  ...prev,
                  quarter: {
                    type: "filter",
                    value: +prev?.quarter?.value - 1,
                  },
                }
              : prev
          )
        }
      />
      <BonusesTableAdapter
        setPage={setPage}
        paginationParams={{
          ...paginationParams,
          limit: factorZ?.data.meta?.pageCount || 0,
        }}
        isLoading={isLoading}
        children={
          <StickyTable
            // @ts-ignore
            headers={headers || []}
            data={factorZ?.data.data || []}
            onSort={handleSort}
            onSearch={(_, value) => {
              setSearchDealer(value || "");
            }}
          />
        }
      />
    </StyledTab>
  );
};
