import { useForm } from "@/hooks/useForm";
import {
  IGenerationFormDTOValues,
  IGenerationFormValues,
  IGenerationSuccessFormValues,
} from "../GenerationFields/types";
import {
  DEFAULT_GENERATION_FORM_VALUES,
  generationFormSchema,
  generationFormValuesToDTO,
} from "../GenerationFields/utils";

export type TOnSubmitCreateGenerationFormCallback = (
  values: IGenerationFormDTOValues
) => void;

export const useCreateGenerationForm = (
  onSubmit: TOnSubmitCreateGenerationFormCallback
) => {
  const onSubmitHandler = (values) => {
    const dtoValues = generationFormValuesToDTO(values);
    onSubmit(dtoValues);
  };
  return useForm<IGenerationFormValues, IGenerationSuccessFormValues>({
    validateFormSchema: generationFormSchema(),
    defaultValues: DEFAULT_GENERATION_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};
