import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  useDeleteTariffs,
  usePatchTatiffs,
} from "@/helpers/api/flex/tariffs/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import { useCallback } from "react";
import { TariffsModal } from "../TariffsModal";
import { t } from "i18next";

export interface IHeadersProps {}

const _Headers = () => {
  const headers = [
    {
      Header: "maintenanceGrids.gridsModel.model",
      isSortable: true,
      accessor: "model",
      width: 50,
      Cell: (data) => {
        const id = data.cell.row.id;
        const modelName = data.data[id].generation?.model?.name;
        const modelGeneration = data.data[id]?.generation.name;
        const restylingName = data.data[id]?.generation?.restylingName || "";

        return (
          <Paragraph
            size={12}
          >{`${modelName} ${modelGeneration} ${restylingName}`}</Paragraph>
        );
      },
    },
    {
      Header: "kiaFlex.weekCost",
      accessor: "weekCost",
      width: 50,
    },
    {
      Header: "kiaFlex.monthCost",
      accessor: "monthCost",
      width: 50,
    },
    {
      Header: "kiaFlex.twelveMonthCost",
      accessor: "twelveMonthCost",
      width: 50,
    },

    {
      Header: "kiaFlex.twentyFourMonthCost",
      accessor: "twentyFourMonthCost",
      width: 50,
    },
    {
      Header: "kiaFlex.thirtySixMonthCost",
      accessor: "thirtySixMonthCost",
      width: 50,
    },
    {
      Header: "kiaFlex.actions",
      accessor: "actions",
      width: 50,

      Cell: (data) => {
        const id = data.cell.row.id;
        const itemId = data.data[id].id;

        const { createNotificationError, createNotificationSuccess } =
          useNotification();

        const { mutateAsync: editTariff } = usePatchTatiffs();

        const { mutateAsync: deleteTariff } = useDeleteTariffs();

        const onSubmitHandler = useCallback(
          async (values) => {
            try {
              await editTariff(values);
              createNotificationSuccess(t("kiaFlex.editSuccess") || "");
            } catch (error) {
              console.log("error    ", error);
              createNotificationError(t("kiaFlex.deleteError") || "");
            }
          },
          [createNotificationError, createNotificationSuccess, editTariff]
        );

        const onClickHandler = useCallback(
          async (id) => {
            try {
              await deleteTariff({ id });
              createNotificationSuccess(t("kiaFlex.deleteSuccess") || "");
            } catch (error) {
              console.log("error    ", error);
              createNotificationError(t("kiaFlex.deleteError") || "");
            }
          },
          [createNotificationError, createNotificationSuccess, deleteTariff]
        );

        const {
          modalState: editTariffsState,
          openModal: openEditTariffsModal,
        } = useModal<any>((close) => {
          return async (payload) => {
            await onSubmitHandler(payload);
            close();
          };
        });
        return (
          <>
            <Space>
              <PermissionsAdapter
                resourceAction="delete"
                resourceName="flex.tariff"
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => onClickHandler(itemId)}
                />
              </PermissionsAdapter>

              <PermissionsAdapter
                resourceName="flex.tariff"
                resourceAction="update"
              >
                <IconButton
                  icon="edit"
                  size="l"
                  color="black"
                  variant="tertiary"
                  onClick={() => openEditTariffsModal()}
                />
              </PermissionsAdapter>
            </Space>
            {editTariffsState.isOpen ? (
              <TariffsModal id={itemId} mode="edit" {...editTariffsState} />
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  return headers;
};
export const Headers = _Headers;
