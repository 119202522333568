import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TBodyCreateRequest,
  TBodyCreateResponse,
  TBodyDeleteRequest,
  TBodyDeleteResponse,
  TBodyPutRequest,
  TBodyPutResponse,
  TGetBodyByIdRequest,
  TGetBodyByIdResponse,
  TGetBodyListRequest,
  TGetBodyListResponse,
} from "./types";

export const useGetBodyList = (request: TGetBodyListRequest) => {
  return useQuery(["bodies", "list", request], () => {
    return api.get<TGetBodyListResponse>("/bodies", {
      params: request,
    });
  });
};

export const useGetBodyById = (request: TGetBodyByIdRequest) => {
  return useQuery(["bodies", "detail", request.id], () => {
    return api.get<TGetBodyByIdResponse>(`/bodies/${request.id}`);
  });
};

export const useCreateBody = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TBodyCreateRequest) => {
      return api.post<TBodyCreateResponse>("/bodies", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bodies", "list"]);
      },
    }
  );
};

export const usePutBody = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TBodyPutRequest) => {
      return api.put<TBodyPutResponse>(`/bodies/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["bodies", "list"]);
        queryClient.invalidateQueries(["bodies", "detail", variables.id]);
      },
    }
  );
};

export const useDeleteBody = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TBodyDeleteRequest) => {
      return api.delete<TBodyDeleteResponse>(`/bodies/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["bodies", "list"]);
        queryClient.invalidateQueries(["bodies", "detail", variables.id]);
      },
    }
  );
};
