import { ModelFieldsName } from "../../KiaFlexCar/KiaFlexFormsFields/ModelFields/types";
import { TSideCheck } from "@/components/KiaFlex/KiaFlexOrders/NewCarModal";
import { StatusOrders } from "@/components/KiaFlex/KiaFlexCar/Tables/KiaFlexOrdersTable";

export const transformDTOtoRecord = (record: any) => {
  const equipment = record?.equipment;
  const equipmentValue = { label: equipment?.name, value: equipment?.id };

  const modification = equipment?.modification;
  const modificationValue = {
    label: modification?.fullName,
    value: modification?.id,
  };

  const generation = modification?.generation;
  const generationValue = {
    label: `${generation?.name} ${
      generation?.restylingName ? generation?.restylingName : ""
    }`,
    value: generation?.id,
  };

  const model = generation?.model;
  const modelValue = {
    label: model?.name,
    value: model?.id,
  };

  const color = record?.color;
  const colorValue = { label: color?.color, value: color?.id };

  const comment = record?.comment;

  return {
    [ModelFieldsName.MODEL]: modelValue,
    [ModelFieldsName.EQUIPMENT]: equipmentValue,
    [ModelFieldsName.GENERATION]: generationValue,
    [ModelFieldsName.COLOR]: colorValue.label,
    comment: comment,
    [ModelFieldsName.MODIFICATION]: modificationValue,
    id: record?.id,
    status: record?.status,
    payload: record?.payload,
    deliveryDate: record?.deliveryDate ? new Date(record?.deliveryDate) : null,
    vin: record?.vin,
  };
};

export const transformValueToDto = (values, editRecord, isKia) => {
  const result = {};

  if (values["equipmentId"]?.value === editRecord["equipment"].id) {
  } else {
    result["equipmentId"] = values["equipmentId"];
  }

  if (values["color"] === editRecord["color"].color) {
  } else {
    result["color"] = values["color"];
  }

  if (values["comment"] === editRecord["comment"]) {
  } else {
    result["comment"] = values["comment"];
  }
  if (values["vin"] === editRecord["vin"]) {
  } else {
    result["vin"] = values["vin"];
  }
  if (editRecord.status.status === "delivery") {
    result["deliveryDate"] = values["deliveryDate"];
  }

  if (editRecord.status.sideCheck === TSideCheck.DEALER &&
    editRecord.status.status === StatusOrders.DELIVERY && isKia) {
    return {
      ...result,
      payload: values?.payload,
    }
  }

  return {
    ...result,
    statusId: values?.status?.id ?? values.status,
    payload: values?.payload,
  };
};

export const transformDataToDTOCreateOrders = (values) => {
  return {
    color: values.color,
    comment: values?.comment,
    equipmentId: values.equipmentId,
    generation: values.generation,
    model: values.model,
    modification: values.modification,
    vin: values.vin,
  };
};
