import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled, { css } from "styled-components";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectField = styled(SelectField)`
  width: 188px;
`;

const StyledTableSimple = styled.div`
  overflow: hidden;
  /* width: 100%; */
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)``;

const StyledAnswerButtons = styled(Space)<{
  isAnswer: boolean;
  hasAnswer: boolean;
}>`
  ${({ isAnswer, hasAnswer }) =>
    hasAnswer &&
    (isAnswer
      ? css`
          div {
            :first-child {
              button {
                background-color: #62bb2c;
                color: white;
              }
            }
          }
        `
      : css`
          div {
            :last-child {
              button {
                background-color: #e32446;
                color: white;
              }
            }
          }
        `)}
`;

const StyledSelect = styled.div`
  width: 107px;
`;

const StyledPhotoInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMessage = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
`;

const StyledNestedQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const StyledSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export {
  StyledPhotoInfoBlock,
  StyledSpan,
  StyledMessage,
  StyledSelect,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
  StyledAnswerButtons,
  StyledNestedQuestions,
};
