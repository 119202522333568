import React from "react";
import { Link } from "react-router-dom";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import {
  StyledButton,
  StyledInfoCardContainer,
  StyledInfoContainer,
  StyledNewsCard,
  StyledParagraph,
  StyledPhoto,
  StyledPostContainer,
} from "./index.styles";

export interface INewsCard {
  id: string;
  currentGroupId: string;
  imgUrl: string;
  author: string;
  title: string;
  description: string;
  createdAt: string;
  onEdit: (id: string) => void;
}

export const NewsCard: React.FC<INewsCard> = ({
  id,
  currentGroupId,
  imgUrl,
  author,
  title,
  description,
  createdAt,
  onEdit,
}) => {
  return (
    <Link to={`/news/${id}`}>
      <StyledNewsCard>
        <StyledPhoto src={imgUrl} />
        <StyledInfoContainer>
          <StyledPostContainer>
            <Paragraph size={12} color="gray200">
              {author}
            </Paragraph>
            <Paragraph size={12} color="gray200">
              {createdAt}
            </Paragraph>
          </StyledPostContainer>

          <Paragraph size={18}>{title}</Paragraph>
          <StyledInfoCardContainer>
            <StyledParagraph size={14} color="gray200">
              {description}
            </StyledParagraph>
          </StyledInfoCardContainer>

          <PermissionsAdapter
            resourceName="news"
            resourceAction="update"
            currentGroupId={currentGroupId}
          >
            <Link to={`/news/edit/${id}`}>
              <StyledButton
                isDisabled={false}
                theme="secondary"
                color="brand"
                size="small"
                onClick={() => onEdit(id)}
              >
                Редактировать
              </StyledButton>
            </Link>
          </PermissionsAdapter>
        </StyledInfoContainer>
      </StyledNewsCard>
    </Link>
  );
};
