import React from "react";
import { Icon } from "@/packages/icon/Icon";
import { Select, Space } from "antd";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { TLocales } from "@/helpers/api/country/type";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    background-color: #f9f9fd !important;
    border-radius: 32px !important;
    padding: 0 14px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export interface ILocaleSelect {
  selectedLanguage: string;
  onLocaleSelect: (value: string) => void;
}

interface ICountrySelectOption extends ISelectOption<TLocales> {
  icon: string;
}

export const LOCALES_SELECT_OPTIONS: ICountrySelectOption[] = [
  {
    value: "ru",
    label: "RU",
    icon: "russia-flag",
  },
  {
    value: "en",
    label: "EN",
    icon: "usa-flag",
  },
];

const renderOption = (option: ISelectOption) => {
  return (
    <Space align="center">
      <Icon name={option.icon} size={16} />
      <Paragraph size={12} weight={400}>
        {option.label}
      </Paragraph>
    </Space>
  );
};

export const LocaleSelect: React.FC<ILocaleSelect> = (props) => {
  const { onLocaleSelect, selectedLanguage } = props;
  return (
    <StyledSelect
      showArrow={false}
      onChange={(value) => value && onLocaleSelect(value as string)}
      value={
        LOCALES_SELECT_OPTIONS.find((item) => item.value === selectedLanguage.slice(0,2))
          ?.value || LOCALES_SELECT_OPTIONS[0].value
      }
    >
      {LOCALES_SELECT_OPTIONS?.map((option, index) => (
        <Select.Option key={index} value={option.value}>
          {renderOption(option)}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
