import React from "react";
import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import { StyledTableSimple, StyledTitle } from "./index.styles";
import { t } from "i18next";

interface SummaryViolationTableProps extends ITableSimple {
  title: string;
}

export const SummaryViolationTable: React.FC<SummaryViolationTableProps> = (
  props
) => {
  const {
    headers,
    title,
    ...rest
  } = props;

  return (
    <StyledTableSimple>
      <StyledTitle level={3} weight={400}>
        {t(title)}
      </StyledTitle>
      <TableSimple
        {...rest}
        alignHeader={'center'}
        headers={headers || []}
        withOverflow
        alignCell="start"
      />
    </StyledTableSimple>
  );
};
