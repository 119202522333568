import React, { useEffect, useState } from "react";
import { usePagination } from "@/hooks/usePagination";
import { BonusesTableAdapter } from "../BonusesTableAdapter";
import { getBonusesTableHeadersZ } from "./hooks";
import {
  HeaderArgType,
  SortOrder,
  StickyTable,
} from "@/packages/stickyTable/StickyTable";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRequestParams } from "@/hooks/useRequestParams";
import { DealerBonusesFilter } from "../DealerBonusesTab/DealerBonusesFilter";
import {
  StyledHR,
  StyledTab,
  StyledQuarterClosedButton,
} from "../DealerBonusesTab/index.styles";
import { DealerBonusesTitleHeader } from "../DealerBonusesTab/DealerBonusesTitleHeader";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import {
  useExportCoefficients,
  useGetFactorZ,
  useGetQuarter,
} from "@/helpers/api/bonuses/calculations/hooks";
import { TGetBonusesFactorZRequest } from "@/helpers/api/bonuses/calculations/types";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { useTranslation } from "react-i18next";

export const FactorZ: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const [dealerSearch, setDealerSearch] = useState("");

  const { t } = useTranslation();

  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const activeTab = new URLSearchParams(search).get("activeTab");
  const [requestParams, setRequestParams] = useRequestParams({
    quarter: {
      type: "filter",
      value: selectedQuarter,
    },
    year: {
      type: "filter",
      value: selectedYear,
    },
  });
  const [sortParams, setSortParams] = useState({
    "sort[sortAt]": undefined,
    "sort[sortBy]": undefined,
  });

  const { paginationParams, setPage } = usePagination();

  const { mutateAsync: exportCoefficients } = useExportCoefficients();

  const { data: quarterData } = useGetQuarter({
    quarter: selectedQuarter,
    year: selectedYear,
  });

  const isQuarterClosed = !!quarterData?.data.closedAt;

  const { data: factorZ, isLoading } = useGetFactorZ({
    ...(requestParamsMapper(requestParams) as TGetBonusesFactorZRequest),
    "page[page]": paginationParams.page,
    "page[limit]": paginationParams.limit,
    "filter[dealerSearch]": dealerSearch,
    "modules[coefficientA]": true,
    "modules[coefficientB]": true,
    "modules[coefficientC]": true,
    ...sortParams,
  });

  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setSortParams({
      "sort[sortAt]": order ? order.toUpperCase() : (undefined as any),
      "sort[sortBy]": order ? accessor.id : (undefined as any),
    });
  };

  useEffect(() => {
    setSearchParams({
      quarter: requestParams?.quarter?.value?.toString() || "",
      year: requestParams?.year?.value?.toString() || "",
      activeTab: activeTab || "dealer",
    });
  }, [requestParams, activeTab]);

  const headers = getBonusesTableHeadersZ();

  const handleFiltersApply = (filters) => {
    Object.entries(filters).forEach(([key, value]) => {
      if (value && (typeof value === "string" || typeof value === "number")) {
        setRequestParams((prev) => {
          return {
            ...prev,
            [key]: { type: "filter", value },
          };
        });
      }
    });
  };

  const handleCoefficientsExport = () => {
    exportCoefficients(
      {
        ...(requestParamsMapper(requestParams) as TGetBonusesFactorZRequest),
        "page[page]": paginationParams.page,
        "page[limit]": paginationParams.limit,
        "modules[coefficientA]": true,
        "modules[coefficientB]": true,
        "modules[coefficientC]": true,
        ...sortParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "requests.xlsx");
        },
      }
    );
  };

  return (
    <StyledTab>
      <DealerBonusesTitleHeader
        hasButton={false}
        handleExport={handleCoefficientsExport}
        disablePrev={
          requestParams?.quarter?.value
            ? +requestParams?.quarter?.value <= 1
            : true
        }
        disableNext={
          requestParams?.quarter?.value
            ? +requestParams?.quarter?.value >= 4
            : true
        }
        disableExport={!factorZ?.data.data}
        onNextClick={() =>
          setRequestParams((prev) =>
            prev?.quarter?.value
              ? {
                  ...prev,
                  quarter: {
                    type: "filter",
                    value: +prev?.quarter?.value + 1,
                  },
                }
              : prev
          )
        }
        onPrevClick={() =>
          setRequestParams((prev) =>
            prev?.quarter?.value
              ? {
                  ...prev,
                  quarter: {
                    type: "filter",
                    value: +prev?.quarter?.value - 1,
                  },
                }
              : prev
          )
        }
      />
      {isQuarterClosed ? (
        <StyledQuarterClosedButton>
          {t("bonuses.calculations.quarterClose") || ""}
        </StyledQuarterClosedButton>
      ) : null}
      <StyledHR />
      <DealerBonusesFilter
        isFactorZ={true}
        year={requestParams?.year?.value?.toString() || selectedYear}
        quarter={requestParams?.quarter?.value?.toString() || selectedQuarter}
        onFiltersChange={handleFiltersApply}
      />

      <BonusesTableAdapter
        isLoading={isLoading}
        paginationParams={{
          ...paginationParams,
          limit: factorZ?.data.meta?.pageCount || 0,
        }}
        setPage={setPage}
      >
        <StickyTable
          // @ts-ignore
          headers={headers || []}
          data={factorZ?.data.data || []}
          onSort={handleSort}
          onSearch={(_, value) => {
            setDealerSearch(value || "");
          }}
        />
      </BonusesTableAdapter>
    </StyledTab>
  );
};
