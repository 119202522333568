import React from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

import {
  IApplicationsFilterFormValues,
  useApplicationsFilterForm,
} from "./hooks";

import {
  StyledButtonsContainer,
  StyledFormLayout,
  StyledFirstFormRow,
  StyledLastFormRow,
  StyledDatepickerWrapper,
  StyledDash,
  StyledApplicationsFilterWrapper,
} from "./index.styles";

import { TFilterOptions } from "@/pages/Applications";
import { APPOINTMENT_OPTIONS } from "../ApplicationsForm";
import { useTranslation } from "react-i18next";

export interface IApplicationsFilter {
  defaultValues: IApplicationsFilterFormValues;
  filterOptions: TFilterOptions;
  onFiltersChange: any;
  isLoading?: boolean;
}

export const ApplicationsFilter: React.FC<IApplicationsFilter> = (props) => {
  const { defaultValues, filterOptions, onFiltersChange, isLoading } = props;
  // const { filterOptions, onFiltersChange, isLoading } = props;

  const { form, submit, reset } = useApplicationsFilterForm(
    onFiltersChange,
    defaultValues
  );
  const { t } = useTranslation();

  const resetFilters = async () => {
    await reset();
    submit && submit();
  };

  return (
    <StyledApplicationsFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <StyledFirstFormRow>
            <FormSelect
              name="modelIds"
              label={t("park.model") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={filterOptions.modelOptions}
              allowClear
              isSearchShow
            />
            <StyledDatepickerWrapper>
              <FormDatepickerField
                name="startEvent"
                label={t("park.test") || ""}
                // maxDate={form.watch("filter[startEvent]") || undefined}
              />
              <StyledDash />
              <FormDatepickerField
                name="endEvent"
                // minDate={form.watch("startEvent") || undefined}
              />
            </StyledDatepickerWrapper>
          </StyledFirstFormRow>

          <StyledLastFormRow>
            <FormSelect
              name="userCreatorId"
              label={t("park.author") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={filterOptions.authorOptions}
              allowClear
              isSearchShow
            />

            <FormSelect
              name="vehicleNumber"
              label={t("park.autoNum") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={filterOptions.vehicleOptions}
              allowClear
              isSearchShow
            />

            <FormSelect
              name="appointment"
              label={t("park.purpose") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={APPOINTMENT_OPTIONS}
              allowClear
            />

            <FormSelect
              name="mediaId"
              label={t("park.media") || ""}
              placeholder={t("dealerMaterials.letters.all") || ""}
              options={filterOptions.mediaOptions}
              allowClear
              isSearchShow
            />
          </StyledLastFormRow>
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("audits.list.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={resetFilters}
        >
          {t("dealerMaterials.letters.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledApplicationsFilterWrapper>
  );
};
