import { colors } from "@/helpers";
import { Icon } from "@/packages/icon/Icon";
import { Input as AntInput, InputProps } from "antd";
import styled, { css } from "styled-components";
import { getInputBorderStyle, getInputPlaceholderStyle, getInputTextStyle, } from "../styles";

export const StyledInputWrapper = styled.div`
  width: 100%;

  .ant-input-affix-wrapper-focused {
    border: 1px solid ${colors.brand};
    box-shadow: 0 0 0 2px rgba(5, 20, 31, 0.2);
  }
`;

export const InputIcon = styled(Icon)<{ disabled?: boolean }>`
  ${({ disabled }) =>
          disabled
                  ? `
        & svg {
          & path {
            fill: ${colors.gray300};
          }
        }
      `
                  : `
        & svg {
          & path {
            fill: ${colors.gray400};
          }
        }
      `}

  position: absolute;
  top: 50%;
  right: 11px;
  transform: translate(0, -50%);
  z-index: 2;
`;

export interface IInputComponent extends InputProps {
  error?: boolean;
  isEditable?: boolean;
  iconRender: (visible: boolean) => React.ReactNode;
}

export const InputComponent = ({
                                 type,
                                 iconRender,
                                 ...rest
                               }: IInputComponent) => {


  if (type === "password") {
    return <AntInput.Password {...rest} iconRender={iconRender}/>;
  }

  return <AntInput {...rest} />;
};

export const StyledInputItem = styled(InputComponent)`
  ${({ disabled, error }) =>
          getInputBorderStyle({ disabled: disabled || false, error: error || false })}

  ${getInputTextStyle()}

  width: 100%;
  min-height: 32px;
  background-color: ${colors.white};
  position: relative;
  transition: all 0.5s ease;

  &:focus {
    border: 1px solid ${colors.brand};
    box-shadow: 0 0 0 2px rgba(5, 20, 31, 0.2);
  }

  &::placeholder {
    ${getInputPlaceholderStyle()}
  }

  ${({ readOnly }) =>
          readOnly &&
          css`
            color: ${colors.gray5};
            cursor: default;
            border: 1px solid ${colors.gray6};
          `}

  ${({ isEditable }) =>
          !isEditable &&
          css`
            pointer-events: none;
            color: ${colors.gray500};
            background-color: ${colors.gray300};

            input {
              color: inherit;
              background-color: inherit;
            }
          `}
`;

export const StyledPasswordIcon = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 5;
`;
