import { colors } from "@/helpers";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Icon } from "@/packages/icon/Icon";

export const StyledMenuTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
`;

export const StyledTriggerButton = styled(IconButton)<{ isOpen: boolean }>`
    ${({ isOpen }) =>
            !isOpen &&
            css`
                position: absolute;
                right: -32px;
                background-color: ${colors.black} !important;
                border-color: ${colors.black} !important;

                svg {
                    transform: rotate(180deg);
                    fill: ${colors.white} !important;
                }
            `}
`;

export const ScrollItem = styled.div`
    display: grid;
    gap: 12px;
    height: calc(100vh - 88px);
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200};
        border-radius: 20px;
        border: 4px solid ${colors.black};
    }
`;

export const DashboardLayoutNavBarStyles = styled.div<{ isOpen: boolean }>`
    box-sizing: border-box;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    grid-area: n;
    height: 100vh;
    transition: all 0.3s ease-out;

    background-color: ${colors.black};

    ${({ isOpen }) =>
            isOpen
                    ? css`
                        padding: 22px 12px;
                        width: 250px;
                    `
                    : css`
                        width: 88px;
                        padding: 22px 14px;
                    `}
`;

export const StyledDashboardLayoutNavBarLogo = styled.svg`
    height: 13px;
    max-width: 60px;
`;

export const StyledDashboardLayoutNavItemsList = styled.div<{
  isOpen: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: ${({ isOpen }) => (isOpen ? "12px" : "16px")};
`;

export const StyledDashboardLayoutNavItemGroupLabel = styled.div<{
  isOpen: boolean;
}>`
    ${({ isOpen }) =>
            !isOpen &&
            css`
                background-color: ${colors.gray200};
                height: 1px;
                margin-top: 7.5px;
                margin-bottom: 7.5px;

                ${StyledDashboardLayoutNavItemGroupLabelText} {
                    display: none;
                }
            `}
`;

export const StyledDashboardLayoutNavItemGroupLabelText = styled(Paragraph)``;

export const StyledDashboardLayoutNavItem = styled(NavLink)<{
  isOpen: boolean;
  isChildrenActive: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    padding-left: 0;
    border-radius: 10px;
    height: 48px;
    font-size: 12px;
    color: ${colors.whiteSecondary};
    transition: background-color 0.3s ease;

    ${({ isOpen }) =>
            !isOpen &&
            css`
                width: 100%;
                height: 32px;
                padding: 0;
                justify-content: center;

                ${StyledDashboardLayoutNavItemLabel} {
                    display: none;
                }
            `}
    ${({ isChildrenActive }) =>
            isChildrenActive
                    ? css`
                        background-color: ${colors.whiteSecondary};
                        color: ${colors.black};

                        svg {
                            fill: ${colors.black};

                            & rect {
                                fill: ${colors.black};
                            }

                            & path {
                                fill: ${colors.black};
                                fill: ${colors.black};
                            }

                            & circle {
                                fill: ${colors.black};
                            }
                        }

                        &:hover {
                            color: ${colors.black};
                        }
                    `
                    : css`
                        &:hover {
                            color: ${colors.whiteSecondary};
                        }
                    `}
    &&.active {
        background-color: ${colors.whiteSecondary};
        color: ${colors.black};

        svg {
            fill: ${colors.black};

            & rect {
                fill: ${colors.black};
            }

            & path {
                stroke: ${colors.black};
                fill: ${colors.black};
            }

            & circle {
                fill: ${colors.black};
            }
        }
    }
`;

export const NavItemWrap = styled.div<{
  isOpen: boolean;
  isChildrenActive: boolean;
}>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 0;
    border-radius: 10px;
    height: 48px;
    font-size: 16px;
    color: ${colors.whiteSecondary};
    transition: background-color 0.3s ease;
    cursor: pointer;

    ${({ isOpen }) =>
            !isOpen &&
            css`
                width: 100%;
                height: 32px;
                padding: 0;
                justify-content: center;

                ${StyledDashboardLayoutNavItemLabel} {
                    display: none;
                }
            `}
    ${({ isChildrenActive }) =>
            isChildrenActive
                    ? css`
                        background-color: ${colors.whiteSecondary};
                        color: ${colors.black};

                        svg {
                            fill: ${colors.black};

                            & rect {
                                fill: ${colors.black};
                            }

                            & path {
                                stroke: ${colors.black};
                                fill: ${colors.black};
                            }

                            & circle {
                                fill: ${colors.black};
                            }
                        }

                        &:hover {
                            color: ${colors.black};
                        }
                    `
                    : css`
                        &:hover {
                            color: ${colors.whiteSecondary};
                        }
                    `}
    &&.active {
        background-color: ${colors.whiteSecondary};
        color: ${colors.black};
    }

`;

export const StyledOpenParentIcon = styled(Icon)`
    rotate: 90deg;
`

export const StyledLabelWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledDashboardLayoutSubNavItem = styled(NavLink)`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.whiteSecondary};
    text-decoration: none;

    &:hover {
        color: ${colors.whiteSecondary};
    }

    &&.active {
        background-color: ${colors.gray3};
        color: ${colors.black};
    }
`;


export const StyledActiveChildIndicator = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 9999px;
    background-color: ${colors.black};
`;

export const StyledDashboardLayoutNavItemLabel = styled(Paragraph)`
    font-size: 13px;
`;
export const StyledClildWrapper = styled.div`
    margin-top: 8px;
`;

export const DashboardLayoutNavBarInnerStyles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const NavBarBottomWrap = styled.div``;

export const NavBarItemText = styled.div`
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
`;

export const NavBarInfoBlock = styled.div<{ isOpen: boolean }>`
    padding-top: 16px;
    display: grid;
    gap: 8px;
    color: ${colors.whiteSecondary};
    margin-top: auto;
    overflow: hidden;
    height: 108px;
    ${({ isOpen }) =>
            !isOpen &&
            css`
                ${NavBarInfoBlockPhone} {
                    display: none;
                }

                ${NavBarInfoBlockEmail} {
                    display: none;
                }

                ${NavBarInfoBlockCloseBtnContainer} {
                    display: none;
                }

                ${NavBarInfoBlockIconContainer} {
                    justify-content: center;
                }
            `}
`;

export const NavBarInfoBlockIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const NavBarInfoBlockPhone = styled(Paragraph)`
    margin-bottom: 4px;

    a {
        color: ${colors.white};
    }
`;

export const NavBarInfoBlockEmail = styled(Paragraph)`
    a {
        color: ${colors.white};
    }
`;

export const NavBarInfoBlockCloseBtnContainer = styled.div`
    grid-area: close;
    display: flex;
    align-items: center;
`;

export const StyledIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
`;

export const StyledContactForm = styled.div``;
