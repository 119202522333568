import React from "react";

import {
  SelectField,
  ISelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetEngineList } from "@/helpers/api/engines/hooks";

export interface IEngineTypeSelectFieldAdapter
  extends Omit<ISelectField, "options"> {}

export const EngineTypeSelectFieldAdapter: React.FC<
  IEngineTypeSelectFieldAdapter
> = (props) => {
  const { data: getEngineListResponse, isLoading } = useGetEngineList({
    page: 1,
    limit: 0,
  });
  const options =
    getEngineListResponse?.data.data.map(
      (fuelRecord) =>
        ({ value: fuelRecord.id, label: fuelRecord.name } as ISelectOption)
    ) || [];

  return <SelectField options={options} loading={isLoading} {...props} />;
};
