import React, { SetStateAction, useEffect } from "react";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Badge, Divider, Space } from "antd";
import {
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderRight,
} from "./index.styles";
import { colors } from "@/helpers";
import { CurrentUser } from "./CurrentUser";
import { LocaleSelect } from "./LocaleSelect";
import { useTranslation } from "react-i18next";
import { useGetNotifications } from "@/helpers/api/notifications/hooks";

interface IDesktopHeader {
  searchValue?: string;
  setSearchValue: React.Dispatch<SetStateAction<string | undefined>>;
  onGoToNotificationsButtonClick: () => void;
}
export const DesktopHeader: React.FC<IDesktopHeader> = (props) => {
  const { onGoToNotificationsButtonClick } = props;
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const { data: notifications} =
    useGetNotifications({
      "filter[isRead]": "false",
    });

  return (
    <>
      <StyledHeaderContainer>
        <StyledHeader>
          <StyledHeaderRight>
            <Space
              size={15}
              split={
                <Divider
                  type="vertical"
                  style={{ backgroundColor: colors.neutral400, margin: 0 }}
                />
              }
            >
              <CurrentUser />
              {/*// @ts-ignore*/}
              <Badge count={notifications?.data?.meta?.itemCount}>
                <IconButton
                  icon="bell"
                  color="gray"
                  onClick={onGoToNotificationsButtonClick}
                />
              </Badge>
              <LocaleSelect
                selectedLanguage={i18n.language}
                onLocaleSelect={changeLanguage}
              />
            </Space>
          </StyledHeaderRight>
        </StyledHeader>
      </StyledHeaderContainer>
    </>
  );
};
