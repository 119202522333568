import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

export interface IFormValues {
  responsibleId: number;
  title?: string;
}

// export const DEFAULT_FORM_VALUES: IFormValues = {};

const formSchema = yup.object({
  responsibleId: yup.string().required("Выберите представителя дилера"),
  title: yup.string().min(1, 'Название не должно быть пустое'),
});

export type TOnSubmitFormCb = (values: IFormValues) => void;

export const useFormValues = (onSubmit: TOnSubmitFormCb, initValues) => {
  const form = useForm<IFormValues>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  useEffect(() => {
    const value = {
      title: initValues.title,
    }
    form.reset(value)
  }, [initValues])

  const onClearHandler = () => {
    form.reset();
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
