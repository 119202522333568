import styled from "styled-components";

export const StyledPhotosTab = styled.div`
  margin-top: 24px;
`;

export const StyledImagesContainer = styled.div`
  display: grid;
  gap: 32px;
`;
