import React from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import {
  TOnSubmitStatisticsFilterFormCb,
  useStatisticsFilterForm,
} from "./hooks";
import {
  StyledButtonsContainer,
  StyledFormLayout,
  StyledDatepickerWrapper,
  StyledDash,
  StyledStatisticsFilterWrapper,
} from "./index.styles";
import { TFilterOptions } from "@/pages/Statistics";
import { useTranslation } from "react-i18next";

export interface IStatisticsFilter {
  onFiltersChange: TOnSubmitStatisticsFilterFormCb;
  filterOptions: TFilterOptions;
}

export const StatisticsFilter: React.FC<IStatisticsFilter> = (props) => {
  const { t } = useTranslation();
  const { onFiltersChange, filterOptions } = props;

  const { form, onSubmitHandler, onClearHandler } =
    useStatisticsFilterForm(onFiltersChange);

  return (
    <StyledStatisticsFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <FormSelect
            name="modelId"
            label={t("park.model") || ""}
            placeholder={t("dealerMaterials.letters.all") || ""}
            options={filterOptions.modelOptions}
            allowClear
            isSearchShow
          />
          <FormSelect
            name="gosNumber"
            label={t("park.autoNum") || ""}
            placeholder={t("dealerMaterials.letters.all") || ""}
            options={filterOptions.vehicleOptions}
            allowClear
            isSearchShow
          />
          <FormSelect
            name="mediaId"
            label="Media / Kia"
            placeholder={t("dealerMaterials.letters.all") || ""}
            options={[
              ...filterOptions.mediaOptions,
              {
                value: "Kia",
                label: "Kia",
                name: "Kia",
              },
            ]}
            allowClear
            isSearchShow
          />
          <StyledDatepickerWrapper>
            <FormDatepickerField
              name="startEvent"
              label={t("park.startEvent") || ""}
              maxDate={form.watch("endEvent") || undefined}
              isSameDateEnabled={true}
            />
            <StyledDash />
            <FormDatepickerField
              name="endEvent"
              label={t("park.endEvent") || ""}
              minDate={form.watch("startEvent") || undefined}
              isSameDateEnabled={true}
            />
          </StyledDatepickerWrapper>
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button
          color="brand"
          theme="primary"
          size="middle"
          onClick={onSubmitHandler}
        >
          {t("audits.list.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("dealerMaterials.letters.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledStatisticsFilterWrapper>
  );
};
