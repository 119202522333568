import { FC, useContext } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormProvider } from "react-hook-form";
import {
  CreateGsmSuccessFormValues,
  useCreateGsmForm,
} from "../hooks";
import { GsmFormModal } from "@/components/MaintenanceGrids/GsmTab/GsmFormModal";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

type TGsmCreateModal = Pick<IModal, "onCancel">;

const GsmCreateModal: FC<TGsmCreateModal> = (props) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const { createNotificationError } = useNotification();
  const {
    actions: { createGsm },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const onFormSubmit = (data: CreateGsmSuccessFormValues) => {
    // @ts-ignore
    createGsm({data}, {
      onSuccess: () => {
        form.reset();
        onCancel && onCancel();
      },
      onError: (error) => {
        //@ts-ignore
        createNotificationError(t(error.response?.data.message ?? error.message) ?? '');
      }
    });
  };

  const { form, onSubmitHandler } = useCreateGsmForm(onFormSubmit);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
  };

  return (
    <Modal
      {...props}
      title={t("maintenanceGrids.gsmModel.createNewGsm") || ""}
      confirmButtonText={t("maintenanceGrids.gsmModel.createNewGsm") || ""}
      cancelButtonText={t("maintenanceGrids.gsmModel.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <GsmFormModal
          editMode={false}
          type={'gsm'}
        />
      </FormProvider>
    </Modal>
  );
};

export { GsmCreateModal };
