import { SelectField } from "@/packages/formElements/fields/SelectField";
import styled from "styled-components";

import { Title } from "@/packages/title/Title";
import { colors } from "@/helpers";

export const StyledTable = styled.div`
  overflow: auto;
`;

// export const StyledTable = styled(TableSimple)`
//   min-width: auto !important;
// `;

export const StyledSelectField = styled(SelectField)`
  width: 145px;
`;

export const StyledSpan = styled.span`
  color: ${colors.black};
`;

export const StyledStatus = styled.span<{ backgroundColor: string }>`
  display: inline-block;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  background-color: ${(props) => props.backgroundColor};
`;

export const StyledApplicationsMessage = styled(Title)`
  padding: 0 0 14px 22px;
`;
