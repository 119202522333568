import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const StyledBackLink = styled("div")`
  cursor: pointer;
`;

export interface IBackLink {
  to: string | number;
}

export const BackLink: React.FC<IBackLink> = (props) => {
  const { to } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {
        typeof to === 'string' ?
          <Link to={to}>
            <Space align="center">
              <Icon name="chevron-left" size={16}/>
              <Paragraph size={16} color="black">
                {t("common.back") || ""}
              </Paragraph>
            </Space>
          </Link> :
          <StyledBackLink>
            <Space align="center" onClick={() => navigate(to)}>
              <Icon name="chevron-left" size={16}/>
              <Paragraph size={16} color="black">
                {t("common.back") || ""}
              </Paragraph>
            </Space>
          </StyledBackLink>
      }
    </>
  );
};
