import { transformDottedDateToIso } from "@/helpers/transformDate";
import { isDate } from "@/helpers/utils";
import { Icon } from "@/packages/icon/Icon";
import { ru } from "date-fns/locale";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import {
  StyledCustomInput,
  StyledDatePickerHeader,
  StyledDecreaseButton,
  StyledIcon,
  StyledIncreaseButton,
  StyledSelect,
} from "./index.style";
import { useTranslation } from "react-i18next";

registerLocale("ru", ru);

export interface IDatepicker {
  error?: boolean;
  value?: Date | null;
  isBlock?: boolean;
  disabled?: boolean;
  onChange?: (date: Date | null) => void;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  readOnly?: boolean;
  defaultValue?: Date | null;
  preventOpenOnFocus?: boolean;
}

interface IExampleCustomInput {
  open: boolean;
  disabled: boolean;
  error: boolean;
  value2?: string;
  isReadOnly: boolean;
  onChange2?: (value?: string) => void;
  onClick?: () => void;
  onBlur2: (value?: string) => void;
  // onKeyDown: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<IExampleCustomInput> = (props) => {
  const {
    error,
    disabled,
    open,
    onClick,
    onChange2,
    value2,
    isReadOnly,
    onBlur2,
    ...rest
  } = props;
  const handleKeyPress = (e) => {
    if (e.keyCode === "Enter") {
      onBlur2(value2);
      e.stopPropagation();
    }
  };

  return (
    <>
      <StyledIcon isOpen={error} isDisabled={disabled} readOnly={isReadOnly}>
        <div onClick={onClick}>
          <Icon name="calendar-16" size={16} />
        </div>
      </StyledIcon>
      <InputMask
        alwaysShowMask={true}
        mask="99.99.9999"
        maskChar="_"
        {...rest}
        value={value2}
        onChange={(e) => {
          onChange2 && onChange2(e?.target?.value);
        }}
        onBlur={() => onBlur2 && onBlur2(value2)}
        readOnly={isReadOnly}
      >
        {(inputProps) => (
          <StyledCustomInput
            {...inputProps}
            isError={error}
            isOpen={open}
            isDisabled={disabled}
            isReadOnly={isReadOnly}
            onKeyDown={handleKeyPress}
          />
        )}
      </InputMask>
    </>
  );
};

const Datepicker: React.FC<IDatepicker> = (props): JSX.Element => {
  const {
    value,
    defaultValue,
    disabled: isDisabled = false,
    error,
    readOnly = false,
    minDate,
    maxDate,
    onChange,
    preventOpenOnFocus = false,
  } = props;

  if (minDate) minDate?.setHours(21, 0, 0, 0);
  if (maxDate) maxDate?.setHours(21, 0, 0, 0);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue(value ? value.toLocaleDateString("ru-RU") : "");
  }, [value]);

  const onCalendarOpenHandler = () => {
    setIsOpen(true);
  };

  const onCalendarCloseHandler = () => {
    setIsOpen(false);
  };

  const handleDatePick = (newDate: Date) => {
    !!newDate && newDate.setHours(21, 0, 0);
    onChange && onChange(newDate ?? null);
  };

  const handleDateChange = (value?: string) => {
    if (!value || readOnly) return;

    setInputValue(value);
  };

  const handleDateBlur = () => {
    if (!inputValue) return;
    const adaptedDate = transformDottedDateToIso(inputValue);
    const newDate = new Date(Date.parse(adaptedDate));

    onChange && onChange(isDate(newDate) ? newDate : null);
  };

  const years = range(1999, getYear(new Date()) + 10);
  const months = [
    t("date.1") || "",
    t("date.2") || "",
    t("date.3") || "",
    t("date.4") || "",
    t("date.5") || "",
    t("date.6") || "",
    t("date.7") || "",
    t("date.8") || "",
    t("date.9") || "",
    t("date.10") || "",
    t("date.11") || "",
    t("date.12") || "",
  ];

  const handleKeyPress = (e) => {
    if (e.keyCode === "Enter") {
      handleDateBlur();
      e.stopPropagation();
    }
  };

  return (
    <DatePicker
      value={
        defaultValue ? defaultValue.toLocaleDateString("ru-RU") : inputValue
      }
      selected={defaultValue ? defaultValue : value}
      onChange={handleDatePick}
      locale={t("date.lang") || ""}
      isClearable={!isDisabled && !readOnly}
      dateFormat="dd.MM.yyyy"
      disabled={isDisabled}
      preventOpenOnFocus={preventOpenOnFocus}
      open={!readOnly && isOpen}
      renderCustomHeader={({
                             date,
                             changeYear,
                             changeMonth,
                             decreaseMonth,
                             increaseMonth,
                             prevMonthButtonDisabled,
                             nextMonthButtonDisabled,
                           }) => (
        <StyledDatePickerHeader>
          <StyledDecreaseButton
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </StyledDecreaseButton>

          <StyledSelect
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value as any)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </StyledSelect>

          <StyledSelect
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </StyledSelect>

          <StyledIncreaseButton
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </StyledIncreaseButton>
        </StyledDatePickerHeader>
      )}
      customInput={
        <CustomInput
          disabled={isDisabled}
          isReadOnly={readOnly}
          error={error ?? false}
          open={isOpen}
          value2={
            defaultValue ? defaultValue.toLocaleDateString("ru-RU") : inputValue
          }
          onChange2={handleDateChange}
          onBlur2={handleDateBlur}
        />
      }
      onCalendarClose={onCalendarCloseHandler}
      onCalendarOpen={onCalendarOpenHandler}
      showYearDropdown={true}
      showMonthDropdown={false}
      minDate={minDate}
      maxDate={maxDate}
      onKeyDown={handleKeyPress}
    />
  );
};

export { Datepicker };
