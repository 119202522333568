import { colors } from "@/helpers";
import { Icon } from "@/packages/icon/Icon";
import { Dropdown, DropdownProps, Menu } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";
import { COUNTRIES } from "@/helpers/consts";
import { Field } from "@/packages/formElements/fields/Field";

export interface IPhoneInput {
  isBlock?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  error?: string;
}

export interface ICountryOption {
  icon: string;
  code: string;
  mask: string;
}

const StyledInputContainer = styled.div<{ isBlock: boolean }>`
    width: ${({ isBlock }) => (isBlock ? "100%" : "fit-content")};
`;

const WrappedInput = styled.div<{ isBlock: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    width: ${({ isBlock }) => (isBlock ? "100%" : "fit-content")};
`;

const StyledInput = styled(MaskedInput)`
    height: 32px;
    padding: 10px 12px 6px 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    border: none;
    width: fit-content;

    &:focus {
        border: none;
        box-shadow: none;
    }
`;

const StyledDropdown = styled(Dropdown)<DropdownProps>`
    cursor: pointer;
    padding: 10px 8px 6px 12px;
`;

const StyledCode = styled.div`
    margin-right: 4px;
    margin-left: 4px;
`;

const StyledLabel = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.black};
    margin-bottom: 4px;
`;

const StyledCountry = styled.div<{ isBlock: boolean }>`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    width: ${({ isBlock }) => (isBlock ? "100%" : "fit-content")};
`;

const StyledMenu = styled(Menu)`
    box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
    border-radius: 8px;
    margin-top: 4px;

    .ant-dropdown-menu-item {
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
    }
`;

const PhoneInput: React.FC<IPhoneInput> = (props) => {
  const { isBlock, value, onChange, label, error } = props;
  const [selectedCountry, setSelectedCountry] = useState(COUNTRIES[0]);

  const preparedOptions = useMemo(
    () =>
      COUNTRIES.map((item, index) => ({
        key: index,
        label: (
          <StyledCountry
            isBlock={true}
            onClick={() => setSelectedCountry(() => item)}
          >
            <Icon name={item.icon} color="black" size={16}/>
            <StyledCode>{item.code}</StyledCode>
          </StyledCountry>
        ),
      })),
    []
  );

  const handleChange: MaskedInputProps["onChange"] = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <StyledInputContainer isBlock={!!isBlock}>
      {label ? <StyledLabel>{label}</StyledLabel> : <></>}
      <WrappedInput isBlock={!!isBlock}>
        <StyledDropdown overlay={<StyledMenu items={preparedOptions}/>}>
          <StyledCountry isBlock={false}>
            <Icon name={selectedCountry.icon} color="black" size={16}/>
            <Icon name="chevron-down-16" color="black" size={16}/>
          </StyledCountry>
        </StyledDropdown>
        <StyledInput
          mask={
            (selectedCountry.code.length === 2 ? "+0 " : "+000 ") +
            selectedCountry.mask
          }
          value={value ?? selectedCountry.code}
          onChange={handleChange}
        />
      </WrappedInput>
      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}
    </StyledInputContainer>
  );
};

export { PhoneInput };
