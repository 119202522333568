import { TFlexExist } from "@/helpers/api/flex/vehicles/types";
import { IExistCarFromValues } from "../ExistsCarForm/type";

export const transformValueToDTO = (value: IExistCarFromValues) => {
  const {
    owner,
    pts,
    c2c,
    materialCode,
    equipmentId,
    number,
    vin,
    color,
    dealerId,
    fileIds,
    photoIds,
  } = value;
  // @ts-ignore

  const objKeys = Object.keys(value);

  const insO = {
    type: "ОСАГО",
    insurant: "",
    series: "",
    insuranceName: "",
    from: "",
    to: "",
    openInsurance: false,
  };
  const insK = {
    type: "КАСКО",
    insurant: "",
    series: "",
    insuranceName: "",
    from: "",
    to: "",
    openInsurance: false,
  };
  // @ts-ignore

  objKeys.map((item) => {
    if (item[item.length - 1] === "O") {
      if (item === "to" || item === "from") {
        insO[item.slice(0, item.length - 1)] = value[item];
      }
      insO[item.slice(0, item.length - 1)] = value[item];
    } else if (item[item.length - 1] === "K") {
      if (item === "to" || item === "from") {
        insK[item.slice(0, item.length - 1)] = value[item];
      }
      insK[item.slice(0, item.length - 1)] = value[item];
    }
  });

  const insurance = [insO, insK]
    .filter((item) => !!item.from && !!item.insuranceName && !!item.insurant)
    // @ts-ignore

    .map((item) => ({
      ...item,
      openInsurance: !!item.openInsurance,
    }));

  const files = fileIds
    ?.filter((item) => item.value !== null && item.value.dir === "files")
    // @ts-ignore

    .map((item) => item.value.id);

  const photos = photoIds
    ?.filter((item) => item.value !== null && item.value.dir === "photos")
    // @ts-ignore

    .map((item) => item.value.id);

  return {
    owner,
    pts,
    c2c: !!c2c,
    materialCode,
    equipmentId,
    number: number?.replace(/_/g, ""),
    vin,
    color,
    dealerId,
    // @ts-ignore
    fileIds: [...files, ...photos],
    insurance,
  };
};

export const transformDTOToValues = (values: TFlexExist) => {
  const objKeys = Object.keys(values);
  if (objKeys.length > 4) {
    const equipmentId = {
      value: values.equipment.id,
      label: values.equipment.name,
    };

    const modification = {
      value: values.equipment.modification.id,
      label: values.equipment.modification.fullName,
    };

    const generation = {
      value: values.equipment.modification.generation.id,
      label: values.equipment.modification.generation.name,
    };

    const model = {
      value: values.equipment.modification.generation.model.id,
      label: values.equipment.modification.generation.model.name,
    };

    const dealerId = {
      value: values.dealer.id,
      label: values.dealer.merchantName,
    };
    const insuranceOsago = values.insurance?.filter(
      (item) => item.type === "ОСАГО"
    );
    const insuranceKasko = values.insurance?.filter(
      (item) => item.type === "КАСКО"
    );

    const resInsuranceOsago = Object.keys(insuranceOsago[0])?.reduce(
      (acc, key) => {
        if (key === "from" || key === "to") {
          acc[key + "O"] = new Date(insuranceOsago[0][key]);
        } else {
          acc[key + "O"] = insuranceOsago[0][key];
        }

        return acc;
      },
      {}
    );

    const resInsuranceKasko = Object.keys(insuranceKasko[0])?.reduce(
      (acc, key) => {
        if (key === "from" || key === "to") {
          acc[key + "K"] = new Date(insuranceKasko[0][key]);
        } else {
          acc[key + "K"] = insuranceKasko[0][key];
        }

        return acc;
      },
      {}
    );

    const files = values.files
      // @ts-ignore

      .filter((item) => item.dir === "files")
      .map((item) => ({ value: item }));
    // @ts-ignore
    const photos = values.files
      // @ts-ignore

      .filter((item) => item.dir === "photos")
      .map((item) => ({ value: item }));

    return {
      ...values,
      ...resInsuranceOsago,
      ...resInsuranceKasko,
      color: values.color.color,
      model,
      generation,
      equipmentId,
      modification,
      fileIds: files,
      photoIds: photos,
      dealerId,
      owner: values.owner,
    };
  }
};
