import { Space } from "antd";
import { Button } from "@/packages/button/Button";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import React, { useEffect } from "react";
import { useCreateUserForm } from "@/pages/Admin/UserCreate/hooks";
import { FormProvider } from "react-hook-form";
import { UserDataFields } from "@/pages/Admin/UserDataFields";
import { useCreateUser } from "@/helpers/api/admin-pannel/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTE } from "@/constants/routes";
import { UserCreateWrapper } from "@/pages/Admin/UserCreate/index.styles";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";

export const UserCreate = () => {
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const navigate = useNavigate();


  const { mutate: createUser } = useCreateUser(
    {
      onSuccess: () => {
        createNotificationSuccess('Создание пользователя прошло успешно');
        navigate(ADMIN_ROUTE);
      },
      onError: () => {
        createNotificationError('При создании пользователя произошла ошибка')
      }
    }
  );
  const { form, submit, isSubmitLoading} = useCreateUserForm({ onSubmit: createUser });

  useEffect(()=>{
    form.setValue('roleId', null);
  },[form.watch('type')]);
  return (
    <DashboardLayout title='Создание пользователя' toBack={-1}>
      <PermissionsAdapter
        resourceName="admin.user"
        resourceAction="create"
        fallback={() => <p>Access denied</p>}
      >
        <UserCreateWrapper>
          <FormProvider {...form}>
            <UserDataFields form={form}/>
          </FormProvider>
          <Space>
            <Button
              theme="primary"
              color="brand"
              size="middle"
              onClick={submit}
              isLoading={isSubmitLoading}
            >
              Создать
            </Button>
          </Space>
        </UserCreateWrapper>
      </PermissionsAdapter>
    </DashboardLayout>
  )
}