import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledModalContentWrapper } from "./index.styles";
import { useTemplateForm } from "./hooks";
import { useChangeAuditName } from "@/helpers/api/audits/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

type IChnageAuditNameProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadAuditTemplateBase {
  id: number;
  oldName: string;
  onSubmit: () => void;
}

export const ChangeAuditNameModal: React.FC<
  IChnageAuditNameProps & IUploadAuditTemplateBase
> = (props) => {
  const { onSubmit, onCancel, oldName, id } = props;
  const { createNotificationError } = useNotification();
  const { mutate: changeName, isLoading } = useChangeAuditName();

  const onFormChange = (value) => {
    changeName({
      id,
      patch: value,
      onSuccess: onSubmit,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };
  const { t } = useTranslation();
  const { form, onSubmitHandler, setDefaultValue } =
    useTemplateForm(onFormChange);

  useEffect(() => {
    setDefaultValue({ title: oldName });
  }, []);

  return (
    <Modal
      {...props}
      title={t("audits.templates.editSection") || ""}
      size="small"
      cancelButtonText={t("audits.templates.cancel") || ""}
      confirmButtonText={t("audits.templates.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancel}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormInputField
            name="title"
            label={t("audits.templates.nameSection") || ""}
            placeholder=""
            description={t("audits.templates.nameSectionDescr") || ""}
          />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
