import { InputField } from "@/packages/formElements/fields/InputField";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledPagination = styled(Pagination)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeaderParagraph = styled(Paragraph)`
  font-weight: 600;
  text-align: center;
`;

export const StyledCommitmentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  text-align: center;
`;

export const StyledSetCommitmentLink = styled(Paragraph)`
  cursor: pointer;
  border-bottom: 1px solid black;
  font-weight: 600;
  text-align: center;
`;

export const StyledCommitmentInput = styled(InputField)`
  margin-top: -18px;
`;

export const StyledLoader = styled(Loader)`
  margin-top: 28px;
`;
