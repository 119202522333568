import styled from "styled-components";

import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

export const StyledApplicationsFormWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 32px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const StyledMainForm = styled.div`
  display: grid;
  row-gap: 24px;
  column-gap: 32px;
  grid-template-columns: 1fr 1fr;
`;

export const StyledFormSelect = styled(FormSelect)`
  max-width: 370px;
`;

export const StyledDatepickerWrapper = styled.div`
  display: flex;
  gap: 10px;
  min-width: 300px;
`;

export const StyledNewStatusForm = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
`;

export const StyledLinkArea = styled(FormTextAreaField)`
  resize: none;
  height: 90px !important;
`;

export const StyledCommentArea = styled(FormTextAreaField)`
  width: 100%;
  height: 92px !important;
  resize: none;
`;
