import { IModal, Modal } from "@/packages/modal/Modal";
import { Space } from "antd";
import { v4 as uuidv4 } from "uuid";

import { FC, useCallback, useEffect, useState } from "react";

import { IconButton } from "@/packages/icon-button/IconButton";

import { StyledFormEditBatchModal, StyledModalFields } from "./index.styles";
import Tree from "./Tree";
import { useWarrantyAddSection } from "@/helpers/api/warranty-audit/templates/hooks";
import { TGetWarrantyTemplateByIdRequest } from "@/helpers/api/warranty-audit/templates/types";
import { useTranslation } from "react-i18next";

export interface IWarrantyEditBatchModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: any;
  data: any[];
  type: TGetWarrantyTemplateByIdRequest["sectionsType"];
  templateId: number;
}

const WarrantyEditBatchModal: FC<IWarrantyEditBatchModal> = (props) => {
  const { data, templateId, onCancel, type, isOpen, onSubmit, ...rest } = props;
  const { t } = useTranslation();
  const [sections, setSections] = useState(data);
  const { mutate: addSection, isLoading: isCreating } = useWarrantyAddSection();
  const [payload, setPayload] = useState<any>([]);

  const onSubmitHandler = () => {
    addSection(
      {
        templateId,
        type,
        data: payload,
      },
      { onSuccess: onSubmit }
    );
  };

  const onActionHandler = (event: "edit" | "add" | "delete", section?: any) => {
    const { id, title } = section;
    switch (event) {
      case "edit":
        const copyPayload = [...payload];
        const objIndex = copyPayload.findIndex((el) => el.payload[0].id === id);

        if (objIndex !== -1) {
          copyPayload[objIndex].payload[0].title = title;
          return setPayload(copyPayload);
        }
        setPayload((prev) => [...prev, { event: "edit", payload: [section] }]);

        break;
      case "add":
        setPayload((prev) => [...prev, { event: "add", payload: [section] }]);
        break;
      case "delete":
        setPayload((prev) => [
          ...prev,
          { event: "delete", payload: [section] },
        ]);
        break;
    }
  };

  const addSectionHandler = useCallback(() => {
    const newSection = {
      id: uuidv4(),
      title: "",
      order: 0,
      parentId: null,
      level: sections.length + 1,
    };

    setSections([...sections, newSection]);
    onActionHandler("add", newSection);
  }, [setSections, sections]);

  useEffect(() => {
    setSections(data);
    return () => {
      setPayload([]);
    };
  }, [data]);

  return (
    <Modal
      {...props}
      title={t("dealerMaterials.letters.editParts") || ""}
      confirmButtonText={t("dealerMaterials.letters.save") || ""}
      cancelButtonText={t("dealerMaterials.letters.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={() => onCancel && onCancel()}
      isLoadingConfirmButton={isCreating}
    >
      <StyledModalFields>
        <StyledFormEditBatchModal>
          <Space align="center">
            <IconButton
              variant="tertiary"
              icon="plus"
              color="brand"
              onClick={addSectionHandler}
            >
              {t("dealerMaterials.letters.addSection") || ""}
            </IconButton>
          </Space>

          <Tree
            data={sections}
            sections={sections}
            setSections={setSections}
            onActionHandler={onActionHandler}
          />
        </StyledFormEditBatchModal>
      </StyledModalFields>
    </Modal>
  );
};

export { WarrantyEditBatchModal };
