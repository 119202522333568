export const MODELS_ROUTE = "/models";
export const ADMIN_ROUTE = "/admin";
export const CREATE_USER_ROUTE = "/admin/user/create";
export const USER_CARD_ROUTE = "/admin/user/:id";
export const LOGIN_ROUTE = "/login";
export const PASSFORGOT_ROUTE = "/passwordForgot";
export const REGISTRATION_ROUTE = "/registration";
export const DIRECTOR_REGISTRATION_ROUTE = "/password-set";
export const CONFIRMREG_ROUTE = "/confirmRegister";
export const PASSRES_ROUTE = "/passwordReset";
export const MAINTENANCE_GRIDS_ROUTE = "/maintenance-grids";
export const MAINTENANCE_GRIDS_DETAILS_ROUTE = "/maintenance-grids/details";
export const MAINTENANCE_GRIDS_DETAILS_EDIT_ROUTE =
  "/maintenance-grids/details/edit/:id";
export const MAINTENANCE_GRIDS_DETAILS_VIEW_ROUTE =
  "/maintenance-grids/details/view/:id";
export const VIN_DECODER_ROUTE = "/vin-decoder";
export const REGISTRY_ROUTE = "/registry";
export const REGISTRY_CREATE_ROUTE = "/registry/create";
export const REGISTRY_ID_ROUTE = "/registry/:id";
export const CARD_DC_ID_ROUTE = "/card-dc/:id";
export const BONUSES_REPORTS_IMPORT_ROUTE = "/bonuses/reports-import";
export const BONUSES_REPORTS_VIEW_ROUTE = "/bonuses/reports-view";
export const BONUSES_CALCULATION_ROUTE = "/bonuses/calculation";
export const BONUSES_PREMIUM_ROUTE = "/bonuses/premium";
export const BONUSES_DOCUMENT_VIEW_ROUTE = "/bonuses/premium/:id";
export const HOLDINGS_ROUTE = "/holdings";
export const PROFILE_ROUTE = "/profile";
export const PROFILE_EDIT_ROUTE = "/profile/edit";
export const PROFILE_PASSCHANGE_ROUTE = "/profile/change-password";
export const PROFILE_EMPLMANAGMENT_ROUTE =
  "/profile/employee-management/:dealerId";
export const PROFILE_CHANGESETTINGS_ROUTE = "/profile/change-settings";
export const CALENDAR_ROUTE = "/calendar";
export const MEDIABASE_ROUTE = "/mediabase";
export const APPLICATIONS_ROUTE = "/applications";
export const APPLICATIONS_CREATE_ROUTE = "/applications/create";
export const APPLICATIONS_EDIT_ROUTE = "/applications/:id";
export const AUTOPARK_ROUTE = "/autopark";
export const AUTOPARK_CREATE_ROUTE = "/autopark/create";
export const AUTOPARK_EDIT_ROUTE = "/autopark/:id";
export const PARTS_ROUTE = "/parts";
export const STATISTICS_ROUTE = "/statistics";
export const NOTIFICATIONS_ROUTE = "/notifications";
export const NOTIFICATIONS_RESULT_ROUTE = "/notifications/result/:id";
export const NEWS_ROUTE = "/news";
export const NEWS_SINGLE_ROUTE = "/news/:id";
export const NEWS_EDIT_ROUTE = "/news/edit/:id";
export const NEWS_CREATE_ROUTE = "/news/create";
export const INFORMATION_LETTERS_ROUTE = "/information-letters";
export const INFORMATION_LETTERS_CREATE_ROUTE =
  "/information-letters/:id/create";
export const INFORMATION_LETTERS_INFO_ROUTE = "/information-letters/:id";
export const INFORMATION_LETTERS_STATISTIC_ROUTE =
  "/information-letters/statistic/:id";
export const MATERIALS_ROUTE = "/materials";
export const TRANSPORT_COMPLAINTS_ROUTE = "/transport-complaints";
export const TRANSPORT_COMPLAINTS_DETAILS_ROUTE = "/transport-complaints/:id";
export const TRANSPORT_COMPLAINTS_CREATE_ROUTE = "/transport-complaints/create";
export const MONITORING_QUALITY = "/monitoring-quality";
export const MONITORING_QUALITY_CREATE_PROGRAM =
  "/monitoring-quality/create-program";
export const MONITORING_QUALITY_CREATE_APPLICATION =
  "/monitoring-quality/:id/create-application";
export const MONITORING_QUALITY_EDIT_APPLICATION =
  "/monitoring-quality/:id/edit-application/:monitoringId";
export const MONITORING_QUALITY_EDIT = "/monitoring-quality/:id/edit";
export const MONITORING_QUALITY_INFO = "/monitoring-quality/:id/info";
export const MONITORING_QUALITY_DEALERS = "/monitoring-quality/:id/dealers";

export const MONITORING_QUALITY_CARS_DEALERS_INFO =
  "/monitoring-quality/:id/dealers-cars-info/:dealerId";

export const MONITORING_QUALITY_CARS_DEALERS =
  "/monitoring-quality/:id/dealers-cars";

export const MONITORING_QUALITY_CARS = "/monitoring-quality/:id/cars";
export const MONITORING_QUALITY_FOR_DEALERS =
  "/monitoring-quality/:id/for-dealers";
export const TRANSPORT_CLAIMS_ROUTE = "/transport-claims";
export const TRANSPORT_CLAIMS_DETAILS_ROUTE = "/transport-claims/:id";
export const TRANSPORT_CLAIMS_LINK_ROUTE = "/transport-claims-link";
export const TRANSPORT_CLAIMS_EDIT_ROUTE = "/transport-claims/edit/:id";
export const TRANSPORT_CLAIMS_CREATE_ROUTE = "/transport-claims/create";
export const AUDIT_LIST = "/audit-list";
export const AUDIT_TOTAL = "/audit-total/:id";
export const AUDIT_TEMPLATES = "/audit-templates";
export const AUDIT_EDIT = "/audit-templates/edit/:id";
export const AUDIT_VIEW = "/audit-templates/view/:id";
export const AUDIT_FILL = "/audit-list/fill/:id";
export const AUDIT_INFO = "/audit-list/info/:id";
export const AUDIT_TASKS = "/audit-tasks";
export const AUDIT_CREATE_PLAN = "/audit-plan/create/:id";
export const AUDIT_PLAN = "/audit-plan/:id";
export const FLEX_SETTINGS_ROUTE = "/flex/settings";
export const FLEX_IDLE_CATALOG_ROUTE = "/flex/idle-catalog";
export const FLEX_STATUS_ROUTE = "/flex/rent-status";
export const FLEX_CARS_ROUTE = "/flex/cars";
export const WARRANTY_AUDIT_TEMPLATES = "/warranty-audit-templates";
export const WARRANTY_AUDIT_LIST = "/warranty-audit-list";
export const WARRANTY_AUDIT_FILL = "/warranty-audit/fill/:id";
export const WARRANTY_AUDIT_TEMPLATE_VIEW = "/warranty-audit-template/:id";
export const WARRANTY_AUDIT_TEMPLATE_EDIT = "/warranty-audit-template/edit/:id";
export const WARRANTY_AUDIT_PLAN = "/warranty-audit/tasks";
export const PRIVACY_POLICY =
  "/documents/consent-to-the-processing-of-personal-data";
