import { TOnSubmitFormCb } from "@/hooks/useForm";
import { InputField } from "@/packages/formElements/fields/InputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ISimpleDictCreateModal
  extends Omit<
    IModal,
    "children" | "onSubmit" | "onCancel" | "isLoadingConfirmButton"
  > {
  contentTitle?: string;
  form: {
    inputLabel?: string;
    inputDescription?: string;
  };
  onSubmit: TOnSubmitFormCb<string>;
  isSubmitLoading?: boolean;
  onClose: () => void;
}

export const SimpleDictCreateModal: React.FC<ISimpleDictCreateModal> = (
  props
) => {
  const { t } = useTranslation();

  const {
    contentTitle,
    cancelButtonText = t("registers.holdingsDC.cancel") || "",
    form: { inputDescription, inputLabel },
    onSubmit,
    onClose,
    isSubmitLoading,
    title,
    confirmButtonText,
    ...rest
  } = props;
  const [value, setValue] = React.useState("");

  const onSubmitHandler = async () => {
    onSubmit(value);
    setValue("");
  };

  const onCloseHandler = async () => {
    setValue("");
    onClose();
  };

  return (
    <Modal
      {...rest}
      // @ts-ignore
      title={t(title) || ""}
      onSubmit={onSubmitHandler}
      cancelButtonText={cancelButtonText}
      // @ts-ignore
      confirmButtonText={t(confirmButtonText) || ""}
      isLoadingConfirmButton={isSubmitLoading}
      onCancel={onCloseHandler}
    >
      {contentTitle ? (
        <TitleDivider size="medium">{t(contentTitle) || ""}</TitleDivider>
      ) : (
        <></>
      )}
      <InputField
        // @ts-ignore
        label={t(inputLabel) || ""}
        description={inputDescription}
        value={value}
        onChange={setValue}
      />
    </Modal>
  );
};
