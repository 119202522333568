import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";

import { ApplicationsErrorModal } from "@/components/Applications/ApplicationsErrorModal";
import { ApplicationsForm } from "@/components/Applications/ApplicationsForm";

import { IApplicationsFormValues } from "@/components/Applications/ApplicationsForm/hooks";
import { TApplicationCreateRecord } from "@/helpers/api/applications/types";

import {
  useGetApplicationById,
  usePutApplication,
} from "@/helpers/api/applications/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

const colorsByTitle = {
  "На рассмотрении": "gray",
  "Новая публикация": "yellow",
  Согласована: "green",
  Подтверждена: "green",
  Kia: "cyan",
  Ремонт: "scarlet",
  Отклонена: "red",
  "": "gray",
};

const CAR_ALREADY_BOOKED_FOR_DATES_ERROR_MESSAGE =
  "Request overlaps with other requests";

export const ApplicationsEdit: React.FC = () => {
  const navigate = useNavigate();
  const { modalState, openModal } = useModal();

  const params = useParams();
  const id = params.id as string;

  const {
    data: applicationResponse,
    isLoading: isLoadingVehicle,
    refetch,
  } = useGetApplicationById({ id });
  const {
    mutateAsync: putApplicationAsync,
    isLoading: isUpdateApplicationLoading,
  } = usePutApplication();

  const statusTitle = applicationResponse?.data?.status?.title || "";
  const { createNotificationError } = useNotification();

  const { t } = useTranslation();

  const handleFormSubmit = async (data: IApplicationsFormValues) => {
    const {
      startEvent,
      endEvent,
      vehicleId,
      appointment,
      mediaId,
      statusId,
      requestPublicationLink,
      comment,
      receivingUser,
      fileId,
    } = data;

    const dataToServer: TApplicationCreateRecord = {
      startEvent: startEvent ? startEvent.toISOString() : "",
      endEvent: endEvent ? endEvent.toISOString() : "",
      vehicleId: vehicleId!,
      appointment: appointment!,
      mediaId: appointment === "Media" ? (mediaId as string) : undefined,
      statusId: statusId!,
      requestPublicationLink: requestPublicationLink || "",
      comment: comment!,
      receivingUser: receivingUser!,
      // @ts-ignore

      fileIds: fileId?.id ? [fileId?.id] : [],
    };

    try {
      await putApplicationAsync({
        id,
        put: { ...dataToServer },
      });
      refetch();
      navigate("/applications");
    } catch (error: any) {
      if (error?.response?.data?.message === "Expired CASCO or OSAGO") {
        createNotificationError(t("park.expiredCASCO&OSAGO") || "");
      }
      if (
        error?.response?.data?.message ===
        "Request overlaps with other requests"
      ) {
        createNotificationError(t("park.overlaps") || "");
      }
    }
  };

  return (
    <DashboardLayout
      toBack="/applications"
      title="Редактирование заявки"
      headerLeft={
        applicationResponse?.data ? (
          <StatusLabel
            content={statusTitle}
            color={colorsByTitle[statusTitle]}
          />
        ) : (
          <></>
        )
      }
    >
      {applicationResponse?.data ? (
        <ApplicationsForm
          defaultValues={applicationResponse?.data}
          onFormSubmit={handleFormSubmit}
          isLoading={isUpdateApplicationLoading}
          isNewPage={true}
        />
      ) : (
        <></>
      )}

      <ApplicationsErrorModal
        isOpen={modalState.isOpen}
        onSubmit={modalState.onSubmit}
      />
    </DashboardLayout>
  );
};
