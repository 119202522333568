import { useForm } from "react-hook-form";

export interface IStatisticsFilterFormValues {
  modelId: string | null;
  gosNumber: string | null;
  mediaId: string | null;
  startEvent: Date | null;
  endEvent: Date | null;
}

export const DEFAULT_STATISTICS_FILTER_FORM_VALUES: IStatisticsFilterFormValues =
  {
    modelId: null,
    gosNumber: null,
    mediaId: null,
    startEvent: null,
    endEvent: null,
  };

export type TOnSubmitStatisticsFilterFormCb = (
  values: IStatisticsFilterFormValues
) => void;

export const useStatisticsFilterForm = (
  onSubmit: TOnSubmitStatisticsFilterFormCb
) => {
  const form = useForm<IStatisticsFilterFormValues>({
    mode: "onChange",
    defaultValues: DEFAULT_STATISTICS_FILTER_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
