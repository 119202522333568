import { RegistryCreateDataTab } from "@/components/registryCreate/RegistryCreateDataTab";
import { useCreateKiaDealer } from "@/helpers/api/dc/kia-dealers/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { SwitchField } from "@/packages/formElements/fields/SwitchField";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "@/helpers";
import styled from "styled-components";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${colors.black};
      }
    }
  }
`;

export const RegistryCreate: React.FC = () => {
  const navigate = useNavigate();
  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { t } = useTranslation();

  const {
    mutateAsync: createKiaDealerAsync,
    isLoading: isLoadingCreateKiaDealer,
  } = useCreateKiaDealer();
  const [isActive, setIsActive] = useState(true);

  const tabs: ITab[] = [
    {
      name: t("cardDC.data") || "",
      slug: "data",
      content: (
        <RegistryCreateDataTab
          onCreate={async (values) => {
            try {
              await createKiaDealerAsync({ ...values, isActive, files: [] });
              createNotificationSuccess(
                t("cardDC.createNotificationSuccess_create") || ""
              );
              navigate("/registry");
            } catch (error) {
              const errorTyped = error as AxiosError<{
                message: string | string[];
              }>;
              createNotificationError(errorTyped?.response?.data.message || "");
            }
          }}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <DashboardLayout
      toBack="/registry"
      title={t("cardDC.creation") || ""}
      headerRight={
        <SwitchField
          label={t("cardDC.active") || ""}
          value={isActive}
          loading={isLoadingCreateKiaDealer}
          onChange={setIsActive}
        />
      }
    >
      <StyledTabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
    </DashboardLayout>
  );
};
