import { TAnswerOnQuestionRequest, TAuditParametrs, } from "@/helpers/api/audit-fill/types";
import { Button } from "@/packages/button/Button";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import { InputNumber } from "@/packages/formElements/inputs/InputNumber";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useState } from "react";
import { PhotoInfo } from "../../PhotoInfo/PhotoInfo";
import { StyledAnswerButtons } from "./index.styles";
import { t } from "i18next";
import { Badge } from "antd";

const getKpiPlaceHolder = (type) => {
  switch (type) {
    case 'Процент':
      return '%'
    case 'Натуральное число':
      return '1';
    case 'Дробное число':
      return '0.00'
    default:
      return '';
  }
}

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  auditId: number;
  level?: string;
  answerQuestion: (data: TAnswerOnQuestionRequest) => void;
  openChat: (paramId: string) => void;
  isBtnLoading: boolean;
  loadingIds?: string[];
  isSoloTable?: boolean;
  isReadOnly?: boolean;
}): IHeaderItem<TAuditParametrs>[] => {
  const {
    isBtnLoading,
    auditId,
    level,
    loadingIds,
    answerQuestion,
    openChat,
    isSoloTable,
    isReadOnly,
  } = options;

  return [
    {
      Header: t("audits.list.question") || "",
      accessor: "number",
      width: 100,
      minWidth: 100,
      maxWidth: 100,

      Cell: ({ row: { original } }) => {
        return (
          <>
            {isSoloTable
              ? original.order
              : `${level || "0"}.${original.order + 1}`}
          </>
        );
      },
    },
    {
      Header: t("audits.list.checkpoint") || "",
      accessor: "title",
      Cell: ({ row: { original }, value }) => {
        return (
          <TextAreaField value={value} readOnly={true} onChange={() => ({})}/>
        );
      },
    },
    {
      Header: t("audits.list.criteria") || "",
      accessor: "criteria",
      Cell: ({ row: { original }, value }) => {
        return (
          <TextAreaField value={value} readOnly={true} onChange={() => ({})}/>
        );
      },
    },
    // {
    //   Header: "План действий",
    //   accessor: "plan",
    // },
    {
      Header: t("audits.list.attribute") || "",
      accessor: "attribute",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: t("audits.list.weight") || "",
      accessor: "weight",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: t("audits.list.answer") || "",
      accessor: "answer",
      width: "140px",
      minWidth: 140,
      maxWidth: 140,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledAnswerButtons
            isAnswer={!!original?.answers[0]?.answer}
            hasAnswer={
              !!original?.answers?.length &&
              original?.answers[0].answer !== null
            }
          >
            <Button
              isDisabled={isReadOnly}
              isLoading={isBtnLoading && loadingIds?.includes(original.id)}
              size="middle"
              onClick={() =>
                answerQuestion({
                  auditId,
                  paramId: original.id,
                  answer: true,
                })
              }
            >
              {t("common.yes") || ""}
            </Button>
            <Button
              isDisabled={isReadOnly}
              isLoading={isBtnLoading && loadingIds?.includes(original.id)}
              size="middle"
              onClick={() =>
                answerQuestion({
                  auditId,
                  paramId: original.id,
                  answer: false,
                })
              }
            >
              {t("common.no") || ""}
            </Button>
          </StyledAnswerButtons>
        );
      },
    },
    {
      Header: t("audits.list.result") || "",
      accessor: "result",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original }, value }) => {
        const hasAnswer = !!original?.answers[0]?.answer;
        const hasImage = !original?.photo || original?.hasPhoto;
        const hasKpi =
          original?.kpi === "Не обязательно" || original?.answers[0]?.kpi;
        return hasAnswer && hasImage && hasKpi
          ? original?.weight
          : !!original?.answers[0]
            ? "0"
            : "-";
      },
    },
    {
      Header: "KPI",
      accessor: "kpi",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row: { original }, value }) => {
        const [inputValue, setInputValue] = useState<number | null>(
          original?.answers[0]?.kpi || null
        );

        return value !== "Не обязательно" ? (
          <InputNumber
            placeholder={getKpiPlaceHolder(value)}
            disabled={isBtnLoading && loadingIds?.includes(original.id) || isReadOnly}
            value={inputValue}
            error={!inputValue}
            onChange={(value) => setInputValue(value)}
            onBlur={() =>
              answerQuestion({
                auditId,
                paramId: original.id,
                kpi: inputValue || 0,
              })
            }
          />
        ) : (
          <></>
        );
      },
    },

    {
      Header: t("audits.list.photo") || "",
      accessor: "photo",
      Cell: ({ row: { original }, value }) => {
        return (
          <PhotoInfo
            isRequired={!!original.photo}
            isUpload={!!original.hasPhoto}
          />
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: "60px",
      minWidth: 60,
      maxWidth: 60,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        const unreadCount = original.unreadMessagesAmount;
        return (
          <Tooltip content={t("audits.list.chat") || ""} placement="left">
            <Badge count={unreadCount}>
              <IconButton
                size="l"
                icon="chat"
                color="black"
                variant="tertiary"
                onClick={() => openChat(original.id)}
              />
            </Badge>
          </Tooltip>
        );
      },
    },
  ];
};
