import { DealerSelectAdapter, IDealerSelectAdapter, } from "@/adapters/selects/DealerSelectAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";

export type FormDealersSelectProps = {
  name: string;
  setSearchValueDealerList: React.Dispatch<React.SetStateAction<string>>;
  dealerList?: TDealerRecord[];
} & Omit<IDealerSelectAdapter, "error" | "value" | "onChange">;

const _FormDealersSelect: React.FC<FormDealersSelectProps> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <DealerSelectAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormDealersSelect = React.memo(_FormDealersSelect);
