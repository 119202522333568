import { TFileRecord } from "@/helpers/api/files/types";
import { deleteDuplicateInArray } from "@/helpers/utils";
import { FileDropzoneField, IFileDropzoneField, } from "@/packages/formElements/fields/FileDropzoneField";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormFileDropzoneField
  extends Omit<IFileDropzoneField, "error" | "value" | "onChange"> {
  name: string;
  isAllowFilesUpload?: boolean;
  isCentered?: boolean;
  fileIds?: TFileRecord[];
  isDisabled?: boolean;
  allowedTypesProps?: Array<string>;
  withCommentField?: boolean;
  isNotTransportClaims?: boolean;
  isMultiple?: boolean;
}

const _FormFileDropzoneField: React.FC<IFormFileDropzoneField> = (props) => {
  const { name, isNotTransportClaims, fileIds, isDisabled, ...inputProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={isNotTransportClaims ? name : `${name}.files`}
      control={control}
      render={({
                 field: { ref: _, onChange, value, ...fieldWithoutRef },
                 fieldState: { error },
               }) => {
        const modValue =
          !isDisabled && value
            ? value?.filter((item) => {
              if (item.id) return item;
            })
            : [];

        const resArr: TFileRecord[] = fileIds
          ? [...fileIds, ...modValue]
          : modValue;
        return (
          <FileDropzoneField
            isDisabled={isDisabled}
            value={
              fileIds ? deleteDuplicateInArray<TFileRecord>(resArr) : value
            }
            onChange={onChange}
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            fieldName={name}
          />
        );
      }}
    />
  );
};
export const FormFileDropzoneField = React.memo(_FormFileDropzoneField);
