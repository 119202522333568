import ResetMessage from "@/components/ResetMesage";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import { FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import { TOnSubmitPasswordForgotFormCb, usePasswordForgotForm } from "./hooks";
import {
  ResetMailButton,
  ResetMailForm,
  ResetMailSubTitle,
  ResetMailSubTitleLink,
  ResetMailTitle,
  ResetMailWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IPasswordReset {
  onSubmit: TOnSubmitPasswordForgotFormCb;
  isLetter: boolean;
}

export const PasswordForgotForm: React.FC<IPasswordReset> = ({
  onSubmit,
  isLetter,
}) => {
  const {
    form,
    submit,
    isSubmitLoading: isSumbitLoading,
  } = usePasswordForgotForm(onSubmit);

  const { t } = useTranslation();

  return isLetter ? (
    <ResetMessage />
  ) : (
    <FormProvider {...form}>
      <ResetMailWrapper>
        <ResetMailTitle color="black" level={1}>
          {t("authorization.title_changePassword") || ""}
        </ResetMailTitle>
        <ResetMailSubTitle>
          <Paragraph size={14} color="gray200">
            {t("authorization.subTitle") || ""}
          </Paragraph>
          <Link to={"/registration"}>
            <ResetMailSubTitleLink>
				{t("authorization.subTitleLink") || ""}
            </ResetMailSubTitleLink>
          </Link>
        </ResetMailSubTitle>
        <ResetMailForm>
          <FormInputField
            name="email"
            label={t("authorization.email") || ""}
            placeholder={t("authorization.emailPlaceholder") || ""}
          />

          <ResetMailButton
            size="middle"
            theme="primary"
            color="brand"
            onClick={submit}
            isLoading={isSumbitLoading}
            isBlock={true}
          >
            {t("authorization.continue") || ""}
          </ResetMailButton>
        </ResetMailForm>
      </ResetMailWrapper>
    </FormProvider>
  );
};
