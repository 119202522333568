import { FC } from "react";

import { IInput, Input } from "@/packages/formElements/inputs/Input";
import { Field } from "../Field";

export interface IInputField extends Omit<IInput, "error"> {
  label?: string;
  error?: string;
  description?: string;
  isBlock?: boolean;
  isErrorAbsolute?: boolean;
}

export const InputField: FC<IInputField> = (props) => {
  const {
    label,
    error,
    description,
    isBlock,
    readOnly,
    isErrorAbsolute,
    ...inputProps
  } = props;

  return (
    <Field isBlock={isBlock}>
      <Field.Label readOnly={readOnly} disabled={inputProps.disabled}>
        {label}
      </Field.Label>

      <Field.FieldControlContainer>
        <Input {...inputProps} readOnly={readOnly} error={!!error} />
      </Field.FieldControlContainer>

      {error ? (
        <Field.FieldError isErrorAbsolute={isErrorAbsolute}>
          {error}
        </Field.FieldError>
      ) : (
        <></>
      )}

      {!error && description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
