import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TEngineVolumesCreateRequest,
  TEngineVolumesCreateResponse,
  TEngineVolumesDeleteRequest,
  TEngineVolumesDeleteResponse,
  TEngineVolumesPutRequest,
  TEngineVolumesPutResponse,
  TGetEngineVolumesByIdRequest,
  TGetEngineVolumesByIdResponse,
  TGetEngineVolumesListRequest,
  TGetEngineVolumesListResponse,
} from "./types";

export const useGetEngineVolumeList = (
  request: TGetEngineVolumesListRequest
) => {
  return useQuery(["engineVolumes", "list", request], () => {
    return api.get<TGetEngineVolumesListResponse>("/engine-volumes", {
      params: request,
    });
  });
};

export const useGetEngineVolumeById = (
  request: TGetEngineVolumesByIdRequest
) => {
  return useQuery(["engineVolumes", "detail", request.id], () => {
    return api.get<TGetEngineVolumesByIdResponse>(
      `/engine-volumes/${request.id}`
    );
  });
};

export const useCreateEngineVolume = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TEngineVolumesCreateRequest) => {
      return api.post<TEngineVolumesCreateResponse>("/engine-volumes", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["engineVolumes", "list"]);
      },
    }
  );
};

export const usePutEngineVolume = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TEngineVolumesPutRequest) => {
      return api.put<TEngineVolumesPutResponse>(`/engine-volumes/${id}`, put);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["engineVolumes", "list"]);
        queryClient.invalidateQueries([
          "engineVolumes",
          "detail",
          variables.id,
        ]);
      },
    }
  );
};

export const useDeleteEngineVolume = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TEngineVolumesDeleteRequest) => {
      return api.delete<TEngineVolumesDeleteResponse>(
        `/engine-volumes/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["engineVolumes", "list"]);
        queryClient.invalidateQueries([
          "engineVolumes",
          "detail",
          variables.id,
        ]);
      },
    }
  );
};
