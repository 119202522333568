import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import React from "react";
import {
  StyledContentTitle,
  StyledNextButton,
  StyledPrevButton,
  StyledWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

interface IDealerBonusesTitleHeader {
  hasButton: boolean;
  disablePrev: boolean;
  disableNext: boolean;
  disableExport: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  handleExport?: () => void;
  onButtonClick?: () => void;
}
export const DealerBonusesTitleHeader: React.FC<IDealerBonusesTitleHeader> = (
  props
) => {
  const {
    hasButton,
    disablePrev,
    disableNext,
    disableExport,
    onNextClick,
    onPrevClick,
    onButtonClick,
    handleExport,
  } = props;

  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  return (
    <StyledWrapper>
      <Space size={16}>
        <StyledPrevButton
          icon="chevron-left"
          variant="primary"
          color="white"
          size="l"
          isDisabled={disablePrev}
          onClick={onPrevClick}
        />
        <Space size={8}>
          {!disableExport ? (
            <IconButton
              icon="save"
              isSquared={true}
              variant="secondary"
              color="gray"
              onClick={handleExport && handleExport}
            />
          ) : null}
          <IconButton
            icon="share"
            isSquared={true}
            variant="secondary"
            color="gray"
            onClick={() => copyCurrentUrl()}
          />
        </Space>
      </Space>

      <StyledContentTitle>
        <Paragraph size={20}>
          {t("bonuses.reportsView.bonusesDealer") || ""}
        </Paragraph>
      </StyledContentTitle>
      <Space size={30}>
        {hasButton ? (
          <Button color="brand" onClick={onButtonClick}>
            {t("bonuses.reportsView.premium") || ""}
          </Button>
        ) : (
          <></>
        )}
        <StyledNextButton
          icon="chevron-left"
          variant="primary"
          color="white"
          isDisabled={disableNext}
          size="l"
          onClick={onNextClick}
        />
      </Space>
    </StyledWrapper>
  );
};
