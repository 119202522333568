import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TOnSubmitRegistryEditFormCb } from "@/components/RegistryDataFields/hooks";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { usePermission } from "@/hooks/usePermission";
import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import React from "react";
import { Link } from "react-router-dom";
import { StyledTable } from "./index.styles";
import { RegistryTableExpandDataForm } from "./RegistryTableExpandDataForm";
import { useTranslation } from "react-i18next";
import { HeaderArgType } from "@/packages/stickyTable/StickyTable";
import { SortOrder } from "@/packages/nestedTable/NestedTable";

export interface IRegistryTable {
  data?: TDealerRecord[];
  onEdit: TOnSubmitRegistryEditFormCb;
  handleSort: (accessor: HeaderArgType, order: SortOrder) => void
}

export const RegistryTable: React.FC<IRegistryTable> = (props) => {
  const { data, onEdit, handleSort } = props;

  const { hasAccess } = usePermission("dc", "update");

  const { t } = useTranslation();

  const headers: IHeaderItem<TDealerRecord>[] = [
    {
      Header: t("registers.registersDC.code") || "",
      accessor: "warrantyCode",
      width: "120px",
      minWidth: 120,
      maxWidth: 120,
    },

    {
      Header: t("registers.registersDC.mobisCode") || "",
      accessor: "mobisCode",
      width: "120px",
      minWidth: 120,
    },
    {
      Header: t("registers.registersDC.sapCode") || "",
      accessor: "sapCode",
      width: "120px",
      minWidth: 120,
    },
    {
      Header: t("registers.registersDC.showroomCode") || "",
      accessor: "showroomCode",
      width: "140px",
      minWidth: 140,
    },
    {
      Header: t("registers.registersDC.lawName") || "",
      accessor: "lawName",
    },
    {
      Header: t("registers.registersDC.merchantName") || "",
      accessor: "merchantName",
      isSortable: true,
    },

    {
      Header: "",
      id: "actions",
      width: "60px",
      minWidth: 60,
      maxWidth: 60,

      Cell: (data) => {
        return (
          <PermissionsAdapter
            resourceName="dc"
            resourceAction="update"
            fallback={() => (
              <Link to={`/card-dc/${data.row.original.id}`}>
                <IconButton
                  icon="info"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Link>
            )}
          >
            <Link to={`/registry/${data.row.original.id}`}>
              <IconButton
                icon="settings"
                color="black"
                size="l"
                variant="tertiary"
              />
            </Link>
          </PermissionsAdapter>
        );
      },
    },
  ];

  return (
    <StyledTable
      headers={headers}
      data={data ?? []}
      isCloseOnRefresh={true}
      onSort={handleSort}
      renderElement={(row) => {
        const registry = row.original as TDealerRecord;

        return (
          <RegistryTableExpandDataForm
            dealer={registry}
            onSubmit={onEdit}
            isDisabled={!hasAccess || !registry.isActive}
          />
        );
      }}
    />
  );
};
