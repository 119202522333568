import { Icon } from "@/packages/icon/Icon";
import { colors } from "@/helpers";
import styled, { css } from "styled-components";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Avatar } from "antd";

export const StyledButtonText = styled(ButtonText)`
  &:not(:last-of-type) {
    margin-right: 35px;
  }

  &:hover {
    color: ${colors["brand"]};

    svg {
      fill: ${colors["brand"]};
    }
  }
`;

export const StyledText = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    transition: 0.4s;
    font-size: 16px;
    line-height: 24px;

    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

export const StyledBlockText = styled.div`
  display: flex;
  flex-direction: column;

  span {
    &:first-child {
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      margin-bottom: 8px;
    }
  }
`;

export const StyledRoleItem = styled.div`
    display: flex;
    justify-content: flex-start;

    height: 62px;
`;

export const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 20px;
`;

export const StyledProfile = styled.div`
  padding: 32px;
  background-color: ${colors["white"]};
  border: 1px solid ${colors["gray3"]};
  border-radius: 0 12px 12px 12px;
`;

export const StyledProfileNav = styled.nav`
  display: flex;
  margin-bottom: 36px;
`;

export const StyledInfo = styled.section`
  &:not(:last-child) {
    margin-bottom: 36px;
  }
`;

export const StyledInfoList = styled.ul`
  display: flex;
  padding: 24px;
`;

export const StyledInfoItem = styled.li`
    position: relative;
    padding: 0 80px 0 0;
    width: 33%;

    &:not(:last-child) {
        margin-right: 35px;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: 100%;
            width: 0.5px;
            background-color: ${colors["gray3"]};
        }
    }
`;

export const StyledAvatarItem = styled(Avatar)`
  border-radius: 50%;
  background-color: ${colors.brand};
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-right: 16px;
  ${({ size }) => css`
    min-width: ${size}px;
    min-height: ${size}px;
  `}
`;

export const StyledBlockItem = styled.div`
  display: flex;
  align-items: center;
`;
