import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled from "styled-components";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectField = styled(SelectField)`
  width: 188px;
`;

const StyledTableSimple = styled.div`
  overflow: hidden;
  /* width: 100%; */
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)``;

const StyledAnswerButtons = styled(Space)<{}>``;

// hasAnswer &&
// (isAnswer
//   ? css`
//       div {
//         :first-child {
//           button {
//             background-color: #62bb2c;
//             color: white;
//           }
//         }
//       }
//     `
//   : css`
//       div {
//         :last-child {
//           button {
//             background-color: #e32446;
//             color: white;
//           }
//         }
//       }
//     `)}

const StyledSelect = styled.div`
  width: 107px;
`;

const StyledGrid = styled.div`
  display: flex;
  gap: 16px;
`;
export {
  StyledGrid,
  StyledSelect,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
  StyledAnswerButtons,
};
