import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TGetWorkByIdRequest,
  TGetWorkByIdResponse,
  TGetWorksRequest,
  TGetWorksResponse,
  TWorksCreateRequest,
  TWorksCreateResponse,
  TWorksDeleteRequest,
  TWorksDeleteResponse,
  TWorksUpdateRequest,
  TWorksUpdateResponse,
} from "./types";

export const useGetWorksDefault = (isEnabled = true) => {
  return useQuery(
    ["worksDefault", isEnabled],
    () => api.get<TGetWorksResponse>("/works", { params: { isDefault: true } }),
    { enabled: isEnabled }
  );
};

export const useGetWorks = (request: TGetWorksRequest, isEnabled = true) => {
  return useQuery(
    ["works", request],
    () => api.get<TGetWorksResponse>("/works", { params: request }),
    { enabled: isEnabled }
  );
};

export const useGetWorkById = (request: TGetWorkByIdRequest) => {
  return useQuery(["work", request.id], () =>
    api.get<TGetWorkByIdResponse>(`/works/${request.id}`)
  );
};

export const useCreateWork = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TWorksCreateRequest) =>
      api.post<TWorksCreateResponse>("/works", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["works"]);
      },
    }
  );
};

export const useUpdateWork = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: TWorksUpdateRequest["id"]; data: TWorksCreateRequest }) =>
      api.patch<TWorksUpdateResponse>(`/works/${data.id}`, data.data),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["work", variables.id]);
        queryClient.invalidateQueries(["works"]);
      },
    }
  );
};

export const useDeleteWork = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TWorksDeleteRequest) =>
      api.delete<TWorksDeleteResponse>(`/works/${data.id}`),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["work", variables.id]);
        queryClient.invalidateQueries(["works"]);
      },
    }
  );
};

export const useGetSettingsKey = (
  request: TGetWorksRequest,
  isEnabled = true
) => {
  return useQuery(
    ["works", request],
    () => api.get<TGetWorksResponse>("/works"),
    { enabled: isEnabled }
  );
};
