import { colors } from "@/helpers";
import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import styled, { css } from "styled-components";
import {
  getInputBorderStyle,
  getInputPlaceholderStyle,
  getInputTextStyle,
} from "../styles";

export interface IStyledTextArea extends TextAreaProps {
  error: boolean;
}
export const StyledTextArea = styled(Input.TextArea)<IStyledTextArea>`
  ${({ disabled, error }) =>
    getInputBorderStyle({ disabled: disabled ?? false, error })}

  ${getInputTextStyle()}

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${colors.gray5};
      cursor: default;
      border: 1px solid ${colors.gray6};
    `}

  &::placeholder {
    ${getInputPlaceholderStyle()}
  }
`;
