import { media } from "@/helpers";
import { Card } from "@/packages/Card";
import styled from "styled-components";

export const StyledIdentityFormCard = styled(Card)`
  width: 100vw;
  height: 100vh;
  ${media.tablet} {
    max-width: 504px;
    width: 504px;
    height: fit-content;
  }
`;
