import { Dropdown, Menu } from "antd";
import styled from "styled-components";

export const StyledDropdownButton = styled(Dropdown.Button)`
  button {
    border: none;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    border-radius: 8px !important;
  }
`;

export const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;
