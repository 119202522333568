import React from "react";
import { ErrorModal } from "@/packages/ErrorModal/ErrorModal";
import { IModal } from "@/packages/modal/Modal";

type AutoParkErrorModalProps = Pick<
  IModal,
  "isOpen" | "onSubmit"
>;

export const AutoParkErrorModal: React.FC<AutoParkErrorModalProps> = (props) => {
  const { ...rest } = props;

  return (
    <ErrorModal
      {...rest}
      size="small"
      title="Отклонено"
      confirmButtonText="Закрыть"
      showCancelButton={false}
    >
      Добавление автомобиля невозможно, так как просрочен срок действия полиса
    </ErrorModal>
  );
};
