import { Button } from "@/packages/button/Button";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Icon } from "@/packages/icon/Icon";
import { Space } from "antd";

import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IBonusesFilters {
  children: JSX.Element | null;
  quarter: number | string;
  isAllDealers: boolean;
  searchValue?: string;
  onSearchInput?: (value?: string) => void;
}

const StyledFilter = styled.div``;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 0;
  border-bottom: 1px solid #f6f6f9;
  margin: 8px 0 12px 0;
`;

export const BonusesFilters: React.FC<IBonusesFilters> = (props) => {
  const { children, quarter, searchValue, onSearchInput, isAllDealers } = props;
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  return (
    <StyledFilter>
      <StyledTop>
        {onSearchInput ? (
          <Search
            value={searchValue}
            onChange={(value) => onSearchInput(value)}
          />
        ) : (
          <div></div>
        )}
        <Space size={12}>
          {isAllDealers ? (
            <Button isUploadLeft={true} icon={<Icon name="home" size={16} />}>
              {t("bonuses.reportsView.allDC") || ""}
            </Button>
          ) : null}
          <Button
            isUploadLeft={true}
            icon={<Icon name="calendar-16" size={16} />}
          >{`${quarter} ${t("bonuses.reportsView.quarter") || ""}`}</Button>
          <IconButton
            icon="filter"
            color="black"
            size="m"
            isSquared={true}
            variant={isOpen ? "primary" : "secondary"}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </Space>
      </StyledTop>
      {isOpen ? children : <></>}
    </StyledFilter>
  );
};
