import React from "react";
import {
  DEFAULT_REGISTRATION_FORM_FIRST_STEP_VALUES,
  RegistrationFirstStepFormSuccessValues,
  RegistrationFirstStepFormValues,
} from "./RegistrationFormFirstStep/hooks";
import {
  DEFAULT_REGISTRATION_FORM_SECOND_STEP_VALUES,
  RegistrationSecondStepFormSuccessValues,
  RegistrationSecondStepFormValues,
} from "./RegistrationFormSecondStep/hooks";

export type RegistrationFormValues = RegistrationFirstStepFormValues &
  RegistrationSecondStepFormValues;

export type RegistrationFormSuccessValues =
  RegistrationFirstStepFormSuccessValues &
    RegistrationSecondStepFormSuccessValues;

export type RegistrationFormSuccessCallback = (
  data: RegistrationFormSuccessValues
) => Promise<void>;

const DEFAULT_REGISTRATION_FORM_VALUES: RegistrationFormValues = {
  ...DEFAULT_REGISTRATION_FORM_FIRST_STEP_VALUES,
  ...DEFAULT_REGISTRATION_FORM_SECOND_STEP_VALUES,
};

export const useRegistrationForm = (
  onSubmitForm: RegistrationFormSuccessCallback
) => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  const [fullFormValues, setFullFormValues] =
    React.useState<RegistrationFormValues>(DEFAULT_REGISTRATION_FORM_VALUES);

  const onSubmitFirstStep = (
    values: RegistrationFirstStepFormSuccessValues
  ) => {
    setCurrentStep(2);
    setFullFormValues((prev) => ({ ...prev, ...values }));
  };

  const onSubmitSecondStep = async (
    values: RegistrationSecondStepFormSuccessValues
  ) => {
    const successValues: RegistrationFormSuccessValues = {
      ...fullFormValues,
      ...values,
    } as RegistrationFormSuccessValues;
    setFullFormValues(successValues);

    try {
      setIsSubmitLoading(true);
      await onSubmitForm(successValues);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return {
    currentStep,
    setCurrentStep,
    onSubmitFirstStep,
    onSubmitSecondStep,
    isSubmitLoading,
  };
};
