import { TFlexQuotasRecord } from "@/helpers/api/flex/settings/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import * as yup from "yup";
import { DEFAULT_MONTH } from "../SettingsFields";
import {
  IQuotasDTOValues,
  IQuotasSuccessFormValues,
  QuotasFormFields,
} from "../SettingsFields/types";
import {
  generationFormValuesToDTO,
  generationFormValuesToDTOPatch,
} from "../SettingsFields/utils";

const DEFAULT_QUOTAS_CREATE_VALUES: IQuotasSuccessFormValues = {
  [QuotasFormFields.YEAR]: new Date().getFullYear(),
  [QuotasFormFields.DEALER_ID]: "",
  [QuotasFormFields.MONTHS]: DEFAULT_MONTH,
};

// const formSchema = yup.object({
//   [QuotasFormFields.YEAR]: yup.string().nullable(),
//   [QuotasFormFields.DEALER_ID]: yup
//     .string()
//     .uuid()
//     .nullable()
//     .required("Это поле обязательно для заполнения"),
//   [QuotasFormFields.MONTHS]: yup.array().nullable(),

//   [QuotasFormFields.GENERATIONS]: yup.array().nullable(),
//   [QuotasFormFields.AMOUNT]: yup
//     .number()
//     .required("Это поле обязательно для заполнения")
//     .nullable(),
//   [QuotasFormFields.MODEL]: yup.string().uuid(),
// });

export type TOnSubmitCreateQuotasForm = (values: IQuotasDTOValues) => void;

export const useCreateQuotasForm = (onSubmit: TOnSubmitCreateQuotasForm) => {
  const onSubmitHandler = (values: IQuotasDTOValues) => {
    const data = generationFormValuesToDTO(values);
    onSubmit(data);
  };
  return useForm<IQuotasSuccessFormValues, IQuotasSuccessFormValues>({
    defaultValues: DEFAULT_QUOTAS_CREATE_VALUES,
    onSubmit: onSubmitHandler,
    // validateFormSchema: formSchema,
  });
};

export const useEditQuotasForm = (options: {
  editableRecord?: TFlexQuotasRecord;
  onSubmit: TOnSubmitCreateQuotasForm;
}) => {
  const { editableRecord, onSubmit } = options;
  const onSubmitHandler = (values: IQuotasSuccessFormValues) => {
    if (editableRecord?.id) {
      const preparedMonth = [];
      Object.entries(values?.months).forEach(([key, value]) => {
        if (value.length) {
          // @ts-ignore
          preparedMonth.push({
            month: +key,
            generations: value.map((item) => ({
              generationId: item.generationId,
              amount: item.amount,
            })),
          });
        }
      });

      onSubmit({
        // @ts-ignore
        id: editableRecord?.id,
        patch: { months: preparedMonth },
      });
    }
  };

  const result = useForm<IQuotasSuccessFormValues, IQuotasSuccessFormValues>({
    defaultValues: DEFAULT_QUOTAS_CREATE_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(generationFormValuesToDTOPatch(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
