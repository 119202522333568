import { TOnSubmitRegistryEditFormCb } from "@/components/RegistryDataFields/hooks";
import { DetailRegistryEmployeesTab } from "@/components/registryDetail/DetailRegistryEmployeesTab";
import {
  DetailRegistryPhotosTab,
  IDetailRegistryPhotosTab,
} from "@/components/registryDetail/DetailRegistryPhotosTab";
import { DetailRegistryStatsTab } from "@/components/registryDetail/DetailRegistryStatsTab";
import { RegistryDetailDataTab } from "@/components/registryDetail/RegistryDetailDataTab";
import { useGetDealerById } from "@/helpers/api/dc/dealers/hooks";
import {
  useExportDealer,
  useGetKiaDealerById,
  usePatchKiaDealer,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { useDeleteUser, useExportUsers } from "@/helpers/api/users/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { useActiveTab } from "@/hooks/useActiveTab";
import { useNotification } from "@/hooks/useNotification";
import { usePermission } from "@/hooks/usePermission";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { SwitchField } from "@/packages/formElements/fields/SwitchField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { Space } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { useTranslation } from "react-i18next";

export const RegistryDetail: React.FC = (props) => {
  const { hasAccess } = usePermission("dc", "update");

  const [filterQueryParamsValues, setFilterQueryParamsValues] = useQueryParams({
    name: StringParam,
    roleId: StringParam,
    status: BooleanParam,
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const params = useParams();
  const { mutateAsync: deleteUserAsync } = useDeleteUser();

  const id = params.id as string;
  const status = params.status as string;

  const { mutateAsync: exportDealer } = useExportDealer();
  const { mutateAsync: exportUsers } = useExportUsers();

  const { data: dealerResponse } = useGetDealerById({ id });
  const { data: kiaDealerResponse } = useGetKiaDealerById({ id });

  const {
    mutateAsync: patchKiaDealerAsync,
    isLoading: isLoadingPatchKiaDealer,
  } = usePatchKiaDealer();

  const dealer = dealerResponse?.data;
  const kiaDealer = kiaDealerResponse?.data;

  const adaptDealer = kiaDealer || dealer;

  const onEditRegistryDataFields: TOnSubmitRegistryEditFormCb = async (
    values
  ) => {
    await patchKiaDealerAsync(values);
  };
  const onDeleteClickHandler = (userId: string) => {
    try {
      deleteUserAsync({
        id: userId,
      });
    } catch {
      createNotificationError(t("cardDC.deleteError") || "");
    }
  };

  const onUploadPhotoHandler: IDetailRegistryPhotosTab["onUploadFile"] = async (
    file
  ) => {
    if (adaptDealer) {
      await patchKiaDealerAsync({
        id: adaptDealer.id,
        patch: {
          //@ts-ignore
          files: [
            ...(adaptDealer.files?.map((file) => file.id) || []),
            file.id,
          ],
        },
      });
    }
  };

  const tabs: ITab[] = [
    {
      name: t("cardDC.data") || "",
      slug: "data",
      content: adaptDealer ? (
        <RegistryDetailDataTab
          dealer={adaptDealer}
          onEdit={onEditRegistryDataFields}
          isReadOnly={!hasAccess || !adaptDealer.isActive}
        />
      ) : undefined,
    },
    {
      name: t("cardDC.photo") || "",
      slug: "photos",
      content: adaptDealer ? (
        <DetailRegistryPhotosTab
          dealer={adaptDealer}
          onUploadFile={onUploadPhotoHandler}
          isDisabled={!hasAccess}
        />
      ) : undefined,
    },
    {
      name: t("cardDC.employersList") || "",
      slug: "employers-list",
      content: adaptDealer ? (
        <DetailRegistryEmployeesTab
          dealer={adaptDealer}
          onDeleteClick={onDeleteClickHandler}
        />
      ) : undefined,
    },
    {
      name: t("cardDC.stats") || "",
      slug: "stats",
      content: adaptDealer ? (
        <DetailRegistryStatsTab dealer={adaptDealer} />
      ) : undefined,
    },
  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const onChangeIsActive = adaptDealer
    ? async (value: boolean) => {
        try {
          await patchKiaDealerAsync({
            id: adaptDealer.id,
            patch: {
              //@ts-ignore
              isActive: value,
            },
          });

          createNotificationSuccess(
            t("cardDC.createNotificationSuccess") || ""
          );
          navigate(0);
        } catch {
          createNotificationError(t("cardDC.createNotificationError") || "");
        }
      }
    : undefined;

  const handleExport = () => {
    if (activeTab.slug === "employers-list") {
      exportUsers(
        {
          //@ts-ignore
          dealerId: id,
          status: filterQueryParamsValues.status,
          roleId: filterQueryParamsValues.roleId,
          name: filterQueryParamsValues.name,
        },
        {
          onSuccess: (response) => {
            handleFileLoad(response.data as Blob, "users.xlsx");
          },
        }
      );
    } else {
      //@ts-ignore
      exportDealer(id, {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "dealers.xlsx");
        },
      });
    }
  };

  return (
    <DashboardLayout
      toBack={-1}
      title={adaptDealer?.merchantName || t("cardDC.loading") || ""}
      headerRight={
        <Space size={32} align="center">
          <SwitchField
            label={t("cardDC.active") || ""}
            value={adaptDealer?.isActive}
            loading={isLoadingPatchKiaDealer}
            onChange={onChangeIsActive}
            disabled={!hasAccess || !adaptDealer}
          />

          <IconButton
            icon="save"
            isSquared
            variant="primary"
            color="white"
            size="m"
            onClick={handleExport}
          />
        </Space>
      }
    >
      <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
    </DashboardLayout>
  );
};
