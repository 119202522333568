import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import styled from "styled-components";

export const StyledFormSelect = styled(FormSelect)`
  width: 300px;
`;

export const StyledViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
