import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { InformationalLettersDataFields } from "@/components/informationalLetters/InformationLettersDataFields";
import { useGetInfoIpForm } from "@/components/informationalLetters/InformationLettersEditData/hooks";
import {
  useGetInformationLetterById,
  useGetInformationLettersStatistic,
  usePatchInformationLetterStatisticStatus,
} from "@/helpers/api/InformationalLetters/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Space } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

export const InformationLettersInfo: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    state: { profile },
  } = useProfile();
  const { t } = useTranslation();
  const {
    id: profileDealerId,
  } = profile?.dealer || {};

  const {
    createNotificationSuccess,
    createNotificationError,
    createNotificationWarning,
  } = useNotification();

  const { data: informationLetterResponse, isLoading } =
    useGetInformationLetterById({
      id: params.id as string,
    });

  const { data: statisticOfLetter } = useGetInformationLettersStatistic({
    letterId: params.id as string,
    limit: 0,
  });

  const dealerStatistic =
    profile?.dealer &&
    statisticOfLetter?.data.data.find((x) => x.dealer.id === profileDealerId);

  const {
    id: dealerStatisticId,
    status: dealerStatisticStatus = t(
      "dealerMaterials.letters.notFamiliarized"
    ) || "",
  } = dealerStatistic || {};

  const isAssignedRole = informationLetterResponse?.data.roles?.find(
    (el) => el.id === profile?.role?.id
  );

  const { mutateAsync: informationLetterStatisticUpdateAsync } =
    usePatchInformationLetterStatisticStatus();

  //@ts-ignore
  const { form } = useGetInfoIpForm(informationLetterResponse?.data);

  const onClickHandler = async () => {
    try {
      if (dealerStatisticId) {
        await informationLetterStatisticUpdateAsync({
          id: dealerStatisticId,
          patch: { status: t("dealerMaterials.letters.familiarized") || "" },
        });
        createNotificationSuccess(
          t("dealerMaterials.letters.createNotificationSuccessFamiliarized") ||
            ""
        );
        navigate("/information-letters");
      } else {
        createNotificationWarning(
          t("dealerMaterials.letters.createNotificationWarning") || ""
        );
      }
    } catch {
      return createNotificationError(
        t("dealerMaterials.letters.createNotificationErrorFamiliarized") || ""
      );
    }
  };

  const record = {
    ...informationLetterResponse?.data,
    fileIds: informationLetterResponse?.data.files,
  };

  return (
    <DashboardLayout
      toBack={-1}
      title={t("dealerMaterials.letters.letterInfo") || ""}
    >
      <div>
        <FormProvider {...form}>
          {!isLoading && (
            <InformationalLettersDataFields isDisabled record={record} />
          )}
        </FormProvider>
        <Space>
          <PermissionsAdapter
            resourceName="information-letters"
            resourceAction="read"
          >
            {isAssignedRole && dealerStatisticStatus === "Ознакомлен" ? (
              <Tooltip
                trigger="hover"
                placement={"left"}
                content={t("dealerMaterials.letters.alreadyFamiliarized") || ""}
                size={"l"}
                isFitContent={true}
              >
                <Button
                  theme="primary"
                  color="brand"
                  size="middle"
                  onClick={onClickHandler}
                  isDisabled={dealerStatisticStatus === "Ознакомлен"}
                >
                  {t("dealerMaterials.letters.familiarized") || ""}
                </Button>
              </Tooltip>
            ) : (
              <Button
                theme="primary"
                color="brand"
                size="middle"
                onClick={onClickHandler}
                isDisabled={dealerStatisticStatus === "Ознакомлен"}
              >
                {t("dealerMaterials.letters.familiarized") || ""}
              </Button>
            )}
          </PermissionsAdapter>
        </Space>
      </div>
    </DashboardLayout>
  );
};
