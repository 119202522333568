import { components, paths } from "../swagger-scheme";

export const enum ChartType {
  Bullet = "bullet",
  Graph = "graph",
}

export type TGetBulletGraphRequest =
  paths["/api/reports/dealers/bullet"]["get"]["parameters"]["query"];

export type TGetBulletGraphResponse =
  paths["/api/reports/dealers/bullet"]["get"]["responses"]["200"]["content"]["application/json"];

export type TGetGraphRequest =
  paths["/api/reports/dealers/graph"]["get"]["parameters"]["query"];

export type TGetGraphResponse =
  paths["/api/reports/dealers/graph"]["get"]["responses"]["200"]["content"]["application/json"];
