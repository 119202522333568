import styled from "styled-components";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

interface IInfoBlockItem {
  padding?: boolean;
}

export const StyledTableColumnLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.gray40};
`;

export const StyledTableHeadLeftWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  padding-left: var(--padding-left);

  background-color: ${colors.gray30};
`;

export const StyledUnitTitle = styled(Paragraph)``;
export const StyledUnitTitleSecond = styled(Paragraph)`
  position: absolute;
  left: 120px;
`;
export const StyledUnitTitleWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50px;
  left: 40px;
`;

export const StyledInfoBlockList = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-left: 1px solid ${colors.gray40};
`;

export const StyledUnitName = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

export const StyledModelParagraph = styled(Paragraph)`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  border-right: 1px solid ${colors.gray40};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledInfoBlockItem = styled.li<IInfoBlockItem>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 330px;
  align-items: center;
  padding-left: ${({ padding }) =>
    padding ? `var(--padding-left)` : 2 + "px"};

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: ${colors.gray40};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`;

export const StyledInfoBlockSubList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoBlockSubItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  width: 235px;
  padding-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:nth-of-type(2n) {
    background-color: ${colors.gray40};
  }
`;

export const StyledInfoBlockSubItemParagraph = styled(Paragraph)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
`;

export const StyledC2CParagraph = styled(Paragraph)`
  width: 36px;
  height: 22px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  background: #dbdce0;
  border-radius: 4px;
  color: #7b7d81;
`;
export const StyledAutoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLoader = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
