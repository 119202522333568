import { colors } from "@/helpers";
import { Select, Space } from "antd";
import styled from "styled-components";

const StyledButtons = styled(Space)``;

const StyledSelectField = styled(Select)<{ color: string }>`
  width: 100%;
  color: ${({ color }) => (color ? color : colors.gray100)};
`;

const StyledTitle = styled.div`
  border-bottom: 1px solid #eaeaef;
  padding: 24px 0 17px 0;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const StyledParagraph = styled.div`
    display: flex;
    justify-items: center;
    justify-content: center;
`;

const StyledInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMessage = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
`;

export {
  StyledTitle,
  StyledButtons,
  StyledSelectField,
  StyledInfoBlock,
  StyledMessage,
  StyledParagraph,
};
