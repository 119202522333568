import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../../axiosInstance";
import {
  IGetFlexCalendarRequest,
  TExportFlexArchiveRequest,
  TExportFlexRentStatusRequest,
  TFlexCreateRentIdleRequest,
  TFlexCreateRentRequest,
  TFlexExistCreateRequest,
  TFlexExistCreateResponse,
  TFlexGetRentCostRequest,
  TFlexGetRentCostResponse,
  TFlexOrdersCarCreateRequest,
  TFlexOrdersCarCreateResponse,
  TFlexPatchRentRequest,
  TFlexPatchRequset,
  TFlexPatchResponse,
  TGetFlexArchivedVehicleStatusResponse,
  TGetFlexArchivesRequest,
  TGetFlexArchivesResponse,
  TGetFlexCalendarResponse,
  TGetFlexRequest,
  TGetFlexRequestById,
  TGetFlexResponse,
  TGetFlexResponseById,
  TGetFlexVehicleStatusRequest,
  TGetFlexVehicleStatusResponse,
} from "./types";
import { TGetFlexArchivedVehicleStatusRequest } from "@/helpers/api/flex/vehicles/types";

export const useGetFlexList = (
  request: TGetFlexRequest,
  isEnabled: boolean
) => {
  return useQuery(
    ["flex", "list", request.query, request],
    () => {
      return api.get<TGetFlexResponse>("/flex/vehicles", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetFlexById = (id: TGetFlexRequestById) => {
  return useQuery(["flex", "detail", id], () => {
    return api.get<TGetFlexResponseById>(`/flex/vehicles/${id}`);
  });
};

export const useCreateFlexExist = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFlexExistCreateRequest) => {
      return api.post<TFlexExistCreateResponse>("/flex/vehicles", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flex", "list"]);
      },
    }
  );
};

export const useCreateOrdersCarFlexExist = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFlexOrdersCarCreateRequest) => {
      return api.post<TFlexOrdersCarCreateResponse>("/flex/vehicles/new", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flex/new", "list"]);
        queryClient.invalidateQueries(["orders", "list"]);
      },
    }
  );
};

export const usePatchFlex = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TFlexPatchRequset) => {
      return api.patch<TFlexPatchResponse>(`/flex/vehicles/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["flex", "list"]);
        queryClient.invalidateQueries(["flex/archives", "list"]);

        queryClient.invalidateQueries(["flex", "detail", variables.id]);
      },
    }
  );
};

export const useGetFlexArchivesList = (
  request: TGetFlexArchivesRequest,
  isEnabled: boolean
) => {
  return useQuery(
    ["flex/archives", "list", request],
    () => {
      return api.get<TGetFlexArchivesResponse>("/flex/vehicles/archives", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetFlexWithStatusList = (
  request: TGetFlexVehicleStatusRequest
) => {
  return useQuery(["flex", "list", request], () => {
    return api.get<TGetFlexVehicleStatusResponse>("/flex/vehicles/status-sum", {
      params: request,
    });
  });
};

export const useGetArchivedFlexWithStatusList = (
  request: TGetFlexArchivedVehicleStatusRequest
) => {
  return useQuery(["flex/arch", "list", request], () => {
    return api.get<TGetFlexArchivedVehicleStatusResponse>(
      "/flex/vehicles/archived-summary",
      {
        params: request,
      }
    );
  });
};

export const useGetFlexCalendar = (request: IGetFlexCalendarRequest) => {
  return useQuery(["flex", "calendar", request], () => {
    return api.get<TGetFlexCalendarResponse>("/flex/rent/calendar", {
      params: request,
    });
  });
};

export const useCreateRent = () => {
  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TFlexCreateRentRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<any>("/flex/rent", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCreateRentIdle = () => {
  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TFlexCreateRentIdleRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<any>("/flex/rent/idle", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetRentById = (request: any) => {
  return useQuery(["rentById", request], () => {
    return api.get<any>(`/flex/rent/${request.id}`);
  });
};

export const usePatchRent = () => {
  return useMutation(
    ({
      id,
      patch,
    }: {
      id: TFlexPatchRentRequest["id"];
      patch: TFlexPatchRentRequest["patch"];
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<any>(`/flex/rent/${id}`, patch);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetAvailableVehicles = (request: any) => {
  return useQuery(["flex", "available-vehicles", request], () => {
    return api.get<any>("/flex/rent/available", {
      params: request,
    });
  });
};

export const useGetIdleList = (request: any) => {
  return useQuery(["flex", "idle-list", request], () => {
    return api.get<any>("/flex/idle", {
      params: request,
    });
  });
};

export const useGetRentCosts = ({
  request,
}: {
  request: TFlexGetRentCostRequest;
}) => {
  return useQuery(
    ["flex", "costs", request],
    () => {
      return api.get<TFlexGetRentCostResponse>("/flex/rent/costs", {
        params: request,
      });
    },
    {
      enabled: !!request.from && !!request.to && !!request.generationId,
    }
  );
};

export const useExportFlexVehicles = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/flex/vehicles/export`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportFlexArchivedVehicles = () => {
  return useMutation((request: TExportFlexArchiveRequest) => {
    return api.get<any>(`/flex/vehicles/archives/export`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportRentStatus = () => {
  return useMutation((request: TExportFlexRentStatusRequest) => {
    return api.get<any>(`/flex/vehicles/export`, {
      responseType: "blob",
    });
  });
};
