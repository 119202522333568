import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export interface ITransportComFilterFormValues {
  vin: string | null;
  codeCase: string | null;
  placeAcceptence: string | null;
  dateAcceptanceStart: Date | null;
  dateAcceptanceEnd: Date | null;
  updatedAtStart: Date | null;
  updatedAtEnd: Date | null;
}

export const DEFAULT_TRANSPORT_FILTER_FORM_VALUES: ITransportComFilterFormValues =
  {
    vin: null,
    codeCase: null,
    placeAcceptence: null,
    dateAcceptanceStart: null,
    dateAcceptanceEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
  };

export type TOnSubmitTransportComFilterFormCb = (
  values: ITransportComFilterFormValues
) => void;

export const useTransportComFilterForm = (options: {
  onFilterChange: TOnSubmitTransportComFilterFormCb;
  initValues: Partial<ITransportComFilterFormValues>;
}) => {
  const { initValues, onFilterChange } = options;

  const result = useForm<ITransportComFilterFormValues>({
    defaultValues: DEFAULT_TRANSPORT_FILTER_FORM_VALUES,
    onSubmit: onFilterChange,
  });

  useEffect(() => {
    result.form.reset(initValues, { keepDefaultValues: true });
  }, []);

  return result;
};
