import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledSellsFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.gray20};
`;

export const StyledFormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px 32px;
  flex-wrap: wrap;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
