import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled, { css } from "styled-components";

export type TLoaderSize = 8 | 24 | 48 | 64;

interface ILoader {
  className?: string;
  size?: TLoaderSize;
  isCentered?: boolean;
}

const StyledLoader = styled.div<{ isCentered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isCentered }) =>
          isCentered &&
          css`
            margin: 0 auto;
          `}

  `;

const Loader: FC<ILoader> = (props) => {
  const { className, size = 24, isCentered } = props;

  return (
    <StyledLoader className={className} isCentered={isCentered}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: size }} spin={true} />}
      />
    </StyledLoader>
  );
};

export { Loader };
