import React from "react";
import {
  StyledFieldContainer,
  StyledFieldControlContainer,
  StyledFieldDescription,
  StyledFieldError,
  StyledFieldLabel,
} from "./index.styles";

export interface IFieldLabel {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
}

const FieldLabel: React.FC<IFieldLabel> = ({
  className,
  children,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <StyledFieldLabel className={className} disabled={disabled} readOnly={readOnly}>
      {children}
    </StyledFieldLabel>
  );
};

export interface IFieldControlWrapper {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const FieldControlContainer: React.FC<IFieldControlWrapper> = ({
  className,
  disabled,
  children,
}) => {
  return (
    <StyledFieldControlContainer disabled={disabled} className={className}>
      {children}
    </StyledFieldControlContainer>
  );
};

export interface IFieldError {
  children?: React.ReactNode;
  className?: string;
  isErrorAbsolute?: boolean;
}

const FieldError: React.FC<IFieldError> = ({
  className,
  isErrorAbsolute,
  children,
}) => {
  return (
    <StyledFieldError className={className} isErrorAbsolute={!!isErrorAbsolute}>
      {children}
    </StyledFieldError>
  );
};

export interface IFieldDescription {
  children?: React.ReactNode;
  className?: string;
}

const FieldDescription: React.FC<IFieldDescription> = ({
  className,
  children,
}) => {
  return (
    <StyledFieldDescription className={className}>
      {children}
    </StyledFieldDescription>
  );
};

export type IField = {
  className?: string;
  children?: React.ReactNode;
  isBlock?: boolean;
};

const Field: React.FC<IField> & {
  Label: typeof FieldLabel;
  FieldControlContainer: typeof FieldControlContainer;
  FieldError: typeof FieldError;
  FieldDescription: typeof FieldDescription;
} = (props) => {
  const { className, children, isBlock } = props;
  return (
    <StyledFieldContainer className={className} isBlock={isBlock}>
      {children}
    </StyledFieldContainer>
  );
};

Field.Label = FieldLabel;
Field.FieldControlContainer = FieldControlContainer;
Field.FieldError = FieldError;
Field.FieldDescription = FieldDescription;

export { Field };
