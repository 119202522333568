import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { OnSubmitFormFirstStepCallback, useRegistrationFormFirstStep, } from "./hooks";
import { FormCountrySelect } from "@/packages/formElements/formFields/FormCountrySelect";
import { FormDealersSelect } from "@/packages/formElements/formFields/FormDealersSelect";
import { FormRoleSelect } from "@/packages/formElements/formFields/FormRoleSelect";
import { StyledFormLayout, StyledSubmitButton } from "../index.styles";
import { useTranslation } from "react-i18next";
import { useGetDealerList, useGetPublicDealerList } from "@/helpers/api/dc/dealers/hooks";

export type RegistrationFormFirstStepProps = {
  onSubmit: OnSubmitFormFirstStepCallback;
};

export const RegistrationFormFirstStep: React.FC<
  RegistrationFormFirstStepProps
> = (props) => {
  const { onSubmit } = props;
  const { form, onSubmitHandler } = useRegistrationFormFirstStep(onSubmit);
  const { t } = useTranslation();

  const [searchValueDealerList, setSearchValueDealerList] = useState("");
  const { data: dealerList } = useGetDealerList({
    name: searchValueDealerList ? searchValueDealerList : undefined,
  });
  const { data: publicDealerList } = useGetPublicDealerList({
    name: searchValueDealerList ? searchValueDealerList : undefined,
  });

  const [isShowHoldingRoles, setIsShowHoldingRoles] = useState<boolean>(true)

  useEffect(() => {
    const holdingIdDealerList = dealerList?.data?.data.find((value) => value.id === form?.watch('dealersId'))?.holdingId
    const holdingIdPublicDealerList = publicDealerList?.data?.data.find((value) => value.id === form?.watch('dealersId'))?.holdingId
    setIsShowHoldingRoles(!!(holdingIdDealerList || holdingIdPublicDealerList))
  }, [form?.watch('dealersId')]);

  return (
    <FormProvider {...form}>
      <StyledFormLayout>
        <FormCountrySelect
          name="country"
          label={t("authorization.country") || ""}
          placeholder={t("authorization.countryPlaceholder") || ""}
        />
        <FormDealersSelect
          name="dealersId"
          label={t("authorization.dealership") || ""}
          placeholder={t("authorization.dealershipPlaceholder") || ""}
          showSearch
          dealerList={dealerList?.data?.data || publicDealerList?.data.data}
          setSearchValueDealerList={setSearchValueDealerList}
        />
        <FormRoleSelect
          name="postId"
          label={t("authorization.post") || ""}
          placeholder={t("authorization.postPlaceholder") || ""}
          showSearch
          isShowHoldingRoles={isShowHoldingRoles}
        />
      </StyledFormLayout>

      <StyledSubmitButton
        isBlock={true}
        theme="primary"
        color="brand"
        size="middle"
        onClick={onSubmitHandler}
      >
        {t("authorization.continue") || ""}
      </StyledSubmitButton>
    </FormProvider>
  );
};
