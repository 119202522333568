import React from "react";

import {
  ISelectField,
  SelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetEngineVolumeList } from "@/helpers/api/engineVolumes/hooks";

export interface IEngineVolumeSelectFieldAdapter
  extends Omit<ISelectField, "options"> {}

export const EngineVolumeSelectFieldAdapter: React.FC<
  IEngineVolumeSelectFieldAdapter
> = (props) => {
  const { data: getEngineVolumeListResponse, isLoading } =
    useGetEngineVolumeList({
      page: 1,
      limit: 0,
    });
  const options =
    getEngineVolumeListResponse?.data.data
      .map<ISelectOption>((fuelRecord) => ({
        value: fuelRecord.id,
        label: fuelRecord.name.toString(),
      }))
      .sort((a, b) => Number(a.label) - Number(b.label)) || [];

  return <SelectField options={options} loading={isLoading} {...props} />;
};
