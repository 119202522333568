import { Icon } from "@/packages/icon/Icon";
import styled from "styled-components";
import identityLayoutBackground from "@/assets/images/login_bg.png";
import { media } from "@/helpers";

export const StyledIdentityLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-image: url(${identityLayoutBackground});
  background-size: cover;
  overflow: hidden;
  position: relative;
`;

export const StyledFixedContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const StyledBackgroundImage = styled.img`
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledScrollableContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const IdentityContentContainer = styled.div`
  ${media.tablet} {
    max-width: 1440px;
    margin: 0 auto;
    padding: 64px 100px;
  }
`;

export const IdentityContentLogo = styled(Icon)``;

export const IdentityContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-height: 100%;
  ${media.tablet} {
    padding-top: 134px;
  }
  ${media.desktop} {
    align-items: flex-end;

    padding-left: 60px;
  }
`;
