import { TGenerationRecord } from "@/helpers/api/generations/types";
import { TModelRecord } from "@/helpers/api/models/types";
import { TModificationRecord } from "@/helpers/api/modifications/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type ModelMaintenanceGrids = {
  model: TModelRecord | null;
  generation: TGenerationRecord | null;
  equipments: TModificationRecord[];
};

export type CreateMaintenanceGridsFormValues = {
  model: ModelMaintenanceGrids;
  modelDuplicate?: ModelMaintenanceGrids | null;
  isWorksDefault?: boolean;
  isGsmDefault?: boolean;
};

export type CreateMaintenanceGridsSuccessFormValues =
  CreateMaintenanceGridsFormValues;

export const DEFAULT_MODEL_FIELD_FORM = {
  model: null,
  generation: null,
  equipments: [],
};

export const DEFAULT_CREATE_MAINTENANCE_GRIDS_FORM_VALUES: CreateMaintenanceGridsFormValues =
  {
    model: DEFAULT_MODEL_FIELD_FORM,
    modelDuplicate: null,
    isWorksDefault: false,
    isGsmDefault: false,
  };

export const formSchema = () => {
  return yup
    .object({
      model: yup.object({
        model: yup
          .object()
          .required(
            t("maintenanceGrids.newGridForm.validation.modelChoose") || ""
          )
          .nullable(),
        generation: yup
          .object()
          .required(
            t("maintenanceGrids.newGridForm.validation.generationChoose") || ""
          )
          .nullable(),
        equipments: yup
          .array()
          .when(".length", {
            is: 0,
            then: (s) => s.strip(),
          })
          .required(
            t("maintenanceGrids.newGridForm.validation.modificationChoose") ||
              ""
          )
          .nullable(),
      }),
      modelDuplicate: yup
        .object({
          model: yup
            .object()
            .required(
              t("maintenanceGrids.newGridForm.validation.modelChoose") || ""
            )
            .nullable(),
          generation: yup
            .object()
            .required(
              t("maintenanceGrids.newGridForm.validation.generationChoose") ||
                ""
            )
            .nullable(),
          equipments: yup
            .array()
            .when(".length", {
              is: 0,
              then: (s) => s.strip(),
            })
            .required(
              t("maintenanceGrids.newGridForm.validation.modificationChoose") ||
                ""
            )
            .nullable(),
        })
        .nullable(),
      isWorksDefault: yup.boolean(),
      isGsmDefault: yup.boolean(),
    })
    .required();
};

export type TOnSubmitCreateMaintenanceGridsFormCallback = (
  values: CreateMaintenanceGridsSuccessFormValues
) => void;

export const useCreateMaintenanceGridsForm = (
  onSubmit: TOnSubmitCreateMaintenanceGridsFormCallback
) => {
  const form = useForm<CreateMaintenanceGridsFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_CREATE_MAINTENANCE_GRIDS_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as CreateMaintenanceGridsSuccessFormValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};
