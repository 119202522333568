import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { InputField } from "@/packages/formElements/fields/InputField";
import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { TEngineVolumeForm, ENGINE_VOLUME_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IEngineVolumeCreateModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (volume: TEngineVolumeForm) => void;
  isLoadingSubmit: boolean;
}

export const EngineVolumeCreateModal: React.FC<IEngineVolumeCreateModal> = (
  props
) => {
  const { onSubmit, isLoadingSubmit, ...restProps } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState<TEngineVolumeForm>(
    ENGINE_VOLUME_INITIAL_FORM
  );

  const engineVolumeError = !form.engineVolume && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleVolumeChange = (value: number | null) =>
    value &&
    setForm((prev) => ({
      ...prev,
      engineVolume: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!Object.values(form).every(Boolean) || !(form.engineVolume > 0)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit(form);
  };

  useEffect(() => {
    if (!restProps.isOpen) {
      setForm(ENGINE_VOLUME_INITIAL_FORM);
    }
  }, [restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
      title={t("parts.addEngineCapacityType") || ""}
      confirmButtonText={t("parts.create") || ""}
    >
      <StyledModalFormContainer>
        <InputNumberField
          label={t("parts.engineCapacity") || ""}
          description={t("parts.engineCapacityEnter") || ""}
          value={form.engineVolume}
          error={engineVolumeError}
          onChange={handleVolumeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
