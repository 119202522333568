import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledModalContentWrapper, StyledTitle } from "./index.styles";
import { useFormValues } from "./hooks";

import { Title } from "@/packages/title/Title";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import {
  preparedDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";

import { useGetWarrantyAuditTemplateList } from "@/helpers/api/warranty-audit/templates/hooks";
import { useCreateWarrantyAudit } from "@/helpers/api/warranty-audit/audit-list/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

type ICreateAuditModal = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadAuditTemplateBase {
  onSubmit: () => void;
}

export const CreateWarrantyAuditModal: React.FC<
  ICreateAuditModal & IUploadAuditTemplateBase
> = (props) => {
  const { onSubmit, onCancel } = props;
  const [searchValue, setSearchValue] = useState({ dealer: "", template: "" });
  const { createNotificationError } = useNotification();

  const { mutate: createAudit, isLoading } = useCreateWarrantyAudit();
  const { data: dealerList} =
    useGetFilteredDealerList({ q: searchValue.dealer, limit: 100 });
  const { data: templates, isLoading: isTemplatesLoading } =
    useGetWarrantyAuditTemplateList({
      "filter[title]": searchValue.template,
      "filter[active]": true,
    });

  const onFormChange = (value) => {
    createAudit({
      data: { ...value, templateId: +value.templateId },
      onSuccess: handleSubmit,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const { form, onSubmitHandler, setDefaultValue, resetValues } =
    useFormValues(onFormChange);

  const handleSubmit = () => {
    resetValues();
    onSubmit();
  };

  const preparedTemplates = useMemo(
    () =>
      templates?.data.data?.map((item) => ({
        label: item.title,
        value: +item.id,
      })) || [],
    [templates?.data?.data, isTemplatesLoading]
  );
  const { t } = useTranslation();

  useEffect(() => {
    setSearchValue({ dealer: "", template: "" });
  }, []);

  const onCancelHandler = () => {
    form.reset();
    setSearchValue({ dealer: "", template: "" });
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <Modal
      {...props}
      title={t("audits.list.createAudit") || ""}
      size="small"
      isLoadingConfirmButton={isLoading}
      cancelButtonText={t("audits.list.cancel") || ""}
      confirmButtonText={t("audits.list.createAudit_2") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancelHandler}
    >
      <StyledModalContentWrapper>
        <StyledTitle>
          <Title level={3} weight={400}>
            {t("audits.list.createAudit") || ""}
          </Title>
        </StyledTitle>
        <FormProvider {...form}>
          <FormInputField
            name="title"
            label={t("audits.list.name") || ""}
            placeholder={t("audits.list.nameDescr") || ""}
          />
          <FormSelect
            name="dealerId"
            label={t("audits.list.dealerId_2") || ""}
            placeholder={t("audits.list.dealerIdDescr") || ""}
            options={preparedDealerList(dealerList?.data?.data || []) || []}
            isSearchShow={true}
            onSearch={(value) => {
              setSearchValue((prev) => ({ ...prev, dealer: value }));
            }}
          />
          <FormSelect
            name="templateId"
            label={t("audits.list.templateId") || ""}
            placeholder={t("audits.list.templateId") || ""}
            options={preparedTemplates || []}
            isSearchShow={true}
            onSearch={(value) =>
              setSearchValue((prev) => ({ ...prev, template: value }))
            }
          />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
