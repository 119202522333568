import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type RightsDelegationFormValues = {
  employee: string;
  delegationDateStart: Date;
  delegationDateEnd: Date;
};

const DEFAULT_RIGHTS_DELEGATION_FORM_VALUES: RightsDelegationFormValues = {
  employee: "",
  delegationDateStart: new Date(),
  delegationDateEnd: new Date(),
};

export type OnSubmitRightsDelegationFormHandler = (
  data: RightsDelegationFormValues
) => void;

export const formSchema = yup.object({
  employee: yup.string().min(1, "Необходимо выбрать сотрудника"),
  delegationDateStart: yup
    .date()
    .nullable()
    .min(
      new Date(Date.now() - 86400000),
      "Некорректная дата начала делегирования прав"
    )
    .required("Необходимо выбрать дату начала делегирования прав"),
  delegationDateEnd: yup
    .date()
    .nullable()
    .min(
      yup.ref("delegationDateStart"),
      "Дата окончания должна быть больше даты начала делегирования прав"
    )
    .required("Необходимо выбрать дату окончания делегирования прав"),
});

export const useRightsDelegationForm = (
  onSubmit: OnSubmitRightsDelegationFormHandler
) => {
  const form = useForm<RightsDelegationFormValues>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: DEFAULT_RIGHTS_DELEGATION_FORM_VALUES,
  });

  const onSubmitButtonClickHandler = form.handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return { form, onSubmitButtonClickHandler };
};
