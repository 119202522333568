export const useFirstSymbolAvatar = (name?: string) => {
  if (!name) {
    return "";
  } else
    return name.match(/(?:^|\P{L})\p{L}/gu)!
      ? name
          .match(/(?:^|\P{L})\p{L}/gu)!
          .join("")
          .toUpperCase()
      : name.match(/(?:^|\P{L})\p{L}/gu)!;
};
