import React from "react";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { SortOrder } from "@/packages/tableSimple/TableSimple";
import { TCarData } from "@/pages/KiaFlexCars";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { Headers } from "./Headers";
import { StyledTable, TableHeader } from "./index.styles";
import { useGetArchivedFlexWithStatusList } from "@/helpers/api/flex/vehicles/hooks";

export interface IKiaFlexCarArchiveTableProps {
  headerInfo?: { status: string; count: string }[];
  data?: TCarData[];
  onSort: (accessor: string, order: SortOrder) => void;
  onSearch: (value: string) => void;
  searchValue: string;
}

export const KiaFlexCarArchiveTable: React.FC<IKiaFlexCarArchiveTableProps> = (
  props
) => {
  const { data: archivedStatus } = useGetArchivedFlexWithStatusList({});

  const { headerInfo, data, onSort, onSearch, searchValue } = props;
  const rentCars = headerInfo?.filter((elem) => elem.status === "rent");
  const idleCars = headerInfo?.filter((elem) => elem.status === "idle");
  const pendingCars = headerInfo?.filter((elem) => elem.status === "pending");
  // @ts-ignore
  const archivedCars = archivedStatus?.data?.archived;
  const { t } = useTranslation();

  return (
    <>
      <>
        <TableHeader>
          <Space direction="vertical">
            <Paragraph size={16}>{t("kiaFlex.autoArchive") || ""}</Paragraph>
            <Paragraph size={12} color="gray400">{`${t("kiaFlex.allCards") || ""} ${
              rentCars?.[0]?.count || 0
            } ${t("kiaFlex.active") || ""}, ${idleCars?.[0]?.count || 0} ${
              t("kiaFlex.downtimeIn") || ""
            }, ${pendingCars?.[0]?.count || 0} ${t("kiaFlex.inWaiting") || ""}, ${
              archivedCars || 0
            } ${t("kiaFlex.archived") || ""}`}</Paragraph>
          </Space>

          <Search
            value={searchValue}
            onChange={(value) => {
              onSearch(value || "");
            }}
          />
        </TableHeader>

        <StyledTable
          variant="secondary"
          headers={Headers()}
          data={data}
          onSort={onSort}
        />
      </>
    </>
  );
};
