import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface ITemplateValues {
  title?: string;
  templateId?: number;
  dealerId?: string;
}

export const DEFAULT_FORM_VALUES: ITemplateValues = {};

export const formSchema = () => {
  return yup.object({
    title: yup
      .string()
      .required(t("audits.templates.validation.name") || "")
      .max(90, t("audits.templates.validation.max_90") || ""),
    templateId: yup
      .string()
      .required(t("audits.templates.validation.template") || ""),
    dealerId: yup
      .string()
      .required(t("audits.templates.validation.dealer") || ""),
  });
};

export type TOnSubmitFormCb = (values: ITemplateValues) => void;

export const useFormValues = (onSubmit: TOnSubmitFormCb) => {
  const form = useForm<ITemplateValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const setDefaultValue = (value: ITemplateValues) => {
    Object.entries(value).forEach(([key, value]) =>
      form.setValue(key as keyof ITemplateValues, value)
    );
  };

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  const resetValues = () => {
    form.reset();
  };

  return {
    form,
    setDefaultValue,
    onSubmitHandler,
    onClearHandler,
    resetValues,
  };
};
