import React, { FC, useState, useContext } from 'react';
import { StyledTitleWrapper } from '../index.styles';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import {
    StyledButton,
    StyledContent,
    StyledDivider,
    StyledHeader,
    StyledRadioContent,
    StyledTitle,
} from './index.styles';
import { Radio } from '@/packages/radio/Radio';
import { RadioGroup } from '@/packages/radio-group/RadioGroup';
import { FormProvider } from 'react-hook-form';
import { FirstExportType } from '@/components/MaintenanceGrids/ExportTab/FirstExportType';
import { SecondExportType } from '@/components/MaintenanceGrids/ExportTab/SecondExportType';
import { ThirdExportType } from '@/components/MaintenanceGrids/ExportTab/ThirdExportType';
import { FourthExportType } from '@/components/MaintenanceGrids/ExportTab/FourthExportType';
import { FiveExportType } from '@/components/MaintenanceGrids/ExportTab/FiveExportType';
import {
    useExportGrids_1,
    useExportGrids_2,
    useExportGrids_3,
    useExportGrids_4,
    useExportGrids_5,
} from '@/helpers/api/maintenanceGrids/exports/hooks';
import { handleFileLoad } from '@/helpers/utils';
import { useCreateExportForm } from '@/components/MaintenanceGrids/ExportTab/hooks';
import { ContextProfile } from '@/hooks/contexts/useProfile';

export enum ExportTypes {
    WORKS_SORT = 'Сортировка по типам работ',
    TABLE_TIME = 'Сводные табличные данные по времени',
    TABLE_APPLICABILITY = 'Сводные табличные данные по применяемости',
    DOWNLOAD_SPARES = 'Выгрузка об используемых запчастях',
    SORT_MODELS = 'Сортировка моделей по всем имеющимся комплектациям',
}

const ExportTab: FC<any> = () => {
    const { t } = useTranslation();

    const [ selectedType, setSelectedType ] = useState('0');

    const onSubmitButtonClick = (data: any) => {
        if (
            selectedType === ExportTypes.WORKS_SORT
        ) {
            handleSortWorkType({
                'filter[generations]': data?.generation,
                'filter[works]': data?.work,
                'filter[materials]': data?.gsm,
            });
        }

        if (selectedType === ExportTypes.TABLE_TIME) {
            handleExportWorkSpareMaterialPrice({
                'filter[generations]': data?.secondGeneration,
            });
        }
        if (
            selectedType === ExportTypes.TABLE_APPLICABILITY
        ) {
            handleExportReplacementSpare({
                'filter[generations]': data?.thirdGeneration,
                'filter[spares]': data?.spareCode,
            });
        }
        if (selectedType === ExportTypes.DOWNLOAD_SPARES) {
            handleExportUsedSpares({
                'filter[generations]': data?.fourthGeneration,
            });
        }
        if (selectedType === ExportTypes.SORT_MODELS) {
            handleExportSortModels({
                'filter[generations]': data?.fiveGeneration,
            });
        }
        console.log(data);
    };
    const { form, onSubmitHandler } = useCreateExportForm(onSubmitButtonClick);

    const { mutateAsync: exportSortWorkType, isLoading: loadingGrids_1 } =
        useExportGrids_1();
    const {
        mutateAsync: exportWorkSpareMaterialPrice,
        isLoading: loadingGrids_2,
    } = useExportGrids_2();
    const { mutateAsync: exportReplacementSpare, isLoading: loadingGrids_3 } =
        useExportGrids_3();
    const { mutateAsync: exportUsedSpares, isLoading: loadingGrids_4 } =
        useExportGrids_4();
    const { mutateAsync: exportSortModels, isLoading: loadingGrids_5 } =
        useExportGrids_5();

    const handleSortWorkType = (filters) => {
        exportSortWorkType(filters, {
            onSuccess: (response) => {
                handleFileLoad(response.data as Blob, 'SortWorkType.xlsx');
            },
        });
    };

    const handleExportWorkSpareMaterialPrice = (filters) => {
        exportWorkSpareMaterialPrice(filters, {
            onSuccess: (response) => {
                handleFileLoad(response.data as Blob, 'WorkSpareMaterialPrice.xlsx');
            },
        });
    };

    const handleExportReplacementSpare = (filters) => {
        exportReplacementSpare(filters, {
            onSuccess: (response) => {
                handleFileLoad(response.data as Blob, 'ReplacementSpare.xlsx');
            },
        });
    };

    const handleExportUsedSpares = (filters) => {
        exportUsedSpares(filters, {
            onSuccess: (response) => {
                handleFileLoad(response.data as Blob, 'UsedSpares.xlsx');
            },
        });
    };

    const handleExportSortModels = (filters) => {
        exportSortModels(filters, {
            onSuccess: (response) => {
                handleFileLoad(response.data as Blob, 'SortModels.xlsx');
            },
        });
    };

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const isDealerType = profile?.type === 'dealer';

    return (
        <StyledContent>
            <FormProvider {...form}>
                <StyledHeader>
                    <StyledTitleWrapper>
                        <StyledTitle>
                            <Paragraph size={16} color='black'>
                                {t('maintenanceGrids.exportTitle') || ''}
                            </Paragraph>
                        </StyledTitle>
                    </StyledTitleWrapper>
                    <RadioGroup
                        isColumn={true}
                        defaultValue={selectedType}
                        onChange={(value) => setSelectedType(value as any)}
                    >
                        <>
                            {
                                !isDealerType &&
                                <>
                                    <Radio
                                        groupValue={ExportTypes.WORKS_SORT}
                                        label={t('maintenanceGrids.exportRadio_1') || ''}
                                    />
                                </>
                            }
                        </>
                        <Radio
                            groupValue={ExportTypes.TABLE_TIME}
                            label={t('maintenanceGrids.exportRadio_2') || ''}
                        />
                        <Radio
                            groupValue={ExportTypes.TABLE_APPLICABILITY}
                            label={t('maintenanceGrids.exportRadio_3') || ''}
                        />
                        <Radio
                            groupValue={ExportTypes.DOWNLOAD_SPARES}
                            label={t('maintenanceGrids.exportRadio_4') || ''}
                        />
                        <>
                            {
                                !isDealerType &&
                                <>
                                    <Radio
                                        groupValue={ExportTypes.SORT_MODELS}
                                        label={t('maintenanceGrids.exportRadio_5') || ''}
                                    />
                                </>

                            }
                        </>
                    </RadioGroup>
                </StyledHeader>
                <StyledRadioContent>
                    {selectedType !== '0' && <StyledDivider/>}
                    {selectedType === ExportTypes.WORKS_SORT && <FirstExportType/>}
                    {selectedType === ExportTypes.TABLE_TIME && <SecondExportType/>}
                    {selectedType === ExportTypes.TABLE_APPLICABILITY && (
                        <ThirdExportType/>
                    )}
                    {selectedType === ExportTypes.DOWNLOAD_SPARES && <FourthExportType/>}
                    {selectedType === ExportTypes.SORT_MODELS && <FiveExportType/>}
                </StyledRadioContent>
            </FormProvider>
            <StyledButton
                color='brand'
                theme='primary'
                size='middle'
                onClick={onSubmitHandler}
                isDisabled={
                    selectedType === '0' ||
                    loadingGrids_1 ||
                    loadingGrids_2 ||
                    loadingGrids_3 ||
                    loadingGrids_4 ||
                    loadingGrids_5
                }
            >
                {'Экспорт'}
            </StyledButton>
        </StyledContent>
    );
};

export { ExportTab };
