import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { memo } from "react";
import {
  StyledBodyWrapper,
  StyledCard,
  StyledHeaderWrapper,
  StyledLineDivider,
  StyledTitle,
} from "./index.styles";

export interface ITransportComplaintsDetailCardProps {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  textMode?: boolean;
}

const _TransportComplaintsDetailCard: React.FC<
  ITransportComplaintsDetailCardProps
> = (props) => {
  const { children, subTitle, title, textMode } = props;
  return (
    <StyledCard>
      <StyledHeaderWrapper textMode={textMode}>
        <StyledTitle textMode={textMode} size={14}>
          {title}
        </StyledTitle>
        {subTitle && (
          <Paragraph size={12} color="gray400">
            {subTitle}
          </Paragraph>
        )}
      </StyledHeaderWrapper>
      <StyledLineDivider textMode={textMode} />
      <StyledBodyWrapper>{children}</StyledBodyWrapper>
    </StyledCard>
  );
};

export const TransportComplaintsDetailCard = memo(
  _TransportComplaintsDetailCard
);
