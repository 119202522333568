import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { requiredSelectSimpleValueSchema } from "@/helpers/validation";
import { t } from "i18next";
import { getClearHoursTodayDate } from "@/helpers/date";

export type Nullable<T> = T | null;
export interface IRentFormValues {
  event: "rent" | "idle";
  from: Date;
  to: Nullable<Date>;
  dealerId: Nullable<string>;
  vehicleId: Nullable<string>;
  idleId: Nullable<string>;
  total: Nullable<number>;
}

export const DEFAULT_RENT_FORM_VALUES: IRentFormValues = {
  event: "rent",
  from: getClearHoursTodayDate(),
  to: null,
  dealerId: null,
  vehicleId: null,
  idleId: null,
  total: 0,
};

export const formSchema = (isRent: boolean | undefined) => {
  return yup.object({
    from: yup
      .date()
      .nullable()
      .required(t("kiaFlex.downtimeRequiredStart") || ""),
    to: yup
      .date()
      .nullable()
      .required(t("kiaFlex.downtimeRequiredEnd") || ""),
    dealerId: requiredSelectSimpleValueSchema(t("kiaFlex.dealerRequired") || ""),
    vehicleId: requiredSelectSimpleValueSchema(t("kiaFlex.autoRequired") || ""),
    idleId: isRent
      ? yup.string().nullable()
      : yup
          .string()
          .required(t("kiaFlex.reasonRequired") || "")
          .nullable(),
  });
};

export type TOnSubmitRentFormCb = (values: IRentFormValues) => void;

export const useRentForm = (
  onSubmit: TOnSubmitRentFormCb,
  defaultValues?: any,
  isRent?: boolean
) => {
  const form = useForm<IRentFormValues>({
    resolver: yupResolver(formSchema(isRent)),
    mode: "onChange",
    defaultValues: defaultValues
      ? {
          event: defaultValues.event
            ? defaultValues.event
            : DEFAULT_RENT_FORM_VALUES.event,
          from: defaultValues.from
            ? new Date(defaultValues.from)
            : DEFAULT_RENT_FORM_VALUES.from,
          to: defaultValues.to ? new Date(defaultValues.to) : null,
          dealerId:
            defaultValues?.vehicle?.dealer?.id ||
            defaultValues.dealerId ||
            null,
          vehicleId:
            defaultValues.vehicle?.id || defaultValues.vehicleId || null,
          idleId: defaultValues.idleReason
            ? defaultValues.idleReason?.id
            : null,
        }
      : DEFAULT_RENT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
  };
};
