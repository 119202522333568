import { TCountry } from "@/helpers/api/country/type";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type RegistrationFirstStepFormValues = {
  country: TCountry | null;
  dealersId: string | null;
  postId: string | null;
};

export const DEFAULT_REGISTRATION_FORM_FIRST_STEP_VALUES: RegistrationFirstStepFormValues =
  {
    country: null,
    dealersId: null,
    postId: null,
  };
export type RegistrationFirstStepFormSuccessValues = {
  country: TCountry;
  dealersId: string;
  postId: string;
};

export const formSchema = () => {
  return yup
    .object({
      country: yup
        .string()
        .nullable()
        .required(t("authorization.validation.country") || ""),
      dealersId: yup
        .string()
        .nullable()
        .required(t("authorization.validation.dealership") || ""),
      postId: yup
        .string()
        .nullable()
        .required(t("authorization.validation.position") || ""),
    })
    .required();
};

export type OnSubmitFormFirstStepCallback = (
  values: RegistrationFirstStepFormSuccessValues
) => void;

export const useRegistrationFormFirstStep = (
  onSubmit: OnSubmitFormFirstStepCallback
) => {
  const form = useForm<RegistrationFirstStepFormValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_REGISTRATION_FORM_FIRST_STEP_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as RegistrationFirstStepFormSuccessValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};
