import React, { useEffect, useState } from "react";
import { DealerBonusesFilter } from "./DealerBonusesFilter";
import { StyledHR, StyledTab } from "./index.styles";

import {
  useEditBonusesParams,
  useGetBonusesCalculation,
} from "@/helpers/api/bonuses/calculations/hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRequestParams } from "@/hooks/useRequestParams";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { TGetBonusesCalculationsRequest } from "@/helpers/api/bonuses/calculations/types";
import { usePagination } from "@/hooks/usePagination";
import { IDealerBonusesFilterFormValues } from "./DealerBonusesFilter/hooks";
import { BonusesModal } from "./BonusesModal";
import {
  useExportDealerBonuses,
  useGetBonusesReports,
} from "@/helpers/api/bonuses/reports-import/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useModal } from "@/hooks/useModal";

import { DealerBonusesTitleHeader } from "./DealerBonusesTitleHeader";
import { BonusesTableAdapter } from "../BonusesTableAdapter";
import { getBonusesTableHeaders } from "./hooks";
import { StickyTable } from "@/packages/stickyTable/StickyTable";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { useTranslation } from "react-i18next";

const DealerBonusesTab: React.FC = () => {
  const [dealerSearch, setDealerSearch] = useState("");
  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { mutate: editBonusesParams, isLoading: isEditing } =
    useEditBonusesParams();

  const {
    modalState: BonusesModalState,
    openModal: BonusesModalOpen,
    closeModal: BonusesModalClose,
  } = useModal();

  const { paginationParams, setPage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;

  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const { data: reportData, refetch: updateReportData } = useGetBonusesReports({
    quarter: +selectedQuarter,
    year: +selectedYear,
  });
  const [requestParams, setRequestParams] = useRequestParams({
    quarter: {
      type: "filter",
      value: selectedQuarter,
    },
    year: {
      type: "filter",
      value: selectedYear,
    },
  });
  const { t } = useTranslation();
  useEffect(() => {
    setSearchParams({
      quarter: requestParams?.quarter?.value?.toString() || "",
      year: requestParams?.year?.value?.toString() || "",
    });
  }, [requestParams]);

  const { mutateAsync: exportDealerBonuses } = useExportDealerBonuses();

  const {
    data: bonusesData,
    isLoading,
    refetch: updateCalculation,
  } = useGetBonusesCalculation({
    ...(requestParamsMapper(requestParams) as TGetBonusesCalculationsRequest),
    "page[page]": paginationParams.page,
    "page[limit]": paginationParams.limit,
    "filter[dealerSearch]": dealerSearch,
  });

  const handleFiltersApply = (filters: IDealerBonusesFilterFormValues) => {
    Object.entries(filters).forEach(([key, value]) => {
      if (key === "dealerSearch") {
        setDealerSearch(value);
      } else {
        setRequestParams((prev) => ({
          ...prev,
          [key]: { type: "filter", value },
        }));
      }
    });
  };

  const handleSubmit = (data) => {
    if (reportData?.data?.quarter.id) {
      editBonusesParams({
        id: reportData?.data?.quarter.id,
        data: {
          planPercent: +data.planPercent,
          bonusPercent: +data.bonusPercent,
        },
        onSuccess: () => {
          createNotificationSuccess(
            t("bonuses.calculations.createNotificationSuccess") || ""
          );
          updateCalculation();
          updateReportData();
          BonusesModalClose();
        },
        onError: (error) =>
          createNotificationError(
            (Array.isArray(error.response?.data?.message) &&
              error.response?.data?.message[0]) ||
              error.response?.data?.message ||
              error.message
          ),
      });
    }
  };

  const headers = getBonusesTableHeaders({
    planPercent: bonusesData?.data?.data[0]?.planPercent || "",
  });

  const handleDealerBonusesExport = () => {
    exportDealerBonuses(
      {
        ...(requestParamsMapper(
          requestParams
        ) as TGetBonusesCalculationsRequest),
        "filter[dealerSearch]": dealerSearch,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "dealer-bonuses.xlsx");
        },
      }
    );
  };

  return (
    <>
      <StyledTab>
        <DealerBonusesFilter
          year={requestParams?.year?.value?.toString() || selectedYear}
          quarter={requestParams?.quarter?.value?.toString() || selectedQuarter}
          onFiltersChange={handleFiltersApply}
        />
        <StyledHR />
        <DealerBonusesTitleHeader
          hasButton={!!reportData?.data.quarter}
          disablePrev={
            requestParams?.quarter?.value
              ? +requestParams?.quarter?.value <= 1
              : true
          }
          disableNext={
            requestParams?.quarter?.value
              ? +requestParams?.quarter?.value >= 4
              : true
          }
          handleExport={handleDealerBonusesExport}
          disableExport={!bonusesData?.data?.data}
          onNextClick={() =>
            setRequestParams((prev) =>
              prev?.quarter?.value
                ? {
                    ...prev,
                    quarter: {
                      type: "filter",
                      value: +prev?.quarter?.value + 1,
                    },
                  }
                : prev
            )
          }
          onPrevClick={() =>
            setRequestParams((prev) =>
              prev?.quarter?.value
                ? {
                    ...prev,
                    quarter: {
                      type: "filter",
                      value: +prev?.quarter?.value - 1,
                    },
                  }
                : prev
            )
          }
          onButtonClick={BonusesModalOpen}
        />
        <BonusesTableAdapter
          isLoading={isLoading}
          paginationParams={{
            ...paginationParams,
            limit: bonusesData?.data.meta?.pageCount || 0,
          }}
          setPage={setPage}
        >
          <StickyTable
            // @ts-ignore
            headers={headers || []}
            data={bonusesData?.data?.data || []}
            onSort={() => console.log(123)}
            onSearch={(_, value) => {
              setDealerSearch(value || "");
            }}
          />
        </BonusesTableAdapter>
      </StyledTab>
      {reportData?.data.quarter ? (
        <BonusesModal
          quarter={+selectedQuarter}
          year={+selectedYear}
          isOpen={BonusesModalState.isOpen}
          planPercent={reportData?.data?.quarter?.planPercent || 0}
          bonusPercent={reportData?.data?.quarter?.bonusPercent || 0}
          onSubmit={handleSubmit}
          onCancel={BonusesModalClose}
          isLoadingConfirmButton={isEditing}
          isDisabledConfirmButton={isEditing}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { DealerBonusesTab };
