import { t } from "i18next";

export enum QualityMonitoringStatusEnum {
  OPEN = "open",
  SEARCH_COMPLETED = "search-completed",
  CLOSED = "closed",
}

export const generateExpandDataCars = (data: any) => {
  const {
    isSold,
    carSoldDate,
    diagnosticResult,
    reasonPetition,
    suspectedDefect,
    generation,
    dealer,
  } = data;

  return [
    {
      label: t("monitoring.model"),
      value: `${generation.model.name} ${generation.name} ${
        generation?.restylingName || ""
      }`,
    },
    {
      label: t("monitoring.autoSold"),
      value: !!isSold,
      type: "checkbox",
    },
    {
      label: t("monitoring.soldDate"),
      value: `${carSoldDate || ""}`,
      type: "datepicker",
    },
    {
      label: t("monitoring.reasons"),
      value: `${reasonPetition || ""}`,
      type: "textarea",
    },
    {
      label: t("monitoring.diagnResults"),
      value: `${diagnosticResult || ""}`,
      type: "textarea",
    },
    {
      label: t("monitoring.possibleCause"),
      value: `${suspectedDefect || ""}`,
      type: "textarea",
    },

    {
      label: t("monitoring.author"),
      value: `${dealer?.name || ""}`,
    },
  ];
};
