import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { StatusOrders } from "../../KiaFlexCar/Tables/KiaFlexOrdersTable";
import { NewCarFields } from "../NewCarFIelds";
import { TOnSubmitKiaFlexForm, useEditFlexOrdersForm, } from "../NewCarForm/hooks";
import { useTranslation } from "react-i18next";

export interface INewCarModal extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitKiaFlexForm;
  onClose: () => void;
  record: TFlexOrder & { ID: string };
  onRemove: any;
}

export enum TSideCheck {
  KIA = "kia-russia-sng",
  DEALER = "dealer",
}

const CreateNewCarModal: React.FC<INewCarModal> = (props) => {
  const { onSubmit, record, onRemove, onClose, ...rest } = props;

  const { t } = useTranslation();

  const [stage, setStage] = useState({
    number: 1,
    text: {
      cancel: t("kiaFlex.appRequestCancel") || "",
      confirm: t("kiaFlex.confirm_2") || "",
    },
  });

  const status = record?.status;

  const payload = record?.payload;

  const {
    state: { profile },
  } = useProfile();

  const isDealer = profile?.type === "dealer";
  const isKia = profile?.type === "kia";

  const { form: editFrom, submit: onEditSubmit } = useEditFlexOrdersForm({
    onSubmit,
    editableRecord: record,
    status,
    isKia,
  });

  useEffect(() => {
    if (record?.status?.status === StatusOrders.NEW && !isDealer) {
      setStage({
        number: 2,
        text: {
          cancel: t("kiaFlex.appRequestCancel") || "",
          confirm: t("kiaFlex.dealerSubmit") || "",
        },
      });
    } else if (record?.status?.status === StatusOrders.CONFIRMATION) {
      setStage({
        number: 3,
        text: {
          cancel: t("kiaFlex.appRequestCancel") || "",
          confirm: t("kiaFlex.confirm_2") || "",
        },
      });
    } else if (record?.status?.status === StatusOrders.DELIVERY && !isDealer) {
      setStage({
        number: 4,
        text: {
          cancel: t("kiaFlex.cancel") || "",
          confirm: t("kiaFlex.confirmDeliveryDate") || "",
        },
      });
    } else if (record?.status?.status === StatusOrders.DELIVERY && isDealer) {
      setStage({
        number: 5,
        text: {
          cancel: t("kiaFlex.cancel") || "",
          confirm: t("kiaFlex.getAutoConfirm") || "",
        },
      });
    } else if (record?.status?.status === StatusOrders.REGISTRATION) {
      setStage({
        number: 6,
        text: {
          cancel: t("kiaFlex.cancel") || "",
          confirm: t("kiaFlex.addNewCar") || "",
        },
      });
    }
  }, [isDealer, payload, profile, record]);

  const showConfirmButton =
    (isDealer && record?.status?.sideCheck === TSideCheck.DEALER) ||
    (!isDealer && record?.status?.sideCheck === TSideCheck.KIA) ||
    (!isDealer && record?.status?.status === StatusOrders.DELIVERY && record?.status.sideCheck === TSideCheck.DEALER);

  return (
    <Modal
      {...rest}
      size="medium"
      confirmButtonText={stage.text.confirm}
      cancelButtonText={stage.text.cancel}
      onSubmit={onEditSubmit}
      showConfirmButton={showConfirmButton}
      allowCancelPopconfirm={stage.number <= 3 ? true : undefined}
      onPopupConfirm={() =>
        stage.number <= 3 ? onRemove(record?.id) : onClose()
      }
      cancelPopconfirmTitle={
        stage.number <= 3 ? t("kiaFlex.cancelOrderConfirm") || "" : undefined
      }
    >
      <FormProvider {...editFrom}>
        <NewCarFields
          // @ts-ignore
          status={status}
          record={record}
          form={editFrom}
          stage={stage.number}
        />
      </FormProvider>
    </Modal>
  );
};
export default CreateNewCarModal;
