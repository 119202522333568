import {
  useGetFlexOrdersVehiclesById,
  useGetFlexOrdersVehiclesList,
} from "@/helpers/api/flex/orders/hooks";
import { TGenerationRecord } from "@/helpers/api/generations/types";
import { TModelRecord } from "@/helpers/api/models/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { ModelFieldsName } from "../../KiaFlexCar/KiaFlexFormsFields/ModelFields/types";
import { FormInputTextUpper } from "./index.styles";
import { ModelFieldsFieldsName } from "./types";
import { useTranslation } from "react-i18next";

export interface IModelFieldsProps {
  description: any;
  isDisabled: boolean;
  record?: any;
  form: UseFormReturn<any>;
}

const _ModelFields: React.FC<IModelFieldsProps> = (props) => {
  const { form, description, isDisabled, record } = props;

  const [modelId, setModelId] = useState<TModelRecord | null>(null);
  const [genId, setGenId] = useState<TGenerationRecord | null>(null);
  const [equpId, setEqupId] = useState<any | null>(null);
  const [modelData, setModelData] = useState();

  const { color, comment, equipmentId, generation, model, modification } =
    useWatch();

  const { t } = useTranslation();

  const {
    state: { profile },
  } = useProfile();

  const { data: ordersVehicles } = useGetFlexOrdersVehiclesList({});

  const { data: ordersVehiclesOnKia, isSuccess } = useGetFlexOrdersVehiclesById(
    record?.id
  );

  const modelOptions = useMemo(() => {
    return modelData
      ? // @ts-ignore

        modelData?.map((item, id) => ({
          label: item.name,
          value: item.id,
        }))
      : [];
  }, [modelData]);

  const genOptions = useMemo(() => {
    if (model) {
      // @ts-ignore
      setModelId(modelData?.filter((item) => item.id === model)[0]);
      return modelId?.generations.map((gen) => ({
        label: `${gen.name} ${gen.restylingName || ""}`,
        value: gen.id,
      }));
    } else {
      return [];
    }
  }, [model, modelData, modelId]);

  const modOptions = useMemo(() => {
    if (generation) {
      setGenId(
        // @ts-ignore

        modelId?.generations?.filter((item) => item.id === generation)[0]
      );
      // @ts-ignore
      return genId?.modifications?.map((gen) => ({
        label: gen.fullName,
        value: gen.id,
      }));
    }
    return [];
  }, [genId, generation, modelId]);

  const equpOptions = useMemo(() => {
    if (modification) {
      setEqupId(
        // @ts-ignore

        genId?.modifications?.filter((item) => item.id === modification)[0]
      );

      // @ts-ignore
      return equpId?.equipments?.map((gen) => ({
        label: gen.name,
        value: gen.id,
      }));
    }
    return [];
  }, [equpId, genId, modification]);

  useEffect(() => {
    if (ordersVehiclesOnKia) {
      // @ts-ignore
      setModelData(ordersVehiclesOnKia?.data?.data);
    } else {
      // @ts-ignore
      setModelData(ordersVehicles?.data?.data);
    }
  }, [isSuccess, modelData, ordersVehicles, ordersVehiclesOnKia, record]);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsFieldsName.MODEL}
            label={t("maintenanceGrids.gridsModel.model") || ""}
            options={modelOptions}
            description={t(description[ModelFieldsFieldsName.MODEL]) || ""}
            isDisabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsFieldsName.GENERATION}
            label={t("kiaFlex.generation") || ""}
            options={genOptions}
            description={t(description[ModelFieldsFieldsName.GENERATION]) || ""}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.MODIFICATION}
            label={t("maintenanceGrids.newGridForm.modification") || ""}
            options={modOptions}
            description={t(description[ModelFieldsName.MODIFICATION]) || ""}
            isDisabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            name={ModelFieldsName.EQUIPMENT}
            label={t("kiaFlex.equip") || ""}
            options={equpOptions}
            description={t(description[ModelFieldsName.EQUIPMENT]) || ""}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInputField
            name={ModelFieldsName.COLOR}
            label={t("kiaFlex.color") || ""}
            description={t(description[ModelFieldsName.COLOR]) || ""}
            isDisabled={isDisabled}
          />
        </Col>
        {record && (
          <Col span={12}>
            <FormInputTextUpper
              name="vin"
              label={t("kiaFlex.VINCode") || ""}
              description={t("kiaFlex.vinCode") || ""}
              isDisabled={isDisabled}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
export const ModelFields = React.memo(_ModelFields);
