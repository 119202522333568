import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import React, { useState } from "react";
import { usePagination } from "@/hooks/usePagination";
import { useModal } from "@/hooks/useModal";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { useRequestParams } from "@/hooks/useRequestParams";
import { handleFileLoad, requestParamsMapper } from "@/helpers/utils";
import { IRequestParams } from "@/helpers/interfaces";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@/hooks/useNotification";
import { AuditWarrantyTableAdapter } from "@/components/AuditWarranty/AuditWarrantyTableAdapter";
import { getDefaultAuditTableHeaders } from "./helpers";
import {
  useArchiveWarrantyAudit,
  useDeleteWarrantyAudit,
  useGetWarrantyAuditList,
} from "@/helpers/api/warranty-audit/audit-list/hooks";
import WarrantyAuditListFilter from "@/components/AuditWarranty/WarrantyAuditListFilter";
import { CreateWarrantyAuditModal } from "@/components/AuditWarranty/CreateWarrantyAuditModal";
import { CopyWarrantyAuditModal } from "@/components/AuditWarranty/CopyWarrantyAuditModal";
import { StyledButtonsTop, StyledContentTitle, StyledSelectField, } from "./index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useTranslation } from "react-i18next";
import { useExportWarrantyAuditList } from "@/helpers/api/audit-list/hooks";
import { DateParam, StringParam, useQueryParams } from "use-query-params";
import { Space } from "antd";
import { IAuditFilterFormValues } from "@/components/AuditWarranty/WarrantyAuditListFilter/hooks";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

const WarrantyAuditList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paginationParams, setPage } = usePagination();
  const [sortParams, setSortParams] = useState({});
  const [requestParams, setRequestParams] = useRequestParams({});
  const [selectedAudit, setSelectedAudit] = useState<number | null>(null);

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    auditorId: StringParam,
    dealerId: StringParam,
    holdingId: StringParam,
    curatorId: StringParam,
    type: StringParam,
    status: StringParam,
    holding: StringParam,
    dealer: StringParam,
    manager: StringParam,
    startCreatedAt: DateParam,
    endCreatedAt: DateParam,
  });

  const filterParams: IAuditFilterFormValues = {
    auditorId: filterQueryParams.auditorId as string,
    dealerId: filterQueryParams.dealerId as string,
    holdingId: filterQueryParams.holdingId as string,
    curatorId: filterQueryParams.curatorId as string,
    type: filterQueryParams.type as string,
    status: filterQueryParams.status as string,
    holding: filterQueryParams.holding as string,
    dealer: filterQueryParams.dealer as string,
    manager: filterQueryParams.manager as string,
    startCreatedAt: filterQueryParams.startCreatedAt as Date,
    endCreatedAt: filterQueryParams.endCreatedAt as Date,
  };

  const {
    data: auditList,
    isLoading,
    refetch: updateList,
  } = useGetWarrantyAuditList({
    ...sortParams,
    ...requestParamsMapper(requestParams),
    "page[limit]": paginationParams.limit,
    "page[page]": paginationParams.page,
  });
  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: "URL страницы скопирован в буфер обмена",
  });

  const { mutate: deleteAudit } = useDeleteWarrantyAudit();
  const { createNotificationError } = useNotification();
  const { mutate: archiveAudit } = useArchiveWarrantyAudit();
  const {
    modalState: CreateAuditModalState,
    openModal: CreateAuditModalOpen,
    closeModal: CreateAuditModalClose,
  } = useModal();

  const {
    modalState: CopyAuditModalState,
    openModal: CopyAuditModalOpen,
    closeModal: CopyAuditModalClose,
  } = useModal();

  const handleSubmit = () => {
    updateList();
    CreateAuditModalClose();
    CopyAuditModalClose();
  };

  const handleDeleteAudit = (id: number) => {
    deleteAudit({
      id,
      onSuccess: handleSubmit,
      onError: (resp) => {
        createNotificationError(resp.response?.data.message || resp.message);
      },
    });
  };

  const handleArchiveAudit = (id: number) => {
    archiveAudit({
      id,
      onSuccess: () => updateList(),
      onError: (resp) => {
        createNotificationError(resp.response?.data.message || resp.message);
      },
    });
  };
  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      "sort[sortBy]": header.id,
      "sort[sortAt]": order?.toUpperCase(),
    }));
  };

  const handleCopyModal = (id: number) => {
    setSelectedAudit(id);
    CopyAuditModalOpen();
  };

  const { dispatch } = useUserInfoModel();

  const headers = getDefaultAuditTableHeaders({
    onDeleteClick: handleDeleteAudit,
    onArchive: handleArchiveAudit,
    openCopyModal: handleCopyModal,
    onViewClick: (path) => {
      navigate(path);
    },
    openUserModal: dispatch,
  });

  const { mutateAsync: exportAuditList } = useExportWarrantyAuditList();

  const handleExportAudits = () => {
    exportAuditList(
      {
        ...sortParams,
        ...requestParamsMapper(requestParams),
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "warrantyAudits.xlsx");
        },
      }
    );
  };

  return (
    <>
      <DashboardLayout
        title={t("warrantyAudits.title") || ""}
        hasMobile={true}
        headerRight={
          <Space>
            <Tooltip content={t("common.save") || ""} placement="bottom">
              <IconButton
                icon="save"
                isSquared={true}
                variant="primary"
                color="white"
                onClick={handleExportAudits}
              />
            </Tooltip>
            <Tooltip content={t("common.share") || ""} placement="bottom">
              <IconButton
                icon="share"
                isSquared={true}
                variant="primary"
                color="white"
                onClick={() => copyCurrentUrl()}
              />
            </Tooltip>
            <PermissionsAdapter
              resourceName="guarantee.audits-list"
              resourceAction="create"
            >
              <StyledButtonsTop>
                <Button
                  color="brand"
                  size="middle"
                  theme="primary"
                  onClick={CreateAuditModalOpen}
                >
                  {t("warrantyAudits.list.addNew") || ""}
                </Button>
              </StyledButtonsTop>
            </PermissionsAdapter>
          </Space>
        }
      >
        <StyledContentTitle>
          <StyledSelectField>
            <WarrantyAuditListFilter
              onFilterChange={(data: IRequestParams) => {
                setRequestParams((prev) => ({ ...prev, ...data }));
              }}
              setFilterQueryParams={setFilterQueryParams}
              initValues={filterParams}
            />
          </StyledSelectField>
        </StyledContentTitle>

        <AuditWarrantyTableAdapter
          autoOverflow
          paginationParams={{
            page: paginationParams.page,
            limit: auditList?.data.meta?.pageCount || 1,
          }}
          onSort={handleSort}
          data={auditList?.data.data || []}
          // @ts-ignore
          headers={headers || []}
          isLoading={isLoading}
          setPage={setPage}
          defaultColumn={{
            minWidth: 100,
            width: 180,
            maxWidth: 300,
          }}
          requestParams={requestParams}
        />
      </DashboardLayout>

      <CreateWarrantyAuditModal
        isOpen={CreateAuditModalState.isOpen}
        onSubmit={handleSubmit}
        onCancel={CreateAuditModalClose}
      />
      {selectedAudit ? (
        <CopyWarrantyAuditModal
          isOpen={CopyAuditModalState.isOpen}
          onSubmit={handleSubmit}
          auditId={selectedAudit}
          onCancel={CopyAuditModalClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { WarrantyAuditList };
