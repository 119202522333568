import { accessTokenService } from "@/helpers/api/accessTokenService";
import { TFileCreateResponse, TFileRecord } from "@/helpers/api/files/types";
import { useNotification } from "@/hooks/useNotification";
import { Button, IButton } from "@/packages/button/Button";
import { Upload as AntUpload, UploadProps } from "antd";
import React from "react";

export interface IUploadButton extends IButton {
  path: string;
  onUploadFile?: (uploadResponse: TFileRecord) => void;
}
const allowedTypes = ["image/png", "image/jpeg"];

export const getUploadFileUrl = (path: string) =>
  `${process.env.REACT_APP_BASE_URL}/files?path=${path}`;

export const UploadButton: React.FC<IUploadButton> = (props) => {
  const { path, onUploadFile, ...buttonProps } = props;
  const { createNotificationError } = useNotification();

  const accessToken = accessTokenService.getToken();

  const onBeforeUploadHandler: UploadProps["beforeUpload"] = (file) => {
    const isAllowed = allowedTypes.includes(file.type);
    if (!isAllowed) {
      createNotificationError("Доступна загрузка только PNG, JPEG файлов");
    }
    return isAllowed;
  };

  const onChangeHandler = (info) => {
    if (onUploadFile && info.file.status === "done") {
      const response = info.file.response as TFileCreateResponse;
      onUploadFile(response.data[0]);
    }
  };

  return (
    <AntUpload
      name="files"
      action={getUploadFileUrl(path)}
      headers={{ Authorization: `Bearer ${accessToken}` }}
      onChange={onChangeHandler}
      beforeUpload={onBeforeUploadHandler}
    >
      <Button {...buttonProps} />
    </AntUpload>
  );
};
