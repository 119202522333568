import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDealersFilter } from "@/components/MonitoringQuality/MonitoringDealers/MonitoringDealersFilter";
import {
  IMonitoringDealersFilterFormValues
} from "@/components/MonitoringQuality/MonitoringDealers/MonitoringDealersFilter/hooks";
import { MonitoringDealersTable } from "@/components/MonitoringQuality/MonitoringDealers/MonitoringDealersTable";
import {
  useExportMonitoringQualityDealers,
  useGetMonitoringQualityById,
  useGetMonitoringQualityDealers,
  useGetMonitoringQualityDealersFilters,
} from "@/helpers/api/monitoringQuality/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Loader } from "@/packages/loader/Loader";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BooleanParam,
  createEnumParam, NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { StyledPagination } from "./index.styles";

export interface IMonitoringQualityDealersProps {
}

export const MonitoringQualityDealers: React.FC<
  IMonitoringQualityDealersProps
> = (props) => {
  const params = useParams();
  const { paginationParams, setPage } = usePagination();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    names: StringParam,
    cityNames: StringParam,
    codes: StringParam,
    submittedCars: createEnumParam(["with-requests", "without-requests"]),
    familiarize: BooleanParam,
    page: NumberParam,
  });

  useEffect(() => {
    const numberPage = Number(filterQueryParams.page);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setFilterQueryParams({
      ...filterQueryParams,
      page: paginationParams.page
    })
  }, [paginationParams.page])

  const filters: IMonitoringDealersFilterFormValues = {
    names: filterQueryParams.names
      ? filterQueryParams.names.split(",")
      : undefined,
    cityNames: filterQueryParams.cityNames
      ? filterQueryParams.cityNames.split(",")
      : undefined,
    codes: filterQueryParams.codes
      ? filterQueryParams.codes.split(",")
      : undefined,
    submittedCars: filterQueryParams.submittedCars || undefined,
    familiarize: filterQueryParams.familiarize || undefined,
  };

  const [sortParams, setSortParams] = useState({});

  const handleSort = (header: HeaderArgType, order: SortOrder) => {
    setSortParams((prev) => ({
      ...prev,
      sortBy: header.id,
      orderBy: order?.toUpperCase(),
    }));
  };

  const { mutateAsync: exportMonitoringQualityDealers } =
    useExportMonitoringQualityDealers();

  const { data: monitoringQualityDealersFiltersData } =
    useGetMonitoringQualityDealersFilters(params.id as string);

  const filtersCitiesOptions = useMemo(() => {
    if (monitoringQualityDealersFiltersData?.data) {
      //@ts-ignore
      const { cities, dealers } = monitoringQualityDealersFiltersData?.data;
      const citiesOptions = cities
        .map((city) => ({
          value: city.name,
          label: city.name,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));

      const dealerNameOptions = dealers.map((dealer) => ({
        value: dealer.merchantName,
        label: dealer.merchantName,
      }));

      const dealerCodeOptions = dealers.map((dealer) => ({
        value: dealer.warrantyCode,
        label: dealer.warrantyCode,
      }));
      return { cities: citiesOptions, dealerCodeOptions, dealerNameOptions };
    }
  }, [monitoringQualityDealersFiltersData]);

  const { data: monitoringQualityDealersData, isLoading } =
    useGetMonitoringQualityDealers(params.id as string, {
      ...filters,
      ...sortParams,
      ...paginationParams,
    });

  //@ts-ignore
  const monitoringQualityDealersList = monitoringQualityDealersData?.data?.data;

  const handleMonitoringQualityCarsExport = () => {
    exportMonitoringQualityDealers(
      {
        //@ts-ignore
        id: params.id,
        ...filters,
        ...paginationParams,
        ...sortParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(
            response.data as Blob,
            "monitoring-quality-dealers.xlsx"
          );
        },
      }
    );
  };

  const { data: dealerInfo } = useGetMonitoringQualityById({
    id: params.id as string,
  });
  return (
    <DashboardLayout
      title={dealerInfo?.data.title || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName="quality-monitoring"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48}/>}
      >
        <MonitoringDealersFilter
          initValues={filters}
          filterOptions={filtersCitiesOptions}
          onFiltersChange={setFilterQueryParams}
          handleExport={handleMonitoringQualityCarsExport}
          resetPage={() => setPage(1)}
        />
        {isLoading ? (
          <Loader size={48}/>
        ) : (
          <>
            <MonitoringDealersTable
              monitoringQualityDealersList={monitoringQualityDealersList}
              onSort={handleSort}
            />
            <StyledPagination
              current={paginationParams.page}
              onChange={setPage}
              pageSize={10}
              //@ts-ignore
              total={monitoringQualityDealersData?.data.meta?.itemCount || 0}
            />
          </>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
