import { Input } from "@/packages/input";
import styled from "styled-components";

export const StyledItem = styled.div<{ opacity: number }>`
  display: flex;
  align-items: center;
  padding: 5px;
  width: max-content;
  border: 1px solid black;
  gap: 8px;
  flex-direction: row;
  cursor: move;
  opacity: ${({ opacity }) => opacity};
`;

export const StyledInput = styled(Input)`
  cursor: move;
`;
