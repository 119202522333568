import { formatDate } from "@/helpers/formatDate";
import { Button } from "@/packages/button/Button";
import React from "react";
import { StyledLastUpdate, StyledModelsListTabFooter } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IModelsListFooter {
  onRefreshModels: () => void;
  isLoadingRefreshModels: boolean;
  dataModelListUpdatedAt: number;
}

export const ModelsListFooter: React.FC<IModelsListFooter> = ({
  onRefreshModels,
  isLoadingRefreshModels,
  dataModelListUpdatedAt,
}) => {
  const updatedAtStr = formatDate(dataModelListUpdatedAt, "dateAndTime");
  const { t } = useTranslation();

  return (
    <StyledModelsListTabFooter>
      <Button
        color="brand"
        size="middle"
        theme="primary"
        onClick={onRefreshModels}
        isLoading={isLoadingRefreshModels}
      >
        {t("models.updateModels") || ""}
      </Button>
      <StyledLastUpdate size={10} color="gray200">
        {t("models.updateModelsMessage") || ""} {updatedAtStr}
      </StyledLastUpdate>
    </StyledModelsListTabFooter>
  );
};
