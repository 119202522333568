import { NotificationsResultModal } from "@/components/NotificationsResult/NotificationsResultModal";
import { colors } from "@/helpers";
import { TRegistrationByDKiaNotification } from "@/helpers/api/notifications/types";
import {
  useConfirmDealerRegistrationByKia,
  useGetUserById,
  useRejectDealerRegistrationByKia,
} from "@/helpers/api/users/hooks";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { InputField } from "@/packages/formElements/fields/InputField";
import { Space } from "antd";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetProfile } from "@/helpers/api/profile/hooks";

const StyledNotificationsResultKiaEmployee = styled.div`
  padding: 24px;
  width: 50%;

  display: grid;
  gap: 32px;

  background-color: ${colors.white};
`;

const StyledInputs = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
`;

interface INotificationsResultDealer {
  notification: TRegistrationByDKiaNotification;
}

const NotificationsResultKiaEmployee: FC<INotificationsResultDealer> = (
  props
) => {
  const { notification } = props;
  const { createNotificationError } = useNotification();
  const params = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    modalState: notificationsResultConfirmModalState,
    openModal: openNotificationsResultConfirmModalState,
  } = useModal();

  const {
    modalState: notificationsResultCancelModalState,
    openModal: openNotificationsResultCancelModalState,
  } = useModal();

  const personId = notification?.data?.entityId as string;

  const { mutate: confirmNotification } = useConfirmDealerRegistrationByKia();

  const { mutate: rejectNotification } = useRejectDealerRegistrationByKia();

  const profile = useGetProfile();

  const { data: userData } = useGetUserById({ id: notification.data.entityId });

  const handleConfirmButtonClick = async () => {
    try {
      if (!notification?.data?.entityId) {
        createNotificationError(
          "Данного пользователя не существует или его заявка была отклонена"
        );
      }
      if (profile?.data?.data.type !== "kia") {
        createNotificationError(
          "Данная заявка должна быть обработана сотрудником KIA "
        );
      }
      // @ts-ignore
      if (userData?.data.confirmationStatus === "confirmed-by-kia") {
        createNotificationError(
          "Данная заявка уже была подтверждена сотрудником KIA"
        );
      } else {
        await confirmNotification({
          id: personId,
        });
        openNotificationsResultConfirmModalState();
        setIsDisabled(true);
      }
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
    }
  };

  const handleCancelButtonClick = async () => {
    try {
      if (
        !notification?.data?.entityId ||
        // @ts-ignore
        !userData?.data?.confirmationStatus
      ) {
        createNotificationError(
          "Данного пользователя не существует или его заявка была отклонена"
        );
      }
      if (profile?.data?.data.type !== "kia") {
        createNotificationError(
          "Данная заявка должна быть обработана сотрудником KIA "
        );
      }
      // @ts-ignore
      if (userData?.data.confirmationStatus === "confirmed-by-kia") {
        createNotificationError(
          "Данная заявка уже была подтверждена сотрудником KIA"
        );
      } else {
        await rejectNotification({
          id: personId,
        });
        openNotificationsResultCancelModalState();
        setIsDisabled(true);
      }
    } catch (error) {
      //@ts-ignore
      createNotificationError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <>
      <StyledNotificationsResultKiaEmployee>
        <StyledInputs>
          <Space direction="vertical" size={24}>
            <InputField
              label="Наименование ДЦ"
              value={notification?.data.dealerName}
              disabled={true}
            />
            <InputField
              label="Код SAP"
              value={notification?.data.dealerSapCode}
              disabled={true}
            />
            <InputField
              label="Код шоурума"
              value={notification?.data.dealerShowroomCode}
              disabled={true}
            />
            <InputField
              label="Фамилия"
              value={notification?.data.lastName}
              disabled={true}
            />
            <InputField
              label="Телефон"
              value={notification?.data.phone}
              disabled={true}
            />
          </Space>

          <Space direction="vertical" size={24}>
            <InputField
              label="Дилерский код"
              value={notification?.data.dealerCode}
              disabled={true}
            />
            <InputField
              label="Код Mobis"
              value={notification?.data.dealerMobisCode}
              disabled={true}
            />
            <InputField
              label="Должность"
              value={notification?.data.role}
              disabled={true}
            />
            <InputField
              label="Имя"
              value={notification?.data.firstName}
              disabled={true}
            />
            <InputField
              label="Почта"
              value={notification?.data.email}
              disabled={true}
            />
          </Space>
        </StyledInputs>

        <Space align="center" size={16}>
          <Button
            color="brand"
            theme="primary"
            onClick={handleConfirmButtonClick}
            isDisabled={
              isDisabled ||
              // @ts-ignore
              !userData?.data?.confirmationStatus ||
              // @ts-ignore
              !(userData?.data.confirmationStatus === "confirmed-by-dealer")
            }
          >
            Подтвердить учетную запись
          </Button>
          <Button
            color="brand"
            theme="secondary"
            onClick={handleCancelButtonClick}
            isDisabled={
              isDisabled ||
              // @ts-ignore
              !userData?.data?.confirmationStatus ||
              // @ts-ignore
              !(userData?.data.confirmationStatus === "confirmed-by-dealer")
            }
          >
            Отклонить учетную запись
          </Button>
        </Space>
      </StyledNotificationsResultKiaEmployee>

      <NotificationsResultModal
        {...notificationsResultConfirmModalState}
        resultText="Учетная запись подтверждена"
      />
      <NotificationsResultModal
        {...notificationsResultCancelModalState}
        resultText="Учетная запись отклонена"
      />
    </>
  );
};

export { NotificationsResultKiaEmployee };
