import { colors } from "@/helpers";
import { FC } from "react";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Radio } from "@/packages/radio/Radio";

interface IRadioCard {
  title: string;
  subtitle: string;
  radioName?: string;
  radioValue?: boolean;
  onRadioChange?: (value?: boolean) => void;
}

interface IStyledRadioCard {
  isSelected: boolean;
}

const StyledRadioCard = styled.div<IStyledRadioCard>`
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  border: 1px solid
    ${({ isSelected }) => colors[isSelected ? "blue100" : "gray20"]};
  border-radius: 4px;

  background-color: ${({ isSelected }) =>
    colors[isSelected ? "blue100" : "white"]};

  transition: background-color 0.4s, border-color 0.4s;

  cursor: pointer;
`;

const StyledContent = styled.div`
  display: grid;
  gap: 6px;
`;

const RadioCard: FC<IRadioCard> = (props) => {
  const {
    title,
    subtitle,
    radioName,
    radioValue = false,
    onRadioChange,
  } = props;

  const onCardClick = () => onRadioChange && onRadioChange();

  return (
    <StyledRadioCard isSelected={radioValue} onClick={onCardClick}>
      <Radio name={radioName} value={radioValue} onChange={onRadioChange} />

      <StyledContent>
        <Paragraph size={14} color="black">
          {title}
        </Paragraph>
        <Paragraph size={14} color="gray200">
          {subtitle}
        </Paragraph>
      </StyledContent>
    </StyledRadioCard>
  );
};

export { RadioCard };
