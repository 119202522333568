import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const StyledLoginFormTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  color: ${colors.black};
`;

export const StyledLoginFormSubTitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray200};
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledLoginFormSubTitleLink = styled(ButtonText)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brand};
  margin-left: 8px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledLoginFromFormLayoutBottomBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLoginFormForgetPassword = styled(ButtonText)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brand};
`;

export const StyledLoginSubmitButton = styled(Button).attrs({
  isBlock: true,
  theme: "primary",
  color: "brand",
  size: "middle",
})`
  margin-top: 40px;
`;

export const LoginOther = styled.p`
  margin-top: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledActiveSessionParagraph = styled.p`
  font-size: 12px;
  color: ${colors.gray500};
  position: absolute;
  bottom: -14px;
`;

export const StyledLoginFormOther = styled(ButtonText)`
  margin-top: 24px;
  border: 1px solid ${colors.black};
  height: 36px;
`;

export const StyledLoginFormOtherLink = styled.span`
  color: ${colors.brand};
`;

export const StyledMailWrapper = styled.div`
  margin-top: 24px;
`;
export const StyledMailLink = styled.a`
  text-decoration: none;
  color: ${colors.gray600};
  
  &:hover {
    color: ${colors.brand};
  }
`;

export const StyledLoginFormOtherAddition = styled.span`
  color: ${colors.gray3};
`;
