import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IPartCreateModal
  extends Pick<
    IModal,
    | "confirmButtonText"
    | "isOpen"
    | "onCancel"
    | "onSubmit"
    | "isLoadingConfirmButton"
    | "isDisabledConfirmButton"
    | "title"
  > {
  children?: React.ReactNode;
}

export const PartCreateModal: React.FC<IPartCreateModal> = (props) => {
  const { children, confirmButtonText, ...rest } = props;

  const { t } = useTranslation();

  return (
    <Modal
      confirmButtonText={confirmButtonText}
      cancelButtonText={t("parts.cancel") || ""}
      {...rest}
    >
      <TitleDivider size="medium">{t("parts.modalTitle") || ""}</TitleDivider>
      {children}
    </Modal>
  );
};
