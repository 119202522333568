import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const StyledModelsExportDictTab = styled.div``;

export const StyledHeader = styled.div`
  padding: 20px 24px;
`;

export const StyledTitle = styled(Title)`
  grid-area: title;
`;

export const StyledDescription = styled(Paragraph)`
  grid-area: desc;
`;

export const StyledHeaderRight = styled.div`
  grid-area: right;
  display: flex;
  align-items: center;
`;
