import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ISwitchField,
  SwitchField,
} from "@/packages/formElements/fields/SwitchField";

export type FormCheckboxProps = {
  name: string;
} & Omit<ISwitchField, "error" | "value" | "onChange">;

const _FormSwitchField: React.FC<FormCheckboxProps> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <SwitchField
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormSwitchField = React.memo(_FormSwitchField);
