import {
  ISimpleConfirmModal,
  SimpleConfirmModal,
} from "@/components/SimpleConfirmModal";
import React from "react";

const modalSettings = {
  cancelButtonText: "Закрыть",
  contentTitle: "Ваши данные успешно изменены!",
};

export interface IProfileConfirmModal
  extends Pick<ISimpleConfirmModal, "isOpen" | "onClose"> {}

export const ProfileConfirmModal: React.FC<IProfileConfirmModal> = (props) => {
  return (
    <SimpleConfirmModal {...modalSettings} {...props}>
      {" "}
    </SimpleConfirmModal>
  );
};
