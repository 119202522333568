import { Space } from "antd";
import React, { useMemo, useState } from "react";

import { StatisticsFilter } from "@/components/Statistics/StatisticsFilter";
import { StatisticsModal } from "@/components/Statistics/StatisticsModal";
import { StatisticsTable } from "@/components/Statistics/StatisticsTable";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { IconButton } from "@/packages/icon-button/IconButton";

import { StyledPagination } from "@/components/parts/index.styles";
import { DEFAULT_STATISTICS_FILTER_FORM_VALUES } from "@/components/Statistics/StatisticsFilter/hooks";
import { useGetMediaList } from "@/helpers/api/mediaBase/hooks";
import { useGetModelList } from "@/helpers/api/models/hooks";
import {
  useExportStatistics,
  useGetStatistics,
} from "@/helpers/api/statistics/hooks";
import { useGetStatuses } from "@/helpers/api/testDrives/hooks";
import { useGetVehicleParkList } from "@/helpers/api/vehicles/hooks";
import { useModal } from "@/hooks/useModal";
import { usePagination } from "@/hooks/usePagination";
import { vehicleNumberToWithSpaces } from "@/helpers";
import { handleFileLoad } from "@/helpers/utils";
import { useTranslation } from "react-i18next";

export type TOptionList = {
  value: string;
  label: string;
};

export type TFilterOptions = {
  modelOptions: TOptionList[];
  mediaOptions: TOptionList[];
  vehicleOptions: TOptionList[];
};

export const Statistics: React.FC = () => {
  const { modalState, openModal } = useModal();
  const [payload, setPayload] = useState(null);
  const { t } = useTranslation();
  const [form, setForm] = useState({
    ...DEFAULT_STATISTICS_FILTER_FORM_VALUES,
  });

  const { paginationParams, setPage } = usePagination();

  const { data: statisticsResponse } = useGetStatistics({
    "filter[modelId]": form.modelId,
    "filter[gosNumber]": form.gosNumber,
    "filter[mediaId]": form.mediaId === "Kia" ? null : form.mediaId,
    "filter[isKia]": form.mediaId === "Kia" ? true : undefined,
    "filter[startEvent]": form.startEvent,
    "filter[endEvent]": form.endEvent,
    "page[take]": paginationParams.limit,
    "page[skip]": paginationParams.limit * (paginationParams.page - 1),
  });

  const statistics = statisticsResponse?.data.data || [];

  const { mutateAsync: exportStatistics } = useExportStatistics();

  const { data: responseGetModelList } = useGetModelList({ limit: 0 });
  const { data: responseGetMediaList } = useGetMediaList({
    "page[take]": 0,
    "sort[name]": "ASC",
  });
  const { data: responseGetStatusList } = useGetStatuses({
    withoutRejection: false,
    limit: 0,
  });
  const { data: responseGetVehicleParkList } = useGetVehicleParkList({
    query: {},
  });

  const filterOptions: TFilterOptions = useMemo(() => {
    const responseModel = responseGetModelList?.data.data || [];
    const modelOptions =
      responseModel.map((model) => ({
        value: model.id,
        label: model.name,
      })) || [];

    const responseMedia = responseGetMediaList?.data.data || [];
    const mediaOptions =
      responseMedia.map((media) => ({
        value: media.id,
        label: media.name,
      })) || [];

    const responseVehicle = responseGetVehicleParkList?.data.data || [];

    const vehicleOptions =
      responseVehicle.flatMap((model) =>
        model.vehicles.map((vehicle) => ({
          value: vehicle.number,
          label: vehicleNumberToWithSpaces(vehicle.number),
        }))
      ) || [];

    return {
      modelOptions,
      mediaOptions,
      vehicleOptions,
    };
  }, [
    responseGetModelList?.data.data,
    responseGetMediaList?.data.data,
    responseGetVehicleParkList?.data.data,
  ]);

  const handleFilterChange = (data) => {
    setForm(data);
  };

  const handlePublicationsClick = (data) => {
    setPayload(data);
    openModal();
  };

  const handleExportStatistic = () => {
    exportStatistics(
      {
        "filter[modelId]": form.modelId,
        "filter[gosNumber]": form.gosNumber,
        "filter[mediaId]": form.mediaId === "Kia" ? null : form.mediaId,
        "filter[isKia]": form.mediaId === "Kia" ? true : undefined,
        "filter[startEvent]": form.startEvent,
        "filter[endEvent]": form.endEvent,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "statistics.xlsx");
        },
      }
    );
  };

  return (
    <DashboardLayout
      title={t("park.subTitle_4") || ""}
      headerRight={
        <Space>
          <IconButton
            icon="share"
            color="gray"
            size="m"
            variant="secondary"
            isSquared={true}
          />
          <IconButton
            icon="save"
            color="gray"
            size="m"
            variant="secondary"
            isSquared={true}
            onClick={handleExportStatistic}
          />
        </Space>
      }
    >
      <StatisticsFilter
        filterOptions={filterOptions}
        onFiltersChange={handleFilterChange}
      />
      <StatisticsTable
        onPublicityClick={handlePublicationsClick}
        statistics={statistics}
      />
      <StyledPagination
        current={paginationParams.page}
        onChange={setPage}
        pageSize={10}
        total={statisticsResponse?.data.meta?.total || 0}
      />
      <StatisticsModal {...modalState} data={payload} />
    </DashboardLayout>
  );
};
