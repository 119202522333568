import { useProfile, ContextProfile } from "@/hooks/contexts/useProfile";
import { FC, ReactNode } from "react";

interface IContextProvider {
  children: ReactNode;
}

const ProfileContextProvider: FC<IContextProvider> = (props) => {
  const { children } = props;

  const profile = useProfile();

  return (
    <ContextProfile.Provider value={profile}>
      {children}
    </ContextProfile.Provider>
  );
};

export { ProfileContextProvider };
