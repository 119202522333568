import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const WrappedMenu = styled.div`
  position: relative;
`;

const StyledMenuItem = styled.div`
  position: absolute;
  box-shadow: 0px 4px 15px rgba(33, 33, 52, 0.15);
  border-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;
  padding: 13px 21px;
  z-index: 1100;
  white-space: nowrap;
`;

interface IMenu {
  onReUpload: () => void;
  children: JSX.Element;
}
export const HiddenMenu: React.FC<IMenu> = (props) => {
  const { onReUpload, children } = props;
  const [isVisible, setIsVisible] = useState(false);
  return (
    <WrappedMenu
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}

      {isVisible ? (
        <StyledMenuItem onClick={onReUpload}>
          <Paragraph size={14} color="gray">
            Загрузить еще раз
          </Paragraph>
        </StyledMenuItem>
      ) : (
        <></>
      )}
    </WrappedMenu>
  );
};
