import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { AuditInfo } from "@/components/Audits/FillAuditStages/AuditInfo";
import { useGetAuditById } from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import {
  useAuditCreateTask,
  useDealerSendAuditPlan,
  useDeleteTask,
  useGetAuditTasksKia,
  usePatchTask,
} from "@/helpers/api/audit-plan/hooks";
import { AuditCreatePlanTable } from "@/components/Audits/AuditCreatePlanTable";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { Icon } from "@/packages/icon/Icon";
import { Button } from "@/packages/button/Button";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ChatModal, IButtonCallback } from "@/components/Audits/ChatModal";
import { useModal } from "@/hooks/useModal";
import { TAuditUpdateTaskRequest } from "@/helpers/api/audit-plan/types";
import {
  useReadAllAuditTaskPPOMessage,
  useSendNotificationTaskForDealer
} from "@/helpers/api/audit-chat/hooks";

export const CreateIcon = styled(Icon)`
    margin: -3px 16px;
    display: inline-block;
`;

const AuditCreatePlan: React.FC = () => {
  const params = useParams();
  const auditId = Number(params.id) as number;
  const navigate = useNavigate();

  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { mutate: sendNotification } = useSendNotificationTaskForDealer();

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { paginationParams, setPage } = usePagination();
  const {
    data: auditData,
    isLoading: isAuditLoading,
    refetch: updateAuditData,
  } = useGetAuditById(auditId);
  const {
    data: planData,
    isLoading: isPlanLoading,
    isRefetching: isPlanRefetching,
    refetch: updatePlanData,
  } = useGetAuditTasksKia(
    {
      "filter[auditId]": auditId,
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
    },
    profile?.type === "kia"
  );
  const [selectedTask, setSelectedTask] = useState<number | null>(null);
  const { mutate: createTask, isLoading: isTaskCreateLoading } =
    useAuditCreateTask({ auditId });
  const { mutate: deleteTask, isLoading: isTaskDeleting } = useDeleteTask();
  const { mutate: patchTask, isLoading: isTaskPatchLoading } = usePatchTask();
  const { mutate: sendDealerPlan, isLoading: isPlanSending } =
    useDealerSendAuditPlan();
  const [loadingIds, setLoadingIds] = useState<number[]>([]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (isTaskCreateLoading) {
      setIsCreating(true);
    } else if (isCreating) {
      setIsCreating(false);
      updatePlanData();
    }
  }, [isTaskCreateLoading]);

  const handleCreateTask = () => {
    createTask({
      onSuccess: (response) => {
      },
      onError: (error) => {
        createNotificationError(error.response?.data?.message || "");
      },
    });
  };

  const handlePathTask = (request: TAuditUpdateTaskRequest) => {
    setLoadingIds((prev) => [...prev, request.id]);
    patchTask({
      ...request,
      onSuccess: (resp) => {
        setLoadingIds((prev) => prev.filter((item) => item !== resp.id));
        updatePlanData();
      },
      onError: (error) => {
        createNotificationError(
          error.response?.data?.message || error?.message
        );
      },
    });
  };

  const handleDeleteTask = (id: number) => {
    deleteTask({ id }, { onSuccess: () => updatePlanData() });
  };

  const handleSendDealerPlan = () => {
    if (planData?.data.data.find((item) => !item.task || !item.title)) {
      createNotificationError("Необходимо заполнить обязательные поля");
    } else
      sendDealerPlan({
        id: auditId,
        onSuccess: () => {
          createNotificationSuccess("План отправлен дилеру");
          updatePlanData();
          updateAuditData();
        },
        onError: (error) => {
          updatePlanData();
          updateAuditData();
          createNotificationError(
            error.response?.data?.message || error?.message
          );
        },
      });
  };

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const handleOpenChat = (id: number) => {
    setSelectedTask(id);
    ChatModalOpen();
  };

  if (!auditId) {
    return (
      <DashboardLayout title="Создание плана действий" toBack={-1}>
        No audit id
      </DashboardLayout>
    );
  }

  const handleCloseChat = () => {
    ChatModalClose();
    updateAuditData();
    updatePlanData();
  };

  const isProfileKia = profile?.type === "kia";

  const btnCallback: IButtonCallback = {
    sideEffectText: 'Отправить уведомление дилеру',
    sideEffectCb: () => {
      sendNotification({ id: selectedTask ?? 0 })
    }
  }
  return (
    <>
      <DashboardLayout hasMobile={true} title="Создание плана действий" toBack={-1}>
        <PermissionsAdapter
          resourceName="audit.task-managment"
          resourceAction="create"
        >
          {isAuditLoading || isPlanLoading ? (
            <Loader size={48}/>
          ) : (
            <>
              {auditData ? (
                <AuditInfo
                  auditData={auditData?.data || []}
                  renderComponent={() =>
                    auditData.data.planStatus !== "Активный" ? (
                      <div>
                        <Button
                          size="middle"
                          color="brand"
                          theme="primary"
                          isLoading={isPlanSending}
                          onClick={handleSendDealerPlan}
                        >
                          Отправить план дилеру
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          size="middle"
                          color="brand"
                          theme="primary"
                          isLoading={isPlanSending}
                          onClick={() =>
                            navigate(`/audit-plan/${auditData.data.id}`)
                          }
                        >
                          Смотреть план действий
                        </Button>
                      </div>
                    )
                  }
                />
              ) : (
                <></>
              )}

              <AuditCreatePlanTable
                loadingIds={loadingIds}
                data={planData?.data}
                hasPagination={
                  !!planData?.data.meta?.pageCount &&
                  planData?.data.meta?.pageCount > 1
                }
                paginationParams={{
                  page: paginationParams.page,
                  limit: planData?.data.meta?.pageCount || 1,
                }}
                isFieldLoading={isPlanRefetching || isTaskPatchLoading}
                setPage={setPage}
                onEditField={handlePathTask}
                onDeleteClick={handleDeleteTask}
                openChat={handleOpenChat}
                CreateRow={() => (
                  <PermissionsAdapter
                    resourceName="audit.task-managment"
                    resourceAction="update"
                  >
                    <ButtonText
                      isIconLeft={true}
                      isDisabled={isTaskCreateLoading}
                      icon={<CreateIcon size={16} name="plus"/>}
                      onClick={handleCreateTask}
                    >
                      Добавить дополнительную задачу
                    </ButtonText>
                  </PermissionsAdapter>
                )}
              />
            </>
          )}
        </PermissionsAdapter>
      </DashboardLayout>
      {selectedTask ? (
        <ChatModal
          btnCallback={isProfileKia ? btnCallback : undefined}
          params={{ taskId: selectedTask }}
          isOpen={ChatModalState.isOpen}
          onClose={handleCloseChat}
          readAllAuditMessage={useReadAllAuditTaskPPOMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { AuditCreatePlan };
