import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import * as yup from "yup";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { t } from "i18next";

export const requiredStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) => yup.string().required(message).nullable();

export const requiredDirectorStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) =>
  yup
    .string()
    .required(message)
    .nullable()
    .max(50, t("registers.holdingsDC.validation.max_50") || "");

export const personalStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) =>
  yup
    .string()
    .nullable()
    .max(50, t("registers.holdingsDC.validation.max_50") || "");

export const requiredAddressStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) =>
  yup
    .string()
    .required(message)
    .nullable()
    .max(150, t("registers.holdingsDC.validation.max_150") || "");

export const requiredCityStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) =>
  yup
    .string()
    .required(message)
    .nullable()
    .max(50, t("registers.holdingsDC.validation.max_50") || "");

export const requiredCarStorageStringSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) =>
  yup
    .string()
    .required(message)
    .nullable()
    .max(500, t("registers.holdingsDC.validation.max_500") || "");

export const requiredNumberSchema = (
  message = t("registers.holdingsDC.validation.full") || ""
) => yup.number().typeError(message).nullable().required(message);

export const emailSchema = (required?: boolean) =>
  required
    ? yup
        .string()
        .email(t("registers.holdingsDC.validation.emailFormatError") || "")
        .required(t("registers.holdingsDC.validation.full") || "")
    : yup
        .string()
        .email(t("registers.holdingsDC.validation.emailFormatError") || "");

export const requiredSelectValueSchema = (
  options: ISelectOption[],
  message = t("registers.holdingsDC.validation.choose") || ""
) =>
  yup
    .mixed()
    .oneOf(
      options.map((option) => option.value),
      message
    )
    .nullable()
    .required(message);

export const requiredSelectSimpleValueSchema = (message?: string) =>
  yup
    .string()
    .nullable()
    .required(message ?? (t("registers.holdingsDC.validation.choose") || ""));

export const requiredDateScheme = (
  message = t("registers.holdingsDC.validation.dataNeed") || ""
) => yup.date().nullable().required(message);

export const requiredHEXScheme = (
  message = t("registers.holdingsDC.validation.hex") || ""
) =>
  yup
    .string()
    .matches(
      /^#([\da-fA-F]{3}){1,2}$/,
      t("registers.holdingsDC.validation.hexError") || ""
    )
    .nullable()
    .required(message);

export const sapCodeSchema = (required?: boolean) =>
  required
    ? yup
        .string()
        .matches(
          /^\w{10}$/,
          t("registers.holdingsDC.validation.codeSymbols_10") || ""
        )
        .required(t("registers.holdingsDC.validation.sap") || "")
    : yup
        .string()
        .matches(
          /^\w{10}$/,
          t("registers.holdingsDC.validation.codeSymbols_10") || ""
        );

export const mobisCodeSchema = (required?: boolean) =>
  required
    ? yup
        .string()
        .matches(
          /^\w{5}$/,
          t("registers.holdingsDC.validation.codeSymbols") || ""
        )
        .required(t("registers.holdingsDC.validation.mobis") || "")
    : yup
        .string()
        .matches(
          /^\w{5}$/,
          t("registers.holdingsDC.validation.codeSymbols_10") || ""
        );

export const showroomCodeSchema = (required?: boolean) =>
  required
    ? yup
        .string()
        .matches(
          /^\w{10}$/,
          t("registers.holdingsDC.validation.codeSymbols_10") || ""
        )
        .required(t("registers.holdingsDC.validation.showroom") || "")
        .nullable()
    : yup
        .string()
        .matches(/^\w{10}$/, {
          excludeEmptyString: true,
          message: t("registers.holdingsDC.validation.codeSymbols_10") || "",
        })
        .nullable();

export const yearRangeSchema = (options: {
  required?: string;
  startAndEndRequired?: string;
  startRequired?: string;
  endRequired?: string;
}) => {
  const { required, startAndEndRequired, startRequired, endRequired } = options;

  let result = yup.object();

  if (required) {
    result = result.required(required);
  }

  if (startAndEndRequired) {
    result = result.test({
      test: (value?: IYearRangeInputFieldValue) =>
        !!value?.start && !!value?.end,
      message: startAndEndRequired,
    });
  }

  if (startRequired) {
    result = result.test({
      test: (value?: IYearRangeInputFieldValue) => !!value?.start,
      message: startRequired,
    });
  }

  if (endRequired) {
    result = result.test({
      test: (value?: IYearRangeInputFieldValue) => !!value?.end,
      message: startRequired,
    });
  }

  return result;
};

export const codeSchema = (options: {
  required: string;
  lengthCnt?: number;
  formatErrorMessage?: string;
}) => {
  const { required, lengthCnt, formatErrorMessage } = options;

  let result = yup.string();

  if (required) {
    result = result.required(required);
  }

  if (lengthCnt) {
    result = result.test({
      test: (value) => {
        if (value) {
          const regex = new RegExp(`^([\\dA-Za-z]){${lengthCnt}}$`);
          return regex.test(value);
        }

        return false;
      },
      message:
        formatErrorMessage ||
        `${
          lengthCnt
            ? `${
                t("registers.holdingsDC.validation.quantity") || ""
              } - ${lengthCnt}. `
            : ""
        }${t("registers.holdingsDC.validation.letters_2") || ""}`,
    });
  }

  return result;
};

export const codeListSchema = (options: {
  required?: string;
  lengthCnt?: number;
  formatErrorMessage?: string;
}) => {
  const { required, lengthCnt, formatErrorMessage } = options;

  let result = yup.array().of(yup.string());

  if (required) {
    result = result.required(required);
  }

  if (lengthCnt) {
    result = result.test({
      test: (value) => {
        if (value) {
          for (let vinCodeItem of value) {
            if (!vinCodeItem) {
              return false;
            }
            const regex = new RegExp(`^([\\dA-Za-z]){${lengthCnt}}$`);
            const regexTestResult = regex.test(vinCodeItem);
            if (!regexTestResult) {
              return false;
            }
          }
          return true;
        }

        return false;
      },
      message:
        formatErrorMessage ||
        `${
          lengthCnt
            ? `${
                t("registers.holdingsDC.validation.quantity") || ""
              } - ${lengthCnt}. `
            : ""
        }${t("registers.holdingsDC.validation.letters_2") || ""}`,
    });
  }

  return result.nullable();
};

export const materialCodeSchema = (options: { required?: string }) => {
  const { required } = options;
  let result = yup.string();

  if (required) {
    result = result.required(required);
  }

  result = result.test({
    test: (value) => {
      if (value) {
        const regex = /^\w{2,}$/;
        return regex.test(value);
      }

      return true;
    },
    message: t("registers.holdingsDC.validation.message") || "",
  });

  return result;
};
