import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TAuditTemplateDeleteRequest,
  TAuditTemplateDeleteResponse,
  TAuditTemplatesChangeStatusRequest,
  TAuditTemplatesChangeStatusResponse,
  TAuditTemplatesRequest,
  TAuditTemplatesResponse,
  TAuditTemplateUploadResponse,
  TChangeAuditNameRequest,
  TChangeAuditNameResponse,
  TCreateAuditTemplateResponse,
  TGetAuditTemplateByIdResponse,
  TGetAuditTemplateFileResponse,
  TExportAuditTemplatesRequest,
  TExportAuditTemplatesResponse,
} from "./types";

export const useGetAuditsTemplatesList = (
  request: TAuditTemplatesRequest,
  ignoreCache?: boolean
) => {
  return useQuery(
    ["audits", "list", request],
    () => {
      return api.get<TAuditTemplatesResponse>("/audits/templates", {
        params: request,
      });
    },
    ignoreCache ? { cacheTime: 0 } : {}
  );
};

export const useGetAuditTemplateById = (id: string) => {
  return useQuery(["audit", id], () => {
    return api.get<TGetAuditTemplateByIdResponse>(`/audits/templates/${id}`);
  });
};

export const useCreateAuditTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      onSuccess,
      onError,
    }: {
      onSuccess: (resp: TCreateAuditTemplateResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateAuditTemplateResponse>("/audits/templates");
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const usePatchAuditTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TAuditTemplatesChangeStatusRequest) => {
      return api.patch<TAuditTemplatesChangeStatusResponse>(
        `/audits/templates/${id}/active`,
        patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["request", "list"]);
        queryClient.invalidateQueries(["requestById", variables.id]);
      },
    }
  );
};

export const useGetAuditTemplateExample = () => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return api.get<TGetAuditTemplateFileResponse>("/audits/templates/files", {
        responseType: "blob",
      });
    },

    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["request", "file"]);
        queryClient.invalidateQueries(["requestByFile", variables]);
      },
    }
  );
};

export const useUploadAuditTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: FormData;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TAuditTemplateUploadResponse>(
        "/audits/templates/files",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteAuditTemplate = () => {
  return useMutation(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: TAuditTemplateDeleteRequest;
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<TAuditTemplateDeleteResponse>(
        `/audits/templates/${id}`,
        {
          // @ts-ignore на беке в типах репонзе == анкноу
          onSuccess: (resp, { onSuccess }) => {
            if (onSuccess) {
              onSuccess();
            }
          },
          onError: (response, { onError }) => {
            if (onError) {
              onError(response as AxiosError<{ message: string }>);
            }
          },
        }
      );
    }
  );
};

export const useChangeAuditName = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
    }: {
      id: TChangeAuditNameRequest["id"];
      patch: TChangeAuditNameRequest["patch"];
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TChangeAuditNameResponse>(
        `/audits/templates/${id}/title`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useExportAuditsTemplates = () => {
  return useMutation((request: TExportAuditTemplatesRequest) => {
    return api.get<TExportAuditTemplatesResponse>("/audits/templates/export", {
      params: request,
      responseType: "blob",
    });
  });
};
