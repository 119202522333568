import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "@/packages/icon-button/IconButton";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const WrappedMenu = styled.div`
  position: relative;
`;

const StyledMenuItem = styled.div`
  position: absolute;
  box-shadow: 4px 4px 4px rgba(115, 115, 115, 0.15);
  border-radius: 8px;
  background-color: ${colors.white};
  cursor: pointer;
  padding: 10px 32px;
  z-index: 1100;
`;

interface IMenu {
  onReply: () => void;
}
export const MessageMenu: React.FC<IMenu> = (props) => {
  const { onReply } = props;
  const [isVisible, setIsVisible] = useState(false);
  return (
    <WrappedMenu
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <IconButton icon="dots" color="black" size="m" variant="tertiary" />

      {isVisible ? (
        <StyledMenuItem onClick={onReply}>
          <Paragraph size={12}>Ответить</Paragraph>
        </StyledMenuItem>
      ) : (
        <></>
      )}
    </WrappedMenu>
  );
};
