import { Tabs as AntdTabs, TabsProps } from "antd";
import styled from "styled-components";
import { colors } from "@/helpers/index";

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTabs = styled(AntdTabs)<TabsProps>`
  margin: 0;

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-nav-list {
    gap: 16px;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab-btn {
    padding: 4px 8px;
  }

  .ant-tabs-tab {
    font-size: 12px;
    padding: 0px !important;
    color: ${colors.black};
    background-color: ${colors.white};
    border-radius: 4px !important;
    overflow: hidden;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    position: relative;

    .ant-tabs-tab-btn {
      color: ${colors.white};
      background-color: ${colors.brand};
    }
  }

  .ant-tabs-nav-wrap {
    overflow: visible !important;
  }

  .ant-tabs-nav-list {
    align-items: center;
    overflow-y: visible;
  }

  && .ant-tabs-tabpane {
    background-color: ${colors.gray30};
    border-radius: 12px;
    overflow: hidden;
  }
`;
