import { colors } from "@/helpers";
import { Col } from "antd";
import styled, { css } from "styled-components";

export const InsuranceWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  cursor: pointer;
  height: 88px;
  border-radius: 10px;
  align-items: center;

  ${({ active }) => {
    return active
      ? css`
          background-color: ${colors.cyanStatusBg};
          border: 1px solid #487bff;
        `
      : css`
          background-color: white;
          border: 1px solid ${colors.gray400};
        `;
  }}
`;

export const StyledColDate = styled(Col).attrs({ span: 24 })`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  background-color: ${colors.gray4};
  margin: 0 10px 14px 10px;
`;
export const DealerFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`;
export const FieldWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: space-around;
  align-items: center;
`;
