import React, { FC } from "react";
import { IBatchResponseToMutate } from "@/pages/InformationalLetters/utils";
import RowComponent from "./Row";

export interface ITreeProps {
  data: IBatchResponseToMutate[];
  sections: IBatchResponseToMutate[];
  setSections: (value: IBatchResponseToMutate[]) => void;
  onActionHandler: (
    event: "edit" | "add" | "delete",
    deleteSection?: any
  ) => void;
}

const Tree: FC<ITreeProps> = (props) => {
  const { data, sections, setSections, ...rest } = props;
  return (
    <>
      {sections?.map((el) => (
        <RowComponent
          key={el.id}
          section={el}
          setSections={setSections}
          sections={data}
          isLastChild={el.children?.length}
          {...rest}
        >
          {el.children && el.children.length ? (
            <Tree
              data={data}
              sections={el.children}
              setSections={setSections}
              {...rest}
            />
          ) : (
            <></>
          )}
        </RowComponent>
      ))}
    </>
  );
};

export default Tree;
