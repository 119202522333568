import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  useGetMonitoringQualityById,
  usePatchMonitoringQualityFamiliarize,
} from "@/helpers/api/monitoringQuality/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { Loader } from "@/packages/loader/Loader";
import { Card } from "antd";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  gridStyleDesc,
  gridStyleTitle,
  StyledCard,
  StyledRow,
} from "./index.styles";
import { generateQualityMonitoringData } from "./utils";
import { useTranslation } from "react-i18next";

export interface IMonitoringQualityForDealersProps {
  id: string;
}

export const MonitoringQualityFamiliarize: React.FC<
  IMonitoringQualityForDealersProps
> = (props) => {
  const { id } = props;
  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { data: monitoringQualityById } = useGetMonitoringQualityById({
    id: id,
  });
  const { t } = useTranslation();

  const { mutateAsync: familiarizeWithProgram } =
    usePatchMonitoringQualityFamiliarize();

  const monitoringQuality = useMemo(
    () => generateQualityMonitoringData(monitoringQualityById?.data),
    [monitoringQualityById?.data]
  );

  const isFamiliarized =
    monitoringQualityById?.data.qualityMonitoringDealers?.find(
      //@ts-ignore
      (dealer) => dealer.dealerId === profile?.dealerId
    );

  const onClickHandler = async () => {
    try {
      await familiarizeWithProgram(id as string);
      createNotificationSuccess(t("monitoring.programIntroSuccess") || "");
      navigate(
        `/monitoring-quality/${isFamiliarized?.qualityMonitoringId}/dealers-cars`
      );
    } catch (error) {
      createNotificationError(t("monitoring.programIntroError") || "");
    }
  };

  return (
    <PermissionsAdapter
      resourceName="quality-monitoring.familiarize"
      resourceAction="read"
      fallback={() => <span>No rights</span>}
    >
      <StyledCard bordered={false}>
        {monitoringQuality?.map((el, index) => {
          return (
            <StyledRow align="middle" key={index}>
              <Card.Grid hoverable={false} style={gridStyleTitle}>
                {el.title}
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyleDesc}>
                {el.description}
              </Card.Grid>
            </StyledRow>
          );
        })}
      </StyledCard>
      <PermissionsAdapter
        resourceName="quality-monitoring.familiarize"
        resourceAction="update"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48} />}
      >
        {isFamiliarized && (
          <Button
            color="brand"
            theme="primary"
            size="middle"
            onClick={onClickHandler}
            // isDisabled={isFamiliarized.familiarized}
          >
            {t("monitoring.programGetFamiliar") || ""}
          </Button>
        )}
      </PermissionsAdapter>
    </PermissionsAdapter>
  );
};
