import { useModal } from "@/hooks/useModal";
import { UserInfoModalProps } from "@/components/UserInfoModal/index";

export function useUserInfoModal() {
  const {
    modalState,
    openModal,
    closeModal,
  } = useModal<UserInfoModalProps, { userId: string }>();
  return {
    openModal,
    closeModal,
    modalState
  }
}
