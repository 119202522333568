import { InputField } from "@/packages/formElements/fields/InputField";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Popconfirm } from "antd";
import React, { FC, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  StyledChildren,
  StyledFormEditBatchModal,
  StyledInputWrapper,
} from "./index.styles";

import { ITreeProps } from "./Tree";
import { IAuditBatchResponseToMutate, removeIdDeep } from "./utils";
import { useTranslation } from "react-i18next";

export interface IRowComponentProps extends Omit<ITreeProps, "data"> {
  section: IAuditBatchResponseToMutate;
  children: React.ReactNode;
  index: number;
  hasChildren?: boolean;
}

const RowComponent: FC<IRowComponentProps> = (props) => {
  const {
    hasChildren,
    section,
    sections,
    setSections,
    children,
    onActionHandler,
  } = props;
  const { t } = useTranslation();
  const [inputSection, setInputSection] = useState(section);

  const addSubSectionHandler = useCallback(() => {
    const { id, order, children, level } = section;

    const index = children ? children.length + 1 : 1;

    const newSubSection = {
      id: uuidv4(),
      title: "",
      order: order + 1,
      parentId: id,
      level: `${level}.${index}`,
    };

    section.children = section.children
      ? [...section.children, newSubSection]
      : [newSubSection];

    setSections(JSON.parse(JSON.stringify(sections)));
    onActionHandler("add", newSubSection);
  }, [section, setSections, sections, onActionHandler]);

  const deleteSectionHandler = useCallback(() => {
    const filteredSections = removeIdDeep(sections, section.id);
    setSections(JSON.parse(JSON.stringify(filteredSections)));
    onActionHandler("delete", section);
  }, [section, setSections, sections, onActionHandler]);

  const editSection = (prop: string, event: string) => {
    setInputSection((prev) => ({ ...prev, title: event }));
    section[prop] = event;
    onActionHandler("edit", section);
  };

  return (
    <StyledFormEditBatchModal>
      <StyledInputWrapper>
        <InputField
          name={section.id}
          label={t("dealerMaterials.letters.sectionName") || ""}
          prefix={inputSection.level}
          value={inputSection.title}
          isBlock={true}
          onChange={(value) => {
            editSection("title", value);
          }}
        />
        <Popconfirm
          title={t("audits.templates.cantDelete") || ""}
          okText="Ok"
          showCancel={false}
          disabled={!hasChildren}
        >
          <IconButton
            variant="tertiary"
            icon="delete"
            color="brand"
            size="l"
            onClick={() => !hasChildren && deleteSectionHandler()}
          />
        </Popconfirm>
      </StyledInputWrapper>

      <IconButton
        variant="tertiary"
        icon="plus"
        color="brand"
        onClick={addSubSectionHandler}
      >
        {t("dealerMaterials.letters.addSection_2") || ""}
      </IconButton>

      {children ? <StyledChildren>{children}</StyledChildren> : <></>}
    </StyledFormEditBatchModal>
  );
};

export default RowComponent;
