import { css } from "styled-components";

export const fontStyles = css`
  :root {
    --font-kia: Kia Signature OTF;
    --font-brand: var(--font-kia);
  }

  @font-face {
    font-family: "Kia Signature OTF";
    src: local("Kia Signature OTF"), local("Kia-Signature-OTF"),
      url("../fonts/KiaSignatureLight.eot") format("eot"),
      url("../fonts/KiaSignatureLight.woff2") format("woff2"),
      url("../fonts/KiaSignatureLight.woff") format("woff"),
      url("../fonts/KiaSignatureLight.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Kia Signature OTF";
    src: local("Kia Signature OTF"), local("Kia-Signature-OTF"),
      url("../fonts/KiaSignatureRegular.eot?#") format("eot"),
      url("../fonts/KiaSignatureRegular.woff2") format("woff2"),
      url("../fonts/KiaSignatureRegular.woff") format("woff"),
      url("../fonts/KiaSignatureRegular.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Kia Signature OTF";
    src: local("Kia Signature OTF"), local("Kia-Signature-OTF"),
      url("../fonts/KiaSignatureBold.eot") format("eot"),
      url("../fonts/KiaSignatureBold.woff2") format("woff2"),
      url("../fonts/KiaSignatureBold.woff") format("woff"),
      url("../fonts/KiaSignatureBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
`;
