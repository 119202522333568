import {
  Datepicker,
  IDatepicker,
} from "@/packages/formElements/inputs/Datepicker";
import { TFunction } from "i18next";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "../Field";

export type IDatepickerField = Omit<IDatepicker, "error"> & {
  label?: string;
  error?: string;
  description?: string;
  isErrorAbsolute?: boolean;
  disabled?: boolean;
  isSameDateEnabled?: boolean;
  preventOpenOnFocus?: boolean;
};

const useInternalDateValidation = ({
  t,
  currentDate,
  maxDate,
  minDate,
  isSameDateEnabled,
}: {
  t: TFunction;
  currentDate?: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  isSameDateEnabled?: boolean;
}) => {
  if (currentDate) currentDate?.setHours(21, 0, 0, 0);
  if (maxDate) maxDate?.setHours(21, 0, 0, 0);
  if (minDate) minDate?.setHours(21, 0, 0, 0);

  let error = "";
  if (!isSameDateEnabled) {
    if (currentDate && maxDate && currentDate > maxDate) {
      error = `${t(
        "common.maxDateValidation"
      )} ${maxDate.toLocaleDateString()}`;
    } else if (currentDate && minDate && currentDate < minDate) {
      error = `${t(
        "common.minDateValidation"
      )} ${minDate.toLocaleDateString()}`;
    }

    if (
      minDate &&
      currentDate &&
      minDate.toLocaleDateString() === currentDate.toLocaleDateString()
    ) {
      error = `${t("kiaFlex.interval_1day") || ""}`;
    }
  }

  return { error };
};

const DatepickerField: FC<IDatepickerField> = (props) => {
  const {
    label,
    error,
    description,
    isErrorAbsolute,
    disabled,
    readOnly,
    maxDate,
    minDate,
    isSameDateEnabled = false,
    preventOpenOnFocus,
    ...selectProps
  } = props;
  const { t } = useTranslation();
  const { error: internalError } = useInternalDateValidation({
    t,
    currentDate: props.value,
    minDate,
    maxDate,
    isSameDateEnabled,
  });

  return (
    <Field>
      {label ? (
        <Field.Label readOnly={readOnly} disabled={disabled}>
          {label}
        </Field.Label>
      ) : (
        <></>
      )}
      <Field.FieldControlContainer>
        <Datepicker
          {...selectProps}
          readOnly={readOnly}
          disabled={disabled}
          error={!!error || !!internalError}
          maxDate={maxDate}
          minDate={minDate}
          preventOpenOnFocus={preventOpenOnFocus}
        />
      </Field.FieldControlContainer>

      {error || internalError ? (
        <Field.FieldError isErrorAbsolute={!!isErrorAbsolute}>
          {error || internalError}
        </Field.FieldError>
      ) : (
        <></>
      )}
      {description && (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      )}
    </Field>
  );
};

export { DatepickerField };
