import React from "react";

import { StyledPagination, StyledTabContent, } from "@/components/parts/index.styles";
import { TBodyRecord } from "@/helpers/api/bodies/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { getDefaultDictTableHeaders, IDefaultDictTab, } from "@/components/parts/utils";

export interface IBodyTypeTab extends IDefaultDictTab<TBodyRecord> {}

export const BodyTypeTab: React.FC<IBodyTypeTab> = (props) => {
  const {
    currentPage,
    onChangePage,
    totalItems,
    items,
    onEditClick,
    onDeleteClick,
    onSort,
    handleSearch,
  } = props;

  const headers = getDefaultDictTableHeaders<TBodyRecord>({
    onEditClick,
    onDeleteClick,
    isSearchable: true,
    isSortable: true,
  });

  return (
    <StyledTabContent>
      <TableSimple
        data={items}
        headers={headers}
        onSort={onSort}
        onSearch={handleSearch}
      />
      <StyledPagination
        current={currentPage}
        onChange={onChangePage}
        pageSize={10}
        total={totalItems}
      />
    </StyledTabContent>
  );
};
