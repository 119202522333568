import { colors } from "@/helpers";
import styled from "styled-components";
import { Button } from "@/packages/button/Button";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

export const StyledContent = styled.div`
  padding-bottom: 32px;
  padding-left: 32px;

  display: grid;
  gap: 24px;

  background-color: ${colors.white};
`;

export const StyledButton = styled(Button)`
  width: 110px;
`;

export const StyledDivider = styled.div`
  height: 1px;
  background-color: #eeeff2;
  width: 100%;
  margin: 32px 0px;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-top: 8px;
`;

export const StyledThirdRadioSelects = styled.div`
  display: flex;
  gap: 40px;
`;

export const StyledFormSelect = styled(FormSelect)`
  width: 330px;
  margin-bottom: 32px;
`;

export const StyledHeader = styled.div`
  margin-bottom: -32px;
  padding: 32px 32px 0 0;
  gap: 24px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  display: grid;
  gap: 24px;
  margin-right: 40px;
`;

export const StyledRadioContent = styled.div``;
export const StyledCheckboxesWrapper = styled.div`
  margin-bottom: 32px;
`;
