import { css } from "styled-components";
import { colors } from "@/helpers/styleColors";

export const antCustomStyles = css`
  .ant-popconfirm {
    .ant-btn-primary {
      background-color: ${colors.brand};
      border: 1px solid ${colors.brand};
    }

    .ant-btn-default {
      &:hover {
        border-color: ${colors.brand};
        color: ${colors.brand};
      }
    }
  }
`;
