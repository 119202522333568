import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { requiredSelectSimpleValueSchema } from "@/helpers/validation";
import { TFileRecord } from "@/helpers/api/files/types";

type Nullable<T> = T | null;

export interface IApplicationsFormValues {
  startEvent: Nullable<Date>;
  endEvent: Nullable<Date>;
  vehicleId: Nullable<string>;
  appointment: Nullable<string>;
  mediaId: string;
  statusId: Nullable<string>;
  requestPublicationLink: Nullable<string>;
  comment: Nullable<string>;
  receivingUser: Nullable<string>;
  fileId: TFileRecord | null;
}

export const DEFAULT_APPLICATIONS_NEW_FORM_VALUES: IApplicationsFormValues = {
  startEvent: null,
  endEvent: null,
  vehicleId: null,
  appointment: null,
  mediaId: " ",
  statusId: null,
  requestPublicationLink: null,
  comment: null,
  receivingUser: null,
  fileId: null,
};

export const formSchema = yup.object({
  startEvent: yup
    .date()
    .nullable()
    .required("Необходимо выбрать дату начала тест-драйва"),
  endEvent: yup
    .date()
    .nullable()
    .required("Необходимо выбрать дату окончания тест-драйва"),
  vehicleId: requiredSelectSimpleValueSchema("Необходимо выбрать автомобиль"),
  appointment: requiredSelectSimpleValueSchema("Необходимо выбрать назначение"),
  mediaId: requiredSelectSimpleValueSchema("Необходимо выбрать медиа"),
  statusId: requiredSelectSimpleValueSchema("Необходимо выбрать статус"),
});

export type TOnSubmitApplicationsFormCb = (
  values: IApplicationsFormValues
) => void;

export const useApplicationsNewForm = (
  onSubmit: TOnSubmitApplicationsFormCb
) => {
  const form = useForm<IApplicationsFormValues>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: DEFAULT_APPLICATIONS_NEW_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
  };
};
