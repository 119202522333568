import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { StyledFormCheckboxes, StyledFormGrid, StyledModalContentWrapper, } from "./index.styles";
import { ITemplateValues, useTemplateForm } from "./hooks";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormInputNumberField } from "@/packages/formElements/formFields/FormInputNumberField";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import {
  useCreateWarrantyParameter,
  useEditWarrantyParameter,
  useGetTemplateViolationList,
  useGetWarrantyParameterById,
} from "@/helpers/api/warranty-audit/templates/hooks";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

type IChangeWarrantyNameProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

export interface IUploadWarrantyModalPayload {
  sectionId?: string;
  selectedParamId?: string;
}

interface IUploadWarrantyTemplateBase {
  isDppo: boolean;
  payload: IUploadWarrantyModalPayload;
  onSubmit: () => void;
}

const MOCK_DEFAULT = [
  { label: "common.yes", value: true },
  { label: "common.no", value: false },
];

const MOCK_DEACEPT = [
  { label: "Claim", value: "Claim" },
  { label: "Sublet", value: "Sublet" },
  { label: "Parts", value: "Parts" },
  { label: "Labor", value: "Labor" },
];

export const AddWarrantyParametersModals: React.FC<
  IChangeWarrantyNameProps & IUploadWarrantyTemplateBase
> = (props) => {
  const { onSubmit, onCancel, payload, isDppo } = props;
  const { createNotificationError } = useNotification();

  const { data: parameters, isLoading: isParametersLoading } =
    useGetWarrantyParameterById(payload.selectedParamId);
  const { data: violations, isLoading: isViolationsLoading } =
    useGetTemplateViolationList({
      "page[limit]": 0,
      "page[page]": 0,
    });

  const preparedViolationsList = useMemo(
    () =>
      violations?.data?.data?.map((item) => ({
        label: `${item.code} ${item.description}`,
        value: item.id,
      })) || [],
    [violations?.data, isViolationsLoading]
  );

  const isLoading = isParametersLoading && !!payload.selectedParamId;
  const { t } = useTranslation();
  const { mutate: createParameter, isLoading: isCreating } =
    useCreateWarrantyParameter(isDppo);
  const { mutate: editParameter, isLoading: isUpdating } =
    useEditWarrantyParameter(isDppo);

  const handleSubmit = () => {
    onSubmit();
    form.reset();
  };

  const onFormChange = (value) => {
    if (payload.selectedParamId) {
      editParameter({
        id: payload.selectedParamId,
        body: { ...value },
        onSuccess: handleSubmit,
        onError: (error) =>
          createNotificationError(
            error.response?.data.message || error.message
          ),
      });
    } else {
      createParameter({
        data: { ...value, sectionId: payload.sectionId },
        onSuccess: handleSubmit,
        onError: (error) =>
          createNotificationError(
            error.response?.data.message || error.message
          ),
      });
    }
  };
  const [isManual, setIsManual] = useState(!!parameters?.data.manual);

  useEffect(() => {
    if (parameters?.data) {
      const defaultValue: ITemplateValues = {
        templateViolationId: parameters.data.templateViolation?.id ?? null,
        ...parameters.data,
      }
      form.reset(defaultValue);
      setIsManual(!!parameters?.data.manual);
    }
  }, [parameters]);

  const { form, onSubmitHandler } =
    useTemplateForm(onFormChange, isDppo, isManual);

  return (
    <Modal
      {...props}
      title={
        !payload.selectedParamId
          ? t("audits.templates.addParameters") || ""
          : t("audits.templates.editParameters") || ""
      }
      size="small"
      cancelButtonText={t("audits.templates.cancel") || ""}
      confirmButtonText={t("audits.templates.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={() => {
        onCancel && onCancel();
        form.reset();
      }}
      isDisabledCancelButton={isCreating || isUpdating || isLoading}
      isLoadingConfirmButton={isCreating || isUpdating || isLoading}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormTextAreaField
            name="title"
            label={t("warrantyAudits.templates.checkParam") || ""}
            placeholder={t("audits.templates.text") || ""}
            disabled={isLoading}
          />
          {!isDppo && (
            <StyledFormGrid>
              <FormSelect
                name="deacceptType"
                label={t("warrantyAudits.templates.deacceptType") || ""}
                options={MOCK_DEACEPT}
                disabled={isLoading}
              />
            </StyledFormGrid>
          )}
          <FormSelect
            name="templateViolationId"
            label={t("warrantyAudits.templates.violation") || ""}
            options={preparedViolationsList}
            disabled={isViolationsLoading}
          />
          <StyledFormGrid>
            {!isManual && !isDppo ? (
              <FormInputNumberField
                name="weight"
                type="number"
                label={t("audits.templates.weight") || ""}
                disabled={isLoading}
                isErrorAbsolute={true}
              />
            ) : (
              <></>
            )}

            <FormInputField
              isErrorAbsolute={true}
              name="code"
              label={t("warrantyAudits.templates.code") || ""}
            />
          </StyledFormGrid>

          <StyledFormCheckboxes>
            {!isManual ? (
              <FormSelect
                name="defaultValue"
                label={t("warrantyAudits.templates.default") || ""}
                options={MOCK_DEFAULT}
                disabled={isLoading}
              />
            ) : (
              <></>
            )}
            <FormCheckbox
              name="photo"
              label={t("audits.templates.photo") || ""}
              isDisabled={isLoading}
            />
          </StyledFormCheckboxes>
          {!isDppo && (
            <FormCheckbox
              value={isManual}
              onChange={(value) => {
                form.setValue("manual", value);
                setIsManual(value);
              }}
              name="manual"
              label={t("warrantyAudits.templates.manual") || ""}
              isDisabled={isLoading}
            />
          )}
          <StyledFormGrid>
            {isManual ? (
              <FormInputField
                name="transcript"
                label={t("warrantyAudits.templates.transcript") || ""}
                disabled={isLoading}
              />
            ) : (
              <></>
            )}
          </StyledFormGrid>

        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
