import styled from "styled-components";

export const StyledNewsTab = styled.div`
  margin-top: 24px;
`;

export const StyledNewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-auto-rows: 150px;
  gap: 32px;
`;
