import React from "react";
import { useExportWithdrawal, useGetWarrantyWithdrawal } from "@/helpers/api/warranty-audit/withdrawal/hooks";
import { AuditWarrantyTableAdapter } from "../../AuditWarrantyTableAdapter";
import { usePagination } from "@/hooks/usePagination";
import { getDefaultWarrantyWithdrawalTableHeaders } from "./helpers";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { t } from "i18next";
import { handleFileLoad } from "@/helpers/utils";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StyledParagraph } from "@/components/AuditWarranty/FillWarrantyStages/Violations/index.styles";

export const StyledExportButton = styled.div`
    margin-bottom: 30px;
`;

interface IViolations {
  id: number;
  openChat: (params: IMessageParams) => void;
}

export const Withdrawal: React.FC<IViolations> = (props) => {
  const { id } = props;
  const { setPage, paginationParams } = usePagination();
  const { data: withdrawal, isLoading } = useGetWarrantyWithdrawal({
    auditId: id,
  });

  const { mutateAsync: exportWithdrawal } = useExportWithdrawal();

  const handleExportWithdrawal = () => {
    exportWithdrawal(
      {
        auditId: id,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "exportWithdrawal.xlsx");
        },
      }
    );
  };

  const headers = getDefaultWarrantyWithdrawalTableHeaders({});

  return (
    <>
      <StyledExportButton>
        <Tooltip content={t("common.export") || ""} placement="bottom">
          <IconButton
            icon="save"
            isSquared
            variant="primary"
            color="white"
            size="m"
            onClick={handleExportWithdrawal}
          />
        </Tooltip>
      </StyledExportButton>
      {
        // @ts-ignore // неправильная дто на беке
        withdrawal?.data?.data &&
        // @ts-ignore // неправильная дто на беке
        withdrawal?.data?.data.length > 0 ?
          <AuditWarrantyTableAdapter
            autoOverflow
            setPage={setPage}
            paginationParams={{ ...paginationParams, limit: 0 }}
            // @ts-ignore // неправильная дто на беке
            data={withdrawal?.data?.data || []}
            // @ts-ignore // неправильная дто на беке
            headers={headers || []}
            isLoading={isLoading}
          />
          :
          <StyledParagraph>
            <Paragraph size={14}>
              {t("common.noFilled") || ""}
            </Paragraph>
          </StyledParagraph>
      }
    </>
  );
};
