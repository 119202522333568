import { useGetMaterialFiles } from "@/helpers/api/materials/hooks";
import { TMaterialFilesRecord, TMaterialsSectionRecord, } from "@/helpers/api/materials/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useNotification } from "@/hooks/useNotification";
import { DeletePopconfirm } from "@/packages/DeletePopconfirm";
import { IconButton } from "@/packages/icon-button/IconButton";
import { CustomCheckbox, IHeaderItem, } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import React, { useMemo } from "react";
import { StyledTable } from "./index.styles";
import { formatBytes } from "./utils";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/helpers/formatDate";
import { isAdminUserBySlug } from "@/helpers/utils";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "../UserInfoModal/index.styled";

export interface IMaterialsTable {
  onOpenEditModal?: (record: TMaterialsSectionRecord) => void;
  onDeleteMaterialSection: (ids: string[]) => void;
  parrentId: string;
  setCheckedIds: (checkedIds: string[]) => void;
  searchValue: string;
}

export const MaterialsTable: React.FC<IMaterialsTable> = (props) => {
  const {
    parrentId,
    onOpenEditModal,
    onDeleteMaterialSection,
    setCheckedIds,
    searchValue,
    ...rest
  } = props;
  const { t } = useTranslation();
  const {
    state: { profile },
  } = useProfile();

  const profileGroup = profile?.role?.groupId;

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const copyUrlClickedFile = async (fileUrl: string) => {
    try {
      await navigator.clipboard.writeText(fileUrl);
      createNotificationSuccess(
        t("dealerMaterials.letters.copyCurrentURL") || ""
      );
    } catch (error) {
      createNotificationError(
        t("dealerMaterials.letters.copyCurrentURLError") || ""
      );
    }
  };

  const { data: materialFiles } = useGetMaterialFiles({
    search: searchValue,
  });

  const materialFilesList = materialFiles?.data.data.filter(
    //@ts-ignore
    (material) => material.section.id === parrentId
  );

  const materialsMemoized = useMemo(() => {
    return (
      materialFilesList?.map((el) => {
        const { createdAt, createdBy, file, id, name } = el;
        return {
          id,
          createdAt: formatDate(createdAt, "dateAndTime"),
          createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
          createdById: createdBy.id,
          createdByUser: createdBy,
          size: formatBytes(file.size, 0),
          name: name ? name : file.fileName,
          file,
        };
      }) || []
    );
  }, [materialFiles]);

  const handleRowsChecked = (ids: string[]) => {
    const clickedRowIds = ids.reduce<string[]>((acc, id) => {
      const matchMaterial = materialsMemoized.find(
        (_, index) => Number(id) === index
      );

      if (matchMaterial) acc.push(matchMaterial.id);

      return acc;
    }, []);

    setCheckedIds(clickedRowIds);
  };

  const { dispatch } = useUserInfoModel();


  const headers: IHeaderItem<TMaterialFilesRecord>[] = [
    {
      // @ts-ignore
      Header: ({ getToggleAllRowsSelectedProps }) => {
        return (
          <CustomCheckbox
            {...getToggleAllRowsSelectedProps()}
            label={t("dealerMaterials.letters.chooseAll") || ""}
          />
        );
      },
      accessor: "checkbox",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row }) => {
        //@ts-ignore
        return <CustomCheckbox {...row.getToggleRowSelectedProps()} />;
      },
    },

    {
      Header: t("dealerMaterials.letters.name") || "",
      accessor: "name",
      width: 200,
    },

    {
      Header: t("dealerMaterials.letters.filesSize") || "",
      accessor: "size",
      width: 100,
    },
    {
      Header: t("dealerMaterials.letters.createdByName") || "",
      accessor: "createdBy",
      width: 200,
      Cell: (data) => {
        //@ts-ignore
        const id = data.cell.row.id;
        //@ts-ignore
        return (
        //@ts-ignore
          <StyledUserNameWrapper onClick={() => dispatch({ userId: data.data[id].createdById })}>
            {data.value}
          </StyledUserNameWrapper>
        )
      }

    },
    {
      Header: t("dealerMaterials.letters.createdAt") || "",
      accessor: "createdAt",
      width: 200,
    },

    {
      Header: "",
      id: "actions",
      width: 100,

      Cell: (data) => {
        const onClickDeleteHandler = onDeleteMaterialSection
          ? () => {
            onDeleteMaterialSection([data.row.original.id]);
          }
          : undefined;

        const onDownloadHandler = (
          fileUrl: string,
          fileName: string,
          extension: string
        ) => {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.target = "_blank";
          link.download = `${fileName}${extension}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        const { fileUrl } = data.row.original.file;
        const { name } = data.row.original;
        const { extension } = data.row.original.file;
        const { role } = data.row.original.createdByUser;

        const isProfileAdmin =
          isAdminUserBySlug(profile?.role?.slug) ||
          // @ts-ignore
          profile?.role?.slug === "kia-admin";

        return (
          <Space>
            <IconButton
              icon="download"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => onDownloadHandler(fileUrl, name, extension)}
            />

            <IconButton
              icon="share"
              color="black"
              size="l"
              variant="tertiary"
              onClick={() => copyUrlClickedFile(fileUrl)}
            />
            {((profile?.type === "kia" &&
                profileGroup &&
                role.groupId === profileGroup) ||
              isProfileAdmin) && (
              <DeletePopconfirm
                title={t("dealerMaterials.materials.deleteConfirm") || ""}
                onConfirm={onClickDeleteHandler}
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </DeletePopconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    //@ts-ignore
    <StyledTable
      headers={headers}
      data={materialsMemoized ?? []}
      onRowsChecked={handleRowsChecked}
    />
  );
};
