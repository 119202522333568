import { Space } from 'antd';
import React, { useMemo, useContext } from 'react';

import { PermissionsAdapter } from '@/adapters/shared/PermissionsAdapter';
import { IGenerationWithModifications, TGenerationRecord } from '@/helpers/api/generations/types';
import { TModelRecord } from '@/helpers/api/models/types';
import { TModificationRecord } from '@/helpers/api/modifications/types';
import { formatDate } from '@/helpers/formatDate';
import { usePermission } from '@/hooks/usePermission';
import { DeletePopconfirm } from '@/packages/DeletePopconfirm';
import { Switch } from '@/packages/formElements/inputs/switch';
import { IconButton } from '@/packages/icon-button/IconButton';
import { IDataItem, IHeaderItem, INestedTable, SortOrder } from '@/packages/nestedTable/NestedTable';
import { StyledModelListTable } from './index.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContextProfile } from '@/hooks/contexts/useProfile';

export interface IModelListTable {
    onCreateModelClick: INestedTable['onCreateModel'];
    onEditModelClick?: (record: TModelRecord) => void;
    onModelActiveChange?: (record: TModelRecord) => void;
    onDeleteModelClick?: (record: TModelRecord) => void;
    openConfirmModal: (additionModalState: { text: string }) => void;
    onViewGridClick?: (record: TModificationRecord) => string | undefined;
    onCreateGenerationClick: INestedTable['onCreateGeneration'];
    onActiveChange: (value: boolean, row: IDataItem) => void;
    onEditGenerationClick: (
        record: TGenerationRecord,
        parentModel: TModelRecord,
    ) => void;
    onGenerationActiveChange?: (
        record: TGenerationRecord,
        parentModel: TModelRecord,
    ) => void;
    onDeleteGenerationClick: (record: TGenerationRecord) => void;
    onCreateCompleteSetClick: INestedTable['onCreateCompleteSetClick'];
    onEditCompleteSetClick: (
        record: TModificationRecord,
        parentModel: TModelRecord,
    ) => void;
    onDeleteCompleteSetClick: (record: TModificationRecord) => void;
    data: TModelRecord[];
    onOpenGeneration?: (
        generationId: string,
        generationRecord: TGenerationRecord,
    ) => void;
    onSort: (accessor: string, order: SortOrder) => void;
    onSearch?: (key: string, value?: string) => void;
}

const mapEquipmentToTable = (
    equipment: TModificationRecord,
    parentModel: TModelRecord,
    parentGeneration: TGenerationRecord,
) => {
    return {
        ...equipment,
        type: 'equipment',
        //@ts-ignore
        name: equipment?.mpv
            ? //@ts-ignore
            `${equipment?.mpv} ${equipment.fullName}`
            : equipment.fullName,
        parentModel,
        parentGeneration,
    };
};

const mapGenerationToTable = (
    generation: IGenerationWithModifications,
    parentModel: TModelRecord,
) => {
    return {
        ...generation,
        name: generation.name, // поле  Название поколения в модалке Создания / Изменение поколения
        type: 'generation',
        year: `${generation.generationYearStart} - ${
            generation.generationYearEnd ? generation.generationYearEnd : '...'
        }`,
        parentModel,
        child: generation?.modifications?.map((modification) =>
            mapEquipmentToTable(modification, parentModel, generation),
        ),
    };
};

const mapModelToTable = (model: TModelRecord) => {
    return {
        ...model,
        type: 'model',
        year: `${model.modelYearStart} - ${
            model.modelYearEnd ? model.modelYearEnd : '...'
        }`,
        child: model?.generations?.map((generation) =>
            mapGenerationToTable(generation, model),
        ),
    };
};

export const ModelListTable: React.FC<IModelListTable> = (props) => {
    const {
        data,
        onEditModelClick: onEditModel,
        onModelActiveChange,
        onDeleteModelClick: onDeleteModel,
        onEditGenerationClick: onEditGeneration,
        onGenerationActiveChange,
        onDeleteGenerationClick: onDeleteGeneration,
        onActiveChange,
        onViewGridClick: onViewGrid,
        onEditCompleteSetClick,
        onDeleteCompleteSetClick,
        onCreateModelClick,
        onCreateGenerationClick,
        openConfirmModal,
        onSort,
        ...restProps
    } = props;

    //@ts-ignore
    const { hasAccess } = usePermission('model', 'create');
    const navigate = useNavigate();

    const mappedData = useMemo(() => {
        return data.map(mapModelToTable);
    }, [ data ]);

    const { t } = useTranslation();
    const {
        state: { profile },
    } = useContext(ContextProfile);

    const isDealerType = profile?.type === 'dealer';
    const headers: IHeaderItem[] = useMemo(
        () => [
            {
                Header: t('models.modelName') || '',
                accessor: 'name',
                isSortable: true,
                isSearchable: true,
                isOpening: true,
            },
            {
                Header: t('models.years') || '',
                accessor: 'year',
                isSortable: true,
            },
            {
                Header: t('models.generation') || '',
                accessor: 'model',
                Cell({ dataItem }) {
                    let str = '';

                    if (dataItem.type === 'model') {
                        const newDataItem = dataItem as TModelRecord;
                        const uniqueCodes = [
                            ...new Set(
                                newDataItem.generations?.map(
                                    (gen) =>
                                        gen.name +
                                        (gen.restylingName ? ` ${gen.restylingName}` : ''),
                                ),
                            ),
                        ];

                        str = uniqueCodes.join(', ');
                    }

                    if (dataItem.type === 'generation') {
                        const newDataItem = dataItem as TGenerationRecord;

                        str = `${newDataItem.name} ${
                            newDataItem.restylingName ? newDataItem.restylingName : ''
                        }`;
                    }

                    if (dataItem.type === 'equipment') {
                        str = `${dataItem.vinEngineTransmission?.join(', ')
                                         ?.toUpperCase() ?? ''}`;
                    }

                    return <>{str}</>;
                },
            },
            {
                Header: t('models.lastModified') || '',
                accessor: 'updated_at',
                isSortable: true,
                Cell: ({ dataItem }) => {
                    if (dataItem.updatedAt) {
                        const dateStr = formatDate(dataItem.updatedAt, 'dateAndTime');
                        return <>{dateStr}</>;
                    }
                    return null;
                },
            },

            {
                Header: t('models.active') || '',
                accessor: 'buttons',
                isFixedWidth: 100,
                Cell({ dataItem: tmpDataItem }) {
                    if (tmpDataItem.type === 'model') {
                        const dataItem = tmpDataItem as TModelRecord;

                        const onEditButtonClick = () => {
                            onEditModel && onEditModel(dataItem);
                        };

                        const onActiveChange = (value: boolean) => {
                            const newData = { ...dataItem, isActive: value };

                            onModelActiveChange && onModelActiveChange(newData);
                        };

                        return (
                            <Space size={60}>
                                <Switch
                                    value={dataItem.isActive}
                                    onChange={onActiveChange}
                                    readOnly={!hasAccess}
                                />

                                {
                                    !isDealerType &&
                                    <IconButton
                                        size='l'
                                        icon={hasAccess ? 'edit' : 'info'}
                                        variant='tertiary'
                                        color='black'
                                        onClick={onEditButtonClick}
                                    />
                                }
                            </Space>
                        );
                    } else if (tmpDataItem.type === 'generation') {
                        const dataItem = tmpDataItem as TGenerationRecord & {
                            parentModel: TModelRecord;
                        };

                        const onEditButtonClick = () => {
                            onEditGeneration &&
                            onEditGeneration(dataItem, dataItem.parentModel);
                        };

                        const onActiveChange = (value: boolean) => {
                            const newData = { ...dataItem, isActive: value };

                            onGenerationActiveChange &&
                            onGenerationActiveChange(newData, newData.parentModel);
                        };

                        return (
                            <Space size={24}>
                                <Switch
                                    value={dataItem.isActive}
                                    onChange={onActiveChange}
                                    readOnly={!hasAccess}
                                />


                                {
                                    !isDealerType &&
                                    <IconButton
                                        size='l'
                                        icon={hasAccess ? 'edit' : 'info'}
                                        variant='tertiary'
                                        color='black'
                                        onClick={onEditButtonClick}
                                    />
                                }
                            </Space>
                        );
                    } else if (tmpDataItem.type === 'equipment') {
                        const dataItem = tmpDataItem as TModificationRecord & {
                            parentModel: TModelRecord;
                        };

                        const onClickMaintenanceGridHandler = () => {
                            if (!onViewGrid) {
                                return;
                            }
                            const url = onViewGrid(dataItem);
                            if (!url) {
                                openConfirmModal({ text: 'Для данной модификации не создано сеток ТО' });
                                return;
                            }
                            navigate(url);
                        };

                        const onClickEditHandler = () => {
                            onEditModel &&
                            onEditCompleteSetClick(dataItem, dataItem.parentModel);
                        };

                        const onClickDeleteHandler = () => {
                            onDeleteModel && onDeleteCompleteSetClick(dataItem);
                        };

                        return (
                            <Space size={24}>
                                <a href={onViewGrid ? onViewGrid(dataItem) : undefined}>
                                    <IconButton
                                        size='l'
                                        icon='grid-16'
                                        variant='tertiary'
                                        color='black'
                                        onClick={onClickMaintenanceGridHandler}
                                    />
                                </a>
                                {
                                    !isDealerType &&
                                    <>
                                        <IconButton
                                            size='l'
                                            icon={hasAccess ? 'edit' : 'info'}
                                            variant='tertiary'
                                            color='black'
                                            onClick={onClickEditHandler}
                                        />
                                        <PermissionsAdapter
                                            resourceName='model'
                                            resourceAction='delete'
                                        >
                                            <DeletePopconfirm
                                                title='Вы действительно хотите удалить данную комплектацию?'
                                                onConfirm={onClickDeleteHandler}
                                            >
                                                <IconButton
                                                    size='l'
                                                    icon='delete'
                                                    variant='tertiary'
                                                    color='black'
                                                />
                                            </DeletePopconfirm>
                                        </PermissionsAdapter>
                                    </>
                                }
                            </Space>
                        );
                    }

                    return null;
                },
            },
        ],
        [ hasAccess ],
    );

    return (
        <StyledModelListTable
            {...restProps}
            onSort={onSort}
            headers={headers}
            data={mappedData}
            onCreateModel={onCreateModelClick}
            onCreateGeneration={onCreateGenerationClick}
            isReadOnly={!hasAccess}
        />
    );
};
