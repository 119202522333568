import { message as AntMessage } from "antd";
import React from "react";

export const useNotification = () => {
  const createNotificationMethods = React.useMemo(() => {
    return {
      createNotificationSuccess: (message: string) => {
        AntMessage.success(message);
      },

      createNotificationError: (message: string | string[]) => {
        if (typeof message === "string") {
          AntMessage.error(message);
        } else {
          message.forEach((msg) => AntMessage.error(msg));
        }
      },

      createNotificationWarning: (message: string) => {
        AntMessage.warning(message);
      },
    };
  }, []);

  return createNotificationMethods;
};
