import React from "react";
import {
  StyledCardInfo,
  StyledCardInfoConfirmButton,
  StyledCardInfoDescription,
  StyledCardInfoTitle,
} from "./index.styles";

export type CardInfoProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  confirmButton?: React.ReactNode;
  onClickConfirmButton?: () => void;
};

export const CardInfo: React.FC<CardInfoProps> = (props) => {
  const { className, title, description, confirmButton, onClickConfirmButton } =
    props;

  return (
    <StyledCardInfo className={className}>
      {title ? (
        <StyledCardInfoTitle level={2} weight={400}>
          {title}
        </StyledCardInfoTitle>
      ) : (
        <></>
      )}
      {description ? (
        <StyledCardInfoDescription size={16}>
          {description}
        </StyledCardInfoDescription>
      ) : (
        <></>
      )}
      {confirmButton ? (
        <StyledCardInfoConfirmButton
          isBlock={true}
          theme="primary"
          onClick={onClickConfirmButton}
          color="brand"
          size="middle"
        >
          {confirmButton}
        </StyledCardInfoConfirmButton>
      ) : (
        <></>
      )}
    </StyledCardInfo>
  );
};
