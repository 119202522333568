import React from "react";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Col, Row } from "antd";
import { UseFormReturn } from "react-hook-form";
import { FieldWrapper, StyledColDate, StyledDash } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IinsuranceFieldsProps {
  form: UseFormReturn;
  insuranceFields: string[];
  isDisabled?: boolean;
}

export const InsuranceFields: React.FC<IinsuranceFieldsProps> = (props) => {
  const { form, insuranceFields, isDisabled } = props;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={24}>
          <FormInputField
            name={insuranceFields[0]}
            label={t("kiaFlex.insurant") || ""}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInputField
            name={insuranceFields[1]}
            label={t("kiaFlex.policy") || ""}
            description={t("kiaFlex.policy") || ""}
            isDisabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <FormInputField
            name={insuranceFields[2]}
            label={t("kiaFlex.insuranceCompanyName") || ""}
            description={t("kiaFlex.insuranceCompany") || ""}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <StyledColDate>
          <Col span={10}>
            <FormDatepickerField
              name={insuranceFields[3]}
              label={t("kiaFlex.insurantPeriod") || ""}
              maxDate={form.watch(insuranceFields[4])}
              isDisabled={isDisabled}
            />
          </Col>
          <StyledDash />
          <Col span={10}>
            <FormDatepickerField
              name={insuranceFields[4]}
              label={t("kiaFlex.to") || ""}
              minDate={form.watch(insuranceFields[3]) || ""}
              isDisabled={isDisabled}
            />
          </Col>
        </StyledColDate>
      </Row>

      <FieldWrapper>
        <FormCheckbox name={insuranceFields[5]} isDisabled={isDisabled} />

        <Paragraph size={12}>{t("kiaFlex.persons") || ""}</Paragraph>
      </FieldWrapper>
    </>
  );
};
