import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { useParams } from "react-router-dom";
import { ITab, Tabs } from "@/packages/tabs/Tabs";
import { UserLogs } from "@/pages/Admin/UserLogs";
import { useActiveTab } from "@/hooks/useActiveTab";
import { UserEdit } from "@/pages/Admin/UserEdit";

export const UserCard = () => {
  const params = useParams();
  const userId = params.id as string;
  const [isTabChanged, setIsTabChanged] = useState<ITab>();
  const tabs: ITab[] = [
    {
      name: "Информация",
      slug: "info",
      content: (
        <>
          <UserEdit
            id={userId}
          />
        </>
      ),
    },
    {
      name: "Лог изменений",
      slug: "logs",
      content: (
        <>
          <UserLogs
            id={userId}
            isTabChanged={isTabChanged}
          />
        </>
      ),
    },
  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  useEffect(() => {
    if (activeTab.name !== isTabChanged?.name) {
      setIsTabChanged(activeTab);
    }
  }, [activeTab]);



  return (
    <DashboardLayout title='Страница пользователя' toBack={-1}>
      <PermissionsAdapter
        resourceName="admin.user"
        resourceAction="update"
        fallback={() => <p>Access denied</p>}
      >
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onChange={setActiveTab}
          type="card"
        />
      </PermissionsAdapter>
    </DashboardLayout>
  )
}