import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TExportGsmRequest,
  TExportGsmResponse,
  TGetGsmListRequest,
  TGetGsmListResponse,
  TGsmCreateRequest,
  TGsmCreateResponse,
  TGsmDeleteRequest,
  TGsmDeleteResponse,
  TGsmUpdateRequest,
  TGsmUpdateResponse,
} from "./types";
import { AxiosError } from "axios";
import { useNotification } from "@/hooks/useNotification";

export const useGetGsmListDefault = (isEnabled = true) => {
  return useQuery(
    ["gsmListDefault", isEnabled],
    () =>
      api.get<TGetGsmListResponse>("/materials", {
        params: { isDefault: true },
      }),
    { enabled: isEnabled }
  );
};

export const useGetGsmList = (
  request: TGetGsmListRequest,
  isEnabled = true
) => {
  return useQuery(
    ["gsmList", request],
    () => api.get<TGetGsmListResponse>("/materials", { params: request }),
    { enabled: isEnabled }
  );
};

export const useCreateGsm = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: TGsmCreateRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) =>
      api.post<TGsmCreateResponse>("/materials",
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["gsmList"]);
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useUpdateGsm = () => {
  const queryClient = useQueryClient();
  const { createNotificationError } = useNotification();
  return useMutation(
    (data: {
      id: TGsmUpdateRequest["id"];
      data: TGsmCreateRequest;
      onCancel?: () => void;
    }) =>
      api.patch<TGsmUpdateResponse>(`/materials/${data.id}`, data.data),
    {
      onSuccess: (_, variables) => {
        variables.onCancel && variables.onCancel();
        queryClient.invalidateQueries(["gsm", variables.id]);
        queryClient.invalidateQueries(["gsmList"]);
        queryClient.invalidateQueries(["sparesList"]);
      },
      onError: (error: AxiosError<any>) => {
        createNotificationError(
          error?.response?.data?.message
        );
      },
    }
  );
};

export const useDeleteGsm = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TGsmDeleteRequest) =>
      api.delete<TGsmDeleteResponse>(`/materials/${data.id}`),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["gsm", variables.id]);
        queryClient.invalidateQueries(["gsmList"]);
      },
    }
  );
};

export const useExportGSM = () => {
  return useMutation((request: TExportGsmRequest) => {
    return api.get<TExportGsmResponse>(`/materials/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
