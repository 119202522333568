import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px 91px;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid ${colors.neutral150};
  border-left: none;
  border-right: none;
  p {
    text-align: center;
  }
`;

export const StyledMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  max-height: 550px;
  overflow-y: scroll;
`;

export const StyledErrorParagraph = styled(Paragraph)`
  width: 295px;
`;

export const StyledMessageParagraph = styled(Paragraph)`
  width: 100%;
  padding: 10px;
`;
