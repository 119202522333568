import React, { FC, useContext, useEffect, useMemo } from "react";
import {
  HeaderArgType,
  IHeaderItem,
  SortOrder,
  TableSimple,
} from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import {
  StyledContent,
  StyledDivider,
  StyledHeader,
  StyledTitle,
  StyledTitleWrapper,
} from "../index.styles";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Button } from "@/packages/button/Button";
import { Pagination } from "@/packages/pagination/Pagination";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { TGsmRecord } from "@/helpers/api/maintenanceGrids/gsm/types";
import { Placeholder } from "@/packages/placeholder/Placeholder";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useExportGSM } from "@/helpers/api/maintenanceGrids/gsm/hooks";
import { handleFileLoad } from "@/helpers/utils";

interface IGsmTab {
  hasEdit: boolean;
  onOpenCreateModalGsm?: () => void;
  onOpenCreateModalSpare?: () => void;
  onOpenEditModal?: () => void;
  onOpenSparesEditModal?: () => void;
  handleSort: (accessor: HeaderArgType, order: SortOrder) => void;
  handleSparesSort: (accessor: HeaderArgType, order: SortOrder) => void;
}

interface IDataItem extends TGsmRecord {
  actions?: {
    isDefault?: boolean;
    onDefaultChange?: (value: boolean, rowItem: IDataItem) => void;
    onDelete?: (row: IDataItem) => void;
    onEdit?: (row: IDataItem) => void;
  };
}

const headersGSM = (hasEdit: boolean): IHeaderItem<IDataItem>[] => [
  {
    Header: t("maintenanceGrids.gsmModel.name") || "",
    accessor: "name",
    isSearchable: true,
    width: "250px",
    minWidth: 250,
    maxWidth: 250,
  },
  {
    Header: t("maintenanceGrids.gsmModel.code") || "",
    accessor: "code",
    isSortable: true,
  },
  {
    Header: t("maintenanceGrids.gsmModel.price") || "",
    accessor: "pricePerLiter",
  },
  {
    Header: t("maintenanceGrids.gsmModel.actions") || "",
    accessor: "actions",
    width: "120px",
    minWidth: 120,
    maxWidth: 120,
    Cell: ({ value, row }) => {
      return (
        <Space align="center">
          {hasEdit ? (
            <Tooltip
              content={
                <div>{t("maintenanceGrids.gsmModel.deleteGsm") || ""}</div>
              }
              placement={"top"}
            >
              <div>
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => value?.onDelete(row.original)}
                />
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip
            content={
              <div>
                {hasEdit
                  ? t("maintenanceGrids.gsmModel.edit") || ""
                  : t("maintenanceGrids.gsmModel.view") || ""}
              </div>
            }
            placement={"top"}
          >
            <div>
              <IconButton
                icon={hasEdit ? "edit" : "eye-open"}
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => value?.onEdit(row.original)}
              />
            </div>
          </Tooltip>
        </Space>
      );
    },
  },
];

const headersSpares = (hasEdit: boolean): IHeaderItem<IDataItem>[] => [
  {
    Header: "Название запчасти",
    accessor: "name",
    isSearchable: true,
    width: "250px",
    minWidth: 250,
    maxWidth: 250,
  },
  {
    Header: "Код запчасти",
    accessor: "code",
    isSortable: false,
  },
  {
    Header: "Цена Запчасти за 1шт",
    accessor: "price",
    isSortable: true,
  },
  {
    Header: t("maintenanceGrids.gsmModel.actions") || "",
    accessor: "actions",
    width: "120px",
    minWidth: 120,
    maxWidth: 120,
    Cell: ({ value, row }) => {
      return (
        <Space align="center">
          {hasEdit ? (
            <Tooltip
              content={
                <div>{t("maintenanceGrids.gsmModel.deleteGsm") || ""}</div>
              }
              placement={"top"}
            >
              <div>
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => value?.onDeleteSpare(row.original)}
                />
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip
            content={
              <div>
                {hasEdit
                  ? t("maintenanceGrids.gsmModel.edit") || ""
                  : t("maintenanceGrids.gsmModel.view") || ""}
              </div>
            }
            placement={"top"}
          >
            <div>
              <IconButton
                icon={hasEdit ? "edit" : "eye-open"}
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => {
                  value?.onEditSpare(row.original);
                }}
              />
            </div>
          </Tooltip>
        </Space>
      );
    },
  },
];

const GsmTab: FC<IGsmTab> = (props) => {
  const {
    hasEdit,
    onOpenCreateModalGsm,
    onOpenCreateModalSpare,
    onOpenEditModal,
    handleSort,
    onOpenSparesEditModal,
    handleSparesSort,
  } = props;
  const { t } = useTranslation();

  const {
    state: {
      gsmList,
      sparesGrids,
      currentPage,
      debouncedSearchValueGsm,
      debouncedSearchValueSpares,
      worksRequestParams,
      currentGridsPage,
    },
    mutations: {
      setPage,
      setSearchValueGsm,
      setActiveGsm,
      setActiveSpare,
      setGridsPage,
      setSearchValueSpares,
    },
    actions: { deleteGsm, updateGsm, deleteSpare },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const handleEditGsmButtonClick = (row: IDataItem) => {
    setActiveGsm(row);
    onOpenEditModal && onOpenEditModal();
  };

  const handleEditSpareButtonClick = (row: IDataItem) => {
    setActiveSpare(row);
    onOpenSparesEditModal && onOpenSparesEditModal();
  };

  const handleDeleteButtonClick = (row: IDataItem) => {
    deleteGsm({ id: row.id });
  };

  const handleDeleteSpareButtonClick = (row: IDataItem) => {
    deleteSpare({ id: row.code });
  };

  const handleGsmSearch = (key: string, value?: string) => {
    setSearchValueGsm(value || "");
  };
  const handleSparesSearch = (key: string, value?: string) => {
    setSearchValueSpares(value || "");
  };

  const handleDefaultChange = (value: boolean, row: IDataItem) => {
    updateGsm({ id: row.id, data: { ...row, isDefault: value } });
  };

  const metaGsm = gsmList?.meta;
  const metaSpares = sparesGrids?.meta;
  const adaptGsmList = useMemo(() => {
    return (
      gsmList?.data.map((item) => ({
        ...item,
        actions: {
          isDefault: item.isDefault,
          onEdit: handleEditGsmButtonClick,

          onDelete: handleDeleteButtonClick,
          onDefaultChange: handleDefaultChange,
        },
      })) || []
    );
  }, [gsmList]);

  const adaptSparesList = useMemo(() => {
    return (
      sparesGrids?.data.map((item) => ({
        ...item,
        actions: {
          onEditSpare: handleEditSpareButtonClick,
          onDeleteSpare: handleDeleteSpareButtonClick,
        },
      })) || []
    );
  }, [sparesGrids]);

  const hasGsm = adaptGsmList.length > 0;
  const hasSpares = adaptSparesList.length > 0;

  const { mutateAsync: exportGSMList } = useExportGSM();

  const handleGSMExport = () => {
    exportGSMList(
      {
        name: debouncedSearchValueGsm,
        ...worksRequestParams,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "GridsGSM.xlsx");
        },
      }
    );
  };

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchValueGsm]);

  return (
    <StyledContent>
      <StyledHeader>
        <StyledTitleWrapper>
          <StyledTitle>
            <Paragraph size={16} color="black">
              {t("maintenanceGrids.gsmModel.gsm") || ""}
            </Paragraph>
            <Paragraph size={10} color="gray3">
              {`${t("maintenanceGrids.worksModel.worksNum") || ""}
            ${gsmList?.meta?.itemCount}
            ${t("maintenanceGrids.gsmType") || ""}`}
            </Paragraph>
          </StyledTitle>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleGSMExport}
            />
          </Tooltip>
        </StyledTitleWrapper>

        <Button
          color="brand"
          size="middle"
          theme="primary"
          onClick={onOpenCreateModalGsm}
          isDisabled={!hasEdit}
        >
          {t("maintenanceGrids.gsmModel.createNewGsm") || ""}
        </Button>
      </StyledHeader>

      {hasGsm || debouncedSearchValueGsm.length !== 0 ? (
        <>
          <TableSimple
            headers={headersGSM(hasEdit)}
            data={adaptGsmList}
            headerParams={{
              color: "white",
            }}
            onSearch={handleGsmSearch}
            onSort={handleSort}
          />
          <Pagination
            current={currentPage}
            onChange={setPage}
            total={metaGsm?.pageCount}
          />
        </>
      ) : (
        <Placeholder>{t("maintenanceGrids.gsmModel.noGsm") || ""}</Placeholder>
      )}
      <StyledDivider></StyledDivider>

      <StyledHeader>
        <StyledTitleWrapper>
          <StyledTitle>
            <Paragraph size={16} color="black">
              {t("maintenanceGrids.gsmModel.spares") || ""}
            </Paragraph>
            <Paragraph size={10} color="gray3">
              {`${t("maintenanceGrids.worksModel.worksNum") || ""}
            ${sparesGrids?.meta?.itemCount}
           ${t("maintenanceGrids.sparesType") || ""}`}
            </Paragraph>
          </StyledTitle>
        </StyledTitleWrapper>

        <Button
          color="brand"
          size="middle"
          theme="primary"
          onClick={onOpenCreateModalSpare}
          isDisabled={!hasEdit}
        >
          {t("maintenanceGrids.gsmModel.createNewSpare") || ""}
        </Button>
      </StyledHeader>

      {hasSpares || debouncedSearchValueSpares.length !== 0 ? (
        <>
          <TableSimple
            headers={headersSpares(hasEdit)}
            // @ts-ignore
            data={adaptSparesList}
            headerParams={{
              color: "white",
            }}
            onSearch={handleSparesSearch}
            onSort={handleSparesSort}
          />
          <Pagination
            current={currentGridsPage}
            onChange={setGridsPage}
            total={metaSpares?.pageCount}
          />
        </>
      ) : (
        <Placeholder>{"У вас пока нет созданных запчастей"}</Placeholder>
      )}
    </StyledContent>
  );
};

export { GsmTab };
