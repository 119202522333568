import styled from "styled-components";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormBooleanSelectField } from "@/packages/formElements/formFields/FormBooleanSelectField";

export const StyledRegistryFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledFormSelectName = styled(FormSelect)`
  min-width: 400px;
`;

export const StyledFormSelectRegion = styled(FormSelect)`
  min-width: 200px;
`;

export const StyledFormSelectCity = styled(FormSelect)`
  min-width: 325px;
`;

export const StyledFormInputField = styled(FormInputField)``;

export const StyledFormBooleanSelectField = styled(FormBooleanSelectField)``;
