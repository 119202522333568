import { useQuery } from "@tanstack/react-query";
import { AxiosResponse, AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TGetDecoderInfoRequest,
  TGetDecoderInfoResponse,
  TGetDecoderPurchaseRequest,
  TGetDecoderPurchaseResponse,
  TGetDecoderMaintenanceGridResponse,
  TGetDecoderMaintenanceGridRequest,
} from "./types";

export const useGetDecoderInfo = ({
  vin,
  onSuccess,
  onError,
}: TGetDecoderInfoRequest & {
  onSuccess?: (response: AxiosResponse<TGetDecoderInfoResponse>) => void;
  onError?: (response: AxiosError) => void;
}) => {
  return useQuery(
    ["decoderInfo", vin],
    () => api.get<TGetDecoderInfoResponse>(`/decoder/${vin}/extended`),
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
      },
      onError: (response) => {
        onError && onError(response as AxiosError);
      },
      enabled: false,
    }
  );
};

export const useGetDecoderPurchase = ({
  vin,
  onSuccess,
  onError,
}: TGetDecoderPurchaseRequest & {
  onSuccess?: (response: AxiosResponse<TGetDecoderPurchaseResponse>) => void;
  onError?: (response: AxiosError) => void;
}) => {
  return useQuery(
    ["decoderPurchase", vin],
    () => api.get<TGetDecoderPurchaseResponse>(`/decoder/${vin}/purchase`),
    {
      enabled: false,
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
      },
      onError: (response) => {
        onError && onError(response as AxiosError);
      },
    }
  );
};

export const useGetDecoderMaintenanceGrid = ({
  vin,
  onSuccess,
  onError,
}: TGetDecoderMaintenanceGridRequest & {
  onSuccess?: (
    response: AxiosResponse<TGetDecoderMaintenanceGridResponse>
  ) => void;
  onError?: (response: AxiosError) => void;
}) => {
  return useQuery(
    ["decoderMaintenanceGrid", vin],
    () =>
      api.get<TGetDecoderMaintenanceGridResponse>(
        `/decoder/${vin}/maintenance-grid`
      ),
    {
      enabled: false,
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
      },
      onError: (response) => {
        onError && onError(response as AxiosError);
      },
    }
  );
};
