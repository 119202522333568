import styled from "styled-components";

export const StyledUserNameWrapper = styled.div`
    cursor: pointer;
    text-decoration: underline;
`;

export const StyledModalWrapper = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
`;
export const StyledDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;