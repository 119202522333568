import styled from "styled-components";

export const StyledDetailRegistryEmployeesTab = styled.div`
  margin-top: 24px;
`;

export const StyledTableWrapper = styled.div`
  display: grid;
  gap: 24px;
  justify-items: center;
`;
