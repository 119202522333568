import { IBatchResponseToMutate } from "@/pages/InformationalLetters/utils";
import * as yup from "yup";

export const removeIdDeep = (
  data: IBatchResponseToMutate[],
  idToRemove: string
) => {
  const filtered = data.filter((entry) => entry.id !== idToRemove);
  return filtered.map((entry) => {
    if (!entry.children) return entry;
    return { ...entry, children: removeIdDeep(entry.children, idToRemove) };
  });
};

export const batchIPFieldsSchema = yup.array().of(
  yup.object({
    event: yup.mixed().oneOf(["add", "edit", "delete"]),
    payload: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        name: yup
          .string()
          .min(1, "Название не должно быть пустое")
          .max(30, "Максимально допустимое количество символов - 30"),
        parrent_id: yup.string(),
        order: yup.number(),
      })
    ),
  })
);
