import { colors } from "@/helpers";
import { Icon } from "@/packages/icon/Icon";
import styled from "styled-components";

export const StyledWrapperLink = styled.a`
  text-decoration: none;
  color: ${colors.brand};
  &:hover {
    cursor: pointer;
    color: ${colors.brand};
  }
`;
export const StyledWrapperIcon = styled.div`
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7eaff;
    border: 1px solid #e4e7ec;
    border-radius: 4px;
`;
export const StyledCloseIcon = styled(Icon).attrs({
  name: "close-16",
  size: 12,
})`
  position: absolute;
  top: 25px;
  right: 0;
  margin: 10px;
  :hover {
    cursor: pointer;
  }
  z-index: 1000;
`;
export const StyledButton = styled.button`
  width: 12px;
  height: 12px;
  border: none;
  position: absolute;
  background: #e7eaff;
  top: 35px;
  right: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const StyledWrapperCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 7px 0;
`;
