import {
  TBonusesDto,
  TFactorZ,
} from "@/helpers/api/bonuses/calculations/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/stickyTable/StickyTable";
import { StyledName } from "../BonusesTableAdapter/index.styles";
import { t } from "i18next";

export const getBonusesTableHeadersZ = <
  TRecord extends object
>(): IHeaderItem<TFactorZ>[] => {
  return [
    {
      Header: t("bonuses.calculations.dealerName") || "",
      accessor: "dealerName",
      defaultCanSort: true,
      isSearchable: true,
      sticky: "left",
      width: 400,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledName>
            {`${original.mobisCode} ${original.dealerName}`}
          </StyledName>
        );
      },
    },
    {
      Header: t("bonuses.calculations.managerRegion") || "",
      accessor: "manager",
      defaultCanSort: true,
      sticky: "left",
      width: 300,
    },
    {
      Header: "A",
      accessor: "coefficientA",
      defaultCanSort: true,
      width: 25,
    },
    {
      Header: "B",
      accessor: "coefficientB",
      defaultCanSort: true,
      width: 25,
    },
    {
      Header: "C",
      accessor: "coefficientC",
      defaultCanSort: true,
      width: 25,
    },
    {
      Header: "Z",
      accessor: "coefficientZ",
      defaultCanSort: true,
      width: 25,
      color: "green",
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={600} color="green">
            {original.coefficientZ}
          </Paragraph>
        );
      },
    },
    {
      Header: t("bonuses.calculations.monthOne") || "",
      accessor: "monthOne",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: t("bonuses.calculations.monthTwo") || "",
      accessor: "monthTwo",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: t("bonuses.calculations.monthThree") || "",
      accessor: "monthThree",
      defaultCanSort: true,
      width: 50,
    },

    {
      Header: t("bonuses.calculations.avg") || "",
      accessor: "npsAvg",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "А",
      accessor: "coefficientA2",
      width: 50,
      color: "green",
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={600} color="green">
            {original.coefficientA}
          </Paragraph>
        );
      },
    },
    {
      Header: "Plan",
      accessor: "auditPlan",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "Result",
      accessor: "auditFact",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "%",
      accessor: "auditPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "B",
      accessor: "coefficientB2",

      width: 50,
      color: "green",
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={600} color="green">
            {original.coefficientB}
          </Paragraph>
        );
      },
    },
    {
      Header: "KSAP",
      accessor: "ksapPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "96%",
      accessor: "ksapNormalizedPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "KSMP",
      accessor: "ksmpPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "96%",
      accessor: "ksmpNormalizedPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "TRP",
      accessor: "trpPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "55%",
      accessor: "trpNormalizedPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "Average",
      accessor: "avgEducationPercent",
      defaultCanSort: true,
      width: 50,
    },
    {
      Header: "C",
      accessor: "coefficientC2",
      width: 50,
      color: "green",
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={600} color="green">
            {original.coefficientC}
          </Paragraph>
        );
      },
    },
  ];
};
