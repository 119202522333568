import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Row } from "antd";
import Card from "antd/lib/card";
import styled from "styled-components";

export const StyledRegistryTableExpandDataForm = styled(Card)`
  background: ${colors.transparent};
`;

export const StyledCardLeft = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  margin: 10px 0;
  border-radius: 12px;
`;

export const StyledCardRight = styled(Card)`
  .ant-card-body {
    margin: 0 auto;
    padding: 0;
    min-height: 354px;
  }

  display: grid;
  grid-auto-flow: column;
  gap: 16px;

  margin: 10px 0;
  border-radius: 12px;
`;

export const StyledFormTitle = styled(Paragraph)`
  padding-left: 15px;
  padding-top: 13px;
`;

export const StyledFormDescription = styled(Paragraph)`
  background: ${colors.white};
  padding: 5px;
`;

export const StyledContainer = styled.div`
  height: 100%;
`;

export const gridStyleTitle: React.CSSProperties = {
  width: "30%",
  boxShadow: "none",
  background: `${colors.gray20}`,
};

export const gridStyleDesc: React.CSSProperties = {
  width: "70%",
  boxShadow: "none",
  overflow: "hidden",
  wordWrap: "break-word",
};

export const StyledRow = styled(Row)`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.gray3};

  :last-child {
    border-radius: 12px;
  }

  :first-child {
    border-radius: 12px 12px 0 0;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  left: 35%;
`;
