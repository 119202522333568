import { FC, useContext } from "react";
import { Icon } from "@/packages/icon/Icon";
import { Title } from "@/packages/title/Title";

import {
  StyledAvatarItem,
  StyledBlockItem,
  StyledBlockText,
  StyledButtonText,
  StyledIcon,
  StyledInfo,
  StyledInfoItem,
  StyledInfoList,
  StyledProfile,
  StyledProfileNav,
  StyledRoleItem,
  StyledText,
} from "./index.style";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useFirstSymbolAvatar } from "@/hooks/useFirstSymbolAvatar";
import { Link } from "react-router-dom";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useTranslation } from "react-i18next";
import { holdingRoles } from "@/components/TransportComplaints/TransportComplaintsFilter";

const Profile: FC = (): JSX.Element => {
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const name = `${profile?.firstName} ${profile?.lastName}`;
  const dealer = profile?.dealer || null;
  const holding = profile?.dealer?.holding?.name || null;
  const role = profile?.role?.name || null;
  const type = profile?.type || null;
  const email = profile?.email?._address || null;
  const dealerUserPermission = profile?.permissions?.filter(
    (elem) => elem.name === "user.managment"
  );

  const dealerUserUpdatePermission =
    dealerUserPermission && dealerUserPermission[0]?.update === true;

  const dealerUserDeletePermission =
    dealerUserPermission && dealerUserPermission[0]?.delete === true;

  // @ts-ignore
  const imageAvatar = profile?.image?.fileUrl;

  const symbolsAvatar = useFirstSymbolAvatar(name);
  const { t } = useTranslation();

  return (
    <DashboardLayout title={t("profile.title") || ""}>
      <StyledProfile>
        <StyledProfileNav>
          {
            type !== 'kia' &&
            <Link to={'/profile/change-password'}>
            <StyledButtonText isIconLeft icon={<Icon name='lock' size={16}/>}>
              {t('profile.passwordChange') || ''}
            </StyledButtonText>
          </Link>
          }
          <Link to={"/profile/change-settings"}>
            <StyledButtonText isIconLeft icon={<Icon name="gear" size={16}/>}>
              {t("profile.settingsChange") || ""}
            </StyledButtonText>
          </Link>
          <Link to={"/profile/edit"}>
            <StyledButtonText
              isIconLeft
              icon={<Icon name="edit" size={16}/>}
            >
              {t("profile.profileChange") || ""}
            </StyledButtonText>
          </Link>
          {type !== "kia" ? (
            <>
              {dealerUserUpdatePermission || dealerUserDeletePermission ? (
                <Link to={`/profile/employee-management/${dealer?.id}`}>
                  <StyledButtonText
                    isIconLeft
                    icon={<Icon name="employees" size={16}/>}
                  >
                    {t("profile.employeeManagement") || ""}
                  </StyledButtonText>
                </Link>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </StyledProfileNav>
        <StyledInfo>
          <Title level={2}>{t("profile.description") || ""}</Title>
          <StyledInfoList>
            <StyledInfoItem>
              <StyledBlockItem>
                <StyledAvatarItem size={62} src={imageAvatar}>
                  <span>{symbolsAvatar}</span>{" "}
                </StyledAvatarItem>
                <StyledText>{name}</StyledText>
              </StyledBlockItem>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledRoleItem>
                <StyledText>
                  {role ? (
                    <>
                      <StyledIcon name="post" size={18}/> {role}
                    </>
                  ) : (
                    t("profile.noPositionData") || ""
                  )}
                </StyledText>
              </StyledRoleItem>
              <StyledText></StyledText>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledText>
                <>
                  <StyledIcon name="phone" size={16}/>
                  {profile?.phone}
                </>
              </StyledText>
              <StyledText>
                <>
                  <StyledIcon name="mail" size={16}/>
                  {email}
                </>
              </StyledText>
            </StyledInfoItem>
          </StyledInfoList>
        </StyledInfo>
        {type !== "kia" ? (
          <StyledInfo>
            {dealer ? (
              <>
                <Title level={2}>
                  {t("profile.dealer.myDealership") || ""}
                </Title>
                <StyledInfoList>
                  <StyledInfoItem>
                    <StyledText>
                      <StyledIcon name="home" size={20}/>
                      <StyledBlockText>
                        <span>{t("profile.dealer.merchantName") || ""}</span>
                        <span>{dealer.merchantName}</span>
                      </StyledBlockText>
                    </StyledText>
                    <StyledText>
                      <StyledIcon name="qr" size={16}/>
                      <StyledBlockText>
                        <span>{t("profile.dealer.mobisCode") || ""}</span>
                        <span>{dealer.mobisCode}</span>
                      </StyledBlockText>
                    </StyledText>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledText>
                      <StyledIcon name="qr" size={16}/>
                      <StyledBlockText>
                        <span>{t("profile.dealer.warrantyCode") || ""}</span>
                        <span>{dealer.warrantyCode}</span>
                      </StyledBlockText>
                    </StyledText>
                    <StyledText>
                      <StyledIcon name="home" size={20}/>
                      <StyledBlockText>
                        <span>{t("profile.dealer.sapCode") || ""}</span>
                        <span>{dealer.sapCode}</span>
                      </StyledBlockText>
                    </StyledText>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledText>
                      <StyledIcon name="home" size={20}/>
                      <StyledBlockText>
                        <span>{t("profile.dealer.showroomCode") || ""}</span>
                        <span>{dealer.showroomCode}</span>
                      </StyledBlockText>
                    </StyledText>
                    {
                      holdingRoles.includes(profile?.role?.slug ?? '') &&
                      <StyledText>
                        <StyledIcon name="home" size={20}/>
                        <StyledBlockText>
                          <span>{t("profile.dealer.holding") || ""}</span>
                          <span>{holding}</span>
                        </StyledBlockText>
                      </StyledText>
                    }
                  </StyledInfoItem>
                </StyledInfoList>
              </>
            ) : null}
          </StyledInfo>
        ) : (
          <></>
        )}
      </StyledProfile>
    </DashboardLayout>
  );
};

export { Profile };
