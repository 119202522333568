import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { InputField } from "@/packages/formElements/fields/InputField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { IModal } from "@/packages/modal/Modal";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { FC, useContext } from "react";
import { FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  TOnSubmitCoefficientFormCb,
  useCoefficientForm,
} from "./CoefficientForm/hooks";
import {
  StyledDescription,
  StyledFormGrid,
  StyledIcon,
  StyledModal,
  StyledModalContentWrapper,
  StyledModalTitle,
  StyledStageContainer,
  StyledInputWrapper,
  StyledParagraph,
} from "./index.styles";
import { useTranslation } from "react-i18next";

interface ICoefficientModal extends Pick<IModal, "isOpen" | "onCancel"> {
  moduleTitle?: string;
  inputTitle1?: string;
  inputTitle2?: string;
  onClose: () => void;
  onSubmit: TOnSubmitCoefficientFormCb;
  // TODO
  defaultValues?: any;
  prevCoefficientPercent?: number;
  isNonDnm?: boolean;
}

export const CoefficientModal: FC<ICoefficientModal> = (props) => {
  const {
    moduleTitle,
    onClose,
    inputTitle1,
    inputTitle2,
    onSubmit,
    defaultValues,
    prevCoefficientPercent,
    isNonDnm,
    ...rest
  } = props;
  const { t } = useTranslation();

  const search = useLocation().search;
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const { form, onSubmitHandler } = useCoefficientForm(
    onSubmit,
    defaultValues,
    isNonDnm || !!prevCoefficientPercent
  );

  const renderInputSubLabel = () => (
    <StyledParagraph size={10}>
      {inputTitle1 === t("bonuses.calculations.nps") || ""
        ? t("bonuses.calculations.npsAvg") || ""
        : inputTitle1 === t("bonuses.calculations.audit") || ""
        ? t("bonuses.calculations.auditAvg") || ""
        : inputTitle1 === t("bonuses.calculations.planIndex") || ""
        ? t("bonuses.calculations.planAvg") || ""
        : null}
    </StyledParagraph>
  );

  return (
    <StyledModal
      title={moduleTitle}
      size="small"
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("common.confirm") || ""}
      onCancel={onClose}
      onSubmit={onSubmitHandler}
      {...rest}
    >
      <StyledModalContentWrapper>
        <StyledModalTitle level={3} weight={400}>
          <>
            <StyledStageContainer>
              <FormProvider {...form}>
                <StyledFormGrid isNonDnm={isNonDnm}>
                  {prevCoefficientPercent ? (
                    <StyledInputWrapper>
                      <InputField
                        prefix="<"
                        suffix="%"
                        label={inputTitle1}
                        value={prevCoefficientPercent}
                        disabled
                      />
                      {renderInputSubLabel()}
                    </StyledInputWrapper>
                  ) : !isNonDnm ? (
                    <StyledInputWrapper>
                      <FormInputField
                        prefix="≥"
                        suffix="%"
                        name="percent"
                        label={inputTitle1}
                        readOnly={isNonDnm}
                      />
                      {renderInputSubLabel()}
                    </StyledInputWrapper>
                  ) : null}
                  <StyledInputWrapper>
                    <FormInputField
                      type="number"
                      name="coefficientValue"
                      label={inputTitle2}
                    />
                    {renderInputSubLabel()}
                  </StyledInputWrapper>
                </StyledFormGrid>
              </FormProvider>
            </StyledStageContainer>
          </>
        </StyledModalTitle>
        <StyledIcon size={64} name="attention" color="red" />
        <StyledDescription>
          {`${t("bonuses.calculations.message") || ""} ${selectedQuarter}`}
        </StyledDescription>
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
