import React from "react";

import {
  StyledPagination,
  StyledTabContent,
} from "@/components/parts/index.styles";
import { TFuelRecord } from "@/helpers/api/fuel/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import {
  getDefaultDictTableHeaders,
  IDefaultDictTab,
} from "@/components/parts/utils";

export interface IFuelTypeTab extends IDefaultDictTab<TFuelRecord> {}

export const FuelTypeTab: React.FC<IFuelTypeTab> = (props) => {
  const {
    currentPage,
    onChangePage,
    totalItems,
    items,
    onEditClick,
    onDeleteClick,
    onSort,
    handleSearch,
  } = props;

  const headers = getDefaultDictTableHeaders<TFuelRecord>({
    onEditClick,
    onDeleteClick,
    isSearchable: true,
  });

  return (
    <StyledTabContent>
      <TableSimple
        data={items}
        headers={headers}
        onSort={onSort}
        onSearch={handleSearch}
      />
      <StyledPagination
        current={currentPage}
        onChange={onChangePage}
        pageSize={10}
        total={totalItems}
      />
    </StyledTabContent>
  );
};
