import { colors } from "@/helpers";
import styled from "styled-components";

export const StyledContent = styled.div`
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;

  background-color: ${colors.white};
`;

export const StyledHeader = styled.div`
  margin-bottom: -32px;
  padding: 16px 32px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  display: grid;
  gap: 8px;
  margin-right: 40px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledDivider = styled.div`
  height: 40px;
  background-color: #f3f4f7;
  width: 100%;
`;

export const StyledFormWrapper = styled.div`
  position: relative;
`;

export const StyledForm = styled.div`
  display: grid;
  gap: 24px;
`;

export const StyledSelects = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    grid-auto-flow: column;
  }
`;

export const StyledFormList = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 24px;

  overflow-y: auto;
  max-height: 300px;
`;

export const StyledGridsFormTop = styled.div`
  margin-bottom: 8px;
`;

export const StyledCheckboxes = styled.div`
  display: grid;
  gap: 18px;
`;

export const StyledWorksFormTop = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 32px;
`;

export const StyledWorksFormBottom = styled.div`
  display: grid;
  gap: 32px;
`;
