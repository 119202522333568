import { IdentityFormCard } from "@/components/IdentityFormCard";
import { useGetUsersPreview } from "@/helpers/api/users/hooks";
import { useNotification } from "@/hooks/useNotification";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ConfirmAlertRegisterModal } from "./ConfirmRegisterAlertModal";
import {
  OnSubmitConfirmRegisterFormHandler,
  useConfirmRegisterForm,
} from "./hooks";
import {
  StyledFormLayout,
  StyledFormPreTitle,
  StyledFormTitle,
} from "./index.styles";

export type ConfirmRegisterFormProps = {
  onSubmit: OnSubmitConfirmRegisterFormHandler;
};

export const ConfirmRegisterForm: React.FC<ConfirmRegisterFormProps> = ({
  onSubmit,
}) => {
  const { form, onSubmitButtonClickHandler, confirmModalState } =
    useConfirmRegisterForm(onSubmit);

  const search = useLocation().search;

  const { createNotificationError } = useNotification();

  const email = new URLSearchParams(search).get("email") || "";
  const hash = new URLSearchParams(search).get("hash") || "";

  const { data: userPreviewResponse, isError } = useGetUsersPreview({
    email: email,
    hash: hash,
  });

  const userPreview = userPreviewResponse?.data;

  React.useEffect(() => {
    if (email.length === 0 && hash.length === 0) {
      createNotificationError("Ссылка не действительна");
    }
  }, [createNotificationError, email.length, hash.length, isError]);

  const fullName = userPreview
    ? `${userPreview.firstName} ${userPreview.lastName} / `
    : null;

  return (
    <IdentityFormCard>
      <StyledFormTitle level={1} weight={400}>
        Создание пароля
      </StyledFormTitle>
      <StyledFormPreTitle>
        {fullName}
        {email}
      </StyledFormPreTitle>
      <FormProvider {...form}>
        <StyledFormLayout>
          <FormInputField
            name="password"
            type="password"
            label="Пароль"
            placeholder="Введите пароль"
          />
          <FormInputField
            name="repeatPassword"
            type="password"
            label="Повторите пароль"
            placeholder="Введите пароль"
          />
        </StyledFormLayout>

        <Button
          isDisabled={!form.formState.isValid}
          onClick={onSubmitButtonClickHandler}
          isBlock={true}
          theme="primary"
          color="brand"
          size="middle"
        >
          Сохранить
        </Button>
      </FormProvider>

      <ConfirmAlertRegisterModal {...confirmModalState} />
    </IdentityFormCard>
  );
};
