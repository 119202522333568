import { colors } from "@/helpers";
import styled from "styled-components";
import { Icon } from "@/packages/icon/Icon";

export const StyledFieldContainer = styled.div<{ isBlock?: boolean }>`
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : "auto")};
`;

export const StyledFieldLabel = styled.label<{
  disabled: boolean;
  readOnly: boolean;
}>`
  ${({ disabled, readOnly }) =>
    disabled
      ? `
        color: ${colors.gray3};
        cursor: not-allowed;
      `
      : readOnly
      ? `
        color: ${colors.gray6};
      `
      : `
        color: ${colors.black};
        cursor: pointer;
      `}
  display: inline-block;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledFieldControlContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? `
    color: ${colors.gray300};
    cursor: not-allowed;
  `
      : `
    color: ${colors.black};
    cursor: pointer;
  `}
  display: flex;
  position: relative;
`;

export const InputIcon = styled(Icon)<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? `
        & svg {
          & path {
            fill: ${colors.gray300};
          }
        }
      `
      : `
        & svg {
          & path {
            fill: ${colors.gray400};
          }
        }
      `}

  position: absolute;
  top: 50%;
  right: 11px;
  transform: translate(0, -50%);
  z-index: 2;
`;

const StyledFieldText = styled.div`
  margin: 4px 0 0 0;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
`;

export const StyledFieldError = styled(StyledFieldText)<{
  isErrorAbsolute: boolean;
}>`
  position: ${({ isErrorAbsolute }) =>
    isErrorAbsolute ? "absolute" : "initial"};
  color: ${colors.red200};
`;

export const StyledFieldDescription = styled(StyledFieldText)`
  color: ${colors.gray400};
`;
