import React from "react";

import {
  SelectField,
  ISelectField,
} from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { useGetFuelList } from "@/helpers/api/fuel/hooks";

export interface IFuelSelectFieldAdapter
  extends Omit<ISelectField, "options"> {}

export const FuelSelectFieldAdapter: React.FC<IFuelSelectFieldAdapter> = (
  props
) => {
  const { data: getFuelListResponse, isLoading } = useGetFuelList({
    page: 1,
    limit: 0,
  });
  const options =
    getFuelListResponse?.data.data.map(
      (fuelRecord) =>
        ({ value: fuelRecord.id, label: fuelRecord.name } as ISelectOption)
    ) || [];
  return <SelectField options={options} loading={isLoading} {...props} />;
};
