import {
  FuelTypeTab,
  IFuelTypeTab,
} from "@/components/parts/fuelType/FuelTypeTab";
import { useGetFuelList } from "@/helpers/api/fuel/hooks";
import { usePagination } from "@/hooks/usePagination";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import React, { useState } from "react";
import { useDebounce } from "use-debounce";

export interface IFuelTypeTabAdapter
  extends Omit<
    IFuelTypeTab,
    "currentPage" | "onChangePage" | "totalItems" | "items" | "onSort"
  > {}

export const FuelTypeTabAdapter: React.FC<IFuelTypeTabAdapter> = (props) => {
  const [requestParams, setRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const [searchValueBody, setSearchValueBody] = useState<string>("");
  const [debouncedSearchValueBody] = useDebounce(searchValueBody, 400);

  const { paginationParams, setPage } = usePagination();
  const { data } = useGetFuelList({
    ...paginationParams,
    ...requestParams,
    name: debouncedSearchValueBody,
  });

  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      orderBy: order ? order.toUpperCase() : null,
      sortBy: order ? accessor.id : null,
    }));
  };

  const handleSearch = (key: string, value?: string) => {
    setSearchValueBody(value || "");
  };

  const items = data?.data.data || [];
  const totalItems = data?.data.meta?.itemCount || 0;

  return (
    <FuelTypeTab
      currentPage={paginationParams.page}
      onChangePage={setPage}
      items={items}
      totalItems={totalItems}
      onSort={handleSort}
      {...props}
      handleSearch={handleSearch}
    />
  );
};
