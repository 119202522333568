import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";

import {
  TArchiveWarrantyAuditRequest,
  TArchiveWarrantyAuditResponse,
  TCopyWarrantyAuditRequest,
  TCopyWarrantyAuditResponse,
  TCreateWarrantyAuditRequest,
  TCreateWarrantyAuditResponse,
  TDeleteWarrantyAuditRequest,
  TDeleteWarrantyAuditResponse,
  TGetAuditorsListRequest,
  TGetAuditorsListResponse,
  TGetWarrantyAuditsClaimsListRequest,
  TGetWarrantyAuditsClaimsListResponse,
  TGetWarrantyAuditsListRequest,
  TGetWarrantyAuditsListResponse,
} from "./types";

export const useGetWarrantyAuditList = (
  request: TGetWarrantyAuditsListRequest
) => {
  return useQuery(["audit", "warranty", "list", request], () => {
    return api.get<TGetWarrantyAuditsListResponse>("/guarantee/audits", {
      params: request,
    });
  });
};

export const useGetWarrantyAuditorsList = (
  request: TGetAuditorsListRequest
) => {
  return useQuery(["guarantee", "auditors", request], () => {
    return api.get<TGetAuditorsListResponse>("/guarantee/audits/auditors", {
      params: request,
    });
  });
};

export const useCreateWarrantyAudit = () => {
  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: TCreateWarrantyAuditRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateWarrantyAuditResponse>("/guarantee/audits", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCopyWarrantyAudit = () => {
  return useMutation(
    ({
       data,
       onSuccess,
       onError,
     }: {
      data: TCopyWarrantyAuditRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCopyWarrantyAuditResponse>(
        "/guarantee/audits/copy",
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteWarrantyAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: TDeleteWarrantyAuditRequest;
      onSuccess: () => void;
      onError: (response: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<TDeleteWarrantyAuditResponse>(
        `/guarantee/audits/${id}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useArchiveWarrantyAudit = () => {
  return useMutation(
    ({
       id,
       onSuccess,
     }: {
      id: TArchiveWarrantyAuditRequest;
      onSuccess: () => void;
      onError: (resp: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TArchiveWarrantyAuditResponse>(
        `/guarantee/audits/${id}/archive`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetWarrantyAuditClaimsList = (
  request: TGetWarrantyAuditsClaimsListRequest
) => {
  return useQuery([request], () => {
    return api.get<TGetWarrantyAuditsClaimsListResponse>("/guarantee/claims", {
      params: request,
    });
  }, { enabled: !!request["filter[claimSerial]"] });
};
