import { useNotification } from "@/hooks/useNotification";
import { IButton } from "@/packages/button/Button";
import { IModal, Modal } from "@/packages/modal/Modal";
import React, { ReactNode } from "react";
import {
  StyledButton,
  StyledModalContentMessage,
  StyledModalContentWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IConfirmModalProps
  extends Pick<IModal, "onCancel" | "isOpen" | "onSubmit"> {
  textMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  onAsyncSubmit?: () => void;
  onCloseModal?: () => void;
}
export const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const {
    onSubmit,
    onAsyncSubmit,
    onCancel,
    textMessage,
    successMessage,
    errorMessage,
    onCloseModal,
    ...rest
  } = props;
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();

  const onSubmitHandler = () => {
    try {
      if (onAsyncSubmit) {
        onAsyncSubmit && onAsyncSubmit();
        onCloseModal && onCloseModal();
      } else {
        onSubmit && onSubmit();
      }
      successMessage && createNotificationSuccess(successMessage);
    } catch {
      errorMessage && createNotificationError(errorMessage);
    }
  };

  const styledConfirmButton = (
    confirmButtonProps: IButton,
    confirmButtonText: string
  ): ReactNode => {
    return (
      <StyledButton {...confirmButtonProps}>{confirmButtonText}</StyledButton>
    );
  };

  return (
    <Modal
      {...props}
      title=""
      cancelButtonText={t("common.cancel") || ""}
      confirmButtonText={t("common.yes") || ""}
      styledConfirmButton={styledConfirmButton}
      onSubmit={onSubmitHandler}
      onCancel={() => onCancel && onCancel()}
    >
      <StyledModalContentWrapper>
        <StyledModalContentMessage size={18}>
          {textMessage}
        </StyledModalContentMessage>
      </StyledModalContentWrapper>
    </Modal>
  );
};
