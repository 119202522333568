import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface IFormValues {
  reason: string;
}

export type TOnSubmitFormCb = (values: IFormValues) => void;

const formSchema = () => {
  return yup.object({
    reason: yup.string().required(t("park.testRequired") || ""),
  });
};

export const useFormHook = (onSubmit: TOnSubmitFormCb) => {
  const form = useForm<IFormValues>({
    resolver: yupResolver(formSchema()),
    defaultValues: {},
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  useEffect(() => {
    form.reset();
  }, []);

  return { form, onSubmitHandler };
};

export interface ICommentModal
  extends Omit<IModal, "onCancel" | "children" | "onSubmit"> {
  onClose: () => void;
  onSubmit: ({ reason }: { reason: string }) => void;
}

export const CommentModal: React.FC<ICommentModal> = (props) => {
  const {
    cancelButtonText = t("common.cancel") || "",
    onClose,
    onSubmit,
    ...rest
  } = props;

  const { form, onSubmitHandler } = useFormHook((value) => {
    onSubmit(value);
  });

  return (
    <Modal
      {...rest}
      title={t("bonuses.reportsView.reason") || ""}
      cancelButtonText={cancelButtonText}
      confirmButtonText={t("common.send") || ""}
      onCancel={onClose}
      onSubmit={onSubmitHandler}
    >
      <FormProvider {...form}>
        <FormInputField
          name="reason"
          label={t("bonuses.reportsView.reason") || ""}
          placeholder={t("bonuses.reportsView.reasonDescr") || ""}
        />
      </FormProvider>
    </Modal>
  );
};
