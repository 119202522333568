import { media } from "@/helpers";
import styled from "styled-components";

export const StyledBody = styled.div`
  height: 100%;

  ${media.tablet} {
    ${media.tablet} {
      min-width: 423px;
    }
  }
`;

export const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledImage = styled.div`
  width: 95px;
  height: 95px;
`;
