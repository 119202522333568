import React, { useContext, useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { TasksListTable } from "@/components/Audits/Tasks/TasksListTable";

import { usePagination } from "@/hooks/usePagination";

import { useRequestParams } from "@/hooks/useRequestParams";
import { requestParamsMapper } from "@/helpers/utils";
import { IRequestParams } from "@/helpers/interfaces";

import { useNavigate } from "react-router-dom";
import { useChangeTaskStatus, useGetAuditTasks, useTaskToReview, } from "@/helpers/api/audit-tasks/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { ChatModal } from "@/components/Audits/ChatModal";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { TUpdateTaskStatusDto } from "@/helpers/api/audit-tasks/types";
import TaskFilter from "@/components/Audits/Tasks/TaskFilter";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";

import { useTranslation } from "react-i18next";
import { auditChatBtnCallback } from "./hooks";
import { useReadAllAuditTaskPPOMessage, useSendNotificationTaskForDealer } from "@/helpers/api/audit-chat/hooks";

export interface ITaskChatPayload {
  taskId: number;
  status: string;
}

const AuditTasks: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { paginationParams, setPage } = usePagination();
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const isDealerKia = profile?.type === "kia";
  const [requestParams, setRequestParams] = useRequestParams({});
  const [selectedTask, setSelectedTask] = useState<ITaskChatPayload | null>(
    null
  );
  const { mutate: changeTaskStatus, isLoading: isStatusChanging } =
    useChangeTaskStatus();

  const { mutate: reviewTarget, isLoading: isReviewSending } =
    useTaskToReview();

  const { mutate: sendNotification } = useSendNotificationTaskForDealer();

  const {
    data: tasksList,
    isLoading,
    refetch: updateTaskList,
  } = useGetAuditTasks(
    {
      ...requestParamsMapper(requestParams),
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
      "filter[withoutDrafts]": true,
    },
    isDealerKia
  );

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();
  const handleOpenChat = (data: ITaskChatPayload) => {
    console.log(data)
    setSelectedTask(data);
    ChatModalOpen();
  };

  const handleCloseChat = () => {
    ChatModalClose();
    updateTaskList();
  };

  const handleSendToReview = (isCancel: boolean) => {
    if (selectedTask?.taskId) {
      reviewTarget({
        id: selectedTask?.taskId,
        isCancel,
        onSuccess: () => {
          handleCloseChat();
          createNotificationSuccess(
            isCancel
              ? t("audits.tasks.createNotificationSuccess") || ""
              : t("audits.tasks.createNotificationSuccessCancel") || ""
          );
        },
        onError: (error) =>
          createNotificationError(
            error.response?.data.message || error.message
          ),
      });
    }
  };

  const handleChangeStatus = (taskId: number, status: TUpdateTaskStatusDto) => {
    changeTaskStatus({
      data: {
        id: taskId,
        data: status,
      },
      onSuccess: () => {
        createNotificationSuccess(t("audits.tasks.statusChange") || "");
        handleCloseChat();
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleSendNotification = () => {
    sendNotification({id: selectedTask?.taskId ?? 0})
  }

  return (
    <>
      <DashboardLayout hasMobile={true} title={t("audits.tasks.title") || ""}>
        <PermissionsAdapter
          resourceName={
            isDealerKia ? "audit.task-managment" : "audit.task-implementation"
          }
          resourceAction="read"
          fallback={() => <span>No rights</span>}
        >
          <TaskFilter
            onFilterChange={(data: IRequestParams) => {
              setRequestParams((prev) => ({ ...prev, ...data }));
            }}
          />
          <TasksListTable
            isDealerKia={isDealerKia}
            onStatusChange={(taskId, status) =>
              handleChangeStatus(taskId, status)
            }
            hasPagination={
              !!tasksList?.data.meta?.pageCount &&
              tasksList?.data.meta?.pageCount > 1
            }
            paginationParams={{
              page: paginationParams.page,
              limit: tasksList?.data.meta?.pageCount || 1,
            }}
            openChat={handleOpenChat}
            data={tasksList?.data || []}
            isLoading={isLoading}
            setPage={setPage}
            requestParams={requestParams}
          />
        </PermissionsAdapter>
      </DashboardLayout>
      {selectedTask ? (
        <ChatModal
          isLoadingConfirmButton={isStatusChanging || isReviewSending}
          isDisabledConfirmButton={isStatusChanging || isReviewSending}
          btnCallback={auditChatBtnCallback({
            t,
            isDealerKia,
            handleChangeStatus,
            selectedTask,
            handleSendToReview,
            handleSendNotification,
          })}
          params={{
            taskId: selectedTask.taskId,
          }}
          isOpen={ChatModalState.isOpen}
          onClose={handleCloseChat}
          readAllAuditMessage={useReadAllAuditTaskPPOMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { AuditTasks };
