import { Paragraph } from "@/packages/paragraph/Paragraph";
import React from "react";
import {
  ResetMessageIcon,
  ResetMessageText,
  ResetMessageTitle,
  ResetMessageWrapper,
} from "./index.style";
import { useTranslation } from "react-i18next";

const ResetMessage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ResetMessageWrapper>
      <ResetMessageIcon name="mail" size={110} />
      <ResetMessageTitle level={1} color="black">
        {t("authorization.title_changePassword") || ""}
      </ResetMessageTitle>
      <ResetMessageText>
        <Paragraph size={16} color="black">
          {t("authorization.title_changePasswordSubtitle") || ""}
        </Paragraph>
      </ResetMessageText>
    </ResetMessageWrapper>
  );
};

export default ResetMessage;
