import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TFuelRecord } from "@/helpers/api/fuel/types";
import { FUEL_INITIAL_FORM, TFuelForm } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export type TFuelEditModalSubmitPayload = {
  record: TFuelRecord;
  data: TFuelForm;
};
export interface IFuelEditModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (payload: TFuelEditModalSubmitPayload) => void;
  isLoadingSubmit: boolean;
  record?: TFuelRecord;
}

export const FuelEditModal: React.FC<IFuelEditModal> = (props) => {
  const { onSubmit, isLoadingSubmit, record, ...restProps } = props;

  const { t } = useTranslation();

  const initialForm = {
    fuelType: record?.name || "",
    // @ts-ignore
    mpv: record?.mpv || "",
  };

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    setForm(restProps.isOpen ? initialForm : FUEL_INITIAL_FORM);
  }, [record, restProps.isOpen]);

  const onSubmitHandler = async () => {
    if (!record || !Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit({ record: record, data: form });
  };

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.changeFuel") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={onSubmitHandler}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.fuel") || ""}
          description={t("parts.fuelEnter") || ""}
          value={form.fuelType}
          error={!form.fuelType && error ? error : ""}
          onChange={(value) =>
            setForm((prev) => ({
              ...prev,
              fuelType: value,
            }))
          }
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={!form.mpv && error ? error : ""}
          onChange={(value) =>
            setForm((prev) => ({
              ...prev,
              mpv: value,
            }))
          }
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
