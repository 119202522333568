import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Avatar } from "antd";
import styled from "styled-components";

export const StyledCurrentUserWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #000;
`;

export const StyledAvatarAvatar = styled(Avatar)`
  margin-left: 15px;
  background-color: ${colors.brand};
`;

export const StyledUsername = styled(Paragraph)``;

export const StyledDealersName = styled(Paragraph)`
  margin-top: 4px;
  margin-left: auto;
`;

export const StyledNameAndDealerName = styled.div`
  display: flex;
  flex-direction: column;
`;
