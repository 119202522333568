import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export interface UserFilter {
  searchById: string | null;
  searchByName: string | null;
  type: string | null;
  roleId: string | null;
  searchByEmail: string | null;
  status: boolean | null;
  country: string | null;
  dealerId: string | null;
  holdingId: string | null;
}

export const DEFAULT_FILTER_FORM_VALUES: UserFilter =
  {
    searchById: null,
    searchByName: null,
    type: null,
    roleId: null,
    searchByEmail: null,
    status: null,
    country: null,
    dealerId: null,
    holdingId: null,
  };

export type SubmitFilterCb = (
  values: UserFilter
) => void;

export const useUserFilterForm = (options: {
  onFilterChange: SubmitFilterCb;
  initValues: UserFilter;
}) => {
  const { initValues, onFilterChange } = options;

  const form = useForm<UserFilter>({
    defaultValues: DEFAULT_FILTER_FORM_VALUES,
    onSubmit: (data) => onFilterChange(data),
  });

  useEffect(() => {
    form.reset(initValues, { keepDefaultValues: true });
  }, []);

  return form;
};
