import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TAddSectionRequest,
  TAddSectionResponse,
  TChangeParamtrsOrderRequest,
  TChangeParamtrsOrderResponse,
  TCreateAuditParametrRequest,
  TCreateAuditParametrTResponse,
  TDeleteAuditParametrRequest,
  TGetAuditParametrRequest,
  TGetAuditParametrResponse,
  TPatchAuditParametrRequest,
  TPatchAuditParametrResponse,
} from "./types";

export const useGetAuditParametrById = (id?: TGetAuditParametrRequest) => {
  return useQuery(
    ["audits", "parametrs", id],
    () => {
      return api.get<TGetAuditParametrResponse>(
        `/audits/templates/parameters/${id}`
      );
    },
    {
      enabled: !!id,
      cacheTime: 0,
    }
  );
};

export const useCreateAuditParametr = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TCreateAuditParametrRequest;
      onSuccess: (resp: TCreateAuditParametrTResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateAuditParametrTResponse>(
        "/audits/templates/parameters",
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditAuditParameter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
      onSuccess,
      onError,
    }: {
      id: TPatchAuditParametrRequest["id"];
      patch: TPatchAuditParametrRequest["patch"];
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchAuditParametrResponse>(
        `/audits/templates/parameters/${id}`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteAuditParametr = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: TDeleteAuditParametrRequest) => {
      return api.delete<TDeleteAuditParametrRequest>(
        `/audits/templates/parameters/${id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["request", "list"]);
        queryClient.invalidateQueries(["requestById", variables.id]);
      },
    }
  );
};

export const useMoveParam = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
      onSuccess,
      onError,
    }: {
      id: TChangeParamtrsOrderRequest["id"];
      patch: TChangeParamtrsOrderRequest["patch"];
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TChangeParamtrsOrderResponse>(
        `/audits/templates/parameters/${id}/order`,
        patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useAddSection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TAddSectionRequest;
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.put<TAddSectionResponse>(`/audits/templates/sections`, data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
