import React from "react";
import { TFlexTariffsRecord } from "@/helpers/api/flex/tariffs/types";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormProvider } from "react-hook-form";
import {
  useCreateTariffsForm,
  useEditTariffsForm,
} from "../TariffsCreateForm/hooks";
import { TariffsFields } from "../TariffsFields";
import { useTranslation } from "react-i18next";
import { useGetTatiffsById } from "@/helpers/api/flex/tariffs/hooks";

export interface ITariffsModalProps
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: any;
  mode?: "create" | "edit";

  id?: string;
}

export const TariffsModal: React.FC<ITariffsModalProps> = (props) => {
  const { onSubmit, mode, id, ...rest } = props;
  const { t } = useTranslation();
  const { data: tariffResponse } = useGetTatiffsById(id);

  // @ts-ignore DTO неправильное
  const record = tariffResponse?.data as TFlexTariffsRecord;

  const { form, onSubmitHandler, onClearHandler } = useCreateTariffsForm({
    onSubmit,
    t,
  });

  const {
    form: editForm,
    onSubmitHandler: editSubmit,
    onClearHandler: reset,
  } = useEditTariffsForm({
    t,
    onSubmit,
    record,
  });

  const handleSubmit = () => {
    if (mode === "create") {
      onSubmitHandler();
    } else {
      editSubmit();
    }
  };

  const isCreate = mode === "create";
  const currentForm = mode === "create" ? form : editForm;

  return (
    <Modal
      {...rest}
      size="medium"
      title={isCreate ? t("kiaFlex.newTariffPlan") || "" : t("kiaFlex.edit") || ""}
      confirmButtonText={t("models.logsModel.save") || ""}
      cancelButtonText={t("audits.list.cancel") || ""}
      onSubmit={handleSubmit}
    >
      <FormProvider {...currentForm}>
        <TariffsFields
          mode={mode}
          setValue={(key, value) => {
            // @ts-ignore
            currentForm.setValue(key, value);
          }}
        />
      </FormProvider>
    </Modal>
  );
};
