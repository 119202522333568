import styled from "styled-components";

export const StyledGridsFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 24px 24px 64px;
  width: 100%;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-right: 4%;
`;
