import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IAuditFilterFormValues, useAuditFilterForm } from "./hooks";
import { Button } from "@/packages/button/Button";
import {
  ColumnWraper,
  StyledButton,
  StyledFilters,
  StyledRow,
  StyledSeparator,
} from "./index.styles";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import {
  preparedDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { IRequestParams } from "@/helpers/interfaces";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { useGetAuditorsList } from "@/helpers/api/audit-list/hooks";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface IAuditListFilter {
  onFilterChange: (value: IRequestParams) => void;
  setFilterQueryParams: (value: IAuditFilterFormValues) => void;
  initValues: IAuditFilterFormValues;
  resetPage: () => void;
}

const MOCK_AUDIT_TYPE = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "audits.list.online",
    value: "Онлайн",
  },
  {
    label: "audits.list.fullTime",
    value: "Очно",
  },
];

const MOCK_AUDIT_STATUS = [
  {
    label: "audits.list.all",
    value: "",
  },
  {
    label: "audits.list.new",
    value: "Новый",
  },
  {
    label: "audits.list.active",
    value: "Активный",
  },
  {
    label: "audits.list.closed",
    value: "Закрытый",
  }
];

const AuditListFilter: React.FC<IAuditListFilter> = (props) => {
  const { onFilterChange, setFilterQueryParams, initValues, resetPage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });
  const onFilterApply = (data) => {
    let params = {};
    Object.entries(data).forEach(([key, value]) => {
      params = {
        ...params,
        [key]: {
          value,
          type: "filter",
        },
      };
    });
    setFilterQueryParams(data);
    onFilterChange(params);
  };
  const { form, onClearHandler, onSubmitHandler } = useAuditFilterForm({
    initValues,
    onSubmit: onFilterApply,
  });
  const { data: dealerList } =
    useGetFilteredDealerList({
      q: searchValue,
      limit: 0,
      //@ts-ignore
      holdingIds: form.watch('holdingId') ? [form.watch('holdingId')] : undefined
    });
  const { t } = useTranslation();
  const { data: auditorList, isLoading: isAuditorsLoading } =
    useGetAuditorsList({
      "page[limit]": 0,
      //@ts-ignore
      "filter[dealerIds]": form.watch('dealerId') ? [form.watch('dealerId')] : undefined
    });

  const clearFilters = () => {
    onClearHandler();
    resetPage();
    onFilterApply({});
  };

  const handleSubmit = () => {
    resetPage()
    onSubmitHandler();
  };

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) =>
      handleSubmit()
    )
    return () => subscription.unsubscribe()
  }, [form.watch])

  const preparedHoldingList = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );

  const preparedAuditorsList = useMemo(
    () =>
      auditorList?.data?.data?.map((item) => ({
        label: `${item.firstName}  ${item.lastName}`,
        value: item.id,
      })) || [],
    [auditorList?.data, isAuditorsLoading]
  );

  return (
    <StyledFilters isOpen={isOpen}>
      <FormProvider {...form}>
        <StyledRow gutter={16} space={24}>
          <Col xs={24} md={4}>
            <SelectField
              label={t("audits.list.auditType") || ""}
              placeholder={t("audits.list.all") || ""}
              options={MOCK_AUDIT_TYPE}
              onChange={(value) => {
                onFilterChange({
                  type: {
                    value,
                    type: "filter",
                  },
                });
              }}
            />
          </Col>
          <Col xs={20} md={4}>
            <SelectField
              label={t("audits.list.auditStatus") || ""}
              placeholder={t("audits.list.all") || ""}
              options={MOCK_AUDIT_STATUS}
              onChange={(value) => {
                onFilterChange({
                  status: {
                    value,
                    type: "filter",
                  },
                });
              }}
            />
          </Col>

          <Col>
            <Tooltip trigger="hover" content={t("audits.list.filters") || ""}>
              <StyledButton
                icon="filter"
                color="black"
                size="m"
                isSquared={true}
                variant={isOpen ? "primary" : "secondary"}
                onClick={() => setIsOpen((prev) => !prev)}
              />
            </Tooltip>
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={12} md={8}>
            <FormSelect
              name="holdingId"
              label={t("audits.list.holdingId") || ""}
              options={preparedHoldingList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={12} md={9}>
            <FormSelect
              name="dealerId"
              label={t("audits.list.dealerId") || ""}
              options={preparedDealerList(dealerList?.data?.data || [])}
              placeholder={t("audits.list.chooseList") || ""}
              onSearch={setSearchValue}
              isSearchShow
              allowClear
            />
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={24} md={8}>
            <FormSelect
              name="auditorId"
              label={t("audits.list.auditorId") || ""}
              options={preparedAuditorsList}
              placeholder={t("audits.list.choose") || ""}
              isSearchShow
              allowClear
            />
          </Col>
          <Col xs={24} md={8}>
            <ColumnWraper>
              <FormDatepickerField
                name="startCreatedAt"
                label={t("audits.list.date") || ""}
              />
              <StyledSeparator/>
              <FormDatepickerField name="endCreatedAt" label={" "}/>
            </ColumnWraper>
          </Col>
        </StyledRow>
        <Space>
          <Button
            color="brand"
            theme="secondary"
            size="middle"
            onClick={clearFilters}
          >
            {t("audits.list.clear") || ""}
          </Button>
        </Space>
      </FormProvider>
    </StyledFilters>
  );
};

export default AuditListFilter;
