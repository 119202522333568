import React from "react";
import { Tabs as AntdTabs } from "antd";

import { ITab } from "@/packages/tabs/Tabs";
import { Button } from "@/packages/button/Button";

import { StyledTabs } from "./index.styles";
import { useTranslation } from "react-i18next";

const { TabPane } = AntdTabs;

export interface IMediaBaseTabsProps {
  tabs: ITab[];
  activeTab?: ITab;
  onAddButtonClick: () => void;
  onChange: (tab: ITab) => void;
}

export const MediaBaseTabs: React.FC<IMediaBaseTabsProps> = (props) => {
  const { tabs, activeTab, onChange, onAddButtonClick } = props;
  const { t } = useTranslation();
  return (
    <StyledTabs
      tabBarExtraContent={
        <Button onClick={onAddButtonClick} color="brand" size="small">
          {t("warrantyAudits.list.add") || ""}
        </Button>
      }
      onChange={(activeKey: string) => {
        onChange(tabs.find((item) => item.slug === activeKey) || tabs[0]);
      }}
      defaultActiveKey={activeTab?.slug}
    >
      {tabs?.map((item) => (
        <TabPane
          tabKey={item.slug}
          disabled={item.isDisabled}
          tab={item.name}
          key={item.slug}
        >
          {item.content}
        </TabPane>
      ))}
    </StyledTabs>
  );
};
