import React from "react";
import {
  TAuditUpdateTaskRequest,
  TGetAuditsTasksDealerResponse,
} from "@/helpers/api/audit-plan/types";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import { getDefaultAuditCreatePlanTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { usePermission } from "@/hooks/usePermission";
import { useGetUserList } from "@/helpers/api/users/hooks";
import styled from "styled-components";
import { useMemo } from "react";

interface IAuditCreatePlanTable extends Pick<ITableSimple, "onSort"> {
  data?: TGetAuditsTasksDealerResponse;
  isLoading?: boolean;
  isFieldLoading: boolean;
  paginationParams: { page: number; limit: number };
  hasPagination?: boolean;
  loadingIds: number[];
  setPage: (page: number) => void;
  onEditField: (request: TAuditUpdateTaskRequest) => void;
  onDeleteClick: (id: number) => void;
  openChat: (id: number) => void;
  CreateRow: () => JSX.Element;
}

const AuditCreatePlanTable_: React.FC<IAuditCreatePlanTable> = (props) => {
  const {
    data,
    isLoading,
    onSort,
    isFieldLoading,
    hasPagination,
    paginationParams,
    loadingIds,
    setPage,
    onDeleteClick,
    openChat,
    onEditField,
    CreateRow,
  } = props;
  const { hasAccess: hasEdit } = usePermission(
    "audit.task-managment",
    "update"
  );
  const { data: responsible, isLoading: isResponsiblesLoading } =
    useGetUserList({
      dealerId: data?.data[0]?.audit?.dealer.id,
      permissionSlug: "audit.implementation",
      permissionAction: "update",
    });

  const headers = useMemo(
    () =>
      getDefaultAuditCreatePlanTableHeaders({
        onDeleteClick,
        openChat,
        onEditField,
        responsible: responsible?.data,
        isLoading: isFieldLoading,
        loadingIds,
      }),
    [responsible?.data, isFieldLoading]
  );

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          <>
            <TableSimple
              headers={headers || []}
              data={data?.data || []}
              onSort={onSort}
              CreateRow={CreateRow}
              withOverflow={false}
            />
            {hasPagination ? (
              <Pagination
                current={paginationParams.page}
                onChange={setPage}
                pageSize={1}
                total={paginationParams.limit}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Loader size={48} />
          </>
        )}
      </StyledTableSimple>
    </>
  );
};

export const AuditCreatePlanTable = React.memo(AuditCreatePlanTable_);
