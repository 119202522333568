import { FC } from "react";
import Row from "./Row";
import { IBatchResponseToMutate } from "./utils";

export interface ITreeComponentProps {
  onUploadMaterialSectionHandler: (
    id: string,
    fileIds: string[]
  ) => Promise<void>;
  onDeleteMaterialSectionHandler: (ids: string[]) => Promise<void>;
  data: IBatchResponseToMutate[];
  searchValue: string;
}

const TreeComponent: FC<ITreeComponentProps> = (props) => {
  const {
    onDeleteMaterialSectionHandler,
    onUploadMaterialSectionHandler,
    data,
    searchValue,
  } = props;

  return (
    <>
      {data?.map((item) => {
        return (
          <Row
            data={data}
            section={item}
            onDeleteMaterialSectionHandler={onDeleteMaterialSectionHandler}
            onUploadMaterialSectionHandler={onUploadMaterialSectionHandler}
            searchValue={searchValue}
          >
            {item.children && item.children.length ? (
              <TreeComponent
                data={item.children}
                onDeleteMaterialSectionHandler={onDeleteMaterialSectionHandler}
                onUploadMaterialSectionHandler={onUploadMaterialSectionHandler}
                searchValue={searchValue}
              />
            ) : (
              <></>
            )}
          </Row>
        );
      })}
    </>
  );
};

export default TreeComponent;
