import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "@/helpers";
import { SellsFilter } from "@/components/Bonuses/Reports/SellsTab/SellsFilter";

import { DealerSellsChart } from "@/components/Bonuses/Reports/SellsTab/DealerSellsChart";

import { BonusesFilters } from "../../BonusesFilters";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRequestParams } from "@/hooks/useRequestParams";
import { ChartType, TGetGraphRequest } from "@/helpers/api/reports/types";
import { ITab, Tabs } from "@/packages/tabs/Tabs";

import { DealerBulletChart } from "./DealerBulletChart";
import { DealerBonusesTitleHeader } from "../../ReportsCalculation/DealerBonusesTab/DealerBonusesTitleHeader";
import { ISellsFilterFormValues } from "./SellsFilter/hooks";
import { BulletLegend } from "./BulletLegend";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useTranslation } from "react-i18next";

const StyledTab = styled.div`
  padding: 0 32px 20px 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${colors.white};
`;

const StyledContent = styled.div`
  padding-top: 20px;
`;

const StyledTopChartFilters = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const tabs: ITab[] = [
  { name: "bonuses.reportsView.spares", slug: "spares" },
  { name: "bonuses.reportsView.accessories", slug: "accessories" },
  { name: "bonuses.reportsView.sum", slug: "sum" },
];

const SellsTab: FC = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeGraph, setActiveGraph] = useState<ChartType>(ChartType.Graph);
  const [isAllDealers, setIsAllDealers] = useState(true);

  const { t } = useTranslation();

  const {
    state: { profile },
  } = useProfile();
  const isProfileKia = profile?.type === "kia";
  const dealerId = profile?.dealer?.id;

  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;

  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const activeTabSlug = new URLSearchParams(search).get("activeTab");

  const [chartRequestParams, setChartRequestParams] =
    useState<TGetGraphRequest>({
      dealerId: "",
      groupBy: "year",
      type: activeTab.slug as "spares" | "accessories" | "sum",
    });

  const [requestParams, setRequestParams] = useRequestParams({
    quarter: {
      type: "filter",
      value: selectedQuarter,
    },
    year: {
      type: "filter",
      value: selectedYear,
    },
    type: {
      type: "filter",
      value: activeTab.slug,
    },
  });

  useEffect(() => {
    setSearchParams({
      quarter: requestParams?.quarter?.value?.toString() || "",
      year: requestParams?.year?.value?.toString() || "",
      activeTab: activeTabSlug || "sells",
    });
  }, [requestParams, activeTab]);

  const handleFiltersApply = (
    filters: ISellsFilterFormValues | TGetGraphRequest
  ) => {
    if (filters.dealerId) {
      setIsAllDealers(false);
    } else {
      setIsAllDealers(true);
    }

    if (activeGraph === ChartType.Bullet) {
      Object.entries(filters).forEach(([key, value]) => {
        setRequestParams((prev) => ({
          ...prev,
          [key]: { type: "filter", value },
        }));
      });
    } else {
      setChartRequestParams(filters as TGetGraphRequest);
    }
  };

  return (
    <StyledTab>
      <BonusesFilters
        quarter={selectedQuarter}
        isAllDealers={isAllDealers && isProfileKia}
      >
        <SellsFilter
          selectedType={activeGraph}
          onFiltersApply={handleFiltersApply}
          onChartTypeChange={(name) => setActiveGraph(name)}
          isProfileKia={isProfileKia}
        />
      </BonusesFilters>

      <StyledContent>
        <DealerBonusesTitleHeader
          disableExport={true}
          hasButton={false}
          disablePrev={
            requestParams?.quarter?.value
              ? +requestParams?.quarter?.value <= 1
              : true
          }
          disableNext={
            requestParams?.quarter?.value
              ? +requestParams?.quarter?.value >= 4
              : true
          }
          onNextClick={() =>
            setRequestParams((prev) =>
              prev?.quarter?.value
                ? {
                    ...prev,
                    quarter: {
                      type: "filter",
                      value: +prev?.quarter?.value + 1,
                    },
                  }
                : prev
            )
          }
          onPrevClick={() =>
            setRequestParams((prev) =>
              prev?.quarter?.value
                ? {
                    ...prev,
                    quarter: {
                      type: "filter",
                      value: +prev?.quarter?.value - 1,
                    },
                  }
                : prev
            )
          }
        />
        <StyledTopChartFilters>
          <Tabs
            type="line"
            tabs={tabs}
            activeTab={activeTab}
            isContentBorderless={false}
            onChange={(tab) => {
              setActiveTab(tab);
              setRequestParams((prev) => ({
                ...prev,
                type: {
                  type: "filter",
                  value: tab.slug,
                },
              }));
            }}
          />
          <BulletLegend />
        </StyledTopChartFilters>
        {
          {
            bullet: (
              <DealerBulletChart
                noDataText={
                  !requestParams?.dateStart?.value &&
                  !requestParams?.dateEnd?.value
                    ? t("bonuses.reportsView.periodChoose") || ""
                    : t("common.noData") || ""
                }
                requestParams={{
                  ...requestParams,
                  dealerId: {
                    type: "filter",
                    value:
                      !isProfileKia && dealerId
                        ? dealerId
                        : //@ts-ignore
                          requestParams.dealerId?.value,
                  },
                }}
              />
            ),
            graph: (
              <DealerSellsChart
                requestParams={
                  {
                    ...chartRequestParams,
                    type: activeTab.slug as "spares" | "accessories" | "sum",
                    dealerId:
                      !isProfileKia && dealerId
                        ? dealerId
                        : chartRequestParams.dealerId,
                  } as TGetGraphRequest
                }
                noDataText={
                  chartRequestParams.dealerId
                    ? t("common.noData") || ""
                    : t("bonuses.reportsView.dealerChoose") || ""
                }
              />
            ),
          }[activeGraph]
        }
      </StyledContent>
    </StyledTab>
  );
};

export { SellsTab };
