import {
  ISimpleDictCreateModal,
  SimpleDictCreateModal,
} from "@/components/SimpleDictCreateModal";
import React from "react";
import { t } from "i18next";

const modalSettings = {
  title: "registers.holdingsDC.addHolding",
  confirmButtonText: "registers.holdingsDC.addHolding",
  contentTitle: "registers.holdingsDC.holdingName",
  form: {
    inputLabel: "registers.holdingsDC.holdingName",
  },
  successSubmitMessage: t("registers.holdingsDC.successCreate") || "",
  errorSubmitMessage: t("registers.holdingsDC.errorCreate") || "",
};

export interface IHoldingCreateModal
  extends Pick<
    ISimpleDictCreateModal,
    "isOpen" | "onClose" | "onSubmit" | "isSubmitLoading"
  > {}

export const HoldingCreateModal: React.FC<IHoldingCreateModal> = (props) => {
  return <SimpleDictCreateModal {...modalSettings} {...props} />;
};
