import styled from "styled-components";
import { IconButton } from "@/packages/icon-button/IconButton";
import { colors } from "@/helpers";

export const StyledValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 76px;
  width: 100%;
  border: 1px solid ${colors.gray20};
  margin: 0 auto;
  padding: 16px;
  text-align: center;
`;

export const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledEditIcon = styled(IconButton)`
  position: absolute;
  right: 50px;
`;

export const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  right: 15px;
`;
