import styled from "styled-components";
import { colors } from "@/helpers";

import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Title } from "@/packages/title/Title";

export const StyledStatisticsModalWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const StyledStatisticsModalTitle = styled(Title)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray30};
`;

export const StyledStatisticsModalFiles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const StyledStatisticsModalLink = styled(ButtonText)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.blue};
  padding: 0;
`;