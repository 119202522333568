import { components, paths } from "@/helpers/api/swagger-scheme";

export const enum BonusesMethodType {
  KSMP = "ksmp",
  TRP = "trp",
  GWMS = "gwms",
  KSAP = "ksap",
  NPS = "nps",
  SalesPlan = "sales-plan",
  SalesFact = "sales-fact",
}

export const enum BonusesFileType {
  KSMP = "ksmp",
  TRP = "trp",
  GWMS = "gwms",
  KSAP = "ksap",
  NPS = "nps",
  SalesPlan = "salesPlan",
  SalesFact = "salesFact",
}

export type TUploadBonusesReportRequest =
  paths["/api/bonuses/reports/files/trp"]["post"]["requestBody"]["content"]["multipart/form-data"];
export type TUploadBonusesReportResponse =
  paths["/api/bonuses/reports/files/trp"]["post"]["responses"]["200"]["content"]["application/json"];
export type TUploadBonusesReport400Response =
  paths["/api/bonuses/reports/files/trp"]["post"]["responses"]["400"]["content"]["application/json"];

export type TGetBonusesReportRequest =
  paths["/api/bonuses/reports"]["get"]["parameters"]["query"];
export type TGetBonusesReportResponse =
  paths["/api/bonuses/reports"]["get"]["responses"]["200"]["content"]["application/json"];

export type TImportBonusesReportFromApiRequest =
  paths["/api/bonuses/reports/import/nps"]["post"]["requestBody"]["content"]["application/json"];
export type TImportBonusesReportFromApiResponse =
  paths["/api/bonuses/reports/import/sales-plan"]["post"]["responses"]["201"];

export type TCreateQuartalReportRequest =
  paths["/api/bonuses/quarters"]["post"]["requestBody"]["content"]["application/json"];
export type TCreateQuartalReportResponse =
  paths["/api/bonuses/quarters"]["post"]["responses"]["200"]["content"]["application/json"];
