import { TGetDecoderInfoResponse } from '@/helpers/api/vinDecoder/types';
import { IconButton } from '@/packages/icon-button/IconButton';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { Space } from 'antd';
import { FC, useContext } from 'react';
import { StyledContent } from '@/components/VinDecoder/index.styles';
import styled from 'styled-components';
import { InputField } from '@/packages/formElements/fields/InputField';
import { InputNumberField } from '@/packages/formElements/fields/InputNumberField';
import { useCopyCurrentUrl } from '@/hooks/useCopyCurrentUrl';
import { useTranslation } from 'react-i18next';
import { handleFileLoad } from '@/helpers/utils';
import { useExportDecoderDocuments } from '@/helpers/api/vin/hooks';
import { ContextProfile } from '@/hooks/contexts/useProfile';

interface IInfoTab {
    title: string;
    data?: TGetDecoderInfoResponse;
    vin: string;
}

const StyledForm = styled(Space)`
    display: grid;
    grid-template-columns: 1fr 2fr;

    .ant-space,
    .ant-space-item {
        width: 100%;
    }
`;

const StyledImportVinField = styled(InputField)`
    margin-bottom: 20px;
`;

const InfoTab: FC<IInfoTab> = (props) => {
    const { title, data, vin } = props;

    const { t } = useTranslation();

    const copyCurrentUrl = useCopyCurrentUrl({
        successMessage: t('bonuses.reportsView.successMessage') || '',
    });

    const { mutateAsync: exportDocuments } = useExportDecoderDocuments();

    const handleExport = () => {
        exportDocuments(
            { vin: vin },
            {
                onSuccess: (response) => {
                    handleFileLoad(response.data as Blob, 'documents.xlsx');
                },
            },
        );
    };

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const isDealer = profile?.type === 'dealer';

    return (
        <StyledContent>
            <Space size={16} align='center'>
                <Space size={8}>
                    <IconButton
                        icon='save'
                        isSquared={true}
                        variant='secondary'
                        color='gray'
                        onClick={handleExport}
                    />
                    <IconButton
                        icon='share'
                        isSquared={true}
                        variant='secondary'
                        color='gray'
                        onClick={() => copyCurrentUrl()}
                    />
                </Space>
                <Paragraph size={16} color='black'>
                    {title}
                </Paragraph>
                <StyledImportVinField
                    label='Импорт VIN'
                    //@ts-ignore
                    value={data?.importVin}
                    readOnly={true}
                />
            </Space>
            <StyledForm size={24}>
                <Space direction='vertical' size={24}>
                    <InputField
                        label='Модель'
                        //@ts-ignore
                        value={data?.model}
                        readOnly={true}
                    />
                    {
                        !isDealer &&
                        <>
                            <InputField
                                label='Материал код'
                                //@ts-ignore
                                value={data?.materialCode}
                                readOnly={true}
                            />
                            <InputField
                                label='Тип кузова'
                                //@ts-ignore
                                value={data?.bodyType}
                                readOnly={true}
                            />
                        </>
                    }
                </Space>
                <Space direction='vertical' size={24}>
                    <InputField
                        label='Модификация'
                        //@ts-ignore
                        value={data?.description}
                        readOnly={true}
                    />
                    <Space direction='vertical' size={24}>
                        <Space size={24}>
                            <InputNumberField
                                label='Год модели'
                                value={data?.modelYear}
                                readOnly={true}
                            />
                            <InputNumberField
                                label='Год производства'
                                value={data?.productionYear}
                                readOnly={true}
                            />
                        </Space>

                        {
                            !isDealer &&
                            <Space size={24}>
                                <InputField
                                    label='Комплектация'
                                    //@ts-ignore
                                    value={data?.equipment}
                                    readOnly={true}
                                />
                                <InputField
                                    label='Производство'
                                    value={data?.factory}
                                    readOnly={true}
                                />
                            </Space>
                        }
                    </Space>
                </Space>
            </StyledForm>
        </StyledContent>
    );
};

export { InfoTab };
