import React from "react";
import { ISwitch, Switch } from "@/packages/formElements/inputs/switch";
import { Field } from "../Field";
import { StyledFieldControlContainer } from "./index.styles";

export interface ISwitchField extends Omit<ISwitch, "hasError"> {
  className?: string;

  label?: string;
  error?: string;
  description?: string;
}

export const SwitchField: React.FC<ISwitchField> = (props) => {
  const { className, label, error, description, readOnly, ...switchProps } = props;

  return (
    <Field>
      <StyledFieldControlContainer>
        <Switch {...switchProps} hasError={!!error} readOnly={readOnly}/>
        {label ? <Field.Label readOnly={readOnly}>{label}</Field.Label> : null}
      </StyledFieldControlContainer>
      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}
      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
