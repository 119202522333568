import { colors } from "@/helpers";
import styled, { css } from "styled-components";
import { IHeaderParams } from "./DraggableTable";

export const StyledTable = styled.div`
  overflow-x: auto;
  width: 100% !important;
  min-width: 100% !important;
  border: none;
`;

interface IStyledHeaderCell {
  isSortable: boolean;
  isFixedWidth?: boolean;
  headerParams?: IHeaderParams;
}

export const StyledHeaderCell = styled.div<IStyledHeaderCell>`
  width: 200px;
  display: flex;
  align-items: center;

  ${({ isFixedWidth }) =>
    isFixedWidth &&
    css`
      flex-grow: 0 !important;
    `}

  ${({ headerParams }) =>
    headerParams?.size === "s"
      ? css`
          height: 56px;
          padding-left: 16px;
          &:first-of-type {
            padding-left: 32px;
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "4px"
              : "0"};
          }
          &:last-of-type {
            padding-right: 28px;
            border-top-right-radius: 4px;
          }
        `
      : css`
          &:first-of-type {
            padding-left: 32px;
            border-top-left-radius: ${headerParams &&
            headerParams?.hasTopBorderRadius
              ? "12px;"
              : "0"};
          }
          &:last-of-type {
            padding-right: 16px;
            border-top-right-radius: 12px;
          }
          padding-left: 16px;
          height: 74px;
        `};

  ${({ headerParams }) => headerParams?.hasTopBorderRadius && css``}

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  background-color: ${({ headerParams }) =>
    colors[headerParams?.color || "gray30"]};
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "auto")};
`;

export const StyledHeaderCellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

interface IStyledCell {
  isFixedWidth?: boolean;
  isButton?: boolean;
  headerParams?: IHeaderParams;
  alignCell: "start" | "center";
}

export const StyledCell = styled.div<IStyledCell>`
  width: 200px;
  ${({ isFixedWidth }) =>
    isFixedWidth &&
    css`
      flex-grow: 0 !important;
    `}

  border-bottom: 1px solid ${colors.gray20};
  ${({ headerParams }) =>
    headerParams?.size === "s"
      ? css`
          min-height: 56px;
          &:first-of-type {
            padding-left: 32px;
          }
          &:last-of-type {
            padding-right: 28px;
          }
          padding-left: 16px;
        `
      : css`
          min-height: 64px;

          &:first-of-type {
            padding-left: 32px;
          }
          &:last-of-type {
            padding-right: 16px;
          }
          padding-left: 16px;
        `}

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: ${({ alignCell }) => alignCell};
  transition: 0.4s;

  ${({ isButton }) =>
    isButton &&
    css`
      padding: 0;
    `}
`;

export const StyledRow = styled.div<{ opacity: number }>`
  position: relative;
  opacity: ${({ opacity }) => opacity};
`;

export const StyledSearchButton = styled.div`
  margin-right: 10px;
`;

export const WrappedButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledDrag = styled.div`
  cursor: grab;
`;
