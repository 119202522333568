import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../../axiosInstance";
import {
  TCreateWarrantyPlanTasksFillingRequest,
  TCreateWarrantyPlanTasksFillingResponse,
  TDeleteWarrantyPlanTasksFillingResponse,
  TEditWarrantyPlanTasksFillingRequest,
  TGetWarrantyPlanTasksFillingRequest,
  TGetWarrantyPlanTasksFillingResponse,
  TGetWarrantyPlanTasksKiaRequest,
  TGetWarrantyPlanTasksKiaResponse,
  TSendPlanRequest,
  TSendPlanResponse,
  TWarrantyEditStatusRequest,
  TWarrantyEditStatusResponse,
} from "./types";

export const useGetWarrantyPlanTasksFilling = (
  query: TGetWarrantyPlanTasksFillingRequest
) => {
  return useQuery(
    ["plan", "warranty", query],
    () => {
      return api.get<TGetWarrantyPlanTasksFillingResponse>(
        "/guarantee/tasks/filling",
        { params: query }
      );
    },
    { cacheTime: 0 }
  );
};

export const useCreateWarrantyPlan = () => {
  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TCreateWarrantyPlanTasksFillingRequest;
      onSuccess: (resp: TCreateWarrantyPlanTasksFillingResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateWarrantyPlanTasksFillingResponse>(
        "/guarantee/tasks",
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditWarrantyPlan = () => {
  return useMutation(
    ({
      id,
      data,
      onSuccess,
      onError,
    }: {
      id: number;
      data: TEditWarrantyPlanTasksFillingRequest;
      onSuccess: (resp: TCreateWarrantyPlanTasksFillingResponse) => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TCreateWarrantyPlanTasksFillingResponse>(
        `/guarantee/tasks/${id}`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteWarrantyPlan = () => {
  return useMutation(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: number;

      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.delete<TDeleteWarrantyPlanTasksFillingResponse>(
        `/guarantee/tasks/${id}`
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useSendPlan = () => {
  return useMutation(
    ({
      type,
      auditId,
      reason,
      onSuccess,
      onError,
    }: {
      auditId: number;
      reason?: string;
      type:
        | "send-to-dealer"
        | "send-to-auditor"
        | "reject-plan"
        | "accept-plan";

      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TSendPlanResponse>(`/guarantee/tasks/${type}`, {
        auditId,
        reason,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditWarrantyTaskStatus = () => {
  return useMutation(
    ({
      taskId,
      data,
      isDealerKia,
      onSuccess,
      onError,
    }: {
      data: TWarrantyEditStatusRequest;
      taskId: number;
      isDealerKia: boolean;
      onSuccess: () => void;
      onError: (err: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TWarrantyEditStatusResponse>(
        `/guarantee/tasks/${taskId}/${
          isDealerKia ? "status" : "status-dealer"
        }`,
        {
          ...data,
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useGetWarrantyAuditTasks = (
  request: TGetWarrantyPlanTasksKiaRequest,
  isDealerKia: boolean
) => {
  return useQuery(["warranty", "plan", isDealerKia, request], () => {
    return api.get<TGetWarrantyPlanTasksKiaResponse>(
      `/guarantee/tasks/${isDealerKia ? "kia" : "dealer"}`,
      {
        params: request,
      }
    );
  });
};
