import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";
import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TBodyRecord } from "@/helpers/api/bodies/types";
import { TBodyForm, BODY_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export type TBodyTypeEditModalSubmitPayload = {
  record: TBodyRecord;
  data: TBodyForm;
};
export interface IBodyTypeEditModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (payload: TBodyTypeEditModalSubmitPayload) => void;
  isLoadingSubmit: boolean;
  record?: TBodyRecord;
}

export const BodyTypeTypeEditModal: React.FC<IBodyTypeEditModal> = (props) => {
  const { onSubmit, isLoadingSubmit, record, ...restProps } = props;

  const { t } = useTranslation();

  const initialForm = {
    bodyType: record?.name || "",
    // @ts-ignore
    mpv: record?.mpv || "",
  };

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState(initialForm);

  const bodyTypeError = !form.bodyType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      bodyType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!record || !Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit({ record: record, data: form });
  };

  useEffect(() => {
    setForm(restProps.isOpen ? initialForm : BODY_INITIAL_FORM);
  }, [record, restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.changeCarcase") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.carcase") || ""}
          description={t("parts.carcaseEnter") || ""}
          value={form.bodyType}
          error={bodyTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
