import { InputField } from "@/packages/formElements/fields/InputField";
import { IconButton } from "@/packages/icon-button/IconButton";
import React, { FC, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  StyledChildren,
  StyledFormEditBatchModal,
  StyledInputWrapper,
} from "./index.styles";

import { IBatchResponseToMutate } from "@/pages/Materials/utils";
import { ITreeProps } from "./Tree";
import { removeIdDeep } from "./utils";
import { useTranslation } from "react-i18next";

export interface IRowComponentProps extends Omit<ITreeProps, "data"> {
  section: IBatchResponseToMutate;
  children: React.ReactNode;
  index: number;
}

const RowComponent: FC<IRowComponentProps> = (props) => {
  const {
    section,
    sections,
    setSections,
    children,
    onActionHandler,
    index,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [inputSection, setInputSection] = useState(section);

  const addSubSectionHandler = useCallback(() => {
    const { id, children, level } = section;

    const index = children ? children.length + 1 : 1;

    const order = Number(`${level}.${index}`.replaceAll(".", ""));

    const newSubSection = {
      id: uuidv4(),
      name: "",
      order: order,
      parrent_id: id,
      level: `${level}.${index}`,
    };

    section.children = section.children
      ? [...section.children, newSubSection]
      : [newSubSection];

    setSections(JSON.parse(JSON.stringify(sections)));
    onActionHandler("add", newSubSection);
  }, [section, setSections, sections, onActionHandler]);

  const deleteSectionHandler = useCallback(() => {
    const filteredSections = removeIdDeep(sections, section.id);
    setSections(JSON.parse(JSON.stringify(filteredSections)));
    onActionHandler("delete", section);
  }, [section, setSections, sections, onActionHandler]);

  const editSection = (prop: string, event: string) => {
    setInputSection((prev) => ({ ...prev, name: event }));
    section[prop] = event;
    onActionHandler("edit", section);
  };

  return (
    <StyledFormEditBatchModal>
      <StyledInputWrapper>
        <InputField
          name={section.id}
          label={t("dealerMaterials.letters.sectionName") || ""}
          prefix={inputSection.level}
          value={inputSection.name}
          isBlock
          onChange={(event) => editSection("name", event)}
        />
        <IconButton
          variant="tertiary"
          icon="delete"
          color="brand"
          size="l"
          onClick={deleteSectionHandler}
        />
      </StyledInputWrapper>

      <IconButton
        variant="tertiary"
        icon="plus"
        color="brand"
        onClick={addSubSectionHandler}
      >
        {t("dealerMaterials.letters.addSection_2") || ""}
      </IconButton>

      {children ? <StyledChildren>{children}</StyledChildren> : <></>}
    </StyledFormEditBatchModal>
  );
};

export default RowComponent;
