import { colors } from "@/helpers";
import styled, { css } from "styled-components";

export const StyledTitleDividerWrapper = styled.div<{
  size: "small" | "medium";
}>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0px;

  ${({ size }) =>
    size === "medium"
      ? css`
          flex-direction: column;
          align-items: flex-start;
        `
      : ""}
`;

export const StyledDivider = styled.div<{
  size: "small" | "medium";
}>`
  flex: 1;
  height: 1px;
  min-height: 1px;
  background-color: ${colors.gray20};
  ${({ size }) =>
    size === "medium"
      ? css`
          width: 100%;
        `
      : ""}
`;
