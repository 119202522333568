import { useState } from "react";

type WithIsOpen<T> = Omit<T, "isOpen"> & { isOpen: boolean };

export const useModal = <
  TSubmitPayload = void,
  TAdditionModalState extends Partial<Record<string, any>> | void = void
>(
  getOnSubmitHandler?: (close: () => void) => (payload: TSubmitPayload) => void
) => {
  //@ts-ignore
  const closedModalState: WithIsOpen<TAdditionModalState> = { isOpen: false };

  const [dynamicModalState, setDynamicModalState] =
    useState<WithIsOpen<TAdditionModalState>>(closedModalState);

  const closeModal = () => {
    setDynamicModalState(closedModalState);
  };

  const onSubmitHandler = getOnSubmitHandler
    ? getOnSubmitHandler(closeModal)
    : closeModal;

  const onCancelHandler = () => {
    closeModal();
  };

  const openModal = (additionModalState: TAdditionModalState) => {
    setDynamicModalState({
      isOpen: true,
      ...additionModalState,
    });
  };

  return {
    modalState: {
      ...dynamicModalState,
      onSubmit: onSubmitHandler,
      onCancel: onCancelHandler,
    },
    openModal,
    closeModal,
  };
};
