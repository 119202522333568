import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDataFields } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields";
import {
  useCreateMonitoringForm
} from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/hooks";
import { ALL_SELECTED, MOSCOW } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/types";
import { useGetCities } from "@/helpers/api/city/hooks";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { usePostMonitoringQuality } from "@/helpers/api/monitoringQuality/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "@/packages/loader/Loader";

export const MonitoringQualityCreate: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState<string[] | undefined>();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { data: citiesData } = useGetCities({ limit: 0 });

  const { t } = useTranslation();

  const { data: dealersData } = useGetKiaDealerList({
    limit: 0,
    cityIds: selectedCities ? selectedCities.filter(it => it !== MOSCOW) : undefined,
  });

  const allCitiesIds = citiesData?.data.data.map((item) => item.id);
  //@ts-ignore
  const allDealersIds = dealersData?.data.data.map((item) => item.id);

  const {
    mutateAsync: postMonitoringQualityAsync,
    isLoading: isCreateLoading,
  } = usePostMonitoringQuality();

  const onSubmitHandler = async (data) => {
    const dataToServer = data;
    if (data.cityIds.includes(ALL_SELECTED)) {
      dataToServer.cityIds = allCitiesIds;
    }
    if (data.dealerIds.includes(ALL_SELECTED)) {
      dataToServer.dealerIds = allDealersIds;
    }
    try {
      await postMonitoringQualityAsync(dataToServer);
      createNotificationSuccess(
        t("monitoring.addNewProgramForm.createNotificationSuccess") || ""
      );
      navigate("/monitoring-quality");
    } catch (error) {
      createNotificationError(
        t("monitoring.addNewProgramForm.createNotificationError") || ""
      );
    }
  };

  const onCancelHandler = useCallback(() => {
    navigate("/monitoring-quality");
  }, [navigate]);

  const { form, submit, isNumberLoading } =
    useCreateMonitoringForm(t, onSubmitHandler);

  useEffect(() => {
    setSelectedCities(
      form.getValues().cityIds.includes("all")
        ? allCitiesIds
        : form.getValues().cityIds
    );
  }, [form.getValues()]);

  return (
    <DashboardLayout
      title={t("monitoring.addNewProgramForm.title") || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName={"quality-monitoring"}
        resourceAction="create"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48}/>}
      >
        <FormProvider {...form}>
          <MonitoringDataFields form={form} isNumberLoading={isNumberLoading}/>
          <Space>
            <Button
              theme="secondary"
              color="brand"
              size="middle"
              onClick={onCancelHandler}
            >
              {t("monitoring.addNewProgramForm.cancel") || ""}
            </Button>
            <Button
              theme="primary"
              color="brand"
              size="middle"
              onClick={submit}
              isLoading={isCreateLoading}
              isDisabled={isCreateLoading}
            >
              {t("monitoring.addNewProgramForm.save") || ""}
            </Button>
          </Space>
        </FormProvider>
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
