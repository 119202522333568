import {
  TDealerBrand,
  TDealerFacade,
  TDealerInterior,
  TDealerFurniture,
  TDealerDistrict,
  TDealerType,
  TDealerGroup,
} from "@/helpers/api/dc/dealers/types";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { t } from "i18next";

export const DC_BRAND_TYPE_OPTIONS: ISelectOption<TDealerBrand>[] = [
  {
    value: "mono",
    label: "registers.holdingsDC.selectMono",
  },
  {
    label: "registers.holdingsDC.selectMulti",
    value: "multi",
  },
];

export const FACADE_OPTIONS: ISelectOption<TDealerFacade>[] = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Dynamic",
    value: "dynamic",
  },
];

export const INTERIOR_OPTIONS: ISelectOption<TDealerInterior>[] = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Dynamic",
    value: "dynamic",
  },
  {
    label: "Tiger",
    value: "tiger",
  },
  {
    label: "common.cancelNo",
    value: "no",
  },
];

export const FURNITURE_OPTIONS: ISelectOption<TDealerFurniture>[] = [
  {
    label: "registers.holdingsDC.selectOld",
    value: "old",
  },
  {
    label: "registers.holdingsDC.selectNew",
    value: "new",
  },
  {
    label: "registers.holdingsDC.selectPlanNew",
    value: "plan_new",
  },
];

export const DISTRICT_OPTIONS: ISelectOption<TDealerDistrict>[] = [
  {
    label: "registers.holdingsDC.CFD",
    value: "ЦФО",
  },
  {
    label: "registers.holdingsDC.NWFD",
    value: "СЗФО",
  },
  {
    label: "registers.holdingsDC.FEFD",
    value: "ДФО",
  },
  {
    label: "registers.holdingsDC.VFD",
    value: "ПФО",
  },
  {
    label: "registers.holdingsDC.SFD",
    value: "СФО",
  },
  {
    label: "registers.holdingsDC.UFO",
    value: "УФО",
  },
  {
    label: "registers.holdingsDC.SFD",
    value: "ЮФО",
  },
];

export const DEALER_TYPE_OPTIONS: ISelectOption<TDealerType>[] = [
  {
    label: "Sales & service",
    value: "sales_and_service",
  },
  {
    label: "Service only",
    value: "service_only",
  },
];

export const DEALER_GROUP_OPTIONS: ISelectOption<TDealerGroup>[] = [
  {
    label: "registers.holdingsDC.metropolises",
    value: "metropolises",
  },
  {
    label: "registers.holdingsDC.provinces",
    value: "provinces",
  },
  {
    label: "registers.holdingsDC.regions",
    value: "regions",
  },
  {
    label: "registers.holdingsDC.satellite",
    value: "satellite",
  },
];
