import styled, { css } from "styled-components";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledTableColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};

  overflow-y: initial;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTableHeadRightList = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
`;

export const StyledParagraph = styled(Paragraph)`
  display: flex;
  justify-content: center;
`;

export const StyledTableHeadRightItem = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: repeat(2, 37px);
  align-items: center;
  width: auto;

  background-color: ${colors.gray30};
  outline: 1px solid ${colors.stroke4HeaderTable};
  text-align: center;
`;

export const StyledDaysInfoList = styled.ul`
  display: flex;
  height: 100%;
`;

export const StyledDaysInfoItem = styled.li<{ isToday: boolean }>`
  display: inline-flex;
  width: 20px;
  justify-content: center;
  align-items: center;

  ${({ isToday }) =>
    isToday &&
    css`
      background-color: black;
      color: white;
    `}
`;

export const StyledDaysBlockList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledDaysItem = styled.li`
  position: relative;
  width: 20px;
  height: 30px;
  border-right: 1px solid ${colors.stroke4HeaderTable};
  border-bottom: 1px solid ${colors.stroke4HeaderTable};
`;

export const StyledDaysBlockSubItem = styled.li`
  display: grid;

  &:first-of-type ${StyledDaysItem} {
    border-top: 1px solid ${colors.stroke4HeaderTable};
  }
`;

export const StyledDaysBlockItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: var(--margin-bottom);
  }

  &:first-of-type {
    ${StyledDaysBlockSubItem}:first-of-type ${StyledDaysItem} {
      &::before {
        box-sizing: border-box;
        position: absolute;
        content: "";
        z-index: 0;
        top: -75px;
        left: 0;
        right: 0;
        width: 20px;
        height: 74px;
        background-color: ${colors.gray30};
      }
    }
  }
`;

export const StyledDaysBlockSubList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledDaysList = styled.ul`
  display: flex;
`;

export const StyledColoredLabelContainer = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
`;
