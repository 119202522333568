import React from "react";
import { useMoveParam } from "@/helpers/api/warranty-audit/templates/hooks";
import { DraggableTable } from "@/packages/draggableTable/DraggableTable";
import { StyledTableSimple } from "./index.styles";
import { getDefaultWarrantyTableHeaders } from "./helpers";
import { TPutWarrantyParameterRequest } from "@/helpers/api/warranty-audit/templates/types";
import { useNotification } from "@/hooks/useNotification";

interface IWarrantyParametersTable {
  data?: [];
  isDppo?: boolean;
  onDeleteParameter: (id: string) => void;
  onEditClick: (id: string) => void;
  onPhotoParamEdit: (
    id: string,
    value: TPutWarrantyParameterRequest["body"]
  ) => void;
}

export const WarrantyTemplateParametersTable: React.FC<
  IWarrantyParametersTable
> = (props) => {
  const { data, isDppo, onEditClick, onDeleteParameter, onPhotoParamEdit } =
    props;


  const { mutate: move } = useMoveParam();
  const { createNotificationError, createNotificationSuccess } = useNotification();
  const headers = getDefaultWarrantyTableHeaders({
    isDppo,
    onDeleteClick: onDeleteParameter,
    onEditClick,
    onPhotoParamEdit,
  });

  const handleMove = ({ id, index }) => {
    move({
      id,
      patch: { order: index },
      onSuccess: () => createNotificationSuccess('Порядок изменен успешно'),
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  return (
    <>
      <StyledTableSimple>
        <DraggableTable
          headers={headers || []}
          data={data || []}
          onDragEnd={handleMove}
          isDndProviderImplemented
        />
      </StyledTableSimple>
    </>
  );
};
