import React, { useContext, useEffect, useMemo, useState } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { StyledChat, StyledChatBody, StyledDate, StyledImagePreview, StyledPhoto, } from "./index.styles";

import { MessageInput } from "@/components/Audits/ChatModal/components/MessageInput";
import { useGetAuditMessage, useSendAuditMessage, } from "@/helpers/api/audit-chat/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { Loader } from "@/packages/loader/Loader";
import { useModal } from "@/hooks/useModal";
import { UploadImageModal } from "./UploadImageModal";
import { MessageAdapter } from "./components/MessageAdapter";
import { MessageText } from "./components/MessageText";
import { TAuditMessage } from "@/helpers/api/audit-chat/types";
import { Button } from "@/packages/button/Button";
import { useNotification } from "@/hooks/useNotification";
import { t } from "i18next";
import { Image } from 'antd';
import { ConfigContext } from "antd/es/config-provider";

type IChatModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

export interface IMessageParams {
  taskId?: number;
  auditId?: number;
  paramId?: string;
  answerId?: string;
}

export interface IButtonCallback {
  confirmText?: string;
  confirmCb?: () => void;
  cancelText?: string;
  cancelCb?: () => void;
  sideEffectText?: string;
  sideEffectCb?: () => void;
}

interface IUploadAuditTemplateBase {
  params: IMessageParams;
  btnCallback?: IButtonCallback;
  onClose: () => void;
  isWarranty?: boolean;
  readAllAuditMessage?: (taskId, answerId) => void;
}

export interface ISendMessage {
  message?: string;
  filesIds?: string[];
  replyId?: string;
}

export const ChatModal: React.FC<IChatModalProps & IUploadAuditTemplateBase> = (
  props
) => {
  const { params, onClose, btnCallback, isWarranty = false, readAllAuditMessage } = props;
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const [page, setPage] = useState(1);
  const {
    data: messages,
    isLoading,
    refetch: updateMessages,
  } = useGetAuditMessage(
    {
      "filter[auditId]": params.auditId,
      "filter[paramId]": params.paramId,
      "filter[taskId]": params.taskId,
      "page[limit]": 50,
      "page[page]": page,
      "filter[answerId]": params.answerId,
    },
    isWarranty
  );

  readAllAuditMessage && readAllAuditMessage(params.taskId ?? params.auditId, params.answerId ?? params.paramId);

  const [currentMessages, setCurrentMessages] = useState<{
    [key: string]: TAuditMessage[];
  }>({});
  const [reply, setReply] = useState<TAuditMessage | null>(null);
  const [files, setFiles] = useState<FileList | null>(null);

  const { mutate: sendMessage } = useSendAuditMessage();
  const { createNotificationError } = useNotification();
  useEffect(() => {
    if (messages?.data?.meta?.page) {
      const page: number = messages?.data?.meta?.page;
      setCurrentMessages((prev) => ({
        ...prev,
        [page]: messages?.data.data,
      }));
    }
  }, [messages]);

  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = ({ message, filesIds, replyId }: ISendMessage) => {
    setReply(null);
    UploadModalClose();
    const preparedData = {
      auditId: params.auditId,
      paramId: params.paramId,
      taskId: params.taskId,
      answerId: params.answerId,
      message,
      filesIds: filesIds || [],
      replyId: replyId || reply?.id,
    };

    Object.entries(preparedData).forEach(([key, value]) => {
      if (!value) {
        delete preparedData[key];
      }
    });

    if (isSending) {
      return;
    }
    setIsSending(true);

    sendMessage({
      data: {
        ...preparedData,
      },
      isWarranty,
      onSuccess: () => {
        setIsSending(false);
        updateMessages();
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const {
    modalState: UploadModalState,
    openModal: UploadModalOpen,
    closeModal: UploadModalClose,
  } = useModal();

  useEffect(() => {
    if (files?.length) {
      UploadModalOpen();
    }
  }, [files]);

  const preparedMessage = useMemo(
    () =>
      Object.values(currentMessages)
        .flat()
        .reduce((acc: { [key: string]: TAuditMessage[] }, item) => {
          const date = new Date(item.createdAt).toLocaleDateString();
          if (acc[date]) {
            return { ...acc, [date]: [...acc[date], item] };
          } else {
            return { ...acc, [date]: [item] };
          }
        }, {}) || {},
    [currentMessages]
  );

  useEffect(() => {
    console.log(messages?.data.data.map(it => it.files
      //@ts-ignore
    ).flat(Infinity).map(it => it.file.fileUrl))
  }, [messages])

  return (
    <>
      <Modal
        {...props}
        title={t("audits.list.chat") || ""}
        size="small"
        isLoadingConfirmButton={isLoading}
        isDisabledCancelButton={isLoading}
        cancelButtonText={btnCallback?.cancelText}
        confirmButtonText={btnCallback?.confirmText}
        showConfirmButton={!!btnCallback?.confirmText}
        showCancelButton={!!btnCallback?.cancelText}
        onSubmit={btnCallback?.confirmCb}
        onCancel={btnCallback?.cancelCb}
        sideEffectText={btnCallback?.sideEffectText}
        onShowSideEffectConfirm={btnCallback?.sideEffectCb}
        showSideEffectButton={!!btnCallback?.sideEffectText}
        showSideEffectTitle={btnCallback?.sideEffectText}
        onClose={onClose}
      >
        <StyledChat>
          <StyledChatBody>
            {Object.entries(preparedMessage).map(([key, messages]) => {
              return (
                <>
                  <Image.PreviewGroup
                  >
                    {messages?.map((item) => {
                      const isMyMessage =
                        profile?.id === item.userId ||
                        profile?.type === item.user.type;
                      return (
                        <>
                          {item?.files?.map((fileItem) => (
                            <MessageAdapter isMyMessage={isMyMessage}>
                              <StyledPhoto
                                key={fileItem.id}
                                isMyMessage={isMyMessage}
                              >
                                <StyledImagePreview
                                  width={150}
                                  height={150}
                                  src={fileItem.file.fileUrl}
                                />
                              </StyledPhoto>
                            </MessageAdapter>
                          ))}
                          {item?.message?.length ? (
                            <MessageAdapter isMyMessage={isMyMessage}>
                              <MessageText
                                data={item}
                                isMyMessage={isMyMessage}
                                onReply={() => setReply(item)}
                              />
                            </MessageAdapter>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </Image.PreviewGroup>
                  <StyledDate>{key}</StyledDate>
                </>
              );
            })}
            {!isLoading ? <></> : <Loader size={48}/>}
            {messages?.data?.meta?.hasNextPage ? (
              <Button
                isBlock={false}
                theme="secondary"
                color="gray"
                onClick={() => setPage((prev) => prev + 1)}
              >
                {t("audits.list.downloadMore") || ""}
              </Button>
            ) : (
              <></>
            )}
          </StyledChatBody>
          <MessageInput
            reply={reply}
            onSendMessage={handleSendMessage}
            onFilesAdd={setFiles}
            onCancelReply={() => setReply(null)}
          />
        </StyledChat>
      </Modal>
      {files?.length ? (
        <UploadImageModal
          isOpen={UploadModalState.isOpen}
          onSubmit={handleSendMessage}
          onCancel={UploadModalClose}
          files={files}
        />
      ) : (
        <></>
      )}
    </>
  );
};
