import { useUploadFilesArchive } from "@/helpers/api/files/hooks";
import { useGetMonitoringQualityById } from "@/helpers/api/monitoringQuality/hooks";
import { useNotification } from "@/hooks/useNotification";
import { Loader } from "@/packages/loader/Loader";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MonitoringUpload } from "../../MonitoringCreateData/MonitoringUpload";
import { generateExpandData } from "../utils";
import { useFileForm } from "./hooks";

import {
  gridStyleDesc,
  gridStyleTitle,
  StyledButton,
  StyledCardLeft,
  StyledCardRight,
  StyledRow,
  StyledRegistryTableExpandDataForm,
} from "./index.styled";

export interface IMonitoringTableExpandDataFormProps {
  monitoringId: string;
}

export const MonitoringTableExpandDataForm: React.FC<
  IMonitoringTableExpandDataFormProps
> = ({ monitoringId }) => {
  const { t } = useTranslation();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { data: monitoringQualityById } = useGetMonitoringQualityById({
    id: monitoringId,
  });

  const { mutateAsync: uploadAllFilesAsync } = useUploadFilesArchive();

  const { form } = useFileForm(monitoringQualityById?.data);
  const [photoIds] = form.watch(["photoIds"]);

  const uploadAllFilesHandler = async () => {
    try {
      if (monitoringQualityById) {
        const { files, photos } = monitoringQualityById.data;
        const filesIds = files
          .map((file) => file.id)
          .concat(photos.map((photo) => photo.id));

        //@ts-ignore
        const response = await uploadAllFilesAsync({ ids: filesIds });
        //@ts-ignore
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        document.body.appendChild(link);
        link.click();
        createNotificationSuccess("Скачивание архива прошло успешно");
      }
    } catch (e) {
      createNotificationError("Произошла ошибка при скачивании архива");
    }
  };

  const imageFieldArray = useFieldArray({
    name: "photoIds",
    control: form.control,
    shouldUnregister: false,
  });

  const rowTableData = generateExpandData(monitoringQualityById?.data);

  useEffect(() => {
    imageFieldArray.append(photoIds);
  }, [monitoringQualityById]);

  return (
    <StyledRegistryTableExpandDataForm>
      {rowTableData ? (
        <Row gutter={12} justify="space-between">
          <Col span={16}>
            {t("monitoring.mainInfo") || ""}
            <StyledCardLeft>
              {rowTableData?.map((quality, index) => {
                return (
                  <StyledRow align="middle" key={index}>
                    <Card.Grid hoverable={false} style={gridStyleTitle}>
                      {quality.label}
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyleDesc}>
                      {quality.value}
                    </Card.Grid>
                  </StyledRow>
                );
              })}
            </StyledCardLeft>
          </Col>
          <Col span={8}>
            {t("monitoring.attachedFiles") || ""}
            <StyledCardRight>
              <FormProvider {...form}>
                <MonitoringUpload
                  label=""
                  data={photoIds}
                  isExpandedGrid
                  description=""
                  fieldArray={imageFieldArray as any}
                  readOnly
                />
                {photoIds?.length !== 0 && (
                  <StyledButton
                    color="brand"
                    theme="secondary"
                    onClick={uploadAllFilesHandler}
                  >
                    Скачать все
                  </StyledButton>
                )}
              </FormProvider>
            </StyledCardRight>
          </Col>
        </Row>
      ) : (
        <Loader size={64} />
      )}
    </StyledRegistryTableExpandDataForm>
  );
};
