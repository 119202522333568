import { TMaterialsSectionRecord } from "@/helpers/api/materials/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { Collapse } from "antd";
import styled from "styled-components";

const { Panel } = Collapse;

export const StyledTable = styled(TableSimple<TMaterialsSectionRecord>)`
  margin-top: 16px;
`;

export const StyledCollapse = styled(Collapse)`
  background: none;
  .ant-collapse-header {
    background: #fff;
    margin: 15px 0;
    border-radius: 12px !important;
  }
`;

export const StyledPanel = styled(Panel)`
  border-radius: 12px;
  border: none;
  margin-bottom: 12px;
  background: none;
`;
