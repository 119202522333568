import { MONTHS } from "@/helpers/consts";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import React from "react";
import { TableHeader } from "../KiaFlexOrdersTable/index.styles";
import { StyledTable } from "./index.styles";
import { useTranslation } from "react-i18next";

interface KiaFlexQuotasTableProps {
  data: Array<{
    generationId: string;
    months: { month: string; amount: number; count: string }[];
    name: string;
  }>;
}

const headers = [
  {
    Header: "Автомобиль",
    accessor: "name",
    isSearchable: true,
    Cell: (data) => {
      const id = data.cell.row.id;

      return <Paragraph size={14}>{data.data[id].name}</Paragraph>;
    },
  },

  ...MONTHS.map((monthName, monthsNumber) => ({
    Header: monthName,
    accessor: monthsNumber.toString(),
    width: 10,
    Cell: (data) => {
      const header = data.column.Header;
      const rowId = data.row.id;
      return <Paragraph size={12}>{data.data[rowId][header]}</Paragraph>;
    },
  })),
];

const KiaFlexQuotasTable: React.FC<KiaFlexQuotasTableProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const currentQuotasData = data.reduce((acc, item) => {
    const res = MONTHS.reduce((accum, itemSec, monthNumber) => {
      item.months.map((month) => {
        // @ts-ignore
        if (month.month === monthNumber) {
          accum[itemSec] = `${month.count}/${month.amount}`;
        } else {
          accum[itemSec] = "0/0";
        }
      });
      return accum;
    }, {} as any);

    acc.push({
      name: t(item.name),
      ...res,
    });

    return acc;
  }, [] as Array<any>);

  const tableArg = { data: currentQuotasData, headers };

  return (
    <>
      <TableHeader>
        <Space direction="vertical">
          <Paragraph size={16}>
            Квота заказов на автомобили Киа Флекс:
          </Paragraph>
          <Paragraph
            size={12}
            color="gray400"
          >{`Всего заявок: ${data?.length} активных`}</Paragraph>
        </Space>
      </TableHeader>
      <StyledTable {...tableArg} withOverflow/>
    </>
  );
};

export { KiaFlexQuotasTable };
