import { FC, ReactNode } from "react";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Icon, TSize } from "@/packages/icon/Icon";
import styled from "styled-components";

interface IPlaceholder {
  className?: string;
  children: ReactNode;
  iconName?: string;
  iconSize?: TSize;
}

const StyledPlaceholder = styled(Paragraph)`
  margin: 48px auto;
  width: 400px;

  display: grid;
  justify-items: center;
  gap: 8px;

  text-align: center;
`;

const Placeholder: FC<IPlaceholder> = (props) => {
  const { className, children, iconName = 'info', iconSize = 40 } = props;

  return (
    <StyledPlaceholder
      className={className}
      size={20}
      color="black"
      weight={700}
    >
      {iconName ? <Icon name={iconName} size={iconSize} /> : <></>}
      {children}
    </StyledPlaceholder>
  );
};

export { Placeholder };
