import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface ITemplateValues {
  title?: string;
  dealerId?: string;
  withData?: boolean;
}

export const DEFAULT_FORM_VALUES: ITemplateValues = {
  withData: false,
};

export const formSchema = () => {
  return yup.object({
    title: yup.string().required(t("audits.templates.validation.name") || ""),
    dealerId: yup
      .string()
      .required(t("audits.templates.validation.dealerAnother") || ""),
  });
};

export type TOnSubmitFormCb = (values: ITemplateValues) => void;

export const useFormValues = (onSubmit: TOnSubmitFormCb) => {
  const form = useForm<ITemplateValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const setDefaultValue = (value: ITemplateValues) => {
    Object.entries(value).forEach(([key, value]) =>
      form.setValue(key as keyof ITemplateValues, value)
    );
  };

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  const resetValues = () => {
    form.reset();
  };

  return {
    form,
    setDefaultValue,
    onSubmitHandler,
    onClearHandler,
    resetValues,
  };
};
