import { t } from "i18next";

export const formatBytes = (bytes) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const sizes = [
    `common.bytes`,
    "common.KB",
    "common.MB",
    "common.GB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${t(sizes[i])}`;
};
