import React, { useEffect, useMemo, useState } from "react";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { TFlexQuotasRecord } from "@/helpers/api/flex/settings/types";
import { useGetModelList } from "@/helpers/api/models/hooks";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { IconButton } from "@/packages/icon-button/IconButton";
import { TitleDivider } from "@/packages/TitleDivider";
import { Col, Row } from "antd";
import { UseFormSetValue } from "react-hook-form";
import { StyledAddButton, StyledAddIcon } from "./index.styles";
import { IQuotasFormValues, QuotasFormFields } from "./types";
import { useTranslation } from "react-i18next";
import { MONTHS_SELECT_ID } from "@/helpers/consts";
import { InputNumberField } from "@/packages/formElements/fields/InputNumberField";
import { generationFormValuesToDTOPatch } from "./utils";
import { useNotification } from "@/hooks/useNotification";

export interface IQuotasFieldsProps {
  setValue: UseFormSetValue<any>;
  isDisabled?: boolean;
  record?: TFlexQuotasRecord;
  isLoading?: boolean;
  setIsDisabledSubmit?: (value: boolean) => void;
}

interface TInputFields {
  generationId?: { value: undefined | string; label: undefined | string };
  amount?: number;
}

const DEFAULT_SECTION = {
  generationId: { value: undefined, label: undefined },
  amount: undefined,
};

export const DEFAULT_MONTH = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
};

export const DEFAULT_MONTH_NO_DATA = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
};

export const SettingsFields: React.FC<IQuotasFieldsProps> = (props) => {
  const {
    setValue,
    isDisabled = false,
    record,
    isLoading,
    setIsDisabledSubmit,
  } = props;
  const { t } = useTranslation();

  const { createNotificationError } = useNotification();

  const dataFromServer =
    record && generationFormValuesToDTOPatch(record, isDisabled);

  const [search, setSearch] = useState({
    model: "",
    dealer: "",
  });

  const { data: dealers } = useGetKiaDealerList({
    limit: 100,
    page: 1,
    name: search.dealer,
  });
  const { data: models } = useGetModelList({
    name: search.model,
    page: 1,
    limit: 100,
  });

  const dealerOption = useMemo(() => {
    return (
      (dealers &&
        // @ts-ignore
        dealers?.data.data.map((item) => ({
          label: item.merchantName,
          value: item.id,
        }))) ||
      []
    );
  }, [dealers]);

  const modelsOption = useMemo(() => {
    const modOptions: ISelectOption[] = [];

    models &&
      models?.data.data.map((item) => {
        modOptions.push(
          ...item.generations.map((gen) => ({
            label: `${item.name} ${gen.name} ${gen.restylingName || ""} `,
            value: gen.id,
          }))
        );
      });
    return modOptions;
  }, [models]);

  const [month, setMonth] = useState(MONTHS_SELECT_ID[0].value);

  const [data, setData] = useState<IQuotasFormValues["months"]>(
    dataFromServer?.months || DEFAULT_MONTH_NO_DATA
  );

  const handleAddSection = () => {
    // @ts-ignore
    setData((prev) => {
      return { ...prev, [month]: [...prev[month], DEFAULT_SECTION] };
    });
  };

  const handleDeleteSection = (selectedIndex: number) => {
    setData((prev) => {
      return {
        ...prev,
        [month]: prev[month].filter((_, index) => index !== selectedIndex),
      };
    });
  };

  const handleChangeValue = (
    value: string | number | null,
    key: string,
    currentIndex: number
  ) => {
    setData((prev) => {
      return {
        ...prev,
        [month]: prev[month].map((item, index) =>
          currentIndex === index ? { ...item, [key]: value } : item
        ),
      };
    });
  };

  useEffect(() => {
    setValue(QuotasFormFields.MONTHS, data);
  }, [data]);

  useEffect(() => {
    if (setIsDisabledSubmit && data[month]) {
      setIsDisabledSubmit(
        data[month].filter(
          (elem) =>
            typeof elem[QuotasFormFields.MODEL] === "object" ||
            elem[QuotasFormFields.AMOUNT] === undefined ||
            elem[QuotasFormFields.AMOUNT] === null
        ).length > 0
      );
    }
  }, [data, month]);

  useEffect(() => {
    let errorModels: boolean = false;
    if (data[month]) {
      for (let i = 0; i < data[month].length; i++) {
        if (
          data[month].filter(
            (elem) =>
              elem[QuotasFormFields.MODEL] ===
              data[month][i][QuotasFormFields.MODEL]
          ).length > 1
        ) {
          errorModels = true;
        }
      }
    }
    if (errorModels) {
      if (setIsDisabledSubmit) {
        setIsDisabledSubmit(errorModels);
      }
      createNotificationError("Модели не должны повторяться");
    }
  }, [data, month]);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            className="space-xxs"
            label={t("audits.list.dealer") || ""}
            name={QuotasFormFields.DEALER_ID}
            options={dealerOption}
            isDisabled={isDisabled || isLoading}
            placeholder={t("kiaFlex.dealerDescr")}
            isSearchShow={true}
            onSearch={(value) =>
              setSearch((prev) => ({ ...prev, dealer: value }))
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            label={t("kiaFlex.month") || ""}
            placeholder="Выберите месяц"
            name={QuotasFormFields.MONTH}
            options={MONTHS_SELECT_ID}
            onChange={(value) => setMonth(value)}
            value={month}
            isDisabled={isLoading}
          />
        </Col>
      </Row>
      <TitleDivider />
      {data[month] && !!data[month].length ? (
        data[month]?.map((item, index) => {
          return (
            <Row key={index} gutter={16} className="space-xxs">
              <Col span={12}>
                <FormSelect
                  label={t("kiaFlex.model") || ""}
                  value={item[QuotasFormFields.MODEL]}
                  name={QuotasFormFields.MODEL}
                  options={modelsOption}
                  isSearchShow={true}
                  onSearch={(value) =>
                    setSearch((prev) => ({ ...prev, model: value }))
                  }
                  description={t("kiaFlex.modelDescr") || ""}
                  onChange={(value) => {
                    !isLoading &&
                      handleChangeValue(value, QuotasFormFields.MODEL, index);
                  }}
                />
              </Col>
              <Col span={11}>
                <InputNumberField
                  value={item[QuotasFormFields.AMOUNT]}
                  label={t("kiaFlex.autoList") || ""}
                  name={QuotasFormFields.AMOUNT}
                  description={t("kiaFlex.quantity") || ""}
                  onChange={(value) =>
                    !isLoading &&
                    handleChangeValue(value, QuotasFormFields.AMOUNT, index)
                  }
                />
              </Col>

              <IconButton
                size="l"
                icon="delete"
                onClick={() => handleDeleteSection(index)}
                color="gray"
                variant="tertiary"
              />
            </Row>
          );
        })
      ) : (
        <></>
      )}
      <StyledAddButton onClick={handleAddSection}>
        <StyledAddIcon size={16} name={"plus"} />
        {t("warrantyAudits.list.add") || ""}
      </StyledAddButton>
    </>
  );
};
