import { InputField } from "@/packages/formElements/fields/InputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import React, { useEffect, useState } from "react";
import { IUseSimpleEditModalRecord } from "./hooks";
import { useTranslation } from "react-i18next";

export interface ISimpleDictEditModal
  extends Omit<
    IModal,
    "children" | "onSubmit" | "onCancel" | "isLoadingConfirmButton"
  > {
  contentTitle?: string;
  form: {
    inputLabel?: string;
    inputDescription?: string;
  };
  onSubmit: (value: IUseSimpleEditModalRecord) => void;
  isSubmitLoading?: boolean;
  onClose: () => void;
  record?: IUseSimpleEditModalRecord;
}

export const SimpleDictEditModal: React.FC<ISimpleDictEditModal> = (props) => {
  const { t } = useTranslation();

  const {
    contentTitle,
    cancelButtonText = t("registers.holdingsDC.cancel") || "",
    form: { inputDescription, inputLabel },
    onSubmit,
    isSubmitLoading,
    onClose,
    record,
    title,
    confirmButtonText,
    ...rest
  } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (rest.isOpen) {
      setValue(record?.value ?? "");
    }
  }, [record?.value, rest.isOpen]);

  const onSubmitHandler = () => {
    onSubmit({ ...record, value });
  };

  return (
    <Modal
      {...rest}
      // @ts-ignore
      title={t(title) || ""}
      onSubmit={onSubmitHandler}
      cancelButtonText={cancelButtonText}
      // @ts-ignore
      confirmButtonText={t(confirmButtonText) || ""}
      isLoadingConfirmButton={isSubmitLoading}
      onCancel={onClose}
    >
      {contentTitle ? (
        <TitleDivider size="medium">{t(contentTitle) || ""}</TitleDivider>
      ) : (
        <></>
      )}

      <InputField
        // @ts-ignore
        label={t(inputLabel) || ""}
        description={inputDescription}
        value={value}
        onChange={setValue}
      />
    </Modal>
  );
};
