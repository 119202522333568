import { WorksFormModalForm } from "@/components/MaintenanceGrids/WorksTab/WorksFormModal";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FC, useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import { CreateWorksSuccessFormValues, useCreateWorksForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

type TWorksCreateModal = Pick<IModal, "onSubmit" | "onCancel">;

const WorksCreateModal: FC<TWorksCreateModal> = (props) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const {
    actions: { createWork },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const [workTypesError, setWorkTypesError] = useState(false);

  const onSubmitButtonClick = (data: CreateWorksSuccessFormValues) => {
    const adaptWorkTypes = data.workTypes
      .filter((workType) => workType.hasOwnProperty("totalCost"))
      // @ts-ignore
      .filter((type) => Boolean(type.code));

    const adaptData = {
      ...data,
      workTypes: adaptWorkTypes,
    };

    if (adaptWorkTypes.length === 0) {
      createNotificationError("Необходимо указать минимум 1 тип работы");
      setWorkTypesError(true);
    } else {
      createWork(adaptData);
      onCancel && onCancel();
    }
  };

  const { form, onSubmitHandler } = useCreateWorksForm(onSubmitButtonClick);

  const onCancelButtonClick = () => {
    form.reset();
    onCancel && onCancel();
  };

  return (
    <Modal
      {...props}
      title={t("maintenanceGrids.worksModel.workForm.addNewWork") || ""}
      confirmButtonText={t("maintenanceGrids.worksModel.workForm.save") || ""}
      cancelButtonText={t("maintenanceGrids.worksModel.workForm.cancel") || ""}
      onSubmit={() => {
        onSubmitHandler();
        if (!workTypesError) form.formState.isValid && form.reset();
      }}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <WorksFormModalForm />
      </FormProvider>
    </Modal>
  );
};

export { WorksCreateModal };
