import { TInformationLettersRecord } from "@/helpers/api/InformationalLetters/types";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { Collapse } from "antd";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";

const { Panel } = Collapse;

export const StyledTable = styled(TableSimple<TInformationLettersRecord>)`
  margin-top: 16px;
`;

export const StyledCollapse = styled(Collapse)`
  background: none;

  .ant-collapse-header {
    background: #fff;
    margin: 15px 0;
    border-radius: 12px !important;
  }
`;

export const StyledPanel = styled(Panel)`
  border-radius: 12px;
  border: none;
  margin-bottom: 12px;
  background: none;
`;

export const StyledText = styled(Paragraph)`
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
