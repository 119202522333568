import { colors } from "@/helpers";
import { t } from "i18next";

export enum Statuses {
  TO_BE_AGREED = "to_be_agreed",
  APPROVED = "approved",
  SENT_FOR_REVISION = "sent_for_revision",
  WITHDRAWN = "withdrawn",
  REJECTED = "rejected",
}

export const statusesInTable = [
  {
    value: Statuses.TO_BE_AGREED,
    label: "На согласовании",
    variantBtn: {
      background: `${colors.yellowStatusBg}`,
      color: `${colors.yellowStatus}`,
    },
  },
  {
    value: Statuses.APPROVED,
    label: "Утверждена",
    variantBtn: {
      background: `${colors.greenStatusBg}`,
      color: `${colors.greenStatus}`,
    },
  },
  {
    value: Statuses.SENT_FOR_REVISION,
    label: "Отправлена на доработку",
    variantBtn: {
      background: `${colors.grayStatusBg}`,
      color: `${colors.grayStatus}`,
    },
  },
  {
    value: Statuses.WITHDRAWN,
    label: "Отозвана",
    variantBtn: {
      background: `${colors.pinkStatusBg}`,
      color: `${colors.pinkStatus}`,
    },
  },
  {
    value: Statuses.REJECTED,
    label: "Отклонена",
    variantBtn: {
      background: `${colors.redStatusBg}`,
      color: `${colors.redStatus}`,
    },
  },
];

export interface IMenuItemsProps {
  requestCarStatus: string;
  id: string;
  isLoading: boolean;
  monitoringId?: string;
  profileType?: string;
  permission?: boolean;
}

export const generateMenuItems = (values: IMenuItemsProps) => {
  const {
    requestCarStatus,
    id,
    isLoading,
    monitoringId,
    profileType,
    permission,
  } = values;
  if (profileType) {
    if (profileType === "kia") {
      return [
        {
          label: t("monitoring.to_be_agreed"),
          key: `${Statuses.TO_BE_AGREED},${id}`,
          disabled: Statuses.TO_BE_AGREED === requestCarStatus || isLoading,
        },
        {
          label: t("monitoring.send_for_revision"),
          key: `${Statuses.SENT_FOR_REVISION},${id}`,
          disabled:
            Statuses.SENT_FOR_REVISION === requestCarStatus || isLoading,
        },
        {
          label: t("monitoring.approve"),
          key: `${Statuses.APPROVED},${id}`,
          disabled: Statuses.APPROVED === requestCarStatus || isLoading,
        },
        {
          label: t("monitoring.reject"),
          key: `${Statuses.REJECTED},${id}`,
          disabled: Statuses.REJECTED === requestCarStatus || isLoading,
        },
      ];
    } else {
      if (monitoringId && permission) {
        const defaultMenuItems = {
          label: t("monitoring.edit"),
          key: `/monitoring-quality/${monitoringId}/edit-application/${id}`,
        };
        switch (requestCarStatus) {
          case Statuses.TO_BE_AGREED:
            return [
              defaultMenuItems,
              {
                label: t("monitoring.withdraw"),
                key: `${Statuses.WITHDRAWN},${id}`,
              },
            ];
          case Statuses.SENT_FOR_REVISION:
            return [
              defaultMenuItems,
              {
                label: t("monitoring.send_for_agree"),
                key: `${Statuses.TO_BE_AGREED},${id}`,
              },
            ];
          case Statuses.WITHDRAWN:
            return [
              defaultMenuItems,
              {
                label: t("monitoring.send_again"),
                key: `${Statuses.TO_BE_AGREED},${id}`,
              },
            ];
          default:
            return [defaultMenuItems];
        }
      }
    }
  }
};
