import React, { FC, useEffect, useState } from "react";
import { usePagination } from "@/hooks/usePagination";
import { LogsTableAdapter } from "@/adapters/logs/LogsTableAdapter";
import { useGetMaintanaceGridEventLog } from "@/helpers/api/eventLogs/hooks";
import { useNotification } from "@/hooks/useNotification";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { ITab } from "@/packages/tabs/Tabs";
import { useDebounce } from "use-debounce";

interface IGridsTypeDataItem {
  dateTime: string;
  account: string;
  user: {
    name: string;
    email: string;
  };
  change: {
    title: string;
    value: string;
  };
  actions?: {
    onRevert?: () => void;
  };
  [key: string]: any;
}

interface LogsTabType {
  isTabChanged: ITab | undefined;
}

const LogsTab: FC<LogsTabType> = (props) => {
  const { isTabChanged } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 800);

  const { paginationParams, setPage } = usePagination();
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();
  const {
    data: maintananceGridLogs,
    isLoading,
    refetch,
  } = useGetMaintanaceGridEventLog({
    ...paginationParams,
    search: debouncedSearchValue,
  });

  const handleSuccessRestore = () => {
    refetch();
    createNotificationSuccess(
      t("models.notifications.createNotificationSuccess") || ""
    );
  };

  const handeRestoreError = (error: AxiosError<{ message: string }>) => {
    createNotificationError(
      !Array.isArray(error?.response?.data?.message)
        ? error?.response?.data?.message
        : error?.response?.data?.message[0]
    );
  };

  useEffect(() => {
    refetch();
  }, [isTabChanged]);

  return (
    <LogsTableAdapter
      isLoading={isLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      //@ts-ignore
      data={maintananceGridLogs?.data?.data || []}
      setPage={setPage}
      hasPagination={!!maintananceGridLogs?.data.meta?.pageCount}
      paginationParams={{
        page: paginationParams.page,
        limit: maintananceGridLogs?.data.meta?.pageCount || 10,
      }}
    />
  );
};

export { LogsTab };
