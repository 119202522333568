import React from "react";
import { Space } from "antd";

import {
  TOnSubmitRegistryEditFormCb,
  useRegistryEditDataForm,
} from "@/components/RegistryDataFields/hooks";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { Button } from "@/packages/button/Button";
import { FormProvider } from "react-hook-form";
import { RegistryDataFields } from "../../RegistryDataFields";
import { StyledDataTab } from "./index.styled";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/contexts/useProfile";

export interface IRegistryDetailDataTab {
  dealer: TDealerRecord;
  onEdit: TOnSubmitRegistryEditFormCb;
  isReadOnly?: boolean;
}

export const RegistryDetailDataTab: React.FC<IRegistryDetailDataTab> = ({
  dealer,
  onEdit,
  isReadOnly,
}) => {
  const {
    state: { profile },
  } = useProfile();

  const { form, submit, isSubmitLoading, reset } = useRegistryEditDataForm(
    {
      ...dealer,
      // @ts-ignore
      city: dealer?.city?.name,
      engCity: dealer?.city?.latinName,
      cityName: dealer?.city?.name,
      dealerManager: {
        ...dealer.dealerManager,
        // @ts-ignore
        directorName: dealer.dealerManager?.director?.firstName,
        // @ts-ignore
        directorLastName: dealer.dealerManager?.director?.lastName,
        // @ts-ignore
        directorEmail: dealer.dealerManager?.director?.email._address,
        // @ts-ignore
        salesManagerName: dealer.dealerManager?.salesManager?.firstName,
        // @ts-ignore
        salesManagerLastName: dealer.dealerManager?.salesManager?.lastName,
        // @ts-ignore
        salesManagerEmail: dealer.dealerManager?.salesManager?.email._address,

        marketingManagerName: dealer.dealerManager?.marketingManager?.firstName,
        marketingManagerLastName:
          dealer.dealerManager?.marketingManager?.lastName,
        marketingManagerEmail:
          dealer.dealerManager?.marketingManager?.email._address,

        chiefTechnicalOfficeName: dealer.dealerManager?.cto?.firstName,
        chiefTechnicalOfficeLastName: dealer.dealerManager?.cto?.lastName,
        chiefTechnicalOfficeEmail: dealer.dealerManager?.cto?.email._address,
        chiefAccountantName: dealer.dealerManager?.chiefAccountant?.firstName,
        chiefAccountantLastName:
          dealer.dealerManager?.chiefAccountant?.lastName,
        chiefAccountantEmail:
          dealer.dealerManager?.chiefAccountant?.email._address,
        holding:
          profile?.type === "dealer"
            ? dealer?.holding?.name
            : dealer?.holding?.id,
        // @ts-ignore
        files: dealer.files.map((file) => file.id),
        domains: dealer.domains.map((value) => ({domain: value})),
      },
    },
    onEdit,
    isReadOnly
  );

  const { t } = useTranslation();

  return (
    <StyledDataTab>
      <FormProvider {...form}>
        <RegistryDataFields isReadOnly={isReadOnly} />
      </FormProvider>
      {!isReadOnly ? (
        <Space>
          <Button
            theme="primary"
            color="brand"
            size="middle"
            onClick={submit}
            // isDisabled={!form.formState.isDirty}
            isLoading={isSubmitLoading}
          >
            {t("models.logsModel.save") || ""}
          </Button>
          <Button
            theme="secondary"
            color="brand"
            size="middle"
            onClick={reset}
            isDisabled={!form.formState.isDirty}
          >
            {t("registers.holdingsDC.cancel") || ""}
          </Button>
        </Space>
      ) : (
        <></>
      )}
    </StyledDataTab>
  );
};
