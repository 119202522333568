import React from "react";
import { UseFieldArrayReturn } from "react-hook-form";

import { Field } from "@/packages/formElements/fields/Field";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { UploadType } from "@/packages/UploadImageDropzone";

import { TFileRecord } from "@/helpers/api/files/types";

import { IAutoParkFormValues, IFileInfo } from "@/components/AutoPark/AutoParkForm/hooks";

import {
  StyledMonitoringUpload,
  StyledParagraph,
  StyledSubTitleWrap,
  StyledUploadFieldWrap,
  StyledUploadItem,
  StyledUploadList,
} from "./index.styles";

const getUploadTitleInfo = (file: TFileRecord | null): JSX.Element => {
  if (!file) {
    return <></>;
  }
  return (
    <>
      <StyledParagraph size={12} color="gray">
        {file.fileName.split(file.extension)[0]}
      </StyledParagraph>
      <StyledSubTitleWrap>
        <StyledParagraph size={12} color="gray3">
          {file.extension.slice(1).toUpperCase()}
        </StyledParagraph>
      </StyledSubTitleWrap>
    </>
  );
};

export type FieldsName = "photoIds" | "fileIds";

export interface IMonitoringUpload {
  label: string;
  data: IFileInfo[];
  description: string;
  isExpandedGrid?: boolean;
  allowedTypes?: string[];
  fieldsName?: FieldsName;
  fieldArray: UseFieldArrayReturn<IAutoParkFormValues, any, "id" | "type">;
  readOnly: boolean;
  maxCount?: number;
}

export const MonitoringUpload: React.FC<IMonitoringUpload> = (props) => {
  const {
    data,
    label,
    fieldsName,
    fieldArray,
    description,
    isExpandedGrid,
    allowedTypes,
    readOnly,
    maxCount = 100,
  } = props;

  const { fields, append, remove } = fieldArray;

  if (
    data?.length !== maxCount &&
    data?.every((el) => el?.value !== null) &&
    !readOnly
  ) {
    append(
      new Array(1)?.fill({
        value: null,
      })
    );
  }

  const typeInfo =
    fieldsName === "photoIds" ? UploadType.IMAGE : UploadType.FILE;

  const typePath =
    fieldsName === "photoIds"
      ? "quality-monitoring/photos"
      : "quality-monitoring/files";

  return (
    <StyledMonitoringUpload>
      <Field.Label>{label}</Field.Label>
      <StyledUploadList isExpandedGrid={isExpandedGrid}>
        {fields?.slice(0, maxCount)?.map((item, index) => (
          <StyledUploadItem key={item?.id}>
            {getUploadTitleInfo(data[index].value)}
            <StyledUploadFieldWrap>
              <FormImageDropzoneField
                path={
                  fieldsName
                    ? typePath
                    : fieldsName === "photoIds"
                      ? "quality-monitoring/photos"
                      : "quality-monitoring/files"
                }
                typeInfo={
                  fieldsName
                    ? typeInfo
                    : item.type === "photoIds"
                      ? UploadType.IMAGE
                      : UploadType.FILE
                }
                maxFileSize={25000000}
                allowedTypes={allowedTypes}
                isWithCustomPlaceholderTitle={false}
                name={`${fieldsName || item.type}.${index}.value`}
                disabled={readOnly}
              />
            </StyledUploadFieldWrap>
          </StyledUploadItem>
        ))}
      </StyledUploadList>
      <Field.FieldDescription>{description}</Field.FieldDescription>
    </StyledMonitoringUpload>
  );
};
