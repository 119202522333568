import { IdentityFormCard } from "@/components/IdentityFormCard";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { RegistrationFormSuccessCallback, useRegistrationForm } from "./hooks";
import { StyledFormTitle, StyledHeaderContainer } from "./index.styles";
import { RegistrationFinalForm } from "./RegistrationFinalForm";
import { RegistrationFormFirstStep } from "./RegistrationFormFirstStep";
import { RegistrationFormSecondStep } from "./RegistrationFormSecondStep";
import { useTranslation } from "react-i18next";

export type RegisterFormProps = {
  onSubmit: RegistrationFormSuccessCallback;
  isSubmitSuccess: boolean;
};

export const RegisterForm: React.FC<RegisterFormProps> = ({
  onSubmit,
  isSubmitSuccess,
}) => {
  const {
    currentStep,
    setCurrentStep,
    onSubmitFirstStep,
    onSubmitSecondStep,
    isSubmitLoading,
  } = useRegistrationForm(onSubmit);
  const { t } = useTranslation();

  useEffect(() => {
    if (isSubmitSuccess && currentStep === 2) {
      setCurrentStep(3);
    }
  }, [currentStep, isSubmitSuccess]);

  if (currentStep === 3) {
    return <RegistrationFinalForm />;
  }

  return (
    <IdentityFormCard>
      <StyledHeaderContainer>
        <StyledFormTitle level={1} weight={400}>
          {t("authorization.title_reg") || ""}
        </StyledFormTitle>
        <Paragraph size={14} color="gray200">
          {t("authorization.subTitle_reg") || ""}&nbsp;
          <Link to="/login">{t("authorization.enterIn") || ""}</Link>
        </Paragraph>
      </StyledHeaderContainer>

      {currentStep === 1 && (
        <RegistrationFormFirstStep onSubmit={onSubmitFirstStep} />
      )}
      {currentStep === 2 && (
        <RegistrationFormSecondStep
          onSubmit={onSubmitSecondStep}
          isSubmitLoading={isSubmitLoading}
        />
      )}
    </IdentityFormCard>
  );
};
