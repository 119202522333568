import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TGetSparesRequest,
  TGetSparesResponse,
  TSparesUpdateResponse,
} from "./types";
import {
  TGsmCreateRequest,
  TGsmCreateResponse,
  TGsmDeleteRequest,
  TGsmDeleteResponse,
  TGsmUpdateRequest,
  TGsmUpdateResponse,
} from "@/helpers/api/maintenanceGrids/gsm/types";
import { AxiosError } from "axios";
import { useNotification } from "@/hooks/useNotification";

export const useGetSpares = (request: TGetSparesRequest, isEnabled = true) => {
  return useQuery(
    ["sparesList", request],
    () => api.get<TGetSparesResponse>("/spares", { params: request }),
    { enabled: isEnabled }
  );
};

export const useCreateSpares = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TGsmCreateRequest) => api.post<TGsmCreateResponse>("/spares", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sparesList"]);
      },
    }
  );
};

export const useUpdateSpares = () => {
  const queryClient = useQueryClient();

  return useMutation(() => api.post<TSparesUpdateResponse>("/spares"), {
    onSuccess: () => {
      queryClient.invalidateQueries(["sparesList"]);
    },
  });
};

export const useDeleteSpares = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TGsmDeleteRequest) =>
      api.delete<TGsmDeleteResponse>(`/spares/${data.id}`),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["spares", variables.id]);
        queryClient.invalidateQueries(["sparesList"]);
      },
    }
  );
};

export const useUpdateSpare = () => {
  const queryClient = useQueryClient();
  const { createNotificationError } = useNotification();
  return useMutation(
    (data: { id: TGsmUpdateRequest["id"]; data: TGsmCreateRequest, onCancel?: () => void; }) =>
      api.put<TGsmUpdateResponse>(`/spares/${data.id}`, data.data),
    {
      onSuccess: (_, variables) => {
        variables.onCancel && variables.onCancel();
        queryClient.invalidateQueries(["spares", variables.id]);
        queryClient.invalidateQueries(["sparesList"]);
        queryClient.invalidateQueries(["gsmList"]);
      },
      onError: (error: AxiosError<any>) => {
        createNotificationError(
          error?.response?.data?.message
        );
      },
    }
  );
};
