import { TKiaDealerCreateRequest, TKiaDealerRecord, } from "@/helpers/api/dc/kia-dealers/types";
import { boolStringToBool } from "@/helpers/boolStringUtils";
import { StringBoolean } from "@/helpers/types";
import {
  emailSchema,
  mobisCodeSchema,
  personalStringSchema,
  requiredAddressStringSchema,
  requiredCarStorageStringSchema,
  requiredCityStringSchema,
  requiredDateScheme,
  requiredDirectorStringSchema,
  requiredNumberSchema,
  requiredSelectValueSchema,
  requiredStringSchema,
  sapCodeSchema,
  showroomCodeSchema,
} from "@/helpers/validation";
import * as yup from "yup";
import { COUNTRY_SELECT_OPTIONS } from "@/packages/formElements/fields/CountrySelect";
import {
  DC_BRAND_TYPE_OPTIONS,
  DEALER_GROUP_OPTIONS,
  DEALER_TYPE_OPTIONS,
  DISTRICT_OPTIONS,
  FACADE_OPTIONS,
  FURNITURE_OPTIONS,
  INTERIOR_OPTIONS,
} from "./constants";
import { RegistryDataFormFieldsEnum, TRegistryDataFieldsSuccessValues, TRegistryDataFieldsValues, } from "./types";
import { TType } from "@/helpers/api/profile/types";
import { t } from "i18next";

export const registryFieldsSchema = () => {
  return yup
    .object({
      [RegistryDataFormFieldsEnum.LawName]: yup
        .string()
        .required(t("registers.holdingsDC.validation.name") || "")
        .max(150, t("registers.holdingsDC.validation.max_150") || ""),
      [RegistryDataFormFieldsEnum.WarrantyCode]: yup
        .string()
        .required(t("registers.holdingsDC.validation.code") || "")
        .matches(
          /^\w{5}$/,
          t("registers.holdingsDC.validation.codeSymbols") || ""
        ),
      [RegistryDataFormFieldsEnum.INN]: yup
        .string()
        .required(t("registers.holdingsDC.validation.INN") || "")
        .nullable(),
      [RegistryDataFormFieldsEnum.NumDealerContract]: yup
        .string()
        .required(t("registers.holdingsDC.validation.dealer") || "")
        .max(50, t("registers.holdingsDC.validation.max_50") || ""),

      [RegistryDataFormFieldsEnum.MerchantName]: yup
        .string()
        .required(t("registers.holdingsDC.validation.commercial") || "")
        .max(30, t("registers.holdingsDC.validation.max_30") || ""),
      [RegistryDataFormFieldsEnum.MobisCode]: mobisCodeSchema(true),
      [RegistryDataFormFieldsEnum.LawAddress]: yup
        .string()
        .required(t("registers.holdingsDC.validation.commercialAddress") || "")
        .max(150, t("registers.holdingsDC.validation.max_150") || ""),
      [RegistryDataFormFieldsEnum.DealerType]: requiredSelectValueSchema(
        DEALER_TYPE_OPTIONS,
        t("registers.holdingsDC.validation.dealerType") || ""
      ),
      [RegistryDataFormFieldsEnum.DealerGroup]: requiredSelectValueSchema(
        DEALER_GROUP_OPTIONS,
        t("registers.holdingsDC.validation.dealerGroup") || ""
      ),

      [RegistryDataFormFieldsEnum.Name]: requiredStringSchema().max(
        30,
        t("registers.holdingsDC.validation.max_30") || ""
      ),
      [RegistryDataFormFieldsEnum.SapCode]: sapCodeSchema(true),
      [RegistryDataFormFieldsEnum.ShowroomCode]: showroomCodeSchema(),
      [RegistryDataFormFieldsEnum.Region]: requiredStringSchema().max(
        150,
        t("registers.holdingsDC.validation.max_150") || ""
      ),

      [RegistryDataFormFieldsEnum.DealerContractStart]: requiredDateScheme(),
      [RegistryDataFormFieldsEnum.DealerContractEnd]: requiredDateScheme().when(
        RegistryDataFormFieldsEnum.DealerContractStart,
        (start, yup) =>
          yup.min(start ?? 0, t("registers.holdingsDC.validation.date") || "")
      ),

      [RegistryDataFormFieldsEnum.Country]: requiredSelectValueSchema(
        COUNTRY_SELECT_OPTIONS
      ),
      [RegistryDataFormFieldsEnum.City]: requiredCityStringSchema(),
      [RegistryDataFormFieldsEnum.EngCity]: yup
        .string()
        .matches(/^[A-Za-z0-9 ,«»";:'`?.—_-]+$/, {
          message: t("registers.holdingsDC.validation.letters") || "",
          excludeEmptyString: true,
        })
        .nullable()
        .max(50, t("registers.holdingsDC.validation.max_50") || ""),
      [RegistryDataFormFieldsEnum.Population]: requiredNumberSchema(),

      [RegistryDataFormFieldsEnum.Address]: requiredAddressStringSchema(),
      [RegistryDataFormFieldsEnum.District]:
        requiredSelectValueSchema(DISTRICT_OPTIONS),
      [RegistryDataFormFieldsEnum.CarStorage]: requiredCarStorageStringSchema(),
      [RegistryDataFormFieldsEnum.DealerEmail]: emailSchema(true).nullable(),

      [RegistryDataFormFieldsEnum.DirectorName]: requiredDirectorStringSchema(),
      [RegistryDataFormFieldsEnum.DirectorLastName]:
        requiredDirectorStringSchema(),
      [RegistryDataFormFieldsEnum.DirectorEmail]: emailSchema(true).nullable(),
      [RegistryDataFormFieldsEnum.SalesManagerName]: personalStringSchema(),
      [RegistryDataFormFieldsEnum.SalesManagerLastName]: personalStringSchema(),
      [RegistryDataFormFieldsEnum.MarketingManagerName]: personalStringSchema(),
      [RegistryDataFormFieldsEnum.MarketingManagerLastName]:
        personalStringSchema(),
      [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeName]:
        personalStringSchema(),
      [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeLastName]:
        personalStringSchema(),
      [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeEmail]:
        emailSchema().nullable(),
      [RegistryDataFormFieldsEnum.ChiefAccountantName]: personalStringSchema(),
      [RegistryDataFormFieldsEnum.ChiefAccountantLastName]:
        personalStringSchema(),
      [RegistryDataFormFieldsEnum.SalesManagerEmail]: emailSchema().nullable(),

      [RegistryDataFormFieldsEnum.MarketingManagerEmail]:
        emailSchema().nullable(),
      [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeEmail]:
        emailSchema().nullable(),

      [RegistryDataFormFieldsEnum.ChiefAccountantEmail]:
        emailSchema().nullable(),

      [RegistryDataFormFieldsEnum.DealerBrand]: requiredSelectValueSchema(
        DC_BRAND_TYPE_OPTIONS
      ),
      [RegistryDataFormFieldsEnum.Facade]:
        requiredSelectValueSchema(FACADE_OPTIONS),
      [RegistryDataFormFieldsEnum.Interior]:
        requiredSelectValueSchema(INTERIOR_OPTIONS),
      [RegistryDataFormFieldsEnum.Mast]: yup
        .boolean()
        .required(t("registers.holdingsDC.validation.full") || ""),

      [RegistryDataFormFieldsEnum.Furniture]:
        requiredSelectValueSchema(FURNITURE_OPTIONS),

      [RegistryDataFormFieldsEnum.WarrantyCurator]: requiredStringSchema(),

      [RegistryDataFormFieldsEnum.ZoneDevelopmentManager]:
        requiredStringSchema(),
      [RegistryDataFormFieldsEnum.ZoneSalesManager]: requiredStringSchema(),
      [RegistryDataFormFieldsEnum.ZoneServiceManager]: requiredStringSchema(),
    })
    .required();
};

export const DEFAULT_REGISTRY_DATA_FORM_VALUES: TRegistryDataFieldsValues = {
  [RegistryDataFormFieldsEnum.LawName]: "",
  [RegistryDataFormFieldsEnum.WarrantyCode]: "",
  [RegistryDataFormFieldsEnum.INN]: "",
  [RegistryDataFormFieldsEnum.NumDealerContract]: "",
  [RegistryDataFormFieldsEnum.MerchantName]: "",
  [RegistryDataFormFieldsEnum.SapCode]: "",
  [RegistryDataFormFieldsEnum.LawAddress]: "",
  [RegistryDataFormFieldsEnum.DealerType]: "sales_and_service",
  [RegistryDataFormFieldsEnum.DealerGroup]: "metropolises",
  [RegistryDataFormFieldsEnum.Name]: "",
  [RegistryDataFormFieldsEnum.MobisCode]: "",
  [RegistryDataFormFieldsEnum.ShowroomCode]: "",
  [RegistryDataFormFieldsEnum.Holding]: "",
  [RegistryDataFormFieldsEnum.Region]: "",
  [RegistryDataFormFieldsEnum.Country]: null,
  [RegistryDataFormFieldsEnum.CityName]: "",

  [RegistryDataFormFieldsEnum.CompanyStartDate]: null,
  [RegistryDataFormFieldsEnum.AddressStartDate]: null,
  [RegistryDataFormFieldsEnum.DealerContractStart]: null,
  [RegistryDataFormFieldsEnum.DealerContractEnd]: null,
  [RegistryDataFormFieldsEnum.CityAmount]: null,
  [RegistryDataFormFieldsEnum.District]: null,
  [RegistryDataFormFieldsEnum.City]: "",
  [RegistryDataFormFieldsEnum.EngCity]: "",
  [RegistryDataFormFieldsEnum.Population]: null,
  [RegistryDataFormFieldsEnum.Address]: "",
  [RegistryDataFormFieldsEnum.CarStorage]: "",
  [RegistryDataFormFieldsEnum.DealerEmail]: "",
  [RegistryDataFormFieldsEnum.Phone1]: "",
  [RegistryDataFormFieldsEnum.Phone2]: "",
  [RegistryDataFormFieldsEnum.Phone3]: "",
  [RegistryDataFormFieldsEnum.Phone4]: "",
  [RegistryDataFormFieldsEnum.IsVerifyDomain]: false,

  [RegistryDataFormFieldsEnum.DirectorName]: "",
  [RegistryDataFormFieldsEnum.DirectorLastName]: "",
  [RegistryDataFormFieldsEnum.DirectorEmail]: "",
  [RegistryDataFormFieldsEnum.SalesManagerName]: "",
  [RegistryDataFormFieldsEnum.SalesManagerLastName]: "",
  [RegistryDataFormFieldsEnum.SalesManagerEmail]: "",
  [RegistryDataFormFieldsEnum.MarketingManagerName]: "",
  [RegistryDataFormFieldsEnum.MarketingManagerLastName]: "",
  [RegistryDataFormFieldsEnum.MarketingManagerEmail]: "",
  [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeName]: "",
  [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeLastName]: "",
  [RegistryDataFormFieldsEnum.ChiefTechnicalOfficeEmail]: "",
  [RegistryDataFormFieldsEnum.ChiefAccountantName]: "",
  [RegistryDataFormFieldsEnum.ChiefAccountantLastName]: "",
  [RegistryDataFormFieldsEnum.ChiefAccountantEmail]: "",

  [RegistryDataFormFieldsEnum.DealerBrand]: "mono",
  [RegistryDataFormFieldsEnum.Facade]: "red",
  [RegistryDataFormFieldsEnum.Interior]: "tiger",
  [RegistryDataFormFieldsEnum.Mast]: true,
  [RegistryDataFormFieldsEnum.Furniture]: "old",
  [RegistryDataFormFieldsEnum.RoundPodium]: true,
  [RegistryDataFormFieldsEnum.RoofInstallation]: true,
  [RegistryDataFormFieldsEnum.FurnitureDynamicCube]: true,
  [RegistryDataFormFieldsEnum.ShowroomArea]: null,
  [RegistryDataFormFieldsEnum.LocksmithArea]: null,
  [RegistryDataFormFieldsEnum.WarehouseArea]: null,
  [RegistryDataFormFieldsEnum.WarrantyCurator]: "",

  [RegistryDataFormFieldsEnum.ZoneDevelopmentManager]: "",
  [RegistryDataFormFieldsEnum.ZoneSalesManager]: "",
  [RegistryDataFormFieldsEnum.ZoneServiceManager]: "",
  domains: [{ domain: '' }],
};

export const dealerRecordToRegistryFields = (
  record: TKiaDealerRecord,
  profileType: TType,
  isReadOnly: boolean | undefined
): TRegistryDataFieldsValues => {
  const {
    holding,
    // domains,
    companyStartDate,
    addressStartDate,
    dealerContractStart,
    dealerContractEnd,
    phones,
    city,
    domains,
    dealerBuildInfrastructure: { ...restDealerBuildInfrastructure },
    dealerManager: { id: _, ...restDealerManager },
    ...restFields
  } = record;

  const getCityData = (cityName, cityObj) => {
    if (cityName) {
      return cityName;
    } else if (!cityName && cityObj?.name) {
      return cityObj?.name;
    } else if (!cityName && !cityObj?.name) {
      return "";
    } else
      return {
        ...cityObj,
        latinName: cityObj?.latinName,
      };
  };
  return {
    ...restFields,
    holding: holding?.id || "",

    companyStartDate: companyStartDate ? new Date(companyStartDate) : null,
    addressStartDate: addressStartDate ? new Date(addressStartDate) : null,
    dealerContractStart: dealerContractStart
      ? new Date(dealerContractStart)
      : null,
    dealerContractEnd: dealerContractEnd ? new Date(dealerContractEnd) : null,
    city: getCityData(
      // @ts-ignore
      record?.cityName,
      record?.city ? record?.city : null
    ),
    // @ts-ignore
    engCity: record?.engCity ? record?.engCity : record?.city?.latinName,
    phone1: phones ? phones[0] : "",
    phone2: phones ? phones[1] : "",
    phone3: phones ? phones[2] : "",
    phone4: phones ? phones[3] : "",
    ...restDealerBuildInfrastructure,
    ...restDealerManager,
    // @ts-ignore
    directorName: restDealerManager?.director?.firstName,
    // @ts-ignore
    directorLastName: restDealerManager?.director?.lastName,
    // @ts-ignore
    directorEmail: restDealerManager?.director?.email._address,
    // @ts-ignore
    salesManagerName: restDealerManager?.salesManager?.firstName,
    // @ts-ignore
    salesManagerLastName: restDealerManager?.salesManager?.lastName,
    // @ts-ignore
    salesManagerEmail: restDealerManager?.salesManager?.email._address,

    marketingManagerName: restDealerManager?.marketingManager?.firstName,
    marketingManagerLastName: restDealerManager?.marketingManager?.lastName,
    // @ts-ignore
    marketingManagerEmail: restDealerManager?.marketingManager?.email._address,

    chiefTechnicalOfficeName: restDealerManager?.cto?.firstName,
    chiefTechnicalOfficeLastName: restDealerManager?.cto?.lastName,
    // @ts-ignore
    chiefTechnicalOfficeEmail: restDealerManager?.cto?.email._address,

    chiefAccountantName: restDealerManager?.chiefAccountant?.firstName,
    chiefAccountantLastName: restDealerManager?.chiefAccountant?.lastName,
    // @ts-ignore
    chiefAccountantEmail: restDealerManager?.chiefAccountant?.email._address,
    domains: domains.map((value): { domain: string; } => ({ domain: value })),
    warrantyCurator: !isReadOnly
      ? restDealerBuildInfrastructure.warrantyCurator?.id
      : `${restDealerBuildInfrastructure.warrantyCurator?.firstName} ${restDealerBuildInfrastructure.warrantyCurator?.lastName}`,
    zoneDevelopmentManager: !isReadOnly
      ? restDealerBuildInfrastructure.zoneDevelopmentManager?.id
      : `${restDealerBuildInfrastructure.zoneDevelopmentManager?.firstName} ${restDealerBuildInfrastructure.zoneDevelopmentManager?.lastName}`,
    zoneSalesManager: !isReadOnly
      ? restDealerBuildInfrastructure.zoneSalesManager?.id
      : `${restDealerBuildInfrastructure.zoneSalesManager?.firstName} ${restDealerBuildInfrastructure.zoneSalesManager?.lastName}`,
    zoneServiceManager: !isReadOnly
      ? restDealerBuildInfrastructure.zoneServiceManager?.id
      : `${restDealerBuildInfrastructure.zoneServiceManager?.firstName} ${restDealerBuildInfrastructure.zoneServiceManager?.lastName}`,
  };
};

export type dealerManagerType = {
  directorName: string;
  directorLastName: string;
  directorEmail: string;
  marketingManagerName: string;
  marketingManagerLastName: string;
  marketingManagerEmail: string;
  salesManagerName: string;
  salesManagerLastName: string;
  salesManagerEmail: string;
  chiefTechnicalOfficeName: string;
  chiefTechnicalOfficeLastName: string;
  chiefTechnicalOfficeEmail: string;
  chiefAccountantName: string;
  chiefAccountantLastName: string;
  chiefAccountantEmail: string;
};

export type dealerManagerTypes =
  TRegistryFieldsDealerDTODataPart["dealerManager"] & dealerManagerType;

export type TRegistryFieldsDealerDTODataPart = Omit<
  TKiaDealerCreateRequest,
  "id" | "isActive" | "files"
>;

export const successRegistryFieldsToDealerDTO = (
  values: TRegistryDataFieldsSuccessValues
): TRegistryFieldsDealerDTODataPart => {
  const {
    chiefAccountantEmail,
    chiefAccountantLastName,
    chiefAccountantName,
    chiefTechnicalOfficeEmail,
    chiefTechnicalOfficeLastName,
    chiefTechnicalOfficeName,
    directorEmail,
    directorLastName,
    directorName,
    marketingManagerEmail,
    marketingManagerLastName,
    marketingManagerName,
    salesManagerEmail,
    salesManagerLastName,
    salesManagerName,
    holding,

    dealerBrand,
    facade,
    furniture,
    furnitureDynamicCube,
    interior,
    mast,
    roofInstallation,
    roundPodium,
    zoneDevelopmentManager,
    zoneSalesManager,
    zoneServiceManager,
    locksmithArea,
    showroomArea,
    warehouseArea,
    phone1,
    phone2,
    phone3,
    phone4,
    warrantyCurator,

    companyStartDate,
    addressStartDate,
    dealerContractStart,
    dealerContractEnd,
    population,
    domains,
    ...restFields
  } = values;
  // @ts-ignore
  const dealerManager: dealerManagerTypes = {
    chiefAccountantLastName,
    chiefAccountantName,
    chiefTechnicalOfficeLastName,
    chiefTechnicalOfficeName,
    directorEmail,
    directorLastName,
    directorName,
    marketingManagerLastName,
    marketingManagerName,
    salesManagerLastName,
    salesManagerName,
  };

  if (chiefAccountantEmail)
    dealerManager.chiefAccountantEmail = chiefAccountantEmail;
  if (chiefTechnicalOfficeEmail)
    dealerManager.chiefTechnicalOfficeEmail = chiefTechnicalOfficeEmail;
  if (marketingManagerEmail)
    dealerManager.marketingManagerEmail = marketingManagerEmail;
  if (salesManagerEmail) dealerManager.salesManagerEmail = salesManagerEmail;

  const dealerBuildInfrastructure: TRegistryFieldsDealerDTODataPart["dealerBuildInfrastructure"] =
    {
      dealerBrand,
      facade,
      furniture,
      furnitureDynamicCube,
      interior,
      mast: boolStringToBool(mast as unknown as StringBoolean), // TODO Узнать почему, найти баг
      roofInstallation,
      roundPodium,
      zoneDevelopmentManager,
      zoneSalesManager,
      zoneServiceManager,
      warrantyCurator,
      locksmithArea: locksmithArea ? Number(locksmithArea) : undefined,
      showroomArea: showroomArea ? Number(showroomArea) : undefined,
      warehouseArea: warehouseArea ? Number(warehouseArea) : undefined,
    };

  const phones = [phone1, phone2, phone3, phone4].filter(Boolean);

  const result: TRegistryFieldsDealerDTODataPart & dealerManagerTypes = {
    dealerManager: {
      // @ts-ignore
      director:
        dealerManager.directorName ||
        dealerManager.directorLastName ||
        dealerManager.directorEmail
          ? {
            ...dealerManager.director,
            firstName: dealerManager.directorName
              ? dealerManager.directorName
              : "",
            lastName: dealerManager.directorLastName
              ? dealerManager.directorLastName
              : "",
            email: dealerManager.directorEmail
              ? dealerManager.directorEmail
              : undefined,
          }
          : null,
      // @ts-ignore
      marketingManager:
        dealerManager.marketingManagerName ||
        dealerManager.marketingManagerLastName ||
        dealerManager.marketingManagerEmail
          ? {
            ...dealerManager.marketingManager,
            firstName: dealerManager.marketingManagerName
              ? dealerManager.marketingManagerName
              : "",
            lastName: dealerManager.marketingManagerLastName
              ? dealerManager.marketingManagerLastName
              : "",
            email: dealerManager.marketingManagerEmail
              ? dealerManager.marketingManagerEmail
              : undefined,
          }
          : null,
      // @ts-ignore
      salesManager:
        dealerManager.salesManagerName ||
        dealerManager.salesManagerLastName ||
        dealerManager.salesManagerEmail
          ? {
            ...dealerManager.salesManager,
            firstName: dealerManager.salesManagerName
              ? dealerManager.salesManagerName
              : "",
            lastName: dealerManager.salesManagerLastName
              ? dealerManager.salesManagerLastName
              : "",
            email: dealerManager.salesManagerEmail
              ? dealerManager.salesManagerEmail
              : undefined,
          }
          : null,
      // @ts-ignore
      cto:
        dealerManager.chiefTechnicalOfficeName ||
        dealerManager.chiefTechnicalOfficeLastName ||
        dealerManager.chiefTechnicalOfficeEmail
          ? {
            ...dealerManager.cto,
            firstName: dealerManager.chiefTechnicalOfficeName
              ? dealerManager.chiefTechnicalOfficeName
              : "",
            lastName: dealerManager.chiefTechnicalOfficeLastName
              ? dealerManager.chiefTechnicalOfficeLastName
              : "",
            email: dealerManager.chiefTechnicalOfficeEmail
              ? dealerManager.chiefTechnicalOfficeEmail
              : undefined,
          }
          : null,
      // @ts-ignore
      chiefAccountant:
        dealerManager.chiefAccountantName ||
        dealerManager.chiefAccountantLastName ||
        dealerManager.chiefAccountantEmail
          ? {
            ...dealerManager.chiefAccountant,
            firstName: dealerManager.chiefAccountantName
              ? dealerManager.chiefAccountantName
              : "",
            lastName: dealerManager.chiefAccountantLastName
              ? dealerManager.chiefAccountantLastName
              : "",
            email: dealerManager.chiefAccountantEmail
              ? dealerManager.chiefAccountantEmail
              : undefined,
          }
          : null,
    },
    dealerBuildInfrastructure,
    phones,
    companyStartDate: companyStartDate?.toISOString(),
    addressStartDate: addressStartDate?.toISOString(),
    dealerContractStart: dealerContractStart?.toISOString(),
    dealerContractEnd: dealerContractEnd?.toISOString(),
    population: Number(population),
    holding: holding ? holding : undefined,
    domains: domains.map((item) => item.domain),
    ...restFields,
  };

  if (holding) result.holding = holding;

  return result;
};
