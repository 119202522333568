import { Space } from "antd";
import { format } from "date-fns";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { NewsCard } from "@/components/News/NewsTab";
import { useGetNewsGroupsList, useGetNewsList } from "@/helpers/api/news/hooks";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { Tabs } from "@/packages/tabs/Tabs";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ReactComponent as UploadIcon } from "@/assets/icons/button-upload.svg";
import { useActiveTab } from "@/hooks/useActiveTab";
import { StringParam, useQueryParam } from "use-query-params";
import { StyledNewsContainer, StyledNewsTab } from "./index.styled";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { Search } from "@/packages/formElements/inputs/search/Search";

const _News: React.FC = () => {
  const [tabs, setTabs] = useState([]);

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 800);

  const { activeTab, setActiveTab } = useActiveTab(tabs);
  const [activeTabSlug, _] = useQueryParam("activeTab", StringParam);

  const [currentGroupId, setCurrentGroupId] = useState("");

  const { paginationParams } = usePagination();
  const {
    data: newsData,
    isLoading: isLoadingNews,
    isError: isNewsError,
    error: newsError,
    refetch: newsListRefetch,
  } = useGetNewsList({
    ...paginationParams,
    groupId: currentGroupId,
    search: debouncedSearchValue,
  });

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isError: isNewsGroupsError,
    error: groupsError,
  } = useGetNewsGroupsList({});

  const newsList = newsData?.data?.data;
  const groupsList = groupsData?.data?.data;

  useEffect(() => {
    if (groupsList?.length) {
      setTabs(
        groupsList.map((el: any, index: number) => {
          if (index === 0 && !activeTabSlug) {
            setCurrentGroupId(el.id);
            setActiveTab({ name: el.name, slug: el.id });
          }

          if (activeTabSlug === el.id) {
            setCurrentGroupId(el.id);
            setActiveTab({ name: el.name, slug: el.id });
          }

          return {
            name: el.name,
            slug: el.id,
            content: <></>,
          };
        })
      );

      setTimeout(() => {
        newsListRefetch();
      }, 100);
    }
  }, [groupsData]);

  const onTabChangeHandle = (activeKey: any) => {
    setCurrentGroupId(activeKey.slug);
    setActiveTab({
      name: activeKey.name,
      slug: activeKey.slug,
    });

    setTimeout(() => {
      newsListRefetch();
    }, 100);
  };

  const fallbackNews = () => {
    return (
      <>
        {isNewsGroupsError && (
          <div style={{ color: "red", margin: "1rem 0" }}>
            {t("news.newsErrorGroup") || ""}
          </div>
        )}
        {isNewsError && (
          <div style={{ color: "red", margin: "1rem 0" }}>
            {t("news.newsError") || ""}
          </div>
        )}
      </>
    );
  };

  return (
    <DashboardLayout
      title={t("news.title") || ""}
      headerLeft={
        <Space>
          <Search
            value={searchValue}
            onChange={(value) => setSearchValue(value || "")}
          />
        </Space>
      }
      headerRight={
        <Space>
          <PermissionsAdapter resourceName="news" resourceAction="create">
            <Link to="/news/create">
              <Button
                color="brand"
                theme="primary"
                size="middle"
                icon={<UploadIcon/>}
              >
                {t("news.addNew") || ""}
              </Button>
            </Link>
          </PermissionsAdapter>
        </Space>
      }
    >
      <>
        {isGroupsLoading ? (
          <div style={{ margin: "1rem 0" }}>Groups is loading...</div>
        ) : groupsData?.data?.data?.length ? (
          <>
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={onTabChangeHandle}
            />
            {isLoadingNews ? (
              <div style={{ margin: "1rem 0" }}>Loading...</div>
            ) : newsList?.length ? (
              <StyledNewsTab>
                <StyledNewsContainer>
                  {newsList.map((news) => (
                    <NewsCard
                      key={news.id}
                      id={news.id}
                      currentGroupId={currentGroupId}
                      imgUrl={news?.photo?.fileUrl}
                      author={`${news?.createdBy?.firstName} ${news?.createdBy?.lastName}`}
                      title={news.name}
                      description={news.description}
                      createdAt={format(
                        Date.parse(news.createdAt),
                        "dd.MM.yyyy"
                      )}
                      onEdit={() => {
                      }}
                    />
                  ))}
                </StyledNewsContainer>
              </StyledNewsTab>
            ) : (
              fallbackNews()
            )}
          </>
        ) : (
          <div style={{ margin: "1rem 0" }}>{t("news.notFound") || ""}</div>
        )}
      </>
    </DashboardLayout>
  );
};

export const News = memo(_News);
