import { Pagination } from "@/packages/pagination/Pagination";
import styled from "styled-components";

const StyledName = styled.div`
  white-space: nowrap;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { StyledName, StyledPagination };
