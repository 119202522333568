import { useQuery } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGetDocumentsRequest,
  TGetDocumentsResponse,
} from "@/helpers/api/documents/types";

export const useGetDocuments = (request: TGetDocumentsRequest) => {
  return useQuery(["document", request], () => {
    return api.get<TGetDocumentsResponse>(`/documents/${request}`, {
      params: { request },
    });
  });
};
