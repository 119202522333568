import React from "react";
import { Loader } from "@/packages/loader/Loader";
import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";

import { getDefaultAuditTotalHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { TAuditSectionWithStats } from "@/helpers/api/audit-total/types";

interface IAuditListTable extends Pick<ITableSimple, "onSort"> {
  data: TAuditSectionWithStats[] | [];
  isLoading?: boolean;
}
export const AuditTotalTable: React.FC<IAuditListTable> = (props) => {
  const { data, isLoading } = props;

  const headers = getDefaultAuditTotalHeaders({}, data);

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          <>
            <TableSimple
              alignHeader="center"
              alignCell="center"
              headers={headers || []}
              data={data || []}
              defaultColumn={{
                minWidth: 80,
                width: 165,
                maxWidth: 400,
              }}
            />
          </>
        ) : (
          <>
            <Loader size={48} />
          </>
        )}
      </StyledTableSimple>
    </>
  );
};
