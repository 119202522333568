import { Radio, RadioChangeEvent, RadioGroupProps } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const { Group } = Radio;

interface IRadioGroupProps {
  title?: string;
  defaultValue?: string;
  isColumn?: boolean;
  children: JSX.Element | JSX.Element[] | boolean;
  onChange: (value: string) => void;
}
interface IGroupStyled extends RadioGroupProps {
  isCloumn?: boolean;
}

const StyledTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 12px;
`;

const StyledGroup = styled(Group)<IGroupStyled>`
  display: ${({ isCloumn }) => (isCloumn ? "block" : "flex")};
  flex-wrap: wrap;
  > div {
    margin: ${({ isCloumn }) => (isCloumn ? "0 0 12px 0" : "0 20px 0 0")};
  }
`;

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const { title, isColumn, defaultValue, children, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  const handleChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div>
      {title ? <StyledTitle>{title}</StyledTitle> : <></>}
      <StyledGroup isCloumn={isColumn} onChange={handleChange} value={value}>
        {children}
      </StyledGroup>
    </div>
  );
};

export { RadioGroup };
