import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface ITemplateValues {
  sectionId: string;
  title: string;
  photo: boolean;
  code: string;
  templateViolationId: string | null;
  manual: boolean;
  weight: number;
  defaultValue: boolean;
  transcript: string;
}

export const DEFAULT_FORM_VALUES: ITemplateValues = {
  sectionId: "",
  title: "",
  photo: false,
  code: "",
  manual: false,
  weight: 0,
  defaultValue: false,
  transcript: "",
  templateViolationId: "",
};

const formSchema = () => {
  return yup.object({
    code: yup
      .string()
      .min(1, t("warrantyAudits.templates.validation.code_1") || "")
      .max(500, t("warrantyAudits.templates.validation.name_500") || "")
      .required(t("warrantyAudits.templates.validation.codeRequired") || ""),
    title: yup
      .string()
      .min(5, t("warrantyAudits.templates.validation.checkPoint_5") || "")
      .max(500, t("warrantyAudits.templates.validation.checkPoint_500") || "")
      .required(
        t("warrantyAudits.templates.validation.checkPointRequired") || ""
      ),
    deacceptType: yup
      .string()
      .required(t("warrantyAudits.templates.validation.deacentRequired") || ""),
    templateViolationId: yup
      .string()
      .required(t("warrantyAudits.templates.validation.violation") || "").nullable(),
    weight: yup
      .number()
      .typeError(t("audits.templates.validation.weightRequired") || "")
      .min(0, t("warrantyAudits.templates.validation.weight_0") || "")
      .max(100, t("warrantyAudits.templates.validation.weight_100") || "")
      .required(t("warrantyAudits.templates.validation.weightRequired") || ""),
  });
};

const formSchemaSimple = () => {
  return yup.object({
    code: yup
      .string()
      .min(1, t("warrantyAudits.templates.validation.code_1") || "")
      .max(500, t("warrantyAudits.templates.validation.name_500") || "")
      .required(t("warrantyAudits.templates.validation.codeRequired") || ""),
    templateViolationId: yup
      .string()
      .required(t("warrantyAudits.templates.validation.violation") || "").nullable(),
    title: yup
      .string()
      .min(5, t("warrantyAudits.templates.validation.checkPoint_5") || "")
      .max(500, t("warrantyAudits.templates.validation.checkPoint_500") || "")
      .required(
        t("warrantyAudits.templates.validation.checkPointRequired") || ""
      ),
  });
};

const formSchemaManual = () => {
  return yup.object({
    code: yup
      .string()
      .min(1, t("warrantyAudits.templates.validation.code_1") || "")
      .max(500, t("warrantyAudits.templates.validation.name_500") || "")
      .required(t("warrantyAudits.templates.validation.codeRequired") || ""),
    deacceptType: yup
      .string()
      .required(t("warrantyAudits.templates.validation.deacentRequired") || ""),
    templateViolationId: yup
      .string()
      .required(t("warrantyAudits.templates.validation.violation") || "").nullable(),
    title: yup
      .string()
      .min(5, t("warrantyAudits.templates.validation.checkPoint_5") || "")
      .max(500, t("warrantyAudits.templates.validation.name_500") || "")
      .required(t("warrantyAudits.templates.validation.nameRequired") || ""),
  });
};

export type TOnSubmitCalendarFilterFormCb = (values: ITemplateValues) => void;

export const useTemplateForm = (
  onSubmit: TOnSubmitCalendarFilterFormCb,
  isDppo: boolean,
  isManual: boolean
) => {
  const form = useForm<ITemplateValues>({
    resolver: yupResolver(
      isDppo ? formSchemaSimple() : isManual ? formSchemaManual() : formSchema()
    ),
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
  };
};
