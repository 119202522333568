import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TGetSettingsValueRequest,
  TGetSettingsValueResponse,
  TSettingsValueCreateRequest,
  TSettingsValueCreateResponse,
  TSettingsValueUpdateRequest,
  TSettingsValueUpdateResponse,
  TMaintenanceGridSettingsValueUpdateRequest,
  TSettingsMaintenanceGridValueUpdateResponse,
  TGetMaintenanceGridSettingsValueRequest,
  TMaintenanceGridSettingsValueRecord,
} from "./types";

export const useGetSettingsValue = (
  data: TGetSettingsValueRequest,
  isEnabled = true
) => {
  return useQuery(
    ["settingsValue", data],
    () => api.get<TGetSettingsValueResponse>(`/settings/${data.key}`),
    { enabled: isEnabled }
  );
};

export const useCreateSettingsValue = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TSettingsValueCreateRequest) =>
      api.post<TSettingsValueCreateResponse>("/settings", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["works"]);
        queryClient.invalidateQueries(["settingsValue"]);
      },
    }
  );
};

export const useUpdateSettingsValue = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TSettingsValueUpdateRequest) =>
      api.patch<TSettingsValueUpdateResponse>(`/settings/${data.key}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["works"]);
        queryClient.invalidateQueries(["settingsValue"]);
      },
    }
  );
};

export const useGetMaintenanceGridSettingsValue = (
  data: TGetMaintenanceGridSettingsValueRequest,
  isEnabled = true
) => {
  return useQuery(
    ["settingsValue", data],
    () =>
      api.get<TMaintenanceGridSettingsValueRecord>(
        `/maintenance-grids/settings/${data.key}`
      ),
    { enabled: isEnabled }
  );
};

export const useCreateMaintenanceGridSettingsValue = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TMaintenanceGridSettingsValueUpdateRequest) =>
      api.post<TSettingsMaintenanceGridValueUpdateResponse>(
        "/maintenance-grids/settings",
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["works"]);
        queryClient.invalidateQueries(["settingsValue"]);
      },
    }
  );
};

export const useUpdateMaintenanceGridSettingsValue = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TMaintenanceGridSettingsValueUpdateRequest) =>
      api.patch<TSettingsMaintenanceGridValueUpdateResponse>(
        `maintenance-grids/settings/${data.key}`,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["works"]);
        queryClient.invalidateQueries(["settingsValue"]);
      },
    }
  );
};
