import { FC, useEffect } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { DetailRegistryEmployeesTab } from "@/components/registryDetail/DetailRegistryEmployeesTab";
import { useGetDealerById } from "@/helpers/api/dc/dealers/hooks";
import { useParams } from "react-router-dom";
import { useNotification } from "@/hooks/useNotification";
import { useDeleteUser } from "@/helpers/api/users/hooks";

const EmployeeManagement: FC = (): JSX.Element => {
  const params = useParams();

  const dealerId = params.dealerId as string;

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { data: dealerResponse, refetch: refetchDealer } = useGetDealerById({
    id: dealerId,
  });
  const { mutate: deleteUser } = useDeleteUser({
    onSuccess: () => createNotificationSuccess("Пользователь успешно удален"),
    onError: () =>
      createNotificationError("Произошла ошибка при удалении пользователя"),
  });

  const dealer = dealerResponse?.data;

  const onUserDeleteButtonClick = (userId: string) => {
    deleteUser({
      id: userId,
    });
  };

  useEffect(() => {
    if (!dealer) {
      refetchDealer();
    }
  }, []);

  return (
    <DashboardLayout toBack="/profile" title={"Управление сотрудниками"}>
      {dealer ? (
        <DetailRegistryEmployeesTab
          dealer={dealer}
          onDeleteClick={onUserDeleteButtonClick}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};

export default EmployeeManagement;
