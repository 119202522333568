import UsersFilter from "@/pages/Admin/UsersFilters";
import { StyledUserTableWrapper } from "@/pages/Admin/UserTable/index.styles";
import { StyledPagination } from "@/components/Models/ModelsListTab/index.styles";
import React, { useCallback } from "react";
import { usePagination } from "@/hooks/usePagination";
import { IHeaderItem, TableSimple } from "@/packages/tableSimple/TableSimple";
import { Popconfirm } from "antd";
import { useGetUserList } from "@/helpers/api/users/hooks";
import { formatDate } from "@/helpers/formatDate";
import { COUNTRY_SELECT_OPTIONS } from "@/packages/formElements/fields/CountrySelect";
import { t } from "i18next";
import { StyledButtons } from "@/components/AuditList/AuditListTable/index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useNavigate } from "react-router-dom";
import { useResetPassword } from "@/helpers/api/admin-pannel/hooks";
import { useNotification } from "@/hooks/useNotification";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { UserFilter } from "@/pages/Admin/UsersFilters/hooks";
import { TGetUserListRequest } from "@/helpers/api/users/types";

export type UserType = {
  id: string,
  role?: string,
  category?: string,
  dc?: string,
  country?: string | null,
  fullName: string,
  email?: string,
  status?: string,
  creationDate?: string,
}


const PROFILE_TYPE = {
  dealer: 'Дилер',
  kia: 'Киа',
}

export const UserTable = () => {

  const { setPage, paginationParams } = usePagination();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    searchById: StringParam,
    searchByName: StringParam,
    type: StringParam,
    roleId: StringParam,
    searchByEmail: StringParam,
    status: BooleanParam,
    country: StringParam,
    holdingId: StringParam,
    dealerId: StringParam,
  });
  const { data: users } = useGetUserList({
    ...paginationParams,
    ...(filterQueryParams as TGetUserListRequest),
  });
  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const navigate = useNavigate();

  const { mutate: resetPassword } = useResetPassword(
    {
      onSuccess: () => {
        createNotificationSuccess('Пароль сброшен');
      },
      onError: () => {
        createNotificationError('Произошла ошибка!')
      }
    }
  );

  const filterParams: UserFilter = {
    searchById: filterQueryParams.searchById as string,
    searchByName: filterQueryParams.searchByName as string,
    type: filterQueryParams.type as string,
    roleId: filterQueryParams.roleId as string,
    searchByEmail: filterQueryParams.searchByEmail as string,
    status: filterQueryParams.status as boolean,
    country: filterQueryParams.country as string,
    holdingId: filterQueryParams.holdingId as string,
    dealerId: filterQueryParams.dealerId as string,
  };

  const headers: IHeaderItem<UserType>[] = [
    {
      Header: "Id",
      accessor: "id",
      maxWidth: 30,
    },
    {
      Header: "Роль",
      accessor: "role",
      maxWidth: 60,
    },
    {
      Header: "Категория пользователя",
      accessor: "category",
      maxWidth: 60,
    },
    {
      Header: "ДЦ",
      accessor: "dc",
      maxWidth: 60,
    },
    {
      Header: "ФИО",
      accessor: "fullName",
      maxWidth: 150,
    },
    {
      Header: "Электронная почта",
      accessor: "email",
      maxWidth: 100,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 50,
    },
    {
      Header: "Страна",
      accessor: "country",
      maxWidth: 100,
    },
    {
      Header: "Дата создания",
      accessor: "creationDate",
      maxWidth: 100,
    },
    {
      Header: "",
      id: "actions",
      width: "118px",
      minWidth: 118,
      maxWidth: 118,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        return (
          <StyledButtons>
            <Tooltip content={"Просмотр"} placement="bottom">
              <IconButton
                icon="eye-open"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => navigate(`/admin/user/${original.id}`)}
              />
            </Tooltip>
            <Tooltip
              content={"Сбросить пароль"}
              placement="bottom"
            >
              <Popconfirm
                title={"Действительно хотите сбросить пароль?"}
                onConfirm={() => {
                  resetPassword(original.id);
                }}
                okText={t("common.yes") || ""}
                cancelText={t("common.no") || ""}
              >
                <IconButton
                  icon="lock"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Popconfirm>
            </Tooltip>
          </StyledButtons>
        );
      },
    },
  ];

  const getMappedData = useCallback((): UserType[] | undefined => {
    return users?.data.data?.map((it) =>
      ({
        id: it.id,
        role: it.role.name,
        category: PROFILE_TYPE[it.type],
        dc: it?.dealer?.merchantName,
        country: t(COUNTRY_SELECT_OPTIONS.find(countryObject => countryObject.value == it.country)?.label ?? ''),
        fullName: `${it.firstName} ${it.lastName}`,
        email: typeof it.email._address === 'string' ? it.email._address : undefined,
        status: it.isActive ? 'Активный' : 'Неактивный',
        creationDate: formatDate(it.createdAt, "dateOnly"),
      })
    )
  }, [users])

  return (
    <StyledUserTableWrapper>
      <UsersFilter
        onFilterChange={setFilterQueryParams}
        initValues={filterParams}
        resetPage={() => setPage(1)}
      />
      <TableSimple
        withOverflow
        headers={headers}
        data={getMappedData() ?? []}
        autoOverflow
        alignCell="start"
        alignHeader="start"
      />
      <StyledPagination
        onChange={setPage}
        current={paginationParams.page}
        pageSize={10}
        total={users?.data.meta?.itemCount}
      />
    </StyledUserTableWrapper>
  )
}