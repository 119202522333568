import React, { useMemo, useState } from "react";
import {
  useGetCityFilters,
  useGetStateFilters,
} from "@/helpers/api/reports/hooks";
import { Button } from "@/packages/button/Button";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { MOCK_QUARTER_LIST } from "@/pages/BonusesReportsImport";
import { StringParam, useQueryParams } from "use-query-params";

import { FormProvider } from "react-hook-form";
import {
  // ISellsFilterFormValues,
  SellsFilterFormFieldsNameEnum,
  TOnSubmitSellsFilterFormCb,
  useSellsFilterForm,
} from "./hooks";
import {
  StyledButtonsContainer,
  StyledFormLayout,
  StyledSellsFilterWrapper,
} from "./index.styles";
import {
  preparedDealerList,
  useGetKiaGroupDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { getYearsList } from "@/helpers/date";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface ICalculationsFilter {
  quarter: string;
  year: string;
  onFiltersApply: TOnSubmitSellsFilterFormCb;
  isProfileKia: boolean;
}

export const CalculationsFilter: React.FC<ICalculationsFilter> = (props) => {
  const { quarter, year, onFiltersApply, isProfileKia } = props;

  const { t } = useTranslation();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    county: StringParam,
    manager: StringParam,
    state: StringParam,
    city: StringParam,
    dealer: StringParam,
  });

  const { form, submit, reset } = useSellsFilterForm({
    onSubmit: onFiltersApply,
    defaultValues: {
      year,
      quarter,
    },
  });

  const [searchValue, setSearchValue] = useState("");
  const preparedYearsList = useMemo(() => getYearsList({ yearsCount: 20 }), []);

  const { data: stateFilterOptionsResponse } = useGetStateFilters({
    ...filterQueryParams,
  });

  const { data: managerFilterOptionsResponse } = useGetKiaGroupDealerList({
    group: "zoneServiceManager",
  });

  const { data: cityFilterOptionsResponse } = useGetCityFilters({
    ...filterQueryParams,
  });
  const { data: dealerFilterOptionsResponse} =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const managerFilterOptions = managerFilterOptionsResponse?.data?.data || [];
  const cityFilterOptions = cityFilterOptionsResponse?.data?.data || [];
  const dealerFilterOptions = dealerFilterOptionsResponse?.data?.data || [];
  const stateFilterOptions = stateFilterOptionsResponse?.data?.data || [];

  return (
    <StyledSellsFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          {isProfileKia ? (
            <>
              <FormSelect
                name={SellsFilterFormFieldsNameEnum.Manager}
                label={t("bonuses.reportsView.manager") || ""}
                placeholder={t("bonuses.reportsView.managerChoose") || ""}
                options={
                  managerFilterOptions
                    ? managerFilterOptions?.map((option) => ({
                        label: `${option?.firstName || ""} ${
                          option?.lastName || ""
                        }`,
                        value: option.id,
                      }))
                    : []
                }
                allowClear
              />
              <FormSelect
                name={SellsFilterFormFieldsNameEnum.State}
                options={
                  stateFilterOptions
                    ? stateFilterOptions?.map((option) => ({
                        label: option,
                        value: option,
                      }))
                    : []
                }
                label={t("bonuses.reportsView.region") || ""}
                placeholder={t("bonuses.reportsView.regionChoose") || ""}
                allowClear
              />
              <FormSelect
                name={SellsFilterFormFieldsNameEnum.City}
                options={
                  cityFilterOptions
                    ? cityFilterOptions?.map((option) => ({
                        label: option,
                        value: option,
                      }))
                    : []
                }
                label={t("bonuses.reportsView.city") || ""}
                placeholder={t("bonuses.reportsView.cityChoose") || ""}
                allowClear
              />
              <FormCheckbox
                name={SellsFilterFormFieldsNameEnum.CoefficientC}
                label={t("bonuses.reportsView.CoefficientC") || ""}
                description={t("bonuses.reportsView.CoefficientCDescr") || ""}
              />
              <FormSelect
                name={SellsFilterFormFieldsNameEnum.Dealer}
                options={preparedDealerList(dealerFilterOptions || [])}
                label={t("bonuses.reportsView.dealer") || ""}
                placeholder={t("bonuses.reportsView.dealerChoose") || ""}
                allowClear
                showSearch
                onSearch={setSearchValue}
              />
            </>
          ) : null}
          <FormSelect
            name={SellsFilterFormFieldsNameEnum.Year}
            options={preparedYearsList || []}
            label={t("bonuses.reportsView.year") || ""}
            placeholder={t("bonuses.reportsView.yearChoose") || ""}
            allowClear
          />
          <FormSelect
            name={SellsFilterFormFieldsNameEnum.Quarter}
            options={MOCK_QUARTER_LIST || []}
            label={t("bonuses.reportsView.quarter_L") || ""}
            placeholder={t("bonuses.reportsView.quarterChoose") || ""}
            allowClear
          />
          {!isProfileKia ? (
            <FormCheckbox
              name={SellsFilterFormFieldsNameEnum.CoefficientC}
              label={t("bonuses.reportsView.CoefficientC") || ""}
              description={t("bonuses.reportsView.CoefficientCDescr") || ""}
            />
          ) : null}
          <FormCheckbox
            name={SellsFilterFormFieldsNameEnum.CoefficientA}
            label={t("bonuses.reportsView.CoefficientA") || ""}
            description={t("bonuses.reportsView.CoefficientADescr") || ""}
          />
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button color="brand" theme="secondary" size="middle" onClick={reset}>
          {t("bonuses.reportsView.cancel") || ""}
        </Button>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("bonuses.reportsView.save") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledSellsFilterWrapper>
  );
};
