import { TOnSubmitFormCb, useForm } from "@/hooks/useForm";
import { Dealer, TTransportRecordRequest } from "../types";
import { TNullablePartial } from "../utils";
import { transformCreateFields } from "./utils";
import * as yup from "yup";
import { t } from "i18next";
import { ITransportComDetailFormValues } from "@/components/TransportComplaints/TransportComplaintsDetail/hooks";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

export interface ITransportComFormCreateValues
  extends TNullablePartial<TTransportRecordRequest> {
}

export const getDefaultValues = (prefillQueryParams, dealer: Dealer | undefined): ITransportComDetailFormValues => {
  return {
    vin: prefillQueryParams.v,
    codeCase: prefillQueryParams.c,
    dateAcceptence: prefillQueryParams.d ? new Date(prefillQueryParams.d) : undefined,
    placeAcceptence: "",
    dealerId: dealer?.id,
    oupts: undefined,
    tthCmr: undefined,
    viewDetail: undefined,
    vinPlate: undefined,
    generalView: undefined,
    photoDefect: undefined,
    other: undefined,
    kiaSng: undefined,
    openForViewing: undefined,
    isEllada: false,
  }
};

export const formComplaintSchema = () => {
  return yup
    .object({
      vin: yup
        .string()
        .required(t("park.validation.vinRequired") || "")
        .min(17, t("kiaFlex.vin_17") || "")
        .max(17, t("kiaFlex.vin_17") || "")
        .matches(/^[A-Za-z0-9]*$/, t("kiaFlex.vin_chars") || "")
        .uppercase()
        .nullable(),
      codeCase: yup
        .string()
        .required(t("complaints.codeCaseRequired") || "")
        .nullable()
        .trim(),
      dateAcceptence: yup
        .date()
        .required(t("complaints.dateAcceptenceRequired") || "")
        .nullable(),
      oupts: yup.object({
        files: yup.array().of(yup.object()).min(1, t("complaints.filesRequired") || "")
          .required(t("complaints.filesRequired") || "")
      }),
      tthCmr: yup.object({
        files: yup.array().of(yup.object()).min(1, t("complaints.filesRequired") || "")
          .required(t("complaints.filesRequired") || "")
      }),
    })
    .required();
};

export type TOnSubmitTransportFormCreate = (
  values: ITransportComFormCreateValues
) => void;

export const useTransportComFormCreate = (
  onSubmit: TOnSubmitTransportFormCreate,
  dealer: Dealer | undefined,
  prefillQueryParams,
) => {

  const onSubmitHandler: TOnSubmitFormCb<any> = async (successValues) => {
    const createRecord = transformCreateFields(successValues);
    onSubmit(createRecord);
  };

  return useForm<any>({
    validateFormSchema: formComplaintSchema(),
    defaultValues: getDefaultValues(prefillQueryParams, dealer),
    onSubmit: onSubmitHandler,
  });
};

export const useScrollToTcTop = (form: UseFormReturn<any>) => {
  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [form.formState.errors]);
}
