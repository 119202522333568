import { useGetPublicRoleListWithAuth } from "@/helpers/api/roles/hooks";
import { FormFileDropzoneField } from "@/packages/formElements/formFields/FormFileDropzoneField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { Col, Row } from "antd";
import React, { useMemo } from "react";
import { StyledRegistryDataFields, StyledSection } from "./index.styles";
import { InformationLettersDataFormFieldsEnum } from "./types";
import { useTranslation } from "react-i18next";

interface IRegistryDataFields {
  isDisabled?: boolean;
  record?: any;
  mode?: "other" | "create" | "edit";
  handleRemove?: (id?: string) => void;
}

export const InformationalLettersDataFields: React.FC<IRegistryDataFields> = (
  props
) => {
  const { isDisabled = false, record, mode = "other", handleRemove } = props;

  const { data: rolesData } = useGetPublicRoleListWithAuth();
  const { t } = useTranslation();
  const rolesOptions = useMemo(() => {
    if (rolesData?.data.data) {
      return rolesData?.data.data.map((item) => ({
        value: item.id,
        //@ts-ignore
        label: `${item.name} (${item.group.name})`,
      }));
    }
  }, [rolesData]);

  return (
    <StyledRegistryDataFields>
      <StyledSection>
        <Row gutter={32}>
          <Col span={12}>
            <FormInputField
              name={InformationLettersDataFormFieldsEnum.Name}
              label={t("dealerMaterials.letters.IPName") || ""}
              isDisabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <FormInputField
              name={InformationLettersDataFormFieldsEnum.Number}
              label={t("dealerMaterials.letters.newIP") || ""}
              isDisabled={isDisabled}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <FormSelect
              label={t("dealerMaterials.letters.RoleIds") || ""}
              name={InformationLettersDataFormFieldsEnum.RoleIds}
              mode="multiple"
              options={rolesOptions}
              isDisabled={isDisabled}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <FormTextAreaField
              label={t("dealerMaterials.letters.Description") || ""}
              name={InformationLettersDataFormFieldsEnum.Description}
              disabled={isDisabled}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <FormFileDropzoneField
              name={InformationLettersDataFormFieldsEnum.FileIds}
              label={t("dealerMaterials.letters.FileIds") || ""}
              path="ip/files"
              isAllowFilesUpload
              maxCount={10}
              isDisabled={isDisabled}
              onRemove={handleRemove}
              iconDelete={!(mode === "other")}
              fileIds={mode !== "create" && record?.fileIds}
              isNotTransportClaims
            />
          </Col>
        </Row>
      </StyledSection>
    </StyledRegistryDataFields>
  );
};
