import { useForm } from "@/hooks/useForm";
import * as yup from "yup";
import { t } from "i18next";

export type ResetPasswordFormValues = {
  password: string;
  repeatPassword: string;
};

const DEFAULT_PASSWORD_FORGOT_FORM_VALUES: ResetPasswordFormValues = {
  password: "",
  repeatPassword: "",
};

export const formSchema = () => {
  return yup
    .object({
      password: yup
        .string()
        .required(t("Введите пароль") || "")
        .matches(
          /^[A-Za-z0-9 ,@$!%*?&«»";:'`.—_-|<>^()-]+$/,
          "Пароль должен содержать буквы латинского алфавита, цифры и спец символы"
        )
        .min(6, t("Длина пароля должна составлять от 6 символов") || "")
        .max(12, t("Длина пароля должна составлять до 12 символов") || ""),

      repeatPassword: yup
        .string()
        .oneOf([yup.ref("password")], t("Пароли должны совпадать") || "")
        .required(t("Введите свой пароль еще раз") || ""),
    })
    .required();
};

export type TOnSubmitResetPasswordFormCb = (
  data: ResetPasswordFormValues
) => Promise<void>;

export const useResetPasswordForm = (
  onSubmit: TOnSubmitResetPasswordFormCb
) => {
  return useForm<ResetPasswordFormValues>({
    validateFormSchema: formSchema(),
    defaultValues: DEFAULT_PASSWORD_FORGOT_FORM_VALUES,
    onSubmit,
  });
};
