import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGetNotificationByIdRequest,
  TGetNotificationByIdResponse,
  TGetNotificationsRequest,
  TGetNotificationsResponse,
  TUpdateNotificationsRequest,
  TUpdateNotificationsResponse,
} from "./types";

export const useGetNotifications = (data?: TGetNotificationsRequest) => {
  return useQuery(["notifications", data], () => {
    return api.get<TGetNotificationsResponse>("/notifications", {
      params: data || {},
    });
  });
};

export const useGetNotificationById = (data: TGetNotificationByIdRequest) => {
  return useQuery(["notificationById", data.id], () => {
    return api.get<TGetNotificationByIdResponse>(`/notifications/${data.id}`);
  });
};

export const useAddNotificationsToFolder = ({
  onSuccess,
}: { onSuccess?: (response: any) => void } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TUpdateNotificationsRequest & { path: string }) => {
      return api.patch<TUpdateNotificationsResponse>(
        `/notifications/bulk-${data.path}`,
        data
      );
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["notifications"]);
        onSuccess && onSuccess(response);
      },
    }
  );
};
