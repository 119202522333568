import { useGetCities } from "@/helpers/api/city/hooks";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { useGetGenerationListWithoutModelId } from "@/helpers/api/generations/hooks";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";

import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { Loader } from "@/packages/loader/Loader";
import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import {
  StyledColDate,
  StyledDash,
  StyledDateContainer,
  StyledIconButton,
  StyledMonitoringDataFields,
  StyledRow,
} from "./index.styles";
import { ALL_SELECTED, MonitoringDataFormFieldsEnum, MOSCOW } from "./types";
import { allowedTypesFiles, allowedTypesImages, DEFAULT_PARTS_VALUE, MAX_COUNT_PARTS, } from "./utils";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { UploadType } from "@/packages/UploadImageAndFileDropzone";
import { FormFileDropzoneField } from "@/packages/formElements/formFields/FormFileDropzoneField";

export interface IMonitoringDataFieldsProps {
  form: any;
  isNumberLoading?: boolean;
  readOnly?: boolean;
  isEditable?: boolean;
}

export const MonitoringDataFields: React.FC<IMonitoringDataFieldsProps> = (
  props
) => {
  const { form, readOnly = false, isEditable = true, isNumberLoading } = props;
  const { t } = useTranslation();
  const [
    cityIds,
    dealerIds,
    carProductionDateFrom,
    carProductionDateTo,
    carSearchPeriodFrom,
    carSearchPeriodTo,
    dateOfTheStudyFrom,
    dateOfTheStudyTo,
    photoIds,
    fileIds,
  ] = form.watch([
    "cityIds",
    "dealerIds",
    "carProductionDateFrom",
    "carProductionDateTo",
    "carSearchPeriodFrom",
    "carSearchPeriodTo",
    "dateOfTheStudyFrom",
    "dateOfTheStudyTo",
    "photoIds",
    "fileIds",
  ]);

  const [searchCity, setSearchCity] = useState("");
  const [debouncedSearchCity] = useDebounce(searchCity, 400);

  const { data: citiesData } = useGetCities({
    limit: 0,
    search: debouncedSearchCity,
  });


  const [searchValue, setSearchValue] = useState("");

  const { data: dealers } = useGetKiaDealerList({
    limit: 0,
    search: searchValue,
    cityIds: cityIds.includes(ALL_SELECTED)
      ? null
      : cityIds.filter(it => it !== MOSCOW)
        .map((it) => (it.value ? it.value : it)),
  });

  const {
    data: generations,
  } = useGetGenerationListWithoutModelId({ limit: 0 });


  const generationOptions = useMemo(
    () =>
      generations?.data.data
        .map((item) => ({
          label: `${item?.model?.name} ${item?.name} ${
            item?.restylingName || ""
          }`,
          value: item.id,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)) || [],
    [generations?.data.data]
  );


  const citiesOptions = useMemo(() => {
    if (citiesData?.data.data) {
      if (cityIds.includes(ALL_SELECTED)) {
        return [
          {
            value: ALL_SELECTED,
            label: t("monitoring.all") || "",
          },
        ];
      }

      const cities = [
        {
          value: MOSCOW,
          label: 'Московская область',
        },
        ...citiesData?.data.data
          .map((item) => ({
            value: item.id,
            label: item?.name || "-",
          }))
      ]

      return [
        {
          value: ALL_SELECTED,
          label: t("monitoring.all") || "",
        },
        ...cities
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
      ];
    }
  }, [citiesData, cityIds]);

  useEffect(() => {
    if (cityIds.includes(MOSCOW) && citiesOptions) {
      const moscowIds = citiesOptions.filter(it => /Московская обл/gm.test(it.label) && it.value !== MOSCOW)
        .map(it => it.value)

      if (!cityIds.some(it => moscowIds.includes(it))) {
        form.setValue('cityIds', [...cityIds, ...moscowIds])
      }
    }
  }, [cityIds]);

  const dealerOptions = useMemo(() => {
    if (dealers?.data) {
      if (dealerIds.includes(ALL_SELECTED)) {
        return [
          {
            value: ALL_SELECTED,
            label: t("monitoring.all") || "",
          },
        ];
      }

      return [
        {
          value: ALL_SELECTED,
          label: t("monitoring.all") || "",
        },
        //@ts-ignore
        ...dealers?.data.data
          .map((dealer) => ({
            value: dealer.id,
            label: `${dealer.mobisCode} / ${dealer.warrantyCode} / ${dealer.lawName} / ${dealer.merchantName}`,
          }))
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
      ];
    }
  }, [dealers, dealerIds]);

  const { fields, append } = useFieldArray({
    control: form.control,
    name: "parts",
  });

  const onRemoveFunc = (field: any, id: any) => {
    if (!readOnly) {
      form.setValue(
        `${field}`,
        form.getValues(field)?.filter((el) => el.id !== id),
      );
    }
  };

  return (
    <>
      {isNumberLoading ? (
        <Loader size={48}/>
      ) : (
        <StyledMonitoringDataFields>
          <Row gutter={32}>
            <Col span={8}>
              <FormTextAreaField
                name={MonitoringDataFormFieldsEnum.Title}
                label={t("monitoring.addNewProgramForm.Title") || ""}
                readOnly={readOnly}
              />
            </Col>
            <Col span={8}>
              <FormInputField
                name={MonitoringDataFormFieldsEnum.Number}
                label={t("monitoring.addNewProgramForm.Number") || ""}
                readOnly={readOnly}
                isEditable={isEditable}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={8}>
              <FormSelect
                name={MonitoringDataFormFieldsEnum.CityIds}
                options={citiesOptions}
                placeholder={t("monitoring.addNewProgramForm.choose") || ""}
                mode="multiple"
                label={t("monitoring.addNewProgramForm.CityIds") || ""}
                readOnly={readOnly}
                onSearch={setSearchCity}
                isSearchShow
                allowClear
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={MonitoringDataFormFieldsEnum.DealerIds}
                options={dealerOptions}
                placeholder={t("monitoring.addNewProgramForm.choose") || ""}
                mode="multiple"
                label={t("monitoring.addNewProgramForm.DealerIds") || ""}
                readOnly={readOnly}
                onSearch={setSearchValue}
                isSearchShow
                allowClear
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={MonitoringDataFormFieldsEnum.GenerationsIds}
                options={generationOptions}
                placeholder={t("monitoring.addNewProgramForm.choose") || ""}
                mode="multiple"
                label={t("monitoring.addNewProgramForm.GenerationsIds") || ""}
                readOnly={readOnly}
                isEditable={isEditable}
              />
            </Col>
          </Row>

          {fields?.map((field, index) => {
            return (
              <Row gutter={32} align="middle" key={field.id}>
                <Col span={8}>
                  <FormInputField
                    label={t("monitoring.addNewProgramForm.parts") || ""}
                    name={`parts.${index}.partName`}
                    readOnly={readOnly}
                  />
                </Col>
                <Col span={8}>
                  <FormInputField
                    label={t("monitoring.addNewProgramForm.catalog") || ""}
                    name={`parts.${index}.catalogueNumber`}
                    readOnly={readOnly}
                  />
                </Col>
                <Col span={8}>
                  {index === fields.length - 1 &&
                    !readOnly &&
                    fields.length !== MAX_COUNT_PARTS && (
                      <StyledIconButton
                        size="m"
                        icon="plus"
                        color="gray"
                        variant="tertiary"
                        onClick={() => append(DEFAULT_PARTS_VALUE)}
                      />
                    )}
                </Col>
              </Row>
            );
          })}
          <Row gutter={32}>
            <Col span={24}>
              <FormTextAreaField
                name={MonitoringDataFormFieldsEnum.PrimaryRequirements}
                label={
                  t("monitoring.addNewProgramForm.PrimaryRequirements") || ""
                }
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24}>
              <FormTextAreaField
                name={MonitoringDataFormFieldsEnum.AdditionalRequirements}
                label={
                  t("monitoring.addNewProgramForm.AdditionalRequirements") || ""
                }
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <StyledDateContainer>
            <Row gutter={16}>
              <StyledColDate>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.CarProductionDateFrom}
                  label={
                    t("monitoring.addNewProgramForm.CarProductionDateFrom") ||
                    ""
                  }
                  readOnly={readOnly}
                  maxDate={carProductionDateTo || undefined}
                  isErrorAbsolute={true}
                />
                <StyledDash/>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.CarProductionDateTo}
                  readOnly={readOnly}
                  minDate={carProductionDateFrom || undefined}
                  isErrorAbsolute={true}
                />
              </StyledColDate>

              <StyledColDate>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.CarSearchPeriodFrom}
                  label={
                    t("monitoring.addNewProgramForm.CarSearchPeriodFrom") || ""
                  }
                  readOnly={readOnly}
                  maxDate={carSearchPeriodTo || undefined}
                  isErrorAbsolute={true}
                />
                <StyledDash/>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.CarSearchPeriodTo}
                  readOnly={readOnly}
                  minDate={carSearchPeriodFrom || undefined}
                  isErrorAbsolute={true}
                />
              </StyledColDate>
              <StyledColDate>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.DateOfTheStudyFrom}
                  label={
                    t("monitoring.addNewProgramForm.DateOfTheStudyFrom") || ""
                  }
                  readOnly={readOnly}
                  maxDate={dateOfTheStudyTo || undefined}
                  isErrorAbsolute={true}
                />
                <StyledDash/>
                <FormDatepickerField
                  name={MonitoringDataFormFieldsEnum.DateOfTheStudyTo}
                  readOnly={readOnly}
                  minDate={dateOfTheStudyFrom || undefined}
                  isErrorAbsolute={true}
                />
              </StyledColDate>
            </Row>
          </StyledDateContainer>
          <StyledRow gutter={32}>
            <Col span={20}>
              <FormFileDropzoneField
                name={MonitoringDataFormFieldsEnum.PhotoIds}
                path={'quality-monitoring/photos'}
                isDisabled={readOnly}
                iconDelete={!readOnly}
                fileIds={photoIds}
                variantBtn="contained"
                typeInfo={UploadType.IMAGE}
                allowedTypesProps={allowedTypesImages}
                withCommentField={false}
                isNotTransportClaims
                maxCount={20}
                onRemove={(id) => {
                  onRemoveFunc("photoIds", id);
                }}
                label={t("monitoring.addNewProgramForm.photo") || ""}
                description={t("monitoring.addNewProgramForm.photoDescr") || ""}
                isMultiple
                placeholder={'Перетащите или выберите фото'}
              />
            </Col>
          </StyledRow>
          <StyledRow gutter={32}>
            <Col span={20}>
              <FormFileDropzoneField
                label={t("monitoring.addNewProgramForm.file") || ""}
                description={t("monitoring.addNewProgramForm.fileDescr") || ""}
                name={MonitoringDataFormFieldsEnum.FileIds}
                path={'quality-monitoring/files'}
                isDisabled={readOnly}
                iconDelete={!readOnly}
                fileIds={fileIds}
                variantBtn="contained"
                typeInfo={UploadType.FILE}
                onRemove={(id) => {
                  onRemoveFunc("fileIds", id);
                }}
                allowedTypesProps={allowedTypesFiles}
                withCommentField={false}
                isNotTransportClaims
                maxCount={20}
                isMultiple
                placeholder={'Перетащите или выберите файл'}
              />
            </Col>
          </StyledRow>
        </StyledMonitoringDataFields>
      )}
    </>
  );
};
