import { IModal, Modal } from "@/packages/modal/Modal";
import { Space } from "antd";
import { v4 as uuidv4 } from "uuid";

import { FC, useCallback, useEffect, useState } from "react";

import { IconButton } from "@/packages/icon-button/IconButton";
import { StyledFormEditBatchModal, StyledModalFields } from "./index.styles";
import Tree from "./Tree";
import { useTranslation } from "react-i18next";

export interface IMaterialsEditBatchModalEditBatchModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: any;
  batchSections: any[];
}

const MaterialsEditBatchModal: FC<IMaterialsEditBatchModalEditBatchModal> = (
  props
) => {
  const { onCancel, isOpen, onSubmit, batchSections, ...rest } = props;
  const { t } = useTranslation();

  const [sections, setSections] = useState(batchSections);
  const [payload, setPayload] = useState<any>([]);

  const onSubmitHandler = () => {
    onSubmit({ data: payload });
  };

  const onActionHandler = (event: "edit" | "add" | "delete", section?: any) => {
    const { id, name } = section;
    switch (event) {
      case "edit":
        const copyPayload = [...payload];
        const objIndex = copyPayload.findIndex((el) => el.payload[0].id === id);

        if (objIndex !== -1) {
          copyPayload[objIndex].payload[0].name = name;
          return setPayload(copyPayload);
        }
        setPayload((prev) => [...prev, { event: "edit", payload: [section] }]);

        break;
      case "add":
        setPayload((prev) => [...prev, { event: "add", payload: [section] }]);
        break;
      case "delete":
        setPayload((prev) => [
          ...prev,
          { event: "delete", payload: [section] },
        ]);
        break;
    }
  };

  const addSectionHandler = useCallback(() => {
    const newSection = {
      id: uuidv4(),
      name: "",
      order: sections.length + 1,
      parentId: null,
      level: sections.length + 1,
    };

    setSections([...sections, newSection]);
    onActionHandler("add", newSection);
  }, [setSections, sections]);

  useEffect(() => {
    setSections(batchSections);
    return () => {
      setPayload([]);
    };
  }, [batchSections]);

  return (
    <Modal
      {...props}
      title={t("dealerMaterials.letters.editParts") || ""}
      confirmButtonText={t("dealerMaterials.letters.save") || ""}
      cancelButtonText={t("dealerMaterials.letters.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={() => onCancel && onCancel()}
    >
      <StyledModalFields>
        <StyledFormEditBatchModal>
          <Space align="center">
            <IconButton
              variant="tertiary"
              icon="plus"
              color="brand"
              onClick={addSectionHandler}
            >
              {t("dealerMaterials.letters.addSection") || ""}
            </IconButton>
          </Space>

          <Tree
            data={sections}
            sections={sections}
            setSections={setSections}
            onActionHandler={onActionHandler}
          />
        </StyledFormEditBatchModal>
      </StyledModalFields>
    </Modal>
  );
};

export { MaterialsEditBatchModal };
