import { TModificationRecord } from "@/helpers/api/modifications/types";
import { TOnSubmitFormCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import {
  ICompleteSetDTOValues,
  ICompleteSetFormValues,
  ICompleteSetSuccessFormValues,
} from "../CompleteSetFields/types";
import {
  completeSetFormSchema,
  completeSetRecordToValues,
  completeSetValuesToDTO,
  DEFAULT_COMPLETE_SET_FORM_VALUES,
} from "../CompleteSetFields/utils";

export interface IEditCompleteSetPayload {
  patch: ICompleteSetDTOValues;
  record: TModificationRecord;
}

export type TOnSubmitEditCompleteFormFormCallback = (
  values: IEditCompleteSetPayload
) => void;

export const useEditCompleteSetForm = (options: {
  record?: TModificationRecord;
  onSubmit: TOnSubmitEditCompleteFormFormCallback;
}) => {
  const { onSubmit, record } = options;

  const onSubmitHandler: TOnSubmitFormCb<
    ICompleteSetSuccessFormValues
  > = async (values) => {
    if (record) {
      const dtoValues = completeSetValuesToDTO(values);
      await onSubmit({
        record,
        patch: dtoValues,
      });
    }
  };
  const result = useForm<ICompleteSetFormValues, ICompleteSetSuccessFormValues>(
    {
      validateFormSchema: completeSetFormSchema,
      onSubmit: onSubmitHandler,
      defaultValues: DEFAULT_COMPLETE_SET_FORM_VALUES,
    }
  );

  const reset = result.form.reset;

  useEffect(() => {
    if (record) {
      const values = completeSetRecordToValues(record);
      // @ts-ignore
      reset(values);
    }
  }, [record, reset]);

  return result;
};
