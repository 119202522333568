import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import {
  TGetKiaHoldingListRequest,
  TGetKiaHoldingListResponse,
  TGetKiaHoldingByIdRequest,
  TGetKiaHoldingByIdResponse,
  TKiaHoldingCreateRequest,
  TKiaHoldingCreateResponse,
  TKiaHoldingPatchRequest,
  TKiaHoldingPatchResponse,
  TKiaHoldingDeleteRequest,
  TKiaHoldingDeleteResponse,
  TExportKiaHoldingsRequest,
  TExportKiaHoldingsResponse,
} from "./types";
import { usePermission } from "@/hooks/usePermission";
import { AxiosResponse } from "axios";
import { TGetProfileResponse } from "@/helpers/api/profile/types";
import { accessTokenService } from "../../accessTokenService";

export const useGetKiaHoldingList = (request: TGetKiaHoldingListRequest) => {
  const queryClient = useQueryClient();
  const { hasAccess } = usePermission("holding", "read");
  const profile = (
    queryClient.getQueryData([
      "profile",
      accessTokenService.getToken(),
    ]) as AxiosResponse<TGetProfileResponse>
  )?.data;

  const isEnabled = Boolean(hasAccess && profile?.type === "kia");
  // const isEnabled =
  //   (hasAccess && profile?.type === "kia") ||
  //   (profile?.type === "dealer" &&
  //     Boolean(profile?.role?.name.toLowerCase().includes("холдинг")));

  return useQuery(
    ["kiaHoldings", "list", request],
    () => {
      return api.get<TGetKiaHoldingListResponse>("/kia/holdings", {
        params: request,
      });
    },
    { enabled: isEnabled }
  );
};

export const useGetKiaHoldingById = (request: TGetKiaHoldingByIdRequest) => {
  const queryClient = useQueryClient();
  const { hasAccess } = usePermission("holding", "read");

  const isEnabled = Boolean(
    hasAccess &&
      (
        queryClient.getQueryData([
          "profile",
          accessTokenService.getToken(),
        ]) as AxiosResponse<TGetProfileResponse>
      )?.data?.type === "kia"
  );

  return useQuery(
    ["kiaHoldings", "detail", request.id],
    () => {
      return api.get<TGetKiaHoldingByIdResponse>(`/kia/holdings/${request.id}`);
    },
    { enabled: isEnabled }
  );
};

export const useCreateKiaHolding = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TKiaHoldingCreateRequest) => {
      return api.post<TKiaHoldingCreateResponse>("/kia/holdings", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["kiaHoldings", "list"]);
      },
    }
  );
};

export const usePatchKiaHolding = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TKiaHoldingPatchRequest) => {
      return api.patch<TKiaHoldingPatchResponse>(`/kia/holdings/${id}`, patch);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["kiaHoldings", "detail", variables.id]);
        queryClient.invalidateQueries(["kiaHoldings", "list"]);
      },
    }
  );
};

export const useDeleteKiaHolding = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TKiaHoldingDeleteRequest) => {
      return api.delete<TKiaHoldingDeleteResponse>(`/kia/holdings/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["kiaHoldings", "detail", variables.id]);
        queryClient.invalidateQueries(["kiaHoldings", "list"]);
      },
    }
  );
};

export const useExportKiaHoldings = () => {
  return useMutation((request: TExportKiaHoldingsRequest) => {
    return api.get<TExportKiaHoldingsResponse>("/kia/holdings/export", {
      params: request,
      responseType: "blob",
    });
  });
};
