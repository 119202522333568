import { TFlexQuotasRecord } from "@/helpers/api/flex/settings/types";
import {
  IQuotasDTOValues,
  IQuotasFormValues,
  IQuotasSuccessFormValues,
} from "./types";

export const mapQuotasFieldsToDTO = (values: IQuotasDTOValues) => {
  const { months, dealerId } = values;
  return { months, dealerId };
};

export const generationFormValuesToDTO = (
  values: IQuotasSuccessFormValues
): IQuotasDTOValues => {
  const { year, dealerId, months } = values;
  const res = [] as Array<any>;

  Object.entries(months).forEach(([key, value]) => {
    if (value?.length) {
      res.push({ month: +key, generations: value });
    }
  });

  return {
    months: res,
    year,
    dealerId,
  };
};

export const generationFormValuesToDTOPatch = (
  values: TFlexQuotasRecord,
  isDisabled?: boolean
): IQuotasFormValues => {
  const { months, dealer, year } = values;
  const res = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
  };

  months?.forEach((item) => (res[item?.month] = item?.generations));

  return {
    year,
    dealerId: dealer.id,
    // @ts-ignore
    months: months.length > 0 && isDisabled ? res : null,
  };
};
