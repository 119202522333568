import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Col, Row } from "antd";
import React from "react";
import { DealerFieldsWrapper } from "./index.styles";

export interface IDealerFieldsProps {
  dealerData?: TDealerRecord;
}

const _DealerFields: React.FC<IDealerFieldsProps> = (props) => {
  const { dealerData } = props;

  return (
    <DealerFieldsWrapper>
      <Row>
        <Col span={4}>
          <Paragraph size={12} color="black10">
            Дилерский центр
          </Paragraph>
          <Paragraph size={10} color="gray400">
            {dealerData?.merchantName || ""}
          </Paragraph>
        </Col>
        <Col>
          <Paragraph size={12} color="black">
            Холдинг
          </Paragraph>
          <Paragraph size={10} color="gray400">
            {dealerData?.name || ""}
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Paragraph size={12} color="black">
            SAP код
          </Paragraph>
          <Paragraph size={10} color="gray400">
            {dealerData?.sapCode || ""}
          </Paragraph>
        </Col>
        <Col span={4}>
          <Paragraph size={12} color="black">
            Код шоурума
          </Paragraph>
          <Paragraph size={10} color="gray400">
            {dealerData?.showroomCode || ""}
          </Paragraph>
        </Col>
        <Col span={4}>
          <Paragraph size={12} color="black">
            Код Modis
          </Paragraph>
          <Paragraph size={10} color="gray400">
            {dealerData?.mobisCode || ""}
          </Paragraph>
        </Col>
      </Row>
    </DealerFieldsWrapper>
  );
};
export const DealerFields = React.memo(_DealerFields);
