import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px;
`;

export const StyledModalContentMessage = styled(Paragraph)`
  text-align: center;
`;
