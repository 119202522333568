import { Button } from "@/packages/button/Button";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col, Row } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  IMonitoringFilterFormValues,
  IMonitoringFilterOptions,
  MonitoringFilterFormFieldsNameEnum,
  TOnSubmitMonitoringFilterFormCb,
  useMonitoringFilterForm,
} from "./hooks";
import { StyledButtonsContainer, StyledCheckboxWrapper, StyledMonitoringFilterWrapper, } from "./index.styles";
import { useTranslation } from "react-i18next";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";

export interface IMonitoringFilterProps {
  initValues: IMonitoringFilterFormValues;
  filterOptions: IMonitoringFilterOptions;
  onFiltersChange: TOnSubmitMonitoringFilterFormCb;
  resetPage: () => void;
}

export const MonitoringFilter: React.FC<IMonitoringFilterProps> = ({
                                                                     initValues,
                                                                     filterOptions,
                                                                     onFiltersChange,
                                                                     resetPage,
                                                                   }) => {
  const { form, submit, reset } = useMonitoringFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  const handleReset = () => {
    reset();
    resetPage()
    submit && submit();
  };

  const handleSubmit = () => {
    resetPage()
    submit && submit();
  };

  const { t } = useTranslation();

  return (
    <StyledMonitoringFilterWrapper>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={4}>
            <FormSelect
              name={MonitoringFilterFormFieldsNameEnum.GenerationIds}
              label={t("monitoring.model") || ""}
              placeholder={t("monitoring.all") || ""}
              mode="multiple"
              isSearchShow={true}
              onSearch={() => ({})}
              options={filterOptions?.generations}
              allowClear
            />
          </Col>

          <Col span={4}>
            <FormSelect
              name={MonitoringFilterFormFieldsNameEnum.Titles}
              label={t("monitoring.titles") || ""}
              placeholder={t("monitoring.all") || ""}
              mode="multiple"
              isSearchShow={true}
              onSearch={() => ({})}
              options={filterOptions?.titles}
              allowClear
            />
          </Col>

          <Col span={4}>
            <FormSelect
              name={MonitoringFilterFormFieldsNameEnum.CatalogueNumbers}
              label={t("monitoring.catalogueNumbers") || ""}
              placeholder={t("monitoring.all") || ""}
              mode="multiple"
              isSearchShow={true}
              onSearch={() => ({})}
              options={filterOptions?.catalogues}
              allowClear
            />
          </Col>
          <Col span={4}>
            <FormSelect
              name={MonitoringFilterFormFieldsNameEnum.Status}
              label={t("monitoring.status") || ""}
              placeholder={t("monitoring.all") || ""}
              options={[
                { value: "", label: t("monitoring.all") || "" },
                { value: "open", label: t("monitoring.open") || "" },
                { value: "closed", label: t("monitoring.close") || "" },
                {
                  value: "search-completed",
                  label: t("monitoring.search") || "",
                },
              ]}
              allowClear
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <StyledCheckboxWrapper>
            <FormCheckbox
              name={MonitoringFilterFormFieldsNameEnum.OnlyUnreadMessages}
              label={'Только непрочитанные сообщения'}
            />
          </StyledCheckboxWrapper>
        </Row>
      </FormProvider>
      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={handleSubmit}>
          {t("monitoring.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={handleReset}
        >
          {t("monitoring.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledMonitoringFilterWrapper>
  );
};
