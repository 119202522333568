import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { api } from "../axiosInstance";
import {
  TCreateMediaRequest,
  TCreateMediaResponse,
  TDeleteMediaRequest,
  TDeleteMediaResponse,
  TGetMediaRequest,
  TGetMediaResponse,
  TUpdateMediaRequest,
} from "./types";
import { requestParamsMapper } from "@/helpers/utils";
import { IRequestParams } from "@/helpers/interfaces";

export const useGetMediaList = (request: TGetMediaRequest) => {
  return useQuery(["media", "list", request], () => {
    return api.get<TGetMediaResponse>("/media", {
      params: request,
    });
  });
};

export const useGetMediaListValidatedParams = (request: TGetMediaRequest) => {
  return useQuery(["media", "list", request], () => {
    return api.get<TGetMediaResponse>("/media", {
      params: requestParamsMapper(request as IRequestParams),
    });
  });
};

export const useCreateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TCreateMediaRequest) => {
      return api.post<TCreateMediaResponse>("/media", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["media", "list"]);
      },
    }
  );
};

export const useUpdateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TUpdateMediaRequest) => {
      return api.put<TUpdateMediaRequest>(`/media/${id}`, put);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["media", "list"]);
      },
    }
  );
};

export const useDeleteMedia = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TDeleteMediaRequest) => {
      return api.delete<TDeleteMediaResponse>("/media", { data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["media", "list"]);
      },
    }
  );
};
