import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { requiredSelectSimpleValueSchema } from "@/helpers/validation";
import { TFileRecord } from "@/helpers/api/files/types";

export type ChangeDataFormValues = {
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  email: string | null;
  phone: string | null;
  imageId: string | null;
  image: TFileRecord | null;
};

export type TOnSubmitChangeDataFormHandler = (data) => void;

export const formSchema = yup.object({
  firstName: yup.string().nullable().required("Введите имя"),
  lastName: yup.string().nullable().required("Введите фамилию"),
  role: requiredSelectSimpleValueSchema("Необходимо выбрать должность"),
  phone: yup.string().nullable(),
  imageId: yup.string().nullable(),
  image: yup.object().nullable(),
});

export const useChangeDataForm = (
  onSubmit: TOnSubmitChangeDataFormHandler,
  defaultValues: any
) => {
  const form = useForm<ChangeDataFormValues>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      dealer: defaultValues.dealer?.id || null,
      role: defaultValues.newRole?.id || defaultValues.role?.id || null,
      image: defaultValues?.image,
      email: defaultValues.email._address,
    },
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  return {
    form,
    onSubmitHandler,
  };
};
