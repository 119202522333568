import { ICheckbox } from "@/packages/checkbox/Checkbox";
import { DatepickerField } from "@/packages/formElements/fields/DatepickerField";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormDatepickerField
  extends Omit<ICheckbox, "error" | "value" | "onChange"> {
  name: string;
  isErrorAbsolute?: boolean;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
  isDisabled?: boolean;
  description?: string;
  isSameDateEnabled?: boolean;
  preventOpenOnFocus?: boolean
}

const _FormDatepickerField: React.FC<IFormDatepickerField> = (props) => {
  const { name, isDisabled, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <DatepickerField
            {...fieldWithoutRef}
            {...inputProps}
            disabled={isDisabled}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormDatepickerField = React.memo(_FormDatepickerField);
