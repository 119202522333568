import { media } from "@/helpers";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Space } from "antd";
import styled from "styled-components";

const StyledContentTitle = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledSelectField = styled.div`
  width: 100%;
`;

const StyledTableSimple = styled.div`
  overflow: auto;
  padding-bottom: 32px;

  display: grid;
  justify-items: center;
  gap: 24px;
`;

const StyledButtons = styled(Space)`
  margin-left: auto;
`;

const StyledButtonsTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  button {
    width: 100%;
  }
  margin-top: 24px;
  ${media.tablet} {
    flex-direction: row;
    button {
      width: fit-content;
    }
  }
  ${media.desktop} {
    margin-top: 0;
  }
`;

const StyledSelect = styled.div`
  width: 107px;
`;

export {
  StyledSelect,
  StyledContentTitle,
  StyledSelectField,
  StyledTableSimple,
  StyledButtons,
  StyledButtonsTop,
};
