import React from "react";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { Field } from "../Field";
import {
  StyledSelect,
  StyledYearRangeFieldControlContainer,
} from "./index.styles";

export interface IYearRangeInputFieldValue {
  start?: number;
  end?: number;
}
export interface IYearRangeInputField {
  className?: string;
  value?: IYearRangeInputFieldValue;
  onChange?: (value?: IYearRangeInputFieldValue) => void;
  label?: string;
  disabled?: boolean;
  error?: string;
  description?: string;
  placeholder?: [string, string];
  readOnly?: boolean;
  min?: number;
  max?: number;
}

const getYearOptions = (options?: { min?: number; max?: number }) => {
  const yearOptions: ISelectOption[] = [];
  const startYear = options?.min ?? 1999;
  const endYear = options?.max ?? new Date().getFullYear();
  for (let year = startYear; year <= endYear; year++) {
    yearOptions.push({
      value: year,
      label: year.toString(),
    });
  }

  return yearOptions;
};
export const YearRangeInputField: React.FC<IYearRangeInputField> = (props) => {
  const {
    className,
    value,
    onChange,
    label,
    disabled,
    description,
    error,
    placeholder,
    readOnly,
    min,
    max,
  } = props;
  const [startPlaceholder, endPlaceholder] = placeholder ?? [
    undefined,
    undefined,
  ];

  const startYearOptions = React.useMemo(() => {
    return getYearOptions({ min: min, max: value?.end || max });
  }, [min, value?.start, max, value?.end]);

  const endYearOptions = React.useMemo(() => {
    return getYearOptions({ min: value?.start || min });
  }, [min, value?.start]);

  const onChangeStartYear = (year: number) => {
    onChange && onChange({ start: year, end: value?.end });
  };

  const onChangeEndYear = (year: number) => {
    onChange && onChange({ start: value?.start, end: year });
  };

  return (
    <Field className={className}>
      {label ? (
        <Field.Label disabled={disabled} readOnly={readOnly}>
          {label}
        </Field.Label>
      ) : (
        <></>
      )}
      <StyledYearRangeFieldControlContainer>
        <StyledSelect
          options={startYearOptions}
          value={value?.start}
          onChange={onChangeStartYear}
          disabled={disabled}
          placeholder={startPlaceholder}
          allowClear={!readOnly}
          readOnly={readOnly}
        />
        <StyledSelect
          options={endYearOptions}
          value={value?.end}
          onChange={onChangeEndYear}
          disabled={disabled}
          placeholder={endPlaceholder}
          allowClear={!readOnly}
          readOnly={readOnly}
        />
      </StyledYearRangeFieldControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
