import { PasswordForgotForm } from "@/components/passwordForgot/PasswordForgotForm";
import { TOnSubmitPasswordForgotFormCb } from "@/components/passwordForgot/PasswordForgotForm/hooks";
import { useAuthForgotPasswordM } from "@/helpers/api/auth/hooks";
import { useNotification } from "@/hooks/useNotification";
import IdentityLayout from "@/layouts/IdentityLayout";
import { AxiosError } from 'axios';
import React from "react";

const PasswordForgot: React.FC = () => {
  const { mutateAsync: authForgotPassword } = useAuthForgotPasswordM();

  const { createNotificationError } = useNotification();
  const [isSentLetter, setIsSentLetter] = React.useState(false);

  const onSubmitHandler: TOnSubmitPasswordForgotFormCb = async (data) => {
    try {
      await authForgotPassword({
        email: data.email,
      });
      setIsSentLetter(true);
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  return (
    <IdentityLayout>
      <PasswordForgotForm onSubmit={onSubmitHandler} isLetter={isSentLetter} />
    </IdentityLayout>
  );
};

export default PasswordForgot;
