import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormPhoneInput } from "@/packages/formElements/formFields/FormPhoneInput";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { StyledFormLayout, StyledSubmitButton } from "../index.styles";
import {
  TOnSubmitFormSecondStepCb,
  useRegistrationFormSecondStep,
} from "./hooks";
import { useTranslation } from "react-i18next";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { StyledCheckboxes } from "@/components/MaintenanceGrids/index.styles";
import { useGetDocuments } from "@/helpers/api/documents/hooks";
import styled from "styled-components";
import { colors } from "@/helpers";

export type RegistrationFormSecondStepProps = {
  onSubmit: TOnSubmitFormSecondStepCb;
  isSubmitLoading: boolean;
};

const StyledLabel = styled.div`
  font-size: 12px;
`;

const StyledLink = styled.a`
  font-size: 13px;
  color: ${colors.black};
  text-decoration: none;
`;

export const RegistrationFormSecondStep: React.FC<
  RegistrationFormSecondStepProps
> = (props) => {
  const { onSubmit, isSubmitLoading } = props;
  const { form, submit } = useRegistrationFormSecondStep(onSubmit);
  const { t } = useTranslation();

  const { data: document } = useGetDocuments(
    "consent-to-the-processing-of-personal-data"
  );

  const [isChecked, setIsChecked] = useState(false);

  return (
    <FormProvider {...form}>
      <StyledFormLayout>
        <FormInputField
          name="firstName"
          label={t("authorization.name") || ""}
          placeholder={t("authorization.namePlaceholder") || ""}
        />
        <FormInputField
          name="lastName"
          label={t("authorization.surname") || ""}
          placeholder={t("authorization.surnamePlaceholder") || ""}
        />

        <FormPhoneInput
          name="phone"
          label={t("authorization.phone") || ""}
          isBlock={true}
        />

        <FormInputField
          name="email"
          label={t("authorization.email") || ""}
          placeholder={t("authorization.emailPlaceholder") || ""}
        />
        <StyledCheckboxes>
          <FormCheckbox
            name="privacyPolicy"
            onChange={() => setIsChecked(!isChecked)}
            value={isChecked}
            fontSize={"12"}
          >
            <StyledLabel>
              {`Даю согласие на обработку своих персональных данных на `}
              <StyledLink
                href={
                  // @ts-ignore
                  document?.data?.data[0]?.file.fileUrl
                }
                target={"_blank"}
              >
                <b>Условиях</b>
              </StyledLink>
            </StyledLabel>
          </FormCheckbox>
        </StyledCheckboxes>
      </StyledFormLayout>

      <StyledSubmitButton
        isBlock={true}
        theme="primary"
        color="brand"
        size="middle"
        onClick={submit}
        isLoading={isSubmitLoading}
        isDisabled={!isChecked}
      >
        {t("authorization.register") || ""}
      </StyledSubmitButton>
    </FormProvider>
  );
};
