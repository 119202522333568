import moment from "moment";

moment.locale("ru");

export type TDateFormat = "dateAndTime" | "dateOnly";

export const formatDate = (
  date: Date | string | number,
  format: TDateFormat
) => {

  const innerDate = new Date(date);

  switch (format) {
    case "dateAndTime": {
      return moment(innerDate).format("DD.MM.YYYY HH:mm");
    }
    case "dateOnly": {
      return moment(innerDate).format("DD.MM.YYYY");
    }
  }
};
