import { TUpdateTaskStatusDto } from "@/helpers/api/audit-tasks/types";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";

import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import { ITaskChatPayload } from "@/pages/AuditTasks";
import React, { useEffect } from "react";

import { getDefaultAuditTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

interface ITasksListTable extends Pick<ITableSimple, "onSort"> {
  data?: any;
  isLoading?: boolean;
  paginationParams: { page: number; limit: number };
  isDealerKia: boolean;
  hasPagination?: boolean;
  setPage: (page: number) => void;
  openChat: (data: ITaskChatPayload) => void;
  onStatusChange: (taskId: number, status: TUpdateTaskStatusDto) => void;
  requestParams: any;
}
export const TasksListTable: React.FC<ITasksListTable> = (props) => {
  const {
    data,
    isDealerKia,
    isLoading,
    hasPagination,
    paginationParams,
    setPage,
    onSort,
    openChat,
    onStatusChange,
    requestParams,
  } = props;

  const { dispatch } = useUserInfoModel();

  const headers = getDefaultAuditTableHeaders({
    isDealerKia,
    openChat,
    onStatusChange,
    openUserModal: dispatch,
  });

  useEffect(() => {
    setPage(1);
  }, [requestParams]);

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          <>
            <TableSimple
              headers={headers || []}
              data={data?.data || []}
              onSort={onSort}
            />
            {hasPagination ? (
              <Pagination
                current={paginationParams.page}
                onChange={setPage}
                pageSize={1}
                total={paginationParams.limit}
                showSizeChanger={false}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Loader size={48} />
          </>
        )}
      </StyledTableSimple>
    </>
  );
};
