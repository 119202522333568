import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledModelsListTabFooter = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;

export const StyledLastUpdate = styled(Paragraph)``;
