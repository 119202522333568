import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "@/helpers/api/axiosInstance";
import {
  BonusesMethodType,
  TCreateQuartalReportRequest,
  TCreateQuartalReportResponse,
  TGetBonusesReportRequest,
  TGetBonusesReportResponse,
  TImportBonusesReportFromApiRequest,
  TImportBonusesReportFromApiResponse,
  TUploadBonusesReportRequest,
  TUploadBonusesReportResponse,
} from "./types";

export const useGetBonusesReports = (requset: TGetBonusesReportRequest) => {
  return useQuery(["bonuses", "reports", requset], () => {
    return api.get<TGetBonusesReportResponse>("/bonuses/reports", {
      params: requset,
    });
  });
};

export const useUploadBonusesReportFile = () => {
  return useMutation(
    ({
      data,
      reportType,
      onSuccess,
      onError,
    }: {
      data: TUploadBonusesReportRequest;
      reportType: BonusesMethodType;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TUploadBonusesReportResponse>(
        `/bonuses/reports/files/${reportType}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditUploadBonusesReport = () => {
  return useMutation(
    ({
      data,
      id,
      reportType,
      onSuccess,
      onError,
    }: {
      id: string;
      data: TUploadBonusesReportRequest;
      reportType: BonusesMethodType;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TUploadBonusesReportResponse>(
        `/bonuses/reports/files/${reportType}/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useImportBonusesReportFromApi = () => {
  return useMutation(
    ({
      request,
      reportType,
      onSuccess,
      onError,
    }: {
      request: TImportBonusesReportFromApiRequest;
      reportType: BonusesMethodType;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TImportBonusesReportFromApiResponse>(
        `/bonuses/reports/import/${reportType}`,
        { ...request }
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useCreateQuarter = () => {
  return useMutation(
    ({
      request,
      onSuccess,
      onError,
    }: {
      request: TCreateQuartalReportRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TCreateQuartalReportResponse>("/bonuses/quarters", {
        ...request,
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError(resp as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

//TODO TYPES
export const useExportDealerBonuses = () => {
  return useMutation((request: any) => {
    return api.get<any>(`bonuses/quarters/bonuses-calculations/export`, {
      params: request,
      responseType: "blob",
    });
  });
};
