import React, { FC, useMemo, useState } from "react";
import {
  StyledFormSelect,
  StyledThirdRadioSelects,
} from "@/components/MaintenanceGrids/ExportTab/index.styles";
import { useGetGenerationListWithoutModelId } from "@/helpers/api/generations/hooks";
import { useGetSpares } from "@/helpers/api/maintenanceGrids/spares/hooks";
import { useDebounce } from "use-debounce";

const ThirdExportType: FC = () => {
  const [searchValue, setSearchValue] = useState<string>();

  const [searchDebouncedValue] = useDebounce(searchValue, 500);

  const {
    data: sparesGrids,
  } = useGetSpares(
    {
      limit: 100,
      query: searchDebouncedValue,
    }
  );

  const { data: generations } = useGetGenerationListWithoutModelId(
    {
      onlyActive: true,
      limit: 1000,
      orderBy: 'ASC',
      sortBy: 'modelName'
    }
  );

  const preparedGenerationsList = useMemo(
    () =>
      generations?.data?.data?.map((item) => ({
        label: `${item.fullModelName} (${item.generationYearStart}${item.generationYearEnd ? ('-' + item.generationYearEnd) : ''})`,
        value: item.id,
      })) || [],
    [generations?.data]
  );

  const preparedSparesList = useMemo(
    () =>
      sparesGrids?.data?.data.map((item) => ({
        label: item.code,
        value: item.code,
      })) || [],
    [sparesGrids]
  );

  return (
    <StyledThirdRadioSelects>
      <StyledFormSelect
        name={"thirdGeneration"}
        label={"Модель"}
        placeholder={"Введите или выберите модель из списка"}
        isSearchShow={true}
        options={preparedGenerationsList}
        allowClear
        mode={"multiple"}
      />
      <StyledFormSelect
        name={"spareCode"}
        label={"Код запчасти"}
        placeholder={"Введите или выберите код запчасти из списка"}
        isSearchShow={true}
        options={preparedSparesList}
        allowClear
        mode={"multiple"}
        refetchValue={searchValue}
        setRefetchValue={setSearchValue}
      />
    </StyledThirdRadioSelects>
  );
};
export { ThirdExportType };
