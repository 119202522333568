import React from "react";
import { Link } from "react-router-dom";

import { DeletePopconfirm } from "@/packages/DeletePopconfirm";
import { Paragraph } from "@/packages/paragraph/Paragraph";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  StyledActionsContainer,
  StyledButton,
  StyledInfoContainer,
  StyledNewsCard,
  StyledNewsText,
  StyledPhoto,
  StyledPostContainer,
  StyledTextContainer,
} from "./index.styles";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

export interface INewsCard {
  id: string;
  currentGroupId: string;
  imgUrl: string;
  author: string;
  title: string;
  text: string;
  authorId: string;
  description: string;
  createdAt: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const NewsCard: React.FC<INewsCard> = ({
                                                id,
                                                currentGroupId,
                                                imgUrl,
                                                author,
                                                title,
                                                text,
                                                description,
                                                createdAt,
                                                onEdit,
                                                onDelete,
                                                authorId,
                                              }) => {
  const { dispatch: openUserModal } = useUserInfoModel();

  return (
    <StyledNewsCard>
      <StyledPhoto src={imgUrl}/>
      <StyledInfoContainer>
        <StyledPostContainer>
          <Paragraph size={12} color="gray700">
            <StyledUserNameWrapper onClick={() => openUserModal({ userId: authorId })}>
              {author} -
            </StyledUserNameWrapper>
          </Paragraph>
          <Paragraph size={12} color="gray200">
            {createdAt}
          </Paragraph>
        </StyledPostContainer>

        <Paragraph size={18}>{title}</Paragraph>
        <StyledTextContainer>
          <Paragraph size={14} color="gray200">
            {description}
          </Paragraph>
        </StyledTextContainer>

        <StyledTextContainer>
          <StyledNewsText size={14} color="gray200">
            {text}
          </StyledNewsText>
        </StyledTextContainer>

        <StyledActionsContainer>
          <PermissionsAdapter
            resourceName="news"
            resourceAction="delete"
            currentGroupId={currentGroupId}
          >
            <DeletePopconfirm
              title="Вы действительно хотите удалить новость?"
              onConfirm={() => onDelete(id)}
            >
              <StyledButton
                isDisabled={false}
                theme="secondary"
                color="brand"
                size="small"
              >
                Удалить
              </StyledButton>
            </DeletePopconfirm>
          </PermissionsAdapter>

          <PermissionsAdapter
            resourceName="news"
            resourceAction="update"
            currentGroupId={currentGroupId}
          >
            <Link to={`/news/edit/${id}`}>
              <StyledButton
                isDisabled={false}
                theme="primary"
                color="brand"
                size="small"
                onClick={() => onEdit(id)}
              >
                Редактировать
              </StyledButton>
            </Link>
          </PermissionsAdapter>
        </StyledActionsContainer>
      </StyledInfoContainer>
    </StyledNewsCard>
  );
};
