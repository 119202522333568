import React from "react";
import {
  IdentityContentBlock,
  IdentityContentContainer,
  IdentityContentLogo,
  StyledIdentityLayoutWrapper,
  StyledScrollableContent,
  StyledBackgroundImage,
  StyledFixedContainer,
} from "./index.styles";
import identityLayoutBackground from "@/assets/images/login_bg.png";

export interface IdentityLayoutProps {
  children: React.ReactNode;
}

const IdentityLayout: React.FC<IdentityLayoutProps> = (props) => {
  const { children } = props;

  return (
    <StyledIdentityLayoutWrapper>
      <StyledFixedContainer>
        <StyledBackgroundImage src={identityLayoutBackground} />
        <IdentityContentContainer>
          <IdentityContentLogo size={64} name="logo" />
        </IdentityContentContainer>
      </StyledFixedContainer>

      <StyledScrollableContent>
        <IdentityContentContainer>
          <IdentityContentBlock>{children}</IdentityContentBlock>
        </IdentityContentContainer>
      </StyledScrollableContent>
    </StyledIdentityLayoutWrapper>
  );
};

export default IdentityLayout;
