import { Button } from "@/packages/button/Button";

import { FormSelect } from "@/packages/formElements/formFields/FormSelect";

import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Radio } from "@/packages/radio/Radio";
import { Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { DatepickerField } from "@/packages/formElements/fields/DatepickerField";
import { StringParam, useQueryParams } from "use-query-params";
import { MONTHS } from "@/helpers/consts";
import {
  useGetCityFilters,
  useGetStateFilters,
} from "@/helpers/api/reports/hooks";

import {
  SellsFilterFormFieldsNameEnum,
  TOnSubmitSellsFilterFormCb,
  useSellsFilterForm,
} from "./hooks";
import {
  RadioGrid,
  StyledButtonsContainer,
  StyledFilter,
  StyledFormLayout,
  StyledFormSelect,
  StyledRadioItem,
  StyledSelect,
  StyledSellsFilterWrapper,
  StyledTextBlock,
} from "./index.styles";
import { ChartType } from "@/helpers/api/reports/types";
import {
  preparedDealerList,
  useGetKiaGroupDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { getYearsList } from "@/helpers/date";
import { useTranslation } from "react-i18next";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface ISellsFilter {
  selectedType: ChartType;
  onFiltersApply: TOnSubmitSellsFilterFormCb;
  onChartTypeChange: (name: ChartType) => void;
  isProfileKia: boolean;
}

interface IRadioItem {
  isSelected: boolean;
  name: ChartType;
  title: string;
  description: string;
  componet: JSX.Element;
  onSelect: (name: string, value: boolean) => void;
}

const RadioItem: React.FC<IRadioItem> = (props) => {
  const { name, title, description, isSelected, componet, onSelect } = props;
  return (
    <StyledRadioItem isSelected={isSelected}>
      <Radio value={isSelected} onChange={(value) => onSelect(name, value)} />
      <StyledTextBlock>
        <Paragraph size={12} weight={400}>
          {title}
        </Paragraph>
        <Paragraph size={12} weight={400}>
          {description}
        </Paragraph>
      </StyledTextBlock>
      <StyledFilter isSelected={isSelected}>{componet}</StyledFilter>
    </StyledRadioItem>
  );
};

interface IDateFields {
  dateStart?: Date | null;
  dateEnd?: Date | null;
}

const BulletFilters = ({
  onDateChange,
}: {
  onDateChange: (data: IDateFields) => void;
}) => {
  const [date, setDate] = useState<IDateFields>({});

  useEffect(() => {
    onDateChange(date);
  }, [date]);
  const { t } = useTranslation();
  return (
    <>
      <Paragraph size={12}>Период данных </Paragraph>
      <Space size={19}>
        <DatepickerField
          label={t("bonuses.reportsView.dataFrom") || ""}
          value={date?.dateStart}
          onChange={(value) =>
            setDate((prev) => ({ ...prev, dateStart: value }))
          }
        />
        <DatepickerField
          label={t("bonuses.reportsView.dataFor") || ""}
          value={date?.dateEnd}
          onChange={(value) => setDate((prev) => ({ ...prev, dateEnd: value }))}
        />
      </Space>
    </>
  );
};

const mockRadio = [
  {
    name: "year",
    label: "bonuses.reportsView.byYear",
  },
  {
    name: "quarter",
    label: "bonuses.reportsView.byQuarter",
    select: "year",
  },
  {
    name: "month",
    label: "bonuses.reportsView.byMonth",
    select: "year",
  },
  {
    name: "day",
    label: "bonuses.reportsView.byDay",
    select: "month_year",
  },
];
const GraphFilters = ({
  onChange,
}: {
  onChange: ({
    value,
    key,
    groupBy,
    withPrev,
  }: {
    value?: string;
    key?: string;
    groupBy: string;
    withPrev?: boolean;
  }) => void;
}) => {
  const [selectedRadio, setSelectedRadio] = useState(mockRadio[0]);
  const { t } = useTranslation();

  const preparedYearsList = useMemo(() => getYearsList({ yearsCount: 20 }), []);

  return (
    <div>
      <RadioGrid>
        {mockRadio?.map((item) => (
          <div key={item.name}>
            <Radio
              name={item.name}
              label={t(item.label) || ""}
              value={selectedRadio.name === item.name}
              onChange={() => {
                onChange({ groupBy: item.name });
                setSelectedRadio(item);
              }}
            />
          </div>
        ))}
      </RadioGrid>
      {selectedRadio.select ? (
        <StyledSelect>
          {
            {
              year: (
                <Space size={16}>
                  <SelectField
                    label={t("bonuses.reportsView.yearChoose") || ""}
                    options={preparedYearsList || []}
                    onChange={(value) =>
                      onChange({
                        value,
                        key: "year",
                        groupBy: selectedRadio.name,
                        withPrev: true,
                      })
                    }
                  />
                </Space>
              ),

              month_year: (
                <Space size={16}>
                  <SelectField
                    label={t("bonuses.reportsView.yearChoose") || ""}
                    options={preparedYearsList || []}
                    onChange={(value) =>
                      onChange({
                        value,
                        key: "year",
                        groupBy: selectedRadio.name,
                        withPrev: true,
                      })
                    }
                  />
                  <SelectField
                    label={t("bonuses.reportsView.monthChoose") || ""}
                    options={MONTHS.map((item, index) => ({
                      label: t(item),
                      value: index + 1,
                    }))}
                    onChange={(value) => {
                      onChange({
                        value,
                        key: "month",
                        groupBy: selectedRadio.name,
                        withPrev: true,
                      });
                    }}
                  />
                </Space>
              ),
            }[selectedRadio.select]
          }
        </StyledSelect>
      ) : (
        <></>
      )}
    </div>
  );
};

export const SellsFilter: React.FC<ISellsFilter> = (props) => {
  const { selectedType, onFiltersApply, onChartTypeChange, isProfileKia } =
    props;

  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState<{
    [key in ChartType]: {};
  }>({
    [ChartType.Bullet]: {},
    [ChartType.Graph]: { groupBy: "year" },
  });

  const filtersMock: Omit<
    IRadioItem,
    "onSelect" | "isSelected" | "onChartTypeChange"
  >[] = [
    {
      name: ChartType.Bullet,
      title: t("bonuses.reportsView.bullet") || "",
      description: t("bonuses.reportsView.bulletDescr") || "",
      componet: (
        <BulletFilters
          onDateChange={(dateFilters) =>
            setSelectedFilters((prev) => ({
              ...prev,
              [ChartType.Bullet]: { ...prev[ChartType.Bullet], ...dateFilters },
            }))
          }
        />
      ),
    },
    {
      name: ChartType.Graph,
      title: t("bonuses.reportsView.graph") || "",
      description: t("bonuses.reportsView.graphDescr") || "",
      componet: (
        <GraphFilters
          onChange={({ value, key, groupBy, withPrev }) =>
            setSelectedFilters((prev) => {
              return key
                ? {
                    ...prev,
                    [ChartType.Graph]: !withPrev
                      ? { [key]: value, groupBy }
                      : { ...prev[ChartType.Graph], [key]: value, groupBy },
                  }
                : {
                    ...prev,
                    [ChartType.Graph]: { ...prev[ChartType.Graph], groupBy },
                  };
            })
          }
        />
      ),
    },
  ];

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    county: StringParam,
    manager: StringParam,
    state: StringParam,
    city: StringParam,
    dealer: StringParam,
  });

  const handleFiltersApply = (filters) => {
    onFiltersApply({ ...filters, ...selectedFilters[selectedType] });
  };
  const { form, submit, reset } = useSellsFilterForm({
    onSubmit: handleFiltersApply,
  });

  const { data: managerFilterOptionsResponse } = useGetKiaGroupDealerList({
    group: "zoneServiceManager",
  });

  const { data: stateFilterOptionsResponse } = useGetStateFilters({
    ...filterQueryParams,
  });
  const { data: cityFilterOptionsResponse } = useGetCityFilters({
    ...filterQueryParams,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data: dealerFilterOptionsResponse} =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const managerFilterOptions = managerFilterOptionsResponse?.data.data;
  const stateFilterOptions = stateFilterOptionsResponse?.data.data;
  const cityFilterOptions = cityFilterOptionsResponse?.data.data;

  return (
    <StyledSellsFilterWrapper>
      {isProfileKia ? (
        <FormProvider {...form}>
          <StyledFormLayout>
            <FormSelect
              name={SellsFilterFormFieldsNameEnum.Manager}
              options={
                managerFilterOptions
                  ? managerFilterOptions.map((option) => ({
                      label: `${option?.firstName || ""} ${
                        option?.lastName || ""
                      }`,
                      value: option.id,
                    }))
                  : []
              }
              label={t("bonuses.reportsView.manager") || ""}
              placeholder={t("bonuses.reportsView.managerChoose") || ""}
              allowClear
              showSearch={true}
            />

            <FormSelect
              name={SellsFilterFormFieldsNameEnum.State}
              options={
                stateFilterOptions
                  ? stateFilterOptions.map((option) => ({
                      label: option,
                      value: option,
                    }))
                  : []
              }
              label={t("bonuses.reportsView.region") || ""}
              placeholder={t("bonuses.reportsView.regionChoose") || ""}
              allowClear
              showSearch={true}
            />

            <FormSelect
              name={SellsFilterFormFieldsNameEnum.City}
              options={
                cityFilterOptions
                  ? cityFilterOptions.map((option) => ({
                      label: option,
                      value: option,
                    }))
                  : []
              }
              label={t("bonuses.reportsView.city") || ""}
              placeholder={t("bonuses.reportsView.cityChoose") || ""}
              allowClear
              showSearch={true}
            />
            <StyledFormSelect>
              <FormSelect
                name={SellsFilterFormFieldsNameEnum.Dealer}
                options={preparedDealerList(
                  dealerFilterOptionsResponse?.data?.data || []
                )}
                label={t("bonuses.reportsView.dealer") || ""}
                placeholder={t("bonuses.reportsView.dealerChoose") || ""}
                allowClear
                onSearch={setSearchValue}
                showSearch
              />
            </StyledFormSelect>
          </StyledFormLayout>
        </FormProvider>
      ) : null}
      {filtersMock.map((item) => (
        <RadioItem
          key={item.name}
          isSelected={item.name === selectedType}
          onSelect={() => {
            onChartTypeChange(item.name);
          }}
          {...item}
        />
      ))}

      <StyledButtonsContainer>
        <Button color="brand" theme="secondary" size="middle" onClick={reset}>
          {t("bonuses.reportsView.cancel") || ""}
        </Button>
        <Button color="brand" theme="primary" size="middle" onClick={submit}>
          {t("bonuses.reportsView.save") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledSellsFilterWrapper>
  );
};
