import { MonitoringUpload } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringUpload";
import { useUploadFilesArchive } from "@/helpers/api/files/hooks";
import { useGetMonitoringQualityCarById } from "@/helpers/api/monitoringQuality/hooks";
import { useNotification } from "@/hooks/useNotification";
import { Checkbox } from "@/packages/checkbox/Checkbox";
import { InputField } from "@/packages/formElements/fields/InputField";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { MonitoringChat } from "./../MonitoringChat";
import { useFileForm } from "./hooks";
import {
  StyledButton,
  StyledCardFormWrapper,
  StyledCardLeft,
  StyledCardRight,
  StyledMonitoringCarsTableExpandDataForm,
  StyledWrapperDropzone,
} from "./index.styled";
import { useTranslation } from "react-i18next";
import { DatepickerField } from "@/packages/formElements/fields/DatepickerField";

export interface IMonitoringCarsTableExpandDataFormProps {
  monitoringCarId: string;
  expandedData?: any;
  refetchRequestCars?: any;
}

export const MonitoringCarsTableExpandDataForm: React.FC<
  IMonitoringCarsTableExpandDataFormProps
> = ({ monitoringCarId, expandedData, refetchRequestCars }) => {
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { data: monitoringQualityCarById } = useGetMonitoringQualityCarById({
    id: monitoringCarId,
  });

  const { t } = useTranslation();

  const { mutateAsync: uploadAllFilesAsync } = useUploadFilesArchive();
  const { form } = useFileForm(monitoringQualityCarById?.data);
  const [photoIds] = form.watch(["photoIds"]);

  const imageFieldArray = useFieldArray({
    name: "photoIds",
    control: form.control,
  });

  const uploadAllFilesHandler = async () => {
    try {
      if (monitoringQualityCarById) {
        const { files, photos } = monitoringQualityCarById.data;
        const filesIds = files
          .map((file) => file.id)
          .concat(photos.map((photo) => photo.id));

        //@ts-ignore
        const response = await uploadAllFilesAsync({ ids: filesIds });
        //@ts-ignore
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        document.body.appendChild(link);
        link.click();
        createNotificationSuccess(t("monitoring.archiveDownloadSuccess") || "");
      }
    } catch (e) {
      createNotificationError(t("monitoring.archiveDownloadError") || "");
    }
  };

  useEffect(() => {
    imageFieldArray.append(photoIds);
  }, []);

  return (
    <StyledMonitoringCarsTableExpandDataForm>
      <Row gutter={32} justify="space-between">
        <Col span={8}>
          <StyledCardLeft>
            <StyledCardFormWrapper>
              {expandedData?.map((el, index) => {
                if (el.type === "textarea") {
                  return (
                    <TextAreaField
                      label={el?.label || ""}
                      value={el?.value || ""}
                      readOnly={true}
                      onChange={() => ({})}
                    />
                  );
                } else if (el.type === "checkbox") {
                  return (
                    <Checkbox
                      readOnly={true}
                      label={el.label}
                      value={el.value}
                    />
                  );
                } else if (el.type === "datepicker") {
                  return (
                    <DatepickerField
                      readOnly={true}
                      label={el?.label}
                      value={el?.value ? new Date(el.value) : null}
                    />
                  );
                } else
                  return (
                    <InputField
                      key={index}
                      label={el.label}
                      value={el.value}
                      readOnly
                    />
                  );
              })}
            </StyledCardFormWrapper>
          </StyledCardLeft>
        </Col>
        <Col span={8} style={{ height: "100%" }}>
          {t("monitoring.attachedFiles") || ""}
          <StyledCardRight>
            <StyledWrapperDropzone>
              <FormProvider {...form}>
                <MonitoringUpload
                  label=""
                  data={photoIds}
                  isExpandedGrid
                  fieldArray={imageFieldArray as any}
                  allowedTypes={["image/png", "image/jpeg", "image/tiff"]}
                  description=""
                  readOnly
                />
              </FormProvider>
            </StyledWrapperDropzone>
            {photoIds?.length !== 0 && (
              <StyledButton
                color="brand"
                theme="secondary"
                onClick={uploadAllFilesHandler}
              >
                {t("monitoring.downloadAll") || ""}
              </StyledButton>
            )}
          </StyledCardRight>
        </Col>
        <Col span={8} style={{ height: "100%" }}>
          {t("common.chat") + ":" || ""}
          <MonitoringChat monitoringCarsId={monitoringCarId} refetchRequestCars={refetchRequestCars}/>
        </Col>
      </Row>
    </StyledMonitoringCarsTableExpandDataForm>
  );
};
