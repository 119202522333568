import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PermissionsAdapter } from "./adapters/shared/PermissionsAdapter";
import { PrivateRoutes, PublicRoutes } from "./constants/pathes";
import {
  APPLICATIONS_CREATE_ROUTE,
  APPLICATIONS_EDIT_ROUTE,
  APPLICATIONS_ROUTE,
  AUTOPARK_CREATE_ROUTE,
  AUTOPARK_EDIT_ROUTE,
  AUTOPARK_ROUTE,
  CALENDAR_ROUTE,
  LOGIN_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_EDIT_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_VIEW_ROUTE,
  MAINTENANCE_GRIDS_ROUTE,
  NEWS_ROUTE,
} from "./constants/routes";
import { accessTokenService } from "./helpers/api/accessTokenService";
import { loginToTokenService } from "./helpers/api/loginToTokenService";
import { refreshTokenService } from "./helpers/api/refreshTokenService";
import { useIsAuth } from "./hooks/useIsAuth";
import { MaintenanceGridsContextProvider } from "./providers/MaintenanceGridsContextProvider";

export const AppRouter: React.FC = () => {
  const [isAuth, setIsAuth] = useState(useIsAuth());

  const location = useLocation();

  useEffect(() => {
    const token = accessTokenService.getToken();
    const refersh = refreshTokenService.getToken();
    setIsAuth(!!token || !!refersh);
    if (!token && !refersh && location.pathname !== "/login") {
      loginToTokenService.setToken(location.pathname + location.search);
    }
  }, [location]);

  return (
    <Routes>
      {isAuth ? (
        <>
          {PrivateRoutes.map(({ path, Element }) => {
            if (
              path === APPLICATIONS_CREATE_ROUTE ||
              path === APPLICATIONS_EDIT_ROUTE ||
              path === APPLICATIONS_ROUTE ||
              path === AUTOPARK_ROUTE ||
              path === AUTOPARK_CREATE_ROUTE ||
              path === AUTOPARK_EDIT_ROUTE ||
              path === CALENDAR_ROUTE ||
              path === APPLICATIONS_EDIT_ROUTE ||
              path === APPLICATIONS_ROUTE
            ) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PermissionsAdapter
                      resourceName="test-drives"
                      resourceAction="read"
                    >
                      <Element/>
                    </PermissionsAdapter>
                  }
                />
              );
            } else if (
              path === MAINTENANCE_GRIDS_ROUTE ||
              path === MAINTENANCE_GRIDS_DETAILS_ROUTE ||
              path === MAINTENANCE_GRIDS_DETAILS_EDIT_ROUTE ||
              path === MAINTENANCE_GRIDS_DETAILS_VIEW_ROUTE
            ) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <MaintenanceGridsContextProvider>
                      <Element/>
                    </MaintenanceGridsContextProvider>
                  }
                />
              );
            } else {
              return <Route key={path} path={path} element={<Element/>}/>;
            }
          })}
          <Route path="*" element={<Navigate to={NEWS_ROUTE}/>}/>
        </>
      ) : (
        <>
          {PublicRoutes.map(({ path, Element }) => (
            <Route key={path} path={path} element={<Element/>}/>
          ))}
          <Route path="*" element={<Navigate to={LOGIN_ROUTE}/>}/>
        </>
      )}
    </Routes>
  );
};
