import { TFlexExist } from "@/helpers/api/flex/vehicles/types";
import { IModal, Modal } from "@/packages/modal/Modal";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ExistsCarFields } from "../ExistsCarFields";
import { TOnSubmitExistCarForm, useExistCarForm } from "../ExistsCarForm/hook";
import { useTranslation } from "react-i18next";

export interface ICreateQuotasModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: TOnSubmitExistCarForm;
  record?: TFlexExist;
}

const CreateExistsCarModal: React.FC<ICreateQuotasModal> = (props) => {
  const { onSubmit, record, ...rest } = props;
  const { t } = useTranslation();
  const { form, submit, reset } = useExistCarForm({
    onSubmit,
    record,
  });
  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [form, reset, rest.isOpen]);

  return (
    <Modal
      {...rest}
      title={t("kiaFlex.existingCar") || ""}
      confirmButtonText={t("models.logsModel.save") || ""}
      cancelButtonText={t("models.addNewModelFrom.cancel") || ""}
      onSubmit={submit}
      showConfirmButton={record ? false : true}
      size="medium"
    >
      <FormProvider {...form}>
        <ExistsCarFields
          form={form}
          isDisabled={record ? true : false}
          mode={record ? "other" : "create"}
          record={record}
        />
      </FormProvider>
    </Modal>
  );
};
export default CreateExistsCarModal;
