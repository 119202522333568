import { FC } from "react";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";
import { colors } from "@/helpers";
import { useScaleValues } from "./hooks";

const StyledReportCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
`;

const StyledDealerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: 150px;
  padding-top: 25px;
`;

const StyledDealerCode = styled.button`
  color: ${colors.blue};
  cursor: pointer;
`;

const StyledBulletContainer = styled.div`
  width: 100%;
  margin-left: 50px;
`;

const StyledScale = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.white};
  margin-top: 16px;
`;

const StyledBulletLayout = styled.div`
  width: 100%;
  background-color: rgba(100, 107, 121, 0.2);
  border-radius: 8px;
  height: 40px;
  position: relative;
`;

const StyledGreenLine = styled.div<{ opacity: number; width: number }>`
  position: absolute;
  transform: translateY(-50%);
  background-color: rgba(31, 205, 3, ${({ opacity }) => opacity});
  border-radius: 0px 4px 4px 0px;
  height: 18px;
  top: 50%;
  left: 0;
  width: ${({ width }) => width}%;
  transition: all 0.5s ease-out;
`;

const StyledAverage = styled.div<{ width: number }>`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: ${({ width }) => width - 0.21}%;
  width: 6px;
  height: 29px;
  background-color: #4a6a57;
  border-radius: 4px;
  transition: all 0.5s ease-out;
`;

const StyledParagraph = styled(Paragraph)`
  transform: translate(-50%);
`;

interface ISellsTab {
  salesAmount: number;
  salesPlan: number;
  avgByDealers: number;
  dealerName: string;
  dealerCode: string;
}

export const DealerSellsBullet: FC<ISellsTab> = (props) => {
  const { salesAmount, salesPlan, avgByDealers, dealerName, dealerCode } =
    props;

  const getPrimaryData = (salesAmount: number, salesPlan: number) =>
    salesAmount > salesPlan
      ? [{ name: "Сумма продаж", y: salesAmount / 1000 }]
      : [
          { name: "Сумма продаж", y: salesAmount / 1000 },
          { name: "План продаж", y: salesPlan / 1000 },
        ];
  const max = Math.max(salesAmount, salesPlan, avgByDealers);

  const { scaleValues } = useScaleValues(max / 1000);

  return (
    <StyledReportCard>
      <StyledDealerInfo>
        <Paragraph size={16}>"{dealerName}"</Paragraph>
        <StyledDealerCode>{dealerCode}</StyledDealerCode>
      </StyledDealerInfo>
      <StyledBulletContainer>
        <StyledBulletLayout>
          <StyledGreenLine opacity={1} width={(+salesAmount * 92.3) / max} />
          <StyledGreenLine opacity={0.5} width={(+salesPlan * 92.3) / max} />
          <StyledAverage width={(+avgByDealers * 92.3) / max} />
        </StyledBulletLayout>
        <StyledScale>
          {scaleValues.map((value) => (
            <StyledParagraph size={12} weight={300} color="gray3">
              {value}
            </StyledParagraph>
          ))}
        </StyledScale>
      </StyledBulletContainer>
    </StyledReportCard>
  );
};
