import styled from "styled-components";
import { media } from "@/helpers";
import { Image } from 'antd';

export const StyledChat = styled.div`
    height: 100%;

    ${media.tablet} {
        min-width: 423px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledFile = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledChatBody = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 7px;
    width: 100%;
    height: 50vh;
    overflow: auto;
`;

export const StyledPhoto = styled.div<{ isMyMessage: boolean }>`
    align-self: ${({ isMyMessage }) => (isMyMessage ? "flex-end" : "flex-start")};
`;

export const StyledImagePreview = styled(Image)`
    position: relative;
    width: 100%;
    object-fit: cover;
`;

export const StyledDate = styled.div`
    padding: 1px 12px 0;
    background: #eeeff2;
    border-radius: 4px;
    margin: 0 auto;
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
`;
