import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  useGetFlexById,
  usePatchFlex,
} from "@/helpers/api/flex/vehicles/hooks";
import { formatDate } from "@/helpers/formatDate";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";
import { Space } from "antd";
import React, { useCallback, useState } from "react";
import CreateExistsCarModal from "../../KiaFlexExistCar/ExistsCarModal";
import { t } from "i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

export interface IHeadersProps {
  children: React.ReactNode;
}

export enum StatusVehicles {
  PENDING = "pending",
  RENT = "rent",
  IDLE = "idle",
}

export const Headers = () => {
  const headers = [
    {
      Header: "park.autoOne",
      accessor: "name",
      isOpening: true,
      Cell: ({ dataItem }) => {
        return (
          <>
            {!dataItem.child && (
              <Space direction="vertical">
                <Paragraph size={14} color="brand">
                  {dataItem.model}
                </Paragraph>
                <Paragraph size={14} color="gray600">
                  {dataItem.vin}
                </Paragraph>
              </Space>
            )}
          </>
        );
      },
    },
    {
      Header: "C2C",
      isSortable: true,
      accessor: "c2c",
      Cell: ({ dataItem }) => {
        const c2c = !!dataItem.c2c;

        return (
          <>
            {!dataItem.child && (
              <Paragraph size={14}>
                {c2c ? t("common.yes") || "" : t("common.no")}
              </Paragraph>
            )}
          </>
        );
      },
    },
    { Header: "kiaFlex.stNum", isSortable: true, accessor: "number" },
    {
      Header: "kiaFlex.insurance",
      isSortable: true,
      accessor: "insuranceExpired",
      Cell: ({ dataItem }) => {
        const date = formatDate(dataItem.insuranceExpired, "dateOnly");

        return (
          <> {!dataItem.child && <Paragraph size={14}>{date}</Paragraph>}</>
        );
      },
    },
    {
      Header: "park.createdAt",
      isSortable: true,
      accessor: "createdAt",
      Cell: ({ dataItem }) => {
        const date = formatDate(dataItem.createdAt, "dateOnly");

        return (
          <> {!dataItem.child && <Paragraph size={14}>{date}</Paragraph>}</>
        );
      },
    },
    {
      Header: "kiaFlex.totalSum",
      isSortable: true,
      accessor: "total",
    },
    {
      Header: "kiaFlex.status",
      isSortable: true,
      accessor: "status",
      Cell: ({ dataItem }) => {
        return (
          <>
            {!dataItem.child && (
              <>
                {dataItem.status === StatusVehicles.IDLE && (
                  <StatusLabel color={"red"} content={t("kiaFlex.downtime")} />
                )}
                {dataItem.status === StatusVehicles.RENT && (
                  <StatusLabel color={"green"} content={t("kiaFlex.rent")} />
                )}
                {dataItem.status === StatusVehicles.PENDING && (
                  <StatusLabel color={"gray"} content={t("kiaFlex.inWaiting")} />
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      Header: t("maintenanceGrids.gridsModel.actions") || "",
      accessor: "actions",
      Cell: ({ dataItem }) => {
        const [itemId, setItemId] = useState("");

        const { createNotificationError, createNotificationSuccess } =
          useNotification();

        const { mutateAsync: vehiclesToArchive } = usePatchFlex();

        const { data: flexVehicles } = useGetFlexById(itemId);

        const { modalState: existModalState, openModal: openExistModal } =
          useModal<any>((close) => {
            return async (payload) => {};
          });

        const onClickHandler = useCallback(
          async (id) => {
            try {
              await vehiclesToArchive({ id, patch: { archived: true } });
              createNotificationSuccess(
                t("kiaFlex.createNotificationSuccessAuto") || ""
              );
            } catch (error) {
              console.log("error    ", error);
              createNotificationError(
                t("kiaFlex.createNotificationErrorAuto") || ""
              );
            }
          },
          [
            createNotificationError,
            createNotificationSuccess,
            vehiclesToArchive,
          ]
        );

        return (
          <>
            {dataItem.status && (
              <Space>
                <Tooltip
                  trigger="hover"
                  placement={"bottom"}
                  content={t("common.info") || ""}
                >
                  <IconButton
                    icon={"info"}
                    size="m"
                    color="white"
                    onClick={() => {
                      setItemId(dataItem.id);
                      openExistModal();
                    }}
                  />
                </Tooltip>
                <PermissionsAdapter
                  resourceName="flex.vehicle"
                  resourceAction="update"
                >
                  <Tooltip
                    trigger="hover"
                    placement={"bottom"}
                    content={t("kiaFlex.toArchive") || ""}
                  >
                    <IconButton
                      icon={"to-archive"}
                      size="m"
                      color="white"
                      onClick={() => onClickHandler(dataItem?.id)}
                    />
                  </Tooltip>
                </PermissionsAdapter>
                <CreateExistsCarModal
                  record={flexVehicles?.data}
                  {...existModalState}
                />
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return headers;
};
