import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TGetBonusesCoefficientsNonDnmParamRequest,
  TGetBonusesCoefficientsNonDnmParamResponse,
  TGetBonusesCoefficientsParamsRequest,
  TGetBonusesCoefficientsParamsResponse,
  TPatchBonusesCoefficientParamRequest,
  TPatchBonusesCoefficientParamResponse,
  TDeleteBonusesCoefficientParamRequest,
  TDeleteBonusesCoefficientParamResponse,
  TPatchBonusesCoefficientsNonDnmParamRequest,
  TPatchBonusesCoefficientsNonDnmParamResponse,
} from "./types";

export const useGetCoefficientsParams = (
  request: TGetBonusesCoefficientsParamsRequest
) => {
  return useQuery(["bonuses", "coefficients-params", request], () => {
    return api.get<TGetBonusesCoefficientsParamsResponse>(
      "/bonuses/coefficients/params",
      {
        params: request,
      }
    );
  });
};

export const useCreateCoefficient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    //TODO
    (data: any) => {
      //TODO
      return api.post<any>(`/bonuses/coefficients/params`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bonuses", "coefficients-params"]);
      },
    }
  );
};

export const usePatchCoefficient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchBonusesCoefficientParamRequest) => {
      return api.patch<TPatchBonusesCoefficientParamResponse>(
        `/bonuses/coefficients/params/${id}`,
        patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bonuses", "coefficients-params"]);
      },
    }
  );
};

export const useDeleteCoefficient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: TDeleteBonusesCoefficientParamRequest) => {
      return api.delete<TDeleteBonusesCoefficientParamResponse>(
        `/bonuses/coefficients/params/${id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bonuses", "coefficients-params"]);
      },
    }
  );
};

export const useGetNonDnmCoefficientsParam = (
  request: TGetBonusesCoefficientsNonDnmParamRequest
) => {
  return useQuery(["bonuses", "coefficients-params-non-dnm", request], () => {
    return api.get<TGetBonusesCoefficientsNonDnmParamResponse>(
      "/bonuses/coefficients/params/non-dnm",
      {
        params: request,
      }
    );
  });
};

export const usePatchNonDnmCoefficient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchBonusesCoefficientsNonDnmParamRequest) => {
      return api.patch<TPatchBonusesCoefficientsNonDnmParamResponse>(
        `/bonuses/coefficients/params/non-dnm/${id}`,
        patch
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "bonuses",
          "coefficients-params-non-dnm",
        ]);
      },
    }
  );
};
