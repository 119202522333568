import { useEffect } from "react";

import { TInformationLettersRecord } from "@/helpers/api/InformationalLetters/types";

import {
  TOnSubmitFormAsyncCb,
  TOnSubmitFormCb,
  useForm,
} from "@/hooks/useForm";
import {
  TInformationLettersDataFieldsSuccessValues,
  TInformationLettersDataFieldsValues,
} from "../InformationLettersDataFields/types";
import {
  DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
  infoAtInformationLettersDTO,
  informationLettersEditFieldsToDealerDTO,
  informationLettersFieldsSchema,
} from "../InformationLettersDataFields/utils";

export type TOnSubmitIPCreateFormCb = TOnSubmitFormAsyncCb<any>;
export type TOnSubmitIPEditFormCb = TOnSubmitFormAsyncCb<{
  id: string;
  patch: any;
}>;

export const useCreateIPForm = (onSubmit: TOnSubmitIPCreateFormCb) => {
  const onSubmitHandler: TOnSubmitFormCb<
    TInformationLettersDataFieldsSuccessValues
  > = async (successValues) => {
    const createRecord = successValues;
    await onSubmit(createRecord);
  };

  return useForm<
    TInformationLettersDataFieldsValues,
    TInformationLettersDataFieldsSuccessValues
  >({
    validateFormSchema: informationLettersFieldsSchema(),
    defaultValues: DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};

// TODO: INFORMATION LETTERS поправить хук
const arrSwitch = (arr, files, removeList) => {
  const newFileIds =
    arr &&
    arr
      .filter((item) => {
        if (!removeList.includes(item.id)) return item.id;
      })
      .map((item) => item.id);

  const curFileIds =
    files &&
    files
      .filter((item) => {
        if (!removeList.includes(item.id)) return item.id;
      })
      .map((item) => item.id);
  if (
    newFileIds &&
    newFileIds.length > curFileIds.length &&
    newFileIds[0] != false
  ) {
    return newFileIds;
  } else {
    return curFileIds;
  }
};
export const useEditIPForm = (options: {
  editableRecord?: TInformationLettersRecord;
  onSubmit: TOnSubmitIPEditFormCb;
  removeList?: string[];
}) => {
  const { editableRecord, onSubmit, removeList } = options;
  const onSubmitHandler = (values: any) => {
    const { fileIds } = values;
    //@ts-ignore
    const files = editableRecord?.fileIds;

    const informationLettersDTOFields = {
      ...values,
      fileIds: arrSwitch(fileIds, files, removeList),
    };

    if (editableRecord) {
      onSubmit({ id: editableRecord.id, patch: informationLettersDTOFields });
    }
  };

  const result = useForm<
    TInformationLettersDataFieldsValues,
    TInformationLettersDataFieldsSuccessValues
  >({
    validateFormSchema: informationLettersFieldsSchema(),
    defaultValues: DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(informationLettersEditFieldsToDealerDTO(editableRecord));
    }
  }, [editableRecord, reset, removeList, onSubmit]);

  return result;
};

export const useGetInfoIpForm = (record: TInformationLettersRecord) => {
  const result = useForm<
    TInformationLettersDataFieldsValues,
    TInformationLettersDataFieldsSuccessValues
  >({
    validateFormSchema: informationLettersFieldsSchema(),
    defaultValues: DEFAULT_INFORMATION_LETTERS_DATA_FORM_VALUES,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (record) {
      reset(infoAtInformationLettersDTO(record));
    }
  }, [record, reset]);

  return result;
};
