import styled from "styled-components";

export const StyledNewsDataFields = styled.div`
  margin-bottom: 64px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
