import { Pagination } from "@/packages/pagination/Pagination";
import styled from "styled-components";

export const StyledPagination = styled(Pagination)`
  width: max-content;
`;

export const StyledTransportComplaintsTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
`;