import { Modal, IModal } from "@/packages/modal/Modal";
import { FC, useContext } from "react";
import { GridsFormModal } from "@/components/MaintenanceGrids/GridsTab/GridsFormModal";
import { useNavigate } from "react-router-dom";
import {
  CreateMaintenanceGridsFormValues,
  useCreateMaintenanceGridsForm,
} from "../hooks";
import { FormProvider } from "react-hook-form";
import {
  MAINTENANCE_GRIDS,
  ContextMaintenanceGrids,
} from "@/hooks/contexts/useMaintenanceGrids";
import { useTranslation } from "react-i18next";

type TGridsCreateModal = Pick<IModal, "onCancel">;

const GridsCreateModal: FC<TGridsCreateModal> = (props) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mutations: { setCreatingMaintenanceGridForm },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const onFormSubmit = (data: CreateMaintenanceGridsFormValues) => {
    setCreatingMaintenanceGridForm(data);
    onCancel && onCancel();
    navigate("/maintenance-grids/details", { state: data });
  };

  const { form, onSubmitHandler } = useCreateMaintenanceGridsForm(onFormSubmit);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
  };

  return (
    <Modal
      {...props}
      title={t("maintenanceGrids.addNewGrid") || ""}
      confirmButtonText={t("maintenanceGrids.newGridForm.create") || ""}
      cancelButtonText={t("maintenanceGrids.newGridForm.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <GridsFormModal />
      </FormProvider>
    </Modal>
  );
};

export { GridsCreateModal };
