import { colors } from "@/helpers";
import { Input, Upload } from "antd";
import styled, { css } from "styled-components";

export const StylesImagePreview = styled.img`
  width: 100%;
  height: 100%;
  min-height: 200px;
  object-fit: cover;
`;

export const StyledDragger = styled(Upload.Dragger)<{ fileExist: boolean }>`
  ${({ fileExist }) => {
    return fileExist
      ? css`
          && .ant-upload-btn {
            padding: 0;
          }
        `
      : "";
  }}
`;
export const StyledUpload = styled(Upload)<{
  fileExist?: boolean;
  isCentered?: boolean;
  variantBtn?: string;
}>`
  width: 200px;
  margin: 10px 0 0 0;
  ${({ variantBtn = "plus", isCentered = false }) =>
    variantBtn === "plus"
      ? css`
          && .ant-upload-list {
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: ${isCentered ? "center" : "flex-start"};
            flex-wrap: wrap;
          }

          && .ant-upload-list-picture-card-container {
            display: inline-block;
            width: 104px;
            height: 130px;
            display: flex;
          }

          && .ant-upload {
            width: 98px;
            height: 96px;
            align-self: flex-end;
          }
        `
      : css`
          && .ant-upload-list {
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: ${isCentered ? "center" : "flex-start"};
            flex-wrap: wrap;
          }

          && .ant-upload-list-picture-card-container {
            display: inline-block;
            width: 104px;
            height: 130px;
          }

          && .ant-upload {
            width: 200px;
            flex-direction: column;
            align-self: flex-end;
          }
        `}
`;

export const StyledInputDrop = styled.label<{
  fileExist?: boolean;
  variantBtn?: string;
}>`
  width: 252px;
  height: 116px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #333;
  background-color: white;
  ${({ variantBtn = "plus" }) =>
    variantBtn === "plus"
      ? css`
          .ant-upload-select {
            margin: 0;
          }

          && .ant-upload-list {
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
          }

          && .ant-upload-list-picture-card-container {
            width: 104px;
            height: 130px;
          }

          && .ant-upload {
            width: 96px;
            height: 96px;
            align-self: flex-end;
          }
        `
      : css`
          && .ant-upload-list {
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
          }

          && .ant-upload-list-picture-card-container {
            display: inline-block;
            width: 104px;
            height: 130px;
          }

          && .ant-upload {
            width: 200px;
            flex-direction: column;
            align-self: flex-end;
          }
        `}
`;
export const StyledIconDropzone = styled.div<{
  isWithCustomPlaceholderTitle?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  background: #e7eaff;

  ${({ isWithCustomPlaceholderTitle }) =>
    isWithCustomPlaceholderTitle
      ? css`
          width: 48px;
          height: 48px;
          margin-bottom: 12px;
        `
      : css`
          width: 55px;
          height: 55px;

          svg {
            width: 55px;
            height: 55px;
          }
        `}
`;
export const StyledPlaceholder = styled.div`
  font-size: 12px;

  span {
    color: ${colors.brand};
  }
`;

export const StyledErrorWrapper = styled.div`
  border: 2px solid red;
`;

export const StyledWraperDragger = styled.div<{ withCommentField?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  flex-wrap: wrap;
  ${({ withCommentField }) =>
    withCommentField
      ? " flex-direction: column; align-items: flex-start;"
      : " flex-direction: row;"}
`;

export const StyledFileListWrpapper = styled.div`
  display: flex;
`;

export const CommentFieldWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
`;
