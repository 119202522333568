import { TTariffsValues } from "./hooks";

export interface TShortPeriod {
  day: number;
  cost: number;
}
export interface TLongPeriod {
  month: number;
  cost: number;
}

export interface TCreateTypeDTO {
  generationId: string;
  shortPeriod: TShortPeriod[];
  longPeriod: TLongPeriod[];
}

export interface ITarifRecord {
  id: string;
  shortPeriod: TShortPeriod[];
  longPeriod: TLongPeriod[];
}

export const transformCreateDataToDTO = (
  values: TTariffsValues
): TCreateTypeDTO => {
  const { generationId, longPeriod, shortPeriod } = values;

  const longPeriodObject = longPeriod
    .slice(0, 34)
    ?.reduce((acc, item, index) => {
      acc[index] = { month: index + 2, cost: item ? +item : 0 };

      return acc;
    }, [] as TLongPeriod[]);

  const shortPeriodObject = shortPeriod?.reduce((acc, item, index) => {
    acc[index] = { day: index + 1, cost: item ? +item : 0 };

    return acc;
  }, [] as TShortPeriod[]);

  return {
    generationId,
    longPeriod: longPeriodObject,
    shortPeriod: shortPeriodObject,
  };
};

export const transformDataDTOToValues = (
  values: ITarifRecord
): TTariffsValues | undefined => {
  if (!values) return undefined;

  const shortPeriod = values.shortPeriod.map((item) => item.cost);
  const longPeriod = values.longPeriod.map((item) => item.cost);

  return {
    longPeriod: longPeriod,
    shortPeriod: shortPeriod,
    generationId: values?.id,
  };
};
