import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { TModelRecord } from "@/helpers/api/models/types";
import { IYearRangeInputFieldValue } from "@/packages/formElements/fields/YearRangeInputField";
import { IModal, Modal } from "@/packages/modal/Modal";
import { TitleDivider } from "@/packages/TitleDivider";
import { GenerationFields } from "../GenerationFields";
import { GenerationFieldsEnum, IGenerationFormDTOValues, } from "../GenerationFields/types";
import { TOnSubmitCreateGenerationFormCallback, useCreateGenerationForm, } from "./hooks";
import { StyledTitleSecondary } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IGenerationCreateModalSubmitPayload
  extends IGenerationFormDTOValues {
  modelId: string;
}
export interface IGenerationCreateModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  parentModel?: TModelRecord;
  onSubmit: (payload: IGenerationCreateModalSubmitPayload) => void;
}

export const GenerationCreateModal: React.FC<IGenerationCreateModal> = (
  props
) => {
  const { onSubmit, parentModel, ...rest } = props;

  const { t } = useTranslation();

  const onSubmitHandler: TOnSubmitCreateGenerationFormCallback = (data) => {
    if (parentModel) {
      onSubmit({
        ...data,
        modelId: parentModel.id,
      });
    }
  };

  const { form, submit, isSubmitLoading, reset } =
    useCreateGenerationForm(onSubmitHandler);

  useEffect(() => {
    if (!rest.isOpen) {
      reset();
    }
  }, [reset, rest.isOpen]);

  const [materialCode, name, isRestyling, restylingName, _yearRange] =
    form.watch([
      GenerationFieldsEnum.MaterialCode,
      GenerationFieldsEnum.Name,
      GenerationFieldsEnum.IsRestyling,
      GenerationFieldsEnum.RestylingName,
      GenerationFieldsEnum.GenerationYearRange,
    ]);

  const yearRange = _yearRange as IYearRangeInputFieldValue;

  return (
    <Modal
      {...rest}
      size="large"
      title={t("models.addNewGenerationModel.title") || ""}
      confirmButtonText={t("models.addNewGenerationModel.save") || ""}
      cancelButtonText={t("models.addNewGenerationModel.cancel") || ""}
      allowCancelPopconfirm={true}
      cancelPopconfirmTitle={
        t("models.addNewGenerationModel.cancelDescr") || ""
      }
      onSubmit={submit}
      isLoadingConfirmButton={isSubmitLoading}
    >
      <TitleDivider size="medium">
        {parentModel?.name} {parentModel?.modelYearStart}-
        {parentModel?.modelYearEnd}{" "}
        <StyledTitleSecondary>
          {name} ({materialCode}
          {isRestyling
            ? `, ${
                t("models.addNewGenerationModel.restyling") || ""
              } ${restylingName}`
            : ""}
          ) {yearRange?.start || "..."} - {yearRange?.end || "..."}
        </StyledTitleSecondary>
      </TitleDivider>
      <FormProvider {...form}>
        <GenerationFields parentModel={parentModel} />
      </FormProvider>
    </Modal>
  );
};
