import { FlexUpload } from "@/components/KiaFlex/FlexUpload";
import {
  allowedTypesFiles,
  allowedTypesImages,
} from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/utils";
import { useGetKiaDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { TFlexExist } from "@/helpers/api/flex/vehicles/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { Button } from "@/packages/button/Button";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Radio } from "@/packages/radio/Radio";
import { TitleDivider } from "@/packages/TitleDivider";

import { Col, Row, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";

import { DealerFields } from "../../KiaFlexFormsFields/DealerFields";
import { InsuranceFields } from "../../KiaFlexFormsFields/InsuranceFields";
import { ModelFields } from "../../KiaFlexFormsFields/ModelFields";
import { ExistCarFieldsName } from "../ExistsCarForm/type";
import {
  ExistCarFormWrapper,
  FieldWrapper,
  FormInputTextUpper,
  InsuranceWrapper,
  TitleFieldWrapper,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import { useGetDecoder } from "@/helpers/api/vin/hooks";
import { useNotification } from "@/hooks/useNotification";

export interface IExistsCarFieldsProps {
  form: UseFormReturn<any>;
  record?: TFlexExist;
  mode?: "other" | "create";
  isDisabled?: boolean;
}
export enum Insurance {
  CASCO = "CASCO",
  OSAGO = "OSAGO",
}

const modelDataTemp = {
  [ExistCarFieldsName.MODEL]: "Rio",
  [ExistCarFieldsName.GENERATION]: "Rio",
  [ExistCarFieldsName.MODIFICATION]: "Rio",
  [ExistCarFieldsName.EQUIPMENT]: "Rio",
  [ExistCarFieldsName.COLOR]: "Rio",
  [ExistCarFieldsName.EQUIPMENT]: "Rio",
  [ExistCarFieldsName.REGISTER_NUMBER]: "Rio",
};

const keysModel = [
  "model",
  "generation",
  "modification",
  "equipment",
  "color",
  "pts",
];

const kaskoFields = [
  "insurantK",
  "seriesK",
  "insuranceNameK",
  "fromK",
  "toK",
  "openInsuranceK",
];
const osagoFields = [
  "insurantO",
  "seriesO",
  "insuranceNameO",
  "fromO",
  "toO",
  "openInsuranceO",
];

const _ExistsCarFields: React.FC<IExistsCarFieldsProps> = (props) => {
  const { form, record, mode = "other", isDisabled = true } = props;

  const { t } = useTranslation();

  const [search, setSearch] = useState({
    dealer: "",
  });

  const [isManualVinPrefilling, setIsManualVinPrefilling] = useState(!!record);
  const [insurance, setInsurance] = useState<Insurance>(Insurance.CASCO);

  const { createNotificationError } = useNotification();

  const profile = useProfile();

  const [vin, modelId, generationId, modificationId, images, files] =
    form.watch([
      "vin",
      "modelId",
      "generationId",
      "modificationId",
      "images",
      "files",
    ]);

  const fieldArrayFiles = useFieldArray({
    control: form.control,
    name: "fileIds",
  });

  const fieldArrayPhotos = useFieldArray({
    control: form.control,
    name: "photoIds",
  });

  const {
    data: responseDecoder,
    isError: isDecoderError,
    mutateAsync: getDecoderAsync,
    isLoading: isGetDecoderLoading,
    reset: responseDecoderReset,
  } = useGetDecoder();

  const [photoIds, fileIds] = form.watch(["photoIds", "fileIds"]);

  const isProfileTypeKia = profile.state.profile?.type === "kia";

  const { data: kiaDealers } = useGetKiaDealerList({
    search: search.dealer,
  });

  if (profile.state.profile?.dealer) {
    form.setValue("dealerId", profile.state.profile?.dealer.id);
  }

  const dealersOptions = useMemo(() => {
    return (
      (kiaDealers &&
        //@ts-ignore
        kiaDealers?.data?.data.map((dealer) => {
          return { label: dealer.merchantName, value: dealer.id };
        })) ||
      []
    );
  }, [kiaDealers]);

  const resetDecoderData = () => {
    setIsManualVinPrefilling(false);
    responseDecoderReset();
  };

  const handleVinButtonClick = () => {
    setIsManualVinPrefilling(true);
    getDecoderAsync({
      data: { vin: vin! },
      onSuccess: () => {
        setTimeout(() => resetDecoderData, 8000);
      },
      onError: () => {
        setIsManualVinPrefilling(false);
        createNotificationError(t("park.createNotificationError") || "");
      },
    });
  };

  useEffect(() => {
    form.setValue("model", responseDecoder?.data?.id);
  }, [responseDecoder]);

  useEffect(() => {
    setTimeout(() => {
      form.setValue("generation", responseDecoder?.data?.generation.id);
    }, 100);
  }, [responseDecoder]);

  useEffect(() => {
    setTimeout(() => {
      form.setValue(
        "modification",
        responseDecoder?.data?.generation.modification.id
      );
    }, 200);
  }, [responseDecoder]);

  useEffect(() => {
    setTimeout(() => {
      form.setValue(
        "equipmentId",
        // @ts-ignore
        responseDecoder?.data?.generation.modification?.equipment?.id
      );
    }, 300);
  }, [responseDecoder]);

  return (
    <FieldWrapper>
      <ExistCarFormWrapper>
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}> {t("kiaFlex.ownerInfo") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>

        {(record?.owner || mode === "create") && (
          <Row>
            <Col span={24}>
              <FormInputField
                name={ExistCarFieldsName.OWNER}
                label={t("kiaFlex.owner") || ""}
                description={t("kiaFlex.ownerDescr") || ""}
                isDisabled={isDisabled}
              />
            </Col>
          </Row>
        )}
        <FormCheckbox
          name={ExistCarFieldsName.C2C}
          label="C2C"
          isDisabled={isDisabled}
        />
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.modelInfo") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>

        <Space direction="vertical" size={20}>
          <Row gutter={16}>
            <Col span={12}>
              <FormInputTextUpper
                name={ExistCarFieldsName.VIN}
                label={t("kiaFlex.vinCode") || ""}
                isDisabled={isDisabled}
              />
            </Col>
            {(record?.materialCode || !isDisabled) && (
              <Col span={12}>
                <FormInputTextUpper
                  name={ExistCarFieldsName.MATERAL_CODE}
                  label={"Material Code"}
                  isDisabled={isDisabled}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Button
              theme="secondary"
              color="brand"
              onClick={handleVinButtonClick}
              isDisabled={isDisabled}
            >
              {t("kiaFlex.prefill") || ""}
            </Button>
          </Row>
        </Space>

        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.manually") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>
        <ModelFields isDisabled={isDisabled} />
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.insuranceInfo") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>

        <Row gutter={16}>
          <Col span={12}>
            <InsuranceWrapper
              active={insurance === Insurance.CASCO}
              onClick={() => setInsurance(Insurance.CASCO)}
            >
              <div>
                <Paragraph size={12}>{t("kiaFlex.casco") || ""}</Paragraph>
                <Paragraph size={12}> {t("kiaFlex.cascoInfo") || ""}</Paragraph>
              </div>
              <Radio value={insurance === Insurance.CASCO} />
            </InsuranceWrapper>
          </Col>
          <Col span={12}>
            <InsuranceWrapper
              active={insurance === Insurance.OSAGO}
              onClick={() => setInsurance(Insurance.OSAGO)}
            >
              <div>
                <Paragraph size={12}>{t("kiaFlex.osago") || ""}</Paragraph>
                <Paragraph size={12}> {t("kiaFlex.osagoInfo") || ""}</Paragraph>
              </div>
              <Radio value={insurance === Insurance.OSAGO} />
            </InsuranceWrapper>
          </Col>
        </Row>

        {insurance === Insurance.CASCO && (
          <InsuranceFields
            form={form}
            insuranceFields={kaskoFields}
            isDisabled={isDisabled}
          />
        )}

        {insurance === Insurance.OSAGO && (
          <InsuranceFields
            form={form}
            insuranceFields={osagoFields}
            isDisabled={isDisabled}
          />
        )}
        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.autoPhoto") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>
        <Row>
          <Col>
            <FlexUpload
              data={photoIds}
              allowedTypes={allowedTypesImages}
              description={""}
              fieldsName={"photoIds"}
              fieldArray={fieldArrayPhotos}
              readOnly={false}
              maxCount={4}
              hasRemove={!isDisabled}
            />
          </Col>
          <Col>
            <FlexUpload
              label={t("kiaFlex.files") || ""}
              data={fileIds}
              allowedTypes={allowedTypesFiles}
              description={""}
              fieldsName={"fileIds"}
              fieldArray={fieldArrayFiles}
              readOnly={false}
              maxCount={4}
              hasRemove={!isDisabled}
            />
          </Col>
        </Row>

        <TitleFieldWrapper>
          <Row>
            <Paragraph size={14}>{t("kiaFlex.dealerInfo") || ""}</Paragraph>
          </Row>
          <TitleDivider />
        </TitleFieldWrapper>

        {isProfileTypeKia ? (
          <FormSelect
            name={ExistCarFieldsName.DEALER_CENTER}
            isSearchShow={true}
            onSearch={(value) =>
              setSearch((prev) => ({ ...prev, dealer: value }))
            }
            label={t("kiaFlex.dealerChoose") || ""}
            options={dealersOptions}
            isDisabled={isDisabled}
          />
        ) : (
          <DealerFields dealerData={profile.state.profile?.dealer} />
        )}
      </ExistCarFormWrapper>
    </FieldWrapper>
  );
};
export const ExistsCarFields = React.memo(_ExistsCarFields);
