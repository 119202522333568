import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IModal } from "@/packages/modal/Modal";

import {
  StyledModal,
  StyledModalContentWrapper,
} from "@/components/Calendar/CalendarFilter/ModelsSortModal/index.styles";
import { useCallback, useEffect, useState } from "react";
import { DraggableModelCard } from "@/components/Calendar/CalendarFilter/ModelsSortModal/DraggableModelCard";
import { useTranslation } from "react-i18next";

type IModel = {
  name: string;
  id: string;
};

interface ICalendarSortModelsModal extends Pick<IModal, "isOpen" | "onCancel"> {
  onSubmit: any;
  defaultValues?: any;
  models: IModel[];
  selectedModelsIds: string[];
}

export const ModelsSortModal: React.FC<ICalendarSortModelsModal> = (props) => {
  const { onSubmit, defaultValues, onCancel, models, selectedModelsIds } =
    props;
  const [cards, setCards] = useState(models);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: IModel[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as IModel],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card: IModel, index: number) => {
    return (
      <DraggableModelCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.name}
        moveCard={moveCard}
      />
    );
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedModelsIds?.length) {
      setCards(models.filter((model) => selectedModelsIds.includes(model.id)));
    } else {
      setCards(models);
    }
  }, [models, selectedModelsIds]);

  return (
    <StyledModal
      {...props}
      title={t("park.modelSort") || ""}
      size="small"
      cancelButtonText={t("audits.list.cancel") || ""}
      confirmButtonText={t("audits.list.createAudit_2") || ""}
      onSubmit={() => onSubmit(cards.map((card) => card.id))}
      onCancel={onCancel}
    >
      <DndProvider backend={HTML5Backend}>
        <StyledModalContentWrapper>
          {cards?.map((card, index) => renderCard(card, index))}
        </StyledModalContentWrapper>
      </DndProvider>
    </StyledModal>
  );
};
