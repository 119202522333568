import { useQuery } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGetBulletGraphRequest,
  TGetBulletGraphResponse,
  TGetGraphRequest,
  TGetGraphResponse,
} from "./types";

export const useGetCountryFilters = (request: any) => {
  return useQuery(["dealers-reports", "countries", request], () => {
    return api.get<any>("/reports/dealers/filters/countries", {
      params: request,
    });
  });
};

export const useGetCountyFilters = (request: any) => {
  return useQuery(["dealers-reports", "counties", request], () => {
    return api.get<any>("/reports/dealers/filters/counties", {
      params: request,
    });
  });
};

export const useGetManagerFilters = (request: any) => {
  return useQuery(["dealers-reports", "managers", request], () => {
    return api.get<any>("/reports/dealers/filters/managers", {
      params: request,
    });
  });
};

export const useGetStateFilters = (request: any) => {
  return useQuery(["dealers-reports", "states", request], () => {
    return api.get<any>("/reports/dealers/filters/states", {
      params: request,
    });
  });
};

export const useGetCityFilters = (request: any) => {
  return useQuery(["dealers-reports", "cities", request], () => {
    return api.get<any>("/reports/dealers/filters/cities", {
      params: request,
    });
  });
};

export const useGetDealerFilters = (request: any) => {
  return useQuery(["dealers-reports", "dealers", request], () => {
    return api.get<any>("/reports/dealers/filters/dealers", {
      params: request,
    });
  });
};

export const useGetDealersReport = (request: TGetBulletGraphRequest) => {
  return useQuery(["dealers-reports", "bullet", request], () => {
    return api.get<TGetBulletGraphResponse>("/reports/dealers/bullet", {
      params: request,
    });
  });
};

export const useGetDealersReportGraph = (request: TGetGraphRequest) => {
  return useQuery(["dealers-reports", "graph", request], () => {
    return api.get<TGetGraphResponse>("/reports/dealers/graph", {
      params: request,
    });
  });
};
