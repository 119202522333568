import { colors } from "@/helpers";
import { Card } from "@/packages/Card";
import styled from "styled-components";

export const StyledPhotoCard = styled(Card)`
  padding: 0;
  border-radius: 8px;
`;

export const StyledPhoto = styled.img`
  background-color: #e7eaff;
  object-fit: cover;
  height: 160px;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
  width: 100%;
`;

export const StyledInfoContainer = styled.div`
  padding: 12px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
