import {
  BonusesFileType,
  BonusesMethodType,
} from "@/helpers/api/bonuses/reports-import/types";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { HiddenMenu } from "./Menu";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  StyledContent,
  StyledDownloadIcon,
  StyledErrorText,
  StyledFileInputLabel,
  StyledInfoIcon,
  StyledInfoWrap,
  StyledName,
  StyledOptionsIcon,
  StyledReportCard,
  StyledReportLabel,
  StyledSaveIcon,
  StyledSendIcon,
  StyledSize,
  StyledUploadedAt,
} from "./index.styles";

export enum REPORT_TYPE {
  Xml = "XML",
  Api = "API",
}

export enum REPORT_STATUS {
  Empty = "empty",
  Uploaded = "uploaded",
  Error = "error",
  Disabled = "disabled",
}

export const status2color: { [key in REPORT_STATUS]: string } = {
  [REPORT_STATUS.Disabled]: "gray3",
  [REPORT_STATUS.Error]: "red",
  [REPORT_STATUS.Empty]: "gray3",
  [REPORT_STATUS.Uploaded]: "green100",
};

export interface IReportCard {
  name: string;
  type: REPORT_TYPE;
  id?: string;
  status?: REPORT_STATUS;
  apiName?: string;
  size?: string;
  uploadedAt?: Date | string;
  errorText?: string;
  methodType: BonusesMethodType;
  originalSize?: number;
  fileType: BonusesFileType;
  fileUrl?: string;
}

interface IReportMethods {
  onUploadFromApi: (methodType: BonusesMethodType) => void;
  onHandleUploadFile: (
    file: File,
    methodType: BonusesMethodType,
    id?: string
  ) => void;
}

export const ReportCard: FC<IReportCard & IReportMethods> = (props) => {
  const {
    id,
    type,
    status = REPORT_STATUS.Empty,
    name,
    apiName,
    methodType,
    size,
    uploadedAt,
    errorText,
    fileUrl,
    onUploadFromApi,
    onHandleUploadFile,
  } = props;
  const inputFile: RefObject<HTMLInputElement> = useRef(null);
  const [isDragEnter, setIsDragEnter] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && status === REPORT_STATUS.Empty) {
      onHandleUploadFile(acceptedFiles[0], methodType, id);
    }
    setIsDragEnter(false);
  };

  const downloadUrl = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
  };

  return (
    <Dropzone
      onDrop={onDrop}
      onDragEnter={() => setIsDragEnter(true)}
      onDragLeave={() => setIsDragEnter(false)}
    >
      {({ getRootProps, getInputProps }) => (
        <StyledReportCard status={status} isDragEnter={isDragEnter}>
          <StyledContent {...getRootProps()}>
            <StyledReportLabel status={status}>
              <Paragraph size={14}>{type}</Paragraph>
              {apiName && <Paragraph size={14}>({apiName})</Paragraph>}
            </StyledReportLabel>
            <input
              {...getInputProps()}
              disabled={status === REPORT_STATUS.Disabled}
              type="file"
              accept="xml/*"
              ref={inputFile}
              multiple={true}
              style={{ display: "none" }}
              onChange={(event) => {
                const eventCopy = { ...event };

                if (eventCopy?.target?.files?.length) {
                  onHandleUploadFile(
                    eventCopy?.target?.files[0],
                    methodType,
                    id
                  );
                }
              }}
            />

            {type === REPORT_TYPE.Api && status !== REPORT_STATUS.Uploaded ? (
              <StyledSendIcon
                size={24}
                name="button-upload"
                onClick={() => onUploadFromApi(methodType)}
              />
            ) : (
              <>
                {(status === REPORT_STATUS.Empty ||
                  status === REPORT_STATUS.Error) && (
                  <StyledFileInputLabel htmlFor="reportFile">
                    <StyledDownloadIcon size={24} name="download" />
                  </StyledFileInputLabel>
                )}
                {status === REPORT_STATUS.Uploaded && (
                  <StyledSaveIcon
                    size={24}
                    name="save"
                    color="black"
                    onClick={() => fileUrl && downloadUrl(fileUrl)}
                  />
                )}
              </>
            )}
            <StyledName size={14}>{name}</StyledName>
            {status !== REPORT_STATUS.Error &&
            status !== REPORT_STATUS.Disabled &&
            uploadedAt ? (
              <>
                <StyledSize size={12}>{size}</StyledSize>
                <StyledUploadedAt size={12}>
                  {new Date(uploadedAt)?.toLocaleString()}
                </StyledUploadedAt>
              </>
            ) : (
              <StyledErrorText size={12}>{errorText}</StyledErrorText>
            )}
            {status === REPORT_STATUS.Error ? (
              <StyledInfoIcon color="red" size={24} name="info" />
            ) : status !== REPORT_STATUS.Empty ? (
              <StyledInfoWrap>
                <HiddenMenu onReUpload={() => inputFile.current?.click()}>
                  <StyledOptionsIcon size={24} name="vertical-dots" />
                </HiddenMenu>
              </StyledInfoWrap>
            ) : (
              <></>
            )}
          </StyledContent>
        </StyledReportCard>
      )}
    </Dropzone>
  );
};
