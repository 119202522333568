import { useForm } from "@/hooks/useForm";
import * as yup from "yup";

export enum CreateUserFormFieldsEnum {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Phone = "phone",
  Country = "country",
  IsActive = "isActive",
  RoleId = "roleId",
  DealerId = "dealerId",
  Type = "type",
  IsConfirmed = "isConfirmed"
}

export interface CreateUser {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  country: string | null;
  isActive: boolean;
  roleId: string | null;
  dealerId: string | null;
  type: 'dealer';
  isConfirmed: boolean;
}

export const ACCOUNT_TYPE_OPTIONS = [
  { label: "Дилер", value: 'dealer' },
  { label: "Внешний пользователь", value: 'external' },
]

export const DEFAULT_CREATE_FORM_VALUES: CreateUser =
  {
    [CreateUserFormFieldsEnum.FirstName]: null,
    [CreateUserFormFieldsEnum.LastName]: null,
    [CreateUserFormFieldsEnum.Email]: null,
    [CreateUserFormFieldsEnum.Phone]: null,
    [CreateUserFormFieldsEnum.Country]: null,
    [CreateUserFormFieldsEnum.IsActive]: false,
    [CreateUserFormFieldsEnum.RoleId]: null,
    [CreateUserFormFieldsEnum.DealerId]: null,
    [CreateUserFormFieldsEnum.Type]: 'dealer',
    [CreateUserFormFieldsEnum.IsConfirmed]: false,
  };

export const formatSchema = () => {
  return yup
    .object({
      [CreateUserFormFieldsEnum.FirstName]: yup
        .string()
        .required("Введите имя")
        .nullable(),
      [CreateUserFormFieldsEnum.LastName]: yup
        .string()
        .required("Введите фамилию")
        .nullable(),
      [CreateUserFormFieldsEnum.Country]: yup
        .string()
        .required("Выберите страну")
        .nullable(),
      [CreateUserFormFieldsEnum.Phone]: yup
        .string()
        .required("Введите номер телефона")
        .nullable(),
      [CreateUserFormFieldsEnum.RoleId]: yup
        .string()
        .required("Выберите должность")
        .nullable(),
      [CreateUserFormFieldsEnum.Email]: yup
        .string()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Неверный формат email"
        )
        .required("Введите email")
        .nullable(),

    })
    .required();
};

export const useCreateUserForm = (options: {
  onSubmit;
}) => {
  const { onSubmit } = options;

  return useForm<CreateUser>({
    defaultValues: DEFAULT_CREATE_FORM_VALUES,
    validateFormSchema: formatSchema(),
    onSubmit: (data) => onSubmit(data),
  });
};