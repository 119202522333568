import React, { useContext, useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { AuditInfo } from "@/components/Audits/FillAuditStages/AuditInfo";
import { useGetAuditById } from "@/helpers/api/audit-fill/hooks";
import { Loader } from "@/packages/loader/Loader";
import { useAuditPlanSetStatus, useCompleteAuditPlan, useGetAuditTasksKia, } from "@/helpers/api/audit-plan/hooks";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { Button } from "@/packages/button/Button";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { AuditPlanTable } from "@/components/Audits/AuditPlanTable";
import { TAuditPlanSetStatusRequest } from "@/helpers/api/audit-plan/types";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ChatModal, IButtonCallback } from "@/components/Audits/ChatModal";
import { useModal } from "@/hooks/useModal";
import { useTaskToReview } from "@/helpers/api/audit-tasks/hooks";
import { TUpdateTaskStatusDto } from "@/helpers/api/audit-tasks/types";
import { useTranslation } from "react-i18next";
import { auditChatBtnCallback } from "../AuditTasks/hooks";
import {
  useReadAllAuditTaskPPOMessage,
  useSendNotificationTaskForDealer
} from "@/helpers/api/audit-chat/hooks";

const AuditPlan: React.FC = () => {
  const params = useParams();
  const auditId = Number(params.id) as number;
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState<{
    taskId: number;
    status: string;
  } | null>(null);
  const { mutate: sendNotification } = useSendNotificationTaskForDealer();


  const {
    state: { profile },
  } = useContext(ContextProfile);
  const { t } = useTranslation();
  const isDealerKia = profile?.type === "kia";

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { paginationParams, setPage } = usePagination();
  const {
    data: auditData,
    isLoading: isAuditLoading,
    refetch: updateAuditData,
  } = useGetAuditById(auditId);
  const {
    data: planData,
    isLoading: isPlanLoading,
    refetch: updatePlanData,
  } = useGetAuditTasksKia(
    {
      "filter[auditId]": auditId,
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
    },
    isDealerKia
  );

  const { mutateAsync: completeAudit, isLoading: isCompleteLoading } =
    useCompleteAuditPlan();
  const { mutateAsync: setTaskStatus, isLoading: isTaskStatusLoading } =
    useAuditPlanSetStatus();

  const handleCompletePlan = () => {
    completeAudit({
      id: auditId,
      onSuccess: () => {
        updatePlanData();
        updateAuditData();
        createNotificationSuccess("План завершен");
      },
      onError: (error) => {
        createNotificationError(
          error.response?.data?.message || error?.message
        );
      },
    });
  };

  const handleSetTaskStatus = (
    id: number,
    patch: TAuditPlanSetStatusRequest["patch"]
  ) => {
    setTaskStatus({
      id,
      patch,
      onSuccess: () => {
        createNotificationSuccess("Статус задачи обновлен");
        updateAuditData();
        updatePlanData();
      },
      onError: (error) => {
        createNotificationError(
          error.response?.data?.message || error?.message
        );
      },
    });
  };

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const handleOpenChat = (data) => {
    setSelectedTask(data);
    ChatModalOpen();
  };

  const { mutate: changeTaskStatus, isLoading: isStatusChanging } =
    useAuditPlanSetStatus();

  const { mutate: reviewTarget, isLoading: isReviewSending } =
    useTaskToReview();

  const handleCloseChat = () => {
    ChatModalClose();
    updateAuditData();
    updatePlanData();
  };

  const handleSendToReview = (isCancel: boolean) => {
    if (selectedTask) {
      reviewTarget({
        id: selectedTask.taskId,
        isCancel,
        onSuccess: () => {
          handleCloseChat();
          createNotificationSuccess(
            isCancel
              ? "Отменена отправка на проверку"
              : "Задача отправлена на проверку"
          );
        },
        onError: (error) => {
          updateAuditData();
          updatePlanData();
          createNotificationError(
            error.response?.data.message || error.message
          );
        },
      });
    }
  };

  const handleChangeStatus = (taskId: number, status: TUpdateTaskStatusDto) => {
    changeTaskStatus({
      id: taskId,
      patch: { status },
      onSuccess: () => {
        handleCloseChat();
        createNotificationSuccess("Статус задачи изменен");
      },
      onError: (error) => {
        updateAuditData();
        updatePlanData();
        createNotificationError(error.response?.data.message || error.message);
      },
    });
  };

  if (!auditId) {
    return (
      <DashboardLayout title="Проверка плана действий" toBack={-1}>
        No audit id
      </DashboardLayout>
    );
  }

  const handleSendNotification = () => {
    sendNotification({id: selectedTask?.taskId ?? 0})
  }

  return (
    <>
      <DashboardLayout title="Проверка плана действий" toBack={-1}>
        {isAuditLoading || isPlanLoading ? (
          <Loader size={48}/>
        ) : (
          <PermissionsAdapter
            resourceName={
              isDealerKia ? "audit.task-managment" : "audit.task-implementation"
            }
            resourceAction="read"
          >
            {auditData ? (
              <>
                <AuditInfo
                  auditData={auditData?.data || []}
                  renderComponent={() => (
                    <div>
                      <Button
                        size="middle"
                        color="brand"
                        theme="secondary"
                        onClick={() => navigate(`/audit-total/${auditId}`)}
                      >
                        Просмотреть итоги аудита
                      </Button>
                      <br/>
                      {isDealerKia &&
                      auditData.data.planStatus !== "Завершен" ? (
                        <Button
                          size="middle"
                          color="brand"
                          theme="primary"
                          isLoading={isCompleteLoading}
                          onClick={handleCompletePlan}
                        >
                          Завершить план действий
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                />
                <AuditPlanTable
                  isDealerKia={isDealerKia}
                  data={planData?.data?.data || []}
                  hasPagination={
                    !!planData?.data.meta?.pageCount &&
                    planData?.data.meta?.pageCount > 1
                  }
                  isLoading={isPlanLoading}
                  paginationParams={{
                    page: paginationParams.page,
                    limit: planData?.data.meta?.pageCount || 1,
                  }}
                  setPage={setPage}
                  onSetTaskStatus={handleSetTaskStatus}
                  onMessageClick={handleOpenChat}
                />
              </>
            ) : (
              <></>
            )}
          </PermissionsAdapter>
        )}
      </DashboardLayout>

      {selectedTask ? (
        <ChatModal
          params={{ taskId: selectedTask.taskId }}
          isOpen={ChatModalState.isOpen}
          onClose={handleCloseChat}
          btnCallback={auditChatBtnCallback({
            t,
            isDealerKia,
            handleChangeStatus,
            selectedTask,
            handleSendToReview,
            handleSendNotification
          })}
          readAllAuditMessage={useReadAllAuditTaskPPOMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { AuditPlan };
