import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TFileCreateRequest,
  TFileCreateResponse,
  TFileDeleteRequest,
  TFileDeleteResponse,
  TPostFileArchiveRequest,
  TPostFileArchiveResponse,
} from "./types";

export const useDeleteFile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TFileDeleteRequest) => {
      return api.delete<TFileDeleteResponse>(`/files/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["file", "list"]);
        queryClient.invalidateQueries(["file", "detail"]);
      },
    }
  );
};

export const useDownloadFile = () => {
  return useMutation((request: any) => {
    return api.get<any>(`/files/${request.id}`);
  });
};

export const useUploadFiles = () => {
  return useMutation(
    ({
      data,
      path,
      onSuccess,
    }: {
      data: TFileCreateRequest | FormData;
      path: string;
      onSuccess: (resp: TFileCreateResponse) => void;
    }) => {
      const dataToSend =
        data instanceof FormData ? { files: data.get("files") } : data;

      return api.post<TFileCreateResponse>(`/files?path=${path}`, dataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp.data);
        }
      },
    }
  );
};

export const useUploadFilesArchive = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: TPostFileArchiveRequest) => {
      return api.post<TPostFileArchiveResponse>("/files/get-archive", request, {
        responseType: "blob",
      });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([variables.ids]);
      },
    }
  );
};
