import { Col, Row } from "antd";
import React from "react";

import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { StyledNewsDataFields } from "./index.styles";
import { NewsDataFormFieldsEnum } from "./types";
import { useTranslation } from "react-i18next";

export const NewsDataFields: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledNewsDataFields>
      <Row gutter={32}>
        <Col span={6}>
          <FormImageDropzoneField
            label={t("news.addNewsForm.addFile") || ""}
            description={t("news.addNewsForm.addFileDescr") || ""}
            name={NewsDataFormFieldsEnum.FileId}
            path="news/photos"
          />
        </Col>
        <Col span={6}>
          <FormInputField
            name={NewsDataFormFieldsEnum.Name}
            label={t("news.addNewsForm.name") || ""}
          />
          <FormTextAreaField
            name={NewsDataFormFieldsEnum.Description}
            label={t("news.addNewsForm.description") || ""}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <FormTextAreaField
            name={NewsDataFormFieldsEnum.Text}
            label={t("news.addNewsForm.text") || ""}
          />
        </Col>
      </Row>
    </StyledNewsDataFields>
  );
};
