import React, { FC } from "react";
import styled, {
  css,
  keyframes,
  Theme,
  ThemeProvider,
} from "styled-components";
import themes from "./themes";
import { Icon, TSize } from "@/packages/icon/Icon";
import { colors } from "@/helpers";

type TButtonSize = "l" | "m" | "s" | "xs";
type TButtonVariant = "primary" | "secondary" | "tertiary";
type TButtonColor = "brand" | "black" | "gray" | "white";

export interface IIconButton {
  icon: string;
  variant?: TButtonVariant;
  color?: TButtonColor;
  size?: TButtonSize;
  type?: string;
  isSquared?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: JSX.Element | string;
}

interface IIconButtonStyle {
  children?: JSX.Element | string;
  variant: TButtonVariant;
  size: TButtonSize;
  iconSize: number;
  isDisabled: boolean;
  isSquared: boolean;
  isLoading: boolean;
}

const rotation = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const StyledLoader = styled.span<{ size: TButtonSize }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ size }) => (size === "m" ? "12px" : "16px")};
  height: ${({ size }) => (size === "m" ? "12px" : "16px")};

  border: 1.5px solid ${({ theme }) => colors[theme.loading.loaderColor]};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 1s linear infinite;
`;

const StyledIconButton = styled.div<IIconButtonStyle>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;

  ${({ variant, size, iconSize }) =>
    variant === "tertiary"
      ? css`
          width: ${iconSize}px;
          height: ${iconSize}px;
          min-width: ${iconSize}px;
          min-height: ${iconSize}px;
        `
      : css`
          width: ${size}px;
          height: ${size}px;
          min-width: ${size}px;
          min-height: ${size}px;
        `}

  ${({ isSquared, size }) =>
    isSquared
      ? css`
          border-radius: ${size === "m" ? "4px" : "6px"};
        `
      : css`
          border-radius: 50%;
        `}

  color: ${({ theme }) => colors[theme.default.color]};
  background-color: ${({ theme }) => colors[theme.default.backgroundColor]};
  border: 1px solid ${({ theme }) => colors[theme.default.borderColor]};

  svg {
    fill: ${({ theme }) => colors[theme.default.color]};
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      background-color: ${colors[theme.disabled.backgroundColor]};
      border-color: ${colors[theme.disabled.borderColor]};

      svg {
        fill: ${colors[theme.disabled.color]};
      }
    `}

  ${({ isLoading, theme }) =>
    isLoading &&
    css`
      color: transparent;
      background-color: ${colors[theme.loading.backgroundColor]};
      border-color: ${colors[theme.loading.borderColor]};

      svg {
        fill: ${colors[theme.loading.loaderColor]};
      }

      cursor: wait;
    `}

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    css`
      &:hover {
        ${StyledIconButton} {
          background-color: ${colors[theme.hoverAndFocus.backgroundColor]};
          border-color: ${colors[theme.hoverAndFocus.borderColor]};

          svg {
            fill: ${colors[theme.hoverAndFocus.color]};
          }
        }

        &:active {
          ${StyledIconButton} {
            background-color: ${colors[theme.active.backgroundColor]};
            border-color: ${colors[theme.active.borderColor]};

            svg {
              fill: ${colors[theme.active.color]};
            }
          }
        }
      }
    `}
`;

const StyledLabel = styled.span`
  margin-left: 12px;
`;

const StyledWrapper = styled.button<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    css`
      &:hover {
        ${StyledIconButton} {
          background-color: ${colors[theme.hoverAndFocus.backgroundColor]};
          border-color: ${colors[theme.hoverAndFocus.borderColor]};

          svg {
            fill: ${colors[theme.hoverAndFocus.color]};
          }
        }

        &:active {
          ${StyledIconButton} {
            background-color: ${colors[theme.active.backgroundColor]};
            border-color: ${colors[theme.active.borderColor]};

            svg {
              fill: ${colors[theme.active.color]};
            }
          }
        }
      }
    `}
`;

const IconButton: FC<IIconButton> = (props): JSX.Element => {
  const {
    icon,
    children,
    variant = "primary",
    color = "brand",
    size = "m",
    isSquared = false,
    isDisabled = false,
    isLoading = false,
    type,
    onClick = () => ({}),
    className,
  } = props;

  const IconSize: { [key in TButtonSize]: TSize } = {
    l: 24,
    m: 16,
    s: 12,
    xs: 10,
  };

  const ButtonSize: { [key in TButtonSize]: number } = {
    l: 40,
    m: 32,
    s: 24,
    xs: 20,
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (isDisabled) return;
    if (onClick) onClick(e);
  };

  const adjustedTheme = (ancestorTheme: Theme) => ({
    ...ancestorTheme,
    ...themes[variant][color],
  });

  return (
    <ThemeProvider theme={adjustedTheme}>
      <StyledWrapper isDisabled={isDisabled} onClick={handleClick}>
        <StyledIconButton
          isDisabled={isDisabled}
          className={className}
          variant={variant}
          size={ButtonSize[size]}
          iconSize={IconSize[size]}
          isSquared={isSquared}
          isLoading={isLoading}
          type={type}
        >
          {!isLoading ? (
            <Icon name={icon} size={IconSize[size]} />
          ) : (
            <StyledLoader size={size} />
          )}
        </StyledIconButton>
        {children ? <StyledLabel>{children}</StyledLabel> : null}
      </StyledWrapper>
    </ThemeProvider>
  );
};

export { IconButton };
