import React from "react";
import {
  TAuditPlanSetStatusRequest,
  TAuditTaskDto,
} from "@/helpers/api/audit-plan/types";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";

import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";

import { getDefaultAuditPlanTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";

interface IAuditPlanTable extends Pick<ITableSimple, "onSort"> {
  data?: TAuditTaskDto[];
  isDealerKia: boolean;
  isLoading?: boolean;
  paginationParams: { page: number; limit: number };
  hasPagination?: boolean;
  setPage: (page: number) => void;
  onSetTaskStatus: (
    id: number,
    patch: TAuditPlanSetStatusRequest["patch"]
  ) => void;
  onMessageClick: ({
    taskId,
    status,
  }: {
    taskId: number;
    status: string;
  }) => void;
}

export const AuditPlanTable: React.FC<IAuditPlanTable> = (props) => {
  const {
    data,
    isLoading,
    onSort,
    hasPagination,
    paginationParams,
    isDealerKia,
    setPage,
    onSetTaskStatus,
    onMessageClick,
  } = props;

  const headers = getDefaultAuditPlanTableHeaders({
    isDealerKia,
    onSetTaskStatus,
    onMessageClick,
  });

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          <>
            <TableSimple
              headers={headers || []}
              data={data || []}
              onSort={onSort}
            />
            {hasPagination ? (
              <Pagination
                current={paginationParams.page}
                onChange={setPage}
                pageSize={1}
                total={paginationParams.limit}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Loader size={48} />
          </>
        )}
      </StyledTableSimple>
    </>
  );
};
