import React from "react";
import { Pie } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IChart {
  labels: string[];
  data: number[];
}

export const PieChart: React.FC<IChart> = (props) => {
  const { labels, data } = props;
  return (
    <Pie
      height={"30%"}
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: [
              "#F94144",
              "#90BE6D",
              "#577590",
              "#43AA8B",
              "#90BE6D",
              "#90BE6D",
              "#F9C74F",
              "#F8961E",
              "#F3722C",
            ],
            borderColor: [
              "#F94144",
              "#90BE6D",
              "#577590",
              "#43AA8B",
              "#90BE6D",
              "#90BE6D",
              "#F9C74F",
              "#F8961E",
              "#F3722C",
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        plugins: {},
        responsive: true,
        maintainAspectRatio: false,
      }}
    />
  );
};
