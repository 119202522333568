import { MonitoringQualityFamiliarize } from "@/components/MonitoringQuality/MonitoringQualityFamiliarize";
import { TMonitoringNewProgramNotification } from "@/helpers/api/notifications/types";

import { FC } from "react";
interface INotificationsResultMonitoringNew {
  notification: TMonitoringNewProgramNotification;
}

export const NotificationsResultMonitoringNew: FC<
  INotificationsResultMonitoringNew
> = (props) => {
  const { notification } = props;
  const monitoringId = notification.data.entityId;

  return <MonitoringQualityFamiliarize id={monitoringId} />;
};
