import { colors, media } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";
import styled, { css } from "styled-components";

export const StyledDashboardLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "n h"
    "n c";
  min-height: 100vh;
  background: ${colors.background};
  max-width: 100vw;

  & > * {
    min-width: 0px;
  }
`;

export const StyledHeaderContainer = styled.div`
  grid-area: h;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  background: ${colors.white};
  max-width: 100%;
`;

export const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledContent = styled.div<{ hasMobile: boolean }>`
  grid-area: c;
  padding: 32px;

  ${({ hasMobile }) =>
    hasMobile &&
    css`
      padding: 32px 16px;

      ${media.tablet} {
        padding: 32px 64px;
      }
    `}
`;

export const StyledContentWrap = styled.div`
  height: 100%;
  max-width: 100%;
`;

export const StyledContentHeader = styled.div<{ noIndent: boolean }>`
  display: flex;
  gap: 12px;
  margin-bottom: 40px;
  ${({ noIndent }) =>
    noIndent &&
    css`
      margin-bottom: 0;
    `}
  justify-content: space-between;
  flex-direction: column;

  ${media.desktop} {
    flex-direction: row;
  }
`;

export const StyledContentHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledContentTitleHeaderLeft = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const StyledContentHeaderWrapper = styled.div`
  ${media.tablet} {
    margin-left: auto;
  }
`;

export const StyledContentTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  max-width: 50vw;
`;

export const StyledContentDescription = styled(Paragraph)``;
