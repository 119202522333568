import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { Card } from "@/packages/Card";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";

export const StyledNewsCard = styled(Card)`
  padding: 0;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  height: 100%;
`;

export const StyledPhoto = styled.img`
  background-color: #e7eaff;
  object-fit: cover;
  min-width: 240px;
  max-width: 268px;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
  flex-grow: 1;
`;

export const  StyledButtonWrap = styled.div`
    display: flex;
    gap: 10px;
`;

export const StyledInfoContainer = styled.div`
  padding: 12px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 3;
  color: black;
`;

export const StyledPostContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  width: 120px;
`;

export const StyledInfoCardContainer = styled.div`
  width: 160px;
`;

export const StyledParagraph = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
