import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export interface ITemplateValues {
  sectionId: string;
  title: string;
  criteria: string;
  plan: string;
  attribute: string;
  kpi: string;
  weight: number;
  photo: boolean;
}

export const DEFAULT_FORM_VALUES: ITemplateValues = {
  sectionId: "",
  title: "",
  criteria: "",
  plan: "",
  attribute: "",
  kpi: "",
  weight: 0,
  photo: false,
};

export const formSchema = () => {
  return yup.object({
    title: yup
      .string()
      .min(5, t("audits.templates.validation.checkPoint_5") || "")
      .max(500, t("audits.templates.validation.checkPoint_500") || "")
      .required(t("audits.templates.validation.checkPointRequired") || ""),
    criteria: yup
      .string()
      .min(5, t("audits.templates.validation.criteria_5") || "")
      .max(500, t("audits.templates.validation.criteria_500") || "")
      .required(t("audits.templates.validation.criteriaRequired") || ""),
    plan: yup
      .string()
      .min(5, t("audits.templates.validation.plan_5") || "")
      .max(800, t("audits.templates.validation.plan_800") || "")
      .required(t("audits.templates.validation.planRequired") || ""),
    weight: yup
      .number()
      .typeError(t("audits.templates.validation.weightRequired") || "")
      .min(0, t("audits.templates.validation.weight_0") || "")
      .max(99, t("audits.templates.validation.weight_100") || "")
      .required(t("audits.templates.validation.weightRequired") || ""),
    attribute: yup
      .string()
      .required(t("audits.templates.validation.signRequired") || ""),
    kpi: yup
      .string()
      .required(t("audits.templates.validation.kpiRequired") || ""),
  });
};

export type TOnSubmitCalendarFilterFormCb = (values: ITemplateValues) => void;

export const useTemplateForm = (onSubmit: TOnSubmitCalendarFilterFormCb) => {
  const form = useForm<ITemplateValues>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const setDefaultValue = (value: ITemplateValues) => {
    Object.entries(value).forEach(([key, value]) => {
      if (value === 0) {
        form.setValue(key as keyof ITemplateValues, "0");
      } else {
        form.setValue(key as keyof ITemplateValues, value);
      }
    });
  };

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  const handleChange = (
    key: keyof ITemplateValues,
    value: string | number | boolean
  ) => {
    form.setValue(key, value);
  };

  const handleReset = () => {
    form.reset();
  };

  return {
    form,
    setDefaultValue,
    onSubmitHandler,
    onClearHandler,
    handleChange,
    handleReset,
  };
};
