import { TFunction } from "i18next";
import * as yup from "yup";
import { IFileInfo } from "./hooks";
import { MonitoringDataFormFieldsEnum } from "./types";

export const DEFAULT_PARTS_VALUE = { partName: "", catalogueNumber: "" };

const FILES_UPLOAD_COUNT = 1;
export const DEFAULT_IMAGES: IFileInfo[] = new Array(FILES_UPLOAD_COUNT);

export const DEFAULT_FILES: IFileInfo[] = new Array(FILES_UPLOAD_COUNT);

export const monitoringFieldsSchema = (t: TFunction) => {
  return yup.object({
    [MonitoringDataFormFieldsEnum.Title]: yup
      .string()
      .max(150, t("monitoring.addNewProgramForm.validation.max_150") || "")
      .required(t("monitoring.addNewProgramForm.validation.name") || ""),
    [MonitoringDataFormFieldsEnum.Number]: yup
      .string()
      .required(t("monitoring.addNewProgramForm.validation.number") || "")
      .max(20, t("monitoring.addNewProgramForm.validation.max_20") || ""),
    [MonitoringDataFormFieldsEnum.CityIds]: yup
      .array()
      .required(t("monitoring.addNewProgramForm.validation.city") || "")
      .min(1, t("monitoring.addNewProgramForm.validation.minOneCity") || ""),
    [MonitoringDataFormFieldsEnum.DealerIds]: yup
      .array()
      .required(t("monitoring.addNewProgramForm.validation.dealer") || "")
      .min(1, t("monitoring.addNewProgramForm.validation.minOneDealer") || ""),
    [MonitoringDataFormFieldsEnum.GenerationsIds]: yup
      .array()
      .required(t("monitoring.addNewProgramForm.validation.model") || "")
      .min(1, t("monitoring.addNewProgramForm.validation.minOneModel") || ""),
    [MonitoringDataFormFieldsEnum.Parts]: yup.array().of(
      yup.object().shape({
        [MonitoringDataFormFieldsEnum.PartName]: yup
          .string()
          .max(50, t("monitoring.addNewProgramForm.validation.max_50") || ""),
        [MonitoringDataFormFieldsEnum.CatalogueNumber]: yup
          .string()
          .max(50, t("monitoring.addNewProgramForm.validation.max_50") || ""),
      })
    ),
    [MonitoringDataFormFieldsEnum.PrimaryRequirements]: yup
      .string()
      .max(2000, t("monitoring.addNewProgramForm.validation.max_2000") || "")
      .required(t("monitoring.addNewProgramForm.validation.needs") || ""),
    [MonitoringDataFormFieldsEnum.AdditionalRequirements]: yup
      .string()
      .max(2000, t("monitoring.addNewProgramForm.validation.max_2000") || ""),
    [MonitoringDataFormFieldsEnum.CarProductionDateFrom]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataStartCreate") || ""
      )
      .nullable()
      .default(undefined),
    [MonitoringDataFormFieldsEnum.CarProductionDateTo]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataEndCreate") || ""
      )
      .nullable()
      .default(undefined),
    [MonitoringDataFormFieldsEnum.CarSearchPeriodFrom]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataStartSearch") || ""
      )
      .nullable()
      .default(undefined),
    [MonitoringDataFormFieldsEnum.CarSearchPeriodTo]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataEndSearch") || ""
      )
      .nullable()
      .default(undefined),
    [MonitoringDataFormFieldsEnum.DateOfTheStudyFrom]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataStartReSearch") || ""
      )
      .nullable()
      .default(undefined),
    [MonitoringDataFormFieldsEnum.DateOfTheStudyTo]: yup
      .date()
      .typeError(t("monitoring.addNewProgramForm.validation.data") || "")
      .required(
        t("monitoring.addNewProgramForm.validation.dataEndReSearch") || ""
      )
      .nullable()
      .default(undefined),
  });
};

export const DEFAULT_MONITORING_DATA_FORM_VALUES: any = {
  [MonitoringDataFormFieldsEnum.Title]: "",
  [MonitoringDataFormFieldsEnum.CityIds]: [],
  [MonitoringDataFormFieldsEnum.DealerIds]: [],
  [MonitoringDataFormFieldsEnum.GenerationsIds]: [],
  [MonitoringDataFormFieldsEnum.Parts]: [DEFAULT_PARTS_VALUE],
  [MonitoringDataFormFieldsEnum.PrimaryRequirements]: "",
  [MonitoringDataFormFieldsEnum.AdditionalRequirements]: "",
  [MonitoringDataFormFieldsEnum.CarProductionDateFrom]: undefined,
  [MonitoringDataFormFieldsEnum.CarProductionDateTo]: undefined,
  [MonitoringDataFormFieldsEnum.CarSearchPeriodFrom]: undefined,
  [MonitoringDataFormFieldsEnum.CarSearchPeriodTo]: undefined,
  [MonitoringDataFormFieldsEnum.DateOfTheStudyFrom]: undefined,
  [MonitoringDataFormFieldsEnum.DateOfTheStudyTo]: undefined,
  [MonitoringDataFormFieldsEnum.PhotoIds]: [],
  [MonitoringDataFormFieldsEnum.FileIds]: [],
};

export const allowedTypesImages = ["image/png", "image/jpeg", "image/tiff"];

export const allowedTypesFiles = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/tiff",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint"
];

export const MAX_COUNT_PARTS = 10;
