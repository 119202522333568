import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { MonitoringDataFields } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields";
import { useEditMonitoringForm } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/hooks";
import { useGetMonitoringQualityById } from "@/helpers/api/monitoringQuality/hooks";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Loader } from "@/packages/loader/Loader";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export interface IMonitoringQualityInfoProps {}

export const MonitoringQualityInfo: React.FC<IMonitoringQualityInfoProps> = (
  props
) => {
  const params = useParams();
  const { t } = useTranslation();
  const { data: monitoringQualityById, isLoading } =
    useGetMonitoringQualityById({
      id: params.id as string,
    });

  const { form } = useEditMonitoringForm({
    editableRecord: monitoringQualityById?.data,
    t,
  });
  return (
    <DashboardLayout
      title={t("monitoring.viewProgram") || ""}
      toBack={-1}
    >
      <PermissionsAdapter
        resourceName="quality-monitoring.request-cars"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        loader={<Loader size={48} />}
      >
        {isLoading ? (
          <Loader size={48} />
        ) : (
          <FormProvider {...form}>
            <MonitoringDataFields form={form} readOnly />
          </FormProvider>
        )}
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
