import {
  TRegistryDataFieldsSuccessValues,
  TRegistryDataFieldsValues,
} from "@/components/RegistryDataFields/types";
import {
  dealerRecordToRegistryFields,
  registryFieldsSchema,
  successRegistryFieldsToDealerDTO,
  TRegistryFieldsDealerDTODataPart,
} from "@/components/RegistryDataFields/utils";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import {
  TOnSubmitFormAsyncCb,
  TOnSubmitFormCb,
  useForm,
} from "@/hooks/useForm";
import { useNotification } from "@/hooks/useNotification";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export type TOnSubmitRegistryEditFormCb = TOnSubmitFormAsyncCb<{
  id: string;
  patch: TRegistryFieldsDealerDTODataPart;
}>;

export const useRegistryEditDataForm = (
  initValues: TDealerRecord,
  onSubmit: TOnSubmitRegistryEditFormCb,
  isReadOnly?: boolean
) => {
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const navigate = useNavigate();

  const defaultValues = dealerRecordToRegistryFields(
    initValues,
    profile?.type || "dealer",
    isReadOnly
  );

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const onSubmitHandler: TOnSubmitFormCb<
    TRegistryDataFieldsSuccessValues
  > = async (successValues) => {
    try {
      const patch = successRegistryFieldsToDealerDTO(successValues);
      await onSubmit({
        id: initValues.id,
        patch,
      });
      createNotificationSuccess("Дилерский центр успешно изменен");
      navigate("/registry");
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  return useForm<TRegistryDataFieldsValues, TRegistryDataFieldsSuccessValues>({
    validateFormSchema: registryFieldsSchema(),
    defaultValues: defaultValues,
    onSubmit: onSubmitHandler,
  });
};
