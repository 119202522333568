import { FC, useEffect, useMemo, useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import {
  IReportCard,
  ReportCard,
  REPORT_STATUS,
  REPORT_TYPE,
} from "@/components/Bonuses/ReportsImport/ReportCard";
import { colors, media } from "@/helpers";
import styled from "styled-components";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Button } from "@/packages/button/Button";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IconButton } from "@/packages/icon-button/IconButton";
import {
  useCreateQuarter,
  useEditUploadBonusesReport,
  useGetBonusesReports,
  useImportBonusesReportFromApi,
  useUploadBonusesReportFile,
} from "@/helpers/api/bonuses/reports-import/hooks";
import {
  BonusesFileType,
  BonusesMethodType,
  TGetBonusesReportRequest,
} from "@/helpers/api/bonuses/reports-import/types";
import { useNotification } from "@/hooks/useNotification";
import { IDataInfo, ProgressBar } from "@/components/Bonuses/ProgressBar";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  IErrorMessages,
  ReportsErrorModal,
} from "@/components/Bonuses/ReportsImport/ReportsErrorModal";
import { useModal } from "@/hooks/useModal";
import { getYearsList } from "@/helpers/date";
import { useTranslation } from "react-i18next";

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 12px 12px 0 0;
  background: ${colors.white};
  gap: 30px;
`;

const StyledContent = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const StyledErrorParagraph = styled(Paragraph)`
  margin-top: 10px;
  color: ${colors.red};
`;

const StyledPrevButton = styled(IconButton)`
  position: absolute;
  left: 20px;
`;

const StyledNextButton = styled(IconButton)`
  transform: rotate(180deg);
  position: absolute;
  right: 20px;
`;

const StyledSelectField = styled(SelectField)`
  width: 200px;
`;

const StyledSuccess = styled.div`
  background: #b5d880;
  border-radius: 4px;
  padding: 9px 8px;
`;

const reportsData: IReportCard[] = [
  {
    type: REPORT_TYPE.Xml,
    name: "bonuses.reportsImport.trp",
    status: REPORT_STATUS.Empty,
    methodType: BonusesMethodType.TRP,
    fileType: BonusesFileType.TRP,
  },
  {
    type: REPORT_TYPE.Xml,
    name: "bonuses.reportsImport.ksmp",
    status: REPORT_STATUS.Empty,
    methodType: BonusesMethodType.KSMP,
    fileType: BonusesFileType.KSMP,
  },
  {
    type: REPORT_TYPE.Xml,
    name: "bonuses.reportsImport.ksap",
    status: REPORT_STATUS.Empty,
    methodType: BonusesMethodType.KSAP,
    fileType: BonusesFileType.KSAP,
  },
  {
    type: REPORT_TYPE.Xml,
    name: "bonuses.reportsImport.warranty",
    status: REPORT_STATUS.Empty,
    methodType: BonusesMethodType.GWMS,
    fileType: BonusesFileType.GWMS,
  },
  {
    type: REPORT_TYPE.Xml,
    // type: REPORT_TYPE.Api,
    name: "bonuses.reportsImport.sales",
    status: REPORT_STATUS.Empty,
    apiName: "1C Dealer",
    methodType: BonusesMethodType.SalesFact,
    fileType: BonusesFileType.SalesFact,
  },
  {
    type: REPORT_TYPE.Xml,
    // type: REPORT_TYPE.Api,

    name: "bonuses.reportsImport.nps",
    apiName: "API (DNM)",
    status: REPORT_STATUS.Empty,
    methodType: BonusesMethodType.NPS,
    fileType: BonusesFileType.NPS,
  },
  {
    type: REPORT_TYPE.Xml,
    // type: REPORT_TYPE.Api,

    name: "bonuses.reportsImport.plan",
    status: REPORT_STATUS.Empty,
    apiName: "API (DNM)",
    methodType: BonusesMethodType.SalesPlan,
    fileType: BonusesFileType.SalesPlan,
  },
];

export const MOCK_QUARTER_LIST = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const BonusesReportsImport: FC = () => {
  const [errorMessages, setErrorMessages] = useState<IErrorMessages>({
    messages: [],
    error: "",
  });
  const [isErrorModalWithSubmit, setIsErrorModalWithSubmit] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;

  const { t } = useTranslation();

  const selectedQuarter = new URLSearchParams(search).get("quarter") || "";
  const selectedYear = new URLSearchParams(search).get("year") || "";

  const [requestParams, setRequestParams] = useState<TGetBonusesReportRequest>({
    year:
      !isNaN(+selectedYear) && selectedYear
        ? +selectedYear
        : new Date().getFullYear(),
    quarter:
      !isNaN(+selectedQuarter) && selectedQuarter
        ? +selectedQuarter
        : Math.floor(new Date().getMonth() / 3 + 1),
  });

  const {
    modalState: errorModalState,
    openModal: errorModalOpen,
    closeModal: errorModalClose,
  } = useModal();

  useEffect(() => {
    if (!errorModalState.isOpen) {
      setIsErrorModalWithSubmit(false);
    }
  }, [errorModalState]);

  useEffect(() => {
    setSearchParams({
      quarter: requestParams.quarter.toString(),
      year: requestParams.year.toString(),
    });
  }, [requestParams]);

  useEffect(() => {
    setRequestParams({
      year:
        !isNaN(+selectedYear) && selectedYear
          ? +selectedYear
          : new Date().getFullYear(),
      quarter:
        !isNaN(+selectedQuarter) && selectedQuarter
          ? +selectedQuarter
          : Math.floor(new Date().getMonth() / 3 + 1),
    });
  }, [search]);

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const {
    data: reports,
    isLoading,
    refetch: updateReports,
  } = useGetBonusesReports(requestParams);
  const { mutate: uploadFile } = useUploadBonusesReportFile();
  const { mutate: importFromApi } = useImportBonusesReportFromApi();
  const { mutate: replaceFile } = useEditUploadBonusesReport();
  const { mutate: createQuarter, isLoading: isCreating } = useCreateQuarter();

  const handleError = (error, isCreateQuarterError = false) => {
    if (
      !!error.response?.data.errors ||
      Array.isArray(error.response?.data.message)
    ) {
      if (isCreateQuarterError) {
        setIsErrorModalWithSubmit(true);
        setErrorMessages({
          messages: error.response?.data.message,
          error: t("bonuses.reportsImport.setErrorMessages") || "",
        });
      } else {
        setErrorMessages({
          messages: error.response?.data.errors,
          error: error.response?.data.message,
        });
      }
      errorModalOpen();
    } else if (error.response?.data.message.includes("Unsupported file type")) {
      createNotificationError(t("bonuses.reportsImport.fileError") || "");
    } else {
      createNotificationError(error.response?.data.message || error.message);
    }
  };

  const handleCreateQuarter = (ignoreWarnings: boolean) => {
    createQuarter({
      request: { ...requestParams, ignoreWarnings: ignoreWarnings },
      onSuccess: () => {
        updateReports();
      },
      onError: (error) => {
        const isCreateQuarterError = true;
        handleError(error, isCreateQuarterError);
      },
    });
  };

  const handleUploadFileFromApi = (reportType: BonusesMethodType) => {
    importFromApi({
      request: requestParams,
      reportType,
      onSuccess: () => {
        updateReports();
      },
      onError: handleError,
    });
  };

  const handleUploadFile = (
    file: File,
    type: BonusesMethodType,
    id?: string
  ) => {
    const formData = new FormData();
    formData.append("file", file);

    if (id) {
      replaceFile({
        id,
        // @ts-ignore
        data: formData,
        reportType: type,
        onSuccess: () => {
          createNotificationSuccess(
            t("bonuses.reportsImport.replaceFileSuccess") || ""
          );
          updateReports();
        },
        onError: handleError,
      });
    } else {
      Object.entries(requestParams).forEach(([key, value]) => {
        formData.append(key, value.toString());
      });

      uploadFile({
        // @ts-ignore
        data: formData,
        reportType: type,
        onSuccess: () => {
          createNotificationSuccess(
            t("bonuses.reportsImport.uploadFileSuccess") || ""
          );
          updateReports();
        },
        onError: handleError,
      });
    }
  };

  const preparedData = useMemo(() => {
    return reportsData.map((report) => {
      const currentFile = reports?.data?.reports?.find(
        (item) => item.type === report?.fileType
      );
      if (currentFile) {
        const size = Math.round(currentFile.file.size * 0.001);
        return {
          ...report,
          status: !!currentFile?.error
            ? REPORT_STATUS.Error
            : REPORT_STATUS.Uploaded,
          uploadedAt: currentFile.updatedAt,
          size: `${size} Kb`,
          originalSize: size,
          fileUrl: currentFile.file.fileUrl,
          id: currentFile.id,
        };
      } else {
        return report;
      }
    });
  }, [reports]);

  const reportInfo = preparedData.reduce(
    (acc: IDataInfo, item) => {
      if (
        item.status !== REPORT_STATUS.Error &&
        item?.size &&
        item?.originalSize
      ) {
        return {
          ...acc,
          successUpload: acc.successUpload + 1,
          size: acc.size + +item?.originalSize,
        };
      }
      return acc;
    },
    { error: 0, successUpload: 0, size: 0 }
  );

  const preparedYearsList = useMemo(() => getYearsList({ yearsCount: 20 }), []);

  return (
    <DashboardLayout
      title={t("bonuses.reportsImport.title") || ""}
      description={t("bonuses.reportsImport.description") || ""}
    >
      <StyledHeader>
        <StyledPrevButton
          icon="chevron-left"
          variant="primary"
          color="white"
          size="l"
          isDisabled={requestParams.quarter === 1}
          onClick={() =>
            setRequestParams((prev) => ({ ...prev, quarter: prev.quarter - 1 }))
          }
        />
        <StyledSelectField
          label={t("bonuses.reportsImport.quarter") || ""}
          placeholder={t("bonuses.reportsImport.quarterChoose") || ""}
          options={MOCK_QUARTER_LIST || []}
          value={
            MOCK_QUARTER_LIST.find(
              (item) => item.value === requestParams.quarter
            ) || []
          }
          onChange={(value) =>
            setRequestParams((prev) => ({ ...prev, quarter: value }))
          }
        />
        <StyledSelectField
          label={t("bonuses.reportsImport.year") || ""}
          placeholder={t("bonuses.reportsImport.yearChoose") || ""}
          value={
            preparedYearsList.find(
              (item) => item.value === requestParams.year
            ) || []
          }
          onChange={(value) =>
            setRequestParams((prev) => ({ ...prev, year: value }))
          }
          options={preparedYearsList || []}
        />
        <div>
          <Paragraph size={12}>
            {t("bonuses.reportsImport.reportsLoaded") || ""}
            {reportInfo.successUpload}/{reportsData.length}
          </Paragraph>
          {reportInfo.error ? (
            <StyledErrorParagraph size={12}>
              {t("bonuses.reportsImport.errors") || ""} {reportInfo.error}
            </StyledErrorParagraph>
          ) : (
            <></>
          )}
        </div>
        {!reports?.data.quarter ? (
          <Button
            color="brand"
            theme="primary"
            isLoading={isCreating}
            isDisabled={reportInfo.successUpload !== reportsData.length}
            onClick={() => handleCreateQuarter(false)}
          >
            {t("bonuses.reportsImport.save") || ""}
          </Button>
        ) : (
          <StyledSuccess>
            <Paragraph size={10} color="greenStatus" weight={400}>
              {t("bonuses.reportsImport.success") || ""}
            </Paragraph>
          </StyledSuccess>
        )}
        <StyledNextButton
          icon="chevron-left"
          variant="primary"
          color="white"
          size="l"
          isDisabled={requestParams.quarter === 4}
          onClick={() =>
            setRequestParams((prev) => ({ ...prev, quarter: prev.quarter + 1 }))
          }
        />
      </StyledHeader>
      <ProgressBar
        dataInfo={reportInfo}
        year={requestParams.year}
        quarter={requestParams.quarter}
        min={reportInfo.successUpload}
        max={reportsData.length}
      />
      <StyledContent>
        {preparedData?.map((report, index) => {
          return (
            <ReportCard
              {...report}
              key={index}
              type={report.type}
              name={t(report.name) || ""}
              status={isLoading ? REPORT_STATUS.Disabled : report?.status}
              apiName={report.apiName}
              size={report?.size}
              uploadedAt={report?.uploadedAt}
              errorText={report?.errorText}
              onUploadFromApi={handleUploadFileFromApi}
              onHandleUploadFile={handleUploadFile}
              methodType={report.methodType}
            />
          );
        })}
        <ReportsErrorModal
          isOpen={errorModalState.isOpen}
          isWithSubmit={isErrorModalWithSubmit}
          onSubmit={() => {
            errorModalClose();
            handleCreateQuarter(true);
          }}
          onCancel={errorModalClose}
          errorMessages={errorMessages}
        />
      </StyledContent>
    </DashboardLayout>
  );
};
