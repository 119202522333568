import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { usePermission } from "@/hooks/usePermission";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FC, useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { CreateWorksSuccessFormValues, useCreateWorksForm } from "../hooks";
import { setEditedWorkFormValues } from "./utils";
import { WorksFormModalForm } from "@/components/MaintenanceGrids/WorksTab/WorksFormModal";
import { useNotification } from "@/hooks/useNotification";

type TWorksEditModal = Pick<IModal, "onSubmit" | "onCancel">;

const WorksEditModal: FC<TWorksEditModal> = (props) => {
  const { onCancel } = props;
  const { hasAccess: hasEdit } = usePermission("maintenance-grid", "update");
  const {
    state: { activeWork },
    actions: { updateWork },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const [workTypesError, setWorkTypesError] = useState(false);

  const onSubmitButtonClick = (data: CreateWorksSuccessFormValues) => {
    if (!activeWork) return;
    const adaptWorkTypes = data.workTypes
      .filter((workType) => workType.hasOwnProperty("totalCost"))
      // @ts-ignore
      .filter((type) => Boolean(type.code));

    const adaptData = {
      ...data,
      workTypes: adaptWorkTypes,
    };

    if (adaptWorkTypes.length === 0) {
      createNotificationError("Необходимо указать минимум 1 тип работы");
      setWorkTypesError(true);
    } else {
      updateWork({ id: activeWork.id, data: adaptData });
      onCancel && onCancel();
    }
  };

  const { form, onSubmitHandler } = useCreateWorksForm(onSubmitButtonClick);

  const onCancelButtonClick = () => {
    if (!activeWork) return;

    form.clearErrors();
    setEditedWorkFormValues({
      work: activeWork,
      formSetter: form.setValue,
    });

    onCancel && onCancel();
  };

  useEffect(() => {
    if (!activeWork) return;

    setEditedWorkFormValues({
      work: activeWork,
      formSetter: form.setValue,
    });
  }, [activeWork, form]);

  return (
    <Modal
      {...props}
      title={`${hasEdit ? "Редактировать работу" : "Просмотр работы"}`}
      confirmButtonText="Сохранить"
      cancelButtonText="Отмена"
      isDisabledCancelButton={!hasEdit}
      isDisabledConfirmButton={!hasEdit}
      onSubmit={onSubmitHandler}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <WorksFormModalForm activeWork={activeWork} />
      </FormProvider>
    </Modal>
  );
};

export { WorksEditModal };
