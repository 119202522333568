import { TBonusesDto } from "@/helpers/api/bonuses/calculations/types";
import { IHeaderItem } from "@/packages/stickyTable/StickyTable";
import { StyledName } from "../BonusesTableAdapter/index.styles";
import { t } from "i18next";

interface IGetBonusesTableHeaders {
  planPercent: string;
}

const separateWithSpaces = (value: string) => {
  const decimalParts = value.split(".");
  return `${decimalParts[0].replace(/.{3}/g, "$& ").trim()}${
    decimalParts[1] ? `.${decimalParts[1]}` : ""
  }`;
};

export const getBonusesTableHeaders = ({
  planPercent,
}: IGetBonusesTableHeaders): IHeaderItem<TBonusesDto>[] => {
  return [
    {
      Header: t("bonuses.calculations.dealerName") || "",
      accessor: "dealerName",
      sticky: "left",
      defaultCanSort: true,
      isSearchable: true,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledName>
            {`${original.mobisCode} ${original.dealerName}`}
          </StyledName>
        );
      },
    },
    {
      Header: t("bonuses.calculations.managerRegion") || "",
      accessor: "manager",
      sticky: "left",
    },

    {
      Header: t("bonuses.calculations.bonus") || "",
      accessor: "bonus",
      tip: "Bonus, Rub",
      defaultCanSort: true,

      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.factorZ") || "",
      accessor: "coefficientZ",
      tip: "Z Factor",
      width: 100,
    },
    {
      Header: t("bonuses.calculations.bonusPercent") || "",
      accessor: "bonusPercent",
      tip: "Bonus %",
      width: 100,

      Cell: ({ row: { original }, value }) => {
        return `${value ? parseInt(value) : ""}%`;
      },
    },
    {
      Header:
        `${planPercent ? parseInt(planPercent) : ""}${t(
          "bonuses.calculations.planPercent"
        )}` || "",
      accessor: "premiumRatePlan",
      tip: `${planPercent ? parseInt(planPercent) : ""}% Plan`,
      width: 100,
      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.bonusBase") || "",
      accessor: "bonusBase",
      tip: "Final Bonus Turnover",
      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.sparesPlan") || "",
      accessor: "sparesPlan",
      width: 100,
      tip: "Spare Parts Plan",
      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.sparesFact") || "",
      accessor: "sparesFact",
      tip: "Spare Parts Sales",
      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },

    {
      Header: t("bonuses.calculations.sparesPlanAchievement") || "",
      accessor: "sparesPlanAchievement",
      tip: "Parts Plan Achievement %",
      width: 250,
      Cell: ({ row: { original }, value }) => {
        return `${value ? parseInt(value) : ""}%`;
      },
    },
    {
      Header: t("bonuses.calculations.accessoriesPlanAchievement") || "",
      accessor: "accessoriesPlanAchievement",
      tip: "Accessory Plan Achievement %",
      width: 250,

      Cell: ({ row: { original }, value }) => {
        return `${value ? parseInt(value) : ""}%`;
      },
    },
    {
      Header: t("bonuses.calculations.planSum") || "",
      accessor: "planSum",
      tip: "Plan",
      width: 350,

      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.factSum") || "",
      accessor: "factSum",
      tip: "Total Turnover",
      width: 450,

      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
    {
      Header: t("bonuses.calculations.totalPlanAchievement") || "",
      accessor: "totalPlanAchievement",
      tip: "Plan Achievement %",
      width: 450,

      Cell: ({ row: { original }, value }) => {
        return `${value ? parseInt(value) : ""}%`;
      },
    },
    {
      Header: t("bonuses.calculations.warrantyRefund") || "",
      accessor: "warrantyRefund",
      tip: "Warranty",
      width: 250,

      Cell: ({ row: { original }, value }) => {
        return value ? separateWithSpaces(value) : "";
      },
    },
  ];
};
