import React from "react";
import { useMoveParam } from "@/helpers/api/audit-parametrs/hooks";
import { DraggableTable } from "@/packages/draggableTable/DraggableTable";

import { getDefaultAuditTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { useNotification } from "@/hooks/useNotification";

interface IAuditParametrsTable {
  data?: [];
  level: string;
  onDeleteParametr: (id: string) => void;
  onEditClick: (id: string) => void;
  onPhotoParamEdit: (id: string, value: boolean) => void;
}
export const AuditParametersTable: React.FC<IAuditParametrsTable> = (props) => {
  const { data, onEditClick, onDeleteParametr, onPhotoParamEdit, level } =
    props;

  const { mutate: move } = useMoveParam();
  const { createNotificationError } = useNotification();
  const headers = getDefaultAuditTableHeaders({
    level,
    onDeleteClick: onDeleteParametr,
    onEditClick,
    onPhotoParamEdit,
  });

  const handleMove = ({ id, index }) => {
    move({
      id,
      patch: { order: index },
      onSuccess: () => ({}),
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  return (
    <>
      <StyledTableSimple>
        <DraggableTable
          headers={headers || []}
          data={data || []}
          onDragEnd={handleMove}
          isDndProviderImplemented
          defaultColumn={{
            minWidth: 100,
            width: 105,
            maxWidth: 300,
          }}
        />
      </StyledTableSimple>
    </>
  );
};
