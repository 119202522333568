import { colors } from "@/helpers";
import styled, { css } from "styled-components";

export const WrappedTable = styled.div`
  overflow-x: auto;
  min-width: 100% !important;
  border: 1px solid #dbdbdb;
  border-top: none;
  border-radius: 0 0 12px 12px;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

interface IStyledHeaderCell {
  isSortable: boolean;
}

export const StyledHead = styled.tr<{ isOrdinary?: boolean }>`
  background-color: ${colors.gray30};
  background-color: ${({ isOrdinary }) =>
    !isOrdinary ? colors.gray30 : colors.white};

  width: 100%;
`;

export const StyledHeadCell = styled.th<IStyledHeaderCell>`
  text-align: left;
  padding: 0;
  &:first-of-type {
    padding-left: 22px;
  }
  &:last-of-type {
    padding-right: 40px;
  }
  padding-left: 16px;
  height: 70px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "auto")};
`;

export const StyledCellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDataCell = styled.div<{ textML?: number }>`
  margin-left: ${({ textML }) => (textML ? textML : 0)}px;
`;

export const StyledTableRow = styled.tr<{
  isChild?: boolean;
  isSecondChild?: boolean;
}>`
  position: relative;
`;

export const StyledNavigation = styled.div<{
  marginLeft: number;
  isEnd?: boolean;
}>`
  position: absolute;

  width: 2px;
  background-color: ${colors.brand};
  top: 0;
  bottom: ${({ isEnd }) => (isEnd ? "calc(50% + 12px)" : 0)};
  left: ${({ marginLeft }) => 33 + marginLeft}px;
`;

export const StyledNavigationCurve = styled.div<{ marginLeft?: number }>`
  position: absolute;
  left: ${({ marginLeft }) => 31.5 + (marginLeft || 0)}px;
  top: 50%;
  transform: translateY(-100%);
`;

export const StyledOrdinaryOpen = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledTableDataCell = styled.td<{
  isHidden?: boolean;
  hideBorder?: boolean;
  isSmall?: boolean;
}>`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;

  padding: 22px 0;

  ${({ isSmall }) =>
    isSmall &&
    css`
      padding: 18px 0;
    `}

  padding-left: 22px;
  &:first-of-type {
    padding-left: 22px;
  }
  &:last-of-type {
    padding-right: 40px;
  }

  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border-bottom: 1px solid ${colors.gray20};
    `}

  display: ${({ isHidden }) => (isHidden ? "none" : "")};
`;

export const StyledSearchButton = styled.div`
  margin-right: 10px;
`;

export const StyledSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const StyledTableRowAdd = styled.td<{
  hasBorder?: boolean;
  isBottom?: boolean;
}>`
  position: relative;
  padding: 20px 0;
  padding-left: 22px;
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-bottom: 1px solid ${colors.gray20};
    `}
  ${({ isBottom }) =>
    isBottom &&
    css`
      background-color: #eeeff2;
      border-radius: 0px 0px 12px 12px;
    `}
`;

export const StyledAdd = styled.div<{ marginLeft: number }>`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  display: flex;
  align-items: center;
`;
