import { colors } from "@/helpers";
import styled, { css } from "styled-components";

interface ICustomInput {
  isDisabled: boolean;
  isOpen: boolean;
  isError: boolean;
  isReadOnly: boolean;
}

const StyledCustomInput = styled.input<ICustomInput>`
  padding: 6px 44px;
  height: 32px;
  width: 100%;
  border: 1px solid
    ${({ isOpen, isError }) =>
      isError ? colors.red : isOpen ? colors.brand : colors.gray300};
  background-color: ${colors.white};
  border-radius: 4px;
  transition: 0.2s;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: ${colors.black};
  outline: none;
  &::placeholder {
    color: ${colors.black};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${colors.gray10};
      color: ${colors.gray200};
      pointer-events: none;
      cursor: not-allowed;
      border-color: #d9d9d9;
    `}

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      color: ${colors.gray5};
      cursor: default;
      border: 1px solid ${colors.gray6};
    `}
`;

interface IStyledDatepicker {
  isOpen: boolean;
  isBlock: boolean;
  isDisabled: boolean;
}

const StyledDatepicker = styled.div<IStyledDatepicker>`
  width: ${({ isBlock }) => (isBlock ? "auto" : "172px")};
  position: relative;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}

  .react-datepicker-popper {
    z-index: 22;
  }

  .react-datepicker {
    background-color: ${colors.white};
    border-radius: 4px;
    overflow: hidden;
    padding: 16px;
    border: none;
    &-wrapper {
      width: 100%;
    }

    &__current-month {
      display: none;
    }
    &__navigation {
      &--next,
      &--previous {
        display: none;
      }
      width: 16px;
      height: 16px;
      &--years-upcoming {
        position: relative;
        margin: 0 auto;
        top: 0;
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #6d6e71;
          transform: rotate(0) translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }
      }
      &--years-previous {
        position: relative;
        margin: 0 auto;
        top: 0;
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #6d6e71;
          transform: rotate(0) translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }
      }
    }

    &__month {
      &-option {
        text-transform: capitalize;
        padding: 8px 12px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        &--selected {
          display: none;
          &_month {
            font-weight: 400;
            background-color: ${colors.gray200};
          }
        }
        &:hover {
          background-color: ${colors.gray100};
        }
      }
      &-dropdown {
        text-align: left;
        width: max-content;
        left: 0;
        background-color: ${colors.white};
        border: none;
        box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
        border-radius: 8px;
        height: 200px;
        overflow: scroll;
        &-container {
          margin: 0 30px 0 8px;
        }
      }
    }

    &__year {
      &-option {
        padding: 8px 12px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        &--selected {
          display: none;
          &_year {
            font-weight: 400;
            background-color: ${colors.gray200};
          }
        }
        &:hover {
          background-color: ${colors.gray100};
        }
      }

      &-dropdown {
        left: 78px;
        width: 100px;
        background-color: ${colors.white};
        border: none;
        box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
        border-radius: 8px;
        &-container {
          margin: 0 30px 0 8px;
        }

        max-height: 200px;
        overflow: scroll;
      }
    }

    &__year-read-view {
      visibility: visible !important;
    }
    &__month-read-view {
      border: none;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      visibility: visible !important;
      &--down-arrow {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #6d6e71;
        transform: rotate(0) translateY(-50%);
        top: 50%;
        border-radius: 10px;
      }
    }
    &__year-read-view {
      border: none;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      &--down-arrow {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #6d6e71;
        transform: rotate(0) translateY(-50%);
        top: 50%;
        border-radius: 10px;
      }
    }
    &__header {
      background-color: ${colors.white};
      padding: 0;
      border-radius: 0;
      border-bottom: none;
      &__dropdown {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 14px;
      }
    }

    &__triangle {
      display: none;
    }

    &__close-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      background-color: ${colors.white};
      transition: 0.2s;
      padding: 0;
      border-radius: 50%;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      ${({ isDisabled }) =>
        isDisabled &&
        css`
          display: none;
        `}

      &:hover {
        background-color: ${colors.gray100};
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        background: ${colors.black} none;
        width: 10px;
        height: 1px;
        border-radius: 2px;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: left top;
        padding: 0;
      }

      &::before {
        transform: rotate(-45deg) translate(50%, -50%);
        right: 50%;
        left: unset;
        transform-origin: right top;
      }
    }

    &__day {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 32px;
      width: 32px;
      height: 32px;
      transition: 0.2s;
      color: ${colors.black};
      margin: 0;
      &:not(:last-child) {
        margin-right: 4px;
      }

      &:hover {
        background-color: rgba(72, 123, 255, 0.2);
      }
      &--disabled {
        cursor: not-allowed;
        color: ${colors.gray300};
        &:hover {
          background-color: transparent;
        }
      }

      &--outside-month {
        color: ${colors.gray20};
      }

      &--selected {
        background-color: rgba(72, 123, 255, 0.2);
      }

      &--keyboard-selected {
        border-color: transparent;
        background-color: inherit;
      }

      &-names {
        margin-bottom: 4px;
      }

      &-name {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        width: 32px;
        height: 24px;
        text-align: center;
        text-transform: capitalize;
        margin: 0;
        color: ${colors.black};
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
      &--today {
        border-radius: 4px;
        font-weight: 600;
      }
    }

    &__month {
      margin: 0;
      background-color: ${colors.white};
    }
    &__week {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &__input-container {
      display: flex;
      align-items: center;
      position: relative;
      border: none;
    }
  }
`;

interface IStyledIconProps {
  isOpen: boolean;
  isDisabled: boolean;
  readOnly?: boolean;
}

const StyledIcon = styled.div<IStyledIconProps>`
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;

      svg {
        fill: ${colors.gray300};
      }
    `}

  svg {
    transition: 0.2s;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          svg {
            fill: ${colors.black};
          }
        `
      : css`
          &:hover {
            svg {
              fill: ${colors.black};
            }
          }
        `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      svg {
        fill: ${colors.gray5};
        cursor: default;
      }
    `}
`;

interface IStyledLabelProps {
  isDisabled: boolean;
}

const StyledLabel = styled.div<IStyledLabelProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${colors.black};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #e4e7ec;
    `}
`;

const StyledDescription = styled.div<IStyledLabelProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${colors.gray500};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #e4e7ec;
    `}
`;
const StyledError = styled.div`
  position: absolute;
  top: 54px;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.red};
`;

const StyledDatePickerHeader = styled.div`
  display: flex;
  gap: 5px;
  padding: 0 10px;
`;

const StyledSelect = styled.select`
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray5};
  cursor: pointer;
`;

const StyledDecreaseButton = styled.button`
  font-size: 26px;
  color: ${colors.gray5};
  cursor: pointer;
  margin-right: auto;
`;

const StyledIncreaseButton = styled.button`
  font-size: 26px;
  color: ${colors.gray5};
  cursor: pointer;
  margin-left: auto;
`;

export {
  StyledDescription,
  StyledDatepicker,
  StyledCustomInput,
  StyledIcon,
  StyledLabel,
  StyledError,
  StyledDatePickerHeader,
  StyledSelect,
  StyledDecreaseButton,
  StyledIncreaseButton,
};
