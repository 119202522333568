export const themes = {
  h5: {
    mobile: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  h4: {
    mobile: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  h3: {
    mobile: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  h2: {
    mobile: {
      fontSize: "25px",
      lineHeight: "34px",
    },
  },
  h1: {
    mobile: {
      fontSize: "32px",
      lineHeight: "32px",
    },
  },
};
