import { FC, ReactNode } from "react";
import { usePermission } from "@/hooks/usePermission";
import { TPermissionAction } from "@/helpers/interfaces";
import { TPermissionResourcesName } from "@/helpers/api/eventLogs/types";

interface IPermissionsAdapter {
  children: ReactNode;
  resourceName:
    | TPermissionResourcesName
    | TPermissionResourcesName[]
    | "hide-section";
  resourceAction: TPermissionAction;
  currentGroupId?: string;
  loader?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  fallback?: () => ReactNode;
  rulesCallback?: (hasAction: boolean) => boolean;
}

const PermissionsAdapter: FC<IPermissionsAdapter> = (props) => {
  const {
    children,
    resourceName,
    resourceAction,
    currentGroupId,
    loader,
    isLoading,
    disabled,
    rulesCallback,
    fallback,
  } = props;
  const { hasAccess, isProfileLoading } = usePermission(
    resourceName,
    resourceAction,
    currentGroupId,
    rulesCallback
  );
  const hasLoading = loader && (isLoading || isProfileLoading);

  return (
    <>
      {hasLoading ? (
        <>{loader}</>
      ) : hasAccess || disabled ? (
        <>{children}</>
      ) : fallback ? (
        fallback()
      ) : (
        <></>
      )}
    </>
  );
};

export { PermissionsAdapter };
