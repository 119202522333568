export enum TransportComNameFields {
  VIN = "vin",
  CODE_CASE = "codeCase",
  PLACE_ACCEPTENCE = "placeAcceptence",
  DATE_ACCEPTENCE = "dateAcceptence",
  DEALED_ID = "dealerId",
  OUPTS = "oupts",
  TTH_CMR = "tthCmr",
  VIN_PLATE = "vinPlate",
  GENERAL_VIEW = "generalView",
  VIEW_DETAIL = "viewDetail",
  PHOTO_DEFECT = "photoDefect",
  OTHER = "other",
  KIA_SNG = "kiaSng",
  OPEN_FOR_VIEWING = "openForViewing",
  IS_ELLADA = 'isEllada'
}

export enum TransportComPath {
  OUPTS = "transport-claims/oupts",
  TTH_CMR = "transport-claims/tth",
  VIN_PLATE = "transport-claims/vin",
  GENERAL_VIEW = "transport-claims/vehilce",
  VIEW_DETAIL = "transport-claims/detail",
  PHOTO_DEFECT = "transport-claims/defect",
  OTHER = "transport-claims/other",
  KIA_SNG = "transport-claims/kia",
}
