import { RoleSelectAdapter } from "@/adapters/selects/RoleSelectAdapter";
import { InputField } from "@/packages/formElements/fields/InputField";
import { SelectBooleanField } from "@/packages/formElements/fields/SelectBooleanField";
import React from "react";
import {
  IRegistryEmployeeFilterFormValues,
  useRegistryEmployeesFilterForm,
} from "./hooks";
import { StyledFormLayout, StyledRegistryFilterWrapper } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface IEmployeeFilter {
  values: IRegistryEmployeeFilterFormValues;
  onChange: (values: IRegistryEmployeeFilterFormValues) => void;
}

export const EmployeeFilter: React.FC<IEmployeeFilter> = (props) => {
  const { values, onChange } = props;

  const { t } = useTranslation();

  const { onChangeFieldHandlerCreator } = useRegistryEmployeesFilterForm({
    values,
    onChange,
  });

  return (
    <StyledRegistryFilterWrapper>
      <StyledFormLayout>
        <InputField
          value={values.name}
          onChange={onChangeFieldHandlerCreator("name")}
          label={t("registers.registersDC.lastName") || ""}
          placeholder={t("registers.registersDC.lastName") || ""}
          allowClear
        />

        <RoleSelectAdapter
          value={values.roleId}
          onChange={onChangeFieldHandlerCreator("roleId")}
          label={t("registers.registersDC.role") || ""}
          placeholder={t("registers.registersDC.statusPlaceholder") || ""}
          allowClear
        />

        <SelectBooleanField
          value={values.status}
          onChange={onChangeFieldHandlerCreator("status")}
          label={t("registers.registersDC.activeStatus") || ""}
          placeholder={t("registers.registersDC.statusPlaceholder") || ""}
          trueLabel={t("registers.registersDC.trueLabel") || ""}
          falseLabel={t("registers.registersDC.falseLabel") || ""}
          allowClear
        />
      </StyledFormLayout>
    </StyledRegistryFilterWrapper>
  );
};
