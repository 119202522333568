import { FC } from "react";

import { IFileAndImageDropwallField, UploadImageAndFileDropzone, } from "@/packages/UploadImageAndFileDropzone";
import { Field } from "../Field";
import { StyledFieldControlContainer } from "./index.styles";

export interface IFileDropzoneField extends IFileAndImageDropwallField {
  label?: string;
  error?: string;
  description?: string;
  isAllowFilesUpload?: boolean;
  isDisabled?: boolean;
  allowedTypesProps?: Array<string>;
  withCommentField?: boolean;
  fieldName?: string;
  isMultiple?: boolean;
}

export const FileDropzoneField: FC<IFileDropzoneField> = (props) => {
  const {
    label,
    error,
    description,
    isAllowFilesUpload,
    onChange,
    value,
    isDisabled,
    fieldName,
    ...restProps
  } = props;

  return (
    <Field>
      <Field.Label>{label}</Field.Label>

      <StyledFieldControlContainer>
        <UploadImageAndFileDropzone
          isDisabled={isDisabled}
          onChange={onChange}
          {...restProps}
          fieldName={fieldName}
          value={value}
        />
      </StyledFieldControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
