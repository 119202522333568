import * as yup from "yup";

export interface IAuditBatchResponseToMutate {
  children?: any[];
  id: string;
  title: string;
  order: number;
  parentId: string | null;
  level?: string;
  isChecked?: boolean;
  depth: number;
}

export function findNodeById(nodes: IAuditBatchResponseToMutate[], id: string): IAuditBatchResponseToMutate | null {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    } else {
      if (!node.children) {
        continue;
      }
      const foundInChildren = findNodeById(node.children, id);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  return null;
}

export function getMutatedParentDndSectionsArray(
  nodes: IAuditBatchResponseToMutate[],
  id: string,
  nodeReference: IAuditBatchResponseToMutate,
  onActionHandler: (
    event: "edit" | "add" | "delete",
    deleteSection?: any
  ) => void,
): IAuditBatchResponseToMutate[] | null {
  const array = JSON.parse(JSON.stringify(nodes));
  for (const node of array) {
    if (node.id === id) {
      node.children = getMutatedChildren(
        node,
        nodeReference.children,
        onActionHandler)
      node.title = nodeReference.title;
      onActionHandler("edit", node);
      return array;
    } else {
      if (!node.children) {
        continue;
      }
      const foundInChildren = findNodeById(node.children, id);
      if (foundInChildren) {
        foundInChildren.children = getMutatedChildren(
          foundInChildren,
          nodeReference.children,
          onActionHandler);
        foundInChildren.title = nodeReference.title;
        onActionHandler("edit", foundInChildren);
        return array;
      }
    }
  }
  return null;
}

const getMutatedChildren = (
  nodeParent: IAuditBatchResponseToMutate,
  childrenArray: IAuditBatchResponseToMutate[] | undefined,
  onActionHandler: (
    event: "edit" | "add" | "delete",
    deleteSection?: any
  ) => void,
): IAuditBatchResponseToMutate[] | undefined => {
  if (!childrenArray) {
    return undefined;
  }
  const array = JSON.parse(JSON.stringify(childrenArray));

  for (const node of array) {
    node.parentId = nodeParent.id;
    node.level = `${nodeParent.level}.${node.order + 1}`;
    if (!node.children) {
      onActionHandler('edit', node);
      continue;
    }
    node.children = getMutatedChildren(node, node.children, onActionHandler);
    onActionHandler('edit', node);
    console.log('actionHandler getMutatedChildren',node);
  }

  return array;
}

export function isParent(parent: IAuditBatchResponseToMutate, childId: string) {
  if (!parent.children) {
    return false
  }
  for (const node of parent.children) {
    if (node.id === childId) {
      return true;
    } else {
      if (!node.children) {
        continue;
      }
      const foundInChildren = findNodeById(node.children, childId);
      if (foundInChildren) {
        return true;
      }
    }
  }
  return false;
}


export const removeIdDeep = (
  data: IAuditBatchResponseToMutate[],
  idToRemove: string
) => {
  const filtered = data.filter((entry) => entry.id !== idToRemove);
  return filtered.map((entry) => {
    if (!entry.children) return entry;
    return { ...entry, children: removeIdDeep(entry.children, idToRemove) };
  });
};

export const batchIPFieldsSchema = yup.array().of(
  yup.object({
    event: yup.mixed().oneOf(["add", "edit", "delete"]),
    payload: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        title: yup
          .string()
          .min(1, "Название не должно быть пустое")
          .max(30, "Максимально допустимое количество символов - 30"),
        parentId: yup.string(),
        order: yup.number(),
      })
    ),
  })
);

export const mutateArrOfObj = (arrObj: IAuditBatchResponseToMutate[]) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  return res.map((item, index) => recursive(item, 0, `${index + 1}`));
};

export const mutateArrOfObjDTO = (arrObj: IAuditBatchResponseToMutate[]) => {
  if (!arrObj) {
    return arrObj;
  }

  const transform1 = ({ id, name, order, parrent_id, children }) => {
    return children
      ? [{ id, name, order, parrent_id }, ...transformAll(children)]
      : [{ id, name, order, parrent_id }];
  };

  const transformAll = (children) => children.flatMap((c) => transform1(c));

  // Удаление дубликатов при рекурсии
  return transformAll(arrObj).filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      transformAll(arrObj).findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};
