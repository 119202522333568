import { FormProvider } from "react-hook-form";
import {
  TOnSubmitDealerBonusesFilterFormCb,
  useDealerBonusesFilterForm,
} from "./hooks";
import { StyledDealerBonusesFilterWrapper } from "./index.styles";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@/packages/button/Button";
import styled from "styled-components";
import { Icon } from "@/packages/icon/Icon";
import { MOCK_QUARTER_LIST } from "@/pages/BonusesReportsImport";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { FormSelectWithPrefixIcon } from "@/packages/formElements/formFields/FormSelectWithPrefixIcon";
import { getYearsList } from "@/helpers/date";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
  height: 32px;
`;

const StyledQuarterFormSelect = styled(FormSelectWithPrefixIcon)`
  width: 139px;
`;

const StyledYearFormSelect = styled(FormSelectWithPrefixIcon)`
  width: 109px;
`;

interface IFiltersProps {
  isFactorZ?: boolean;
  year: string | number;
  quarter: string | number;
  onFiltersChange: TOnSubmitDealerBonusesFilterFormCb;
}

export const DealerBonusesFilter: React.FC<IFiltersProps> = (props) => {
  const { t } = useTranslation();

  const { year, quarter, isFactorZ, onFiltersChange } = props;
  const [searchValue, setSearchValue] = useState("");
  const [defaultValues, setDefaultValues] = useState({
    year: year.toString(),
    quarter: quarter.toString() + t("bonuses.calculations.quarter") || "",
  });
  useEffect(() => {
    quarter &&
      setValue(
        "quarter",
        quarter.toString() + t("bonuses.calculations.quarter") || ""
      );
    year && setValue("year", year.toString());
  }, [year, quarter]);

  const handleFiltersChange = (value) => {
    onFiltersChange({
      ...value,
      dealerSearch: searchValue,
      quarter: !isNaN(+value?.quarter) ? value.quarter : value?.quarter[0],
    });
  };
  const { form, submit, reset, setValue } = useDealerBonusesFilterForm(
    handleFiltersChange,
    defaultValues
  );

  const preparedYearsList = useMemo(() => getYearsList({ yearsCount: 20 }), []);

  const preparedQuarters = MOCK_QUARTER_LIST.map((item) => ({
    ...item,
    label: `${item.label} ${t("bonuses.calculations.quarter") || ""}`,
  }));

  return (
    <StyledDealerBonusesFilterWrapper>
      <FormProvider {...form}>
        {!isFactorZ ? (
          <>
            <Search
              value={searchValue}
              onChange={(value) => setSearchValue(value || "")}
            />
            <FormSelectWithPrefixIcon
              prefixIcon={<Icon size={16} name="profile" />}
              name="managerId"
              placeholder={t("bonuses.calculations.manager") || ""}
              options={[]}
            />
          </>
        ) : (
          <></>
        )}

        <StyledYearFormSelect
          prefixIcon={<Icon size={16} name="calendar-16" />}
          name="year"
          placeholder={t("bonuses.calculations.year") || ""}
          options={preparedYearsList || []}
        />
        <StyledQuarterFormSelect
          prefixIcon={<Icon size={16} name="calendar-16" />}
          name="quarter"
          placeholder={t("bonuses.calculations.quarter_L") || ""}
          options={preparedQuarters || []}
        />
        <StyledButton size="middle" onClick={submit}>
          {t("bonuses.calculations.find") || ""}
        </StyledButton>
      </FormProvider>
    </StyledDealerBonusesFilterWrapper>
  );
};
