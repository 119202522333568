import { TGenerationRecord } from "@/helpers/api/generations/types";
import { useForm } from "@/hooks/useForm";
import { useEffect } from "react";
import {
  IGenerationFormDTOValues,
  IGenerationFormValues,
  IGenerationSuccessFormValues,
} from "../GenerationFields/types";
import {
  DEFAULT_GENERATION_FORM_VALUES,
  generationFormSchema,
  generationFormValuesToDTO,
  generationDTOToFormValues,
} from "../GenerationFields/utils";

export type TOnSubmitCreateGenerationFormCallback = (
  values: IGenerationFormDTOValues
) => void;

export const useEditGenerationForm = (options: {
  editableRecord?: TGenerationRecord;
  onSubmit: TOnSubmitCreateGenerationFormCallback;
}) => {
  const { editableRecord, onSubmit } = options;
  const onSubmitHandler = (values) => {
    const dtoValues = generationFormValuesToDTO(values);
    onSubmit(dtoValues);
  };

  const result = useForm<IGenerationFormValues, IGenerationSuccessFormValues>({
    validateFormSchema: generationFormSchema(),
    defaultValues: DEFAULT_GENERATION_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(generationDTOToFormValues(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
