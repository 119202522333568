import styled from "styled-components";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledTableColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.stroke4HeaderTable};
`;

export const StyledTableHeadLeftWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  padding-left: var(--padding-left);

  background-color: ${colors.gray30};
`;

export const StyledInfoBlockList = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

export const StyledInfoBlockItem = styled.li`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 330px;
  align-items: center;
  padding-left: var(--padding-left);

  &:not(:last-of-type) {
    margin-bottom: var(--margin-bottom);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: ${colors.stroke4HeaderTable};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`;

export const StyledInfoBlockSubList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoBlockSubItem = styled.li`
  box-sizing: border-box;
  padding: 7px 8.5px;
  cursor: pointer;

  &:nth-of-type(2n) {
    background-color: ${colors.gray40};
  }
`;

export const StyledInfoBlockSubItemParagraph = styled(Paragraph)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
