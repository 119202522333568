import React from "react";
import { StyledCardWrapper } from "./index.styles";

export type CardProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const Card: React.FC<CardProps> = (props) => {
  const { className, style, children } = props;

  return (
    <StyledCardWrapper className={className} style={style}>
      {children}
    </StyledCardWrapper>
  );
};
