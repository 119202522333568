import styled from "styled-components";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMPVWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledMPVInputField = styled(FormInputField)`
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  margin-top: 5px;
  width: 50%;
`;
