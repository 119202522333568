import { useGetDealersReportGraph } from "@/helpers/api/reports/hooks";
import { TGetGraphRequest } from "@/helpers/api/reports/types";
import { usePagination } from "@/hooks/usePagination";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { StyledNoData } from "../SellsFilter/index.styles";
import { t } from "i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      align: "start" as const,
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
};

const data = ({
  labels,
  facts,
  plan,
}: {
  labels: number[];
  facts: number[];
  plan: number[];
}) => ({
  labels,
  datasets: [
    {
      label: t("bonuses.reportsView.sellFact") || "",
      data: facts,
      borderColor: "#F3722C",
      backgroundColor: "#F3722C",
      borderWidth: 3,
    },
    {
      label: t("bonuses.reportsView.sellPlan") || "",
      data: plan,
      borderColor: "#90BE6D",
      backgroundColor: "#90BE6D",
      borderWidth: 3,
    },
  ],
});

interface IDealerSellsChart {
  requestParams: TGetGraphRequest;
  noDataText: string;
}

const createNumbersArray = (start: number, step: number, end: number) => {
  const array: number[] = [];
  do {
    array.push(start + array.length * step);
  } while (array.length < end);
  return array;
};

export const DealerSellsChart: React.FC<IDealerSellsChart> = (props) => {
  const { requestParams, noDataText } = props;

  const { data: reportGraph, isLoading } =
    useGetDealersReportGraph(requestParams);

  const grids = useMemo(() => {
    switch (requestParams.groupBy) {
      case "quarter": {
        return createNumbersArray(1, 1, 4);
      }
      case "month": {
        return createNumbersArray(1, 1, 12);
      }
      case "day": {
        return createNumbersArray(1, 1, 31);
      }
      case "year": {
        // @ts-ignore опечатка на беке
        return reportGraph?.data?.plans.map((item) => item.date);
      }
    }
  }, [requestParams, reportGraph]);

  return !isLoading ? (
    // @ts-ignore опечатка на беке
    !reportGraph?.data?.facts?.length && !reportGraph?.data?.plans?.length ? (
      <StyledNoData>
        <Paragraph size={16}>{noDataText}</Paragraph>
      </StyledNoData>
    ) : (
      <Line
        options={options}
        data={data({
          labels: grids || [],
          // @ts-ignore
          plan: reportGraph?.data?.plans.map((item) => item.value),
          // @ts-ignore
          facts: reportGraph?.data?.facts.map((item) => item.value),
        })}
      />
    )
  ) : (
    <Loader size={48} />
  );
};
