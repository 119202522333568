import styled from "styled-components";

export const StyledDataTab = styled.div`
  margin-top: 24px;
`;

export const StyledFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 64px;
`;
