import { Icon } from "@/packages/icon/Icon";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const StyledPhotoInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMessage = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
`;

export const PhotoInfo: React.FC<{ isRequired: boolean; isUpload: boolean }> = (
  props
) => {
  const { isRequired, isUpload } = props;
  const { t } = useTranslation();
  if (isUpload) {
    return (
      <StyledPhotoInfoBlock>
        <StyledMessage background="#B5D880" color="#2F4A04">
          {t("audits.list.downloaded") || ""}
        </StyledMessage>
      </StyledPhotoInfoBlock>
    );
  } else if (!!isRequired && !isUpload) {
    return (
      <StyledPhotoInfoBlock>
        <Icon name="info" color="yellowStatusBg" size={16} />
        <StyledMessage background="#F5DA69" color="#B06E0A">
          {t("audits.list.downloadIn") || ""} <br />{" "}
          {t("audits.list.inComm") || ""}
        </StyledMessage>
      </StyledPhotoInfoBlock>
    );
  } else {
    return (
      <StyledPhotoInfoBlock>
        <StyledMessage background="#7B7D81" color="#DBDCE0">
          {t("audits.list.option") || ""}
        </StyledMessage>
      </StyledPhotoInfoBlock>
    );
  }
};
