import React, { useState } from "react";
import {
  ITransmissionTypeTab,
  TransmissionTypeTab,
} from "@/components/parts/transmission/TransmissionTypeTab";
import { usePagination } from "@/hooks/usePagination";
import { useGetTransmissionList } from "@/helpers/api/transmissions/hooks";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { useDebounce } from "use-debounce";

export interface ITransmissionTypeTabAdapter
  extends Omit<
    ITransmissionTypeTab,
    "currentPage" | "onChangePage" | "totalItems" | "items" | "onSort"
  > {}

export const TransmissionTypeTabAdapter: React.FC<
  ITransmissionTypeTabAdapter
> = (props) => {
  const [requestParams, setRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const [searchValueBody, setSearchValueBody] = useState<string>("");
  const [debouncedSearchValueBody] = useDebounce(searchValueBody, 400);

  const { paginationParams, setPage } = usePagination();
  const { data } = useGetTransmissionList({
    ...paginationParams,
    ...requestParams,
    name: debouncedSearchValueBody,
  });

  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      orderBy: order ? order.toUpperCase() : null,
      sortBy: order ? accessor.id : null,
    }));
  };

  const handleSearch = (key: string, value?: string) => {
    setSearchValueBody(value || "");
  };

  const items = data?.data.data || [];
  const totalItems = data?.data.meta?.itemCount || 0;
  return (
    <TransmissionTypeTab
      currentPage={paginationParams.page}
      onChangePage={setPage}
      items={items}
      totalItems={totalItems}
      onSort={handleSort}
      {...props}
      handleSearch={handleSearch}
    />
  );
};
