import { colors } from "@/helpers";
import styled, { css } from "styled-components";

export const StyledSellsFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.gray20};
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledRadioItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-radius: 4px;
  border: 1px solid ${({ isSelected }) => (isSelected ? "#487bff" : "#ABACAE")};
  background-color: ${({ isSelected }) =>
    isSelected ? "rgba(132, 166, 254, 0.3)" : "#FFFFFF"};
`;

export const StyledTextBlock = styled.div`
  display: grid;
  gap: 6px;
  margin-left: 16px;
`;

export const StyledFilter = styled.div<{ isSelected: boolean }>`
  margin-left: auto;
  width: 395px;

  position: relative;
  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:before {
        content: "";
        cursor: not-allowed;
        position: absolute;
        inset: 0;
        background-color: white;
        opacity: 0.5;
        pointer-events: none;
        z-index: 20;
      }
    `}
`;

export const RadioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 44px;
`;

export const StyledFormSelect = styled.div`
  width: 300px;
`;

export const StyledSelect = styled.div`
  width: "fit-content";
  margin-top: 16px;
`;

export const StyledNoData = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
