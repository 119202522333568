import styled from "styled-components";

export const StyledFlexStatusFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .react-datepicker-popper {
    z-index: 22;
  }
`;

export const StyledFormLayout = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const StyledFirstFormRow = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

export const StyledLastFormRow = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  grid-template-columns: 200px 200px 200px;
`;

export const StyledDatepickerWrapper = styled.div`
  display: grid;
  gap: 10px;
  align-items: end;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

export const StyledDatepickerDash = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonsContainer = styled.div`
  margin-top: 26px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 16px;
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  width: 150px;
`;

export const StyledDaysCount = styled.div`
    padding-bottom: 3px;
`;
