import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { FC, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useViewerWidth } from "./hooks";
import { StyledPdfViewer } from "./index.styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPremiumPdfViewer {
  fileUrl: string;
}

export const PremiumPdfViewer: FC<IPremiumPdfViewer> = (props) => {
  const { fileUrl } = props;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const pageWidth = useViewerWidth();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <StyledPdfViewer>
      <Document
        file={`https://ark.onudu.com/${fileUrl}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loader size={48} />}
      >
        <Page pageNumber={pageNumber} width={pageWidth} />
      </Document>
      <Pagination
        current={pageNumber}
        onChange={setPageNumber}
        pageSize={1}
        total={numPages}
      />
    </StyledPdfViewer>
  );
};
