import { TFileRecord } from "@/helpers/api/files/types";
import { useGetMonitoringQualityNumber } from "@/helpers/api/monitoringQuality/hooks";
import { TMonitoringQualityRecord } from "@/helpers/api/monitoringQuality/types";
import { useForm } from "@/hooks/useForm";
import { TFunction } from "i18next";
import { useEffect } from "react";
import { DEFAULT_MONITORING_DATA_FORM_VALUES, monitoringFieldsSchema, } from "./utils";
import { MOSCOW } from "@/components/MonitoringQuality/MonitoringCreateData/MonitoringDataFields/types";

export const monitoringProgramToDTO = (values: any) => {
  const { fileIds, photoIds, cityIds } = values;
  console.log(values);
  return {
    ...values,
    cityIds: cityIds ? cityIds.filter(it => it !== MOSCOW) : [],
    fileIds: fileIds
      ? fileIds.map((file) => file.id)
      : [],
    photoIds: photoIds
      ? photoIds.map((file) => file.id)
      : [],
  };
};

export type TOnSubmitCreateMonitoringFormCallback = (values: any) => void;

export type IFileInfo = {
  value: TFileRecord | null;
};

export const useCreateMonitoringForm = (
  t: TFunction,
  onSubmit: TOnSubmitCreateMonitoringFormCallback
) => {
  const onSubmitHandler = (values: any) => {
    try {
      onSubmit(monitoringProgramToDTO(values));
    } catch (error) {
      console.log(values, error);
    }
  };

  const {
    data: numberOfProgramData,
    isLoading: isNumberLoading,
    isError: isNumberError,
  } = useGetMonitoringQualityNumber();
  //@ts-ignore
  const number = numberOfProgramData?.data.nextNum as string;

  const result = useForm<any>({
    // validateFormSchema: monitoringFieldsSchema(t),
    defaultValues: DEFAULT_MONITORING_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (number) {
      reset({ ...DEFAULT_MONITORING_DATA_FORM_VALUES, number });
    }
  }, [number, reset]);

  return { ...result, isNumberLoading, isNumberError };
};

const generateOptions = <T extends { id: string; name: string }>(
  dataOptions?: T[]
) => {
  return dataOptions?.map((option) => ({
    value: option.id,
    label: option?.name,
  }));
};

export const monitoringQualityFieldsAtEditDTO = (
  values: TMonitoringQualityRecord
) => {
  const {
    qualityMonitoringDealers,
    generations,
    parts,
    cities,
    carProductionDateFrom,
    carProductionDateTo,
    carSearchPeriodFrom,
    carSearchPeriodTo,
    dateOfTheStudyFrom,
    dateOfTheStudyTo,
    photos,
    files,
    ...restFields
  } = values;

  return {
    dealerIds: qualityMonitoringDealers?.map((option) => ({
      value: option.dealer.id,
      label: `${option.dealer.sapCode} / ${option.dealer.mobisCode} / ${option.dealer.merchantName}`,
    })),
    cityIds: generateOptions(cities),
    generationIds: generations?.map((option) => ({
      value: option.id,
      label: `${option?.model?.name} ${option?.name} ${
        option?.restylingName || ""
      }`,
    })),
    parts,
    carProductionDateFrom: new Date(carProductionDateFrom),
    carProductionDateTo: new Date(carProductionDateTo),
    carSearchPeriodFrom: new Date(carSearchPeriodFrom),
    carSearchPeriodTo: new Date(carSearchPeriodTo),
    dateOfTheStudyFrom: new Date(dateOfTheStudyFrom),
    dateOfTheStudyTo: new Date(dateOfTheStudyTo),
    photoIds: photos.length ? photos : [],
    fileIds: files.length ? files : [],
    ...restFields,
  };
};

export const monitoringQualityFieldsAtEditDTOToBack = (values: any) => {
  const {
    dealerIds,
    cityIds,
    generationIds,
    fileIds,
    photoIds,
    ...restFields
  } = values;
  return {
    dealerIds: dealerIds?.map((x) => (x.value ? x.value : x)),
    cityIds: cityIds?.filter(it => it !== MOSCOW).map((x) => (x.value ? x.value : x)),
    generationIds: generationIds?.map((x) => (x.value ? x.value : x)),
    fileIds: fileIds
      ? fileIds.map((file) => file.id)
      : [],
    photoIds: photoIds
      ? photoIds.map((file) => file.id)
      : [],
    ...restFields,
  };
};

export const useEditMonitoringForm = ({
                                        editableRecord,
                                        t,
                                        onSubmit,
                                      }: {
  editableRecord: any;
  t: TFunction;
  onSubmit?: TOnSubmitCreateMonitoringFormCallback;
}) => {
  const onSubmitHandler = (values: any) => {
    onSubmit && onSubmit(monitoringQualityFieldsAtEditDTOToBack(values));
  };

  const result = useForm<any>({
    validateFormSchema: monitoringFieldsSchema(t),
    defaultValues: editableRecord ? monitoringQualityFieldsAtEditDTO(editableRecord) : DEFAULT_MONITORING_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
  const reset = result.form.reset;

  useEffect(() => {
    if (editableRecord) {
      reset(monitoringQualityFieldsAtEditDTO(editableRecord));
    }
  }, [editableRecord, reset]);

  return result;
};
