import React, { useState } from "react";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { WARRANTY_AUDIT_TEMPLATES } from "@/constants/routes";
import { useGetWarrantyTemplateById } from "@/helpers/api/warranty-audit/templates/hooks";
import { useParams } from "react-router-dom";
import { TGetWarrantyTemplateByIdRequest } from "@/helpers/api/warranty-audit/templates/types";
import TreeComponent from "@/components/Audits/TreeSections/TreeComponent";
import { TableSimple } from "@/packages/tableSimple/TableSimple";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { mutateArrOfObj } from "@/helpers/utils";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

export const AuditWarrantyTemplateView: React.FC = (props) => {
  const params = useParams();
  const templateId = Number(params.id) as number;
  const { t } = useTranslation();
  const [sectionsType, setSectionsType] = useState<
    TGetWarrantyTemplateByIdRequest["sectionsType"]
  >("Проверка стандартов ДППО");

  const { data: template, isLoading } = useGetWarrantyTemplateById({
    id: templateId,
    sectionsType,
  });

  const batchSections = mutateArrOfObj(template?.data?.sections || []);

  // const [activeAuditsKeys, setActiveAuditsKeys] = useState([]);
  //
  // useEffect(() => {
  //   const storedActiveKey = sessionStorage.getItem("activeAuditsKeys");
  //   if (storedActiveKey) {
  //     const parsedActiveKey = JSON.parse(storedActiveKey);
  //     const filteredActiveKey = parsedActiveKey.filter(
  //       (value, index, self) => value !== null && self.indexOf(value) === index
  //     );
  //     setActiveAuditsKeys(filteredActiveKey);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   if (activeAuditsKeys && activeAuditsKeys.length > 0) {
  //     sessionStorage.setItem(
  //       "activeAuditsKeys",
  //       JSON.stringify(activeAuditsKeys)
  //     );
  //   }
  // }, [activeAuditsKeys]);

  return (
    <DashboardLayout
      toBack={WARRANTY_AUDIT_TEMPLATES}
      title={t("audits.templates.newTemplate") || ""}
    >
      <>
        <PermissionsAdapter
          resourceName="guarantee.templates"
          resourceAction="read"
          fallback={() => <span>No rights</span>}
        >
          <DndProvider backend={HTML5Backend}>
            <TreeComponent
              data={batchSections || []}
              renderComponent={({ data }) => (
                <TableSimple headers={[]} data={data || []}/>
              )}
            />
          </DndProvider>
        </PermissionsAdapter>
      </>
    </DashboardLayout>
  );
};
