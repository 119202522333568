import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { Icon } from "@/packages/icon/Icon";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { getPermissionsList, NavMenuItem } from ".";
import { ChildNavItem } from "@/layouts/DashboardLayout/MainLayoutNavBar/ChildNavItem";
import {
  NavItemWrap,
  StyledClildWrapper,
  StyledDashboardLayoutNavItem,
  StyledDashboardLayoutNavItemLabel,
  StyledIconWrapper,
  StyledLabelWrapper,
  StyledOpenParentIcon,
} from "./index.styles";

interface INavItem {
  navItem: NavMenuItem;
  isOpen: boolean;
  permissionDisabled?: boolean;
  onClick?: () => void;
  navScrollPositionRef: React.MutableRefObject<number>
}

export const NavItem: React.FC<INavItem> = (props) => {
  const { navItem, isOpen, permissionDisabled, onClick, navScrollPositionRef } = props;
  const [isChildrenOpen, setIsChildrenOpen] = useState(false);

  const location = useLocation();

  const isChildrenActive = navItem.children?.some((navSubItem) =>
    location.pathname.includes(navSubItem.path)
  );

  const saveNavScrollPosition = () => {
    sessionStorage.setItem('navScrollPositionRef', navScrollPositionRef.current.toString())
  }

  return (
    <PermissionsAdapter
      resourceName={
        navItem.children?.length
          ? getPermissionsList(navItem.children)?.flat() || []
          : navItem.permission || []
      }
      resourceAction="read"
      disabled={permissionDisabled}
    >
      <div>
        <div onClick={() => isOpen && setIsChildrenOpen((prev) => !prev)}>
          {"path" in navItem ? (
            <StyledDashboardLayoutNavItem
              onClick={() => {
                saveNavScrollPosition();
                onClick && onClick();
              }}
              key={navItem.key}
              to={navItem.path}
              isOpen={isOpen}
              isChildrenActive={!!isChildrenActive}
            >
              {navItem?.icon ? (
                <StyledIconWrapper>
                  <Icon name={navItem.icon} size={16} color="white"/>
                </StyledIconWrapper>
              ) : (
                <></>
              )}
              <StyledDashboardLayoutNavItemLabel size={12} weight={500}>
                {navItem.label}
              </StyledDashboardLayoutNavItemLabel>
            </StyledDashboardLayoutNavItem>
          ) : (
            <NavItemWrap
              onClick={onClick}
              key={navItem.key}
              isOpen={isOpen}
              isChildrenActive={!!isChildrenActive}
            >
              <StyledLabelWrapper>
                {navItem?.icon ? (
                  <StyledIconWrapper>
                    <Icon name={navItem.icon} size={16} color="white"/>
                  </StyledIconWrapper>
                ) : (
                  <></>
                )}
                <StyledDashboardLayoutNavItemLabel size={12}>
                  {navItem.label}
                </StyledDashboardLayoutNavItemLabel>
              </StyledLabelWrapper>
              {
                isOpen && isChildrenActive &&
                <StyledOpenParentIcon size={16} name={'menu-close'}/>
              }
            </NavItemWrap>
          )}
        </div>
        {
          navItem.children &&
          (isChildrenOpen || isChildrenActive) &&
          isOpen &&
          <StyledClildWrapper>
            {
              navItem.children?.map((child, i) => (
                <ChildNavItem navScrollPositionRef={navScrollPositionRef} index={i} navItemChild={child}/>
              ))
            }
          </StyledClildWrapper>
        }
      </div>
    </PermissionsAdapter>
  );
};
