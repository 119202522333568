import {
  DEFAULT_NEWS_DATA_FORM_VALUES,
  newsFieldsSchema,
  TNewsFieldsDataPart,
} from "@/components/NewsDataFields/utils";
import {
  TOnSubmitFormAsyncCb,
  TOnSubmitFormCb,
  useForm,
} from "@/hooks/useForm";
import { useNotification } from "@/hooks/useNotification";
import { useEffect } from "react";

export type TOnSubmitNewsEditFormCb = TOnSubmitFormAsyncCb<{
  id: string;
  patch: TNewsFieldsDataPart;
}>;

export const useNewsEditDataForm = (
  initValues: TNewsFieldsDataPart & { id?: string },
  onSubmit: TOnSubmitNewsEditFormCb,
  isLoadingNews: boolean
) => {
  const defaultValues = initValues;
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const onSubmitHandler: TOnSubmitFormCb<TNewsFieldsDataPart> = async (
    successValues
  ) => {
    try {
      const patch = successValues;
      if (initValues.id) {
        await onSubmit({
          id: initValues.id,
          patch,
        });
        createNotificationSuccess("Новость успешно изменена");
      }
    } catch {
      createNotificationError("Произошла ошибка при изменении новости");
    }
  };

  const result = useForm<TNewsFieldsDataPart>({
    validateFormSchema: newsFieldsSchema(),
    defaultValues: defaultValues,
    onSubmit: onSubmitHandler,
  });

  const reset = result.form.reset;

  useEffect(() => {
    if (!isLoadingNews) {
      reset(initValues);
    }
  }, [reset, isLoadingNews]);

  return result;
};

export type TOnSubmitCreateNewsFormCallback = (
  values: TNewsFieldsDataPart
) => void;

export const useCreateNewsForm = (
  onSubmit: TOnSubmitCreateNewsFormCallback
) => {
  const onSubmitHandler = (values: TNewsFieldsDataPart) => {
    onSubmit(values);
  };

  return useForm<TNewsFieldsDataPart>({
    validateFormSchema: newsFieldsSchema(),
    defaultValues: DEFAULT_NEWS_DATA_FORM_VALUES,
    onSubmit: onSubmitHandler,
  });
};
