import { FC, useContext } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormProvider } from "react-hook-form";
import {
  CreateGsmSuccessFormValues,
  useCreateSparesForm,
} from "../hooks";
import {
  ContextMaintenanceGrids,
  MAINTENANCE_GRIDS,
} from "@/hooks/contexts/useMaintenanceGrids";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";
import { GsmFormModal } from "@/components/MaintenanceGrids/GsmTab/GsmFormModal";

type TGsmCreateModal = Pick<IModal, "onCancel">;

const SpareCreateModal: FC<TGsmCreateModal> = (props) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const { createNotificationError } = useNotification();
  const {
    actions: { createSpare },
  } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

  const onFormSubmit = (data: CreateGsmSuccessFormValues) => {
    createSpare(
      {
        code: data.code,
        name: data.name,
        // @ts-ignore
        price: Number(data.price),
      },
      {
        onSuccess: () => {
          form.reset();
          onCancel && onCancel();
        },
        onError: (error) => {
          //@ts-ignore
          createNotificationError(error.response?.data.message ?? error.message);
        }
      }
    );
  };

  const { form, onSubmitHandler } = useCreateSparesForm(onFormSubmit);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
  };

  return (
    <Modal
      {...props}
      title={t("maintenanceGrids.gsmModel.createNewSpare") || ""}
      confirmButtonText={t("maintenanceGrids.gsmModel.createNewSpare") || ""}
      cancelButtonText={t("maintenanceGrids.gsmModel.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancelButtonClick}
    >
      <FormProvider {...form}>
        <GsmFormModal
          editMode={false}
          type={'spare'}
        />
      </FormProvider>
    </Modal>
  );
};

export { SpareCreateModal };
