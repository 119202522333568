import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { IModal, Modal } from "@/packages/modal/Modal";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { StyledModalContentWrapper } from "./index.styles";
import { useTemplateForm } from "./hooks";
import { usePatchWarrantyAuditTemplate } from "@/helpers/api/warranty-audit/templates/hooks";
import { useTranslation } from "react-i18next";

type IChangeWarrantyTemplateNameProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadWarrantyTemplateBase {
  id: number;
  oldName: string;
  onSubmit: () => void;
}

export const ChangeWarrantyTemplateNameModal: React.FC<
  IChangeWarrantyTemplateNameProps & IUploadWarrantyTemplateBase
> = (props) => {
  const { onSubmit, onCancel, oldName, id } = props;

  const { mutate: changeName } = usePatchWarrantyAuditTemplate();

  const onFormChange = (value) => {
    changeName({
      patch: {
        id,
        data: { ...value },
      },
      onSuccess: onSubmit,
      onError: () => {},
    });
  };

  const { form, onSubmitHandler, setDefaultValue } =
    useTemplateForm(onFormChange);

  useEffect(() => {
    setDefaultValue({ title: oldName });
  }, []);
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      title={t("audits.templates.editSection") || ""}
      size="small"
      cancelButtonText={t("audits.templates.cancel") || ""}
      confirmButtonText={t("audits.templates.save") || ""}
      onSubmit={onSubmitHandler}
      onCancel={onCancel}
    >
      <StyledModalContentWrapper>
        <FormProvider {...form}>
          <FormInputField
            name="title"
            label={t("audits.templates.nameSection") || ""}
            placeholder=""
            description={t("audits.templates.nameSectionDescr") || ""}
          />
        </FormProvider>
      </StyledModalContentWrapper>
    </Modal>
  );
};
