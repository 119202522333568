import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { Button } from "@/packages/button/Button";
import {
  StyledPage,
  StyledPageHeader,
  StyledChangePasswordForm,
  StyledFormBox,
  StyledFormGroup,
  StyledFormAction,
} from "./index.style";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useUpdatePassword } from "@/helpers/api/profile/hooks";
import { useNotification } from "@/hooks/useNotification";
import {
  TOnSubmitPasswordChangeFormCb,
  usePasswordChangeForm,
} from "@/components/profile/ChangePasswordForm/hooks";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { AxiosError } from "axios";
import { ProfileConfirmModal } from "@/components/profile/ProfileConfirmModal";
import { useConfirmModal } from "@/components/SimpleConfirmModal/hooks";
import { useTranslation } from "react-i18next";

const ChangePassword: FC = (): JSX.Element => {
  const {
    modalState: profileConfirmModalState,
    openModal: openProfileConfirmModal,
  } = useConfirmModal();

  const { mutateAsync: profileChangePassword } = useUpdatePassword();
  const { createNotificationError } = useNotification();

  const onSubmit: TOnSubmitPasswordChangeFormCb = async (data) => {
    try {
      await profileChangePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      await openProfileConfirmModal();
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  const {
    form,
    submit,
    isSubmitLoading: isSumbitLoading,
  } = usePasswordChangeForm(onSubmit);
  const { t } = useTranslation();

  return (
    <DashboardLayout toBack="/profile" title={t("common.password.title") || ""}>
      <StyledPage>
        <StyledPageHeader>
          <FormProvider {...form}>
            <StyledChangePasswordForm>
              <StyledFormBox>
                <StyledFormGroup>
                  <FormInputField
                    name="oldPassword"
                    type="password"
                    label={t("common.password.password") || ""}
                    placeholder={t("common.password.passwordEnter") || ""}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormInputField
                    name="newPassword"
                    type="password"
                    label={t("common.password.passwordNew") || ""}
                    placeholder={t("common.password.passwordNew") || ""}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormInputField
                    name="repeatPassword"
                    type="password"
                    label={t("common.password.passwordNewRepeat") || ""}
                    placeholder={t("common.password.passwordNewRepeat") || ""}
                  />
                </StyledFormGroup>
              </StyledFormBox>
              <StyledFormAction>
                <Button
                  isDisabled={!form.formState.isValid}
                  onClick={submit}
                  isBlock={true}
                  theme="primary"
                  color="brand"
                  size="middle"
                >
                  {t("common.password.edit") || ""}
                </Button>
              </StyledFormAction>
            </StyledChangePasswordForm>
          </FormProvider>
        </StyledPageHeader>
      </StyledPage>
      <ProfileConfirmModal {...profileConfirmModalState} />
    </DashboardLayout>
  );
};

export { ChangePassword };
