import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TransportComplaintsDetail } from "@/components/TransportComplaints/TransportComplaintsDetail";
import {
  useGetTransportClaimsById,
  usePatchTransportClaims,
} from "@/helpers/api/transportComplaints/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import React, { useCallback } from "react";
import { To, useNavigate, useParams } from "react-router-dom";
import {
  RESOURCE_ACTIONS,
  RESOURCE_NAME_TCLAIMS,
} from "../TransportComplaints";

export const TransportComplaintsEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { data: transportClaimsItem, isLoading } = useGetTransportClaimsById({
    id: id?.replace('&goBack', '') ?? "0",
  });

  const { mutateAsync: editTransportClaims } = usePatchTransportClaims();

  const onSubmitHandler = useCallback(async (editData) => {
    try {
      await editTransportClaims(editData);
      createNotificationSuccess("Рекламация успешно обновлена");
      navigate(id?.includes('&goBack') ? '/transport-claims' as To : -1 as To);
    } catch (error) {
      createNotificationError("Произошла ошибка");
    }
  }, []);
  return (
    <DashboardLayout toBack={id?.includes('&goBack') ? '/transport-claims' : -1} title=" ">
      {!isLoading && (
        <PermissionsAdapter
          resourceName={RESOURCE_NAME_TCLAIMS}
          resourceAction={RESOURCE_ACTIONS.READ}
          fallback={() => <div>No Access</div>}
        >
          {transportClaimsItem && (
            <TransportComplaintsDetail
              detailRecord={transportClaimsItem?.data}
              onSubmit={onSubmitHandler}
              isReadOnly={false}
            />
          )}
        </PermissionsAdapter>
      )}
    </DashboardLayout>
  );
};

export default TransportComplaintsEdit;
