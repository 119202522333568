import React from "react";

import { IModal } from "@/packages/modal/Modal";

import { AutoParkForm } from "@/components/AutoPark/AutoParkForm";
import {
  useGetVehicleById,
  usePatchVehicle,
} from "@/helpers/api/vehicles/hooks";
import { IAutoParkFormValues } from "@/components/AutoPark/AutoParkForm/hooks";
import { TVehiclePutRecord } from "@/helpers/api/vehicles/types";
import {
  StyledModal,
  StyledModalContentWrapper,
  StyledModalTitle,
} from "./index.styles";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

interface IVehicleModal extends Pick<IModal, "isOpen" | "onCancel"> {
  id: string;
  closeModal: any;
}

export const VehicleModal: React.FC<IVehicleModal> = (props) => {
  const { id, onCancel, closeModal } = props;
  const { t } = useTranslation();
  const { data: vehicleResponse, isLoading: isLoadingVehicle } =
    useGetVehicleById({ id });
  const { mutateAsync: putVehicleAsync, isLoading: isPutVehicleLoading } =
    usePatchVehicle();

  const { createNotificationError } = useNotification();

  const handleFormSubmit = async (data: IAutoParkFormValues) => {
    const {
      commissioningDate,
      expiredCASCO,
      expiredOSAGO,
      registrationCertificate,
      number,
      vin,
      equipmentId,
      color,
      images,
      files,
    } = data;

    const mediaImages = images
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const mediaFiles = files
      .filter((el) => !!el.value)
      .map((el) => el.value?.id || "");
    const media = {
      photo: mediaImages,
      file: mediaFiles,
    };

    const dataToServer: TVehiclePutRecord = {
      commissioning: commissioningDate ? commissioningDate.toISOString() : "",
      expiredCASCO: expiredCASCO ? expiredCASCO.toISOString() : undefined,
      expiredOSAGO: expiredOSAGO ? expiredOSAGO.toISOString() : "",
      equipmentId: equipmentId || "",
      registrationCertificate,
      governmentNumber: number,
      media,
      color,
      vin,
    };

    try {
      await putVehicleAsync({
        id,
        put: { ...dataToServer },
      });
      closeModal();
    } catch (error) {
      if (
        error &&
        // @ts-ignore
        error.response?.data.message === "Generation is inactive"
      ) {
        createNotificationError("Поколение неактивно");
      }
    }
  };

  return (
    <StyledModal
      {...props}
      title={t("park.request") || ""}
      size="medium"
      cancelButtonText={t("models.logsModel.cancel") || ""}
      showConfirmButton={false}
      onCancel={onCancel}
    >
      <StyledModalContentWrapper>
        <StyledModalTitle level={3} weight={400}>
          {t("park.editCar") || ""}
        </StyledModalTitle>

        {vehicleResponse?.data ? (
          <AutoParkForm
            defaultValues={vehicleResponse?.data}
            onFormSubmit={handleFormSubmit}
            isLoading={isPutVehicleLoading || isLoadingVehicle}
          />
        ) : (
          <></>
        )}
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
