import React, { useState } from "react";
import { IconButton } from "@/packages/icon-button/IconButton";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";

import { Badge, Popconfirm } from "antd";
import { StyledButtons, StyledSelectField } from "./index.styles";
import { TAuditTaskDto, TAuditUpdateTaskRequest, } from "@/helpers/api/audit-plan/types";
import { Datepicker } from "@/packages/formElements/inputs/Datepicker";
import { TGetUserListResponse } from "@/helpers/api/users/types";
import { InputNumber } from "@/packages/formElements/inputs/InputNumber";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import { responsibleToOptions } from "@/helpers/utils";
import { InputField } from "@/packages/formElements/fields/InputField";

interface ITableInput {
  onEditField: (request: TAuditUpdateTaskRequest) => void;
  name: string;
  id: number;
  type?: "text" | "date" | "number" | "textarea";
  initValueData?: any;
  isLoading: boolean;
  error?: string;
}

export const TableInput: React.FC<ITableInput> = ({
                                                    onEditField,
                                                    id,
                                                    name,
                                                    type = "text",
                                                    initValueData,
                                                    isLoading,
                                                    error,
                                                  }) => {
  let initValue;
  switch (type) {
    case "text":
      initValue = "";
  }
  const [value, setValue] = useState(initValueData || initValue);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      {type === "date" && (
        <Datepicker
          disabled={isLoading}
          value={value && new Date(value)}
          onChange={(value) =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
        />
      )}
      {type === "text" && (
        <InputField
          error={error}
          disabled={isLoading}
          value={value}
          onChange={(value) => setValue(value)}
          onBlur={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
          onPressEnter={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
        />
      )}
      {type === "number" && (
        <InputNumber
          disabled={isLoading}
          onChange={(value) => setValue(value)}
          value={value}
          onBlur={() =>
            onEditField({
              id,
              patch: {
                [name]: +value,
              },
            })
          }
          onPressEnter={() =>
            onEditField({
              id,
              patch: {
                [name]: +value,
              },
            })
          }
        />
      )}
      {type === "textarea" && (
        <TextAreaField
          disabled={isLoading}
          value={value}
          onChange={(value) => setValue(value)}
          onBlur={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
          onPressEnter={() =>
            onEditField({
              id,
              patch: {
                [name]: value,
              },
            })
          }
        />
      )}
    </div>
  );
};

export const customValidation = (
  value: string,
  min: number,
  max: number,
  minText: string,
  maxText: string
) => {
  if (value?.length < min) {
    return minText;
  } else if (value?.length > max) {
    return maxText;
  } else return "";
};

export const getDefaultAuditCreatePlanTableHeaders = <
  TRecord extends object
>(options: {
  openChat: (taskId: number) => void;
  onDeleteClick?: (id: number) => void;
  onEditField: (request: TAuditUpdateTaskRequest) => void;
  responsible?: TGetUserListResponse;
  isLoading: boolean;
  loadingIds: number[];
}): IHeaderItem<TAuditTaskDto>[] => {
  const {
    onDeleteClick,
    onEditField,
    openChat,
    loadingIds,
    responsible,
    isLoading,
  } = options;

  return [
    {
      Header: "№ Задачи",
      accessor: "dealerTaskId",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },

    {
      Header: "Контрольная точка для проверки",
      accessor: "title",
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      Cell: ({ row: { original }, value }) => {
        const [currentValue, setCurrentValue] = useState(value);
        return (
          <PermissionsAdapter
            resourceName="audit.task-managment"
            resourceAction="update"
          >
            <TextAreaField
              isBlock={true}
              error={customValidation(
                currentValue,
                3,
                500,
                "Контрольная точка для проверки должна состоять не менее чем из 3 символов",
                "Контрольная точка для проверки не должна превышать 500 символов"
              )}
              disabled={isLoading && loadingIds.includes(original.id)}
              value={currentValue}
              onChange={(value) => setCurrentValue(value)}
              onBlur={() =>
                onEditField({
                  id: original.id,
                  patch: {
                    title: currentValue,
                  },
                })
              }
              onPressEnter={() =>
                onEditField({
                  id: original.id,
                  patch: {
                    title: currentValue,
                  },
                })
              }
            />
          </PermissionsAdapter>
        );
      },
    },
    {
      Header: "План действий",
      accessor: "task",
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      Cell: ({ row: { original }, value }) => {
        const [currentValue, setCurrentValue] = useState(value);
        return (
          <PermissionsAdapter
            resourceName="audit.task-managment"
            resourceAction="update"
          >
            <TextAreaField
              error={customValidation(
                currentValue,
                3,
                800,
                "План действий должен состоять не менее чем из 3 символов",
                "План действий не должен превышать 800 символов"
              )}
              disabled={isLoading && loadingIds.includes(original.id)}
              value={currentValue}
              onChange={(value) => setCurrentValue(value)}
              onBlur={() =>
                onEditField({
                  id: original.id,
                  patch: {
                    task: currentValue,
                  },
                })
              }
              onPressEnter={() =>
                onEditField({
                  id: original.id,
                  patch: {
                    task: currentValue,
                  },
                })
              }
            />
          </PermissionsAdapter>
        );
      },
    },
    {
      Header: "Вес параметра",
      accessor: "weight",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      Cell: ({ row: { original }, value }) => {
        return (
          <PermissionsAdapter
            resourceName="audit.task-managment"
            resourceAction="update"
          >
            <TableInput
              isLoading={isLoading && loadingIds.includes(original.id)}
              name="weight"
              type="number"
              initValueData={value}
              onEditField={onEditField}
              id={original.id}
            />
          </PermissionsAdapter>
        );
      },
    },
    {
      Header: "Ответственный",
      accessor: "responsibleId",
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      Cell: ({ row: { original }, value }) => {
        const options = responsible ? responsibleToOptions(responsible) : [];
        return (
          <PermissionsAdapter
            resourceName="audit.task-managment"
            resourceAction="update"
          >
            <StyledSelectField
              options={options}
              disabled={isLoading && loadingIds.includes(original.id)}
              value={options.find((item) => item.value === value?.toString())}
              onChange={(value, option) => {
                if (!Array.isArray(option)) {
                  onEditField({
                    id: original.id,
                    patch: {
                      responsibleId: value,
                    },
                  });
                }
              }}
            />
          </PermissionsAdapter>
        );
      },
    },
    {
      Header: "Выполнить до",
      accessor: "deadline",
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      Cell: ({ row: { original }, value }) => {
        const [dateValue, setDateValue] = useState<Date | null>(value);
        return (
          <PermissionsAdapter
            resourceName="audit.task-managment"
            resourceAction="update"
          >
            <Datepicker
              disabled={isLoading && loadingIds.includes(original.id)}
              defaultValue={dateValue && new Date(dateValue)}
              onChange={(value) => {
                onEditField({
                  id: original.id,
                  patch: {
                    // @ts-ignore
                    deadline: value,
                  },
                });
                setDateValue(value);
              }}
            />
          </PermissionsAdapter>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      width: 70,
      minWidth: 70,
      maxWidth: 70,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        const unreadCount = original.unreadMessagesAmount;
        return (
          <StyledButtons>
            <Badge count={unreadCount}>
              <IconButton
                icon="chat"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => openChat(original.id)}
              />
            </Badge>
            <PermissionsAdapter
              resourceName="audit.task-managment"
              resourceAction="delete"
            >
              <Popconfirm
                title="Вы действительно хотите удалить задачу?"
                onConfirm={() => onDeleteClick && onDeleteClick(original.id)}
                okText="Да"
                cancelText="Нет"
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Popconfirm>
            </PermissionsAdapter>
          </StyledButtons>
        );
      },
    },
  ];
};
