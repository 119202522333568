import { useMutation } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";
import { TUserCreateRecord } from "@/helpers/api/admin-pannel/types";

export const useCreateUser = ({ onSuccess, onError }: { onSuccess: () => void, onError: () => void, }) => {
  return useMutation(
    (data: TUserCreateRecord) => {
      return api.post<any>("/users", data);
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: () => {
        onError && onError();
      },
    }
  );
};

export const useResetPassword = ({ onSuccess, onError }: { onSuccess: () => void, onError: () => void, }) => {
  return useMutation(
    (id: string) => {
      return api.post<any>(`/users/${id}/reset-password`);
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: () => {
        onError && onError();
      },
    }
  );
};