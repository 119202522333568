import { TModificationRecord } from '@/helpers/api/modifications/types';
import { TGenerationRecord } from '@/helpers/api/generations/types';
import { TModelRecord } from '@/helpers/api/models/types';
import { useModal } from '@/hooks/useModal';

import React from 'react';
import { CompleteSetCreateModal, ICompleteSetCreateModal } from './CompleteSetCreateModal';
import { ICreateCompleteSetPayload } from './CompleteSetCreateModal/hooks';
import { CompleteSetEditModal, ICompleteSetEditModal } from './CompleteSetEditModal';
import { IEditCompleteSetPayload } from './CompleteSetEditModal/hooks';
import {
    GenerationCreateModal,
    IGenerationCreateModal,
    IGenerationCreateModalSubmitPayload,
} from './GenerationCreateModal';
import { GenerationEditModal, IGenerationEditModal, IGenerationEditModalSubmitPayload } from './GenerationEditModal';
import { StyledModelsListTab } from './index.styles';
import { ModelCreateModal } from './ModelCreateModal';
import { TOnSubmitCreateModelFormCallback } from './ModelCreateModal/hooks';
import { ModelEditModal } from './ModelEditModal';
import { TOnSubmitEditModelFormCallback } from './ModelEditModal/hooks';
import { IModelDTOFields } from './ModelFields/types';
import { IModelListTable, ModelListTable } from './ModelListTable';
import { IConfirmModal, ModalConfirm } from './ModalConfirm';
import { IDataItem, SortOrder } from '@/packages/nestedTable/NestedTable';
import { useDeleteFile } from '@/helpers/api/files/hooks';

export interface IModelsListTab {
    modelListData: IModelListTable['data'];
    onCreateModel: TOnSubmitCreateModelFormCallback;
    onEditModel: TOnSubmitEditModelFormCallback;
    onModelActiveChange: (data: { id: string; isActive: boolean }) => void;
    onDeleteModel: (record: TModelRecord) => void;
    onCreateGeneration: IGenerationCreateModal['onSubmit'];
    onEditGeneration: IGenerationEditModal['onSubmit'];
    onGenerationActiveChange: (data: { id: string; isActive: boolean }) => void;
    onDeleteGeneration: IModelListTable['onDeleteGenerationClick'];

    onCreateCompleteSet: ICompleteSetCreateModal['onSubmit'];
    onEditCompleteSet: ICompleteSetEditModal['onSubmit'];
    onDeleteCompleteSet: IModelListTable['onDeleteCompleteSetClick'];
    onOpenGeneration?: (
        generationId: string,
        generationRecord: TGenerationRecord,
    ) => void;
    onSort: (accessor: string, order: SortOrder) => void;
    onSearch: (key: string, value?: string) => void;
}

export const ModelsListTab: React.FC<IModelsListTab> = ({
                                                            modelListData,
                                                            onCreateModel,
                                                            onEditModel,
                                                            onModelActiveChange,
                                                            onDeleteModel,
                                                            onCreateGeneration,
                                                            onEditGeneration,
                                                            onGenerationActiveChange,
                                                            onDeleteGeneration,
                                                            onCreateCompleteSet,
                                                            onEditCompleteSet,
                                                            onDeleteCompleteSet,

                                                            onOpenGeneration,
                                                            onSort,
                                                            onSearch,
                                                        }) => {
    const { modalState: modalConfirmState, openModal: openConfirmModal } =
        useModal<IConfirmModal, { text: string }>((close) => {
            return async () => {
                // await onCreateModel(payload);
                close();
            };
        });

    const { modalState: createModelModalState, openModal: openCreateModelModal } =
        useModal<IModelDTOFields>((close) => {
            return async (payload) => {
                await onCreateModel(payload);
                close();
            };
        });

    const { modalState: editModelModalState, openModal: openEditModelModal } =
        useModal<{ id: string; patch: IModelDTOFields }, { record: TModelRecord }>(
            (close) => {
                return async (payload) => {
                    await onEditModel(payload);
                    close();
                };
            },
        );

    const handleModelActiveChange = (record: TModelRecord) => {
        onModelActiveChange({
            id: record.id,
            isActive: record.isActive,
        });
    };

    const {
        modalState: createGenerationModalState,
        openModal: openCreateGenerationModal,
    } = useModal<
        IGenerationCreateModalSubmitPayload,
        { parentModel?: TModelRecord }
    >((close) => {
        return async (payload) => {
            await onCreateGeneration(payload);
            close();
        };
    });

    const { mutateAsync: deleteFile } = useDeleteFile();
    const {
        modalState: editGenerationModalState,
        openModal: openEditGenerationModal,
    } = useModal<
        IGenerationEditModalSubmitPayload,
        { record?: TGenerationRecord; parentModel?: TModelRecord, }
    >((close) => {
        return async (payload) => {
            await onEditGeneration(payload);

            //Костыль старый файл удаляется после обновления сущности
            //@ts-ignore
            if (payload.patch.fileIdToDelete && payload.patch.fileIdToDelete !== payload.patch?.photo?.id) {
                //@ts-ignore
                await deleteFile({ id: payload.patch.fileIdToDelete });
            }

            close();
        };
    });

    const handleGenerationActiveChange = (
        record: TGenerationRecord,
        parentModel: TModelRecord,
    ) => {
        onGenerationActiveChange({
            id: record.id,
            isActive: record.isActive,
        });
    };

    const {
        modalState: createCompleteSetModalState,
        openModal: openCreateCompleteSetModal,
    } = useModal<
        ICreateCompleteSetPayload,
        { parentModel?: TModelRecord; parentGeneration?: TGenerationRecord }
    >((close) => {
        return async (payload) => {
            await onCreateCompleteSet(payload);
            close();
        };
    });

    const {
        modalState: editCompleteSetModalState,
        openModal: openEditCompleteSetModal,
    } = useModal<
        IEditCompleteSetPayload,
        {
            record: TModificationRecord;
            parentModel?: TModelRecord;
            parentGeneration?: TGenerationRecord;
        }
    >((close) => {
        return async (payload) => {
            await onEditCompleteSet(payload);
            close();
        };
    });

    const onViewGrid = (record): string | undefined => {
        if (record.maintenanceGrids.length) {
            return `/maintenance-grids/details/view/${record.maintenanceGrids[0].id}`;
        } else {
//            openConfirmModal({ text: 'Для данной модификации не создано сеток ТО' });
            return undefined;
        }
    };

    const handleActiveChange = (value: boolean, dataItem: IDataItem) => {
    };

    return (
        <StyledModelsListTab>
            <ModelListTable
                data={modelListData}
                onSort={onSort}
                onSearch={onSearch}
                onCreateModelClick={openCreateModelModal}
                onEditModelClick={(record) => openEditModelModal({ record })}
                onModelActiveChange={handleModelActiveChange}
                onDeleteModelClick={onDeleteModel}
                onCreateGenerationClick={(modelRecord) => {
                    openCreateGenerationModal({ parentModel: modelRecord });
                }}
                openConfirmModal={openConfirmModal}
                onEditGenerationClick={(record, parentModel) => {
                    openEditGenerationModal({ record, parentModel });
                }}
                onGenerationActiveChange={handleGenerationActiveChange}
                onDeleteGenerationClick={onDeleteGeneration}
                onCreateCompleteSetClick={(modelRecord) =>
                    openCreateCompleteSetModal({ parentGeneration: modelRecord })
                }
                onActiveChange={handleActiveChange}
                onViewGridClick={onViewGrid}
                onEditCompleteSetClick={(record, parentModel) =>
                    openEditCompleteSetModal({ record, parentModel })
                }
                onDeleteCompleteSetClick={onDeleteCompleteSet}
                onOpenGeneration={onOpenGeneration}
            />

            <ModelCreateModal {...createModelModalState} />
            <ModelEditModal {...editModelModalState} />
            <GenerationCreateModal {...createGenerationModalState} />
            <GenerationEditModal {...editGenerationModalState} />
            <CompleteSetCreateModal {...createCompleteSetModalState} />
            <CompleteSetEditModal {...editCompleteSetModalState} />
            <ModalConfirm {...modalConfirmState} onSubmit={() => ({})}/>
        </StyledModelsListTab>
    );
};
