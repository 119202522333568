import { useGetFlexOrderStatusesList } from "@/helpers/api/flex/orders/hooks";
import { TFlexOrder } from "@/helpers/api/flex/orders/types";
import { useProfile } from "@/hooks/contexts/useProfile";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormTextAreaField } from "@/packages/formElements/formFields/FormTextAreaField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";
import { TitleDivider } from "@/packages/TitleDivider";

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { ExistCarFieldsName } from "../../KiaFlexCar/KiaFlexExistCar/ExistsCarForm/type";
import { StatusOrders } from "../../KiaFlexCar/Tables/KiaFlexOrdersTable";
import { ModelFields } from "../ModelFields";

import { StyledStatusLabel, TitleFieldWrapper } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface INewCarFieldsProps {
  form: UseFormReturn<any>;
  status: "Новая заявка" | "Подтверждение" | "Доставка" | "Регистрация";
  record?: TFlexOrder & { ID: string };
  stage?: number;
}

const descriptionModel = {
  [ExistCarFieldsName.MODEL]: "kiaFlex.modelsList",
  [ExistCarFieldsName.GENERATION]: "kiaFlex.generationChoose",
  [ExistCarFieldsName.MODIFICATION]: "kiaFlex.modificationChoose",
  [ExistCarFieldsName.EQUIPMENT]: "kiaFlex.partsChoose",
  [ExistCarFieldsName.COLOR]: "kiaFlex.colorChoose",
};

const _NewCarFields: React.FC<INewCarFieldsProps> = (props) => {
  const { form, status, record, stage } = props;

  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(false);
  const { data: statuses } = useGetFlexOrderStatusesList({});

  const changeForKia = record?.payload;

  const {
    state: { profile },
  } = useProfile();

  const statusList = statuses?.data.data;
  const isDealer = profile?.type === "dealer";

  useEffect(() => {
    if (stage) {
      if (stage >= 1 && isDealer) {
        setIsDisabled(true);
      } else if (
        // @ts-ignore
        (status.status === StatusOrders.CONFIRMATION && !isDealer) ||
        // @ts-ignore

        (status.status === StatusOrders.DELIVERY && !isDealer)
      ) {
        setIsDisabled(true);
      }
    }
  }, [isDealer, stage, status]);

  const viewStatus = (value) => {
    return (
      <>
        {value === StatusOrders.CONFIRMATION && (
          <StatusLabel color="yellow" content={t("kiaFlex.confirm") || ""}/>
        )}
        {value === StatusOrders.DELIVERY && (
          <StatusLabel color="cyan" content={t("kiaFlex.delivery") || ""}/>
        )}
      </>
    );
  };

  useEffect(() => {
    if (record?.status?.status === "new" && statusList) {
      form.setValue("status", statusList[1]?.id);
    } else if (statusList && record?.status?.id === statusList[1].id) {
      form.setValue("status", statusList[2]?.id);
    } else if (statusList && record?.status?.id === statusList[2].id) {
      form.setValue("status", statusList[3]?.id);
    } else if (statusList && record?.status?.id === statusList[3].id) {
      form.setValue("status", statusList[4]?.id);
    }
  }, [record?.status, profile?.type, statuses, form, statusList]);

  return (
    <>
      <TitleFieldWrapper>
        <Row>
          <Col span={stage && stage >= 2 ? 2 : 12}>
            <Paragraph size={14}>
              {stage && stage >= 2
                ? `${t("kiaFlex.order") || ""} ${record?.ID}`
                : `${t("kiaFlex.step_1") || ""}`}
            </Paragraph>
          </Col>

          {viewStatus(record?.status.status)}
        </Row>
        <Row>
          <Paragraph size={12} color="gray400">
            {stage && stage >= 2
              ? `${t("kiaFlex.confirmOrder") || ""}`
              : ` ${t("kiaFlex.kiaFlexChoose") || ""}`}
          </Paragraph>
        </Row>
        <TitleDivider/>
      </TitleFieldWrapper>

      <ModelFields
        form={form}
        description={descriptionModel}
        isDisabled={isDisabled}
        record={record}
      />
      <FormTextAreaField
        name={"comment"}
        label={t("kiaFlex.commentOrder") || ""}
        description={t("park.comment") || ""}
        disabled={isDisabled}
      />
      {record?.payload && (
        <>
          <TitleFieldWrapper>
            <Row>
              <Paragraph size={14}>{t("kiaFlex.distribution") || ""}</Paragraph>
            </Row>
            <Row>
              <Paragraph size={12} color="gray400">
                {t("kiaFlex.changesDisplayed") || ""}
              </Paragraph>
            </Row>
            <TitleDivider/>
          </TitleFieldWrapper>
          {changeForKia?.equipment && (
            <StyledStatusLabel size={12}>
              {changeForKia?.equipment}
            </StyledStatusLabel>
          )}
          {changeForKia?.model && (
            <StyledStatusLabel size={12}>
              {changeForKia?.model}
            </StyledStatusLabel>
          )}
          {changeForKia?.generation && (
            <StyledStatusLabel size={12}>
              {changeForKia?.generation}
            </StyledStatusLabel>
          )}
          {changeForKia?.modification && (
            <StyledStatusLabel size={12}>
              {changeForKia?.modification}
            </StyledStatusLabel>
          )}
          {changeForKia?.color && (
            <StyledStatusLabel size={12}>
              {changeForKia?.color}
            </StyledStatusLabel>
          )}
          {changeForKia?.vin && (
            <StyledStatusLabel size={12}>{changeForKia?.vin}</StyledStatusLabel>
          )}
          {changeForKia?.updatedDeliveryDates &&
            changeForKia?.updatedDeliveryDates.map(it => <StyledStatusLabel size={12}>{it}</StyledStatusLabel>)
          }
        </>
      )}

      {record?.status.status === "delivery" && (
        <>
          <TitleFieldWrapper>
            <Row>
              <Paragraph size={14}>{t("kiaFlex.deliveryDate") || ""}</Paragraph>
            </Row>
            <TitleDivider/>
          </TitleFieldWrapper>
          <Row>
            <Col span={12}>
              <FormDatepickerField
                name={"deliveryDate"}
                label={t("kiaFlex.confirmDeliveryDate_2") || ""}
                description={t("kiaFlex.confirmDeliveryDateDescr") || ""}
                isDisabled={isDealer}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export const NewCarFields = React.memo(_NewCarFields);
