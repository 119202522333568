import { useForm } from "@/hooks/useForm";
import * as yup from "yup";
import { t } from "i18next";

export type PasswordForgotFormValues = {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

const DEFAULT_PASSWORD_CHANGE_FORM_VALUES: PasswordForgotFormValues = {
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};

export const formSchema = () => {
  return yup
    .object({
      oldPassword: yup
        .string()
        .required(t("common.password.passwordEnter") || "")
        .matches(
          /^[A-Za-z0-9 ,@$!%*?&«»";:'`.—_-|<>^()-]+$/,
          t("common.password.letters") || ""
        )

        .min(6, t("common.password.min") || "")
        .max(12, t("common.password.max") || ""),
      newPassword: yup
        .string()
        .required(t("common.password.passwordEnter") || "")
        .matches(
          /^[A-Za-z0-9 ,@$!%*?&«»";:'`.—_-|<>^()-]+$/,
          t("common.password.letters") || ""
        )
        .min(6, t("common.password.min") || "")
        .max(12, t("common.password.max") || ""),

      repeatPassword: yup
        .string()
        .required(t("common.password.again") || "")
        .oneOf([yup.ref("newPassword")], t("common.password.same") || ""),
    })
    .required();
};

export type TOnSubmitPasswordChangeFormCb = (
  data: PasswordForgotFormValues
) => Promise<void>;

export const usePasswordChangeForm = (
  onSubmit: TOnSubmitPasswordChangeFormCb
) => {
  return useForm<PasswordForgotFormValues>({
    validateFormSchema: formSchema(),
    defaultValues: DEFAULT_PASSWORD_CHANGE_FORM_VALUES,
    onSubmit,
  });
};
