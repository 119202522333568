import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import CreateNewCarModal from "@/components/KiaFlex/KiaFlexOrders/NewCarModal";
import RegisterModal from "@/components/KiaFlex/KiaFlexOrders/RegisterModal";
import { useDeleteOrders, useGetFlexOrdersById, usePatchFlexOrders, } from "@/helpers/api/flex/orders/hooks";
import { useCreateOrdersCarFlexExist } from "@/helpers/api/flex/vehicles/hooks";
import { formatDate } from "@/helpers/formatDate";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { StatusLabel } from "@/packages/StatusLabel/StatusLabel";
import { Space } from "antd";
import React, { useCallback, useState } from "react";
import { StyledTable, TableHeader } from "./index.styles";
import { t } from "i18next";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";

export enum StatusOrders {
  NEW = "new",
  CONFIRMATION = "confirmation",
  DELIVERY = "delivery",
  REGISTRATION = "registration",
}

interface KiaFlexOrdersTableProps {
  data: any;
  onSearch: (value: string) => void;
  onSort?: (header: HeaderArgType, order: SortOrder) => void;
  searchValue: string;
}

const KiaFlexOrdersTable: React.FC<KiaFlexOrdersTableProps> = (props) => {
  const { data, searchValue, onSearch, onSort } = props;

  const [orderId, setOrderId] = useState("");

  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { data: orderItems } = useGetFlexOrdersById(orderId);

  const { mutateAsync: createViheclesOnOrders } = useCreateOrdersCarFlexExist();

  const { mutateAsync: onEditFlexOrders } = usePatchFlexOrders();

  const { mutateAsync: deleteOrders } = useDeleteOrders();

  const onCreateVihecles = useCallback(
    async (payload) => {
      try {
        await createViheclesOnOrders(payload);
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessCreation") || ""
        );
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(t("kiaFlex.errorMessage") || "");
      }
    },
    [createNotificationError, createNotificationSuccess, createViheclesOnOrders]
  );

  const onSubmitHandler = useCallback(
    async (payload) => {
      try {
        await onEditFlexOrders(payload);
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessCreationUpdate") || ""
        );
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(t("kiaFlex.errorMessage") || "");
      }
    },
    [createNotificationError, createNotificationSuccess, onEditFlexOrders]
  );

  const {
    modalState: editOrdersState,
    openModal: openEditOrdersModal,
    closeModal: editModalClose,
  } = useModal<any>((close) => {
    return async (payload) => {
      await onSubmitHandler(payload);
      close();
    };
  });

  const {
    modalState: registerOrdersState,
    openModal: openRegistrerOrdersModal,
  } = useModal<any>((close) => {
    return async (payload) => {
      await onCreateVihecles({ ...payload, orderId });
      close();
    };
  });

  const onClickHandler = useCallback(
    (status, orderId) => {
      if (status.status === StatusOrders.REGISTRATION) {
        openRegistrerOrdersModal();
        setOrderId(orderId);
      } else {
        openEditOrdersModal();
        setOrderId(orderId);
      }
    },
    [openEditOrdersModal, openRegistrerOrdersModal]
  );
  const onRemoveHandler = useCallback(
    async (id) => {
      try {
        await deleteOrders({ id });
        createNotificationSuccess(
          t("kiaFlex.createNotificationSuccessCreationDelete") || ""
        );
        editModalClose();
        editOrdersState.isOpen = false;
      } catch (error) {
        console.log("error    ", error);
        createNotificationError(t("kiaFlex.errorMessage") || "");
      }
    },
    [
      createNotificationError,
      createNotificationSuccess,
      deleteOrders,
      editModalClose,
      editOrdersState,
    ]
  );

  const headers = [
    { Header: "ID", accessor: "ID", width: 70, maxWidth: 70, minWidth: 70 },
    {
      Header: "park.autoOne",
      accessor: "vin",
      Cell: (data) => {
        const id = data.cell.row.id;
        const gen = data.data[id].equipment.modification.generation;
        const name = `${gen.model.name} ${gen.name} ${gen.restylingName || ""}`;

        return <Paragraph size={14}>{name} </Paragraph>;
      },
    },
    {
      Header: "kiaFlex.createdAt",
      accessor: "createdAt",
      isSortable: true,
      Cell: (data) => {
        const date = formatDate(data.cell.value, "dateAndTime");

        return <Paragraph size={14}> {date} </Paragraph>;
      },
    },
    {
      Header: "kiaFlex.updatedAt",
      accessor: "updatedAt",
      isSortable: true,

      Cell: (data) => {
        const date = formatDate(data.cell.value, "dateAndTime");

        return <Paragraph size={14}> {date} </Paragraph>;
      },
    },
    {
      Header: "kiaFlex.sideCheck",
      accessor: "sideCheck",
      isSortable: true,

      Cell: (data) => {
        const id = data.row.id;
        const sideCheck = data.data[id].status.sideCheck;

        return (
          <Paragraph size={12}>
            {sideCheck === "dealer"
              ? t("kiaFlex.dealer") || ""
              : t("kiaFlex.kiaFrom") || ""}
          </Paragraph>
        );
      },
    },
    {
      Header: "kiaFlex.status",
      accessor: "status",
      width: 10,
      isSortable: true,

      Cell: (data) => {
        const value = data.value.status;

        return (
          <>
            {value === StatusOrders.NEW && (
              <StatusLabel color="gray" content={t("kiaFlex.newApp")} />
            )}
            {value === StatusOrders.CONFIRMATION && (
              <StatusLabel color="yellow" content={t("kiaFlex.confirm")} />
            )}
            {value === StatusOrders.DELIVERY && (
              <StatusLabel color="cyan" content={t("kiaFlex.delivery")} />
            )}
            {value === StatusOrders.REGISTRATION && (
              <StatusLabel color="green" content={t("kiaFlex.reg")} />
            )}
          </>
        );
      },
    },

    {
      Header: t("maintenanceGrids.gridsModel.actions") || "",
      accessor: "buttons",
      minWidth: 90,
      maxWidth: 90,

      Cell: (data) => {
        const id = data.cell.row.id;
        const orderId = data.data[id].id;
        const status = data.data[id].status;

        return (
          <Space>
            <PermissionsAdapter
              resourceName="flex.vehicle"
              resourceAction="update"
            >
              <IconButton
                icon="edit"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => onClickHandler(status, orderId)}
              />
            </PermissionsAdapter>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader>
        <Space direction="vertical">
          <Paragraph size={16}>{t("kiaFlex.applications")}</Paragraph>
          <Paragraph size={12} color="gray400">{`${t("kiaFlex.allApp")} ${
            data?.length
          } ${t("kiaFlex.active")}`}</Paragraph>
        </Space>

        <Search
          value={searchValue}
          onChange={(value) => onSearch(value || "")}
        />
      </TableHeader>
      <StyledTable data={data} headers={headers} onSort={onSort} withOverflow/>
      {orderItems && (
        <CreateNewCarModal
          onRemove={onRemoveHandler}
          onClose={editModalClose}
          // @ts-ignore

          record={orderItems?.data}
          {...editOrdersState}
        />
      )}
      {orderItems && (
        <RegisterModal
          //@ts-ignore
          record={orderItems?.data}
          {...registerOrdersState}
        />
      )}
    </>
  );
};

export { KiaFlexOrdersTable };
