import { colors } from "@/helpers";
import { css } from "styled-components";

export const getInputBorderStyle = (params: {
  disabled: boolean;
  error: boolean;
}) => {
  const { disabled, error } = params;

  return css`
    border: 1px solid ${colors.gray300};
    border-radius: 4px;

    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border-color: ${colors.brand};
        box-shadow: 0 0 0 2px rgba(5, 20, 31, 0.2);
      }
    }

    &:focus,
      &-focused {
        border: 1px solid ${colors.brand};
        box-shadow: 0 0 0 2px rgba(5, 20, 31, 0.2);
      }

    ${disabled
      ? css`
          border: 1px solid ${colors.gray300};
        `
      : ""}

    ${!disabled && error
      ? css`
          border-color: ${colors.red200} !important;
        `
      : ""}
  `;
};

export const getInputTextStyle = () => {
  return css`
    color: ${colors.black};
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  `;
};

export const getInputPlaceholderStyle = () => {
  return css`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray400};
  `;
};
