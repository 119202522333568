import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TGetAuditMessagesRequest,
  TGetAuditMessagesResponse,
  TGetWarrantyAuditMessagesRequest,
  TSendAuditMessagesRequest,
  TSendAuditMessagesResponse,
} from "./types";

export const useGetAuditMessage = (
  request: TGetAuditMessagesRequest | TGetWarrantyAuditMessagesRequest,
  isWarranty: boolean = false
) => {
  return useQuery(["audit", "messages", request], () => {
    return api.get<TGetAuditMessagesResponse | TGetAuditMessagesResponse>(
      `/${isWarranty ? "guarantee" : "audits"}/messages`,
      {
        params: request,
      }
    );
  });
};

export const useReadAllAuditGaranteeMessage = (
  taskId: number | undefined,
  answerId: string | undefined,
) => {
  return useQuery([answerId, taskId], () => {
    return api.post(
      `/guarantee/${taskId ? 'tasks' : 'answers'}/${taskId ? taskId : answerId}/read-all-messages`,
    );
  });
};

export const useReadAllAuditPPOMessage = (
  id: number | undefined,
  paramId: string | undefined,
) => {
  return useQuery([paramId, id], () => {
    return api.post(
      `/audits/${id}/parameters/${paramId}/read-all-messages`,
    );
  })
};

export const useSendAuditMessage = () => {
  return useMutation(
    ({
       data,
       isWarranty,
       onSuccess,
       onError,
     }: {
      data: TSendAuditMessagesRequest;
      isWarranty: boolean;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TSendAuditMessagesResponse>(
        `/${isWarranty ? "guarantee" : "audits"}/messages`,
        data
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error, { onError }) => {
        if (onError) {
          onError(error as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useReadAllAuditTaskPPOMessage = (
  taskId: number | undefined,
  answerId: string | undefined,
) => {
  return useQuery([taskId], () => {
    return api.post(
      `/audits/tasks/${taskId}/read-all-messages`,
    );
  });
};

export const useSendNotificationForDealer = () => {
  return useMutation(
    ({
       id,
       paramId,
       onSuccess,
       onError,
     }: {
      id: number;
      paramId: string
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post(
        `/audits/${id}/parameters/${paramId}/send-notification-for-dealer`,
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error, { onError }) => {
        if (onError) {
          onError(error as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useSendNotificationTaskForDealer = () => {
  return useMutation(
    ({
       id,
       onSuccess,
       onError,
     }: {
      id: number;
      onSuccess?: () => void;
      onError?: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post(
        `/audits/tasks/${id}/send-notification-for-dealer`,
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error, { onError }) => {
        if (onError) {
          onError(error as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
