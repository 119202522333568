import { TGetListTransportClaimsResponse } from "@/helpers/api/transportComplaints/types";
import { HeaderArgType, SortOrder, TableSimple } from "@/packages/tableSimple/TableSimple";

import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { transportClaimsHeaders } from "./headers";

interface TransportComplaintsTableProps {
  transportClaimsList: TGetListTransportClaimsResponse;
  onSort?: (header: HeaderArgType, order: SortOrder) => void;
}

const TrabsportComplaintsTable: React.FC<TransportComplaintsTableProps> = (
  props
) => {
  const { transportClaimsList } = props;

  const navigate = useNavigate();

  const headers = useMemo(() => {
    return transportClaimsHeaders({ navigate });
  }, []);
  return <TableSimple headers={headers} data={transportClaimsList ?? []} onSort={props.onSort} />;
};

export default TrabsportComplaintsTable;
