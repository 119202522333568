import styled from "styled-components";
import { Button } from "@/packages/button/Button";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Title } from "@/packages/title/Title";
import { colors } from "@/helpers";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Icon } from "@/packages/icon/Icon";
import { Modal } from "@/packages/modal/Modal";

export const StyledInputField = styled(FormInputField)`
  width: 220px;
  border: 1px solid #c7c8ca;
  border-radius: 4px;
  background: #f9f9fd;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
`;

export const StyledForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledButton = styled(Button)`
  margin-left: 24px;
  margin-top: 23px;
  width: 150px;
  height: 34px;
`;

export const StyledModal = styled(Modal)`
  background-color: black;
  width: 100%;
`;

export const StyledModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;

export const StyledModalTitle = styled(Title)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray30};
`;

export const StyledFormGrid = styled.div<{ isNonDnm?: boolean }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ isNonDnm }) => (isNonDnm ? "1" : "2")},
    1fr
  );
  gap: 16px 32px;
  margin-bottom: 32px;
`;

export const StyledStageContainer = styled.div`
  max-width: 848px;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.gray500};
`;

export const StyledIcon = styled(Icon)`
  margin: 0 auto;
`;

export const StyledDescription = styled.div`
  text-align: center;
`;
