import { Checkbox, ICheckbox } from "@/packages/checkbox/Checkbox";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export type FormCheckboxProps = {
  name: string;
} & Omit<ICheckbox, "error" | "name">;

const _FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <Checkbox
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            name={name}
          />
        );
      }}
    />
  );
};
export const FormCheckbox = React.memo(_FormCheckbox);
