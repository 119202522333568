import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";
import styled from "styled-components";

export const ResetMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 64px;
  width: 587px;
  background: #ffffff;
  border-radius: 16px;
`;

export const ResetMessageIcon = styled(Icon)`
  margin-bottom: 24px;
`;
// text-align: center; in title
export const ResetMessageTitle = styled(Title)`
  text-align: center;
  font-weight: 400;
  font-size: 24px;
`;

export const ResetMessageText = styled(Paragraph)`
  margin-top: 16px;
`;
