import React from "react";

export interface IUseSimpleEditModalRecord {
  value: string;
  [key: string]: any;
}
export const useSimpleDictEditModal = (
  onSubmitCreator: (close) => (record: IUseSimpleEditModalRecord) => void
) => {
  const [state, setState] = React.useState<{
    isOpen: boolean;
    record?: IUseSimpleEditModalRecord;
  }>({ isOpen: false });

  const closeModal = () => {
    setState({ isOpen: false });
  };

  const onClose = () => {
    closeModal();
  };

  const openModal = (record: IUseSimpleEditModalRecord) => {
    setState({ isOpen: true, record: record });
  };

  const onSubmit = onSubmitCreator(closeModal);

  const modalState = {
    isOpen: state.isOpen,
    record: state.record,
    onSubmit,
    onClose,
  };

  return {
    modalState,
    openModal,
    closeModal,
  };
};
