import { TWorksCreateRequest } from "@/helpers/api/maintenanceGrids/works/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { t } from "i18next";

export type CreateWorksSuccessFormValues = TWorksCreateRequest;

export const DEFAULT_CREATE_WORKS_FORM_VALUES: TWorksCreateRequest = {
  name: "",
  reportName: "",
  code: "",
  description: "",
  isDefault: false,
  workTypes: [
    {
      // @ts-ignore
      code: "",
      // ratePerHour: 0,
      normInHours: 0,
      // totalCost: 0,
      type: "I",
    },
    {
      // @ts-ignore

      code: "",
      // ratePerHour: 0,
      normInHours: 0,
      // totalCost: 0,
      type: "R",
    },
    {
      // @ts-ignore

      code: "",
      // ratePerHour: 0,
      normInHours: 0,
      // totalCost: 0,
      type: "O",
    },
  ],
};

export const formSchema = () => {
  return yup
    .object({
      name: yup
        .string()
        .required(
          t("maintenanceGrids.worksModel.workForm.validation.name") || ""
        )
        .max(500, t("registers.holdingsDC.validation.max_500") || "")
        .min(5, t("registers.holdingsDC.validation.min_5") || "")
        .trim(),
      description: yup
        .string()
        .nullable()
        .max(1000, t("registers.holdingsDC.validation.max_1000") || "")
        .matches(/^[\dа-яёА-ЯЁa-zA-Z, «»";:'`?.—_-|<>^*()%!-]+$/, {
          message: t("registers.holdingsDC.validation.letters") || "",
          excludeEmptyString: true,
        })
        .trim(),
      isDefault: yup.boolean(),
      code: yup
        .string()
        .required(
          t("maintenanceGrids.worksModel.workForm.validation.codeMessage") || ""
        )
        .trim(),
      workTypes: yup.array<TWorksCreateRequest["workTypes"]>().required(),
    })
    .required();
};

export type TOnSubmitCreateWorksFormCallback = (
  values: CreateWorksSuccessFormValues
) => void;

export const useCreateWorksForm = (
  onSubmit: TOnSubmitCreateWorksFormCallback,
  defaultData?: any
) => {
  const form = useForm<TWorksCreateRequest>({
    resolver: yupResolver(formSchema()),
    mode: "onChange",
    defaultValues: defaultData || DEFAULT_CREATE_WORKS_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as CreateWorksSuccessFormValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};
