import React, { useMemo } from "react";
import { Space } from "antd";

import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IconButton } from "@/packages/icon-button/IconButton";
import { TableSimple } from "@/packages/tableSimple/TableSimple";

import { transformDateToDotted } from "@/helpers/transformDate";

import { StyledApplicationsMessage, StyledSelectField, StyledSpan, StyledStatus, StyledTable, } from "./index.styles";

import { TApplicationRecord } from "@/helpers/api/applications/types";
import { TOptionList } from "@/pages/Applications";
import { useTranslation } from "react-i18next";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

interface ApplicationsDataItem {
  id: string;
  startEvent: string;
  endEvent: string;
  vehicle: string;
  appointment: string;
  media: string;
  сreatedBy: string;
  changedBy: string;
  createdAt: string;
  status: string;
  statusColor: string;
}

interface IApplicationsTable {
  isLoading: boolean;
  data: TApplicationRecord[];
  statusList: {
    [key: string]: TOptionList[];
  };
  onEditButtonClick: (dataId: string) => void;
  onDeleteButtonClick: (dataId: string) => void;
  onStatusChange: (dataId: string, statusId: string) => void;
}

export const ApplicationsTable: React.FC<IApplicationsTable> = (props) => {
  const {
    data,
    isLoading,
    statusList,
    onEditButtonClick,
    onDeleteButtonClick,
    onStatusChange,
  } = props;
  const { t } = useTranslation();
  const { dispatch } = useUserInfoModel();

  const [headers, tableData] = useMemo(() => {
    const tempHeaders = [
      {
        Header: t("park.status") || "",
        accessor: "statusColor",
        width: "75px",
        minWidth: 75,

        Cell: (data) => {
          return (
            <Tooltip
              content={
                <Paragraph size={10} color="gray600">
                  {data.row.original.status}
                </Paragraph>
              }
            >
              <StyledStatus backgroundColor={data.value}/>
            </Tooltip>
          );
        },
      },
      {
        Header: "park.purpose",
        accessor: "appointment",
        minWidth: 100,
        width: "100px",
      },
      {
        Header: "park.media",
        accessor: "media",
        minWidth: 100,
        width: "100px",
      },
      {
        Header: "park.autoOne",
        accessor: "vehicle",
        maxWidth: 230,
      },
      {
        Header: "park.startEvent",
        accessor: "startEvent",
        minWidth: 130,
        width: "130px",
      },
      {
        Header: t("park.endEvent") || "",
        accessor: "endEvent",
        minWidth: 130,
        width: "130px",
      },
      {
        Header: t("park.сreatedBy") || "",
        accessor: "сreatedBy",
        minWidth: 100,
        width: "100px",
        Cell: ({ row: { original } }) => {
          return (
            <StyledUserNameWrapper
              onClick={() => dispatch({ userId: original?.сreatedById })}
            >
              {original?.сreatedBy}
            </StyledUserNameWrapper>
          );
        },
      },
      {
        Header: t("park.changedBy") || "",
        accessor: "changedBy",
        minWidth: 100,
        width: "100px",
        Cell: ({ row: { original } }) => {
          return (
            <StyledUserNameWrapper
              onClick={() => dispatch({ userId: original?.changedById })}
            >
              {original?.changedBy}
            </StyledUserNameWrapper>
          );
        },
      },
      {
        Header: "audits.templates.dateCreation",
        accessor: "createdAt",
        minWidth: 110,
        width: "110px",
      },
      {
        Header: "",
        accessor: "status",
        maxWidth: 160,
        Cell: (tableData) => {
          return (
            <StyledSelectField
              defaultValue={tableData.row.values.status}
              renderOption={(option) => <StyledSpan>{option.label}</StyledSpan>}
              //@ts-ignore
              options={statusList.filter(
                (option) =>
                  option.record.appointment === tableData.row.values.appointment
              )}
              onChange={(value: string) =>
                onStatusChange(tableData.row.original.id, value)
              }
              value={tableData.row.values.status}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "buttons",
        minWidth: 100,
        maxWidth: 100,
        Cell: (tableData) => {
          return (
            <Space>
              <IconButton
                size="l"
                icon="edit"
                color="black"
                variant="tertiary"
                isDisabled={isLoading}
                onClick={() => onEditButtonClick(tableData.row.original.id)}
              />
              <IconButton
                size="l"
                icon="delete"
                color="black"
                variant="tertiary"
                isDisabled={isLoading}
                onClick={() => onDeleteButtonClick(tableData.row.original.id)}
              />
            </Space>
          );
        },
      },
    ];

    const tempData: ApplicationsDataItem[] = data.map((application) => {
      const modelName =
        //@ts-ignore
        application.vehicle?.equipment.modification.generation.model.name;
      //@ts-ignore
      const vehicleDisplayName = `${modelName}, ${application.vehicle?.number} ${application.vehicle?.equipment.modification.fullName} ${application.vehicle?.equipment.name} ${application.vehicle?.color.color}`;
      return {
        startEvent: transformDateToDotted(application.startEvent),
        endEvent: transformDateToDotted(application.endEvent),
        vehicle: vehicleDisplayName || "",
        appointment: application.appointment,
        media: application.media?.name || "",
        сreatedById: application.userCreator?.id || "",
        сreatedBy: `${application.userCreator?.firstName || ""} ${
          application.userCreator?.lastName || ""
        }`,
        changedById: application.userUpdate?.id || "",
        changedBy: `${application.userUpdate?.firstName || ""} ${
          application.userUpdate?.lastName || ""
        }`,
        createdAt: transformDateToDotted(application.createdAt),
        status: application.status?.title || "",
        statusId: application.status?.id || "",
        statusColor: application.status?.color || "",
        id: application.id,
      };
    });

    return [tempHeaders, tempData];
  }, [data]);

  return (
    <StyledTable>
      {!!data.length ? (
        <TableSimple
          // onSort={onSort}
          data={tableData}
          headers={headers}
        />
      ) : !isLoading && !data.length ? (
        <StyledApplicationsMessage level={2}>
          {t("common.noData") || ""}
        </StyledApplicationsMessage>
      ) : (
        <></>
      )}
    </StyledTable>
  );
};
