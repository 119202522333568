import { Select as AntSelect, SelectProps } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { StyledDropdown, StyledSelect } from "./index.styles";
import { useTranslation } from "react-i18next";

export interface ISelectOption<
  ValueType extends string | number | boolean = string | number | boolean
> {
  className?: string;
  label: string;
  value: ValueType;
  disabled?: boolean;

  [key: string]: any;
}

export interface ISelect extends SelectProps<any, ISelectOption> {
  renderOption?: (option: ISelectOption) => React.ReactNode;
  optionInValue?: boolean;
  readOnly?: boolean;
  isSearchShow?: boolean;
  isEditable?: boolean;
  refetchValue?: string;
  setRefetchValue?: Dispatch<SetStateAction<string | undefined>>;
}

export const Select: React.FC<ISelect> = (props) => {
  const { t } = useTranslation();

  const {
    className,
    renderOption,
    options,
    mode,
    optionInValue = false,
    isSearchShow = false,
    readOnly = false,
    disabled = false,
    allowClear = false,
    loading = false,
    onChange,
    onSearch,
    isEditable = true,
    value,
    placeholder,
    showArrow = true,
    refetchValue,
    setRefetchValue,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const [debouncedValue] = useDebounce(inputValue, 300);
  const [currentOptions, setCurrentOptions] = useState(options);

  useEffect(() => {
    setCurrentOptions(options);
  }, [options]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedValue || "");
    }
  }, [debouncedValue]);

  const handleChange = (value: string, selectedOption) => {
    if (mode !== 'multiple') {
      setIsOpen(false);
    }
    if (!mode) {
      const matchOption = options?.find((option) => option.value === value);
      onChange && onChange(value, optionInValue ? matchOption : selectedOption);
    } else {
      const matchOptions = selectedOption?.reduce(
        (acc, innerSelectedOption) => {
          const matched = options?.filter(
            (innerOption) => innerOption.id === innerSelectedOption.value
          );

          matched && acc.push(...matched);

          return acc;
        },
        []
      );
      onChange &&
      onChange(value, optionInValue ? matchOptions : selectedOption);
    }
  };

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
  };

  const handleFilterOption = (input: string, option) => {
    return (
      option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  return (
    /*@ts-ignore*/
    <StyledSelect
      notFoundContent={t("common.noData")}
      className={className}
      mode={mode}
      open={!readOnly && isOpen}
      onChange={handleChange}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onBlur={() => setIsOpen(false)}
      isEditable={isEditable}
      showSearch={isSearchShow}
      filterOption={handleFilterOption}
      disabled={disabled}
      readOnly={readOnly}
      showArrow={!showArrow ? showArrow : !readOnly}
      allowClear={!readOnly && allowClear}
      onClear={() => onChange && onChange(undefined, options || [])}
      autoClearSearchValue={false}
      dropdownRender={(menu) => <StyledDropdown>{menu}</StyledDropdown>}
      searchValue={refetchValue ? refetchValue : inputValue}
      onSearch={(value) => {
        if (setRefetchValue) {
          setRefetchValue(value);
          return;
        }
        if (onSearch) {
          setInputValue(value);
        }
      }}
      value={typeof value === "string" ? t(value) : value}
      placeholder={placeholder}
      {...rest}
    >
      {currentOptions?.length ? (
        currentOptions?.map((option, index) => (
          <AntSelect.Option key={index} value={option.value}>
            {renderOption ? renderOption(option) : t(option.label) || ""}
          </AntSelect.Option>
        ))
      ) : (
        <></>
      )}
    </StyledSelect>
  );
};
