import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { t } from "i18next";

export interface IFormValues {
  visitId?: number;
  visitNumber?: number;
  code?: number;
  dealerId?: number;
  auditType?: string;
  email?: string;
  responsibleId?: number;
  implementerId?:string;
  date?: Date;
  img?: any;
}

export const DEFAULT_FORM_VALUES: IFormValues = { date: new Date() };

const formSchema = () => {
  return yup.object({
    responsibleId: yup
      .string()
      .required(t("warrantyAudits.list.needToChoose") || ""),
    // externalAuditorEmail: yup.string().email('Неверный тип данных'),
    // allClaims: yup.object().required("Необходимо прикрепить файл"),
    // warranty: yup.object().required("Необходимо прикрепить файл"),
    // spares: yup.object().required("Необходимо прикрепить файл"),
  });
};

export type TOnSubmitFormCb = (values: IFormValues) => void;

export const useFormValues = (onSubmit: TOnSubmitFormCb) => {
  const form = useForm<IFormValues>({
    mode: "onChange",
    resolver: yupResolver(formSchema()),
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
  };

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
