import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TApplicationCreateRequest,
  TApplicationCreateResponse,
  TGetApplicationListRequest,
  TGetApplicationListResponse,
  TGetApplicationByIdRequest,
  TGetApplicationByIdResponse,
  TApplicationPatchRequest,
  TApplicationPatchResponse,
  TApplicationPutRequest,
  TApplicationPutResponse,
  TApplicationDeleteRequest,
  TApplicationDeleteResponse,
} from "./types";

export const useCreateApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TApplicationCreateRequest) => {
      return api.post<TApplicationCreateResponse>(
        "/test-drives/requests",
        data
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.removeQueries(["requests", "list"]);
      },
    }
  );
};

export const useGetApplicationList = (request: TGetApplicationListRequest) => {
  return useQuery(["requests", "list", request], () => {
    return api.get<TGetApplicationListResponse>("/test-drives/requests", {
      params: request,
    });
  });
};

export const useGetApplicationById = (request: TGetApplicationByIdRequest) => {
  return useQuery(["requestById", request], () => {
    return api.get<TGetApplicationByIdResponse>(
      `/test-drives/requests/${request.id}`
    );
  });
};

export const usePatchApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TApplicationPatchRequest) => {
      return api.patch<TApplicationPatchResponse>(
        `/test-drives/requests/${id}`,
        patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["request", "list"]);
        queryClient.invalidateQueries(["requestById", variables.id]);
      },
    }
  );
};

export const usePutApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TApplicationPutRequest) => {
      return api.put<TApplicationPutResponse>(
        `/test-drives/requests/${id}`,
        put
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.removeQueries(["requests", "list"]);
        queryClient.removeQueries(["requestById", variables.id]);
        queryClient.invalidateQueries(["test-drives", "calendar"]);
      },
    }
  );
};

export const useDeleteApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TApplicationDeleteRequest) => {
      return api.delete<TApplicationDeleteResponse>(
        `/test-drives/requests/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["requests", "list", "calendar"]);
      },
    }
  );
};
