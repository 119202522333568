import styled from "styled-components";
import { Space } from "antd";
import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";

export const StyledMaintenanceGridsDetails = styled.div`
  padding: 24px 32px;
  margin-bottom: 64px;

  border-radius: 12px;

  background-color: ${colors.white};
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
`;

export const StyledContentTop = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledContentTitle = styled.div`
  position: relative;

  padding: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtons = styled(Space)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledRoleButtons = styled(Space)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  border-radius: 4px;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  width: 96px;
  border: 0;
  border-radius: 0;
`;

export const StyledInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
