import add_car from "@/assets/images/add_car.jpeg";
import order_new_car from "@/assets/images/order_new_car.jpeg";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { IModal, Modal } from "@/packages/modal/Modal";
import { Paragraph } from "@/packages/paragraph/Paragraph";

import React, { useCallback, useState } from "react";

import { useCreateFlexOrders } from "@/helpers/api/flex/orders/hooks";
import { useCreateFlexExist } from "@/helpers/api/flex/vehicles/hooks";
import { useProfile } from "@/hooks/contexts/useProfile";
import CreateOrdersModal from "../../KiaFlexOrders/CreateOrdersModal";
import CreateExistsCarModal from "../KiaFlexExistCar/ExistsCarModal";
import { StyledCard, StyledImg, StyledSpace } from "./index.style";
import { useTranslation } from "react-i18next";

interface KiaFlexModalProps extends Pick<IModal, "onCancel" | "isOpen"> {
  isOpen: boolean;
  closeModal: () => void;
}

export enum StageAddAutoKiaFlex {
  START = "start",
  REQ_APP = "request_app",
  SEND_DEALER = "send_dealer",
  CONFIRM = "confirm",
  EXIST_CAR = "exist_car",
}

const KiaFlexModal: React.FC<KiaFlexModalProps> = (props) => {
  const { isOpen, closeModal } = props;
  const { t } = useTranslation();
  const [stage, setStage] = useState<StageAddAutoKiaFlex>(
    StageAddAutoKiaFlex.START
  );

  const {
    state: { profile },
  } = useProfile();

  const { createNotificationError, createNotificationSuccess } =
    useNotification();

  const { mutateAsync: createFlexExist } = useCreateFlexExist();
  const { mutateAsync: createFlexOrder } = useCreateFlexOrders();

  const onSubmitHandlerExists = useCallback(
    async (value) => {
      try {
        await createFlexExist(value);
        createNotificationSuccess(t("kiaFlex.successCreated") || "");
      } catch (error) {
        createNotificationError(t("kiaFlex.errorMessage") || "");
      }
    },
    [createFlexExist, createNotificationError, createNotificationSuccess]
  );

  const onSubmitHandlerOrders = useCallback(
    async (value) => {
      try {
        // @ts-ignore
        await createFlexOrder(value);
        createNotificationSuccess(t("kiaFlex.successCreated") || "");
      } catch (error) {
        createNotificationError(t("kiaFlex.errorMessage") || "");
      }
    },
    [createFlexOrder, createNotificationError, createNotificationSuccess]
  );

  const { modalState: createExistsCarState, openModal: openCreateExistsModal } =
    useModal<any>((close) => {
      return async (payload) => {
        onSubmitHandlerExists(payload);
        close();
      };
    });

  const { modalState: createNewCarState, openModal: openNewExistsModal } =
    useModal<any>((close) => {
      return async (payload) => {
        onSubmitHandlerOrders(payload);
        close();
      };
    });

  return (
    <>
      <Modal
        size="medium"
        isOpen={isOpen}
        onCancel={closeModal}
        showCancelButton={false}
        title={t("kiaFlex.addNewAuto") || ""}
      >
        <>
          {stage === StageAddAutoKiaFlex.START && (
            <StyledSpace>
              {profile?.type === "dealer" && (
                <StyledCard
                  hoverable
                  cover={
                    <StyledImg
                      preview={false}
                      alt="newCar"
                      src={order_new_car}
                    />
                  }
                  onClick={() => {
                    openNewExistsModal();
                    closeModal();
                  }}
                >
                  <Paragraph size={14}>{t("kiaFlex.newCarOrder") || ""}</Paragraph>
                </StyledCard>
              )}
              <StyledCard
                hoverable
                cover={
                  <StyledImg preview={false} alt="add_car" src={add_car} />
                }
                onClick={() => {
                  openCreateExistsModal();
                  closeModal();
                }}
              >
                <Paragraph size={14}>{t("kiaFlex.existingCar") || ""}</Paragraph>
              </StyledCard>
            </StyledSpace>
          )}
        </>
      </Modal>

      <CreateExistsCarModal {...createExistsCarState} />
      <CreateOrdersModal {...createNewCarState} />
    </>
  );
};

export { KiaFlexModal };
