import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ISelectBooleanField,
  SelectBooleanField,
} from "@/packages/formElements/fields/SelectBooleanField";

export interface IFormBooleanSelectField
  extends Omit<ISelectBooleanField, "error" | "value" | "onChange"> {
  name: string;
  isDisabled?: boolean;
}

const _FormBooleanSelectField: React.FC<IFormBooleanSelectField> = (props) => {
  const { name, isDisabled, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <SelectBooleanField
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
export const FormBooleanSelectField = React.memo(_FormBooleanSelectField);
