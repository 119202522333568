import { FC } from "react";

import {
  IUploadImageDropdown,
  UploadImageDropzone,
} from "@/packages/UploadImageDropzone";
import { Field } from "../Field";
import { StyledFieldControlContainer } from "./index.styles";

export interface IImageDropzoneField extends IUploadImageDropdown {
  label?: string;
  error?: string;
  description?: string;
  isAllowFilesUpload?: boolean;
  readOnly?: boolean;
}

export const ImageDropzoneField: FC<IImageDropzoneField> = (props) => {
  const { label, error, description, isAllowFilesUpload, ...restProps } = props;

  return (
    <Field>
      {label ? <Field.Label>{label}</Field.Label> : <></>}

      <StyledFieldControlContainer>
        <UploadImageDropzone {...restProps} />
      </StyledFieldControlContainer>

      {error ? <Field.FieldError>{error}</Field.FieldError> : <></>}

      {description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
