import { getYearsList, transformDateToDotted } from "@/helpers/date";
import { getSelectedYearAndQuarter } from "@/helpers/getSelectedYearAndQuarter";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { Button } from "@/packages/button/Button";
import { SelectFieldWithPrefixIcon } from "@/packages/formElements/fields/SelectFieldWithPrefixIcon";
import { Search } from "@/packages/formElements/inputs/search/Search";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Space } from "antd";

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  StyledFirstLine,
  StyledHeader,
  StyledNextButton,
  StyledPrevButton,
  StyledQuarterClosedButton,
  StyledQuarterNotFoundParagraph,
  StyledSecondLine,
  StyledThirdLine,
} from "./index.styles";
import { usePatchDiadocPremium } from "@/helpers/api/bonuses/premium-documents/hooks";
import { useNotification } from "@/hooks/useNotification";

export const QUARTER_OPTIONS = [
  { label: "bonuses.reportsImport.quarter_1", value: 1 },
  { label: "bonuses.reportsImport.quarter_2", value: 2 },
  { label: "bonuses.reportsImport.quarter_3", value: 3 },
  { label: "bonuses.reportsImport.quarter_4", value: 4 },
];

interface IPremiumHeader {
  quarterExists: boolean;
  isQuarterLoading: boolean;
  isActGenerated: boolean;
  fileExists?: boolean;
  searchValue?: string;
  quartedClosedAt?: string;
  handleExport: () => void;
  downloadPdf?: () => void;
  handleCloseQuarter?: () => void;
  onGenerateDocuments?: () => void;
  onSearchInput?: (value?: string) => void;
  documentIds?: any;
}

export const PremiumHeader: React.FC<IPremiumHeader> = (props) => {
  const {
    searchValue,
    onSearchInput,
    isActGenerated,
    onGenerateDocuments,
    handleExport,
    downloadPdf,
    handleCloseQuarter,
    quartedClosedAt,
    quarterExists,
    fileExists,
    isQuarterLoading,
    documentIds,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const documentId = params.id as string;
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const [selectedYear, selectedQuarter] = getSelectedYearAndQuarter(search);

  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { mutate: sendToDiadoc } = usePatchDiadocPremium();

  const hanldeSendToDiadoc = () => {
    if (documentIds) {
      sendToDiadoc({
        data: { ids: documentIds },
        onSuccess: () => createNotificationSuccess(t("common.success") || ""),
        onError: (error) =>
          createNotificationError(error.response?.data?.message || ""),
      });
    } else if (documentId) {
      sendToDiadoc({
        data: { ids: [documentId] },
        onSuccess: () => createNotificationSuccess(t("common.success") || ""),
        onError: (error) =>
          createNotificationError(error.response?.data?.message || ""),
      });
    }
  };

  const [requestParams, setRequestParams] = useState({
    year:
      !isNaN(+selectedYear) && selectedYear
        ? +selectedYear
        : new Date().getFullYear(),
    quarter:
      !isNaN(+selectedQuarter) && selectedQuarter
        ? +selectedQuarter
        : Math.floor(new Date().getMonth() / 3 + 1),
  });

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: `${t("bonuses.calculations.url")} ${selectedQuarter} ${t(
      "bonuses.calculations.url_2"
    )}`,
  });

  useEffect(() => {
    setSearchParams({
      quarter: requestParams.quarter.toString(),
      year: requestParams.year.toString(),
    });
  }, [requestParams]);

  const preparedYearsList = useMemo(() => getYearsList({ yearsCount: 20 }), []);

  return (
    <StyledHeader>
      <StyledFirstLine>
        <Search
          value={searchValue}
          onChange={(value) => onSearchInput && onSearchInput(value)}
          isDisabled={!!documentId}
        />
        {quartedClosedAt && !documentId ? (
          <StyledQuarterClosedButton>{`${
            t("bonuses.calculations.quarterClose") || ""
          } ${transformDateToDotted(
            quartedClosedAt
          )}`}</StyledQuarterClosedButton>
        ) : null}
        {documentId ? (
          <Paragraph>{t("bonuses.reportsView.bonusesDealer") || ""}</Paragraph>
        ) : null}

        <Space size={12}>
          {quarterExists ? (
            <>
              {!quartedClosedAt && !documentId ? (
                <Button
                  isUploadLeft={true}
                  theme="primary"
                  color="brand"
                  onClick={handleCloseQuarter}
                >
                  {t("bonuses.reportsImport.closeQuarter")}
                </Button>
              ) : null}
              {!isActGenerated && !documentId ? (
                <Button
                  isUploadLeft={true}
                  theme="primary"
                  color="brand"
                  onClick={onGenerateDocuments && onGenerateDocuments}
                >
                  {t("bonuses.reportsImport.generateQuarter")}
                </Button>
              ) : null}
            </>
          ) : null}
          <SelectFieldWithPrefixIcon
            prefixIcon={<Icon size={16} name="calendar-16" />}
            placeholder={t("bonuses.reportsView.yearChoose") || ""}
            options={preparedYearsList || []}
            value={selectedYear}
            onChange={(value) =>
              setRequestParams((prev) => ({ ...prev, year: value }))
            }
            readOnly={!!documentId}
          />
          <SelectFieldWithPrefixIcon
            prefixIcon={<Icon size={16} name="calendar-16" />}
            placeholder={t("bonuses.calculations.quarterChoose") || ""}
            options={QUARTER_OPTIONS || []}
            value={selectedQuarter}
            onChange={(value) =>
              setRequestParams((prev) => ({ ...prev, quarter: value }))
            }
            readOnly={!!documentId}
          />
        </Space>
      </StyledFirstLine>
      <StyledSecondLine>
        <StyledPrevButton
          icon="chevron-left"
          variant="primary"
          color="white"
          size="l"
          isDisabled={requestParams.quarter === 1 || !!documentId}
          onClick={() =>
            setRequestParams((prev) => ({ ...prev, quarter: prev.quarter - 1 }))
          }
        />
        <Space size={8}>
          {isActGenerated ? (
            <>
              <Tooltip
                placement="bottom"
                content={t("bonuses.premium.exportExcel") || ""}
              >
                <IconButton
                  icon="save"
                  isSquared={true}
                  variant="secondary"
                  color="gray"
                  onClick={handleExport}
                />
              </Tooltip>

              {documentId && fileExists ? (
                <Tooltip
                  placement="bottom"
                  content={t("bonuses.premium.exportPdf") || ""}
                >
                  <IconButton
                    icon="download"
                    isSquared={true}
                    variant="secondary"
                    color="gray"
                    onClick={downloadPdf}
                  />
                </Tooltip>
              ) : null}
            </>
          ) : null}

          <Tooltip
            placement="bottom"
            content={t("bonuses.premium.copyURL") || ""}
          >
            <IconButton
              icon="share"
              isSquared={true}
              variant="secondary"
              color="gray"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
          {isActGenerated ? (
            <Button theme="primary" color="brand" onClick={hanldeSendToDiadoc}>
              {t("bonuses.calculations.sendDiadoc") || ""}
            </Button>
          ) : null}
        </Space>
        {!quarterExists && !isQuarterLoading && !documentId ? (
          <StyledQuarterNotFoundParagraph>
            {t("bonuses.calculations.quarterNotFound") || ""}
          </StyledQuarterNotFoundParagraph>
        ) : null}
        <StyledNextButton
          icon="chevron-left"
          variant="primary"
          color="white"
          size="l"
          isDisabled={requestParams.quarter === 4 || !!documentId}
          onClick={() =>
            setRequestParams((prev) => ({
              ...prev,
              quarter: prev.quarter + 1,
            }))
          }
        />
      </StyledSecondLine>
      <StyledThirdLine>
        {quartedClosedAt && documentId ? (
          <StyledQuarterClosedButton>{`${
            t("bonuses.calculations.quarterClose") || ""
          } ${transformDateToDotted(
            quartedClosedAt
          )}`}</StyledQuarterClosedButton>
        ) : null}
      </StyledThirdLine>
    </StyledHeader>
  );
};
