import React, { useEffect, useState } from "react";
import { IModal, Modal } from "@/packages/modal/Modal";
import { StyledBody, StyledImage, ImageGrid } from "./index.styles";
import { useUploadFiles } from "@/helpers/api/files/hooks";
import {
  UploadImageDropzone,
  UploadType,
} from "@/packages/UploadImageDropzone";
import { MessageInput } from "@/components/Audits/ChatModal/components/MessageInput";
import { ISendMessage } from "@/components/Audits/ChatModal";
import { useNotification } from "@/hooks/useNotification";
import { Icon } from "@/packages/icon/Icon";
import { StyledFilePlaceholder } from "../index.styles";

type IChatModalProps = Pick<
  IModal,
  "isOpen" | "onCancel" | "isLoadingConfirmButton" | "isDisabledConfirmButton"
>;

interface IUploadImageModalProps {
  files: FileList;
  onSubmit: (data: ISendMessage) => void;
  onCancel: () => void;
}

export const UploadImageModal: React.FC<
  IUploadImageModalProps & IChatModalProps
> = (props) => {
  const { files, onSubmit, onCancel } = props;
  const [currentFiles, setCurrentFiles] = useState(files);

  const { createNotificationError } = useNotification();

  useEffect(() => {
    setCurrentFiles(files);
  }, [files]);

  const { mutate: uploadFiles } = useUploadFiles();

  const [isSending, setIsSending] = useState(false);

  const handleUploadFiles = (data: ISendMessage) => {
    const formData = new FormData();
    Object.values(currentFiles).forEach((value) => {
      formData.append("files", value);
    });

    if (isSending) {
      return;
    }
    setIsSending(true);

    uploadFiles({
      // @ts-ignore
      data: formData,
      path: "quality-monitoring/request-cars/files",
      onSuccess: (resp) => {
        onSubmit({
          message: data.message,
          filesIds: resp.data.map((item) => item.id),
        });
        setIsSending(false);
      },
    });
  };

  const handleAddFiles = (newFiles) => {
    if (currentFiles.length < 10) {
      //@ts-ignore
      setCurrentFiles([...currentFiles, ...newFiles]);
    } else {
      createNotificationError("Максимальное количество вложение - 10");
    }
  };

  return (
    <Modal
      {...props}
      title=""
      size="small"
      //   isLoadingConfirmButton={isLoading}
      showCancelButton={false}
      showConfirmButton={false}
      onSubmit={() => ({})}
      onCancel={onCancel}
    >
      <StyledBody>
        <ImageGrid>
          {Object.values(currentFiles).map((item, index) => (
            <StyledImage key={index}>
              <UploadImageDropzone
                hasRemove={false}
                // @ts-ignore
                value={{
                  fileUrl: URL.createObjectURL(item),
                }}
                description="Максимальный размер фотографии — 5 МБ"
                placeholder={
                  <StyledFilePlaceholder>
                    <Icon name="file-pdf" size={40} />
                  </StyledFilePlaceholder>
                }
                isWithCustomPlaceholder
                typeInfo={
                  item.type.includes("image")
                    ? UploadType.IMAGE
                    : UploadType.FILE
                }
              />
            </StyledImage>
          ))}
        </ImageGrid>
        <MessageInput
          onFilesAdd={handleAddFiles}
          onSendMessage={handleUploadFiles}
        />
      </StyledBody>
    </Modal>
  );
};
