import { RegistryFilter } from "@/components/registry/RegistryFilter";
import { IRegistryFilterFormValues } from "@/components/registry/RegistryFilter/hooks";
import { RegistryTable } from "@/components/registry/RegistryTable";
import { TOnSubmitRegistryEditFormCb } from "@/components/RegistryDataFields/hooks";
import { useGetDealerList, useGetDealerListFilters, } from "@/helpers/api/dc/dealers/hooks";
import {
  useExportDealers,
  useGetKiaDealerList,
  useGetKiaDealerListFilters,
  usePatchKiaDealer,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { usePagination } from "@/hooks/usePagination";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { Pagination } from "@/packages/pagination/Pagination";
import styled from "styled-components";
import { handleFileLoad } from "@/helpers/utils";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { HeaderArgType } from "@/packages/stickyTable/StickyTable";
import { SortOrder } from "@/packages/nestedTable/NestedTable";

const StyledPagination = styled(Pagination)`
    margin-left: 40%;
`;

export const RegistryList: React.FC = () => {
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const { t } = useTranslation();

  const { paginationParams, setPage } = usePagination();
  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: "URL страницы скопирован в буфер обмена",
  });

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    name: StringParam,
    city: StringParam,
    warrantyCode: StringParam,
    region: StringParam,
    status: BooleanParam,
    sortBy: StringParam,
    sortAt: StringParam,
  });

  const filters: IRegistryFilterFormValues = {
    name: filterQueryParams.name || undefined,
    city: filterQueryParams.city || undefined,
    warrantyCode: filterQueryParams.warrantyCode || undefined,
    region: filterQueryParams.region || undefined,
    status: filterQueryParams.status ?? true,
  };

  const [requestParams, setRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });

  const { mutateAsync: exportDealers } = useExportDealers();
  const { data: filterOptionsResponse } = useGetDealerListFilters();
  const { data: kiaFilterOptionsResponse } = useGetKiaDealerListFilters();
  const { data: dealers } = useGetDealerList({
    ...paginationParams,
    ...filters,
    sortAt: requestParams.sortAt ?? undefined,
    sortBy: requestParams.sortBy ?? undefined,
  });
  const { data: kiaDealers } = useGetKiaDealerList({
    ...paginationParams,
    ...filters,
    sortAt: requestParams.sortAt ?? undefined,
    sortBy: requestParams.sortBy ?? undefined,
  });


  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      sortAt: order ? order.toUpperCase() : null,
      sortBy: order ? accessor.id : null,
    }));
  };

  const filterOptions = filterOptionsResponse?.data;
  const kiaFilterOptions = kiaFilterOptionsResponse?.data;
  const dealersList = dealers?.data?.data;
  // @ts-ignore
  const kiaDealersList = kiaDealers?.data?.data;

  const dealersTotal = dealers?.data?.meta?.itemCount;
  // @ts-ignore
  const kiaDealersTotal = kiaDealers?.data?.meta?.itemCount;

  const adaptFilterOptions = kiaFilterOptions || filterOptions;
  const adaptDealersList = kiaDealersList || dealersList;

  const { mutateAsync: patchKiaDealer } = usePatchKiaDealer();

  const onEditInTableHandler: TOnSubmitRegistryEditFormCb = async (values) => {
    await patchKiaDealer(values);
  };

  const handleExportAuditsTemplates = () => {
    exportDealers(
      //@ts-ignore
      {
        ...filters,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "dealers.xlsx");
        },
      }
    );
  };

  useEffect(() => {
    setPage(1);
  }, [filterQueryParams]);

  return (
    <DashboardLayout
      title={t("registers.registersDC.title") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleExportAuditsTemplates}
            />
          </Tooltip>

          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>

          <PermissionsAdapter resourceName="dc" resourceAction="create">
            <Link to="/registry/create">
              <Button color="brand" theme="primary" size="middle">
                {t("registers.registersDC.addNew") || ""}
              </Button>
            </Link>
          </PermissionsAdapter>
        </Space>
      }
    >
      <PermissionsAdapter
        resourceName="dc"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        rulesCallback={(hasAction) =>
          hasAction &&
          (profile?.type === "kia" ||
            (profile?.type === "dealer" &&
              Boolean(profile?.role?.name.toLowerCase().includes("холдинг"))))
        }
      >
        <>
          {adaptFilterOptions ? (
            <RegistryFilter
              initValues={filters}
              filterOptions={adaptFilterOptions}
              onFiltersChange={setFilterQueryParams}
            />
          ) : (
            <></>
          )}
          <RegistryTable
            data={adaptDealersList}
            onEdit={onEditInTableHandler}
            handleSort={handleSort}
          />
          <StyledPagination
            current={paginationParams.page}
            onChange={setPage}
            pageSize={10}
            total={dealersTotal || kiaDealersTotal}
            showSizeChanger={false}
          />
        </>
      </PermissionsAdapter>
    </DashboardLayout>
  );
};
