import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { TTransmissionForm, TRANSMISSION_INITIAL_FORM } from "@/pages/Parts";
import { useTranslation } from "react-i18next";

export interface ITransmissionCreateModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (value: TTransmissionForm) => void;
  isLoadingSubmit: boolean;
}

export const TransmissionCreateModal: React.FC<ITransmissionCreateModal> = (
  props
) => {
  const { onSubmit, isLoadingSubmit, ...restProps } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState<TTransmissionForm>(
    TRANSMISSION_INITIAL_FORM
  );

  const transmissionTypeError = !form.transmissionType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      transmissionType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    onSubmit(form);
  };

  useEffect(() => {
    if (!restProps.isOpen) {
      setForm(TRANSMISSION_INITIAL_FORM);
    }
  }, [restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.createTransmission") || ""}
      confirmButtonText={t("parts.create") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.transmission") || ""}
          description={t("parts.transmissionEnter") || ""}
          value={form.transmissionType}
          error={transmissionTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
