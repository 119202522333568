import { useForm } from "react-hook-form";
import {
  CreateGsmFormValues,
  CreateGsmSuccessFormValues,
  TOnSubmitCreateGsmFormCallback,
} from "@/components/MaintenanceGrids/GsmTab/hooks";

export const useCreateExportForm = (
  onSubmit: TOnSubmitCreateGsmFormCallback,
  defaultData?: any
) => {
  const form = useForm<CreateGsmFormValues>({
    mode: "onChange",
    defaultValues: {},
  });

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data as CreateGsmSuccessFormValues);
  });

  return {
    form,
    onSubmitHandler,
  };
};
