import React from "react";
import { StyledIdentityFormCard } from "./index.styles";

export interface IIdentityFormCard {
  className?: string;
  children?: React.ReactNode;
}

export const IdentityFormCard: React.FC<IIdentityFormCard> = (props) => {
  const { className, children } = props;

  return (
    <StyledIdentityFormCard className={className}>
      {children}
    </StyledIdentityFormCard>
  );
};
