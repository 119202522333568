import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useNotification } from "@/hooks/useNotification";
import IdentityLayout from "@/layouts/IdentityLayout";
import { useRetrieveChief } from "@/helpers/api/profile/hooks";
import { useAuthChiefPassword } from "@/helpers/api/auth/hooks";
import { useDirectorRegistrationForm } from "./hooks";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import {
  DirectorRegistrationButton,
  DirectorRegistrationForm,
  DirectorRegistrationTitle,
  DirectorRegistrationWrapper,
} from "./index.style";
import { AxiosError } from "axios";
import { loginToTokenService } from "@/helpers/api/loginToTokenService";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";

export const DirectorRegistration: React.FC = () => {
  const [directorInfo, setDirectorInfo] = useState<string>("");
  const { mutateAsync: getChiefInfo } = useRetrieveChief();
  const { mutateAsync: chiefRegister } = useAuthChiefPassword();
  const { createNotificationError } = useNotification();
  const navigate = useNavigate();
  const { search } = useLocation();
  const token = search.split("?token=")[1];

  const onSubmit = async (data) => {
    try {
      await chiefRegister({
        token: token,
        newPassword: data.password,
      });
      const redirectTo = loginToTokenService.getToken();
      navigate(redirectTo);
    } catch (error) {
      const errorTyped = error as AxiosError<{
        message: string | string[];
      }>;
      createNotificationError(errorTyped?.response?.data.message || "");
    }
  };

  const { form, onSubmitButtonClickHandler } =
    useDirectorRegistrationForm(onSubmit);

  useEffect(() => {
    getChiefInfo({ token: token })
      .then((res) => {
        setDirectorInfo(`${res.data.name} / ${res.data.email}`);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <IdentityLayout>
      <DirectorRegistrationWrapper>
        <DirectorRegistrationTitle level={1} color="black" weight={400}>
          Создание профиля
        </DirectorRegistrationTitle>
        <Paragraph size={14} color="gray200">
          {directorInfo}
        </Paragraph>
        <FormProvider {...form}>
          <DirectorRegistrationForm>
            <FormInputField
              name="password"
              label="Пароль"
              placeholder="Введите пароль"
              type="password"
            />
            <FormInputField
              name="repeatPassword"
              label="Повторите пароль"
              placeholder="Введите пароль"
              type="password"
            />

            <DirectorRegistrationButton
              onClick={onSubmitButtonClickHandler}
              theme="primary"
              color="brand"
              isDisabled={!form.formState.isValid}
              size="middle"
            >
              Сохранить
            </DirectorRegistrationButton>
          </DirectorRegistrationForm>
        </FormProvider>
      </DirectorRegistrationWrapper>
    </IdentityLayout>
  );
};
