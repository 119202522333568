import React, { useMemo, useState } from "react";

import { Space } from "antd";
import { Button } from "@/packages/button/Button";
import { DashboardLayout } from "@/layouts/DashboardLayout";

import { IconButton } from "@/packages/icon-button/IconButton";
import { FlexCalendarTable } from "@/components/KiaFlex/FlexStatus/FlexCalendarTable";
import { FlexStatusFilter } from "@/components/KiaFlex/FlexStatus/FlexStatusFilter";
import {
  DEFAULT_FLEX_STATUS_FILTER_FORM_VALUES,
  IFlexStatusFilterFormValues,
} from "@/components/KiaFlex/FlexStatus/FlexStatusFilter/hooks";
import {
  useExportRentStatus,
  useGetFlexCalendar,
} from "@/helpers/api/flex/vehicles/hooks";
import { RentNewModal } from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight/RentNewModal";
import { useModal } from "@/hooks/useModal";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { handleFileLoad } from "@/helpers/utils";
import {
  ISelectedDayProps,
  TOnDayClick,
} from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight";
import { RentEditModal } from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight/RentEditModal";
import {
  IEditDatePayload,
  TOnEditCLick,
} from "@/components/KiaFlex/FlexStatus/FlexCalendarTable/FlexCalendarTableColumnRight/CalendarRent";
import {
  BooleanParam,
  DateParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

export const KiaFlexStatus: React.FC = () => {
  const [form, setForm] = useState({
    ...DEFAULT_FLEX_STATUS_FILTER_FORM_VALUES,
  });
  const [scrollPage, setScrollPage] = useState<number>(1);
  const [scrollPrevPage, setScrollPrevPage] = useState<number>(1);
  const monthOffset = scrollPage * 12;
  const monthPrevOffset = scrollPrevPage * 6;

  const { t } = useTranslation();

  const date = new Date();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const { mutateAsync: exportRentStatus } = useExportRentStatus();

  const fromDate = useMemo(
    () => new Date(date.setMonth(date.getMonth() - monthPrevOffset)),
    [scrollPrevPage]
  );
  const toDate = useMemo(
    () => new Date(date.setMonth(date.getMonth() + monthOffset)),
    [scrollPage]
  );

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    fromDate: DateParam,
    toDate: DateParam,
    number: StringParam,
    vin: StringParam,
    holding: StringParam,
    dealer: StringParam,
    model: StringParam,
    c2c: BooleanParam,
  });

  const filterParams: IFlexStatusFilterFormValues = {
    fromDate: filterQueryParams.fromDate as Date,
    toDate: filterQueryParams.toDate as Date,
    number: filterQueryParams.number as string,
    vin: filterQueryParams.vin as string,
    holding: filterQueryParams.holding as string,
    dealer: filterQueryParams.dealer as string,
    model: filterQueryParams.model as string,
    c2c: filterQueryParams.c2c as boolean,
  };

  const {
    data: flexCalendarData,
    refetch: refetchFlexCalendar,
    isLoading,
    isRefetching,
  } = useGetFlexCalendar({
    from: form.fromDate ? form.fromDate : fromDate,
    to: form.toDate ? form.toDate : toDate,
    number: form.number,
    vin: form.vin,
    holdingId: form.holding,
    dealerId: form.dealer,
    generationId: form.model,
    // @ts-ignore
    c2c: form.c2c,
    // ...filterParams,
  });

  const handleFilterChange = (data: IFlexStatusFilterFormValues) => {
    setForm(data);
    setFilterQueryParams(data);
  };

  const dealerOptions = useMemo(() => {
    return (
      flexCalendarData?.data.data?.map((dealer) => ({
        label: dealer.name,
        value: dealer.id,
      })) || []
    );
  }, [flexCalendarData?.data.data]);

  const [newRentModalProps, setNewRentModalProps] =
    useState<ISelectedDayProps | null>(null);
  const [modalEditPayload, setModalEditPayload] =
    useState<IEditDatePayload | null>(null);
  const handleDayClick: TOnDayClick = (data) => {
    setNewRentModalProps(data);
    rentOpenModal();
  };

  const {
    modalState: rentModalState,
    openModal: rentOpenModal,
    closeModal: rentCloseModal,
  } = useModal();

  const {
    modalState: rentEditModalState,
    openModal: rentEditModalOpen,
    closeModal: rentEditModalClose,
  } = useModal();

  const handleRentStatusExport = () => {
    exportRentStatus(
      {},
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "flex-rent-status.xlsx");
        },
      }
    );
  };

  const handleCloseModal = () => {
    setNewRentModalProps(null);
    setModalEditPayload(null);
    refetchFlexCalendar();
    rentCloseModal();
    rentEditModalClose();
  };

  const handleOpenEditModal: TOnEditCLick = (payload) => {
    setModalEditPayload(payload);
    rentEditModalOpen();
  };

  return (
    <DashboardLayout
      title={t("kiaFlex.subTitle_2") || ""}
      description={t("kiaFlex.subTitle_2Descr") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleRentStatusExport}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
          <Button
            color="brand"
            size="middle"
            theme="primary"
            onClick={() => rentOpenModal()}
          >
            {t("kiaFlex.createLease") || ""}
          </Button>
        </Space>
      }
    >
      <FlexStatusFilter
        onFiltersChange={handleFilterChange}
        initValues={filterParams}
      />
      {fromDate && toDate ? (
        <FlexCalendarTable
          isLoading={isLoading && !isRefetching}
          data={flexCalendarData?.data.data || []}
          endPeriodDate={toDate}
          startPeriodDate={fromDate}
          scrollPage={scrollPage}
          setScrollPage={setScrollPage}
          setScrollPrevPage={setScrollPrevPage}
          onDayClick={handleDayClick}
          onEditDateClick={handleOpenEditModal}
          startPeriodDateFilters={form.fromDate ? form.fromDate : fromDate}
        />
      ) : (
        <></>
      )}
      {rentModalState.isOpen ? (
        <RentNewModal
          {...rentModalState}
          dealerOptions={dealerOptions}
          closeModal={handleCloseModal}
          startEventForNew={newRentModalProps?.day || null}
          vehicleIdForNew={newRentModalProps?.carItem.id || null}
          dealerIdForNew={newRentModalProps?.unitId || null}
        />
      ) : (
        <></>
      )}
      {!!rentEditModalState.isOpen && modalEditPayload?.id ? (
        <RentEditModal
          {...rentEditModalState}
          id={modalEditPayload.id}
          closeModal={handleCloseModal}
          dealerOptions={dealerOptions}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};
