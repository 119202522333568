import {
  useAddNotificationsToFolder,
  useGetNotifications,
} from "@/helpers/api/notifications/hooks";
import { TGetNotificationsRequest } from "@/helpers/api/notifications/types";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import {
  TableSimple,
  CustomCheckbox,
} from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/helpers/formatDate";
import { usePagination } from "@/hooks/usePagination";
import { Pagination } from "@/packages/pagination/Pagination";
import { t } from "i18next";

const StyledActions = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: flex-end;
  gap: 32px;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TYPE_OPTIONS = [
  { label: "monitoring.all", value: "all" },
  { label: "monitoring.isFavorite", value: "filter[isFavorite]" },
  { label: "monitoring.isArchived", value: "filter[isArchived]" },
  { label: "monitoring.isDeleted", value: "filter[isDeleted]" },
];

const getNotificationsHeader = () => [
  {
    Header: "monitoring.category",
    accessor: "category",
    minWidth: 190,
    maxWidth: 190,
    Cell: ({ value, row }) => {
      const isRead = row.original.isRead;

      const weight = isRead ? 400 : 700;

      return (
        <Paragraph size={14} weight={weight} color="black">
          {value}
        </Paragraph>
      );
    },
  },
  {
    Header: "monitoring.description",
    accessor: "description",
    width: 720,
    Cell: ({ value, row }) => {
      const isRead = row.original.isRead;
      const weight = isRead ? 400 : 600;
      return (
        <Paragraph size={14} weight={weight} color="black">
          {value}
        </Paragraph>
      );
    },
  },
  {
    Header: "monitoring.date",
    accessor: "date",
    width: "150px",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    Header: ({ getToggleAllRowsSelectedProps }) => {
      return (
        <CustomCheckbox
          {...getToggleAllRowsSelectedProps()}
          label={t("dealerMaterials.letters.chooseAll") || ""}
        />
      );
    },
    accessor: "actions",
    width: "150px",
    minWidth: 120,
    maxWidth: 120,
    Cell: ({ row: { getToggleRowSelectedProps } }) => {
      return <CustomCheckbox {...getToggleRowSelectedProps()} />;
    },
  },
];

const Notifications: FC = () => {
  const navigate = useNavigate();
  const { paginationParams, setPage } = usePagination();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [actions, setActions] = useState<TGetNotificationsRequest>({});
  const [isNotificationRead, setIsNotificationRead] = useState(false);
  const { t } = useTranslation();

  const { data: notifications, refetch: notificationsRefetch } =
    useGetNotifications({
      ...actions,
      "page[limit]": paginationParams.limit,
      "page[page]": paginationParams.page,
    });

  //@ts-ignore
  const pageCount = notifications?.data.meta?.itemCount || 0;

  const { mutate: addToFolder } = useAddNotificationsToFolder();

  const notificationReadText = isNotificationRead
    ? t("notifications.unread")
    : t("notifications.read");

  const notificationDeleteText = actions["filter[isDeleted]"]
    ? t("notifications.restore")
    : t("notifications.delete");

  const notificationArchiveText = actions["filter[isArchived]"]
    ? t("notifications.unarchive")
    : t("notifications.archive");

  const notificationFavoriteText = actions["filter[isFavorite]"]
    ? t("notifications.unfavorite")
    : t("notifications.favorite");

  const dataMemoized = useMemo(() => {
    return (
      //@ts-ignore
      notifications?.data.data.map((notification) => {
        return {
          ...notification,
          date: formatDate(notification.createdAt, "dateAndTime"),
        };
      }) || []
    );
  }, [notifications]);

  // useEffect(() => {
  //   const isOpen = localStorage.getItem("isMenuOpen") === "true";
  //   setIsMenuOpen(isOpen);

  //   const storageEventHandler = (event) => {
  //     console.log(event);

  //     if (event.key === "isMenuOpen") {
  //       const isOpen = event.newValue === "true";
  //       setIsMenuOpen(isOpen);
  //       console.log(isMenuOpen);
  //     }
  //   };
  //   window.addEventListener("storage", storageEventHandler);

  //   return () => {
  //     window.removeEventListener("storage", storageEventHandler);
  //   };
  // }, []);

  const headers = getNotificationsHeader();

  const handleCellClick = (cell: { rowIndex: number; accessor: string }) => {
    const { rowIndex, accessor } = cell;

    if (accessor === "actions") return;

    const clickedRow = dataMemoized[rowIndex];

    addToFolder({
      path: "read",
      action: true,
      ids: [clickedRow.id],
    });

    navigate(`/notifications/result/${clickedRow.id}`);
  };

  const handleRowsChecked = (ids: string[]) => {
    if (!ids.length) {
      setIsNotificationRead(false);
      return;
    }

    const clickedRowIds = ids.reduce((acc, id) => {
      const matchNotification = dataMemoized.find(
        (_, index) => Number(id) === index
      );

      // @ts-ignore
      if (matchNotification) acc.push(matchNotification.id);

      return acc;
    }, []);

    const checkedNotificationRead = dataMemoized[Number(ids[0])];

    setIsNotificationRead(checkedNotificationRead.isRead);
    setCheckedIds(clickedRowIds);
  };

  const handleActionButtonClick = (path: string) => {
    if (!checkedIds.length) return;

    const action = {
      delete: !actions["filter[isDeleted]"],
      read: !isNotificationRead,
      archive: !actions["filter[isArchived]"],
      favorite: !actions["filter[isFavorite]"],
    };

    addToFolder({
      path: path,
      action: action[path],
      ids: checkedIds,
    });
  };

  const handleTypeChange = (value: string) => {
    setActions(() =>
      value === "all"
        ? {}
        : {
            [value]: true,
          }
    );
  };

  useEffect(() => {
    setPage(1);
  }, [setPage, actions]);

  useEffect(() => {
    notificationsRefetch();
  }, []);

  return (
    <DashboardLayout title={t("profile.settings.notifications") || ""}>
      <Space direction="vertical" size={24}>
        <StyledActions>
          <SelectField
            label="Тип"
            options={TYPE_OPTIONS}
            defaultValue="all"
            onChange={handleTypeChange}
          />

          <Space align="center" size={32}>
            <ButtonText onClick={() => handleActionButtonClick("delete")}>
              {notificationDeleteText}
            </ButtonText>
            <ButtonText
              onClick={() => handleActionButtonClick("archive")}
              isDisabled={!!actions["filter[isDeleted]"]}
            >
              {notificationArchiveText}
            </ButtonText>
            <ButtonText
              onClick={() => handleActionButtonClick("read")}
              isDisabled={!!actions["filter[isDeleted]"]}
            >
              {notificationReadText}
            </ButtonText>
            <ButtonText
              onClick={() => handleActionButtonClick("favorite")}
              isDisabled={!!actions["filter[isDeleted]"]}
            >
              {notificationFavoriteText}
            </ButtonText>
          </Space>
        </StyledActions>

        <TableSimple
          // @ts-ignore
          headers={headers}
          data={dataMemoized}
          onCellClick={handleCellClick}
          onRowsChecked={handleRowsChecked}
          defaultColumn={{
            minWidth: 80,
            width: 120,
            maxWidth: 720,
          }}
        />
        {pageCount > 1 ? (
          <StyledPagination
            onChange={setPage}
            current={paginationParams.page}
            pageSize={10}
            total={pageCount}
          />
        ) : null}
      </Space>
    </DashboardLayout>
  );
};

export { Notifications };
