import { accessTokenService } from "@/helpers/api/accessTokenService";
import { api } from "@/helpers/api/axiosInstance";
import { TGetProfileResponse } from "@/helpers/api/profile/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { TGetPublicRoleListRequest, TGetRoleListRequest, TGetRoleListResponse } from "./types";

export const useGetPublicRoleList = (request?: TGetPublicRoleListRequest) => {
  return useQuery(
    ["roles", "list", "public", request?.["filter[isShowHoldingRoles]"]],
    () => {
      return api.get<TGetRoleListResponse>("/public/roles", { params: request });
    },
    { enabled: !Boolean(accessTokenService.getToken()) }
  );
};

export const useGetRoleListForDealer = (isEnabledProp?: boolean) => {
  const queryClient = useQueryClient();
  const hasToken = Boolean(accessTokenService.getToken());

  const isEnabled = Boolean(
    hasToken &&
    (
      queryClient.getQueryData([
        "profile",
        accessTokenService.getToken(),
      ]) as AxiosResponse<TGetProfileResponse>
    )?.data?.type === "dealer"
  );

  return useQuery(
    ["roles", "list"],
    () => {
      return api.get<TGetRoleListResponse>("/roles/for/dealers");
    },
    { enabled: isEnabledProp ? isEnabledProp : isEnabled }
  );
};

export const useGetPublicRoleListWithAuth = () => {
  return useQuery(
    ["roles", "list", "public"],
    () => {
      return api.get<TGetRoleListResponse>("/public/roles");
    },
    { enabled: Boolean(accessTokenService.getToken()) }
  );
};

export const useGetRoleList = (params?:TGetRoleListRequest) => {
  return useQuery(
    ["roles", "list", params],
    () => {
      return api.get<TGetRoleListResponse>("/roles", {
        params
      });
    },
  );
};
