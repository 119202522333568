const baseColors = {
  white: "#ffffff",
  black: "#353333",
  background: "#f3f4f7",
  black10: "#05141F",
  gray900: "#111111",
  gray800: "#333333",
  gray700: "#282A30",
  gray600: "#707787",
  gray500: "#A4ABB5",
  gray400: "#c5c8cd",
  gray300: "#E4E7EC",
  gray200: "#939598",
  gray100: "#6D6E71",
  gray40: "#F3F3F3",
  gray30: "#EEEFF2",
  gray20: "#EFEFEF",
  gray10: "#F5F5F5",
  gray: "#6D6E71",
  cyan: "#4595ff",
  orange: "#FFA524",
  red200: "#FF7875",
  pink: "#E00751",
  gray3: "#C7C8CA",
  gray4: "#8E8EA9",
  gray5: "#575858",
  gray6: "#949494",
  purple200: "#6F7CD4",
  red: "#EA0029",
  red10: "#B72B1A",
  red20: "#F5C0B8",
  red30: "#FCECEA",
  yellow: "#F3C300",
  green: "#5D7D2B",
  green100: "#62BB2C",
  table: "#EEEFF2",
  whiteSecondary: "#F9F9FD",
  blackSecondary: "#282A30",
  chay: "#0AB697",
  blue: "#487BFF",
  blue100: "#EBF1FF",
  defaultBlack: "#000000",
  transparent: "transparent",
  stroke4HeaderTable: "#DBDBDB",
  orangeStatus: "#DE8500",
  redStatus10: "#E32446",
  greenStatus10: "#62BB2C",
  greenStatusBg: "#B5D880",
  greenStatus: "#2F4A04",
  redStatusBg: "#FCECEA",
  redStatus: "#B72B1A",
  grayStatusBg: "#DBDCE0",
  grayStatus: "#6D6E71",
  pinkStatusBg: "#FEA1A1",
  pinkStatus: "#800202",
  purpleStatusBg: "#E9D1F9",
  purpleStatus: "#9634D4",
  yellowStatusBg: "#F5DA69",
  yellowStatus: "#B06E0A",
  cyanStatusBg: "#A0E4FA",
  cyanStatus: "#107696",
  scarletStatusBg: "#E32446",
  scarletStatus: "#7C0909",
  midnightBlack: "#05141F",
  neutral800: "#32324D",
  neutral150: "#EAEAEF",
  neutral400: "#A5A5BA",
  photoBG: "#f8f9fb",
};

const extendedColors = {
  brand: baseColors.midnightBlack,
  error: baseColors.pink,
  warning: baseColors.orange,
  buttonPrimary: baseColors.blue,
  buttonSecondary: baseColors.black,
};

export const colors = { ...baseColors, ...extendedColors };

export type TColorName = keyof typeof colors;
