import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../../axiosInstance";
import {
  TFlexQuotasCreateRequest,
  TFlexQuotasCreateResponse,
  TFlexQuotasPatchRequest,
  TFlexQuotasPatchResponse,
  TGetFlexQuotasRequest,
  TGetFlexQuotasRequestById,
  TGetFlexQuotasResponse,
  TGetFlexQuotasResponseById,
} from "./types";

export const useGetFlexQuotasList = (request: TGetFlexQuotasRequest) => {
  return useQuery(
    ["quotas", "list", request],
    () => {
      return api.get<TGetFlexQuotasResponse>("/flex/quotas", {
        params: request,
      });
    },
    { cacheTime: 0 }
  );
};

export const useExportFlexQuotas = () => {
  return useMutation((request: any) => {
    return api.get<any>("/flex/quotas/export", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useGetFlexQuotasById = (id: TGetFlexQuotasRequestById) => {
  return useQuery(
    ["quotas", "detail", id],
    () => {
      return api.get<TGetFlexQuotasResponseById>(`/flex/quotas/${id}`);
    },
    { cacheTime: 0 }
  );
};

export const useCreateFlexQuotas = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      onSuccess,
      onError,
    }: {
      data: TFlexQuotasCreateRequest;
        onSuccess: () => void;
        onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.post<TFlexQuotasCreateResponse>("/flex/quotas", data);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const usePatchFlexQuotas = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      patch,
      onError,
      onSuccess,
    }: {
      id: TFlexQuotasPatchRequest["id"];
      patch: TFlexQuotasPatchRequest["patch"];
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TFlexQuotasPatchResponse>(`/flex/quotas/${id}`, patch);
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};
