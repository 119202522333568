import React, { useEffect, useState } from "react";
import { useMq } from "@/hooks/index";
import {
  StyledContent,
  StyledContentDescription,
  StyledContentHeader,
  StyledContentHeaderLeft,
  StyledContentHeaderWrapper,
  StyledContentTitle,
  StyledContentTitleHeaderLeft,
  StyledContentWrap,
  StyledDashboardLayout,
} from "./index.styles";
import { DashboardLayoutNavBar } from "./MainLayoutNavBar";
import { BackLink } from "./BackLink";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/packages/loader/Loader";
import { useTranslation } from "react-i18next";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export type DashboardLayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  toBack?: string | number;
  isLoading?: boolean;
  disableScrollToTop?: boolean;
  hasMobile?: boolean;
  noIndent?: boolean;
};

export const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const {
    children,
    title,
    description,
    headerLeft,
    headerRight,
    toBack,
    isLoading = false,
    disableScrollToTop = false,
    hasMobile = false,
    noIndent = false,
  } = props;
  const menuState = localStorage.getItem("isMenuOpen");
  const mq = useMq();
  const { ready } = useTranslation();

  const enableMobile = hasMobile && mq !== "desktop";
  const [isNavOpen, setIsNavOpen] = React.useState(menuState === "true");

  useEffect(() => {
    setIsNavOpen(menuState === "true");
  }, []);

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>();

  useEffect(() => {
    if (!disableScrollToTop) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const onMenuToggle = () => {
    setIsNavOpen((prev) => {
      localStorage.setItem("isMenuOpen", !prev + "");
      return !prev;
    });
  };
  const onGoToNotificationsButtonClick = () => navigate("/notifications");

  return ready ? (
    <StyledDashboardLayout>
      {!enableMobile ? (
        <>
          <DesktopHeader
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onGoToNotificationsButtonClick={onGoToNotificationsButtonClick}
          />
          <DashboardLayoutNavBar
            isOpen={isNavOpen}
            onMenuToggle={onMenuToggle}
          />
        </>
      ) : (
        <MobileHeader/>
      )}
      <StyledContent hasMobile={hasMobile}>
        {!isLoading ? (
          <StyledContentWrap>
            {toBack || title || description || headerLeft || headerRight ? (
              <StyledContentHeader noIndent={noIndent}>
                <StyledContentHeaderLeft>
                  {toBack ? <BackLink to={toBack}/> : <></>}
                  {title || headerLeft ? (
                    <StyledContentTitleHeaderLeft>
                      {title ? (
                        <StyledContentTitle level={1} weight={500}>
                          {title}
                        </StyledContentTitle>
                      ) : (
                        <></>
                      )}
                      {headerLeft ? (
                        <StyledContentHeaderWrapper>
                          {headerLeft}
                        </StyledContentHeaderWrapper>
                      ) : (
                        <></>
                      )}
                    </StyledContentTitleHeaderLeft>
                  ) : (
                    <></>
                  )}
                  {description ? (
                    <StyledContentDescription
                      color="gray200"
                      size={16}
                      weight={300}
                    >
                      {description}
                    </StyledContentDescription>
                  ) : (
                    <></>
                  )}
                </StyledContentHeaderLeft>

                {headerRight ? (
                  <StyledContentHeaderWrapper>
                    {headerRight}
                  </StyledContentHeaderWrapper>
                ) : (
                  <></>
                )}
              </StyledContentHeader>
            ) : (
              <></>
            )}

            {children}
          </StyledContentWrap>
        ) : (
          <Loader size={48}/>
        )}
      </StyledContent>
    </StyledDashboardLayout>
  ) : (
    <Loader size={48}/>
  );
};
