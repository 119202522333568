import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum MonitoringCarsFilterFormFieldsNameEnum {
  CityIds = "cityIds",
  DealerIds = "dealerIds",
  Vins = "vins",
  GenerationIds = "generationIds",
  OnlyUnreadMessages = "onlyUnreadMessages",
}

export enum MonitoringCarsFilterOptionsNameEnum {
  Cities = "cities",
  Dealers = "dealers",
  Generations = "generations",
  Vins = "vins",
}

export interface IMonitoringCarsFilterOptions {
  [MonitoringCarsFilterOptionsNameEnum.Cities]:
    | { value: string; label: string }[]
    | undefined;
  [MonitoringCarsFilterOptionsNameEnum.Dealers]:
    | { value: string; label: string }[]
    | undefined;
  [MonitoringCarsFilterOptionsNameEnum.Generations]:
    | { value: string; label: string }[]
    | undefined;
  [MonitoringCarsFilterOptionsNameEnum.Vins]:
    | { value: string; label: string }[]
    | undefined;
}

export interface IMonitoringCarsFilterFormValues {
  [MonitoringCarsFilterFormFieldsNameEnum.CityIds]: string | undefined;
  [MonitoringCarsFilterFormFieldsNameEnum.DealerIds]: string | undefined;
  [MonitoringCarsFilterFormFieldsNameEnum.Vins]: string | undefined;
  [MonitoringCarsFilterFormFieldsNameEnum.GenerationIds]: string | undefined;
  [MonitoringCarsFilterFormFieldsNameEnum.OnlyUnreadMessages]: false;
}

export const DEFAULT_MONITORING_CARS_FILTER_FORM_VALUES: IMonitoringCarsFilterFormValues =
  {
    [MonitoringCarsFilterFormFieldsNameEnum.CityIds]: undefined,
    [MonitoringCarsFilterFormFieldsNameEnum.DealerIds]: undefined,
    [MonitoringCarsFilterFormFieldsNameEnum.Vins]: undefined,
    [MonitoringCarsFilterFormFieldsNameEnum.GenerationIds]: undefined,
    [MonitoringCarsFilterFormFieldsNameEnum.OnlyUnreadMessages]: false,
  };

export type TOnSubmitMonitoringFilterFormCb = TOnSubmitFormSyncCb<any>;

export const useMonitoringCarsFilterForm = (options: {
  onSubmit: TOnSubmitMonitoringFilterFormCb;
  initValues: IMonitoringCarsFilterOptions;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<any>({
    defaultValues: DEFAULT_MONITORING_CARS_FILTER_FORM_VALUES,
    onSubmit,
  });

  useEffect(() => {
    result.form.reset(initValues, { keepDefaultValues: true });
  }, []);

  return result;
};
