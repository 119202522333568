import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";
import { Card } from "@/packages/Card";
import styled from "styled-components";
import { Paragraph } from "@/packages/paragraph/Paragraph";

export const StyledNewsCard = styled(Card)`
  padding: 0;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  max-width: 75rem;
`;

export const StyledPhoto = styled.img`
  background-color: #e7eaff;
  object-fit: cover;
  width: 500px;
  margin: 50px;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
  flex-grow: 1;
`;

export const StyledInfoContainer = styled.div`
  padding: 50px 50px 50px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 3;
`;

export const StyledPostContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledActionsContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  width: 141px;
`;

export const StyledTextContainer = styled.div`
  margin-top: 12px;
`;

export const StyledNewsText = styled(Paragraph)`
  white-space: pre-wrap;
  word-wrap: break-word;
  table-layout: fixed;
  max-width: 550px;
`;
