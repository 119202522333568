import React, { useMemo } from "react";
import { useGetPublicRoleList, useGetRoleListForDealer, } from "@/helpers/api/roles/hooks";
import { ISelectField, SelectField, } from "@/packages/formElements/fields/SelectField";

export interface IRoleSelectAdapter extends Omit<ISelectField, "options"> {
  isShowHoldingRoles?: boolean;
}

export const RoleSelectAdapter: React.FC<IRoleSelectAdapter> = (props) => {
  const { data: roleList } = useGetRoleListForDealer(true);
  const { data: publicRoleList } = useGetPublicRoleList(
    {
      "filter[isShowHoldingRoles]": props.isShowHoldingRoles
    }
  );
  const adaptRoleList = roleList?.data || publicRoleList?.data;

  const rolesOptions = useMemo(() => {
    if (adaptRoleList?.data) {
      return adaptRoleList?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
  }, [adaptRoleList]);

  return <SelectField options={rolesOptions} {...props} />;
};
