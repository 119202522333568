import { FC } from "react";

import {
  IInputNumber,
  InputNumber,
} from "@/packages/formElements/inputs/InputNumber";
import { Field } from "../Field";

export interface IInputNumberField extends Omit<IInputNumber, "error"> {
  label?: string;
  error?: string;
  description?: string;
  isErrorAbsolute?: boolean;
}

export const InputNumberField: FC<IInputNumberField> = (props) => {
  const { label, error, description, isErrorAbsolute, ...inputProps } = props;

  return (
    <Field>
      <Field.Label disabled={inputProps.disabled}>{label}</Field.Label>

      <Field.FieldControlContainer>
        <InputNumber {...inputProps} error={!!error} />
      </Field.FieldControlContainer>

      {error ? (
        <Field.FieldError isErrorAbsolute={isErrorAbsolute}>
          {error}
        </Field.FieldError>
      ) : (
        <></>
      )}

      {!error && description ? (
        <Field.FieldDescription>{description}</Field.FieldDescription>
      ) : (
        <></>
      )}
    </Field>
  );
};
