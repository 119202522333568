import { useMutation } from "@tanstack/react-query";
import { api } from "@/helpers/api/axiosInstance";

export const useExportGrids_1 = () => {
  return useMutation((request: any) => {
    return api.get<any>("/maintenance-grids/export-sort-work-type", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportGrids_2 = () => {
  return useMutation((request: any) => {
    return api.get<any>("/maintenance-grids/export-work-spare-material-price", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportGrids_3 = () => {
  return useMutation((request: any) => {
    return api.get<any>("/maintenance-grids/export-replacement-spare", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportGrids_4 = () => {
  return useMutation((request: any) => {
    return api.get<any>("/maintenance-grids/export-used-spares", {
      params: request,
      responseType: "blob",
    });
  });
};

export const useExportGrids_5 = () => {
  return useMutation((request: any) => {
    return api.get<any>("/maintenance-grids/export-sort-models", {
      params: request,
      responseType: "blob",
    });
  });
};
