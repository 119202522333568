import { IModal, Modal } from "@/packages/modal/Modal";
import { Space } from "antd";
import { v4 as uuidv4 } from "uuid";

import { FC, useCallback, useEffect, useState } from "react";

import { IconButton } from "@/packages/icon-button/IconButton";

import { StyledFormEditBatchModal, StyledModalFields } from "./index.styles";
import Tree from "./Tree";
import { useAddSection } from "@/helpers/api/audit-parametrs/hooks";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

export interface IAuditEditBatchModal
  extends Pick<IModal, "onCancel" | "isOpen"> {
  onSubmit: any;
  data: any[];
  templateId: number;
}

const AuditEditBatchModal: FC<IAuditEditBatchModal> = (props) => {
  const { data, templateId, onCancel, isOpen, onSubmit } = props;

  const [sections, setSections] = useState(data);
  const { mutate: addSection, isLoading: isCreating } = useAddSection();
  const [payload, setPayload] = useState<any>([]);
  const { createNotificationError } = useNotification();
  const onSubmitHandler = () => {
    addSection({
      data: {
        templateId,
        data: payload,
      },
      onSuccess: onSubmit,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const onActionHandler = (event: "edit" | "add" | "delete", section?: any) => {
    const { id, title } = section;
    switch (event) {
      case "edit":
        const copyPayload = [...payload];
        const objIndex = copyPayload.findIndex((el) => el.payload[0].id === id);

        if (objIndex !== -1) {
          copyPayload[objIndex].payload[0].title = title;
          return setPayload(copyPayload);
        }
        setPayload((prev) => [...prev, { event: "edit", payload: [section] }]);

        break;
      case "add":
        setPayload((prev) => [...prev, { event: "add", payload: [section] }]);
        break;
      case "delete":
        setPayload((prev) => [
          ...prev,
          { event: "delete", payload: [section] },
        ]);
        break;
    }
  };

  const addSectionHandler = useCallback(() => {
    const newSection = {
      id: uuidv4(),
      title: "",
      depth: 0,
      parentId: null,
      level: sections.length + 1,
      order: sections.length,
    };

    setSections([...sections, newSection]);
    onActionHandler("add", newSection);
  }, [setSections, sections]);
  const { t } = useTranslation();
  useEffect(() => {
    setSections(data);
    return () => {
      setPayload([]);
    };
  }, [data]);

  return (
    <Modal
      {...props}
      title={t("dealerMaterials.letters.editParts") || ""}
      confirmButtonText={t("dealerMaterials.letters.save") || ""}
      cancelButtonText={t("dealerMaterials.letters.cancel") || ""}
      onSubmit={onSubmitHandler}
      onCancel={() => onCancel && onCancel()}
      isLoadingConfirmButton={isCreating}
    >
      <StyledModalFields>
        <StyledFormEditBatchModal>
          <Space align="center">
            <IconButton
              variant="tertiary"
              icon="plus"
              color="brand"
              onClick={addSectionHandler}
            >
              {t("dealerMaterials.letters.addSection") || ""}
            </IconButton>
          </Space>
          <DndProvider backend={HTML5Backend}>
            <Tree
              data={sections}
              sections={sections}
              setSections={setSections}
              onActionHandler={onActionHandler}
            />
          </DndProvider>
        </StyledFormEditBatchModal>
      </StyledModalFields>
    </Modal>
  );
};

export { AuditEditBatchModal };
