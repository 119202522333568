import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { HoldingCreateModal } from "@/components/holdings/HoldingCreateModal";
import { HoldingEditModal } from "@/components/holdings/HoldingEditModal";
import { HoldingsFilter } from "@/components/holdings/HoldingsFilter";
import { IHoldingsFilterFormValues } from "@/components/holdings/HoldingsFilter/hooks";
import {
  HoldingsTable,
  IHoldingTable,
} from "@/components/holdings/HoldingsTable";
import { useSimpleDictCreateModal } from "@/components/SimpleDictCreateModal/hooks";
import { useSimpleDictEditModal } from "@/components/SimpleDictEditModal/hooks";
import {
  useCreateKiaHolding,
  useDeleteKiaHolding,
  useExportKiaHoldings,
  useGetKiaHoldingList,
  usePatchKiaHolding,
} from "@/helpers/api/dc/kia-holdings/hooks";
import { handleFileLoad } from "@/helpers/utils";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { usePermission } from "@/hooks/usePermission";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Space } from "antd";
import React, { useContext, useMemo } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

export const Holdings: React.FC = () => {
  const { t } = useTranslation();

  const {
    state: { profile },
  } = useContext(ContextProfile);
  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("registers.holdingsDC.successMessage") || "",
  });

  const { hasAccess } = usePermission("holding", "create");

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const {
    mutateAsync: createKiaHoldingAsync,
    isLoading: isCreateHoldingLoading,
  } = useCreateKiaHolding();
  const {
    mutateAsync: patchKiaHoldingAsync,
    isLoading: isPatchHoldingLoading,
  } = usePatchKiaHolding();
  const { mutateAsync: deleteKiaHoldingAsync } = useDeleteKiaHolding();

  const {
    modalState: holdingCreateModalState,
    openModal: openHoldingCreateModal,
  } = useSimpleDictCreateModal((close) => async (value) => {
    try {
      if (value) {
        await createKiaHoldingAsync({ name: value });
        createNotificationSuccess(
          t("registers.holdingsDC.createNotificationSuccessCreate") || ""
        );
      } else {
        createNotificationError(
          t("registers.holdingsDC.createNotificationErrorCreateName") || ""
        );
      }

      close();
    } catch {
      createNotificationError(
        t("registers.holdingsDC.createNotificationErrorCreate") || ""
      );
    }
  });

  const { modalState: holdingEditModalState, openModal: openHoldingEditModal } =
    useSimpleDictEditModal((close) => async (record) => {
      try {
        await patchKiaHoldingAsync({
          id: record.id,
          patch: { name: record.value },
        });
        createNotificationSuccess(
          t("registers.holdingsDC.createNotificationSuccessChange") || ""
        );
        close();
      } catch {
        createNotificationError(
          t("registers.holdingsDC.createNotificationErrorChange") || ""
        );
      }
    });

  const { paginationParams, setPage } = usePagination();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    holdingName: StringParam,
    dealerWarrantyCode: StringParam,
  });

  const filterParams: IHoldingsFilterFormValues = {
    holdingName: filterQueryParams.holdingName || undefined,
    dealerWarrantyCode: filterQueryParams.dealerWarrantyCode || undefined,
  };

  const { mutateAsync: exportHoldings } = useExportKiaHoldings();

  const { data: holdingListResponse, isLoading } = useGetKiaHoldingList({
    ...paginationParams,
    ...filterParams,
  });

  const { data: holdingListFilters } = useGetKiaHoldingList({
    limit: 0,
  });

  const holdings = holdingListResponse?.data.data ?? [];

  const holdingsOptions = useMemo(() => {
    return (
      holdingListFilters?.data?.data?.map((holding) => ({
        value: holding.name,
        label: holding.name,
      })) || []
    );
  }, [holdingListFilters?.data?.data]);

  const onEditClickHandler: IHoldingTable["onEditClick"] = (record) => {
    openHoldingEditModal({
      id: record.id,
      value: record.name,
    });
  };

  const onDeleteClickHandler: IHoldingTable["onDeleteClick"] = (record) => {
    deleteKiaHoldingAsync({
      id: record.id,
    });
  };

  const handleExportHoldings = () => {
    exportHoldings(filterParams, {
      onSuccess: (response) => {
        handleFileLoad(response.data as Blob, "holdings.xlsx");
      },
    });
  };

  return (
    <DashboardLayout
      title={t("registers.holdingsDC.title") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleExportHoldings}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
        </Space>
      }
    >
      <PermissionsAdapter
        resourceName="holding"
        resourceAction="read"
        fallback={() => <span>No rights</span>}
        rulesCallback={(hasAction) => hasAction && profile?.type === "kia"}
      >
        <>
          {holdingsOptions ? (
            <HoldingsFilter
              initValues={filterParams}
              filterOptions={holdingsOptions}
              onFiltersChange={setFilterQueryParams}
            />
          ) : (
            <></>
          )}
          <HoldingsTable
            isLoading={isLoading}
            paginationParams={paginationParams}
            setPage={setPage}
            pageCount={holdingListResponse?.data.meta?.pageCount || 0}
            data={holdings}
            onEditClick={onEditClickHandler}
            onDeleteClick={onDeleteClickHandler}
            filterQueryParams={filterQueryParams}
          />
          {hasAccess ? (
            <Button
              color="brand"
              theme="primary"
              size="middle"
              onClick={openHoldingCreateModal}
            >
              {t("registers.holdingsDC.addHolding") || ""}
            </Button>
          ) : (
            <></>
          )}
        </>
      </PermissionsAdapter>

      <HoldingCreateModal
        {...holdingCreateModalState}
        isSubmitLoading={isCreateHoldingLoading}
      />
      <HoldingEditModal
        {...holdingEditModalState}
        isSubmitLoading={isPatchHoldingLoading}
      />
    </DashboardLayout>
  );
};
