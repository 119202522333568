import styled from "styled-components";
import { Field } from "@/packages/formElements/fields/Field";
import { Input } from "@/packages/formElements/inputs/Input";

export const StyledFieldControlContainer = styled(Field.FieldControlContainer)`
  display: flex;
  gap: 12px;
`;

export const StyledInput = styled(Input)`
  flex: 1;
`;
