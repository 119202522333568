import { Space } from "antd";
import React, { memo, useCallback, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { NewsDataFields } from "@/components/NewsDataFields";
import { useCreateNewsForm } from "@/components/NewsDataFields/hooks";
import { newsFormValuesToDTO } from "@/components/NewsDataFields/utils";
import { useCreateNews } from "@/helpers/api/news/hooks";
import { useNotification } from "@/hooks/useNotification";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { useTranslation } from "react-i18next";

const _NewsCreate: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // TODO: Некорректно работает isSubmitting в useForm, добавил локальный флаг загрузки
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { mutateAsync: createNewsAsync } = useCreateNews();

  const onCreateNewsHandler: any = async (data) => {
    try {
      setIsLoadingData(true);
      const response = await createNewsAsync(newsFormValuesToDTO(data));
      setIsLoadingData(false);
      createNotificationSuccess(
        t("news.addNewsForm.createNotificationSuccess") || ""
      );
      //@ts-ignore
      navigate(`/news?activeTab=${response.data.group_id}`);
    } catch (e) {
      setIsLoadingData(false);
      createNotificationError(
        t("news.addNewsForm.createNotificationError") || ""
      );
      throw e;
    }
  };

  const { form, submit, isSubmitLoading } =
    useCreateNewsForm(onCreateNewsHandler);

  const onSubmitHandler = useCallback(() => {
    if (!submit) return;

    submit();
  }, [submit]);

  return (
    <DashboardLayout title={t("news.addNewsForm.title") || ""} toBack="/news">
      <PermissionsAdapter
        resourceName="news"
        resourceAction="create"
        fallback={() => <p>Access denied</p>}
      >
        <FormProvider {...form}>
          <NewsDataFields />
        </FormProvider>
        <Space>
          <Button
            theme="primary"
            color="brand"
            size="middle"
            onClick={onSubmitHandler}
            isLoading={isSubmitLoading || isLoadingData}
          >
            {t("news.addNewsForm.publish") || ""}
          </Button>
        </Space>
      </PermissionsAdapter>
    </DashboardLayout>
  );
};

export const NewsCreate = memo(_NewsCreate);
