import React from "react";
import { ISelectField, SelectField, } from "@/packages/formElements/fields/SelectField";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { TDealerRecord } from "@/helpers/api/dc/dealers/types";

export interface IDealerSelectAdapter
  extends Omit<ISelectField, "options" | "onChange"> {
  setSearchValueDealerList: React.Dispatch<React.SetStateAction<string>>;
  dealerList?: TDealerRecord[];
}

export const DealerSelectAdapter: React.FC<IDealerSelectAdapter> = (props) => {
  const { dealerList } = props;

  const options = dealerList?.map(
    (record) =>
      ({
        value: record.id,
        label: `${record.sapCode} / ${record.mobisCode} / ${record.merchantName}`,
      } as ISelectOption)
  );

  return <SelectField options={options} {...props}/>;
};
