import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TCreateInformationLetterRequest,
  TCreateInformationLetterResponse,
  TDeleteInformationLetterRequest,
  TDeleteInformationLetterResponse,
  TExportInformationLettersStatisticRequest,
  TExportInformationLettersStatisticResponse,
  TGetInformationLetterByIdRequest,
  TGetInformationLetterByIdResponse,
  TGetInformationLettersRequest,
  TGetInformationLettersResponse,
  TGetInformationLettersSectionsRequest,
  TGetInformationLettersSectionsResponse,
  TGetInformationLettersStatisticRequest,
  TGetInformationLettersStatisticResponse,
  TPatchInformationLetterBatchRequest,
  TPatchInformationLetterBatchResponse,
  TPatchInformationLetterRequest,
  TPatchInformationLetterResponse,
  TPatchInformationLetterStatisticStatusRequest,
  TPatchInformationLetterStatisticStatusResponse,
  TPostInformationLetterStatisticRequest,
  TPostInformationLetterStatisticResponse,
} from "./types";

export const useGetInformationLettersSectionsBatch = (request: TGetInformationLettersRequest) => {
  return useQuery(["information-letters", "sections", "batch", request], () => {
    return api.get<TGetInformationLettersSectionsResponse>(
      "/information-letters-sections/batch",
      {
        params: request,
      }
    );
  });
};

export const useGetInformationLettersSections = (
  request: TGetInformationLettersSectionsRequest
) => {
  return useQuery(["information-letters", "sections"], () => {
    return api.get<TGetInformationLettersSectionsResponse>(
      "/information-letters-sections",
      {
        params: request,
      }
    );
  });
};

export const useGetInformationLetters = (
  request: TGetInformationLettersRequest
) => {
  return useQuery(["information-letters", "list", request], () => {
    return api.get<TGetInformationLettersResponse>("/information-letters", {
      params: request,
    });
  });
};

export const useCreateInformationLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TCreateInformationLetterRequest) => {
      return api.post<TCreateInformationLetterResponse>(
        "/information-letters",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["information-letters", "list"]);
      },
    }
  );
};

export const useGetInformationLetterById = (
  request: TGetInformationLetterByIdRequest
) => {
  return useQuery(["information-letters", "list", request.id], () => {
    return api.get<TGetInformationLetterByIdResponse>(
      `/information-letters/${request.id}`
    );
  });
};

export const usePatchInformationLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, patch }: TPatchInformationLetterRequest) => {
      return api.patch<TPatchInformationLetterResponse>(
        `/information-letters/${id}`,
        patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "information-letters",
          "detail",
          variables.id,
        ]);
        queryClient.invalidateQueries(["information-letters", "list"]);
      },
    }
  );
};

export const useDeleteInformationLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TDeleteInformationLetterRequest) => {
      return api.delete<TDeleteInformationLetterResponse>(
        `/information-letters/${data.id}`
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "information-letters",
          "detail",
          variables.id,
        ]);
        queryClient.invalidateQueries(["information-letters", "list"]);
      },
    }
  );
};

export const usePatchInformationLetterBatch = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPatchInformationLetterBatchRequest) => {
      return api.patch<TPatchInformationLetterBatchResponse>(
        "/information-letters-sections",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "information-letters",
          "sections",
          "batch",
        ]);
        queryClient.invalidateQueries(["information-letters", "detail"]);
        queryClient.invalidateQueries(["information-letters", "list"]);
      },
    }
  );
};

export const useDeleteInformationLetterBatch = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TPatchInformationLetterBatchRequest) => {
      return api.delete<TPatchInformationLetterBatchResponse>(
        "/information-letters-sections",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["information-letters", "detail"]);
        queryClient.invalidateQueries(["information-letters", "list"]);
      },
    }
  );
};

export const useGetInformationLettersStatistic = (
  request: TGetInformationLettersStatisticRequest
) => {
  return useQuery(["information-letters-statistic", "list", request], () => {
    return api.get<TGetInformationLettersStatisticResponse>(
      "/information-letters-statistic",
      { params: request }
    );
  });
};

export const usePatchInformationLetterStatisticStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: TPatchInformationLetterStatisticStatusRequest) => {
      return api.patch<TPatchInformationLetterStatisticStatusResponse>(
        `/information-letters-statistic/${request.id}`,
        request.patch
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "information-letters-statistic",
          variables.id,
        ]);
      },
    }
  );
};

export const usePostInformationLetterAgain = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ letterId, dealerId }: TPostInformationLetterStatisticRequest) => {
      return api.post<TPostInformationLetterStatisticResponse>(
        `/information-letters-statistic?letterId=${letterId}&dealerId=${dealerId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "information-letters-statistic",
          "list",
        ]);
      },
    }
  );
};

export const useExportInformationLetterStatistic = () => {
  return useMutation((request: TExportInformationLettersStatisticRequest) => {
    return api.get<TExportInformationLettersStatisticResponse>(
      `/information-letters-statistic/export`,
      {
        params: request,
        responseType: "blob",
      }
    );
  });
};
