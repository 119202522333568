import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  IImageDropzoneField,
  ImageDropzoneField,
} from "@/packages/formElements/fields/ImageDropzoneField";

export interface IFormImageDropzoneField
  extends Omit<IImageDropzoneField, "error" | "value"> {
  name: string;
  isAllowFilesUpload?: boolean;
}

const _FormImageDropzoneField: React.FC<IFormImageDropzoneField> = (props) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <ImageDropzoneField
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};

export const FormImageDropzoneField = React.memo(_FormImageDropzoneField);
