import { colors } from "@/helpers";
import { TAuditMessage } from "@/helpers/api/audit-chat/types";
import { TMonitoringQualityCarMessageRecord } from "@/helpers/api/monitoringQuality/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import React, { RefObject, useRef, useState } from "react";
import styled from "styled-components";
import { ISendMessage } from "..";
import { MessageTextarea } from "./MessageTextarea";

const StyledMessageInput = styled.div<{ hasReply: boolean }>`
  width: 100%;
  min-height: 52px;
  padding: 14px 32px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: ${(hasReply) => (hasReply ? "29px" : "0")};
  border-top: 0.6px solid #d7dbe2;
`;

const StyledAntiContainer = styled.div<{ isContainer?: boolean }>`
  ${({ isContainer }) =>
    isContainer
      ? "margin: 0"
      : `margin-left: -32px;
        margin-right: -32px;
        margin-bottom: -24px;
        margin-top: 24px;
 `}
`;

const StyledReply = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eeeff2;
  background-color: ${colors.white};
  padding: 6px 34px;
  margin-bottom: -24px;
  margin-top: 10px;
`;

const StyledMessageBody = styled.div`
  margin-left: 18px;
  padding-left: 8px;
  width: 100%;
`;

const StyledReplyMessage = styled(Paragraph)`
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 390px;
  height: 16px;
  white-space: nowrap;
`;

interface IMessageInput {
  reply?: TAuditMessage | TMonitoringQualityCarMessageRecord | null;
  onCancelReply?: () => void;
  onSendMessage: (message: ISendMessage) => void;
  onFilesAdd?: (files: FileList) => void;
  isContainer?: boolean;
}

export const MessageInput: React.FC<IMessageInput> = (props) => {
  const {
    reply,
    onCancelReply,
    onSendMessage,
    onFilesAdd,
    isContainer = false,
  } = props;
  const [message, setMessage] = useState("");
  const inputFile: RefObject<HTMLInputElement> = useRef(null);

  const handleSubmitSendMessage = () => {
    onSendMessage({ message });
    setMessage("");
  };

  return (
    <StyledAntiContainer isContainer={isContainer}>
      {reply ? (
        <StyledReply>
          <Icon name="reply" size={24} />
          <StyledMessageBody>
            <Paragraph size={10}>
              {`${reply.user.firstName} ${reply.user.lastName}`}{" "}
            </Paragraph>
            <StyledReplyMessage size={10}>{reply.message}</StyledReplyMessage>
          </StyledMessageBody>
          <IconButton
            icon="close-16"
            size="m"
            color="gray"
            variant="tertiary"
            onClick={onCancelReply}
          />
        </StyledReply>
      ) : (
        <></>
      )}
      <StyledMessageInput hasReply={!!reply?.user}>
        {onFilesAdd ? (
          <IconButton
            icon="attachment"
            variant="tertiary"
            size="l"
            onClick={() => {
              inputFile?.current?.click();
            }}
          />
        ) : null}
        <MessageTextarea message={message} onChange={setMessage} />
        <IconButton
          type="submit"
          icon="send"
          variant="tertiary"
          size="l"
          onClick={handleSubmitSendMessage}
        />
      </StyledMessageInput>
      <input
        type="file"
        accept="image/*,.pdf,.xlsx,.xls,.docx"
        ref={inputFile}
        multiple={true}
        style={{ display: "none" }}
        onChange={(event) => {
          const eventCopy = { ...event };
          if (eventCopy?.target?.files?.length) {
            onFilesAdd && onFilesAdd(eventCopy?.target?.files);
          }
        }}
      />
    </StyledAntiContainer>
  );
};
