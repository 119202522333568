import { colors } from "@/helpers";
import { FC } from "react";
import styled, { css } from "styled-components";

type WorkType = "I" | "R" | "O";

interface IIconWorkType {
  type: WorkType;
}

const TYPES = {
  I: "I",
  R: "R",
  O: "O",
};

const StyledIconWorkType = styled.div<IIconWorkType>`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  color: ${colors.white};

  ${({ type }) => {
    switch (type) {
      case "I":
        return css`
          background-color: ${colors.blue};
        `;

      case "R":
        return css`
          background-color: ${colors.orange};
        `;

      case "O":
        return css`
          background-color: ${colors.green100};
        `;
    }
  }}
`;

const IconWorkType: FC<IIconWorkType> = (props) => {
  const { type } = props;

  return <StyledIconWorkType type={type}>{TYPES[type]}</StyledIconWorkType>;
};

export { IconWorkType };
