import { InputField } from "@/packages/formElements/fields/InputField";
import React, { useEffect, useState } from "react";

import {
  IPartCreateModal,
  PartCreateModal,
} from "@/components/parts/PartCreateModal";
import { TTransmissionRecord } from "@/helpers/api/transmissions/types";
import { TTransmissionForm, TRANSMISSION_INITIAL_FORM } from "@/pages/Parts";
import { StyledModalFormContainer } from "@/components/parts/index.styles";
import { useTranslation } from "react-i18next";

export interface TTransmissionEditModalSubmitPayload {
  record: TTransmissionRecord;
  data: TTransmissionForm;
}
export interface ITransmissionEditModal
  extends Pick<IPartCreateModal, "isOpen" | "onCancel"> {
  onSubmit: (payload: TTransmissionEditModalSubmitPayload) => void;
  isLoadingSubmit: boolean;
  record?: TTransmissionRecord;
}

export const TransmissionEditModal: React.FC<ITransmissionEditModal> = (
  props
) => {
  const { onSubmit, isLoadingSubmit, record, ...restProps } = props;

  const { t } = useTranslation();

  const initialForm = {
    //@ts-ignore
    transmissionType: record?.name || "",
    // @ts-ignore
    mpv: record?.mpv || "",
  };

  const [error, setError] = useState<string>("");
  const [form, setForm] = useState(initialForm);

  const transmissionTypeError = !form.transmissionType && error ? error : "";
  const mpvError = !form.mpv && error ? error : "";

  const handleTypeChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      transmissionType: value,
    }));

  const handleMpvChange = (value: string) =>
    setForm((prev) => ({
      ...prev,
      mpv: value,
    }));

  const handleSubmit = async () => {
    if (!record || !Object.values(form).every(Boolean)) {
      setError(t("parts.errorMessage") || "");
      return;
    }
    setError("");
    // @ts-ignore
    onSubmit({ record: record, data: form });
  };

  useEffect(() => {
    setForm(restProps.isOpen ? initialForm : TRANSMISSION_INITIAL_FORM);
  }, [record, restProps.isOpen]);

  return (
    <PartCreateModal
      {...restProps}
      title={t("parts.changeTransmission") || ""}
      confirmButtonText={t("parts.save") || ""}
      onSubmit={handleSubmit}
      isLoadingConfirmButton={isLoadingSubmit}
    >
      <StyledModalFormContainer>
        <InputField
          label={t("parts.transmission") || ""}
          description={t("parts.transmissionEnter") || ""}
          value={form.transmissionType}
          error={transmissionTypeError}
          onChange={handleTypeChange}
        />
        <InputField
          label={t("parts.mpv") || ""}
          description={t("parts.mpvEnter") || ""}
          value={form.mpv}
          error={mpvError}
          onChange={handleMpvChange}
        />
      </StyledModalFormContainer>
    </PartCreateModal>
  );
};
