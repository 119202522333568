import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../axiosInstance";
import {
  TGetMaintenanceGridByIdRequest,
  TGetMaintenanceGridByIdResponse,
  TGetMaintenanceGridsRequest,
  TGetMaintenanceGridsResponse,
  TMaintenanceGridsCreateRequest,
  TMaintenanceGridsCreateResponse,
  TMaintenanceGridsUpdateRequest,
  TMaintenanceGridsUpdateResponse,
  TExportMaintenanceGridRequest,
  TExportMaintenanceGridResponse,
} from "./types";

export const useGetMaintenanceGrids = (
  request: TGetMaintenanceGridsRequest,
  isEnabled = true
) => {
  return useQuery(
    ["maintenanceGrids", request],
    () =>
      api.get<TGetMaintenanceGridsResponse>("/maintenance-grids", {
        params: request,
      }),
    {
      enabled: isEnabled,
    }
  );
};

export const useGetMaintenanceGridById = (
  request: TGetMaintenanceGridByIdRequest & { isEnabled?: boolean }
) => {
  return useQuery(
    ["maintenanceGrid", request.id],
    () =>
      api.get<TGetMaintenanceGridByIdResponse>(
        `/maintenance-grids/${request.id}`
      ),
    { enabled: Boolean(request?.isEnabled) }
  );
};

export const useGetMaintenanceGridByModificationId = (
  request: TGetMaintenanceGridByIdRequest & { isEnabled?: boolean }
) => {
  return useQuery(
    ["maintenanceGridByModification", request.id],
    () =>
      api.get<TGetMaintenanceGridByIdResponse>(
        `/maintenance-grids/modification/${request.id}`
      ),
    { enabled: Boolean(request?.isEnabled) }
  );
};

export const useCreateMaintenanceGrid = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TMaintenanceGridsCreateRequest) =>
      api.post<TMaintenanceGridsCreateResponse>("/maintenance-grids", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["maintenanceGrids"]);
        onSuccess && onSuccess();
      },
    }
  );
};

export const useUpdateMaintenanceGrid = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, navigateOnSuccess }: {data : TMaintenanceGridsUpdateRequest, navigateOnSuccess?: () => void}) =>
      api.patch<TMaintenanceGridsUpdateResponse>(
        `/maintenance-grids/${data.id}`,
        data.data,
        { params: { id: data.id } }
      ),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["maintenanceGrid", variables.data.id]);
        queryClient.invalidateQueries(["maintenanceGrids"]);
        variables.navigateOnSuccess && variables.navigateOnSuccess();
        onSuccess && onSuccess();
      },
    }
  );
};

export const useExportMaintenanceGrid = () => {
  return useMutation((id: TExportMaintenanceGridRequest) => {
    return api.get<TExportMaintenanceGridResponse>(
      `/maintenance-grids/${id}/export`,
      {
        responseType: "blob",
      }
    );
  });
};
