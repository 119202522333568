import {
  BodyTypeSelectFieldAdapter,
  IBodyTypeSelectFieldAdapter,
} from "@/adapters/selects/BodyTypeSelectFieldAdapter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IFormBodyTypeSelectField
  extends Omit<IBodyTypeSelectFieldAdapter, "error" | "value" | "onChange"> {
  name: string;
}

const _FormBodyTypeSelectField: React.FC<IFormBodyTypeSelectField> = (
  props
) => {
  const { name, ...inputProps } = props;

  const control = useFormContext();

  return (
    <Controller
      name={name}
      control={control.control}
      render={({
        field: { ref: _, ...fieldWithoutRef },
        fieldState: { error },
      }) => {
        return (
          <BodyTypeSelectFieldAdapter
            {...fieldWithoutRef}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
export const FormBodyTypeSelectField = React.memo(_FormBodyTypeSelectField);
