import { WorkTypesLegend } from "@/components/MaintenanceGrids/GridsTab/WorkTypesLegend";
import { colors } from "@/helpers";
import {
  Checkbox,
  IMultiValue,
  TCheckboxMultiValueColor,
} from "@/packages/checkbox/Checkbox";
import { InputNumber } from "@/packages/formElements/inputs/InputNumber";
import { ISelectOption, Select } from "@/packages/formElements/inputs/Select";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { HeaderGroup, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import { Field } from "../formElements/fields/Field";
import { Icon } from "../icon/Icon";
import { IconWorkType } from "../iconWorkType";
import {
  getMultiColorCheckboxValue,
  getMultiValues,
  getTypeByCheckboxColor,
} from "./utils";
import { useTranslation } from "react-i18next";

export interface ICreatedRow {
  name: ISelectOption;
  quantity: number | null;
}

export interface IHeader<TData extends object> extends HeaderGroup<TData> {
  isCheckable?: boolean;
  isMultiColor?: boolean;
  textAlign?: "left" | "center";
  isQuantity?: boolean;
  isReadOnlyQuantity?: boolean;
}

interface ITableMaintenanceGrids<TData extends object> {
  className?: string;
  title: string;
  amountTitle?: string;
  headers: IHeader<TData>[];
  data: TData[];
  createRowButtonText?: string;
  withFooter?: boolean;
  createRowSelectPlaceholder?: string;
  createRowSelectOptions?: ISelectOption[];
  error?: string;
  isReadOnly?: boolean;
  isViewMode?: boolean;
  gsmCheckMark?: boolean;
  isSummary?: boolean;
  onRowCreate?: (createdRow: ICreatedRow) => void;
  onRowChange?: (accessor: string, row: TData & { id?: number }) => void;
  onRowDelete?: (row: TData & { id?: number }) => void;
  onSearch?: (value: string) => void;
  isSearchShow?: boolean;
}

const StyledTableMaintenanceGrids = styled.div``;

const StyledTableTitle = styled.div`
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.gray30};
`;

const StyledTable = styled.div<{ isSummary: boolean }>`
  overflow-x: auto;
  min-width: auto !important;

  background-color: ${({ isSummary }) =>
    isSummary ? `${colors.blue100}` : `${colors.white}`};

  [data-sticky-td] {
    background-color: ${({ isSummary }) =>
      isSummary ? `${colors.blue100}` : `${colors.white}`};
  }

  .footer[data-sticky-td] {
    background-color: ${colors.blue100};
  }
`;

interface IStyledTableHeader {
  textAlign?: "left" | "center";
}

const StyledTableHeader = styled(Paragraph)<IStyledTableHeader>`
  padding: 18px 16px;
  background: ${colors.white};
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign = "center" }) => textAlign};

  text-align: center;
  white-space: pre;
`;

const StyledTableRow = styled.div`
  display: flex;
  align-items: center;

  .header,
  .cell,
  .footer {
    &.separator:not(:last-child) {
      position: relative;

      &::before {
        content: "";

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        width: 1px;
        height: 16px;

        background-color: ${colors.gray200};
      }
    }
  }
`;

interface IStyledTableCell {
  textAlign?: "left" | "center";
}

const StyledTableCell = styled.div<IStyledTableCell>`
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: ${({ textAlign = "center" }) => textAlign};
`;

const StyledTableFooterCell = styled(StyledTableCell)`
  background-color: ${colors.blue100};
`;

const StyledTableBottom = styled.div`
  padding: 12px 82px;
`;

const StyledInput = styled(InputNumber)`
  width: 240px;
`;

const StyledSelect = styled(Select)`
  width: 240px;
`;

const StyledIcon = styled(Icon)`
  margin-left: 5px;
  cursor: pointer;
`;

const StyledFirstCellFiller = styled.div`
  width: 28px;
`;

const DEFAULT_COLUMN = {
  minWidth: 84,
  maxWidth: 84,
};

const INITIAL_ADDING_ROW_FORM: ICreatedRow = {
  name: {} as ISelectOption,
  quantity: null,
};

const TableMaintenanceGrids = <TData extends object>(
  props: ITableMaintenanceGrids<TData>
) => {
  const {
    withFooter,
    className,
    title,
    amountTitle,
    headers,
    data,
    createRowButtonText,
    createRowSelectPlaceholder,
    createRowSelectOptions,
    error,
    isReadOnly = false,
    isViewMode = false,
    gsmCheckMark = false,
    onRowCreate,
    onRowChange,
    onRowDelete,
    onSearch,
    isSummary,
    isSearchShow = false,
  } = props;

  const [showError, setShowError] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [addingRow, setAddingRow] = useState<ICreatedRow>(
    INITIAL_ADDING_ROW_FORM
  );
  const [newQuantity, setNewQuantity] = useState(null);
  const onChangeValueHandler = (value: any, id: any) => {
    // @ts-ignore
    setNewQuantity({ value: value, id: id });
  };

  useEffect(() => {
    data.map((el) => {
      // @ts-ignore
      if (el?.id === newQuantity?.id) {
        // @ts-ignore
        el.quantity = newQuantity?.value;
      }
    });
  }, [newQuantity]);

  const { t } = useTranslation();
  const headersMemoized = useMemo(
    () =>
      amountTitle
        ? headers?.map((column, index) => {
            const countField = data?.reduce((acc, item) => {
              // @ts-ignore
              if (item[column.accessor] && !isNaN(+item[column.accessor])) {
                // @ts-ignore
                return acc + Number(item[column.accessor]);
              } else {
                return acc;
              }
            }, 0);

            if (index === 1) {
              return {
                ...column,
                Footer: amountTitle,
              };
            } else if (index > 2) {
              return {
                ...column,
                Footer: countField === 0 ? 0 : countField?.toFixed(2),
              };
            } else {
              return { ...column };
            }
          })
        : headers,
    [amountTitle, headers, data]
  );
  const dataMemoized = useMemo(
    () =>
      data.map((item, index) => ({
        id: isSummary ? null : index + 1,
        ...item,
      })),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      defaultColumn: DEFAULT_COLUMN,
      columns: headersMemoized,
      data: dataMemoized,
    },
    useSticky,
    useFlexLayout
  );
  const hasFooters = footerGroups.some((group) =>
    group.headers.some((header) => Boolean(header.Footer))
  );

  const handleOpenCreateFormButtonClick = () => setShowCreateForm(true);

  const handleCloseCreateFormButtonClick = () => setShowCreateForm(false);

  const handleCreateFormButtonClick = () => {
    if (!addingRow?.name?.value) return;

    onRowCreate && onRowCreate(addingRow);
    setShowCreateForm(false);
    setShowError(false);
    setAddingRow(INITIAL_ADDING_ROW_FORM);
  };

  const handleRowChange = ({
    isActive,
    accessor,
    row,
    color,
    colors,
  }: {
    isActive?: boolean;
    accessor: string;
    row: TData & { workTypes?: { type: string; id: string }[] };
    colors?: any;
    color?: TCheckboxMultiValueColor;
  }) => {
    let newRow: any;

    const workType = row.workTypes?.find(
      // @ts-ignore
      (type) => type.type === getTypeByCheckboxColor(color)
    );

    const colorType =
      colors?.color === "green" ? "O" : colors?.color === "orange" ? "R" : null;

    if (!workType) {
      newRow = {
        ...row,
        [accessor]: {
          type: colorType,
          number: +accessor,
          id: +accessor,
        },
      };
    } else {
      newRow = {
        ...row,
        [accessor]: {
          workTypeId: workType?.id,
          workType: workType?.type,
          number: +accessor,
        },
      };
    }
    onRowChange && onRowChange(accessor, newRow);
  };

  const handleAddingRowChange = (
    key: keyof ICreatedRow,
    value: string | number | ISelectOption | ISelectOption[] | null
  ) => {
    if (typeof value === "number") {
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      // @ts-ignore
      value = Number(value.toString().match(regex)[0]);
    }
    setAddingRow((prev) => ({
      ...prev,
      [key]: value,
    }));
    onSearch && onSearch("");
  };

  const handleRowDelete = (row) => {
    if (onRowDelete) {
      onRowDelete(row);
    }
  };

  useEffect(() => {
    if (!error) return;

    setShowCreateForm(true);
    setShowError(true);
  }, [error]);

  return (
    <StyledTableMaintenanceGrids className={className}>
      <StyledTableTitle>
        <Paragraph size={14} color="black">
          {title}
        </Paragraph>
      </StyledTableTitle>

      {dataMemoized.length ? (
        <StyledTable isSummary={!!isSummary} {...getTableProps()}>
          {headerGroups.map((group) => {
            const { getHeaderGroupProps, headers } = group;

            return (
              <StyledTableRow {...getHeaderGroupProps()}>
                {!isViewMode && (
                  <StyledTableHeader
                    className="header"
                    size={12}
                    color="gray200"
                  >
                    <StyledFirstCellFiller />
                  </StyledTableHeader>
                )}
                {headers.map((column) => {
                  const { getHeaderProps, render, textAlign } =
                    column as IHeader<TData>;

                  return (
                    <div {...getHeaderProps()} className="header separator">
                      <StyledTableHeader
                        className="header"
                        size={12}
                        color="gray200"
                        textAlign={textAlign}
                      >
                        {t(`${column.render("Header")}`)}
                      </StyledTableHeader>
                    </div>
                  );
                })}
              </StyledTableRow>
            );
          })}

          <div {...getTableBodyProps()}>
            {title === t("maintenanceGrids.gridsModel.viewGrid.works") &&
              !withFooter && <WorkTypesLegend />}

            {rows.map((row) => {
              prepareRow(row);

              const { getRowProps } = row;

              return (
                <StyledTableRow {...getRowProps()}>
                  {!isViewMode && (
                    <StyledTableCell>
                      <StyledIcon
                        name="close-16"
                        size={24}
                        onClick={() => handleRowDelete(row.original)}
                      />
                    </StyledTableCell>
                  )}
                  {row.cells.map((cell, $$i) => {
                    const { getCellProps, render } = cell;

                    const {
                      isCheckable,
                      isMultiColor,
                      textAlign,
                      isQuantity,
                      isReadOnlyQuantity,
                    } = cell.column as IHeader<TData>;
                    const accessor = cell.column.id;
                    const multiCheckboxType =
                      cell?.value?.workType || cell?.value?.type;
                    const isMultiCheckbox = isCheckable;
                    const mockTypes = [
                      {
                        type: "O",
                      },
                      {
                        type: "R",
                      },
                    ];
                    const isChecked = isMultiCheckbox
                      ? getMultiColorCheckboxValue(multiCheckboxType)
                      : cell?.value;
                    const multiCheckboxValues = getMultiValues(
                      (isMultiColor
                        ? // @ts-ignore
                          cell.row?.original?.workTypes
                          ? // @ts-ignore
                            cell.row.original.workTypes
                          : mockTypes
                        : []) || []
                    );

                    const checkboxProps = isMultiCheckbox
                      ? {
                          multiValue: isChecked,
                          multiValues: multiCheckboxValues,
                          onMultiChange: (value: IMultiValue) => {
                            handleRowChange({
                              accessor,
                              row: cell.row.original,
                              color: value.color,
                              colors: value,
                            });
                          },
                        }
                      : {
                          value: isChecked,
                          gsmCheckMark: gsmCheckMark,
                          onChange: (value: boolean) => {
                            handleRowChange({
                              isActive: value,
                              accessor,
                              colors: value,
                              row: cell.row.original,
                            });
                          },
                        };

                    return (
                      <StyledTableCell
                        {...getCellProps()}
                        key={$$i}
                        className="cell separator"
                        textAlign={textAlign}
                      >
                        {isReadOnly && isCheckable && isMultiCheckbox ? (
                          <IconWorkType type={multiCheckboxType} />
                        ) : isReadOnly && isCheckable ? (
                          <Checkbox {...checkboxProps} readOnly={isReadOnly} />
                        ) : isCheckable ? (
                          <Checkbox {...checkboxProps} />
                        ) : isQuantity && !isReadOnlyQuantity ? (
                          <InputNumber
                            onChange={(value) =>
                              onChangeValueHandler(value, cell?.row?.values?.id)
                            }
                            value={cell?.row?.values?.quantity}
                            disabled={isReadOnly}
                            disableDot={true}
                          />
                        ) : // @ts-ignore
                        cell.row.original?.value === true ? (
                          render("Cell")
                        ) : (
                          render("Cell")
                          // <Tooltip content={"123"}>
                          // <div>{render("Cell")}</div>
                          // </Tooltip>
                        )}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </div>

          {hasFooters &&
            withFooter &&
            footerGroups.map((group) => {
              const { getFooterGroupProps, headers } = group;

              return (
                <StyledTableRow {...getFooterGroupProps()}>
                  {headers.map((column, index) => {
                    const { getHeaderProps, render } = column;
                    const hasSeparator = index > 2;

                    return (
                      <StyledTableFooterCell
                        {...getHeaderProps()}
                        className={`footer ${hasSeparator ? "separator" : ""}`}
                      >
                        {render("Footer")}
                      </StyledTableFooterCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
        </StyledTable>
      ) : (
        <></>
      )}

      {createRowButtonText && createRowSelectPlaceholder ? (
        <StyledTableBottom>
          {showCreateForm ? (
            <>
              <Space align="start" size={24}>
                <Space direction="vertical" size={4}>
                  <Space align="center" size={14}>
                    <StyledSelect
                      placeholder={createRowSelectPlaceholder}
                      options={createRowSelectOptions || []}
                      optionInValue={true}
                      isSearchShow={isSearchShow}
                      onSearch={onSearch}
                      onChange={(_, option) => {
                        handleAddingRowChange("name", option);
                      }}
                    />
                    <StyledInput
                      placeholder={
                        t("maintenanceGrids.gridsModel.viewGrid.amount") || ""
                      }
                      value={addingRow.quantity}
                      onChange={(value) =>
                        handleAddingRowChange("quantity", value)
                      }
                    />
                  </Space>
                  {showError && error ? (
                    <Field.FieldError>{error}</Field.FieldError>
                  ) : (
                    <></>
                  )}
                </Space>
                <Space align="center" size={8}>
                  <IconButton
                    icon="close-16"
                    isSquared={true}
                    onClick={handleCloseCreateFormButtonClick}
                  />
                  <IconButton
                    icon="check-icon"
                    isSquared={true}
                    onClick={handleCreateFormButtonClick}
                  />
                </Space>
              </Space>
            </>
          ) : (
            <IconButton
              icon="plus"
              isSquared={true}
              onClick={handleOpenCreateFormButtonClick}
            >
              {createRowButtonText}
            </IconButton>
          )}
        </StyledTableBottom>
      ) : (
        <></>
      )}
    </StyledTableMaintenanceGrids>
  );
};

export { TableMaintenanceGrids };
