import React from "react";
import { StyledTable, StyledIcon } from "./index.styles";
import { useTranslation } from "react-i18next";

interface IStatisticsTable {
  onPublicityClick: (data) => void;
  statistics: StatisticsDataItem[];
}

interface StatisticsDataItem {
  car: string;
  vin: string;
  requestsCount;

  publications: any[];
  publicationCount: number;
  repairTime: number;
  standbyTime: number;
}

export const StatisticsTable: React.FC<IStatisticsTable> = (props) => {
  const { onPublicityClick, statistics } = props;
  const { t } = useTranslation();
  const headers = [
    {
      Header: t("park.autoOne") || "",
      accessor: "car",
      maxWidth: 440,
    },
    {
      Header: "VIN",
      accessor: "vin",
      maxWidth: 100,
    },
    {
      Header: t("park.subTitle_3") || "",
      accessor: "requests",
      maxWidth: 100,
      isSortable: true,
    },
    {
      Header: t("park.publicationCount") || "",
      accessor: "publicationCount",
      maxWidth: 100,
    },
    {
      Header: t("park.repairTime") || "",
      accessor: "repairTime",
      maxWidth: 100,
    },
    {
      Header: t("park.standbyTime") || "",
      accessor: "standbyTime",
      maxWidth: 100,
    },
    {
      Header: t("park.publicationCount") || "",
      accessor: "publicityLink",
      maxWidth: 100,
      Cell: (data) => {
        const currentData = data.row.original;
        return (
          <StyledIcon
            icon="eye-open"
            color="black"
            size="l"
            variant="tertiary"
            onClick={() => onPublicityClick(currentData)}
          />
        );
      },
    },
  ];

  // const dataItem: StatisticsDataItem = {
  //   car: "Sportage O 577 АХ 797 2.5 AT AWD PRESTIGE RED",
  //   vin: "XWEG6417BN0007549",
  //   requests: 3,
  //   publications: 5,
  //   repairTime: 93,
  //   standbyTime: 433,
  // };

  const data: any = statistics.map((statistic) => ({
    //@ts-ignore
    car: `${statistic.equipment.modification.generation.model.name}, ${statistic.number} ${statistic.equipment.modification.fullName} ${statistic.equipment.name} ${statistic.color.color}`,
    //@ts-ignore
    carModel: `${statistic.equipment.modification.generation.model.name}`,
    vin: statistic.vin,
    requests: statistic.requestsCount || 0,
    publications: statistic.publications || [],
    publicationCount: statistic.publicationCount || 0,
    repairTime: statistic.repairTime || 0,
    standbyTime: statistic.standbyTime || 0,
  }));

  // const data: any = [dataItem, dataItem];

  const nestedTableArgs = {
    headers,
    data,
  };

  return <StyledTable {...nestedTableArgs} />;
};
