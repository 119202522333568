import React, { FC } from "react";
import RowComponent from "./Row";
import { IAuditBatchResponseToMutate } from "./utils";

export interface ITreeProps {
  data: IAuditBatchResponseToMutate[];
  sections: IAuditBatchResponseToMutate[];
  setSections: (value: IAuditBatchResponseToMutate[]) => void;
  onActionHandler: (
    event: "edit" | "add" | "delete",
    deleteSection?: any
  ) => void;
}

const Tree: FC<ITreeProps> = (props) => {
  const { data, sections, setSections, ...rest } = props;
  return (
    <>
      {sections?.map((el, index) => {
        return (
          <RowComponent
            key={el.id}
            index={index + 1}
            section={el}
            setSections={setSections}
            sections={data}
            hasChildren={!!el.children?.length}
            {...rest}
          >
            {el.children && el.children.length ? (
              <Tree
                data={data}
                sections={el.children}
                setSections={setSections}
                {...rest}
              />
            ) : (
              <></>
            )}
          </RowComponent>
        );
      })}
    </>
  );
};

export default Tree;
