import { TOnSubmitFormSyncCb, useForm } from "@/hooks/useForm";
import { useEffect } from "react";

export enum StatisticFilterFormFieldsNameEnum {
  DealerName = "name",
  City = "city",
  WarrantyCode = "warrantyCode",
  SapCode = "sapCode",
  MobisCode = "mobisCode",
  Status = "status",
}

export interface IStatisticFilterFormValues {
  [StatisticFilterFormFieldsNameEnum.DealerName]: string | undefined;
  [StatisticFilterFormFieldsNameEnum.City]: string | undefined;
  [StatisticFilterFormFieldsNameEnum.WarrantyCode]: string | undefined;
  [StatisticFilterFormFieldsNameEnum.SapCode]: string | undefined;
  [StatisticFilterFormFieldsNameEnum.MobisCode]: string | undefined;
  [StatisticFilterFormFieldsNameEnum.Status]:
    | "Ознакомлен"
    | "Не ознакомлен"
    | undefined;
}

export const DEFAULT_STATISTIC_FILTER_FORM_VALUES: IStatisticFilterFormValues =
  {
    [StatisticFilterFormFieldsNameEnum.DealerName]: undefined,
    [StatisticFilterFormFieldsNameEnum.City]: undefined,
    [StatisticFilterFormFieldsNameEnum.WarrantyCode]: undefined,
    [StatisticFilterFormFieldsNameEnum.SapCode]: undefined,
    [StatisticFilterFormFieldsNameEnum.MobisCode]: undefined,
    [StatisticFilterFormFieldsNameEnum.Status]: undefined,
  };

export type TOnSubmitStatisticFilterFormCb = TOnSubmitFormSyncCb<any>;

export type TOnSubmitFormAsyncCb<TSuccessFormValues> = (
  values: TSuccessFormValues
) => Promise<any>;

export const useStatisticFilterForm = (options: {
  onSubmit: TOnSubmitStatisticFilterFormCb;
  initValues: IStatisticFilterFormValues;
}) => {
  const { initValues, onSubmit } = options;

  const result = useForm<IStatisticFilterFormValues>({
    defaultValues: DEFAULT_STATISTIC_FILTER_FORM_VALUES,
    onSubmit,
  });

  const onClearHandler = () => {
    result.form.reset();
    onSubmit(result.form.getValues());
  };

  return {
    ...result,
    onClearHandler,
  };
};
