import { Paragraph } from "@/packages/paragraph/Paragraph";
import styled from "styled-components";
import { media } from "@/helpers";

export const StyledFormGrid = styled.div`
  display: grid;
  gap: 16px 32px;
  margin-bottom: 32px;
  ${media.tablet} {
    grid-template-columns: 1fr;
  }
  ${media.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledStageContainer = styled.div`
  max-width: 848px;
`;

export const StyledGroupTitle = styled(Paragraph)`
  margin-bottom: 15px;
`;

export const StyledGroup = styled.div`
  margin-bottom: 64px;
`;

export const StyledConfirm = styled.div`
  p {
    text-align: center;
  }
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaef;
`;

export const StyledConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 34px;
  width: 100%;
  height: 100%;
`;
