import React, { useState } from "react";

import { IModal } from "@/packages/modal/Modal";

import { ApplicationsForm } from "@/components/Applications/ApplicationsForm";
import { IApplicationsFormValues } from "@/components/Applications/ApplicationsForm/hooks";
import {
  StyledModal,
  StyledModalContentWrapper,
  StyledModalTitle,
} from "@/components/Calendar/CalendarTable/CalendarTableColumnRight/CalendarApplication/CalendarApplicationModal/index.styles";
import {
  useGetApplicationById,
  usePutApplication,
} from "@/helpers/api/applications/hooks";
import { TApplicationCreateRecord } from "@/helpers/api/applications/types";
import { useNotification } from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

interface ICalendarApplicationModal
  extends Pick<IModal, "isOpen" | "onCancel"> {
  id: string;
  closeModal: any;
}

export const CalendarApplicationModal: React.FC<ICalendarApplicationModal> = (
  props
) => {
  const { id, onCancel, closeModal, isOpen } = props;

  const { data: applicationResponse, refetch: refetchApplication } =
    useGetApplicationById({ id } as any);

  const {
    mutateAsync: putApplicationAsync,
    isLoading: isUpdateApplicationLoading,
  } = usePutApplication();

  const { createNotificationError } = useNotification();

  const { t } = useTranslation();

  const handleFormSubmit = async (data: IApplicationsFormValues) => {
    const {
      startEvent,
      endEvent,
      vehicleId,
      appointment,
      mediaId,
      statusId,
      requestPublicationLink,
      comment,
      receivingUser,
      fileId,
    } = data;

    const dataToServer: TApplicationCreateRecord = {
      startEvent: startEvent ? startEvent.toISOString() : "",
      endEvent: endEvent ? endEvent.toISOString() : "",
      vehicleId: vehicleId!,
      appointment: appointment!,
      mediaId: appointment === "Media" ? (mediaId as string) : undefined,
      statusId: statusId!,
      requestPublicationLink: requestPublicationLink || "",
      comment: comment!,
      receivingUser: receivingUser!,
      // @ts-ignore TODO
      fileIds: fileId?.id ? [fileId?.id] : [],
    };

    try {
      await putApplicationAsync({
        id,
        put: { ...dataToServer },
      });
      closeModal();
      refetchApplication();
    } catch (err: any) {
      if (err?.response?.data?.message === "Expired CASCO or OSAGO") {
        createNotificationError(t("park.expiredCASCO&OSAGO") || "");
      }
      if (
        err?.response?.data?.message === "Request overlaps with other requests"
      ) {
        createNotificationError(t("park.overlaps") || "");
      }
    }
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmitHandler = () => {
    setIsSubmitted(true);
  };

  return (
    <StyledModal
      {...props}
      title="Заявка"
      size="medium"
      confirmButtonText="Сохранить"
      cancelButtonText="Отменить"
      showConfirmButton={true}
      isDisabledConfirmButton={isDisabled}
      onCancel={onCancel}
      onSubmit={onSubmitHandler}
    >
      <StyledModalContentWrapper>
        <StyledModalTitle level={3} weight={400}>
          Данные по заявке
        </StyledModalTitle>

        {applicationResponse?.data ? (
          <ApplicationsForm
            defaultValues={applicationResponse?.data}
            onFormSubmit={handleFormSubmit}
            isLoading={isUpdateApplicationLoading}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            setIsDisabled={setIsDisabled}
          />
        ) : (
          <></>
        )}
      </StyledModalContentWrapper>
    </StyledModal>
  );
};
