import { colors } from "@/helpers";
import { Button } from "@/packages/button/Button";

import styled from "styled-components";

export const StyledTab = styled.div`
  padding: 0 32px 20px 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${colors.white};
  &:last-child {
    gap: 0px;
  }
`;

export const StyledHR = styled.hr`
  border: 1px solid #f2f3f6;
  width: 100%;
  margin: 0 auto;
`;

export const StyledQuarterClosedButton = styled(Button)`
  margin: 20px 0 30px 0;

  background-color: ${colors.red30};
  color: ${colors.red10};
  border-color: ${colors.red20};

  &.active {
    background-color: ${colors.red30};
    color: ${colors.red10};
    border-color: ${colors.red20};
  }
`;
