import React from "react";
import { UseFieldArrayReturn } from "react-hook-form";

import { Field } from "@/packages/formElements/fields/Field";
import { FormImageDropzoneField } from "@/packages/formElements/formFields/FormImageDropzoneField";
import { UploadType } from "@/packages/UploadImageDropzone";

import { TFileRecord } from "@/helpers/api/files/types";

import {
  IAutoParkFormValues,
  IFileInfo,
} from "@/components/AutoPark/AutoParkForm/hooks";

import {
  StyledAutoParkUpload,
  StyledParagraph,
  StyledSubTitleWrap,
  StyledUploadFieldWrap,
  StyledUploadItem,
  StyledUploadList,
} from "./index.styles";

const getUploadTitleInfo = (file: TFileRecord | null): JSX.Element => {
  if (!file) {
    return <></>;
  }

  return (
    <>
      <StyledParagraph size={12} color="gray">
        {file.fileName.split(file.extension)[0]}
      </StyledParagraph>
      <StyledSubTitleWrap>
        <StyledParagraph size={12} color="gray3">
          {file.extension.slice(1).toUpperCase()}
        </StyledParagraph>
      </StyledSubTitleWrap>
    </>
  );
};

export type FieldsName = "images" | "files";

export interface IAutoParkUpload {
  label: string;
  data: IFileInfo[];
  description: string;
  allowedTypes?: string[];
  fieldsName: FieldsName;
  fieldArray: UseFieldArrayReturn<IAutoParkFormValues, any, "id">;
}

export const AutoParkUpload: React.FC<IAutoParkUpload> = (props) => {
  const { data, label, fieldsName, fieldArray, description, allowedTypes } =
    props;

  const { fields } = fieldArray;

  const typeInfo = fieldsName === "images" ? UploadType.IMAGE : UploadType.FILE;

  const typePath =
    fieldsName === "images" ? "vehicles/photos" : "vehicles/files";

  return (
    <StyledAutoParkUpload>
      <Field.Label>{label}</Field.Label>
      <StyledUploadList>
        {fields.map((item, index) => (
          <StyledUploadItem key={item.id}>
            {getUploadTitleInfo(data[index].value)}
            <StyledUploadFieldWrap>
              <FormImageDropzoneField
                path={typePath}
                typeInfo={typeInfo}
                maxFileSize={5000000}
                allowedTypes={allowedTypes}
                isWithCustomPlaceholderTitle={false}
                name={`${fieldsName}.${index}.value`}
              />
            </StyledUploadFieldWrap>
          </StyledUploadItem>
        ))}
      </StyledUploadList>
      <Field.FieldDescription>{description}</Field.FieldDescription>
    </StyledAutoParkUpload>
  );
};
