import React from "react";
import styled, { css } from "styled-components";
import { Button as AntButton } from "antd";
import { keyframes } from "styled-components";

import { colors } from "@/helpers";

import { ReactComponent as LoadingIcon } from "@/assets/icons/button-loading.svg";

export interface IButton {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  href?: string;
  htmlType?: "submit" | "reset" | "button";
  isBlock?: boolean;
  target?: string;
  isUploadLeft?: boolean;
  isUploadRight?: boolean;
  count?: string;
  theme?: "primary" | "secondary";
  color?: "brand" | "red" | "gray";
  size?: "middle" | "small";
  paddingInline?: number;
  customColor?: string;
}

const StyledButton = styled(AntButton)<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  outline: none;
  transition: all 0.5s ease;
  position: relative;
  height: auto;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ color }) =>
    color === "gray"
      ? `
    & svg {
          & path {
            fill: ${colors.black}
          }
      }
  `
      : `
    & svg {
          & path {
            fill: ${colors.white}
          }
      }
  `}

  ${({ size }) =>
    size === "middle"
      ? `
    padding: 8px 16px;
  `
      : `
    padding: 4px 8px;
  `}

  ${({ href }) =>
    href &&
    `
    display: inline-block;
  `}

  ${({ theme, color }) =>
    theme === "primary"
      ? `
      background: ${
        color === "brand"
          ? colors.brand
          : color === "red"
          ? colors.red
          : colors.gray300
      };
      color: ${
        color === "brand" || color === "red" ? colors.white : colors.black
      };

      &:disabled {
        background: ${colors.gray300};
        color:  ${colors.gray200};
      }

      &:hover {
        background: ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.gray300
        };
        border-color: ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.gray300
        };
        color: ${
          color === "brand" || color === "red" ? colors.white : colors.black
        };
      }

      &:focus {
        border: 1px solid ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.brand
        };
        background: ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.gray300
        };
        color: ${
          color === "brand" || color === "red" ? colors.white : colors.black
        };
      }
      `
      : `
      background: transparent;
      color: ${colors.black};
      border: 1px solid ${
        color === "brand"
          ? colors.brand
          : color === "red"
          ? colors.red
          : colors.gray300
      };

      &:disabled {
        background: transparent;
        color: ${colors.gray200};
        border: 1px solid ${colors.gray300};
      }

      &:hover {
        background: transparent;
        color: ${colors.black};
        border: 1px solid ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.gray300
        };
      }

      &:focus {
        color: ${colors.black};
        border: 1px solid ${
          color === "brand"
            ? colors.brand
            : color === "red"
            ? colors.red
            : colors.gray300
        };
        outline: 1px solid ${colors.black};
        outline-offset: 0px;
        background: transparent;
      }
      
      & svg {
          & path {
            fill: ${colors.black}
          }
      }
  `}

  ${({ paddingInline }) =>
    paddingInline
      ? `
    padding-inline: ${paddingInline}px;
  `
      : ``}

      ${({ customColor }) =>
    customColor &&
    css`
      background-color: ${customColor} !important;
      color: ${colors.white} !important; ;
    `}
`;

const AnimationCircle = keyframes`
 0% { transform: rotate(0deg) }
 100% { transform: rotate(360deg) }
`;

const LoadingIcons = styled(LoadingIcon)`
  width: 12px;
  height: 12px;
  transition: all 0.5s ease;
  animation-name: ${AnimationCircle};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

const StyledButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLeftIcon = styled(StyledButtonIcon)`
  margin-right: 4px;
`;

const ButtonRightIcon = styled(StyledButtonIcon)`
  margin-left: 4px;
`;

const StyledCountBlock = styled.div<IButton>`
  ${({ theme }) =>
    theme === "primary"
      ? `background: ${colors.white};`
      : `background: ${colors.black};`}
  background: ${({ theme }) =>
    theme === "primary" ? colors.white : colors.black};
  color: ${({ color, theme }) =>
    theme === "secondary" ? colors.white : color};
  ${({ size }) =>
    size === "middle"
      ? `
    padding: 2px 6px;
    min-width: 25px;
    height: 18px;
  `
      : `
    padding: 1px 5px;
    min-width: 23px;
    height: 16px;
  `}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin-left: 8px;
`;

const StyledChildrenBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChildrenWrapper = styled.div<{ type: "visible" | "hide" }>`
  ${({ type }) =>
    type === "visible"
      ? `& 
  .children-block {
      opacity: 0;
      height: 0;
      margin-top: 4px;
  }`
      : ` 
  .children-block {
    opacity: 1;
    height: 100%;
    margin-top: 0;
  }`}
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  position: relative;
`;

const Button: React.FC<IButton> = (props) => {
  const {
    className,
    isDisabled,
    children,
    onClick,
    isLoading: loading,
    icon,
    href,
    htmlType,
    isBlock,
    target,
    isUploadLeft,
    isUploadRight,
    count,
    theme,
    color,
    size,
    paddingInline = 0,
    customColor,
    ...rest
  } = props;

  return (
    <StyledButton
      customColor={customColor}
      paddingInline={paddingInline}
      className={className}
      disabled={isDisabled}
      onClick={onClick}
      href={href}
      htmlType={htmlType}
      block={isBlock}
      target={target}
      theme={theme}
      color={color}
      size={size}
      {...rest}
    >
      <StyledChildrenWrapper type={!loading ? "hide" : "visible"}>
        <StyledChildrenBlock className="children-block">
          {icon && isUploadLeft ? (
            <ButtonLeftIcon>{icon}</ButtonLeftIcon>
          ) : (
            <></>
          )}
          {children}
          {count ? (
            <StyledCountBlock theme={theme} color={color} size={size}>
              {count}
            </StyledCountBlock>
          ) : (
            <></>
          )}
          {icon && isUploadRight ? (
            <ButtonRightIcon>{icon}</ButtonRightIcon>
          ) : (
            <></>
          )}
        </StyledChildrenBlock>
        {loading ? <LoadingIcons /> : <></>}
      </StyledChildrenWrapper>
    </StyledButton>
  );
};

export { Button };
